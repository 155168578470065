import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Table, Pagination, Modal, Tag,  message } from 'antd'
import moment from 'moment'
import Loading from '@/components/Loading'
import { articleMessageList, articleMessageReplyList, articleMessageReply } from '@/api/recommend.js'

class CommentDetail extends Component {
    state = {
        columns: [
            {
                title: '评论内容',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    if(rowData.id=="yyy"){
                        return {
                            children:<Button size='small' onClick={()=>{this.handleMore(rowData)}}>查看更多...</Button>,
                            props:{colSpan:5}
                        }
                    }

                    return (rowData.content || "")
                }
            },
            {
                title: '评论人',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    if(rowData.id=="yyy"){
                        return {
                            children:'',
                            props:{colSpan:0}
                        }
                    }

                    return (
                        <p>{rowData.name} </p>
                    )
                }
            },
            {
                title: '评论时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    if(rowData.id=="yyy"){
                        return {
                            children:'',
                            props:{colSpan:0}
                        }
                    }

                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title: '公开状态',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    if(rowData.id=="yyy"){
                        return {
                            children:'',
                            props:{colSpan:0}
                        }
                    }

                    let color = "", tag_text = ""
                    if (rowData.status == 3 || rowData.status == 4) {
                        color = ""
                        tag_text = "隐藏"
                    } else if (rowData.status == 1) {
                        color = "orange"
                        tag_text = "待审核"
                    } else if (rowData.status == 2) {
                        color = "green"
                        tag_text = "公开"
                    }

                    return (
                        <Tag color={color}>{tag_text} </Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '7',
                width: '250px',
                render: (text, rowData, index) => {
                    if(rowData.id=="yyy"){
                        return {
                            children:'',
                            props:{colSpan:0}
                        }
                    }

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleReply(rowData)}}>回复</Button>
                        </div>
                    )
                }
            }
        ],
        isLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        article: {},
        comment_val:"",
        visible:false,
        modalLoading:false,
        article_message_id:"",
        reply_name:""
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {
            article_id: this.props.match.params.id
        };
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;

        this.setState({
            isLoading:true
        })

        articleMessageList(sendData).then(res => {
            let { article, list: { current_page, per_page, total, data } } = res.data
            data=data.map(val=>{
                if(val.reply_count > 1){
                    val.children=[]
                }
                return val
            })
            this.setState({
                article,
                page: current_page,
                per_page,
                total,
                tableData: data
            })
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })

    }

    fetchReplyList=(id,params={page:1,per_page:20},is_more)=>{
        let sendData=params
        sendData.article_message_id=id

        this.setState({
            isLoading:true
        })
        articleMessageReplyList(sendData).then(res=>{
            if(res.code==1){
                let {current_page,per_page,last_page,data}=res.data.list,tableData=this.state.tableData,index
                index=tableData.findIndex(val=>val.id==id) 

                if(is_more){
                    tableData[index].children.pop()
                    tableData[index].children=[...data]
                }else{
                    tableData[index].children=[...tableData[index].children,...data]
                }
                

                if(last_page>current_page){
                    tableData[index].children.push({
                        id:"yyy",
                        page:current_page,
                        per_page,
                        article_message_id:id,
                        index
                    })
                }

                this.setState({
                    tableData:tableData
                })
            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    handleMore=(row)=>{
        let params={
            page:row.page + 1,
            per_page:row.per_page
        }
        this.fetchReplyList(row.article_message_id,params,true)
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    handleCommentChange=e=>{
        let value=e.target.value
        this.setState({
            comment_val:value
        })
    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    handleOk=()=>{
        let {comment_val,article_message_id}=this.state,sendData={}
        if(!comment_val){
            this.handleCancel()
            return message.error("评论内容不能为空")
        }

        sendData.article_message_id=article_message_id
        sendData.content=comment_val
        this.setState({
            modalLoading:true
        })
        articleMessageReply(sendData).then(res=>{
            if(res.code==1){
                message.success('回复成功！').then(res=>{
                    this.fetchData()
                })
               
            }
        }).finally(()=>{
            this.setState({
                modalLoading:false,
                visible:false
            })
        })
    }

    handleReply=row=>{
        this.setState({
            article_message_id:row.id,
            comment_val:"",
            visible:true,
            reply_name:row.name
        })
    }
    handleExpand=(expanded, record)=>{
        console.log(expanded,record);
        if(expanded && (!record.children.length)){
            this.fetchReplyList(record.id)
        }
    }
   

    render() {
        let article = this.state.article || {}

        return (
            <div className='shadow-radius'>
                <div className={'msg-title'}>
                    “{article.title || ""}”的评论-{article.author_name || ""}（作者）
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={false}
                        rowKey={record => record.id}
                        pagination={false}
                        indentSize={40}
                        onExpand={this.handleExpand}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                <Modal
                    title={'回复评论'}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={600}
                    centered={true}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                >
                    <Input.TextArea rows={4} placeholder={`回复${this.state.reply_name}：`} value={this.state.comment_val} onChange={this.handleCommentChange} />
                </Modal>


                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}


export default withRouter(CommentDetail)
