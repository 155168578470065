import React from 'react'
import { Form, Input, Tooltip, Button, Pagination, Tabs, Divider, Table, Icon, Tag, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { fxGoods, addFxGoods, setGoodsCommission, removeFxGoods } from '@/api/fx'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import SetGoodsCommission from './set-goods-commission'
import ItemPicker from '@/components/ItemPicker/ItemPicker'
const FormItem = Form.Item

class FxGoods extends React.Component{
    state = {
        searchForm:{
            name:''
        },
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60} height={60} />
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <span>{ rowData.price ? `${rowData.price}元` : `--` }</span>
                        </div>
                    )
                }
            },
            {
                title:'累计销量',
                dataIndex:'real_sales',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.real_sales || 0 }</p>
                    )
                }
            },
            {
                title:() => {
                    return(
                        <div>
                            分销佣金
                            <Tooltip placement="topLeft" title={`以商品实际售价为准；例：商品零售价为100元，分销佣金为10%；当顾客购买时，分销员所获得佣金为100*10%`}>
                                <Icon type="question-circle"  className={`mgl5`} />
                            </Tooltip>
                        </div>
                    )
                },
                dataIndex:'first',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            { rowData.first }
                        </p>
                    )
                }
            },
            {
                title:() => {
                    return(
                        <div>
                            邀请奖励佣金
                            <Tooltip placement="topLeft" title={`当分销员分销商品成功后，邀请人获得对应的邀请奖励佣金；例：商品零售价为100元，邀请奖励佣金为5%，当顾客购买时邀请人活的100*5%`}>
                                <Icon type="question-circle"  className={`mgl5`} />
                            </Tooltip>
                        </div>
                    )
                },
                dataIndex:'second',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            { rowData.second }
                        </p>
                    )
                }
            },
            {
                title:'添加时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? `green` : `orange`}>{rowData.status == 1 ? '上架中' : '仓库中'}</Tag>
                    )
                }
            },

            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                this.state.isOpenGoods == 1
                                    ?
                                    <div className={'btn-row'}>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleCommission(rowData)}}>设置佣金</Button>
                                    </div>
                                    :
                                    <div className={'btn-row'}>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleCommission(rowData)}}>设置佣金</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>移除</Button>
                                    </div>

                            }

                        </div>
                    )
                }
            },
        ],
        tableData:[],
        selectRows:[],
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        agent_config:'',
        isOpenGoods:'',
        currentRow:null
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            isLoading:true
        })
        fxGoods(sendData)
            .then(res => {
                if(res.code == 1){
                    let goods_list = res.data.goods_list.data,
                        per_page = res.data.goods_list.per_page,
                        total = res.data.goods_list.total,
                        agent_config = res.data.agent_config || '',
                        isOpenGoods = res.data.config;
                    this.setState({
                        tableData:goods_list,
                        total,per_page,agent_config, isOpenGoods
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }
    handleAddItem = () => {
        let sendData = {};
        this.ItemPicker.show(res => {
            if(res && res.length){
                sendData.id = res[0]['id'] || '';
                this.setState({
                    isLoading:true
                })
                addFxGoods(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('添加成功')
                                .then(() => {
                                    this.handleReset();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleCommission = (rowData) => {
        let sendData = {}
        this.setState({
            currentRow:rowData
        },() => {
            this.SetGoodsCommission.show(res => {
                if(res){
                    sendData = res;
                    sendData.id = rowData.id || '';
                    this.setState({
                        isLoading:true
                    })
                    setGoodsCommission(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('设置成功')
                                    .then(() => {
                                        this.handleReset();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })
                }
            })
        })

    }
    handleDelete = (rowData) => {
        let sendData = {}
        Modal.confirm({
            title: `确定移除吗？移除后顾客购买该商品，分销员及邀请人将不能获得佣金。`,
            okType: `danger`,
            onOk: () => {
                sendData.id = rowData.id || ``;
                this.setState({
                    isLoading:true
                })
                removeFxGoods(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.handleReset();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }

    render() {
        const rowSelection = {
            selectedRowKeys:this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form

        return(
            <div className={``}>
                <ItemPicker
                    onRef={ e => { this.ItemPicker = e } }
                    selectIds={this.state.tableData.length ? this.state.tableData.map(item => {return Number(item.id)}) : []}
                    items={ this.state.tableData.length ? this.state.tableData : [] }
                    multiple={false}
                ></ItemPicker>
                <SetGoodsCommission
                    onRef={ e => { this.SetGoodsCommission = e } }
                    config={ this.state.agent_config }
                    data={ this.state.currentRow }
                ></SetGoodsCommission>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'商品名/商品编号'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索商品名/商品编号'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}
export default Form.create({})(withRouter(FxGoods))
