import React, { Component } from 'react'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Icon, Tooltip, Tag, message } from 'antd'
import {Link} from 'react-router-dom'
import CX from '@/utils/CX'

export default class activityItem extends Component {

    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }

    render() {

        let item=this.props.item || {}, group_list=item.group_list || []

        const head = (
            <div className='tal fw-400'>
                <span>团编号：{item.tuan_order_sn || ''}</span>
                <Icon type="file-text" className={`mgl5 cursor`} onClick={() => { this.handleCopy(item.tuan_order_sn || '') }} />
                <span className={`mgl20`}>开团时间：{item.created_at || ''}</span>
                <span className={`mgl20`}>成团人数：{item.open_num ||''}人</span>
                <span className={`mgl20`}>成团时间：{item.success_at?item.success_at :'尚未成团'}</span>
            </div>
        )


        const columns = [
            {
                title: head,
                colSpan: 9,
                dataIndex: '1',
                width: "17%",
                align:"left",
                render: (text, rowData, index) => {
                    if(!rowData.user){return null}
                    return (
                        <div className='flex align-center'>
                            <div>
                                <img src={rowData.user.head_img_url} width={60} alt="" className='bras50' />
                                <div className='mgl10' style={{'marginTop':'-10px'}}>
                                    {
                                       rowData.is_tuan? <Tag color='blue'>团长</Tag>:null
                                    }
                                </div>
                            </div>
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.user.name || ''}
                                    <i className={`icon-${rowData.user.sex == 1 ? 'male' : rowData.user.sex == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.user.mobile || `暂无手机号`}</p>
                                <Tag>普通会员</Tag>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '2',
                width: "13%",
                render: (text, rowData, index) => {
                    return <p>{rowData.trade && rowData.trade.order_sn ||'-'}</p>
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '3',
                width: "8%",
                render: (text, rowData, index) => {
                    return <p>{rowData.group_price||'-' }</p>
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '4',
                width: "8%",
                render: (text, rowData, index) => {
                    return <p>{rowData.trade && rowData.trade.pay_ment ||'-'}</p>
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '5',
                width: "13%",
                render: (text, rowData, index) => {
                    return <p>{rowData.trade && rowData.trade.pay_time ||'-'}</p>
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '6',
                width: "8%",
                render: (text, rowData, index) => {
                    if(rowData.status==2){
                        return   <Tag color="orange">拼团中</Tag>
                    }else if(rowData.success_at){
                        return   <Tag color="green">已成团</Tag>
                    }else{
                        return   <Tag >未成团</Tag>
                    }
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '7',
                width: "10%",
                render: (text, rowData, index) => {
                    return <p>{ rowData.store && rowData.store.name ||'-'}</p>
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '8',
                width: "15%",
                render: (text, rowData, index) => {
                    return (
                    <div>
                        <p>核销码：{rowData.trade && rowData.trade.use_code || '-'}</p>
                        <p>有效期：{rowData.effect_end_time? rowData.effect_end_time:'-'}</p>
                    </div>
                    )
                }
            },
            {
                title: '',
                colSpan: 0,
                dataIndex: '9',
                width: "8%",
                render: (text, rowData, index) => {
                    let order_sn=rowData.trade && rowData.trade.order_sn ||'-'
                    return <Link to={`/order/detail/${order_sn}`} target={`_blank`} className={`fi-link fz14 w150`}>查看详情</Link>
                }
            },
        ]

        return (
            <Table
                size={`middle`}
                columns={columns}
                dataSource={group_list}
                rowKey={record => record.id}
                pagination={false}
                className="mgt15"
                bordered
            ></Table>
        )
    }
}
