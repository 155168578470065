import React, { Component } from 'react'
import { Table, Pagination, Tooltip, Icon } from 'antd'

export default class RanKingTop10 extends Component {
    render() {
        let { exchange_num,exchange_points,exchange_user}=this.props.exchange || {};
       

        let tableData1 = exchange_num ||[];
        let tableData2 =exchange_user || [];
        let tableData3 =exchange_points || [];

        let columns1 = [
            {
                title: '排名',
                dataIndex: 'ranking',
                render: (text, rowData, index) => {
                    if(index==0){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/5d06c366ccb5f8a8e533361098e9bda0.png" alt="" width={30} />
                    }
                    if(index==1){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/f88adce65e85110056d9f37ed9d527e0.png" alt="" width={30} />
                    }
                    if(index==2){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/aa9b015742dfc2046c98b33bdfeac3b0.png" alt="" width={30} />
                    }


                    return (
                        <p className={`lh25`}>&nbsp;&nbsp;{index +1}</p>
                    )
                }
            },
            {
                title: '兑换商品名称',
                dataIndex: 'goods',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData?.goods_info?.cover_img} alt="" width={60} />
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} >
                                    {rowData?.goods_info?.name}
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '兑换数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.num}</p>
                    )
                }
            }
        ]

        let columns2 = [
            {
                title: '排名',
                dataIndex: 'ranking',
                render: (text, rowData, index) => {
                    if(index==0){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/5d06c366ccb5f8a8e533361098e9bda0.png" alt="" width={30} />
                    }
                    if(index==1){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/f88adce65e85110056d9f37ed9d527e0.png" alt="" width={30} />
                    }
                    if(index==2){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/aa9b015742dfc2046c98b33bdfeac3b0.png" alt="" width={30} />
                    }
                    
                    return (
                        <p className={`lh25`}>&nbsp;&nbsp;{index +1}</p>
                    )
                }
            },
            {
                title: '兑换商品名称',
                dataIndex: 'goods',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData?.goods_info?.cover_img} alt="" width={60} />
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} >
                                    {rowData?.goods_info?.name}
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '兑换人数',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.num}</p>
                    )
                }
            }
        ]

        let columns3 = [
            {
                title: '排名',
                dataIndex: 'ranking',
                render: (text, rowData, index) => {
                    if(index==0){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/5d06c366ccb5f8a8e533361098e9bda0.png" alt="" width={30} />
                    }
                    if(index==1){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/f88adce65e85110056d9f37ed9d527e0.png" alt="" width={30} />
                    }
                    if(index==2){
                        return <img src="https://img.cxkoo.com/chengxuan/1/2023-05-26/aa9b015742dfc2046c98b33bdfeac3b0.png" alt="" width={30} />
                    }

                    return (
                        <p className={`lh25`}>&nbsp;&nbsp;{index +1}</p>
                    )
                }
            },
            {
                title: '兑换商品名称',
                dataIndex: 'goods',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData?.goods_info?.cover_img} alt="" width={60} />
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} >
                                    {rowData?.goods_info?.name}
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '兑换消耗积分数',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData?.points}</p>
                    )
                }
            }
        ]

        return (
            <div className='flex justify-between'>
                <div className="flex1">
                    <p className='fz18 color-000 lh45 mgb10'>积分兑换数量TOP10</p>
                    <Table
                        size={`middle`}
                        columns={columns1}
                        dataSource={tableData1}
                        rowKey={(record,i) => (i+'')}
                        pagination={false}
                    ></Table>
                </div>
                <div className="flex1 mgl30">
                    <p className='fz18 color-000 lh45 mgb10'>积分兑换人数TOP10</p>
                    <Table
                        size={`middle`}
                        columns={columns2}
                        dataSource={tableData2}
                        rowKey={(record,i) => (i+'')}
                        pagination={false}
                    ></Table>
                </div>
                <div className="flex1 mgl30">
                    <p className='fz18 color-000 lh45 mgb10'>积分兑换消耗TOP10</p>
                    <Table
                        size={`middle`}
                        columns={columns3}
                        dataSource={tableData3}
                        rowKey={(record,i) => (i+'')}
                        pagination={false}
                    ></Table>
                </div>
            </div>
        )
    }
}
