import React from 'react'
import { Form, Tabs } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import GuestBookManage from "./components/guest-book-manage";
import TotalBookManage from './components/total-book-manage'
const FormItem = Form.Item
const { TabPane } = Tabs

class CourtPriceManage extends React.Component{
    state = {
        isLoading:false,
        activeKey:'1'

    }
    componentDidMount() {

    }
    fetchData = () => {}
    clickTab = (val) => {
        this.setState({
            activeKey:val
        })
    }

    render() {
        return(
            <div className={`shadow-radius`}>
                <Tabs activeKey={this.state.activeKey} onChange={ this.clickTab }>
                    <TabPane tab="整租场地价格管理" key="1">
                        <TotalBookManage onRef={ e => { this.TotalBookManage = e } }></TotalBookManage>
                    </TabPane>
                    <TabPane tab="散客价格管理" key="2">
                        <GuestBookManage onRef={ e => { this.GuestBookManage = e } }></GuestBookManage>
                    </TabPane>
                </Tabs>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(CourtPriceManage))
