import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Table,Icon,Button ,message,Tag} from 'antd'
import CX from '@/utils/CX'

export default class GroupBuyDetail extends Component {

    state={
        columns:[
            {
                title: '客户信息',
                dataIndex: '1',
                align:"left",
                render: (text, rowData, index) => {
                    if(!rowData.user){return null}
                    return (
                        <div className='flex align-center'>
                            <div>
                                <img src={rowData.user.head_img_url} width={60} alt="" className='bras50' />
                                <div className='mgl10' style={{'marginTop':'-10px'}}>
                                    {
                                       rowData.is_tuan? <Tag color='blue'>团长</Tag>:null
                                    }
                                </div>
                            </div>
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.user.name || ''}
                                    <i className={`icon-${rowData.user.sex == 1 ? 'male' : rowData.user.sex == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.user.mobile || `暂无手机号`}</p>
                                <Tag>普通会员</Tag>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '商户单号',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData.trade && rowData.trade.order_sn ||'-'}</p>
                }
            },
            {
                title: '拼团价',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return <p>{rowData.group_price||'-' }</p>
                }
            },
            {
                title: '实付金额',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return <p>{rowData.trade && rowData.trade.pay_ment ||'-'}</p>
                }
            },
            {
                title: '支付时间',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return <p>{rowData.trade && rowData.trade.pay_time ||'-'}</p>
                }
            },
            {
                title: '团状态',
                dataIndex: '6',
                render: (text, rowData, index) => {

                    if(rowData.status==2){
                        return   <Tag color="orange">拼团中</Tag>
                    }else if(rowData.success_at){
                        return   <Tag color="green">已成团</Tag>
                    }else{
                        return   <Tag >未成团</Tag>
                    }

                   
                }
            },
            {
                title: '下单门店',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return <p>{ rowData.store && rowData.store.name ||'-'}</p>
                }
            },
           
            {
                title: '操作',
                dataIndex: '8',
                width: "10%",
                render: (text, rowData, index) => {
                    let order_sn=rowData.trade && rowData.trade.order_sn ||'-'
                    
                    return <Link to={`/order/detail/${order_sn}`} target={`_blank`} className={`fi-link fz14 w150`}>查看详情</Link>
                }
            },
        ]
    }

    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }

  render() {
    let tableData=this.props.group_list || [],group_info=this.props.group_info||{},group_trade=this.props.group_trade || {}

    return (
      <div>
            <div className={`msg-title mgt15`}>拼团详情</div>
            <div className='msg-title mgt15'>
                <div className='tal fw-400'>
                    <span>团编号：{group_info.tuan_order_sn ||''}</span>
                    <Icon type="file-text" className={`mgl5 cursor`} onClick={() => { this.handleCopy(group_info.tuan_order_sn||'') }} />
                    <span className={`mgl20`}>开团时间：{group_info.open_time||''}</span>
                    <span className={`mgl20`}>成团人数：{group_info.open_num||''}人</span>
                    <span className={`mgl20`}>成团时间：{group_info.success_at?group_info.success_at: [5,7].includes(group_trade.status)?'交易关闭，未成团':'尚未成团' }</span>
                </div>
            </div>

            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                rowKey={record => record.id}
                pagination={false}
               
                bordered
            ></Table>
      </div>
    )
  }
}
