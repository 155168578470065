import $axios from '@/axios/axios.js'

export function storeCourt(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space`,
        method:'post',
        data
    })
}

export function courtStoreLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/storeList`,
        method:'post',
        data
    })
}

export function courtStoreDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/storeDetail`,
        method:'post',
        data
    })
}

export function addCourtTag(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/addTag`,
        method:'post',
        data
    })
}

export function delCourtTag(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/deleteTag`,
        method:'post',
        data
    })
}

export function saveCourtDesc(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/saveDesc`,
        method:'post',
        data
    })
}

export function getCourtTypes(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/spaceType`,
        method:'post',
        data
    })
}

export function saveCourtTypes(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/saveSpaceType`,
        method:'post',
        data
    })
}

export function delCourtTypes(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/deleteSpaceType`,
        method:'post',
        data
    })
}

export function storeCourtManage(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/space`,
        method:'post',
        data
    })
}

export function addStoreCourt(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/addSpace`,
        method:'post',
        data
    })
}

export function storeCourtDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/spaceDetail`,
        method:'post',
        data
    })
}

export function saveStoreCourt(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/saveSpace`,
        method:'post',
        data
    })
}

export function delStoreCourt(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/deleteSpace`,
        method:'post',
        data
    })
}

export function getSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/getSetting`,
        method:'post',
        data
    })
}

export function saveSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/saveSetting`,
        method:'post',
        data
    })
}

export function guestBookLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/spaceMix`,
        method:'post',
        data
    })
}

export function guestBookSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/saveSpaceMix`,
        method:'post',
        data
    })
}

export function delGuestBook(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/deleteSpaceMix`,
        method:'post',
        data
    })
}

export function guestBookSuitStore(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/suitStores`,
        method:'post',
        data
    })
}

export function copyGuestBook(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/copySpaceMix`,
        method:'post',
        data
    })
}

export function getTotalBook(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/spaceRent`,
        method:'post',
        data
    })
}

export function setTotalBook(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/saveSpaceRent`,
        method:'post',
        data
    })
}

export function copyTotalBook(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/copySpaceRent`,
        method:'post',
        data
    })
}

export function courtDynamic(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/spaceDynamic`,
        method:'post',
        data
    })
}

export function courtGetTime(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/getTime`,
        method:'post',
        data
    })
}

export function addCourtBook(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/addBook`,
        method:'post',
        data
    })
}

export function cleanUp(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/cleanup`,
        method:'post',
        data
    })
}

export function courtBookingOrder(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/bookingOrder`,
        method:'post',
        data
    })
}

export function courtOrderDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/orderDetail`,
        method:'post',
        data
    })
}

export function courtCash(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/payOrder`,
        method:'post',
        data
    })
}

export function courtCancelOrder(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/cancelOrder`,
        method:'post',
        data
    })
}

export function saveGuestTime(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/saveTime`,
        method:'post',
        data
    })
}

export function delGuestTime(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/space/delTime`,
        method:'post',
        data
    })
}
