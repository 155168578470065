import React, { Component } from 'react'
import { Table, Pagination, Tooltip,Icon } from 'antd'
import {studentCourseRank} from '@/api/data'

export default class StudentTotalRanking extends Component {
    state = {
        columns: [
            {
                title: '排名',
                dataIndex: 'ranking',
                align:'center',
                render: (text, rowData, index) => {
                    let {page,per_page}=this.state;
                    let rank=(page-1)*per_page +index +1;
                    return (
                        <p className={`lh25 `}>{rank}</p>
                    )
                }
            },
            {
                title: '学员信息',
                dataIndex: 'logo',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url || 'https://img.cxkoo.com/chengxuan/1/2022-05-24/67968ca724e5676cfdf58c050a6beba5.png'} alt="" width={60} height={60} style={{objectFit:'cover'}} />
                            <div className={`mgl10`}>
                                <div className={`color-primary w150 lh25`} >
                                    {rowData.real_name || ``}
                                   
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '学员手机',
                dataIndex: 'mobile',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.parent_mobile}</p>
                    )
                }
            },
            {
                title:
                    <>
                        累计总课时数
                        <Tooltip placement={`right`} title={`截止查询时间末，学员总课时数（含购买及商家赠送）`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </>,
                dataIndex: 'total_points',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.total_course_num}</p>
                    )
                }
            },
            {
                title:
                    <>
                        待消耗课时数
                        <Tooltip placement={`right`} title={`截止查询时间末，该学员待消耗课时数`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </>,
                dataIndex: 'points',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.course_num}</p>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {
            page: this.state.page,
            per_page: this.state.per_page
        }
        this.setState({
            tableLoading:true
        })

        studentCourseRank(sendData).then(res=>{
            if(res.code==1){
                let {current_page,data,per_page,total}=res.data.rank_list;
                this.setState({
                    page:current_page,
                    tableData:data,
                    per_page,
                    total
                })
            }

        }).finally(()=>{
            this.setState({
                tableLoading:false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        return (
            <div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}
