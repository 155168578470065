export const MODULE_DEFAULT_DATA = {
    1: {
        /*公告*/
        type: 1,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            desc: '请填写内容，如果过长，将会在手机上滚动显示'
        }
    },
    2: {
        /*图片*/
        type: 2,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            spacePadding:0,
            showType: 1,
            dataset: [
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                }
            ]
        }
    },
    3: {
        /*图文导航*/
        type: 3,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style: 1,
            txtColor:'#000',
            bgColor:'#fff',
            dataset: []
        }
    },
    4: {
        /*分割线*/
        type: 4,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            color: '#DDDDDD',
            borderWidth: 1,
            borderStyle: 0, //0实线 1虚线
        }
    },
    5: {
        /*轮播图*/
        type: 5,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            spacePadding:0,
            height: '',
            style:1,
            borderStyle:1,
            sizeStyle:1,  // 1 自适应 2 750*750 3 750*450
            interval:3000,
            bgColor:'#4F0201',
            txtColor:'#ffffff',
            showAddress:1,
            tags:[],
            dataset: [
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                }
            ],
        }
    },
    6: {
        /*标题文本*/
        type: 6,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            alignStyle: 0, //0居中 1居左 2居右
            title: '请输入文本内容',
            linkType: 0,
            link: '',
            fontSize: 16,
            fontBold:0,
            titleColor: '#333333',
            backgroundColor: '#ffffff'
        }
    },
    7: {
        /*图片热区*/
        type: 7,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            dataset: [
                {
                    pic: '',
                    hotArea: []
                }
            ]
        }
    },
    8: {
        /*商品列表*/
        type: 8,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            borderStyle:1,
            showSubTitle:1,
            showOprice:1,
            showCart:1,
            cartStyle:1,
            showSale:1,
            showMarket:1,
            dataset: []
        }
    },
    9: {
        /*商品搜索*/
        type: 9,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            borderColor:'#C2C2C2',
            bgColor:'#F5F5F5',
            btnColor:'#000',
            placeholder:'商品搜索'
        }
    },
    10: {
        /*扫码下单*/
        type: 10,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            bgColor:'#ffffff',
            txtColor:'#000000',
            dataset:[
                {
                    pic:'https://img.cxkoo.com/chengxuan/1/2021-12-05/096f12a701b14553cf442b40a491c056.png',
                    title:'开始点餐',
                    link:'/pages/scan/item'
                },
                {
                    pic:'https://img.cxkoo.com/chengxuan/1/2021-12-05/c7481e4069a1690f6266ace4163f5f86.png',
                    title:'买单付款',
                    link:'/pages/selfPay/index'
                }
            ]
        }
    },
    11: {
        /*倒计时*/
        type: 11,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            title:'春节大促活动',
            titleColor:'#FFD40D',
            subTitle:'活动',
            txtColor:'#ffffff',
            timeColor:'#A80000',
            bgStyle:1,
            bgColor:'#A80000',
            pic:'https://img.cxkoo.com/chengxuan/1/2021-12-10/58b1e8a82125ec98473f4004c5312d3a.png',
            start_at:'',
            end_at:'',

        }
    },
    12: {
        /*ktv预订*/
        type: 12,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            style:1
        }
    },
    13: {
        /*限时折扣*/
        type: 13,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            style:1,
            borderStyle:1,
            showSubTitle:1,
            showStock:1,
            dataset:[]
        }
    },
    14: {
        /*场馆预订*/
        type: 14,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            style:1,
            title:'场馆预订',
            titleFontSize:16,
            titleFontColor:'#000000',
            btnColor:'#FF345C',
            btnFontColor:'#ffffff',
            dataset:[]
        }
    },
    15: {
        /*课程列表*/
        type: 15,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            borderStyle:1,
            showSubTitle:1,
            dataset: []
        }
    },
    16: {
        /*老师列表*/
        type: 16,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            borderStyle:1,
            showTeacherAge:1,
            showTag: 1,
            tagBgColor:"#ffffff",
            tagBorderColor:"#EEB896",
            tagTextColor:"#EEB896",
            dataset: []
        }
    },
    17: {
        /*拼团列表*/
        type: 17,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            borderStyle:1,
            showSubTitle:1,
            showSales: 1,
            textColor:"#ffffff",
            startColor:"#FF3636",
            notStartColor:"#6D30FF",
            dataset: []
        }
    },
    18: {
        /*笔记列表*/
        type: 18,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            showLike:1,
            showPv:1,
            dataset: []
        }
    },
    19: {
        /*web-view*/
        type: 19,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            cover_img:"",
            jump_url:"",
            dataset: []
        }
    },
    20: {
        /*优惠券*/
        type: 20,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            couponColor:"#000000",
            couponTextColor:"#ffffff",
            btnColor:"#ffffff",
            btnTextColor:"#000000",
            title:"先领券再购物",
            dataset: []
        }
    },
    21: {
        /*卡片轮播图*/
        type: 21,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            second:2,
            showType: 1,
            bg_img:"",
            dataset: [
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                },
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                },
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                }
            ]
        }
    },
    22: {
        /*3D轮播图*/
        type: 22,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            showType: 1,
            bg_img:"",
            dataset: [
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                },
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                },
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                }
            ]
        }
    },
    23: {
        /*社群团购*/
        type: 23,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            borderStyle:1,
            showPv:1,
            showGroupNum:1,
            btnColor:'#FF3636',
            btnTextColor:'#FFFFFF',
            dataset: []
        }
    },
    24: {
        /*积分兑换*/
        type: 24,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            style:1,
            borderStyle:1,
            showCategory:0,
            showBtn:1,
            btnBgColor:'#ff0000',
            btnTextColor:'#ffffff',
            priceColor:'#ff0000',
            dataset: [],
            category:{}
        }
    },
}
