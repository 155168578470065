import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tabs, message } from 'antd';
import BaseForm from './components/BaseForm'
import ShareForm from './components/ShareForm';
import Loading from '@/components/Loading';
import { pullNewSave, pullNewDetail } from "@/api/recruit.js"
import moment from 'moment'
import '@/assets/css/recruit/index.scss';
const FormItem = Form.Item;
const { TabPane } = Tabs;


class RecruitAdd extends Component {
    state = {
        activeStatus: '1',
        isLoading: false,
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-07-15/68547b034762fd6b3fb5680febb2089b.png",
        share_poster: "https://img.cxkoo.com/chengxuan/1/2022-07-15/68547b034762fd6b3fb5680febb2089b.png",
        is_edit: false,
        activityData: {},
        bg_color: "#FF2700",
    }

    componentDidMount() {

        if (this.props.match.params.id) {
            this.setState({
                is_edit: true
            })
            this.fetchData()
        }
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })

        pullNewDetail({ id: this.props.match.params.id }).then(res => {
            if (res.code == 1) {

                const { detail } = res.data

                if (detail.start_time && detail.end_time) {
                    detail.time = [moment(detail.start_time, 'YYYY-MM-DD HH:mm:ss'), moment(detail.end_time, 'YYYY-MM-DD HH:mm:ss')]
                }


                if (detail.exchange_start_time && detail.exchange_end_time) {
                    detail.exchange_time = [moment(detail.exchange_start_time, 'YYYY-MM-DD HH:mm:ss'), moment(detail.exchange_end_time, 'YYYY-MM-DD HH:mm:ss')]
                }

                this.setState({
                    activityData: detail,
                    bg_img: detail.bg_img,
                    bg_color:detail.bg_color
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    clickTab = val => {
        this.setState({
            activeStatus: val
        })
    }

    saveData = async () => {
        const BaseFormData = await this.BaseForm.getFormData()
        
        if (!BaseFormData) {
            return this.clickTab('1')
        }

        const ShareFormData = await this.ShareForm.getFormData()
        if (!ShareFormData) {
            return this.clickTab('2')
        }

        const sendData = { ...BaseFormData, ...ShareFormData }
        sendData.bg_img = this.state.bg_img

        if (this.state.is_edit) {
            sendData.id = this.state.activityData.id
        }

        if (!sendData.share_poster) {
            sendData.share_poster = this.state.share_poster
        }

        this.setState({
            isLoading: true
        })

        pullNewSave(sendData).then(res => {
            if (res.code == 1) {
                message.success(res.msg)
                    .then(() => {
                        this.props.history.push({
                            pathname: `/recruit/lists`
                        })
                    })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }



    updateData = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleChangeTabs = key => {
        this.setState({
            activeStatus: key
        })
    }

    render() {

        const { bg_color } = this.state
        

        return (
            <div className='shadow-radius recruit'>
                <div className="clearfix mgt15">
                    <div className="phone-wrap fl" style={{ 'background': bg_color }}>
                        <div className="phone-title">拉新分享</div>
                        <div className="phone-img">
                            <img src={this.state.bg_img} alt="" />
                            <div className="news">
                                <div className='news-icon'></div>
                                <span>小苹果成功邀请1人，获得2课时</span>
                            </div>
                            <div className="desc-btn">活动说明</div>
                        </div>
                        <div className="phone-body" >
                            <div className="item-wrap">
                                <div className="title">邀请攻略</div>
                                <div className="steps">
                                    <div className="step1">
                                        <div className="img"></div>

                                    </div>
                                    <div className="you"></div>
                                    <div className="step2">
                                        <div className="img"></div>

                                    </div>
                                    <div className="you"></div>
                                    <div className="step3">
                                        <div className="img"></div>
                                    </div>
                                </div>
                                <div className="steps-text">
                                    <div className="text">分享邀请<br /> 给好友</div>
                                    <div className="text">好友点击链接<br />完成授权</div>
                                    <div className="text">邀请达成<br />获得奖励</div>
                                </div>
                            </div>

                            <div className="share-btns">
                                <div className="btn">
                                    <div className="wx-ico"></div>
                                    <span>微信好友</span>
                                </div>
                                <div className="btn">
                                    <div className="hb-ico"></div>
                                    <span>生成海报</span>
                                </div>
                            </div>

                            <div className="item-wrap">
                                <div className="title">我的成就</div>
                                <div className="attainment">
                                    <div>
                                        <div className="label">已成功邀请</div>
                                        <div className="text">0人</div>
                                    </div>
                                    <div>
                                        <div className="label">已成功邀请</div>
                                        <div className="text">0人</div>
                                    </div>
                                </div>
                            </div>

                            <div className="item-wrap">
                                <div className="title">邀请记录</div>
                                <div className="record">—— 仅展示最近20位 ——</div>
                                <div className="record mgb40 mgt30">暂无邀请记录</div>
                            </div>


                        </div>
                    </div>
                    <div className="ctrl-wrap fl mgl20">
                        <Tabs className={'mgt15'} activeKey={this.state.activeStatus} onChange={this.handleChangeTabs}>
                            <TabPane tab="基础设置" key={'1'}>
                                <BaseForm onRef={e => this.BaseForm = e} updateData={this.updateData} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData}></BaseForm>
                            </TabPane>
                            <TabPane tab="分享设置" key={'2'}>
                                <ShareForm onRef={e => this.ShareForm = e} saveData={this.saveData} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData}></ShareForm>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(RecruitAdd))