import React from 'react'
import { Form, Tabs } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import KtvMeal from './components/ktv-meal'
import StockPriceManage from './components/stock-price-manage'
import KtvRoomStatus from './components/ktv-room-status'
import BookSetting from './components/book-setting'
const FormItem = Form.Item;
const { TabPane } = Tabs
class KTV extends React.Component{
    static childContextTypes = {
        isLoading:PropTypes.bool,
        setLoading:PropTypes.func,
        setContextState:PropTypes.func,
    }
    state = {
        isLoading:false,
        storeLists:[],
        activeKey:'1'
    }
    getChildContext = () => {
        return{
            isLoading:this.state.isLoading,
            setLoading:(e) => {
                this.setLoading(e)
            },
            setContextState:(params = {}) => {
                this.setContextState(params)
            }
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchStore();
    }
    setLoading = e => {
        this.setState({
            isLoading:!!e
        })
    }
    setContextState = params => {
        if (Object.keys(params).length) {
            this.setState(params)
        }
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data || [];
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchData = () => {}
    clickTab = (val) => {
        this.setState({
            activeKey:val
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        let storeLists = this.state.storeLists || []
        return(
            <div className={'shadow-radius'}>
                <Tabs activeKey={this.state.activeKey} onChange={ this.clickTab }>
                    <TabPane tab="套餐管理" key="1">
                        <KtvMeal onRef={ e => { this.KtvMeal = e } } storeLists={ this.state.storeLists }></KtvMeal>
                    </TabPane>
                    <TabPane tab="时段库存及价格管理" key="2">
                        {
                            storeLists.length < 1
                                ?
                                <div className={`content-msg mgb10`}>
                                    <p>
                                        包厢预订功能基于门店实现，请先<Link to={'/store/add'} className={`mgl5`} >创建门店</Link>
                                    </p>
                                </div>
                                :
                                <StockPriceManage onRef={ e => { this.StockPriceManage = e } } storeLists={ this.state.storeLists }></StockPriceManage>
                        }
                    </TabPane>
                    <TabPane tab="每日房态管理" key="3">
                        {
                            storeLists.length < 1
                                ?
                                <div className={`content-msg mgb10`}>
                                    <p>
                                        包厢预订功能基于门店实现，请先<Link to={'/store/add'} className={`mgl5`} >创建门店</Link>
                                    </p>
                                </div>
                                :
                                <KtvRoomStatus onRef={ e => { this.KtvRoomStatus = e } } storeLists={ this.state.storeLists }></KtvRoomStatus>
                        }
                    </TabPane>
                    <TabPane tab="预订设置" key="4">
                        {
                            storeLists.length < 1
                                ?
                                <div className={`content-msg mgb10`}>
                                    <p>
                                        包厢预订功能基于门店实现，请先<Link to={'/store/add'} className={`mgl5`} >创建门店</Link>
                                    </p>
                                </div>
                                :
                                <BookSetting onRef={ e => { this.BookSetting = e } } storeLists={ this.state.storeLists }></BookSetting>
                        }
                    </TabPane>
                </Tabs>


                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(KTV))
