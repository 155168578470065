/**
 * 场馆预订组件
 * @type 14
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'

const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})

class tpl_con_type14 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }
    state = {
        moduleData:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这个模块吗？`,
            okType:`danger`,
            onOk:() => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`场馆预订`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{ paddingTop:`${moduleData.content.modulePadding}px`,paddingBottom:`${moduleData.content.modulePadding}px`}} onClick={ this.handleEditModule }>
                        <div className={`tpl_14`}>
                            <div className={`tpl14-cont`}>
                                {
                                    moduleData.content.title
                                        ?
                                        <div className={`title`} style={{ fontSize:`${moduleData.content.titleFontSize}px`,color:`${moduleData.content.titleFontColor}` }}>{ moduleData.content.title }</div>
                                        :
                                        null
                                }
                                {
                                    moduleData.content.style == 1
                                        ?
                                        moduleData.content.dataset.length
                                            ?
                                            <div className="court-style1">
                                                {
                                                    moduleData.content.dataset.map((item,index) => (
                                                        <div className="court-list" key={index}>
                                                            <div className="left" style={{ backgroundImage:`url(${item.pic})` }}></div>
                                                            <div className="right">
                                                                <div className="name">{ item.store?.name }</div>
                                                                <div className="tags-cont">
                                                                    <div className="tag">休息区</div>
                                                                    <div className="tag">停车场</div>
                                                                </div>
                                                                <div className="address">{ item.store?.address }</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className="court-style1">
                                                <div className="court-list">
                                                    <div className="left" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2022-03-31/0b9d20130c782561afcb91551776cd5c.png)` }}></div>
                                                    <div className="right">
                                                        <div className="name">场地名称</div>
                                                        <div className="tags-cont">
                                                            <div className="tag">休息区</div>
                                                            <div className="tag">停车场</div>
                                                        </div>
                                                        <div className="address">场地地址详情</div>
                                                    </div>
                                                </div>
                                                <div className="court-list">
                                                    <div className="left" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2022-03-31/0b9d20130c782561afcb91551776cd5c.png)` }}></div>
                                                    <div className="right">
                                                        <div className="name">场地名称</div>
                                                        <div className="tags-cont">
                                                            <div className="tag">休息区</div>
                                                            <div className="tag">停车场</div>
                                                        </div>
                                                        <div className="address">场地地址详情</div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        null
                                }
                                {
                                    moduleData.content.style == 2
                                        ?
                                        moduleData.content.dataset.length
                                            ?
                                            <div className="court-style2">
                                                {
                                                    moduleData.content.dataset.map((item,index) => (
                                                        <div className="court-list" key={index}>
                                                            <div className="store-img" style={{ backgroundImage:`url(${item.pic})` }}></div>
                                                            <div className="store-desc">
                                                                <div className="store-name">
                                                                    <div className="left">{ item.store?.name }</div>
                                                                    <div className="book-btn" style={{ color:`${ moduleData.content.btnFontColor }`,backgroundColor:`${moduleData.content.btnColor}` }}>预订</div>
                                                                </div>
                                                                <div className="store-address">{ item.store?.address }</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className="court-style2">
                                                <div className="court-list">
                                                    <div className="store-img" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2022-03-31/0b9d20130c782561afcb91551776cd5c.png)` }}></div>
                                                    <div className="store-desc">
                                                        <div className="store-name">
                                                            <div className="left">场馆名称</div>
                                                            <div className="book-btn">预订</div>
                                                        </div>
                                                        <div className="store-address">场馆地址</div>
                                                    </div>
                                                </div>
                                                <div className="court-list">
                                                    <div className="store-img" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2022-03-31/0b9d20130c782561afcb91551776cd5c.png)` }}></div>
                                                    <div className="store-desc">
                                                        <div className="store-name">
                                                            <div className="left">场馆名称</div>
                                                            <div className="book-btn">预订</div>
                                                        </div>
                                                        <div className="store-address">场馆地址</div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        null
                                }
                                {
                                    moduleData.content.style == 3
                                        ?
                                        moduleData.content.dataset.length
                                            ?
                                            <div className="court-style3">
                                                {
                                                    moduleData.content.dataset.map((item,index) => (
                                                        <div className="court-list" key={index}>
                                                            <div className="store-img" style={{ backgroundImage:`url(${item.pic})` }}></div>
                                                            <div className="store-desc">
                                                                <div className="name">{ item.store?.name }</div>
                                                                <div className="address">{ item.store?.address }</div>
                                                                <div className="book-btn" style={{ color:`${ moduleData.content.btnFontColor }`,backgroundColor:`${moduleData.content.btnColor}` }}>预订</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className="court-style3">
                                                <div className="court-list">
                                                    <div className="store-img" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2022-03-31/0b9d20130c782561afcb91551776cd5c.png)` }}></div>
                                                    <div className="store-desc">
                                                        <div className="name">场馆名称</div>
                                                        <div className="address">场馆地址</div>
                                                        <div className="book-btn">预订</div>
                                                    </div>
                                                </div>
                                                <div className="court-list">
                                                    <div className="store-img" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2022-03-31/0b9d20130c782561afcb91551776cd5c.png)` }}></div>
                                                    <div className="store-desc">
                                                        <div className="name">场馆名称</div>
                                                        <div className="address">场馆地址</div>
                                                        <div className="book-btn">预订</div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type14)

