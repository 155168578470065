import React from 'react'
import { Modal, Input, Form } from 'antd'
import { expressLists } from '@/api/order'
const FormItem = Form.Item;
const { TextArea } = Input

class PayCode extends React.Component{
    state = {
        formData:{},
        title:'同意申请',
        visible:false,
        modalLoading:false,
        callback:null

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback:callback || null
        })

    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let callback = this.state.callback
        this.props.form.validateFields((err,values) => {
            if(!err){
                this.setState({
                    formData:values
                },() => {
                    if(typeof callback == 'function'){
                        callback(values)
                    }
                    this.close()
                })
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol:{
                span:5
            },
            wrapperCol:{
                span:17
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <FormItem label="交易流水号">
                        {getFieldDecorator('pay_code', {
                            initialValue: data && data.pay_code,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入交易流水号'
                                }
                            ]
                        })(<TextArea rows={6} placeholder={'手动打款后，请复制交易流水号到这里'} />)}
                    </FormItem>

                </Form>
            </Modal>
        )
    }
}
export default Form.create({})(PayCode)
