/**
 * 图片热区
 * @type 7
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber} from 'antd'
import PropTypes from "prop-types";
import message from '@/utils/message'
import Gallery from '@/components/Gallery/Gallery'
import EditHotArea from '../EditHotArea/EditHotArea'
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 16 }}

class tpl_ctrl_type7 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }

    state = {
        moduleData:{}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    chooseImg = (item,index) => {
        let moduleData = this.state.moduleData;
        this.Gallery.show(res => {
            if(res){
                moduleData.content.dataset[index]['pic'] = res[0];
                this.context.updateDesign(moduleData)
            }
        })

    }
    delImg = (item,index) => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这张图片吗？`,
            okType:`danger`,
            onOk:() => {
                moduleData.content.dataset.splice(index,1);
                this.context.updateDesign(moduleData)
            }
        })
    }
    handleAdd = () => {
        let moduleData = this.state.moduleData,
            newData = { pic:'', hotArea:[] };
        moduleData.content.dataset.push(newData);
        this.context.updateDesign(moduleData)
    }
    handleEditHotArea = (item,index) => {
        let moduleData = this.state.moduleData;
        if(!item.pic){
            message.error('请先选择图片再设置热区');
            return ;
        }
        this.EditHotArea.show({
            pic:item.pic,
            hotAreas:item.hotArea || [],
            callBack:(res) => {
                moduleData.content.dataset[index]['hotArea'] = res;
                this.context.updateDesign(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>图片热区</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>添加图片</div>
                            <div className={`cx-ctrl-panel--box`}>
                                {
                                    moduleData.content.dataset.map((item,index) => (
                                        <div className={`cx-img-list`} key={index}>
                                            <div className={`cx-img-item clearfix`}>
                                                <FormItem label={`选择图片`} {...ctrlItemLayout}>
                                                    <div className={'clearfix'}>
                                                        <div className={'img-add'} onClick={() => { this.chooseImg(item,index) }} data-name="pic">
                                                            {
                                                                item.pic
                                                                    ?
                                                                    <div>
                                                                        <img src={item.pic} alt=""/>
                                                                    </div>
                                                                    :
                                                                    '+'
                                                            }
                                                        </div>
                                                    </div>
                                                </FormItem>
                                                <FormItem label={`操作`} {...ctrlItemLayout}>
                                                    {
                                                        item.hotArea && item.hotArea.length
                                                            ?
                                                            <Button type={`primary`} onClick={ () => { this.handleEditHotArea(item,index) } }>查看热区</Button>
                                                            :
                                                            <Button type={`default`} onClick={ () => { this.handleEditHotArea(item,index) } }>设置热区</Button>

                                                    }
                                                </FormItem>
                                            </div>
                                            {
                                                index > 0
                                                    ?
                                                    <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delImg(item,index) }} data-name={'pic'}  />
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))
                                }
                                <Button type={`primary`} className={`w100`} onClick={this.handleAdd} disabled={moduleData.content.dataset.length >= 3} >添加图片</Button>

                            </div>
                        </div>

                    </Form>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <EditHotArea onRef={ e => { this.EditHotArea = e } }></EditHotArea>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type7)
