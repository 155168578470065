import * as actionTypes from '../constants/index'
const setShop = (data) => {
    return {
        type: actionTypes.SET_SHOP,
        data
    }
}
const removeShop = () => {
    return {
        type: actionTypes.REMOVE_SHOP
    }

}
export { setShop, removeShop }
