import React, { Component } from 'react'
import { Select, InputNumber, Radio, Checkbox, Button, message } from 'antd';
import { storeLists } from '@/api/store'
import { getTradeConfig, setTradeConfig } from '@/api/system'
import Loading from '@/components/Loading'
const { Option } = Select;

export default class TradeSystem extends Component {
    state = {
        isLoading: false,
        store_id: '',
        store_list: [],
        setting: {
            await_pay_num: 15, //待付款订单设置
            delivery_time_num: 15, //自动确认收货设置
            order_taking_type: 1, //接单方式
            await_use_order: [], //待使用订单设置
            refund_order_verify: 1,//退款订单审核设置
            refund_verify:1 //退款设置
        }
    }

    componentDidMount() {
        this.fetchStore().then(() => {
            this.fetchData()
        })
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })
        getTradeConfig({ store_id: this.state.store_id }).then(res => {
            if (res.code == 1) {
                let config = res.data.config
                if (config) {
                    let await_use_order=[]
                    config.await_use_order['accept_trade_refund'] && await_use_order.push(1);
                    config.await_use_order['auto_refund'] && await_use_order.push(2);
                    config.await_use_order=await_use_order

                    this.setState({
                        setting: config
                    })
                } else {
                    this.setState({
                        setting: {
                            await_pay_num: 15,
                            delivery_time_num: 15,
                            order_taking_type: 1,
                            await_use_order: [],
                            refund_order_verify: 1,
                            refund_verify:1
                        }
                    })
                }
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    saveData = () => {
        let { setting, store_id } = this.state, sendData = {}
        sendData.store_id = store_id

        sendData.config ={ ...setting}
        let arr={}
        arr['accept_trade_refund']=Number(setting.await_use_order.includes(1))
        arr['auto_refund']=Number(setting.await_use_order.includes(2))
        sendData.config.await_use_order=arr

        this.setState({
            isLoading: true
        })

        setTradeConfig(sendData).then(res => {
            if (res.code == 1) {
                message.success("保存成功")
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })


    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        return new Promise((resolve, reject) => {
            storeLists(sendData)
                .then(res => {
                    if (res.code == 1) {
                        let lists = res.data.store.data || [],
                            store_id = this.state.store_id || '';
                        if (!store_id) {
                            store_id = lists[0] && lists[0].id || ''
                        }
                        this.setState({
                            store_list: lists,
                            store_id
                        }, () => {
                            resolve()
                        })
                    } else {
                        reject()
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        })
    }

    handleChange = name => {
        return e => {
            let setting = this.state.setting
            if (name == 'order_taking_type' || name == 'refund_order_verify' || name=='refund_verify') {
                setting[name] = e.target.value
            } else {
                setting[name] = e
            }
            this.setState({
                setting
            })
        }
    }

    handleSelectStore = value => {
        this.setState({
            store_id: value
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const { store_id, setting, store_list, isLoading } = this.state

        return (
            <div className={`shadow-radius`} >
                <div className="mgb20">
                    <span className="ft-bold fz16 mgr10">选择门店:</span>
                    <Select value={store_id} style={{ width: 200 }} onChange={this.handleSelectStore}>
                        {
                            store_list.map(store => (
                                <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                            ))
                        }
                    </Select>
                    <span className='fz13 mgl10 color-999'>选择门店后进行单独设置，设置后保存</span>
                </div>
                <div className="msg-title">待付款订单设置</div>
                <div className='pdt15 pdb15'>
                    <div >
                        <span className="mgr10">提交订单</span>
                        <Select value={setting.await_pay_num} style={{ width: 100 }} onChange={this.handleChange("await_pay_num")}>
                            <Option value={1}>1</Option>
                            <Option value={5}>5</Option>
                            <Option value={10}>10</Option>
                            <Option value={15}>15</Option>
                            <Option value={30}>30</Option>
                            <Option value={60}>60</Option>
                            <Option value={120}>120</Option>
                        </Select>
                        <span className='mgl10'>分钟内未支付，自动取消订单</span>
                    </div>
                </div>
                <div className="msg-title mgt15 ">自动确认收货设置</div>
                <div className='pdt15 pdb15'>
                    <div>
                        <span className="mgr10">快递商品发货</span>
                        <InputNumber min={7} max={90} value={setting.delivery_time_num} precision={0} onChange={this.handleChange("delivery_time_num")} />
                        <span className='mgl10'>天后自动确认收货</span>
                    </div>
                    <div className='fz13 color-999 mgt10'>最少为7天，最长不超过90天。</div>
                </div>
                <div className="msg-title mgt15 ">接单设置</div>
                <div className='pdt15 pdb15'>
                    <div>
                        <Radio.Group value={setting.order_taking_type} onChange={this.handleChange("order_taking_type")} >
                            <Radio value={1}>自动接单</Radio>
                            <Radio value={2}>手动接单</Radio>
                        </Radio.Group>
                    </div>
                    <div className='fz13 color-999 mgt10'>设置后，所有类型的订单统一按照设定的方式接单（预订类型的订单除外，根据垂直行业需求订购后，单独设置）</div>
                </div>
                <div className="msg-title mgt15 ">待使用订单设置</div>
                <div className='pdt15 pdb15'>
                    <div>
                        <Checkbox.Group value={setting.await_use_order} onChange={this.handleChange("await_use_order")}>
                            <Checkbox value={1}>待使用订单不支持申请退款</Checkbox>
                            <div className='fz13 color-999 mgt5 mgb15 pdl25'>勾选后，用户端待使用的订单不展示“申请退款”的按钮</div>
                            {/* <Checkbox value={2}>自提订单所有门店都可使用</Checkbox>
                            <div className='fz13 color-999 mgt5 pdl25'>勾选后，买家可在任意自提门店完成核销和提货。未勾选，买家只能在下单时选择的自提门店进行提货并核销</div> */}
                        </Checkbox.Group>
                    </div>
                </div>
                <div className="msg-title mgt15 ">退款订单审核设置</div>
                <div className='pdt15 pdb15'>
                    <div>
                        <Radio.Group value={setting.refund_order_verify} onChange={this.handleChange("refund_order_verify")} >
                            <Radio value={1}>订单接单后，用户发起退款需要审核</Radio>
                            <Radio value={2}>无需审核，用户发起后自动退款</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="msg-title mgt15 ">退款设置</div>
                <div className='pdt15 pdb15'>
                    <div>
                        <Radio.Group value={setting.refund_verify} onChange={this.handleChange("refund_verify")} >
                            <Radio value={1}>订单接单后，允许用户申请退款</Radio>
                            <Radio value={2}>订单接单后，不允许用户申请退款</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className='mgt15 pdb30'>
                    <Button type='primary' onClick={this.saveData}>保存</Button>
                </div>

                {isLoading ? <Loading /> : null}
            </div>
        )
    }
}
