import $axios from '@/axios/axios.js'

export function studentLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student`,
        method:'post',
        data
    })
}

export function studentAdd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/save`,
        method:'post',
        data
    })
}

export function studentDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/delete`,
        method:'post',
        data
    })
}

export function studentDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/detail`,
        method:'post',
        data
    })
}

export function studentGrade(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/grade`,
        method:'post',
        data
    })
}

export function studentCourseTable(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/courseTable`,
        method:'post',
        data
    })
}

export function studentAddGrade(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/addGrade`,
        method:'post',
        data
    })
}


export function studentAddCourseNum(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/addCourseNum`,
        method:'post',
        data
    })
}


export function studentImport(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/import`,
        method:'post',
        headers:{'Content-Type':'multipart/form-data'},
        data
    })
}

export function studentCourseRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/courseRecord`,
        method:'post',
        data
    })
}

export function studentBuyCourse(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/buyCourse`,
        method:'post',
        data
    })
}


export function studentOpenCourse(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/openCourse`,
        method:'post',
        data
    })
}


export function studentChangePoints(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/changePoints`,
        method:'post',
        data
    })
}


export function studentPhysical(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/physical`,
        method:'post',
        data
    })
}


export function studentPhysicalSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/savePhysicalForm`,
        method:'post',
        data
    })
}

export function studentPhysicalDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/deleteForm`,
        method:'post',
        data
    })
}
