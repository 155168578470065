import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Modal, Switch, Radio, Button, Icon } from 'antd'
import { setConfig,getConfig } from "@/api/point-mall";
import message from '@/utils/message'
import Loading from '@/components/Loading'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import '@/assets/css/design/index.scss'
const FormItem = Form.Item;
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 16 }}


class PointMallConfig extends React.Component{
    state = {
        formData:'',
        title:'积分商城设置',
        visible:false,
        modalLoading:false,
        isLoading:false,
        callback:null,
        default_img:"https://img.cxkoo.com/chengxuan/1/2022-02-18/3ecb61d29cbfeb2ff5c8abf297ad0023.png"
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.fetchData()
            .then(() => {
                this.setState({
                    visible:true,
                    callback : callback || null
                })
            })

    }
    fetchData = () => {
        this.setState({
            isLoading:true
        })
        return new Promise((resolve, reject) => {
            getConfig({})
                .then(res => {
                    if(res.code == 1){
                        let config = res.data.config || '';
                        this.setState({
                            formData:config
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                    resolve();
                })
        })

    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            config = {},
            formData = this.state.formData || {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                config = values;
                config.bg_img = formData.bg_img || '';
                config.poster_info = formData.poster_info || [];
                config.order_by = config.order_by || 1;
                for(var x in config){
                    if(typeof config[x] === 'boolean'){
                        config[x] = config[x] ? 1 : 0
                    }
                }
                sendData.config = config;
                console.log('sendData',sendData)
                this.setState({
                    isLoading:true
                })
                setConfig(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.close();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    handleResetImg=(keyNmae)=>{
        return ()=>{
            const formData=this.state.formData || {}
            formData[keyNmae]=this.state.default_img
            this.setState({
                formData
            })
        }
    }
    chooseImg = (e,index,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            poster_info = formData.poster_info || [];
        this.Gallery.show(res => {
            if(res){
                switch (name) {
                    case 'bg_img':
                        formData[name] = res[0];
                        this.setState({
                            formData
                        })
                        break;
                    case 'img':
                        poster_info[index][keyName] = res[0];
                        this.setState({
                            formData
                        })
                        let datas = {}
                        datas[`poster_info[${index}].img`] = res[0]
                        this.props.form.setFieldsValue(datas)
                        break;
                }

            }
        })
    }
    delImg = (e,index,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            poster_info = formData.poster_info || [];
        switch (name) {
            case 'bg_img':
                formData[name] = '';
                
                this.setState({
                    formData
                })
                break;
            case 'img':
                poster_info[index][keyName] = '';
                this.setState({
                    formData
                })
                let datas = {}
                datas[`poster_info[${index}].img`] = ''
                this.props.form.setFieldsValue(datas)
                break;
        }

    }
    handleAddItem = () => {
        let formData = this.state.formData || {},
            poster_info = formData.poster_info || [],
            newData = { img: '', link:'' };
        if(poster_info && poster_info.length >= 3){
            message.error('最多添加三张图片');
            return ;
        }
        poster_info.push(newData);
        formData.poster_info = poster_info;
        this.setState({
            formData
        })
    }
    delItem = (e,item,index) => {
        e.stopPropagation()
        let formData = this.state.formData || {},
            poster_info = formData.poster_info || [];
        poster_info.splice(index,1);
        this.setState({
            formData
        })
    }
    changeLink = (e,item,index) => {
        let formData = this.state.formData || {},
            poster_info = formData.poster_info || [];
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    poster_info[index][i] = res[i];
                }
                this.setState({
                    formData
                })
            }
        })
    }
    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={600}
                    centered={true}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                >
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                    <Form className={`model-form`} { ...formItemLayout }>
                        <FormItem label={`积分排行榜`}>
                            {getFieldDecorator('show_rank', {
                                initialValue: data.show_rank == undefined ? false : !!data.show_rank,
                                valuePropName:'checked'
                            })(
                                <Switch></Switch>
                            )}
                        </FormItem>
                        {
                            getFieldValue('show_rank')
                                ?
                                <FormItem label={` `} colon={false}>
                                    {getFieldDecorator('order_by', {
                                        initialValue: data.order_by || 1,
                                    })(
                                        <Radio.Group>
                                            <Radio value={1} className={`block  lh40`}>
                                                按照兑换销量从高到低排列
                                            </Radio>
                                            <Radio value={2} className={`block  lh40`}>
                                                按照兑换商品序号从小到大排列
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                :
                                null
                        }
                        <FormItem label={`背景图`}>
                            <div className={'clearfix'}>
                                {
                                    data.bg_img
                                        ?
                                        <div>
                                            <PictureCard
                                                onRef={ e => { this.PictureCard = e } }
                                                imgs={ data.bg_img }
                                                onDel={ e => { this.delImg(e,0,'bg_img') } }
                                            ></PictureCard>
                                        </div>

                                        :
                                        <ImgAdd
                                            onRef={ e => { this.ImgAdd = e } }
                                            onClick={ e => { this.chooseImg(e,0,'bg_img') } }
                                        ></ImgAdd>
                                }
                                <Button type='link' className='mgt60' onClick={this.handleResetImg('bg_img')}>恢复默认</Button>
                            </div>
                            <span className={'fi-help-text'}>建议尺寸：750x430像素，单张不超过1M。</span>
                        </FormItem>
                        <FormItem label={`广告位`} style={{ marginBottom: '5px' }}>
                            {getFieldDecorator('ad_type', {
                                initialValue: data.ad_type || 1,
                            })(
                                <Radio.Group>
                                    <Radio value={1} >
                                        单图
                                    </Radio>
                                    <Radio value={2} >
                                        多图轮播
                                    </Radio>
                                </Radio.Group>
                            )}
                        </FormItem>
                        {
                            getFieldValue('ad_type') == 1
                                ?
                                <FormItem label={` `} colon={false}>
                                    {
                                        data.poster_info && data.poster_info.length
                                            ?
                                            <div className={`cx-ctrl-panel`}>
                                                <div className={`cx-ctrl-panel--box`} style={{ padding:'10px' }}>
                                                    {
                                                        data.poster_info && data.poster_info.map((item,index) => (
                                                            <div key={index}>
                                                                {
                                                                    index > 0
                                                                        ?
                                                                        null
                                                                        :
                                                                        <div className={`cx-img-list`}>
                                                                            <div className={`cx-img-item clearfix`}>
                                                                                <FormItem label={`选择图片`} {...ctrlItemLayout}>
                                                                                    {getFieldDecorator(`poster_info[${index}].img`, {
                                                                                        initialValue: item.img || '',
                                                                                        rules: [
                                                                                            {
                                                                                                required: true,
                                                                                                message: '请选择图片'
                                                                                            }
                                                                                        ]
                                                                                    })(
                                                                                        <div className={'clearfix'}>
                                                                                            {
                                                                                                item.img
                                                                                                    ?
                                                                                                    <PictureCard
                                                                                                        onRef={ e => { this.PictureCard = e } }
                                                                                                        imgs={ item.img }
                                                                                                        onDel={ e => { this.delImg(e,index,'img') } }
                                                                                                    ></PictureCard>
                                                                                                    :
                                                                                                    <ImgAdd
                                                                                                        onRef={ e => { this.ImgAdd = e } }
                                                                                                        onClick={ e => { this.chooseImg(e,index,'img') } }
                                                                                                    ></ImgAdd>
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                    <span className={'fi-help-text'}>建议尺寸：750x215像素，单张不超过1M。</span>
                                                                                </FormItem>
                                                                                <FormItem label={`链接到`} {...ctrlItemLayout}>
                                                                                    <Input
                                                                                        value={item.link}
                                                                                        placeholder={'仅支持系统内的小程序链接'}
                                                                                        style={{width:'200px'}}
                                                                                        suffix={ <Icon type="link" /> }
                                                                                        onClick={ (e) => { this.changeLink(e,item,index) } }
                                                                                    />
                                                                                </FormItem>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        data.poster_info && data.poster_info.length >= 1
                                            ?
                                            null
                                            :
                                            <Button type={`primary`} className={`w100`} onClick={this.handleAddItem} >添加图片</Button>
                                    }

                                </FormItem>
                                :
                                <FormItem label={` `} colon={false}>
                                    {
                                        data.poster_info && data.poster_info.length
                                            ?
                                            <div className={`cx-ctrl-panel`}>
                                                <div className={`cx-ctrl-panel--box`} style={{ padding:'10px' }}>
                                                    {
                                                        data.poster_info && data.poster_info.map((item,index) => (
                                                            <div className={`cx-img-list`} key={index}>
                                                                <div className={`cx-img-item clearfix`}>
                                                                    <FormItem label={`选择图片`} {...ctrlItemLayout}>
                                                                        {getFieldDecorator(`poster_info[${index}].img`, {
                                                                            initialValue: item.img || '',
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: '请选择图片'
                                                                                }
                                                                            ]
                                                                        })(
                                                                            <div className={'clearfix'}>
                                                                                {
                                                                                    item.img
                                                                                        ?
                                                                                        <PictureCard
                                                                                            onRef={ e => { this.PictureCard = e } }
                                                                                            imgs={ item.img }
                                                                                            onDel={ e => { this.delImg(e,index,'img') } }
                                                                                        ></PictureCard>
                                                                                        :
                                                                                        <ImgAdd
                                                                                            onRef={ e => { this.ImgAdd = e } }
                                                                                            onClick={ e => { this.chooseImg(e,index,'img') } }
                                                                                        ></ImgAdd>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        <span className={'fi-help-text'}>建议尺寸：750x215像素，单张不超过1M。</span>
                                                                    </FormItem>
                                                                    <FormItem label={`链接到`} {...ctrlItemLayout}>
                                                                        <Input
                                                                            value={item.link}
                                                                            placeholder={'仅支持系统内的小程序链接'}
                                                                            style={{width:'200px'}}
                                                                            suffix={ <Icon type="link" /> }
                                                                            onClick={ (e) => { this.changeLink(e,item,index) } }
                                                                        />
                                                                    </FormItem>
                                                                </div>
                                                                <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={(e) => { this.delItem(e,item,index) }}  />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        data.poster_info && data.poster_info.length >= 3
                                            ?
                                            null
                                            :
                                            <Button type={`primary`} className={`w100`} onClick={this.handleAddItem} >添加图片</Button>
                                    }
                                </FormItem>
                        }

                    </Form>
                </Modal>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(PointMallConfig))
