import React from 'react'
import { Form, Input, Button, Table, Pagination, Tag, Modal, Divider } from 'antd'
import { withRouter } from 'react-router-dom'
import { shortLinks, shortLinkSave, shortLinkDelete } from '@/api/short-link'
import CX from '@/utils/CX'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import AddShortLink from './components/AddShortLink'
const FormItem = Form.Item;
class ShortLink extends React.Component{
    state = {
        columns:[
            {
                title:'链接名称',
                dataIndex:'name',
                width:'10%',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'有效期',
                dataIndex:'end_time',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.end_time||'永久有效'}</p>
                    )
                }
            },
            {
                title:'微信小程序Url Link',
                dataIndex:'url_link',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.url_link}</p>
                    )
                }
            },
            {
                title:'小程序短链接',
                dataIndex:'short_link',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.short_link}</p>
                        </div>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleCopy(rowData.url_link)}}>复制Url Link</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleCopy(rowData.short_link)}}>复制小程序短链</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            name:''
        },
        storeLists:[],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow: null

    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        shortLinks(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        let sendData = {}
        this.setState({
            currentRow:null
        },() => {
            this.AddShortLink.show((res) => {
                sendData = res;
                this.setState({
                    tableLoading:true
                })
                shortLinkSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })
            })
        })

    }
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }
    handleDelete = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:`是否删除该短链接`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                shortLinkDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <AddShortLink onRef={ e => { this.AddShortLink = e } }></AddShortLink>
                <div className={`content-msg mgb10`}>
                    <p>
                        将微信小程序路径转成适用于微信外网页、短信、邮件可打开的链接，用于短信，微博，抖音等平台推广使用。
                    </p>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>添加短链</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(ShortLink))
