import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Alert, Checkbox, Row, Col } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class PrintScene extends React.Component{
    state = {
        title:'分配小票',
        visible:false,
        modalLoading:false,
        callback:null,
        sceneLists:[
            {
                scene_type:1,
                printer_num:1,
                name:'收银小票',
                desc:'下单结账、自助买单打印小票',
                disable:true
            },
            {
                scene_type:2,
                printer_num:1,
                name:'后厨小票',
                desc:'扫码点餐接单后打印小票',
                disable:true
            },
            {
                scene_type:3,
                printer_num:1,
                name:'开卡充值小票',
                desc:'开通办理会员卡、储值卡充值后打印小票',
                disable:true
            },
            {
                scene_type:4,
                printer_num:1,
                name:'预约小票',
                desc:'包厢预约、技师服务预约接单后打印小票',
                disable:true
            },
            {
                scene_type:5,
                printer_num:1,
                name:'核销小票',
                desc:'优惠券、自提单、存酒单、预约单等核销后打印小票',
                disable:true
            },
            {
                scene_type:6,
                printer_num:1,
                name:'存酒小票',
                desc:'顾客存酒后打印小票',
                disable:true
            },
            {
                scene_type:7,
                printer_num:1,
                name:'补打小票',
                desc:'订单详情点击补打后进行打印，该设置仅用于手动打印',
                disable:true
            }
        ],
        checkKeys:[]

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        },() => {
            let scene = this.props.scene || [],
                sceneLists = this.state.sceneLists,
                checkKeys = []
            if(scene.length){
                scene.forEach((item,index) => {
                    checkKeys.push(item.scene_type)
                    sceneLists.forEach((child,childKey) => {
                        if(child.scene_type == item.scene_type){
                            child.printer_num = item.printer_num
                        }
                    })
                })
                this.setState({
                    checkKeys,sceneLists
                },() => {
                    this.updateData()
                })
            }else {
                this.updateData()
            }
        })

    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sceneLists = this.state.sceneLists,
            res = [],
            callback = this.state.callback;
        sceneLists.forEach(item => {
            if(!item.disable){
                res.push({ scene_type:item.scene_type, printer_num:item.printer_num })
            }
        })
        if(typeof callback == 'function'){
            callback(res)
        }
        this.close()

    }
    handleCancel = () => {
        this.close()
    }
    handleChange = (val) => {
        this.setState({
            checkKeys:val
        },() => {
            this.updateData()
        })
    }
    handleInputChange = (e,item,index) => {
        let val = Number(e.target.value),
            sceneLists = this.state.sceneLists;
        sceneLists[index]['printer_num'] = val;
        this.setState({
            sceneLists
        })
    }
    updateData = () => {
        let checkKeys = this.state.checkKeys,
            sceneLists = this.state.sceneLists;
        sceneLists.forEach(item => {
            item.disable = true
            if( checkKeys.includes(item.scene_type)){
                item.disable = false
            }
        })
        this.setState({
            sceneLists
        })


    }
    render() {
        const scene = this.props.scene || []
        const formItemLayout = {
            labelCol: {
                span: 0
            },
            wrapperCol: {
                span: 22
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Alert message={`分配后以当前设置的打印小票数量为准`} className={`mgb20`} type="info" showIcon />
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={``} colon={false}>
                        <Checkbox.Group style={{ width: '100%' }} onChange={ this.handleChange } value={this.state.checkKeys}>
                            {
                                this.state.sceneLists.map((item,index) => (
                                    <Row className={`mgb15`} key={index}>
                                        <Col span={6}>
                                            <Checkbox value={item.scene_type}>{ item.name }</Checkbox>
                                        </Col>
                                        <Col span={13} offset={3}>
                                            <span>{item.desc}</span>
                                            <div>
                                                打印联数
                                                <Input
                                                    placeholder={``}
                                                    type={`number`}
                                                    value={item.printer_num}
                                                    min={1}
                                                    style={{ width:'100px' }}
                                                    className={`mgt10 mgl5`}
                                                    disabled={item.disable}
                                                    onChange={(e) => { this.handleInputChange(e,item,index) }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Checkbox.Group>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(PrintScene))
