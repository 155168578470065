import React from 'react'
import { Form, Input, Button, Select, DatePicker, Table,Tag, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Loading from '@/components/Loading'
import SkuItemPicker from '@/components/SkuItemPicker/SkuItemPicker'
import '@/assets/css/item/item.scss'
import { stockIn, stockOut } from '@/api/stock'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
import moment from 'moment';
import StockSet from './components/setStock'
import { STOCK_IN_TYPES, STOCK_OUT_TYPES } from '@/constants/stock'

const FormItem = Form.Item
const { TextArea } = Input

class AddStock extends React.Component{
    state = {
        formData:'',
        isLoading:false,
        modalVisible:false,
        storeLists:[],
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.item_id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'sku编码',
                dataIndex:'goods_sku_code',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.goods_sku_code || '-- '}</p>
                    )
                }
            },
            {
                title:'单位',
                dataIndex:'unit',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.unit_name || '件'}</p>
                    )
                }
            },
            {
                title:'规格',
                dataIndex:'sku',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sku_names
                                    ?
                                    <Tag>
                                        {
                                            rowData.sku_names.map((item,index) => (
                                                <span className={`lh25`} key={index}>{item}
                                                    {
                                                        index >= rowData.sku_names.length - 1
                                                            ?
                                                            null
                                                            :
                                                            '/'
                                                    }
                                                </span>
                                            ))
                                        }
                                    </Tag>
                                    :
                                    '--'
                            }
                        </div>
                    )
                }
            },
            {
                title:'可用库存',
                dataIndex:'stock1',
                render:(text, rowData, index) => {
                    return(
                        <div>{ rowData.stock || 0 }</div>
                    )
                }
            },
            {
                title:`${this.props.match.params.type == 1 ? '入库' : '出库'}数量`,
                dataIndex:'number',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type={`number`}
                                min={0}
                                placeholder={'请输入'}
                                style={{ width:'150px' }}
                                value={rowData.number || ``}
                                onChange={(e) => { this.getInputValue(e,rowData,index) }}
                                data-name={`number`}
                            />

                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opt',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <Button type={"default"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleDelete(rowData,index)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        skuItemLists:[]

    }

    componentDidMount() {
        this.fetchStore()

    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    addItem = () => {
        let store_id = this.props.form.getFieldValue('store_id');
        if(!store_id){
            message.warning('请先选择门店/仓库');
            return ;
        }
        this.SkuItemPicker.show(res => {
            if(res){
                let skuItemLists = res;
                this.setState({
                    skuItemLists
                })
            }
        })
    }
    getInputValue = (e,rowData,index) => {
        let skuItemLists = this.state.skuItemLists,
            value = e.target.value,
            name = e.currentTarget.dataset.name || ``;
        skuItemLists[index][name] = value;
        this.setState({
            skuItemLists
        })

    }
    handleDelete = (rowData,index) => {
        let skuItemLists = this.state.skuItemLists;
        Modal.confirm({
            title:'确定要删除这条数据吗?',
            onOk:() => {
                skuItemLists.splice(index,1)
                this.setState({
                    skuItemLists
                })
            }
        })
    }
    showPatch = () => {
        this.setState({
            modalVisible:true
        })
    }
    handlePatch = () => {
        this.formRef.props.form.validateFields((err,values) => {
            if(!err){
                let skuItemLists = this.state.skuItemLists || [];
                skuItemLists.forEach(item => {
                    item.number = values.number
                })
                this.setState({
                    skuItemLists,
                    modalVisible:false
                })
            }
        })
    }
    handleCancel = () => {
        this.setState({
            modalVisible:false
        })
    }

    renderTableTitle = () => {
        return(
            <div>
                <span className={`batch-setting-title`}>批量设置</span>
                <a className={`btn-batch-setting`} onClick={this.showPatch}>{`${this.props.match.params.type == 1 ? '入库' : '出库'}数量`}</a>
            </div>
        )
    }
    saveData = ()  => {
        let sendData = {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData.store_id = values.store_id;
                sendData.type = values.type;
                sendData.remark = values.remark || ``;
                sendData.operate_time = moment(values.operate_time).format('YYYY-MM-DD HH:mm:ss')
                sendData.goods_ids = this.state.skuItemLists.map((item,index) => {
                    return {
                        goods_id:item.item_id,
                        sku_id:item.sku_id || ``,
                        name:item.name,
                        stock:item.number
                    }
                })
                if(!sendData.goods_ids && !sendData.goods_ids.length){
                    message.error('请选择商品');
                    return
                }
                this.setState({
                    isLoading:true
                })
                if(this.props.match.params.type == 1){
                    stockIn(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/stock/manage`
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })
                }else {
                    stockOut(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/stock/manage`
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })
                }



            }
        })
    }

    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return(
            <div className={`shadow-radius`}>
                <SkuItemPicker onRef={ (e) => { this.SkuItemPicker = e } }  multiple={true} shouldChooseDisable={true} store_id={getFieldValue('store_id')}  selectIds={this.state.skuItemLists.map(item => {return item.id })}></SkuItemPicker>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    <div className={`msg-title mgb15`}>{`新增${this.props.match.params.type == 1 ? '入库' : '出库'}`}</div>

                    <FormItem label={'仓库/门店'}>
                        {
                            getFieldDecorator('store_id',{
                                rules:[
                                    {
                                        required:true,
                                        message: '请选择仓库/门店'
                                    }
                                ]
                            })(
                                <Select style={{width:'300px'}} placeholder={'请选择'} >
                                    {
                                        this.state.storeLists.map(store => (
                                            <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem label={`${this.props.match.params.type == 1 ? '入库' : '出库'}类型`}>
                        {
                            getFieldDecorator('type',{
                                rules:[
                                    {
                                        required:true,
                                        message: `请选择${this.props.match.params.type == 1 ? '入库' : '出库'}类型`
                                    }
                                ]
                            })(
                                <Select style={{width:'300px'}} placeholder={'请选择'} >
                                    {
                                        this.props.match.params.type == 1
                                            ?
                                            STOCK_IN_TYPES.map(item => (
                                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>

                                            ))
                                            :
                                            STOCK_OUT_TYPES.map(item => (
                                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>

                                            ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>

                    <FormItem label={`${this.props.match.params.type == 1 ? '入库' : '出库'}时间`}>
                        {
                            getFieldDecorator('operate_time',{
                                initialValue: data.operate_time || null,
                                rules:[
                                    {
                                        required:true,
                                        message: `请选择${this.props.match.params.type == 1 ? '入库' : '出库'}时间`
                                    }
                                ]
                            })(
                                <DatePicker format={`YYYY-MM-DD HH:mm:ss`} showTime />
                            )
                        }
                    </FormItem>
                    <FormItem label={`备注`}>
                        {
                            getFieldDecorator('remark',{
                                initialValue: data.remark || '',
                            })(
                                <TextArea rows={3} placeholder={'请输入备注'} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`选择商品`} required={true}>
                        <Button type={`primary`}  onClick={this.addItem}>添加商品</Button>
                    </FormItem>
                    <FormItem label={`商品库存`}>
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.skuItemLists}
                            bordered
                            rowKey={record => record.id}
                            pagination={false}
                            title={this.renderTableTitle}
                        />
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>
                </Form>
                {
                    this.state.isLoading ? <Loading /> : null
                }
                <Modal
                    title={'批量设置库存'}
                    visible={this.state.modalVisible}
                    onOk={this.handlePatch}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                >
                    <StockSet
                        visible={this.state.modalVisible}
                        wrappedComponentRef={form => {this.formRef = form} }
                    ></StockSet>
                </Modal>

            </div>
        )
    }


}

export default Form.create({})(withRouter(AddStock))
