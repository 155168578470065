import React from 'react'
import { Form, Input, Table, Select, InputNumber } from 'antd'
const FormItem = Form.Item
const { Option } = Select

class CoursePrice extends React.Component {
    state = {
        columns: [
            {
                title: () => {
                    if (this.props.change_type == 1) {
                        return <span >课时</span>
                    } else if (this.props.change_type == 2) {
                        return <span >时段</span>
                    } else if (this.props.change_type == 3) {
                        return <span >班级</span>
                    }

                },
                dataIndex: 'course_num',
                width: '200px',
                render: (text, rowData, index) => {
                    let node1 = (

                        <div>
                            <Input
                                placeholder={'课时'}
                                style={{ width: '180px', marginBottom: "25px" }}
                                value={rowData.course_num}
                                onChange={(e) => { this.getInputValue(e) }}
                                data-name={'course_num'}
                            />

                        </div>
                    )

                    let node2 = (
                        <div>
                            <Input.Group compact style={{ margin: "0" }}>
                                <Input
                                    style={{ width: '50%' }}
                                    value={rowData.course_time}
                                    data-name={'course_time'}
                                    placeholder={"有效期"}
                                    onChange={(e) => { this.getInputValue(e) }}
                                />
                                <Select value={rowData.course_time_unit} data-name={'course_time_unit'} onChange={(e) => { this.getSelectValue(e, "course_time_unit") }}>
                                    <Option value={1}>天</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>季</Option>
                                    <Option value={4}>年</Option>
                                </Select>
                            </Input.Group>
                            <span className={`fi-help-text`}>有效期=生效当天+N天（N=填写数量）</span>
                        </div>

                    )

                    let node3 = (
                        <div>
                            <Select value={rowData.grade_id} data-name={'grade_id'} onChange={(e) => { this.getSelectValue(e, "grade_id") }} placeholder={"请选择班级"} style={{ width: '180px', margin: "0" }}>

                                {
                                    this.props.gradeLists.map(grade => (
                                        <Select.Option value={grade.id} key={grade.id}>{grade.name}</Select.Option>
                                    ))
                                }
                            </Select>
                            <span className={`fi-help-text`}>学员报名后自动被分配到本班，需先创建班级</span>
                        </div>

                    )

                    let node = node1
                    if (this.props.change_type == 1) {
                        node = node1
                    } else if (this.props.change_type == 2) {
                        node = node2
                    } else if (this.props.change_type == 3) {
                        node = node3
                    }
                    return (
                        <div>
                            {
                                node
                            }
                        </div>
                    )


                }
            },
            {
                title: '售价',
                dataIndex: 'price',
                width: '180px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                             <InputNumber
                                placeholder={'售价'}
                                style={{ width: '180px', marginBottom: "25px" }}
                                value={rowData.price}
                                onChange={(e) => { this.getInputNumberValue(e,'price') }}
                                step={0.01}
                                formatter={this.limitDecimals}
                                parser={this.limitDecimals}
                            />
                        </div>
                    )
                }
            },
            {
                title: '门市价',
                dataIndex: 'market_price',
                width: '180px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <InputNumber
                                placeholder={'门市价'}
                                style={{ width: '180px', marginBottom: "25px" }}
                                value={rowData.market_price}
                                onChange={(e) => { this.getInputNumberValue(e,'market_price') }}
                                step={0.01}
                                formatter={this.limitDecimals}
                                parser={this.limitDecimals}
                            />
                        </div>
                    )
                }
            },
            {
                title: '名额(人)',
                dataIndex: 'course_quota',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <InputNumber
                                style={{ width: '180px', marginBottom: "25px" }}
                                value={rowData.course_quota}
                                onChange={(e) => { this.getInputNumberValue(e,'course_quota') }}
                                precision={0}
                            />
                        </div>
                    )
                }
            }
        ],
        tableData: [
            {
                course_num: "",
                price: 0,
                market_price: 0,
                course_quota: 0,
                course_time: "",
                course_time_unit: 1,
                grade_id: ""
            }
        ]
    }
    componentWillReceiveProps(nextProps, nextContext) {

        if (this.props.change_type !== nextProps.change_type) {
            let tableData = [{
                course_num: "",
                price: 0,
                market_price: 0,
                course_quota: 0,
                course_time: "",
                course_time_unit: 1,
                grade_id: ""
            }]
            this.setState({
                tableData
            })
        }
        if (nextProps.id) {
            let tableData = {}
            console.log(nextProps);
            tableData.course_num = nextProps.data.course_num
            tableData.price = nextProps.data.price
            tableData.market_price = nextProps.data.market_price
            tableData.course_time = nextProps.data.course_time
            tableData.course_quota = nextProps.data.course_quota
            tableData.course_time_unit = nextProps.data.course_time_unit
            tableData.grade_id = nextProps.data.grade_id

            this.setState({
                tableData: [tableData]
            })
        }

    }

    componentDidMount() {
        this.props.refPrice(this)
    }

    getPriceData = () => {
        return this.state.tableData[0]
    }

    getInputNumberValue = (value,name) => {
        let tableData = this.state.tableData;
        tableData[0][name] = value;
        this.setState({
            tableData
        })
    }

    getInputValue = e => {

        let value = e.target.value,
            name = e.currentTarget.dataset.name || ``,
            tableData = this.state.tableData;
        tableData[0][name] = value;
        this.setState({
            tableData
        })
    }
    getSelectValue = (value, name) => {
        let tableData = this.state.tableData;
        tableData[0][name] = value;
        this.setState({
            tableData
        })
    }

    limitDecimals = value => {
        const reg = /^(\-)*(\d+)\.(\d\d).*$/;
       
        if(typeof value === 'string') {
            return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : ''
        } else if (typeof value === 'number') {
            return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : ''
        } else {
            return 0
        }
    };

    render() {
        return (
            <FormItem label={`课程费用`} required={true}>
                <div style={{ border: "1px solid #e8e8e8", borderBottom: "0" }}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        rowKey={(record, index) => index}
                        pagination={false}

                    />
                </div>
            </FormItem>
        )
    }

}

export default Form.create({})(CoursePrice)
