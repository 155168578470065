import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input, Form,Radio } from 'antd'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import message from '@/utils/message'
const FormItem = Form.Item

class AddHot extends React.Component{
    state = {
        title:'添加热词',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(typeof callback == 'function'){
                    callback(values)
                    this.close()
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    changeLink = () => {
      
        this.PopSelectLink.show(res => {
            if(res){
               this.props.form.setFieldsValue({
                link:res.link || ""
               })
                
            }
        })
    }

    render() {
        const data = this.props.data || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <PopSelectLink onRef={e=>this.PopSelectLink=e} ></PopSelectLink>
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`关键词`}>
                        {
                            getFieldDecorator('name',{
                                initialValue:"",
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入关键词'
                                    }
                                ]
                            })(
                                <Input placeholder={`请输入关键词（最多10个字）`} maxLength={10} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`跳转链接`}>
                        {
                            getFieldDecorator('link',{
                                initialValue:"",
                            })(
                                <Input placeholder={`请选择`} onClick={this.changeLink} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`热词图标`}>
                        {
                            getFieldDecorator('is_hot',{
                                initialValue:0
                            })(
                                <Radio.Group  >
                                <Radio value={0}>无 </Radio>
                                <Radio value={1}>默认图标 </Radio>
                            </Radio.Group>
                            )
                        }
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddHot))
