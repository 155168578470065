import React, { Component } from 'react'
import { Form, Input, InputNumber, Select, DatePicker, Row, Col, Modal ,TreeSelect} from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { gradeSaveApi,gradeCopy} from '@/api/educational'
import message from '@/utils/message'
const FormItem = Form.Item;

class RestartGrade extends Component {

    state = {
        courseLists: [],
        storeLists: [],
        teacherList:[],
        modalLoading: false,
        showVisible:false
    }
    componentDidMount() {
       
    }

    saveData = () => {
        let sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;
                
                sendData.start_date = values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : ''
                sendData.end_date = values.start_date ? moment(values.end_date).format('YYYY-MM-DD') : ''

                if(!moment(sendData.start_date).isBefore(sendData.end_date)){
                    return message.error("开班时间必须小于结班时间")
                }

            
                sendData.id=this.props.grade_line.id

                this.setState({
                    modalLoading: true
                })
                gradeCopy(sendData).then(res => {
                   
                    if (res.code === 1) {
                        message.success(res.msg)
                        this.setState({
                            modalLoading: false
                        })
                        this.props.handleS()
                        this.handleCancel()
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.props.closeRestartGrade()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        let data={}
       

        return (

            <Modal
                title={`重开班`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={20}>
                           

                            <FormItem label={'开班时间'} >
                                {
                                    getFieldDecorator('start_date', {
                                        initialValue:data.start_date? moment(data.start_date, "YYYY-MM-DD'"): null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择开班时间'
                                            }
                                        ]
                                    })(
                                        <DatePicker placeholder='请选择开班时间' style={{width:'100%'}}  disabled={this.props.edit_status} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'结班时间'} >
                                {
                                    getFieldDecorator('end_date', {
                                        initialValue: data.start_date? moment(data.end_date, "YYYY-MM-DD'"): null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择结班时间'
                                            }
                                        ]
                                    })(
                                        <DatePicker placeholder='请选择结班时间' style={{width:'100%'}} />
                                    )
                                }
                            </FormItem>
                        </Col>

                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(RestartGrade))