import React from 'react'
import { Table,Button } from 'antd'

class GroupOrder extends React.Component{
    state = {
        columns:[
            {
                title:'商品编码',
                dataIndex:'code',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            <p className={`lh25`}>{ rowData.goods_code || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'商品名称',
                dataIndex:'name',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.name }</p>
                        </div>
                    )
                }
            },
            {
                title:'规格',
                dataIndex:'sku',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.sku_info ? rowData.sku_info.name : '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.price || '0.00' }</p>
                        </div>
                    )
                }
            },
            {
                title:'数量',
                dataIndex:'num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.num }</p>
                        </div>
                    )
                }
            },
            {
                title:'单位',
                dataIndex:'unit',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ rowData.unit_name || '件' }</p>
                        </div>
                    )
                }
            },
            {
                title:'优惠',
                dataIndex:'discount_price',
                render:(text, rowData, index) => {
                    let group_price=this.props.trade && this.props.trade.group_trade && this.props.trade.group_trade.group_price || 0
                    let price=Number(rowData.price - group_price) || 0
                    price=price.toFixed(2)

                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ price }</p>
                            <p className='color-999'>拼团优惠</p>
                        </div>
                    )
                }
            },
            {
                title:'合计',
                dataIndex:'total',
                render:(text, rowData, index) => {
                    let price=this.props.trade && this.props.trade.group_trade && this.props.trade.group_trade.group_price || '0.00'
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ price }</p>
                            <Button type="primary" size="small" ghost>拼团价</Button>
                        </div>
                    )
                }
            }
        ],
        columns2: [
            {
                title: '课程信息',
                dataIndex: 'name',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={``}>
                            <p className={`lh25`}>{rowData.name || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title: '课时数',
                dataIndex: 'course_num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.course_num ? rowData.course_num + "课时" : "-"}</p>
                        </div>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex: 'sku',
                render: (text, rowData, index) => {
                    let item=rowData,term='-'
                    if (item.charge_type == 1) {
                        term= '-'
                    } else if (item.charge_type == 2) {
                        term= item.course_time + ["", "天", "月", "季", "年"][item.course_time_unit]
                    } else {
                        if (item.grade_info) {
                            term= item.grade_info.name + item.grade_info.start_date + "至" + item.grade_info.end_date
                        }
                        term= "-"
                    }

                    return (
                        <div>
                            <p className={`lh25`}>{term}</p>
                        </div>
                    )
                }
            },
            {
                title: '售价',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>￥{rowData.price}</p>
                        </div>
                    )
                }
            },
            {
                title: '数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.num}</p>
                        </div>
                    )
                }
            },

            {
                title: '优惠',
                dataIndex: 'discount_price',
                render: (text, rowData, index) => {

                    let group_price=this.props.trade && this.props.trade.group_trade && this.props.trade.group_trade.group_price || 0
                    let price=Number(rowData.price - group_price) || 0
                    price=price.toFixed(2)

                    return (
                        <div className={''}>
                            <p className={`lh25`}>{price}</p>
                            <p className='color-999'>拼团优惠</p>
                        </div>
                    )
                }
            },
            {
                title: '合计',
                dataIndex: 'total',
                render: (text, rowData, index) => {
                    let price=this.props.trade && this.props.trade.group_trade && this.props.trade.group_trade.group_price || '0.00'
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{price}</p>
                            <Button type="primary" size="small" ghost>拼团价</Button>
                        </div>
                    )
                }
            }
        ],
        tableLoading:false,
        course_text:'-'
    }
    componentDidMount() {
        this.props.onRef(this)

    }

   

    render() {
        let tableData = this.props.tableData || []
        let group_trade= this.props.trade && this.props.trade.group_trade ||{}
        let columns=group_trade.type==2?this.state.columns2 : this.state.columns

        return(
            <Table
                size={`middle`}
                columns={columns }
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={record => record.id}
                pagination={false}
            ></Table>
        )
    }
}
export default GroupOrder
