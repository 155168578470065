import React from 'react'
import { Form, Input, Row, Col } from 'antd';
const FormItem = Form.Item;

class EditForm extends React.Component{
    componentWillReceiveProps(nextProps){
        !nextProps.visible && this.props.form.resetFields();
    }
    render() {
        let { data } = this.props;
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol:{
                span:7
            },
            wrapperCol:{
                span:17
            }
        }
        return (
            <Form {...formItemLayout} className={'model-form'}>
                <Row gutter={24}>
                    <Col span={20}>
                        <FormItem label="库存数量">
                            {getFieldDecorator('number', {
                                initialValue: data && data.number,
                                rules: [
                                    {
                                        required: true,
                                        message: '请设置库存数量'
                                    }
                                ]
                            })(<Input type={`number`} min={0} />)}
                        </FormItem>
                    </Col>
                    <Col span={4}>
                    </Col>
                </Row>
            </Form>
        )
    }

}

export default Form.create({})(EditForm)
