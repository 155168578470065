import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, InputNumber, Radio, Pagination, Tooltip, Tag, TreeSelect, Drawer, Icon } from 'antd';
import { storeLists } from '@/api/store'
import AreaPicker from '@/components/AreaPicker/AreaPicker'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class BaseForm extends Component {
    state = {
        storeLists: [],
        selectItems: [],
        formData: {
            bg_color: '#29CC8A',
            font_color: '#29CC8A',
            bg_img: "https://img.cxkoo.com/chengxuan/1/2022-07-05/02291df483af8c9aad0d9bdf6ca657db.png"
        },
        default_img: "https://img.cxkoo.com/chengxuan/1/2022-07-05/02291df483af8c9aad0d9bdf6ca657db.png",
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchStore()
    }

    componentWillReceiveProps(nextProps){
       
        if((nextProps.activityData != this.props.activityData) && this.props.is_edit){
           
            let data=nextProps.activityData,selectItems=this.state.selectItems
            const {formData}=this.state
        
            formData.bg_color=data.bg_color
            formData.font_color=data.font_color
            formData.bg_img=data.bg_img
            selectItems=data.join_area || []
          
            this.setState({
                formData,
                selectItems
            })
        }
    }


    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    addFreeArea = () => {
        let selectItems = this.state.selectItems

        this.AreaPicker.show({
            selectItems: selectItems,
            is_default_select: true,
            callback: (res) => {

                if (res.length) {
                    let selectItems = res
                    this.setState({
                        selectItems
                    })
                }
            }
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
            this.props.updateData('bg_img',imgs[0])
        })
    }
    handleResetImg = (keyNmae) => {
        return () => {
            const formData = this.state.formData || {}
            formData[keyNmae] = this.state.default_img
            this.setState({
                formData
            })
            this.props.updateData('bg_img',this.state.default_img)
        }
    }
    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
        this.props.updateData('bg_img',this.state.default_img)
    }

    handleColorChange = (color, name) => {
        const formData = this.state.formData || {}
        formData[name] = color.hex
        this.setState({
            formData
        })

        this.props.updateData(name, color.hex)
    }

    getFormData = () => {
      return new Promise((reslove, reject) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const { formData, selectItems } = this.state
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                    values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''

                    if (values.suit_store_type == 2) {
                        values.suit_store_ids = values.suit_store_ids.join()
                    }

                    if (values.join_area_type == 2) {
                        values.join_area = selectItems
                    }

                    return reslove( { ...values, ...formData })
                } else {
                    return reslove( false)
                }
            })
        })
    }


    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const data = this.props.activityData || {}
        const formData= this.state.formData || {}


        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>基本信息</div>
                <FormItem label={'活动名称'}>
                    {getFieldDecorator('name', {
                        initialValue: data.name || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入活动名称'
                            }
                        ]
                    })(<Input
                        style={{ width: 400 }}
                        placeholder={'请输入活动名称'}
                        maxLength={20}
                        suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/20</span>}
                    />)}
                </FormItem>

                <FormItem label={'活动时间'}>
                    {getFieldDecorator('time', {
                        initialValue: data.time || null,
                        rules: [
                            {
                                required: true,
                                message: '请选择活动时间'
                            }
                        ]
                    })(
                        <RangePicker
                            style={{ width: 400 }}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder={['开始时间', '结束时间']}
                        />
                    )}
                </FormItem>

                <FormItem label={'需集赞总数'}>
                    {getFieldDecorator('jizan_num', {
                        initialValue: data.jizan_num || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入需要点赞的总人数'
                            }
                        ]
                    })(
                        <InputNumber
                            style={{ width: 400 }}
                            min={data.jizan_num || 0}
                            precision={0}
                            placeholder={'请输入需要点赞的总人数，满足条件后即可领奖'}
                        />
                    )}
                    <span className={'fi-help-text'}> 满足条件后，用户领奖成功不影响继续集赞，数值会累加</span>
                </FormItem>

                <div className={`msg-title mgb15`}>参与条件</div>

                <FormItem label={"活动发起人"} >
                    {getFieldDecorator('join_user', {
                        initialValue: data.join_user || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择活动发起人'
                            }
                        ]
                    })(
                        <Radio.Group   >
                            <Radio value={1}>所有客户 </Radio>
                            <Radio value={2}>仅限新客户
                                <Tooltip placement={`right`} title={`新客户指店铺内没有订单的客户，或仅拥有以下类型订单的客户：全额退款订单、已关闭的待付款订单`}>
                                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                </Tooltip>
                            </Radio>

                        </Radio.Group>
                    )}
                </FormItem>

                <FormItem label={"点赞人"} >
                    {getFieldDecorator('zan_user', {
                        initialValue: data.zan_user || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择点赞人'
                            }
                        ]
                    })(
                        <Radio.Group   >
                            <Radio value={1}>所有客户 </Radio>
                            <Radio value={2}>仅限新客户
                                <Tooltip placement={`right`} title={`新客户指店铺内没有订单的客户，或仅拥有以下类型订单的客户：全额退款订单、已关闭的待付款订单`}>
                                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                </Tooltip>
                            </Radio>

                        </Radio.Group>
                    )}
                </FormItem>

                <FormItem label={"重复点赞限制"} >
                    {getFieldDecorator('zan_limit', {
                        initialValue: data.zan_limit || 1,
                    })(
                        <Radio.Group   >
                            <Radio value={2}>不限制 </Radio>
                            <Radio value={1}>限制 </Radio>
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("zan_limit") == 1 ?
                        <FormItem label={' '} className={'no-colon'}>
                            每位点赞人仅可为除自己外的客户点赞
                            {getFieldDecorator('limit_zan_num', {
                                initialValue: data.limit_zan_num || 1,
                            })(
                                <InputNumber
                                    min={0}
                                    precision={0}
                                    placeholder={''}
                                    className='mgl20 mgr20'
                                />
                            )}
                            次
                        </FormItem>
                        : null
                }

                <FormItem label={"是否授权"} >
                    {getFieldDecorator('is_authorize', {
                        initialValue: data.is_authorize || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择是否授权'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}>授权 </Radio>
                            <Radio value={2}>不授权 </Radio>
                        </Radio.Group>
                    )}
                    <span className={'fi-help-text'}> 授权时，则发起人及点赞人都需要授权手机号才可参与活动。</span>
                </FormItem>

                <FormItem label={"适用门店"} >
                    {getFieldDecorator('suit_store_type', {
                        initialValue: data.suit_store_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择适用门店'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}> 所有门店</Radio>
                            <Radio value={2}>指定门店可用 </Radio>
                            {
                                getFieldValue("suit_store_type") == 2 ? <span className='small-help-text'>已选择 {getFieldValue("suit_store_ids") && getFieldValue("suit_store_ids").length || 0} 家</span> : null
                            }
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("suit_store_type") == 2 ?
                        <FormItem label={' '} className={'no-colon'}>
                            {getFieldDecorator('suit_store_ids', {
                                initialValue: data.suit_store_ids || [],
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择适用门店'
                                    }
                                ]
                            })(
                                <Select
                                    style={{ width: 400 }}
                                    placeholder="请选择"
                                    mode="multiple"
                                    showArrow
                                >
                                    {
                                        this.state.storeLists.map(store => (
                                            <Select.Option value={`${store.id}`} key={store.id}>{store.name}</Select.Option>

                                        ))
                                    }
                                </Select>
                            )}

                        </FormItem>
                        : null
                }

                <FormItem label={"可参与地区"} >
                    {getFieldDecorator('join_area_type', {
                        initialValue: data.join_area_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择可参与地区'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}>所有地区 </Radio>
                            <Radio value={2}>部分地区</Radio>
                            {getFieldValue("join_area_type") == 2 ? <span className='text_link' onClick={this.addFreeArea}>{this.state.selectItems.length > 0 ? '查看设置' : '请选择地区'}</span> : null}
                        </Radio.Group>
                    )}
                </FormItem>

                <div className={`msg-title mgb15`}>展示设置</div>

                <FormItem label={`背景图`}>
                    <div className={'clearfix'}>
                        {
                            formData.bg_img
                                ?
                                <PictureCard
                                    onRef={e => { this.PictureCard = e }}
                                    imgs={formData.bg_img}
                                    onDel={e => { this.delImg(e, 'bg_img') }}
                                ></PictureCard>
                                :
                                <ImgAdd
                                    onRef={e => { this.ImgAdd = e }}
                                    onClick={e => { this.chooseImg(e, 'bg_img') }}
                                ></ImgAdd>
                        }
                        <Button type='link' className='mgt60' onClick={this.handleResetImg('bg_img')}>恢复默认</Button>
                    </div>
                    <span className={'fi-help-text'}>建议尺寸：750x1334像素，单张不超过2M</span>
                </FormItem>

                <FormItem label={`背景色`} >
                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={formData.bg_color} callback={(color) => { this.handleColorChange(color, 'bg_color') }} ></ColorPicker>
                    <Button type="link" onClick={() => this.handleColorChange({ hex: '#29CC8A' }, 'bg_color')}>重置</Button>
                </FormItem>

                <FormItem label={`字体颜色`} >
                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={formData.font_color} callback={(color) => { this.handleColorChange(color, 'font_color') }} ></ColorPicker>
                    <Button type="link" onClick={() => this.handleColorChange({ hex: '#29CC8A' }, 'font_color')}>重置</Button>
                </FormItem>

                <FormItem label={'活动说明'}>
                    {getFieldDecorator('desc', {
                        initialValue: data.desc || undefined,

                    })(<Input.TextArea
                        style={{ width: 400 }}
                        placeholder={''}
                        rows={5}
                        maxLength={500}
                        suffix={<span>{getFieldValue("desc") ? getFieldValue("desc").length : 0}/500</span>}
                    />)}
                </FormItem>

                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button type='primary' onClick={() => this.props.updateTab('2')}>下一步</Button>
                </FormItem>

                <div className={'no-colon mgt60 pdb50'}> </div>

                <AreaPicker onRef={e => {
                    this.AreaPicker = e
                }}></AreaPicker>

                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
            </Form>
        )
    }
}

export default Form.create({})(BaseForm)
