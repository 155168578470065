/**
 * 搜索组件
 * @type 9
 * @return Object
 */

import React from 'react'
import {Form, Icon, Modal, Tooltip} from 'antd'
import {ICON_FONT_URL} from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ9 extends React.Component{
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }
    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`商品搜索`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>
                        <div className={`item-search-cont`}>
                            <div className={`item-search style${moduleData.content.style}`} style={{ borderColor:`${ moduleData.content.style == 1 ? moduleData.content.borderColor : '' }`,backgroundColor:`${ moduleData.content.style == 2 ? moduleData.content.bgColor : '' }` }}>
                                <div className={`search-icon`}></div>
                                <div className={`search-placeholder`}>{ moduleData.content.placeholder }</div>
                                <div className={`search-btn`} style={{ backgroundColor:`${ moduleData.content.btnColor }` }}>搜索</div>
                            </div>
                        </div>
                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }

}

export default Form.create({})(tpl_con_typ9)
