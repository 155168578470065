import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, DatePicker, Button, Select, Radio, Switch, InputNumber, Tooltip, Icon, Checkbox, Table, message } from 'antd';
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import ExpendDiscount from './components/expend-discount'
import GoodsDiscount from './components/goods-discount'
import CourseDiscount from './components/course-discount'
import BirthdayDiscount from './components/birthday-discount';
import Loading from '@/components/Loading';
import { equityDetail, equitySave } from '@/api/level'

const FormItem = Form.Item;

const Imgs_default={
    1:'https://img.cxkoo.com/chengxuan/1/2023-08-10/fa521ee8f5dd31280324aa41c6fd0ebe.png',
    2:'https://img.cxkoo.com/chengxuan/1/2023-08-10/0b6b37a9016e1bc92d9bd5b64002b4de.png',
    3:'https://img.cxkoo.com/chengxuan/1/2023-08-10/e906a1dc413bb2d4a170f8fb73583337.png',
    4:'https://img.cxkoo.com/chengxuan/1/2023-08-10/28edf5a181b5fb75f1631526afd56ff7.png'
}

class EquityEdit extends Component {
    state = {
        type: '',
        formData: {
            icon: ''
        },
        isLoading: false,
        detail:{}
    }



    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })
        equityDetail({ id: this.props.match.params.id }).then(res => {

            if (res.code === 1) {
                let detail = res.data.detail;

                this.setState({
                    type: detail.type || '',
                    formData:{icon:detail.icon || ''},
                    detail,
                    id:this.props.match.params.id
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }



    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleResetImg = () => {
        let {type,formData}=this.state
        formData.icon=Imgs_default[type];
        this.setState({
            formData
        })
    }

    handleSaveData = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let {formData,id,type}=this.state,sendData=values;
                
                if(!formData.icon){
                    return message.error('请上传权益图标！')
                }
                sendData.icon=formData.icon;

                if(id){
                    sendData.id=id;

                    if(type==1){
                        sendData.rule=this.ExpendDiscount?.getData?.();
                    }else if(type==2){
                        sendData.rule=this.GoodsDiscount?.getData?.();
                    }else if(type==3){
                        sendData.rule=this.CourseDiscount?.getData?.();
                    }else if(type==4){
                        sendData.rule=this.BirthdayDiscount?.getData?.();
                        
                    }
                }

                console.log(sendData);

                this.setState({
                    isLoading: true
                })
                equitySave(sendData).then(res=>{
                    if(res.code==1){
                        message.success('保存成功！');

                        setTimeout(()=>{
                            this.handleBack()
                        },1000)
                    }
                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
            }
        })
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 19
            }
        }

        const data = this.state.detail || {};
        const formData = this.state.formData || {}
        const { getFieldDecorator, getFieldValue } = this.props.form

        return (
            <div className='shadow-radius'>
                <Form {...formItemLayout} className={'model-form '}>
                    <div className={`msg-title mgb15`}>基础设置</div>
                    <FormItem label={`权益图标`} required>
                        <div className={'clearfix'}>
                            {
                                formData.icon
                                    ?
                                    <PictureCard
                                        onRef={e => { this.PictureCard = e }}
                                        imgs={formData.icon}
                                        onDel={e => { this.delImg(e, 'icon') }}
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={e => { this.ImgAdd = e }}
                                        onClick={e => { this.chooseImg(e, 'icon') }}
                                    ></ImgAdd>
                            }
                            {
                                 [1, 2, 3, 4].includes(this.state.type) ? 
                                 <Button type='link' className='mgt60' onClick={this.handleResetImg}>恢复默认</Button>:null
                            }
                           
                        </div>
                        <span className={'fi-help-text'}>建议300X300px，支持JPG,PNG格式</span>
                    </FormItem>
                    <FormItem label={'权益名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || undefined,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入权益名称'
                                }
                            ]
                        })(<Input
                            style={{ width: 400 }}
                            placeholder={''}
                            maxLength={10}
                            suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/10</span>}
                        />)}
                    </FormItem>
                    <FormItem label={'权益说明'}>
                        {getFieldDecorator('desc', {
                            initialValue: data.desc || undefined,
                        })(<Input.TextArea
                            style={{ width: 400 }}
                            placeholder={''}
                            maxLength={300}
                            rows={5}
                        />)}
                        {
                            [1, 2, 3, 4].includes(this.state.type) ? null : <span className={'fi-help-text'}>自定义权益仅做小程序端展示使用。</span>
                        }

                    </FormItem>

                    {
                        [1, 2, 3, 4].includes(this.state.type) ? <div className={`msg-title mgb15`}>规则设置</div> : null
                    }
                    {
                        this.state.type == 1 ? <ExpendDiscount onRef={e=>this.ExpendDiscount=e} detail={data} /> : null
                    }
                    {
                        this.state.type == 2 ? <GoodsDiscount onRef={e=>this.GoodsDiscount=e} detail={data} /> : null
                    }
                    {
                        this.state.type == 3 ? <CourseDiscount onRef={e=>this.CourseDiscount=e} detail={data} /> : null
                    }
                    {
                        this.state.type == 4 ? <BirthdayDiscount onRef={e=>this.BirthdayDiscount=e} detail={data} /> : null
                    }


                    <FormItem label={' '} className={'no-colon mgb20 mgt40'}>
                        <Button type='primary' onClick={this.handleSaveData}>保存</Button>
                        <Button type='default' className='mgl20' onClick={this.handleBack}>取消</Button>
                    </FormItem>
                </Form>

                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(EquityEdit))

