import React from 'react'
import { Modal, Input, Button, Table, Pagination,Tag } from 'antd'
import { Link } from 'react-router-dom'
import { skuItemLists } from '@/api/item'
import CX from '@/utils/CX'


export default class StorePicker extends React.Component{

    state = {
        title:'选择商品',
        visible:false,
        modalLoading:false,
        tableLoading:false,
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.item_id}`} target={`_blank`} className={`fi-link w150`} onClick={() => {this.handleEdit(rowData)}}>{rowData.name || ''}</Link>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }

            },
            {
                title:'sku信息',
                dataIndex:'sku',
                render:(text, rowData, index) => {
                    return(
                        <div>
                            {
                                rowData.sku_names
                                    ?
                                    <Tag>
                                        {
                                            rowData.sku_names.map((item,index) => (
                                                <span className={`lh25`} key={index}>{item}
                                                    {
                                                        index >= rowData.sku_names.length - 1
                                                            ?
                                                            null
                                                            :
                                                            '/'
                                                    }
                                                </span>
                                            ))
                                        }
                                    </Tag>
                                    :
                                    '--'
                            }
                        </div>
                    )
                }
            },
            {
                title:'商品单位',
                dataIndex:'unit',
                render:(text, rowData, index) => {
                    return(
                        <div>{rowData.unit_name}</div>
                    )
                }
            },
            {
                title:'可用库存',
                dataIndex:'stock1',
                render:(text, rowData, index) => {
                    return(
                        <div>{ rowData.stock || 0 }</div>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'stock2',
                render:(text, rowData, index) => {
                    return(
                        <Tag color={rowData.status == 1 ? `green` : `orange`}>{rowData.status == 1 ? '上架中' : '仓库中'}</Tag>
                    )
                }
            }
        ],
        tableData:[],
        selectRows:[],
        selectedRowKeys:[],
        page:1,
        name:'',
        goods_code:'',
        per_page:8,
        total:0,
        callback:null,
        shouldChooseDisable:false
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.selectIds){
            this.setState({
                selectedRowKeys:nextProps.selectIds
            })
        }
        if(nextProps.shouldChooseDisable){
            this.setState({
                shouldChooseDisable:nextProps.shouldChooseDisable
            })
        }
    }

    show = (callback) => {
        this.setState({
            visible:true,
            callback: callback ? callback : null
        })
        this.fetchData();
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    fetchData = () => {
        let sendData = {
            page:this.state.page,
            name:this.state.name,
            goods_code:this.state.goods_code,
            per_page:this.state.per_page
        }
        sendData.store_id = this.props.store_id || ``;
        this.setState({
            tableLoading:true
        })
        skuItemLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.goods_list.data,
                        total = res.data.goods_list.total,
                        per_page = res.data.goods_list.per_page;
                    tableData.forEach((item,index) => {
                        item.item_id = item.id;
                        item.id = `${item.id}-${item.sku_id}`
                        if(item.alias){
                            let sku_names = [];
                            for(var i in item.alias){
                                sku_names.push(item.alias[i])
                            }
                            item.sku_names = sku_names
                        }

                    })
                    this.renderSelectRows(tableData)
                    this.setState({
                        tableData, per_page, total
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    renderSelectRows = lists => {
        let selectedRowKeys = this.state.selectedRowKeys,
            selectRows = this.state.selectRows;
        if(selectedRowKeys.length){
            for(var k in lists){
                lists[k]['disable'] = 0
                if(selectedRowKeys.includes(lists[k]['id'])){
                    if(this.state.shouldChooseDisable){
                        lists[k]['disable'] = 1;
                    }
                    let selectRowsIds = selectRows.map(item => {return item.id})
                    if(!selectRowsIds.includes(lists[k]['id'])){
                        selectRows.push(lists[k])
                    }
                }
            }
            this.setState({
                selectRows
            })
        }
    }

    handleEdit = row => {

    }

    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback(this.state.selectRows)
        }

        this.close();
    }
    handleCancel = () => {
        this.close()
    }
    handleCurrentChange = val =>  {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    getInputValue = e => {
        let name = e.currentTarget.dataset.name || ``,
            value = e.target.value,
            datas = {};
            datas[name] = value;
        this.setState(datas);
    }

    render() {
        let multiple = !!this.props.multiple
        const rowSelection = {
            type: multiple ? 'checkbox' : 'radio',
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {
                if(selectedRowKeys.length == selectedRows.length){
                    this.setState({
                        selectRows:selectedRows,
                        selectedRowKeys
                    })
                }else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr,selectedRowKeys);
                    for(var i in diffArr){
                        for (var j in  oldList){
                            if(oldList[j]['id'] == diffArr[i]){
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows:tmpList,
                        selectedRowKeys
                    })


                }
            },
            getCheckboxProps :record => ({
                disabled: record.disable == 1,
                name:record.name
            })
        }
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    maskClosable={false}
                    confirmLoading={this.state.modalLoading}
                    centered={true}
                >
                    <div className={'title clearfix'}>
                        <div className={'fr btn-group'}>
                            <Input value={this.state.name} onChange={this.getInputValue} data-name={`name`}  placeholder={'商品名称'} style={{'width':'150px'}} />
                            <Input value={this.state.goods_code} onChange={this.getInputValue} data-name={`goods_code`} placeholder={'商品编码'} style={{'width':'150px'}} className={`mgl10`} />
                            <Button type={'primary'} icon={'search'} style={{'verticalAlign':'top'}} onClick={() => {this.fetchData()}}>搜索</Button>
                        </div>
                    </div>
                    <div className={'mgt20'}>
                        <div className={`min-h400`}>
                            <Table
                                size={`middle`}
                                rowSelection={rowSelection}
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                loading={this.state.tableLoading}
                                rowKey={record => record.id}
                                pagination={false}
                            >
                            </Table>
                        </div>
                        {
                            this.state.tableData.length
                                ?
                                <div className={'clearfix mgt10'}>
                                    <div className={'fl'}>
                                        <p>已选择{this.state.selectedRowKeys.length}项</p>
                                    </div>
                                    <div className={'fr'}>
                                        <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    </div>
                                </div>
                                :
                                null
                        }


                    </div>

                </Modal>
            </div>
        )
    }

}
