// 当前id 截止 1-13
const defaultData = {
    1:{
        bgImg:'https://img.cxkoo.com/chengxuan/1/2021-12-09/4a7ba5fa6ca563d77bc2914de0883b6a.png',
        showMemberCard:1,
        userCenter:[
            {
                id:'1-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/b47aeb912ae8d2663179cb5d9eaf4bd1.png',
                name: '我的地址',
                link: '/pages/address/lists',
                isShow:1
            },
            {
                id:'1-12',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-04-15/7fafb71cd641ac282a9152026e530cae.png',
                name: '我的卡包',
                link: '/pages/card/my-card',
                isShow:1
            },
            {
                id:'1-14',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-12-26/37fb69c4bda58a13b61e630ab3e327b6.png',
                name: '足迹',
                link: '/pages/footmark/index',
                isShow:1
            },
            {
                id:'1-2',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/d0b8da764c88cdfd20feade251452643.png',
                name: '购物车',
                link: '/pages/cart/index',
                isShow:1
            },
            {
                id:'1-3',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/febf1c50ada7a6391084284965d0add1.png',
                name: '领券中心',
                link: '/pages/coupon/center',
                isShow:1
            },
            {
                id:'1-4',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/b339c8af76d8b8b560b8941918b344eb.png',
                name: '自助买单',
                link: '/pages/selfPay/index',
                isShow:1
            },
            {
                id:'1-5',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-12-09/b8adee6116c652956acf25bba7b297ab.png',
                name: '我的收藏',
                link: '/pages/collect/index',
                isShow:1
            },
            {
                id:'1-6',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-03-18/97a4c711b50e24d72864bbbd99848718.png',
                name: '积分商城',
                link: '/pages/pointMall/index',
                isShow:1
            },
            {
                id:'1-7',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-03-15/e6a46227f53bf9e4ec6be31803fdedef.png',
                name: '分销员',
                link: '/pages/fx/index',
                isShow:0
            },
            {
                id:'1-8',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/2b5dfcca901edc75343ac988ff349f76.png',
                name: '学员信息',
                link: 'pages/course/student-list',
                isShow:1
            },
            {
                id:'1-9',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/21f065e0dbe2e1ac3b656e9d07fee8dc.png',
                name: '我的班课',
                link: 'pages/course/mine',
                isShow:1
            },
            {
                id:'1-10',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/393dba2424d208b2daf544527930ea8b.png',
                name: '请假',
                link: 'pages/leave/list',
                isShow:1
            },
            {
                id:'1-11',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/f43ad35c9148e060a936b353e0ffcc04.png',
                name: '上课记录',
                link: 'pages/course/record',
                isShow:1
            },
            {
                id:'1-13',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-10-24/c4f3c752712de48a337109323db96d34.png',
                name: '创作中心',
                link: 'pages/recommend/works',
                isShow:1
            },
            {
                id:'1-15',
                icon: 'https://img.cxkoo.com/chengxuan/1/2023-02-16/433b1d69798d25bd9450773048adbe73.png',
                name: '专属客服',
                link: '',
                isShow:1
            },
            {
                id:'1-16',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-05-31/df0e61e68e32a3f9fde5dd4f18af6c8f.png',
                name: '观演人',
                link: 'pages/ticket/viewers',
                isShow:1
            },
           
        ],
    },
    2:{
        bgImg:'https://img.cxkoo.com/chengxuan/1/2021-10-20/01ec0cd4af17f4cc63a4c0b4e98d99a2.png',
        showMemberCard:1,
        userCenter:[
            {
                id:'2-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/efee1ef8ed79c860994136298ec2729e.png',
                name: '购物车',
                link: '/pages/cart/index',
                isShow:1
            },
            {
                id:'2-2',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/fbd1a350a83daefe07cbffb443d2b45e.png',
                name: '我的地址',
                link: '/pages/address/lists',
                isShow:1
            },
            {
                id:'2-14',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-12-26/28b47ea287edf184c2ab1563efaa87cf.png',
                name: '足迹',
                link: '/pages/footmark/index',
                isShow:1
            },
            {
                id:'2-12',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-04-15/9e16c57df64e51f24514a50d3afbfc22.png',
                name: '我的卡包',
                link: '/pages/card/my-card',
                isShow:1
            },
            {
                id:'2-3',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/6dbd3e5e1b65b44dafb055f7c06626a5.png',
                name: '积分商城',
                link: '/pages/pointMall/index',
                isShow:1
            },
            {
                id:'2-4',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/eec2691d6a65e0cf6827ccfcc8e382a3.png',
                name: '领券中心',
                link: '/pages/coupon/center',
                isShow:1
            },
            {
                id:'2-5',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/89aa9906b7df68add5c37635ae010964.png',
                name: '自助买单',
                link: '/pages/selfPay/index',
                isShow:1
            },
            {
                id:'2-6',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/5fbbbca0800f553b20fd9adc477ba7b8.png',
                name: '收藏',
                link: '/pages/collect/index',
                isShow:1
            },
            {
                id:'2-7',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/68ef093d452862210196cf9774935e8d.png',
                name: '专属客服',
                link: '',
                isShow:1
            },
            {
                id:'2-8',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/defdb49cff726179e4985f2da4df81dd.png',
                name: '学员信息',
                link: 'pages/course/student-list',
                isShow:1
            },
            {
                id:'2-9',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/6b8795eae0ee141c636990d46efc6024.png',
                name: '我的班课',
                link: 'pages/course/mine',
                isShow:1
            },
            {
                id:'2-10',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/c1483507aa52ba7b445da64bc82fd8c9.png',
                name: '请假',
                link: 'pages/leave/list',
                isShow:1
            },
            {
                id:'2-11',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-06-14/5dfbededcb06f57a8bdb544766d9af52.png',
                name: '上课记录',
                link: 'pages/course/record',
                isShow:1
            },
            {
                id:'2-13',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-10-24/b020a766e109a20369fd150fc52e4616.png',
                name: '创作中心',
                link: 'pages/recommend/works',
                isShow:1
            },
            {
                id:'2-16',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-05-31/919a8ef24052d304e67c5ba182433509.png',
                name: '观演人',
                link: 'pages/ticket/viewers',
                isShow:1
            }
        ],
        shareTool:[
            {
                id:'3-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2021-10-20/3aeac65b9df756f05a5655289485f2e6.png',
                name: '分销员',
                link: '/pages/fx/index',
                isShow:0
            }
        ]
    },
    3:{
        bgImg:'https://img.cxkoo.com/chengxuan/1/2022-11-02/527e594a1a5862bb2ad8ba1350b6f092.png',
        showMemberCard:1,
        showTrain:1,
        trainingTool:[
            {
                id:'4-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/46ff6be4db99c5d534c21031b1e3ea7d.png',
                name: '学员信息',
                link: 'pages/course/student-list',
                isShow:1
            },
            {
                id:'4-2',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/8f996b29fc1bad7407d9afc546e12402.png',
                name: '我的班课',
                link: 'pages/course/mine',
                isShow:1
            },
            {
                id:'4-3',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/a17bb873c97cd839082a2e5ce65ffa97.png',
                name: '请假',
                link: 'pages/leave/list',
                isShow:1
            },
            {
                id:'4-4',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/0d01913b02aa2305e65b7647a6a4f711.png',
                name: '上课记录',
                link: 'pages/course/record',
                isShow:1
            },
            {
                id:'4-5',
                icon: 'https://img.cxkoo.com/chengxuan/1/2024-01-31/7edf9587aaaf64f30322c3c2682235df.png',
                name: '体测信息',
                link: 'pages/bmi/list',
                isShow:1
            },
        ],
        myContent:[
            {
                id:'5-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/6a44ae6404a1516f32b19453f0bd8b8d.png',
                name: '创作中心',
                link: 'pages/recommend/works',
                isShow:1
            },
            {
                id:'5-2',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/ff31255f7b225cdd4cc3972f4af70026.png',
                name: '收藏',
                link: 'pages/collect/index',
                isShow:1
            },
            {
                id:'5-3',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/5cd2d970fb2ddde85b93fed36d535088.png',
                name: '足迹',
                link: 'pages/footmark/index',
                isShow:1
            }
            // ,
            // {
            //     id:'5-4',
            //     icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/e90c99dc0e25e7498a2f95f645d2c07d.png',
            //     name: '赞过',
            //     link: 'pages/recommend/note-like',
            //     isShow:1
            // },
        ],
        userCenter:[
            {
                id:'6-1',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/44b436612343f9fc08072c61bdcf2587.png',
                name: '我的订单',
                link: 'pages/order/lists',
                isShow:1
            },
            {
                id:'6-2',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/d097925ca74dcec14c4bb472bd950147.png',
                name: '我的地址',
                link: 'pages/address/lists',
                isShow:1
            },
            {
                id:'6-3',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/66a0f9c4c4bfa5da3b898926b6a42365.png',
                name: '购物车',
                link: 'pages/cart/index',
                isShow:1
            },
            {
                id:'6-4',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/b15743400a3e28939314088ad0f6a30a.png',
                name: '积分商城',
                link: 'pages/pointMall/index',
                isShow:1
            },
            {
                id:'6-5',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/b6e4407790cda59ccb19626740b45211.png',
                name: '领券中心',
                link: 'pages/coupon/center',
                isShow:1
            },
            {
                id:'6-6',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/16ee0abd85c6e814097507e88ea04fab.png',
                name: '自助买单',
                link: 'pages/selfPay/index',
                isShow:1
            },
            {
                id:'6-7',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/c3046c677ee720dbf63b3deb17ce9bd1.png',
                name: '我的卡包',
                link: 'pages/card/my-card',
                isShow:1
            },
            {
                id:'6-8',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/03cec6dfcbe5d3f9db236dd82895ce3f.png',
                name: '专属客服',
                link: '',
                isShow:1
            },
            {
                id:'6-9',
                icon: 'https://img.cxkoo.com/chengxuan/1/2022-11-02/67914102fcfe605d819d9de5a55e7b5e.png',
                name: '分销员',
                link: 'pages/fx/index',
                isShow:0
            },
        ],
    }
}
export default defaultData
