import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, DatePicker, Table, Pagination } from 'antd'
import { pickUpLists } from '@/api/order'
import moment from 'moment'
import PickUpSearch from './components/pickup-search'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class PickUp extends React.Component{
    state = {
        searchForm:{
            order_sn:'',
            user_name:'',
            user_mobile:'',
            use_code:'',
            audit_type:'',
            start_time:'',
            end_time:''
        },
        columns:[
            {
                title:'订单编号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.order_sn || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title:'客户/收货人',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.user_address ? rowData.user_address.name : '-' }</p>
                            <p className={`lh25`}>{ rowData.user_address ? rowData.user_address.mobile : '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'核销内容',
                dataIndex:'content',
                width:'300px',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.goods_info || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'核销金额',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.pay_ment }</p>
                        </div>
                    )
                }
            },
            {
                title:'核销时间',
                dataIndex:'time',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.finished_at || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'类型',
                dataIndex:'type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.trade_type_name }</p>
                        </div>
                    )
                }
            },
            {
                title:'核销码',
                dataIndex:'use_code',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.use_code }</p>
                        </div>
                    )
                }
            },
            {
                title:'核销来源',
                dataIndex:'audit_type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.audit_type == 1 ? '后台' : '小程序端' }</p>
                            <p className={`lh25`}>{ rowData.audit_name ||''}</p>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10

    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        pickUpLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page;
                    this.setState({
                        tableData:lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    handleAdd = () => {
        this.PickUpSearch.show(res => {
            this.fetchData()
        })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    render() {
        const formItemLayout = {
            labelCol: {
                span: 9
            },
            wrapperCol: {
                span: 15
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <PickUpSearch onRef={ e => { this.PickUpSearch = e } }></PickUpSearch>
                <div className={`content-head mgb15`}>
                    <Form className={'search-form clearfix'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户/收货人昵称'} className={'fl'}>
                                {
                                    getFieldDecorator('user_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'客户/收货人手机号'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'商户单号'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('order_sn',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;&emsp;&emsp;&emsp;&nbsp;核销码</span>} className={'fl'}  >
                                {
                                    getFieldDecorator('use_code',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入核销码' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;&emsp;&emsp;&emsp;&nbsp;核销来源</span>} className={'mgl20 fl'} >
                                {
                                    getFieldDecorator('audit_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>后台</Select.Option>
                                            <Select.Option value={2}>小程序端</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;&emsp;&emsp;&nbsp;核销时间</span>} className={' fl'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem className={'fl mgl20'}>
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>在线核销</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default Form.create({})(withRouter(PickUp))
