import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Tooltip, Tag, } from 'antd'
import moment from 'moment'
import ActivityItem from './components/activityItem'
import {groupShoppingActiveData,exportActiveData} from '@/api/group-buying.js'
import CX from '@/utils/CX'

import '@/assets/css/group-buying/index.scss';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class GroupBuyingActivity extends Component {

    state = {
        searchForm: {
            tuan_order_sn: "",
            order_sn:"",
            start_time: "",
            end_time: "",
            status:0
        },
        storeList: [],
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false,
       
    }

    componentDidMount() {
        this.fetchData()
        
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.group_shopping_id = this.props.match.params.id

        this.setState({
            tableLoading: true
        })
        groupShoppingActiveData(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }



    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }

    handleExport = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let sendData = values;
                sendData.group_shopping_id = this.props.match.params.id
                this.setState({
                    isLoading: true
                })
                exportActiveData(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '拼团活动数据.xlsx';
                        CX.exportExcel(blob, fileName)
                    }).finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        searchForm.status=0

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }


    render() {
       
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;团编号</span>}  className={'fl'}>
                                {
                                    getFieldDecorator('tuan_order_sn', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入团编号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={<span>商户单号</span>}  className={'fl mgl40'}>
                                {
                                    getFieldDecorator('order_sn', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'团状态'} className={'fl mgl40'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: '200px' }}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>拼团中</Select.Option>
                                            <Select.Option value={2}>已成团</Select.Option>
                                            <Select.Option value={3}>未成团</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                        </div>

                        <div className={`clearfix`}>
                          
                            <FormItem  label={<span>开团时间</span>} className={'fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: null
                                    })(
                                        <RangePicker
                                            style={{ width: 400 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder={['开始时间', '结束时间']}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;</span>}  >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleExport() }}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>

                <div className={'group-buying-activity mgt15'}>
                    <div className="activty-head">
                        <div style={{"width":"17%"}}>客户信息</div>
                        <div  style={{"width":"13%"}}>商户单号</div>
                        <div  style={{"width":"8%"}}>拼团价</div>
                        <div  style={{"width":"8%"}}>实付金额</div>
                        <div  style={{"width":"13%"}}>支付时间</div>
                        <div  style={{"width":"8%"}}>团状态</div>
                        <div  style={{"width":"10%"}}>下单门店</div>
                        <div  style={{"width":"15%"}}>使用有效期</div>
                        <div  style={{"width":"8%"}}>操作</div>
                    </div>
                    {this.state.tableData.map(val=>{
                        return <ActivityItem item={val} key={val.id} />
                    })}
                    
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default Form.create({})(withRouter(GroupBuyingActivity))