import React from 'react'
import { Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import CouponLists from './lists'
import CouponRecord from './record'
const { TabPane } = Tabs;

class Coupon extends React.Component {

    state = {
        activeKey:"1"
    }

    changeTabs=key=>{
        this.setState({
            activeKey:key
        })
    }


    render() {


        return (
            <div className={'shadow-radius'}>
                <Tabs defaultActiveKey={this.state.activeKey} onChange={this.changeTabs}>
                    <TabPane tab="优惠券" key="1">
                        <CouponLists />
                    </TabPane>
                    <TabPane tab="领取记录" key="2">
                        <CouponRecord />
                    </TabPane>
                </Tabs>
            </div>
        )
    }


}

export default withRouter(Coupon)
