import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Table, Pagination, Divider, Select, TimePicker } from 'antd'
import moment from 'moment'
import { gradeCourseRecord } from '@/api/educational'
import Loading from '@/components/Loading'

class CourseRecord extends Component {
    state = {
        columns: [
            {
                title: '上课时间',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{`${rowData.table_date}   ${rowData.start_time}-${rowData.end_time}`}</p>
                    )
                }
            },
            {
                title: '上课地点',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.store.name}</p>
                    )
                }
            },
            {
                title: '老师/助教',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.teacher_name}{rowData.assist_teacher_name && ('/'+rowData.assist_teacher_name)}</p>
                    )
                }
            },
            {
                title: '实到/应到',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p>{ `${rowData.sign_num}/${this.props.grade_detail.grade_student_count}`}</p>
                    )
                }
            },
            {
                title: '上课',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.sign_num}</p>
                    )
                }
            },
            {
                title: '请假',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.off_num}</p>
                    )
                }
            },
            {
                title: '未到',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.no_sign_num}</p>
                    )
                }
            }
        ],
        tableData: [],
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        tableLoading: false,
    }

    componentDidMount() {
        this.fetchData()
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }


    fetchData = () => {
        let sendData = {};
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.grade_id = this.props.grade_id;
        this.setState({
            tableLoading: true
        })
        gradeCourseRecord(sendData).then(res => {
            console.log(res);
            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    








    render() {
        return (
            <div >
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default CourseRecord
