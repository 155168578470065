import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon } from 'antd'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { studentAdd } from '@/api/student'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
const FormItem = Form.Item;

class AddStudentM extends Component {

    state = {
        formData: {
            real_name: "",
            parent_mobile: "",
            parent_user_id: "",
            remark_name: "",
            stature: "",
            weight: "",
            age: "",
            time: null,
            sex: "",
            address: "",
            grade_id:"",
            store_id: "",
            course_adviser: "",
            cover_img: '',
            remark:''
        },
        storeLists: [],
        isLoading: false
    }
    componentDidMount() {
        this.fetchStore()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }
    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover_img':
                    formData[name] = imgs[0];
                    break;
                case 'pic_urls':
                    formData['pic_urls'] = formData['pic_urls'] || [];
                    formData['pic_urls'] = formData['pic_urls'].concat(imgs);
                    if (formData['pic_urls'].length > 10) {
                        formData['pic_urls'] = formData['pic_urls'].slice(0, 10)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    onChangeDate = (dateObj, date) => {
        let age
        if (dateObj === null) {
            age = ""
        } else {
            age = moment().diff(date, "years")
        }

        let { setFieldsValue } = this.props.form
        setFieldsValue({
            age
        })
    }

    saveData = () => {
        let formData = this.state.formData || {},
            sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;
                sendData.head_img_url = formData.cover_img || ``;
                sendData.birthday = values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : ''

                let pattern = /^1[0-9]{10}$/

                if (!pattern.test(sendData.parent_mobile)) {
                    return message.error("请输入正确的手机号")
                }

                this.setState({
                    modalLoading: true
                })
                studentAdd(sendData).then(res => {
                    message.success(res.msg)
                    if (res.code === 1) {
                        this.setState({
                            modalLoading: false
                        })
                        this.props.handleS()
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.props.closeAddSM()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        let data = this.state.formData
        const { getFieldDecorator } = this.props.form
        return (

            <Modal
                title={"添加学员"}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                    <Row gutter={24}>
                        <Col span={20}>
                            <FormItem label="姓名">
                                {getFieldDecorator('real_name', {
                                    initialValue: data && data.real_name,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入学员姓名'
                                        }
                                    ]
                                })(<Input />)}
                            </FormItem>
                            <FormItem label="学员手机号">
                                {getFieldDecorator('parent_mobile', {
                                    initialValue: data && data.parent_mobile,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入学员手机号进行关联'
                                        }
                                    ]
                                })(<Input placeholder='请输入学员手机号进行关联' />)}
                                <span className={`fi-help-text`}>手机号为系统唯一标识，当此手机号的微信用户授权后会自动绑定</span>
                            </FormItem>
                            <FormItem label={'备注名'} >
                                {
                                    getFieldDecorator('remark_name', {
                                        initialValue: data && data.remark_name
                                    })(<Input />)
                                }
                            </FormItem>


                            <FormItem label={'头像'} >
                                <div className={`clearfix`}>
                                    {
                                        data.cover_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={data.cover_img}
                                                onDel={e => { this.delImg(e, 'cover_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'cover_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：750x750像素，单张不超过2M</span>
                            </FormItem>

                            <FormItem label={'身高'} >
                                {
                                    getFieldDecorator('stature', {
                                        initialValue: data && data.stature
                                    })(<Input addonAfter="cm" />)
                                }
                            </FormItem>

                            <FormItem label={'体重'} >
                                {
                                    getFieldDecorator('weight', {
                                        initialValue: data && data.weight
                                    })(<Input addonAfter="kg" />)
                                }
                            </FormItem>
                            <FormItem label={'年龄'} >
                                {
                                    getFieldDecorator('age', {
                                        initialValue: data && data.age
                                    })(<Input addonAfter="岁" placeholder='选择生日后自动计算' disabled />)
                                }
                            </FormItem>
                            <FormItem label={'生日'} >
                                {
                                    getFieldDecorator('birthday', {
                                        initialValue: data && data.birthday,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择学员生日'
                                            }
                                        ]
                                    })(
                                        <DatePicker style={{width:'100%'}} placeholder='请选择生日' onChange={this.onChangeDate} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'性别'} >
                                {
                                    getFieldDecorator('sex', {
                                        initialValue: data && data.sex,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择'}   >
                                            <Select.Option value={1}>男</Select.Option>
                                            <Select.Option value={2}>女</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'联系地址'} >
                                {
                                    getFieldDecorator('address', {
                                        initialValue: data && data.address
                                    })(<Input placeholder='请输入详细地址' />)
                                }
                            </FormItem>

                            <FormItem label={'所在班级'} >
                                {
                                    getFieldDecorator('grade_id', {
                                        initialValue: data && data.grade_id
                                    })(
                                        <Select placeholder={'请选择所在班级，未购课程可不选'} >

                                            {
                                                this.props.gradeLists.map(grade => (
                                                    <Select.Option value={grade.id} key={grade.id}>{grade.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'所属校区'} >
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: data && data.store_id
                                    })(
                                        <Select placeholder={'请选择校区'}>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'课程顾问'} >
                                {
                                    getFieldDecorator('course_adviser', {
                                        initialValue: data && data.course_adviser
                                    })(<Input />)
                                }
                            </FormItem>

                            <FormItem label={'备注'} >
                                {
                                    getFieldDecorator('remark', {
                                        initialValue: data && data.remark 
                                    })(<Input placeholder='显示于课程记录...'  maxLength={15}  />)
                                }
                            </FormItem>

                        </Col>
                        <Col span={4}>
                        </Col>
                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(AddStudentM))