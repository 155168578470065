/**
 * 笔记列表组件
 * @type 8
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'


const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ18 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}

        return (
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`笔记列表`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>
                        {
                            moduleData.content.style == 1
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`flowList pdl10 pdr10`}>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 0
                                                        ?
                                                        <div className={`note-item-style1`} key={index} >
                                                            <div className="item-img img-auto">
                                                                <img src={item.cover} alt="" />
                                                            </div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.title}</div>
                                                                <div className={`mgt10`}>
                                                                    <div className="note-user">
                                                                        <div className="user-img" style={{ backgroundImage: `url(${item.author_info && item.author_info.img})` }}></div>
                                                                        <div className="user-name">{item.author_info && item.author_info.name}</div>
                                                                        {
                                                                            moduleData.content.showLike ?
                                                                                <div className="like">
                                                                                    <div className="icon-zan"></div>
                                                                                    <div className='mgl5'>{item.zan}</div>
                                                                                </div> : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }

                                        </div>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 1
                                                        ?
                                                        <div className={`note-item-style1`} key={index} >
                                                            <div className="item-img img-auto">
                                                                <img src={item.cover} alt="" />
                                                            </div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.title}</div>
                                                                <div className={`mgt10`}>
                                                                    <div className="note-user">
                                                                        <div className="user-img" style={{ backgroundImage: `url(${item.author_info && item.author_info.img})` }}></div>
                                                                        <div className="user-name">{item.author_info && item.author_info.name}</div>
                                                                        {
                                                                            moduleData.content.showLike ?
                                                                                <div className="like">
                                                                                    <div className="icon-zan"></div>
                                                                                    <div className='mgl5'>{item.zan}</div>
                                                                                </div> : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`flowList pdl10 pdr10`}>
                                        <div className={`column`}>
                                            <div
                                                className={`note-item-style1`}
                                            >
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                <div className="item-desc">
                                                    <div className="title">笔记标题</div>
                                                    <div className={`mgt10`}>
                                                        <div className="note-user">
                                                            <div className="user-img"></div>
                                                            <div className="user-name">作者昵称</div>
                                                            {
                                                                moduleData.content.showLike ?
                                                                    <div className="like">
                                                                        <div className="icon-zan"></div>
                                                                        <div>2222</div>
                                                                    </div> : null
                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`column`}>
                                            <div className={`note-item-style1`}>
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                <div className="item-desc">
                                                    <div className="title">笔记标题</div>
                                                    <div className={`mgt10`}>
                                                        <div className="note-user">
                                                            <div className="user-img"></div>
                                                            <div className="user-name">作者昵称</div>
                                                            {
                                                                moduleData.content.showLike ?
                                                                    <div className="like">
                                                                        <div className="icon-zan"></div>
                                                                        <div>2222</div>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                :
                                null

                        }

                        {
                            moduleData.content.style == 2
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`bdbox pdl10 pdr10`}>
                                        {
                                            moduleData.content.dataset.map((item, index) => (
                                                <div className={`note-item-style2 `} key={index} >
                                                    <div className="note-item">
                                                        <div className="left">
                                                            <div className="title">{item.title}</div>
                                                            <div className="desc">
                                                                <div className="user">
                                                                    <div className="user-img" style={{ backgroundImage: `url(${item.author_info && item.author_info.img})` }}></div>
                                                                    <div className="user-name">{item.author_info && item.author_info.name}</div>
                                                                </div>
                                                                <div className="flex">
                                                                    {
                                                                        moduleData.content.showPv ?
                                                                            <div className="eye">
                                                                                <div className="icon"></div>
                                                                                <div>{item.pv}</div>
                                                                            </div> : null
                                                                    }
                                                                    {
                                                                        moduleData.content.showLike ?
                                                                            <div className="like">
                                                                                <div className="icon"></div>
                                                                                <div>{item.zan}</div>
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right" style={{ backgroundImage: `url(${item.cover})` }}></div>
                                                    </div>
                                                    {
                                                        item.recommend_info.length ?
                                                            <div className="item-wrap">
                                                                {
                                                                    item.recommend_info.map(val => {
                                                                        return (
                                                                            <div className="item" key={val.id}>
                                                                                <div className="item-img" style={{ backgroundImage: `url(${val.cover_img})` }} ></div>
                                                                                <div className="item-right">
                                                                                    <div className="title">{val.name}</div>
                                                                                    <div className="price">{val.price}</div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div> : null
                                                    }

                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className={`bdbox pdl10 pdr10`}>
                                        <div className={`note-item-style2 `} >
                                            <div className="note-item">
                                                <div className="left">
                                                    <div className="title">笔记标题</div>
                                                    <div className="desc">
                                                        <div className="user">
                                                            <div className="user-img"></div>
                                                            <div className="user-name">作者昵称</div>
                                                        </div>
                                                        <div className="flex">
                                                            {
                                                                moduleData.content.showPv ?
                                                                    <div className="eye">
                                                                        <div className="icon"></div>
                                                                        <div>3939</div>
                                                                    </div> : null
                                                            }
                                                            {
                                                                moduleData.content.showLike ?
                                                                    <div className="like">
                                                                        <div className="icon"></div>
                                                                        <div>9999</div>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right" style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                            </div>
                                        </div>
                                        <div className={`note-item-style2 `} >
                                            <div className="note-item">
                                                <div className="left">
                                                    <div className="title">笔记标题</div>
                                                    <div className="desc">
                                                        <div className="user">
                                                            <div className="user-img"></div>
                                                            <div className="user-name">作者昵称</div>
                                                        </div>
                                                        <div className="flex">
                                                            {
                                                                moduleData.content.showPv ?
                                                                    <div className="eye">
                                                                        <div className="icon"></div>
                                                                        <div>3939</div>
                                                                    </div> : null
                                                            }
                                                            {
                                                                moduleData.content.showLike ?
                                                                    <div className="like">
                                                                        <div className="icon"></div>
                                                                        <div>9999</div>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right" style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                            </div>
                                        </div>

                                    </div>
                                :
                                null
                        }

                        <EditorIcon type={`icon-guanbi`} className={`del-module`}
                            onClick={this.handleDeleteModule}></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_typ18)
