/**
 * 场馆预订组件
 * @type 14
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip, Select} from 'antd'
import PropTypes from "prop-types";
import Gallery from '@/components/Gallery/Gallery'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import { courtStoreLists } from '@/api/court'
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 16 }}

class tpl_ctrl_type14 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }

    state = {
        storeLists:[],
        moduleData:{},
        itemStyleGroup:[
            {
                name:'样式一',
                style:1,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            },
            {
                name:'样式二',
                style:2,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            },
            {
                name:'样式三',
                style:3,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
        this.fetchStore();
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        courtStoreLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    changeLink = (e,item,index) => {
        let moduleData = this.state.moduleData;
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    moduleData.content.dataset[index][i] = res[i];
                }
                this.context.updateDesign(moduleData)
            }
        })
    }
    handleStoreChange = (e,item,index) => {
        let moduleData = this.state.moduleData,
            storeLists = this.state.storeLists || [],
            currrent = '';
        currrent = storeLists.filter(child => { return child.id == e })
        if(currrent && currrent.length){
            moduleData.content.dataset[index]['store'] = currrent[0];
            this.context.updateDesign(moduleData)
        }
    }
    chooseImg = (item,index) => {
        let moduleData = this.state.moduleData;
        this.Gallery.show(res => {
            if(res){
                moduleData.content.dataset[index]['pic'] = res[0];
                this.context.updateDesign(moduleData)
            }
        })

    }
    delStore = (item,index) => {
        let moduleData = this.state.moduleData;
        moduleData.content.dataset.splice(index,1);
        this.context.updateDesign(moduleData)

    }
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)

    }
    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }
    changeTxt = (e) => {
        let moduleData = this.state.moduleData,
            value = e.target.value,
            name = e.currentTarget.dataset.name || ``;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData);
    }
    handleAdd = () => {
        let moduleData = this.state.moduleData,
            newData = { pic:'',store:{} };
        moduleData.content.dataset.push(newData);
        this.context.updateDesign(moduleData)
    }
    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>场馆预订</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件样式`}>
                                    <ul className={`template-box`}>
                                        {
                                            this.state.itemStyleGroup.map((item,index) => (
                                                <Tooltip effect="dark" placement="bottom" title={ item.name } key={index}>
                                                    <li className={`temp ${ moduleData.content.style == item.style ? 'selected' : '' }`} onClick={ () => { this.handleItemStyle(item.style) } }>
                                                        {
                                                            moduleData.content.style == item.style
                                                                ?
                                                                <img src={ item.imgs[0] } className={`img`} alt=""/>
                                                                :
                                                                <img src={ item.imgs[1] } className={`img`} alt=""/>

                                                        }
                                                    </li>
                                                </Tooltip>
                                            ))
                                        }
                                    </ul>
                                </FormItem>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`组件标题`} { ...ctrlItemLayout }>
                                    <Input
                                        type={'text'}
                                        value={moduleData.content.title}
                                        onChange={ this.changeTxt }
                                        data-name={'title'}
                                    />
                                </FormItem>
                                <FormItem label={`字号大小`} { ...ctrlItemLayout }>
                                    <Input
                                        type={'text'}
                                        value={moduleData.content.titleFontSize}
                                        onChange={ this.changeTxt }
                                        data-name={'titleFontSize'}
                                        addonAfter={`px`}
                                    />
                                </FormItem>
                                <FormItem label={`字体颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.titleFontColor} callback={ (color) => { this.handleColorChange(color,'titleFontColor') } } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('titleFontColor','#000000')}>重置</Button>
                                </FormItem>
                                {
                                    moduleData.content.style == 1
                                        ?
                                        null
                                        :
                                        <FormItem label={`按钮背景`} { ...ctrlItemLayout }>
                                            <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnColor} callback={ (color) => { this.handleColorChange(color,'btnColor') } } ></ColorPicker>
                                            <Button type="link" onClick={()=>this.handleResetColor('btnColor','#FF345C')}>重置</Button>
                                        </FormItem>
                                }
                                {
                                    moduleData.content.style == 1
                                        ?
                                        null
                                        :
                                        <FormItem label={`按钮文字颜色`} { ...ctrlItemLayout }>
                                            <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnFontColor} callback={ (color) => { this.handleColorChange(color,'btnFontColor') } } ></ColorPicker>
                                            <Button type="link" onClick={()=>this.handleResetColor('btnFontColor','#ffffff')}>重置</Button>
                                        </FormItem>
                                }


                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>选择门店</div>
                            <div className={`cx-ctrl-panel--box`}>
                                {
                                    moduleData.content.dataset.map((item,index) => (
                                        <div className={`cx-img-list`} key={index}>
                                            <div className={`cx-img-item clearfix`}>
                                                <FormItem label={`选择门店`} {...ctrlItemLayout}>
                                                    <Select value={item.store?.id} style={{width:200}} placeholder={'请选择'} onChange={ (e) => { this.handleStoreChange(e,item,index) } }>
                                                        {
                                                            this.state.storeLists.map(store => (
                                                                <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormItem>
                                                <FormItem label={`门店图片`} {...ctrlItemLayout}>
                                                    <div className={'clearfix'}>
                                                        <div className={'img-add'} onClick={() => { this.chooseImg(item,index) }} data-name="pic">
                                                            {
                                                                item.pic
                                                                    ?
                                                                    <div>
                                                                        <img src={item.pic} alt=""/>
                                                                    </div>
                                                                    :
                                                                    '+'
                                                            }
                                                        </div>
                                                    </div>
                                                    <span className={`fi-help-text`}>建议尺寸16：9</span>
                                                </FormItem>
                                            </div>
                                            {
                                                index > 0
                                                    ?
                                                    <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delStore(item,index) }} data-name={'pic'}  />
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))
                                }
                                <Button type={`primary`} className={`w100`} onClick={this.handleAdd} disabled={moduleData.content.dataset.length >= 5} >添加门店</Button>
                            </div>
                        </div>
                    </Form>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type14)

