import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio } from 'antd'
import { memberCardLists } from '@/api/member-card'
import { timesCardLists } from '@/api/times-card'
import message from '@/utils/message'
import Loading from '@/components/Loading'
const FormItem = Form.Item
const { TextArea } = Input

class CardOpen extends React.Component{
    state = {
        title:'开通卡项',
        visible:false,
        modalLoading:false,
        isLoading:false,
        callback:null,
        current:'',
        currentCard:'',
        currentTimeCard:'',
        cardLists:[],
        cardListsTimes:[],
        rechargeRules:[],

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
        this.fetchCards()
        this.fetchTimesCards()
    }
    fetchCards = () => {
        let sendData = {};
        sendData.page = 1;
        sendData.per_page = 100;
        memberCardLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.card_list.data;
                    this.setState({
                        cardLists:lists
                    })

                }
            })
            .finally(() => {

            })
    }
    fetchTimesCards = () => {
        let sendData = {};
        sendData.page = 1;
        sendData.per_page = 100;
        timesCardLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data;
                    this.setState({
                        cardListsTimes:lists
                    })

                }
            })
            .finally(() => {

            })
    }
    close = () => {
        this.setState({
            visible:false,
            current:''
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            rechargeRules = this.state.rechargeRules,
            currentTimeCard = this.state.currentTimeCard || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(values.card_type == 1){
                    values.card_id = values.member_card_id
                    values.pay_ment = rechargeRules[values.ruleIndex]['recharge_money'];
                }else {
                    values.card_id = values.times_card_id
                    values.pay_ment = currentTimeCard.price || ''
                }
                if(typeof callback == 'function'){
                    callback(values)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    handleSelectChange = val => {
        let rechargeRules = this.state.rechargeRules,
            current = rechargeRules[val];
        this.setState({
            current
        })
    }
    handleCardChange = val => {
        let cardLists = this.state.cardLists,
            currentCard = '';
        cardLists.forEach(item => {
            if(item.id == val){
                currentCard = item
            }
        })
        if(currentCard){
            this.setState({
                currentCard,
                current:'',
                rechargeRules:currentCard.recharge_rules
            })
            this.props.form.setFieldsValue({
                ruleIndex:undefined
            })
        }
    }
    handleTimeCardChange = val => {
        let cardListsTimes =  this.state.cardListsTimes,
            currentTimeCard = '';
        cardListsTimes.forEach(item => {
            if(item.id == val){
                currentTimeCard = item
            }
        })
        this.setState({
            currentTimeCard
        })


    }
    render() {
        const storeLists = this.props.storeLists || []
        const rechargeRules = this.state.rechargeRules || []
        const data = this.props.data || {}
        let current = this.state.current || {},
            currentTimeCard = this.state.currentTimeCard || {};
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`选择门店`}>
                        {getFieldDecorator('store_id', {
                            initialValue: data.store_id,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择门店'
                                }
                            ],
                        })(
                            <Select placeholder={'请选择'}>
                                {
                                    storeLists.map((item,index) => (
                                        <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                    ))
                                }
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label={`选择卡类型`}>
                        {getFieldDecorator('card_type', {
                            initialValue: 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择卡类型'
                                }
                            ],
                        })(
                            <Select placeholder={'请选择'}>
                                <Select.Option value={1} >储值卡</Select.Option>
                                <Select.Option value={2} >次卡</Select.Option>
                            </Select>
                        )}
                    </FormItem>
                    {
                        getFieldValue('card_type') == 1
                            ?
                            <div>
                                <FormItem label={`选择卡名称`}>
                                    {getFieldDecorator('member_card_id', {
                                        initialValue: data.member_card_id,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择卡'
                                            }
                                        ],
                                    })(
                                        <Select placeholder={'请选择'} onChange={ this.handleCardChange }>
                                            {
                                                this.state.cardLists.map((item,index) => (
                                                    <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem label={`选择充值套餐`}>
                                    {getFieldDecorator('ruleIndex', {
                                        initialValue: data.ruleIndex,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择充值套餐'
                                            }
                                        ],
                                    })(
                                        <Select placeholder={'请选择'} onChange={ this.handleSelectChange }>
                                            {
                                                rechargeRules.map((item,index) => (
                                                    <Select.Option value={index} key={index}>
                                                        充{ item.recharge_money }元，送{ item.present_money }元+{ item.present_points }积分
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem label={`实付金额`}>
                                    <p className={``}>{ current.recharge_money ? `${current.recharge_money}元` : '请选择套餐' }</p>
                                </FormItem>
                                <FormItem label={`到账金额`}>
                                    <p className={``}>{ current.recharge_money ? `${parseFloat(current.recharge_money) + parseFloat(current.present_money)}元+${current.present_points}积分` : '请选择套餐' }</p>
                                </FormItem>
                            </div>
                            :
                            <div>
                                <FormItem label={`选择卡名称`}>
                                    {getFieldDecorator('times_card_id', {
                                        initialValue: data.times_card_id,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择卡'
                                            }
                                        ],
                                    })(
                                        <Select placeholder={'请选择'} onChange={ this.handleTimeCardChange }>
                                            {
                                                this.state.cardListsTimes.map((item,index) => (
                                                    <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem label={`实付金额`}>
                                    <p className={``}>{ currentTimeCard.price ? `${currentTimeCard.price}元` : '请选择卡' }</p>
                                </FormItem>
                            </div>
                    }
                    <FormItem label={`操作员`}>
                        {
                            getFieldDecorator('remark',{
                                initialValue: data.remark || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写操作员'
                                    }
                                ]
                            })(
                                <Input placeholder={`操作员姓名`} />
                            )
                        }
                    </FormItem>
                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </Modal>
        )
    }
}

export default Form.create({})(withRouter(CardOpen))
