/**
 * 表单文本
 * @type 1
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Radio,Icon, Modal, InputNumber } from 'antd'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import PropTypes from "prop-types";
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
const FormItem = Form.Item
const { TextArea } = Input
const ctrlItemLayout = {labelCol: { span: 6 }, wrapperCol: { span: 17 }}

class tpl_ctrl_type1 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }
    state = {
        moduleData:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    handleTxt = e => {
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content.title = val;
        this.context.updateDesign(moduleData)
    }
    handleStyle = e => {
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content.alignStyle = val;
        this.context.updateDesign(moduleData)
    }
    handleBold = e => {
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content.fontBold = val;
        this.context.updateDesign(moduleData)
    }
    changeLink = e => {
        let moduleData = this.state.moduleData;
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    moduleData.content[i] = res[i];
                }
                this.context.updateDesign(moduleData)
            }
        })

        this.context.updateDesign(moduleData)
    }
    handleFontSize = e => {
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content.fontSize = val;
        this.context.updateDesign(moduleData)
    }
    handleTitleColor = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content.titleColor = hex;
        this.context.updateDesign(moduleData)

    }
    handleBgColor = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content.backgroundColor = hex;
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                    <div className={`ctrl-right-title`}>标题文本</div>
                    <Form  className={`cx-ctrl-panel`} >
                        <div className={`cx-ctrl-panel--title`}>基本设置</div>
                        <div className={`cx-ctrl-panel--box`}>
                            <FormItem label={`组件间距`}>
                                <div className={`cx-slider`}>
                                    <Slider
                                        min={0}
                                        max={20}
                                        value={moduleData.content.modulePadding}
                                        onChange={ this.changePadding }
                                        className={`cx-slider-main`}
                                    ></Slider>
                                    <InputNumber
                                        min={0}
                                        max={20}
                                        style={{ width:60 }}
                                        value={moduleData.content.modulePadding}
                                        onChange={ this.changePadding }
                                    />
                                </div>
                            </FormItem>
                            <FormItem label={`文本内容`}>
                                <TextArea rows={3} placeholder={'请输入文本内容'} value={moduleData.content.title} onChange={this.handleTxt}  />
                            </FormItem>
                            <FormItem label={`文本样式`}>
                                <Radio.Group value={moduleData.content.alignStyle} onChange={ this.handleStyle }>
                                    <Radio value={0}>居中</Radio>
                                    <Radio value={1}>居左</Radio>
                                    <Radio value={2}>居右</Radio>
                                </Radio.Group>
                            </FormItem>
                            <FormItem label={`链接到`} { ...ctrlItemLayout }>
                                <Input type={`text`} value={moduleData.content.link} onClick={ this.changeLink } placeholder={`请选择`}  />
                            </FormItem>
                            <FormItem label={`字体大小`} { ...ctrlItemLayout }>
                                <Input type={`number`} value={moduleData.content.fontSize} onChange={ this.handleFontSize } min={12} />
                            </FormItem>
                            <FormItem label={`字体颜色`} { ...ctrlItemLayout }>
                                <ColorPicker onRef={e => { this.ColorPicker = e }} color={moduleData.content.titleColor} callback={ this.handleTitleColor } ></ColorPicker>
                            </FormItem>
                            <FormItem label={`背景颜色`} { ...ctrlItemLayout }>
                                <ColorPicker onRef={e => { this.ColorPicker1 = e }} color={moduleData.content.backgroundColor} callback={ this.handleBgColor } ></ColorPicker>
                            </FormItem>
                            <FormItem label={`字体加粗`}  { ...ctrlItemLayout }>
                                <Radio.Group value={moduleData.content.fontBold || 0} onChange={ this.handleBold }>
                                    <Radio value={0}>不加粗</Radio>
                                    <Radio value={1}>加粗</Radio>
                                </Radio.Group>
                            </FormItem>


                        </div>


                    </Form>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type1)

