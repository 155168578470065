import React from 'react'
import { Form, Input, Button, Select, Table, Pagination } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
const FormItem = Form.Item;

class UserDetail extends React.Component{
    state = {
        columns:[
            {
                title:'单据编号',
                dataIndex:'id',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.id || ''}</p>
                    )
                }
            },
            {
                title:'出入库时间',
                dataIndex:'logo',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <img src={rowData.logo} width={80} alt=""/>
                        </div>
                    )
                }
            },
            {
                title:'业务编号',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'商品名',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.address}</p>
                    )
                }
            },
            {
                title:'出入库类型',
                dataIndex:'stock1',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.address}</p>
                    )
                }
            },
            {
                title:'出入库数量',
                dataIndex:'stock2',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.address}</p>
                    )
                }
            },
            {
                title:'操作人员',
                dataIndex:'stock3',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.address}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"default"} size={"small"} style={{width:'80px'}} onClick={() => {this.handleEdit(rowData)}}>查看详情</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            id:'',
            name:''
        },
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10

    }

    componentDidMount() {
        //this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data,
                        total = res.data.store.total,
                        per_page = res.data.store.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/store/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleEdit = rowData => {
        // this.props.history.push({
        //     pathname:`/store/add/${rowData.id}`
        // })
    }
    handleStatus = () => {
        message.info('敬请期待')
    }
    handleShare = () => {
        message.info('敬请期待')
    }


    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'商品名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='请输入商品名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem label={'单据编号'} className={'mgl20 fl'}>
                            {
                                getFieldDecorator('id',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='请输入sku编码'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新建入库</Button>
                    <Button type={'default'} onClick={this.handleAdd} icon={'plus'} className={`mgl10`}>新建出库</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default Form.create({})(withRouter(UserDetail))
