import React, { Component } from 'react'
import { Divider, Button, Tag, Tabs, Select, Table, Pagination, Modal, message,Radio,Input } from 'antd';
import { withRouter, Link } from 'react-router-dom'
import { gradeTableStudent, gradeSign,gradeAddStudent } from '@/api/educational'
import GradeStudentAdd from './components/gradeStudentAdd';
import Loading from '@/components/Loading';
import '@/assets/css/educational/educational.scss';
const { Option } = Select;
const { TabPane } = Tabs;

class SignIn extends Component {

    state = {
        columns: [
            {
                title: '学员',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return <p>{rowData.student.real_name || "-"}{rowData.is_normal==2?<Tag className='mgl5' color="gold">串</Tag>:null}</p>
                }
            },
            {
                title: '学员手机号',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData.student.parent_mobile || "-"}</p>
                }
            },
            {
                title: '剩余课时',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return <p>{rowData.student.course_num}</p>
                }
            },
            {
                title: '课程有效期',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return <p>{"-"}</p>
                }
            },
            {
                title: '扣除课时',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return <p>{rowData.course_cost_num}</p>
                }
            },
            {
                title: '签到状态',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    let tag
                    switch (rowData.sign_status) {
                        case 0:
                            tag = <Tag color="red">待签到</Tag>
                            break;
                        case 1:
                            tag = <Tag color="green">已签到</Tag>
                            break;
                        case 2:
                            tag = <Tag color="gold">已请假</Tag>
                            break;
                    }
                    return <div>{tag}</div>

                }
            },
            {
                title: '签到时间',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return <p>{rowData.sign_time || "-"}</p>
                }
            },
            {
                title: '操作',
                dataIndex: '8',
                render: (text, rowData, index) => {
                    let opts
                    switch (rowData.sign_status) {
                        case 0:
                            opts = (<div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleSignIn(rowData) }}>签到</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleLeave(rowData) }}>请假</Button>
                            </div>)
                            break;
                        default:
                            opts = <div className={'btn-row'}><Button type={"link"} size={"small"} onClick={()=>{this.handleSignStatus(rowData)}}>更改状态</Button></div>
                            break;
                    }

                    return opts
                }
            },
        ],
        activeKey: "1",
        isLoading: false,
        tableData: [],
        grade: {},
        course_table: {},
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        status: 0,
        selectedRowKeys: [],
        visible: false,
        student_id: "",
        sign_type:1,
        is_add_student: false,
        student_name:''
    }

    componentDidMount() {
        if (!this.props.match.params.id) {
            this.props.history.goBack()
        }

        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData.course_table_id = this.props.match.params.id
        sendData.student_name=this.state.student_name
        sendData.status = this.state.status
        sendData.per_page = this.state.per_page
        sendData.page = this.state.page
        this.setState({
            isLoading: true
        })
        gradeTableStudent(sendData)
            .then(res => {
                if (res.code == 1) {
                    let { course_table, grade, list } = res.data
                    this.setState({
                        course_table,
                        grade,
                        tableData: list.data,
                        total: list.total,
                        per_page: list.per_page
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }

    handleSignIn = row => {
        Modal.confirm({
            title: `确认该学员已来上课？确定后将扣除${this.state.course_table.course_cost_num}课时`,
            onOk: () => {
                this.handleSign([row.student_id], 1)
            },
        });
    }
    handleLeave = row => {
        Modal.confirm({
            title: ` 确定该学员已请假？确认后不扣课时。`,
            onOk: () => {
                this.handleSign([row.student_id], 2)
            }
        });
    }
    handleSignStatus=row=>{
        
        this.setState({
            sign_type:row.sign_status,
            student_id:row.student_id,
            visible:true
        })
    }

    handleBatchLeave = () => {
        let selectedRowKeys = this.state.selectedRowKeys
        Modal.confirm({
            title: ` 确定选中学员已请假？确认后不扣课时。`,
            onOk: () => {
                this.handleSign([...selectedRowKeys], 2)
            }
        });
    }

    handleBatchSign = () => {
        let selectedRowKeys = this.state.selectedRowKeys

        Modal.confirm({
            title: `确认选中学员已来上课？确定后将扣除${this.state.course_table.course_cost_num}课时`,
            onOk: () => {
                this.handleSign([...selectedRowKeys], 1)
            },
        });
    }

    handleSign = (ids, type) => {
        let sendData = {}
        sendData.course_table_id = this.props.match.params.id
        sendData.type = type
        sendData.student_ids = ids
        this.setState({
            isLoading: true
        })
        gradeSign(sendData).then(res => {
            if (res.code == 1) {
                message.success(res.msg)
                this.fetchData()
            }

        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    changeTabs = key => {
        this.setState({
            activeKey: key
        })
    }

    handleChange = v => {
        this.setState({
            status: v
        })
    }
    goBack = () => {
        window.sessionStorage.setItem("sign-page",true)
        this.props.history.goBack()
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    handleOk = () => {
        let student_id=this.state.student_id,type=this.state.sign_type
        this.handleSign([student_id], type)
        this.setState({
            visible: false
        })
    }
    onChangeSignType=e=>{
        this.setState({
            sign_type: e.target.value,
          });
    }
    closeStudentList = () => {
        this.setState({
            is_add_student: false
        })
    }
    handleAdd = () => {
        this.setState({
            is_add_student: true
        })
    }

    handleAddStudent = arr => {
        if (arr.length === 0) {
            return this.closeStudentList()
        }

        let sendData = {}
        sendData.grade_id = this.state.grade?.id;
        sendData.student_ids = arr;
        sendData.is_normal=2;
        gradeAddStudent(sendData).then(res => {
            if (res.code == 1) {
                message.success("学员添加成功")
                this.fetchData()
            }
            this.setState({
                is_add_student: false
            })
        })
    }

    changeStudentName=(e)=>{
        let value=e.target.value;
        this.setState({
            student_name:value
        })
    }

    render() {
        let grade = this.state.grade, course_table = this.state.course_table

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            }
        }

        return (
            <div className={'shadow-radius'}>
                <div className={'grade-head'} >
                    <div className='grade-title mgb30'>
                        <div>
                            <span className='head-title'>{grade.name}</span>
                            <Tag color={grade.status == 2 ? '#5E4CFF' : grade.status == 1 ? "#FF0000" : "#9D9D9D"} className='head-tag'>{grade.status == 2 ? '开班中' : grade.status == 1 ? "待开班" : "已结班"}</Tag>
                        </div>
                        <div>
                            < Button onClick={this.goBack}>返回</Button>
                           
                        </div>
                    </div>
                    <Divider />
                    <div className='grade-head-content'>
                        <div className='head-line'>
                            <div className="head-item">课程类目：{grade.course_category && grade.course_category.name}</div>
                            <div className="head-item">上课时间：{`${course_table.table_date}   ${course_table.start_time}-${course_table.end_time}`}</div>
                            <div className="head-item">老师/助教：{course_table.teacher?course_table.teacher.name:"-"}</div>
                            <div className="head-item">扣课时数：{course_table.course_cost_num}</div>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey={this.state.activeKey} onChange={this.changeTabs} className={"mgt15"}>
                    <TabPane tab="班级学员" key="1">
                        <div className={' mgb15'}>
                            <span>签到状态:</span>
                            <Select value={this.state.status} style={{ width: 120 }} className={"mgl15"} onChange={this.handleChange}>
                                <Option value={0}>全部</Option>
                                <Option value={1}>待签到</Option>
                                <Option value={2}>已签到</Option>
                                <Option value={3}>请假</Option>
                            </Select>
                            <Input placeholder="学员姓名" value={this.state.student_name} onChange={this.changeStudentName}  style={{ width: 200 }} className='mgl20' />
                            <Button type={'primary'} className={"mgl20"} onClick={this.fetchData} >查询</Button>
                            < Button type='default' className='mgl20' onClick={this.handleAdd}>添加串课学员</Button>
                        </div>
                        <div className={'content-body mgt15'}>
                            <Table
                                size={`middle`}
                                rowSelection={rowSelection}
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                loading={this.state.tableLoading}
                                rowKey={record => record.student_id}
                                pagination={false}
                            ></Table>
                        </div>
                        <div className={'clearfix mgt10'}>
                            <div className={'fr'}>
                                {
                                    this.state.tableData.length
                                        ?
                                        <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                        :
                                        ''
                                }
                            </div>
                            {
                                this.state.tableData.length ?
                                    <div className={'fl'}>
                                        <Button onClick={this.handleBatchSign} >批量签到</Button>
                                        <Button className={"mgl20"} onClick={this.handleBatchLeave}>批量请假</Button>
                                    </div> : null
                            }

                        </div>


                    </TabPane>

                </Tabs>

                <Modal
                    title="变更签到状态"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div>
                        <span>签到状态：</span>
                        <Radio.Group onChange={this.onChangeSignType} value={this.state.sign_type}>
                            <Radio value={1}>签到</Radio>
                            <Radio value={2}>请假</Radio>
                        </Radio.Group>
                    </div>
                </Modal>

                {this.state.is_add_student ? <GradeStudentAdd close={this.closeStudentList} success={this.handleAddStudent} /> : null}

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default withRouter(SignIn)
