import $axios from '@/axios/axios.js'

export function saveInfo(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/complete-info/save`,
        method:'post',
        data
    })
}

export function getInfo(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/complete-info/detail`,
        method:'post',
        data
    })
}
