import React from 'react'
import { Form, Button, Table } from 'antd'
import { withRouter, Link } from 'react-router-dom'
const FormItem = Form.Item

class PointCoupon extends React.Component{
    state = {
        columns:[
            {
                title: '券名称',
                dataIndex: 'name',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '券类型',
                dataIndex: 'coupon_type',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => {this.handleDelItem(rowData, index)}}
                                disabled={!!this.props.match.params.id}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ]
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    handleDelItem = (rowData,index) => {
        this.props.onDel && this.props.onDel(rowData,index)
    }

    render() {
        let data = this.props.item || []
        return(
            <div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={ data }
                    rowKey={record => record.id}
                    style={{ width:'600px' }}
                    pagination={false}
                ></Table>
            </div>
        )
    }

}

export default Form.create({})(withRouter(PointCoupon))
