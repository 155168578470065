import $axios from '@/axios/axios.js'

export function getAllMenu(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/role/menu`,
        method:'post',
        data
    })
}

export function roleSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/role/save`,
        method:'post',
        data
    })
}

export function roleDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/role/detail`,
        method:'post',
        data
    })
}

export function roleDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/role/delete`,
        method:'post',
        data
    })
}

export function roleList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/role`,
        method:'post',
        data
    })
}

export function staffList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/staff`,
        method:'post',
        data
    })
}


export function staffSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/staff/save`,
        method:'post',
        data
    })
}


export function staffDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/staff/delete`,
        method:'post',
        data
    })
}   


