import React, { Component } from 'react'
import { Calendar, Badge, Button } from 'antd';
import { withRouter, Link } from 'react-router-dom'
import CX from '@/utils/CX';
import moment from 'moment';
import { liveLists } from '@/api/livestream'

class LiveStreamCalendar extends Component {
    state = {
        liveData: {},
        liveDate: moment(),
        currentMonth: moment().format('YYYY-MM')
    }

    componentDidMount() {
        let month = moment().format('YYYY-MM');
        this.fetchData(month)
    }

    fetchData = (month) => {
        liveLists({ month }).then(res => {
            if (res.code == 1) {
                let live_times = res.data.live_times;
                this.setState({
                    liveData: live_times
                })
            }
        })
    }

    onPanelChange = (value, mode) => {
        if (mode === 'month') {
            let date = value.format('YYYY-MM');
            this.fetchData(date)
        }
    }

    handleDetail=id=>{
        this.props.history.push({
            pathname:'/livestream/calendar-detail/'+id
        })
    }

    handleEdit=id=>{
        this.props.history.push({
            pathname:'/livestream/calendar-add/'+id
        })
    }

   

    dateCellRender = (value) => {

        let date = value.format('YYYY-MM-DD');
        let liveData = this.state.liveData;
        let liveArr = liveData[date]

        if (Array.isArray(liveArr)) {
            return (
                <div>
                    {
                        liveArr.map((val, index) => {
                            let color = CX.color16();
                            return (
                                <div className='flex mgb5' key={index + ''} >
                                    <Badge color={color} />
                                    <div className='flex1'>
                                        <div className='flex justify-between fz14'>
                                            <span className='flex text_link' style={{ color: color}} onClick={()=>{this.handleDetail(val.id)}} >{val.title}</span>
                                            <span className='text_link' onClick={()=>{this.handleEdit(val.id)}} >编辑</span>
                                        </div>
                                        <div className='fz12'>开播时间：{val.time}</div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            )
        }
        return null
    }




    handleAdd = () => {
        this.props.history.push({
            pathname: '/livestream/calendar-add'
        })
    }

    render() {
        return (
            <div className={`shadow-radius`}>
                <div>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增直播日历</Button>
                </div>
                <Calendar onPanelChange={this.onPanelChange} dateCellRender={this.dateCellRender}   />
            </div>
        )
    }
}

export default withRouter(LiveStreamCalendar)
