import $axios from '@/axios/axios.js'

export function socialGroupSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/save`,
        method:'post',
        data
    })
}

export function socialGroupLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group`,
        method:'post',
        data
    })
}

export function socialGroupDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/detail`,
        method:'post',
        data
    })
}

export function socialGroupEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/end`,
        method:'post',
        data
    })
}

export function socialGroupDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/delete`,
        method:'post',
        data
    })
}

export function socialGroupSetField(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/setField`,
        method:'post',
        data
    })
}

export function socialGroupSelectGoods(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/selectGoods`,
        method:'post',
        data
    })
}

export function socialGroupGroupData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/groupData`,
        method:'post',
        data
    })
}

export function exportGroupData(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/exportGroupData`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function socialSelectLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/social-group/selectSocialGroup`,
        method:'post',
        data
    })
}