import React, { Component } from 'react'
import { Form, Input, Button, Table, Modal, Pagination ,Tag} from 'antd'
import { selectCourseApi } from '@/api/course'
import { marketItemLists } from '@/api/item'
import message from '@/utils/message'


class ActivityGoodsModal extends Component {
    state = {
        tableData: [],
        modalLoading: false,
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        name: "",
        is_goods: true,
        visible: false,
        selectedRows: [],
        callBack:null
    }

    fetchData = () => {
        let sendData = {};
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.name = this.state.name;
        sendData.status = 1
        this.setState({
            tableLoading: true
        })

        if (this.state.is_goods) {
            marketItemLists(sendData)
                .then(res => {
                    if (res.code == 1) {
                        let lists = res.data.goods_list.data,
                            total = res.data.goods_list.total,
                            per_page = res.data.goods_list.per_page;

                        this.setState({
                            tableData: lists,
                            total, per_page
                        })
                    }
                }).finally(() => {
                    this.setState({
                        tableLoading: false
                    })
                })
        } else {
            selectCourseApi(sendData)
                .then(res => {
                    if (res.code == 1) {
                        let lists = res.data.list.data,
                            total = res.data.list.total,
                            per_page = res.data.list.per_page;

                        this.setState({
                            tableData: lists,
                            total, per_page
                        })
                    }
                }).finally(() => {
                    this.setState({
                        tableLoading: false
                    })
                })
        }

    }

    show=(bool,callBack)=>{
        this.setState({
            callBack:callBack,
            visible:true,
            is_goods:bool,
            selectedRows:[]
        },()=>{
            this.fetchData()
        })
    }

    saveData = () => {
        this.setState({
            visible:false,
            name:'',
            tableData:[],
            page:1
        })
        
        let callBack=this.state.callBack
        if(typeof callBack == 'function'){
            callBack(this.state.selectedRows)
        }

    }
    handleCancel = () => {
        this.setState({
            visible:false,
            name:'',
            tableData:[],
            page:1
        })

        let callBack=this.state.callBack
        if(typeof callBack == 'function'){
            callBack([])
        }
    }

    onChange = e => {
        this.setState({
            name: e.currentTarget.value
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const rowSelection = {
            type:'radio',
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRows
                })
            },
            getCheckboxProps:record=>({
                disabled:record.status === -1
            })
        }
        const { is_goods, visible } = this.state

        const columns=[
            {
                title:is_goods?'商品信息':'课程信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    let img_url
                    if(this.state.is_goods){
                        img_url=rowData.cover_img
                    }else{
                        img_url=rowData.pic_urls && rowData.pic_urls[0]
                    }

                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={img_url} alt="" width={60} height={60} />
                            <div className={`mgl10`}>
                                <p className={`fi-link w150`}>{rowData.name || ''}</p>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '零售价',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    
                    return (
                        <p>
                            {rowData.price }元
                        </p>
                    )
                }
            },
            {
                title: '销量',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.real_sales || rowData.sales || "-"}
                        </p>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return <Tag color='green'>上架中</Tag>
                }
            },
            {
                title: '活动状态',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    if(rowData.status == -1){
                        return <Tag color='orange'>活动中</Tag>
                    }
                    return <p>-</p>
                }
            },

        ]

        return (

            <Modal
                title={is_goods ? '商品信息' : '课程信息'}
                visible={visible}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={800}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <div>
                    <Input placeholder={`请输入${is_goods ? '商品' : '课程'}名称`} value={this.state.name} onChange={this.onChange} style={{ width: 200 }} />
                    <Button type="primary" className='mgl20' onClick={this.fetchData}>搜索</Button>
                </div>
                <div className={' mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ActivityGoodsModal