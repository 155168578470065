import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Form, Table, Tag, Pagination, Input, Select, DatePicker, Button, Tabs,Popover } from 'antd'
import { studentDetail ,studentAdd,studentChangePoints} from '@/api/student'
import Loading from '@/components/Loading'
import GradeTab from './gradeTab'
import PeriodTab from './periodTab'
import TimeTableTab from './timeTableTab'
import StudentAddGrade from './studentAddGrade'
import StudentAddCourseNum from './studentAddCourseNum'
import StudentAddCourse from './studentAddCourse'
import moment from 'moment'
import message from '@/utils/message';
import CoursesPurchased from './CoursesPurchased'
import BMITab from './bmiTab'
import './studentDetail.scss'

const FormItem = Form.Item;
const { RangePicker } = DatePicker
const { TextArea } = Input
const { TabPane } = Tabs;

class StudentDetail extends React.Component {
    state = {
        formData: '',
        course_data: '',
        isLoading: false,
        editForm: {
            second_name: false,
            sex: false,
            mobile: false,
            birthday: false,
            wechat_id: false,
        },

        tableData: [],
        storeLists: [],
        tableLoading: false,
        popVisible: false,
        page: 1,
        total: 0,
        per_page: 0,
        tabsKey:'1',
        isGrade:false,
        isCourseNum:false,
        isAddCourse:false,
        popVisible:false
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {}
        if (this.props.student_id) {
            sendData.id = this.props.student_id;
            this.setState({
                isLoading: true
            })
            studentDetail(sendData)
                .then(res => {
                    if (res.code == 1) {
                       
                        this.setState({
                            formData: res.data.detail,
                            course_data:res.data.course_data
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        }

    }

  
    handleInputChange = (e) => {
        let formData = this.state.formData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value;
        formData[name] = val;
        this.setState({
            formData
        })
    }
    handleSexChange = (val) => {
        let formData = this.state.formData,
            editForm = this.state.editForm,
            sendData = {};
        formData.sex = val;
        this.setState({
            formData
        }, () => {
            this.updateUser('sex')
        })
    }
    handleDayChange = (time) => {
        let formData = this.state.formData;
        formData.birthday = time ? moment(time).format('YYYY-MM-DD') : ''
        this.setState({
            formData
        }, () => {
            this.updateUser('birthday')
        })

    }
    updateUser = (name = '') => {
        let sendData = {},
            editForm = this.state.editForm,
            formData = this.state.formData;
        sendData = formData;
        editForm[name] = false
        this.setState({
            isLoading: true
        })
        studentAdd(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.setState({
                        editForm
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    handleEdit = (e) => {
        let editForm = this.state.editForm,
            name = e.currentTarget.dataset.name || '';
        editForm[name] = true;
        this.setState({
            editForm
        })
    }
  
    tabsChange=key=>{
        this.setState({
            tabsKey:key
        })
    }

    handleOpenGade=()=>{
        this.setState({
            isGrade:true
        })
    }
    handleOpenCourseNum=()=>{
        this.setState({
            isCourseNum:true
        })
    }
    handleOpenCourse=()=>{
        this.setState({
            isAddCourse:true
        })
    }
    closeGrade=()=>{
        this.setState({
            isGrade:false
        })
    }
    closeCourseNum=()=>{
        this.setState({
            isCourseNum:false
        })
    }
    closeCourse=()=>{
        this.setState({
            isAddCourse:false
        })
    }

    handlePoints = () => {
        this.setState({
            popVisible: true
        })
    }
    closePop = () => {
        let datas = {}
        datas[`change_points`] = '';
      
        this.props.form.setFieldsValue(datas)
        this.setState({
            popVisible: false
        })
    }
    handleSavePoints = () => {
        let sendData = {}
        sendData.student_id = this.props.student_id;
        this.setState({
            isLoading: true
        })
        this.props.form.validateFields((err, values) => {
            if (!err) {
                sendData.change_points = values.change_points;
               
                studentChangePoints(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功');
                            this.closePop();
                            this.fetchData()
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }

    render() {
        let data = this.state.formData || {},
            course_data = this.state.course_data || {};

        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 16
            }
        }

        const content = (
            <div style={{width:300}}>
                <Form {...formItemLayout} className={`model-form`}>
                    <FormItem label={`增减积分`} className={`mgb5`}>
                        {
                            getFieldDecorator('change_points', {
                                initialValue: '',
                            })(
                                <Input placeholder='请输入' />
                            )
                        }
                        <span className={`fi-help-text`}>请输入要增/减的积分数（-1代表减1积分）,学员中增加积分会同步增加到客户，扣减积分只作用于学员</span>
                    </FormItem>
                   
                    <FormItem label={` `} colon={false} className={`clearfix mgb5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={this.handleSavePoints}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={this.closePop}>取消</Button>
                    </FormItem>
                </Form>
            </div>
        )

        return (
            <div className={`drawer-cont`}>
                <div className={`msg-title`}></div>
                <div className={`flex justify-between align-center pd20`}>
                    <div className={`flex jusify-start align-center`}>
                        <img src={data.head_img_url} alt="" width={60} />
                        <div className={`mgl10`}>
                            <div className={`lh30`}>
                                {data.real_name || ''}
                                <i className={`icon-${data.sex == 1 ? 'male' : data.sex == 2 ? 'female' : ''}`}></i>
                            </div>
                        </div>
                    </div>
                    <div className={``}>
                        <Button type={`primary`} onClick={this.handleOpenCourse} >开课</Button>
                        <Button type={`primary`} className={"mgl15"} ghost onClick={this.handleOpenCourseNum}>调整课时</Button>
                        <Button type={`primary`} className={"mgl15"} ghost onClick={this.handleOpenGade}>添加班级</Button>
                    </div>
                </div>
                <div className={`msg-title`}></div>
                <div className={'user-content'}>
                    <div className={`user-content-wrapper`}>
                        <div className={`user-info-wrapper`}>
                            <div className={`user-card h100`}>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>学员基本信息</div>
                                    </div>
                                </div>
                                <div className={`user-card-body`}>
                                    <ul className={`user-info`}>

                                        <li className={`user-info-li`}>
                                            <div className={`left`}>学员手机号</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.parent_mobile
                                                        ?
                                                        <Input
                                                            value={data.parent_mobile}
                                                            placeholder={`填写学员手机号`}
                                                            style={{ width: '150px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`parent_mobile`}
                                                            onBlur={() => { this.updateUser('parent_mobile') }}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.parent_mobile || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="parent_mobile" ></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>备注名</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.remark_name
                                                        ?
                                                        <Input
                                                            value={data.remark_name}
                                                            placeholder={`填写备注名`}
                                                            style={{ width: '150px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`remark_name`}
                                                            onBlur={() => { this.updateUser('remark_name') }}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.remark_name || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="remark_name" ></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>家长昵称</div>
                                            <div className={`right`}>
                                               
                                                {
                                                     data.parent?
                                                     <div className={`right-txt`}> {data.parent.name}{data.parent.second_name ? `（${data.parent.second_name}）`:''}</div>:"-"
                                                }
                                               
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>性别</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.sex
                                                        ?
                                                        <Select
                                                            style={{ width: 150 }}
                                                            placeholder={'请选择'}
                                                            value={data.sex}
                                                            data-name={`sex`}
                                                            onChange={this.handleSexChange}
                                                        >
                                                            <Select.Option value={1}>男</Select.Option>
                                                            <Select.Option value={2}>女</Select.Option>
                                                        </Select>
                                                        :
                                                        <div className={`right-txt`}>{data.sex == 1 ? '男' :  '女' }</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="sex"></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>年龄</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{data.age || '-'}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>生日</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.birthday
                                                        ?
                                                        <DatePicker
                                                            onChange={this.handleDayChange}
                                                            style={{ width: 150 }}
                                                            value={data.birthday ? moment(data.birthday, 'YYYY-MM-DD') : null}
                                                        ></DatePicker>
                                                        :
                                                        <div className={`right-txt`}>{data.birthday || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="birthday"></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>身高（cm）</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.stature
                                                        ?
                                                        <Input
                                                            value={data.stature}
                                                            placeholder={`填写身高`}
                                                            style={{ width: '150px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`stature`}
                                                            onBlur={() => { this.updateUser('stature') }}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.stature || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="stature" ></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>体重（kg）</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.weight
                                                        ?
                                                        <Input
                                                            value={data.weight}
                                                            placeholder={`填写体重`}
                                                            style={{ width: '150px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`weight`}
                                                            onBlur={() => { this.updateUser('weight') }}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.weight || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="weight" ></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>积分</div>
                                            <div className={`right`}>
                                                <span>{data.points}</span>
                                                <Popover
                                                    content={content}
                                                    title={null}
                                                    placement={`top`}
                                                    trigger="click"
                                                    visible={this.state.popVisible}>
                                                    <div className={`icon-pencil mgl10`} onClick={ this.handlePoints } ></div>
                                                </Popover>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>家庭住址</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.address
                                                        ?
                                                        <Input
                                                            value={data.address}
                                                            placeholder={`填写家庭住址`}
                                                            style={{ width: '200px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`address`}
                                                            onBlur={() => { this.updateUser('address') }}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.address || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="address" ></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>所属校区</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{(data.store_info && data.store_info.name) || "-"}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>添加时间</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{data.created_at}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>课程顾问</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.course_adviser
                                                        ?
                                                        <Input
                                                            value={data.course_adviser}
                                                            placeholder={`填写课程顾问`}
                                                            style={{ width: '150px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`course_adviser`}
                                                            onBlur={() => { this.updateUser('course_adviser') }}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.course_adviser || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="course_adviser" ></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>备注</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.remark
                                                        ?
                                                        <Input
                                                            value={data.remark}
                                                            placeholder={``}
                                                            style={{ width: '150px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`remark`}
                                                            onBlur={() => { this.updateUser('remark') }}
                                                            maxLength={15}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.remark || '-'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="remark" ></div>
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className={`user-card-wrap`}>
                            <div className={`user-card`}>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>课程总览</div>
                                    </div>
                                </div>
                                <div className={`user-card-body inner`}>
                                    <div className={`overview-panel-wrapper`}>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>最近上课时间</div>
                                                <div className={`value tac`}>{course_data.latest_course_time || '-'}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>剩余课时数</div>
                                                <div className={`value tac`}>{course_data.last_course_num}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>已用课时</div>
                                                <div className={`value tac`}>{course_data.use_course_num}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>总课时数</div>
                                                <div className={`value tac`}>{course_data.total_course_num || 0}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>累计购课金额</div>
                                                <div className={`value tac`}>￥{course_data.total_buy_payment || 0}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className={`user-card  user-card-tabs mgt10`} >
                                <Tabs defaultActiveKey={this.state.tabsKey} onChange={this.tabsChange} >
                                    <TabPane tab={<span style={{paddingLeft:'8px'}}>已购课程</span>} key="1">
                                       <CoursesPurchased student_id={this.props.student_id} ></CoursesPurchased>
                                    </TabPane>
                                    <TabPane tab="班级" key="2" forceRender={true}>
                                       <GradeTab student_id={this.props.student_id} refGradeTab={e=>this.refGradeTab=e} ></GradeTab>
                                    </TabPane>
                                    <TabPane tab="课时明细" key="3">
                                       <PeriodTab student_id={this.props.student_id} ></PeriodTab>
                                    </TabPane>
                                    <TabPane tab="课表" key="4">
                                       <TimeTableTab student_id={this.props.student_id}  ></TimeTableTab>
                                    </TabPane>
                                    <TabPane tab="体测" key="5">
                                        <BMITab student_id={this.props.student_id} ></BMITab>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>

                </div>


                {this.state.isGrade?<StudentAddGrade onClose={this.closeGrade} student_id={this.props.student_id} onSuccess={this.refGradeTab.fetchData } />:null}
                {this.state.isCourseNum ?<StudentAddCourseNum close={this.closeCourseNum} student={this.state.formData} success={this.fetchData}  />:null}
                {this.state.isAddCourse?<StudentAddCourse close={this.closeCourse} student={this.state.formData} success={this.fetchData} ></StudentAddCourse>:null}

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }

}
export default Form.create({})(withRouter(StudentDetail))
