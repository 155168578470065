/**
 * 3D轮播图组件
 * @type 22
 * @return Object
 */
 import React from 'react'
 import { Form, Icon, Modal, Tooltip } from 'antd'
 import { ICON_FONT_URL } from '@/constants/iconFont'
 import PropTypes from "prop-types";
 const EditorIcon = Icon.createFromIconfontCN({
     scriptUrl: ICON_FONT_URL
 })
 
 class tpl_con_type22 extends React.Component {
     static contextTypes = {
         updateDesign: PropTypes.func,
         deleteModule: PropTypes.func
     }
     state = {
         moduleData: {}
     }
 
     componentDidMount() {
         this.props.onRef(this)
         let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
     componentWillReceiveProps(nextProps, nextContext) {
         let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
     handleEditModule = () => {
         let moduleData = this.state.moduleData;
         this.context.updateDesign(moduleData)
     }
     handleDeleteModule = () => {
         let moduleData = this.state.moduleData;
         Modal.confirm({
             title: `确定删除这个模块吗？`,
             okType: `danger`,
             onOk: () => {
                 this.context.deleteModule(moduleData)
             }
         })
     }
 
     render() {
         let moduleData = this.state.moduleData || {}
 
         return (
             Object.keys(moduleData).length
                 ?
                 <Tooltip placement={'left'} title={`图片`}>
                     <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{ paddingTop: `${moduleData.content.modulePadding}px`, paddingBottom: `${moduleData.content.modulePadding}px` }} onClick={this.handleEditModule}>
                         <div className="swiper-3d">
                             <div className="swiper-bg" style={{backgroundImage:`url(${moduleData.content.bg_img || "https://img.cxkoo.com/chengxuan/1/2022-12-15/c6f19b64ce9e6d3cc71d9477d425055f.jpg"})`}} >
                                <div className="swiper">
                                    {
                                        moduleData.content.dataset.slice(0,3).map((item, index) => (
                                            <div className={`swiper-item`} key={index} >
                                                <img src={item.pic || `https://img.cxkoo.com/chengxuan/1/2021-11-30/0f46fd1a85220b65d77e0590010f0bd5.png`} alt=""  />
                                            </div>
                                        ))
                                    }
                                </div>
                             </div>
                         </div>
                         <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={this.handleDeleteModule}></EditorIcon>
                     </div>
                 </Tooltip>
                 :
                 null
         )
     }
 }
 
 export default Form.create({})(tpl_con_type22)
 