import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, InputNumber, Radio, Pagination, Tooltip, Tag, TreeSelect, Drawer, Icon } from 'antd';
import { storeLists } from '@/api/store'
import AreaPicker from '@/components/AreaPicker/AreaPicker'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import moment from 'moment'
import { adminDetail } from '@/api/admin'
import { couponLists } from '@/api/coupon'
import Store from '@/redux/store.js'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class BaseForm extends Component {
    state = {
        storeLists: [],
        selectItems: [],
        couponLists: [],
        formData: {
            bg_color: '#FF2700',
            bg_img: "https://img.cxkoo.com/chengxuan/1/2022-07-15/68547b034762fd6b3fb5680febb2089b.png",
            share_success_num: 1,
            share_limit_num: 1
        },
        default_img: "https://img.cxkoo.com/chengxuan/1/2022-07-15/68547b034762fd6b3fb5680febb2089b.png",
        version_id: 0
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchStore()
        this.fetchCoupon()
        this.fetchAdmin()

    }

    componentWillReceiveProps(nextProps) {

        if ((nextProps.activityData != this.props.activityData) && this.props.is_edit) {

            let data = nextProps.activityData, selectItems = this.state.selectItems
            const { formData } = this.state

            formData.bg_color = data.bg_color
            formData.bg_img = data.bg_img
            formData.share_success_num = data.share_success_num
            formData.share_limit_num = data.share_limit_num

            selectItems = data.join_area || []

            this.setState({
                formData,
                selectItems
            })
        }
    }

    fetchCoupon = () => {
        let sendData = {
            page: 1,
            per_page: 100,
            status: 1
        };

        couponLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.coupon_list.data

                    lists = lists.filter(val => val.last_num > 0)
                    this.setState({
                        couponLists: lists
                    })

                }
            })

    }


    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }

    fetchAdmin = () => {

        let storeData = Store.getState()
        let shop = storeData.shop
        if (shop) {
            this.setState({
                version_id: shop.version_id
            })
        } else {
            adminDetail({})
                .then(res => {
                    if (res.code == 1) {
                        let shop = res.data.shop
                        this.setState({
                            version_id: shop.version_id
                        })
                    }
                })
        }

    }

    addFreeArea = () => {
        let selectItems = this.state.selectItems

        this.AreaPicker.show({
            selectItems: selectItems,
            is_default_select: true,
            callback: (res) => {

                if (res.length) {
                    let selectItems = res
                    this.setState({
                        selectItems
                    })
                }
            }
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
            this.props.updateData('bg_img', imgs[0])
        })
    }
    handleResetImg = (keyNmae) => {
        return () => {
            const formData = this.state.formData || {}
            formData[keyNmae] = this.state.default_img
            this.setState({
                formData
            })
            this.props.updateData('bg_img', this.state.default_img)
        }
    }
    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
        this.props.updateData('bg_img', this.state.default_img)
    }

    handleColorChange = (color, name) => {
        const formData = this.state.formData || {}
        formData[name] = color.hex
        this.setState({
            formData
        })

        this.props.updateData(name, color.hex)
    }

    handleNumberChange = name => {
        return val => {
            if (typeof val === 'number') {

                let formData = this.state.formData
                formData[name] = val
                this.setState({
                    formData
                })
            }
        }
    }

    getFormData = () => {
        return new Promise((reslove, reject) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const { formData, selectItems } = this.state
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                    values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''

                    if (values.suit_store_type == 2) {
                        values.suit_store_ids = values.suit_store_ids.join()
                    }

                    if (values.join_area_type == 2) {
                        values.join_area = selectItems
                    }

                    return reslove({ ...values, ...formData })
                } else {
                    return reslove(false)
                }
            })
        })
    }


    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 19
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const data = this.props.activityData || {}
        const formData = this.state.formData || {}
        const { is_edit } = this.props

        const radioSty = { display: "block", marginTop: "8px" }

        const desc_default =
            `1、直接微信分享或通过海报分享给新朋友，新朋友首次访问并授权完成，分享者将获得奖励，优惠券及课时数可在“我的—优惠券/课程中”查看，微信零钱将自动发放到微信钱包内。\n 2、每成功邀请1/n位新朋友都将获得奖励，在活动期内最多可获得1/n次奖励或奖励上不封顶。\n 3、同一个手机号/同一个微信号，满足任一条件均视为同一用户。
        `

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>基本信息</div>
                <FormItem label={'活动名称'}>
                    {getFieldDecorator('name', {
                        initialValue: data.name || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入活动名称'
                            }
                        ]
                    })(<Input
                        style={{ width: 400 }}
                        placeholder={'请输入活动名称'}
                        maxLength={20}
                        suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/20</span>}
                    />)}
                </FormItem>

                <FormItem label={'活动时间'}>
                    {getFieldDecorator('time', {
                        initialValue: data.time || null,
                        rules: [
                            {
                                required: true,
                                message: '请选择活动时间'
                            }
                        ]
                    })(
                        <RangePicker
                            style={{ width: 400 }}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder={['开始时间', '结束时间']}
                        />
                    )}
                </FormItem>

                <FormItem label={"分享人奖励"} >
                    {getFieldDecorator('prize_type', {
                        initialValue: data.prize_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择奖品类型'
                            }
                        ]
                    })(
                        <Radio.Group disabled={is_edit}>
                            <Radio value={1}>优惠券 </Radio>

                            {[5, 6, 7].includes(this.state.version_id) ? <Radio value={2}>课时数</Radio> : null}
                            <Radio value={3}>微信零钱 </Radio>
                            <Radio value={4}>积分 </Radio>
                        </Radio.Group>
                    )}
                </FormItem>


                {
                    getFieldValue("prize_type") == 1 ?
                        <FormItem label={"赠送优惠券"} >
                            {getFieldDecorator('coupon_id', {
                                initialValue: data.coupon_id || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择优惠券'
                                    }
                                ]
                            })(
                                <Select style={{ width: 400 }} placeholder={'请选择'}>
                                    {this.state.couponLists.map(val => {
                                        return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                    })}
                                </Select>
                            )}
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("prize_type") == 2 ?
                        <FormItem label={'赠送课时数'}>
                            {getFieldDecorator('course_num', {
                                initialValue: data.course_num || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '赠送课时数'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400 }}
                                    min={0}
                                    precision={0}
                                    placeholder={'请输入赠送课时数'}
                                />
                            )}
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("prize_type") == 3 ?
                        <FormItem label={'赠送微信零钱'}>
                            {getFieldDecorator('wallet_money', {
                                initialValue: data.wallet_money || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入赠送的微信零钱'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400, paddingRight: 100 }}
                                    min={1}
                                    precision={2}
                                    placeholder={'请输入赠送的微信零钱'}
                                />
                            )}
                            <span className='custom-input-suffix'>元</span>
                            <span className={'fi-help-text'}>请确保您开通了企业支付到零钱功能，且零钱金额不能小于1元</span>
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("prize_type") == 4 ?
                        <FormItem label={'赠送积分数'}>
                            {getFieldDecorator('points', {
                                initialValue: data.points || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '赠送积分数'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400 }}
                                    min={0}
                                    precision={0}
                                    placeholder={'请输入赠送积分数'}
                                />
                            )}
                        </FormItem>
                        : null
                }

                <FormItem label={'奖品发放数量'}>
                    {getFieldDecorator('total_num', {
                        initialValue: data.total_num || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入奖品数量'
                            }
                        ]
                    })(
                        <InputNumber
                            style={{ width: 400, paddingRight: 100 }}
                            min={data.total_num || 0}
                            precision={0}
                            placeholder={'请输入奖品数量'}

                        />
                    )}
                    <span className='custom-input-suffix'>个</span>
                    <span className={'fi-help-text'}>奖品数量必填，0表示0件奖品，如果奖品为优惠券，优惠券库存为0时将提示用户“奖品已领完”</span>
                </FormItem>

                <FormItem label={"分享人奖励规则"} >
                    {getFieldDecorator('share_rule', {
                        initialValue: data.share_rule || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择分享人奖励规则'
                            }
                        ]
                    })(
                        <Radio.Group disabled={is_edit}>
                            <Radio value={1}  >每成功邀请一位新用户，即发放奖励 </Radio>
                            <br />
                            <Radio value={2} style={radioSty}>每成功邀请 <InputNumber disabled={is_edit} onChange={this.handleNumberChange("share_success_num")} value={formData.share_success_num} style={{ width: 80 }} min={1} precision={0} /> 位新用户，即发放奖励</Radio>
                        </Radio.Group>
                    )}
                    <span className={'fi-help-text'}>被邀请的用户授权手机号成功，即为邀请成功，给分享者发放奖励。</span>
                </FormItem>

                <FormItem label={"分享人领奖上限"} >
                    {getFieldDecorator('share_prize_limit_type', {
                        initialValue: data.share_prize_limit_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择分享人领奖上限'
                            }
                        ]
                    })(
                        <Radio.Group disabled={is_edit}>
                            <Radio value={1} >不限 </Radio>
                            <br />
                            <Radio value={2} style={radioSty}>最多可获得 <InputNumber disabled={is_edit} onChange={this.handleNumberChange("share_limit_num")} value={formData.share_limit_num} style={{ width: 80 }} min={1} precision={0} /> 次奖励，超出后奖励将不予发放</Radio>
                        </Radio.Group>
                    )}
                </FormItem>


                <FormItem label={"新用户授权手机号奖励"} >
                    {getFieldDecorator('new_user_prize_type', {
                        initialValue: data.new_user_prize_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择奖品类型'
                            }
                        ]
                    })(
                        <Radio.Group disabled={is_edit}>
                            <Radio value={1}>优惠券 </Radio>

                            {[5, 6, 7].includes(this.state.version_id) ? <Radio value={2}>课时数</Radio> : null}
                            <Radio value={3}>积分 </Radio>
                            <Radio value={0}>无奖励 </Radio>
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("new_user_prize_type") == 1 ?
                        <FormItem label={"赠送优惠券"} >
                            {getFieldDecorator('new_user_coupon_id', {
                                initialValue: data.new_user_coupon_id || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择优惠券'
                                    }
                                ]
                            })(
                                <Select style={{ width: 400 }} placeholder={'请选择'}>
                                    {this.state.couponLists.map(val => {
                                        return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                    })}
                                </Select>
                            )}
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("new_user_prize_type") == 2 ?
                        <FormItem label={'赠送课时数'}>
                            {getFieldDecorator('new_user_course_num', {
                                initialValue: data.new_user_course_num || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '赠送课时数'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400 }}
                                    min={0}
                                    precision={0}
                                    placeholder={'请输入赠送课时数'}
                                />
                            )}
                        </FormItem>
                        : null
                }


                {
                    getFieldValue("new_user_prize_type") == 3 ?
                        <FormItem label={'赠送积分数'}>
                            {getFieldDecorator('new_user_points', {
                                initialValue: data.new_user_points || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '赠送积分数'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400 }}
                                    min={0}
                                    precision={0}
                                    placeholder={'请输入赠送积分数'}
                                />
                            )}
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("new_user_prize_type") != 0 ?
                        <FormItem label={'奖品发放数量'}>
                            {getFieldDecorator('new_user_total_num', {
                                initialValue: data.new_user_total_num || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入奖品数量'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400, paddingRight: 100 }}
                                    min={data.new_user_total_num || 0}
                                    precision={0}
                                    placeholder={'请输入奖品数量'}
                                />
                            )}
                            <span className='custom-input-suffix'>个</span>
                            <span className={'fi-help-text'}>奖品数量必填，0表示0件奖品，如果奖品为优惠券，优惠券库存为0时将提示用户“奖品已领完”</span>
                        </FormItem>
                        : null
                }

                <div className={`msg-title mgb15`}>参与条件</div>

                <FormItem label={"适用门店"} >
                    {getFieldDecorator('suit_store_type', {
                        initialValue: data.suit_store_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择适用门店'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}> 所有门店</Radio>
                            <Radio value={2}>指定门店可用 </Radio>
                            {
                                getFieldValue("suit_store_type") == 2 ? <span className='small-help-text'>已选择 {getFieldValue("suit_store_ids") && getFieldValue("suit_store_ids").length || 0} 家</span> : null
                            }
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("suit_store_type") == 2 ?
                        <FormItem label={' '} className={'no-colon'}>
                            {getFieldDecorator('suit_store_ids', {
                                initialValue: data.suit_store_ids || [],
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择适用门店'
                                    }
                                ]
                            })(
                                <Select
                                    style={{ width: 400 }}
                                    placeholder="请选择"
                                    mode="multiple"
                                    showArrow
                                >
                                    {
                                        this.state.storeLists.map(store => (
                                            <Select.Option value={`${store.id}`} key={store.id}>{store.name}</Select.Option>

                                        ))
                                    }
                                </Select>
                            )}

                        </FormItem>
                        : null
                }

                <FormItem label={"可参与地区"} >
                    {getFieldDecorator('join_area_type', {
                        initialValue: data.join_area_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择可参与地区'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}>所有地区 </Radio>
                            <Radio value={2}>部分地区</Radio>
                            {getFieldValue("join_area_type") == 2 ? <span className='text_link' onClick={this.addFreeArea}>{this.state.selectItems.length > 0 ? '查看设置' : '请选择地区'}</span> : null}
                        </Radio.Group>
                    )}
                </FormItem>

                <div className={`msg-title mgb15`}>展示设置</div>

                <FormItem label={`背景图`}>
                    <div className={'clearfix'}>
                        {
                            formData.bg_img
                                ?
                                <PictureCard
                                    onRef={e => { this.PictureCard = e }}
                                    imgs={formData.bg_img}
                                    onDel={e => { this.delImg(e, 'bg_img') }}
                                ></PictureCard>
                                :
                                <ImgAdd
                                    onRef={e => { this.ImgAdd = e }}
                                    onClick={e => { this.chooseImg(e, 'bg_img') }}
                                ></ImgAdd>
                        }
                        <Button type='link' className='mgt60' onClick={this.handleResetImg('bg_img')}>恢复默认</Button>
                    </div>
                    <span className={'fi-help-text'}>建议尺寸：750x1206像素，单张不超过2M</span>
                </FormItem>

                <FormItem label={`背景色`} >
                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={formData.bg_color} callback={(color) => { this.handleColorChange(color, 'bg_color') }} ></ColorPicker>
                    <Button type="link" onClick={() => this.handleColorChange({ hex: '#29CC8A' }, 'bg_color')}>重置</Button>
                </FormItem>

                <FormItem label={'活动说明'}>
                    {getFieldDecorator('desc', {
                        initialValue: data.desc || desc_default,

                    })(<Input.TextArea
                        style={{ width: 400 }}
                        placeholder={''}
                        rows={6}
                        maxLength={500}
                        suffix={<span>{getFieldValue("desc") ? getFieldValue("desc").length : 0}/500</span>}
                    />)}
                </FormItem>

                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button type='primary' onClick={() => this.props.updateTab('2')}>下一步</Button>
                </FormItem>

                <div className={'no-colon mgt60 pdb50'}> </div>

                <AreaPicker onRef={e => {
                    this.AreaPicker = e
                }}></AreaPicker>

                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
            </Form>
        )
    }
}

export default Form.create({})(BaseForm)
