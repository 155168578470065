/**
 * 3D轮播图组件
 * @type 22
 * @return Object
 */

 import React from 'react'
 import { Form, Slider, Input, Button, Icon, Modal, InputNumber} from 'antd'
 import PropTypes from "prop-types";
 import Gallery from '@/components/Gallery/Gallery'
 import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
 const FormItem = Form.Item
 const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 16 }}
 
 class tpl_ctrl_type22 extends React.Component{
     static contextTypes = {
         updateDesign:PropTypes.func
     }
 
     state = {
         moduleData:{}
     }
 
     componentDidMount() {
         this.props.onRef(this)
         let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
     componentWillReceiveProps(nextProps, nextContext) {
         let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
 
     changePadding = val => {
         let moduleData = this.state.moduleData;
         moduleData.content.modulePadding = val;
         this.context.updateDesign(moduleData)
     }
     changeSpacePadding = val => {
         let moduleData = this.state.moduleData;
         moduleData.content.spacePadding = val;
         this.context.updateDesign(moduleData)
     }
     chooseImg = (item,index) => {
         let moduleData = this.state.moduleData;
         this.Gallery.show(res => {
             if(res){
                 moduleData.content.dataset[index]['pic'] = res[0];
                 this.context.updateDesign(moduleData)
             }
         })
     }
     chooseBgImg=()=>{
        let moduleData = this.state.moduleData;
         this.Gallery.show(res => {
             if(res){
                 moduleData.content.bg_img = res[0];
                 this.context.updateDesign(moduleData)
             }
         })
     }
     delImg = (item,index) => {
         let moduleData = this.state.moduleData;
         Modal.confirm({
             title:`确定删除这张图片吗？`,
             okType:`danger`,
             onOk:() => {
                 moduleData.content.dataset.splice(index,1);
                 this.context.updateDesign(moduleData)
             }
         })
     }
     changeLink = (e,item,index) => {
         let moduleData = this.state.moduleData;
         this.PopSelectLink.show(res => {
             if(res){
                 for(var i in res){
                     moduleData.content.dataset[index][i] = res[i];
                 }
                 this.context.updateDesign(moduleData)
             }
         })
     }
     handleAdd = () => {
         let moduleData = this.state.moduleData,
             newData = { linkType:0, link:'', pic:'' };
         moduleData.content.dataset.push(newData);
         this.context.updateDesign(moduleData)
     }
     handleUp = (item,index) => {
         let moduleData = this.state.moduleData;
         if(index == 0){ return ; }
         let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
         moduleData.content.dataset.splice(index, 1);
         moduleData.content.dataset.splice(index - 1, 0, tmpdata);
         this.context.updateDesign(moduleData)
     }
     handleDown = (item,index) => {
         let moduleData = this.state.moduleData,
             len = moduleData.content.dataset.length;
         if(index == len - 1){ return ; }
         let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
         moduleData.content.dataset.splice(index, 1);
         moduleData.content.dataset.splice(index + 1, 0, tmpdata);
         this.context.updateDesign(moduleData)
     }
 
     render() {
         let moduleData = this.state.moduleData || {}
         return(
             Object.keys(moduleData).length
                 ?
                 <div>
                     <div className={`ctrl-right-title`}>3D轮播图</div>
                     <Form>
                         <div className={`cx-ctrl-panel`}>
                             <div className={`cx-ctrl-panel--title`}>基本设置</div>
                             <div className={`cx-ctrl-panel--box`}>
                                 <FormItem label={`组件间距`}>
                                     <div className={`cx-slider`}>
                                         <Slider
                                             min={0}
                                             max={20}
                                             value={moduleData.content.modulePadding}
                                             onChange={ this.changePadding }
                                             className={`cx-slider-main`}
                                         ></Slider>
                                         <InputNumber
                                             min={0}
                                             max={20}
                                             style={{ width:60 }}
                                             value={moduleData.content.modulePadding}
                                             onChange={ this.changePadding }
                                         />
                                     </div>
                                 </FormItem>
                                 <FormItem label={`选择背景图`} {...ctrlItemLayout} className="mgt20" >
                                    <div className={'clearfix'}>
                                        <div className={'img-add'} onClick={() => { this.chooseBgImg() }} >
                                            {
                                                moduleData.content.bg_img
                                                    ?
                                                    <div>
                                                        <img src={moduleData.content.bg_img} alt="" />
                                                    </div>
                                                    :
                                                    '+'
                                            }
                                        </div>
                                    </div>
                                </FormItem>
                             </div>
                         </div>
                         <div className={`cx-ctrl-panel`}>
                             <div className={`cx-ctrl-panel--title`}>
                                <p>添加图片</p>
                                <p className="fz13 mgt10">建议背景图宽高比例710x980，轮播图片宽高比例475x640,轮播图片最少3张</p>
                             </div>

                             <div className={`cx-ctrl-panel--box`}>
                                 {
                                     moduleData.content.dataset.map((item,index) => (
                                         <div className={`cx-img-list`} key={index}>
                                             <div className={`cx-img-item clearfix`}>
                                                 <FormItem label={`选择图片`} {...ctrlItemLayout}>
                                                     <div className={'clearfix'}>
                                                         <div className={'img-add'} onClick={() => { this.chooseImg(item,index) }} data-name="pic">
                                                             {
                                                                 item.pic
                                                                     ?
                                                                     <div>
                                                                         <img src={item.pic} alt=""/>
                                                                     </div>
                                                                     :
                                                                     '+'
                                                             }
                                                         </div>
                                                     </div>
                                                 </FormItem>
                                                 <FormItem label={`链接到`} {...ctrlItemLayout}>
                                                     <Input type={`text`} value={item.link} onClick={ (e) => { this.changeLink(e,item,index) } } placeholder={`请选择`}  />
                                                 </FormItem>
                                             </div>
                                             <div className="ctrl-item-list-actions">
                                                 <i className={`gicon-arrow-up`} onClick={ () => { this.handleUp(item,index) }}></i>
                                                 <i className={`gicon-arrow-down`} onClick={ () => { this.handleDown(item,index) }}></i>
                                             </div>
                                             {
                                                 index > 2
                                                     ?
                                                     <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delImg(item,index) }} data-name={'pic'}  />
                                                     :
                                                     null
                                             }
                                         </div>
                                     ))
                                 }
                                 <Button type={`primary`} className={`w100`} onClick={this.handleAdd} disabled={moduleData.content.dataset.length >= 8} >添加图片</Button>
                             </div>
                         </div>
 
                     </Form>
                     <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                     <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                 </div>
                 :
                 null
         )
     }
 }
 
 export default Form.create({})(tpl_ctrl_type22)
 