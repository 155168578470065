import React from 'react'
import { Modal, Alert, Upload, Icon, Button } from 'antd'
import Cookie from 'js-cookie'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import { itemImport } from '@/api/item'
const { Dragger } = Upload
const ACCEPT_FILE_NAMES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/wps-office.xlsx','text/csv','application/octet-stream','application/vnd.ms-excel']


class ItemUpload extends React.Component{
    state = {
        title:'导入商品',
        visible:false,
        modalLoading:false,
        downLoadTpl:'',
        GoodsFile:'',
        callback:null
    }
    componentDidMount() {
        this.props.onRef(this)
        let downLoadTpl = `${process.env.REACT_APP_BASE_API}/GoodsFile.xlsx`
        this.setState({
            downLoadTpl
        })

    }
    show = (callback) => {
        this.setState({
            visible:true,
            GoodsFile:'',
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleCancel = () => {
        this.close();
    }
    handleUpload = () => {
        let files  = this.state.GoodsFile,
            callback = this.state.callback;
        if(files) {
            let fd = new FormData()
            fd.append('GoodsFile', files);
            fd.append('api_token', Cookie.get('token') || ``)
            this.setState({
                modalLoading: true
            })
            itemImport(fd)
                .then(res => {
                    if (res.code == 1) {
                        message.success('操作成功')
                            .then(() => {
                                this.close();
                                if(typeof callback == 'function'){
                                    callback(res)
                                }
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        modalLoading: false,
                    })
                })
        }




    }

    render() {
        const props = {
            name: 'file',
            multiple: false,
            showUploadList:false,
            beforeUpload:(file) => {
                this.setState({
                    GoodsFile:''
                },() => { 
                    if(!ACCEPT_FILE_NAMES.includes(file.type)){
                        message.error('仅支持 xlsx 格式');
                        return ;
                    }
                    this.setState({
                        GoodsFile:file
                    })
                    return false;
                 })
                
            },
        }

        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={700}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
                okText={`导入商品`}
                okButtonProps={{ onClick:() => { this.handleUpload() } }}
            >
                <Alert message={
                    <div className={`clearfix`}>
                        请确保您的表格中包含模版中罗列的必填字段，否则可能会导致导入失败
                        <a href={ this.state.downLoadTpl } target={`_blank`}  className={`fi-link fr`} >立即下载</a>
                    </div>
                } type="info" showIcon />
                <div className={`mgt10`} style={{ height:'250px' }}>
                    <Dragger {...props} >
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        {
                            this.state.GoodsFile
                                ?
                                <p className="ant-upload-text">{ this.state.GoodsFile.name }</p>
                                :
                                <div>
                                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                    <p className="ant-upload-hint">
                                        文件格式仅支持xlsx，单个文件需小于10M
                                    </p>
                                </div>
                        }
                        <div className={`mgt30 txtCenter`}>
                            <Button type={`primary`} >上传文件</Button>
                        </div>
                    </Dragger>
                </div>
            </Modal>
        )
    }
}

export default ItemUpload
