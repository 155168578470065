import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class AddDesk extends React.Component{
    state = {
        title:'包厢/桌台',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                sendData = values;
                sendData.store_id = this.props.match.params.id || ``;
                this.setState({
                    modalLoading:true
                })
                if(this.props.data && this.props.data.id){
                    sendData.id = this.props.data.id
                    deskEdit(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('编辑成功')
                                    .then(() => {
                                        this.close()
                                        if(typeof callback == 'function'){
                                            callback()
                                        }
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                modalLoading:false
                            })
                        })
                }else {
                    deskAdd(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('添加成功')
                                    .then(() => {
                                        this.close()
                                        if(typeof callback == 'function'){
                                            callback()
                                        }
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                modalLoading:false
                            })
                        })
                }


            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        const roomLists = this.props.roomLists || []
        const data = this.props.data || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`包厢/桌台类型`}>
                        {
                            getFieldDecorator('room_id',{
                                initialValue: data.room_id,
                            })(
                                <Select placeholder={'请选择'}>
                                    {
                                        roomLists.map((item,index) => (
                                            <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    {
                        data.id
                            ?
                            <FormItem label={`包厢/桌号名称`}>
                                {
                                    getFieldDecorator('desk_name',{
                                        initialValue: data.desk_name || ``,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入名称'
                                            }
                                        ]
                                    })(
                                        <Input placeholder={`包厢桌号名称`} />
                                    )
                                }
                            </FormItem>
                            :
                            <FormItem label={`包厢/桌号名称`}>
                                {
                                    getFieldDecorator('desk',{
                                        initialValue: data.desk_name || ``,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入名称'
                                            }
                                        ]
                                    })(
                                        <TextArea placeholder={`包厢桌号名称`} rows={8}></TextArea>
                                    )
                                }
                                <span className={`fi-help-text`}>输入您需要添加的包厢/桌号，并通过回车进行分隔进行批量添加；数字、字母、汉字不限，不支持特殊字符</span>
                            </FormItem>
                    }

                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddDesk))
