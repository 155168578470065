import React from 'react'
import { Form, Input, Button, Table, Pagination, Tag, Modal, Divider } from 'antd'
import { withRouter } from 'react-router-dom'
import { miniProgramPath } from '@/api/miniProgram'
import { connect } from 'react-redux'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
import CX from '@/utils/CX'
const FormItem = Form.Item;
class MiniProgramPath extends React.Component{
    state = {
        columns:[
            {
                title:'标题',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'路径',
                dataIndex:'path',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.path}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleCopy(rowData.path)}}>复制</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleShare(rowData)}}>太阳码</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            id:'',
            name:''
        },
        storeLists:[],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow: null,
        poster:'',
        path:''

    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        this.setState({
            tableLoading:true
        })
        miniProgramPath(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list;
                    this.setState({
                        tableData:lists
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }
    handleShare = rowData => {
        let shop = this.props.shop || {},
            program = shop.program || {},
            app_id = program.app_id || '';
        this.setState({
            poster:`${window.location.origin}/sunQrcode?app_id=${app_id}&page=${rowData.path}`,
            path:`${rowData.path}`
        },() => {
            this.ShareModal.show()
        })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        let sendData = {}
        this.setState({
            currentRow:null
        },() => {
            this.AddShortLink.show((res) => {



            })
        })

    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={`content-msg mgb10`}>
                    <p>可以应用到公众号自定义菜单->链接到小程序</p>
                    <p>注意：带参数路径不能单独使用</p>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.path}
                        pagination={false}
                    ></Table>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
const mapStateToProps = state => state

export default Form.create({})(withRouter( connect(mapStateToProps)(MiniProgramPath) ))
