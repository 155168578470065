import React from 'react'

export default class LjwPromise {
    // 定义Promise状态变量，初始值为pending
    status = 'pending';
    data = '';
    onResolvedCallback = [];
    onRejectedCallback = [];
    constructor(executor){
        function resolve(value) {
            console.log(this)
            this.status = 'resolved';
            this.data = value;
            for( const func of this.onResolvedCallback){
                func(this.data)
            }
        }
        function reject(reason) {
            this.status = 'rejected'
            this.data = reason;
            for(const func of this.onRejectedCallback){
                func(this.data)
            }

        }

        try {
            console.log('executor',executor)
            executor(resolve, reject)
        }catch (e) {
            reject(e)
        }
    }
    then(onResolved, onRejected){
        onResolved = typeof onResolved === 'function' ? onResolved : function (v) { return v };
        onRejected = typeof onRejected === 'function' ? onRejected : function (e) { throw e };
        let promise2;
        promise2 = new Promise((resolve, reject) =>  {
            if(this.status == 'resolved'){
                setTimeout(() => {
                    try {
                        const x = onResolved(this.data)
                        this.resolvePromise(promise2, x, resolve, reject)
                    }catch (e) {
                        reject(e)
                    }
                },0)
            }
            if( this.status == 'rejected' ){

                setTimeout(() => {
                    try {
                        const x = onRejected(this.data)
                        this.resolvePromise(promise2, x, resolve, reject)
                    }catch (e) {
                        reject(e)
                    }
                },0)

            }
            if( this.status == 'pending' ){
                this.onResolvedCallback.push(() => {
                    setTimeout(() => {
                        try {
                            const x = onResolved(this.data)
                            this.resolvePromise(promise2, x, resolve, reject)
                        }catch (e) {
                            reject(e)
                        }
                    },0)
                })
                this.onRejectedCallback.push(() => {
                    setTimeout(() => {
                        try {
                            const x = onRejected(this.data)
                            this.resolvePromise(promise2, x, resolve, reject)
                        }catch (e) {
                            reject(e)
                        }
                    },0)
                })

            }

        })
        return promise2;
    }
    resolvePromise(promise2,x,resolve,reject){
        let called = false;
        if(promise2 == x){
            return reject(new TypeError(`Chaining cycle detected for promise!`))
        }
        if(x instanceof Promise){
            if(x.status === 'pending'){
                x.then( function (value) {
                    this.resolvePromise(promise2,value,resolve,reject)
                } )
            }else {
                x.then(resolve,reject)
            }
        }
    }



}

