import React from 'react'
import { Form, Input, Table, Select, Icon, Button, Checkbox, Tag } from 'antd'
import PropTypes from "prop-types";
import StorePicker from '@/components/StorePicker/StorePicker'
const FormItem = Form.Item

class ItemStorePrice extends React.Component{
    static contextTypes = {
        loading: PropTypes.object,
        setLoading: PropTypes.func
    }

    state = {
        columns:[
            {
                title:'门店划分',
                dataIndex:'id',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.isAll == 1
                                    ?
                                    <div className={`tag-cont`}>
                                        <Tag>其他门店</Tag>
                                    </div>
                                    :
                                    <div className={'tag-cont'}>
                                        {
                                            rowData.stores.map((store,storeIndex) => {
                                                return(
                                                    <Tag closable onClose={(e) => { this.delStore(e,index,storeIndex,store)}} key={storeIndex} >{store.name}</Tag>
                                                )
                                            })
                                        }
                                        <Button type={'default'} size={'small'} icon={'plus'} onClick={() => {this.addInLineStore(index)}}>添加</Button>
                                    </div>

                            }
                        </div>

                    )
                }
            },
            {
                title:() => {
                    return(
                        <div>
                            <span className={`ant-form-item-required`}>零售价</span>
                        </div>
                    )
                },
                dataIndex:'price',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                placeholder={'零售价'}
                                style={{ width:'150px' }}
                                value={rowData.price}
                                onChange={(e) => { this.getInputValue(e) }}
                                data-name={'price'}
                                data-index={index}
                                disabled={rowData.disableEdit}
                            />
                        </div>
                    )
                }
            },
            {
                title:'划线价',
                dataIndex:'market_price',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                placeholder={'划线价'}
                                style={{ width:'150px' }}
                                value={rowData.market_price}
                                onChange={(e) => { this.getInputValue(e) }}
                                data-name={'market_price'}
                                data-index={index}
                                disabled={rowData.disableEdit}
                            />

                        </div>
                    )
                }
            }
        ],
        storeLists:[],
        tableData:[]
    }

    componentDidMount() {
        this.props.onRef(this)
        this.initTableData() //初始化方法调用一次
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tableData = this.state.tableData || [];
        if(nextProps.item && tableData.length){
            let price = nextProps.item.price || ``,
                market_price = nextProps.item.market_price || ``;
            tableData[0]['price'] = price;
            tableData[0]['market_price'] = market_price;
            this.setState({
                tableData
            })
        }
    }

    initTableData = () => {
        let tableData = [],
            storeLists = [],
            defaultData = { isAll:1,stores:[],price:'',market_price :'',disableEdit:true};  //其他门店
        if(this.props.item){
            let initItemData = this.props.item
            defaultData.price = initItemData.price || ``;
            defaultData.market_price = initItemData.market_price || ``;
            tableData.push(defaultData);
            if(initItemData.store_price){
                tableData = tableData.concat(initItemData.store_price)
                for(var i in initItemData.store_price){
                    storeLists = storeLists.concat(initItemData.store_price[i].stores)
                }
            }
        }else {
            tableData.push(defaultData);
        }
        this.setState({
            tableData,
            storeLists
        })


    }
    renderTableTitle = () => {
        return(
            <div>
                <span className={`batch-setting-title`}>批量设置</span>
                <a className={`btn-batch-setting`}>零售价</a>
            </div>
        )
    }
    renderTableFooter = () => {
        return(
            <div>
                <Button type={'default'} size={'small'} icon={'plus'} onClick={this.addStore}>添加门店</Button>
            </div>
        )
    }
    addStore = () => {
        this.StorePicker.show(stores => {
            if(stores){
                let tableData = this.state.tableData,
                    storeLists = this.state.storeLists;
                //去重
                for(var i in storeLists){
                    for(var j in stores){
                        if(stores[j]['id'] == storeLists[i]['id']){
                            stores.splice(j,1)
                        }
                    }
                }
                let storePrice = {
                    stores:stores.map(store => { return { id:store.id, name :store.name} }),
                    price:'',
                    market_price:''
                }
                if(storePrice.stores.length){
                    tableData.push(storePrice);
                    storeLists = storeLists.concat(stores)
                    this.setState({
                        tableData,
                        storeLists
                    })
                }
            }
        })
    }
    addInLineStore = (rowIndex) => {
        this.StorePicker.show(stores => {
            if(stores){
                let tableData = this.state.tableData,
                    storeLists = this.state.storeLists;
                //去重
                for(var i in storeLists){
                    for(var j in stores){
                        if(stores[j]['id'] == storeLists[i]['id']){
                            stores.splice(j,1)
                        }
                    }
                }
                let storePrice = {
                    stores:stores.map(store => { return { id:store.id, name :store.name} }),
                }
                if(storePrice.stores.length){
                    tableData[rowIndex].stores = tableData[rowIndex].stores.concat(storePrice.stores)
                    storeLists = storeLists.concat(stores)
                    this.setState({
                        tableData,
                        storeLists
                    })
                }
            }
        })

    }
    delStore = (e,rowIndex,storeIndex,store) => {
        e.preventDefault();
        let tableData = this.state.tableData,
            storeLists = this.state.storeLists;
         tableData[rowIndex]['stores'].splice(storeIndex,1);
        for(var i in storeLists){
            if(storeLists[i]['id'] == store.id){
                storeLists.splice(i,1)
            }
        }
        if(tableData[rowIndex]['stores'].length < 1){
            tableData.splice(rowIndex,1)
        }
        this.setState({
            tableData,
            storeLists
        })
    }
    getInputValue = e => {
        let value = e.target.value,
            name = e.currentTarget.dataset.name || ``,
            index = e.currentTarget.dataset.index || ``,
            tableData = this.state.tableData;
        tableData[index][name] = value;
        this.setState({
            tableData
        })
    }
    getData = () => {
        let tableData = this.state.tableData
        if(tableData.length && tableData.length > 1){
            return tableData.slice(1)
        }else {
            return []
        }
    }

    render() {
        return(
            <div>
                <StorePicker onRef={e => {this.StorePicker = e}} multiple={true} shouldChooseDisable={true} selectIds={this.state.storeLists.map(item => {return Number(item.id)})} ></StorePicker>
                <FormItem label={`门店定价`} required={true}>
                    <div>
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            bordered
                            rowKey={(record,index) => index}
                            pagination={false}
                            footer={this.renderTableFooter}
                        />
                    </div>
                </FormItem>
            </div>
        )
    }

}

export default Form.create({})(ItemStorePrice)
