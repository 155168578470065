import React from 'react'
import { Form, Input, Badge, Button, Table, Alert, Modal, Col, Row, Icon } from 'antd'
import { priceDetail, bookComboList, savePrice } from '@/api/book'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import message from '@/utils/message'
import CX from '@/utils/CX'
import AddTimeMeal from './add-time-meal'
import CopyTime from './copy-time'
import '@/assets/css/ktv/index.scss'
const FormItem = Form.Item;
class KtvPriceManage extends React.Component{
    static contextTypes = {
        setLoading: PropTypes.func
    }
    state = {
        columns:[],
        tableData:[],
        week_data:[],
        price_data:[],
        currentWeek:'',
        currentPriceData:'',
        comboLists:[],
        showEdit:false
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchCombo(() => {
            this.fetchData()
        })
    }
    fetchCombo = (callback) => {
        let sendData = {}
        sendData.store_id = this.props.currentStore.id || ''
        this.context.setLoading(true)
        bookComboList(sendData)
            .then((res) => {
                if(res.code == 1){
                    let comboLists = res.data.combo_list
                    this.setState({
                        comboLists
                    },() => {
                        if(typeof callback == 'function'){
                            callback()
                        }
                    })
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })

    }
    fetchData = () => {
        let sendData = {}
        sendData.store_id = this.props.currentStore.id || ''
        this.context.setLoading(true)
        priceDetail(sendData)
            .then(res => {
                let formData = res.data,
                    week_data = formData.week_data,
                    price_data = formData.price_data,
                    currentWeek = this.state.currentWeek,
                    comboLists = this.state.comboLists;
                if(currentWeek){
                    let currentId = currentWeek.id || ``;
                    currentWeek= '';
                    week_data.forEach((item,index) => {
                        item.is_choose = 0;
                        if(item.id == currentId){
                            item.is_choose = 1;
                            currentWeek = item
                        }
                    })
                }else {
                    week_data.forEach((item,index) => {
                        item.is_choose = 0;
                    })
                    week_data[0]['is_choose'] = 1;
                    currentWeek = week_data[0]
                }
                price_data.forEach((week,weekIndex) => {
                    week.room_data && week.room_data.forEach((room,roomIndex) => {
                        room.time_data && room.time_data.forEach((time,timeIndex) => {
                            if(!time.combo_list.length && comboLists.length){
                                time.combo_list.push({ id:comboLists[0]['id'],name:comboLists[0]['name'],price:''  })
                            }
                        })
                    })
                })

                if(!currentWeek){
                    week_data[0]['is_choose'] = 1;
                    currentWeek = week_data[0]
                }
                this.setState({
                    week_data,price_data,currentWeek
                },() => {
                    this.handleCurrentPrice()
                })
            })
            .finally(() => {
                this.context.setLoading(false)
            })
    }
    renderWeek = () => {
        let week_data = this.state.week_data || [];
        return(
            <div className={`ktv-btn-group mgb15`}>
                {
                    week_data.map((item,index) => (
                        <div className={`ktv-btn`} key={index}>
                            <Button
                                type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                onClick={ () => { this.handleWeek(item,index) } }
                            >
                                { item.time_name }
                            </Button>
                        </div>
                    ))
                }
            </div>
        )

    }
    handleWeek = (rowData,index) => {
        let week_data = this.state.week_data || [],
            currentWeek = '';
        for(var i in week_data){
            week_data[i]['is_choose'] = 0
            if(week_data[i]['id'] == rowData.id){
                week_data[i]['is_choose'] = 1
                currentWeek = week_data[i]
            }
        }
        this.setState({
            week_data,
            currentWeek
        },() => {
            this.handleCurrentPrice()
        })

    }
    handleCurrentPrice = () => {
        let currentWeek = this.state.currentWeek,
            price_data = this.state.price_data,
            currentPriceData = ''
        for(var i in price_data){
            if(price_data[i]['id'] == currentWeek.id){
                currentPriceData = price_data[i]
                this.setState({
                    currentPriceData
                },() => {
                    this.handleTableData()
                })
            }
        }
    }
    handlePriceChange = (e,rowData,index,room_id) => {
        let val = e.target.value,
            currentPriceData = this.state.currentPriceData,
            room_data = currentPriceData.room_data;
        room_data.forEach((room,roomIndex) => {
            if(room.id == room_id){
                room.time_data && room.time_data.forEach((time,timeIndex) => {
                    if(time.id == rowData.time_id){
                        time.combo_list && time.combo_list.forEach((combo,comboIndex) => {
                            if(combo.id == rowData.id){
                                combo.price = val
                            }
                        })
                    }
                })
            }
        })

        this.setState({
            currentPriceData
        },() => {
            this.handleTableData()
        })
    }
    handleDurationChange = (e,rowData,index) => {
        let val = e.target.value,
            currentPriceData = this.state.currentPriceData,
            room_data = currentPriceData.room_data;
        room_data.forEach((room,roomIndex) => {
            room.time_data && room.time_data.forEach((time,timeIndex) => {
                if(time.id == rowData.time_id){
                    time.duration = val;
                }
            })
        })
        this.setState({
            currentPriceData
        },() => {
            this.handleTableData()
        })

    }
    handleComboChange = (val,rowData,index) => {
        let currentPriceData = this.state.currentPriceData,
            comboLists = this.state.comboLists,
            newCombo = '',
            room_data = currentPriceData.room_data;
        for(var i in comboLists){
            if(comboLists[i]['id'] == val){
                newCombo = comboLists[i]
            }
        }
        room_data.forEach((room,roomIndex) => {
            room.time_data && room.time_data.forEach((time,timeIndex) => {
                if(time.id == rowData.time_id){
                    time.combo_list && time.combo_list.forEach((combo,comboIndex) => {
                        if(combo.id == rowData.id && newCombo){
                            combo.id = newCombo.id;
                            combo.name = newCombo.name
                        }
                    })
                }
            })
        })
        this.setState({
            currentPriceData
        },() => {
            this.handleTableData()
        })
    }
    handleAddTimeCombo = (rowData,index) => {
        let currentPriceData = this.state.currentPriceData,
            disableIds = [],
            room_data = currentPriceData.room_data;
        room_data.forEach((room,roomIndex) => {
            room.time_data && room.time_data.forEach((time,timeIndex) => {
                if(time.id == rowData.time_id){
                    disableIds = time.combo_list.map( combo => { return combo.id } )
                }
            })
        })
        this.AddTimeMeal.show({
            disableIds:disableIds,
            callback:(res) => {
                let newCombo = {}
                newCombo.price = '';
                newCombo.id = res.id;
                newCombo.name = res.name;
                room_data.forEach((child,childKey) => {
                    child.time_data && child.time_data.forEach((time,timeIndex) => {
                        if(time.id == rowData.time_id){
                            time.combo_list.push(newCombo)
                        }
                    })
                })
                let tmp = JSON.stringify(room_data)
                //内存有污染，清空重新赋值
                currentPriceData.room_data = '';
                currentPriceData.room_data = JSON.parse(tmp)
                this.setState({
                    currentPriceData
                },() => {
                    this.handleTableData()
                })
            }
        })
    }
    handleDelMeal = (rowData,index) => {
        let currentPriceData = this.state.currentPriceData,
            room_data = currentPriceData.room_data;
        Modal.confirm({
            title:`确定删除这个套餐吗？未保存的数据可能会丢失`,
            okType:`danger`,
            onOk:() => {
                room_data.forEach((room,roomIndex) => {
                    room.time_data && room.time_data.forEach((time,timeIndex) => {
                        if(time.id == rowData.time_id){
                            time.combo_list && time.combo_list.forEach(( combo,comboIndex ) => {
                                if(combo.id == rowData.id){
                                    time.combo_list.splice(comboIndex,1)
                                }
                            })
                        }
                    })
                })
                this.setState({
                    currentPriceData
                },() => {
                    this.handleTableData()
                })

            }
        })

    }
    handleTableData = () => {
        let currentPriceData = this.state.currentPriceData || ''
        if(currentPriceData){
            let room_data = currentPriceData.room_data,
                columns = [],
                tmp_price_arr = {},
                tableData = [];
            room_data.forEach(( room,roomIndex ) => {
                columns.push(
                    {
                        title:room.name,
                        dataIndex:room.id,
                        render:(text, rowData, index) => {
                            return (
                                <div className={`lh25`}>
                                    {
                                        this.state.showEdit
                                            ?
                                            <Input
                                                type={`text`}
                                                min={1}
                                                style={{ width:'80px' }}
                                                value={rowData[`price_${room.id}`]}
                                                className={`mgr5`}
                                                onChange={ (e) => { this.handlePriceChange(e,rowData,index,room.id) } }
                                            />
                                            :
                                            <span className={`mgr5`}>{ rowData[`price_${room.id}`] > 0 ? rowData[`price_${room.id}`] : '未设置' }</span>
                                    }
                                    {
                                        this.state.showEdit
                                            ?
                                            '元/间'
                                            :
                                            rowData[`price_${room.id}`] > 0  ?  '元/间' : ''
                                    }

                                </div>
                            )
                        }
                    }
                )
                room.time_data && room.time_data.forEach((time,timeIndex) => {
                    time.combo_list && time.combo_list.forEach((combo,comboIndex) => {
                        combo.start_time = time.start_time
                        combo.end_time = time.end_time;
                        combo.time_id = time.id;
                        combo.room_id = room.id;
                        combo.duration = time.duration
                        tmp_price_arr[`${room.id}_${time.id}_${combo.id}`] = combo.price
                    })
                    if(roomIndex == 0){
                        tableData = tableData.concat(time.combo_list)
                    }
                })
            })

            let thFirst = {
                    title: '时段',
                    width: 170,
                    dataIndex: 'timeRange',
                    fixed: 'left',
                    render:(text, rowData, index) => {
                        return {
                            children:<div className={`lh25`}><p>{`${rowData.start_time}-${rowData.end_time}`}</p></div>,
                            props:{
                                rowSpan:rowData.rowSpan
                            }
                        }
                    }
                },
                thSecond = {
                    title: '欢唱时长',
                    width: 170,
                    dataIndex: 'duration',
                    fixed: 'left',
                    render:(text, rowData, index) => {
                        return {
                            children: <div className={``}> { this.state.showEdit ? <Input type={`text`} style={{ width:'80px' }} value={rowData.duration} className={`mgr5`} onChange={ (e) => { this.handleDurationChange(e,rowData,index) } }  /> : <span className={`mgr5`}>{ rowData.duration }</span> }  小时</div>,
                            props:{
                                rowSpan:rowData.rowSpan
                            }
                        }
                    }
                },
                thThird = {
                    title: '套餐',
                    width: 200,
                    dataIndex: 'comboName',
                    render:(text, rowData, index) => {
                        return (
                            <div className={``}>
                                <div className={`txtCenter`}>
                                    <p className={`lh25`}>{ rowData.name }</p>
                                </div>
                                <div className={`txtCenter`}>
                                    {
                                        rowData.showAddMeal && this.state.showEdit
                                            ?
                                            <span className={`fi-link`} onClick={ () => { this.handleAddTimeCombo(rowData,index) } }>添加套餐</span>
                                            :
                                            null

                                    }
                                </div>
                            </div>
                        )}
                },
                thLast = { title:'操作', width:150, dataIndex:'action',fixed:'right',render:(text, rowData, index) => {return (<div className={`lh25`}><Button type={`link`} onClick={ () => { this.handleDelMeal(rowData,index) } } disabled={ rowData.disableDel }>删除</Button></div>)} };
            if(this.state.showEdit){
                columns = [ thFirst,thSecond,thThird,...columns,thLast ]
            }else {
                columns = [ thFirst,thSecond,thThird,...columns ]
            }
            tableData.forEach((child,childKey) => {
                room_data.forEach((sub,subKey) => {
                    let room_id = sub.id;
                    child[`price_${room_id}`] = tmp_price_arr[`${room_id}_${child.time_id}_${child.id}`]
                })
            })
            let tmpTableGroup = CX.groupBy(tableData,'time_id'),
                newTableData = []
            for(var i in tmpTableGroup){
                let group = tmpTableGroup[i];
                group.forEach((child,childKey) => {
                    child.rowSpan = 0;
                    if(childKey == 0){
                        child.rowSpan = group.length
                        child.showAddMeal = true
                    }
                    if(group.length > 1){
                        //可以删
                        child.disableDel = false
                    }else {
                        //不能删
                        child.disableDel = true
                    }
                    newTableData.push(child)
                })
            }
              //console.log('tmp_price_arr',tmp_price_arr)
            this.setState({
                tableData:newTableData,
                columns
            })


        }

    }
    handleSave = () => {
        let sendData = {},
            price_data = this.state.price_data,
            datas = [];
        sendData.store_id = this.props.currentStore.id || ''
        price_data.forEach((week,weekIndex) => {
            let parent = {}
            parent.id = week.id;
            parent.time_week = week.time_week;
            parent.price_data = []
            week.room_data && week.room_data.forEach((room,roomIndex) => {
                room.time_data && room.time_data.forEach((time,timeIndex) => {
                    time.combo_list && time.combo_list.forEach((combo,comboIndex) => {
                        let tmp = {}
                        tmp.key=`${week.id}_${room.id}_${time.id}`
                        tmp.duration = time.duration;
                        tmp.id = combo.id;
                        tmp.price = combo[`price`] || ''
                        parent.price_data.push(tmp)
                    })

                })
            })
            datas.push(parent)
        })
        sendData.price_data = datas;
        this.context.setLoading(true)
        savePrice(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('保存成功')
                        .then(() => {
                            this.setState({
                                showEdit:false
                            })
                            this.fetchData()
                        })
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })

    }
    handleCopy = () => {
        let price_data = this.state.price_data,
            currentPriceData = this.state.currentPriceData
        this.CopyTime.show(res => {
            if(res){
                let weeks = res.weeks;
                let room_data_copy = JSON.stringify(currentPriceData.room_data);
                price_data.forEach((week,weekIndex) => {
                    if(weeks.includes(week.id)){
                        week.room_data = '';
                        week.room_data = JSON.parse(room_data_copy)
                    }
                })
                message.success('复制成功')
                this.setState({
                    price_data
                })
            }
        })
    }
    showEdit = () => {
        this.setState({
            showEdit:true
        },() => {
            this.handleTableData()
        })
    }
    handleCancel = () => {
        this.setState({
            showEdit:false
        },() => {
            this.handleTableData()
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`mgt15`}>
                <AddTimeMeal onRef={ e => { this.AddTimeMeal = e } } comboLists={ this.state.comboLists }></AddTimeMeal>
                <CopyTime onRef={ e => { this.CopyTime = e } } weeks = { this.state.week_data } current={ this.state.currentWeek }></CopyTime>
                <div className={`fi-title mgb15 mgt30`}>价格表管理</div>
                <div className={`mgb15`}>
                    <Button type='default' onClick={() => this.showEdit()}>编辑价格表</Button>
                </div>
                <Alert message={`选择门店进行单独设置，设置后保存；每个时段至少需要填写一个房型售价，若某时段某房型下售价未填写，将不会在小程序端展示，每个时段下最多添加10个套餐。`} className={`mgb15`} type="warning" banner />
                <Row className={` mgb15`}>
                    <Col span={2} >
                        <Badge status="processing" text="已设置" />
                    </Col>
                    <Col span={2}>
                        <Badge status="default" text="未设置" />
                    </Col>
                </Row>
                { this.renderWeek() }
                <div className={`mgb15`}>
                    {
                        this.state.showEdit
                            ?
                            <Button type={`primary`} onClick={ this.handleCopy }>复制到其他日期</Button>
                            :
                            null
                    }
                </div>
                <div className={' mgt15'}>
                    <Table
                        style={{ width:'100%' }}
                        size={`middle`}
                        columns={ this.state.columns }
                        dataSource={ this.state.tableData }
                        rowKey={(record,index) => index }
                        scroll={{ x: 1600 }}
                        pagination={false}
                        bordered
                    ></Table>
                </div>
                {
                    this.state.showEdit
                        ?
                        <div className={`mgt15`}>
                            <Button type={'primary'} onClick={this.handleSave}>保存价格表</Button>
                            <Button type={'default'} onClick={this.handleCancel} className={`mgl10`}>取消</Button>
                        </div>
                        :
                        null
                }



            </div>
        )
    }

}
export default Form.create({})(withRouter(KtvPriceManage))
