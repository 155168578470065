import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, Tag, TreeSelect, Modal, Divider, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
// import { gradeApi,gradeDeleteApi} from '@/api/educational'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import { storeLists } from '@/api/store'


const { TreeNode } = TreeSelect;

const FormItem = Form.Item;

class EducationalClassroom extends React.Component {

    state = {
        selectRows: [],
        searchForm: {
            status: 0,
            name: ''
        },
        columns: [
            {
                title: '教室名称',
                dataIndex: 'name',
                
                render: (text, rowData, index) => {
                    return (
                        <div >
                            {rowData.name}
                        </div>
                    )
                }
            },
            {
                title: '可容纳人数',
                dataIndex: 'course_category',
            
                render: (text, rowData, index) => {
                    return (
                        <div>
                           {rowData.course_category && rowData.course_category.name}
                        </div>
                    )
                }
            },
            {
                title: '所属校区',
                dataIndex: 'store',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <span>{rowData.store && rowData.store.name }</span>
                        </div>
                    )
                }
            },
           
            {
                title: '操作',
                dataIndex: 'opts',
                width: '20%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.editGrade(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.delteGrade([rowData.id]) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        storeLists: [],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
      
       
    }

    componentDidMount() {
        this.fetchStore()
    //    this.fetchData()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }


   
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        // gradeApi(sendData)
        //     .then(res => {
                
        //         if (res.code == 1) {
        //             let lists = res.data.list.data,
        //                 total = res.data.list.total,
        //                 per_page = res.data.list.per_page;
                  
        //             this.setState({
        //                 tableData: lists,
        //                 total, per_page
        //             })
        //         }
        //     })
        //     .finally(() => {
        //         this.setState({
        //             tableLoading: false
        //         })
        //     })
    }

   
    delteGrade=ids=>{
        // Modal.confirm({
        //     title:`删除后，已分入的班级学员都会从班内剔除需重新分班，且后续的课程都将取消，是否确认删除？`,
        //     onOk:() => {
        //         this.setState({
        //             isLoading:true
        //         })
        //         gradeDeleteApi({ids})
        //             .then(res => {
        //                 if(res.code == 1){
        //                     message.success('操作成功')
        //                         .then(() => {
        //                             this.fetchData()
        //                         })
        //                 }
        //             })
        //             .finally(() => {
        //                 this.setState({
        //                     isLoading:false
        //                 })
        //             })
        //     }
        // })
    }

  
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = {
            name:"",
            status:0
        }
     
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }




    render() {
       
        const { getFieldDecorator } = this.props.form

        return (
            <div className={'shadow-radius'}>
               
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'教室名称'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入教室名称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'所属校区'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: ""
                                    })(
                                        <Select style={{ width: 200 }} placeholder={''}>
                                             {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                    
                        <div className={`clearfix`}>
                            <FormItem className={'mgl70 fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增教室</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
              
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }


}

export default Form.create({})(withRouter(EducationalClassroom))
