import React from 'react'
import Gallery from '@/components/Gallery/Gallery'


export default class Editor extends React.Component{
    state = {
        id: this.props.id || null,
        ueEditor: null
    }
    componentDidMount() {
        let UE = window.UE;
        let { id } = this.state;
        if(id){
            try{
                UE.delEditor(id)
            }catch (e) {

            }
            UE.registerUI('diyimg',(editor,uiName) => {
                var btn = new UE.ui.Button({
                    name:uiName, //按钮的名字
                    title:"添加图片",//提示
                    cssRules :'background-position: -380px 0;',
                    onclick(){
                        editor.showImgPicker((res) => {
                            for(var i=0;i<res.length;i++){
                                var img = new Image();
                                img.onload = function () {};
                                img.src = res[i];
                                editor.execCommand('inserthtml', '<div style="text-align: center"><img src="'+res[i]+'" style="max-width: 100%;display: block"></div>​');//向光标插入内容
                            }
                        })
                    }
                });
                return btn;
            },2);
            let ueEditor = UE.getEditor(id,{
                autoFloatEnabled:false
            })
            ueEditor.showImgPicker = (callback) => {
                this.Gallery.show((res) => {
                    callback(res);
                })
            }
            this.setState({ ueEditor });
            ueEditor.ready((editor) => {
                let value = this.props.value ? this.props.value : '<p style="margin: 0px"></p>';
                ueEditor.setContent(value)
            })
            ueEditor.addListener('selectionchange',() => {
               this.props.callback && this.props.callback(ueEditor.getContent());
            })

        }
    }
    render() {
        let { id } = this.state;
        return (
            <div>
                <Gallery onRef={(e) => {this.Gallery = e}} ></Gallery>
                <textarea  id={id} style={{ width: this.props.width, height: this.props.height }}></textarea>
            </div>
        )
    }


}
