import React, { Component } from 'react'
import { Tabs } from 'antd';
import GoodsGroup from './components/goods-group';
import LiveStreamCalendar from './components/calendar'
const { TabPane } = Tabs;

export default class LiveStrean extends Component {
    state={
        activeKey:'1'
    }
    render() {
        return (
            <div className={'shadow-radius'}>
                <Tabs activeKey={this.state.activeKey} onChange={(key)=>{this.setState({activeKey:key})}}>
                    <TabPane tab="直播日历管理" key="1">
                       <LiveStreamCalendar></LiveStreamCalendar>
                    </TabPane>
                    <TabPane tab="直播商品分组管理" key="2">
                       <GoodsGroup></GoodsGroup>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
