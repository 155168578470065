import React, { Component } from 'react'
import { Form, Input, Radio, Button, Checkbox, message, Icon, Select } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Loading from '@/components/Loading'
import CX from '@/utils/CX'
import { physicalItem } from '@/api/educational'
import { physicalFormDetail, physicalFormSave } from '@/api/educational'


const FormItem = Form.Item

class BMIFormAdd extends Component {
    state = {
        isLoading: false,
        physicalLists: [],
        formData: {},
        baseData: [
            {
                id: '1',
                name: '体测时间',
                is_default: true,
            },
            {
                id: '2',
                name: '任课教练',
                is_default: true,
            },
            {
                id: '3',
                name: '训练时间',
                is_default: true,
            },
            {
                id: '4',
                name: '身高',
                is_default: true,
            },
            {
                id: '5',
                name: '体重',
                is_default: true,
            },
            {
                id: '6',
                name: '身体质量指数BMI',
                is_default: true,
            },
        ],
        project_list: [{
            type: '',
            type_name: '',
            list: [
                {
                    name: ''
                },
                {
                    name: ''
                }
            ]
        }],
        other_project_list: [
            {
                name: ''
            }
        ]
    }

    componentDidMount() {
        this.fetchPhysicalItem();
        if (this.props.match.params.id) {
            this.fetchData()
        }
    }

    fetchPhysicalItem = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;

        physicalItem(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data

                    this.setState({
                        physicalLists: lists,
                    })
                }
            })

    }

    fetchData = () => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading: true
        })
        physicalFormDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let detail = res.data.detail
                    console.log(detail);
                    let {baseData,other_project_list,project_list}=this.state
                    detail.base_ids=detail.static_data.filter(val=>val.checked).map(val=>val.id);
                    baseData=detail.static_data
                    other_project_list=detail.other_item
                    project_list=detail.test_item
                    this.setState({
                        project_list,
                        baseData,
                        other_project_list,
                        formData:detail
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleAddBase = () => {
        let baseData = this.state.baseData;
        let id = CX.genUUID();

        baseData.push({
            id: id,
            name: '',
            is_default: false,
        })
        this.setState({
            baseData
        }, () => {
            console.log(this.state.baseData);
        })
    }

    changeBaseInput = index => {
        return e => {
            let value = e.target.value;
            let baseData = this.state.baseData;
            baseData[index].name = value;
            this.setState({
                baseData
            })
        }
    }

    handleSelectProject = i => {
        return val => {
            console.log(val);
            let project_list = this.state.project_list;
            project_list[i].type = val.key

            if (typeof val.label === 'string') {
                project_list[i].type_name = val.label
            } else {
                project_list[i].type_name = val.label.join('')
            }


            this.setState({
                project_list
            })
        }
    }

    getInputValue = (e, i, j, type) => {
        let value = e.target.value

        if (type === 'other') {
            let other_project_list = this.state.other_project_list;
            other_project_list[j].name = value;
            this.setState({
                other_project_list
            })
            return;
        }



        let project_list = this.state.project_list;
        project_list[i].list[j].name = value
        this.setState({
            project_list
        })
    }

    handleAddText = (i, j, type) => {
        if (type === 'other') {
            let other_project_list = this.state.other_project_list;
            other_project_list.push({ name: '' })
            this.setState({
                other_project_list
            })
            return;
        }

        let project_list = this.state.project_list
        project_list[i].list.push({ name: '' })
        this.setState({
            project_list
        })
    }
    handleDeleText = (i, j, type) => {
        if (type === 'other') {
            let other_project_list = this.state.other_project_list;
            other_project_list.splice(j, 1)
            this.setState({
                other_project_list
            })
            return;
        }

        let project_list = this.state.project_list
        if (project_list[i].list.length > 1) {
            project_list[i].list.splice(j, 1)
        } else {
            project_list.splice(i, 1)
        }
        this.setState({
            project_list
        })
    }
    handleAddProject = () => {
        let project_list = this.state.project_list;
        project_list.push(
            {
                type: '',
                type_name: '',
                list: [
                    {
                        name: ''
                    },
                    {
                        name: ''
                    }
                ]
            }
        )
        this.setState({
            project_list
        })

    }

    saveData = () => {
        let formData = this.state.formData || {},num=0,
            { baseData, project_list, other_project_list } = this.state,
            sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData.name = values.name
                sendData.static_data = baseData.filter(val => val.name).map(val => {
                    val.checked = values.base_ids.includes(val.id);
                    return val
                })
                sendData.test_item = project_list.filter(val => {
                    return val.type && val.list.some(val => val.name)
                }).map(val => {
                    val.list = val.list.filter(val => val.name)
                    num =num +val.list.length;
                    return val
                })
                sendData.other_item = other_project_list.filter(val => val.name);
                sendData.item_num=num+sendData.other_item.length;
                sendData.status = 1;
                console.log(sendData);

                this.setState({
                    isLoading: true
                })
                if (this.props.match.params.id) {
                    sendData.id = this.props.match.params.id
                }
                physicalFormSave(sendData).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                            .then(() => {
                                this.props.history.push({
                                    pathname: `/educational/bmi`
                                })
                            })
                    }
                })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })
    }


    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        const radioSty = { display: "block", marginBottom: "8px" }
        return (
            <div className={`shadow-radius`}>
                <div className={'mgt10'}>


                    <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                        <div className={`msg-title mgb15`}>基本信息</div>
                        <FormItem label={'表单名称'}>
                            {getFieldDecorator('name', {
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入表单名称'
                                    }
                                ]
                            })(<Input
                                style={{ width: 300 }}
                                placeholder={''}
                                maxLength={60}
                            />)}

                        </FormItem>

                        <FormItem label={'静态数据'}>
                            {
                                getFieldDecorator('base_ids', {
                                    initialValue: data.base_ids || ['1', '2', '3', '4', '5', '6'],
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择所属角色'
                                        }
                                    ]
                                })(
                                    <Checkbox.Group
                                    >
                                        {
                                            this.state.baseData.map((val, index) => {
                                                if (val.is_default) {
                                                    return (
                                                        <Checkbox value={val.id} key={val.id}  >{val.name}</Checkbox>
                                                    )
                                                }
                                                return (
                                                    <Checkbox className='mgb20' value={val.id} key={val.id}   >
                                                        <Input style={{ width: 200 }} defaultValue={val.name} onChange={this.changeBaseInput(index)} />
                                                    </Checkbox>
                                                )

                                            })
                                        }
                                    </Checkbox.Group>
                                )
                            }
                        </FormItem>
                        <FormItem label={` `} colon={false}>
                            <Button type={'primary'} onClick={this.handleAddBase} icon={'plus'}>自定义数据</Button>

                        </FormItem>

                        <div className={`msg-title mgb15`}>测试项目</div>

                        {
                            this.state.project_list.map((v, i) => {
                                return (
                                    <div className="syllabus-wrap" key={i}>
                                        <div className="syllabus-label">
                                            <Select onChange={this.handleSelectProject(i)} labelInValue placeholder='请选择项目属性' style={{ width: '150px' }} value={{ key: v.type  }}>

                                                {
                                                    this.state.physicalLists.map(val => {
                                                        return (
                                                            <Select.Option value={val.id }>{val.name} </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>

                                        </div>
                                        <div className="syllabus-content">
                                            {
                                                v.list.map((text, j) => {
                                                    return (
                                                        <div key={j}>
                                                            <Input
                                                                placeholder={'请输入具体测试的项目名称'}
                                                                style={{ width: '300px', marginBottom: "10px" }}
                                                                value={text.name}
                                                                onChange={(e) => { this.getInputValue(e, i, j) }}

                                                            />
                                                            {
                                                                j === 0 ? <Icon type="plus-circle-o" className="syllabus-icon" onClick={() => { this.handleAddText(i, j) }} /> : null
                                                            }

                                                            <Icon type="minus-circle-o" className="syllabus-icon" onClick={() => { this.handleDeleText(i, j) }} />
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>

                                )
                            })
                        }
                        <div className={'mgt10 mgb15'}>
                            <Button type={'primary'} ghost onClick={this.handleAddProject} icon={'plus'}>添加项目属性</Button>
                        </div>

                        <div className={`msg-title mgb15`}>其他测试项目</div>

                        <div className="syllabus-wrap" >
                            <div className="syllabus-label">
                            </div>
                            <div className="syllabus-content">
                                {
                                    this.state.other_project_list.map((text, j) => {
                                        return (
                                            <div key={j}>
                                                <Input
                                                    placeholder={'请输入具体测试的项目名称'}
                                                    style={{ width: '450px', marginBottom: "10px", maxWidth: '450px' }}
                                                    value={text.name}
                                                    onChange={(e) => { this.getInputValue(e, '', j, 'other') }}

                                                />

                                                <Icon type="minus-circle-o" className="syllabus-icon" onClick={() => { this.handleDeleText('', j, 'other') }} />
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div className={'mgt10 mgb15'}>
                            <Button type={'primary'} ghost onClick={() => { this.handleAddText('', '', 'other') }} icon={'plus'}>添加测试项目</Button>
                        </div>


                        <FormItem label={` `} colon={false}>
                            <Button type={'primary'} onClick={this.saveData}>保存</Button>

                        </FormItem>

                    </Form>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(BMIFormAdd))