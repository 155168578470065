import React, { Component } from 'react'
import { Table, Pagination, Button, Divider, Form, Select, Tag, message, DatePicker } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { studentBuyCourse } from '@/api/student'
import moment from 'moment'

const FormItem = Form.Item;
const { RangePicker } = DatePicker


class CoursesPurchased extends Component {
    state = {
        columns: [
            {
                title: <span className='mgl16'>商户单号</span>,
                dataIndex: 'order_sn',
                render: (text, rowData, index) => {
                    return (
                        <div className={`lh25 mgl16`}>{rowData.order_sn || '-'}</div>
                    )
                }
            },
            {
                title: '课程名称',
                dataIndex: 'name',
                width: '250px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            { rowData.course_info && rowData.course_info.name || '-'}
                        </div>
                    )
                }
            },
            {
                title: '内容',
                dataIndex: 'content',
                render: (text, rowData, index) => {
                    return (
                        <div>{ rowData.show_course || '-'}</div>
                    )
                }
            },
            {
                title: '报名校区',
                dataIndex: 'store',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.store_name || '-'}</div>
                    )
                }
            },
            {
                title: '下单时间',
                dataIndex: 'created_at',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.created_at || '-'}</div>
                    )
                }
            },
            {
                title: '订单金额',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p>&yen;{rowData.pay_ment}</p>
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Link to={`/order/detail/${rowData.order_sn}`} target={`_blank`} className={`fi-link w150`}>订单详情</Link>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 10,
        total: 0 
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {

        let sendData = {}
        this.props.form.validateFields((err, values) => {
            sendData = values;
            sendData.student_id = this.props.student_id;
            sendData.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
            sendData.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
            sendData.page = this.state.page;
            sendData.per_page = 10;
        })

        this.setState({
            tableLoading: true
        })
        studentBuyCourse(sendData).then(res => {

            if (res.code == 1) {
                let list = res.data.course_list;
                let total = list.total,
                    per_page = list.per_page,
                    tableData = list.data
                this.setState({
                    total, per_page, tableData
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    onSelect = val => {

        this.setState({
            status: val
        })
    }

    handleSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.fetchData()
        })
    }


    render() {
        const { getFieldDecorator } = this.props.form

        return (
            <div className='pdb20'>
                <Form className={`search-form mgt15`}>
                    <div className={`clearfix`}>
                        {/* <FormItem label={'课程类型'} className={'mgl15 fl'}>
                            {
                                getFieldDecorator('status', {
                                    initialValue: 0
                                })(
                                    <Select style={{ width: 150 }} placeholder={'请选择'} >
                                        <Select.Option value={0}>全部</Select.Option>
                                        <Select.Option value={1}>课时数</Select.Option>
                                        <Select.Option value={2}>班级</Select.Option>
                                        <Select.Option value={3}>有效期</Select.Option>
                                    </Select>
                                )
                            }
                        </FormItem> */}
                        <FormItem label={'下单时间'} className={'mgl24 fl'}>
                            {
                                getFieldDecorator('time', {
                                    initialValue: ''
                                })(
                                    <RangePicker style={{ width: 230 }} ></RangePicker>
                                )
                            }
                        </FormItem>
                        <FormItem label={''} className={'mgl15 fl'}>
                            <Button type={`primary`} onClick={this.handleSearch}>查询</Button>
                        </FormItem>

                    </div>
                    <div className={`clearfix`}>
                    </div>
                </Form>
                <div className={`user-card-body`}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                    <div className={'clearfix mgt10'}>
                        <div className={'fr'}>
                            {
                                this.state.tableData.length
                                    ?
                                    <Pagination current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(CoursesPurchased))


