import React from 'react'
import { Button, Table, Icon, Divider } from 'antd'
import { officialAccount, importFans, fansLists } from '@/api/miniProgram'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import Cookie from 'js-cookie'
import { withRouter } from 'react-router-dom'

class OfficialAccount extends React.Component{
    state = {
        columns:[
            {
                title:'APPID',
                dataIndex:'app_id',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.app_id || ''}</p>
                    )
                }
            },
            {
                title:'名称',
                dataIndex:'nick_name',
                width:'20%',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.nick_name || ''}</p>
                    )
                }
            },
            {
                title:'粉丝数量',
                dataIndex:'official_account_weixin_user_count',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.official_account_weixin_user_count || 0}</p>
                    )
                }
            },
            {
                title:'授权时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"}  onClick={() => {this.handleSync(rowData)}}>同步</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleFans(rowData)}}>查看粉丝</Button>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        showAuth:false,
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        this.setState({
            tableLoading:true
        })
        officialAccount(sendData)
            .then(res => {
                if(res.code == 1){
                    let officialAccounts = res.data.officialAccounts;
                    if(officialAccounts.length < 1){
                        this.setState({
                            showAuth:true
                        })
                    }
                    this.setState({
                        tableData:officialAccounts
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    handleSync = row => {
        let sendData = {}
        sendData.app_id = row.app_id;
        this.setState({
            tableLoading:true
        })
        importFans(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('粉丝导入中，请过会儿查看');
                    this.fetchData();
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    handleFans = row => {
        this.props.history.push({
            pathname:`/officialAccount/fans-lists`
        })
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        return(
            <div className={'shadow-radius'}>
                <div className={`content-msg`}>
                    <p>
                        功能介绍 ：授权公众号后，顾客可在微信内收到商家公众号的消息模板推送，并跳转回小程序进行引流。
                    </p>
                    <p>
                        购买商品、参与活动、办卡等各种场景下，顾客可收到微信模板消息提醒。
                        {/*<a className={'mgl5'}  target={'_blank'}>*/}
                            {/*<Icon type="question-circle" theme="filled" />*/}
                        {/*</a>*/}
                    </p>
                </div>
                {
                    this.state.showAuth
                        ?
                        <div className={'mgt10'}>
                            <a href={`/toAuth/${Cookie.get('token')}`} target={`_blank`}>
                                <Button type={'primary'}>立即授权</Button>
                            </a>
                        </div>
                        :
                        null
                }
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                { this.state.isLoading ? <Loading /> : null }

            </div>
        )
    }
}

export default withRouter(OfficialAccount)
