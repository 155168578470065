import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Table, Radio, Checkbox, Tag, message } from 'antd'
import ItemPicker from '@/components/ItemPicker/ItemPicker'
import TagsPicker from './TagsPicker';
import Loading from '@/components/Loading'
import moment from 'moment';
import { labelSaveLabel } from '@/api/user'
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

class ConditionTags extends Component {
    state = {
        columns: [
            {
                title: '商品信息',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60} />
                            <div className={`mgl10`}>
                                <p className={`lh25`}>{rowData.name || ''}</p>
                                <p className={`lh25`}>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '零售价',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <span className={`lh25`}>{rowData.price ? `${rowData.price}元` : `--`}</span>
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: 90,
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleGoodsDele(index) }}>删除</Button>
                        </div>
                    )
                }
            }

        ],
        formData: {
            points_min: 0,
            points_max: 0,
            trade_day: 3,
            trade_num_min: 0,
            trade_num_max: 0,
            trade_amount_min: 0,
            trade_amount_max: 0
        },
        create_user_time: [],
        buy_goods_time: [],
        goods_list: [],
        tags_list: [],
        tags_select_list: [],
        isLoading:false,
        detail:{}
    }

    componentDidMount(){
        if(this.props.status==3 && this.props.tag_item){
            let rule=this.props.tag_item.rule
            let detail={
                condition:rule.condition,
                user_opt:rule.user_opt,
                goods_opt:rule.goods_opt,
                points_opt:rule.points_opt,
                trade_opt:rule.trade_opt
            }
            let formData={
                points_min: rule.points.points_min,
                points_max: rule.points.points_max,
                trade_day: rule.trade.trade_day,
                trade_num_min: rule.trade.trade_num_min,
                trade_num_max: rule.trade.trade_num_max,
                trade_amount_min: rule.trade.trade_amount_min,
                trade_amount_max: rule.trade.trade_amount_max
            }

            let create_user_time=rule.user.create_user_time,
                buy_goods_time=rule.goods.buy_goods_time,
                goods_list=rule.goods.goods_list,
                tags_list=rule.tags_list

            this.setState({
                detail,
                formData,
                create_user_time,
                buy_goods_time,
                goods_list,
                tags_list
            },()=>{
                this.renderSelectTag()
            })


        }
    }

    handleChangeTime = (date, dateStr, name) => {
        this.setState({
            [name]: dateStr
        })
    }

    formatMoment = (arr) => {
        if (arr?.length == 0) {
            return []
        }
        arr = [moment(arr[0], 'YYYY-MM-DD HH:mm:ss'), moment(arr[1], 'YYYY-MM-DD HH:mm:ss')];
        return arr
    }

    handleFormChange = (val, name) => {
        let formData = this.state.formData
        formData[name] = val
        this.setState({
            formData
        })
    }

    addItem = (item, index) => {
        this.ItemPicker.show(res => {
            if (res.length) {

                this.setState({
                    goods_list: res
                })
            }
        })
    }

    handleGoodsDele = index => {
        let goods_list = this.state.goods_list
        goods_list.splice(index, 1)
        this.setState({
            goods_list
        })
    }

    AddTags = (item, index) => {
        this.TagsPicker.show(res => {
            if (res.length) {


                this.setState({
                    tags_list: res
                }, () => {
                    this.renderSelectTag()
                })
            }
        })
    }

    renderSelectTag = () => {
        let tags_select_list = [], tags_list = this.state.tags_list;
        tags_list.forEach((item) => {
            if (item.label_data) {
                item.label_data.forEach(val => {
                    tags_select_list.push(val)
                })
            }
        })
        this.setState({
            tags_select_list
        })
    }

    handleTagDele = (item) => {
        let tags_list = this.state.tags_list,
            index = tags_list.findIndex(val => val.group_id == item.group_id);
        if (index != -1) {
            tags_list.splice(index, 1)
            this.setState({
                tags_list
            }, () => {
                this.renderSelectTag()
            })
        }
    }

    saveData = () => {

        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                let sendData = {}, rule = {},desc={}
                let { formData, create_user_time, buy_goods_time, goods_list, tags_list ,tags_select_list} = this.state;

                rule = {
                    ...values,
                    tags_list
                }

                if (!tags_list.length) {
                    message.error('标签组为必填项！')
                    return
                }

                let label_ids=tags_select_list.map(item=>item.extra_id).join(',');
                rule.label_ids=label_ids;

                rule.user = {
                    create_user_time,
                    switch: values.user_opt.includes(1) ? 1 : 0
                }

                if(values.user_opt.includes(1)){
                    desc.user=[`成为客户的时间${create_user_time[0]}~${create_user_time[1]}`]
                }

                rule.goods = {
                    buy_goods_time,
                    goods_list,
                    switch: values.goods_opt.includes(1) ? 1 : 0
                }

                if(values.goods_opt.includes(1)){
                    let str=`在${buy_goods_time[0]}~${buy_goods_time[1]}购买过`+goods_list.map(val=>val.name).join('、');
                    desc.goods=[str]
                }

                rule.points = {
                    points_min: formData.points_min,
                    points_max: formData.points_max,
                    switch: values.points_opt.includes(1) ? 1 : 0
                }

                if(values.points_opt.includes(1)){
                    desc.points=[`累计获得积分${formData.points_min}分~${formData.points_max}分`]
                }


                rule.trade = {
                    trade_day: formData.trade_day,
                    trade_num_min: formData.trade_num_min,
                    trade_num_max: formData.trade_num_max,
                    trade_amount_min: formData.trade_amount_min,
                    trade_amount_max: formData.trade_amount_max,
                    switch_day:values.trade_opt.includes(1)?1:0,
                    switch_num:values.trade_opt.includes(2)?1:0 ,
                    switch_amount:values.trade_opt.includes(3)?1:0
                }

                if(values.trade_opt.includes(1)){
                    let str=`最后消费时间在${formData.trade_day}天内`;
                    desc.trade=[str]
                }

                if(values.trade_opt.includes(2)){
                    let str=`历史累计消费次数${formData.trade_num_min}次~${formData.trade_num_max}次`;
                    if(desc.trade){
                        desc.trade.push(str)
                    }else{
                        desc.trade=[str]
                    }
                }

                if(values.trade_opt.includes(3)){
                    let str=`历史累计消费金额${formData.trade_amount_min}元~${formData.trade_amount_max}元`;
                    if(desc.trade){
                        desc.trade.push(str)
                    }else{
                        desc.trade=[str]
                    }
                }

                rule.tags_list = tags_list
    
                sendData.rule = rule;
                sendData.group_name=tags_list.map(item=>item.group_name).join('-');
                sendData.desc=desc;


                if(this.props.status==3 && this.props.tag_item){
                    sendData.id=this.props.tag_item.id
                }

                this.setState({
                    isLoading:false
                })
                labelSaveLabel(sendData).then(res => {
                    this.setState({
                        isLoading:false
                    })
                    if (res.code == 1) {
                        message.success('保存成功！')
                        this.props.back?.()
                    }
                })
            }
        })
    }


    render() {

        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 19
            }
        }

        const data = this.state.detail || {}
        const formData = this.state.formData
        const { getFieldDecorator, getFieldValue } = this.props.form


        return (
            <div>
                <Form  {...formItemLayout} className={'model-form '}>
                    <FormItem label={"满足条件"} >
                        {getFieldDecorator('condition', {
                            initialValue: data.condition || 1,

                        })(
                            <Radio.Group  >
                                <Radio value={1}>满足任意一个被选中的条件即可</Radio>
                                <Radio value={2}>满足所有被选中的条件 </Radio>
                            </Radio.Group>
                        )}
                        <span className={'fi-help-text'}>标签组创建后新满足条件的客户会实时打标，标签创建前已满足条件的客户会在次日凌晨进行打标。</span>
                    </FormItem>

                    <FormItem label={"客户条件"} >
                        {getFieldDecorator('user_opt', {
                            initialValue: data.user_opt || [],

                        })(
                            <Checkbox.Group style={{ lineHeight: 'unset' }} >
                                <Checkbox value={1}>客户信息</Checkbox>

                                {
                                    getFieldValue("user_opt").includes(1) ?
                                        <div className='mgt10 pd20' style={{ background: '#F6F6F6', width: 700 }}>
                                            <div className="flex align-center">
                                                <span className='mgr20'>成为客户时间</span>
                                                <RangePicker
                                                    value={this.formatMoment(this.state.create_user_time)}
                                                    showTime={{ format: 'HH:mm:ss' }}
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    placeholder={['开始时间', '结束时间']}
                                                    onChange={(a, b) => { this.handleChangeTime(a, b, 'create_user_time') }}
                                                />
                                            </div>
                                        </div> : null
                                }

                            </Checkbox.Group>
                        )}
                    </FormItem>

                    <FormItem label={"商品条件"} >
                        {getFieldDecorator('goods_opt', {
                            initialValue: data.goods_opt || [],

                        })(
                            <Checkbox.Group style={{ lineHeight: 'unset' }} >
                                <Checkbox value={1}>历史时间购买过以下商品</Checkbox>

                                {
                                    getFieldValue("goods_opt").includes(1) ?
                                        <div className='mgt10 pd20' style={{ background: '#F6F6F6', width: 700 }}>
                                            <div className="flex align-center">
                                                <span className='mgr20'>购买时间</span>
                                                <RangePicker
                                                    value={this.formatMoment(this.state.buy_goods_time)}
                                                    showTime={{ format: 'HH:mm:ss' }}
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    placeholder={['开始时间', '结束时间']}
                                                    onChange={(a, b) => { this.handleChangeTime(a, b, 'buy_goods_time') }}
                                                />
                                            </div>
                                            <div className="flex mgt10">
                                                <span className='mgr20'>商品信息</span>
                                                <div style={{ width: 500 }}>
                                                    <span className='text_link' onClick={this.addItem}>请选择</span>
                                                    {
                                                        this.state.goods_list.length ?
                                                            <Table
                                                                size={`middle`}
                                                                columns={this.state.columns}
                                                                dataSource={this.state.goods_list}
                                                                rowKey={record => record.id}
                                                                pagination={false}
                                                            >
                                                            </Table> : null
                                                    }

                                                </div>
                                            </div>
                                        </div> : null
                                }

                            </Checkbox.Group>
                        )}
                    </FormItem>

                    <FormItem label={"积分条件"} >
                        {getFieldDecorator('points_opt', {
                            initialValue: data.points_opt || [],

                        })(
                            <Checkbox.Group style={{ lineHeight: 'unset' }} >
                                <Checkbox value={1}>历史累计获得积分</Checkbox>

                                {
                                    getFieldValue("points_opt").includes(1) ?
                                        <div className='mgt10 pd20' style={{ background: '#F6F6F6', width: 700 }}>
                                            <div className="flex align-center">
                                                <Input type='text' onChange={e => { this.handleFormChange(e.target.value, 'points_min') }} style={{ width: 100 }} value={formData.points_min} />
                                                <span className='mgl20 mgr20'>分 -</span>
                                                <Input type='text' onChange={e => { this.handleFormChange(e.target.value, 'points_max') }} style={{ width: 100 }} value={formData.points_max} />
                                                <span className='mgl20'>分</span>
                                            </div>
                                        </div> : null
                                }

                            </Checkbox.Group>
                        )}
                    </FormItem>

                    <FormItem label={"交易条件"} >
                        {getFieldDecorator('trade_opt', {
                            initialValue: data.trade_opt || [],

                        })(
                            <Checkbox.Group style={{ lineHeight: 'unset' }} >
                                <Checkbox value={1}>最后消费时间</Checkbox>
                                {
                                    getFieldValue("trade_opt").includes(1) ?
                                        <div className='mgt10 pd20' style={{ background: '#F6F6F6', width: 700 }}>
                                            <div className="flex align-center">
                                                最近
                                                <Select style={{ width: 120 }} className='mgl20 mgr20' onChange={e => { this.handleFormChange(e, 'trade_day') }} value={formData.trade_day}  >

                                                    {
                                                        [3, 7, 15, 30, 45, 60, 90, 180].map(val => {
                                                            return <Select.Option value={val} key={val} >{val}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                                天
                                            </div>
                                        </div> : null
                                }
                                <div></div>
                                <Checkbox value={2}>历史累计消费次数</Checkbox>
                                {
                                    getFieldValue("trade_opt").includes(2) ?
                                        <div className='mgt10 pd20' style={{ background: '#F6F6F6', width: 700 }}>
                                            <div className="flex align-center">
                                                <Input type='text' onChange={e => { this.handleFormChange(e.target.value, 'trade_num_min') }} style={{ width: 100 }} value={formData.trade_num_min} />
                                                <span className='mgl20 mgr20'>次 -</span>
                                                <Input type='text' onChange={e => { this.handleFormChange(e.target.value, 'trade_num_max') }} style={{ width: 100 }} value={formData.trade_num_max} />
                                                <span className='mgl20'>次</span>
                                            </div>
                                        </div> : null
                                }
                                <div></div>
                                <Checkbox value={3}>历史累计消费金额</Checkbox>
                                {
                                    getFieldValue("trade_opt").includes(3) ?
                                        <div className='mgt10 pd20' style={{ background: '#F6F6F6', width: 700 }}>
                                            <div className="flex align-center">
                                                <Input type='text' onChange={e => { this.handleFormChange(e.target.value, 'trade_amount_min') }} style={{ width: 100 }} value={formData.trade_amount_min} />
                                                <span className='mgl20 mgr20'>元 -</span>
                                                <Input type='text' onChange={e => { this.handleFormChange(e.target.value, 'trade_amount_max') }} style={{ width: 100 }} value={formData.trade_amount_max} />
                                                <span className='mgl20'>元</span>
                                            </div>
                                        </div> : null
                                }

                            </Checkbox.Group>
                        )}
                    </FormItem>

                    <FormItem label={"选择标签组"} required >
                        <Button type='default' onClick={this.AddTags}>选择标签组</Button>
                        {
                            this.state.tags_list.length ?
                                <div className='mgt10 pd20' style={{ background: '#F6F6F6', width: 700 }}>

                                    {
                                        this.state.tags_select_list.map(item => {
                                            return <Tag closable onClose={() => { this.handleTagDele(item) }}>{item.name}</Tag>
                                        })
                                    }
                                </div>
                                : null
                        }

                        <div className='mgt50'>
                            <Button type='primary' onClick={this.saveData}>确认</Button>
                            <Button type='default' className='mgl20' onClick={()=>{ this.props.back?.()}} >返回</Button>
                        </div>
                    </FormItem>
                </Form>

                <ItemPicker
                    onRef={e => { this.ItemPicker = e }}
                    selectIds={this.state.goods_list.length ? this.state.goods_list.map(item => { return Number(item.id) }) : []}
                    items={this.state.goods_list.length ? this.state.goods_list : []}
                    multiple={true}
                ></ItemPicker>

                <TagsPicker
                    onRef={e => { this.TagsPicker = e }}
                    selectIds={this.state.tags_list.length ? this.state.tags_list.map(item => { return Number(item.group_id) }) : []}
                    items={this.state.tags_list.length ? this.state.tags_list : []}
                    multiple={true}
                ></TagsPicker>

                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(ConditionTags)
