import React from 'react'
import { Table } from 'antd'

class CourtOrder extends React.Component{
    state = {
        columns:[
            {
                title:'预订信息',
                dataIndex:'code',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            <p className={`lh25`}>时间：{ rowData.book_info?.book_date || '-' }</p>
                            {
                                rowData.space_trade.space_use_type == 1
                                    ?
                                    <div>
                                        {
                                            rowData.book_info.book_time && rowData.book_info.book_time.length
                                                ?
                                                rowData.book_info.book_time.map((child,childKey) => (
                                                    <div className={`lh25`} key={childKey}>
                                                        <span>{ childKey == 0 ? '时段：' : <span>&emsp;&emsp;&emsp;</span> }</span>{ child }
                                                    </div>
                                                ))
                                                :
                                                null

                                        }
                                    </div>
                                    :
                                    rowData.space_trade.space_mix_info
                                        ?
                                        <div>
                                            <p>
                                                &emsp;&emsp;&emsp;{rowData.space_trade.space_mix_info.start_time}-{rowData.space_trade.space_mix_info.end_time}
                                                可用{rowData.space_trade.space_mix_info.duration}小时
                                            </p>
                                        </div>
                                        :
                                        null
                            }

                            <p className={`lh25`}>场地：{ rowData.book_info?.space_type_name || '-' }-{ rowData.book_info?.space_name || '' }</p>
                            <p className={`lh25`}>用途：{ rowData.book_info?.use_type_name || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'预订金额',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.space_trade?.pay_ment - rowData.space_trade?.safety_money }</p>
                        </div>
                    )
                }
            },
            {
                title:'保险费',
                dataIndex:'insurance',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.space_trade?.safety_money || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'预留信息',
                dataIndex:'user_info',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>预留姓名：{ rowData.space_trade?.name || '-' }</p>
                            <p className={`lh25`}>预留电话：{ rowData.space_trade?.phone || '-' }</p>
                            <p className={`lh25`}>推荐人：{ rowData.space_trade?.referee || '-' }</p>
                            {
                                rowData.space_trade?.is_need_bill == 1
                                    ?
                                    <p>(需要开发票)</p>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'购保信息',
                dataIndex:'insurance_info',
                render:(text, rowData, index) => {
                    return (
                        rowData.space_trade.real_name || rowData.space_trade.id_number
                            ?
                            <div className={''}>
                                <p className={`lh25`}>姓名：{ rowData.space_trade?.real_name || '-' }</p>
                                <p className={`lh25`}>身份证：{ rowData.space_trade?.id_number || '-' }</p>
                            </div>
                            :
                            <div>
                                <p className={`lh25`}>-</p>
                            </div>
                    )
                }
            },
            {
                title:'合计',
                dataIndex:'total',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>&yen;{ rowData.space_trade?.pay_ment || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableLoading:false,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    render() {
        let tableData = this.props.tableData || []
        console.log('tableData',tableData)

        return(
            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={record => record.id}
                pagination={false}
            ></Table>
        )
    }
}
export default CourtOrder
