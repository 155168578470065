import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { studentPhysicalSave } from '@/api/student'
import { physicalForm } from '@/api/educational'
import message from '@/utils/message'
const FormItem = Form.Item;

class AddBMI extends Component {

    state = {
        formData: {},
        show: false,
        tableData: [],
        static_data: [],
        test_item: [],
        other_item: [],
        callBack: null
    }
    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        this.fetchData()
    }

    show = (callBack,detail) => {
        if(detail){
            let  test_item=detail.test_item,
            static_data=detail.static_data,
            other_item=detail.other_item

            static_data=static_data.map(val=>{
                if(val.id =='1'){
                    val.value=moment(val.value)
                }
                return val
            })

            this.setState({
                formData:detail,
                test_item,
                static_data,
                other_item,
                show: true,
                callBack: callBack || null
            })
        }else{
            this.setState({
                show: true,
                callBack: callBack || null,
                formData:{}
            })
        }
    }

    fetchData = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        sendData.status=1;
        this.setState({
            tableLoading: true
        })
        physicalForm(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data;

                    this.setState({
                        tableData: lists,
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    handleChange = e => {
        let tableData = this.state.tableData;
        let item = tableData.find(val => val.id == e);
        if (item) {
            let static_data = item.static_data.filter(val => val.checked)
            let test_item = item.test_item, other_item = item.other_item
            this.setState({
                static_data,
                test_item,
                other_item
            })
        }
    }

    InputChange=(name,i,j)=>{
        return e=>{
            let value=e.target.value
            if(i===''){
                let other_item=this.state.other_item
                other_item[j][name]=value
                this.setState({
                    other_item
                })
            }else{
                let test_item=this.state.test_item
                test_item[i].list[j][name]=value
                this.setState({
                    test_item
                })
            }
        }
    }

    handleReset=()=>{
        let formData=this.state.formData;
        this.handleChange(formData.form_id)
    }

    saveData = () => {
        let {static_data,test_item,other_item,formData} = this.state,
            sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values);
                if(values.id_1){
                    values.id_1= moment(values.id_1).format('YYYY-MM-DD')
                }
                sendData.form_id=values.bmi_id
                sendData.static_data=static_data.map(val=>{
                    val.value=values['id_'+val.id]||'';
                    return val
                })
                sendData.test_item=test_item.map(item=>{
                    item.list=item.list.map(val=>{
                        val.value=val.value || ''
                        val.remark=val.remark || ''
                        return val
                    })
                    return item
                })
                sendData.other_item=other_item.map(val=>{
                    val.value=val.value || ''
                    val.remark=val.remark || ''
                    return val
                })
                sendData.student_id=this.props.student_id

                if(formData.id){
                    sendData.user_physical_form_id=formData.id
                }
                
                this.setState({
                    modalLoading: true
                })
                studentPhysicalSave(sendData).then(res => {
                    message.success(res.msg)
                    if (res.code === 1) {
                        this.setState({
                            modalLoading: false
                        })
                        this.handleCancel()
                        let callBack=this.state.callBack;
                        if(typeof callBack === 'function'){
                            callBack()
                        }
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.setState({
            show: false,
            static_data: [],
            test_item: [],
            other_item: [],
        })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        let formData = this.state.formData
        const { getFieldDecorator } = this.props.form
        return (

            <Modal
                title={"体测表"}
                visible={this.state.show}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={900}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                footer={[
                    <Button key="reset" onClick={this.handleReset}> 重置</Button>,
                    <Button key="back" onClick={this.handleCancel}> 取消</Button>,
                    <Button key="submit" type="primary" loading={this.state.modalLoading} onClick={this.saveData}> 确认</Button>,
                  ]}
            >
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={18}>

                            <FormItem label={'体测表名称'} >
                                {
                                    getFieldDecorator('bmi_id', {
                                        initialValue: formData.form_id ||undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择表单'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择表单'} onChange={this.handleChange} >

                                            {
                                                this.state.tableData.map(item => (
                                                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>

                            {
                                this.state.static_data.map(item => {
                                    return (
                                        <FormItem label={item.name}>
                                            {getFieldDecorator('id_' + item.id, {
                                                initialValue: item.value || undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入必填项'
                                                    }
                                                ]
                                            })(
                                                item.id == '1' ? <DatePicker style={{ width: '100%' }} placeholder='请选择时间' /> : item.id == '4' ? <Input addonAfter="cm" /> : item.id == '5' ? <Input addonAfter="kg" /> :
                                                    <Input />

                                            )}
                                        </FormItem>
                                    )
                                })
                            }




                        </Col>
                        <Col span={6}>
                        </Col>
                    </Row>
                    {
                       ( this.state.test_item.length || this.state.other_item.length)?
                       <Row gutter={24}>
                       <Col span={24}>
                           <table className="cxtables cxtables-sku">
                               <thead>
                                   <tr>
                                       <td>测试项目</td>
                                       <td>测试结果</td>
                                       <td>备注</td>
                                   </tr>
                               </thead>
                               <tbody>

                                   {
                                       this.state.test_item.map((item, i) => {
                                           return (<>
                                               {
                                                   item.list.map((val, j) => {
                                                       return (
                                                           <tr key={j}>
                                                               <td>{item.type_name}/{val.name}</td>
                                                               <td>
                                                                   <Input value={val.value || ''} onChange={this.InputChange('value',i,j)} ></Input>
                                                               </td>
                                                               <td>
                                                                   <Input value={val.remark || ''}  onChange={this.InputChange('remark',i,j)} ></Input>
                                                               </td>
                                                           </tr>
                                                       )
                                                   })
                                               }
                                           </>)
                                       })
                                   }
                                   {
                                       this.state.other_item.map((val,j)=>{
                                           return (
                                               <tr key={j}>
                                                   <td>{val.name}</td>
                                                   <td>
                                                       <Input value={val.value || ''}  onChange={this.InputChange('value','',j)} ></Input>
                                                   </td>
                                                   <td>
                                                       <Input value={val.remark || ''}  onChange={this.InputChange('remark','',j)} ></Input>
                                                   </td>
                                               </tr>
                                           )
                                       })
                                   }
                               </tbody>
                           </table>
                       </Col>
                   </Row>:null
                    }
                  

                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(AddBMI))