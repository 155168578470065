import React from 'react'
import { Table } from 'antd'

class CardOrder extends React.Component{
    state = {
        columns:[
            {
                title:'卡名称',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            {
                                rowData.name
                            }

                        </div>
                    )
                }
            },
            {
                title:'内容',
                dataIndex:'desc',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            {
                                this.props.trade.trade_type_name
                            }

                        </div>
                    )
                }
            },
            {
                title:'售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.price }</p>
                        </div>
                    )
                }
            },
            {
                title:'数量',
                dataIndex:'num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>1</p>
                        </div>
                    )
                }
            },
            {
                title:'合计',
                dataIndex:'pay_ment',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ rowData.pay_ment || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableLoading:false,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    render() {
        let tableData = this.props.tableData || []
        console.log('tableData',tableData)
        return(
            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={(record,index) => index}
                pagination={false}
            ></Table>
        )
    }
}
export default CardOrder
