import $axios from '@/axios/axios.js'

export function getSubmitConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/getSubmitConfig`,
        method:'post',
        data
    })
}
export function setSubmitConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/setSubmitConfig`,
        method:'post',
        data
    })
}

export function getConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/config/get`,
        method:'post',
        data
    })
}

export function setConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/config/set`,
        method:'post',
        data
    })
}

export function userCenterConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/config/getUserCenterConfig`,
        method:'post',
        data
    })
}
