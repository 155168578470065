import React, { Component } from 'react'
import { Tabs } from 'antd'
import PointsDetail from './components/points-detail';
import PointsData from './components/points-data';
const { TabPane } = Tabs;

export default class DataPoints extends Component {
    state = {
        activeKey:"1"
    }

    changeTabs=key=>{
        this.setState({
            activeKey:key
        })
    }


    render() {

        return (
            <div className={'shadow-radius'}>
                <Tabs defaultActiveKey={this.state.activeKey} onChange={this.changeTabs}>
                    <TabPane tab="积分数据" key="1">
                    <PointsData />
                    </TabPane>
                    <TabPane tab="积分明细" key="2">
                       
                        <PointsDetail />
                    </TabPane>
                </Tabs>
            </div>
        )
    }

}
