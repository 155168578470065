import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon, Checkbox,Popover } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
// import { storeLists } from '@/api/store'
import {  createLabelGroup } from '@/api/user'
import CouponModal from './CouponModal';
import { equityList } from '@/api/level';

const FormItem = Form.Item;

 class EquityModal extends Component {
    state={
        visible:false,
        modalLoading:false,
        checkKeys:null,
        storeLists:[
            {
                id:1,
                type:3,
                name:'送优惠券',
                icon:''
            },
            {
                id:2,
                type:1,
                name:'送积分',
                icon:''
            },
            {
                id:3,
                type:2,
                name:'送成长值',
                icon:''
            },
        ],
        popVisible:false,
        popVisible1:false,
        tableData:[],
        callback:null
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    fetchData = (row) => {
        let tableData = row;
        let storeLists = this.state.storeLists;
        if(tableData){
            storeLists.forEach((item,index)=>{
                let data = tableData.find(items => items.id == item.id)
                if(data){
                    if(data.type === 3){
                        item.select_coupon = data.select_coupon
                    }else if(data.type === 1 || data.type === 2){
                        item.input_num = data.input_num
                    }
                }
               
            })
        }
        // let sendData = {}
        // equityList(sendData)
        //     .then(res => {
        //         if (res.code === 1) {
        //             console.log('------',res)
        //             let lists = res.data.list.data;
        //             this.setState({
        //                 storeLists: lists
        //             })
        //         }
        //     })

    }

    show = (row,callback) => {
        // this.fetchData();
        this.setState({
            visible:true,
            callback : callback || null
        })
        if(row && row.length>0){
            let arr = [];
            row.forEach((item,index)=>{
                arr.push(item.id)
            })
            this.setState({
                tableData:row,
                checkKeys:arr
            })
        }else{
            this.setState({
                tableData:[],
                checkKeys:null,
                storeLists:[
                    {
                        id:1,
                        type:3,
                        name:'送优惠券',
                        icon:'https://img.cxkoo.com/chengxuan/1/2023-08-10/40ffdbd57ec08c1b43d31d0ee108671b.png'
                    },
                    {
                        id:2,
                        type:1,
                        name:'送积分',
                        icon:'https://img.cxkoo.com/chengxuan/1/2023-08-10/349ac00380d4b88b3db9ce7e4c26b746.png'
                    },
                    {
                        id:3,
                        type:2,
                        name:'送成长值',
                        icon:'https://img.cxkoo.com/chengxuan/1/2023-08-10/349ac00380d4b88b3db9ce7e4c26b746.png'
                    },
                ],
            })
        }
        this.fetchData(row);
    }

    saveData=()=>{


        this.setState({
            modalLoading: true
        })
        
        const { sendReward } = this.props;
        let storeLists  = this.state.storeLists;
        let tableData = this.state.tableData;
        tableData.forEach((item,index)=>{
            let resData = storeLists.find(items=> items.id == item.id);
            if(resData.type == 3){
                  item.select_coupon = resData.select_coupon || null
            }else if(resData.type == 1){
                  item.input_num = resData.input_num || 0
            }else if(resData.type == 2){
                  item.input_num = resData.input_num || 0
            }

        })
        this.setState({
            tableData
        })
        sendReward(tableData);
        
        this.setState({
            modalLoading: false,
            visible:false
        })


    }
    handleDel = (index) =>{
        let resData = this.state.tableData;
        resData.label_value.splice(index,1);
        this.setState({
            tableData:resData
        })
    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    handlePoints = (type) => {
        if(type === '1'){
            this.setState({
                popVisible: true
            })
        }else if(type === '2'){
            this.setState({
                popVisible1: true
            })
        }
        
    }

    handleSavePoints = (type) => {
        if(type === '1'){
            this.setState({
                popVisible: false
            })
        }else if(type === '2'){
            this.setState({
                popVisible1: false
            })
        }
    }

    closePop = (type) => {
        if(type === '1'){
            this.setState({
                popVisible: false
            })
        }else if(type === '2'){
            this.setState({
                popVisible1: false
            })
        }
    }


    handleCheckChange = val =>{
        console.log('这是选择的权益',val);
        let data = this.state.tableData;
        let storeLists = this.state.storeLists;
        if(val.length>0){
            data = [];
            val.forEach((item,index)=>{
                let resData =  storeLists.find(items=> items.id === item);
                let oldData =  data.find(oitems=> oitems.id === item);
                if(!oldData){
                    let arr;
                    arr = {
                        id:resData.id,
                        type:resData.type,
                        name:resData.name
                    }
                    data.push(arr)
                }
            })
        }else{
            data = [];
        }
        this.setState({
            tableData:data,
            checkKeys:val
        })
       
    }

    showCoupon = ()=> {
        let row = [];
        let storeLists = this.state.storeLists;
        let data  = storeLists.find(item=> item.type == 3);
        if(data.select_coupon){
            row = data.select_coupon
        }
        this.couponCreate.show(row,(res)=>{
            
        })
    }
    

    handleCouponAdd = res =>{
        let data = this.state.storeLists;
        data.forEach((item,index)=>{
            if(item.type === 3){
                item.select_coupon = res;
            }
        })
        this.setState({
            storeLists:data
        })
    }


    skipEquity = () =>{
        // this.props.history.push({
        //     pathname:`/level/equity-edit`
        // })
    }

    changeJf = (e,type)=>{
        let val = e.target.value;
        let storeLists = this.state.storeLists;
        if(type === '1'){
            storeLists.forEach((item,index)=>{
                if(item.type == 1){
                  item.input_num = val   
                }
            })
        }else if(type === '2'){
            storeLists.forEach((item,index)=>{
                if(item.type == 2){
                  item.input_num = val   
                }
            })
        }
        this.setState({
            storeLists
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const formItemLayouts = {
            labelCol: {
                span: 10
            },
            wrapperCol: {
                span: 10
            }
        }
        const { getFieldDecorator } = this.props.form
        const storeLists = this.state.storeLists || []
        const content = (
            <div>
                    <div className={`flex align-center`}>
                        <div>送</div>
                        <Input style={{width:'100px'}} className={`mgl5 mgr5`} onChange={ (e)=>{ this.changeJf(e,'1') }}></Input>
                        <div>积分</div>
                    </div>
                    <div className={`clearfix mgt10 mg5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={()=>{this.handleSavePoints('1')}}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={()=>{this.closePop('1')}}>取消</Button>
                    </div>
            </div>
        )
        
        const content1 = (
            <div>
                    <div className={`flex align-center`}>
                        <div>送</div>
                        <Input style={{width:'100px'}} className={`mgl5 mgr5`} onChange={ (e)=>{ this.changeJf(e,'2') }}></Input>
                        <div>成长值</div>
                    </div>
                    <div className={`clearfix mgt10 mg5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={()=>{this.handleSavePoints('2')}}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={()=>{this.closePop('2')}}>取消</Button>
                    </div>
            </div>
        )
        return (
            <div>
            <Modal
            title={"选择升级礼包"}
            visible={this.state.visible}
            onOk={this.saveData}
            onCancel={this.handleCancel}
            width={1500}
            centered={true}
            confirmLoading={this.state.modalLoading}
            maskClosable={false}
            destroyOnClose={true}
        >
            <Form {...formItemLayout} className={'model-form'}>
                
                {/* <Row gutter={24}>
                    <Col span={20}> */}
                        <FormItem label="升级礼包">
                            <div>升级为该等级会员后立即发放</div>
                            <div>
                                <Checkbox.Group onChange={this.handleCheckChange} value={this.state.checkKeys}>
                                    {
                                        storeLists && storeLists.map(val => {
                                            return (
                                                
                                                <Checkbox value={val.id} className={`flex align-center mgr15`} style={{backgroundColor:'rgb(242 242 242)',padding:'10px 15px',marginBottom:'10px',marginLeft:'0px',float:'left',width:'250px',height:'60px'}} key={ val.id }>
                                                    <div className={`flex align-center`}>
                                                        <div className={`mgl10`}><img src={val.icon}  alt='' width={30} height={30}/></div>
                                                        <div className={`mgl15`}>
                                                            <div className={`fz16 fw-600`}>{val.name}</div>
                                                            {
                                                                val.type == 1?
                                                                <Popover
                                                                    content={content}
                                                                    width={300}
                                                                    title={null}
                                                                    placement={`bottom`}
                                                                    trigger="click"
                                                                    visible={this.state.popVisible}>
                                                                    <div className={`icon-pencil`} style={{color:'blue'}} onClick={()=>{this.handlePoints('1')}} >
                                                                        {
                                                                            val.input_num>0 ?
                                                                                <div>积分数 {val.input_num}</div>
                                                                                :
                                                                                <div>输入积分</div>
                                                                        }
                                                                    </div>
                                                                </Popover>
                                                               :null
                                                            }

                                                            {
                                                                val.type ==2?
                                                                <Popover
                                                                    content={content1}
                                                                    width={300}
                                                                    title={null}
                                                                    placement={`bottom`}
                                                                    trigger="click"
                                                                    visible={this.state.popVisible1}>
                                                                    <div className={`icon-pencil`} style={{color:'blue'}} onClick={()=>{this.handlePoints('2')}} >
                                                                        {
                                                                            val.input_num>0 ?
                                                                                <div>成长值数 {val.input_num}</div>
                                                                                :
                                                                                <div>输入成长值</div>
                                                                        }
                                                                    </div>
                                                                </Popover>
                                                               :null
                                                            }

                                                            {
                                                                val.type == 3?

                                                                (
    
                                                                    val.select_coupon?
                                                                        <div style={{color:'blue'}} onClick={this.showCoupon }>已选择{val.select_coupon.length || 0}张</div>
                                                                        :
                                                                        <div style={{color:'blue'}} onClick={this.showCoupon }>选择优惠券</div>
                                                                )
                                                                
                                                               : null
                                                            }
                                                                
                                                            {/* <div style={{color:'blue'}}>输入积分倍数</div> */}
                                                        </div>
                                                        
                                                    </div>
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </Checkbox.Group>
                            </div>
                        </FormItem>
            </Form>

        </Modal>
            <CouponModal onRef={e => { this.couponCreate = e }} sendCoupon={this.handleCouponAdd}></CouponModal>
        </div>
        )
    }
}

export default Form.create({})(EquityModal)
