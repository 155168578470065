import React from 'react'
import { Form, Input, Row, Col, Icon } from 'antd';
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
const FormItem = Form.Item;

class EditForm extends React.Component{
    state = {
        formData:{
            name:'',
            cover_img:'',
            sort:'',
        }
    }
    componentDidMount() {
        if(this.props.data){
            let formData = {};
            for(var i in this.props.data){
                formData[i] = this.props.data[i]
            }
            this.setState({
                formData
            });
        }
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.visible){
            this.props.form.resetFields()
        }
    }
    getData = (callback) => {
        let res = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                res = values;
                res.cover_img = this.state.formData.cover_img;
                if(typeof callback == 'function'){
                    callback(res)
                }
            }else {
                if(typeof callback == 'function'){
                    callback('')
                }
            }
        })

    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover_img':
                    formData[name] = imgs[0];
                    break;
                case 'pic_urls':
                    formData['pic_urls'] = formData['pic_urls'] || [];
                    formData['pic_urls'] = formData['pic_urls'].concat(imgs);
                    if(formData['pic_urls'].length > 10){
                        formData['pic_urls'] = formData['pic_urls'].slice(0,10)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    render() {
        let data = this.state.formData ;
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol:{
                span:7
            },
            wrapperCol:{
                span:17
            }
        }
        return (
            <Form {...formItemLayout} className={'model-form'}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Row gutter={24}>
                    <Col span={20}>
                        <FormItem label="分类名称">
                            {getFieldDecorator('name', {
                                initialValue: data && data.name,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入分类名称（不得超过10个字符）'
                                    }
                                ]
                            })(<Input />)}
                        </FormItem>
                        <FormItem label="封面图" required={true}>
                            <div className={'clearfix'}>
                                {
                                    data.cover_img
                                        ?
                                        <PictureCard
                                            onRef={ e => { this.PictureCard = e } }
                                            imgs={ data.cover_img }
                                            onDel={ e => { this.delImg(e,'cover_img') } }
                                        ></PictureCard>
                                        :
                                        <ImgAdd
                                            onRef={ e => { this.ImgAdd = e } }
                                            onClick={ e => { this.chooseImg(e,'cover_img') } }
                                        ></ImgAdd>
                                }
                            </div>
                            <span className={`fi-help-text`}>建议尺寸：300x300像素，单张不超过500k</span>

                        </FormItem>
                        <FormItem label="排序号">
                            {getFieldDecorator('sort', {
                                initialValue: data && data.sort,
                            })(<Input />)}
                            <span className={`fi-help-text`}>排序号，越小越靠前</span>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                    </Col>
                </Row>
            </Form>
        )
    }

}

export default Form.create({})(EditForm)
