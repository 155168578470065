import $axios from '@/axios/axios.js'

export function printLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/printer/lists`,
        method:'post',
        data
    })
}

export function addPrint(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/printer/addPrinter`,
        method:'post',
        data
    })
}

export function editPrint(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/printer/editPrinter`,
        method:'post',
        data
    })
}

export function delPrint(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/printer/deletePrinter`,
        method:'post',
        data
    })
}

export function setPrintScene(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/printer/setScene`,
        method:'post',
        data
    })
}

export function setTicket(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/printer/setTicket`,
        method:'post',
        data
    })
}

