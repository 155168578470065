import React,{ Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FirstSideBar from './FirstSideBar'
import Header from './Header'
import SecondSideBar from './SecondSideBar'
import MainContent from './MainContent'
import BreadCrumb from './BreadCrumb'
import { Layout } from 'antd';
import { routes } from '@/router/routes'

let socket = ''

class Index extends Component {
    componentDidMount() {}
    render() {
        const { breadCrumb }  = this.props
        const currentPath = this.props.history.location.pathname
        let withoutSideMenu = false;
        routes.forEach(item => {
            let matchPath = item.path.split('/:')[0];
            if(currentPath.includes(matchPath)){
                if(item.withoutSideMenu){
                    withoutSideMenu = true
                }
            }
        })
        return (
            <div className={`layout-wrapper`}>
                <FirstSideBar  />
                <div className={`main-wrapper`}>
                    <Header />
                    <div className={`content-wrapper`}>
                        {
                            withoutSideMenu
                                ?
                                null
                                :
                                <SecondSideBar />
                        }
                        <Layout className={`router-view-wrapper`}>
                            { breadCrumb.show && !withoutSideMenu ? <BreadCrumb /> : null }
                           
                            <MainContent/>
                        </Layout>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => state
const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Index))
