import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import '@/assets/css/miniprogram/user-center.scss'

const FormItem = Form.Item

class UserStyle3 extends React.Component {
    state = {}

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }

    fetchData = () => {

    }

    render() {
        let data = this.props.current || {}

        return (
            <div className={`user-style3`}>
                <div className='mine-cont'>
                    <div className={`mine-head ${data.showMemberCard ? '' : ''}`} style={{ backgroundImage: `url(${data.bgImg || 'https://img.cxkoo.com/chengxuan/1/2022-11-02/527e594a1a5862bb2ad8ba1350b6f092.png'})` }}>
                        <div className="status-bar"></div>
                        <div className="head-cont">
                            <div className="user-cont flex justify-between align-center">
                                <div className="user-info">
                                    <div className="avatar"></div>
                                    <div className="info-desc">
                                        <div className="nick-name">登录/注册</div>
                                        <div className="level">普通会员</div>
                                    </div>
                                </div>
                                <div className="right"></div>
                            </div>
                            <div className="pd15 tac">
                                <div className="flex justify-between align-center h100">
                                    <div className="top-item">
                                        <div className="txt1">0</div>
                                        <div className="txt2">获赞</div>
                                    </div>
                                    <div className="top-item">
                                        <div className="txt1">0</div>
                                        <div className="txt2">收藏</div>
                                    </div>
                                    <div className="top-item">
                                        <div className="txt1">0</div>
                                        <div className="txt2">优惠券</div>
                                    </div>
                                    <div className="top-item">
                                        <div className="txt1">0</div>
                                        <div className="txt2">成长值</div>
                                    </div>
                                    <div className="top-item">
                                        <div className="txt1">0</div>
                                        <div className="txt2">积分</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mine-content">
                        {
                            data.showMemberCard 
                                ?
                                <div className="member-card">
                                    <div className="txt1"></div>
                                    <div className="txt2">开通VIP会员，享专属特权！</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.showTrain == 1
                                ?
                                <div className={`cx-mine-panel ${data.showMemberCard?'mgt20':'move-top'}`}>
                                    <div className="cx-panel-title">
                                        <div className="left">我的训练</div>
                                    </div>
                                    <div className="cx-panel-cont flex justify-between align-center">
                                        <div className="top-item">
                                            <div className="txt1">0</div>
                                            <div className="txt2">累计训练数/次</div>
                                        </div>
                                        <div className="top-item">
                                            <div className="txt1">0</div>
                                            <div className="txt2">训练时长/分钟</div>
                                        </div>
                                        <div className="top-item">
                                            <div className="txt1">0</div>
                                            <div className="txt2">剩余课时/次</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <div  className={`cx-mine-panel ${(data.showMemberCard || data.showTrain) ?'mgt20':'move-top'}`} >
                            <div className="cx-panel-title">
                                <div className="left">教务服务</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-start align-center flex-wrap">
                                {
                                    data.trainingTool && data.trainingTool.map((item, index) => (
                                        item.isShow
                                            ?
                                            <div className="tool-item" key={item.id}>
                                                <img src={item.icon}></img>
                                                <div className="txt">{item.name}</div>
                                            </div>
                                            :
                                            null
                                    ))
                                }
                            </div>
                        </div>

                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">我的内容</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-start align-center flex-wrap">
                                {
                                    data.myContent && data.myContent.map((item, index) => (
                                        item.isShow
                                            ?
                                            <div className="tool-item" key={item.id}>
                                                <img src={item.icon}></img>
                                                <div className="txt">{item.name}</div>
                                            </div>
                                            :
                                            null
                                    ))
                                }
                            </div>
                        </div>

                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">更多工具</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-quick-items">
                                {
                                    data.userCenter && data.userCenter.map((item, index) => (
                                        item.isShow
                                            ?
                                            <div className="quick-item" key={index}>
                                                <div className="left">
                                                    <div className="icon"
                                                        style={{ backgroundImage: `url(${item.icon})` }}></div>
                                                    <div className="txt">{item.name}</div>
                                                </div>
                                                <div className="right">
                                                    <div className="arrow"></div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    ))
                                }

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(UserStyle3))

