import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class AddPayType extends React.Component{
    state = {
        title:'新增记账方式',
        formData:{},
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        let formData = this.props.data || {};
        if(!formData.icon){
            formData.icon = 'https://img.cxkoo.com/chengxuan/1/2022-03-31/e6583f6818ce84a906f5d42fc270535d.png'
        }
        if(!formData.reckon_income){
            formData.reckon_income = 0
        }
        this.setState({
            visible:true,
            callback : callback || null,
            formData:formData
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let formData = this.state.formData || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                for(var i in values){
                    formData[i] = values[i]
                }
                formData.sort = formData.sort || 0;
                formData.status = formData.status || 0;
                formData.type_name = formData.type_name || '';
                formData.icon = formData.icon || 'https://img.cxkoo.com/chengxuan/1/2022-03-31/e6583f6818ce84a906f5d42fc270535d.png'

                if(typeof callback == 'function'){
                    callback(formData)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        if(formData.disable == 1){ return; }
        this.Gallery.show(imgs => {
            formData['icon'] = imgs[0];
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(formData.disable == 1){ return; }
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    render() {
        const data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`记账方式名称`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入记账方式名称'
                                    }
                                ]
                            })(
                                <Input placeholder={`请输入`} disabled={!!data.disable} />
                            )
                        }
                    </FormItem>
                    <FormItem label="图标样式" required={true}>
                        <div className={'clearfix'}>
                            {
                                data.icon
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.icon }
                                        onDel={ e => { this.delImg(e,'icon') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'icon') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={`fi-help-text`}>建议尺寸：100x100像素，单张不超过500k</span>

                    </FormItem>

                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddPayType))
