import React, { Component } from 'react'
import { Table, Button, Switch, Divider, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { equityList, equitySave, equityDelete } from '@/api/level'

class EquityManage extends Component {
    state = {
        columns: [
            {
                title: '展示名称',
                dataIndex: 'name',
                width: '230px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.icon} alt="" width={60} />
                            <div className={`mgl10`}>{rowData.name}</div>
                        </div>
                    )
                }
            },
            {
                title: '权益说明',
                dataIndex: 'desc',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.desc} </div>
                    )
                }
            },
            {
                title: '启用状态',
                dataIndex: 'switch',
                width: 100,
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Switch defaultChecked={!!rowData.status} onChange={e => { this.handleSwitch(e, rowData.id) }} />
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: 150,
                render: (text, rowData, index) => {
                    if (rowData.type < 5 && rowData.is_default) {
                        return <Button type='link' size='small' onClick={() => { this.handleEdit(rowData.id) }}>设置规则</Button>
                    }

                    if (!rowData.is_default) {
                        return (
                            <div className={'btn-row'}>
                                <Button type='link' size='small' onClick={() => { this.handleEdit(rowData.id) }}>编辑</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData.id) }}>删除</Button>
                            </div>
                        )
                    }

                    return (
                        <div >-</div>
                    )
                }
            },
        ],
        tableLoading: false,
        tableData: []
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {

        this.setState({
            tableLoading: true
        })
        equityList({ page: 1, per_page: 100 }).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleSwitch = (e, id) => {
        let sendData = {
            status: e ? 1 : 0,
            id
        }

        this.setState({
            tableLoading: true
        })
        equitySave(sendData).then(res => {
            if (res.code == 1) {
                this.fetchData()
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleEdit = (id) => {
        let link = '/level/equity-edit'
        if (id) {
            link = link + '/' + id
        }
        this.props.history.push({
            pathname: link
        })
    }

    handleDele = id => {
        this.setState({
            tableLoading: true
        })
        equityDelete({ id }).then(res => {
            if (res.code == 1) {
                message.success('删除成功！')
                this.fetchData()
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    render() {
        return (
            <div className='shadow-radius'>
                <Button type='primary' onClick={() => { this.handleEdit() }}>添加自定义权益</Button>
                <div className="mgt20">
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
            </div>
        )
    }
}

export default withRouter(EquityManage)
