import React, { Component } from 'react'
import { Form, Input, Radio, Button, InputNumber,Select ,Checkbox,message} from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Loading from '@/components/Loading'
import Editor from '@/components/Editor/Editor'
import { getPosition,teacherDetail,teacherSave} from '@/api/educational'
import { storeLists } from '@/api/store'
import {  roleList } from '@/api/staff'
const { Option } = Select;

const FormItem = Form.Item

class TeacherAdd extends Component {
    state = {
        isLoading: false,
        formData: {},
        storeLists: [],
        teacherTitleList:[],
        role_list:[],
        showEditor: false,
    }

    componentDidMount() {
        
        if(!this.props.match.params.id){
            this.props.history.goBack()
        }
        this.fetchStore()
        this.fetchTeacherTitle()
        this.fetchRole()
        this.fetchData()

    }

    fetchData=()=>{
        let sendData = {}
        sendData.id = this.props.match.params.id;
        
        this.setState({
            isLoading: true
        })
        teacherDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let formData = res.data.detail;
                    this.setState({
                        formData: formData,
                        showEditor:true
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    
    fetchRole = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        roleList(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data
                    lists=lists.map(v=>{
                        let obj={}
                       obj.value=v.id +''
                       obj.label=v.name
                        return obj
                    })


                    this.setState({
                        role_list: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            }) 
    }

    fetchTeacherTitle = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading: true
        })
        getPosition(sendData)
            .then(res => {
                
                if (res.code == 1) {
                    let lists = res.data.list.data
                  
                    this.setState({
                        teacherTitleList: lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }


    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.desc = content;
        this.setState({
            formData
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleSort = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = urls.splice(oldIndex, 1)[0];
        urls.splice(newIndex, 0, targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'head_img':
                    formData[name] = imgs[0];
                    break;
                case 'base_certificate_info':
                    formData['base_certificate_info'] = formData['base_certificate_info'] || [];
                    formData['base_certificate_info'] = formData['base_certificate_info'].concat(imgs);
                    if (formData['base_certificate_info'].length > 10) {
                        formData['base_certificate_info'] = formData['base_certificate_info'].slice(0, 10)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }

    saveData = () => {
        let sendData = {},formData=this.state.formData
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;

                let pattern = /^1[0-9]{10}$/

                if (!pattern.test(sendData.phone)) {
                    return message.error("请输入正确的手机号")
                }

               

                sendData.head_img=formData.head_img
                sendData.base_certificate_info=formData.base_certificate_info
                sendData.desc=formData.desc



                sendData.role_ids= sendData.role_ids.join()

                sendData.id=this.props.match.params.id;

                this.setState({
                    isLoading: true
                })
                teacherSave(sendData).then(res => {

                    if (res.code === 1) {
                        message.success(res.msg)
                        this.props.history.push({
                            pathname:"/educational/teacher"
                        }) 
                    }else{
                        this.setState({
                            isLoading: false
                        })
                    }
                })

            }
        })
    }

    goBack = () => {
        this.props.history.goBack()
    }


    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 12
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        const radioSty = { display: "block", marginBottom: "8px" }
        return (
            <div className={`shadow-radius`}>
                <div className={'mgt10'}>
                    <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                    <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                        <div className={`msg-title mgb15`}>基本信息</div>
                        <FormItem label={'员工姓名'}>
                            {getFieldDecorator('name', {
                                initialValue: data.name || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入员工姓名'
                                    }
                                ]
                            })(<Input
                                placeholder={'请输入员工姓名'}
                                style={{ width: 300 }}
                            />)}

                        </FormItem>

                        <FormItem label={'员工手机号'}>
                            {getFieldDecorator('phone', {
                                initialValue: data.phone ||undefined
                            })(<Input
                                placeholder={'请输入员工手机号'}
                                style={{ width: 300 }}
                            />)}
                            <span className={`fi-help-text`}>手机号为系统唯一标识，用户后台登陆账号使用</span>
                        </FormItem>

                        <FormItem label={'头像'}>
                            <div className={`clearfix`}>
                                {
                                    data.head_img
                                        ?
                                        <PictureCard
                                            onRef={e => { this.PictureCard = e }}
                                            imgs={data.head_img}
                                            onDel={e => { this.delImg(e, 'head_img') }}
                                        ></PictureCard>
                                        :
                                        <ImgAdd
                                            onRef={e => { this.ImgAdd = e }}
                                            onClick={e => { this.chooseImg(e, 'head_img') }}
                                        ></ImgAdd>
                                }
                            </div>
                        </FormItem>

                        <FormItem label={'角色'} >
                                {
                                    getFieldDecorator('role_ids', {
                                        initialValue: data.staff && data.staff.role_ids || [],
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择所属角色'
                                            }
                                        ]
                                    })(
                                        <Checkbox.Group
                                            options={this.state.role_list}
                                        />
                                    )
                                }
                            </FormItem>

                        <FormItem label={'所属门店'}>
                            {getFieldDecorator('store_id', {
                                initialValue: data.store_id || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择所属门店'
                                    }
                                ]
                            })(
                                <Select
                                style={{ width: 300 }}
                                    placeholder="请选择"
                                   
                                >
                                    {
                                        this.state.storeLists.map(store => (
                                            <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )}
                        </FormItem>


                        <FormItem label={"状态"} >
                            {getFieldDecorator('status', {
                                initialValue: data.status || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择员工任职状态'
                                    }
                                ]
                            })(
                                <Radio.Group >
                                    <Radio value={1}>在职</Radio>
                                    <Radio value={2}>离职</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>

                        <FormItem label={'排序号'}>
                            {getFieldDecorator('sort', {
                                initialValue: data.sort || ``
                            })(<Input
                                placeholder={'请输入排序号'}
                                style={{ width: 300 }}
                            />)}
                        </FormItem>

                        <div className={`msg-title mgb15`}>个人详情</div>

                        <FormItem label={'老师昵称'}>
                            {getFieldDecorator('nick_name', {
                                initialValue: data.nick_name || undefined
                            })(<Input
                                placeholder={'请输入老师昵称'} maxLength={20}
                                style={{ width: 300 }}
                            />)}
                            <span className={`fi-help-text`}>客户购买课程查看老师，看到的是老师昵称</span>
                        </FormItem>

                        <FormItem label={'教龄'} >
                            {
                                getFieldDecorator('teacher_age', {
                                    initialValue: data.teacher_age||0
                                })(<InputNumber  placeholder='请输入教龄'  style={{ width: 300 }} min={0} precision={0}   />)
                            }
                            <span className='mgl10'>年</span>
                        </FormItem>

                        <FormItem label={'职位/职称'} >
                            {
                                getFieldDecorator('position_id', {
                                    initialValue: data.position_id ||undefined
                                })(
                                    <Select   style={{ width: 300 }}  placeholder="请选择职称" >
                                        {
                                            this.state.teacherTitleList.map(item => (
                                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>

                        <FormItem label={'擅长'}>
                            {getFieldDecorator('skill', {
                                initialValue: data.skill || []
                            })(
                                <Select mode="tags"  style={{ width: 300 }} placeholder="请输入教师擅长点">
                                </Select>
                            )}

                        </FormItem>


                        <FormItem label={'专业资质'} required={true}>
                            <div className={'clearfix'}>
                                {
                                    data.base_certificate_info && data.base_certificate_info.length
                                        ?
                                        <PictureCard
                                            onRef={e => { this.PictureCard = e }}
                                            imgs={data.base_certificate_info}
                                            onDel={e => { this.delImg(e, 'base_certificate_info') }}
                                            onSort={e => { this.handleSort(e, 'base_certificate_info') }}
                                        ></PictureCard>
                                        :
                                        null
                                }
                                {
                                    data.base_certificate_info && data.base_certificate_info.length >= 10
                                        ?
                                        null
                                        :
                                        <ImgAdd
                                            onRef={e => { this.ImgAdd = e }}
                                            onClick={e => { this.chooseImg(e, 'base_certificate_info') }}
                                        ></ImgAdd>
                                }
                            </div>
                            <span className={'fi-help-text'}> 建议：单张图片不超过2M，最多可上传10张</span>
                        </FormItem>

                        <FormItem label={'老师简介'}>
                            {
                                this.state.showEditor
                                    ?
                                    <Editor id={'text'} value={data.desc} callback={content => this.EditorChange(content)}></Editor>
                                    :
                                    null
                            }
                        </FormItem>

                        <FormItem label={` `} colon={false}>
                            <Button type={'primary'} onClick={this.saveData}>保存</Button>
                            <Button className='mgl15' onClick={this.goBack} >取消</Button>
                        </FormItem>

                    </Form>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(TeacherAdd))