import $axios from '@/axios/axios.js'

export function financeDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance`,
        method:'post',
        data
    })
}

export function financeExport(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance/export`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function withdrawAudit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance/withdrawAudit`,
        method:'post',
        data
    })
}

export function agreeWithdraw(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance/agreeWithdraw`,
        method:'post',
        data
    })
}

export function refuseWithdraw(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance/refuseWithdraw`,
        method:'post',
        data
    })
}


export function financeBill(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance/bill`,
        method:'post',
        data
    })
}

export function financeOpenBill(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance/confirmBill`,
        method:'post',
        data
    })
}

export function financeExportBill(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/finance/exportBill`,
        method:'get',
        responseType:'blob',
        params
    })
}
