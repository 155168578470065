/**
 * 公告组件
 * @type 1
 * @return Object
 */
import React from 'react'
import { Form, Slider, Input, InputNumber } from 'antd'
import PropTypes from "prop-types";
const FormItem = Form.Item
const { TextArea } = Input


class tpl_ctrl_type1 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }


    state = {
        moduleData:{}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }


    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }

    changeDesc = e => {
        let moduleData = this.state.moduleData;
        moduleData.content.desc = e.target.value;
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>公告</div>
                    <Form  className={`cx-ctrl-panel`} >
                        <div className={`cx-ctrl-panel--title`}>基本设置</div>
                        <div className={`cx-ctrl-panel--box`}>
                            <FormItem label={`组件间距`}>
                                <div className={`cx-slider`}>
                                    <Slider
                                        min={0}
                                        max={20}
                                        value={moduleData.content.modulePadding}
                                        onChange={ this.changePadding }
                                        className={`cx-slider-main`}
                                    ></Slider>
                                    <InputNumber
                                        min={0}
                                        max={20}
                                        style={{ width:60 }}
                                        value={moduleData.content.modulePadding}
                                        onChange={ this.changePadding }
                                    />
                                </div>
                            </FormItem>
                            <FormItem label={`公告内容`}>
                                <TextArea rows={3} value={moduleData.content.desc} onChange={ this.changeDesc }  placeholder={'公告内容'} maxLength={30} />
                            </FormItem>
                        </div>

                    </Form>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type1)
