export const MODULE_DEFAULT_DATA = {
    1: {
        /*文本*/
        type: 1,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            alignStyle: 0, //0居中 1居左 2居右
            title: '请输入文本内容',
            linkType: 0,
            link: '',
            fontSize: 16,
            fontBold:0,
            titleColor: '#333333',
            backgroundColor: '#ffffff'
        }
    },
    2: {
        /*图片*/
        type: 2,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 0,
            spacePadding:0,
            showType: 1,
            dataset: [
                {
                    linkType: 0,
                    link: '',
                    pic: ''
                }
            ]
        }
    },
    3:{
        /*单选*/
        type: 3,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'单选题题目',
            subTitle:'单选提示',
            required:0,
            dataset: [
                {
                    name:'选项1',
                    is_choose:1
                },
                {
                    name:'选项2',
                    is_choose:0
                },
                {
                    name:'选项3',
                    is_choose:0
                }
            ]
        }
    },
    4:{
        /*多选*/
        type: 4,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'多选题题目',
            subTitle:'多选提示',
            required:0,
            dataset: [
                {
                    name:'选项1',
                    is_choose:0
                },
                {
                    name:'选项2',
                    is_choose:0
                },
                {
                    name:'选项3',
                    is_choose:0
                }
            ]
        }
    },
    5:{
        /*图片单选*/
        type: 5,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'图片单选题目',
            subTitle:'',
            required:0,
            style:1,
            dataset: [
                {
                    name:'选项1',
                    pic:'',
                    is_choose:1
                },
                {
                    name:'选项2',
                    pic:'',
                    is_choose:0
                },
            ]
        }
    },
    6:{
        /*图片多选*/
        type: 6,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'图片多选题目',
            subTitle:'',
            required:0,
            style:1,
            dataset: [
                {
                    name:'选项1',
                    pic:'',
                    is_choose:0
                },
                {
                    name:'选项2',
                    pic:'',
                    is_choose:0
                }
            ]
        }
    },
    7:{
        /*下拉*/
        type: 7,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'下拉选项标题',
            subTitle:'',
            required:0,
            selectedValue:'',
            dataset: [
                {
                    name:'选项1',
                    value:0,
                },
                {
                    name:'选项2',
                    value:1
                }
            ]
        }
    },
    8:{
        /*单项填空*/
        type: 8,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'单项填空',
            subTitle:'',
            required:0,
            placeholder:'请输入',
            value:''
        }
    },
    9:{
        /*多项填空*/
        type: 9,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'多项填空',
            subTitle:'',
            required:0,
            dataset: [
                {
                    name:'问题1',
                    placeholder:'',
                    value:'',
                },
                {
                    name:'问题2',
                    placeholder:'',
                    value:'',
                }
            ]
        }
    },
    10:{
        /*横杠填空*/
        type: 10,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'横杠填空',
            subTitle:'',
            required:0,
            dataset: [
                {
                    name:'问题1',
                    value:'',
                },
                {
                    name:'问题2',
                    value:'',
                }
            ]
        }
    },
    11:{
        /*简答*/
        type: 11,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'简答题',
            subTitle:'',
            required:0,
            placeholder:'请输入',
            value:''
        }
    },
    12:{
        /*姓名*/
        type: 12,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'姓名',
            subTitle:'',
            required:0,
            placeholder:'请输入',
            value:''
        }
    },
    13:{
        /*电话*/
        type: 13,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'电话',
            subTitle:'',
            required:0,
            placeholder:'请输入',
            value:''
        }
    },
    14:{
        /*微信*/
        type: 14,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'微信',
            subTitle:'',
            required:0,
            placeholder:'请输入',
            value:''
        }
    },
    15:{
        /*QQ*/
        type: 15,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'QQ',
            subTitle:'',
            required:0,
            placeholder:'请输入',
            value:''
        }
    },
    16:{
        /*邮箱*/
        type: 16,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'邮箱',
            subTitle:'',
            required:0,
            placeholder:'请输入',
            value:''
        }
    },
    17:{
        /*地址*/
        type: 17,
        draggable: true,
        sort: 0,
        content: {
            modulePadding: 5,
            spacePadding:10,
            title:'地址',
            subTitle:'',
            required:0,
            placeholder:'街道信息',
            provinceId:'',
            cityId:'',
            areaId:'',
            provinceName:'',
            cityName:'',
            areaName:'',
            address:''
        }
    },


}
