/**
 * 社群团购
 * @type 23
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip, Tag } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'


const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_type23 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        let tagStyle = {}
        if (moduleData.content && moduleData.content.style) {
            tagStyle = {
                border: `1px solid ${moduleData.content.tagBorderColor}`,
                background: `${moduleData.content.tagBgColor}`,
                color: `${moduleData.content.tagTextColor}`
            }
        }

        return (
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`社群团购`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>

                        {
                            moduleData.content.dataset.length
                                ?
                                <div className={` pdl10 pdr10`}>
                                    {
                                        moduleData.content.dataset.map((item, index) => (
                                            <div className={`group-solitaire ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} key={index}>
                                                <div className="cover_imgs">
                                                    {
                                                        item.goods_list && item.goods_list.map(val=>{
                                                            return (
                                                                <div className="img" key={val.id} style={{ backgroundImage: `url(${val.cover_img})` }}></div>
                                                            )
                                                        })
                                                    }
                                                   
                                                </div>

                                                <div className="tags">
                                                    {item.status == 2 ?
                                                        <div className="tag">
                                                            <div>跟团</div>
                                                            <div>进行中</div>
                                                        </div> : null
                                                    }
                                                    {
                                                        item.status == 1 ?
                                                            <div className="tag no-start">
                                                                <div>活动</div>
                                                                <div>未开始</div>
                                                            </div> : null
                                                    }

                                                </div>
                                                <div className="title">{item.name}</div>
                                                <div className="price">&yen;
                                                    <span className='bold'>{ CX.dealPrice(item.show_price_ary[0])[0] }</span>.{ CX.dealPrice(item.show_price_ary[0])[1] }
                                                    {
                                                        item.show_price_ary[1]?
                                                        <span className='mgl5'>
                                                            <span className='bold'> - { CX.dealPrice(item.show_price_ary[1])[0]}</span>.
                                                            { CX.dealPrice(item.show_price_ary[1])[1]}
                                                        </span>:null
                                                    }
                                                </div>
                                                <div className="related">
                                                    <div className="left">
                                                        {
                                                            moduleData.content.showPv ?
                                                                <div className="eye-icon">{item.pv}</div> : null
                                                        }
                                                        {
                                                            moduleData.content.showGroupNum ?
                                                                <div className="cart-icon">{item.group_num}人跟团</div> : null
                                                        }
                                                    </div>
                                                    <div className="right" style={{ 'color': moduleData.content.btnTextColor, 'background': moduleData.content.btnColor }} >立即跟团 </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div className={` pdl10 pdr10`}>
                                    <div className={`group-solitaire ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} >
                                        <div className="cover_imgs">
                                            <div className="img" style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                            <div className="img" style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                            <div className="img" style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                        </div>

                                        <div className="tags">
                                            <div className="tag">
                                                <div>跟团</div>
                                                <div>进行中</div>
                                            </div>
                                        </div>
                                        <div className="title">这里显示活动标题，最多展示2行字</div>
                                        <div className="price">&yen;
                                            <span className='bold'>99</span>.00
                                        </div>
                                        <div className="related">
                                            <div className="left">
                                                {
                                                    moduleData.content.showPv ?
                                                        <div className="eye-icon">3.2万</div> : null
                                                }
                                                {
                                                    moduleData.content.showGroupNum ?
                                                        <div className="cart-icon">2932人跟团</div> : null
                                                }


                                            </div>
                                            <div className="right" style={{ 'color': moduleData.content.btnTextColor, 'background': moduleData.content.btnColor }} >立即跟团 </div>
                                        </div>
                                    </div>
                                </div>
                        }


                        <EditorIcon type={`icon-guanbi`} className={`del-module`}
                            onClick={this.handleDeleteModule}></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type23)
