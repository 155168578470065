import React from 'react'
import { Table } from 'antd'

class KtvOrder extends React.Component{
    state = {
        columns:[
            {
                title:'预订信息',
                dataIndex:'code',
                width:400,
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            <p className={`lh25`}>时间：{ rowData.trade.trade_extends.booking_date }{ rowData.trade.trade_extends.booking_time }</p>
                            <p className={`lh25`}>包厢：{ rowData.trade.room_info.name }（{ rowData.trade.room_info.room_size }人）</p>
                            <p className={`lh25`}>套餐：{ rowData.name }</p>
                            <p className={`lh25`}>预留姓名：{ rowData.trade.user_address ? rowData.trade.user_address.name : '-' }</p>
                            <p className={`lh25`}>预留电话：{ rowData.trade.user_address ? rowData.trade.user_address.mobile : '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'套餐内容',
                dataIndex:'name',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.content || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'预订金额',
                dataIndex:'sku',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.trade.total_price }</p>
                        </div>
                    )
                }
            },
            {
                title:'优惠',
                dataIndex:'discount_price',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ rowData.discount_price || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'合计',
                dataIndex:'total',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ rowData.pay_ment || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableLoading:false,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    render() {
        let tableData = this.props.tableData || []
        tableData.forEach( item => {
            item.trade = this.props.trade || {}
        })
        return(
            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={record => record.id}
                pagination={false}
            ></Table>
        )
    }
}
export default KtvOrder
