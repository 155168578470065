import $axios from '@/axios/axios.js'

export function equityList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/equity`,
        method:'post',
        data
    })
}

export function equitySave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/equity/save`,
        method:'post',
        data
    })
}

export function equityDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/equity/detail`,
        method:'post',
        data
    })
}

export function equityDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/equity/delete`,
        method:'post',
        data
    })
}