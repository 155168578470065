import React from 'react'
import { Modal, Input, Button, Spin, Pagination, Form, Tabs } from 'antd'
import message from '@/utils/message'
import LINK_TYPES from './constants/linkType.js'
import CommonLinks from './components/common-links'
import ItemLinks from './components/item-links'
import CourseLinks from './components/course-links.js'
import MakeTel from './components/make-tel'
import ChooseMap from './components/choose-map'
import './PopSelectLink.scss'
const FormItem = Form.Item
const FormLayout = { labelCol: { span: 2 }, wrapperCol: { span: 21 } }
const { TabPane } = Tabs;

class PopSelectLink extends React.Component {
    state = {
        title: '选择链接',
        visible: false,
        modalLoading: false,
        activeKey: '1',
        currentLink: {},
        callback: null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible: true,
            currentLink: {},
            callback: callback ? callback : null
        })
    }
    close = () => {
        this.setState({
            visible: false
        })
    }
    handleOk = () => {
        let callback = this.state.callback,
            currentLink = this.state.currentLink || {}
        if (typeof callback == 'function' && Object.keys(currentLink).length) {
            callback(currentLink)
        }
        this.close();
    }
    handleCancel = () => {
        this.close();

    }
    clickTab = val => {
        this.setState({
            activeKey: val,
        })
    }
    handleLinkChange = res => {
        let activeKey = Number(this.state.activeKey),
            currentLink = this.state.currentLink;
        switch (activeKey) {
            case 1:
                //常用链接
                this.ItemLinks && this.ItemLinks.clearData()
                this.CourseLinks && this.CourseLinks.clearData()
                break;
            case 2:
                //商品详情
                this.CommonLinks && this.CommonLinks.clearData()
                this.CourseLinks && this.CourseLinks.clearData()
                break;
            case 3:
                //拨打电话
                break;
            case 4:
                //地图导航
                break;
            case 5:
                //课程详情
                this.ItemLinks && this.ItemLinks.clearData()
                this.CommonLinks && this.CommonLinks.clearData()
                break;
        }
        if (res[0].linkType == 7 && currentLink.link) {
            //自定义链接特殊处理
            res[0].link = res[0]['link'] || currentLink.link
        }
        this.setState({
            currentLink: res[0]
        })
    }
    handleInputChange = e => {
        let currentLink = this.state.currentLink,
            val = e.target.value;
        if (currentLink.linkType == 7 || currentLink.linkType == undefined) {
            currentLink.linkType = 7;
            currentLink.name = '自定义链接';
            currentLink.link = val;
        }
        this.setState({
            currentLink
        })
    }
    renderTabContent = (item, index) => {
        switch (item.type) {
            case 1:
                //常用链接
                return (
                    <CommonLinks onRef={e => { this.CommonLinks = e }} data={item} onChange={this.handleLinkChange} ></CommonLinks>
                )
            case 2:
                //商品链接
                return (
                    <ItemLinks onRef={e => { this.ItemLinks = e }} data={item} onChange={this.handleLinkChange}></ItemLinks>
                )
            case 5:
                //课程链接
                return (
                    <CourseLinks onRef={e => { this.CourseLinks = e }} data={item} onChange={this.handleLinkChange}></CourseLinks>
                )
            case 3:
                //拨打电话
                return (
                    <MakeTel onRef={e => { this.MakeTel = e }} data={item} onChange={this.handleLinkChange}></MakeTel>
                )
            case 4:
                //地图导航
                return (
                    <ChooseMap onRef={e => { this.ChooseMap = e }} data={item} onChange={this.handleLinkChange}></ChooseMap>
                )
        }

    }
    render() {
        let currentLink = this.state.currentLink || {}
        return (
            <div>
                <Form>
                    <Modal
                        title={this.state.title}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        width={915}
                        confirmLoading={this.state.modalLoading}
                        maskClosable={false}
                        centered={true}
                    >
                        <div className={`selectLink-content`}>
                            <div className={`panel-wrap`}>
                                <div className={`panel-title`}>
                                    <FormItem label={`添加链接`} {...FormLayout}>
                                        <Input
                                            type={`text`}
                                            value={currentLink.link}
                                            onChange={(e) => { this.handleInputChange(e) }}
                                        />
                                        <span className={`fi-help-text`}>选择自定义链接时可以进行修改</span>
                                    </FormItem>
                                </div>
                                <div className={`panel-ctx`}>
                                    <Tabs activeKey={this.state.activeKey} onChange={this.clickTab}>
                                        {
                                            LINK_TYPES.map((item, index) => (
                                                <TabPane tab={item.label} key={String(item.type)}>
                                                    {this.renderTabContent(item, index)}
                                                </TabPane>
                                            ))
                                        }

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
            </div>
        )
    }
}
export default Form.create({})(PopSelectLink)


