import * as actionTypes from '../constants/index'
const shop = (state = '', action) => {
    switch (action.type) {
        case actionTypes.SET_SHOP:
            return action.data;
            break;
        case actionTypes.REMOVE_SHOP:
            return (state = '');
            break;
        default:
            return state;
    }
}
export default shop
