import React, { Component } from 'react'
import { Statistic, Radio, Icon, Tooltip, DatePicker, Select } from 'antd';
import { courseData, courseTrend } from '@/api/data'
import { storeLists } from '@/api/store'
import Loading from '@/components/Loading'
import Chart from '@/components/Chart/Chart'
import StudentTotalRanking from './student-total-ranking';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Option } = Select;

export default class PointsData extends Component {
    state = {
        chartData: {
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: ['增加课时数', '已消耗课时','待消耗课时'],
                top: '20',
                right: '20'
            },
            grid: {
                left: '1%',
                right: '50',
                bottom: '1%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLabel: {
                    //x轴文字的配置
                    interval: 0,
                    show: true,
                },
                splitLine: { show: false },
                data: []
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '增加课时数',
                    type: 'line',
                    stack: '增加课时数',
                    smooth: true,
                    data: []
                },
                {
                    name: '已消耗课时',
                    type: 'line',
                    stack: '已消耗课时',
                    smooth: true,
                    data: []
                },
                {
                    name: '待消耗课时',
                    type: 'line',
                    stack: '待消耗课时',
                    smooth: true,
                    data: []
                }
            ],
            color: ['#FF0000', '#98D87D','#B6CFFF']
        },
        isLoading: false,
        basic: {},
        dateForm: {
            start_time: moment().add(-7, 'd').format('YYYY-MM-DD'),
            end_time: moment().startOf('day').format('YYYY-MM-DD')
        },
        dateType: '1',
        storeLists: [],
        stord_id_1: 0,
        stord_id_2: 0
    }

    componentDidMount() {
        this.fetchStore()

        this.fetchData()
        this.fetchTrend()
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })
        courseData({store_id:this.state.stord_id_1}).then(res => {
            if (res.code == 1) {
                let basic = res.data;
                this.setState({
                    basic
                })
            }

        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    fetchTrend = () => {
        this.setState({
            isLoading: true
        })
        courseTrend({...this.state.dateForm,store_id:this.state.stord_id_2}).then(res => {
            if (res.code == 1) {
                let trend_list = res.data.trend_list;
                let arr1 = [], arr2 = [], arr3 = [], date = [];
                trend_list.forEach(item => {
                    arr1.push(item.give_out_course);
                    arr2.push(item.cost_course );
                    arr3.push(item.last_course );
                    date.push(item.date)
                })
                let chartData = this.state.chartData;
                chartData.series[0].data = arr1;
                chartData.series[1].data = arr2;
                chartData.series[2].data = arr3;

                chartData.xAxis.data = date
                this.setState({
                    chartData
                })

            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleChangeDate = (e) => {
        let value = +e.target.value;
        if (value == 1) {
            this.setState({
                dateForm: {
                    start_time: moment().add(-7, 'd').format('YYYY-MM-DD'),
                    end_time: moment().startOf('day').format('YYYY-MM-DD')
                },
                dateType: value + ''
            }, () => {
                this.fetchTrend()
            })
        } else if (value == 2) {
            this.setState({
                dateForm: {
                    start_time: moment().add(-30, 'd').format('YYYY-MM-DD'),
                    end_time: moment().startOf('day').format('YYYY-MM-DD')
                },
                dateType: value + ''
            }, () => {
                this.fetchTrend()
            })
        }
    }
    handleChangeDateStr = (time, timeStr) => {
        let [start_time, end_time] = timeStr;
        if (!(start_time && end_time)) {
            return
        }
        this.setState({
            dateForm: {
                start_time, end_time
            },
            dateType: '0'
        }, () => {
            this.fetchTrend()
        })
    }

    storeChange = name => {
        return (value) => {
            if (name === 'stord_id_2') {
                this.setState({
                    stord_id_2: value
                },()=>{
                    this.fetchTrend()
                })
            } else {
                this.setState({
                    stord_id_1: value
                },()=>{
                    this.fetchData()
                })
            }
        }
    }

    render() {

        const { basic } = this.state


        return (
            <div>
                <div>
                    <span className='fz18 color-000 mgr20'>选择门店：</span>
                    <Select style={{ width: 200 }} placeholder={'请选择'} value={this.state.stord_id_1} onChange={this.storeChange('stord_id_1')}>
                        <Option value={0}>全部</Option>
                        {
                            this.state.storeLists.map(store => (
                                <Option value={store.id} key={store.id}>{store.name}</Option>
                            ))
                        }
                    </Select>
                    {/* <span className='fz18 color-000 mgr20 mgl20'>时间筛选：</span> */}
                </div>
                <div className='flex mgt15'>
                    <div className='bg-fafafa pd15 radius-3 flex1'  >
                        <Statistic
                            title={
                                <>
                                    待消耗课时数
                                    <Tooltip placement={`right`} title={`当前剩余课时总数`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic?.last_course_num || 0}
                        />
                    </div>
                    <div className='bg-fafafa pd15 radius-3 flex1 mgl30'>
                        <Statistic
                            title={
                                <>
                                    总课时数
                                    <Tooltip placement={`right`} title={`学员累计的总课时数（含购买及商家赠送）`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic?.total_course_num|| 0}
                        />
                    </div>
                    <div className='bg-fafafa pd15 radius-3 flex1 mgl30'>
                        <Statistic
                            title={
                                <>
                                    累计消耗课时数
                                    <Tooltip placement={`right`} title={`学员累计消耗的课时总和（包含正常课时扣减及商家手动扣减数）`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic?.course_num || 0}
                        />
                    </div>

                    <div className='bg-fafafa pd15 radius-3 flex1 mgl30'>
                        <Statistic
                            title={
                                <>
                                    课时消耗率
                                    <Tooltip placement={`right`} title={`累计消耗课时 / 累计总课时`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic.cost_percent}
                            precision={2}
                            suffix="%"
                        />
                    </div>
                </div>
                <div className='mgt20'>
                    <span className='fz18 color-000'>课时趋势</span>
                    <Tooltip placement={`right`} title={`统计时间截止到前一天`}>
                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                    </Tooltip>
                </div>
                <div className='flex mgt15'>
                    <div>
                        <Radio.Group onChange={this.handleChangeDate} value={this.state.dateType} >
                            <Radio.Button value="1">近七天</Radio.Button>
                            <Radio.Button value="2">近30天</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className='mgl20'>
                        <RangePicker onChange={this.handleChangeDateStr} />
                    </div>
                    <div className='mgl20'>
                        <Select style={{ width: 200 }} placeholder={'请选择'} value={this.state.stord_id_2} onChange={this.storeChange('stord_id_2')}>
                            <Option value={0}>全部</Option>
                            {
                                this.state.storeLists.map(store => (
                                    <Option value={store.id} key={store.id}>{store.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                <div>
                    <Chart chartData={this.state.chartData} className={'line-chart'} height={'450px'}></Chart>
                </div>

                <div className='mgt20'>
                    <span className='fz18 color-000'>学员课时累计排行</span>
                    <Tooltip placement={`right`} title={`学员课时变动排行为截止昨日学员累计或待消耗课时数TOP1000排名情况（默认按累计总课时数排行）`}>
                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                    </Tooltip>
                </div>
                <div className="mgt15">
                    <StudentTotalRanking />
                </div>


                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}
