import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, Tag, Modal, Divider, Icon, DatePicker } from 'antd'
import { withRouter } from 'react-router-dom'
import { studentApplyOff, studentAgreeApply } from '@/api/educational'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import LeaveRefuse from './components/leaveRefuse'
import StudentLeave from './components/studentLeave'



const { RangePicker } = DatePicker;
const FormItem = Form.Item;

class EducationalLeave extends React.Component {

    state = {
        searchForm: {
            status: 0,
            type: 0,
            name: ''
        },
        columns: [
            {
                title: '学员',
                dataIndex: '1',

                render: (text, rowData, index) => {
                    return (
                        <div >{rowData.real_name}</div>
                    )
                }
            },
            {
                title: '请假类型',
                dataIndex: '2',

                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.type_name}</div>
                    )
                }
            },
            {
                title: '请假时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p>开始时间：{rowData.start_time}</p>
                            <p>结束时间：{rowData.end_time}</p>
                        </div>
                    )
                }
            },
            {
                title: '提交时间',
                dataIndex: '4',
                render: (text, rowData, index) => {

                    return (
                        <div>{rowData.created_at} </div>
                    )
                }
            },
            {
                title: '提交人',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div> {rowData.user_name}</div>
                    )
                }
            },

            {
                title: '处理状态',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    let status_color = ""
                    if (rowData.status == 1) {
                        status_color = "red"
                    } else if (rowData.status == 3) {
                        status_color = "green"
                    }
                    return (
                        <Tag color={status_color}>{rowData.status_name}</Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '20%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'} >
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDetail(rowData.id) }}>查看</Button>
                            {
                                rowData.status == 1 ?
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => { this.handleConfirm(rowData.id) }}>通过</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => { this.handleRefuse(rowData.id) }}>拒绝</Button>
                                    </> : null
                            }
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        visible: false,
        current_id: "",
        is_leave:false,
    }

    componentDidMount() {
        this.fetchData()
    }


    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        studentApplyOff(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;

                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    handleDetail = id => {
        this.props.history.push({
            pathname: `/educational/leave-detail/` + id
        })
    }

    handleAdd=()=>{
        this.setState({
            is_leave:true
        })
    }

    handleConfirm = id => {
        let _this = this
        Modal.confirm({
            title: '是否通过学员的请假审批?',
            onOk() {
                _this.setState({
                    isLoading: true
                })
                studentAgreeApply({ id }).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                        _this.fetchData()
                    }

                }).finally(() => {
                    _this.setState({
                        isLoading: false
                    })
                })
            }
        });
    }

    handleRefuse = id => {
        this.setState({
            current_id: id,
            visible: true
        })
    }


    search = () => {
        this.props.form.validateFields((err, values) => {
           
            if (!err) {
                let searchForm = {};
                searchForm.name = values.name
                searchForm.type = values.type
                searchForm.status = values.status
                console.log(values);
                if (values.apply_time && values.apply_time.length>0) {
                    searchForm.apply_start_time = moment(values.apply_time[0]).format('YYYY-MM-DD HH:mm')
                    searchForm.apply_end_time = moment(values.apply_time[1]).format('YYYY-MM-DD HH:mm')
                }

                if (values.leave_time && values.leave_time.length>0) {
                    searchForm.start_time = moment(values.leave_time[0]).format('YYYY-MM-DD HH:mm')
                    searchForm.end_time = moment(values.leave_time[1]).format('YYYY-MM-DD HH:mm')
                }

               

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = {
            name: "",
            status: 0,
            type: 0
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }





    handleCancel = () => {
        this.setState({
            visible: false
        })
    }


    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <div className={'shadow-radius'}>

                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'学员'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入学员名称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'处理状态'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择处理状态'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>待处理</Select.Option>
                                            <Select.Option value={2}>已撤销</Select.Option>
                                            <Select.Option value={3}>已通过</Select.Option>
                                            <Select.Option value={4}>已拒绝</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'请假类型'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('type', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择请假类型'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>事假</Select.Option>
                                            <Select.Option value={2}>病假</Select.Option>
                                            <Select.Option value={3}>其他</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`} >
                            <FormItem className={'fl'} label={'申请时间'}>
                                {
                                    getFieldDecorator('apply_time', {
                                    })(
                                        <RangePicker className={`mgl10`} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" ></RangePicker>
                                    )
                                }
                            </FormItem>

                            <FormItem className={' fl mgl20 '} label={"请假时间"}>
                                {
                                    getFieldDecorator('leave_time', {
                                    })(
                                        <RangePicker className={`mgl10`} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" ></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'mgl70 fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>给学员请假</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>


                {this.state.visible ?
                    <LeaveRefuse close={this.handleCancel} success={this.fetchData} id={this.state.current_id} />
                    : null}
                
                {this.state.is_leave?<StudentLeave close={()=>this.setState({is_leave:false})} success={ this.fetchData} />:null}
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }


}

export default Form.create({})(withRouter(EducationalLeave))


