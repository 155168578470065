import React from 'react'
import { Modal,Tabs,Icon } from 'antd'
import IconImgSrc from './constants/IconImgSrc'
import Gallery from '@/components/Gallery/Gallery'
import message from '@/utils/message'
import './NavIconPicker.scss'
const { TabPane } = Tabs
class NavIconPicker extends React.Component{
    state = {
        title:'选择图标',
        visible:false,
        modalLoading:false,
        activeStatus:'1',
        iconUrl:'',
        customUrl:'',
        callback:null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            customUrl:'',
            callback:callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let activeStatus = Number(this.state.activeStatus),
            callback = this.state.callback,
            res = {};
        switch (activeStatus) {
            case 1:
                res.icon = this.state.iconUrl || ''
                break;
            case 2:
                res.icon = this.state.customUrl || ''
                break;
        }
        if(!res.icon){
           message.error('请选择图标');
           return ;
        }
        if(typeof callback == 'function'){
            callback(res)
        }
        this.close();

    }
    handleCancel = () => {
        this.close()
    }
    clickTab = (val) => {
        this.setState({
            activeStatus:val
        })
    }
    chooseImg = () => {
        this.Gallery.show(res => {
            if(res){
                this.setState({
                    customUrl:res[0]
                })
            }
        })
    }
    delImg = (e) => {
        e.stopPropagation();
        this.setState({
            customUrl:''
        })
    }
    handleChoose = (icon) => {
        this.setState({
            iconUrl:icon
        })
    }



    render() {
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={550}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
            >
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Tabs  onChange={this.clickTab} type="card" className={''} activeKey={this.state.activeStatus}>
                    <TabPane tab="系统图标" key={`1`}></TabPane>
                    <TabPane tab="自定义上传" key={`2`}></TabPane>
                </Tabs>
                {
                    this.state.activeStatus == 1
                        ?
                        <div className={`albums-icon-tab`}>
                            {
                                IconImgSrc.map((color,colorIndex) => (
                                    <div className={`icon-color-line`} key={colorIndex}>
                                        {
                                            color.icons.map((icon,iconIndex) => (
                                                <div className={`icon-color ${ this.state.iconUrl == icon ? 'active' : '' }`} key={iconIndex} onClick={ () => { this.handleChoose(icon) } }>
                                                    <img src={ icon } alt=""/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        :
                        null
                }
                {
                    this.state.activeStatus == 2
                        ?
                        <div className={`albums-icon-tab pdt10`} >
                            <div className={'clearfix'}>
                                <div className={'img-add'} onClick={this.chooseImg} data-name="logo">
                                    {
                                        this.state.customUrl
                                            ?
                                            <div>
                                                <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={this.delImg} data-name={'logo'}  />
                                                <img src={this.state.customUrl} alt=""/>
                                            </div>
                                            :
                                            '+'
                                    }
                                </div>
                            </div>
                            <span className={'fi-help-text'}>建议上传尺寸：80px*80px</span>

                        </div>
                        :
                        null
                }

            </Modal>
        )
    }
}
export default NavIconPicker
