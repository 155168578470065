import React from 'react'
import { Form, Tabs, Switch, Dropdown, Menu, Button } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import FxGoods from './components/fx-goods'
import FxCards from './components/fx-cards'
import { getConfig, setConfig } from '@/api/config'
const FormItem = Form.Item
const { TabPane } = Tabs

class FxGoodsLists extends React.Component{
    state = {
        isOpenGoods:'',
        activeStatus:'1',
        isLoading:false,

    }

    componentDidMount() {
        this.fetchConfig()
    }
    fetchConfig = () => {
        let sendData = {}
        sendData.key = 'agent_goods'
        this.setState({
            isLoading:true
        })
        getConfig(sendData)
            .then(res => {
                if(res.code == 1){
                    let isOpenGoods = res.data.config.value || 0;
                    this.setState({
                        isOpenGoods
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }

    handleSwitch = val => {
        let sendData = {}
        sendData.key='agent_goods';
        if(val){
            sendData.value = 1;
        }else {
            sendData.value = 0;
        }
        this.setState({
            isLoading:true,
            isOpenGoods:sendData.value
        })
        setConfig(sendData)
            .then(res => {
                if(res.code == 1){
                    let activeStatus = Number(this.state.activeStatus);
                    switch (activeStatus) {
                        case 1:
                            this.FxGoods.handleReset();
                            break;
                        case 2:
                            this.FxCards.handleReset();
                            break;
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    clickTab = (val) => {
        this.setState({
            activeStatus:val
        })
    }
    handleMenuClick = (e) => {
        let key = Number(e.key)
        switch (key) {
            case 1:
                //商品
                this.FxGoods.handleAddItem();
                break;
            case 2:
                //卡
                this.FxCards.handleAddItem();

                break;
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form
        const dropMenu = (
            <Menu onClick={this.handleMenuClick}>
                {
                    this.state.activeStatus == 1
                        ?
                        <Menu.Item key="1">添加分销商品</Menu.Item>
                        :
                        <Menu.Item key="2">添加卡项</Menu.Item>
                }
            </Menu>
        )
        return(
            <div className={`shadow-radius`}>
                <div className={`sysPanel`}>
                    <div className={`sysPanel-con`}>
                        <div className={`sysPanel-title`}>全店商品参与</div>
                        <div className={`sysPanel-tip`}>开启后，切换为全店商品模式，新上架的商品将自动加入分销；关闭后，切换为指定商品模式</div>
                    </div>
                    <div className={`list-switch`}>
                        <Switch onChange={ this.handleSwitch } checked={ this.state.isOpenGoods == 1 ? true : false }></Switch>
                    </div>
                </div>
                {
                    this.state.isOpenGoods == 1
                        ?
                        null
                        :
                        <div className={'mgt15'}>
                            <Dropdown overlay={dropMenu}>
                                <Button type={'primary'} icon={'plus'}>添加分销商品</Button>
                            </Dropdown>
                        </div>
                }

                <Tabs  onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="商品" key={`1`}>
                        <FxGoods onRef={ e => { this.FxGoods = e } }></FxGoods>
                    </TabPane>
                    <TabPane tab="卡项" key={`2`}>
                        <FxCards onRef={ e => { this.FxCards = e } }></FxCards>
                    </TabPane>
                </Tabs>

                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}
export default Form.create({})(withRouter(FxGoodsLists))
