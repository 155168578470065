import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
const FormItem = Form.Item

class CouponGift extends React.Component{
    state = {}
    componentDidMount() {}
    render() {
        return(
            <div className={`shadow-radius`}>转赠记录</div>
        )
    }
}

export default Form.create({})(withRouter(CouponGift))
