import React, { Component } from 'react'
import { Modal, Button, Table, Pagination, Divider, Popover, Form, Input, InputNumber, Row, Col,message } from 'antd';
import { articleTopic, articleTopicSave,articleTopicDele } from '@/api/recommend.js'
const FormItem = Form.Item;

class TopicManage extends Component {
    state = {
        visible: false,
        page: 1,
        per_page: 10,
        total: 0,
        tableLoading: false,
        tableData: [],
        columns: [
            {
                title: '话题名称',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    if(rowData.is_edit){
                        return <Input defaultValue={rowData.name} onChange={e=>{this.handleChangeEmit(e.target.value,'name')}} />
                    }

                    return (
                        <p> { rowData.name}</p>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: '2',
                width:"30%",
                render: (text, rowData, index) => {

                    if(rowData.is_edit){
                        return <InputNumber  min={0} precision={0} defaultValue={rowData.sort } onChange={e=>{this.handleChangeEmit(e,'sort')}} />
                    }

                    return (
                        <p> {rowData.sort  }</p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '3',
                width:"30%",
                render: (text, rowData, index) => {
                    if (rowData.is_edit) {
                        return (
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleEditSave(rowData)}} >保存</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleShowEdit(index,false)}} >取消</Button>
                            </div>
                        )
                    }

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleShowEdit(index,true)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleDele(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        popoverVisible: false,
        callBack:null,
        is_end:false,
        emitForm:{
            name:"",
            sort:undefined
        }
    }

    componentDidMount(){
        this.props.onRef(this)
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;

        this.setState({
            tableLoading: true
        })

        articleTopic(sendData).then(res => {
            if(res.code==1){
                let {current_page,per_page ,total,data}=res.data.list
                this.setState({
                    page:current_page,
                    total,
                    per_page,
                    tableData:data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    show=(callBack)=>{
        this.setState({
            visible:true,
            callBack:callBack || null
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            popoverVisible:false
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    handleVisibleChange = () => {
        this.setState({
            popoverVisible: true,

        })
    }

    popoverHide = () => {
        this.setState({
            popoverVisible: false
        })
    }

    handleAddTopic=()=>{
        this.props.form.validateFieldsAndScroll((err, values) =>{
            if(!err){
                let sendData=values

                if(this.is_end){
                    return
                }
                this.setState({is_end:true})
                articleTopicSave(sendData).then(res=>{
                    if(res.code == 1){
                        message.success(res.msg)
                        this.fetchData()
                    }
                }).finally(()=>{
                    this.setState({
                        is_end:false,
                        popoverVisible:false
                    })
                })

            }
        })
    }

    handleShowEdit=(index,bool)=>{
        let {tableData,emitForm}=this.state
        tableData[index].is_edit=bool
        emitForm.name=tableData[index].name
        emitForm.sort=tableData[index].sort
        this.setState({
            tableData,
            emitForm
        })
    }

    handleChangeEmit=(value,name)=>{
        let emitForm=this.state.emitForm
        emitForm[name]=value
        this.setState({
            emitForm
        })
    }

    handleEditSave=row=>{
        let emitForm=this.state.emitForm,sendData
        sendData={...emitForm}
        sendData.topic_id=row.id

        if(this.is_end){
            return
        }
        this.setState({is_end:true})
        articleTopicSave(sendData).then(res=>{
            if(res.code == 1){
                this.fetchData()
            }
        }).finally(()=>{
            this.setState({
                is_end:false
            })
        })
    }

    handleDele=row=>{
        articleTopicDele({topic_ids:[row.id]}).then(res=>{
            if(res.code == 1){
                this.fetchData()
            }
        })
    }
  


    render() {
        const formItemLayout = {
            labelCol: {
                span: 8
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        const formNode = (
            <Form {...formItemLayout} className={'model-form'}>

                <Row gutter={24}>
                    <Col span={24}>
                        <FormItem label="话题名称">
                            {getFieldDecorator('name', {
                                initialValue: "",
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入话题名称'
                                    }
                                ]
                            })(<Input placeholder='请输入话题名称' maxLength={30} />)}
                        </FormItem>
                        <FormItem label="排序">
                            {getFieldDecorator('sort', {
                                initialValue: ""
                            })(<InputNumber  min={0} style={{ width: '100%' }} precision={0} />)}
                        </FormItem>
                        <div className="tar">
                            <Button type={"default"}  onClick={() => { this.popoverHide() }}>取消</Button>
                            <Button type='primary' className={'mgl10'} onClick={this.handleAddTopic} >确认</Button>
                        </div>
                    </Col>
                  
                </Row>
            </Form>
        )
        return (
            <Modal
                title="话题管理"
                visible={this.state.visible}
                onCancel={this.handleCancel}
                footer={null}
                width={800}
            >
                <div>
                    <Popover
                        content={formNode}
                        trigger="click"
                        visible={this.state.popoverVisible}
                        onVisibleChange={this.handleVisibleChange}
                        placement="rightTop"
                    >
                        <Button type={'primary'} icon={'plus'}>添加话题</Button>
                    </Popover>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </Modal>
        )
    }
}

export default Form.create({})(TopicManage)
