import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Modal, Tag, Tabs, message } from 'antd'
import moment from 'moment'
import Loading from '@/components/Loading'
import { articleMessage, articleMessageDele, articleMessageSetStatus } from '@/api/recommend.js'

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs

class CommentManage extends Component {
    state = {
        columns: [
            {
                title: '评论内容',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.content || ""}</p>
                    )
                }
            },
            {
                title: '评论人',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name} </p>
                    )
                }
            },
            {
                title: '评论时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title: '公开状态',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    let color = "", tag_text = ""
                    if (rowData.status == 3 || rowData.status == 4) {
                        color = ""
                        tag_text = "隐藏"
                    } else if (rowData.status == 1) {
                        color = "orange"
                        tag_text = "待审核"
                    } else if (rowData.status == 2) {
                        color = "green"
                        tag_text = "公开"
                    }

                    return (
                        <Tag color={color}>{tag_text} </Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '7',
                width: '250px',
                render: (text, rowData, index) => {
                    if (rowData.status == 1) {
                        return (
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleStatusChange([rowData.id],2)}}>通过</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleStatusChange([rowData.id],3)}}>拒绝</Button>
                            </div>
                        )
                    } else if (rowData.status == 2) {
                        return (
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleStatusChange([rowData.id],4)}}>隐藏</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={()=>{this.seeComment(rowData.article_id)}}>查看相关评论</Button>
                            </div>
                        )
                    } else {
                        return (
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleStatusChange([rowData.id],2)}}>公开</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={()=>{this.seeComment(rowData.article_id)}}>查看相关评论</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={()=>{this.handleDele(rowData.id)}}>删除</Button>
                            </div>
                        )
                    }
                }
            }
        ],
        searchForm: {
            name: "",
            start_time: "",
            end_time: ""
        },
        isLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        selectedRowKeys: [],
        activeStatus: '0'
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.status = this.state.activeStatus

        this.setState({
            isLoading: true
        })

        articleMessage(sendData).then(res => {
            if (res.code == 1) {
                let { current_page, per_page, total, data } = res.data.list
                this.setState({
                    page: current_page,
                    per_page,
                    total,
                    tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }

    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    clickTab = val => {
        this.setState({
            activeStatus: val
        }, () => {
            this.fetchData()
        })
    }

    handleStatusChange=(ids,status)=>{
        let sendData={}
        sendData.article_message_ids=ids
        sendData.status=status

        this.setState({
            isLoading:true
        })
        articleMessageSetStatus(sendData).then(res=>{
            if(res.code==1){
                message.success("操作成功！").then(()=>{
                    this.fetchData()
                })
            }
        }).finally(()=>{
            this.setState({
                isLoading:false,
                selectedRowKeys:[]
            })
        })

    }

    handleDele=id=>{
        Modal.confirm({
            title: "是否确认删除？删除后不可恢复",
            onOk:()=>{
                this.setState({
                    isLoading:true
                })
                articleMessageDele({article_message_ids:[id]}).then(res=>{
                    if(res.code==1){
                        message.success('操作成功').then(()=>{
                            this.fetchData()
                        })
                    }
                }).finally(()=>{
                    this.setState({
                        isLoading:false
                    })
                })
            }
        });
    }

    seeComment=id=>{
        this.props.history.push('/recommend/comment-detail/'+id)
    }

    handleBatch=status=>{
        let selectedRowKeys=this.state.selectedRowKeys
        this.handleStatusChange(selectedRowKeys,status)
    }


    render() {
        const { getFieldDecorator } = this.props.form

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            }
        }

        return (
            <div>
                <div className="content-head mgb15">
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'关联笔记'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入笔记标题' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'评论时间'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: null
                                    })(
                                        <RangePicker
                                            style={{ width: 400 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder={['开始时间', '结束时间']}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;</span>} >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>

                <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="全部" key={0}></TabPane>
                    <TabPane tab="待审核" key={1}></TabPane>
                    <TabPane tab="公开" key={2}></TabPane>
                    <TabPane tab="隐藏" key={4}></TabPane>
                </Tabs>


                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={false}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={()=>{this.handleBatch(2)}}>批量通过</Button>
                        <Button className={'btn mgl10'} type={""} onClick={()=>{this.handleBatch(3)}}>批量拒绝</Button>

                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}


export default Form.create({})(withRouter(CommentManage))
