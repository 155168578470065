import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Checkbox, Radio, Input, Button, Icon } from 'antd'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import { getPoster, saveRecruit } from '@/api/fx'
import { uploadFile } from '@/api/file'
import Loading from '@/components/Loading'
import Cookie from 'js-cookie'
import message from '@/utils/message'
import '@/assets/css/fx/fx.scss'
const FormItem = Form.Item

class RecruitPoster extends React.Component{
    state = {
        formData:{
            bg_img:'https://img.cxkoo.com/chengxuan/1/2022-02-28/b442a88b8bd6ddc3435206f9064b32d7.png',
            title:'',
            font_size:'',
            color:'',
            apply_info:[]
        },
        isLoading:false,
        default_img:"https://img.cxkoo.com/chengxuan/1/2022-02-28/b442a88b8bd6ddc3435206f9064b32d7.png"
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData.type = 1;
        this.setState({
            isLoading:true
        })
        getPoster(sendData)
            .then(res => {
                if(res.code == 1){
                    let formData = res.data.default || '';
                    if(formData){
                        this.setState({
                            formData
                        })
                    }

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }
    handleResetImg=(keyNmae)=>{
        return ()=>{
            const formData=this.state.formData || {}
            formData[keyNmae]=this.state.default_img
            this.setState({
                formData
            })
        }
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            formData = this.state.formData || {};
        formData[name] = hex;
        this.setState({
            formData
        })

    }
    handleResetColor = e => {
        let name = e.currentTarget.dataset.name || ``,
            color = e.currentTarget.dataset.color || ``,
            formData = this.state.formData || {};
        formData[name] = color;
        this.setState({
            formData
        })
    }
    uploadChange = e => {
        let files  = document.getElementById("file"+this.state.uniqueId).files,
            formData = this.state.formData || {}
        files = Array.from(files);
        let fd = new FormData()
        files.forEach(item => {
            fd.append('Filedata[]',item);
        })
        fd.append('cate_id',this.state.category_id)
        fd.append('api_token',Cookie.get('token') || ``)
        this.setState({
            isLoading:true
        })
        uploadFile(fd)
            .then(res => {
                if(res.code == 1){
                    formData.agreement_file = res.data.url;
                    formData.agreement_file_name = files[0]['name'];
                    this.setState({
                        formData
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    handleAddApply = () => {
        let formData = this.state.formData || {},
            apply_info = formData.apply_info || [],
            newData = { name:'', required:0 };
        if(apply_info.length > 2){ return ; };
        apply_info.push(newData);
        formData.apply_info = apply_info;
        this.setState({
            formData
        })
    }
    handleDelApply = (rowData,index) => {
        let formData = this.state.formData || {},
            apply_info = formData.apply_info;
        apply_info.splice(index,1);
        formData.apply_info = apply_info;
        this.setState({
            formData
        })
    }
    handleInputChange = (e,rowData,index) => {
        let value = e.target.value,
            formData = this.state.formData || {},
            apply_info = formData.apply_info;
        apply_info[index]['name'] = value;
        formData.apply_info = apply_info;
        this.setState({
            formData
        })

    }
    handleCheckChange = (e,rowData,index) => {
        let checked = e.target.checked,
            formData = this.state.formData || {},
            apply_info = formData.apply_info;
        apply_info[index]['required'] = checked ? 1 : 0;
        formData.apply_info = apply_info;
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                for(var i in values){
                    formData[i] = values[i]
                }
                sendData = formData;
                for(var x in sendData){
                    if(typeof sendData[x] === 'boolean'){
                        sendData[x] = sendData[x] ? 1 : 0
                    }
                }
                if(sendData.apply_info){
                    sendData.apply_info.forEach(child => {
                        child.required = child.required ? 1 : 0
                    })
                }
                sendData.bg_img = sendData.bg_img || 'https://img.cxkoo.com/chengxuan/1/2022-02-28/b442a88b8bd6ddc3435206f9064b32d7.png'
                this.setState({
                    isLoading:true
                })
                saveRecruit(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }

    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol:{
                span:3
            },
            wrapperCol:{
                span:20
            }
        }
        return(
            <div className={``}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <div className={' clearfix mgt15'}>
                    <div className={'diy-fx-phone recruit fl'}>
                        <div className={'phone-title'}>
                            <div className={`title-txt`}>招募海报</div>
                        </div>
                        <div className={'phone-angle'}></div>
                        <div
                            className={'phone-inner-bg'}
                            style={{ backgroundColor:'#fff' }}
                        >
                            <div className={`recruit-ad`} style={{ backgroundImage:`url(${ data.bg_img ? data.bg_img : 'https://img.cxkoo.com/chengxuan/1/2022-02-28/b442a88b8bd6ddc3435206f9064b32d7.png' })` }}></div>
                            <div className="recruit-form-cont">
                                <div className="title" style={{ fontSize:`${getFieldValue('font_size')}px`, color:`${data.color}` }}>{ getFieldValue('title') }</div>
                                <div className="sub-title">为了保证您的奖励可以成功发放，请直接获取微信认证过的手机号（“*”为必填信息）。</div>
                                <div className="form-inner">
                                    <div className="form-item">
                                        <div className="fi-name required">
                                            <div className="fi-txt">姓名</div>
                                        </div>
                                        <div className="form-control">
                                            <div className="input placeholder">请填写姓名</div>
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <div className="fi-name required">
                                            <div className="fi-txt">手机</div>
                                        </div>
                                        <div className="form-control">
                                            <div className="input placeholder">请获取手机号</div>
                                            <div className="mobile-btn">获取微信手机号</div>
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <div className="fi-name">
                                            <div className="fi-txt">邀请码</div>
                                        </div>
                                        <div className="form-control">
                                            <div className="input placeholder">请填写</div>
                                        </div>
                                    </div>
                                    {
                                        data.apply_info && data.apply_info.map((item,index) => (
                                            <div className="form-item" key={index}>
                                                <div className={`fi-name ${item.required ? `required` : ''}`}>
                                                    <div className="fi-txt">{ item.name }</div>
                                                </div>
                                                <div className="form-control">
                                                    <div className="input placeholder">请填写</div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                                <div className="bottom-inner">
                                    <div className="agree-file">
                                        <div className="check"></div>
                                        <div className="file-txt">
                                            我已阅读并同意
                                            <div className="txt1">《分销员招募章程》</div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn">
                                        提交申请
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'diy-fx-ctrl fl mgl20'}>
                        <div className={`data-title`}>
                            <span className={`fi-title`}>基本信息</span>
                            <span className={`fi-sub-title mgl15`}></span>
                        </div>
                        <Form className={`model-form`} { ...formItemLayout }>
                            <FormItem label={`背景图`}>
                                <div className={'clearfix'}>
                                    {
                                        data.bg_img
                                            ?
                                            <PictureCard
                                                onRef={ e => { this.PictureCard = e } }
                                                imgs={ data.bg_img }
                                                onDel={ e => { this.delImg(e,'bg_img') } }
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={ e => { this.ImgAdd = e } }
                                                onClick={ e => { this.chooseImg(e,'bg_img') } }
                                            ></ImgAdd>
                                    }
                                    <Button type='link' className='mgt60' onClick={this.handleResetImg('bg_img')}>恢复默认</Button>
                                </div>
                                <span className={'fi-help-text'}>建议上传尺寸：750px*420px</span>
                            </FormItem>
                            <FormItem label={`标题`}>
                                {getFieldDecorator('title', {
                                    initialValue: data.title || '申请成为分销员',
                                })(
                                    <Input style={{width: '200px'}} placeholder={`请输入标题`}  />
                                )}
                            </FormItem>
                            <FormItem label={`字号`}>
                                {getFieldDecorator('font_size', {
                                    initialValue: data.font_size || '25',
                                })(
                                    <Input style={{width: '200px'}} placeholder={`请输入字号`}  />
                                )}
                            </FormItem>
                            <FormItem label={`颜色`}>
                                <div className={`flex`}>
                                    {getFieldDecorator('color', {
                                        initialValue: data.color || '',
                                    })(
                                        <ColorPicker
                                            onRef={e => { this.ColorPicker = e }}
                                            color={data.color || '#000000'}
                                            callback={ (color) => { this.handleColorChange(color,'color') } }
                                        ></ColorPicker>
                                    )}
                                    <span className={`fi-link mgl10`} onClick={ this.handleResetColor } data-name="color" data-color="#000000">重置</span>
                                </div>
                            </FormItem>
                            <FormItem label={`申请信息`}>
                                <div className={`custom-form`}>
                                    <div className={`custom-form-item`}>
                                        <div className={`form-item-label`}>
                                            姓名
                                        </div>
                                        <div className={`form-item-label`}>
                                            <Checkbox checked={true} disabled={true}>必填</Checkbox>
                                        </div>
                                    </div>
                                    <div className={`custom-form-item`}>
                                        <div className={`form-item-label`}>
                                            手机
                                        </div>
                                        <div className={`form-item-label`}>
                                            <Checkbox checked={true} disabled={true}>必填</Checkbox>
                                        </div>
                                    </div>
                                    <div className={`custom-form-item`}>
                                        <div className={`form-item-label`}>
                                            邀请码
                                        </div>
                                        <div className={`form-item-label`}>
                                            <Checkbox checked={false} disabled={true}>必填</Checkbox>
                                        </div>
                                    </div>
                                    {
                                        data.apply_info && data.apply_info.map((item,index) => (
                                            <div className={`custom-form-item`} key={index}>
                                                <div className={`form-item-label`}>
                                                    {
                                                        getFieldDecorator(`apply_info[${index}].name`,{
                                                            initialValue: item.name || '',
                                                        })(
                                                            <Input
                                                                style={{ width:'100px' }}
                                                                placeholder={`请输入`}
                                                                maxLength={5}
                                                                onChange={ e => { this.handleInputChange(e,item,index) } }
                                                            />
                                                        )
                                                    }
                                                </div>
                                                <div className={`form-item-label`}>
                                                    {
                                                        getFieldDecorator(`apply_info[${index}].required`, {
                                                            initialValue: item.required || 0,
                                                            valuePropName: 'checked'
                                                        })(
                                                            <Checkbox onChange={ e => { this.handleCheckChange(e,item,index) } } >必填</Checkbox>
                                                        )
                                                    }
                                                </div>
                                                <Icon type="close-circle" theme="filled"  className={`custom-delete-icon`} onClick={() => { this.handleDelApply(item,index) }}  />
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    data.apply_info && data.apply_info.length > 2
                                        ?
                                        null
                                        :
                                        <div className={`mgt15`}>
                                            <Button
                                                type={'default'}
                                                onClick={this.handleAddApply}
                                                icon={'plus'}
                                                style={{ width:'280px' }}
                                            >增加自定义信息</Button>
                                        </div>
                                }

                            </FormItem>
                            <FormItem label={'招募章程'}>
                                {
                                    getFieldDecorator('is_agreement', {
                                        initialValue: data.is_agreement || 0,
                                        valuePropName: 'checked'
                                    })(
                                        <Checkbox className={`mgr5`}></Checkbox>
                                    )
                                }
                                需勾选同意招募章程
                                <span style={{'position':'relative'}}>
                                        <Button type={`default`} className={`mgl5`}>添加章程(pdf)</Button>
                                        <Input type="file" id={`file${this.state.uniqueId}`}  className={'pdf-file-input'} multiple="multiple" accept="application/pdf" onChange={this.uploadChange} ></Input>
                                    </span>
                                {
                                    data.agreement_file && data.agreement_file_name
                                        ?
                                        <span className={`fi-help-text`}>已选择{data.agreement_file_name}</span>
                                        :
                                        <span className={`fi-help-text`}>未选择任何文件</span>
                                }
                            </FormItem>

                            <FormItem label={` `} colon={false}>
                                <Button type={`primary`} onClick={ this.saveData }>立即保存</Button>
                            </FormItem>

                        </Form>
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}
export default Form.create({})(withRouter(RecruitPoster))
