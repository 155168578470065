/**
 * 分割线组件
 * @type 4
 * @return Object
 */

import React from 'react'
import { Form, Slider, Radio, InputNumber ,Button} from 'antd'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import PropTypes from "prop-types";
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 6 }, wrapperCol: { span: 17 }}

class tpl_ctrl_type4 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }

    state = {
        moduleData:{}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    handleStyle = e => {
        let val = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content.borderStyle = val;
        this.context.updateDesign(moduleData)
    }
    handleColorChange = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content.color = hex;
        this.context.updateDesign(moduleData)
    }
    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>分割线</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`线条样式`} { ...ctrlItemLayout } >
                                    <Radio.Group value={moduleData.content.borderStyle} onChange={ this.handleStyle }>
                                        <Radio value={0}>实线</Radio>
                                        <Radio value={1}>虚线</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`线条颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.color}  callback={this.handleColorChange} ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('color','#DDDDDD')}>重置</Button>
                                </FormItem>
                            </div>

                        </div>
                    </Form>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type4)
