/**
 * 图文导航组件
 * { linkType: 0, link: '', pic: '', title: '' }
 * @type 3
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})

class tpl_con_type3 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }
    state = {
        moduleData:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这个模块吗？`,
            okType:`danger`,
            onOk:() => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {};

        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`图文导航`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{ paddingTop:`${moduleData.content.modulePadding}px`,paddingBottom:`${moduleData.content.modulePadding}px`}} onClick={ this.handleEditModule }>
                        <div className={`img-nav-swiper style${moduleData.content.style || 1}`} style={{ backgroundColor:moduleData.content.bgColor }}>
                            <div className={`swiper`}>
                                <div className={`img-nav-cont ${ moduleData.content.style == 1 && moduleData.content.dataset.length < 5 ? 'space-around' : '' }`}>
                                    {
                                        moduleData.content.dataset.length
                                            ?
                                            moduleData.content.dataset.map((item,index) => {
                                                return(
                                                    moduleData.content.style == 1 && index < 5
                                                        ?
                                                        <div className={`img-nav-item`}  key={index}>
                                                            <div className={`nav-item-img`} style={{ backgroundImage:`url(${ item.pic })` }}></div>
                                                            <div className={`nav-item-txt`} style={{ color:moduleData.content.txtColor }}>{ item.title }</div>
                                                        </div>
                                                        :
                                                        moduleData.content.style == 2 && index < 10
                                                            ?
                                                            <div className={`img-nav-item`}  key={index}>
                                                                <div className={`nav-item-img`} style={{ backgroundImage:`url(${ item.pic })` }}></div>
                                                                <div className={`nav-item-txt`} style={{ color:moduleData.content.txtColor }}>{ item.title }</div>
                                                            </div>
                                                            :
                                                            null
                                                )


                                            })
                                            :
                                            moduleData.content.style == 1
                                                ?
                                                [1,2,3,4,5].map((item,index) => (
                                                    <div className={`img-nav-item`} key={index}>
                                                        <div className={`nav-item-img`} style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                        <div className={`nav-item-txt`} style={{ color:moduleData.content.txtColor }}>示例标题</div>
                                                    </div>
                                                ))
                                                :
                                                [1,2,3,4,5,6,7,8,9,10].map((item,index) => (
                                                    <div className={`img-nav-item`} key={index}>
                                                        <div className={`nav-item-img`} style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                        <div className={`nav-item-txt`} style={{ color:moduleData.content.txtColor }}>示例标题</div>
                                                    </div>
                                                ))
                                    }
                                </div>
                            </div>
                        </div>
                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type3)
