import React from 'react'
import { Form, Input, Button, Table, Pagination, Tag, Modal, Divider } from 'antd'
import { withRouter } from 'react-router-dom'
import { printLists, addPrint, editPrint, delPrint, setPrintScene } from '@/api/print'
import { storeLists } from '@/api/store'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import AddPrint from './components/add-print'
import PrintScene from './components/print-scene'
const FormItem = Form.Item;
class Print extends React.Component{
    state = {
        columns:[
            {
                title:'打印机名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'打印机终端号',
                dataIndex:'terminal_no',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.terminal_no}</p>
                    )
                }
            },
            {
                title:'所在门店',
                dataIndex:'store_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.store_name}</p>
                    )
                }
            },
            {
                title:'使用场景',
                dataIndex:'scene_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.scene && rowData.scene.length
                                    ?
                                    <div dangerouslySetInnerHTML = {{ __html:rowData.scene_name }} className={`lh25`}></div>
                                    :
                                    <p className={`fi-link`} onClick={() => {this.handleScene(rowData)}}>{rowData.scene_name}</p>
                            }
                        </div>
                    )
                }
            },
            {
                title:'添加时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>配置</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleScene(rowData)}}>分配小票</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            id:'',
            name:''
        },
        storeLists:[],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow: null

    }

    componentDidMount() {
        this.fetchData()
        this.fetchStore();
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        printLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.printer_list.data,
                        total = res.data.printer_list.total,
                        per_page = res.data.printer_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        let sendData = {}
        this.setState({
            currentRow:null
        },() => {
            this.AddPrint.show((res) => {
                sendData.name = res.name;
                sendData.terminal_no = res.terminal_no;
                sendData.terminal_secret = res.terminal_secret;
                sendData.store_id = res.store_id;
                this.setState({ isLoading : true })
                addPrint(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('添加成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({ isLoading : false })
                    })


            })
        })

    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleEdit = rowData => {
        let sendData = {}
        this.setState({
            currentRow:rowData
        },() => {
            this.AddPrint.show(res => {
                sendData.id = rowData.id || ``;
                sendData.name = res.name;
                sendData.terminal_no = res.terminal_no;
                sendData.terminal_secret = res.terminal_secret;
                sendData.store_id = res.store_id;
                this.setState({ isLoading : true })
                editPrint(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('编辑成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({ isLoading : false })
                    })
            })
        })
    }
    handleDelete = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        Modal.confirm({
            title:`是否删除该打印机？请谨慎操作`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                delPrint(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleScene = row => {
        let sendData = {}
        sendData.id = row.id || '';
        this.setState({
            currentRow:row
        },() => {
            this.PrintScene.show(res => {
                if(res){
                    sendData.scene_data = res;
                    this.setState({ isLoading : true })
                    setPrintScene(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('分配成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({ isLoading : false })
                        })

                }
            })
        })

    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <AddPrint onRef={ e => { this.AddPrint = e } } storeLists={this.state.storeLists} data={this.state.currentRow}></AddPrint>
                <PrintScene onRef={ e => { this.PrintScene = e } } scene={ this.state.currentRow ? this.state.currentRow.scene : [] }></PrintScene>
                <div className={`content-msg mgb10`}>
                    <p>
                        系统支持易联云K6热敏小票打印机
                    </p>
                </div>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'打印机名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索打印机名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>添加打印机</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(Print))
