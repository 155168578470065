import $axios from '@/axios/axios.js'

export function timesCardLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/time-card/lists`,
        method:'post',
        data
    })
}

export function suitStores(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/time-card/suitStores`,
        method:'post',
        data
    })
}

export function timesCardSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/time-card/save`,
        method:'post',
        data
    })
}

export function timesCardDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/time-card/detail`,
        method:'post',
        data
    })
}

export function timesCardDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/time-card/delete`,
        method:'post',
        data
    })
}

export function timesCardStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/time-card/setStatus`,
        method:'post',
        data
    })
}

export function timesCardItem(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/trade/timeCardItem`,
        method:'post',
        data
    })
}

export function userTimesCard(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/timeCardDetail`,
        method:'post',
        data
    })
}

export function timesCardOpenDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/time-card/cardDetail`,
        method:'post',
        data
    })
}


export function changeEndDate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/changeTimeCardEndDate`,
        method:'post',
        data
    })
}

export function disUseTimesCard(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/disuseTimeCard`,
        method:'post',
        data
    })
}

