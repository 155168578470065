import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, DatePicker } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class CardActive extends React.Component{
    state = {
        title:'修改有效期',
        visible:false,
        modalLoading:false,
        callback:null,
        current:''

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false,
            current:''
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.end_date = values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : ''
                if(typeof callback == 'function'){
                    callback(values)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    handleSelectChange = val => {
        let rechargeRules = this.props.rechargeRules,
            current = rechargeRules[val];
        console.log(current)
        this.setState({
            current
        })
    }
    render() {
        const data = this.props.data || {}
        let current = this.state.current || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`有效期至`}>
                        {getFieldDecorator('type', {
                            initialValue: data.type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择有效期'
                                }
                            ]
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`block mgb15`}>
                                    {
                                        getFieldDecorator('end_date',{
                                            initialValue:data.end_date ? moment(data.end_date, 'YYYY-MM-DD')   : null
                                        })(
                                            <DatePicker />
                                        )
                                    }
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    原有效期后
                                    {
                                        getFieldDecorator('days',{
                                            initialValue:data.days || ''
                                        })(
                                            <Input type={'number'}  style={{ width:'80px' }} className={`mgl10`}></Input>
                                        )
                                    }
                                    <span className={`mgl10`}>天</span>
                                </Radio>
                            </Radio.Group>
                        )}
                        <span className={`fi-help-text`}>
                            本操作仅对状态为“使用中”和“已过期”的卡有效
                        </span>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(CardActive))
