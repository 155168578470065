import React from 'react'
import { Input, Form, Radio, Switch } from 'antd'
import { withRouter, Link } from 'react-router-dom'
const FormItem = Form.Item

class PointItemSku extends React.Component{
    state = {
        title:'设置活动折扣',
        visible:false,
        modalLoading:false,
        callback:null,
        tableData:[],
        sku:{},
        skuCache:{},
        props:{}, //属性
        norms:[], //规格
        currentItem:'',
        patch_stock:'',
        patch_discount:'',
        patch_cut_price:'',
        patch_sales_price:'',
    }
    componentDidMount() {
        this.props.onRef(this)
        this.initSku();
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let currentItem = this.state.currentItem || {}
        if(nextProps.item && nextProps.item.id != currentItem.id){
            this.initSku();
        }
    }
    initSku = () => {
        let item = this.props.item || {};
        if(item.sku_list && item.sku_list.length){
            let norms = item.norms,
                props = item.props,
                sku = this.state.sku,
                skuCache = this.state.skuCache;
            item.sku_props = {};
            item.sku_list.forEach((child,childKey) => {
                let props = child.props;
                child.props_arr = Object.values(props);
                item.sku_props[Object.values(props).join(';')] = child;
                child.cost_type = child.cost_type || 1;
            })
            skuCache = sku = item.sku_props;
            this.setState({
                norms,
                props,
                skuCache,
                sku
            },() => {
                this.combinaSku()
            })
        }
        this.setState({
            currentItem:this.props.item
        })
    }
    combinaSku = () => {
        //组合sku
        let keys=[],
            norms = this.state.norms || [],
            sku = this.state.sku || {}
        norms.forEach((item,index) => {
            if(item.props && item.props.length){
                keys.push(item.props);
            }
        })
        sku = {};
        this.setState({
            sku
        },() => {
            //向sku中插入数据，并更新skuCache缓存
            switch(keys.length){
                case 0:
                    break;
                case 1:
                    for(var i=0;i<keys[0].length;i++){
                        var arr=[keys[0][i]];
                        this.addSku(arr.join(";"));
                    }
                    break;
                case 2:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            var arr=[keys[0][i],keys[1][j]];
                            this.addSku(arr.join(";"));
                        }
                    }
                    break;
                case 3:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                var arr=[keys[0][i],keys[1][j],keys[2][k]];
                                this.addSku(arr.join(";"));
                            }
                        }
                    }
                    break;
                case 4:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n]];
                                    this.addSku(arr.join(";"));
                                }

                            }
                        }
                    }
                    break;
                case 5:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    for(var m=0;m<keys[4].length;m++){
                                        var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n],keys[4][m]];
                                        this.addSku(arr.join(";"));
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        })

    }
    addSku = (key,val) => {
        let skuCache = this.state.skuCache || {},
            sku = this.state.sku || {},
            itemInfo = this.props.item || {};
        //添加sku
        if(!skuCache[key]){
            let tmparr=key.split(";"),
                defaultSku = { market_price:'',price:'',basic_sales:'',cover_img:'',weight:'',goods_sku_code:'',store_price:[], props:tmparr };
            defaultSku.price = itemInfo.price || ``;
            defaultSku.market_price = itemInfo.market_price || ``;
            defaultSku.basic_sales = itemInfo.basic_sales || ``;
            defaultSku.weight = itemInfo.weight || ``;
            skuCache[key]= sku[key] = defaultSku;
        }else {
            sku[key] = skuCache[key];
        }


        let tableData = [];
        for(var i in sku){
            sku[i]['sku_key'] = i
            tableData.push(sku[i])
        }
        this.setState({
            sku,skuCache,tableData
        },() => {
        })
    }
    getInputValue = (e,rowData,index) => {
        let name = e.currentTarget.dataset.name || ``,
            value = e.target.value,
            tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        skuCache[sku_key][name] = sku[sku_key][name] = value;
        this.setState({
            sku,skuCache
        })
    }
    handleIsJoin = (e,rowData,index) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        skuCache[sku_key]['is_join'] = sku[sku_key]['is_join'] = e;
        this.setState({
            sku,skuCache
        })
    }
    handleDisable = (rowData,index) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        if(rowData['cost_type'] == 1){
            skuCache[sku_key]['cost_type'] = sku[sku_key]['cost_type'] = 2;
        }else {
            skuCache[sku_key]['cost_type'] = sku[sku_key]['cost_type'] = 1;
        }
        this.setState({
            sku,skuCache
        })
    }
    getData = () => {
        let res = {},
            callback = this.state.callback,
            currentItem = this.state.currentItem,
            sku = this.state.sku;
        res.item = currentItem
        return res;

    }
    toDo = () => {}

    render() {
        let currentItem = this.state.currentItem || {}
        return(
            <div>
                {
                    currentItem.props
                        ?
                        <table className="cxtables cxtables-sku">
                            <thead>
                            <tr>
                                {
                                    this.state.norms.map((item, index) => (
                                        <td key={index}>{item.name}</td>
                                    ))
                                }
                                <td>是否参与</td>
                                <td>商品库存</td>
                                <td>零售价</td>
                                <td>可兑换数量</td>
                                <td>纯积分兑换</td>
                                <td>积分+金额兑换</td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.tableData.map((item, index) => (
                                    <tr key={index} className="cbMoney">
                                        {
                                            this.state.tableData[index].props_arr.map((k, v) => (
                                                <td key={v}>{this.state.props[k]}</td>
                                            ))
                                        }
                                        <td>
                                            <Switch checked={!!item.is_join } onChange={ (e) => { this.handleIsJoin(e,item,index) } }></Switch>
                                        </td>
                                        <td>
                                            { item.sku_stock }
                                        </td>
                                        <td>
                                            &yen;{item.price}
                                        </td>
                                        <td>
                                            <Input
                                                placeholder={'请输入'}
                                                style={{ width:'100px' }}
                                                data-name={`stock`}
                                                value={item.stock}
                                                onChange={(e) => { this.getInputValue(e,item,index) }}
                                            />
                                        </td>
                                        <td>
                                            <span onClick={ () => { item.cost_type == 2 ? this.handleDisable(item,index) : this.toDo() } }>
                                                <Input
                                                    placeholder={'请输入'}
                                                    style={{ width:'100px' }}
                                                    data-name={`cost_points`}
                                                    value={ item.cost_type == 2 ? '' : item.cost_points}
                                                    onChange={(e) => { this.getInputValue(e,item,index) }}
                                                    className={`mgr5`}
                                                    disabled={ !item.is_join || item.cost_type == 2 }
                                                />
                                                积分
                                            </span>
                                        </td>
                                        <td>
                                            <span onClick={ () => { item.cost_type == 1 ?  this.handleDisable(item,index) : this.toDo() } }>
                                                  <Input
                                                      placeholder={'请输入'}
                                                      style={{ width:'100px' }}
                                                      data-name={`cost_points`}
                                                      value={ item.cost_type == 1 ? '' : item.cost_points}
                                                      disabled={ !item.is_join || item.cost_type == 1 }
                                                      onChange={(e) => { this.getInputValue(e,item,index) }}
                                                      className={`mgr5`}
                                                  />
                                                    积分+
                                            </span>
                                            <span onClick={ () => { item.cost_type == 1 ? this.handleDisable(item,index) : this.toDo() } }>
                                               <Input
                                                   placeholder={'请输入'}
                                                   style={{ width:'100px'}}
                                                   data-name={`cost_price`}
                                                   value={item.cost_price}
                                                   disabled={ !item.is_join || item.cost_type == 1  }
                                                   onChange={(e) => { this.getInputValue(e,item,index) }}
                                                   className={`mgr5 mgl5`}
                                               />
                                                元
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        :
                        null
                }


            </div>
        )
    }

}

export default Form.create({})(PointItemSku)
