import React from 'react'
import { Form, Modal, Input } from 'antd'
const FormItem = Form.Item;

class ItemRefund extends React.Component{
    state = {
        formData:'',
        title:'单品退款',
        visible:false,
        modalLoading:false,
        callback:null

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(typeof callback == 'function'){
                    callback(values);
                }
                this.close()
            }
        })

    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let data = this.state.formData || {},
            refundMoney = this.props.refundMoney || 0,
            lastRefundMoney = this.props.lastRefundMoney || 0,
            payMent = this.props.payMent || 0
        const formItemLayout = {
            labelCol:{
                span:5
            },
            wrapperCol:{
                span:17
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={500}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <FormItem label="单品实收" className={`mgb5`}>
                        <span className={`fi-help-text colorRed ft-bold lh30`}>￥{ payMent }</span>
                    </FormItem>
                    <FormItem label="已退金额" className={`mgb5`}>
                        <span className={`fi-help-text colorRed ft-bold lh30`}>￥{ refundMoney }</span>
                    </FormItem>
                    <FormItem label="退款数量">
                        {getFieldDecorator('refund_num', {
                            initialValue: data.refund_num || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入退款数量'
                                }
                            ]
                        })(<Input />)}
                    </FormItem>
                    <FormItem label="退款金额">
                        {getFieldDecorator('refund_price', {
                            initialValue: data && data.refund_price,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入退款金额'
                                }
                            ]
                        })(<Input />)}
                        <span className={`fi-help-text`}>最多可退￥{ lastRefundMoney }</span>
                    </FormItem>
                </Form>



            </Modal>
        )
    }
}
export default Form.create({})(ItemRefund)
