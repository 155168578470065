export const STOCK_IN_TYPES = [
    { id:1, name:'采购入库' },
    { id:2, name:'调拨入库' },
    { id:3, name:'其他入库' },
    { id:4, name:'退单入库' }
]

export const STOCK_OUT_TYPES = [
    { id:5, name:'销售出库-线上商城' },
    { id:6, name:'销售出库-线下门店' },
    { id:7, name:'调拨出库' },
    { id:8, name:'其他出库' }
]
