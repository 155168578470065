import React, { Component } from 'react'
import { Table, Button } from 'antd'
import { SetExpendVipRule } from './set-vip-price'

export default class ExpendDiscount extends Component {
    state = {
        columns: [
            {
                title: '类别',
                dataIndex: '1',
                width: 160,
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.name} </div>
                    )
                }
            },
            {
                title: '优惠方式',
                dataIndex: '2',
                width: 120,
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>折扣 </div>
                    )
                }
            },
            {
                title: 'V1',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v1 || '-'}{rowData.v1 ? '折' : ''} </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v2 || '-'}{rowData.v21 ? '折' : ''} </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v3 || '-'}{rowData.v3 ? '折' : ''} </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v4 || '-'}{rowData.v4 ? '折' : ''} </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v5 || '-'} {rowData.v5 ? '折' : ''}</div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '8',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v6 || '-'}{rowData.v6 ? '折' : ''} </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: 80,
                render: (text, rowData, index) => {

                    return <Button type='link' size='small' onClick={() => { this.handleEdit(rowData,index) }}>编辑</Button>
                }
            },
        ],
        tableData: [
            {
                id: 1,
                name: '所有商品、自助买单',
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            },
            {
                id: 2,
                name: '所有课程',
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            }
        ]
    }

    componentDidMount(){
        if(this.props.onRef){
            this.props.onRef(this)
        }
        let detail=this.props.detail;
        if(Array.isArray(detail.rule) && detail.rule.length){
            this.setState({
                tableData:detail.rule
            })
        }
    }

    getData=()=>{
        let tableData=this.state.tableData;
        return tableData
    }

    handleEdit = (row,index) => {
        this.SetExpendVipRule.show(row,(res)=>{
            let tableData=this.state.tableData;
            tableData[index]=res
            this.setState({
                tableData
            })
        })
    }
    render() {
        return (
            <>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <SetExpendVipRule onRef={e=>this.SetExpendVipRule=e} />
            </>
        )
    }
}
