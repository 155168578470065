import React from 'react'
import { Table } from 'antd'

class TimesCardOrder extends React.Component{
    state = {
        columns:[
            {
                title:'卡名称',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            { rowData.time_card_info?.name || '-' }

                        </div>
                    )
                }
            },
            {
                title:'内容',
                dataIndex:'desc',
                width:'300px',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            {
                                rowData.goods_lists && rowData.goods_lists.map((item,index) => (
                                    <div key={index}>
                                        <p>商品名称：{item.name}</p>
                                        <p>可用次数：{item.count - item.use_count}</p>
                                    </div>
                                ))
                            }



                        </div>

                    )
                }
            },
            {
                title:'售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.pay_ment }</p>
                        </div>
                    )
                }
            },
            {
                title:'数量',
                dataIndex:'num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>1</p>
                        </div>
                    )
                }
            },
            {
                title:'合计',
                dataIndex:'pay_ment',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ rowData.pay_ment || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableLoading:false,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    render() {
        let tableData = this.props.tableData || [],
            trade = this.props.trade || {};
        console.log('tableData',tableData)
        console.log('trade',trade)
        return(
            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={(record,index) => index}
                pagination={false}
            ></Table>
        )
    }
}
export default TimesCardOrder
