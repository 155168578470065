import React from 'react'
import { Table, Button } from 'antd'

class TicketOrder extends React.Component {
    state = {
        columns: [
            {
                title: '演出活动名称',
                dataIndex: 'name',
                width: '22%',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.ticket_info.name}</p>
                        </div>
                    )
                }
            },
            {
                title: '场次',
                dataIndex: 'sku',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{this.getWeekDay(rowData.ticket_info.perform_start_time)}</p>
                        </div>
                    )
                }
            },
            {
                title: '座位',
                dataIndex: 'unit',
                render: (text, rowData, index) => {
                    let str = rowData.ticket_info?.seat_info?.map(val => val.seat_name).join('、') || '-'
                    return (
                        <div className={''}>
                            {
                                rowData.ticket_info?.seat_info?.map((val, i) => (
                                    <p className={`lh25`} key={i}>{val.seat_name}</p>
                                ))
                            }

                        </div>
                    )
                }
            },
            {
                title: '票价',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    if (rowData.ticket_info) {
                        return (
                            <div className={''}>
                                {
                                    rowData.ticket_info?.seat_info?.map((val, i) => (
                                        <p className={`lh25`} key={i}>{ val.price}</p>
                                    ))
                                }

                            </div>
                        )
                    }


                    return (
                        <div>
                            <p className={`lh25`}>{rowData.ticket_info ? '-' : rowData.ticket_info.single_price}</p>
                        </div>
                    )
                }
            },
            {
                title: '数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.ticket_info.ticket_num}</p>
                        </div>
                    )
                }
            },
            {
                title: '合计',
                dataIndex: 'total',
                render: (text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{rowData.total_price || '-'}</p>
                        </div>
                    )
                }
            }
        ],
        tableLoading: false,
    }
    componentDidMount() {
        this.props.onRef(this)
    }

    getWeekDay = (dateString) => {
        const date = new Date(dateString);
        const dayOfWeek = date.getDay(); // 0 表示周日，6 表示周六
        let dayname = ';'
        switch (dayOfWeek) {
            case 0:
                dayname = '周日';
                break;
            case 1:
                dayname = '周一';
                break;
            case 2:
                dayname = '周二';
                break;
            case 3:
                dayname = '周三';
                break;
            case 4:
                dayname = '周四';
                break;
            case 5:
                dayname = '周五';
                break;
            case 6:
                dayname = '周六';
                break;
            default:
                break;
        }
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const min = date.getMinutes();
        return year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0') + ' ' + dayname + ' ' + hours.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0');
    }

    render() {
        let tableData = this.props.tableData || []
        return (
            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={record => record.id}
                pagination={false}
            ></Table>
        )
    }
}
export default TicketOrder
