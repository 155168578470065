import React, { Component } from 'react'
import { Form, Input, DatePicker, Button, Select, Radio, Switch, InputNumber, Tooltip, Icon, Checkbox, Table, message, Carousel } from 'antd';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import Loading from '@/components/Loading';
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import ActivityGoodsModal from './components/activityGoodsModal';
import { storeLists } from '@/api/store'
import { groupShoppingSave, groupShoppingDetail } from '@/api/group-buying.js'
import { adminDetail } from '@/api/admin'
import Store from '@/redux/store.js'

import '@/assets/css/group-buying/index.scss';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select

const dayArr = Array.from({ length: 31 }, (_, i) => i)
const hourArr = Array.from({ length: 24 }, (_, i) => i)
const minuteArr = Array.from({ length: 60 }, (_, i) => i)

class GroupBuyingAdd extends Component {
    state = {
        isLoading: false,
        storeLists: [],
        formData: {
            time_validity_day: 1,
            time_validity_hour: 0,
            time_validity_minute: 0,
            limit_num: undefined,
            valid_day: 1,
            limit_time: []
        },
        detail: {},
        goodsList: [],
        goods_type: undefined,
        prize_id: "",
        is_disabled: false,
        version_id: 0,
    }

    componentDidMount() {
        this.fetchStore()
        if (this.props.match.params.id) {
            this.fetchData()
        }
        this.fetchAdmin()
    }

    fetchData = () => {
        let sendData = { id: this.props.match.params.id }

        this.setState({
            isLoading: true
        })
        groupShoppingDetail(sendData).then(res => {
            if (res.code == 1) {
                let detail = res.data.detail, formData = this.state.formData

                if (detail.start_time && detail.end_time) {
                    detail.time = [moment(detail.start_time, 'YYYY-MM-DD HH:mm:ss'), moment(detail.end_time, 'YYYY-MM-DD HH:mm:ss')]
                }

                let valid_time = detail.valid_time.split("_")
                formData.time_validity_day = valid_time[0] * 1
                formData.time_validity_hour = valid_time[1] * 1
                formData.time_validity_minute = valid_time[2] * 1

                if (detail.limit_num) {
                    detail.is_activity_count = 1
                    formData.limit_num = detail.limit_num
                }

                if (detail.valid_type == 2 && detail.start_at && detail.end_at) {
                    formData.limit_time = [moment(detail.start_at, 'YYYY-MM-DD HH:mm:ss'), moment(detail.end_at, 'YYYY-MM-DD HH:mm:ss')]
                }
                formData.valid_day = detail.valid_day
                formData.share_poster = detail.share_poster
                formData.share_img = detail.share_img

                let goodsList = []
                if (detail.goods_list && detail.goods_list.length) {
                    goodsList = detail.goods_list.map(val => {
                        val.name = detail.goods_info.name
                        val.cover_img = detail.goods_info.cover_img
                        val.pic_urls = detail.goods_info.pic_urls
                        val._price = val.price
                        val.price = val.sku_price || detail.goods_info.price

                        if (val.alias) {
                            val.sku_name = Object.values(val.alias).join("/")
                        }
                        val.goods_stock = val.sku_stock
                        return val
                    })
                }

                let is_disabled = false
                if (detail.status != 1) {
                    is_disabled = true
                }

                this.setState({
                    detail,
                    goods_type: detail.type,
                    goodsList,
                    prize_id: detail.prize_id,
                    is_disabled: is_disabled
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    fetchAdmin = () => {

        let storeData = Store.getState()
        let shop = storeData.shop
        if (shop) {
            this.setState({
                version_id: shop.version_id
            })
        } else {
            adminDetail({})
                .then(res => {
                    if (res.code == 1) {
                        let shop = res.data.shop
                        this.setState({
                            version_id: shop.version_id
                        })
                    }
                })
        }

    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleResetImg = (keyNmae) => {
        return () => {
            const formData = this.state.formData || {}
            formData[keyNmae] = this.state.default_img
            this.setState({
                formData
            })
        }
    }

    handleGoodsUpdate = (name, type, index) => {
        return e => {
            const { goodsList } = this.state
            if (type == 'radio') {
                goodsList[index][name] = e.target.value
            } else {
                goodsList[index][name] = e
            }
            this.setState({
                goodsList
            })
        }
    }

    handleFormDataUpdate = (name, type) => {
        return (e, d) => {
            const { formData } = this.state
            if (type == 'radio') {
                formData[name] = e.target.value
            } else {
                formData[name] = e
            }

            this.setState({
                formData
            })
        }
    }

    handleGoodsSelect = val => {

        let bool = val === 1 ? true : false
        this.GoodsModal.show(bool, (res) => {
            let arr = res
            if (res.length) {
                if (val === 1) {

                    if (res[0].sku_list.length) {
                        arr = res[0].sku_list.map(item => {
                            item.sku_id = item.id
                            item.name = res[0].name
                            item.cover_img = res[0].cover_img

                            if (item.alias) {
                                item.sku_name = Object.values(item.alias).join("/")
                            }
                            item.goods_stock = item.sku_stock
                            return item
                        })

                    }
                }
                arr = arr.map(val => {
                    val.tuan_sale = 0

                    let total
                    if (bool) {
                        total = val.goods_stock
                    } else {
                        total = val.course_quota
                    }

                    if (total) {
                        val.total_num = 1
                    } else {
                        val.total_num = 0
                        val._price=val.price
                    }

                    return val
                })

                this.setState({
                    goodsList: arr,
                    goods_type: val,
                    prize_id: res[0].id
                })
            } else {
                this.props.form.setFields({
                    activity_goods: undefined
                })
            }
        })
    }

    handleDeleGoods = () => {
        this.setState({
            goods_type: undefined,
            goodsList: []
        })
    }

    handleSaveData = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { formData, goodsList, goods_type, prize_id, detail } = this.state
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''

                if (values.suit_store_type == 2) {
                    values.suit_store_ids = values.suit_store_ids.join()
                }

                values.valid_time = formData.time_validity_day + '_' + formData.time_validity_hour + '_' + formData.time_validity_minute
                values.type = goods_type
                values.prize_id = prize_id
                values.limit_num = formData.limit_num
                if (values.valid_type == 2 && formData.limit_time.length) {
                    values.start_at = moment(formData.limit_time[0]).format('YYYY-MM-DD HH:mm:ss')
                    values.end_at = moment(formData.limit_time[1]).format('YYYY-MM-DD HH:mm:ss')
                }
                values.valid_day = formData.valid_day
                values.share_poster = formData.share_poster
                values.share_img = formData.share_img
                values.goods_list = goodsList.map(val => {
                    let id
                    if (this.props.match.params.id && prize_id == detail.prize_id && goods_type == detail.type) {
                        id = val.id
                    } else {
                        id = ''
                    }

                    return {
                        goods_id: prize_id,
                        sku_id: val.sku_id,
                        total_num: val.total_num,
                        price: val._price || 0,
                        tuan_sale: val.tuan_sale,
                        tuan_price: val.tuan_price,
                        id: id
                    }
                })

                console.log(values);
                let sendData = values

                if (this.props.match.params.id) {
                    sendData.id = this.props.match.params.id
                }

                this.setState({
                    isLoading: true
                })
                groupShoppingSave(sendData).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                        this.props.history.push('/group-buying/lists')
                    } else {
                        this.setState({
                            isLoading: false
                        })
                    }
                })
            }
        })
    }


    render() {

        let is_goods = this.state.goods_type === 1 ? true : false
        let is_disabled = this.state.is_disabled
        const goodsColumns = [
            {
                title: is_goods ? '商品信息' : '课程信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    let img_url
                    if (is_goods) {
                        img_url = rowData.cover_img
                    } else {
                        img_url = rowData.pic_urls && rowData.pic_urls[0]
                    }
                    let rowSpan = 0
                    if (index === 0) {
                        rowSpan = 100
                    }


                    return {
                        children: (
                            <div className={`flex jusify-start align-center`}>
                                <img src={img_url} alt="" width={60} height={60} />
                                <div className={`mgl10`}>
                                    <p className={` w150`}>{rowData.name || ''}</p>
                                </div>
                            </div>
                        ),
                        props: {
                            rowSpan
                        }
                    }
                }
            },
            {
                title: '商品规格',
                dataIndex: '2',
                render: (text, rowData, index) => {

                    return (
                        <p>
                            {rowData.sku_name || '-'}
                        </p>
                    )
                }
            },
            {
                title: '可用库存',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    let total
                    if (!is_goods) {
                        total = rowData.course_quota
                    } else {
                        total = rowData.goods_stock
                    }
                    return (
                        <p>
                            {total || 0}
                        </p>
                    )
                }
            },
            {
                title: <> 活动库存
                    <Tooltip placement={`right`} title={`客户开团即扣减成团所需库存，建议库存数量设置多一些（例如:客户开了3人团，库存数星会扣减3，保证该团可以成团)活动库存为0时,表示不参与活动`}>
                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                    </Tooltip>
                </>,
                dataIndex: '4',
                render: (text, rowData, index) => {
                    let total
                    if (is_goods) {
                        total = rowData.goods_stock
                    } else {
                        total = rowData.course_quota
                    }

                    return (
                        <InputNumber disabled={is_disabled || (!total)} value={rowData.total_num} onChange={this.handleGoodsUpdate('total_num', 'inputnumber', index)} min={0} precision={0} />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    let rowSpan = 0
                    if (index === 0) {
                        rowSpan = 100
                    }

                    return {
                        children: (
                            <Button disabled={is_disabled} type='link' onClick={this.handleDeleGoods}>删除</Button>
                        ),
                        props: {
                            rowSpan
                        }
                    }
                }
            }
        ]

        const columns = [
            {
                title: '商品规格',
                dataIndex: 'sku_name',
                render: (text, rowData, index) => {

                    return (
                        <p>
                            {rowData.sku_name || '-'}
                        </p>
                    )
                }
            },
            {
                title: '售价',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            ￥{rowData.price}
                        </p>
                    )
                }
            },
            {
                title: '拼团价',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    let total
                    if (!is_goods) {
                        total = rowData.course_quota
                    } else {
                        total = rowData.goods_stock
                    }
                    return (
                        <div>
                            <InputNumber disabled={is_disabled || (!total)} value={rowData._price} onChange={this.handleGoodsUpdate('_price', 'inputnumber', index)} min={0} precision={2} />元
                        </div>
                    )
                }
            },
            {
                title: '团长优惠价',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    let total
                    if (!is_goods) {
                        total = rowData.course_quota
                    } else {
                        total = rowData.goods_stock
                    }
                    return (
                        <div>
                            <Radio.Group disabled={is_disabled || (!total)} value={rowData.tuan_sale} onChange={this.handleGoodsUpdate('tuan_sale', 'radio', index)}>
                                <Radio value={0}>
                                    <InputNumber disabled={is_disabled || (!total)} min={0} precision={2} value={rowData.tuan_price} onChange={this.handleGoodsUpdate('tuan_price', 'inputnumber', index)} />元
                                </Radio>
                                <Radio value={1}>免单 </Radio>

                            </Radio.Group>
                        </div>
                    )
                }
            },
        ]

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 19
            }
        }
        const radioSty = { display: "block", marginBottom: "8px" }

        const { getFieldDecorator, getFieldValue } = this.props.form
        const data = this.state.detail || {}
        const formData = this.state.formData

        return (
            <div className='shadow-radius'>
                <div className="clearfix mgt15 group-buying">
                    <div className="phone-wrap fl">
                        <Carousel autoplay >
                            <div>
                                <img src="https://img.cxkoo.com/chengxuan/1/2022-09-07/e5aa7aa43b5602120f3e06bdba7a744f.png" alt="" />
                            </div>
                            <div>
                                <img src="https://img.cxkoo.com/chengxuan/1/2022-09-07/99020104887a300f85f5e9fe5eb5196e.png" alt="" />
                            </div>
                        </Carousel>
                    </div>
                    <div className="ctrl-wrap fl mgl20" >
                        <Form {...formItemLayout} className={'model-form '}>
                            <div className={`msg-title mgb15`}>基本信息</div>
                            <FormItem label={'活动名称'}>
                                {getFieldDecorator('name', {
                                    initialValue: data.name || undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入活动名称'
                                        }
                                    ]
                                })(<Input
                                    style={{ width: 400 }}
                                    placeholder={'请输入活动名称'}
                                    maxLength={30}
                                    suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/30</span>}
                                />)}
                            </FormItem>
                            <FormItem label={'活动时间'}>
                                {getFieldDecorator('time', {
                                    initialValue: data.time || null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择活动时间'
                                        }
                                    ]
                                })(
                                    <RangePicker
                                        style={{ width: 400 }}
                                        showTime={{ format: 'HH:mm:ss' }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        placeholder={['开始时间', '结束时间']}
                                    />
                                )}
                            </FormItem>

                            <FormItem label={'成团有效期'} required>
                                <div>
                                    <Select style={{ width: 70 }} value={formData.time_validity_day} onChange={this.handleFormDataUpdate('time_validity_day', 'select')}>
                                        {
                                            dayArr.map(val => {
                                                return <Option key={val} value={val}>{val}</Option>
                                            })
                                        }
                                    </Select>&nbsp; 天 &nbsp;
                                    <Select style={{ width: 70 }} value={formData.time_validity_hour} onChange={this.handleFormDataUpdate('time_validity_hour', 'select')}>
                                        {
                                            hourArr.map(val => {
                                                return <Option key={val} value={val}>{val}</Option>
                                            })
                                        }
                                    </Select>&nbsp; 时 &nbsp;
                                    <Select style={{ width: 70 }} value={formData.time_validity_minute} onChange={this.handleFormDataUpdate('time_validity_minute', 'select')} >
                                        {
                                            minuteArr.map(val => {
                                                return <Option key={val} value={val}>{val}</Option>
                                            })
                                        }
                                    </Select> &nbsp;分
                                </div>
                                <span className={'fi-help-text'}>若设置1天，客户开团后，需要1天内成团，超时则会拼团失败。若活动还在有效期内，客户可以重新发起拼团。编辑修改成团时间，则对进行中的团及新开的团均生效。</span>
                            </FormItem>

                            <FormItem label={'活动商品'} required >
                                {
                                    this.state.goodsList.length ?
                                        <Table
                                            size={`middle`}
                                            columns={goodsColumns}
                                            dataSource={this.state.goodsList}
                                            rowKey={record => record.id}
                                            pagination={false}
                                        ></Table> :
                                        getFieldDecorator('activity_goods', {
                                            initialValue: data.activity_goods || undefined,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择活动商品'
                                                }
                                            ]
                                        })(
                                            <Select
                                                style={{ width: 400 }}
                                                placeholder="请选择活动商品"
                                                onChange={this.handleGoodsSelect}
                                            >
                                                <Option value={1} >商品</Option>
                                                {[5, 6, 7].includes(this.state.version_id) ?<Option value={2} >课程</Option>:null}
                                            </Select>
                                        )
                                }

                            </FormItem>

                            <FormItem label={'拼团价'} required >
                                {
                                    this.state.goodsList.length ?
                                        <>
                                            {getFieldDecorator('open_num', {
                                                initialValue: data.open_num || undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入拼团人数'
                                                    }
                                                ]
                                            })(
                                                <InputNumber
                                                    style={{ width: 200 }}
                                                    min={2}
                                                    max={100}
                                                    precision={0}
                                                    placeholder='请输入2-100的整数'
                                                    disabled={is_disabled}
                                                />
                                            )} 人成团
                                            <Table
                                                size={`middle`}
                                                columns={columns}
                                                dataSource={this.state.goodsList}
                                                rowKey={record => record.id}
                                                pagination={false}
                                            ></Table>

                                        </> :
                                        <span>请先选择商品</span>
                                }

                            </FormItem>


                            <FormItem label={"适用门店"} >
                                {getFieldDecorator('suit_store_type', {
                                    initialValue: data.suit_store_type || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择适用门店'
                                        }
                                    ]
                                })(
                                    <Radio.Group  >
                                        <Radio value={1}> 所有门店</Radio>
                                        <Radio value={2}>指定门店可用 </Radio>
                                        {
                                            getFieldValue("suit_store_type") == 2 ? <span className='small-help-text'>已选择 {getFieldValue("suit_store_ids") && getFieldValue("suit_store_ids").length || 0} 家</span> : null
                                        }
                                    </Radio.Group>
                                )}
                            </FormItem>

                            {
                                getFieldValue("suit_store_type") == 2 ?
                                    <FormItem label={' '} className={'no-colon'}>
                                        {getFieldDecorator('suit_store_ids', {
                                            initialValue: data.suit_store_ids || [],
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择适用门店'
                                                }
                                            ]
                                        })(
                                            <Select
                                                style={{ width: 400 }}
                                                placeholder="请选择"
                                                mode="multiple"
                                                showArrow
                                            >
                                                {
                                                    this.state.storeLists.map(store => (
                                                        <Option value={`${store.id}`} key={store.id}>{store.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        )}

                                    </FormItem>
                                    : null
                            }

                            <FormItem label={"参团限制"} >
                                {getFieldDecorator('join_user', {
                                    initialValue: data.join_user || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择参团限制'
                                        }
                                    ]
                                })(
                                    <Radio.Group  >
                                        <Radio value={1}>所有客户 </Radio>
                                        <Radio value={2}>仅限新客户
                                            <Tooltip placement={`right`} title={`新客户指店铺内没有订单的客户，或仅拥有以下类型订单的客户：全额退款订单、已关闭的待付款订单`}>
                                                <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                            </Tooltip>
                                        </Radio>

                                    </Radio.Group>
                                )}
                            </FormItem>

                            <FormItem label={"活动限购"} >
                                {getFieldDecorator('is_activity_count', {
                                    initialValue: data.is_activity_count || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择活动限购'
                                        }
                                    ]
                                })(
                                    <Radio.Group   >
                                        <Radio value={0}>不限制 </Radio>
                                        <Radio value={1}> 限制</Radio>
                                    </Radio.Group>
                                )}
                                {getFieldValue("is_activity_count") == 1 ? <span><InputNumber placeholder='自定义' onChange={this.handleFormDataUpdate('limit_num', 'inputnumber')} value={formData.limit_num} style={{ width: 90 }} min={1} precision={0} /> 次/人</span> : null}
                            </FormItem>

                            <div className={`msg-title mgb15`}>高级设置</div>

                            <FormItem label={'凑团设置'}>
                                {getFieldDecorator('show_group', {
                                    initialValue: Boolean(data.show_group) || false,
                                    valuePropName: 'checked',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择凑团设置'
                                        }
                                    ]
                                })(
                                    <Switch />
                                )}

                                <span className={'fi-help-text'}>开启凑团后，对于未参团的买家，活动商品详情页会显示待成团的团列表，买家可以直接任选一个参团，提升成团率。<br /> 注：开启参团仅限新客时，则凑团的用户必须是新人才能拼团成功。</span>
                            </FormItem>

                            <FormItem label={'模拟成团'}>
                                {getFieldDecorator('success_group', {
                                    initialValue: Boolean(data.success_group) || false,
                                    valuePropName: 'checked',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择模拟成团'
                                        }
                                    ]
                                })(
                                    <Switch />
                                )}

                                <span className={'fi-help-text'}>开启模拟成团后，该团有效期结束时，人数未满的团，系统将会模拟“匿名买家”凑满人数，使该团成团。只有已付款参团的真实买家才会获得商品，建议合理开启，以提高成团率。</span>
                            </FormItem>

                            <FormItem label={'团长优惠'}>
                                {getFieldDecorator('tuan_sale', {
                                    initialValue: Boolean(data.tuan_sale) || false,
                                    valuePropName: 'checked',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择团长优惠'
                                        }
                                    ]
                                })(
                                    <Switch />
                                )}

                                <span className={'fi-help-text'}>开启团长(开团人)优惠后，团长将享受更优惠价格，有助于提高开团率和成团率。<br /> 请注意：模拟成团的团长也能享受团长优惠，请谨慎设置，避免资金损失。</span>
                            </FormItem>

                            <FormItem label={"优惠叠加"} >
                                {getFieldDecorator('can_discount', {
                                    initialValue: data.can_discount || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择优惠叠加'
                                        }
                                    ]
                                })(
                                    <Radio.Group   >
                                        <Radio value={0}>不可叠加 </Radio>
                                        <Radio value={1}> 可叠加</Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>


                            {
                                getFieldValue("can_discount") == 1 ?
                                    <FormItem label={"可叠加的优惠"} >
                                        {getFieldDecorator('use_scene', {
                                            initialValue: data.use_scene || [1]
                                        })(
                                            <Checkbox.Group  >
                                                <Checkbox value={1}>优惠券
                                                    <Tooltip placement={`right`} title={`可用优惠券不含兑换券`}>
                                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                                    </Tooltip>
                                                </Checkbox>
                                                <Checkbox value={2}>积分抵扣</Checkbox>

                                            </Checkbox.Group>
                                        )}
                                    </FormItem> : null
                            }

                            <div className={`msg-title mgb15`}>展示设置</div>

                            <FormItem label={'虚拟参与人数'}>
                                {getFieldDecorator('basic_sales', {
                                    initialValue: data.basic_sales || undefined
                                })(
                                    <InputNumber
                                        style={{ width: 400 }}
                                        min={0}
                                        precision={0}
                                        placeholder={'虚拟参与人数'}
                                    />
                                )}
                            </FormItem>

                            <FormItem label={'排序号'}>
                                {getFieldDecorator('sort', {
                                    initialValue: data.sort || 0
                                })(
                                    <InputNumber
                                        style={{ width: 400 }}
                                        min={0}
                                        precision={0}
                                        placeholder={'排序号'}
                                    />
                                )}
                            </FormItem>

                            <FormItem label={"使用有效期"}>
                                {getFieldDecorator('valid_type', {
                                    initialValue: data.valid_type || 1
                                })(
                                    <Radio.Group  >

                                        <Radio value={1} style={radioSty}>永久有效</Radio>
                                        <Radio value={2} style={radioSty}>
                                            <span className='mgr20'>固定有效期</span>
                                            <RangePicker
                                                style={{ width: 400 }}
                                                showTime={{ format: 'HH:mm:ss' }}
                                                format="YYYY-MM-DD HH:mm:ss"
                                                placeholder={['开始时间', '结束时间']}
                                                value={formData.limit_time}
                                                onChange={this.handleFormDataUpdate('limit_time', 'rangepicker')}
                                            />
                                        </Radio>
                                        <Radio value={3} style={radioSty}>拼团成功后 &nbsp;

                                            <InputNumber onChange={this.handleFormDataUpdate('valid_day', 'inputnumber')} value={formData.valid_day} style={{ width: 80 }} min={1} precision={0} /> 天内有效
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>

                            <FormItem label={`活动封面`}>
                                <div className={'clearfix'}>
                                    {
                                        formData.share_poster
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={formData.share_poster}
                                                onDel={e => { this.delImg(e, 'share_poster') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'share_poster') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：750x750像素，单张不超过2M</span>
                            </FormItem>

                            <FormItem label={'分享描述'}>
                                {getFieldDecorator('share_title', {
                                    initialValue: data.share_title || undefined
                                })(<Input
                                    style={{ width: 400 }}
                                    placeholder={'请输入分享描述'}
                                    maxLength={20}
                                    suffix={<span>{getFieldValue("share_title") ? getFieldValue("share_title").length : 0}/20</span>}
                                />)}
                                <span className={'fi-help-text'}>将展示在分享链接中，最多20个字符</span>
                            </FormItem>

                            <FormItem label={`分享图片`}>
                                <div className={'clearfix'}>
                                    {
                                        formData.share_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={formData.share_img}
                                                onDel={e => { this.delImg(e, 'share_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'share_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M，用于小程序卡片分享</span>
                            </FormItem>

                            <FormItem label={' '} className={'no-colon mgb40'}>
                                <Button type='primary' onClick={this.handleSaveData}>保存</Button>
                            </FormItem>

                        </Form>
                    </div>
                </div>

                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <ActivityGoodsModal ref={e => this.GoodsModal = e}></ActivityGoodsModal>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(GroupBuyingAdd))