import React from 'react'
import { Table } from 'antd'

class CouponOrder extends React.Component{
    state = {
        columns:[
            {
                title:'券名称',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            {
                                rowData.goods_lists && rowData.goods_lists.length
                                    ?
                                    <p className={`lh25`}>{ rowData.goods_lists[0]['name'] }</p>
                                    :
                                    <p className={`lh25`}>{ rowData.coupon_name || '-' }</p>
                            }

                        </div>
                    )
                }
            },
            {
                title:'售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.pay_ment }</p>
                        </div>
                    )
                }
            },
            {
                title:'数量',
                dataIndex:'num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>1</p>
                        </div>
                    )
                }
            },
            {
                title:'合计',
                dataIndex:'pay_ment',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ rowData.pay_ment || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableLoading:false,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    render() {
        let tableData = this.props.tableData || []
        return(
            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={record => record.id}
                pagination={false}
            ></Table>
        )
    }
}
export default CouponOrder
