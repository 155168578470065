import React from 'react'
import { Form, Input, Radio, Table, Button, Checkbox, Modal, Select } from 'antd'
import { withRouter } from 'react-router-dom'
import AreaPicker from '@/components/AreaPicker/AreaPicker'
import { addExpressTemplate, expressTemplateDetail } from '@/api/express'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import '@/assets/css/express/index.scss'

const FormItem = Form.Item

class AddExpressTemplate extends React.Component {
    state = {
        formData: {},
        columns: [
            {
                title: '配送区域',
                dataIndex: 'rowCityData',
                width: '31%',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <div className={`first-column-td`}>
                                {
                                    !rowData.rowCityData || rowData.rowCityData.length < 1
                                        ?
                                        <div className={`area-text`}>默认区域</div>
                                        :
                                        <div className={`area-txt`}>{rowData.province_city_name}</div>
                                }
                                {
                                    rowData.rowCityData.length
                                        ?
                                        <div className={`area-btn`}>
                                            {/*<span>修改</span>*/}
                                            <span onClick={ () => { this.handleDeleteLogistic(rowData,index) } }>删除</span>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    )
                }
            },
            {
                title: `首件`,
                width: '15%',
                dataIndex: 'first_num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                placeholder={''}
                                style={{width: '80px'}}
                                value={rowData.first_num}
                                onChange={(e) => {
                                    this.getInputValue(e, rowData, index)
                                }}
                                data-name={`first_num`}
                            />&nbsp;{this.state.formData.type == 2 ? 'kg' : '件'}内
                        </div>
                    )
                }
            },
            {
                title: '运费（元）',
                width: '18%',
                dataIndex: 'first_price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            收取
                            &nbsp;
                            <Input
                                placeholder={''}
                                style={{width: '80px'}}
                                value={rowData.first_price}
                                onChange={(e) => {
                                    this.getInputValue(e, rowData, index)
                                }}
                                data-name={`first_price`}
                            />&nbsp;元
                        </div>
                    )
                }
            },
            {
                title: '续件',
                width: '18%',
                dataIndex: 'renew_num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            续&nbsp;
                            <Input
                                placeholder={''}
                                style={{width: '80px'}}
                                value={rowData.renew_num}
                                onChange={(e) => {
                                    this.getInputValue(e, rowData, index)
                                }}
                                data-name={`renew_num`}
                            />&nbsp;{this.state.formData.type == 2 ? 'kg' : '件'}
                        </div>
                    )
                }
            },
            {
                title: '运费（元）',
                width: '18%',
                dataIndex: 'renew_price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            增加&nbsp;
                            <Input
                                placeholder={''}
                                style={{width: '80px'}}
                                value={rowData.renew_price}
                                onChange={(e) => {
                                    this.getInputValue(e, rowData, index)
                                }}
                                data-name={`renew_price`}
                            />&nbsp;元
                        </div>
                    )
                }
            },
        ],
        freeColumns: [
            {
                title: '配送区域',
                dataIndex: 'rowCityData',
                width:'40%',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <div className={`first-column-td`}>
                                {
                                    !rowData.rowCityData || rowData.rowCityData.length < 1
                                        ?
                                        <div className={`area-text`}>默认区域</div>
                                        :
                                        <div className={`area-txt`}>{rowData.province_city_name}</div>
                                }
                                {
                                    rowData.rowCityData.length
                                        ?
                                        <div className={`area-btn`}>
                                            {/*<span>修改</span>*/}
                                            <span onClick={ () => { this.handleDeleteFree(rowData,index) } }>删除</span>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    )
                }
            },
            {
                title: '包邮条件',
                dataIndex: 'case',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex`}>
                            <Select
                                placeholder={''}
                                style={{width: '100px'}}
                                value={rowData.type}
                                onChange={(e) => {
                                    this.handleSelect(e, rowData, index)
                                }}
                            >
                                <Select.Option value={1}>金额</Select.Option>
                                <Select.Option value={2}>重量</Select.Option>
                                <Select.Option value={3}>金额+重量</Select.Option>
                            </Select>
                            {
                                rowData.type == 1
                                    ?
                                    <div className={``}>
                                        <span className={`mgl20`}>
                                            单笔订单金额满&nbsp;
                                            <Input
                                                placeholder={''}
                                                style={{width: '80px'}}
                                                value={rowData.amount}
                                                onChange={(e) => {
                                                    this.getFreeInputValue(e, rowData, index)
                                                }}
                                                data-name={`amount`}
                                            />&nbsp;元
                                        </span>
                                    </div>
                                    :
                                    null
                            }
                            {
                                rowData.type == 2
                                    ?
                                    <div className={``}>
                                        <span className={`mgl20`}>
                                            单笔订单重量在&nbsp;
                                            <Input
                                                placeholder={''}
                                                style={{width: '80px'}}
                                                value={rowData.weight}
                                                onChange={(e) => {
                                                    this.getFreeInputValue(e, rowData, index)
                                                }}
                                                data-name={`weight`}
                                            />&nbsp;kg内
                                        </span>
                                    </div>
                                    :
                                    null
                            }
                            {
                                rowData.type == 3
                                    ?
                                    <div className={``}>
                                        <span className={`mgl20`}>
                                            单笔订单重量在&nbsp;
                                            <Input
                                                placeholder={''}
                                                style={{width: '80px'}}
                                                value={rowData.weight}
                                                onChange={(e) => {
                                                    this.getFreeInputValue(e, rowData, index)
                                                }}
                                                data-name={`weight`}
                                            />
                                            &nbsp;kg内且金额满&nbsp;
                                            <Input
                                                placeholder={''}
                                                style={{width: '80px'}}
                                                value={rowData.amount}
                                                onChange={(e) => {
                                                    this.getFreeInputValue(e, rowData, index)
                                                }}
                                                data-name={`amount`}
                                            />
                                            &nbsp;元
                                        </span>
                                    </div>
                                    :
                                    null
                            }


                        </div>
                    )
                }
            }
        ],
        logisticArea: [],
        freePostArea: [],
        isFreePost: 0,
        isLoading:false,
        defaultPostSet: {first_num: 1, first_price: 0, renew_num: 1, renew_price: 0, rowCityData: []}

    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchData()
        } else {
            this.init()
        }
    }

    init = () => {
        let logisticArea = this.state.logisticArea,
            tmp = JSON.stringify(this.state.defaultPostSet),
            defaultPostSet = JSON.parse(tmp);
        if (!logisticArea.length) {
            logisticArea.push(defaultPostSet)
        }
        this.setState({
            logisticArea
        })
    }
    fetchData = () => {
         let sendData = {};
         sendData.id = this.props.match.params.id;
         this.setState({
             isLoading:true
         })
        expressTemplateDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail,
                        formData = this.state.formData,
                        logisticArea = [],
                        freePostArea = [];
                    formData.id = detail.id;
                    formData.name = detail.name;
                    formData.type = detail.type;
                    detail.defaultPostSet.rowCityData = [];
                    logisticArea.push(detail.defaultPostSet);
                    logisticArea = logisticArea.concat(detail.logisticArea);
                    logisticArea = this.dealAreaName(logisticArea);
                    freePostArea = detail.freePostArea;
                    freePostArea = this.dealAreaName(freePostArea);


                    this.setState({
                        formData,
                        logisticArea,
                        freePostArea,
                        isFreePost: freePostArea.length ? 1 : 0
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }

    getInputValue = (e,rowData,index) => {
        let val = e.target.value,
            name = e.currentTarget.dataset.name,
            logisticArea = this.state.logisticArea;
        logisticArea[index][name] = val;
        this.setState({
            logisticArea
        })
    }
    getFreeInputValue = (e,rowData,index) => {
        let val = e.target.value,
            name = e.currentTarget.dataset.name,
            freePostArea = this.state.freePostArea;
        freePostArea[index][name] = val;
        this.setState({
            freePostArea
        })

    }
    handleSelect = (val,rowData,index) => {
        let freePostArea = this.state.freePostArea;
        freePostArea[index]['type'] = val;
        this.setState({
            freePostArea
        })

    }
    handleDeleteLogistic = (rowData,index) => {
        let logisticArea = this.state.logisticArea;
        Modal.confirm({
            title:`是否删除这条设置？`,
            okType:`danger`,
            onOk:() => {
                logisticArea.splice(index,1);
                this.setState({
                    logisticArea
                })
            }
        })
    }
    handleDeleteFree = (rowData,index) => {
        let freePostArea = this.state.freePostArea;
        Modal.confirm({
            title:`是否删除这条设置？`,
            okType:`danger`,
            onOk:() => {
                freePostArea.splice(index,1);
                this.setState({
                    freePostArea
                })
            }
        })

    }
    handleRadioChange = (e) => {
        let val = e.target.value,
            formData = this.state.formData || {};
        formData['type'] = val;
        this.setState({
            formData
        })
    }
    addLogisticArea = () => {
        let logisticArea = this.state.logisticArea,
            selectItems = []
        logisticArea.forEach(item => {
            selectItems = selectItems.concat(item.rowCityData);
        })


        this.AreaPicker.show({
            selectItems:selectItems,
            callback: (res) => {
                if (res.length) {
                    let tmp = JSON.stringify(this.state.defaultPostSet),
                        newData = JSON.parse(tmp);
                    newData.rowCityData = res;
                    logisticArea = logisticArea.concat(newData);
                    logisticArea = this.dealAreaName(logisticArea);
                    this.setState({
                        logisticArea
                    })
                }
            }
        })
    }
    dealAreaName = (data = []) => {
        data.forEach((item, index) => {
            let rowCityData = item.rowCityData,
                tmp = '';
            rowCityData.forEach(province => {
                if(!province.id){ province.id = province.province_id }
                if(province.indeterminate == 1){
                    province.city.forEach(city => {
                        if(!city.id){ city.id = city.city_id }
                        tmp = tmp + city.city_name + '，'
                    })
                }else {
                    province.city.forEach(city => {
                        if(!city.id){ city.id = city.city_id }
                    })
                    tmp = tmp + province.province_name + '，'
                }
            })
            item.province_city_name = tmp
        })
        return data;
    }
    addFreeArea = () => {
       let freePostArea = this.state.freePostArea,
           selectItems = [];
        freePostArea.forEach(item => {
            selectItems = selectItems.concat(item.rowCityData);
        })

        this.AreaPicker.show({
            selectItems:selectItems,
            callback : (res) => {
               if(res.length){
                   let newData = { rowCityData:[], type:1, amount:0, weight:0 }
                   newData.rowCityData = res
                   freePostArea = freePostArea.concat(newData)
                   freePostArea = this.dealAreaName(freePostArea);
                   this.setState({
                       freePostArea
                   })


               }
           }
       })

    }

    handleColumn = () => {
        let columns = this.state.columns,
            type = this.state.formData.type || 1;
        columns.forEach(column => {
            switch (column.dataIndex) {
                case 'first_num':
                    column.title = `${type == 2 ? '首重' : '首件'}`
                    break;
                case 'renew_num':
                    column.title = `${type == 2 ? '续重' : '续件'}`
                    break;
            }
        })
        return this.state.columns
    }
    handleFreePost = (e) => {
        let val = e.target.checked;
        this.setState({
            isFreePost:val
        })
    }
    logisticTableFooter = () => {
        return (
            <div>
                <Button type={'default'} size={'small'} icon={'plus'} onClick={this.addLogisticArea}>添加区域</Button>
            </div>
        )
    }
    freeTableFooter = () => {
        return (
            <div>
                <Button type={'default'} size={'small'} icon={'plus'} onClick={this.addFreeArea}>添加区域</Button>
            </div>
        )
    }
    saveData = ()  => {
        let sendData = {},
            logisticArea = this.state.logisticArea,
            freePostArea = this.state.freePostArea;
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
                sendData.default_post_set = logisticArea[0];
                if(logisticArea.length > 1){
                    sendData.logistic_area = logisticArea.slice(1);
                }else {
                    sendData.logistic_area = []
                }
                sendData.free_area = freePostArea;
                if(this.state.formData.id){
                    sendData.id = this.state.formData.id
                }
                this.setState({
                    isLoading:true
                })
                addExpressTemplate(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:'/express/template'
                                    })
                                })

                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }

    render() {
        const data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 2
            },
            wrapperCol: {
                span: 21
            }
        }

        return (
            <div className={`shadow-radius`}>
                <AreaPicker onRef={e => {
                    this.AreaPicker = e
                }}></AreaPicker>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    <div className={`msg-title mgb15`}>基本信息</div>
                    <FormItem label={'模板名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '设置运费模板名称'
                                }
                            ]
                        })(<Input placeholder={'请输入模板名称，不超过20字符'} data-name={`name`}
                                  maxLength={20}/>)}
                    </FormItem>
                    <FormItem label={'计费方式'}>
                        {getFieldDecorator('type', {
                            initialValue: data.type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择计费方式'
                                }
                            ]
                        })(
                            <Radio.Group onChange={this.handleRadioChange}>
                                <Radio value={1}>按件数</Radio>
                                <Radio value={2}>按重量</Radio>
                            </Radio.Group>
                        )}

                    </FormItem>
                    <FormItem label={`运费设置`}>
                        <Table
                            size={`middle`}
                            columns={this.handleColumn()}
                            dataSource={this.state.logisticArea}
                            loading={this.state.tableLoading}
                            rowKey={(record, index) => index}
                            pagination={false}
                            bordered
                            footer={this.logisticTableFooter}
                        ></Table>
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Checkbox
                            checked={this.state.isFreePost}
                            onChange={this.handleFreePost}
                        >
                            指定条件包邮
                        </Checkbox>
                    </FormItem>
                    {
                        this.state.isFreePost
                            ?
                            <FormItem label={` `} colon={false}>
                                <Table
                                    size={`middle`}
                                    columns={this.state.freeColumns}
                                    dataSource={this.state.freePostArea}
                                    loading={this.state.tableLoading}
                                    rowKey={(record, index) => index}
                                    pagination={false}
                                    bordered
                                    footer={this.freeTableFooter}
                                ></Table>
                            </FormItem>
                            :
                            null
                    }
                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={ this.saveData }>立即保存</Button>
                    </FormItem>
                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(AddExpressTemplate))
