import React from 'react'
import { Modal, Table } from 'antd'

class CouponFitStore extends React.Component{
    state = {
        title:'适用门店',
        visible:false,
        callback:null,
        columns:[
            {
                title:'门店名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.name || '--'}</div>
                    )
                }
            },
            {
                title:'地址',
                dataIndex:'address',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.address || '--'}</div>
                    )
                }
            }
        ],
        tableData:[]
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback()
        }
        this.close()
    }
    handleCancel = () => {
        this.close()

    }
    render() {
        let lists = this.props.stores || [];
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={ lists }
                    rowKey={record => record.id}
                    style={{ width:'650px' }}
                ></Table>
            </Modal>
        )
    }
}
export default CouponFitStore
