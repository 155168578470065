import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Form, Input, Badge, Button, Table, Alert, Select, Col, Row, Icon, Modal } from 'antd'
import CopyTime from '@/views/ktv/components/copy-time'
import { storeLists } from '@/api/store'
import { getTotalBook, setTotalBook, copyTotalBook } from '@/api/court'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import PatchPrice from './patch-price'
import '@/assets/css/ktv/index.scss'
const FormItem = Form.Item

class TotalBookManage extends React.Component{

    state = {
        allStore:[],
        currentStore:'',
        week:[
            { id:1,name:'周一',is_choose:1,special_date:'' },
            { id:2,name:'周二',is_choose:0,special_date:'' },
            { id:3,name:'周三',is_choose:0,special_date:'' },
            { id:4,name:'周四',is_choose:0,special_date:'' },
            { id:5,name:'周五',is_choose:0,special_date:'' },
            { id:6,name:'周六',is_choose:0,special_date:'' },
            { id:0,name:'周日',is_choose:0,special_date:'' },
        ],
        currentWeek:{ id:1,name:'周一',is_choose:1,special_date:'' },
        spaceTypeLists:[],
        currentSpaceType:'',
        spaceLists:[],//场地号
        timeData:[],
        price_data:'',
        showEdit:false,
        isLoading:true
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchStore()
            .then(() => {
                this.fetchData()
            })
    }
    fetchData = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        sendData.store_id = currentStore ? currentStore.id : ''
        this.setState({
            isLoading:true
        })
        getTotalBook(sendData)
            .then(res => {
                if(res.code == 1){
                    let spaceTypeLists = res.data.space_type_list,
                        timeData = res.data.time_data || [],
                        price_data = res.data.price_data  || {},
                        spaceLists = res.data.space_list || [];
                    spaceTypeLists.forEach((item,index) => {
                        item.is_choose = 0;
                    })
                    if(spaceTypeLists && spaceTypeLists.length){
                        spaceTypeLists[0]['is_choose'] = 1;
                        this.setState({
                            spaceTypeLists,
                            timeData,
                            spaceLists,
                            price_data,
                            currentSpaceType:spaceTypeLists[0]
                        },() => {
                            this.handleTableData()
                        })
                    }else {
                        message.error('请设置场地类型')
                    }

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading:true
        })
        return new Promise((resolve, reject) => {
            storeLists(sendData)
                .then(res => {
                    if(res.code == 1){
                        let lists = res.data.store.data || [],
                            currentStore = this.state.currentStore || '';
                        if(!currentStore){
                            currentStore = lists[0]
                        }
                        this.setState({
                            allStore:lists,
                            currentStore
                        },() => {
                            resolve()
                        })
                    }else {
                        reject()
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })

    }
    handleStoreChange = (val) => {
        let currentStore = '',
            allStore = this.state.allStore || [];
        if(val){
            allStore.map(item => {
                if(item.id == val){
                    currentStore = item;
                    this.setState({
                        currentStore
                    },() => {
                        this.fetchData();
                    })
                }
            })
        }

    }
    renderWeek = () => {
        let week = this.state.week || [];
        return(
            <div className={`ktv-btn-group mgb15`}>
                {
                    week.map((item,index) => (
                        <div className={`ktv-btn`} key={index}>
                            <Button
                                type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                onClick={ () => { this.handleWeek(item,index) } }
                            >
                                <div>
                                    { item.name }
                                </div>
                            </Button>
                        </div>
                    ))
                }
            </div>
        )

    }
    handleWeek = (rowData,index) => {
        let week = this.state.week || [];
        week.forEach((item) => {
            item.is_choose = 0
        })
        week[index]['is_choose'] = 1;
        this.setState({
            week,
            currentWeek:week[index]
        })
    }
    handleSpace = (rowData,index) => {
        let spaceTypeLists = this.state.spaceTypeLists || [];
        spaceTypeLists.forEach((item) => {
            item.is_choose = 0
        })
        spaceTypeLists[index]['is_choose'] = 1;
        this.setState({
            spaceTypeLists,
            currentSpaceType:spaceTypeLists[index]
        })

    }
    handleTableData = () => {
        let currentSpaceType = this.state.currentSpaceType || '',
            currentWeek = this.state.currentWeek || {},
            timeData = this.state.timeData,
            spaceLists = this.state.spaceLists,
            price_data = this.state.price_data || {},
            currentSpaceLists = [],
            columns = [],
            tableData = [];
        if(currentSpaceType){
            currentSpaceLists = spaceLists.filter(item => { return item.space_type_id == currentSpaceType.id })
            timeData.forEach((time,timeIndex) => {
                let newData = {};
                newData['time_id'] = time.id;
                newData['time'] = time['time'];
                tableData.push(newData)
            })
            tableData.forEach((table,tableIndex) => {
                currentSpaceLists.forEach((space,spaceIndex) => {
                    let tmpArr = price_data?.[space.space_type_id]?.[`${currentWeek.id}_${space.id}`] || [],
                        tmpPrice = ''
                    if(tmpArr && tmpArr.length){
                        for(var j in tmpArr){
                            if(tmpArr[j]['id'] == table.time_id){
                                tmpPrice = tmpArr[j]['price']
                            }
                        }
                    }
                    table[`${currentWeek.id}_${space.space_type_id}_${space.id}`] = {
                        week_id:`${currentWeek.id}`,
                        space_type_id:`${space.space_type_id}`,
                        space_id:`${space.id}`,
                        spance_name:`${space.name}`,
                        price:tmpPrice,
                    }
                })
            })
            currentSpaceLists.forEach((child,childKey) => {
                columns.push(
                    {
                        title:
                            <div className={`txtCenter`}>
                                {
                                    this.state.showEdit
                                        ?
                                        <div>{child.name} <span className={`fi-link mgl5`} style={{ fontWeight:'normal' }} onClick={ () => { this.handlePatchPrice(`${currentWeek.id}_${currentSpaceType.id}_${child.id}`) } } >批量设置</span></div>
                                        :
                                        <span>{child.name}</span>
                                }
                            </div>,
                        dataIndex:child.id,
                        width: 200,
                        render:(text, rowData, index) => {
                            return (
                                <div className={`lh25`}>
                                    {
                                        this.state.showEdit
                                            ?
                                            <Input
                                                min={1}
                                                style={{ width:'150px' }}
                                                value={rowData[`${currentWeek.id}_${currentSpaceType.id}_${child.id}`]['price']}
                                                className={`mgr5`}
                                                addonAfter={`元/场`}
                                                onChange={ (e) => { this.handlePriceChange(e,rowData,index,`${currentWeek.id}_${currentSpaceType.id}_${child.id}`) } }
                                            />
                                            :
                                            <span className={`mgr5`}>{ rowData[`${currentWeek.id}_${currentSpaceType.id}_${child.id}`]['price'] ? rowData[`${currentWeek.id}_${currentSpaceType.id}_${child.id}`]['price'] + `元/场` : '未设置'}</span>
                                    }
                                </div>
                            )
                        }
                    }
                )
            })
            let thFirst = {
                    title: '时段/场地',
                    width: 150,
                    dataIndex: 'timeRange',
                    fixed: 'left',
                    render:(text, rowData, index) => {
                        return (
                            <div className={`lh25`}>
                                <span>{`${rowData.time || '-'}`}</span>
                            </div>
                        )
                    }
                };
            columns = [ thFirst,...columns ]
            return(
                <div className={'content-body mgt15'}>
                    <Table
                        style={{ width:'100%' }}
                        size={`middle`}
                        columns={ columns }
                        dataSource={ tableData }
                        rowKey={(record,index) => index }
                        scroll={{ x: 1000 }}
                        pagination={false}
                    ></Table>
                </div>
            )

        }

    }
    showEdit = () => {
        this.setState({
            showEdit:true
        })
    }
    handleCancel = () => {
        this.setState({
            showEdit:false
        })
    }
    handlePriceChange = (e,rowData,index,keyName) => {
        let val = e.target.value,
            price_data = this.state.price_data || {},
            [ week_id, space_type_id, space_id ] = keyName.split('_');
        if(!price_data[space_type_id]){
            price_data[space_type_id] = {}
        }
        if(!price_data[space_type_id][`${week_id}_${space_id}`]){
            price_data[space_type_id][`${week_id}_${space_id}`] = []
        }
        let tmp = price_data[space_type_id][`${week_id}_${space_id}`],
            hasData = false;
        for(var i in tmp){
            if(tmp[i]['id'] == rowData['time_id']){
                hasData = true
                tmp[i]['price'] = val
            }
        }
        if(!hasData){
            //增加一条
            tmp.push({
                id:rowData.time_id,
                start_time:rowData.time.split('~')[0],
                end_time:rowData.time.split('~')[1],
                price:val
            })
        }
        this.setState({ price_data })
    }
    handlePatchPrice = (keyName) => {
        let [ week_id, space_type_id, space_id ] = keyName.split('_'),
            price_data = this.state.price_data || {},
            timeData = this.state.timeData || [];
        if(!price_data[space_type_id]){
            price_data[space_type_id] = {}
        }
        price_data[space_type_id][`${week_id}_${space_id}`] = []
        let tmp = price_data[space_type_id][`${week_id}_${space_id}`];
        this.PatchPrice.show(res => {
            for(var i in timeData){
                tmp.push({
                    id:timeData[i]['id'],
                    start_time:timeData[i].time.split('~')[0],
                    end_time:timeData[i].time.split('~')[1],
                    price:res.price
                })
            }
            this.setState({
                price_data
            },() => {
                this.handleSave()
            })
        })
    }
    handleSave = (params = {}) => {
        let sendData = {},
            price_data = this.state.price_data || {},
            currentStore = this.state.currentStore || '',
            datas = {};
        sendData.store_id = currentStore ? currentStore.id : '';
        if(Object.keys(price_data).length){
            for(var i in price_data){
                datas[i] = []
                for(var j in  price_data[i]){
                    for(var k in price_data[i][j]){
                        if(price_data[i][j][k]['price']){
                            price_data[i][j][k]['price'] = parseFloat(price_data[i][j][k]['price']).toFixed(2)
                        }
                    }
                    datas[i].push({
                        week_space_key:j,
                        price_data:price_data[i][j]
                    })
                }
            }
            sendData.price_data = datas
        }else {
            message.error('请设置价格');
            return ;
        }
        this.setState({
            isLoading:true
        })
        setTotalBook(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('保存成功')
                        .then(() => {
                            if(params.close){
                                this.setState({
                                    showEdit:false
                                })
                                this.fetchData()
                            }

                        })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleCopyTime = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '',
            currentSpaceType = this.state.currentSpaceType || '',
            currentWeek = this.state.currentWeek || '';
        sendData.store_id = currentStore ? currentStore.id : '';
        sendData.space_type_id = currentSpaceType ? currentSpaceType.id : '';
        sendData.origin_week_id = currentWeek ? currentWeek.id : '';
        this.CopyTime.show(res => {
            if(res){
                sendData.to_week_ids = res.weeks;
                this.setState({
                    isLoading:true
                })
                copyTotalBook(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('复制成功')
                                .then(() => {
                                    this.setState({
                                        showEdit:false
                                    })
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }


    render() {
        let allStore = this.state.allStore || [],
            currentStore = this.state.currentStore || {},
            spaceTypeLists = this.state.spaceTypeLists || [];
        const { getFieldDecorator } = this.props.form
        return(
            <div className={``}>
                <div>
                    <CopyTime
                        onRef={ e => { this.CopyTime = e } }
                        weeks = { this.state.week }
                        current={ this.state.currentWeek }
                        currentSpaceType = { this.state.currentSpaceType }
                        error={`请注意！复制后该日期原有已设置好的租赁模式及价格设置将被覆盖，请及时检查和确认。`}
                    ></CopyTime>
                    <PatchPrice onRef={ e => { this.PatchPrice = e } }></PatchPrice>
                    <div className={'content-head mgb15'}>
                        <Form className={'search-form clearfix'}>
                            <FormItem label={'选择门店'} >
                                <Select style={{width:200}} placeholder={'请选择'} value={ currentStore.id } onChange={ this.handleStoreChange }>
                                    {
                                        allStore.map(store => (
                                            <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                        ))
                                    }
                                </Select>
                            </FormItem>
                        </Form>
                        <div className={`clearfix`}></div>
                    </div>
                    <div className={`fi-title mgb15`}>价格管理</div>
                    <div className={`mgb15`}>
                        <Button type='default' onClick={() => this.showEdit()}>编辑价格表</Button>
                    </div>
                    <Alert message={`选择门店进行单独设置，如有不同类型的场地，也请分别设置，设置后保存。`} className={`mgb15`} type="warning" banner />
                    <div className={`ktv-btn-group mgb15`}>
                        {
                            spaceTypeLists.map((item,index) => (
                                <div className={`ktv-btn`} key={index}>
                                    <Button
                                        type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                        onClick={ () => { this.handleSpace(item,index) } }
                                    >
                                        <div>
                                            { item.name }
                                        </div>
                                    </Button>
                                </div>
                            ))
                        }
                    </div>
                    { this.renderWeek() }
                    {
                        this.state.showEdit
                            ?
                            null
                            :
                            <div className={`mgb15`}>
                                <Button type={`primary`} onClick={ this.handleCopyTime }>复制到其他日期</Button>
                            </div>
                    }

                    {
                        this.handleTableData()
                    }
                    {
                        this.state.showEdit
                            ?
                            <div className={`mgt15`}>
                                <Button type={'primary'} onClick={ () => { this.handleSave({ close:true }) } } >保存价格表</Button>
                                <Button type={'default'} onClick={this.handleCancel} className={`mgl10`}>取消</Button>
                            </div>
                            :
                            null
                    }

                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}
export default Form.create({})(withRouter(TotalBookManage))
