import React, { Component } from 'react'
import { Button, Table, Icon, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { getConfig } from '@/api/config'

 class PointsRules extends Component {
    state={
        columns: [
            {
                title: '规则名称',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.name}</p>
                    )
                }
            },
            {
                title: '规则详情',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    if(Array.isArray(rowData.desc)){
                        return (
                            <div>
                                {
                                    rowData.desc.map((val,i)=>{
                                        return <p key={i}>{ val} </p>
                                    })
                                }
                            </div>
                        )
                    }
                    return (
                        <p>{ rowData.desc || '-'} </p>
                    )
                }
            },
            {
                title: '更新时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.updated_at || '-' }</p>
                    )
                }
            },
        ],
        columns2: [
            {
                title: '规则名称',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p> {rowData.name}</p>
                            {index==1?<p className='fi-help-text'>需先设置积分兑换比例才可使用</p>:null}
                        </div>
                    )
                }
            },
            {
                title: '规则详情',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    if(Array.isArray(rowData.desc)){
                        return (
                            <div>
                                {
                                    rowData.desc.map((val,i)=>{
                                        return <p key={i}>{ val} </p>
                                    })
                                }
                            </div>
                        )
                    }

                    return (
                        <p>{ rowData.desc || '-'} </p>
                    )
                }
            },
            {
                title: '更新时间',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.updated_at || '-' }</p>
                    )
                }
            },
        ],
        tableData:[
            {
                name:"积分有效期",
                desc:"永久有效",
                updated_at:""
            },
            {
                name:"积分发放规则",
                desc:"",
                updated_at:""
            },
            {
                name:"积分获取上限",
                desc:"",
                updated_at:""
            },
        ],
        tableData2:[
            {
                name:"积分抵扣比例",
                desc:"",
                updated_at:""
            },
            {
                name:"积分抵现规则",
                desc:"",
                updated_at:""
            }
        ],
        tableLoading:false
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData=async ()=>{
        this.setState({
            tableLoading:true
        })

        let res=await getConfig({ key: 'points_general_rule'});
        let res2=await getConfig({ key: 'points_cost_rule' });
        
        this.setState({
            tableLoading:false
        })

        let tableData=this.state.tableData,tableData2=this.state.tableData2;
        if(res.code==1){
            let {value,updated_at}=res.data.config;
            if(value){;
                value = JSON.parse(value);
                if(value.validity_rule ==1){
                    tableData[0].desc="永久有效";
                }else if(value.validity_rule ==2){
                    tableData[0].desc=`每笔积分，在发放之日起${value.validity.year_month_num}${['年','月'][value.validity.date_type - 1]}后过期`
                }else if(value.validity_rule ==3){
                     tableData[0].desc=`每笔积分，在发放之日起${value.validity.year_month_num}${['年','月'][value.validity.date_type - 1]}后的${value.validity.day_num}日过期`
                }else if(value.validity_rule ==4){
                     tableData[0].desc=`每笔积分，在发放之日起${value.validity.year_month_num}年后的${value.validity.day_num}日过期`
                }

                tableData[1].desc= value.grant_rule.map(val=>{
                    if(val==1){
                        val=`购买商品时，每消费${value.grant.goods_money}元获得${value.grant.goods_points}积分`
                    }else if(val==2){
                        val=`购买课程时，每消费${value.grant.course_money}元获得${value.grant.course_points}积分`
                    }
                    return val
                })
                tableData[2].desc=`每个客户每天通过消费最多获取${value.points_max}积分`

                tableData[0].updated_at=updated_at
                tableData[1].updated_at=updated_at
                tableData[2].updated_at=updated_at
            }
        }

        if(res2.code==1){
            let {value,updated_at}=res2.data.config;
            if(value){
                value = JSON.parse(value);
                tableData2[0].desc=`${value.deduction_points}积分=1元`;

                if(value.deduction_switch){
                    tableData2[1].desc=[];
                    if(value.order_threshold==1){
                        let str=`订单金额门槛：不限制`
                        tableData2[1].desc.push(str)
                    }else if(value.order_threshold==2){
                        let str=`订单金额门槛：订单金额最低为${value.order_min_price}元`
                        tableData2[1].desc.push(str)
                    }

                    if(value.deduction_max ==1){
                        let str=`抵扣金额上限：不限制`
                        tableData2[1].desc.push(str)
                    }else if(value.deduction_max==2){
                        let str=`抵扣金额上限：每笔订单最多抵扣${value.order_max_price}元`
                        tableData2[1].desc.push(str)
                    }else if(value.deduction_max==3){
                        let str=`抵扣金额上限：每笔订单最多抵扣${value.order_max_percentage}%`
                        tableData2[1].desc.push(str)
                    }

                    tableData2[0].updated_at=updated_at
                    tableData2[1].updated_at=updated_at
                }
            }
        }

        this.setState({
            tableData,
            tableData2
        })

    }

    handleLink=(type)=>{
       
        this.props.history.push({
            pathname: '/operating/points-rules-edit/' + type
        })
    }

    render() {
        return (
            <div className={`shadow-radius`}>
                <div className="msg-title">通用积分规则设置 <Button type='link' size='small' className='mgl20' onClick={()=>{this.handleLink(1)}} >编辑</Button></div>
                <div className="content-body mgt15">
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(_,i) => i}
                        pagination={false}
                    ></Table>
                </div>
                <div className="msg-title mgt40">积分消耗规则设置 <Button type='link' size='small' className='mgl20' onClick={()=>{this.handleLink(2)}}>编辑</Button></div>
                <div className="content-body mgt15">
                    <Table
                        size={`middle`}
                        columns={this.state.columns2}
                        dataSource={this.state.tableData2}
                        loading={this.state.tableLoading}
                        rowKey={(_,i) => i}
                        pagination={false}
                    ></Table>
                </div>

            </div>
        )
    }
}

export default withRouter(PointsRules)