import React from 'react'
import { Form, Input } from 'antd'
const FormItem = Form.Item

class MakeTel extends React.Component{
    state = {
        formData:''
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    handleTel= e => {
        let tel = e.target.value,
            data = this.props.data || {},
            res = {};
        res.linkType = data.options[0]['linkType'];
        res.name = data.options[0]['name'];
        res.link = tel;
        res.tel = tel;
        this.props.onChange && this.props.onChange([res])
    }
    clearData = () => {
        this.setState({
            formData:''
        })
    }
    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 18
            }
        }

        return(
            <div>
                <Form className={`model-form`} {...formItemLayout}>
                    <FormItem label={'电话号码'} >
                        {
                            getFieldDecorator('tel',{
                                initialValue: data.tel || ''
                            })(
                                <Input placeholder='请输入电话号码' style={{ width:'300px' }} onChange={ this.handleTel } />
                            )
                        }
                    </FormItem>
                </Form>
            </div>
        )
    }
}

export default Form.create({})(MakeTel)
