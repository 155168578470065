import $axios from '@/axios/axios.js'

export function comboLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/combo/lists`,
        method:'post',
        data
    })
}

export function suitStores(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/combo/suitStores`,
        method:'post',
        data
    })
}

export function saveCombo(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/combo/save`,
        method:'post',
        data
    })
}

export function comboDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/combo/detail`,
        method:'post',
        data
    })
}


export function comboDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/combo/delete`,
        method:'post',
        data
    })
}

export function copyCombo(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/combo/copy`,
        method:'post',
        data
    })
}


