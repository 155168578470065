import React, { Component } from 'react'
import { Statistic, Radio, Icon, Tooltip,DatePicker } from 'antd';
import {pointsData,pointsTrend} from '@/api/data'
import Loading from '@/components/Loading'
import Chart from '@/components/Chart/Chart'
import UserPointsRanKing from './user-total-ranking';
import RanKingTop10 from './ranking-top10';
import PointsDistribution from './points-distribution';
import moment from 'moment';
const {   RangePicker  } = DatePicker;

export default class PointsData extends Component {
    state={
        chartData:{
            tooltip:{
                trigger:'axis',
            },
            legend:{
                data:['发放积分','消耗积分','过期积分'],
                top:'20',
                right:'20'
            },
            grid: {
                left: '1%',
                right: '50',
                bottom: '1%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLabel: {
                    //x轴文字的配置
                    interval:0,
                    show:true,
                },
                splitLine:{show:false},
                data:[]
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '发放积分',
                    type: 'line',
                    stack: '发放积分',
                    smooth: true,
                    data: []
                },
                {
                    name: '消耗积分',
                    type: 'line',
                    stack: '消耗积分',
                    smooth: true,
                    data: []
                },
                {
                    name: '过期积分',
                    type: 'line',
                    stack: '过期积分',
                    smooth: true,
                    data: []
                }
            ],
            color:['#7F64FF','#B6CFFF','#f44']
        },
        isLoading:false,
        basic:{},
        exchange:{},
        dateForm:{
            start_time:moment().add(-7,'d').format('YYYY-MM-DD'),
            end_time:moment().startOf('day').format('YYYY-MM-DD')
        },
        dateType:'1'
    }

    componentDidMount(){
        this.fetchData()
        this.fetchTrend()
    }

    fetchData=()=>{
        this.setState({
            isLoading:true
        })
        pointsData({}).then(res=>{
            if(res.code==1){
                let {exchange,basic}=res.data;
                this.setState({
                    basic,
                    exchange
                })
            }

        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    fetchTrend=()=>{
        this.setState({
            isLoading:true
        })
        pointsTrend(this.state.dateForm).then(res=>{
            if(res.code==1){
                let trend_list=res.data.trend_list;
                let arr1=[],arr2=[],arr3=[],date=[];
                trend_list.forEach(item=>{
                    arr1.push(item.give_out_points );
                    arr2.push(item.cost_points);
                    arr3.push(item.expired_points);
                    date.push(item.date)
                })
                let chartData=this.state.chartData;
                chartData.series[0].data=arr1;
                chartData.series[1].data=arr2;
                chartData.series[2].data=arr3;
                chartData.xAxis.data=date
                this.setState({
                    chartData
                })

            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    handleChangeDate=(e)=>{
        let value=+e.target.value;
        if(value==1){
            this.setState({
                dateForm:{
                    start_time:moment().add(-7,'d').format('YYYY-MM-DD'),
                    end_time:moment().startOf('day').format('YYYY-MM-DD')
                },
                dateType:value+''
            },()=>{
                this.fetchTrend()
            })
        }else if(value==2){
            this.setState({
                dateForm:{
                    start_time:moment().add(-30,'d').format('YYYY-MM-DD'),
                    end_time:moment().startOf('day').format('YYYY-MM-DD')
                },
                dateType:value+''
            },()=>{
                this.fetchTrend()
            })
        }
    }
    handleChangeDateStr=(time,timeStr)=>{
        let [start_time,end_time]=timeStr;
        if(!(start_time && end_time)){
            return
        }
        this.setState({
            dateForm:{
                start_time,end_time
            },
            dateType:'0'
        },()=>{
            this.fetchTrend()
        })
    }

    render() {

        const {basic,exchange}=this.state


        return (
            <div>
                <p>
                    <span className='fz18 color-000'>实时监测</span>
                    <span className='mgl20'>更新时间:2023-04-09 12:23:45</span>
                </p>
                <div className='flex mgt15'>
                    <div className='bg-fafafa pd15 radius-3 flex1'  >
                        <Statistic
                            title={
                                <>
                                    可用积分
                                    <Tooltip placement={`right`} title={`当前可用积分数`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic?.total_points||0}
                        />
                    </div>
                    <div className='bg-fafafa pd15 radius-3 flex1 mgl30'>
                        <Statistic
                            title={
                                <>
                                    累计发放积分
                                    <Tooltip placement={`right`} title={`累计发放的积分总和`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic?.total_give_out_points || 0}
                        />
                    </div>
                    <div className='bg-fafafa pd15 radius-3 flex1 mgl30'>
                        <Statistic
                            title={
                                <>
                                    累计消耗积分
                                    <Tooltip placement={`right`} title={`客户累计消耗的积分总和（包含所有扣减的积分，如积分兑换、手动扣减、参与活动等）`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic?.total_use_points ||0}
                        />
                    </div>
                    <div className='bg-fafafa pd15 radius-3 flex1 mgl30'>
                        <Statistic
                            title={
                                <>
                                    过期积分
                                    <Tooltip placement={`right`} title={`累计过期的积分总和`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic?.total_expired_points || 0}
                        />
                    </div>
                    <div className='bg-fafafa pd15 radius-3 flex1 mgl30'>
                        <Statistic
                            title={
                                <>
                                    积分消耗率
                                    <Tooltip placement={`right`} title={`累计消耗积分 / 累计发放积分`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </>
                            }
                            value={basic.total_use_percent }
                            precision={2}
                            suffix="%"
                        />
                    </div>
                </div>
                <div className='mgt20'>
                    <span className='fz18 color-000'>积分趋势</span>
                    <Tooltip placement={`right`} title={`统计时间截止到前一天`}>
                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                    </Tooltip>
                </div>
                <div className='flex mgt15'>
                    <div>
                        <Radio.Group onChange={this.handleChangeDate} value={this.state.dateType} >
                            <Radio.Button value="1">近七天</Radio.Button>
                            <Radio.Button value="2">近30天</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className='mgl20'>
                        <RangePicker onChange={this.handleChangeDateStr}  />
                    </div>
                </div>
                <div>
                     <Chart chartData={this.state.chartData} className={'line-chart'} height={'450px'}></Chart>
                </div>
                <div className='mgt20'>
                    <PointsDistribution />
                </div>
                <div className='mgt20'>
                    <span className='fz18 color-000'>客户积分累计排行</span>
                    <Tooltip placement={`right`} title={`客户积分变动排行为截止昨日客户累计或可用积分数TOP1000排名情况（默认按累计获得积分数排行）`}>
                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                    </Tooltip>
                </div>
                <div className="mgt15">
                    <UserPointsRanKing  />
                </div>
                <div className="mgt30">
                    <RanKingTop10 exchange={exchange} />
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}
