import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Tooltip, Tag, TreeSelect, Drawer, Icon, message } from 'antd'
import moment from 'moment'
import { pullNewPullData ,exportPullNew} from "@/api/recruit.js"
import CX from '@/utils/CX'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class RecruitActivity extends Component {

    state = {
        columns: [
            {
                title: '分享人信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className='flex align-center'>
                            <img src={rowData.s_head_img_url} width={60} alt="" />
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.s_name || ''}
                                    <i className={`icon-${rowData.s_name == 1 ? 'male' : rowData.s_name == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.s_mobile || `暂无手机号`}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '被邀请人信息',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div className='flex align-center'>
                            <img src={rowData.u_head_img_url} width={60} alt="" />
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.u_name || ''}
                                    <i className={`icon-${rowData.u_sex == 1 ? 'male' : rowData.u_sex == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.u_mobile || `暂无手机号`}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Tag color={'green'}> 邀请成功</Tag>
                            <p> {rowData.is_share_user_reward == 1 ? "" : "奖励发放失败"}</p>
                        </div>
                    )

                }
            },

            {
                title: <div>分享人奖励
                    <Tooltip placement={`right`} title={`分享人奖励：成功邀请新用户授权手机号，分享人将获得奖励，奖励自动发放，发放成功后不可撤回`}>
                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: '4',
                render: (text, rowData, index) => {

                    return (
                        <p> {rowData.share_user_reward_name || '-'}</p>
                    )
                }
            },
            {
                title: '被邀请人奖励',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>  {rowData.by_share_user_reward_name ||"-"}</p>
                    )
                }
            },
           
            {
                title: '邀请成功时间',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.share_time || '-'}</p>
                    )
                }
            },


        ],
        searchForm: {
            share_user_name: "",
            user_name:"",
            start_time: "",
            end_time: ""
        },
        storeList: [],
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false,
       
    }

    componentDidMount() {
        this.fetchData()
        
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.id = this.props.match.params.id

        this.setState({
            tableLoading: true
        })
        pullNewPullData(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }



    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }

    handleExport = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let sendData = values;
                sendData.id = this.props.match.params.id
                this.setState({
                    isLoading: true
                })
                exportPullNew(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '拉新活动数据.csv';
                        CX.exportExcel(blob, fileName)
                    }).finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }





    render() {
       
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;分享人信息</span>}  className={'fl'}>
                                {
                                    getFieldDecorator('share_user_name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入呢称/手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'被邀请人信息'} className={'fl mgl40'}>
                                {
                                    getFieldDecorator('user_name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入呢称/手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                        </div>

                        <div className={`clearfix`}>
                          
                            <FormItem label={'邀请成功时间'} className={'fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: null
                                    })(
                                        <RangePicker
                                            style={{ width: 400 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder={['开始时间', '结束时间']}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>}  >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleExport() }}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default Form.create({})(withRouter(RecruitActivity))