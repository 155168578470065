/**
 * 限时折扣组件
 * @type 13
 * @return Object
 */
import React from 'react'
import { Form, Slider, Input, InputNumber, Tooltip, Radio, Button, Icon, Modal } from 'antd'
import PropTypes from "prop-types";
import TimeDiscountPicker from '@/components/TimeDiscountPicker/TimeDiscountPicker'
const FormItem = Form.Item
const { TextArea } = Input
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 17 }}

class tpl_ctrl_type13 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }


    state = {
        moduleData:{},
        itemStyleGroup:[
            {
                name:'列表',
                style:1,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/0286fef6460e27c1e3a8f796991e2380.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/845a95002c6d0adfda6928801d74ce7a.png']
            },
            {
                name:'横划',
                style:2,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/6ba92c930723d0fcbcf775bda369adb1.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/f1dcbd0b7694e2e1cff713b1d0c3aa4d.png']
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }


    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }

    changeDesc = e => {
        let moduleData = this.state.moduleData;
        moduleData.content.desc = e.target.value;
        this.context.updateDesign(moduleData)
    }
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)
    }
    handleStyleChange = (e,name) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData)

    }
    handleInputChange = (e,item,index) => {
        e.persist();
        let val = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content.dataset[index]['goods_limit'] = val
        this.context.updateDesign(moduleData)

    }
    handleAdd = () => {
        let moduleData = this.state.moduleData;
        this.TimeDiscountPicker.show(res => {
            if(res){
                moduleData.content.dataset = res;
                this.context.updateDesign(moduleData)
            }
        })
    }
    handleDel = (rowData,index) => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`是否删除这个活动？`,
            okType:`danger`,
            onOk:() => {
                moduleData.content.dataset.splice(index,1);
                this.context.updateDesign(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <TimeDiscountPicker onRef={ e => { this.TimeDiscountPicker = e } }></TimeDiscountPicker>
                    <div className={`ctrl-right-title`}>限时折扣</div>
                    <Form>
                        <div className={`cx-ctrl-panel`} >
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件样式`}>
                                    <ul className={`template-box`}>
                                        {
                                            this.state.itemStyleGroup.map((item,index) => (
                                                <Tooltip effect="dark" placement="bottom" title={ item.name } key={index}>
                                                    <li className={`temp ${ moduleData.content.style == item.style ? 'selected' : '' }`} onClick={ () => { this.handleItemStyle(item.style) } }>
                                                        {
                                                            moduleData.content.style == item.style
                                                                ?
                                                                <img src={ item.imgs[0] } className={`img`} alt=""/>
                                                                :
                                                                <img src={ item.imgs[1] } className={`img`} alt=""/>

                                                        }
                                                    </li>
                                                </Tooltip>
                                            ))
                                        }
                                    </ul>
                                </FormItem>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                {
                                    moduleData.content.style == 2
                                        ?
                                        <div>
                                            <FormItem label={`商品样式`} { ...ctrlItemLayout }>
                                                <Radio.Group value={moduleData.content.borderStyle} onChange={(e) => { this.handleStyleChange(e,'borderStyle') }}>
                                                    <Radio value={1}>无边</Radio>
                                                    <Radio value={2}>卡片</Radio>
                                                    <Radio value={3}>描边</Radio>
                                                </Radio.Group>
                                            </FormItem>
                                            <FormItem label={`副标题`} { ...ctrlItemLayout }>
                                                <Radio.Group value={moduleData.content.showSubTitle} onChange={(e) => { this.handleStyleChange(e,'showSubTitle') }}>
                                                    <Radio value={1}>显示</Radio>
                                                    <Radio value={0}>不显示</Radio>
                                                </Radio.Group>
                                            </FormItem>
                                        </div>
                                        :
                                        null
                                }


                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>选择活动</div>
                            <div className={`cx-ctrl-panel--box`}>
                                {
                                    moduleData.content.dataset.map((item,index) => (
                                        <div className={`cx-img-list`} key={index}>
                                            <div className={`cx-img-item clearfix`}>
                                                <FormItem label={`活动名称`} {...ctrlItemLayout}>
                                                    <div className={'clearfix'}>
                                                        <p className={`lh40`}>{ item.name }</p>
                                                    </div>
                                                </FormItem>
                                                <FormItem label={`商品数量`} {...ctrlItemLayout}>
                                                    <Input type={`text`} value={item.goods_limit} onChange={(e) => { this.handleInputChange(e,item,index) }} max={10}  placeholder={`最多显示10个`}  />
                                                </FormItem>
                                            </div>
                                            <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.handleDel(item,index) }} data-name={'pic'}  />
                                        </div>
                                    ))
                                }
                                <Button type={`primary`} className={`w100`} onClick={this.handleAdd}  >选择活动</Button>

                            </div>
                        </div>
                    </Form>

                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type13)
