/**
 * 多选组件
 * @type 4
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})

class tpl_con_type4 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }
    state = {
        moduleData:{}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这个模块吗？`,
            okType:`danger`,
            onOk:() => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}

        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={'left'} title={`多选`}>
                    <div
                        className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`}
                        style={{
                            paddingTop:`${moduleData.content.modulePadding}px`,
                            paddingBottom:`${moduleData.content.modulePadding}px`,
                            paddingLeft:`${moduleData.content.spacePadding || 0}px`,
                            paddingRight:`${moduleData.content.spacePadding || 0}px`
                        }}
                        onClick={ this.handleEditModule }
                    >
                        <div className={`check-box-module`}>
                            <div className={`title ${ moduleData.content.required == 1 ? 'required' : '' }`}>{ moduleData.content.title }</div>
                            <div className={`sub-title`}>{ moduleData.content.subTitle }</div>
                            {
                                moduleData.content.dataset.map((item,index) => (
                                    <div key={index} className={`check-box`}>
                                        <div className={`check-box-icon ${ item.is_choose == 1 ? 'active' : '' }`}></div>
                                        <div className={`check-box-content`}>{ item.name }</div>
                                    </div>
                                ))
                            }
                        </div>

                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type4)
