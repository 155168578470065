import React from 'react'
import { Modal, Form, Checkbox, Dropdown, Menu, Icon } from 'antd'
import { getCity } from '@/api/express'
import Loading from '@/components/Loading'
import './AreaPicker.scss'
const FormItem  = Form.Item
class AreaPicker extends React.Component{
    state = {
        title:'选择区域',
        visible:false,
        isLoading:false,
        modalLoading:false,
        cityData:[],
        selectItems:[],
        is_default_select:false,
        callback:null,
        chooseAll:false
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = ( params = {} ) => {
        this.setState({
            visible:true,
            callback : params.callback ? params.callback : null,
            selectItems: params.selectItems ? params.selectItems : [],
            is_default_select:params.is_default_select?true : false
        },() => {
            this.fetchCity();
        })

    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    fetchCity = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        getCity(sendData)
            .then(res => {
                if(res.code == 1){
                    let cityData = res.data.data,
                        selectItems = this.state.selectItems,
                        cityIds = [];
                    if(selectItems.length){
                        selectItems.forEach(item => {
                            item.city && item.city.forEach(child => {
                                cityIds.push(child.id)
                            })
                        })
                    }else {
                        cityIds = []
                    }
                    cityData.forEach( (province,provinceIndex ) => {
                        province.is_choose = 0;
                        province.indeterminate = 0;
                        province.cityVisible = 0;
                        province.city && province.city.forEach(city => {
                            city.is_choose = 0;
                            city.area && city.area.forEach(area => {
                                area.is_choose = 0
                            })
                        })
                        if(cityIds.length){
                            
                            if(this.state.is_default_select){
                                province.city = province.city.map(city => {
                                    city.is_choose=cityIds.includes(city.id)
                                    return city
                                })

                            }else{
                                province.city = province.city.filter(city => {
                                    return !cityIds.includes(city.id)
                                })
                            }
                        }

                        if(this.state.is_default_select){
                            province.is_choose=selectItems.some(val=>val.id==province.id)
                        }
                    })
                    this.setState({
                        cityData
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleOk = () => {
        let tmp = JSON.stringify(this.state.cityData),
            result = [],
            callback = this.state.callback,
            cityData = [];
        cityData = JSON.parse(tmp);
        cityData.forEach(province => {
            if(province.is_choose == 1){
                if(province.indeterminate == 1){
                    province.city = province.city.filter(city => {
                        return city.is_choose == 1
                    })
                }
                result.push(province)
            }
        })
        if(callback && typeof callback == 'function'){
            callback(result)
        }
        this.close();
    }
    handleCancel = () => {
        this.close()
    }
    handleProvince = (rowData,index) => {
        let cityData = this.state.cityData,
            is_choose = 0;
        if(rowData.is_choose == 1){
            is_choose = 0
        }else{
            is_choose = 1
        }
        rowData.is_choose = is_choose;
        rowData.indeterminate = 0;
        rowData.city && rowData.city.forEach(city => {
            city.is_choose = is_choose;
            city.area && city.area.forEach(area => {
                area.is_choose = is_choose
            })
        })
        cityData[index] = rowData;
        this.setState({
            cityData
        })
    }
    handleCity = (e,rowData,index,parentIndex) => {
        let cityData = this.state.cityData,
            province = cityData[parentIndex],
            cityCheckCount = 0,
            is_choose = 0;
        if(rowData.is_choose == 1){
            is_choose = 0
        }else {
            is_choose = 1
        }
        rowData.is_choose = is_choose;
        rowData.area && rowData.area.forEach(area => {
            area.is_choose = is_choose
        })
        province['city'][index] = rowData;
        province.city.forEach( city => {
            if(city.is_choose == 1){
                cityCheckCount = cityCheckCount + 1;
            }
        })
        if(cityCheckCount == 0){
            province.is_choose = 0;
            province.indeterminate = 0;
        }else if( cityCheckCount < province.city.length ){
            province.is_choose = 1;
            province.indeterminate = 1
        }else {
            province.is_choose = 1;
            province.indeterminate = 0
        }
        this.setState({
            cityData
        })
    }
    renderCity = (province,provinceIndex) => {
        return(
            <Menu className={`city-menu`}>
                {
                    province.city && province.city.map((city,cityIndex) => (
                        <Menu.Item key={ city.id }>
                            <Checkbox
                                checked={ !!city.is_choose }
                                disabled={false}
                                onChange={ (e) => { this.handleCity(e,city,cityIndex,provinceIndex) } }
                            >
                                <span>{city.city_name}</span>
                            </Checkbox>
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }
    handleVisibleChange = (flag,province,provinceIndex) => {
        let cityData = this.state.cityData;
        cityData[provinceIndex]['cityVisible'] = flag;
        this.setState({
            cityData
        })
    }
    handleAll = (e) => {
        //全选
        let val = e.target.checked,
            cityData = this.state.cityData;
        cityData.forEach(province => {
            province.indeterminate = 0;
            province.is_choose = val ? 1 : 0
            province.city && province.city.forEach(city => {
                city.is_choose = val ? 1 : 0
                city.area && city.area.forEach(area => {
                    area.is_choose = val ? 1 : 0
                })
            })
        })
        this.setState({
            cityData,
            chooseAll:val
        })


    }

    render() {
        let cityData = this.state.cityData
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                    centered={true}
                >
                    <Form className={`area-form area-modal-wrapper`}>
                        <FormItem className={`area-form-item`}>
                            {
                                cityData.map(( province, provinceIndex ) => {
                                    return (
                                        province.city && province.city.length
                                            ?
                                            <div className={`area-modal-province`} key={province.id}>
                                                <Checkbox
                                                    checked={!!province.is_choose}
                                                    indeterminate={!!province.indeterminate}
                                                    disabled={false}
                                                    onChange={() => { this.handleProvince(province,provinceIndex)}}
                                                ></Checkbox>
                                                <Dropdown
                                                    overlay={ this.renderCity(province,provinceIndex) }
                                                    onVisibleChange={(e) => { this.handleVisibleChange(e,province,provinceIndex) }}
                                                    visible={!!province.cityVisible}
                                                >
                                                    <div className={`province-dropdown`}>
                                                        { province.province_name }
                                                        <Icon type="down" className={`down`} />
                                                    </div>
                                                </Dropdown>
                                            </div>
                                            :
                                            null
                                    )
                                })
                            }
                        </FormItem>
                        {
                            cityData.length
                                ?
                                <div className={`area-modal-province`}>
                                    <Checkbox
                                        checked={ this.state.chooseAll }
                                        disabled={false}
                                        onChange={ this.handleAll }
                                    >全选</Checkbox>
                                </div>
                                :
                                null
                        }

                    </Form>
                </Modal>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(AreaPicker)
