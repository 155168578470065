import $axios from '@/axios/axios.js'

export function memberCardLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/member-card/lists`,
        method:'post',
        data
    })
}

export function suitStores(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/member-card/suitStores`,
        method:'post',
        data
    })
}

export function memberCardSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/member-card/save`,
        method:'post',
        data
    })
}

export function memberCardDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/member-card/detail`,
        method:'post',
        data
    })
}

export function memberCardDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/member-card/delete`,
        method:'post',
        data
    })
}

export function memberCardStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/member-card/setStatus`,
        method:'post',
        data
    })
}

export function rechargeRules(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/rechargeRules`,
        method:'post',
        data
    })
}

export function rechargeUserCard(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/rechargeCard`,
        method:'post',
        data
    })
}

export function changeEndDate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/changeCardEndDate`,
        method:'post',
        data
    })
}

export function disuseCard(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/disuseCard`,
        method:'post',
        data
    })
}

export function openUserCard(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/openCard`,
        method:'post',
        data
    })
}

export function openCardDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/member-card/cardDetail`,
        method:'post',
        data
    })
}


