import React from 'react'
import { Modal, Table, Button, Divider, Form, Popover, Input, Pagination } from 'antd'
import { withRouter } from 'react-router-dom'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import { roomLists, roomSave, delRoom } from '@/api/desk'
const FormItem = Form.Item


class RoomManageLists extends React.Component{
    state = {
        title:'包厢/桌台类型',
        visible:false,
        popVisible:false,
        callback:null,
        columns:[
            {
                title:'类型名称',
                dataIndex:'name',
                width:'40%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            {
                                rowData.isEdit
                                    ?
                                    <Input value={ rowData.name } style={{ width:'150px' }} onChange={ (e) => { this.handleInputChange(e,rowData,index) } } data-name={`name`} />
                                    :
                                    <p>{rowData.name || '--'}</p>
                            }
                        </div>
                    )
                }
            },
            {
                title:'容纳人数',
                dataIndex:'room_size',
                width:'40%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            {
                                rowData.isEdit
                                    ?
                                    <Input value={ rowData.room_size } style={{ width:'150px' }} onChange={ (e) => { this.handleInputChange(e,rowData,index) } } data-name={`room_size`}  />
                                    :
                                    <p>{rowData.room_size || '--'}</p>
                            }
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'20%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.isEdit
                                    ?
                                    <div className={`btn-row`}>
                                        <Button type={`link`} size={`small`} onClick={ () => { this.handleEdit(rowData,index) } }>保存</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={`link`} size={`small`} onClick={ () => { this.hideInput(rowData,index) } } >取消</Button>
                                    </div>
                                    :
                                    <div className={`btn-row`}>
                                        <Button type={`link`} size={`small`} onClick={ () => { this.showInput(rowData,index) } }>编辑</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={`link`} size={`small`} onClick={ () => { this.handleDelete(rowData,index) } }>删除</Button>
                                    </div>
                            }
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.store_id = this.props.match.params.id || ``;
        this.setState({
            tableLoading:true
        })
        roomLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.room_list.data,
                        total = res.data.room_list.total,
                        per_page = res.data.room_list.per_page;
                    lists.forEach(item => {
                        item.isEdit = false
                    })
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    close = () => {
        this.closePop()
        this.setState({
            visible:false,
        })
    }
    closePop = () => {
        this.setState({
            popVisible:false
        })
        this.props.form.resetFields();
    }
    handleAdd = () => {
        this.setState({
            popVisible:true
        })
    }
    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback()
        }
        this.close()
    }
    handleCancel = () => {
        this.close()

    }
    showInput = (rowData,index) => {
        let lists = this.state.tableData;
        lists[index]['isEdit'] = true;
        this.setState({
            tableData:lists
        })
    }
    hideInput = (rowData,index) => {
        let lists = this.state.tableData;
        lists[index]['isEdit'] = false;
        this.setState({
            tableData:lists
        })
        this.fetchData()
    }
    handleInputChange = (e,rowData,index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value;
        lists[index][name] = val;
        this.setState({
            tableData:lists
        })

    }
    handleEdit = (rowData,index) => {
        let sendData = {};
        sendData.id = rowData.id || ``;
        sendData.name = rowData.name || ``;
        sendData.room_size = rowData.room_size || ``;
        sendData.store_id = this.props.match.params.id || ``;
        if(!sendData.name || !sendData.room_size){
            message.error('请填写内容');
            return ;
        }
        this.setState({
            isLoading:true
        })
        roomSave(sendData)
            .then(res => {
                if(res.code == 1){
                    this.closePop()
                    message.success('编辑成功')
                        .then(() => {
                            this.fetchData();
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })



    }
    handleDelete = (rowData,index) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        sendData.store_id = this.props.match.params.id || ``;
        Modal.confirm({
            title:'是否删除该包厢/桌台类型？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                delRoom(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleSaveRoom = () => {
        let sendData = {};
        this.props.form.validateFields((err,values) => {
            if(!err){
                sendData = values;
                this.setState({
                    isLoading:true
                })
                sendData.store_id = this.props.match.params.id || ``;
                roomSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            this.closePop()
                            message.success('添加成功')
                                .then(() => {
                                    this.setState({
                                        page:1
                                    },() => {
                                        this.fetchData();
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        const content = (
            <div>
                <Form {...formItemLayout}  className={`model-form`} >
                    <FormItem label={`类型名称`} className={`mgb5`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入类型名称'
                                    }
                                ]
                            })(
                                <Input placeholder='请输入类型'/>
                            )
                        }
                    </FormItem>
                    <FormItem label={`容纳人数`} className={`mgb5`}>
                        {
                            getFieldDecorator('room_size',{
                                initialValue: '',
                                rules: []
                            })(
                                <Input placeholder='例如3-5'/>
                            )
                        }
                    </FormItem>
                    <FormItem label={` `} colon={false} className={`clearfix mgb5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={ this.handleSaveRoom }>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={ this.closePop }>取消</Button>
                    </FormItem>
                </Form>
            </div>
        )
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={800}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <div className={`mgb15`}>
                    <Popover
                        content={content}
                        title={null}
                        placement={`right`}
                        trigger="click"
                        visible={this.state.popVisible}>
                        <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>添加类型</Button>
                    </Popover>
                </div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={ this.state.tableData }
                    rowKey={record => record.id}
                    loading={this.state.tableLoading}
                    style={{ width:'750px' }}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </Modal>
        )
    }
}
export default  Form.create({})(withRouter(RoomManageLists))
