import React from 'react'
import {withRouter} from 'react-router-dom'
import {Modal, Input, Form, Select, Row, Col} from 'antd'
import message from '@/utils/message'
import moment from 'moment'
import Gallery from '@/components/Gallery/Gallery'

const FormItem = Form.Item

class AddTimeMeal extends React.Component {
    state = {
        formData: {},
        title: '选择套餐',
        visible: false,
        modalLoading: false,
        callback: null,
        disableIds:[]

    }

    componentDidMount() {
        this.props.onRef(this)
    }

    show = (params = {}) => {
        this.setState({
            visible: true,
            disableIds:params.disableIds || [],
            callback: params.callback || null,
        })
    }
    close = () => {
        this.setState({
            visible: false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            comboLists = this.props.comboLists || [],
            callback = this.state.callback;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                comboLists.forEach(item => {
                    if(item.id == values.combo_id){
                        res.id = item.id;
                        res.name = item.name;
                    }
                })
                this.close()
                if (typeof callback == 'function') {
                    callback(res)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }

    render() {
        const data = this.state.formData || {}
        let comboLists = this.props.comboLists || []
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 16
            }
        }
        const {getFieldDecorator} = this.props.form
        let disableIds = this.state.disableIds

        return (
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={400}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Gallery onRef={e => {
                    this.Gallery = e
                }}></Gallery>
                <Form className={`model-form`} {...formItemLayout}>
                    <FormItem label={`选择套餐`}>
                        {
                            getFieldDecorator('combo_id', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择套餐'
                                    }
                                ]
                            })(
                                <Select style={{width: 200}} placeholder={'选择套餐'}>
                                    {
                                        comboLists.map(combo => (
                                            <Select.Option value={combo.id} key={combo.id} disabled={ disableIds.includes(combo.id) }>{combo.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddTimeMeal))
