import React, { Component } from 'react'
import { Button, Table, Pagination, Divider, Input, Icon, message } from 'antd';
import GoodsGroupModal from './goods-group-modal';
import { liveGroup,liveGroupDele,liveGroupSave } from '@/api/livestream'

export default class GoodsGroup extends Component {
    state = {
        columns: [
            {
                title: '分组名称',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.name}</p>
                    )
                }
            },
            {
                title: '关联商品数',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p> { rowData.goods_count  }</p>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sort_edit
                                    ?
                                    <Input
                                        style={{ width: '80px' }}
                                        value={rowData.sort}
                                        onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                        onBlur={(e) => { this.handleField(e, rowData, index) }}
                                        data-name={'sort'}
                                    />
                                    :
                                    <span>{rowData.sort}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`sort`} />
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '4',
                width:'20%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 20,
        total: 0
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () => {
        let sendData={
            page:this.state.page,
            per_page:this.state.per_page
        }

        this.setState({
            tableLoading:true
        })
        liveGroup(sendData).then(res=>{
            if(res.code==1){
                let {current_page,data,per_page }=res.data.group_list;
                this.setState({
                    page:current_page,
                    tableData:data,
                    per_page
                })
            }
        }).finally(()=>{
            this.setState({
                tableLoading:false
            })
        })
    }

    handleAdd = () => {
        this.goodsGroupRef.show(()=>{
            this.fetchData()
        })
    }

    handleEdit = (row) => {
        this.goodsGroupRef.show(()=>{
            this.fetchData()
        },row)
    }
    handleDele = (row) => {
        liveGroupDele({id:row.id}).then(res=>{
            if(res.code ==1){
                message.success(res.msg)
                this.fetchData()
            }
        })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value;
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }

    handleField = (e, rowData, index) => {
        let name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }
        sendData.id = rowData.id;
        sendData[name] = val;

        liveGroupSave(sendData).then(res=>{
            if(res.code==1){
                this.fetchData()
            }
        })
    }

    showEdit = (e,rowData,index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
            lists[index]['sort_edit'] = 1
        this.setState({
            tableData:lists
        })
    }

    handleCurrentChange = (val) => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    render() {
        return (
            <div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增分组</Button>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                <GoodsGroupModal onRef={e=>{this.goodsGroupRef=e}} ></GoodsGroupModal>
            </div>
        )
    }
}
