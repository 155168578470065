import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tabs, message } from 'antd';
import BaseForm from './components/BaseForm'
import ShareForm from './components/ShareForm';
import PrizeForm from './components/PrizeForm';
import Loading from '@/components/Loading';
import { jizanSave, jizanDetail } from "@/api/collect-likes.js"
import moment from 'moment'
import '@/assets/css/collect-likes/index.scss';
const FormItem = Form.Item;
const { TabPane } = Tabs;


class CollectLikesAdd extends Component {
    state = {
        activeStatus: '1',
        isLoading: false,
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-07-05/02291df483af8c9aad0d9bdf6ca657db.png",
        share_poster:"https://img.cxkoo.com/chengxuan/1/2022-07-11/86c1035c92118425be7ef199bb878174.png",
        is_edit:false,
        activityData:{},
        bg_color:"#29CC8A",
        font_color:"#29CC8A",
        isForceRender:false
    }

    componentDidMount(){
       
        if(this.props.match.params.id){
            this.setState({
                is_edit:true
            })
            this.fetchData()
        }else{
            this.setState({
                isForceRender: true
            })
        }
    }

    fetchData=()=>{
        this.setState({
            isLoading: true
        })

        jizanDetail({id:this.props.match.params.id}).then(res=>{
            if(res.code ==1){
               
                const {detail}=res.data

                if(detail.start_time && detail.end_time){
                    detail.time=[moment(detail.start_time,'YYYY-MM-DD HH:mm:ss'),moment(detail.end_time,'YYYY-MM-DD HH:mm:ss')]
                }


                if(detail.exchange_start_time && detail.exchange_end_time){
                    detail.exchange_time=[moment(detail.exchange_start_time,'YYYY-MM-DD HH:mm:ss'),moment(detail.exchange_end_time,'YYYY-MM-DD HH:mm:ss')]
                }

                this.setState({
                    activityData:detail,
                    bg_img:detail.bg_img,
                    bg_color:detail.bg_color
                },()=>{
                    this.setState({
                        isForceRender: true
                    })
                })
            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    clickTab = val => {
        this.setState({
            activeStatus: val
        })
    }

    saveData =async () => {
        const BaseFormData =await this.BaseForm.getFormData()
        console.log(BaseFormData);
        if (!BaseFormData) {
            return this.clickTab('1')
        }
     
        const PrizeFormData =await this.PrizeForm.getFormData()
        if (!PrizeFormData) {
            return this.clickTab('2')
        }

        const ShareFormData =await this.ShareForm.getFormData()
        if (!ShareFormData) {
            return this.clickTab('3')
        }

        const sendData = { ...BaseFormData, ...PrizeFormData, ...ShareFormData }
        sendData.bg_img = this.state.bg_img

        if(this.state.is_edit){
            sendData.id=this.state.activityData.id
        }

        if(!sendData.share_poster){
            sendData.share_poster=this.state.share_poster
        }

        this.setState({
            isLoading: true
        })
        
        jizanSave(sendData).then(res => {
            if (res.code == 1) {
                message.success(res.msg)
                .then(() => {
                    this.props.history.push({
                        pathname: `/collect-likes/lists`
                    })
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

   
    
    updateData=(key,value)=>{
        this.setState({
            [key]: value
        })
    }

    handleChangeTabs=key=>{
        this.setState({
            activeStatus:key
        })
    }

    render() {

        const {bg_color,font_color}=this.state

        return (
            <div className='shadow-radius collect-likes'>
                <div className="clearfix mgt15">
                    <div className="phone-wrap fl">
                        <div className="phone-title">一起来集赞</div>
                        <div className="phone-img">
                            <img src={this.state.bg_img} alt="" />
                            <div className="phone-btn">我要集赞</div>
                        </div>
                        <div className="phone-body" style={{'background':bg_color}}>
                            <div className="title">已有0人参与活动</div>
                            <div className="item-wrap">
                                <div className="item-title">距离活动开始</div>
                                <div className="time-wrap">
                                    <span>99天</span>
                                    <div className="time-num">12</div>
                                    <span>:</span>
                                    <div className="time-num">53</div>
                                    <span>:</span>
                                    <div className="time-num">23</div>
                                </div>
                            </div>

                            <div className="item-wrap">
                                <div className="item-title">集赞排行榜</div>
                                <div className="ranking-title">—— 只显示前100名 ——</div>
                                <div className="primary">
                                    <div className="item item1">
                                        <div className="hat1-icon"></div>
                                        <div className="avatar">
                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/95913481356853376b72b0f2b3c3df17.png" alt="" />
                                        </div>
                                        <div className="user-name">小毛驴</div>
                                        <div className="zan" style={{"color":font_color}}>共340赞</div>
                                    </div>
                                    <div className="item item2">
                                        <div className="hat2-icon"></div>
                                        <div className="avatar">
                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/9fbdc298b693386fda1256dfb5bdfa31.png" alt="" />
                                        </div>
                                        <div className="user-name">一只小包子</div>
                                        <div className="zan" style={{"color":font_color}}>共340赞</div>
                                    </div>
                                    <div className="item item3">
                                        <div className="hat3-icon"></div>
                                        <div className="avatar">
                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/2a68ebd048cd421b32903a1cfafe0d7f.png" alt="" />
                                        </div>
                                        <div className="user-name">花骨朵</div>
                                        <div className="zan" style={{"color":font_color}}>共340赞</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ctrl-wrap fl mgl20">
                        <Tabs className={'mgt15'} activeKey={this.state.activeStatus}  onChange={this.handleChangeTabs}>
                            <TabPane tab="基础设置" key={'1'}>
                                <BaseForm onRef={e => this.BaseForm = e} updateData={this.updateData} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData}></BaseForm>
                            </TabPane>
                            <TabPane tab="奖品设置" key={'2'} forceRender={this.state.isForceRender}>
                                <PrizeForm onRef={e => this.PrizeForm = e} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData}></PrizeForm>
                            </TabPane>
                            <TabPane tab="分享设置" key={'3'}>
                                <ShareForm onRef={e => this.ShareForm = e} saveData={this.saveData} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData}></ShareForm>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(CollectLikesAdd))