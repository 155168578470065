/**
 * 倒计时组件
 * @type 11
 * @return Object
 */

import React from 'react'
import {Form, Icon, Modal, Tooltip} from 'antd'
import {ICON_FONT_URL} from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ11 extends React.Component{
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }
    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`倒计时`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>
                        <div className={`tpl11-cont`}>
                            <div
                                className={'count-down-style1'}
                                style={ moduleData.content.bgStyle == 1 ? {backgroundImage:`url(${moduleData.content.pic})`} : { backgroundColor:`${ moduleData.content.bgColor }` }}
                            >
                                <div className={`title`}>
                                    <div className={`title-txt`}  style={{ color:moduleData.content.titleColor }}>
                                        <div className={`stick before`}  style={{ backgroundColor:moduleData.content.titleColor }}></div>
                                        { moduleData.content.title }
                                        <div className={`stick after`}  style={{ backgroundColor:moduleData.content.titleColor }}></div>
                                    </div>
                                </div>
                                <div className={`sub-title`} style={{ color:moduleData.content.txtColor }}>距{ moduleData.content.subTitle }开始/结束</div>
                                <div className={`time-group`}>
                                    <div className={`time`} style={{ color:moduleData.content.timeColor }}>00</div>
                                    <div className={`unit`} style={{ color:moduleData.content.txtColor }}>天</div>
                                    <div className={`time`} style={{ color:moduleData.content.timeColor }}>00</div>
                                    <div className={`unit`} style={{ color:moduleData.content.txtColor }}>时</div>
                                    <div className={`time`} style={{ color:moduleData.content.timeColor }}>00</div>
                                    <div className={`unit`} style={{ color:moduleData.content.txtColor }}>分</div>
                                    <div className={`time`} style={{ color:moduleData.content.timeColor }}>00</div>
                                    <div className={`unit`} style={{ color:moduleData.content.txtColor }}>秒</div>
                                </div>
                            </div>
                        </div>
                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }

}

export default Form.create({})(tpl_con_typ11)
