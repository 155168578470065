import React from 'react'
import { Icon, Input, Upload } from 'antd'
import Loading from '@/components/Loading'
import Cookie from 'js-cookie'
import OSS from 'ali-oss';
import { uploadVideo } from '@/api/gallery'
import message from '@/utils/message'
import CX from '@/utils/CX'
import "./videoAdd.scss"


class VideoAdd extends React.Component {
    state = {
        isLoading: false,
        progress_num: 0

    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps, nextContext) {

    }


    // uploadChange = e => {
    //     console.log(e);
    //     var files = document.getElementById("fiel-video").files;
    //     files = Array.from(files);
    //     let fd = new FormData()
    //     files.forEach(item => {
    //         fd.append('Filedata[]', item);
    //     })
    //     fd.append('api_token', Cookie.get('token') || ``)
    //     this.setState({
    //         isLoading: true
    //     })
    //     uploadVideo(fd)
    //         .then(res => {
    //             console.log(res);
    //             if (res.code == 1) {
    //                 this.props.handleVideo(res.data.url)
    //                 message.success(res.msg)
    //             }
    //         })
    //         .finally(() => {
    //             this.setState({
    //                 isLoading: false
    //             })
    //         })
    // }

    getNowdate = () => {
        var date = new Date();
        var seperator1 = '-';
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = '0' + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate;
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
        return currentdate;
    };

    multipartUpload = async (file) => {
        let ali_oss = JSON.parse(localStorage.getItem('ali_oss'));
        let filename = CX.genUUID();
        let path = '/aixincun/' + this.getNowdate() + '/' + filename;
        const ossClient = new OSS({
            region: ali_oss.endPoint,
            accessKeyId: ali_oss.accessKeyId,
            accessKeySecret: ali_oss.accessKeySecret,
            bucket: ali_oss.bucket,
        });
        return ossClient
            .multipartUpload(path, file, {
                // 设置并发上传的分片数量。
                parallel: 10,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                timeout: 120000,
                partSize: 1024 * 1024 * 1,
                // 获取分片上传进度、断点和返回值。
                progress: (p, cpt, res) => {
                    console.log('进度', p);
                    let progress_num = parseInt(Number(p) * 100);

                    this.setState({
                        progress_num
                    })
                },
            })
            .then((result) => {
                // 更新上传文件地址
                console.log('上传结果', result);

                this.setState({
                    isLoading: false
                })
                let videoUrl = ali_oss.imgUrl + path;
                if (typeof this.props.handleVideo === 'function') {
                    this.props.handleVideo(videoUrl)
                }
            })
            .catch((err) => {
                console.log(err);
                message.error('上传失败,请稍后重新尝试');
            });
    }

    beforeUpload = (file) => {
        this.multipartUpload(file);
        this.setState({
            isLoading: true
        })
        return false;
    }
    render() {
        return (
            <div style={{ width: '95px', display: 'inline-block' }}>
                <Upload
                    className="avatar-uploader"
                    listType="picture-card"
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={this.beforeUpload}
                    disabled={this.state.isLoading}
                    accept="video/*"
                >
                    <div>
                        <Icon type={this.state.isLoading ? 'loading' : 'plus'} />
                        <div
                            style={{
                                marginTop: 8,
                            }}
                        >
                            视频上传
                        </div>
                    </div>
                </Upload>
                {
                    this.state.isLoading ?
                        <div style={{ width: '120px' }}>
                            上传进度：<span style={{ color: 'rgb(46 202 104)' }}>{this.state.progress_num}%</span>
                        </div> : null
                }

            </div>
        )
    }

    // render() {
    //     return(
    //         <div className={`ant-upload ant-upload-select ant-upload-select-picture-card`}>
    //             <span className={`ant-upload file-video-wrap`} >
    //                 <div>
    //                     <Icon type="plus" />
    //                     <div className={`ant-upload-text`}>选择视频</div>
    //                 </div>
    //                 <Input type="file" id="fiel-video"  className={'file-video'} multiple="multiple" accept="video/*" onChange={this.uploadChange} ></Input>
    //             </span>

    //             {this.state.isLoading ? <Loading /> : null}
    //         </div>
    //     )
    // }
}

export default VideoAdd
