import React from 'react'
import { Modal, Button, Radio, Form, Input } from 'antd'
import message from '@/utils/message'
import { withRouter, Link } from 'react-router-dom'
const FormItem = Form.Item;

class SetGoodsCommission extends React.Component{
    state = {
        formData:'',
        title:'设置佣金',
        visible:false,
        modalLoading:false,
        callback:null,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        let formData = this.props.data || ''
        this.setState({
            visible:true,
            callback : callback || null,
            formData
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(typeof callback == 'function'){
                    callback(values)
                }
                this.close()
            }else {
                message.error('请完成佣金设置')
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    handleCommonChange = () => {}
    handleRadio = () => {}

    render() {
        const config = this.props.config || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        let data = this.state.formData || {}

        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={800}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`}>
                    <table className="cxtables cxtables-sku">
                        {
                            config.mode == 2
                                ?
                                <colgroup>
                                    <col width={`46%`} />
                                    <col width={`54%`} />
                                </colgroup>
                                :
                                <colgroup>
                                    <col width={`46%`} />
                                    <col width={`27%`} />
                                    <col width={`27%`} />
                                </colgroup>

                        }
                        <thead>
                        {
                            config.mode == 2
                                ?
                                <tr>
                                    <td>佣金比例</td>
                                    <td>团长佣金</td>
                                </tr>
                                :
                                <tr>
                                    <td>佣金比例</td>
                                    <td>分销佣金</td>
                                    <td>邀请奖励佣金</td>
                                </tr>
                        }
                        </thead>
                        <tbody>
                        {
                            config.mode == 2
                                ?
                                <tr className={`cbMoney`}>
                                    <td>
                                        <div>
                                            {getFieldDecorator('commission_type', {
                                                initialValue: data.commission_type  || 1,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择佣金比例'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group onChange={ this.handleRadio }>
                                                    <Radio value={1}>默认佣金比例</Radio>
                                                    <Radio value={2}>自定义佣金比例</Radio>
                                                    <Radio value={3}>自定义固定金额</Radio>
                                                </Radio.Group>
                                            )}

                                        </div>
                                    </td>
                                    <td>
                                        {
                                            getFieldValue('commission_type') == 1
                                                ?
                                                <Input
                                                    addonAfter={`%`}
                                                    placeholder={'请设置'}
                                                    style={{ width:'160px',textAlign:'right' }}
                                                    value={ config.goods_commission_rate }
                                                    disabled={true}
                                                />
                                                :
                                                getFieldValue('commission_type') == 2
                                                    ?
                                                    getFieldDecorator('first_commission_ratio',{
                                                        initialValue: data.first_commission_ratio || '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请设置'
                                                            }
                                                        ]
                                                    })(
                                                        <Input
                                                            addonAfter={`%`}
                                                            placeholder={'请设置'}
                                                            style={{ width:'160px',textAlign:'right' }}
                                                        />
                                                    )
                                                    :
                                                    getFieldDecorator('first_commission',{
                                                        initialValue: data.first_commission || '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请设置'
                                                            }
                                                        ]
                                                    })(
                                                        <Input
                                                            addonAfter={`元`}
                                                            placeholder={'请设置'}
                                                            style={{ width:'160px',textAlign:'right' }}
                                                        />
                                                    )
                                        }
                                    </td>
                                </tr>
                                :
                                <tr className={`cbMoney`}>
                                    <td>
                                        <div>
                                            {getFieldDecorator('commission_type', {
                                                initialValue: data.commission_type  || 1,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择佣金比例'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group onChange={ this.handleRadio }>
                                                    <Radio value={1}>默认佣金比例</Radio>
                                                    <Radio value={2}>自定义佣金比例</Radio>
                                                    <Radio value={3}>自定义固定金额</Radio>
                                                </Radio.Group>
                                            )}

                                        </div>
                                    </td>
                                    <td>
                                        {
                                            getFieldValue('commission_type') == 1
                                                ?
                                                <Input
                                                    addonAfter={`%`}
                                                    placeholder={'请设置'}
                                                    style={{ width:'160px',textAlign:'right' }}
                                                    value={ config.goods_commission_rate }
                                                    disabled={true}
                                                />
                                                :
                                                getFieldValue('commission_type') == 2
                                                    ?
                                                    getFieldDecorator('first_commission_ratio',{
                                                        initialValue: data.first_commission_ratio || '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请设置'
                                                            }
                                                        ]
                                                    })(
                                                        <Input
                                                            addonAfter={`%`}
                                                            placeholder={'请设置'}
                                                            style={{ width:'160px',textAlign:'right' }}
                                                        />
                                                    )
                                                    :
                                                    getFieldDecorator('first_commission',{
                                                        initialValue: data.first_commission || '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请设置'
                                                            }
                                                        ]
                                                    })(
                                                        <Input
                                                            addonAfter={`元`}
                                                            placeholder={'请设置'}
                                                            style={{ width:'160px',textAlign:'right' }}
                                                        />
                                                    )
                                        }
                                    </td>
                                    <td>
                                        {
                                            getFieldValue('commission_type') == 1
                                                ?
                                                <Input
                                                    addonAfter={`%`}
                                                    placeholder={'请设置'}
                                                    style={{ width:'160px',textAlign:'right' }}
                                                    value={ config.reward_commission_rate }
                                                    disabled={true}
                                                />
                                                :
                                                getFieldValue('commission_type') == 2
                                                    ?
                                                    getFieldDecorator('second_commission_ratio',{
                                                        initialValue: data.second_commission_ratio || '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请设置'
                                                            }
                                                        ]
                                                    })(
                                                        <Input
                                                            addonAfter={`%`}
                                                            placeholder={'请设置'}
                                                            style={{ width:'160px',textAlign:'right' }}
                                                        />
                                                    )
                                                    :
                                                    getFieldDecorator('second_commission',{
                                                        initialValue: data.second_commission || '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请设置'
                                                            }
                                                        ]
                                                    })(
                                                        <Input
                                                            addonAfter={`元`}
                                                            placeholder={'请设置'}
                                                            style={{ width:'160px',textAlign:'right' }}
                                                        />
                                                    )
                                        }
                                    </td>
                                </tr>

                        }
                        </tbody>
                    </table>
                </Form>
            </Modal>

        )
    }

}

export default Form.create({})(withRouter(SetGoodsCommission))
