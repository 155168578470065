import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Form, Input, Badge, Button, Table, Alert, Select, Col, Row, Icon, Modal } from 'antd'
import PropTypes from 'prop-types'
import  KtvPriceManage from './ktv-price-manage'
import AddTime from './add-time'
import AddSpecialDate from './add-special-date'
import CopyTime from './copy-time'
import { stockDetail, addBookTime, addWeek, delWeek, delTime, saveStock, editBookTime } from '@/api/book'
import message from '@/utils/message'
import '@/assets/css/ktv/index.scss'
const FormItem = Form.Item

class StockPriceManage extends React.Component{
    static contextTypes = {
        setLoading: PropTypes.func
    }
    state = {
        week:[],
        time_and_stock:[],
        currentStore:'',
        currentWeek:'',
        currentTimeStock:'',
        showEdit:false,
        currentRow:'', //编辑时段名称
        showRoomTip:false
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        if(!currentStore){
            this.setState({
                currentStore:this.props.storeLists[0]
            })
        }
        sendData.store_id = currentStore ? currentStore.id : this.props.storeLists[0].id
        this.context.setLoading(true)
        stockDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let week = [],
                        time_and_stock = [],
                        currentWeek = this.state.currentWeek,
                        currentTimeStock = this.state.currentTimeStock,
                        formData = res.data;
                    week = formData.week_data;
                    time_and_stock = formData.time_and_stock_data;
                    if(currentWeek){
                        week.forEach(item => {
                            item.is_choose = 0;
                            if(item.id == currentWeek.id){
                                item.is_choose = 1;
                                currentWeek = item
                            }
                        })
                    }else {
                        week.forEach(item => {
                            item.is_choose = 0;
                        })
                        week[0]['is_choose'] = 1;
                        currentWeek = week[0]
                    }

                    for(var i in time_and_stock){
                        if(time_and_stock[i]['id'] == currentWeek['id']){
                            currentTimeStock = time_and_stock[i]
                        }
                    }
                    if(time_and_stock && time_and_stock.length){
                        this.setState({
                            showRoomTip: time_and_stock[0]['room_data'] && time_and_stock[0]['room_data'].length ? false : true
                        })
                    }
                    this.setState({
                        week,time_and_stock,currentWeek, currentTimeStock
                    })
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })


    }
    handleStoreChange = (val) => {
        let storeLists = this.props.storeLists,
            current = '';
        for(var i in storeLists){
            if(storeLists[i]['id'] == val){
                current = storeLists[i]
            }
        }
        this.setState({
            currentStore:current,
            currentWeek:''
        },() => {
            this.fetchData()
            this.KtvPriceManage.fetchData();

        })
    }
    handleAddSpecialDate = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        sendData.store_id = currentStore ? currentStore.id : this.props.storeLists[0].id;
        this.AddSpecialDate.show(res => {
            if(res){
                sendData.special_date = res.special_date;
                this.context.setLoading(true)
                addWeek(sendData)
                    .then(res => {
                        if(res.code == 1){
                            let special_date = res.data.special_date,
                                week = this.state.week,
                                time_and_stock = this.state.time_and_stock,
                                time_stock_tpl = JSON.stringify(time_and_stock[time_and_stock.length - 1]),
                                tmp_time = '',
                                datas = { id: special_date.id, time_week: special_date.time_week, special_date: special_date.special_date, time_name: special_date.special_date };
                            tmp_time = JSON.parse(time_stock_tpl);
                            for(var i in tmp_time){
                                tmp_time[i] = datas[i] ? datas[i] : tmp_time[i]
                            }
                            week.push(datas)
                            time_and_stock.push(tmp_time)
                            this.setState({
                                week,time_and_stock
                            })
                            this.KtvPriceManage.fetchData();

                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })

            }
        })


    }
    handleDelSpecialDate = (e,item,index) => {
        e.stopPropagation();
        let week = this.state.week,
            time_and_stock = this.state.time_and_stock,
            sendData= {};
        sendData.store_id = this.state.currentStore ? this.state.currentStore.id : this.props.storeLists[0].id
        sendData.id = item.id || ''
        Modal.confirm({
            title:`确定删除这个日期吗？未保存的数据可能会丢失`,
            okType:`danger`,
            onOk:() => {
                this.context.setLoading(true)
                delWeek(sendData)
                    .then(res => {
                        if(res.code == 1){
                            for(var i in time_and_stock){
                                if(time_and_stock[i]['id'] == item.id){
                                    time_and_stock.splice(i,1)
                                }
                            }
                            week.splice(index,1);
                            this.setState({
                                week,time_and_stock
                            })
                            this.KtvPriceManage.fetchData();
                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })


            }
        })
    }
    handleDelTime = (rowData,index) => {
        let time_and_stock = this.state.time_and_stock,
            currentWeek = this.state.currentWeek,
            currentTimeStock = ''
        let sendData = {}
        sendData.store_id = this.state.currentStore ? this.state.currentStore.id : this.props.storeLists[0].id
        sendData.id = rowData.id;
        Modal.confirm({
            title:`确定删除这个时间段吗？未保存的数据可能会丢失`,
            okType:`danger`,
            onOk:() => {
                this.context.setLoading(true)
                delTime(sendData)
                    .then(res => {
                        if(res.code == 1){
                            for(var i in time_and_stock){
                                if(time_and_stock[i]['id'] == currentWeek['id']){
                                    currentTimeStock = time_and_stock[i]
                                }
                                for(var j in time_and_stock[i]['room_data']){
                                    let stock_time_data = time_and_stock[i]['room_data'][j]['time_data']
                                    for(var k in stock_time_data){
                                        if(stock_time_data[k]['id'] == rowData.id){
                                            stock_time_data.splice(k,1)
                                        }
                                    }
                                }
                            }
                            this.setState({
                                currentTimeStock, time_and_stock
                            })
                            this.KtvPriceManage.fetchData();
                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })



            }
        })

    }
    showEdit = () => {
        this.setState({
            showEdit:true
        })
    }
    handleCancel = () => {
        this.setState({
            showEdit:false
        })
    }
    handleEditName = (e,rowData,index) => {
        let sendData = {}
        this.setState({
            currentRow:rowData
        },() => {
            this.AddTime.show(res => {
                sendData = res;
                sendData.store_id = this.state.currentStore.id || ''
                sendData.id = rowData.id || ''
                this.context.setLoading(true)
                editBookTime(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                    this.KtvPriceManage.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })

            })
        })

    }
    renderWeek = () => {
        let week = this.state.week || [];
        return(
            <div className={`ktv-btn-group mgb15`}>
                {
                    week.map((item,index) => (
                        <div className={`ktv-btn`} key={index}>
                            <Button
                                type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                onClick={ () => { this.handleWeek(item,index) } }
                            >
                                <div>
                                    { item.time_name }
                                    {
                                         this.state.showEdit && item.special_date && !item.is_choose
                                            ?
                                            <Icon
                                                type="close"
                                                style={{ position:'relative',zIndex:2 }}
                                                className={'mgl5'}
                                                onClick={ (e) => { this.handleDelSpecialDate(e,item,index) } }
                                            />
                                            :
                                            null
                                    }
                                </div>
                            </Button>
                        </div>
                    ))
                }
                <div className={`ktv-btn`}>
                    {
                        this.state.showEdit
                            ?
                            <Button type={'default'} onClick={this.handleAddSpecialDate} icon={'plus'}>添加特殊日期</Button>
                            :
                            null
                    }
                </div>
            </div>
        )

    }
    handleWeek = (rowData,index) => {
        let week = this.state.week || [],
            time_and_stock = this.state.time_and_stock || [],
            currentTimeStock = '',
            currentWeek = '';
        for(var i in week){
            week[i]['is_choose'] = 0
            if(week[i]['id'] == rowData.id){
                week[i]['is_choose'] = 1
                currentWeek = week[i]
            }
        }
        for(var j in time_and_stock){
            if(time_and_stock[j]['id'] == currentWeek['id']){
                currentTimeStock = time_and_stock[j]
            }
        }
        this.setState({
            week,
            currentWeek,
            currentTimeStock
        })
    }
    handleAddTime = () => {
        let sendData = {}
        this.setState({
            currentRow:''
        },() => {
            this.AddTime.show(res => {
                sendData = res;
                sendData.store_id = this.state.currentStore.id || ''
                this.context.setLoading(true)
                addBookTime(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                    this.KtvPriceManage.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })

            })
        })

    }
    handleTableData = () => {
        let currentTimeStock = this.state.currentTimeStock || ''
        if(currentTimeStock){
            let room_data = currentTimeStock.room_data,
                columns = [],
                tableData = [];
            room_data.forEach(( room,roomIndex ) => {
                columns.push(
                    {
                        title:room.name,
                        dataIndex:room.id,
                        width:'120px',
                        render:(text, rowData, index) => {
                            return (
                                <div className={`lh25`}>
                                    {
                                        this.state.showEdit
                                            ?
                                            <Input
                                                type={`number`}
                                                min={1}
                                                style={{ width:'80px' }}
                                                value={rowData[`stock_${room.id}`]}
                                                className={`mgr5`}
                                                onChange={ (e) => { this.handleStockChange(e,rowData,index,`stock_${room.id}`) } }
                                            />
                                            :
                                            <span className={`mgr5`}>{rowData[`stock_${room.id}`]}</span>
                                    }

                                    间
                                </div>
                            )
                        }
                    }
                )
                room.time_data && room.time_data.forEach((child,childKey) => {
                    child[`stock_${room.id}`] = child[`stock_${room.id}`] ? child[`stock_${room.id}`] : child.stock
                    child['room_id'] = room.id
                })
            })
            let thFirst = {
                    title: '时段',
                    width: 220,
                    dataIndex: 'timeRange',
                    fixed: 'left',
                    render:(text, rowData, index) => {
                        return (
                                <div className={`lh25`}>
                                    <span>{ rowData.name } {`${rowData.start_time}-${rowData.end_time}`}</span>
                                    <Icon type="edit" className={`mgl5 cursor`} onClick={ (e) => { this.handleEditName(e,rowData,index) } }  />
                                </div>
                                )
                            }
                        },
                thLast = { title:'操作', width:150, dataIndex:'action',fixed:'right',render:(text, rowData, index) => {return (<div className={`lh25`}><Button type={`link`} onClick={ () => { this.handleDelTime(rowData,index) } }>删除</Button></div>)} };
            if(this.state.showEdit){
                columns = [ thFirst,...columns,thLast ]
            }
            else {
                columns = [ thFirst,...columns ]
            }
            if(room_data && room_data.length){
                tableData = room_data[0]['time_data'];
                for(var i in tableData){
                    let time_id = tableData[i]['id'];
                    for(var j in room_data){
                        let tmp = room_data[j]['time_data']
                        for(var k in tmp){
                            if(tmp[k]['id'] == time_id){
                                let datas = tmp[k];
                                for(var x in datas){
                                    if(x.includes('stock')){
                                        tableData[i][x] = datas[x]
                                    }
                                }

                            }
                        }
                    }
                }
            }
            return(
                <div className={'content-body mgt15'}>
                    <Table
                        style={{ width:'100%' }}
                        size={`middle`}
                        columns={ columns }
                        dataSource={tableData}
                        rowKey={(record,index) => index }
                        scroll={{ x: 1600 }}
                        pagination={false}
                    ></Table>
                </div>
            )

        }else {
            return null
        }

    }
    handleStockChange = (e,rowData,index,keyName) => {
        let val = e.target.value,
            currentTimeStock = this.state.currentTimeStock,
            room_id = keyName.split('_')[1],
            room_data = currentTimeStock.room_data;
        for(var i in room_data){
            if(room_data[i]['id'] == room_id){
                let time_data = room_data[i]['time_data']
                time_data[index]['stock'] = val
                time_data[index][keyName] = val
            }
        }
        currentTimeStock.room_data = room_data
        this.setState({
            currentTimeStock: currentTimeStock
        })

    }
    handleSaveStock = () => {
        let sendData = {},
            time_and_stock = this.state.time_and_stock,
            stock_data = []
        sendData.store_id = this.state.currentStore.id || ''
        time_and_stock.forEach((week,weekIndex) => {
            let parent = {}
            parent.id = week.id;
            parent.time_week = week.time_week;
            parent.stock_data = []
            week.room_data && week.room_data.forEach((room,roomIndex) => {
                room.time_data && room.time_data.forEach((time,timeIndex) => {
                    let datas = {}
                    datas['key'] = `${week.id}_${room.id}_${time.id}`
                    if(time[`stock_${time.room_id}`] === undefined){
                        datas['stock'] = 1
                    }else {
                        datas['stock'] = time[`stock_${time.room_id}`]
                    }
                    parent.stock_data.push(datas)
                })
            })
            stock_data.push(parent)
        })
        sendData.stock_data = stock_data;
        this.context.setLoading(true)
        saveStock(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('保存成功')
                        .then(() => {
                            this.setState({
                                showEdit:false
                            })
                            this.fetchData()
                        })

                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })
    }
    handleCopyTime = () => {
        let time_and_stock = this.state.time_and_stock,
            currentTimeStock = this.state.currentTimeStock;
        this.CopyTime.show(res => {
            if(res){
                let weeks = res.weeks;
                let room_data_copy = JSON.stringify(currentTimeStock.room_data);
                time_and_stock.forEach((week,weekIndex) => {
                    if(weeks.includes(week.id)){
                        week.room_data = '';
                        week.room_data = JSON.parse(room_data_copy)
                    }
                })
                message.success('复制成功')
                this.setState({
                    time_and_stock
                })
            }
        })
    }


    render() {
        let storeLists = this.props.storeLists || []
        const { getFieldDecorator } = this.props.form
        return(
            <div className={``}>
                <div>
                    <AddSpecialDate onRef={ e => { this.AddSpecialDate = e } }></AddSpecialDate>
                    <AddTime onRef={ e => { this.AddTime = e } } current={ this.state.currentRow }></AddTime>
                    <CopyTime onRef={ e => { this.CopyTime = e } } weeks = { this.state.week } current={ this.state.currentWeek }></CopyTime>
                    {
                        this.state.showRoomTip
                            ?
                            <div className={`content-msg mgb10`}>
                                <p>
                                    尚未创建包厢类型，请先<Link to={`/desk/add/${this.state.currentStore.id}`} className={`mgl5`} >创建包厢类型</Link>
                                </p>
                            </div>
                            :
                            null
                    }

                    <div className={'content-head mgb15'}>
                        <Form className={'search-form clearfix'}>
                            <FormItem label={'选择门店'} className={'fl mgl10'}>
                                {
                                    getFieldDecorator('store_id',{
                                        initialValue:this.state.currentStore.id
                                    })(
                                        <Select style={{width:170}} placeholder={'请选择'} onChange={ this.handleStoreChange }>
                                            {
                                                storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </Form>
                    </div>
                    <div className={`fi-title mgb15`}>时段和库存</div>
                    <div className={`mgb15`}>
                        <Button type='default' onClick={() => this.showEdit()}>编辑时段库存</Button>
                    </div>
                    <Alert message={`选择门店进行单独设置，设置后保存；各时段包房可设置库存上限为房间数，请根据实际情况填写。`} className={`mgb15`} type="warning" banner />
                    { this.renderWeek() }
                    <div className={`mgb15`}>
                        {
                            this.state.showEdit
                                ?
                                <Button type={`primary`} onClick={ this.handleCopyTime }>复制到其他日期</Button>
                                :
                                null
                        }
                    </div>
                    {
                        this.handleTableData()
                    }
                    {
                        this.state.showEdit
                            ?
                            <div className={`mgt15`}>
                                <Button type={'default'} onClick={this.handleAddTime} icon={'plus'}>添加时段</Button>
                            </div>
                            :
                            null
                    }
                    {
                        this.state.showEdit
                            ?
                            <div className={`mgt15`}>
                                <Button type={'primary'} onClick={this.handleSaveStock} >保存时段库存</Button>
                                <Button type={'default'} onClick={this.handleCancel} className={`mgl10`}>取消</Button>
                            </div>
                            :
                            null
                    }

                </div>
                {
                    this.state.currentStore && this.state.week && this.state.week.length
                        ?
                        <KtvPriceManage onRef={ e => { this.KtvPriceManage = e } } currentStore={ this.state.currentStore } className={`mgt15`}></KtvPriceManage>
                        :
                        null
                }
            </div>
        )
    }
}
export default Form.create({})(withRouter(StockPriceManage))
