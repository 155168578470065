import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Table, Pagination } from 'antd'
import { pickUpLists } from '@/api/order'
import { userPointsRecord } from '@/api/user'
import Loading from '@/components/Loading'
import '@/assets/css/user/points-record.scss'
const FormItem = Form.Item


class UserPointsRecord extends React.Component{
    state = {
        formData:'',
        columns:[
            {
                title:'变更时间',
                dataIndex:'created_at',
                width:'20%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.created_at || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title:'积分类型',
                dataIndex:'type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.change_points < 0 ? '消耗' : '获得' }</p>
                        </div>
                    )
                }
            },
            {
                title:'获取途径',
                dataIndex:'type_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.type_name || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'内容',
                dataIndex:'show_name',
                width:'25%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.show_name || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'明细',
                dataIndex:'change_points',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.change_points || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'备注',
                dataIndex:'remark',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.remark || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10

    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.user_id = this.props.match.params.user_id || '';
        this.setState({
            tableLoading:true
        })
        userPointsRecord(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page,
                        points_data = res.data.points_data || '';
                    this.setState({
                        tableData:lists,
                        formData:points_data,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }

    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        let data = this.state.formData || {};
        return(
            <div className={`shadow-radius`}>
                <div className={`msg-title mgb30`}>积分明细</div>
                <div className={`points-data-stat`}>
                    <div className={`points-data`}>
                        <div className={`title`}>总积分</div>
                        <div className={`num`}>{ data.total_points || 0 }</div>
                    </div>
                    <div className={`points-data`}>
                        <div className={`title`}>可使用积分</div>
                        <div className={`num`}>{ data.points || 0 }</div>
                    </div>
                    <div className={`points-data`}>
                        <div className={`title`}>已消耗积分</div>
                        <div className={`num`}>{ data.used_points || 0 }</div>
                    </div>
                    <div className={`points-data`}>
                        <div className={`title`}>已过期积分</div>
                        <div className={`num`}>{ data.expired_points || 0 }</div>
                    </div>
                </div>
                <div className={'content-body mgt30'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}
export default Form.create({})(withRouter(UserPointsRecord))
