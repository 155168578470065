/**
 * 优惠券
 * @type 20
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip } from 'antd'
import PropTypes from "prop-types";
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
const FormItem = Form.Item
const ctrlItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

class tpl_ctrl_type20 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func
    }

    state = {
        moduleData: {},
        itemStyleGroup: [
            {
                name: '一行三列',
                style: 1,
                imgs: ['https://img.cxkoo.com/chengxuan/1/2021-11-22/31862b3f6c920a83b155fda50ce6a273.png', 'https://img.cxkoo.com/chengxuan/1/2021-11-22/e96b248aa2080c7de856ba18c364b309.png']
            },
            {
                name: '横向滑动',
                style: 2,
                imgs: ['https://img.cxkoo.com/chengxuan/1/2021-11-22/97ce104bf88be476eed6dcca94434b7e.png', 'https://img.cxkoo.com/chengxuan/1/2021-11-22/3c8595b015d56382929a650c7c771265.png']
            },
            {
                name: '详细列表',
                style: 3,
                imgs: ['https://img.cxkoo.com/chengxuan/1/2021-11-22/f7dcf64fdd72cbb723424b305555ad8e.png', 'https://img.cxkoo.com/chengxuan/1/2021-11-22/0b322df6db5236e45ca4b83d6e0e9b05.png']
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }

    handleAdd=()=>{
        let moduleData = this.state.moduleData;
        this.CouponPicker.show(res => {
            if(res.length > 6){
                res.length = 6;
            }
            moduleData.content.dataset = res;
            this.context.updateDesign(moduleData)
        })
    }

    handleDele=(item , index)=>{
        let moduleData = this.state.moduleData;
         index = index || 0
         moduleData.content.dataset.splice(index, 1);
         this.context.updateDesign(moduleData)
    }
    
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)
    }

    handleColorChange = (color, name) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }

    handleResetColor = (name, color) => {
        let moduleData = this.state.moduleData
        moduleData.content[name] = color
        this.context.updateDesign(moduleData)
    }



    changeInput = (e, item, index) => {
        let moduleData = this.state.moduleData;
        moduleData.content.title = e.target.value;
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return (
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>优惠券</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`选择优惠券样式`}>
                                    <ul className={`template-box`}>
                                        {
                                            this.state.itemStyleGroup.map((item, index) => (
                                                <Tooltip effect="dark" placement="bottom" title={item.name} key={index}>
                                                    <li className={`temp ${moduleData.content.style == item.style ? 'selected' : ''}`} onClick={() => { this.handleItemStyle(item.style) }}>
                                                        {
                                                            moduleData.content.style == item.style
                                                                ?
                                                                <img src={item.imgs[0]} className={`img`} alt="" />
                                                                :
                                                                <img src={item.imgs[1]} className={`img`} alt="" />

                                                        }
                                                    </li>
                                                </Tooltip>
                                            ))
                                        }
                                    </ul>
                                </FormItem>

                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width: 60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                        />
                                    </div>
                                </FormItem>

                                <FormItem label={`券面颜色`} {...ctrlItemLayout}>
                                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={moduleData.content.couponColor} callback={(color) => { this.handleColorChange(color, 'couponColor') }} ></ColorPicker>
                                    <Button type="link" onClick={() => this.handleResetColor('couponColor', '#000000')}>重置</Button>
                                </FormItem>

                                <FormItem label={`券面字体颜色`} {...ctrlItemLayout}>
                                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={moduleData.content.couponTextColor} callback={(color) => { this.handleColorChange(color, 'couponTextColor') }} ></ColorPicker>
                                    <Button type="link" onClick={() => this.handleResetColor('couponTextColor', '#ffffff')}>重置</Button>
                                </FormItem>

                                <FormItem label={`按钮颜色`} {...ctrlItemLayout} >
                                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnColor} callback={(color) => { this.handleColorChange(color, 'btnColor') }} ></ColorPicker>
                                    <Button type="link" onClick={() => this.handleResetColor('btnColor', '#ffffff')}>重置</Button>
                                </FormItem>

                                <FormItem label={`按钮字体颜色`} {...ctrlItemLayout} >
                                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnTextColor} callback={(color) => { this.handleColorChange(color, 'btnTextColor') }} ></ColorPicker>
                                    <Button type="link" onClick={() => this.handleResetColor('btnTextColor', '#000000')}>重置</Button>
                                </FormItem>

                                <FormItem label={`活动文案`} {...ctrlItemLayout}>
                                    <Input type={`text`} value={moduleData.content.title} onChange={(e) => { this.changeInput(e) }} placeholder={`请输入`} maxLength={10} suffix={<span>{moduleData.content.title ? moduleData.content.title.length : 0}/10</span>} />
                                </FormItem>
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>添加优惠券
                                <div className="fi-help-text">最少3张，最多可选取6张优惠券</div>
                            </div>
                            <div className={`cx-ctrl-panel--box`}>
                                <div className={`cx-ctrl-panel--box`}>
                                    {
                                        moduleData.content.dataset.map((item, index) => (
                                            <div className={`cx-img-list`} key={index}>
                                                <div className={`cx-img-item clearfix`}>
                                                    <span className='fz14 ft-bold'>{item.name}</span>
                                                </div>

                                                <Icon type="close-circle" theme="filled" className={`delete-icon`} onClick={() => { this.handleDele(item, index) }}   />
                                            </div>
                                        ))
                                    }
                                    <Button type={`primary`} className={`w100`} onClick={this.handleAdd} disabled={moduleData.content.dataset.length > 5} >添加优惠券</Button>

                                </div>
                            </div>
                        </div>
                    </Form>

                    <CouponPicker
                        onRef={e => { this.CouponPicker = e }}
                        multiple={true}
                        selectIds={moduleData.content.dataset && moduleData.content.dataset.map(item => { return Number(item.id) })}
                    ></CouponPicker>

                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type20)
