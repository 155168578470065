import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Tree,Checkbox } from 'antd'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import {getAllMenu,roleSave ,roleDetail} from "@/api/staff"
import CX from '@/utils/CX'

const FormItem = Form.Item
const { TreeNode } = Tree

class AddRole extends Component {
    state = {
        menus:[],
        checkedKeys:[],
        autoExpandParent:true,
        expandedKeys:[],
        halfCheckedKeys:[],
        formData:{},
        isLoading: false
    }

    componentDidMount(){
        this.fetchMenu().then(()=>{
            this.fetchData()
        })
    }

    fetchMenu = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        return new Promise((resolve, reject) => {
            getAllMenu(sendData)
                .then(res => {
                    if(res.code == 1){
                        let menus = res.data.list
                        this.setState({
                            menus
                        },() => {
                            resolve()
                        })
                    }else {
                        reject()
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })
    }

    fetchData = (params = {}) => {
        let sendData = {}
        if(this.props.match.params.id){
            this.setState({
                isLoading:true
            })
            sendData.id = this.props.match.params.id
            roleDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.detail,
                            menus = this.state.menus,
                            halfCheckedKeys = detail.half_checked_keys || [],
                            rules = detail.rules || [],
                            checkedKeys = [];
                        checkedKeys = rules.filter(value => halfCheckedKeys.indexOf(value) == -1);
                        
                        menus=menus.map(item=>{
                            if(item.subMenu){
                                item.subMenu=item.subMenu.filter(sub=>!sub.hide)
                                
                                item.subMenu = item.subMenu.map(sub=>{
                                    if(sub.children){
                                        sub.children=sub.children.filter(child=>!child.hide)
                                    }
                                    return sub
                                })
                                
                            }
                            return item
                        })
                       
                        menus.forEach((item,index) => {
                            item.subMenu && item.subMenu.forEach((sub,subIndex) => {
                                if(checkedKeys.indexOf(sub.menu_id) == -1 && !halfCheckedKeys.includes(item.menu_id)){
                                    halfCheckedKeys.push(item.menu_id)
                                }
                                sub.children && sub.children.forEach((child,childKey) => {
                                    if(checkedKeys.indexOf(child.menu_id) == -1 && !halfCheckedKeys.includes(sub.menu_id)){
                                        halfCheckedKeys.push(sub.menu_id)
                                        if(!halfCheckedKeys.includes(item.menu_id)){
                                            halfCheckedKeys.push(item.menu_id)
                                        }
                                    }
                                })
                            })
                        })
                        checkedKeys = rules.filter(value => halfCheckedKeys.indexOf(value) == -1);
                        this.setState({
                            formData:detail,
                            checkedKeys,
                            halfCheckedKeys
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        }
    }

    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys
        })
    }

    onCheck = (checkedKeys,e) => {
        console.log(checkedKeys);
        this.setState({
            checkedKeys,
            halfCheckedKeys:e.halfCheckedKeys
        })
    }

    handleAll = (e) => {
        let checkedKeys = [],
            allMenu = this.state.menus,
            val = e.target.checked;
        if(val){
           //全选
           allMenu.map((item) => {
               checkedKeys.push(item.menu_id);
               item.subMenu && item.subMenu.map((sub) => {
                   checkedKeys.push(sub.menu_id);
                   sub.children && sub.children.map((child) => {
                       checkedKeys.push(child.menu_id)
                   })
               })
           })
        }else {
            //反选
            checkedKeys = []
        }
        this.setState({
            isAll:val,
            halfCheckedKeys:[],
            checkedKeys
        })
    }

    saveData = () => {
        let sendData = {},
            checkedKeys = this.state.checkedKeys,
            halfCheckedKeys = this.state.halfCheckedKeys;
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
               
                sendData.half_checked_keys = halfCheckedKeys;
                sendData.rules = CX.diff(halfCheckedKeys,checkedKeys)

                if(this.props.match.params.id){
                    sendData.id=this.props.match.params.id
                }

                this.setState({
                    isLoading:true
                })
                roleSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('编辑成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/staff/role`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:true
                        })
                    })
            }
        })
    }

    goBack=()=>{
        this.props.history.goBack()
    }

    render() {

        let menus = this.state.menus || [];
        let data = this.state.formData|| {}

        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
       
        return (
            <div className={`shadow-radius`}>
                <div className="mgt10">
                    <Form  {...formItemLayout} className={'model-form ec-input-w300'}>
                        <div className={`msg-title mgb15`}>角色信息</div>
                        <FormItem label={'角色名称'}>
                            {getFieldDecorator('name', {
                                initialValue: data.name || "",
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入名称'
                                    }
                                ]
                            })(<Input
                                placeholder={'请输入名称，限30字符'}
                                maxLength={30}
                                disabled={Boolean(data.is_default)}
                                suffix={<span>{getFieldValue("name").length}/30</span>}
                            />)}
                        </FormItem>
                        <FormItem label={'角色描述'}>
                            {getFieldDecorator('desc', {
                                initialValue: data.desc || "",

                            })(<Input.TextArea placeholder={'请输入角色描述'}    disabled={Boolean(data.is_default)} />)}
                        </FormItem>

                        <div className={`msg-title mgb15`}>权限设置</div>
                        {
                            menus && menus.length ? (
                                <FormItem label={'菜单'}>
                                    <div>
                                        <Tree
                                            checkable
                                            onExpand={this.onExpand}
                                            expandedKeys={this.state.expandedKeys}
                                            autoExpandParent={this.state.autoExpandParent}
                                            onCheck={this.onCheck}
                                            checkedKeys={this.state.checkedKeys}
                                            checkStrictly={false}
                                        >
                                            {
                                                menus.map((item, index) => (
                                                    <TreeNode title={item.title} key={item.menu_id}>
                                                        {
                                                            item.subMenu && item.subMenu.map((sub, subIndex) => (

                                                                <TreeNode title={sub.title} key={sub.menu_id}>
                                                                    {
                                                                        sub.children && sub.children.map((child, childKey) => {
                                                                            return (
                                                                                <TreeNode title={child.title} key={child.menu_id}></TreeNode>
                                                                            )
                                                                        })
                                                                    }
                                                                </TreeNode>
                                                            ))
                                                        }
                                                    </TreeNode>
                                                ))
                                            }
                                        </Tree>
                                    </div>
                                    <div>
                                        <Checkbox checked={this.state.isAll} onChange={this.handleAll}>全选</Checkbox>
                                    </div>
                                </FormItem>
                            ) : null
                        }
                        <FormItem label={` `} className="no-colon">
                            <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                            <Button className='mgl15'onClick={this.goBack} >取消</Button>
                        </FormItem>
                    </Form>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(AddRole))
