import React from "react";
import { Table, Pagination, Form } from 'antd'
import { GetFxUserData } from '@/api/fx'
const FormItem = Form.Item

class FxWithdrawRecord extends React.Component {
    state = {
        columns:[
            {
                title:'申请时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.created_at }</p>
                        </div>
                    )
                }
            },
            {
                title:'申请提现金额',
                dataIndex:'withdraw_money',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.withdraw_money }</p>
                        </div>
                    )
                }
            },
            {
                title:'提现到账方式',
                dataIndex:'pay_type_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.pay_type_name }</p>
                        </div>
                    )
                }
            },
            {
                title:'提现账号',
                dataIndex:'pay_account',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.pay_type == 1
                                    ?
                                    '-'
                                    :
                                    rowData.pay_type == 2
                                        ?
                                        '-'
                                        :
                                        rowData.pay_type == 3
                                            ?
                                            <div>
                                                <p className={`lh25`}>姓名：{ rowData.real_name }</p>
                                                <p className={`lh25`}>账号：{ rowData.pay_account }</p>
                                            </div>
                                            :
                                            rowData.pay_type == 4
                                                ?
                                                <div>
                                                    <p className={`lh25`}>姓名：{ rowData.real_name }</p>
                                                    <p className={`lh25`}>支行信息：{ rowData.bank_name }</p>
                                                    <p className={`lh25`}>提现卡号：{ rowData.pay_account }</p>
                                                </div>
                                                :
                                                '-'
                            }
                        </div>

                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.status_name }</p>
                        </div>
                    )
                }
            },
            {
                title:'交易流水号',
                dataIndex:'pay_code',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{ rowData.pay_code || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10

    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {};
        sendData.user_id = this.props.user_id || '';
        sendData.type = 5;
        sendData.page = this.state.page
        this.setState({
            tableLoading:true
        })
        GetFxUserData(sendData)
            .then(res => {
                if(res.code == 1){
                    let tableData = res.data.list.data,
                        per_page = res.data.list.per_page,
                        total = res.data.list.total;
                    console.log('tableData',tableData)
                    this.setState({
                        tableData,per_page,total
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        return (
            <div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination
                                    showQuickJumper
                                    current={this.state.page}
                                    total={this.state.total}
                                    pageSize={this.state.per_page}
                                    onChange={this.handleCurrentChange}
                                />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(FxWithdrawRecord)
