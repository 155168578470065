import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Modal } from 'antd'
import message from '@/utils/message'
import { getSubmitConfig, setSubmitConfig } from '@/api/config'
import Loading from '@/components/Loading'

const FormItem = Form.Item
const { TextArea } = Input

class ReleaseConfig extends React.Component{
    state = {
        formData:{
            tpl_id:'',
            version_id:'',
            pages:[],
            subpackage:[]
        },
        isLoading:false
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        sendData.password = process.env.REACT_APP_API_KEY || ''
        getSubmitConfig(sendData)
            .then(res => {
                if(res.code == 1){
                    let config = res.data.config || '';
                    if(config){
                        this.setState({
                            formData:config
                        })
                    }

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData;
        sendData.password = process.env.REACT_APP_API_KEY || ''
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                values.subpackage = values.subpackage.replace(/\n/g,'');
                values.subpackage = values.subpackage.replace(/\r/g,'');
                values.subpackage = values.subpackage.replace(/\s/g,'');
                values.subpackage = JSON.parse(values.subpackage)
                sendData.config = {};
                sendData.config.tpl_id = values.tpl_id;
                sendData.config.version_id = values.version_id;
                sendData.config.pages = values.pages;
                sendData.subpackage = values.subpackage;
                this.setState({
                    isLoading:true
                })
                setSubmitConfig(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData() ;
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }else {
                if(err.pages){
                    message.error('路由信息未完善')
                }
            }
        })




    }
    handleAdd = () => {
        let formData = this.state.formData,
            row = { name:'', path:'' };
        formData.pages.push(row);
        this.setState({
            formData
        })
    }
    handleDelete = (rowData,index) => {
        let formData = this.state.formData;
        Modal.confirm({
            title:`确定删除这条路由吗？`,
            okType:`danger`,
            onOk:() => {
                formData.pages.splice(index,1)
                this.setState({
                    formData
                })
            }

        })
    }
    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 12
            }
        }
        return(
            <div className={`shadow-radius`}>
                <Form className={'model-form'} { ...formItemLayout }>
                    <FormItem label={`版本号`}>
                        {
                            getFieldDecorator('version_id',{
                                initialValue: data.version_id || '',
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写版本号'
                                    }
                                ]
                            })(
                                <Input placeholder={`版本号`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`模板id`}>
                        {
                            getFieldDecorator('tpl_id',{
                                initialValue: data.tpl_id || '',
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写模板id'
                                    }
                                ]
                            })(
                                <Input placeholder={`模板id`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`路由设置`}>
                        {
                            data.pages.map(( page,pageIndex ) => (
                                <div key={pageIndex}>
                                    {
                                        getFieldDecorator(`pages[${pageIndex}].path`,{
                                            initialValue: page.path || '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请填写路由地址'
                                                }
                                            ]
                                        })(
                                            <Input  placeholder={'路由地址'} style={{ width:'230px' }} />
                                        )
                                    }
                                    {
                                        getFieldDecorator(`pages[${pageIndex}].name`,{
                                            initialValue: page.name || '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请填写路由名称'
                                                }
                                            ]
                                        })(
                                            <Input  placeholder={'路由名称'} style={{ width:'230px' }} className={`mgl10`} />
                                        )
                                    }
                                    <Button type={`primary`} className={`mgl10`} onClick={ () => { this.handleDelete(page,pageIndex) } }>删除</Button>
                                </div>
                            ))
                        }
                    </FormItem>
                    <FormItem label={`分包配置`}>
                        {
                            getFieldDecorator('subpackage',{
                                initialValue: data.subpackage || [],
                                rules: []
                            })(
                                <TextArea rows={6} placeholder={'分包配置'}  />
                            )
                        }
                    </FormItem>
                    <FormItem label={`操作`}>
                        <Button type={`primary`} className={``} onClick={ this.handleAdd } >添加路由</Button>
                        <Button type={`primary`} className={`mgl10`} onClick={ this.saveData } >立即保存</Button>
                    </FormItem>

                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(ReleaseConfig))
