import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input, Form, TimePicker, Row, Col } from 'antd'
import message from '@/utils/message'
import moment from 'moment'
import Gallery from '@/components/Gallery/Gallery'
const FormItem = Form.Item

class AddTime extends React.Component{
    state = {
        formData:{},
        title:'添加时段',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null,
            title:this.props.current ? `编辑时段` : '添加时段'
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = moment(values.start_time).format('HH:mm')
                values.end_time = moment(values.end_time).format('HH:mm')
                this.close()
                if(typeof callback == 'function'){
                    callback(values)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    handleInputChange = e => {
        let value = e.target.value,
            name = e.currentTarget.dataset.name,
            formData = this.state.formData || {}
        formData[name] = value;
        this.setState({
            formData
        })
    }
    render() {
        const data = this.props.current || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator,getFieldValue } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`时段名称`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入时段名称'
                                    }
                                ]
                            })(
                                <Input
                                    placeholder={`如白天场`}
                                    maxLength={6}
                                    suffix={<span>{getFieldValue('name') && getFieldValue('name').length ? getFieldValue('name').length : 0}/6</span>}
                                />
                            )
                        }
                    </FormItem>
                    <FormItem label={`欢唱时段`} required={true}>
                        <Row>
                            <Col span={10}>
                                <FormItem>
                                    <div>
                                        {
                                            getFieldDecorator('start_time',{
                                                initialValue: data.start_time ? moment(data.start_time, 'HH:mm:ss') : null,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择开始时间'
                                                    }
                                                ]
                                            })(
                                                <TimePicker
                                                    className={'mgr10'}>
                                                </TimePicker>
                                            )
                                        }
                                        ~
                                    </div>

                                </FormItem>
                            </Col>
                            <Col span={10}>
                                <FormItem>
                                    {
                                        getFieldDecorator('end_time',{
                                            initialValue: data.end_time ? moment(data.end_time, 'HH:mm:ss') : null,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择结束时间'
                                                }
                                            ]
                                        })(
                                            <TimePicker
                                                className={''} >
                                            </TimePicker>
                                        )
                                    }

                                </FormItem>
                            </Col>
                        </Row>

                    </FormItem>




                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddTime))
