import $axios from '@/axios/axios.js'

export function storeRoom(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/room`,
        method:'post',
        data
    })
}

export function roomLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/room/lists`,
        method:'post',
        data
    })
}

export function roomSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/room/saveRoom`,
        method:'post',
        data
    })
}

export function delRoom(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/room/deleteRoom`,
        method:'post',
        data
    })
}

export function deskLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/desk`,
        method:'post',
        data
    })
}

export function deskAdd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/desk/addDesk`,
        method:'post',
        data
    })
}

export function deskEdit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/desk/edit`,
        method:'post',
        data
    })
}

export function deskDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/desk/deleteDesk`,
        method:'post',
        data
    })
}

export function deskStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/desk/setStatus`,
        method:'post',
        data
    })
}
