import React from 'react'
import { Form, Button, Table } from 'antd'
import { withRouter, Link } from 'react-router-dom'
const FormItem = Form.Item

class PointItem extends React.Component{
    state = {
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                width:'45%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.name || '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>&yen;{rowData.price || '--'}</p>
                    )
                }
            },
            {
                title:'库存',
                dataIndex:'stock',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>&yen;{rowData.price || '--'}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => { this.handleDelItem(rowData,index) }}
                                disabled={!!this.props.match.params.id}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ]
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    handleDelItem = (rowData,index) => {
        this.props.onDel && this.props.onDel(rowData,index)
    }

    render() {
        let data = this.props.item || []
        return(
            <div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={ data }
                    rowKey={record => record.id}
                    style={{ width:'600px' }}
                    pagination={false}
                ></Table>
            </div>
        )
    }

}

export default Form.create({})(withRouter(PointItem))
