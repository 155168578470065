import Dashboard from '@/views/dashboard/index'
import StoreLists from '@/views/store/lists'
import StoreAdd from '@/views/store/add'
import OfficialAccount from '@/views/officialAccount/lists'
import OfficialFansLists from '@/views/officialAccount/fans-lists'
import MiniProgramLists from '@/views/miniProgram/lists'
import ItemLists from '@/views/item/lists'
import ItemAdd from '@/views/item/add'
import ItemCategory from '@/views/item/category'
import Stock from '@/views/stock/stock'
import StockManage from '@/views/stock/manage'
import StockDetail from '@/views/stock/detail'
import AddStock from '@/views/stock/add'
import UserLists from '@/views/user/lists'
import UserLabel from '@/views/user/user-label'
import UserDetail from '@/views/user/detail'
import UserCards from '@/views/user/cards'
import AddUserCard from '@/views/user/add-card'
import CardDetail from '@/views/user/card-detail'
import UserMemberCard from '@/views/user/member-card'
import DesignLists from '@/views/design/lists'
import ShopInfo from '@/views/system/shop-info'
import PaySet from '@/views/system/payset'
import PayType from '@/views/system/pay-type'
import AddPaySet from '@/views/system/add-payset'
import ReleaseConfig from '@/views/system/miniapp-release-config'
import ExpressTemplate from '@/views/express/express-template'
import AddExpressTemplate from '@/views/express/add-express-template'
import OrderLists from '@/views/order/lists'
import OrderDetail from '@/views/order/detail'
import PickUp from '@/views/order/pickup'
import Application from '@/views/application/lists'
import CouponLists from '@/views/coupon/coupon'
import CouponGift from '@/views/coupon/gift'
import CouponAdd from '@/views/coupon/add'
import Finance from '@/views/finance/index'
import DeskManage from '@/views/desk/manage'
import DeskAdd from '@/views/desk/add'
import Ticket from '@/views/system/ticket'
import ADPOP from '@/views/design/ad-pop'
import Print from '@/views/system/print'
import KTV from '@/views/ktv/index'
import OrderBookLists from '@/views/order/book-lists'
import TimeDiscountLists from '@/views/timeDiscount/lists'
import AddTimeDiscount from '@/views/timeDiscount/add'
import TimeDiscountDetail from '@/views/timeDiscount/detail'
import NewGiftLists from '@/views/newGift/lists'
import AddNewGift from '@/views/newGift/add'
import FillInformation from '@/views/user/fill-information'
import PointMall from '@/views/pointMall/index'
import AddPointGoods from '@/views/pointMall/add-point-goods'
import UserPointsRecord from '@/views/user/points-record'
import SystemMessage from '@/views/system/system-message'
import FxMemberLists from '@/views/fx/fx-member-lists'
import FxGoodsLists from '@/views/fx/fx-goods-lists'
import FxCommissionDetail from '@/views/fx/fx-commission-detail'
import FxDataAnalyse from '@/views/fx/fx-data-analyse'
import FxSetting from '@/views/fx/fx-setting'
import FxCardDiy from '@/views/fx/fx-card-diy'
import WithDrawAudit from '@/views/finance/withdraw-audit'
import UserCenter from '@/views/miniProgram/user-center'
import CourtBookSetting from '@/views/court/book-setting'
import CourtBookingStatus from '@/views/court/booking-status'
import CourtLists from '@/views/court/lists'
import CourtManage from '@/views/court/manage'
import CourtPriceManage from '@/views/court/price-manage'
import CourtDetail from '@/views/court/detail'
import CourtBookLists from '@/views/order/court-lists'
import FinanceBill from '@/views/finance/bill'
import ShortLink from '@/views/miniProgram/short-link'
import MiniProgramPath from '@/views/miniProgram/path'
import TimesCard from '@/views/user/times-card'
import AddTimesCard from '@/views/user/add-times-card'
import TimesCardDetail from '@/views/user/times-card-detail'
import UserTimesCard from '@/views/user/user-times-card'
import LessonLists from '@/views/lesson/lists'
import AddLesson from '@/views/lesson/add'
import LessonCates from '@/views/lesson/cates'
import StudentLists from '@/views/student/lists'
import StudentIntroduce from '@/views/student/introduce'
import CourseOffline from '@/views/course/offline'
import CourseOfflineAdd from '@/views/course/add'
import CourseCategory from '@/views/course/category'
import EducationalGrade from "@/views/educational/grade"
import Classroom from '@/views/educational/classroom'
import GradeDetail from '@/views/educational/gradeDetail'
import CourseLists from '@/views/order/course-lists'
import CourseOrderDetail from '@/views/order/course-detail'
import Teacher from '@/views/educational/teacher'
import TeacherEdit from '@/views/educational/teacherEdit'
import SignIn from '@/views/educational/signIn'
import Staff from '@/views/staff/staff'
import Role from '@/views/staff/role'
import AddRole from '@/views/staff/addRole'
import SignCode from '@/views/educational/signCode'
import EducationalLeave from '@/views/educational/leave'
import LeaveDetail from '@/views/educational/leave-detail'
import CustomFormLists from '@/views/custom-form/lists'
import CustomFormLog from '@/views/custom-form/log'
import CollectLikesLists from "@/views/collect-likes/lists"
import CollectLikesAdd from "@/views/collect-likes/add"
import CollectLikesInitiator from "@/views/collect-likes/initiator"
import CollectLikesWinners from "@/views/collect-likes/winners"
import RecruitLists from "@/views/recruit/lists"
import RecruitAdd from "@/views/recruit/add"
import RecruitActivity from "@/views/recruit/activity"
import RedGame from "@/views/red-game/lists"
import RedGameAdd from "@/views/red-game/add"
import RedGameActivity from "@/views/red-game/activity"
import GroupBuying from '@/views/group-buying/lists'
import GroupBuyingAdd from '@/views/group-buying/add'
import GroupBuyingActivity from '@/views/group-buying/activity'
import OverallColor from '@/views/design/color'
import TradeSystem from '@/views/system/trade'
import RecommedNote from '@/views/recommend/note'
import NoteAdd from '@/views/recommend/note-add'
import CommentDetail from '@/views/recommend/comment-detail'
import UserSignIn from '@/views/user/sign-in'
import SearchSet from '@/views/design/search'
import Open from '@/views/open'
import GroupSolitaireLists from '@/views/group-solitaire/lists'
import GroupSolitaireAdd from '@/views/group-solitaire/add'
import GroupSolitaireActivity from '@/views/group-solitaire/activity'
import TikTokOrder from '@/views/system/tiktok-order'
import LiveStreamCalendar from '@/views/livestream/index'
import CalendarAdd from '@/views/livestream/add'
import CalendarDetail from '@/views/livestream/detail'
import TikTokOrderRecord from '@/views/order/tiktok'
import LuckyLists from "@/views/lucky/lists"
import LuckyAdd from "@/views/lucky/add"
import LotteryDetail from '@/views/lucky/Lottery-detail'
import DataPoints from '@/views/data/points'
import PointsRules  from '@/views/operating-rules/points-rules'
import PointsRulesEdit  from '@/views/operating-rules/points-rules-edit'
import UserLevel  from '@/views/level/user-level'
import DevTask  from '@/views/level/dev-task'
import Equity  from '@/views/level/equity'
import EquityEdit from '@/views/level/equity-edit'
import ErrorPage from '@/views/error/index'
import levelEdit from '@/views/level/level-edit'
import vipNotice from '@/views/level/vip-notice'
import DataTeaching from '@/views/data/teaching'
import EducationalBMI from '@/views/educational/bmi'
import BMIFormAdd from '@/views/educational/components/bmiFormAdd'
import TicketLists from '@/views/ticket/lists'
import TicketAdd from '@/views/ticket/add'
import OrderTicketLists from '@/views/order/ticket-lists'

export const routes = [
    { path: '/dashboard', component: Dashboard, withoutSideMenu: true },
    { path: '/store/lists', component: StoreLists },
    { path: '/store/add/:id?', component: StoreAdd },
    { path: '/officialAccount/lists', component: OfficialAccount },
    { path: '/miniProgram/lists', component: MiniProgramLists },
    { path: '/miniProgram/user-center', component: UserCenter },
    { path: '/item/lists', component: ItemLists },
    { path: '/item/add/:id?', component: ItemAdd },
    { path: '/item/category', component: ItemCategory },
    { path: '/stock/stock', component: Stock },
    { path: '/stock/manage', component: StockManage },
    { path: '/stock/detail/:code?', component: StockDetail },
    { path: '/stock/add/:type?', component: AddStock },
    { path: '/user/lists', component: UserLists },
    { path: '/user/user-label', component: UserLabel },
    { path: '/user/detail/:id?', component: UserDetail },
    { path: '/user/cards', component: UserCards },
    { path: '/user/add-card/:id?', component: AddUserCard },
    { path: '/user/card-detail/:id?', component: CardDetail },
    { path: '/user/member-card/:user_id?/:user_card_id?', component: UserMemberCard },
    { path: '/user/points-record/:user_id?', component: UserPointsRecord },
    { path: '/design/lists', component: DesignLists },
    { path: '/system/shop-info', component: ShopInfo },
    { path: '/system/payset', component: PaySet },
    { path: '/system/add-payset/:id?', component: AddPaySet },
    { path: '/system/system-message', component: SystemMessage },
    { path: '/express/template', component: ExpressTemplate },
    { path: '/express/add-template/:id?', component: AddExpressTemplate },
    { path: '/order/lists/:status?', component: OrderLists },
    { path: '/order/detail/:id?', component: OrderDetail },
    { path: '/order/pickup', component: PickUp },
    { path: '/application/lists', component: Application },
    { path: '/coupon/lists', component: CouponLists },
    { path: '/coupon/gift', component: CouponGift },
    { path: '/coupon/add/:id?/:type?', component: CouponAdd },
    { path: '/finance/index', component: Finance },
    { path: '/desk/manage', component: DeskManage },
    { path: '/desk/add/:id?', component: DeskAdd },
    { path: '/system/ticket', component: Ticket },
    { path: '/system/print', component: Print },
    { path: '/design/ad-pop', component: ADPOP },
    { path: '/ktv/index', component: KTV },
    { path: '/order/book-lists', component: OrderBookLists },
    { path: '/officialAccount/fans-lists', component: OfficialFansLists },
    { path: '/time-discount/lists', component: TimeDiscountLists },
    { path: '/time-discount/add/:id?', component: AddTimeDiscount },
    { path: '/time-discount/detail/:id?', component: TimeDiscountDetail },
    { path: '/new-gift/lists', component: NewGiftLists },
    { path: '/new-gift/add/:id?', component: AddNewGift },
    { path: '/user/fill-information', component: FillInformation },
    { path: '/point-mall/index', component: PointMall },
    { path: '/point-mall/add-point-goods/:id?', component: AddPointGoods },
    { path: '/fx/member-lists', component: FxMemberLists },
    { path: '/fx/goods-lists', component: FxGoodsLists },
    { path: '/fx/commission-detail', component: FxCommissionDetail },
    { path: '/fx/data-analyse', component: FxDataAnalyse },
    { path: '/fx/setting', component: FxSetting },
    { path: '/fx/card-diy', component: FxCardDiy },
    { path: '/finance/withdraw-audit', component: WithDrawAudit },
    { path: '/court/book-setting', component: CourtBookSetting },
    { path: '/court/booking-status', component: CourtBookingStatus },
    { path: '/court/lists/:id?', component: CourtLists },
    { path: '/court/manage', component: CourtManage },
    { path: '/court/price-manage', component: CourtPriceManage },
    { path: '/court/detail/:id?', component: CourtDetail },
    { path: '/order/court-lists', component: CourtBookLists },
    { path: '/system/pay-type', component: PayType },
    { path: '/finance/bill', component: FinanceBill },
    { path: '/miniProgram/short-link', component: ShortLink },
    { path: '/miniProgram/path', component: MiniProgramPath },
    { path: '/user/times-card', component: TimesCard },
    { path: '/user/add-times-card/:id?', component: AddTimesCard },
    { path: '/user/times-card-detail/:id?', component: TimesCardDetail },
    { path: '/user/user-times-card/:user_id?/:times_card_id?', component: UserTimesCard },
    { path: '/lesson/lists', component: LessonLists },
    { path: '/lesson/add/:id?', component: AddLesson },
    { path: '/lesson/category', component: LessonCates },
    { path: '/system/miniapp-release-config', component: ReleaseConfig },
    { path: '/student/lists', component: StudentLists },
    { path: '/student/introduce', component: StudentIntroduce },
    { path: '/course/offline', component: CourseOffline },
    { path: '/course/offline-add/:id?', component: CourseOfflineAdd },
    { path: '/course/category', component: CourseCategory },
    { path: '/educational/grade', component: EducationalGrade },
    { path: '/educational/classroom', component: Classroom },
    { path: '/educational/grade-detail/:id?', component: GradeDetail },
    { path: '/order/course-lists', component: CourseLists },
    { path: '/order/course-detail/:id?', component: CourseOrderDetail },
    { path: '/educational/teacher', component: Teacher },
    { path: '/educational/teacher-edit/:id?', component: TeacherEdit },
    { path: '/educational/sign-in/:id?', component: SignIn },
    { path: '/staff/lists', component: Staff },
    { path: '/staff/role', component: Role },
    { path: '/staff/role-add/:id?', component: AddRole },
    { path: '/educational/sign-code', component: SignCode },
    { path: '/custom-form/lists', component: CustomFormLists },
    { path: '/custom-form/log/:id?', component: CustomFormLog },
    { path: "/educational/leave", component: EducationalLeave },
    { path: "/educational/leave-detail/:id?", component: LeaveDetail },
    { path: '/collect-likes/lists', component: CollectLikesLists },
    { path: '/collect-likes/add/:id?', component: CollectLikesAdd },
    { path: '/collect-likes/initiator/:id', component: CollectLikesInitiator },
    { path: '/collect-likes/winners/:id', component: CollectLikesWinners },
    { path: '/recruit/lists', component: RecruitLists },
    { path: '/recruit/add/:id?', component: RecruitAdd },
    { path: '/recruit/activity/:id', component: RecruitActivity },
    { path: '/red-game/lists', component: RedGame },
    { path: '/red-game/add/:id?', component: RedGameAdd },
    { path: '/red-game/activity/:id', component: RedGameActivity },
    { path: '/group-buying/lists', component: GroupBuying },
    { path: '/group-buying/add/:id?', component: GroupBuyingAdd },
    { path: '/group-buying/activity/:id', component: GroupBuyingActivity },
    { path: '/design/color', component: OverallColor },
    { path: '/system/trade', component: TradeSystem },
    { path: '/recommend/note', component: RecommedNote },
    { path: '/recommend/note-add/:id?', component: NoteAdd },
    { path: '/recommend/comment-detail/:id', component: CommentDetail },
    { path: '/user/sign-in', component: UserSignIn },
    { path: '/search/set', component: SearchSet },
    { path: '/open/index', component: Open },
    { path: '/group-solitaire/lists', component: GroupSolitaireLists },
    { path: '/group-solitaire/add/:id?', component: GroupSolitaireAdd },
    { path: '/group-solitaire/activity/:id', component: GroupSolitaireActivity },
    { path: '/system/tiktok-order', component: TikTokOrder },
    { path: '/livestream/calendar', component: LiveStreamCalendar },
    { path: '/livestream/calendar-detail/:id', component: CalendarDetail },
    { path: '/livestream/calendar-add/:id?', component: CalendarAdd },
    { path: '/order/tiktok-record', component: TikTokOrderRecord },
    { path: '/lucky/lists', component: LuckyLists },
    { path: '/lucky/add/:id?', component: LuckyAdd },
    { path: '/data/points', component: DataPoints },
    { path: '/lucky/Lottery-detail/:id', component: LotteryDetail },
    { path: '/operating/points-rules', component: PointsRules },
    { path: '/operating/points-rules-edit/:id', component: PointsRulesEdit },
    { path: '/level/user-level', component: UserLevel },
    { path: '/level/dev-task', component: DevTask },
    { path: '/level/equity', component: Equity },
    { path: '/level/equity-edit/:id?', component: EquityEdit },
    { path: '/level/level-edit', component: levelEdit },
    { path: '/level/vip-notice', component: vipNotice },
    { path: '/data/teaching', component: DataTeaching },
    { path: '/educational/bmi', component: EducationalBMI },
    { path: '/educational/bmi-edit/:id?', component: BMIFormAdd },
    { path: '/ticket/lists', component: TicketLists },
    { path: '/ticket/add/:id?', component: TicketAdd },
    { path: '/order/ticket-lists', component: OrderTicketLists },

    { path: '/error/404', component: ErrorPage },
]
