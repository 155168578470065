import React, { Component } from 'react'
import { Switch, Icon, Button,message } from 'antd';
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import Loading from '@/components/Loading'
import { ICON_FONT_URL } from '@/constants/iconFont'
import { setAppColor, getAppColor } from '@/api/design'
import '@/assets/css/design/color.scss'
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

const colorList = [
    {
        id: 1,
        primary: "#FF0000",
        secondary: "#FF9A79"
    },
    {
        id: 2,
        primary: "#FF9D9E",
        secondary: "#FFD69D"
    },
    {
        id: 3,
        primary: "#FF2458",
        secondary: "#FFEC68"
    },
    {
        id: 4,
        primary: "#FCA5F1",
        secondary: "#FDDDAB"
    },
    {
        id: 5,
        primary: "#5D4CFE",
        secondary: "#FFA6B7"
    },
    {
        id: 6,
        primary: "#C9B1E0",
        secondary: "#FFDFE5"
    },
    {
        id: 7,
        primary: "#0077FF",
        secondary: "#8FF6FF"
    },
    {
        id: 8,
        primary: "#B96E62",
        secondary: "#EFE3D2"
    }
]

export default class OverallColor extends Component {
    state = {
        isLoading: false,
        colorForm: {
            id: 0,
            primary: "#000",
            secondary: "#000"
        },
        current: {
            id: 1,
            primary: "#FF0000",
            secondary: "#FF9A79"
        },
        diy_checkd: false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })

        getAppColor({ key: "global_color" }).then(res => {
            if (res.code == 1) {
                let value = res.data.config && res.data.config.value
                if (typeof value == 'string') {
                    value=JSON.parse(value)

                    let current={
                        id:value.default_style,
                        primary:value.default_brand_color,
                        secondary:value.default_support_color
                    } ,
                    colorForm={
                        id: 0,
                        primary: value.brand_color,
                        secondary: value.support_color
                    },
                    diy_checkd=value.is_customize

                    this.setState({
                        current,colorForm,diy_checkd
                    })
                }
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    saveData = () => {
        let { colorForm, current, diy_checkd } = this.state, value,sendData={}
        value = {
            is_customize:diy_checkd,
            brand_color:colorForm.primary,
            support_color:colorForm.secondary,
            default_style:current.id,
            default_brand_color:current.primary,
            default_support_color:current.secondary
        }
        sendData.key='global_color'
        sendData.value=value

        this.setState({
            isLoading: true
        })

        setAppColor(sendData).then(res => {
            if (res.code == 1) {
                message.success(res.msg)
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })

    }

    handleColorChange = (color, name) => {
        let colorForm = this.state.colorForm, current = this.state.current
        colorForm[name] = color.hex

        if (current.id == 0) {
            current = colorForm
        }
        this.setState({
            colorForm,
            current
        })

    }

    handleSwitch = (bool) => {
        let current = colorList[0]
        if (bool) {
            current = this.state.colorForm
        }

        this.setState({
            current,
            diy_checkd: bool
        })
    }

    handleSelcetColor = item => {
        return () => {
            let current = this.state.current
            if (item.id == current.id) { return }
            current = item
            this.setState({
                current,
                diy_checkd: false
            })
        }
    }

    render() {

        let { diy_checkd, colorForm, current } = this.state

        return (
            <div className={'shadow-radius'} style={{ '--primary-color': current.primary, '--secondary-color': current.secondary, 'overflow': 'auto' }} >
                <div className='flex'>
                    <div className="set-color">
                        <div className="title">
                            风格选择 <span className='mgl20'>店铺风格主配色</span>
                        </div>
                        <div className="color-container">
                            {
                                colorList.map(item => {
                                    return (
                                        <div className={`color-wrap ${item.id == current.id ? 'active' : ''}`} key={item.id} onClick={this.handleSelcetColor(item)} >
                                            <div className="color-item" style={{ 'background': item.primary }} ></div>
                                            <div className="color-item" style={{ 'background': item.secondary }} ></div>
                                            <div className="color-item"></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="title">自定义配色</div>
                        <div className="diy-color">
                            <div className='flex justify-between mgt20'>
                                <div>是否启用</div>
                                <Switch checked={diy_checkd} onChange={this.handleSwitch} />
                            </div>
                            <div className='flex justify-between mgt20'>
                                <div>品牌色</div>
                                <ColorPicker onRef={e => { this.ColorPicker = e }} color={colorForm.primary} callback={(color) => { this.handleColorChange(color, 'primary') }} ></ColorPicker>
                            </div>
                            <div className='flex justify-between mgt20'>
                                <div>辅助色</div>
                                <ColorPicker onRef={e => { this.ColorPicker = e }} color={colorForm.secondary} callback={(color) => { this.handleColorChange(color, 'secondary') }} ></ColorPicker>
                            </div>
                        </div>
                    </div>
                    <div className="example-phone">
                        <div className="phone-wrap phone1">
                            <div className="search-btn">搜索</div>
                            <IconFont type={`icon-icon_huxian`} className={`arc`} ></IconFont>
                            <div className="price price1">
                                &yen; <span className='price-num'>49</span>.00
                            </div>
                            <div className="price price2">
                                &yen; <span className='price-num'>59</span>.00
                            </div>
                            <div className="price price3">
                                &yen; <span className='price-num'>129</span>.00
                            </div>
                            <div className="price price4">
                                &yen; <span className='price-num'>249</span>.00
                            </div>

                            <div className="coupon coupon1">
                                <div className="tag">
                                    <span className="tag-bg"></span>
                                    <span>领券</span>
                                </div>
                                <div className='flex1'>满199减10</div>
                            </div>

                            <div className="coupon coupon2">
                                <div className="tag">
                                    <span className="tag-bg"></span>
                                    <span>领券</span>
                                </div>
                                <div className='flex1'>满199减60</div>
                            </div>

                            <div className="coupon coupon3">
                                <div className='flex1'>满110减30</div>
                            </div>
                        </div>
                        <div className="phone-wrap phone2">
                            <div className="goods">
                                <div>商品</div>
                                <IconFont type={`icon-icon_huxian`} ></IconFont>
                            </div>

                            <IconFont type={`icon-shoucang-xuanzhong`} className='collect' ></IconFont>

                            <div className="price">
                                &yen; <span className='price-num'>690</span>.00
                            </div>
                            <div className="cart-tag">2</div>
                            <div className="btn-group">
                                <div className="btn secondary">加入购物车</div>
                                <div className="btn ">立即购买</div>
                            </div>
                        </div>
                        <div className="phone-wrap phone3">
                            <div className="price">
                                &yen; <span className='price-num'>690</span>.00
                            </div>
                            <div className="sku-tag sku1">
                                <span className="tag-bg"></span>
                                <i className="img"></i>
                                <span>白色</span>
                            </div>
                            <div className="sku-tag sku2">
                                <span className="tag-bg"></span>
                                <span>39</span>
                            </div>
                            <div className="text-desc">满199减20</div>
                            <div className="btn-group">
                                <div className="btn secondary">加入购物车</div>
                                <div className="btn ">领券购买</div>
                            </div>
                        </div>
                        <div className="phone-wrap phone4">
                            <div className="search-btn">搜索</div>
                            <IconFont type={`icon-icon_huxian`} className={`arc`} ></IconFont>
                            <div className="text-desc desc1">等待付款</div>
                            <div className="text-desc desc2">交易成功</div>
                            <div className="text-desc desc3">交易关闭</div>
                            <div className="order-btn btn1">去支付</div>
                            <div className="order-btn btn2">加入购物车</div>
                        </div>
                    </div>
                </div>
                <div className='mgt20 '>
                    <Button type='primary' onClick={this.saveData}>保存</Button>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}
