import React from 'react'
import { Form } from 'antd'
import { withRouter } from 'react-router-dom'
import EditForm from './components/EditForm'
import Loading from '@/components/Loading'
import PropTypes from 'prop-types'


class StoreAdd extends React.Component{
    static childContextTypes = {
        isLoading:PropTypes.bool,
        setLoading:PropTypes.func
    }
    state = {
        isLoading:false,
    }

    getChildContext = () => {
        return {
            isLoading:this.state.isLoading,
            setLoading:(e) => {
                this.setLoading(e)
            }
        }
    }
    setLoading = e => {
        this.setState({
            isLoading:!!e
        })
    }


    componentDidMount() {}


    render() {
        return(
            <div className={'shadow-radius'}>
                <EditForm onRef={e => { this.EditForm = e }} ></EditForm>
                {
                    this.state.isLoading
                        ?
                        <Loading/>
                        :
                        null
                }
            </div>
        )
    }

}
export default Form.create({})(withRouter(StoreAdd))

