import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tabs, message } from 'antd';
import BaseForm from './components/BaseForm'
import ShareForm from './components/ShareForm';
import PrizeForm from './components/PrizeForm';
import Loading from '@/components/Loading';
import { redGameSave, redGameDetail } from "@/api/red-game.js"
import moment from 'moment';


const FormItem = Form.Item;
const { TabPane } = Tabs;


class RedGameAdd extends Component {
    state = {
        activeStatus: '1',
        isLoading: false,
        share_poster: "https://img.cxkoo.com/chengxuan/1/2022-07-28/b308a9d94404f2fac08f1fe8bc77d6e3.png",
        is_edit: false,
        is_disabled:false,
        activityData: {},
        red_num: 0,
        isForceRender: false
    }

    componentDidMount() {

        if (this.props.match.params.id) {
            this.setState({
                is_edit: true
            })
            this.fetchData()
        } else {
            this.setState({
                isForceRender: true
            })
        }
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })

        redGameDetail({ id: this.props.match.params.id }).then(res => {
            if (res.code == 1) {

                const { detail } = res.data
                let red_num = 0,is_disabled=false

                if (detail.start_time && detail.end_time) {
                    detail.time = [moment(detail.start_time, 'YYYY-MM-DD HH:mm:ss'), moment(detail.end_time, 'YYYY-MM-DD HH:mm:ss')]
                }

                if (detail.cost_point > 0) {
                    detail.is_cost_point = 1
                } else {
                    detail.is_cost_point = 0
                    detail.cost_point = 1
                }

                if (detail.limit > 0) {
                    detail.is_limit = 1
                } else {
                    detail.is_limit = detail.limit
                    detail.limit = 1
                }

                if (detail.win_prize_count > 0) {
                    detail.is_win_prize_count = 1
                } else {
                    detail.is_win_prize_count = 0
                    detail.win_prize_count = 1
                }

                red_num = detail.red_num

                if(detail.status != 1){
                    is_disabled=true
                }
              

                this.setState({
                    activityData: detail,
                    red_num,
                    is_disabled
                },()=>{
                    this.setState({
                        isForceRender: true
                    })
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    clickTab = val => {
        this.setState({
            activeStatus: val
        })
    }

    saveData = async () => {
        const BaseFormData = await this.BaseForm.getFormData()

        if (!BaseFormData) {
            return this.clickTab('1')
        }

        const PrizeFormData = await this.PrizeForm.getFormData()
        if (!PrizeFormData) {
            return this.clickTab('2')
        }

        const ShareFormData = await this.ShareForm.getFormData()
        if (!ShareFormData) {
            return this.clickTab('3')
        }

        const sendData = { ...BaseFormData, ...PrizeFormData, ...ShareFormData }


        if (this.state.is_edit) {
            sendData.id = this.state.activityData.id
        }

        if (!sendData.share_poster) {
            sendData.share_poster = this.state.share_poster
        }

        this.setState({
            isLoading: true
        })

        redGameSave(sendData).then(res => {
            if (res.code == 1) {
                message.success(res.msg)
                    .then(() => {
                        this.props.history.push({
                            pathname: `/red-game/lists`
                        })
                    })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }



    updateData = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleChangeTabs = key => {
        this.setState({
            activeStatus: key
        })
    }

    render() {

        return (
            <div className='shadow-radius'>
                <div className="clearfix mgt15 active-add">
                    <div className="phone-wrap fl">
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-07-25/a07161980c5976da0ae5beabdc12085c.png" alt="" />
                    </div>
                    <div className="ctrl-wrap fl mgl20" >
                        <Tabs className={'mgt15'} activeKey={this.state.activeStatus} onChange={this.handleChangeTabs}>
                            <TabPane tab="基础设置" key={'1'}>
                                <BaseForm onRef={e => this.BaseForm = e} updateData={this.updateData} updateTab={this.clickTab} is_edit={this.state.is_edit} is_disabled={this.state.is_disabled} activityData={this.state.activityData}></BaseForm>
                            </TabPane>
                            <TabPane tab="奖品设置" key={'2'} forceRender={this.state.isForceRender}>
                                <PrizeForm onRef={e => this.PrizeForm = e} updateTab={this.clickTab} is_edit={this.state.is_edit} is_disabled={this.state.is_disabled} activityData={this.state.activityData} red_num={this.state.red_num}></PrizeForm>
                            </TabPane>
                            <TabPane tab="分享设置" key={'3'}>
                                <ShareForm onRef={e => this.ShareForm = e} saveData={this.saveData} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData}></ShareForm>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(RedGameAdd))