import React from 'react'
import { Spin } from 'antd'
const style = {
    width:'100%',
    height:'100%',
    position:'fixed',
    zIndex:'99999',
    left:'0px',
    top:'0px',
    backgroundColor:'rgba(255,255,255,0.3)',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
}

class Loading extends React.Component{
    render() {
        return(
            <div className={'mask'} style={style}>
                <Spin></Spin>
            </div>
        )
    }
}

export default Loading
