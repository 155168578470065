import $axios from '@/axios/axios.js'

export function lotteryLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery`,
        method:'post',
        data
    })
}

export function lotteryDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/detail`,
        method:'post',
        data
    })
}

export function lotterySave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/save`,
        method:'post',
        data
    })
}

export function lotteryDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/delete`,
        method:'post',
        data
    })
}

export function lotteryEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/end`,
        method:'post',
        data
    })
}

export function lotteryCopy(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/copy`,
        method:'post',
        data
    })
}

export function lotteryGameData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/gameData`,
        method:'post',
        data
    })
}

export function lotteryReward(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/reward`,
        method:'post',
        data
    })
}

export function lotteryJoinRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/joinRecord`,
        method:'post',
        data
    })
}
export function lotteryInviteRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/inviteRecord`,
        method:'post',
        data
    })
}
export function lotteryTaskRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/lottery/taskRecord`,
        method:'post',
        data
    })
}