import $axios from '@/axios/axios.js'

export function getPayConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program-pay/getConfig`,
        method:'post',
        data
    })
}
export function setPayConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program-pay/setConfig`,
        method:'post',
        data
    })
}

export function payTypeLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pay-type`,
        method:'post',
        data
    })
}

export function savePayType(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pay-type/save`,
        method:'post',
        data
    })
}

export function delPayType(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pay-type/delete`,
        method:'post',
        data
    })
}

export function payTypeStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pay-type/setStatus`,
        method:'post',
        data
    })
}

export function payTypeSort(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pay-type/changeSort`,
        method:'post',
        data
    })
}


export function getTradeConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program-pay/getTradeConfig`,
        method:'post',
        data
    })
}

export function setTradeConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program-pay/setTradeConfig`,
        method:'post',
        data
    })
}
