import React from 'react'
import { Table } from 'antd'

class SelfOrder extends React.Component{
    state = {
        columns:[
            {
                title:'内容',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={``}>
                            <p className={`lh25`}>自助买单</p>
                        </div>
                    )
                }
            },
            {
                title:'售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>&yen;{ rowData.total_price }</p>
                        </div>
                    )
                }
            },
            {
                title:'数量',
                dataIndex:'num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>1</p>
                        </div>
                    )
                }
            },
            {
                title:'优惠',
                dataIndex:'discount',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.trade_extends.coupon_discount_price
                                    ?
                                    <p className={`lh25`}>-&yen;{ rowData.trade_extends.coupon_discount_price }</p>
                                    :
                                    <p className={`lh25`}>-&yen;0.00</p>

                            }
                        </div>
                    )
                }
            },
            {
                title:'合计',
                dataIndex:'pay_ment',
                render:(text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{ rowData.pay_ment || '-' }</p>
                        </div>
                    )
                }
            }
        ],
        tableLoading:false,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    render() {
        let tableData = this.props.tableData || []
        console.log(tableData)
        return(
            <Table
                size={`middle`}
                columns={this.state.columns}
                dataSource={tableData}
                loading={this.state.tableLoading}
                rowKey={record => record.id}
                pagination={false}
            ></Table>
        )
    }
}
export default SelfOrder
