import React, { Component } from 'react'
import { Form, Input, InputNumber, Select, DatePicker, Row, Col, Modal ,TreeSelect} from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { gradeSaveApi,teacherApi} from '@/api/educational'
import { CourseCategoryApi} from '@/api/course'
import message from '@/utils/message'
const FormItem = Form.Item;

class AddGrade extends Component {

    state = {
        courseLists: [],
        storeLists: [],
        teacherList:[],
        modalLoading: false
    }
    componentDidMount() {
        this.fetchStore()
        this.fetchCourse()
        this.fetchTeacher()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    fetchCourse = () => {

        CourseCategoryApi({ page: 1, per_page: 100 }).then(res => {

            if (res.code === 1) {
                let { data } = res.data.list

                data.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.disabled = item.children && item.children.length > 0
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })

                this.setState({
                    courseLists: data
                })
            }
        })
    }

    fetchTeacher = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        
        teacherApi(sendData)
            .then(res => {
                
                if (res.code == 1) {
                    let lists = res.data.list.data
        
                    this.setState({
                        teacherList: lists
                    })
                }
            })
            
    }




    saveData = () => {
        let sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;
                
                sendData.start_date = values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : ''
                sendData.end_date = values.start_date ? moment(values.end_date).format('YYYY-MM-DD') : ''

                if(!moment(sendData.start_date).isBefore(sendData.end_date)){
                    return message.error("开班时间必须小于结班时间")
                }

                console.log(values);
                if(this.props.edit_status){
                    sendData.id=this.props.grade_line.id
                }

                this.setState({
                    modalLoading: true
                })
                gradeSaveApi(sendData).then(res => {
                   
                    if (res.code === 1) {
                        message.success(res.msg)
                        this.setState({
                            modalLoading: false
                        })
                        this.props.handleS()
                        this.handleCancel()
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.props.closeAddGrade()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        let data={}
        if(this.props.edit_status){
            data=this.props.grade_line
        }

        return (

            <Modal
                title={`${this.props.edit_status?'编辑':'新增'}班级`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={20}>
                            <FormItem label="班级名称">
                                {getFieldDecorator('name', {
                                    initialValue: data.name||"",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入班级名称'
                                        }
                                    ]
                                })(<Input placeholder='请输入班级名称' maxLength={30} />)}
                            </FormItem>
                            <FormItem label={'课程类目'} >
                                {
                                    getFieldDecorator('category_id', {
                                        initialValue: data.category_id|| "",
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择课程类目'
                                            }
                                        ]
                                    })(
                                        <TreeSelect
                                                showSearch
                                                style={{width:'100%'}}
                                                placeholder={'请选择课程类目'}
                                                treeData={this.state.courseLists}
                                                disabled={this.props.edit_status}
                                            />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'上课地点'} >
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue:  data.store_id|| undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择上课地点'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择上课地点'} style={{width:'100%'}}  >
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'开班时间'} >
                                {
                                    getFieldDecorator('start_date', {
                                        initialValue:data.start_date? moment(data.start_date, "YYYY-MM-DD'"): null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择开班时间'
                                            }
                                        ]
                                    })(
                                        <DatePicker placeholder='请选择开班时间' style={{width:'100%'}}  disabled={this.props.edit_status} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'结班时间'} >
                                {
                                    getFieldDecorator('end_date', {
                                        initialValue: data.start_date? moment(data.end_date, "YYYY-MM-DD'"): null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择结班时间'
                                            }
                                        ]
                                    })(
                                        <DatePicker placeholder='请选择结班时间' style={{width:'100%'}} />
                                    )
                                }
                            </FormItem>

                            <FormItem label="班级总人数">
                                {getFieldDecorator('grade_num', {
                                    initialValue:data.grade_num|| "",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入班级可容纳总人数'
                                        }
                                    ]
                                })(<InputNumber placeholder='请输入最多可容纳人数' style={{width:'100%'}}  precision={0} />)}
                            </FormItem>

                            <FormItem label={'老师'} >
                                {
                                    getFieldDecorator('teacher_id', {
                                        initialValue: data.teacher_id || undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择上课地点'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择老师'}  >
                                            {
                                                this.state.teacherList.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'助教'} >
                                {
                                    getFieldDecorator('assist_teacher_id', {
                                        initialValue: data.assist_teacher_id || undefined,
                                    })(
                                        <Select placeholder={'请选择助教'}    >
                                            {
                                                this.state.teacherList.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>





                        </Col>

                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(AddGrade))