import $axios from '@/axios/axios.js'

export function redGameSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/red-game/save`,
        method:'post',
        data
    })
}

export function redGameLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/red-game`,
        method:'post',
        data
    })
}

export function redGameDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/red-game/detail`,
        method:'post',
        data
    })
}

export function redGameEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/red-game/end`,
        method:'post',
        data
    })
}

export function redGameDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/red-game/delete`,
        method:'post',
        data
    })
}


export function redGameGameData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/red-game/gameData`,
        method:'post',
        data
    })
}

export function exportRedGame(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/red-game/export`,
        method:'get',
        responseType:'blob',
        params
    })
}