import React from 'react'
import { Table, Pagination, Tag } from 'antd'
import { Link } from 'react-router-dom'
import {  CourseListsApi } from '@/api/course'
import CX from '@/utils/CX'


class CourseLinks extends React.Component{
    state = {
        columns:[
            {
                title:'课程信息',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.pic_urls && rowData.pic_urls[0]} alt="" width={45}/>
                            <div className={`mgl10`}>
                                <Link to={`/course/offline-add/${rowData.id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'价格',
                dataIndex:'price',
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <p>{ rowData.price ? `${rowData.price}元` : `--` }</p>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'created_at',
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render: (text, rowData, index) => {
                    let status_color, status_text
                    if (rowData.status == 1) {
                        status_text = "出售中"
                        status_color = "green"
                    } else if (rowData.status == 2) {
                        status_text = "已售罄"
                        status_color = "red"
                    } else {
                        status_text = "已下架"
                        status_color = ""
                    }
                    return (
                        <Tag color={status_color}>{status_text}</Tag>
                    )
                }
            }
        ],
        searchForm:{
            status: 0,
            name: '',
            category_id: '',
            charge_type: '',
            store_id: '',
            order_field:'',
            order_asc:''
        },
        tableData:[],
        selectRows:[],
        selectedRowKeys:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:5,

    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading:true
        })
        CourseListsApi(sendData)
            .then(res => {
              
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleSort = (pagination, filters, sorter) => {
        let field = sorter.field,
            order = sorter.order,
            searchForm = this.state.searchForm;
        if(order){
            searchForm.order_field = field;
            searchForm.order_asc  = order == 'ascend' ? 'asc' : 'desc';
        }else {
            searchForm.order_field = '';
            searchForm.order_asc  = '';
        }
        this.setState({
            searchForm
        },() => {
            this.fetchData()
        })
    }
    clearData = () => {
        this.setState({
            selectedRowKeys:[],
            selectRows:[]
        })
    }
    selectRow = (record) => {
        this.setState({ 
            selectedRowKeys:[record.id],
            selectRows:[record]
        },() => {
            let data = this.props.data || {},  res = {}
            res.link = `${data.options[0]['link']}?course_id=${record['id']}`
            res.linkType = data.options[0]['linkType']
            res.name = data.options[0]['name']
            this.props.onChange && this.props.onChange([res])
        });
      }
    render() {
        let data = this.props.data || {}
       
        const rowSelection = {
            type: 'radio',
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {
                let res = {}
                if(selectedRowKeys.length == selectedRows.length){
                   
                    this.setState({
                        selectRows:selectedRows,
                        selectedRowKeys
                    },() => {
                        res.link = `${data.options[0]['link']}?course_id=${selectedRows[0]['id']}`
                        res.linkType = data.options[0]['linkType']
                        res.name = data.options[0]['name']
                        this.props.onChange && this.props.onChange([res])
                    })
                  
                }else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr,selectedRowKeys);
                    for(var i in diffArr){
                        for (var j in  oldList){
                            if(oldList[j]['id'] == diffArr[i]){
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows:tmpList,
                        selectedRowKeys
                    },() => {
                        this.props.onChange && this.props.onChange(selectedRows)
                    })


                }
            }
        }
        return(
            <div>
                <div className={'content-body'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                        onChange={(pagination, filters, sorter) => { this.handleSort(pagination, filters, sorter) }}
                        onRow={(record) => ({
                            onClick: () => {
                              this.selectRow(record);
                            },
                          })}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CourseLinks
