import React, { Component } from 'react'
import { Form, Input, Button, Table, Divider, Select, InputNumber, Icon,Tooltip } from 'antd';
import moment from 'moment'
import PrizeAdd from './PrizeAdd';
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
const FormItem = Form.Item;

const no_prize_img = 'https://img.cxkoo.com/chengxuan/1/2023-05-05/4a47e7a95759bc2340e3babe797939a8.png';

class PrizeForm extends Component {
    state = {
        couponLists: [],
        formData: {},
        prizeLists: [],
        columns: [
            {
                title: '奖品名称',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.prize_name}</p>
                    )
                }
            },
            {
                title: '奖品类型',
                dataIndex: '2',
                render: (text, rowData, index) => {

                    return (
                        <p>{['积分', '优惠券', '实物', '微信零钱'][rowData.prize_type - 1]} </p>
                    )
                }
            },
            {
                title: '奖品数量',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.prize_num}</p>
                    )
                }
            },
            {
                title: '已发放数量',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p> {0}</p>
                    )
                }
            },
            {
                title: '奖品图片',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <img src={rowData.prize_img} alt="" width={60} />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '7',
                width: '250px',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData, index) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData, index) }}>删除</Button>
                        </div>
                    )
                }
            }
        ],
        no_prize: {
            prize_name: '谢谢参与',
            prize_img: no_prize_img,
            fail_depict: "离大奖就差一步，不要灰心，再来一次吧！",
            id: '0'
        },
        exampleLists: [
            {
                id: 1,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },
            {
                id: 2,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },
            {
                id: 3,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },
            {
                id: 4,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },
            {
                id: 5,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },
            {
                id: 6,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },
            {
                id: 7,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },
            {
                id: 8,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name:'谢谢参与'
            },

        ]
    }

    componentDidMount() {
        this.props.onRef(this)

        if (this.props.is_edit) {
            const data = this.props.activityData
            let {no_prize,exampleLists,prizeLists}=this.state

            no_prize.prize_name=data.fail_name;
            no_prize.fail_depict=data.fail_depict;
            no_prize.prize_img=data.fail_img;

            prizeLists=data.prize_info
            exampleLists=data.lottery_rule
            
            this.setState({
                no_prize,prizeLists,exampleLists
            })

        }

    }

    updateParentExample=(value)=>{
        this.props.updateData('exampleLists',value)
    }

    handleAddPrize = () => {
        let prizeLists = this.state.prizeLists || [];

        if (prizeLists.length >= 7) {
            return
        }
        this.PrizeAdd.show((item) => {
            prizeLists.push(item)
            this.setState({
                prizeLists
            })
        })
    }
    handleEdit = (row, index) => {
        let { prizeLists, exampleLists } = this.state;
        this.PrizeAdd.show((item) => {
            prizeLists[index] = item

            let bool = exampleLists.some(val => val.prize_id == row.id);
            if (bool) {
                exampleLists = exampleLists.map(val => {
                    if (val.prize_id == row.id) {
                        val.prize_img = item.prize_img
                        val.prize_name=item.prize_name
                    }
                    return val
                })
            }

            this.setState({
                prizeLists,
                exampleLists
            })
            this.updateParentExample(exampleLists)
        }, row)
    }
    handleDele = (row, index) => {
        let { prizeLists, exampleLists, no_prize } = this.state;

        prizeLists.splice(index, 1);
        let bool = exampleLists.some(val => val.prize_id == row.id);
        if (bool) {
            exampleLists = exampleLists.map(val => {
                if (val.prize_id == row.id) {
                    val.prize_id = no_prize.id
                    val.prize_img = no_prize.prize_img
                    val.prize_name=no_prize.prize_name
                }
                return val
            })
        }

        this.setState({
            prizeLists,
            exampleLists
        })
        this.updateParentExample(exampleLists)
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            no_prize = this.state.no_prize || {};
        this.Gallery.show(imgs => {
            no_prize[name] = imgs[0];
            this.setState({
                no_prize
            }, () => {
                this.freshenPrize()
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            no_prize = this.state.no_prize || {};
        if (typeof no_prize[name] == 'string') {
            no_prize[name] = ''
        } else {
            no_prize[name].splice(index, 1);
        }
        this.setState({
            no_prize
        }, () => {
            this.freshenPrize()
        })
    }

    handleReset = () => {
        let no_prize = this.state.no_prize;
        no_prize.prize_img = no_prize_img;
        this.setState({
            no_prize
        }, () => {
            this.freshenPrize()
        })
    }
    handleChange = (value, name) => {
        let no_prize = this.state.no_prize;
        no_prize[name] = value;
        this.setState({
            no_prize
        },()=>{
            this.freshenPrize()
        })
    }
    freshenPrize = () => {
        let { exampleLists, no_prize } = this.state;
        exampleLists = exampleLists.map(val => {
            if (val.prize_id == '0') {
                val.prize_img = no_prize.prize_img
                val.prize_name=no_prize.prize_name
            }
            return val
        })
        this.setState({
            exampleLists
        })
        this.updateParentExample(exampleLists)
    }

    handleOddsChange = (e, index) => {
        const exampleLists = this.state.exampleLists
        exampleLists[index].odds_num = e;
        this.setState({
            exampleLists
        })
        this.updateParentExample(exampleLists)
    }
    handleSelect = (e, index) => {
        let { no_prize, prizeLists, exampleLists } = this.state;
        if (e == '0') {
            exampleLists[index].prize_id = '0'
            exampleLists[index].prize_img = no_prize.prize_img
            exampleLists[index].prize_name=no_prize.prize_name
        } else {
            let item = prizeLists.find(val => val.id == e);
            if (item) {
                exampleLists[index].prize_id = item.id
                exampleLists[index].prize_img = item.prize_img
                exampleLists[index].prize_name=item.prize_name
            }
        }
        this.setState({
            exampleLists
        })
        this.updateParentExample(exampleLists)
    }

    getFormData = () => {

        return new Promise((reslove, reject) => {
            let values={}
            const { no_prize,prizeLists,exampleLists} = this.state
            values.prize_info=prizeLists;
            values.fail_name=no_prize.prize_name;
            values.fail_depict=no_prize.fail_depict;
            values.fail_img=no_prize.prize_img || no_prize_img;
            values.lottery_rule=exampleLists;
    
            return reslove({ ...values })
        })
    }


    render() {

        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const data = this.props.activityData || {}
     
        const { is_edit } = this.props
        const no_prize = this.state.no_prize
        const prizeLists = this.state.prizeLists;

        const prizeSelect = [...prizeLists, no_prize]

        const columns2 = [
            {
                title: '转盘位置',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.id}</p>
                    )
                }
            },
            {
                title: '奖品',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Select disabled={rowData.id == 8} style={{ width: 200 }} value={rowData.prize_id} placeholder={'请选择'} onChange={e => { this.handleSelect(e, index) }} >
                                {prizeSelect.map(val => {
                                    return <Select.Option key={val.id} value={val.id}>{val.prize_name}</Select.Option>
                                })}
                            </Select>
                            {
                                rowData.id == 8 ?
                                    <Tooltip placement={`bottom`} title={<div>在奖品库存不足等意外情况下会导致用户未中奖，所以必须要有一个「谢谢参与」，此选项转到概率可以设置为0%</div>}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip> : null
                            }
                        </div>
                    )
                }
            },
            {
                title: '奖品图片',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <img src={rowData.prize_img} alt="" width={60} />
                    )
                }
            },
            {
                title: <Tooltip placement={`bottom`} title={<div>所有中奖概率总和需等于100%。</div>}>
                    转到概率
                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                </Tooltip>,
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <InputNumber value={rowData.odds_num} style={{ width: 120 }} min={0} max={100} precision={2}
                                formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                onChange={e => { this.handleOddsChange(e, index) }}
                            />
                        </div>
                    )
                }
            },
        ]

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>奖品设置</div>
                <Button type='primary' onClick={this.handleAddPrize} disabled={this.state.prizeLists.length >= 7} >添加奖品</Button>
                <span>（{this.state.prizeLists.length}/7）</span>
                <div className={'mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={prizeLists}
                        rowKey={(record) => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={`msg-title mgt15 mgb15`}>未中奖设置</div>
                <FormItem label={'名称'}>
                    <Input
                        style={{ width: 400 }}
                        placeholder={'请输入未中奖名称'}
                        maxLength={6}
                        value={no_prize.prize_name}
                        suffix={<span>{no_prize.prize_name ? no_prize.prize_name.length : 0}/6</span>}
                        onChange={e => { this.handleChange(e.target.value, 'prize_name') }}
                    />
                </FormItem>
                <FormItem label={`图片`} required={true}>
                    <div className={'clearfix'}>
                        {
                            no_prize.prize_img
                                ?
                                <PictureCard
                                    onRef={e => { this.PictureCard = e }}
                                    imgs={no_prize.prize_img}
                                    onDel={e => { this.delImg(e, 'prize_img') }}
                                ></PictureCard>
                                :
                                <ImgAdd
                                    onRef={e => { this.ImgAdd = e }}
                                    onClick={e => { this.chooseImg(e, 'prize_img') }}
                                ></ImgAdd>
                        }
                        <Button type="link" className='mgt60' onClick={() => { this.handleReset() }} >恢复默认</Button>
                    </div>
                    <span className={'fi-help-text'}>建议尺寸100*100px，支持jpg、png、jpeg格式。</span>
                </FormItem>
                <FormItem label={'提示语'}>
                    <Input
                        style={{ width: 400 }}
                        placeholder={''}
                        value={no_prize.fail_depict}
                        onChange={e => { this.handleChange(e.target.value, 'fail_depict') }}
                    />
                </FormItem>
                <div className={`msg-title mgt15 mgb15`}>转盘设置</div>
                <div className={'flex mgb15'}>
                    <div className="flex1">
                        <Table
                            size={`middle`}
                            columns={columns2}
                            dataSource={this.state.exampleLists}
                            rowKey={(record, index) => index}
                            pagination={false}
                        ></Table>
                    </div>
                    <div className='example_img'></div>
                </div>

                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button onClick={() => this.props.updateTab('1')}>上一步</Button>
                    <Button type='primary' className='mgl20' onClick={() => this.props.updateTab('3')}>下一步</Button>
                </FormItem>

                <div className={'no-colon mgt60 pdb50'}> </div>
                <PrizeAdd onRef={e => { this.PrizeAdd = e }}  is_disabled={data.is_disabled}  ></PrizeAdd>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
            </Form>
        )
    }
}

export default Form.create({})(PrizeForm)
