/**
 * 限时打折组件
 * @type 13
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'

const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})

class tpl_con_type13 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }
    state = {
        moduleData:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这个模块吗？`,
            okType:`danger`,
            onOk:() => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`限时折扣`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{ paddingTop:`${moduleData.content.modulePadding}px`,paddingBottom:`${moduleData.content.modulePadding}px`}} onClick={ this.handleEditModule }>
                        {
                            moduleData.content.style == 1
                                ?
                                <div className={`tpl13`}>
                                    {
                                        moduleData.content.dataset && moduleData.content.dataset.length
                                            ?
                                            <div className={`tpl13-cont`}>
                                                <div className="title">
                                                    <div className="left">
                                                        限时抢购
                                                        <div className="count-down">
                                                            0天
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="right-txt">查看更多</div>
                                                        <div className="arrow"></div>
                                                    </div>
                                                </div>
                                                {
                                                    moduleData.content.dataset[0].goods_list.map((item,index) => (
                                                        <div className={`item-list ${ moduleData.content.dataset[0].status == 1 ? `soon`:''}`} key={index}>
                                                            <div className="left" style={{ backgroundImage:`url(${item.cover_img})` }}></div>
                                                            <div className="right">
                                                                <div className="txt1">{ item.name }</div>
                                                                <div className="right-img-cont">
                                                                    <div className="price">&yen;{ item.goods_price }</div>
                                                                    <div className="arrow-cont">
                                                                        <div className="arrow-txt1">补贴</div>
                                                                        <div className="arrrow-txt2">&yen;{ item.discount_price }</div>
                                                                    </div>
                                                                    <div className="fi-txt">即将恢复</div>
                                                                </div>
                                                                <div className="right-line">
                                                                    <div className="line-left">
                                                                        <div className="left-txt1">&yen;{ item.price }</div>
                                                                        <div className="left-txt2">活动价</div>
                                                                    </div>
                                                                    {
                                                                        moduleData.content.dataset[0].status == 1
                                                                            ?
                                                                            <div className="line-right">
                                                                                <div className="right-txt1">提醒我</div>
                                                                                <div className="right-txt2">{ item.basic_tobuy_num }人想要</div>
                                                                            </div>
                                                                            :
                                                                            <div className="line-right">
                                                                                <div className="right-txt1">抢购</div>
                                                                                <div className="right-txt2">剩余{ item.stock }件</div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <div className={`tpl13-cont`}>
                                                <div className="title">
                                                    <div className="left">
                                                        限时抢购
                                                        <div className="count-down">
                                                            0天
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="right-txt">查看更多</div>
                                                        <div className="arrow"></div>
                                                    </div>
                                                </div>
                                                <div className="item-list">
                                                    <div className="left" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                    <div className="right">
                                                        <div className="txt1">示例活动商品名称</div>
                                                        <div className="right-img-cont">
                                                            <div className="price">&yen;540</div>
                                                            <div className="arrow-cont">
                                                                <div className="arrow-txt1">补贴</div>
                                                                <div className="arrrow-txt2">&yen;100</div>
                                                            </div>
                                                            <div className="fi-txt">即将恢复</div>
                                                        </div>
                                                        <div className="right-line">
                                                            <div className="line-left">
                                                                <div className="left-txt1">&yen;300</div>
                                                                <div className="left-txt2">活动价</div>
                                                            </div>
                                                            <div className="line-right">
                                                                <div className="right-txt1">抢购</div>
                                                                <div className="right-txt2">剩余100件</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item-list">
                                                    <div className="left" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                    <div className="right">
                                                        <div className="txt1">示例活动商品名称</div>
                                                        <div className="right-img-cont">
                                                            <div className="price">&yen;540</div>
                                                            <div className="arrow-cont">
                                                                <div className="arrow-txt1">补贴</div>
                                                                <div className="arrrow-txt2">&yen;100</div>
                                                            </div>
                                                            <div className="fi-txt">即将恢复</div>
                                                        </div>
                                                        <div className="right-line">
                                                            <div className="line-left">
                                                                <div className="left-txt1">&yen;300</div>
                                                                <div className="left-txt2">活动价</div>
                                                            </div>
                                                            <div className="line-right">
                                                                <div className="right-txt1">抢购</div>
                                                                <div className="right-txt2">剩余100件</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item-list soon">
                                                    <div className="left" style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                    <div className="right">
                                                        <div className="txt1">示例活动商品名称</div>
                                                        <div className="right-img-cont">
                                                            <div className="price">&yen;540</div>
                                                            <div className="arrow-cont">
                                                                <div className="arrow-txt1">补贴</div>
                                                                <div className="arrrow-txt2">&yen;100</div>
                                                            </div>
                                                            <div className="fi-txt">即将恢复</div>
                                                        </div>
                                                        <div className="right-line">
                                                            <div className="line-left">
                                                                <div className="left-txt1">&yen;300</div>
                                                                <div className="left-txt2">活动价</div>
                                                            </div>
                                                            <div className="line-right">
                                                                <div className="right-txt1">提醒我</div>
                                                                <div className="right-txt2">100人想要</div>
                                                            </div>
                                                        </div>
                                                        <div className="right-fi-txt">限量3000件</div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                :
                                null
                        }
                        {
                            moduleData.content.style  == 2
                                ?
                                <div className={`tpl13`}>
                                    {
                                        moduleData.content.dataset && moduleData.content.dataset.length
                                            ?
                                            <div className={`tpl13-cont`}>
                                                <div className="title">
                                                    <div className="left">
                                                        限时抢购
                                                        <div className="count-down">
                                                            0天
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="right-txt">查看更多</div>
                                                        <div className="arrow"></div>
                                                    </div>
                                                </div>
                                                <div className={`tpl13-style2`}>
                                                    <div className={`flex-cont`}>
                                                        {
                                                            moduleData.content.dataset[0].goods_list.map((item,index) => (
                                                                <div className={`item-list-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`} key={index}>
                                                                    <div className="top-img" style={{ backgroundImage:`url(${item.cover_img})` }} ></div>
                                                                    <div className="item-desc">
                                                                        <div className="title">{ item.name }</div>
                                                                        <div className="sub-title">{ moduleData.content.showSubTitle ? item.subtitle : '' }</div>
                                                                        <div className="price-group">
                                                                            <div className="price">
                                                                                &yen;
                                                                                <span>{ CX.dealPrice(item.price)[0] }</span>
                                                                                .{ CX.dealPrice(item.price)[1] }
                                                                            </div>
                                                                            <div className={`cart-style3 ${ moduleData.content.dataset[0].status == 1 ? `soon` : ''} `}>购买</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className={`tpl13-cont`}>
                                                <div className="title">
                                                    <div className="left">
                                                        限时抢购
                                                        <div className="count-down">
                                                            0天
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>:
                                                            <div className="count">00</div>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="right-txt">查看更多</div>
                                                        <div className="arrow"></div>
                                                    </div>
                                                </div>
                                                <div className={`tpl13-style2`}>
                                                    <div className={`flex-cont`}>
                                                        <div className={`item-list-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`}>
                                                            <div className="top-img noImg"></div>
                                                            <div className="item-desc">
                                                                <div className="title">这里显示商品标题，最多2行字</div>
                                                                <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                                <div className="price-group">
                                                                    <div className="price">
                                                                        &yen;
                                                                        <span>99</span>
                                                                        .00
                                                                    </div>
                                                                    <div className={`cart-style3`}>购买</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`item-list-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`}>
                                                            <div className="top-img noImg"></div>
                                                            <div className="item-desc">
                                                                <div className="title">这里显示商品标题，最多2行字</div>
                                                                <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                                <div className="price-group">
                                                                    <div className="price">
                                                                        &yen;
                                                                        <span>99</span>
                                                                        .00
                                                                    </div>
                                                                    <div className={`cart-style3`}>购买</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`item-list-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`}>
                                                            <div className="top-img noImg"></div>
                                                            <div className="item-desc">
                                                                <div className="title">这里显示商品标题，最多2行字</div>
                                                                <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                                <div className="price-group">
                                                                    <div className="price">
                                                                        &yen;
                                                                        <span>99</span>
                                                                        .00
                                                                    </div>
                                                                    <div className={`cart-style3`}>购买</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`item-list-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`}>
                                                            <div className="top-img noImg"></div>
                                                            <div className="item-desc">
                                                                <div className="title">这里显示商品标题，最多2行字</div>
                                                                <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                                <div className="price-group">
                                                                    <div className="price">
                                                                        &yen;
                                                                        <span>99</span>
                                                                        .00
                                                                    </div>
                                                                    <div className={`cart-style3`}>购买</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>

                                :
                                null
                        }
                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type13)

