import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Icon, Row } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import message from '@/utils/message'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
const FormItem = Form.Item
const { TextArea } = Input

class AddMeal extends React.Component{
    state = {
        formData:{},
        title:'新增套餐',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        let data = this.props.data || ''
        if(data){
            data.suit_stores = data.suit_store_ids;
            for(var i in data.suit_stores){
                data.suit_stores[i] = Number(data.suit_stores[i])
            }

        }
        this.setState({
            visible:true,
            callback : callback || null,
            formData: data || {}
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            formData = this.state.formData || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                res = values;
                res.cover = formData.cover || '';
                this.close()
                if(typeof callback == 'function'){
                    callback(res)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover':
                    formData[name] = imgs[0];
                    break;
                default:
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    render() {
        const storeLists = this.props.storeLists || []
        const data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`适用门店`}>
                        {getFieldDecorator('suit_store_type', {
                            initialValue: data.suit_store_type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择适用门店'
                                }
                            ]
                        })(
                            <Radio.Group>
                                <Row className={`mgt10 mgb10`}>
                                    <Radio value={1}>全部门店</Radio>
                                </Row>
                                <Row>
                                    <Radio value={2}>
                                        部分门店
                                        {
                                            getFieldValue('suit_store_type') == 2
                                                ?
                                                getFieldDecorator('suit_stores',{
                                                    initialValue: data.suit_stores || [],
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择门店'
                                                        }
                                                    ]
                                                })(
                                                    <Select placeholder={'请选择'}  mode="multiple" className={`mgl10`} style={{ width:'200px' }}>
                                                        {
                                                            storeLists.map((item,index) => (
                                                                <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                )
                                                :
                                                null
                                        }

                                    </Radio>
                                </Row>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={`套餐名称`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入名称'
                                    }
                                ]
                            })(
                                <Input placeholder={`套餐名称`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`套餐图片`} required={true}>
                        <div className={'clearfix'}>
                            {
                                data.cover
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.cover }
                                        onDel={ e => { this.delImg(e,'cover') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'cover') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={`fi-help-text`}>建议尺寸：750x750像素，单张不超过500k</span>
                    </FormItem>
                    <FormItem label={`套餐内容`}>
                        {
                            getFieldDecorator('content',{
                                initialValue: data.content || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入内容'
                                    }
                                ]
                            })(
                                <TextArea placeholder={`套餐内容`} rows={8}></TextArea>
                            )
                        }
                    </FormItem>

                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddMeal))
