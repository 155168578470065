import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Checkbox, Input, InputNumber, Table, Switch, Radio, message, Divider, Tooltip, Icon } from 'antd'
import Loading from '@/components/Loading'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import SignDaysPrize from './components/SignDaysPrize'
import { getSignConfig, setSignConfig,setSignConfigStatus } from '@/api/user.js';
import '@/assets/css/user/sign-in.scss';
const FormItem = Form.Item

class UserSignIn extends React.Component {

    state = {
        formData: {},
        isLoading: false,
        columns: [
            {
                title: '券名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '券类型',
                dataIndex: 'coupon_type',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex: 'effect_time',
                render: (text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML={{ __html: rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title: '赠送数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <InputNumber
                            style={{ width: 80 }}
                            min={1}
                            precision={0}
                            placeholder={`张`}
                            value={rowData.num || ``}
                            onChange={e => { this.handleCouponNum(e, rowData, index) }}
                        />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => { this.handleDelItem(rowData, index) }}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ],
        prizeColumns: [
            {
                title: '连续天数',
                dataIndex: 'days',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.days}</p>
                    )
                }
            },
            {
                title: '连签额外奖励',
                dataIndex: 'prize',
                render: (text, rowData, index) => {

                    return (
                        <p className={`lh25`}>{rowData.name || '-'}</p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => { this.handleUpdatePrize(rowData, index) }}
                            >更换</Button>
                            <Divider type="vertical" />
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => { this.handleDelPrize(rowData, index) }}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ],
        id:0
    }

    componentDidMount() {
        this.fetchData()
    }


    handleCoupon = () => {
        let formData = this.state.formData || {}
        this.CouponPicker.show(res => {
            if (res.length > 5) {
                res.length = 5;
            }
            formData.present_coupon_data = res;
            this.setState({
                formData
            })
        })
    }
    handleCouponNum = (e, rowData, index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data;
        present_coupon_data[index]['num'] = e;
        this.setState({
            formData
        })
    }
    handleDelItem = (rowData, index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data;
        present_coupon_data.splice(index, 1);
        this.setState({
            formData
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleAddDaysPrize = () => {
        this.SignDaysPrize.show((res) => {

            let formData = this.state.formData,
                daysPrizeList = formData.daysPrizeList || [];

            if (daysPrizeList.some(val => val.days == res.days)) {
                return message.warning("已添加过相同天数奖励！")
            }

            daysPrizeList.push(res);
            daysPrizeList.sort((a, b) => a.days - b.days);
            formData.daysPrizeList = daysPrizeList

            this.setState({
                formData
            })
        })
    }

    handleUpdatePrize = (rowData, index) => {
        this.SignDaysPrize.show((res) => {
            let formData = this.state.formData,
                daysPrizeList = formData.daysPrizeList || [];

            if (rowData.days == res.days) {
                daysPrizeList[index] = res
            } else {
                if (daysPrizeList.some(val => val.days == res.days)) {
                    return message.warning("已有相同天数奖励！")
                }

                daysPrizeList.push(res);
                daysPrizeList.sort((a, b) => a.days - b.days);
            }

            formData.daysPrizeList = daysPrizeList
            this.setState({
                formData
            })
        }, rowData)
    }

    handleDelPrize = (rowData, index) => {
        let formData = this.state.formData,
            daysPrizeList = formData.daysPrizeList;
        daysPrizeList.splice(index, 1);
        this.setState({
            formData
        })
    }

    InputNumberChange = (e, name) => {
        let formData = this.state.formData;
        formData[name] = e;
        this.setState({
            formData
        })
    }

    increasePrizeChange = (e, name) => {
        let formData = this.state.formData || {},
            increase_prize = formData.increase_prize || {};
        increase_prize[name] = e;
        formData.increase_prize = increase_prize;
        this.setState({
            formData
        })
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })
        getSignConfig({}).then(res => {
            if (res.code == 1) {
                console.log(res);
                if (res.data.config) {
                    let { is_present_points,
                        is_present_exp,
                        is_present_coupon,
                        increase_prize_status,
                        increase_prize = {},
                        special_prize_status,
                        special_prize = [],
                        id
                    } = res.data.config,
                        formData = {
                            base_prize: [],
                        };
                    if (is_present_points) formData.base_prize.push(1);

                    if (is_present_exp) formData.base_prize.push(2);

                    if (is_present_coupon) formData.base_prize.push(3);

                    if (increase_prize_status) {
                        formData.increase_prize_arr = []
                        if (increase_prize.is_present_points) formData.increase_prize_arr.push(1);
                        if (increase_prize.is_present_exp) formData.increase_prize_arr.push(2);
                    }

                    if (special_prize_status) {
                        formData.daysPrizeList = special_prize.map(val => {
                            let item = {}
                            item.prize = []
                            if (val.is_present_points) item.prize.push(1);
                            if (val.is_present_exp) item.prize.push(2);
                            if (val.is_present_coupon) item.prize.push(3);
                            item.days = val.sign_day;
                            item.points = val.present_points;
                            item.grow_up_value = val.present_exp;
                            item.present_coupon_data = val.present_coupon_data;
                            item.name = val.name
                            return item
                        })
                    }

                    formData = { ...res.data.config, ...formData }

                    this.setState({
                        formData,
                        id
                    })
                }
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    changeSwitch=checked=>{
        
        if(!this.state.id){
            return
        }

        let sendData = {};
        sendData['status']=checked?1:0;
        setSignConfigStatus(sendData).then(res => {
            if (res.code == 1) {
               
            }
        })
    }

    saveData = () => {
        let formData = this.state.formData, sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                let { status,
                    base_prize,
                    increase_prize_status,
                    special_prize_status,
                    desc,
                    sign_notice,
                    is_cover,
                    share_title = "",
                    increase_prize_arr,
                    repeat_status
                } = values;

                let { present_points, present_exp, present_coupon_data, increase_prize, daysPrizeList, cover_img, share_img } = formData;

                increase_prize_arr = increase_prize_arr ?? [];
                present_coupon_data = present_coupon_data ?? [];
                increase_prize = increase_prize ?? {};
                daysPrizeList = daysPrizeList ?? [];

                sendData = {
                    status: +status,
                    increase_prize_status: +increase_prize_status,
                    special_prize_status: +special_prize_status,
                    sign_notice: +sign_notice,
                    is_cover,
                    share_title,
                    desc
                }

                sendData.repeat_status = repeat_status ? 1 : 0;


                sendData.is_present_points = base_prize.includes(1) ? 1 : 0;
                sendData.present_points = present_points || 0;

                sendData.is_present_exp = base_prize.includes(2) ? 1 : 0;
                sendData.present_exp = present_exp || 0;

                sendData.is_present_coupon = base_prize.includes(3) ? 1 : 0;
                sendData.present_coupon_data = present_coupon_data.map(item => {
                    return { id: item.id, name: item.name, coupon_type: item.coupon_type, effect_time: item.effect_time, num: item.num }
                })



                if (increase_prize_status) {

                    increase_prize.is_present_points = increase_prize_arr.includes(1) ? 1 : 0;
                    increase_prize.is_present_exp = increase_prize_arr.includes(2) ? 1 : 0;
                    sendData.increase_prize = increase_prize
                }

                if (special_prize_status) {

                    sendData.special_prize = daysPrizeList.map(val => {
                        let item = {}
                        item.sign_day = val.days
                        item.is_present_points = val.prize.includes(1) ? 1 : 0;
                        item.is_present_exp = val.prize.includes(2) ? 1 : 0;
                        item.is_present_coupon = val.prize.includes(3) ? 1 : 0;
                        item.present_points = val.points;
                        item.present_exp = val.grow_up_value;
                        item.present_coupon_data = val.present_coupon_data.map(coupon => {
                            return { id: coupon.id, name: coupon.name, coupon_type: coupon.coupon_type, effect_time: coupon.effect_time, num: coupon.num }
                        })

                        item.name = val.name

                        return item
                    })
                }else{
                    sendData.special_prize =[]
                }

                sendData.share_img = share_img || ""
                sendData.cover_img = cover_img || ""



                this.setState({
                    isLoading: true
                })
                setSignConfig(sendData).then(res => {
                    if (res.code == 1) {
                        message.success('保存成功')
                    }
                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })

            }
        })
    }



    render() {
        const data = this.state.formData || {};
        const increase_prize = data.increase_prize || {};

        let default_desc = `1.每日签到可以获得日签奖励，连续签到可获得额外奖励，当超出连续签到天数后会重新计算连续天数，继续赠送额外奖励；\n2.每日最多可签到1次，断签则会重新计算连签天数；\n3.活动以及奖励最终解释权归商家所有。`;

        let defualt_img = 'https://img.cxkoo.com/chengxuan/1/2022-11-14/46e1bb89486c628813126d79f2491681.png';

        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        return (
            <div className={`shadow-radius`}>
                <CouponPicker
                    onRef={e => { this.CouponPicker = e }}
                    multiple={true}
                    selectIds={data.present_coupon_data && data.present_coupon_data.map(item => { return Number(item.id) })}
                ></CouponPicker>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <SignDaysPrize onRef={e => { this.SignDaysPrize = e }} ></SignDaysPrize>

                <Form {...formItemLayout} className={'model-form '}>
                    <div className={`sysPanel`}>
                        <div className={`sysPanel-con`}>
                            <div className={`sysPanel-title`}>签到活动</div>
                            <div className={`sysPanel-tip`}>开启后，用户端可以参与签到的奖励活动</div>
                        </div>
                        <div className={`list-switch`}>
                            {getFieldDecorator('status', {
                                initialValue: !!data.status,
                                valuePropName: 'checked'
                            })(
                                <Switch onChange={this.changeSwitch}></Switch>
                            )}
                        </div>
                    </div>
                    <div className={`msg-title mgb15`}>每日签到固定奖励</div>
                    <FormItem label={'奖励设置'}>
                        {getFieldDecorator('base_prize', {
                            initialValue: data.base_prize ?? [],
                            rules: [
                                {
                                    required: true,
                                    message: '请每日签到固定奖励'
                                }
                            ]
                        })(
                            <Checkbox.Group >
                                <Checkbox value={1}>积分&nbsp;&nbsp;&nbsp;
                                    送 <InputNumber style={{ width: 80 }} value={data.present_points || undefined} min={1} precision={0} onChange={e => { this.InputNumberChange(e, 'present_points') }} /> 积分
                                </Checkbox>
                                <br />
                                <Checkbox value={2} className='mgt10'>成长值&nbsp;&nbsp;&nbsp;
                                    送 <InputNumber style={{ width: 80 }} value={data.present_exp || undefined} min={1} precision={0} onChange={e => { this.InputNumberChange(e, 'present_exp') }} /> 成长值
                                </Checkbox>
                                <br />
                                <Checkbox value={3} className='mgt10' >优惠券&nbsp;&nbsp;&nbsp;
                                    <Button type="primary" ghost size='small' onClick={this.handleCoupon} >添加券</Button>
                                    <span className={`fi-help-text`}>优惠券最多设置 5 张，优惠券数量建议设置无限数量，如领完可能导致后续签到将无奖励发放。</span>
                                </Checkbox>
                            </Checkbox.Group>
                        )}

                        {
                            data.present_coupon_data && data.present_coupon_data.length
                                ?
                                <Table
                                    size={`middle`}
                                    columns={this.state.columns}
                                    dataSource={data.present_coupon_data}
                                    rowKey={record => record.id}
                                    style={{ width: '700px' }}
                                ></Table>
                                :
                                null
                        }

                    </FormItem>

                    <div className={`msg-title mgb15`}>连续签到额外奖励</div>
                    <FormItem label={'递增奖励'}>
                        {getFieldDecorator('increase_prize_status', {
                            initialValue: !!data.increase_prize_status,
                            valuePropName: 'checked',
                        })(
                            <Switch />
                        )}
                        <Tooltip placement={`right`} title={`客户连续签到第二天起，开始递增奖励，若断签则从头计算。例如:固定签到每次积分为2积分，此处勾选第二天起每天递增奖励1积分，5天后不再递增，则客户第一天签到积分为2积分，第二天连续签到则为2+1共3积分，第三天得2+2共4积分，第四天为2+3共5积分，第五天为2+4共6积分，第六天为2+5共7积分，第七、第八天为7积分......，若断签，则额外的积分从头计算。`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                        <span className={'fi-help-text'}>如每日固定奖励未设置积分或成长值奖励，则该设置项无法开启。</span>
                    </FormItem>

                    {
                        getFieldValue("increase_prize_status") ?
                            <FormItem label={' '} className='no-colon'>
                                {getFieldDecorator('increase_prize_arr', {
                                    initialValue: data.increase_prize_arr ?? []
                                })(
                                    <Checkbox.Group >
                                        <Checkbox value={1}>积分&nbsp;&nbsp;&nbsp;
                                            第二天起递增奖励 <InputNumber style={{ width: 80 }} min={1} precision={0} onChange={e => { this.increasePrizeChange(e, 'present_points') }} value={increase_prize.present_points || undefined} /> 积分
                                            <InputNumber className='mgl20' style={{ width: 80 }} min={1} precision={0} onChange={e => { this.increasePrizeChange(e, 'points_stop') }} value={increase_prize.points_stop || undefined} /> 天后，不再递增
                                        </Checkbox>
                                        <br />
                                        <Checkbox value={2} className='mgt10'>成长值&nbsp;&nbsp;&nbsp;
                                            第二天起递增奖励 <InputNumber style={{ width: 80 }} min={1} precision={0} onChange={e => { this.increasePrizeChange(e, 'present_exp') }} value={increase_prize.present_exp || undefined} /> 成长值
                                            <InputNumber className='mgl20' style={{ width: 80 }} min={1} precision={0} onChange={e => { this.increasePrizeChange(e, 'exp_stop') }} value={increase_prize.exp_stop || undefined} /> 天后，不再递增
                                        </Checkbox>
                                    </Checkbox.Group>
                                )}
                            </FormItem> : null
                    }

                    <FormItem label={'指定天数奖励'}>
                        {getFieldDecorator('special_prize_status', {
                            initialValue: !!data.special_prize_status,
                            valuePropName: 'checked',
                        })(
                            <Switch />
                        )}
                        <Tooltip placement={`right`} title={`客户到达指定的天数后，在该时间点，获得额外赠送奖励，若断签则从头计算。
                         例如：商家设置连续签到第7天送优惠券A，连续签到第12天得100积分；客户连续签到第7天得固定奖励+A，第8天得固定奖励，第12天得固定奖励+100积分，第13天得固定奖励，若开启超出天数后继续发放奖励，则第19天得固定奖励+A，若断签，则重新计算连续天数，可再得相应奖励。`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                        <br />
                        {
                            getFieldValue("special_prize_status") ?
                                <>
                                    {
                                        data.daysPrizeList && data.daysPrizeList.length
                                            ?
                                            <Table
                                                size={`middle`}
                                                columns={this.state.prizeColumns}
                                                dataSource={data.daysPrizeList}
                                                rowKey={record => record.days}
                                                style={{ width: '700px' }}
                                            ></Table>
                                            :
                                            null
                                    }
                                    <Button type="primary" ghost size='small' icon={'plus'} onClick={this.handleAddDaysPrize} >添加规则</Button>
                                </> : null
                        }

                    </FormItem>

                    {
                        getFieldValue("special_prize_status") ?
                            <FormItem label={'连签设置'}>
                                {getFieldDecorator('repeat_status', {
                                    initialValue: !!data.repeat_status,
                                    valuePropName: 'checked',
                                })(
                                    <Switch />
                                )}
                                <span className={'fi-help-text'}>未开启，连签超出指定签到天数后，不再继续赠送额外奖励。开启后，则重新计算天数，继续赠送奖励。</span>
                            </FormItem> : null
                    }



                    <div className={`msg-title mgb15`}>活动设置</div>
                    <FormItem label={'活动规则'}>
                        {getFieldDecorator('desc', {
                            initialValue: data.desc || default_desc
                        })(<Input.TextArea
                            autoSize={true}
                            style={{ width: 400 }}
                            rows={4}
                            placeholder={'请输入活动规则'}
                        />)}
                    </FormItem>

                    <FormItem label={'签到提醒'}>
                        {getFieldDecorator('sign_notice', {
                            initialValue: !!data.sign_notice,
                            valuePropName: 'checked',
                        })(
                            <Switch />
                        )}
                        <span className={'fi-help-text'}>开启后用户可主动订阅签到提醒。订阅后，将于每天20:00向未签到的订阅用户推送微信模板消息</span>
                    </FormItem>

                    <FormItem label={"活动页面背景"} >
                        {getFieldDecorator('is_cover', {
                            initialValue: data.is_cover || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择活动页面背景图'
                                }
                            ]
                        })(
                            <Radio.Group   >
                                <Radio value={1}>默认背景图 </Radio>
                                <Radio value={2}> 自定义图片</Radio>
                            </Radio.Group>
                        )}
                        <div className="preview-box">
                            <div className="preview">
                                <div>当前样式预览：</div>
                                <img src={(getFieldValue("is_cover") == 2) && data.cover_img || defualt_img} alt="" className="pre-img" />
                                <div className="pre-bg"></div>
                            </div>
                        </div>

                    </FormItem>

                    {
                        getFieldValue("is_cover") == 2 ?
                            <FormItem label={`自定义图片`}>
                                <div className={'clearfix'}>
                                    {
                                        data.cover_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={data.cover_img}
                                                onDel={e => { this.delImg(e, 'cover_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'cover_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：600x800像素，单张不超过1M</span>
                            </FormItem> : null
                    }



                    <FormItem label={'分享描述'}>
                        {getFieldDecorator('share_title', {
                            initialValue: data.share_title || undefined
                        })(<Input
                            style={{ width: 400 }}
                            placeholder={'请输入分享描述'}
                            maxLength={20}
                            suffix={<span>{getFieldValue("share_title") ? getFieldValue("share_title").length : 0}/20</span>}
                        />)}
                        <span className={'fi-help-text'}>将展示在分享链接中，最多20个字符</span>
                    </FormItem>

                    <FormItem label={`分享图片`}>
                        <div className={'clearfix'}>
                            {
                                data.share_img
                                    ?
                                    <PictureCard
                                        onRef={e => { this.PictureCard = e }}
                                        imgs={data.share_img}
                                        onDel={e => { this.delImg(e, 'share_img') }}
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={e => { this.ImgAdd = e }}
                                        onClick={e => { this.chooseImg(e, 'share_img') }}
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M，用于小程序卡片分享</span>
                    </FormItem>


                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>

                </Form>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(UserSignIn))
