/**
 * 轮播图组件
 * @type 5
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip, Tag} from 'antd'
import PropTypes from "prop-types";
import Gallery from '@/components/Gallery/Gallery'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import AddTag from '../addTag'
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 16 }}

class tpl_ctrl_type5 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }

    state = {
        moduleData:{},
        itemStyleGroup:[
            {
                name:'多图轮播',
                style:1,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            },
            {
                name:'会员加轮播',
                style:2,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            },
            {
                name:'店招加轮播',
                style:3,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    changeSpacePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.spacePadding = val;
        this.context.updateDesign(moduleData)
    }
    chooseImg = (item,index) => {
        let moduleData = this.state.moduleData;
        this.Gallery.show(res => {
            if(res){
                moduleData.content.dataset[index]['pic'] = res[0];
                let imgContext = new Image();
                imgContext.src = res[0];
                moduleData.content.dataset[index]['imgRpx'] = (imgContext.height * 750 / imgContext.width).toFixed(2);
                moduleData = this.calcSwiperHeight(moduleData)
                this.context.updateDesign(moduleData)
            }
        })

    }
    delImg = (item,index) => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这张图片吗？`,
            okType:`danger`,
            onOk:() => {
                moduleData.content.dataset.splice(index,1);
                moduleData = this.calcSwiperHeight(moduleData)
                this.context.updateDesign(moduleData)
            }
        })
    }
    calcSwiperHeight = (moduleData) => {
        let arr = []
        moduleData.content.dataset.forEach(item => {
            if(!item.imgRpx){
                item.imgRpx = 0
            }
            arr.push(parseFloat(item.imgRpx))
        })
        moduleData.content.swiperHeight = Math.max(...arr);
        return moduleData
    }
    changeLink = (e,item,index) => {
        let moduleData = this.state.moduleData;
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    moduleData.content.dataset[index][i] = res[i];
                }
                this.context.updateDesign(moduleData)
            }
        })
    }
    handleAdd = () => {
        let moduleData = this.state.moduleData,
            newData = { linkType:0, link:'', pic:'' };
        moduleData.content.dataset.push(newData);
        this.context.updateDesign(moduleData)
    }
    handleStyleChange = (e,name) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData)

    }
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)

    }
    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }
    handleAddTag = () => {
        let moduleData = this.state.moduleData,
            tags = moduleData.content.tags;
        this.AddTag.show(res => {
            if(res.name && tags.length < 5){
                tags.push(res.name)
                moduleData.content.tags = tags;
                this.context.updateDesign(moduleData)
            }
        })
    }
    delTag = (index) => {
        let moduleData = this.state.moduleData,
            tags = moduleData.content.tags;
        tags.splice(index,1);
        moduleData.content.tags = tags;
        this.context.updateDesign(moduleData)
    }
    handleUp = (item,index) => {
        let moduleData = this.state.moduleData;
        if(index == 0){ return ; }
        let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
        moduleData.content.dataset.splice(index, 1);
        moduleData.content.dataset.splice(index - 1, 0, tmpdata);
        this.context.updateDesign(moduleData)
    }
    handleDown = (item,index) => {
        let moduleData = this.state.moduleData,
            len = moduleData.content.dataset.length;
        if(index == len - 1){ return ; }
        let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
        moduleData.content.dataset.splice(index, 1);
        moduleData.content.dataset.splice(index + 1, 0, tmpdata);
        this.context.updateDesign(moduleData)
    }
    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>轮播图</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`轮播图样式`}>
                                    <ul className={`template-box`}>
                                        {
                                            this.state.itemStyleGroup.map((item,index) => (
                                                <Tooltip effect="dark" placement="bottom" title={ item.name } key={index}>
                                                    <li className={`temp ${ moduleData.content.style == item.style ? 'selected' : '' }`} onClick={ () => { this.handleItemStyle(item.style) } }>
                                                        {
                                                            moduleData.content.style == item.style
                                                                ?
                                                                <img src={ item.imgs[0] } className={`img`} alt=""/>
                                                                :
                                                                <img src={ item.imgs[1] } className={`img`} alt=""/>

                                                        }
                                                    </li>
                                                </Tooltip>
                                            ))
                                        }
                                    </ul>
                                </FormItem>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                {
                                    moduleData.content.style == 1
                                        ?
                                        <FormItem label={`左右间距`}>
                                            <div className={`cx-slider`}>
                                                <Slider
                                                    min={0}
                                                    max={20}
                                                    value={moduleData.content.spacePadding || 0}
                                                    onChange={ this.changeSpacePadding }
                                                    className={`cx-slider-main`}
                                                ></Slider>
                                                <InputNumber
                                                    min={0}
                                                    max={20}
                                                    style={{ width:60 }}
                                                    value={moduleData.content.spacePadding || 0}
                                                    onChange={ this.changeSpacePadding }
                                                />
                                            </div>
                                        </FormItem>
                                        :
                                        null
                                }
                                <FormItem label={`边角样式`}>
                                    <Radio.Group value={moduleData.content.borderStyle} onChange={(e) => { this.handleStyleChange(e,'borderStyle') }} >
                                        <Radio value={1}>直角</Radio>
                                        <Radio value={2}>圆角</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`轮播间隔`}>
                                    <Radio.Group value={moduleData.content.interval} onChange={(e) => { this.handleStyleChange(e,'interval') }} >
                                        <Radio value={3000}>3s</Radio>
                                        <Radio value={5000}>5s</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`轮播尺寸`}>
                                    <Radio.Group value={moduleData.content.sizeStyle} onChange={(e) => { this.handleStyleChange(e,'sizeStyle') }} >
                                        <Radio value={1}>自定义</Radio>
                                        <Radio value={2}>1：1</Radio>
                                        <Radio value={3}>5：3</Radio>
                                    </Radio.Group>
                                </FormItem>
                                {
                                    moduleData.content.style == 3
                                        ?
                                        <div>
                                            <FormItem label={`门店地址`}>
                                                <Radio.Group value={moduleData.content.showAddress} onChange={(e) => { this.handleStyleChange(e,'showAddress') }} >
                                                    <Radio value={1}>显示</Radio>
                                                    <Radio value={2}>不显示</Radio>
                                                </Radio.Group>
                                            </FormItem>
                                            <FormItem label={`背景颜色`} { ...ctrlItemLayout }>
                                                <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.bgColor} callback={ (color) => { this.handleColorChange(color,'bgColor') } } ></ColorPicker>
                                                <Button type="link" onClick={()=>this.handleResetColor('bgColor','#4F0201')}>重置</Button>
                                            </FormItem>
                                            <FormItem label={`文字颜色`} { ...ctrlItemLayout }>
                                                <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.txtColor} callback={ (color) => { this.handleColorChange(color,'txtColor') } } ></ColorPicker>
                                                <Button type="link" onClick={()=>this.handleResetColor('txtColor','#ffffff')}>重置</Button>
                                            </FormItem>
                                            <FormItem label={`门店标签`} { ...ctrlItemLayout }>
                                                {
                                                    moduleData.content.tags.map((tag,tagIndex) => (
                                                        <Tag className={`lh30`} key={tagIndex}>
                                                            { tag }
                                                            <Icon type="close" className={'tagclose'} onClick={() => {this.delTag(tagIndex)}} />
                                                        </Tag>
                                                    ))
                                                }

                                                <span className={`fi-help-text lh30`}>最多5个，字数限制8个字</span>
                                            </FormItem>
                                            <Button type={`primary`} className={`w100`} onClick={this.handleAddTag} disabled={moduleData.content.tags.length >= 5} >添加标签</Button>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>添加图片</div>
                            <div className={`cx-ctrl-panel--box`}>
                                {
                                    moduleData.content.dataset.map((item,index) => (
                                        <div className={`cx-img-list`} key={index}>
                                            <div className={`cx-img-item clearfix`}>
                                                <FormItem label={`选择图片`} {...ctrlItemLayout}>
                                                    <div className={'clearfix'}>
                                                        <div className={'img-add'} onClick={() => { this.chooseImg(item,index) }} data-name="pic">
                                                            {
                                                                item.pic
                                                                    ?
                                                                    <div>
                                                                        <img src={item.pic} alt=""/>
                                                                    </div>
                                                                    :
                                                                    '+'
                                                            }
                                                        </div>
                                                    </div>
                                                </FormItem>
                                                <FormItem label={`链接到`} {...ctrlItemLayout}>
                                                    <Input type={`text`} value={item.link} onClick={ (e) => { this.changeLink(e,item,index) } } placeholder={`请选择`}  />
                                                </FormItem>
                                            </div>
                                            <div className="ctrl-item-list-actions">
                                                <i className={`gicon-arrow-up`} onClick={ () => { this.handleUp(item,index) }}></i>
                                                <i className={`gicon-arrow-down`} onClick={ () => { this.handleDown(item,index) }}></i>
                                            </div>
                                            {
                                                index > 0
                                                    ?
                                                    <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delImg(item,index) }} data-name={'pic'}  />
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))
                                }
                                <Button type={`primary`} className={`w100`} onClick={this.handleAdd} disabled={moduleData.content.dataset.length >= 5} >添加图片</Button>
                            </div>
                        </div>
                    </Form>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                    <AddTag onRef={ e => { this.AddTag = e } }></AddTag>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type5)

