import React from 'react'
import { Form, Input, Radio, TimePicker, Button, Icon, Upload, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import moment from 'moment';
import '@/assets/css/store/store.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Mapper from '@/components/Mapper/Mapper'
import PropTypes from "prop-types";
import { addStore, storeDetail, storeEdit } from '@/api/store'
import message from '@/utils/message'
const FormItem = Form.Item
class EditForm extends React.Component {
    static contextTypes = {
        loading: PropTypes.object,
        setLoading: PropTypes.func
    }

    state = {
        formData: '',
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }

    fetchData = (params = {}) => {
        let sendData = {}
        if(this.props.match.params.id){
            this.context.setLoading(true)
            sendData.id = this.props.match.params.id
            storeDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.detail;
                        this.setState({
                            formData:detail
                        })
                    }
                })
                .finally(() => {
                    this.context.setLoading(false)
                })
        }

    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'logo':
                    formData[name] = imgs[0];
                    break;
                case 'imgs':
                    formData['imgs'] = formData['imgs'] || []
                    formData['imgs'] = formData['imgs'].concat(imgs);
                    if(formData['imgs'].length > 10){
                        formData['imgs'] = formData['imgs'].slice(0,10)
                    }
                    break;
                case 'base_certificate_info':
                    formData['base_certificate_info'] = formData['base_certificate_info'] || []
                    formData['base_certificate_info'] = formData['base_certificate_info'].concat(imgs);
                    if(formData['base_certificate_info'].length > 3){
                        formData['base_certificate_info'] = formData['base_certificate_info'].slice(0,3)
                    }
                    break;


            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleSort = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if(oldIndex == newIndex){
            return ;
        }
        let targetRow = urls.splice(oldIndex,1)[0];
        urls.splice(newIndex,0,targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    showMap = e => {
        this.Mapper.show(res => {
            let formData = this.state.formData || {};
            for(var i in res){
                formData[i] = res[i]
            }
            this.props.form.setFieldsValue({
                address: res.address || '',
            });
            this.setState({
                formData
            })
        })
    }
    handleOnLine = () => {}
    changeTime = (e, name) => {
        let formData = this.state.formData || {},
            value = e ? moment(e).format('HH:mm:ss') : '';


        formData[name] = value;
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {}
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
                for(var i in this.state.formData){
                    if(!sendData[i]){
                        sendData[i] = this.state.formData[i];
                    }
                }
                this.context.setLoading(true)
                if(this.props.match.params.id){
                    sendData.id = this.props.match.params.id
                    storeEdit(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('门店编辑成功')
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/store/lists`
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })

                }else{
                    addStore(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('门店创建成功')
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/store/lists`
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })
                }
            }
        })
    }


    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return (
            <div>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Mapper onRef={e => { this.Mapper = e }} lat={data.lat || ''} lng={data.lng || ''} address={data.address || ''}></Mapper>
                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`msg-title mgb15`}>门店基本信息</div>
                    <FormItem label={'门店名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data && data.name,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入门店名称'
                                }
                            ]
                        })(<Input
                            placeholder={'请输入名称，限30字符'}
                            maxLength={30}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/30</span>}
                        />)}
                    </FormItem>
                    <FormItem label={'门店Logo'} required={true}>
                        <div className={'clearfix'}>
                            {
                                data.logo
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.logo }
                                        onDel={ e => { this.delImg(e,'logo') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'logo') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text mgt0'}>建议上传尺寸：300px*300px</span>
                    </FormItem>
                    <FormItem label={'门店图片'} required={true}>
                        <div className={'clearfix'}>
                            {
                                data.imgs && data.imgs.length
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.imgs }
                                        onDel={ e => { this.delImg(e,'imgs') } }
                                        onSort={ e => { this.handleSort(e,'imgs') } }
                                    ></PictureCard>
                                    :
                                    null
                            }
                            {
                                data.imgs && data.imgs.length >= 10
                                    ?
                                    null
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'imgs') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text mgt0'}>
                                至少上传一张门店实景图，最多10张
                                <br/>
                                建议上传尺寸750px * 420px
                            </span>
                    </FormItem>
                    <FormItem label={'门店资质'} required={true}>
                        <div className={`clearfix`}>
                            {
                                data.base_certificate_info && data.base_certificate_info.length
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.base_certificate_info }
                                        onDel={ e => { this.delImg(e,'base_certificate_info') } }
                                        onSort={ e => { this.handleSort(e,'base_certificate_info') } }
                                    ></PictureCard>
                                    :
                                    null
                            }
                            {
                                data.base_certificate_info && data.base_certificate_info.length >= 3
                                    ?
                                    null
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'base_certificate_info') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text mgt0'}>
                                根据新电商法的要求，请您上传门店营业执照和资质相关照片
                        </span>
                    </FormItem>
                    <FormItem label={'门店电话'}>
                        {getFieldDecorator('service_tel', {
                            initialValue: data && data.service_tel,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入门店电话'
                                }
                            ]
                        })(<Input placeholder={'请输入门店电话'} maxLength={30} />)}
                    </FormItem>
                    <FormItem label={'接收短信手机'}>
                        {getFieldDecorator('sms_mobile', {
                            initialValue: data.sms_mobile || ''
                        })(<Input placeholder={'请输入短信手机号'} maxLength={30} />)}
                        <span className={'fi-help-text'}>通知消息将以短信形式发送到该手机上</span>
                    </FormItem>
                    <FormItem label={'门店地址'}>
                        {getFieldDecorator('address', {
                            initialValue: data.address || '',
                            rules: [
                                {
                                    required: true,
                                    message: '请选择门店地址'
                                }
                            ]
                        })(<Input placeholder={'点击选择门店地址'} maxLength={30} onClick={this.showMap} />)}
                    </FormItem>
                    <div className={`msg-title mgb15`}>门店经营信息</div>
                    <FormItem label={'全天营业'}>
                        {getFieldDecorator('is_online_all_day', {
                            initialValue: data.is_online_all_day  || 0,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择营业类型'
                                }
                            ],
                        })(
                            <Radio.Group onChange={ this.handleOnLine }>
                                <Radio value={1}>是</Radio>
                                <Radio value={0}>否</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    {
                        getFieldValue('is_online_all_day') == 0
                            ?
                            <FormItem label={'营业时间'}>
                                <div className={'form-group'}>
                                    <TimePicker
                                        value={data.online_start_time ? moment(data.online_start_time, 'HH:mm:ss') : null}
                                        className={'mgr5'} onChange={(e) => {this.changeTime(e, 'online_start_time')}}>
                                    </TimePicker>
                                    至
                                    <TimePicker
                                        value={data.online_end_time ? moment(data.online_end_time, 'HH:mm:ss') : null}
                                        className={'mgl5 mgr5'} onChange={(e) => {this.changeTime(e, 'online_end_time')}}>
                                    </TimePicker>
                                </div>
                            </FormItem>
                            :
                            null
                    }
                    <div className={`msg-title mgb15`}>其他信息</div>
                    <FormItem label={'钉钉群消息'}>
                        {getFieldDecorator('dingding_access_token', {
                            initialValue: data && data.dingding_access_token,
                        })(<Input placeholder={'请输入access_token'} />)}
                        <span className={'fi-help-text'}>
                            用于顾客下单后在钉钉群内自动通知消息
                            <br/>
                            配置：智能群助手->添加机器人->安全设置->自定义关键词 如：填写“来单提醒”。
                        </span>
                    </FormItem>
                    <FormItem label={'企业微信群消息'}>
                        {getFieldDecorator('company_wechat_key', {
                            initialValue: data && data.company_wechat_key,
                        })(<Input placeholder={'请输入key'}  />)}
                        <span className={'fi-help-text'}>
                            用于顾客下单后在公司企业微信群自动通知消息
                            <br/>
                            配置：群设置-群机器人-添加后复制KEY
                        </span>
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>



                </Form>


            </div>
        )

    }
}
export default Form.create({})(withRouter(EditForm))


