import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Table, Pagination, Divider, Select, Switch, Modal, message ,Icon} from 'antd'

import Loading from '@/components/Loading'
import CourseCategoryModal from './components/courseCategoryModal'
import { CourseCategoryApi, CourseCategoryDeleteApi ,CourseCategorySaveApi} from '@/api/course'
const FormItem = Form.Item

class CourseCategory extends Component {
    state = {
        columns: [
            {
                title: '课程类目',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <span>{rowData.name || '--'}</span>
                    )
                }
            },
            {
                title: '关联课数',
                dataIndex: '2',
                width: '20%',
                render: (text, rowData, index) => {
                    let num = 0
                    if (rowData.children) {
                        rowData.children.forEach(v => {
                            num += Number(v.course_count)
                        })
                    } else {
                        num = rowData.course_count
                    }
                    return (
                        <div className={`lh25`}>
                            <p>{num}</p>
                        </div>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: '3',
                width: '20%',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sort_edit
                                    ?
                                    <Input
                                        style={{ width: '80px' }}
                                        value={rowData.sort}
                                        onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                        onBlur={(e) => { this.handleField(e, rowData, index) }}
                                        data-name={'sort'}
                                    />
                                    :
                                    <span>{rowData.sort}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`sort`} />

                        </div>
                    )
                }
            },
            {
                title: '操作',
                width: '25%',
                dataIndex: '4',
                render: (text, rowData, index) => {

                    return (

                        <div className={`btn-row`}>
                            {
                                rowData.pid === 0 ? <Button type={`link`} size={`small`} onClick={() => { this.handleCategoryAddChild(rowData) }}>新增子类目</Button> : null
                            }
                            {
                                rowData.pid === 0 ? <Divider type={`vertical`}></Divider> : null
                            }
                            <Button type={`link`} size={`small`} onClick={() => { this.handleEdit(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={() => { this.handleDelete(rowData) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        tableLoading: false,
        modalForm: {
            title: "新增类目",
            name: "",
            sort: "",
            id: "",
            pid: ""
        },
        searchForm: {
            name: ""
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }


    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        CourseCategoryApi(sendData).then(res => {
            console.log(res);
            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                data.forEach(item => {
                    if (item.children && item.children.length < 1) {
                        delete item.children
                    }
                })
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleDelete = (row) => {
        let _this = this
        Modal.confirm({
            title: "是否确认删除？",
            onOk() {
                _this.handleOk(row.id)
            }
        })
    }
    handleOk = (id) => {
        this.setState({
            isLoading: true
        })
        CourseCategoryDeleteApi({ id }).then(res => {
            if (res.code === 1) {
                message.success(res.msg)
                this.fetchData()
            } else {
                Modal.error({
                    title: '该类目下有课程存在，不可删除！'
                });
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }


    handleModal = (form) => {
        this.setState({
            modalForm: form
        }, () => {
            this.ccmRef.show()
        })
    }

    handleCategoryAdd = () => {
        this.handleModal({
            title: "新增类目",
            name: "",
            sort: "",
            id: "",
            pid: ""
        })
    }
    handleCategoryAddChild = (row) => {
        this.handleModal({
            title: "新增子类目",
            name: "",
            sort: "",
            id: "",
            pid: row.id
        })

    }
    handleEdit = (row) => {
        let modalForm = {
            title: "编辑类目",
            name: row.name,
            sort: row.sort,
            id: row.id,
            pid: ""
        }
        if (row.pid !== 0) {
            modalForm.title = "编辑子类目"
            modalForm.pid = row.pid
        }
        this.handleModal(modalForm)
    }

    showEdit = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'sort':
                lists[index]['sort_edit'] = 1
                break;
        }
        this.setState({
            tableData: lists
        })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }

    handleField = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }

        sendData = { ...rowData }
        sendData[name] = val;
        CourseCategorySaveApi(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.fetchData();
                }
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div className={`shadow-radius`}>
                <CourseCategoryModal formData={this.state.modalForm} onRef={e => { this.ccmRef = e }} onSuccess={e => { this.fetchData() }} />
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'课程类目名称'} className={'fl'}>
                            {
                                getFieldDecorator('name', {
                                    initialValue: ''
                                })(
                                    <Input placeholder='请搜素课程类目名称' />
                                )
                            }
                        </FormItem>

                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary' onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={''}>
                    <Button type={'primary'} onClick={this.handleCategoryAdd} icon={'plus'}>新增课程类目</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(CourseCategory))
