import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input, Form, Radio } from 'antd'
import message from '@/utils/message'
const FormItem = Form.Item

class AddRoomStock extends React.Component{
    state = {
        formData:{},
        title:'设置包厢及售卖状态',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null,
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            formData = this.state.formData || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(values.type == 2){
                    values.stock = 0
                }
                this.close()
                if(typeof callback == 'function'){
                    callback(values)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let data = this.props.data || {}
        if(data.stock == 0){
            data.type = 2
        }else {
            data.type = 1
        }
        const formItemLayout = {
            labelCol: {
                span: 1
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`  `} colon={false}>
                        {getFieldDecorator('type', {
                            initialValue: data.type || 1,
                        })(
                            <Radio.Group disabled={!!this.props.match.params.id}>
                                <Radio value={1} className={`block mgb15`}>
                                    有房，可订
                                    {
                                        getFieldDecorator('stock',{
                                            initialValue: data.stock || ``
                                        })(
                                            <Input addonAfter={`间`} className={` addon-after-input mgl10 `} style={{ width:'200px' }} ></Input>
                                        )
                                    }
                                </Radio>
                                <Radio value={2} >满包</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>



                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddRoomStock))
