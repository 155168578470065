import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Modal, Tag, InputNumber, Icon, message } from 'antd'
import moment from 'moment'
import Loading from '@/components/Loading'
import TopicManage from './topic-manage'
import UpdateTopic from './updateTopic'
import VerifyNote from './verifyNote'
import ShareModal from '@/components/shareModal/shareModal'
import { articleLists, articleTopic,articleDele,articleSetTop ,articleSetTopic,articleSetStatus,articleSetField} from '@/api/recommend.js'

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
// status，0-隐藏 1-公开 2-草稿 3-待审核
// is_top：1-置顶
class NoteManage extends Component {
    state = {
        columns: [
            {
                title: '标题',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.title}</p>
                    )
                }
            },
            {
                title: '创作者',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    let author_info=rowData.author_info ||{}
                    return (
                        <p>{author_info.name} </p>
                    )
                }
            },
            {
                title: '话题',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.topic_name}</p>
                    )
                }
            },
            {
                title: '阅读量',
                width: 80,
                dataIndex: 'pv',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.pv} </p>
                    )
                }
            },
            {
                title: '点赞数',
                width: 80,
                dataIndex: 'zan',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.zan} </p>
                    )
                }
            },
            {
                title: '评论数',
                width: 80,
                dataIndex: 'comment_num',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.comment_num || 0} </p>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: 'sort',
                defaultSortOrder: '',
                sorter: true,
                width: 110,
                render: (text, rowData, index) => {
                    return (
                        <div>
                        {
                            rowData.sort_edit
                                ?
                                <Input
                                    type={'number'}
                                    style={{ width: '70px' }}
                                    value={rowData.sort}
                                    onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                    onBlur={(e) => { this.handleField(e, rowData, index) }}
                                    data-name={'sort'}
                                />
                                :
                                <span>{rowData.sort}</span>
                        }
                        <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`sort`} />
                    </div>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.publish_time ||rowData.created_at} </p>
                    )
                }
            },
            {
                title: '公开状态',
                dataIndex: '6',
                width: 90,
                render: (text, rowData, index) => {
                    let color = "", tag_text = ""

                    if (rowData.status == 0 || rowData.status == 2) {
                        color = ""
                        tag_text = "隐藏"
                    } else if (rowData.status == 3) {
                        color = "orange"
                        tag_text = "待审核"
                    } else if (rowData.status == 1) {
                        color = "green"
                        tag_text = "公开"

                        if (rowData.is_top) {
                            color = "red"
                            tag_text = "置顶"
                        }
                    }

                    return (
                        <Tag color={color}>{tag_text} </Tag>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '7',
                width: '200px',
                render: (text, rowData, index) => {

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleShare(rowData) }}>推广</Button>

                            {
                                rowData.source==1 ?
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData.id) }}>编辑</Button>
                                    </> : null
                            }

                            {
                                (rowData.source==2) && (rowData.status==3) ?
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => { this.handleCheck(rowData) }}>审核</Button>
                                    </> : null
                            }

                            {
                                (rowData.status==1) ? rowData.is_top ?
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={()=>{this.handleSetTop(rowData.id,2)}} >取消置顶</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={()=>{this.handleShowNote(rowData.id,0,'是否确认隐藏，隐藏后将在小程序端不可见')}}>隐藏</Button>
                                    </> :
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={()=>{this.handleSetTop(rowData.id,1)}} >置顶</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={()=>{this.handleShowNote(rowData.id,0,'是否确认隐藏，隐藏后将在小程序端不可见')}}>隐藏</Button>
                                    </> : null
                            }
                            {
                                [0, 2].includes(rowData.status) ?
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={()=>{this.handleShowNote(rowData.id,1,'是否确认公开，公开后将在小程序端展示')}} >公开</Button>
                                    </> : null
                            }


                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleComment(rowData.id)}} >评论</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.batchUpdateTopic(false,rowData.id)}}>修改话题</Button>

                            {
                                rowData.source==1 ?
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={()=>{this.handleDeleNote(rowData.id)}} >删除</Button>
                                    </> : null
                            }


                        </div>
                    )
                }
            }
        ],
        searchForm: {
            name: "",
            status: 0,
            topic_id: 0,
            start_time: "",
            end_time: "",
            order_field:'',
            order_asc:''
        },
        isLoading: false,
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        selectedRowKeys: [],
        isTopicVisible: false,
        topicLists: [],
        poster: "",
        path: ""
    }

    componentDidMount() {
        this.fetchTopic()
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;

        this.setState({
            isLoading: true
        })

        articleLists(sendData).then(res => {
            if (res.code == 1) {
                let { data ,total,per_page,current_page} = res.data.list
                this.setState({
                    tableData: data,
                    total,
                    per_page,
                    page:current_page
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    fetchTopic = () => {
        let sendData = {};
        sendData.page = 1;
        sendData.per_page = 100;

        this.setState({
            isLoading: true
        })

        articleTopic(sendData).then(res => {
            if (res.code == 1) {
                let { data } = res.data.list
                this.setState({
                    topicLists: data
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                }) 
            }
        })
    }

    showEdit = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'sort':
                lists[index]['sort_edit'] = 1
                break;
        }
        this.setState({
            tableData: lists
        })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }
    handleField = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }

        sendData = {article_id:rowData.id }
        sendData['key'] = name;
        sendData['value']=val

        articleSetField(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.fetchData();
                }
            })
    }

    handleSort = (pagination, filters, sorter) => {
        let field = sorter.field,
            order = sorter.order,
            searchForm = this.state.searchForm;
        if (order) {
            searchForm.order_field = field;
            searchForm.order_asc = order == 'ascend' ? 'asc' : 'desc';
        } else {
            searchForm.order_field = '';
            searchForm.order_asc = '';
        }
        this.setState({
            searchForm
        },() => {
            this.fetchData()
        })
    }

    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        searchForm.status = 0
        searchForm.topic_id = 0

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    handleTopic = () => {
        this.TopicManage.show()
    }

    handleAdd = () => {
        this.props.history.push('/recommend/note-add')
    }

    handleEdit=id=>{
        this.props.history.push('/recommend/note-add/'+id)
    }

    handleComment=id=>{
        this.props.history.push('/recommend/comment-detail/'+id)
    }

    batchUpdateTopic = (bool,id) => {
        let selectedRowKeys = this.state.selectedRowKeys,article_ids,title,sendData={}
        if(bool){
            article_ids=selectedRowKeys
            title= '批量修改话题'
        }else{
            article_ids=[id]
            title= '修改话题'
        }

        if(!article_ids.length){return}

        this.UpdateTopic.show({
            title: '批量修改话题',
            callback: (res) => {
                sendData.article_ids=article_ids
                sendData.topic_ids=res.join()
                
                this.setState({
                    isLoading:true
                })

                articleSetTopic(sendData).then(res=>{
                    if(res.code==1){
                        message.success('操作成功').then(()=>{
                            this.fetchData()
                        })
                    }
                }).finally(()=>{
                    this.setState({
                        isLoading:false,
                        selectedRowKeys:[]
                    })
                })
                
            }
        })
        
    }

    handleSetTop=(id,type)=>{
        let sendData={
            article_ids:[id],
            type
        }

        this.setState({
            isLoading:true
        })

        articleSetTop(sendData).then(res=>{
            if(res.code==1){
                message.success('操作成功').then(()=>{
                    this.fetchData()
                })
            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    setNoteStatus=(ids,status,msg)=>{
        let sendData={
            article_ids:ids,
            status
        }
        Modal.confirm({
            title: msg,
            onOk:()=>{
                this.setState({
                    isLoading:true
                })
                articleSetStatus(sendData).then(res=>{
                    if(res.code==1){
                        message.success('操作成功').then(()=>{
                            this.fetchData()
                        })
                    }
                }).finally(()=>{
                    this.setState({
                        isLoading:false,
                        selectedRowKeys:[]
                    })
                })
            }
        });
    }

    handleShowNote=(id,status,msg)=>{
        this.setNoteStatus([id],status,msg)
    }

    handleDeleNote=id=>{
        Modal.confirm({
            title: '是否确认删除，删除后不可恢复',
            onOk:()=>{
                this.setState({
                    isLoading:true
                })
                articleDele({article_ids:[id]}).then(res=>{
                    if(res.code==1){
                        message.success('操作成功').then(()=>{
                            this.fetchData()
                        })
                    }
                }).finally(()=>{
                    this.setState({
                        isLoading:false
                    })
                })
            }
        });
     
    }
    handleCheck=row=>{
        let article=row
        if(typeof article.content == 'string'){
            article.content=JSON.parse(article.content)
        }
        this.VerifyNote.show({
            article:article,
            callback:(res)=>{
                let msg=''
                if(res ==1){
                    msg='是否确认通过，通过后将公开展示在小程序端'
                }else{
                    msg='是否确认拒绝？'
                }
                this.setNoteStatus([row.id],res,msg)
            }
        })
    }
    batchOk=()=>{
        let selectedRowKeys = this.state.selectedRowKeys
        if(!selectedRowKeys.length){
            return
        }
        this.setNoteStatus(selectedRowKeys,1,'是否确认批量通过审核？')
    }

    batchFail=()=>{
        let selectedRowKeys = this.state.selectedRowKeys
        if(!selectedRowKeys.length){
            return
        }
        this.setNoteStatus(selectedRowKeys,4,'是否确认批量拒绝审核？')
    }

    handleShare = row => {
        this.setState({
            poster: row.code_url,
            path: `/pages/recommend/note-detail?article_id=${row.id}`
        })
        
        this.ShareModal.show()
    }


    render() {
        const { getFieldDecorator } = this.props.form

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            }
        }

        return (
            <div>
                <div className="content-head mgb15">
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'笔记标题'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入笔记标题' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'笔记状态'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: '200px' }}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>公开</Select.Option>
                                            <Select.Option value={2}>隐藏</Select.Option>
                                            <Select.Option value={3}>置顶</Select.Option>
                                            <Select.Option value={4}>待审核</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'话题类型'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('topic_id', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: '200px' }}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.topicLists.map(item => {
                                                    return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem label={'创建时间'} className={'fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: null
                                    })(
                                        <RangePicker
                                            style={{ width: 400 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder={['开始时间', '结束时间']}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;</span>} >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} icon={'plus'} onClick={this.handleAdd}>新增笔记</Button>
                    <Button type={"default"} className={'mgl10'} onClick={this.handleTopic}>话题管理</Button>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={false}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        onChange={(pagination, filters, sorter) => { this.handleSort(pagination, filters, sorter) }}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={this.batchOk} >批量通过</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.batchFail} >批量拒绝</Button>
                        <Button className={'btn mgl10'} type={""} onClick={()=>{this.batchUpdateTopic(true)}}>批量修改话题</Button>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>


                <ShareModal onRef={e => { this.ShareModal = e }} poster={this.state.poster} path={this.state.path} ></ShareModal>
                <VerifyNote  onRef={e => this.VerifyNote = e} />
                <UpdateTopic topicLists={this.state.topicLists} onRef={e => this.UpdateTopic = e} />
                <TopicManage onRef={e => this.TopicManage = e} />
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}


export default Form.create({})(withRouter(NoteManage))
