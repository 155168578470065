/**
 * 老师列表组件
 * @type 16
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip, Tag } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'


const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ16 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        let tagStyle = {}
        if (moduleData.content && moduleData.content.style) {
            tagStyle = {
                border: `1px solid ${moduleData.content.tagBorderColor}`,
                background: `${moduleData.content.tagBgColor}`,
                color: `${moduleData.content.tagTextColor}`
            }
        }

        return (
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`老师`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>
                        {
                            moduleData.content.style == 1
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`flowList teacher pdl10 pdr10`}>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 0
                                                        ?
                                                        <div
                                                            className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                                            key={index}
                                                        >
                                                            <div className="item-img"
                                                                style={{ backgroundImage: `url(${item.head_img})` }}></div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.nick_name}</div>
                                                                {
                                                                    moduleData.content.showTeacherAge ? <div className="sub-title">{`${item.position && item.position.name} | ${item.teacher_age}年`}</div> : null
                                                                }
                                                                {
                                                                    moduleData.content.showTag ?
                                                                        <div className="tag-wrap">
                                                                            <span>擅长：</span>
                                                                            {item.skill && item.skill.map((val, i) => {
                                                                                return <div key={i} className="tag" style={tagStyle}>{val}</div>
                                                                            })}
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }

                                        </div>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 1
                                                        ?
                                                        <div
                                                            className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                                            key={index}>
                                                            <div className="item-img"
                                                                style={{ backgroundImage: `url(${item.head_img})` }}></div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.nick_name}</div>
                                                                {
                                                                    moduleData.content.showTeacherAge ? <div className="sub-title">{`${item.position && item.position.name} | ${item.teacher_age}年`}</div> : null
                                                                }
                                                                {
                                                                    moduleData.content.showTag ?
                                                                        <div className="tag-wrap">
                                                                            <span>擅长：</span>
                                                                            {item.skill && item.skill.map((val, i) => {
                                                                                return <div key={i} className="tag" style={tagStyle}>{val}</div>
                                                                            })}
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`flowList teacher pdl10 pdr10`}>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                            >
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                <div className="item-desc">
                                                    <div className="title">老师呢称</div>
                                                    {
                                                        moduleData.content.showTeacherAge ? <div className="sub-title">{`老师职位 | 教龄`}</div> : null
                                                    }
                                                    {
                                                        moduleData.content.showTag ?
                                                            <div className="tag-wrap">
                                                                <span>擅长：</span>
                                                                <div className="tag" style={tagStyle}>标签一</div>
                                                                <div className="tag" style={tagStyle} >标签二</div>
                                                            </div> : null

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                            >
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                <div className="item-desc">
                                                    <div className="title">老师呢称</div>
                                                    {
                                                        moduleData.content.showTeacherAge ? <div className="sub-title">{`老师职位 | 教龄`}</div> : null
                                                    }
                                                    {
                                                        moduleData.content.showTag ?
                                                            <div className="tag-wrap">
                                                                <span>擅长：</span>
                                                                <div className="tag" style={tagStyle}>标签一</div>
                                                                <div className="tag" style={tagStyle} >标签二</div>
                                                            </div> : null

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null

                        }
                        {
                            moduleData.content.style == 2
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`bdbox pdl10 pdr10`}>
                                        {
                                            moduleData.content.dataset.map((item, index) => (
                                                <div className={`cx-item-style2 teacher ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} key={index}>
                                                    <div className={`left`} style={{ backgroundImage: `url(${item.head_img})` }}>
                                                    </div>
                                                    <div className={`right`}>
                                                        <div className={`title`}>{item.nick_name}</div>
                                                      
                                                        <div className={`right-bottom`}>
                                                            {
                                                                moduleData.content.showTeacherAge ? <div className="sub-title">{`${item.position && item.position.name} | ${item.teacher_age}年`}</div> : null
                                                            }
                                                            {
                                                                moduleData.content.showTag ?
                                                                    <div className="tag-wrap">
                                                                        <span>擅长：</span>
                                                                        {item.skill && item.skill.map((val, i) => {
                                                                            return <div key={i} className="tag" style={tagStyle}>{val}</div>
                                                                        })}
                                                                    </div> : null
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className={`bdbox pdl10 pdr10`}>
                                        <div className={`cx-item-style2 teacher ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} >
                                            <div className={`left`} style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}>
                                            </div>
                                            <div className={`right`}>
                                                <div className={`title`}>老师呢称</div>

                                                <div className={`right-bottom`}>

                                                    {
                                                        moduleData.content.showTeacherAge ? <div className="sub-title">{`老师职位 | 教龄`}</div> : null
                                                    }
                                                    {
                                                        moduleData.content.showTag ?
                                                            <div className="tag-wrap">
                                                                <span>擅长：</span>
                                                                <div className="tag" style={tagStyle}>标签一</div>
                                                                <div className="tag" style={tagStyle} >标签二</div>
                                                            </div> : null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`cx-item-style2 teacher ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} >
                                            <div className={`left`} style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}>
                                            </div>
                                            <div className={`right`}>
                                                <div className={`title`}>老师呢称</div>

                                                <div className={`right-bottom`}>

                                                    {
                                                        moduleData.content.showTeacherAge ? <div className="sub-title">{`老师职位 | 教龄`}</div> : null
                                                    }
                                                    {
                                                        moduleData.content.showTag ?
                                                            <div className="tag-wrap">
                                                                <span>擅长：</span>
                                                                <div className="tag" style={tagStyle}>标签一</div>
                                                                <div className="tag" style={tagStyle} >标签二</div>
                                                            </div> : null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                :
                                null
                        }


                        <EditorIcon type={`icon-guanbi`} className={`del-module`}
                            onClick={this.handleDeleteModule}></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_typ16)
