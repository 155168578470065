import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Checkbox } from 'antd'
import message from '@/utils/message'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import {articleAuthorSave} from '@/api/recommend.js'

const FormItem = Form.Item

class CreatorAdd extends React.Component {
    state = {
        modalLoading: false,
        show: false,
        formData: {},
        callBack:null,
    }
    componentDidMount() {
        this.props.onRef(this)
    }


    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {formData,callBack}=this.state,sendData
                sendData=values
                sendData.img=formData.img
                if(!formData.img){
                   return message.error('请添加创作者头像')
                }
                if(formData.id){
                    sendData.author_id=formData.id
                }

                this.setState({
                    modalLoading:true
                })
                articleAuthorSave(sendData).then(res=>{
                    if(res.code == 1){
                        message.success(res.msg)
                        if(typeof callBack == 'function'){
                            callBack()
                        }
                    }
                }).finally(()=>{
                    this.setState({
                        modalLoading:false,
                        show:false
                    })
                })

            }
        })
    }
    handleCancel = () => {
        this.setState({
            show: false
        })
    }
    show = (params) => {

        this.setState({
            show: true,
            formData:params.creatorData ||'',
            callBack:params.callBack || null
        })
    }
    

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'img':
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    render() {

        let formData = this.state.formData || {}

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return (
            <Modal
                title={`${formData.id?'编辑':'新增'}创作者`}
                visible={this.state.show}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                destroyOnClose={true}
            >
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <Form className={`model-form`} {...formItemLayout}>
                    <FormItem label={`创作者昵称`}>
                        {
                            getFieldDecorator('name', {
                                initialValue: formData.name || "",
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入创作者'
                                    }
                                ]
                            })(
                                <Input placeholder='请输入创作者昵称，限10个字符' maxLength={10} suffix={<span>{getFieldValue("name").length}/10</span>} />
                            )
                        }
                    </FormItem>
                    <FormItem label={'创作者头像'} required={true}>
                        <div className={`clearfix`}>
                            {
                                formData.img
                                    ?
                                    <PictureCard
                                        onRef={e => { this.PictureCard = e }}
                                        imgs={formData.img}
                                        onDel={e => { this.delImg(e, 'img') }}
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={e => { this.ImgAdd = e }}
                                        onClick={e => { this.chooseImg(e, 'img') }}
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={`fi-help-text`}>建议尺寸：750x750像素，单张不超过2M</span>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(CreatorAdd))

