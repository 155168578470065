import React from 'react'
import { Icon } from 'antd'
import './ImgAdd.scss'

class ImgAdd extends React.Component{
    state = {}
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    handleClick = (e) => {
        this.props.onClick && this.props.onClick(e)
    }
    render() {
        return(
            <div className={`ant-upload ant-upload-select ant-upload-select-picture-card`}>
                <span className={`ant-upload`} onClick={ this.handleClick } data-name={ this.props[`data-name`] || `` } data-index={ this.props[`data-index`] || `` }>
                    <div>
                        <Icon type="plus" />
                        <div className={`ant-upload-text`}>{this.props.icon_name?this.props.icon_name:"选择图片"} </div>
                    </div>
                </span>
            </div>
        )
    }
}

export default ImgAdd
