import React, { Component } from 'react'
import { Table, Button, Divider } from 'antd'
import MarketItemPicker from './market-item-picker'
import { SetGoodsVipRule } from './set-vip-price'

export default class GoodsDiscount extends Component {
    state = {
        columns: [
            {
                title: '商品名称',
                dataIndex: '1',
                width: 140,
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.name} </div>
                    )
                }
            },
            {
                title: '优惠方式',
                dataIndex: '2',
                width: 100,
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.discount_type == 2 ? '指定价格' : '折扣'} </div>
                    )
                }
            },
            {
                title: 'V1',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v1 || '-'} </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v2 || '-'}</div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v3 || '-'} </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v4 || '-'} </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v5 || '-'} </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '8',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v6 || '-'}</div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: 120,
                render: (text, rowData, index) => {
                    return (
                        <div className="btn-row">
                            <Button type='link' size='small' onClick={() => { this.handleEdit(rowData, index) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type='link' size='small' onClick={() => { this.handleDele(index) }}>移除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        selectedRowKeys: []
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        let detail=this.props.detail || {};
        if (Array.isArray(detail.rule) && detail.rule.length && detail.goods_list) {
            let rule = detail.rule, tableData = detail.goods_list;
            tableData = tableData.map(item => {
                let goods = rule.find(val => val.id == item.id);
                if (goods) {
                    item.discount_type = goods.discount_type || 1;
                    if (goods.rule && goods.rule.length) {
                        item.discount_list = goods.rule;

                        let min = { v1: 0, v2: 0, v3: 0, v4: 0, v5: 0, v6: 0 },
                            max = { v1: 0, v2: 0, v3: 0, v4: 0, v5: 0, v6: 0 };

                        goods.rule.forEach((val, index) => {
                            let v1 = Number(val.v1);
                            if (!isNaN(v1)) {
                                if (index == 0) {
                                    min.v1 = val.v1
                                    max.v1 = val.v1
                                }
                                min.v1 = min.v1 < v1 ? min.v1 : v1;
                                max.v1 = max.v1 > v1 ? max.v1 : v1;
                            }
                            let v2 = Number(val.v2);
                            if (!isNaN(v2)) {
                                if (index == 0) {
                                    min.v2 = val.v2
                                    max.v2 = val.v2
                                }
                                min.v2 = min.v2 < v2 ? min.v2 : v2;
                                max.v2 = max.v2 > v2 ? max.v2 : v2;
                            }
                            let v3 = Number(val.v3);
                            if (!isNaN(v3)) {
                                if (index == 0) {
                                    min.v3 = val.v3
                                    max.v3 = val.v3
                                }
                                min.v3 = min.v3 < v3 ? min.v3 : v3;
                                max.v3 = max.v3 > v3 ? max.v3 : v3;
                            }
                            let v4 = Number(val.v4);
                            if (!isNaN(v4)) {
                                if (index == 0) {
                                    min.v4 = val.v4
                                    max.v4 = val.v4
                                }
                                min.v4 = min.v4 < v4 ? min.v4 : v4;
                                max.v4 = max.v4 > v4 ? max.v4 : v4;
                            }
                            let v5 = Number(val.v5);
                            if (!isNaN(v5)) {
                                if (index == 0) {
                                    min.v5 = val.v5
                                    max.v5 = val.v5
                                }
                                min.v5 = min.v5 < v5 ? min.v5 : v5;
                                max.v5 = max.v5 > v5 ? max.v5 : v5;
                            }
                            let v6 = Number(val.v6);
                            if (!isNaN(v6)) {
                                if (index == 0) {
                                    min.v6 = val.v6
                                    max.v6 = val.v6
                                }
                                min.v6 = min.v6 < v6 ? min.v6 : v6;
                                max.v6 = max.v6 > v6 ? max.v6 : v6;
                            }
                        })

                        for (let key in min) {
                            if (min[key] != max[key]) {
                                if (item.discount_type == 1) {
                                    item[key] = min[key] + '~' + max[key] + '折'
                                } else {
                                    item[key] = '￥' + min[key] + '~' + max[key]
                                }
                            } else if (min[key] == max[key] && min[key] != 0) {
                                if (item.discount_type == 1) {
                                    item[key] = min[key] + '折'
                                } else {
                                    item[key] = '￥' + min[key]
                                }
                            }
                        }

                    }
                }

                return item
            })

            this.setState({
                tableData
            })
        }

    }

    getData = () => {
        let tableData = this.state.tableData;
        tableData = tableData.map(item => {
            let obj = {
                id: item.id,
                name: item.name,
                discount_type: item.discount_type || 1,
            }
            if (item.discount_list && item.discount_list.length) {
                obj.rule = item.discount_list
            } else {
                obj.rule = []
            }
            return obj
        })
        tableData = tableData.filter(item => {
            if (item.rule.length) {
                return true
            } else {
                return false
            }
        })

        return tableData
    }


    handleAdd = () => {
        let tableData = this.state.tableData || []
        this.MarketItemPicker.show(res => {
            if (res) {
                let lists = [];
                res.forEach((item, index) => {
                    item.discount_type = 1;
                    tableData.forEach((child, childKey) => {
                        if (child.id == item.id) {
                            item = child
                        }
                    })
                    lists.push(item)
                })
                this.setState({
                    tableData: lists,
                })
            }
        })
    }


    batchDele = () => {
        let selectedRowKeys = this.state.selectedRowKeys;
        if (selectedRowKeys.length) {
            let tableData = this.state.tableData;
            tableData = tableData.filter(item => {
                return !selectedRowKeys.includes(item.id)
            })

            this.setState({
                tableData,
                selectedRowKeys: []
            })
        }
    }

    handleDele = index => {
        let tableData = this.state.tableData;
        tableData.splice(index, 1);
        this.setState({
            tableData
        })
    }

    handleEdit = (row, index) => {
        this.SetGoodsVipRule.show(row, (res) => {
            let tableData = this.state.tableData;
            tableData[index] = res
            this.setState({
                tableData
            })
        })
    }


    render() {

        const rowSelection = {
            type: 'checkbox',
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            }
        }


        return (
            <>
                <div className="mgb20">
                    <Button type='primary' onClick={this.handleAdd}>添加商品</Button>
                    {
                        this.state.tableData.length ? <Button type='default' className='mgl20' onClick={this.batchDele}>批量移除</Button> : null
                    }

                </div>
                <Table
                    size={`middle`}
                    rowSelection={rowSelection}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>

                <MarketItemPicker
                    onRef={e => { this.MarketItemPicker = e }}
                    selectIds={this.state.tableData.map(item => { return Number(item.id) })}
                    multiple={true}
                ></MarketItemPicker>
                <SetGoodsVipRule onRef={e => this.SetGoodsVipRule = e} />
            </>
        )
    }
}
