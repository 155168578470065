import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
// import { storeLists } from '@/api/store'
import {  createLabelGroup } from '@/api/user'

const FormItem = Form.Item;

 class CreateLabel extends Component {
    state={
        visible:false,
        modalLoading:false,
        storeLists:[],
        tableData:{
            label_name:'',
            label_value:[
                {
                    name:''
                }
            ]
        },
        callback:null
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    // fetchStore = () => {
    //     let sendData = {}
    //     sendData.page = 1;
    //     sendData.per_page = 100;
    //     storeLists(sendData)
    //         .then(res => {
    //             if (res.code == 1) {
    //                 let lists = res.data.store.data;
    //                 this.setState({
    //                     storeLists: lists
    //                 })
    //             }
    //         })

    // }

    addLabel = () =>{
        let data = this.state.tableData;
        if(data.label_value.length === 10){
            message.error('最多不能超过10个标签值');
            return;
        }
        data.label_value.push({ name:''})
        this.setState({
            tableData: data
        })
    }

    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
        console.log('-----',this.props.formdata)
        if(this.props.formdata && this.props.formdata.id){
            let data = this.props.formdata;
            console.log('---',data)
            this.setState({
                tableData:data   
            })
        }else{
            let data = {
                label_name:'',
                label_value:[
                    {
                        name:''
                    }
                ]
            }
            this.setState({
                tableData:data   
            })
        }
    }

    saveData=()=>{
        console.log('-------',this.state.tableData);
        let  sendData = {},callback=this.state.callback

        sendData = this.state.tableData;
        if(this.props.formdata.id){
            sendData.id = this.props.formdata.id
        }

        this.setState({
            modalLoading: true
        })
        createLabelGroup(sendData).then(res => {
            message.success(res.msg)
            if (res.code === 1) {
                if(typeof callback == 'function'){
                    callback()
                }
            } 
            
            this.setState({
                modalLoading: false,
                visible:false
            })
        })


    }
    handleDel = (index) =>{
        let resData = this.state.tableData;
        resData.label_value.splice(index,1);
        this.setState({
            tableData:resData
        })
    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    getInputValue = e => {
        let val = e.target.value;
        let datas = this.state.tableData;
        datas.label_name = val;
        this.setState({
            tableData: datas
        })
    }


    getLabelInput = (e,index) =>{
        let val = e.target.value;
        let datas = this.state.tableData;
        datas.label_value[index].name = val;
        this.setState({
            tableData: datas
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        const tableData = this.state.tableData || []
      
        return (
            <Modal
            title={"添加标签组"}
            visible={this.state.visible}
            onOk={this.saveData}
            onCancel={this.handleCancel}
            width={900}
            centered={true}
            confirmLoading={this.state.modalLoading}
            maskClosable={false}
            destroyOnClose={true}
        >
            <Form {...formItemLayout} className={'model-form'}>
                
                <Row gutter={24}>
                    <Col span={20}>
                        <FormItem label="标签组名称">
                            {/* {getFieldDecorator('label_name', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入标签组名称'
                                    }
                                ]
                            })( */}
                            <Input maxLength={15} value={tableData.label_name} onChange={this.getInputValue}/>
                            {/* )} */}
                        </FormItem>

                        <FormItem label="标签内容">
                            <div className='label-bg'>
                            {
                                tableData.label_value.map((item,index) => (
                                    <div className='label-item flex mgb15' key={index}>
                                        <div>标签值{index+1}</div>
                                        <div className='mgl20'><Input maxLength={10} placeholder='建议不超过10个字' value={item.name} onChange={ (e)=>{ this.getLabelInput(e,index)} }/></div>
                                        {
                                            index > 0
                                                ?
                                                <div className='mgl20'><Button type="primary" onClick={() => { this.handleDel(index) } }>删除</Button></div>
                                                :
                                                null
                                        }
                                       
                                    </div>
                                ))
                            }
                            <div style={{color:'blue',cursor:'pointer'}} className='tac' onClick={()=>{ this.addLabel()}}>+新增标签值</div>
                            </div>
                            <div style={{color:'#999'}} className='mgt5 fz12'>最多可创建10个标签值，最少需创建1个标签值，为空则不保存。</div>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                    </Col>
                </Row>
            </Form>

        </Modal>
        )
    }
}

export default Form.create({})(CreateLabel)
