import React from 'react'
import { Form, Input, Button, Table, Pagination, Divider } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import { storeCourt } from '@/api/court'
const FormItem = Form.Item

class CourtManage extends React.Component{
    state = {
        searchForm:{
            name:''
        },
        columns:[
            {
                title:'门店名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.name || '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'已使用场地数量',
                dataIndex:'space_num',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.space_num}</p>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleEdit(rowData) } }>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleDetail(rowData) } }>场地介绍</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10
    }
    componentDidMount() {
        this.fetchData()

    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        storeCourt(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store_list.data,
                        total = res.data.store_list.total,
                        per_page = res.data.store_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleEdit = (row) => {
        this.props.history.push({
            pathname:`/court/lists/${row.id}`
        })
    }
    handleDetail = (row) => {
        this.props.history.push({
            pathname:`/court/detail/${row.id}`
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={`content-msg mgb10`}>
                    <p>
                        场馆预订功能基于门店实现，如果未创建门店请先<Link to={'/store/add'} className={`mgl5`} >新增门店</Link>
                    </p>
                </div>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'门店名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索门店名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(CourtManage))
