import React, { Component } from 'react'
import { Form, Input, Button, Select, Checkbox, Row, Col, Table, Modal, InputNumber } from 'antd'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
import moment from 'moment'
import message from '@/utils/message'

const FormItem = Form.Item;

class SignDaysPrize extends Component {
    state = {
        visible: false,
        modalLoading: false,
        formData: {},
        callback: null,
        columns: [
            {
                title: '券名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '券类型',
                dataIndex: 'coupon_type',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex: 'effect_time',
                render: (text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML={{ __html: rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title: '赠送数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <InputNumber
                            style={{ width: 80 }}
                            min={1}
                            precision={0}
                            placeholder={`张`}
                            value={rowData.num || ``}
                            onChange={e => { this.handleCouponNum(e, rowData, index) }}
                        />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => { this.handleDelItem(rowData, index) }}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
    }


    show = (callback,params) => {
        let formData=params||{}
        this.setState({
            visible: true,
            formData,
            callback: callback || null
        })
    }

    handleCoupon = () => {
        let formData = this.state.formData || {}
        this.CouponPicker.show(res => {
            if (res.length > 5) {
                res.length = 5;
            }
            formData.present_coupon_data = res;
            this.setState({
                formData
            })
        })
    }

    handleCouponNum = (e, rowData, index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data;
        present_coupon_data[index]['num'] = e;
        this.setState({
            formData
        })
    }
    handleDelItem = (rowData, index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data;
        present_coupon_data.splice(index, 1);
        this.setState({
            formData
        })
    }

    handleNumChange=(e,name)=>{
        let formData=this.state.formData
        formData[name]=e
        this.setState({
            formData
        })
    }

    saveData = () => {
        let { callback,formData} = this.state,data={};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                data.days=values.days
                data.prize=values.prize ||[]
                data.points=formData.points || 0;
                data.grow_up_value=formData.grow_up_value || 0;
                data.present_coupon_data=formData.present_coupon_data || [];

                let prize_text=""
                if (data.prize.includes(1)) {
                    prize_text += `${data.points || 0}积分`
                    if (data.prize.length > 1) {
                        prize_text += "、"
                    }
                }

                if (data.prize.includes(2)) {
                    prize_text += `${data.grow_up_value || 0}成长值`
                    if (data.prize.includes(3)) {
                        prize_text += "、"
                    }
                }

                if (data.prize.includes(3)) {
                    prize_text += data.present_coupon_data.map(val => `${val.name + (val.num || 0)}张`).join('、')
                }

                data.name=prize_text


                if(typeof callback =='function'){
                    callback(data)
                }

                this.setState({
                    formData:{},
                    visible:false
                })
            }
        })

    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator } = this.props.form

        const data = this.state.formData || {}

        return (
            <Modal
                title={"添加会员"}
                visible={this.state.visible}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={800}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                destroyOnClose={true}
            >
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={24}>
                            <FormItem label="连续签到">
                                {getFieldDecorator('days', {
                                    initialValue: data.days|| undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入连续签到天数'
                                        }
                                    ]
                                })(
                                    <InputNumber style={{ width: 120 }} min={1} precision={0} />
                                )}
                                <span> 天</span>
                            </FormItem>

                            <FormItem label=" " className='no-colon' >
                                {getFieldDecorator('prize', {
                                    initialValue: data.prize || [],
                                })(
                                    <Checkbox.Group >
                                        <Checkbox value={1}>积分&nbsp;&nbsp;&nbsp;
                                            送 <InputNumber style={{ width: 80 }}  min={1} precision={0} value={data.points || undefined } onChange={e=>{this.handleNumChange(e,'points')}} /> 积分
                                        </Checkbox>
                                        <br />
                                        <Checkbox value={2} className='mgt10'>成长值&nbsp;&nbsp;&nbsp;
                                            送 <InputNumber style={{ width: 80 }} min={1} precision={0} value={data.grow_up_value || undefined} onChange={e=>{this.handleNumChange(e,'grow_up_value')}} /> 成长值
                                        </Checkbox>
                                        <br />
                                        <Checkbox value={3} className='mgt10' >优惠券&nbsp;&nbsp;&nbsp;
                                            {
                                                 data.present_coupon_data && data.present_coupon_data.length>4?null:
                                                 <Button type="primary" ghost size='small' onClick={this.handleCoupon} >添加券</Button>
                                            }
                                           
                                        </Checkbox>
                                    </Checkbox.Group>
                                )}
                                {
                                    data.present_coupon_data && data.present_coupon_data.length
                                        ?
                                        <Table
                                            size={`middle`}
                                            columns={this.state.columns}
                                            dataSource={data.present_coupon_data}
                                            rowKey={record => record.id}
                                        ></Table>
                                        :
                                        null
                                }
                            </FormItem>

                        </Col>
                    </Row>
                </Form>

                <CouponPicker
                    onRef={e => { this.CouponPicker = e }}
                    multiple={true}
                    selectIds={[]}
                ></CouponPicker>

            </Modal>
        )
    }
}

export default Form.create({})(SignDaysPrize)
