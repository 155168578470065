import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Checkbox, Row, TimePicker } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import message from '@/utils/message'
import moment from 'moment'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
const FormItem = Form.Item
const { TextArea } = Input

class AddGuestTime extends React.Component{
    state = {
        formData:{},
        title:'',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        let data = this.props.data || ''
        this.setState({
            visible:true,
            callback : callback || null,
            formData: data || {},
            title: data ? '编辑时段': '添加时段'
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            formData = this.state.formData || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.start_time ? moment(values.start_time).format('HH:mm') : ''
                values.end_time = values.end_time ? moment(values.end_time).format('HH:mm') : ''
                res = values;
                if(formData.id){
                    res.id = formData.id
                }
                this.close()
                if(typeof callback == 'function'){
                    callback(res)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover':
                    formData[name] = imgs[0];
                    break;
                default:
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    render() {
        const storeLists = this.props.storeLists || []
        const data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 18
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`时段名称`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入时段名称'
                                    }
                                ]
                            })(
                                <Input placeholder={``} style={{ width:'200px' }} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`设置时段`}>
                        <div >
                            {
                                getFieldDecorator(`start_time`,{
                                    initialValue:data.start_time ? moment(data.start_time,'HH:mm') : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择时间'
                                        }
                                    ]
                                })(
                                    <TimePicker placeholder={`开始时间`} secondStep={60}  />
                                )
                            }
                            <span className={`mgl10 mgr10`}>至</span>
                            {
                                getFieldDecorator(`end_time`,{
                                    initialValue:data.end_time ? moment(data.end_time,'HH:mm') : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择时间'
                                        }
                                    ]
                                })(
                                    <TimePicker placeholder={`结束时间`} secondStep={60}/>
                                )
                            }
                        </div>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddGuestTime))
