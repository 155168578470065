import $axios from '@/axios/axios.js'

export function couponSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/save`,
        method:'post',
        data
    })
}

export function couponLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/list`,
        method:'post',
        data
    })
}

export function couponDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/detail`,
        method:'post',
        data
    })
}

export function couponStop(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/stop`,
        method:'post',
        data
    })
}

export function couponRestart(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/restart`,
        method:'post',
        data
    })
}

export function couponDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/delete`,
        method:'post',
        data
    })
}


export function suitStores(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/suitStores`,
        method:'post',
        data
    })
}

export function drawRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/drawRecord`,
        method:'post',
        data
    })
}

export function changeEndAt(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/changeEndAt`,
        method:'post',
        data
    })
}

export function changeStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/coupon/changeStatus`,
        method:'post',
        data
    })
}
