import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon, Checkbox,Popover } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
// import { storeLists } from '@/api/store'
import {  createLabelGroup } from '@/api/user'
import CouponModal from './CouponModal';
import { equityList } from '@/api/level';

const FormItem = Form.Item;

 class EquityModal extends Component {
    state={
        type:null,
        visible:false,
        checkKeys:null,
        checkKeys2:null,
        modalLoading:false,
        storeLists:[],
        storeListTwo:[
            {
                id:1,
                type:3,
                name:'送优惠券',
                icon:'https://img.cxkoo.com/chengxuan/1/2023-08-10/40ffdbd57ec08c1b43d31d0ee108671b.png'
            },
            {
                id:2,
                type:1,
                name:'送积分',
                icon:'https://img.cxkoo.com/chengxuan/1/2023-08-10/349ac00380d4b88b3db9ce7e4c26b746.png'
            },
        ],
        tableData2:[],
        popVisible:false,
        popVisible1:false,
        popVisible2:false,
        tableData:[],
        callback:null
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    fetchData = (row) => {
        let sendData = {}
        let tableData = row.equity;
        let tableData2 = row.gift;
        let storeListTwo = this.state.storeListTwo;
        equityList(sendData)
            .then(res => {
                if (res.code === 1) {
                    console.log('------',res)
                    let lists = res.data.list.data;
                    if(tableData){
                        lists.forEach((item,index)=>{
                            let data = tableData.find(items => items.id == item.id)
                            if(data){
                                if(data.type === 5){
                                    item.select_coupon = data.select_coupon
                                }else if(data.type === 8 || data.type === 6){
                                    item.input_num = data.input_num
                                }
                            }
                           
                        })
                    }

                    if(tableData2){
                        storeListTwo.forEach((itemt,indext)=>{
                            let datas = tableData2.find(items => items.id == itemt.id)
                            if(datas){
                                if(datas.type === 3){
                                    itemt.select_coupon = datas.select_coupon
                                }else if(datas.type === 1){
                                    itemt.input_num = datas.input_num
                                }
                            }
                           
                        })
                    }
                    this.setState({
                        storeLists: lists,
                        storeListTwo
                    })

                }
            })

    }

    show = (row,callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
        if(row.equity && row.equity.length>0){
            let arr = [];
            if(row.equity){
                row.equity.forEach((item,index)=>{
                    arr.push(item.id)
                })
            } 
            this.setState({
                tableData:row.equity,
                checkKeys:arr,
            })
        }else{
            this.setState({
                tableData:[],
                checkKeys:null
            })
        }

        if(row.gift && row.gift.length>0){
            let arr2 = [];
            if(row.gift){
                row.gift.forEach((item,index)=>{
                    arr2.push(item.id)
                })
            }
            
            this.setState({
                tableData2:row.gift,
                checkKeys2:arr2
            })
        }else{
            this.setState({
                tableData2:[],
                checkKeys2:null
            })
        }
        this.fetchData(row);
    }

    saveData=()=>{


        this.setState({
            modalLoading: true
        })
        
        const { sendEquity } = this.props;
        let storeLists  = this.state.storeLists;
        let storeListTwo  = this.state.storeListTwo;
        let tableData = this.state.tableData;
        let tableData2 = this.state.tableData2;
        tableData.forEach((item,index)=>{
            let resData = storeLists.find(items=> items.id == item.id);
            if(resData.type == 5){
                  item.select_coupon = resData.select_coupon || null
            }else if(resData.type == 6){
                  item.input_num = resData.input_num || 0
            }else if(resData.type == 8){
                  item.input_num = resData.input_num || 0
            }

        })

        tableData2.forEach((itemt,indext)=>{
            let resDatas = storeListTwo.find(itemx=> itemx.id == itemt.id);
            if(resDatas.type == 3){
                itemt.select_coupon = resDatas.select_coupon || null
            }else if(resDatas.type == 1){
                itemt.input_num = resDatas.input_num || 0
            }

        })
        this.setState({
            tableData,
            tableData2
        })
        let arr = {
            equity:tableData,
            gift:tableData2
        }
        sendEquity(arr);
        
        this.setState({
            modalLoading: false,
            visible:false
        })


    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    handlePoints = (type) => {
        if(type === '6'){
            this.setState({
                popVisible: true
            })
        }else if(type === '8'){
            this.setState({
                popVisible1: true
            })
        }else if(type === '1'){
            this.setState({
                popVisible2: true
            })
        }
        
    }

    handleSavePoints = (type) => {
        if(type === '6'){
            this.setState({
                popVisible: false
            })
        }else if(type === '8'){
            this.setState({
                popVisible1: false
            })
        }else if(type === '1'){
            this.setState({
                popVisible2: false
            })
        }
    }

    closePop = (type) => {
        if(type === '6'){
            this.setState({
                popVisible: false
            })
        }else if(type === '8'){
            this.setState({
                popVisible1: false
            })
        }else if(type === '1'){
            this.setState({
                popVisible2: false
            })
        }
    }


    handleCheckChange = val =>{
        console.log('这是选择的权益',val);
        let data = this.state.tableData;
        let storeLists = this.state.storeLists;
        if(val.length>0){
            data = [];
            val.forEach((item,index)=>{
                let resData =  storeLists.find(items=> items.id === item);
                
                let oldData =  data.find(oitems=> oitems.id === item);
                if(!oldData){
                    let arr;
                    arr = {
                        id:resData.id,
                        type:resData.type,
                        name:resData.name
                    }
                    data.push(arr)
                }
            })
        }else{
            data = [];
        }
        this.setState({
            tableData:data,
            checkKeys:val
        })
       
    }

    handleCheckChangeTwo = val =>{
        let data = this.state.tableData2;
        let storeListTwo = this.state.storeListTwo;
        if(val.length>0){
            data = [];
            val.forEach((item,index)=>{
                let resData =  storeListTwo.find(items=> items.id === item);
                
                let oldData =  data.find(oitems=> oitems.id === item);
                if(!oldData){
                    let arr;
                    arr = {
                        id:resData.id,
                        type:resData.type,
                        name:resData.name
                    }
                    data.push(arr)
                }
            })
        }else{
            data = [];
        }
        this.setState({
            tableData2:data,
            checkKeys2:val
        })
       
    }

    showCoupon = (type)=> {
        let row;
        let storeLists = this.state.storeLists;
        let storeListTwo = this.state.storeListTwo;
        let data;
        if(type === 1){
            data = storeLists.find(item=> item.type == 5);
        }else if(type === 2){
            data = storeListTwo.find(item=> item.type == 3);
        }
        row = data.select_coupon;
        this.setState({
            type:type
        })
        this.couponCreate.show(row,(res)=>{
            
        })
    }
    

    handleCouponAdd = res =>{
        let type = this.state.type;
        if(type == 1){
            let data = this.state.storeLists;
            data.forEach((item,index)=>{
                if(item.type === 5){
                    item.select_coupon = res;
                }
            })
            this.setState({
                storeLists:data
            })
        }else{
            let data = this.state.storeListTwo;
            data.forEach((item,index)=>{
                if(item.type === 3){
                    item.select_coupon = res;
                }
            })
            this.setState({
                storeListTwo:data
            })
        }
        
    }


    skipEquity = (type) =>{
        if(type == 1){
            this.props.history.push({
                pathname:`/level/equity`
            })
        }else if(type == 2){
            this.props.history.push({
                pathname:`/level/equity-edit`
            })
        }
        
    }

    changeJf = (e,type)=>{
        let val = e.target.value;
        let storeLists = this.state.storeLists;
        let storeListTwo = this.state.storeListTwo;
        if(type === '6'){
            storeLists.forEach((item,index)=>{
                if(item.type == 6){
                  item.input_num = val   
                }
            })
        }else if(type === '8'){
            storeLists.forEach((item,index)=>{
                if(item.type == 8){
                  item.input_num = val   
                }
            })
        }else if(type === '1'){
            storeListTwo.forEach((item,index)=>{
                if(item.type == 1){
                  item.input_num = val   
                }
            })
        }
        this.setState({
            storeLists,
            storeListTwo
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const formItemLayouts = {
            labelCol: {
                span: 10
            },
            wrapperCol: {
                span: 10
            }
        }
        const { getFieldDecorator } = this.props.form
        const storeLists = this.state.storeLists || []
        const storeListTwo = this.state.storeListTwo || []
        const content = (
            <div>
                    <div className={`flex align-center`}>
                        <div>送</div>
                        <Input style={{width:'100px'}} className={`mgl5 mgr5`} onChange={ (e)=>{ this.changeJf(e,'6') }}></Input>
                        <div>倍积分</div>
                    </div>
                    <div className={`clearfix mgt10 mg5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={()=>{this.handleSavePoints('6')}}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={()=>{this.closePop('6')}}>取消</Button>
                    </div>
            </div>
        )
        
        const content1 = (
            <div>
                    <div className={`flex align-center`}>
                        <div>送</div>
                        <Input style={{width:'100px'}} className={`mgl5 mgr5`} onChange={ (e)=>{ this.changeJf(e,'8') }}></Input>
                        <div>积分</div>
                    </div>
                    <div className={`clearfix mgt10 mg5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={()=>{this.handleSavePoints('8')}}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={()=>{this.closePop('8')}}>取消</Button>
                    </div>
            </div>
        )

        const content2 = (
            <div>
                    <div className={`flex align-center`}>
                        <div>送</div>
                        <Input style={{width:'100px'}} className={`mgl5 mgr5`} onChange={ (e)=>{ this.changeJf(e,'1') }}></Input>
                        <div>积分</div>
                    </div>
                    <div className={`clearfix mgt10 mg5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={()=>{this.handleSavePoints('1')}}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={()=>{this.closePop('1')}}>取消</Button>
                    </div>
            </div>
        )
        return (
            <div>
            <Modal
            title={"选择等级权益"}
            visible={this.state.visible}
            onOk={this.saveData}
            onCancel={this.handleCancel}
            width={1500}
            centered={true}
            confirmLoading={this.state.modalLoading}
            maskClosable={false}
            destroyOnClose={true}
        >
            <Form {...formItemLayout} className={'model-form'}>
                
                {/* <Row gutter={24}>
                    <Col span={20}> */}
                        <FormItem label="权益内容">
                            <div>
                                <Checkbox.Group onChange={this.handleCheckChange} value={this.state.checkKeys}>
                                    {
                                        storeLists && storeLists.map(val => {
                                            return (
                                                
                                                <Checkbox value={val.id} className={`flex align-center mgr15`} style={{backgroundColor:'rgb(242 242 242)',padding:'10px 15px',marginBottom:'10px',marginLeft:'0px',float:'left',width:'250px',height:'60px'}} key={ val.id }>
                                                    <div className={`flex align-center`}>
                                                        <div className={`mgl10`}><img src={val.icon}  alt='' width={30} height={30}/></div>
                                                        <div className={`mgl15`}>
                                                            <div className={`fz16 fw-600`}>{val.name}</div>
                                                            {
                                                                val.type == 8?
                                                                <Popover
                                                                    content={content1}
                                                                    width={300}
                                                                    title={null}
                                                                    placement={`bottom`}
                                                                    trigger="click"
                                                                    visible={this.state.popVisible1}>
                                                                    <div className={`icon-pencil`} style={{color:'blue'}} onClick={()=>{this.handlePoints('8')}} >
                                                                        {
                                                                            val.input_num>0 ?
                                                                                <div>积分数 {val.input_num}</div>
                                                                                :
                                                                                <div>输入积分</div>
                                                                        }
                                                                    </div>
                                                                </Popover>
                                                               :null
                                                            }

                                                            {
                                                                val.type == 6?
                                                                <Popover
                                                                    content={content}
                                                                    width={300}
                                                                    title={null}
                                                                    placement={`bottom`}
                                                                    trigger="click"
                                                                    visible={this.state.popVisible}>
                                                                    <div className={`icon-pencil`} style={{color:'blue'}} onClick={()=>{this.handlePoints('6')}} >
                                                                         {
                                                                            val.input_num>0 ?
                                                                                <div>积分倍数 {val.input_num}</div>
                                                                                :
                                                                                <div>输入积分倍数</div>
                                                                        }
                                                                    </div>
                                                                </Popover>
                                                               :null
                                                            }

                                                            {
                                                                val.type == 5?

                                                                (
    
                                                                    val.select_coupon?
                                                                        <div style={{color:'blue'}} onClick={()=>{this.showCoupon(1) }}>已选择{val.select_coupon.length || 0}张</div>
                                                                        :
                                                                        <div style={{color:'blue'}} onClick={()=>{this.showCoupon(1)} }>选择优惠券</div>
                                                                )
                                                                
                                                               : null
                                                            }

                                                            {
                                                                val.type == 1?

                                                                    <div style={{color:'blue'}} onClick={()=>{this.skipEquity(1) }}>前往权益管理设置</div>
                                                                
                                                               : null
                                                            }
                                                                
                                                            {/* <div style={{color:'blue'}}>输入积分倍数</div> */}
                                                        </div>
                                                        
                                                    </div>
                                                </Checkbox>
                                            )
                                        })
                                    }
                                    <div style={{backgroundColor:'rgb(242 242 242)',padding:'10px 15px',float:'left'}} >
                                        <div style={{height:'40px',lineHeight:'40px',color:'blue',cursor:'pointer',width:'220px',textAlign:'center'}} className={`fz16`} onClick={()=>{this.skipEquity(2)}}>+增加自定义权益</div>
                                    </div>
                                </Checkbox.Group>
                            </div>
                        </FormItem>

                        <FormItem label="每月礼包">
                            <div>
                                <div>根据成为会员的日期，每月同一时间发放</div>
                                <div>
                                    <Checkbox.Group onChange={this.handleCheckChangeTwo} value={this.state.checkKeys2}>
                                        {
                                            storeListTwo && storeListTwo.map(val => {
                                                return (
                                                    
                                                    <Checkbox value={val.id} className={`flex align-center mgr15`} style={{backgroundColor:'rgb(242 242 242)',padding:'10px 15px',marginBottom:'10px',marginLeft:'0px',float:'left',width:'250px',height:'60px'}} key={ val.id }>
                                                        <div className={`flex align-center`}>
                                                            <div className={`mgl10`}><img src={val.icon}  alt='' width={30} height={30}/></div>
                                                            <div className={`mgl15`}>
                                                                <div className={`fz16 fw-600`}>{val.name}</div>
                                                                {
                                                                    val.type == 1?
                                                                    <Popover
                                                                        content={content2}
                                                                        width={300}
                                                                        title={null}
                                                                        placement={`bottom`}
                                                                        trigger="click"
                                                                        visible={this.state.popVisible2}>
                                                                        <div className={`icon-pencil`} style={{color:'blue'}} onClick={()=>{this.handlePoints('1')}} >
                                                                            {
                                                                                val.input_num>0 ?
                                                                                    <div>积分数 {val.input_num}</div>
                                                                                    :
                                                                                    <div>输入积分</div>
                                                                            }
                                                                        </div>
                                                                    </Popover>
                                                                :null
                                                                }


                                                                {
                                                                    val.type == 3?

                                                                    (
        
                                                                        val.select_coupon?
                                                                            <div style={{color:'blue'}} onClick={()=>{this.showCoupon(2)} }>已选择{val.select_coupon.length || 0}张</div>
                                                                            :
                                                                            <div style={{color:'blue'}} onClick={()=>{this.showCoupon(2)} }>选择优惠券</div>
                                                                    )
                                                                    
                                                                : null
                                                                }
                                                                    
                                                                {/* <div style={{color:'blue'}}>输入积分倍数</div> */}
                                                            </div>
                                                            
                                                        </div>
                                                    </Checkbox>
                                                )
                                            })
                                        }
                                    </Checkbox.Group>
                                </div>
                            </div>
                        </FormItem>
                    {/* </Col>
                    <Col span={4}>
                    </Col>
                </Row> */}
            </Form>

        </Modal>
            <CouponModal onRef={e => { this.couponCreate = e }} sendCoupon={this.handleCouponAdd}></CouponModal>
        </div>
        )
    }
}

export default Form.create({})(withRouter(EquityModal))
