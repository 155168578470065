import React from 'react'
import { Modal, DatePicker, Form } from 'antd'
import moment from 'moment'
const FormItem = Form.Item

class ModalForm extends React.Component{
    state = {
        visible:false,
        modalLoading:false,
        callback:null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        if(this.state.visible){ return  ; }
        this.setState({
            visible : true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields()
    }
    handleOk = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let callback = this.state.callback,
                    res = {};
                res.end_at = moment(values.end_at).format('YYYY-MM-DD')
                if(typeof callback == 'function'){
                    callback(res)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }

    render() {
        let data = this.props || {}
        const { getFieldDecorator } = this.props.form
        const formItemLayout = { labelCol:{ span:7 }, wrapperCol:{ span:17 } }
        return(
            <Modal
                title={data.title || `激活`}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                maskClosable={false}
                confirmLoading={this.state.modalLoading}
                centered={true}
            >
                <Form { ...formItemLayout } className={'model-form'}>
                    <FormItem label="延长有效期至">
                        {getFieldDecorator('end_at', {
                            initialValue: null,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择有效期'
                                }
                            ]
                        })(<DatePicker />)}
                    </FormItem>

                </Form>
            </Modal>
        )
    }
}
export default Form.create({})(ModalForm)
