import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Tabs } from 'antd'
import RecruitPoster from './components/recruit-poster'
import SharePoster from './components/share-poster'
import '@/assets/css/fx/fx.scss'
const FormItem = Form.Item
const { TabPane } = Tabs

class FxCardDiy extends React.Component{
    state = {
        formData:'',
        activeStatus:'0',

    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {

    }

    clickTab = val => {
        this.setState({
            activeStatus:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol:{
                span:3
            },
            wrapperCol:{
                span:20
            }
        }
        return(
            <div className={`shadow-radius`}>
                <Tabs  onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="招募海报" key={`0`}>
                        <RecruitPoster onRef={ e => { this.RecruitPoster = e } }></RecruitPoster>
                    </TabPane>
                    <TabPane tab="推广海报" key={`1`}>
                        <SharePoster onRef={ e => { this.SharePoster = e } }></SharePoster>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
export default Form.create({})(withRouter(FxCardDiy))
