import React from 'react'
import { Form, Input, Radio, Button, Select, Icon, Checkbox, Table, Modal, DatePicker, TimePicker, Switch } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { couponSave, couponDetail } from '@/api/coupon'
import { storeLists } from '@/api/store'
import '@/assets/css/item/item.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import ItemPicker from '@/components/ItemPicker/ItemPicker'
import PropTypes from "prop-types";
import message from '@/utils/message';
import moment from 'moment';
const FormItem = Form.Item
const { TextArea } = Input


class EditForm extends React.Component{
    static contextTypes = {
        setLoading: PropTypes.func
    }
    state = {
        formData: '',
        columns:[
            {
                title:'商品名称',
                dataIndex:'name',
                width:'60%',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>&yen;{rowData.price || '--'}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={() => { this.handleDelItem(rowData,index) }} disabled={!!this.props.match.params.type}>删除</Button>
                        </div>
                    )
                }
            }
        ],
        multipleSelect:true,
        chooseItems:[],
        storeLists:[]
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchStore();
        if(this.props.match.params.id){
            this.fetchData()
        }
    }
    fetchData = () => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        this.context.setLoading(true)
        couponDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.coupon_detail,
                        coupon_extend = detail.coupon_extend || {},
                        formData = {};
                    for(var i in coupon_extend){
                        formData[i] = coupon_extend[i]
                    }
                    for(var j in detail){
                        formData[j] = detail[j]
                    }
                    delete formData.coupon_extend;
                    if(formData.discount_type == 2){
                        formData.random_price_min = formData.discount_price.split('~')[0];
                        formData.random_price_max = formData.discount_price.split('~')[1];
                        formData.discount_price = '';
                    }
                    if(formData.use_time_type == 2 && formData.coupon_type != 3){
                        let time = formData.use_time.split(','),
                            use_time_arr = [];
                        time.forEach(item => {
                            let datas = {}
                            datas.start_at = item.split('~')[0];
                            datas.end_at = item.split('~')[1];
                            use_time_arr.push(datas)
                        })
                        formData.use_time_arr = use_time_arr
                    }
                    if(formData.use_type == 2){
                        formData.today_valid_days = formData.valid_days
                    }else if(formData.use_type == 3){
                        formData.tomorrow_valid_days = formData.valid_days
                    }
                    if(formData.limit_num > 0){
                        formData.limit_num_type = 1;
                    }else {
                        formData.limit_num_type = 0;
                    }
                    formData.suit_goods = formData.goods_list || [];
                    formData.suit_stores = formData.suit_store_ids || []
                    this.setState({
                        formData
                    })

                }
            })
            .finally(() => {
                this.context.setLoading(false)

            })

    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.context.setLoading(true)
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })
    }
    chooseSuitItems = () => {
        let formData = this.state.formData || {};
        this.setState({
            multipleSelect:true,
            chooseItems: formData.suit_goods ? formData.suit_goods : []
        },() => {
            this.ItemPicker.show(res => {
                if(res.length){
                    formData.suit_goods = res
                    this.setState({
                        formData
                    })
                }
            })
        })

    }
    chooseExchangeItem = () => {
        let formData = this.state.formData || {};
        this.setState({
            multipleSelect:false,
            chooseItems: formData.suit_goods ? formData.suit_goods : []
        },() => {
            this.ItemPicker.show(res => {
                if(res.length){
                    formData.suit_goods = [res[0]]
                    this.setState({
                        formData
                    })
                }
            })
        })
    }
    handleDelItem = (rowData,index) => {
        let formData = this.state.formData || {},
            coupon_type = this.props.form.getFieldValue('coupon_type');
        if(coupon_type == 3){
            //兑换商品
            formData.suit_goods = []
        }else {
            let suit_goods = formData.suit_goods;
            for(var i in suit_goods){
                if(suit_goods[i]['id'] == rowData.id){
                    suit_goods.splice(i,1)
                }
            }
            formData.suit_goods = suit_goods
        }
        this.setState({
            formData
        })


    }

    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover':
                    formData[name] = imgs[0];
                    break;
                case 'share_img':
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleAddUseTime = () => {
        let formData = this.state.formData || {},
            use_time_arr = formData.use_time_arr ? formData.use_time_arr : [],
            newData = {start_at: '', ent_at: ''};
        if (use_time_arr.length >= 2) {
            message.error('最多设置两个时段')
            return;
        }

        use_time_arr.push(newData);
        formData.use_time_arr = use_time_arr;
        this.setState({
            formData
        })
    }
    handleDeleteTime = (item,index) => {
        let formData = this.state.formData || {};
        formData.use_time_arr.splice(index,1)
        this.setState({
            formData
        })

    }


    saveData = () => {
        let formData = this.state.formData,
            senData = {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                if(values.discount_type == 2){
                    values.discount_price = `${values.random_price_min}~${values.random_price_max}`
                }
                if(values.use_type == 1){
                    values.start_at = values.start_at ? moment(values.start_at).format('YYYY-MM-DD') : ''
                    values.end_at = values.end_at ? moment(values.end_at).format('YYYY-MM-DD') : ''
                    values.valid_days = ''
                }else if( values.use_type == 2 ) {
                    //领取后当日有效
                    values.start_at = '';
                    values.end_at = '';
                    values.valid_days = values.today_valid_days;
                }else {
                    //领取后次日有效
                    values.start_at = '';
                    values.end_at = '';
                    values.valid_days = values.tomorrow_valid_days;
                }
                if(values.coupon_type != 3){
                    values.use_week =  values.use_week ? values.use_week.join(',') : '';
                    if(values.use_time_type == 1){
                        values.use_time = ''
                    }else {
                        let use_time = []
                        values.use_time_arr.forEach((item) => {
                            item.time = `${item.start_at ? moment(item.start_at).format('HH:mm:ss') : ''}~${item.end_at ? moment(item.end_at).format('HH:mm:ss') : ''}`;
                            use_time.push(item.time)
                        })
                        values.use_time = use_time.join(',')
                    }
                }

                senData = values;
                senData.share_img = formData.share_img;
                if(formData.id){
                    senData.id = formData.id
                }
                if(senData.suit_goods_type == 1){
                    //所有商品可用
                    senData.suit_goods = []
                }else if(senData.coupon_type == 3){
                    //兑换券
                    senData.suit_goods =  formData.suit_goods.length ? [formData.suit_goods[0]['id']] : []
                }else {
                    senData.suit_goods = formData.suit_goods.map(item => { return item.id })
                }
                if(senData.limit_num_type == 0){
                    senData.limit_num = 0
                }
                for(var x in senData){
                    if(typeof senData[x] === 'boolean'){
                        senData[x] = senData[x] ? 1 : 0
                    }
                }
                if(senData.limit_num < 0){
                    message.error('限领数量不能小于0');
                    return ;
                }
                this.context.setLoading(true)
                couponSave(senData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/coupon/lists`
                                    })
                                })

                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })





            }
        })
    }


    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return(
            <div>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <ItemPicker
                    onRef={ e => { this.ItemPicker = e } }
                    selectIds={this.state.chooseItems.length ? this.state.chooseItems.map(item => {return item.id}) : []}
                    items={ this.state.chooseItems.length ? this.state.chooseItems : [] }
                    multiple={ this.state.multipleSelect }
                ></ItemPicker>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    <div className={`msg-title mgb15`}>基本信息</div>
                    <FormItem label={'优惠券类型'}>
                        {getFieldDecorator('coupon_type', {
                            initialValue: data.coupon_type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择优惠券类型'
                                }
                            ]
                        })(
                            <Radio.Group onChange={ this.handleRadioChange } disabled={!!this.props.match.params.id}>
                                <Radio value={1}>满减券</Radio>
                                <Radio value={2}>折扣券</Radio>
                                <Radio value={3}>兑换券</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'优惠券名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入优惠券名称'
                                }
                            ]
                        })(<Input
                            placeholder={'请输入优惠券名称，限30字符'}
                            maxLength={30}
                            disabled={!!this.props.match.params.id}
                            suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/30</span>}
                        />)}
                    </FormItem>
                    {
                        getFieldValue('coupon_type') == 1
                            ?
                            <FormItem label={'优惠金额'}>
                                {getFieldDecorator('discount_type', {
                                    initialValue: data.discount_type || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请设置优惠金额'
                                        }
                                    ]
                                })(
                                    <Radio.Group disabled={!!this.props.match.params.id}>
                                        <Radio value={1} className={`mgb15 block`}>
                                            固定金额
                                            {
                                                getFieldDecorator('discount_price',{
                                                    initialValue:data.discount_price || ''
                                                })(
                                                    <Input addonAfter={`元`} className={`addon-after-input mgl10`} style={{ width:'200px' }} disabled={!!this.props.match.params.id} ></Input>
                                                )
                                            }

                                        </Radio>
                                        <Radio value={2} className={`block`}>
                                            随机金额
                                            {
                                                getFieldDecorator('random_price_min',{
                                                    initialValue:data.random_price_min || ''
                                                })(
                                                    <Input addonAfter={`元`} className={`addon-after-input mgl10`} style={{ width:'200px' }} disabled={!!this.props.match.params.id}></Input>
                                                )
                                            }
                                            <span className={`mgl10 mgr10`}>至</span>
                                            {
                                                getFieldDecorator('random_price_max',{
                                                    initialValue:data.random_price_max || ''
                                                })(
                                                    <Input addonAfter={`元`} className={`addon-after-input`} style={{ width:'200px' }} disabled={!!this.props.match.params.id}></Input>
                                                )
                                            }
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>
                            :
                            null
                    }
                    {
                        getFieldValue('coupon_type') == 2
                            ?
                            <div>
                                <FormItem label={'优惠金额'}>
                                    <div>
                                        打
                                        {getFieldDecorator('discount', {
                                            initialValue: data.discount || ``,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请设置折扣'
                                                }
                                            ]
                                        })(
                                            <Input addonAfter={`折`} className={`addon-after-input mgl10`} style={{ width:'200px' }} disabled={!!this.props.match.params.id}></Input>
                                        )}
                                    </div>
                                </FormItem>
                                <FormItem label={` `}  colon={false}>
                                    最多优惠
                                    {getFieldDecorator('max_discount_price', {
                                        initialValue: data.max_discount_price || ``
                                    })(
                                        <Input addonAfter={`元`} className={`addon-after-input mgl10`} style={{ width:'200px' }} disabled={!!this.props.match.params.id}></Input>
                                    )}
                                </FormItem>
                            </div>
                            :
                            null
                    }
                    {
                        getFieldValue('coupon_type') == 3
                            ?
                            <div>
                                <FormItem label={'兑换商品'}>
                                    {getFieldDecorator('suit_goods', {
                                        initialValue: data.suit_goods || [],
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择兑换商品'
                                            }
                                        ]
                                    })(
                                        <Button type={`primary`} onClick={ this.chooseExchangeItem } disabled={!!this.props.match.params.type}>请选择商品</Button>
                                    )}
                                    <span className={`fi-help-text`}>选择的商品如有sku则所有sku都可用</span>
                                </FormItem>
                                {
                                    data.suit_goods && data.suit_goods.length
                                        ?
                                        <FormItem label={` `} colon={false}>
                                            <Table
                                                size={`middle`}
                                                columns={this.state.columns}
                                                dataSource={[data.suit_goods[0]]}
                                                rowKey={record => record.id}
                                                style={{ width:'600px' }}
                                            ></Table>
                                        </FormItem>
                                        :
                                        null
                                }
                                <FormItem label={'优惠券图片'}>
                                    {getFieldDecorator('cover', {
                                        initialValue: data.cover || ``,
                                    })(
                                        <div className={'clearfix'}>
                                            {
                                                data.cover
                                                    ?
                                                    <PictureCard
                                                        onRef={ e => { this.PictureCard = e } }
                                                        imgs={ data.cover }
                                                        onDel={ e => { this.delImg(e,'cover') } }
                                                    ></PictureCard>
                                                    :
                                                    <ImgAdd
                                                        onRef={ e => { this.ImgAdd = e } }
                                                        onClick={ e => { this.chooseImg(e,'cover') } }
                                                    ></ImgAdd>
                                            }
                                        </div>
                                    )}
                                    <span className={'fi-help-text'}>建议尺寸：750x750像素，单张不超过500k</span>
                                </FormItem>
                            </div>
                            :
                            null
                    }

                    <FormItem label={'使用门槛'}>
                        {getFieldDecorator('use_condition', {
                            initialValue: data.use_condition || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请设置使用门槛'
                                }
                            ]
                        })(
                            <Radio.Group className={`mgt10`} disabled={!!this.props.match.params.id}>
                                <Radio value={1} className={`block mgb15`}>无使用门槛</Radio>
                                <Radio value={2}>
                                    订单满
                                    {
                                        getFieldDecorator('least_price',{
                                            initialValue: data.least_price || ``
                                        })(
                                            <Input addonAfter={`元`} className={` addon-after-input mgl10 `} style={{ width:'200px' }} disabled={!!this.props.match.params.id}></Input>
                                        )
                                    }
                                    <span className={`mgl10 `} >可用</span>
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'发放总量'}>
                        <div>
                            {getFieldDecorator('total_num', {
                                initialValue: data.total_num || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择发放总量'
                                    }
                                ]
                            })(<Input type={'number'} addonAfter={`张`} className={`addon-after-input`} style={{ width:'200px' }} disabled={!!this.props.match.params.type}></Input>)}
                            <span className={`fi-help-text`}>修改优惠券总量时只能增加不能减少，请谨慎设置</span>
                        </div>
                    </FormItem>
                    <FormItem label={'适用门店'}>
                        {getFieldDecorator('suit_store_type', {
                            initialValue: data.suit_store_type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择适用门店'
                                }
                            ]
                        })(
                            <Radio.Group disabled={!!this.props.match.params.type}>
                                <Radio value={1}>全部</Radio>
                                <Radio value={2}>指定门店可用</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    {
                        getFieldValue('suit_store_type') == 2
                            ?
                            <FormItem label={'选择门店'}>
                                {getFieldDecorator('suit_stores', {
                                    initialValue: data.suit_stores || [],
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择适用门店'
                                        }
                                    ]
                                })(
                                    <Select mode="multiple" style={{width:'300px'}} placeholder="请选择" disabled={!!this.props.match.params.type}>
                                        {
                                            this.state.storeLists.map(store => (
                                                <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                            ))
                                        }
                                    </Select>
                                )}
                            </FormItem>
                            :
                            null

                    }
                    {
                        getFieldValue('coupon_type') == 3
                            ?
                            null
                            :
                            <div>
                                <FormItem label={'适用商品'}>
                                    {getFieldDecorator('suit_goods_type', {
                                        initialValue: data.suit_goods_type || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择适用门店'
                                            }
                                        ]
                                    })(
                                        <Radio.Group disabled={!!this.props.match.params.type}>
                                            <Radio value={1}>所有商品可用</Radio>
                                            <Radio value={2}>指定商品可用</Radio>
                                            <Radio value={3}>指定商品不可用</Radio>
                                        </Radio.Group>
                                    )}
                                    {
                                        getFieldValue('suit_goods_type') == 2
                                            ?
                                            <span className={`fi-help-text`}>选择的商品如有SKU则所有SKU都可用</span>
                                            :
                                            null

                                    }
                                    {
                                        getFieldValue('suit_goods_type') == 3
                                            ?
                                            <span className={`fi-help-text`}>选择的商品如有SKU则所有SKU都不可用</span>
                                            :
                                            null

                                    }
                                </FormItem>
                                {
                                    [2,3].includes(getFieldValue('suit_goods_type'))
                                        ?
                                        <div>
                                            <FormItem label={'选择商品'}>
                                                {
                                                    getFieldDecorator('suit_goods',{
                                                        initialValue:data.suit_goods || []
                                                    })(
                                                        <Button type={`primary`} onClick={ this.chooseSuitItems } disabled={!!this.props.match.params.type}>请选择商品</Button>
                                                    )
                                                }
                                            </FormItem>
                                            {
                                                data.suit_goods && data.suit_goods.length
                                                    ?
                                                    <FormItem label={` `} colon={false}>
                                                        <Table
                                                            size={`middle`}
                                                            columns={this.state.columns}
                                                            dataSource={data.suit_goods}
                                                            rowKey={record => record.id}
                                                            style={{ width:'600px' }}
                                                        ></Table>
                                                    </FormItem>
                                                    :
                                                    null
                                            }

                                        </div>

                                        :
                                        null
                                }
                            </div>

                    }
                    <FormItem label={'使用场景'}>
                        {getFieldDecorator('use_scene', {
                            initialValue: data.use_scene || [1],
                            rules: [
                                {
                                    required: true,
                                    message: '请选择使用场景'
                                }
                            ]
                        })(
                            <Checkbox.Group disabled={!!this.props.match.params.id}>
                                <Checkbox value={1} >线上订单</Checkbox>
                                <Checkbox value={3} >线下直接核销</Checkbox>
                                {
                                    getFieldValue('coupon_type') == 3
                                        ?
                                        null
                                        :
                                        <Checkbox value={2}>自助买单</Checkbox>
                                }
                            </Checkbox.Group>
                        )}
                        <span className={`fi-help-text`}>
                            若希望活动商品同时参与满减/折等其他活动， 需在对应类型的活动配置中同时设置与优惠券叠加才可生效；
                            <br/>
                            当选择自助买单或线下直接核销时，券消耗后不予商品做关联。
                        </span>
                    </FormItem>
                    <FormItem label={'使用时间'}>
                        {getFieldDecorator('use_type', {
                            initialValue: data.use_type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择使用时间'
                                }
                            ]
                        })(
                            <Radio.Group disabled={!!this.props.match.params.id}>
                                <Radio value={1} className={`block mgb15`}>
                                    {
                                        getFieldDecorator('start_at',{
                                            initialValue:data.start_at ? moment(data.start_at, 'YYYY-MM-DD')   : null
                                        })(
                                            <DatePicker disabled={!!this.props.match.params.type}  />
                                        )
                                    }
                                    <span className={`mgl10 mgr10`}>至</span>
                                    {
                                        getFieldDecorator('end_at',{
                                            initialValue:data.end_at ? moment(data.end_at, 'YYYY-MM-DD')   : null
                                        })(
                                            <DatePicker disabled={!!this.props.match.params.type} />
                                        )
                                    }
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    领取后当日生效
                                    {
                                        getFieldDecorator('today_valid_days',{
                                            initialValue:data.today_valid_days || ''
                                        })(
                                            <Input type={'number'}  style={{ width:'80px' }} className={`mgl10`} disabled={!!this.props.match.params.type}></Input>
                                        )
                                    }
                                    <span className={`mgl10`}>天内有效</span>
                                </Radio>
                                <Radio value={3} className={`block`}>
                                    领取后次日生效
                                    {
                                        getFieldDecorator('tomorrow_valid_days',{
                                            initialValue:data.tomorrow_valid_days || ''
                                        })(
                                            <Input type={'number'}  style={{ width:'80px' }} className={`mgl10`} disabled={!!this.props.match.params.type}></Input>
                                        )
                                    }
                                    <span className={`mgl10`}>天内有效</span>
                                </Radio>
                            </Radio.Group>
                        )}
                        <span className={`fi-help-text`}>
                            使用的有效期编辑保存后，仅对后续领取的券生效。
                        </span>
                    </FormItem>
                    {
                        getFieldValue('coupon_type') == 3
                            ?
                            null
                            :
                            <div>
                                <FormItem label={'生效时间'}>
                                    <div className={``}>
                                        {getFieldDecorator('use_week', {
                                            initialValue: data.use_week || [0,1,2,3,4,5,6],
                                        })(
                                            <Checkbox.Group  disabled={!!this.props.match.params.type}>
                                                <Checkbox value={1}>周一</Checkbox>
                                                <Checkbox value={2}>周二</Checkbox>
                                                <Checkbox value={3}>周三</Checkbox>
                                                <Checkbox value={4}>周四</Checkbox>
                                                <Checkbox value={5}>周五</Checkbox>
                                                <Checkbox value={6}>周六</Checkbox>
                                                <Checkbox value={0}>周日</Checkbox>
                                            </Checkbox.Group>
                                        )}
                                    </div>
                                </FormItem>
                                <FormItem label={` `} colon={false}>
                                    <div className={``}>
                                        {getFieldDecorator('use_time_type', {
                                            initialValue: data.use_time_type || 1
                                        })(
                                            <Radio.Group  disabled={!!this.props.match.params.type}>
                                                <Radio value={1}>全天时段</Radio>
                                                <Radio value={2}>指定时段</Radio>
                                            </Radio.Group>
                                        )}
                                    </div>
                                </FormItem>
                                <FormItem label={` `} colon={false}>
                                    {
                                        getFieldValue('use_time_type') == 2
                                            ?
                                            <div>
                                                {
                                                    data.use_time_arr && data.use_time_arr.map((item,index) => (
                                                        <div key={index}>
                                                            {
                                                                getFieldDecorator(`use_time_arr[${index}].start_at`,{
                                                                    initialValue:item.start_at ? moment(item.start_at,'HH:mm:ss') : null
                                                                })(
                                                                    <TimePicker disabled={!!this.props.match.params.type} />
                                                                )
                                                            }
                                                            <span className={`mgl10 mgr10`}>至</span>
                                                            {
                                                                getFieldDecorator(`use_time_arr[${index}].end_at`,{
                                                                    initialValue:item.end_at ? moment(item.end_at,'HH:mm:ss') : null
                                                                })(
                                                                    <TimePicker disabled={!!this.props.match.params.type} />
                                                                )
                                                            }
                                                            <Button type={`link`} onClick={() => { this.handleDeleteTime(item,index) }} disabled={!!this.props.match.params.type}>删除</Button>
                                                        </div>
                                                    ))
                                                }
                                                <Button type={`link`} size={`small`} onClick={ this.handleAddUseTime } disabled={!!this.props.match.params.type}>添加时间段（最多两个）</Button>
                                            </div>
                                            :
                                            null

                                    }
                                    <span className={`fi-help-text`}>若设定时间段，则优惠券只会在有效期内的指定时间段可用</span>
                                </FormItem>
                            </div>

                    }

                    <FormItem label={'使用说明'}>
                        {
                            getFieldDecorator('desc',{
                                initialValue: data.desc || '',
                                rules: []
                            })(
                                <TextArea rows={6} placeholder={'优惠券使用说明'} style={{ width:'350px' }} disabled={!!this.props.match.params.type}  />
                            )
                        }
                    </FormItem>
                    <FormItem label={'分享标题'}>
                        {getFieldDecorator('share_title', {
                            initialValue: data.share_title || ``,
                        })(<Input placeholder={'请输入'} maxLength={30} disabled={!!this.props.match.params.type} />)}
                        <span className={`fi-help-text`}>将展示在分享链接中</span>
                    </FormItem>
                    <FormItem label={'分享图'}>
                        {getFieldDecorator('share_img', {
                            initialValue: data.share_img || ``,
                        })(
                            <div className={'clearfix'}>
                                {
                                    data.share_img
                                        ?
                                        <PictureCard
                                            onRef={ e => { this.PictureCard = e } }
                                            imgs={ data.share_img }
                                            onDel={ e => { this.delImg(e,'share_img') } }
                                        ></PictureCard>
                                        :
                                        <ImgAdd
                                            onRef={ e => { this.ImgAdd = e } }
                                            onClick={ e => { this.chooseImg(e,'share_img') } }
                                        ></ImgAdd>
                                }
                            </div>
                        )}
                        <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M</span>
                    </FormItem>
                    <div className={`msg-title mgb15`}>领取和使用规则</div>
                    <FormItem label={'领取条件'}>
                        {getFieldDecorator('is_free', {
                            initialValue: data.is_free || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请设置领取条件'
                                }
                            ]
                        })(
                            <Radio.Group  className={`mgt10`} disabled={!!this.props.match.params.type}>
                                <Radio value={1} className={`mgb15 block`}>免费领取</Radio>
                                <Radio value={2} className={`block`}>
                                    付费领取
                                    {
                                        getFieldDecorator('cost_price',{
                                            initialValue:data.cost_price || ''
                                        })(
                                            <Input type={`number`}  addonAfter={`元`} className={` addon-after-input mgl10 `} style={{ width:'200px' }} disabled={!!this.props.match.params.type} />
                                        )
                                    }
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'领取人限制'}>
                        {getFieldDecorator('receive_type', {
                            initialValue: data.receive_type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请设置领取人限制'
                                }
                            ]
                        })(
                            <Radio.Group  className={`mgt10`} disabled={!!this.props.match.params.type}>
                                <Radio value={1} className={`block mgb15`}>所有人都可领取</Radio>
                                <Radio value={2} className={`block`}>仅限新用户可领</Radio>
                            </Radio.Group>
                        )}
                        <span className={`fi-help-text`}>
                            在本小程序无消费记录的用户，即为新用户
                        </span>
                    </FormItem>
                    <FormItem label={'领取数量限制'}>
                        {getFieldDecorator('limit_num_type', {
                            initialValue: data.limit_num_type || 0,
                            rules: [
                                {
                                    required: true,
                                    message: '请设置领取数量限制'
                                }
                            ]
                        })(
                            <Radio.Group  className={`mgt10`} disabled={!!this.props.match.params.type}>
                                <Radio value={0} className={`block mgb15`}>不限数量</Radio>
                                <Radio value={1} className={`block`}>
                                    每人限领
                                    {
                                        getFieldDecorator('limit_num',{
                                            initialValue:data.limit_num || ''
                                        })(
                                            <Input type={'number'}  style={{ width:'80px' }} className={`mgl10 mgr10`} disabled={!!this.props.match.params.type}></Input>
                                        )
                                    }
                                    张
                                </Radio>
                            </Radio.Group>
                        )}
                        <span className={`fi-help-text`}>不填或者填0代表不限数量</span>
                    </FormItem>
                    <FormItem label={'公开设置'}>
                        {getFieldDecorator('is_open', {
                            initialValue: data.is_open == undefined ? true : !!data.is_open ,
                            valuePropName:'checked'
                        })(
                            <Switch disabled={!!this.props.match.params.type}></Switch>
                        )}
                        <span className={`fi-help-text`}>
                            设置后，符合条件的优惠券将展示在商品详情，购物车和领券中心，用户可主动领取
                        </span>
                    </FormItem>
                    <FormItem label={'分享设置'}>
                        {getFieldDecorator('can_share', {
                            initialValue: data.can_share == undefined ? true : !!data.can_share,
                            valuePropName:'checked'
                        })(
                            <Switch disabled={!!this.props.match.params.type}></Switch>
                        )}
                        <span className={`fi-help-text`}>
                            设置后，优惠券分享给好友后，好友可以在活动页中直接领取
                        </span>
                    </FormItem>

                    <FormItem label={'到期提醒'}>
                        <div>
                            {getFieldDecorator('is_notice', {
                                initialValue: data.is_notice == undefined ? true : !!data.is_notice,
                                valuePropName:'checked'
                            })(
                                <Switch disabled={!!this.props.match.params.type}></Switch>
                            )}
                        </div>
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <div className={`lh25`}>
                            优惠券过期前
                            {getFieldDecorator('notice_day', {
                                initialValue: data.notice_day || 1,
                            })(
                                <Select  style={{width:'80px'}} placeholder="请选择" className={`mgl5 mgr5`} disabled={!!this.props.match.params.type}>
                                    <Select.Option value={1}>1</Select.Option>
                                    <Select.Option value={2}>2</Select.Option>
                                    <Select.Option value={3}>3</Select.Option>
                                    <Select.Option value={4}>4</Select.Option>
                                    <Select.Option value={5}>5</Select.Option>
                                    <Select.Option value={6}>6</Select.Option>
                                    <Select.Option value={7}>7</Select.Option>
                                    <Select.Option value={8}>8</Select.Option>
                                    <Select.Option value={9}>9</Select.Option>
                                    <Select.Option value={10}>10</Select.Option>
                                </Select>
                            )}
                            天提醒
                        </div>
                        <span className={`fi-help-text`}>
                            设置后，优惠券过期前会给用户发送消息提醒。注意需要小于优惠券的有效期，<br/>否则用户收不到消息
                        </span>
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={`primary`} onClick={ this.saveData } disabled={!!this.props.match.params.type}>立即保存</Button>
                    </FormItem>

                </Form>

            </div>
        )
    }


}

export default Form.create({})(withRouter(EditForm))
