import React from 'react'
import { withRouter } from 'react-router-dom'
import '@/assets/css/application/index.scss'
import message from '@/utils/message';
class Application extends React.Component{
    state = {}
    componentDidMount() {}
    handlePath = (e) => {
        let url = e.currentTarget.dataset.url || ``;
        if(url){
            this.props.history.push({
                pathname:url
            })
        }else {
            message.info('敬请期待');
            return ;
        }
        console.log(url)
    }
    render() {
        return(
            <div className={`shadow-radius`}>
                <div className={`app-title`}>为你推荐</div>
                <div className={`flex justify-between align-center mgt25`}>
                    <div className={`app-recommend-item`} onClick={ this.handlePath } data-url="/coupon/lists">
                        <div className={`left`} style={{ backgroundImage:`url('https://img.cxkoo.com/chengxuan/1/2021-11-08/c2082f5c2db3c38521835129b6025f38.png')` }}></div>
                        <div className={`right`}>
                            <div className={`title`}>优惠券</div>
                            <div className={`desc`}>
                                优惠券是一种促进流量和销量的促销工具。借助优惠券，您可以提高转化率并引导进店购买。
                            </div>
                        </div>
                    </div>
                    <div className={`app-recommend-item`}>
                        <div className={`left`} style={{ backgroundImage:`url('https://img.cxkoo.com/chengxuan/1/2021-11-08/77f25de35e98f9ff7904bde738b1dd24.png')` }}></div>
                        <div className={`right`}>
                            <div className={`title`}>限时折扣</div>
                            <div className={`desc`}>
                                限时折扣是一种商品在限定时间内打折的工具。给用户一种下单紧迫感，提升客单价。
                            </div>
                        </div>
                    </div>
                    <div className={`app-recommend-item`}>
                        <div className={`left`} style={{ backgroundImage:`url('https://img.cxkoo.com/chengxuan/1/2021-11-08/a98fe41810590eee7daad667e7fb7ed3.png')` }}></div>
                        <div className={`right`}>
                            <div className={`title`}>多件多折</div>
                            <div className={`desc`}>
                                多件多折是一种可以创建买得越多省得越多的促销工具，提供满金额/满件折扣优惠。
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`app-title mgt25`}>营销玩法</div>
                <div className={`flex justify-between align-center flex-wrap mgt25`} style={{ paddingBottom:'50px' }}>
                    <div className={`cx-app-item`} onClick={ this.handlePath } data-url="/coupon/lists">
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/72090943efabd2bbd722835b4904b7f3.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>优惠券</div>
                            <div className={`txt2`}>优惠券是一种促进流量和销量的促销工具。借助优惠券，您可以提高转化率并引导进店购买。</div>
                        </div>
                    </div>
                    <div className={`cx-app-item`}>
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/80b805fe1a6207ce55d4fe7642d742af.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>优惠券包</div>
                            <div className={`txt2`}>优惠券打包发放，用户可以一次性领取多张</div>
                        </div>
                    </div>
                    <div className={`cx-app-item`}>
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/b566817c6ef890bf7b403da3d2cdc9a1.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>新人礼</div>
                            <div className={`txt2`}>新人礼是一种促进新客完成首次购买的工具，可以快速提升新客转化！</div>
                        </div>
                    </div>
                    <div className={`cx-app-item`}>
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/3618379913c830d58349c7fdd3d52d22.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>邀请有礼</div>
                            <div className={`txt2`}>邀请有礼是一种给予奖励的方式引导客户自发老带新传播。精准拓客同时提高粉丝活跃！</div>
                        </div>
                    </div>
                    <div className={`cx-app-item`}>
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/0ace18ca88d2d24ec6169c6e6e22c8c6.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>完善信息</div>
                            <div className={`txt2`}>完善信息是一种信息收集的工具，可以更好的对客户进行划分管理！</div>
                        </div>
                    </div>
                    <div className={`cx-app-item`}>
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/72090943efabd2bbd722835b4904b7f3.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>发券宝</div>
                            <div className={`txt2`}>发券宝是一种单独针对一类客户发放优惠券，通过定向发券，促进新客转化、老客复购。</div>
                        </div>
                    </div>
                    <div className={`cx-app-item`}>
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/9067b815dbeeaa1900200afe05ec9b7a.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>限时折扣</div>
                            <div className={`txt2`}>限时折扣是一种商品在限定时间内打折的工具。给用户一种下单紧迫感，提升客单价。</div>
                        </div>
                    </div>
                    <div className={`cx-app-item`}>
                        <div className={`left`}>
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-08/4f634abb6aae34680efe2053a9768485.png" className={`item-img`}></img>
                        </div>
                        <div className={`right`}>
                            <div className={`txt1`}>多件多折</div>
                            <div className={`txt2`}>多件多折是一种可以创建买得越多省得越多的促销工具，提供满金额/满件折扣优惠。</div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default withRouter(Application)
