import React, { Component } from 'react'
import { Form, Input, Button, Select, message, InputNumber, Radio, Checkbox, Tooltip, Switch, Icon } from 'antd';
import { withRouter } from 'react-router-dom'
import { getConfig, setConfig } from '@/api/config'
import { adminDetail } from '@/api/admin'
import Loading from '@/components/Loading'
import Store from '@/redux/store.js'
const FormItem = Form.Item;

const arr_5 = Array.from(Array(5), (_, i) => i + 1);
const arr_12 = Array.from(Array(12), (_, i) => i + 1);
const arr_31 = Array.from(Array(31), (_, i) => i + 1);
class BaseForm extends Component {
    state = {
        formData: {
            date_type: 1,
            year_month_num: 1,
            day_num: 1,
            goods_money: undefined,
            goods_points: undefined,
            course_money: undefined,
            course_points: undefined
        },
        isLoading: false,
        detail: {},
        version_id: 0,
    }

    componentDidMount() {
        this.fetchData()
        this.fetchAdmin()
    }

    fetchData() {
        this.setState({ isLoading: true })
        getConfig({ key: 'points_general_rule' }).then(res => {
            if (res.code == 1) {
                let { value } = res.data.config;
                if (value) {
                    let formData = this.state.formData;
                    value = JSON.parse(value)
                    formData = { ...formData, ...value.validity, ...value.grant }

                    this.setState({
                        formData,
                        detail: value
                    })
                }
            }
            this.setState({
                showEditor: true
            })
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    fetchAdmin = () => {

        let storeData = Store.getState()
        let shop = storeData.shop
        if (shop) {
            this.setState({
                version_id: shop.version_id
            })
        } else {
            adminDetail({})
                .then(res => {
                    if (res.code == 1) {
                        let shop = res.data.shop
                        this.setState({
                            version_id: shop.version_id
                        })
                    }
                })
        }

    }


    handleNumberChange = name => {
        return val => {
            if (typeof val === 'number') {

                let formData = this.state.formData
                formData[name] = val
                this.setState({
                    formData
                })
            }
        }
    }

    handleSaveData = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let sendData = {}, data = {}, formData = this.state.formData;

                data.validity_rule = values.validity_rule
                data.validity = {
                    date_type: formData.date_type,
                    year_month_num: formData.year_month_num,
                    day_num: formData.day_num,
                }

                data.grant_rule = values.grant_rule
                data.grant = {
                    goods_money: formData.goods_money,
                    goods_points: formData.goods_points,
                    course_money: formData.course_money,
                    course_points: formData.course_points,
                    goods_switch: values.grant_rule.includes(1) ? 1 : 0,
                    course_switch: values.grant_rule.includes(2) ? 1 : 0
                }
                data.points_max = values.points_max

                sendData['key'] = 'points_general_rule'
                sendData.value = data

                this.setState({ isLoading: true })
                setConfig(sendData).then(res => {
                    if (res.code == 1) {
                        message.success('保存成功');
                        setTimeout(() => {
                            this.handleBack()
                        }, 500);
                    }

                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
            }
        })
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        const formData = this.state.formData
        const data = this.state.detail || {}

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>通用积分规则设置</div>
                <FormItem label={
                    <span>
                        <span>积分有效期</span>
                        <Tooltip placement={`right`} title={`积分有效期规则仅适用于规则生效后发放的积分，不影响历史发放积分`}>
                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                        </Tooltip>
                    </span>
                } >
                    {getFieldDecorator('validity_rule', {
                        initialValue: data.validity_rule || 1,
                        rules: [
                            {
                                required: true,
                                message: '积分有效期'
                            }
                        ]
                    })(
                        <Radio.Group   >
                            <Radio value={1}>永久有效 </Radio>
                            <Radio value={2}>逐笔过期</Radio>
                            <Radio value={3}>逐月过期</Radio>
                            <Radio value={4}>逐年过期</Radio>
                        </Radio.Group>
                    )}

                    {
                        getFieldValue('validity_rule') == 2 ?
                            <div className="content-head w650">
                                <div>
                                    每笔积分，在发放之日起
                                    <Select style={{ width: 100 }} className='mgl10' value={formData.year_month_num} onChange={e => { this.handleSelectChange(e, 'year_month_num') }}>
                                        {
                                            (formData.date_type == 1 ? arr_5 : arr_12).map(val => {
                                                return <Select.Option value={val}>{val}</Select.Option>
                                            })
                                        }
                                    </Select>
                                    <Select style={{ width: 100 }} className='mgl10 mgr10' value={formData.date_type} onChange={e => { this.handleSelectChange(e, 'date_type') }} >
                                        <Select.Option value={1}>年</Select.Option>
                                        <Select.Option value={2}>月</Select.Option>
                                    </Select>
                                    后过期
                                </div>
                                <p className='fi-help-text pdb10'>“年”支持1-5年，“月”支持1-12个月</p>
                            </div> : null
                    }
                    {
                        getFieldValue('validity_rule') == 3 ?
                            <div className="content-head w650">
                                <div>
                                    每笔积分，在发放之日起
                                    <Select style={{ width: 100 }} className='mgl10' value={formData.year_month_num} onChange={e => { this.handleSelectChange(e, 'year_month_num') }}>
                                        {
                                            (formData.date_type == 1 ? arr_5 : arr_12).map(val => {
                                                return <Select.Option value={val}>{val}</Select.Option>
                                            })
                                        }
                                    </Select>
                                    <Select style={{ width: 100 }} className='mgl10 mgr10' value={formData.date_type} onChange={e => { this.handleSelectChange(e, 'date_type') }} >
                                        <Select.Option value={1}>年</Select.Option>
                                        <Select.Option value={2}>月</Select.Option>
                                    </Select>
                                    后的
                                    <Select style={{ width: 100 }} className='mgl10 mgr10' value={formData.day_num} onChange={e => { this.handleSelectChange(e, 'day_num') }} >
                                        {
                                            arr_31.map(val => {
                                                return <Select.Option value={val}>{val}</Select.Option>
                                            })
                                        }
                                    </Select>
                                    日过期
                                </div>
                                <p className='fi-help-text pdb10'>
                                    <p>此处为自然年或自然月</p>
                                    <p>示例：如设置1月后的1日过期，则5月获取的所有积分将在6月30日23点59分59秒过期；</p>
                                    <p>如设置1年后的1日过期，则23年5月获取的所有积分将在24年5月31日23点59分59秒过期；</p>
                                </p>
                            </div> : null
                    }
                    {
                        getFieldValue('validity_rule') == 4 ?
                            <div className="content-head w650">
                                <div>
                                    每笔积分，在发放之日起
                                    <Select style={{ width: 100 }} className='mgl10 mgr10' value={formData.year_month_num} onChange={e => { this.handleSelectChange(e, 'year_month_num') }}>
                                        {
                                            arr_5.map(val => {
                                                return <Select.Option value={val}>{val}</Select.Option>
                                            })
                                        }
                                    </Select>
                                    年后的
                                    <Select style={{ width: 100 }} className='mgl10 mgr10' value={formData.day_num} onChange={e => { this.handleSelectChange(e, 'day_num') }} >
                                        {
                                            arr_31.map(val => {
                                                return <Select.Option value={val}>{val}</Select.Option>
                                            })
                                        }
                                    </Select>
                                    日过期
                                </div>
                                <p className='fi-help-text pdb10'>示例：如设置1年后的1月1日过期，则23年获取的所有积分将在24年1月1日0点过期；</p>
                            </div> : null
                    }

                </FormItem>

                <FormItem label={"积分发放规则"} >
                    {getFieldDecorator('grant_rule', {
                        initialValue: data.grant_rule || [],
                    })(
                        <Checkbox.Group onChange={this.handleExtraPrize} style={{marginTop:'8px'}}>
                            <div>
                                <Checkbox value={1}> 购买商品
                                    <Tooltip placement={`right`} title={`设置成功后，购买店内所有商品都会按照规则赠送积分，包含限时折扣，拼团，积分商城等；消费积分在交易成功后发放。`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                    </Tooltip>
                                </Checkbox>
                                <div className="content-head w650 mgt10">
                                    每消费 <InputNumber onChange={this.handleNumberChange("goods_money")} value={formData.goods_money} style={{ width: 120 }} min={0} precision={2} className='mgl10 mgr10' /> 元，获得 <InputNumber onChange={this.handleNumberChange("goods_points")} value={formData.goods_points} style={{ width: 120 }} min={0} precision={0} className='mgl10 mgr10' /> 积分
                                    <p className='pdb10'></p>
                                </div>
                            </div>
                            {
                                [5, 6, 7].includes(this.state.version_id) ?
                                    <div className='mgt10'>
                                        <Checkbox value={2}>购买课程</Checkbox>
                                        <div className="content-head w650 mgt10">
                                            每消费 <InputNumber onChange={this.handleNumberChange("course_money")} value={formData.course_money} style={{ width: 120 }} min={0} precision={2} className='mgl10 mgr10' /> 元，获得 <InputNumber onChange={this.handleNumberChange("course_points")} value={formData.course_points} style={{ width: 120 }} min={0} precision={0} className='mgl10 mgr10' /> 积分
                                            <p className='pdb10'></p>
                                        </div>
                                    </div> : null
                            }


                        </Checkbox.Group>
                    )}
                </FormItem>
                <FormItem label={'积分获取上限'}>
                    每个客户每天通过消费最多获取
                    {getFieldDecorator('points_max', {
                        initialValue: data.points_max || undefined,
                    })(<InputNumber
                        style={{ width: 120 }}
                        min={0}
                        precision={0}
                        className='mgl10 mgr10'
                    />)}
                    积分
                    <span className={'fi-help-text'}> 仅针对每消费金额X元赠送的积分有效</span>
                </FormItem>

                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button type='primary' onClick={this.handleSaveData}>保存</Button>
                    <Button type='default' className='mgl20' onClick={this.handleBack}>取消</Button>
                </FormItem>

                {this.state.isLoading ? <Loading /> : null}
            </Form>
        )
    }
}

class ConsumeForm extends Component {
    state = {
        formData: {
            order_min_price: 0,
            order_max_price: 0,
            order_max_percentage: 0
        },
        isLoading: false,
        detail:{}
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        this.setState({ isLoading: true })
        getConfig({ key: 'points_cost_rule' }).then(res => {
            if (res.code == 1) {
                let { value } = res.data.config;
                if (value) {
                    let formData = this.state.formData;
                    value = JSON.parse(value)
                    formData = { ...formData,  ...value }

                    this.setState({
                        formData,
                        detail: value
                    })
                }
            }
            this.setState({
                showEditor: true
            })
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleChange = name => {
        return e => {
            let value = e.target.value;
            let formData = this.state.formData
            formData[name] = value
            this.setState({
                formData
            })
        }
    }
    handleSaveData = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let sendData = {},formData = this.state.formData,
                data={
                    order_min_price: formData.order_min_price,
                    order_max_price: formData.order_max_price,
                    order_max_percentage: formData.order_max_percentage
                };

                data.deduction_switch = values.deduction_switch?1:0;
                data.deduction_points=values.deduction_points|| 0;
                data.order_threshold=values.order_threshold ||1;
                data.deduction_max=values.deduction_max ||1;

                sendData['key'] = 'points_cost_rule'
                sendData.value = data

                this.setState({ isLoading: true })
                setConfig(sendData).then(res => {
                    if (res.code == 1) {
                        message.success('保存成功');
                        setTimeout(() => {
                            this.handleBack()
                        }, 500);
                    }

                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
            }
        })
    }

    handleBack = () => {
        this.props.history.goBack()
    }
    render() {
        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        const radioSty = { display: "block", marginBottom: "8px" }

        const formData = this.state.formData
        const data = this.state.detail || {};

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>积分消耗规则设置</div>
                <FormItem label={'抵扣比例'}>
                    {getFieldDecorator('deduction_points', {
                        initialValue: data.deduction_points || undefined,
                    })(<InputNumber
                        style={{ width: 120 }}
                        min={0}
                        precision={0}
                        className=' mgr10'
                    />)}
                    积分=1元
                </FormItem>
                <FormItem label={'抵扣规则'}>
                    {getFieldDecorator('deduction_switch', {
                        initialValue: !!data.deduction_switch,
                        valuePropName: 'checked'
                    })(
                        <Switch />
                    )}
                </FormItem>
                {
                    getFieldValue('deduction_switch') ?
                        <>
                            <FormItem label={"订单金额门槛"}>
                                {getFieldDecorator('order_threshold', {
                                    initialValue: data.order_threshold || 1
                                })(
                                    <Radio.Group style={{marginTop:'8px'}} >
                                        <Radio value={1} style={radioSty}>不限制</Radio>
                                        <p className='fi-help-text pdb10'>默认订单金额最低为1元</p>
                                        <Radio value={2} style={radioSty}>订单金额最低为
                                            <Input addonAfter={'元'} type='number' style={{ width: 120 }} className='mgl10 mgr10' onChange={this.handleChange('order_min_price')} value={formData.order_min_price} />
                                            时可抵扣
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>
                            <FormItem label={"抵扣金额上限"}>
                                {getFieldDecorator('deduction_max', {
                                    initialValue: data.deduction_max || 1
                                })(
                                    <Radio.Group style={{marginTop:'8px'}} >
                                        <Radio value={1} style={radioSty}>不限制</Radio>

                                        <Radio value={2} style={radioSty}>每笔订单最多抵扣
                                            <Input addonAfter={'元'} type='number' style={{ width: 120 }} className='mgl10 mgr10' onChange={this.handleChange('order_max_price')} value={formData.order_max_price} />
                                        </Radio>
                                        <Radio value={3} style={radioSty}>每笔订单最多抵扣
                                            <Input addonAfter={'%'} type='number' style={{ width: 120 }} className='mgl10 mgr10' onChange={this.handleChange('order_max_percentage')} value={formData.order_max_percentage} />
                                        </Radio>
                                        <p className='fi-help-text pdb10'>如：设置兑换比例为10积分=1元，则客户每次可用积分为10的整数倍。客户有25积分，订单金额为26.9元，客户可以使用20积分抵掉2元。</p>
                                    </Radio.Group>
                                )}
                            </FormItem>


                        </> : null
                }


                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button type='primary' onClick={this.handleSaveData}>保存</Button>
                    <Button type='default' className='mgl20' onClick={this.handleBack}>取消</Button>
                </FormItem>

                {this.state.isLoading ? <Loading /> : null}
            </Form>
        )
    }
}

const BaseRules = Form.create({})(withRouter(BaseForm))
const ConsumeRules = Form.create({})(withRouter(ConsumeForm))
class PointsRulesEdit extends Component {

    render() {
        let type = this.props.match.params.id;
        return (
            <div className={`shadow-radius`}>
                {
                    type == 1 ? <BaseRules /> : null
                }
                {
                    type == 2 ? <ConsumeRules /> : null
                }
            </div>
        )
    }
}
export default withRouter(PointsRulesEdit)
