/**
 * 倒计时组件
 * @type 11
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip, DatePicker} from 'antd'
import PropTypes from "prop-types";
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import Gallery from '@/components/Gallery/Gallery'
import moment from 'moment';
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 17 }}

class tpl_ctrl_type11 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }
    state = {
        moduleData:{},
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    handleStyleChange = (e,name) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData)

    }
    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }
    changeTxt = (e) => {
        let moduleData = this.state.moduleData,
            value = e.target.value,
            name = e.currentTarget.dataset.name || ``;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData);
    }
    chooseImg = (e) => {
        let moduleData = this.state.moduleData,
            name = e.currentTarget.dataset.name || ``;
        this.Gallery.show(res => {
            if(res){
                moduleData.content.pic = res[0];
                if(!moduleData.content.pic){
                    moduleData.content.pic = 'https://img.cxkoo.com/chengxuan/1/2021-12-10/58b1e8a82125ec98473f4004c5312d3a.png'
                }
                this.context.updateDesign(moduleData)
            }
        })
    }

    delImg = (e) => {
        e.stopPropagation();
        let moduleData = this.state.moduleData;
        moduleData.content.pic = 'https://img.cxkoo.com/chengxuan/1/2021-12-10/58b1e8a82125ec98473f4004c5312d3a.png';
        this.context.updateDesign(moduleData)
    }
    handleTimeChange = (e,name) => {
        let moduleData = this.state.moduleData;
        moduleData.content[name] = moment(e).format('YYYY/MM/DD HH:mm:ss')
        console.log(moduleData.content)
        this.context.updateDesign(moduleData)
    }
    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <div className={`ctrl-right-title`}>倒计时</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`组件样式`} { ...ctrlItemLayout }>
                                    <Radio.Group value={moduleData.content.style} onChange={(e) => { this.handleStyleChange(e,'style') }} >
                                        <Radio value={1}>上下排列</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`活动标题`} { ...ctrlItemLayout }>
                                    <Input
                                        type={'text'}
                                        value={moduleData.content.title}
                                        onChange={ this.changeTxt }
                                        data-name={'title'}
                                    />
                                </FormItem>
                                <FormItem label={`标题颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.titleColor} callback={ (color) => { this.handleColorChange(color,'titleColor') } } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('titleColor','#FFD40D')}>重置</Button>
                                </FormItem>

                                <FormItem label={`副标题`} { ...ctrlItemLayout }>
                                    距
                                    <Input
                                        type={'text'}
                                        value={moduleData.content.subTitle}
                                        style={{ width:'100px' }}
                                        className={`mgl5 mgr5`}
                                        onChange={ this.changeTxt }
                                        data-name={'subTitle'}
                                    />开始/结束
                                    <span className={`fi-help-text`}>
                                        注：当开始时间大于当前时间，组件上会显示距活动开始还有多少时间，当开始时间小于当前时间，组件上会显示距离活动结束还有多少时间
                                    </span>
                                </FormItem>
                                <FormItem label={`副标题颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.txtColor} callback={ (color) => { this.handleColorChange(color,'txtColor') } } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('txtColor','#ffffff')}>重置</Button>
                                </FormItem>

                                <FormItem label={`倒计时颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.timeColor} callback={ (color) => { this.handleColorChange(color,'timeColor') } } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('timeColor','#A80000')}>重置</Button>
                                </FormItem>
                                <FormItem label={`背景样式`} { ...ctrlItemLayout }>
                                    <Radio.Group value={moduleData.content.bgStyle} onChange={(e) => { this.handleStyleChange(e,'bgStyle') }} >
                                        <Radio value={1}>图片</Radio>
                                        <Radio value={2}>纯色</Radio>
                                    </Radio.Group>
                                </FormItem>
                                {
                                    moduleData.content.bgStyle == 1
                                        ?
                                        <FormItem label={`选择图片`} { ...ctrlItemLayout }>
                                            <div className={'clearfix'}>
                                                <div className={'img-add'} onClick={this.chooseImg} data-name="pic">
                                                    {
                                                        moduleData.content.pic
                                                            ?
                                                            <div>
                                                                <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={this.delImg} data-name={'pic'}  />
                                                                <img src={moduleData.content.pic} alt=""/>
                                                            </div>
                                                            :
                                                            '+'
                                                    }
                                                </div>
                                            </div>
                                            <span className={'fi-help-text'}>建议上传尺寸：710px*238px</span>
                                        </FormItem>
                                        :
                                        null
                                }

                                {
                                    moduleData.content.bgStyle == 2
                                        ?
                                        <FormItem label={`背景颜色`} { ...ctrlItemLayout }>
                                            <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.bgColor} callback={ (color) => { this.handleColorChange(color,'bgColor') } } ></ColorPicker>
                                            <Button type="link" onClick={()=>this.handleResetColor('bgColor','#A80000')}>重置</Button>
                                        </FormItem>
                                        :
                                        null
                                }
                                <FormItem label={`开始时间`} { ...ctrlItemLayout }>
                                    <DatePicker
                                        showTime
                                        placeholder="请选择"
                                        value={ moduleData.content.start_at ? moment(moduleData.content.start_at, 'YYYY/MM/DD HH:mm:ss') : null }
                                        onChange={ (e) => { this.handleTimeChange(e,'start_at') } }
                                    />
                                </FormItem>
                                <FormItem label={`结束时间`} { ...ctrlItemLayout }>
                                    <DatePicker
                                        showTime
                                        placeholder="请选择"
                                        value={ moduleData.content.end_at ? moment(moduleData.content.end_at, 'YYYY/MM/DD HH:mm:ss') : null }
                                        onChange={ (e) => { this.handleTimeChange(e,'end_at') } }
                                    />
                                </FormItem>
                            </div>
                        </div>
                    </Form>

                </div>
                :
                null
        )

    }

}

export default Form.create({})(tpl_ctrl_type11)
