import $axios from '@/axios/axios.js'

export function authInfo(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program/index`,
        method:'post',
        data
    })
}

export function updateInfo(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program/update`,
        method:'post',
        data
    })
}

export function unBind(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program/unBind`,
        method:'post',
        data
    })
}

export function bindTester(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program/bindTester`,
        method:'post',
        data
    })
}

export function unBindTester(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program/unBindTester`,
        method:'post',
        data
    })
}


export function release(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program/release`,
        method:'post',
        data
    })
}

export function officialAccount(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/official-account`,
        method:'post',
        data
    })
}

export function importFans(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/official-account`,
        method:'post',
        data
    })
}

export function fansLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/official-account/fans`,
        method:'post',
        data
    })
}

export function miniProgramPath(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/miniprogram/pages`,
        method:'post',
        data
    })
}


export function programClearCache(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/program/clearCache`,
        method:'post',
        data
    })
}