import React from 'react'
import { Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import TeacherList from './components/teacherList';
import TeacherPosition from './components/teacherPosition';
const { TabPane } = Tabs;



class EducationalTeacher extends React.Component {

    state = {
        activeKey:"1"
    }

    changeTabs=key=>{
        this.setState({
            activeKey:key
        })
    }


    render() {


        return (
            <div className={'shadow-radius'}>
                <Tabs defaultActiveKey={this.state.activeKey} onChange={this.changeTabs}>
                    <TabPane tab="老师管理" key="1">
                        <TeacherList />
                    </TabPane>
                    <TabPane tab="职位管理" key="2">
                        <TeacherPosition />
                    </TabPane>
                   
                </Tabs>

            </div>
        )
    }


}

export default withRouter(EducationalTeacher)
