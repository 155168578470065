import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, DatePicker, Tag, Drawer, Divider,Modal } from 'antd'
import { withRouter,Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { labelGroupList, groupDetail, deleteGroup } from '@/api/user'
import message from '@/utils/message'
import CreateLabel from './components/CreateLabel'
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker } = DatePicker

class LabelLists extends React.Component{
    state = {
        columns:[
            {
                title:'标签组名称',
                dataIndex:'logo',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            {rowData.group_name} 
                        </div>
                    )
                }
            },
            {
                title:'标签值',
                dataIndex:'name',
                width:140,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {rowData.label_value_name}
                        </div>
                    )
                }
            },
            {
                title:'人数',
                dataIndex:'price',
                width:'300px',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {rowData.user_num}
                        </div>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'first_user',
                width:160,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {rowData.created_at}
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:100,
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"}  onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        searchForm:{
            name:'',
        },
        tableData:[],
        formdata:[],
        tableLoading:false,
        drawerVisible:false,
        page:1,
        total:0,
        per_page:10,
        currentRow:''

    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        labelGroupList(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }

     getLabelDetail = async (id) =>{
        await groupDetail({id}).then(res => {
            if (res.code === 1) {
                let resSult = res.data.detail;
                let arr = [];
                resSult.label_info.forEach((item,index)=>{
                    let sonArr = {
                        id:item.id,
                        name:item.label_name
                    }
                    arr.push(sonArr)
                })
                let data = {
                    id:id,
                    label_name:resSult.group_name,
                    label_value:arr
                }
                
                this.setState({
                    formdata:data
                })
                console.log('----',this.state.formdata)
            }   
        })
    }

    delLabel = async (id) =>{
        await deleteGroup({id}).then(res => {
            if (res.code === 1) {
                message.info('删除成功')
                this.fetchData();
            }   
        })
    }

    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/store/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleUpload=()=>{
        this.userUpload.show(res=>{
            this.fetchData();
        })
    }

    handleEdit = async rowData => {
        await this.getLabelDetail(rowData.id)
        this.userCreate.show(()=>{
           
        })
    }

    handleDelete = rowData =>{
        Modal.confirm({
            title:`确定删除这个标签组吗？`,
            okType:`danger`,
            onOk:() => {
                this.delLabel(rowData.id)
            }
        })
        
    }

    handleStatus = () => {
        message.info('敬请期待')
    }
    handleShare = () => {
        message.info('敬请期待')
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible:false,
            currentRow:null
        })
        this.fetchData();
    }

    handleAddUser= async ()=>{
        await this.setState({
            formdata:[]
        })
        this.userCreate.show(()=>{
            this.fetchData()
        })
    }

    handleUpload=()=>{
        this.userUpload.show(res => {
            this.fetchData();
        });
    }


    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form flex'}>
                        <div className={`clearfix mgr20`}>
                            <FormItem label={'标签组名'} className={'fl'}>
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入标签组名' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`} >
                            <FormItem className={'fl'} >
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAddUser} icon={'plus'}>新增标签组</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                <CreateLabel onRef={e => { this.userCreate = e }}  formdata={ this.state.formdata } />
            </div>
        )
    }

}

export default Form.create({})(withRouter(LabelLists))
