import React from 'react'
import { Row, Col, Tabs, Icon } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})
const { TabPane } = Tabs

class ShortcutsPanel extends React.Component {
    state = {
        components: [
            {
                name: '基础组件',
                plugins: [
                    {
                        type: 0,
                        name: '页面设置',
                        icon: 'icon-setting'
                    },
                    {
                        type: 2,
                        name: '图片',
                        icon: 'icon-tupian'
                    },
                    {
                        type: 3,
                        name: '图文导航',
                        icon: 'icon-fenlei'
                    },
                    {
                        type: 5,
                        name: '轮播图',
                        icon: 'icon-lunbo'
                    },
                    {
                        type: 6,
                        name: '标题文本',
                        icon: 'icon-biaoti'
                    },
                    {
                        type: 7,
                        name: '多热区切图',
                        icon: 'icon-requ'
                    },
                    {
                        type: 4,
                        name: '分割线',
                        icon: 'icon-xiantiao'
                    },
                    {
                        type: 1,
                        name: '店铺公告',
                        icon: 'icon-gonggao'
                    },
                    {
                        type: 9,
                        name: '搜索',
                        icon: 'icon-search'
                    },
                    {
                        type: 8,
                        name: '商品',
                        icon: 'icon-shangping'
                    },
                    {
                        type: 15,
                        name: '课程',
                        icon: 'icon-kecheng'
                    },
                    {
                        type: 16,
                        name: '老师',
                        icon: 'icon-laoshi'
                    },
                    {
                        type: 21,
                        name: '卡片轮播图',
                        icon: 'icon-lunbo-card'
                    },
                    {
                        type: 22,
                        name: '3D轮播图',
                        icon: 'icon-lunbo-3d'
                    }
                ]
            },
            {
                name: '高级组件',
                plugins: [
                    {
                        type: 10,
                        name: '行业组件',
                        icon: 'icon-hangye'
                    },
                    {
                        type: 11,
                        name: '倒计时',
                        icon: 'icon-count-down'
                    },
                    {
                        type: 12,
                        name: '休闲娱乐预订',
                        icon: 'icon-ktv'
                    },
                    {
                        type: 14,
                        name: '场馆预订',
                        icon: 'icon-court'
                    },
                    {
                        type: 18,
                        name: '笔记',
                        icon: 'icon-note'
                    },
                    {
                        type: 19,
                        name: 'web-view',
                        icon: 'icon-web'
                    }

                ]
            },
            {
                name: '活动组件',
                plugins: [
                    {
                        type: 20,
                        name: '优惠券',
                        icon: 'icon-coupon'
                    },
                    {
                        type: 13,
                        name: '限时折扣',
                        icon: 'icon-time-discount'
                    },
                    {
                        type: 17,
                        name: '拼团',
                        icon: 'icon-pingtuan'
                    },
                    {
                        type: 23,
                        name: '社群团购',
                        icon: 'icon-shequntuan'
                    },
                    {
                        type: 24,
                        name: '积分兑换',
                        icon: 'icon-jifenduihuan'
                    }

                ]
            },
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps){
        if(this.props.version_id != nextProps.version_id){
            let version_id =nextProps.version_id;
            // let version_id =2;
            let components = this.state.components;
            components[0].plugins = components[0]?.plugins?.filter(val => {
                if (val.type == 15 || val.type == 16) {
                    return [5, 6, 7, 8].includes(version_id)
                }
                return true
            })
            components[1].plugins = components[1]?.plugins?.filter(val => {
                if ([10,12,14].includes(val.type)) {
                    return [5, 6, 8].includes(version_id)
                }
                return true
            })

            this.setState({
                components
            })
        }
    }

    handleClickPlugin = item => {
        this.props.handleCurrentChange && this.props.handleCurrentChange(item)
    }

    render() {
        let components = this.state.components;
      
        return (
            components.map((item, index) => (
                <Tabs tabBarGutter={10} key={index}>
                    <TabPane key={`${index}`} tab={item.name}>
                        <Row gutter={20}>
                            {
                                item.plugins && item.plugins.map(plugin => (
                                    <Col span={12} style={{ marginTop: '10px' }} key={plugin.type}>
                                        <div className={`shortcut-button`} onClick={() => { this.handleClickPlugin(plugin) }}>
                                            <i className={`comp-icons ${plugin.icon}`}></i>
                                            <span className={`shortcut-txt`}>{plugin.name}</span>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </TabPane>
                </Tabs>
            ))
        )
    }
}

export default ShortcutsPanel
