import React from 'react'
import { Form, Table, Pagination, Tag, Button, message ,Input} from 'antd'
import { openCardDetail } from '@/api/member-card'
import { withRouter, Link } from 'react-router-dom'
import Loading from '@/components/Loading'
import Editor from '@/components/Editor/Editor'
import '@/assets/css/card/detail.scss'
import { setConfig, getConfig } from '@/api/config'
import { uploadFile } from '@/api/file'
import Cookie from 'js-cookie'
const FormItem = Form.Item;

class VipNotice extends React.Component {
    state = {
        content: null,
        loading: false,
        pact_url: '',
        uniqueId:""+new Date().getMilliseconds() + Math.round(Math.random()*100)
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        getConfig({ key: 'level_intro' }).then(res => {
            if (res.code == 1) {
                let { value } = res.data.config;
                if (value) {
                    value=JSON.parse(value);
                    this.setState({
                        content: value.content || '',
                        pact_url: value.pact_url || ''
                    })
                }
            }
        }).finally(() => {
            this.setState({
                is_show: true
            })
        })
    }

    EditorChange = (content) => {
        let data = this.state.content;
        data = content;
        this.setState({
            content: data
        })
    }

    uploadChange = e => {
        let files  = document.getElementById("file"+this.state.uniqueId).files;
        files = Array.from(files);
        let fd = new FormData()
        files.forEach(item => {
            fd.append('Filedata[]',item);
        })
        fd.append('api_token',Cookie.get('token') || ``)
        this.setState({
            isLoading:true
        })
        uploadFile(fd)
            .then(res => {
                if(res.code == 1){
                    this.setState({
                        pact_url:res.data.url
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }

    handleDele=(e)=>{
        e.preventDefault();
        this.setState({
            pact_url:''
        })
    }

    saveData = () => {
        let sendData = {}, { content, pact_url } = this.state;
        sendData['key'] = 'level_intro'
        sendData.value = { content, pact_url }
        setConfig(sendData).then(res => {
            if (res.code == 1) {
                message.success('保存成功');
                this.props.history.push({
                    pathname: `/level/user-level`
                })
            }
        })
    }

    render() {

        return (
            <div className={`shadow-radius`}>
                <div className={`msg-title`}>会员等级须知</div>
                <div className={'mgt10'} style={{ color: '#000' }}>
                    <div className={'flex mgt15'}>
                        <div style={{ width: "100px", textAlign: 'right' }}>会员协议：</div>
                        <div>
                            {
                                this.state.pact_url ?
                                    <>
                                        <Tag color="green" closable onClose={this.handleDele}>已上传</Tag>
                                    </> :
                                    <span style={{ 'position': 'relative' }}>
                                        <Button type={`primary`} className={`mgl5`} ghost>添加协议(pdf)</Button>
                                        <Input type="file" id={`file${this.state.uniqueId}`} className={'pdf-file-input'} multiple="multiple" accept="application/pdf" onChange={this.uploadChange} ></Input>
                                    </span>
                            }
                        </div>
                    </div>
                    <div className={'flex mgt15'}>
                        <div style={{ width: "100px", textAlign: 'right' }}>规则说明：</div>
                        <div>
                            {
                                this.state.is_show ?
                                    <Editor id={'text'} callback={content => this.EditorChange(content)} value={this.state.content}></Editor>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center' }} className={`mgt15`}>
                    <Button type={'primary'} onClick={() => { this.saveData() }} >保存</Button>
                </div>
            </div>
        )
    }

}

export default Form.create({})(withRouter(VipNotice))
