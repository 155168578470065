import React from 'react'
import { Table } from 'antd'
import CX from '@/utils/CX'

class CommonLinks extends React.Component{
    state = {
        columns:[
            {
                title:'链接到',
                dataIndex:'name',
            },
            {
                title:'链接地址',
                dataIndex:'link',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.linkType == 7
                                    ?
                                    '--'
                                    :
                                    rowData.link
                            }
                        </div>
                    )
                }

            },
        ],
        tableData:[],
        selectedRowKeys:[],
        selectRows:[]

    }
    componentDidMount() {
        this.props.onRef(this)
        if(this.props.data){
            let data = this.props.data
            this.setState({
                tableData: data.options
            })
        }
    }
    clearData = () => {
        this.setState({
            selectedRowKeys:[],
            selectRows:[]
        })
    }
    selectRow = (record) => {
        this.setState({ 
            selectedRowKeys:[record.linkType],
            selectRows:[record]
        },() => {
            this.props.onChange && this.props.onChange([record])
        });
      }
    render() {
        const rowSelection = {
            type: 'radio',
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {
               console.log(selectedRowKeys,selectedRows);
                if(selectedRowKeys.length == selectedRows.length){
                    this.setState({
                        selectRows:selectedRows,
                        selectedRowKeys
                    },() => {
                        this.props.onChange && this.props.onChange(selectedRows)
                    })
                }else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr,selectedRowKeys);
                    for(var i in diffArr){
                        for (var j in  oldList){
                            if(oldList[j]['id'] == diffArr[i]){
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows:tmpList,
                        selectedRowKeys
                    },() => {
                        this.props.onChange && this.props.onChange(selectedRows)
                    })

                }
            }
        }
        return(
            <div>
                <Table
                    size={`middle`}
                    rowSelection={rowSelection}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    pagination={{ pageSize:8 }}
                    rowKey={(record,index) => record.linkType}
                    onRow={(record) => ({
                        onClick: () => {
                          this.selectRow(record);
                        },
                      })}
                >
                </Table>
            </div>
        )
    }
}

export default CommonLinks
