import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class AddPrint extends React.Component{
    state = {
        title:'添加打印机',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(typeof callback == 'function'){
                    callback(values)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        const storeLists = this.props.storeLists || []
        const data = this.props.data || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`打印机型号`}>
                        {getFieldDecorator('type', {
                            initialValue: data.type  || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择打印机型号'
                                }
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={1}>易联云K6</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={`打印宽度`}>
                        {getFieldDecorator('size', {
                            initialValue: data.size  || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择打印宽度'
                                }
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={1}>58mm</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={`打印机名称`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入打印机名称'
                                    }
                                ]
                            })(
                                <Input placeholder={`打印机名称`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`打印机终端号`}>
                        {
                            getFieldDecorator('terminal_no',{
                                initialValue: data.terminal_no || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入打印机终端号'
                                    }
                                ]
                            })(
                                <Input placeholder={`打印机终端号`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`打印机秘钥`}>
                        {
                            getFieldDecorator('terminal_secret',{
                                initialValue: data.terminal_secret || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入打印机秘钥'
                                    }
                                ]
                            })(
                                <Input placeholder={`打印机秘钥`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`所在门店`}>
                        {
                            getFieldDecorator('store_id',{
                                initialValue: data.store_id,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择门店'
                                    }
                                ]
                            })(
                                <Select placeholder={'请选择'}>
                                    {
                                        storeLists.map((item,index) => (
                                            <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddPrint))
