import React from 'react'
import { Form, Select, DatePicker, Input, Button, Table, Pagination, Tag, Tooltip, Icon, Tabs, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import '@/assets/css/finance/index.scss'
import { financeBill, financeExportBill, financeOpenBill } from '@/api/finance'
import moment from 'moment'
import message from '@/utils/message'
import CX from '@/utils/CX'
import RefuseRemark from './components/refuse-remark'
import PayCode from './components/pay-code'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { TabPane } = Tabs

class FinanceBill extends React.Component{
    state = {
        searchForm:{
            order_sn:'',
            name:'',
            mobile:'',
            status:'', //状态 1-已开票 2-待开票
            start_time:'',
            end_time:''
        },
        columns:[
            {
                title:'商户单号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.order_sn || '--'}</div>
                    )
                }
            },
            {
                title:'申请时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.created_at || '--'}</div>
                    )
                }
            },
            {
                title:'申请人信息',
                dataIndex:'user_info',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p className={`lh25`}>{ rowData.name || '--' }</p>
                            <p className={`lh25`}>{ rowData.phone || '--' }</p>
                        </div>
                    )
                }
            },
            {
                title:'申请开票金额',
                dataIndex:'pay_ment',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div className={`lh25`}>{ rowData.pay_ment }元</div>
                        </div>
                    )
                }
            },
            {
                title:'发票抬头',
                dataIndex:'bill_head',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{ rowData.bill_head || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'发票税号',
                dataIndex:'bill_account',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{ rowData.bill_account_number || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'发票邮箱',
                dataIndex:'bill_email',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{ rowData.bill_email || '-' }</p>
                        </div>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.confirm_bill == 0
                                    ?
                                    <Tag color={`orange`}>待开票</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.confirm_bill == 1
                                    ?
                                    <Tag color={`green`}>已开票</Tag>
                                    :
                                    null
                            }


                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.confirm_bill == 0
                                    ?
                                    <div className={`lh25`}>
                                        <Button type={`link`} onClick={ () => { this.handleAgree(rowData) } }>确认开票</Button>
                                    </div>
                                    :
                                    '--'

                            }

                        </div>

                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
    }
    componentDidMount() {
        this.fetchData()


    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        financeBill(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })


                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })


    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                this.setState({
                    searchForm,
                    tableLoading:true
                })
                financeExportBill(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '发票申请.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })



            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAgree = row => {
        let sendData = {}
        sendData.trade_id = row.trade_id || '';
        this.setState({
            tableLoading:true
        })
        financeOpenBill(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('操作成功')
                        .then(() => {
                            this.fetchData();
                        })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <RefuseRemark onRef={ e => { this.RefuseRemark = e } }></RefuseRemark>
                <PayCode onRef={ e => { this.PayCode = e } }></PayCode>
                <div className={`content-head mgb15`}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={`商户单号`} className={`fl`}>
                                {
                                    getFieldDecorator('order_sn',{
                                        initialValue:''
                                    })(
                                        <Input placeholder='请输入' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={`申请人昵称`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('name',{
                                        initialValue:''
                                    })(
                                        <Input placeholder='请输入申请人昵称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={`手机号`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('mobile',{
                                        initialValue:''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={`申请时间`} className={`fl`}>
                                {
                                    getFieldDecorator('time')(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem label={`状态`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('status')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>已开票</Select.Option>
                                            <Select.Option value={2}>未开票</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                            </FormItem>

                        </div>
                        <div className={`clearfix`}></div>
                    </Form>
                </div>
                <div className={`content-body`}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => index}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(FinanceBill))
