import React from 'react'
import {
    Form,
    Input,
    Radio,
    Button,
    Select,
    Icon,
    Checkbox,
    Tooltip,
    Table,
    Modal,
    DatePicker,
    TimePicker,
    Switch
} from 'antd'
import {withRouter, Link} from 'react-router-dom'
import { timesCardSave, timesCardDetail } from '@/api/times-card'
import { storeLists } from '@/api/store'
import { uploadFile } from '@/api/file'
import { labelGroupList} from '@/api/user'
import '@/assets/css/item/item.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Editor from '@/components/Editor/Editor'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
import ItemPicker from '@/components/ItemPicker/ItemPicker'
import PropTypes from "prop-types";
import message from '@/utils/message';
import moment from 'moment';
import Cookie from 'js-cookie'
import '@/assets/css/card/detail.scss'


const FormItem = Form.Item
const {TextArea} = Input

class TimesCardEditForm extends React.Component {
    static contextTypes = {
        setLoading: PropTypes.func
    }
    state = {
        formData: '',
        columns: [
            {
                title: '券名称',
                dataIndex: 'name',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '券类型',
                dataIndex: 'coupon_type',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title: '赠送数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <Input
                            style={{width:'100px'}}
                            placeholder={`张`}
                            value = { rowData.num || `` }
                            onChange={ e => { this.handleNumChange(e,rowData,index) } }
                        />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => {this.handleDelItem(rowData, index)}}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ],
        goodsColumns:[
            {
                title:'商品名称',
                dataIndex:'name',
                width:'30%',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || rowData.goods_name}</p>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>&yen;{rowData.price || '--'}</p>
                    )
                }
            },
            {
                title:'可用次数',
                dataIndex:'times',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <Radio.Group onChange={ (e) => { this.handleChangeType(e,rowData,index) } } value={rowData.limit_type}>
                                <Radio value={1}>不限</Radio>
                                <Radio value={2}>
                                    限制
                                    <Input
                                        value={rowData.limit_num}
                                        addonAfter={`次`}
                                        style={{ width:'100px' }}
                                        className={`mgl5`}
                                        onChange={ e => { this.handleTimesChange(e,rowData,index) } }
                                    />
                                </Radio>
                            </Radio.Group>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={() => { this.handleDelGoods(rowData,index) }} disabled={!!this.props.match.params.type}>删除</Button>
                        </div>
                    )
                }
            }
        ],
        tagsLists:[],
        multipleSelect: true,
        chooseItems: [],
        storeLists: [],
        step: 1,
        showEditor:false,
        uniqueId:""+new Date().getMilliseconds() + Math.round(Math.random()*100)
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchStore();
        this.fetchTages();
        if (this.props.match.params.id) {
            this.fetchData()
        }else {
            this.setState({
                showEditor:true
            })
        }
    }

    fetchData = () => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        this.context.setLoading(true)
        timesCardDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail;
                    let present_coupon_list = detail.present_coupon_list || [],
                        present_coupon_data = detail.present_coupon_data || [];
                    present_coupon_list.forEach(item => {
                        present_coupon_data.forEach(child => {
                            if(child.id == item.id){
                                item.num= child.num;
                            }
                        })
                    })
                    detail.present_coupon_json = present_coupon_list;
                    detail.suit_stores = detail.suit_store_ids
                    if(detail.expired_notice_day > 0){
                        detail.is_notice = 1
                    }else {
                        detail.is_notice = 0
                    }
                    this.setState({
                        formData:detail,
                        showEditor:true
                    })
                    console.log(detail)

                }
            })
            .finally(() => {
                this.context.setLoading(false)

            })

    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.context.setLoading(true)
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })
    }
    fetchTages = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.context.setLoading(true)
        labelGroupList(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.list.data,arr=[];

                    lists.forEach(item=>{
                        let ids=item.label_value.split(','),
                            names=item.label_value_name.split(',');
                        ids.forEach((val,i)=>{
                            arr.push({
                                id:item.id+'_'+ids[i],
                                name:item.group_name+'-'+names[i]
                            })
                        })
                    })


                    this.setState({
                        tagsLists: arr
                    })
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })
    }
    handleCoupon = () => {
        let formData = this.state.formData || {}
        this.CouponPicker.show(res => {
            console.log('res',res)
            if(res.length > 10){
                res.length = 10;
            }
            formData.present_coupon_json = res;
            this.setState({
                formData
            })
        })
    }
    handleChangeType = (e,rowData,index) => {
        let val = e.target.value,
            formData = this.state.formData || {},
            present_goods_data = formData.present_goods_data || [];
        present_goods_data[index]['limit_type'] =  val;
        this.setState({
            formData
        })
    }
    handleTimesChange = (e,rowData,index) => {
        let formData = this.state.formData || {},
            present_goods_data = formData.present_goods_data || [],
            val = e.target.value;
        present_goods_data[index]['limit_num'] = val;
        this.setState({
            formData
        })
    }
    handleNumChange = (e,rowData,index) => {
        let formData = this.state.formData,
            present_coupon_json = formData.present_coupon_json,
            val = e.target.value;
        present_coupon_json[index]['num'] = val;
        this.setState({
            formData
        })

    }
    handleInputChange = (e,name) => {
        let formData = this.state.formData || {},
            val = e.target.value;
        formData[name] = val;
        this.setState({ formData })
    }
    handleDelItem = (rowData,index) => {
        let formData = this.state.formData,
            present_coupon_json = formData.present_coupon_json;
        present_coupon_json.splice(index,1);
        this.setState({
            formData
        })
    }
    handleDelGoods = (rowData,index) => {
        let formData = this.state.formData || {},
            present_goods_data = formData.present_goods_data || [];
        present_goods_data.splice(index,1);
        this.setState({
            formData
        })
    }
    handleNextStep = () => {
        let formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                for(var i in values){
                    formData[i] = values[i]
                }
                this.setState({
                    formData,
                    step:2
                })
            }
        })
    }
    handlePrevStep = () => {
        let formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                for(var i in values){
                    formData[i] = values[i]
                }
                this.setState({
                    formData,
                    step:1
                })
            }
        })

    }
    handleRuleChange = (e,rowData,index,keyName) => {
        let formData = this.state.formData || {},
            recharge_rules = formData.recharge_rules,
            val = e.target.value;
        recharge_rules[index][keyName] = val;
        this.setState({
            formData
        })

    }
    handleAddRule = () => {
        let formData = this.state.formData || {},
            recharge_rules = formData.recharge_rules || [],
            newData = { recharge_money:0, present_money:0,present_points:0};
        recharge_rules.push(newData);
        formData.recharge_rules = recharge_rules;
        this.setState({
            formData
        })
    }
    handleDelRule = (rowData,index) => {
        let formData = this.state.formData || {},
            recharge_rules = formData.recharge_rules;
        recharge_rules.splice(index,1);
        this.setState({
            formData
        })

    }
    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.desc = content;
        this.setState({
            formData
        })

    }
    uploadChange = e => {
        let files  = document.getElementById("file"+this.state.uniqueId).files,
            formData = this.state.formData || {}
        files = Array.from(files);
        let fd = new FormData()
        files.forEach(item => {
            fd.append('Filedata[]',item);
        })
        fd.append('cate_id',this.state.category_id)
        fd.append('api_token',Cookie.get('token') || ``)
        this.setState({
            isLoading:true
        })
        uploadFile(fd)
            .then(res => {
                if(res.code == 1){
                    formData.agreement_file = res.data.url;
                    formData.agreement_file_name = files[0]['name'];
                    console.log(formData)
                    this.setState({
                        formData
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover_img':
                    formData[name] = imgs[0];
                    break;
                default:
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                for(var i in values){
                    formData[i] = values[i]
                }
                if(formData.end_time){
                    formData.end_time = moment(formData.end_time).format('YYYY-MM-DD')
                }

                if(formData.valid_type == 1){
                    formData.end_time = null;
                    formData.valid_days = ''
                }else if(formData.valid_type == 2){
                    formData.valid_days = ''
                }else if(formData.valid_type == 3){
                    formData.end_time = null;
                }
                sendData = formData;
                if(sendData.present_coupon_json && sendData.present_coupon_json.length){
                    let tmp = JSON.stringify(sendData.present_coupon_json)
                    sendData.present_coupon_data = JSON.parse(tmp).map(item => { return{ id:item.id,num:item.num } })
                }
                sendData.present_goods_data && sendData.present_goods_data.forEach((item) => {
                    if(item.limit_type == 1){
                        item.limit_num = ''
                    }
                })
                if(sendData.present_goods_data && sendData.present_goods_data.length){
                    let tmp_goods = JSON.stringify(sendData.present_goods_data)
                    sendData.present_goods_data = JSON.parse(tmp_goods).map(item => { return{ id:item.id,limit_num:item.limit_num,limit_type:item.limit_type } })
                }
                if(sendData.is_notice == 0){
                    sendData.expired_notice_day = '';
                }
                for(var x in sendData){
                    if(typeof sendData[x] === 'boolean'){
                        sendData[x] = sendData[x] ? 1 : 0
                    }
                }
                if(formData.label_ids){
                    sendData.label_ids=formData.label_ids.join(',');
                }
                console.log(sendData);
                this.context.setLoading(true);
                timesCardSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/user/times-card`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })



            }
        })
    }
    chooseSuitItems = () => {
        let formData = this.state.formData || {};
        this.setState({
            chooseItems: formData.present_goods_data ? formData.present_goods_data : []
        },() => {
            this.ItemPicker.show(res => {
                if(res.length){
                    formData.present_goods_data = res
                    formData.present_goods_data && formData.present_goods_data.forEach(item => {
                        item.limit_num = item.limit_num || ''
                        item.limit_type = item.limit_type || 1
                    })
                    this.setState({
                        formData
                    })
                }
            })
        })

    }

    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return (
            <div className={``}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <CouponPicker
                    onRef={ e => { this.CouponPicker = e } }
                    multiple={true}
                    selectIds={ data.present_coupon_json && data.present_coupon_json.map(item => {return Number(item.id)})}
                ></CouponPicker>
                <ItemPicker
                    onRef={ e => { this.ItemPicker = e } }
                    selectIds={this.state.chooseItems.length ? this.state.chooseItems.map(item => {return item.id}) : []}
                    items={ this.state.chooseItems.length ? this.state.chooseItems : [] }
                    multiple={ true }
                ></ItemPicker>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    {
                        this.state.step == 1
                            ?
                            <div>
                                <div className={`msg-title mgb15`}>基本信息</div>
                                <FormItem label={'卡名称'}>
                                    {getFieldDecorator('name', {
                                        initialValue: data.name || ``,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入卡名称'
                                            }
                                        ]
                                    })(<Input
                                        placeholder={'请输入卡名称，限10字符'}
                                        maxLength={10}
                                        onBlur={ (e) => { this.handleInputChange(e,'name') } }
                                        suffix={<span>{getFieldValue("name").length }/10</span>}

                                    />)}
                                </FormItem>
                                <FormItem label={'售价'}>
                                    {getFieldDecorator('price', {
                                        initialValue: data.price || '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入售价'
                                            }
                                        ]
                                    })(
                                        <Input addonAfter={'元'} style={{ width:'250px' }} />
                                    )}
                                    {/* <span className={`fi-help-text`}>选择免费开卡，顾客可直接领取；选择充值开卡，则按照储值金额开卡</span> */}
                                </FormItem>
                                <FormItem label={'购卡权益'} required={true} >
                                    {
                                        getFieldDecorator('present_goods_data',{
                                            initialValue:data.present_goods_data || []
                                        })(
                                            <Button type={`primary`} onClick={ this.chooseSuitItems } disabled={!!this.props.match.params.type}>选择商品</Button>
                                        )
                                    }
                                </FormItem>
                                {
                                    data.present_goods_data && data.present_goods_data.length
                                        ?
                                        <FormItem label={` `} colon={false}>
                                            <Table
                                                size={`middle`}
                                                columns={this.state.goodsColumns}
                                                dataSource={data.present_goods_data}
                                                rowKey={record => record.id}
                                                style={{ width:'600px' }}
                                            ></Table>
                                        </FormItem>
                                        :
                                        null
                                }

                                <FormItem label={'卡有效期'}>
                                    {getFieldDecorator('valid_type', {
                                        initialValue: data.valid_type || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请设置有效期'
                                            }
                                        ]
                                    })(
                                        <Radio.Group className={`mgt10`}>
                                            <Radio value={1} className={`mgb15 block`}>
                                                永久有效
                                            </Radio>
                                            <Radio value={2} className={`mgb15 block`}>
                                                固定有效期
                                                {
                                                    getFieldDecorator('end_time', {
                                                        initialValue: data.end_time ? moment(data.end_time, 'YYYY-MM-DD') : null
                                                    })(
                                                        <DatePicker className={`mgl10`} style={{ width:'150px' }} />
                                                    )
                                                }
                                            </Radio>
                                            <Radio value={3} className={`block`}>
                                                开通后
                                                {
                                                    getFieldDecorator('valid_days', {
                                                        initialValue: data.valid_days || ''
                                                    })(
                                                        <Input className={`addon-after-input mgl10`}
                                                               style={{width: '100px'}}></Input>
                                                    )
                                                }
                                                <span className={`mgl10 mgr10`}>天内有效</span>

                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                <FormItem label={'适用门店'}>
                                    {getFieldDecorator('suit_store_type', {
                                        initialValue: data.suit_store_type || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择适用门店'
                                            }
                                        ]
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>全部</Radio>
                                            <Radio value={2}>指定门店可用</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                {
                                    getFieldValue('suit_store_type') == 2
                                        ?
                                        <FormItem label={'选择门店'}>
                                            {getFieldDecorator('suit_stores', {
                                                initialValue: data.suit_stores || [],
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择适用门店'
                                                    }
                                                ]
                                            })(
                                                <Select mode="multiple" style={{width: '300px'}} placeholder="请选择">
                                                    {
                                                        this.state.storeLists.map(store => (
                                                            <Select.Option
                                                                value={store.id}
                                                                key={store.id}
                                                            >{store.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            )}
                                        </FormItem>
                                        :
                                        null

                                }
                                <FormItem label={`到期提醒`}>
                                    {
                                        getFieldDecorator('is_notice', {
                                            initialValue: data.is_notice || 0,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox className={`mgr5`}></Checkbox>
                                        )
                                    }
                                    会员卡到期前
                                    {getFieldDecorator('expired_notice_day', {
                                        initialValue: data.expired_notice_day || '',
                                    })(
                                        <Input style={{width: '80px'}} className={`mgl5 mgr5`}/>
                                    )}
                                    天发送消息提醒
                                </FormItem>
                                <FormItem label={'赠送权益'}>
                                    {
                                        getFieldDecorator('present_coupon', {
                                            initialValue: data.present_coupon || 0,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox className={`mgr5`}></Checkbox>
                                        )
                                    }
                                    开卡后赠券
                                    <Button type={`default`} className={`mgl5`} onClick={ this.handleCoupon }>选择优惠</Button>
                                    <span className={`fi-help-text`}>设置成功后，不遵循限领规则直接发放，优惠券最多设置 10 张。</span>
                                </FormItem>
                                <FormItem label={'自动打标签'}>
                                 
                                     {getFieldDecorator('label_ids', {
                                        initialValue: data.label_ids_ary || [],
                                    })(
                                        <Select mode="multiple" style={{ width: '300px' }} placeholder="请选择">
                                            {
                                                this.state.tagsLists.map(store => (
                                                    <Select.Option
                                                        value={store.id}
                                                        key={store.id}
                                                    >{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                    <span className={`fi-help-text`}>开卡后自动打上【选择标签】</span>
                                </FormItem>
                                {
                                    data.present_coupon_json && data.present_coupon_json.length
                                        ?
                                        <FormItem label={` `} colon={false}>
                                            <Table
                                                size={`middle`}
                                                columns={this.state.columns}
                                                dataSource={data.present_coupon_json}
                                                rowKey={record => record.id}
                                                style={{ width:'700px' }}
                                            ></Table>
                                        </FormItem>
                                        :
                                        null
                                }
                                <div className={`msg-title mgb15`}>其他设置</div>
                                <FormItem label={'卡协议'}>
                                    {
                                        getFieldDecorator('is_agreement', {
                                            initialValue: data.is_agreement || 0,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox className={`mgr5`}></Checkbox>
                                        )
                                    }
                                    需勾选同意协议
                                    <span style={{'position':'relative'}}>
                                        <Button type={`default`} className={`mgl5`}>添加协议(pdf)</Button>
                                        <Input type="file" id={`file${this.state.uniqueId}`}  className={'pdf-file-input'} multiple="multiple" accept="application/pdf" onChange={this.uploadChange} ></Input>
                                    </span>
                                    {
                                        data.agreement_file && data.agreement_file_name
                                            ?
                                            <span className={`fi-help-text`}>已选择{data.agreement_file_name}</span>
                                            :
                                            <span className={`fi-help-text`}>未选择任何文件</span>
                                    }
                                </FormItem>
                                <FormItem label={`是否在列表显示`}>
                                    {getFieldDecorator('is_show', {
                                        initialValue: data.is_show || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择'
                                            }
                                        ]
                                    })(
                                        <Radio.Group className={`mgt10`}>
                                            <Radio value={1} className={``}>显示</Radio>
                                            <Radio value={2} className={``}>隐藏</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                <FormItem label={'卡列表排序'}>
                                    {getFieldDecorator('sort', {
                                        initialValue: data.sort || ``,
                                    })(<Input maxLength={10}/>)}
                                    <span className={`fi-help-text`}>小程序端及后台呈现的形式，按照数字从小到大排列，不填写，默认按后添加的在前</span>
                                </FormItem>
                                <div className={`txtCenter`}>
                                    <Button type={`primary`} onClick={ this.handleNextStep }>下一步</Button>
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        this.state.step == 2
                            ?
                            <div style={{ position:'relative' }} >
                                <div className={`msg-title mgb15`}>卡面装修</div>
                                <div className={`clearfix pdb50`}>
                                    <FormItem label={`卡面背景`}>
                                        {getFieldDecorator('is_cover', {
                                            initialValue: data.is_cover || 1,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]
                                        })(
                                            <Radio.Group className={`mgt10`}>
                                                <Radio value={1} className={``}>默认背景</Radio>
                                                <Radio value={2} className={``}>自定义图片</Radio>
                                            </Radio.Group>
                                        )}
                                    </FormItem>
                                    {
                                        getFieldValue('is_cover') == 2
                                            ?
                                            <FormItem label={'自定义图片'}>
                                                {getFieldDecorator('cover_img', {
                                                    initialValue: data.cover_img || ``,
                                                })(
                                                    <div className={'clearfix'}>
                                                        {
                                                            data.cover_img
                                                                ?
                                                                <PictureCard
                                                                    onRef={ e => { this.PictureCard = e } }
                                                                    imgs={ data.cover_img }
                                                                    onDel={ e => { this.delImg(e,'cover_img') } }
                                                                ></PictureCard>
                                                                :
                                                                <ImgAdd
                                                                    onRef={ e => { this.ImgAdd = e } }
                                                                    onClick={ e => { this.chooseImg(e,'cover_img') } }
                                                                ></ImgAdd>
                                                        }

                                                    </div>
                                                )}
                                                <span className={'fi-help-text'}>建议尺寸：660x308像素，单张不超过500k</span>
                                            </FormItem>
                                            :
                                            null
                                    }

                                    <FormItem label={'卡面设置'}>
                                        <div className={``}>
                                            {
                                                getFieldDecorator('show_card_name', {
                                                    initialValue: data.show_card_name || 1,
                                                    valuePropName: 'checked'
                                                })(
                                                    <Checkbox>显示卡名称</Checkbox>
                                                )
                                            }
                                            {
                                                getFieldDecorator('show_card_no', {
                                                    initialValue: data.show_card_no || 1,
                                                    valuePropName: 'checked'
                                                })(
                                                    <Checkbox>显示卡号</Checkbox>
                                                )
                                            }
                                        </div>
                                    </FormItem>
                                    <div className={`card-detail absolute`} style={{ top:'50px',right:'40%' }}>
                                        <div className="card times-card" style={{ backgroundImage:`url(${ getFieldValue('is_cover') == 2 && data.cover_img ? data.cover_img : 'https://img.cxkoo.com/chengxuan/1/2022-04-13/5bd6e8f471595fcb70ad92da86469f94.png' })` }}>
                                            <div className="title">
                                                <div className="crown"></div>
                                                <div className="type-img"></div>
                                                {
                                                    getFieldValue('show_card_name')
                                                        ?
                                                        <div className="txt">(这里显示卡名称)</div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="opt-txt"></div>
                                            <div className="card-no">
                                                {
                                                    getFieldValue('show_card_no')
                                                        ?
                                                        20102003010
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="valid">有效期：9999-99-99</div>
                                            <div className="qr-img"></div>
                                        </div>
                                    </div>
                                </div>
                                <FormItem label={'卡详情'}>
                                    {
                                        this.state.showEditor
                                            ?
                                            <Editor id={'text'} value={data.desc} callback={content => this.EditorChange(content)}></Editor>
                                            :
                                            null
                                    }
                                </FormItem>
                                <div className={`txtCenter`}>
                                    <Button type={`default`} className={`mgr15`} onClick={ this.handlePrevStep }>上一步</Button>
                                    <Button type={`primary`} onClick={ this.saveData }>保存</Button>
                                </div>
                            </div>
                            :
                            null
                    }

                </Form>

            </div>
        )
    }

}

export default Form.create({})(withRouter(TimesCardEditForm))
