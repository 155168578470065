import $axios from '@/axios/axios.js'

export function CourseCategoryApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course-category`,
        method:'post',
        data
    })
}

export function CourseCategorySaveApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course-category/save`,
        method:'post',
        data
    })
}

export function CourseCategoryDeleteApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course-category/delete`,
        method:'post',
        data
    })
}

export function CourseListsApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course`,
        method:'post',
        data
    })
}

export function CourseSaveApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course/save`,
        method:'post',
        data
    })
}

export function setStatusApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course/setStatus`,
        method:'post',
        data
    })
}

export function setCategoryApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course/setCategory`,
        method:'post',
        data
    })
}

export function deleteApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course/delete`,
        method:'post',
        data
    })
}


export function detailApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course/detail`,
        method:'post',
        data
    })
}

export function copyApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course/copy`,
        method:'post',
        data
    })
}

export function selectCourseApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/course/selectCourse`,
        method:'post',
        data
    })
}