import React from 'react'
import { Modal, Input, Select, Form } from 'antd'
import { expressLists } from '@/api/order'
const FormItem = Form.Item;

class Deliver extends React.Component{
    state = {
        formData:{},
        exprssLists:[],
        title:'标记发货',
        visible:false,
        modalLoading:false,
        callback:null

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.getExpress()
        this.setState({
            visible:true,
            callback:callback || null
        })

    }
    getExpress = () => {
        let sendData = {};
        if(!this.state.exprssLists.length){
            expressLists(sendData)
                .then(res => {
                    if(res.code == 1){
                        let lists = res.data.express_list;
                        this.setState({
                            exprssLists:lists
                        })
                    }
                })
                .finally(() => {})
        }

    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let callback = this.state.callback
        this.props.form.validateFields((err,values) => {
            if(!err){
                this.setState({
                    formData:values
                },() => {
                    if(typeof callback == 'function'){
                        callback(values)
                    }
                    this.close()
                })
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol:{
                span:5
            },
            wrapperCol:{
                span:17
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={500}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
                destroyOnClose={true}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <FormItem label="快递公司">
                        {getFieldDecorator('express_id', {
                            initialValue: data && data.express_id,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择快递公司'
                                }
                            ]
                        })(
                            <Select placeholder={'请选择'}>
                                {
                                    this.state.exprssLists.map((item,index) => (
                                        <Select.Option value={item.express_id} key={index}>{ item.express_name }</Select.Option>
                                    ))
                                }
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label="运单号">
                        {getFieldDecorator('express_sn', {
                            initialValue: data && data.express_sn,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入快递运单号'
                                }
                            ]
                        })(<Input />)}
                    </FormItem>

                </Form>
            </Modal>
        )
    }
}
export default Form.create({})(Deliver)
