import React from 'react'
import { Modal, Input, Form, Radio, Select } from 'antd'
import { fxMemberLists  } from '@/api/fx'
import Loading from '@/components/Loading'
const FormItem = Form.Item;
const { TextArea } = Input

class ChangeFx extends React.Component{
    state = {
        formData:{},
        lists:[],
        title:'取消身份提示',
        visible:false,
        isLoading:false,
        modalLoading:false,
        callback:null,
        total:0,
        per_page:1000

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback:callback || null
        })
        this.fetchData()

    }
    fetchData = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 1000;
        this.setState({
            isLoading:true
        })
        fxMemberLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.lists.data,
                        total = res.data.lists.total,
                        per_page = res.data.lists.per_page;
                    this.setState({
                        lists, total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleOk = () => {
        let callback = this.state.callback
        this.props.form.validateFields((err,values) => {
            if(!err){
                this.setState({
                    formData:values
                },() => {
                    if(typeof callback == 'function'){
                        callback(values)
                    }
                    this.close()
                })
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol:{
                span:4
            },
            wrapperCol:{
                span:18
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
            >

                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`content-msg mgb15`}>
                        分销员取消身份后，继续分销将不再获得收益，待结算的金额依旧会结算。
                        原有的客户关系及下级分销员，你可以选择解绑或转给其他分销员。
                    </div>
                    <FormItem label="请选择">
                        {getFieldDecorator('type', {
                            initialValue: data.type || 1,
                            rules: []
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                    直接与下级客户及下级分销员解绑
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    转给分销员
                                    {
                                        getFieldDecorator('target_user_id')(
                                            <Select style={{width:200}} placeholder={'请选择'} className={`mgl5`}>
                                                {
                                                    this.state.lists.map((item,index) => (
                                                        <Select.Option value={item.user_id} key={index}>{ item.name }</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        )
                                    }
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>

                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </Modal>
        )
    }
}
export default Form.create({})(ChangeFx)
