import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, InputNumber, Radio, Pagination, Tooltip, Tag, TreeSelect, Drawer, Icon } from 'antd';
import moment from 'moment'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import { couponLists } from '@/api/coupon'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class PrizeForm extends Component {
    state = {
        couponLists: [],
        formData: {

        },
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchCoupon()
      
        if(this.props.is_edit){
            const {formData}=this.state
            let data=this.props.activityData
            formData['prize_img']=data.prize_img || ''
            this.setState({formData})
        }
    }


    fetchCoupon = () => {
        let sendData = {
            page: 1,
            per_page: 100,
            status: 1
        };

      
        couponLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.coupon_list.data,
                        total = res.data.coupon_list.total,
                        per_page = res.data.coupon_list.per_page;

                    lists = lists.filter(val => val.last_num > 0)
                    this.setState({
                        couponLists: lists,
                        total, per_page
                    })

                }
            })
           
    }


    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    getFormData=()=>{
    
        return new Promise((reslove, reject) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const {formData}=this.state
    
                    if(values.exchange_time_type ==2){
                        values.exchange_start_time = values.exchange_time ? moment(values.exchange_time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                        values.exchange_end_time = values.exchange_time ? moment(values.exchange_time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                    }
    
                   return reslove({...values,...formData})
                }else{
                    return reslove(false)
                }
            })
        })
    }


    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const data = this.props.activityData || {}
        const formData= this.state.formData || {}
        const {is_edit}=this.props

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>基本选项</div>
                <FormItem label={"奖品类型"} >
                    {getFieldDecorator('prize_type', {
                        initialValue: data.prize_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择奖品类型'
                            }
                        ]
                    })(
                        <Radio.Group disabled={is_edit}>
                            <Radio value={1}>优惠券 </Radio>
                            <Radio value={2}>积分</Radio>
                            <Radio value={3}>微信零钱 </Radio>
                            <Radio value={4}>实物奖</Radio>
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("prize_type") == 1 ?
                        <FormItem label={"赠送优惠券"} >
                            {getFieldDecorator('coupon_id', {
                                initialValue: data.coupon_id || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择优惠券'
                                    }
                                ]
                            })(
                                <Select style={{ width: 400 }} placeholder={'请选择'}>
                                    {this.state.couponLists.map(val => {
                                        return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                    })}
                                </Select>
                            )}
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("prize_type") == 2 ?
                        <FormItem label={'赠送积分数'}>
                            {getFieldDecorator('points', {
                                initialValue: data.points || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '赠送积分数'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400 }}
                                    min={0}
                                    precision={0}
                                    placeholder={'请输入赠送积分数'}
                                />
                            )}
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("prize_type") == 3 ?
                        <FormItem label={'赠送微信零钱'}>
                            {getFieldDecorator('wallet_money', {
                                initialValue: data.wallet_money || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入赠送的微信零钱'
                                    }
                                ]
                            })(
                                <InputNumber
                                    style={{ width: 400,paddingRight:100 }}
                                    min={1}
                                    precision={2}
                                    placeholder={'请输入赠送的微信零钱'}
                                />
                            )}
                             <span className='custom-input-suffix'>元</span>
                            <span className={'fi-help-text'}>请确保您开通了企业支付到零钱功能，且零钱金额不能小于1元</span>
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("prize_type") == 4 ?
                        <>
                            <FormItem label={'奖品名称'}>
                                {getFieldDecorator('actual_name', {
                                    initialValue: data.actual_name || undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入实物奖品名称'
                                        }
                                    ]
                                })(<Input
                                    style={{ width: 400 }}
                                    placeholder={'请输入实物奖品名称'}
                                    maxLength={50}
                                />)}
                            </FormItem>

                            <FormItem label={`奖品图片`}>
                                <div className={'clearfix'}>
                                    {
                                        formData.prize_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={formData.prize_img}
                                                onDel={e => { this.delImg(e, 'prize_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'prize_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：500x500像素。单张不超过2M。</span>
                            </FormItem>
                        </>
                        : null
                }



                <FormItem label={'奖品发放数量'}>
                    {getFieldDecorator('prize_num', {
                        initialValue: data.prize_num || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入奖品数量'
                            }
                        ]
                    })(
                        <InputNumber
                            style={{ width: 400,paddingRight:100 }}
                            min={data.prize_num|| 0}
                            precision={0}
                            placeholder={'请输入奖品数量'}
                           
                        />
                    )}
                    <span className='custom-input-suffix'>个</span>
                    <span className={'fi-help-text'}>奖品数量必填，0表示0件奖品，如果奖品为优惠券，优惠券库存为0时将提示用户“奖品已领完”</span>
                </FormItem>

                <div className={`msg-title mgb15`}>兑换选项</div>

                {
                    getFieldValue("prize_type") != 4 ?
                        <FormItem label={"兑奖方式"} >
                            {getFieldDecorator('cash_prize_type', {
                                initialValue:  1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择奖品类型'
                                    }
                                ]
                            })(
                                <Radio.Group >
                                    <Radio value={1}>直接发放</Radio>
                                </Radio.Group>
                            )}
                            { getFieldValue("prize_type") == 3? <span className={'fi-help-text'}>如微信运营账户内没有余额时，可能导致奖品发放失败</span>:null}
                           
                        </FormItem>
                        : null
                }

                {
                    getFieldValue("prize_type") == 4 ?
                        <FormItem label={"兑奖方式"} >
                            {getFieldDecorator('exchange_type', {
                                initialValue: data.exchange_type || 2,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择奖品类型'
                                    }
                                ]
                            })(
                                <Radio.Group disabled={is_edit}>
                                    <Radio value={1}>上门自取</Radio>
                                    <Radio value={2}>邮寄</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>
                        : null
                }



                <FormItem label={"兑奖期限"} >
                    {getFieldDecorator('exchange_time_type', {
                        initialValue: data.exchange_time_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择兑奖期限'
                            }
                        ]
                    })(
                        <Radio.Group >
                            <Radio value={1}>活动结束前都可领奖</Radio>
                            <Radio value={2}>固定期限</Radio>
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("exchange_time_type") == 2 ?
                        <FormItem label={' '} className={'no-colon'}>
                            {getFieldDecorator('exchange_time', {
                                initialValue: data.exchange_time || null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择兑奖时间'
                                    }
                                ]
                            })(
                                <RangePicker
                                    style={{ width: 400 }}
                                    showTime={{ format: 'HH:mm:ss' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder={['开始时间', '结束时间']}
                                />
                            )}
                        </FormItem>
                        : null
                }


                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button  onClick={()=>this.props.updateTab('1')}>上一步</Button>
                    <Button type='primary' className='mgl20'  onClick={()=>this.props.updateTab('3')}>下一步</Button>
                </FormItem>

                <div className={'no-colon mgt60 pdb50'}> </div>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
            </Form>
        )
    }
}

export default Form.create({})(PrizeForm)
