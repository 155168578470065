/**
 * 优惠券组件
 * @type 20
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'


const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ20 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}

        return (
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`优惠券`}>
                    <div className={`modulePadding pdl10 pdr10 ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`,
                        '--coupon-color': `${moduleData.content.couponColor}`,
                        '--coupon-text-color': `${moduleData.content.couponTextColor}`,
                        '--coupon-btn-color': `${moduleData.content.btnColor}`,
                        '--coupon-btn-text-color': `${moduleData.content.btnTextColor}`
                    }} onClick={this.handleEditModule}>
                        <div className="d-plone-coupon">
                            <div className="coupon-head">
                                {
                                    moduleData.content.style == 2 ? null : <div className="tag">Get Coupons </div>
                                }
                                <div className="title">
                                    {
                                        moduleData.content.style == 2 ?
                                            <span className={'txt1'}>领好券&nbsp;&nbsp;购好物</span> :
                                            <span className={'txt2'}>{moduleData.content.title}</span>
                                    }
                                </div>
                            </div>
                            {
                                moduleData.content.style == 1
                                    ?
                                    moduleData.content.dataset.length
                                        ?
                                        <div className="coupon-style1">
                                            {
                                                moduleData.content.dataset.map(item => {
                                                    return (
                                                        <div className="coupon-item" key={item.id} >
                                                            {item.coupon_type == 1 ?
                                                                <>
                                                                    <div className="fz14">RMB</div>
                                                                    <div className="fz36">{item.discount_price}</div>
                                                                </> : item.coupon_type == 1 ?
                                                                    <>
                                                                        <div className="fz14">折扣</div>
                                                                        <div className="fz36">{item.discount}</div>
                                                                    </> :
                                                                    <>
                                                                        <div className="fz14">免费</div>
                                                                        <div className="fz36 scale-08">兑换</div>
                                                                    </>
                                                            }

                                                            <div className="fz12">{item.use_condition == 1 ? '无门槛' : `满${item.least_price}可用`} </div>
                                                            <div className="btn">立即领取</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className="coupon-style1">
                                            <div className="coupon-item">
                                                <div className="fz14">RMB</div>
                                                <div className="fz36">10</div>
                                                <div className="fz12">满599可用</div>
                                                <div className="btn">立即领取</div>
                                            </div>
                                            <div className="coupon-item">
                                                <div className="fz14">RMB</div>
                                                <div className="fz36">50</div>
                                                <div className="fz12">满199可用</div>
                                                <div className="btn">立即领取</div>
                                            </div>
                                            <div className="coupon-item">
                                                <div className="fz14">RMB</div>
                                                <div className="fz36">10</div>
                                                <div className="fz12">无门槛</div>
                                                <div className="btn">立即领取</div>
                                            </div>
                                        </div>
                                    :
                                    null
                            }
                            {
                                moduleData.content.style == 2
                                    ?
                                    moduleData.content.dataset.length
                                        ?
                                        <div className="coupon-style2">
                                            {
                                                moduleData.content.dataset.map(item => {
                                                    return (
                                                        <div className="coupon-item" key={item.id}>
                                                            {item.coupon_type == 1 ?
                                                                <>
                                                                    <div className="tag">RMB</div>
                                                                    <div className="fz26">{item.discount_price}</div>
                                                                </> : item.coupon_type == 1 ?
                                                                    <>
                                                                        <div className="tag">折扣</div>
                                                                        <div className="fz26">{item.discount}</div>
                                                                    </> :
                                                                    <>
                                                                        <div className="tag">免费</div>
                                                                        <div className="fz26 scale-08">兑换</div>
                                                                    </>
                                                            }

                                                            <div className="fz12">{item.use_condition == 1 ? '无门槛' : `满${item.least_price}可用`} </div>
                                                            <div className="btn">领取优惠券</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className="coupon-style2">
                                            <div className="coupon-item">
                                                <div className="tag">RMB</div>
                                                <div className="fz26">10</div>
                                                <div className="fz12">无门槛</div>
                                                <div className="btn">领取优惠券</div>
                                            </div>
                                            <div className="coupon-item">
                                                <div className="tag">RMB</div>
                                                <div className="fz26">10</div>
                                                <div className="fz12">满599可用</div>
                                                <div className="btn">领取优惠券</div>
                                            </div>
                                            <div className="coupon-item">
                                                <div className="tag">RMB</div>
                                                <div className="fz26">10</div>
                                                <div className="fz12">满599可用</div>
                                                <div className="btn">领取优惠券</div>
                                            </div>
                                        </div>
                                    :
                                    null
                            }
                            {
                                moduleData.content.style == 3
                                    ?
                                    moduleData.content.dataset.length
                                        ?
                                        <div className="coupon-style3">
                                            {
                                                moduleData.content.dataset.map(item => {
                                                    return (
                                                        <div className="coupon-item" key={item.id}>
                                                            <div className="flex justify-between align-center">
                                                                <div className="fz12 ft-bold">{item.coupon_type == 1 ?'RMB':item.coupon_type == 2 ?'折扣':'免费'}</div>
                                                                <div className="fz14">                                                            <div className="fz12">{item.use_condition == 1 ? '无门槛' : `满${item.least_price}可用`} </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-between align-center mgt5">
                                                                <div className="fz26">{item.coupon_type == 1 ?item.discount_price:item.coupon_type == 2 ?item.discount:<span className='fz22'>兑换</span> }</div>
                                                                <div className="btn">立即领取</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className="coupon-style3">
                                            <div className="coupon-item">
                                                <div className="flex justify-between align-center">
                                                    <div className="fz12 ft-bold">RMB</div>
                                                    <div className="fz14">无门槛</div>
                                                </div>
                                                <div className="flex justify-between align-center mgt5">
                                                    <div className="fz26">10</div>
                                                    <div className="btn">立即领取</div>
                                                </div>
                                            </div>
                                            <div className="coupon-item">
                                                <div className="flex justify-between align-center">
                                                    <div className="fz12 ft-bold">RMB</div>
                                                    <div className="fz14">满199可用</div>
                                                </div>
                                                <div className="flex justify-between align-center mgt5">
                                                    <div className="fz26">10</div>
                                                    <div className="btn">立即领取</div>
                                                </div>
                                            </div>
                                            <div className="coupon-item">
                                                <div className="flex justify-between align-center">
                                                    <div className="fz12 ft-bold">折扣</div>
                                                    <div className="fz14">满599可用</div>
                                                </div>
                                                <div className="flex justify-between align-center mgt5">
                                                    <div className="fz26">8.5</div>
                                                    <div className="btn">立即领取</div>
                                                </div>
                                            </div>
                                            <div className="coupon-item">
                                                <div className="flex justify-between align-center">
                                                    <div className="fz12 ft-bold">免费</div>
                                                    <div className="fz14">满2999可用</div>
                                                </div>
                                                <div className="flex justify-between align-center mgt5">
                                                    <div className="fz26">兑换</div>
                                                    <div className="btn">立即领取</div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    null
                            }
                        </div>

                        <EditorIcon type={`icon-guanbi`} className={`del-module`}
                            onClick={this.handleDeleteModule}></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_typ20)
