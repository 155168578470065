import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, Tag, TreeSelect, Modal, Divider, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { itemLists, itemCates, itemStatus, itemDelete, setField, itemSetCates } from '@/api/item'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
const FormItem = Form.Item;

class LessonLists extends React.Component{

    state = {
        selectRows:[],
        searchForm:{
            status:0,
            name:'',
            goods_code:'',
            goods_type:'',
            sell_channel:'',
            category_id:'',
            order_field:'',
            order_asc:''
        },
        columns:[
            {
                title:'课程名称',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60} height={60} />
                            <div className={`mgl10`}>
                                <p className={`fi-link w150`} onClick={() => {this.handleEdit(rowData)}}>{rowData.name || ''}</p>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'课程价格',
                dataIndex:'price',
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.price_edit
                                    ?
                                    <Input
                                        type={`number`}
                                        style={{ width:'80px' }}
                                        value={rowData.price}
                                        onChange={ (e) => { this.handleInputChange(e,rowData,index) } }
                                        onBlur={ (e) => { this.handleField(e,rowData,index) } }
                                        data-name={'price'}
                                    />
                                    :
                                    <span>{ rowData.price ? `${rowData.price}元` : `--` }</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={ (e) => { this.showEdit(e,rowData,index) } } data-name={`price`} />
                        </div>
                    )
                }
            },
            {
                title:'课程类目',
                dataIndex:'category_name',
                width:'150px',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.category_name
                                    ?
                                    rowData.category_name.split(',').map((item,index) => (
                                        <span className={`lh25`} key={index}>{item}/</span>

                                    ))
                                    :
                                    <p>--</p>
                            }
                        </div>
                    )
                }
            },
            {
                title:'收费方式',
                dataIndex:'basic_sales',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sales_edit
                                    ?
                                    <Input
                                        style={{ width:'80px' }}
                                        value={rowData.basic_sales}
                                        onChange={ (e) => { this.handleInputChange(e,rowData,index) } }
                                        onBlur={ (e) => { this.handleField(e,rowData,index) } }
                                        data-name={'basic_sales'}
                                    />
                                    :
                                    <span>{rowData.basic_sales || 0}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={ (e) => { this.showEdit(e,rowData,index) } } data-name={`basic_sales`} />
                        </div>
                    )
                }
            },
            {
                title:'销量',
                dataIndex:'real_sales',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.real_sales || 0}</p>
                    )
                }
            },
            {
                title:'排序',
                dataIndex:'sort',
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sort_edit
                                    ?
                                    <Input
                                        style={{ width:'80px' }}
                                        value={rowData.sort}
                                        onChange={ (e) => { this.handleInputChange(e,rowData,index) } }
                                        onBlur={ (e) => { this.handleField(e,rowData,index) } }
                                        data-name={'sort'}
                                    />
                                    :
                                    <span>{rowData.sort}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={ (e) => { this.showEdit(e,rowData,index) } } data-name={`sort`} />

                        </div>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'id',
                defaultSortOrder: '',
                sorter: true,
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'课程状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? `green` : `orange`}>{rowData.status == 1 ? '上架中' : '仓库中'}</Tag>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'20%',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            {
                                rowData.status == 1
                                    ?
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleOff(rowData)}}>下架</Button>
                                    :
                                    <Button type={"link"} size={"small"} onClick={() => {this.handleOn(rowData)}}>上架</Button>
                            }
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleShare(rowData)}}>推广</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        cateLists:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        poster:'',
        path:''
    }

    componentDidMount() {
        this.fetchData()
        this.fetchCate()
    }

    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        itemLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.goods_list.data,
                        total = res.data.goods_list.total,
                        per_page = res.data.goods_list.per_page;
                    lists.forEach(item => {
                        item.price_edit = 0;
                        item.sort_edit = 0;
                        item.sales_edit = 0;
                    })
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchCate = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        itemCates(sendData)
            .then(res => {
                let lists = res.data.list.data,
                    defaults = [ { name:'全部',id:0 } ];
                lists = defaults.concat(lists);
                lists.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })
                this.setState({
                    cateLists:lists
                })

            })
            .finally(res => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname:`/item/add`
        })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        searchForm.status = 0;
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleInputChange = (e,rowData,index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        lists[index][name] = val;
        this.setState({
            tableData:lists
        })
    }
    handleField = (e,rowData,index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if(!val){ return ; }
        sendData.id = rowData.id;
        sendData.key = name;
        sendData.value = val;
        setField(sendData)
            .then(res => {
                if(res.code == 1){
                    this.fetchData();
                }
            })

    }
    handleEdit = row => {
        this.props.history.push({
            pathname:`/item/add/${row.id}`
        })

    }
    showEdit = (e,rowData,index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'price':
                lists[index]['price_edit'] = 1
                break;
            case 'sort':
                lists[index]['sort_edit'] = 1
                break;
            case 'basic_sales':
                lists[index]['sales_edit'] = 1
                break;
        }
        this.setState({
            tableData:lists
        })
    }
    handleDelete = row => {
        let sendData = {}
        sendData.ids = [row.id];
        Modal.confirm({
            title:`是否删除该商品`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                itemDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleOff = row => {
        let sendData = {}
        sendData.ids = [row.id];
        sendData.status = 2;
        Modal.confirm({
            title:`是否将该商品下架`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                itemStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleOn = row => {
        let sendData = {}
        sendData.ids = [row.id];
        sendData.status = 1;
        Modal.confirm({
            title:`是否将该商品上架`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                itemStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/item/detail?id=${row.id}`
        })
        this.ShareModal.show()
    }
    patchDelete = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择要删除的商品')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        Modal.confirm({
            title:'确定要删除这些商品吗？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                itemDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    patchOn = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择要上架的商品')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        sendData.status = 1
        Modal.confirm({
            title:'确定要上架这些商品吗？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                itemStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    patchOff = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.error('请选择要下架的商品')
            return ;
        }
        sendData.ids = selectRows.map(item => {
            return item.id
        })
        sendData.status = 2
        Modal.confirm({
            title:'确定要下架这些商品吗？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                itemStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleSort = (pagination, filters, sorter) => {
        let field = sorter.field,
            order = sorter.order,
            searchForm = this.state.searchForm;
        if(order){
            searchForm.order_field = field;
            searchForm.order_asc  = order == 'ascend' ? 'asc' : 'desc';
        }else {
            searchForm.order_field = '';
            searchForm.order_asc  = '';
        }
        this.setState({
            searchForm
        },() => {
            this.fetchData()
        })
    }


    render() {
        const rowSelection = {
            selectedRowKeys:this.state.selectedRowKeys || [],
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form

        return(
            <div className={'shadow-radius'}>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>

                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'课程名称'} className={'fl'}>
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商品名称' style={{width:'200px'}}/>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'收费方式'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('status',{
                                        initialValue: 0
                                    })(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>按课时</Select.Option>
                                            <Select.Option value={2}>按时段</Select.Option>
                                            <Select.Option value={2}>按期</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'课程类目'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('cate_id',{
                                        initialValue: 0
                                    })(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>分类一</Select.Option>
                                            <Select.Option value={2}>分类2</Select.Option>
                                            <Select.Option value={2}>分类3</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={'课程状态'} className={' fl'}>
                                {
                                    getFieldDecorator('goods_type',{
                                        initialValue: 0
                                    })(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>出售中</Select.Option>
                                            <Select.Option value={2}>已售罄</Select.Option>
                                            <Select.Option value={3}>已下架</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'上课地点'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('sell_channel',{
                                        initialValue: 0
                                    })(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>门店1</Select.Option>
                                            <Select.Option value={2}>门店2</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem className={'mgl20 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增课程</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                        onChange={(pagination, filters, sorter) => { this.handleSort(pagination, filters, sorter) }}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}></div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }


}

export default Form.create({})(withRouter(LessonLists))
