import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Input } from 'antd'
import { getPayConfig, setPayConfig } from '@/api/system'
import Loading from '@/components/Loading'
import message from '@/utils/message'
const FormItem = Form.Item;
const { TextArea } = Input

class AddPaySet extends React.Component{
    state = {
        formData:'',
        isLoading:false
    }
    componentDidMount() {
        this.fetchConfig()
    }
    fetchConfig = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        getPayConfig(sendData)
            .then(res => {
                if(res.code == 1){
                    let config = res.data.config || '';
                    this.setState({
                        formData:config
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    saveData = () => {
        let sendData = {}
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
                this.setState({
                    isLoading:true
                })
                setPayConfig(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:'/system/payset'
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }

    render() {
        let data = this.state.formData || {};
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }


        return(
            <div className={`shadow-radius`}>
                <Form {...formItemLayout} className={'model-form '}>
                    <div className={`msg-title mgb15`}>微信支付设置</div>
                    <FormItem label={'商户号'}>
                        {getFieldDecorator('mch_id', {
                            initialValue: data.mch_id || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写商户号'
                                }
                            ]
                        })(<Input placeholder={'请填写商户号'} data-name={`mch_id`} />)}
                        <span className={`fi-help-text`}>微信支付的商户号</span>
                    </FormItem>
                    <FormItem label={'API秘钥'}>
                        {getFieldDecorator('key', {
                            initialValue: data.key || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写API秘钥'
                                }
                            ]
                        })(<Input placeholder={'请填写API秘钥'} data-name={`key`} />)}
                        <span className={`fi-help-text`}>请正确填写微信支付对应的32位API秘钥，如证书重新获取则秘钥需及时修改，以免收不到款。</span>
                    </FormItem>
                    <FormItem label={'粘贴apiclient_key证书'}>
                        {getFieldDecorator('ssl_key', {
                            initialValue: data.ssl_key || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写apiclient_key证书'
                                }
                            ]
                        })(<TextArea rows={12} placeholder={'粘贴apiclient_key证书'} />)}
                        <span className={`fi-help-text`}>请将下载下来的文件apiclient_key.pem内容以文本方式打开，不要做任何修改，复制出来</span>
                    </FormItem>
                    <FormItem label={'粘贴apiclient_cert证书'}>
                        {getFieldDecorator('ssl_cert', {
                            initialValue: data.ssl_cert || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写apiclient_cert证书'
                                }
                            ]
                        })(<TextArea rows={12} placeholder={'粘贴apiclient_cert证书'} />)}
                        <span className={`fi-help-text`}>请将下载下来的文件:apiclient_cert.pem内容以文本方式打开，不要做任何修改，复制出来</span>
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={ this.saveData }>立即保存</Button>
                    </FormItem>



                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}
export default Form.create({})(withRouter(AddPaySet))
