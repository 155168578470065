import * as actionTypes from '../constants/index'
const setMenu = (data) => {
    return {
        type: actionTypes.SET_MENU,
        data
    }
}
const removeMenu = () => {
    return {
        type: actionTypes.REMOVE_MENU
    }

}
export { setMenu, removeMenu }
