import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Tag, Divider, Table, Button, Modal, message } from 'antd';
import LeaveRefuse from './components/leaveRefuse'
import Loading from '@/components/Loading'
import { studentApplyDetail, studentAgreeApply } from '@/api/educational'

import '@/assets/css/educational/educational.scss';
import leave from './leave';


class LeaveDetail extends Component {
    state = {
        columns: [
            {
                title: '上课时间',
                dataIndex: '1',

                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.table_date + ` ` + rowData.start_time + `-` + rowData.end_time}</div>
                    )
                }
            },
            {
                title: '班级',
                dataIndex: '2',

                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.name}</div>
                    )
                }
            },
            {
                title: '老师',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div> {rowData.teacher_name || "-"}</div>
                    )
                }
            },
            {
                title: '助教',
                dataIndex: '4',
                render: (text, rowData, index) => {

                    return (
                        <div>{rowData.assist_teacher_name || "-"} </div>
                    )
                }
            },
            {
                title: '教室',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>-</div>
                    )
                }
            }
        ],
        leave: {},
        tableData: [],
        visible: false,
        isLoading: false,
        imgVisible:false,
        img_url:""
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.fetchData()
        } else {
            this.props.history.goBack()
        }
    }

    fetchData = () => {
        let sendData = { id: this.props.match.params.id }

        this.setState({
            isLoading: true
        })
        studentApplyDetail(sendData).then(res => {
            if (res.code == 1) {
                let { detail, course_list } = res.data
                this.setState({
                    leave: detail,
                    tableData: course_list
                })
            }


        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleConfirm = () => {
        let _this = this
        Modal.confirm({
            title: '是否通过学员的请假审批?',
            onOk() {
                _this.setState({
                    isLoading: true
                })
                studentAgreeApply({ id: _this.props.match.params.id }).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                        _this.fetchData()
                    }

                }).finally(() => {
                    _this.setState({
                        isLoading: false
                    })
                })
            }
        });
    }

    handleRefuse = () => {
        this.setState({
            visible: true
        })
    }


    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleImgCancel=()=>{
        this.setState({
            imgVisible:false
        })
    }
    openImage=img_url=>{
        this.setState({
            imgVisible:true,
            img_url
        })
    }



    render() {
        let leave = this.state.leave || {}
        return (
            <div className={'shadow-radius leave-container'}>
                <div className="leave-head">
                    <span className="leave-title">请假申请</span>
                    <Tag color="#f44">{["", "待处理", "已撤销", "已同意", "已拒绝"][leave.status || 1]}</Tag>
                </div>
                <Divider />
                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">学员：</div>
                    </Col>
                    <Col span={17}>
                        <div>{leave.student && leave.student.real_name || "-"}</div>
                    </Col>
                </Row>
                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">请假时间：</div>
                    </Col>
                    <Col span={17}>
                        <div>{leave.start_time} 至 {leave.end_time}</div>
                    </Col>
                </Row>
                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">请假课程：</div>
                    </Col>
                    <Col span={17}>
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={false}
                            rowKey={record => record.id}
                            pagination={false}
                        ></Table>
                    </Col>
                    <Col span={2}></Col>
                </Row>
                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">请假类型：</div>
                    </Col>
                    <Col span={17}>
                        <div>{leave.type_name}</div>
                    </Col>
                </Row>

                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">请假原因：</div>
                    </Col>
                    <Col span={17}>
                        <div>{leave.reason || "-"}</div>
                    </Col>
                </Row>

                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">相关凭证：</div>
                    </Col>
                    <Col span={17}>
                        <div className='leave-apply-imgs'>
                            {
                                leave.pic_urls && leave.pic_urls.map(val => {
                                    return (
                                        <div className="leave-apply-img" onClick={()=>this.openImage(val)}>
                                            <img src={val} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>

                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">提交人：</div>
                    </Col>
                    <Col span={17}>
                        <div>{leave.user && leave.user.name || "-"}</div>
                    </Col>
                </Row>

                <Row className='mgt20'>
                    <Col span={3}>
                        <div className="leave-label">提交时间：</div>
                    </Col>
                    <Col span={17}>
                        <div>{leave.created_at}</div>
                    </Col>
                </Row>



                {
                    leave.status == 4 ?
                        <Row className='mgt20'>
                            <Col span={3}>
                                <div className="leave-label">拒绝原因：</div>
                            </Col>
                            <Col span={17}>
                                <div>{leave.refuse_reason || "-"}</div>
                            </Col>
                        </Row>
                        : null
                }

                {
                    leave.status == 1 ?
                        <Row className='mgt20'>
                            <Col span={3}>  </Col>
                            <Col span={17}>
                                <Button type="primary" className='mgr20' onClick={this.handleConfirm}>同意</Button>
                                <Button onClick={this.handleRefuse}>拒绝</Button>
                            </Col>
                        </Row>
                        : null
                }


                {this.state.visible ?
                    <LeaveRefuse close={this.handleCancel} success={this.fetchData} id={this.props.match.params.id} />
                    : null}
                {this.state.isLoading ? <Loading /> : null}

                <Modal visible={this.state.imgVisible} footer={null} onCancel={this.handleImgCancel}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.img_url} />
                </Modal>
            </div>
        )
    }
}

export default withRouter(LeaveDetail)
