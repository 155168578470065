import React, { Component } from 'react'
import { Form, Input, DatePicker, Button, Select, Radio, Divider, InputNumber, Tooltip, Icon, Checkbox, Table, message, Carousel } from 'antd';
import { withRouter, Link } from 'react-router-dom'
import moment from 'moment'
import Loading from '@/components/Loading';
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import FullDiscount from './components/full-discount';
import Editor from '@/components/Editor/Editor'
import MarketItemPicker from './components/market-item-picker';
import SetItemStock from './components/set-item-stock';
import { storeLists } from '@/api/store'
import { expressTemplateLists } from '@/api/express'
import {socialGroupSave,socialGroupDetail} from '@/api/group-solitaire'

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select

class GroupSolitaireAdd extends Component {
    state = {
        isLoading: false,
        detail: {},
        formData: {
            limit_buy: 1,
        },
        storeLists: [],
        expressLists: [],
        showEditor: false,
        step1_data: {},
        step: 1,
        columns: [
            {
                title: '商品名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '零售价',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <p>&yen;{rowData.price}</p>
                    )
                }
            },
            // {
            //     title: '商品库存',
            //     dataIndex: 'goods_stock',
            //     render: (text, rowData, index) => {
            //         let total_stock = 0;
            //         if (rowData.sku_list && rowData.sku_list.length) {
            //             let tmp_stock = 0;
            //             rowData.sku_list.forEach((sku, skuKey) => {
            //                 let stock = sku.sku_stock ? Number(sku.sku_stock) : 0
            //                 tmp_stock = tmp_stock + stock
            //             })
            //             total_stock = tmp_stock;
            //         } else {
            //             total_stock = rowData.goods_stock || 0
            //         }

            //         return (
            //             <div>{total_stock}</div>
            //         )
            //     }
            // },
            {
                title: '活动价',
                dataIndex: 'sales_price',
                render: (text, rowData, index) => {

                    if (!rowData.sales_price) {
                        return <div>未设置</div>
                    }
                    return (
                        <div>&yen;{rowData.sales_price}</div>
                    )
                }
            },
            {
                title: '活动库存',
                dataIndex: 'can_sale_stock',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.stock_edit
                                    ?
                                    <Input
                                        style={{ width: '60px' }}
                                        value={rowData.can_sale_stock || ''}
                                        onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                        onBlur={(e) => { this.showStockEdit(e, rowData, index, false) }}
                                        data-name={`can_sale_stock`}
                                    />
                                    :
                                    <span>{rowData.can_sale_stock ?? `未设置`}</span>
                            }
                            {
                                rowData.sku_list && rowData.sku_list.length ? null :
                                    <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showStockEdit(e, rowData, index, true) }} data-name={`can_sale_stock`} />
                            }

                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '25%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData, index) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDelete(rowData, index) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        currentItem: '',
    }

    componentDidMount() {
        this.fetchStore()
        this.fetchExpress()

        if (this.props.match.params.id) {
            this.fetchData()
        }else{
            this.setState({
                showEditor: true
            })
        }

    }

    fetchData = () => {
        let sendData = {}
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading: true
        })
        socialGroupDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let detail = res.data.detail,
                        goods_list = detail.goods_list || [],
                        formData=this.state.formData;
                    
                        detail.time = [moment(detail.start_time, 'YYYY-MM-DD HH:mm:ss'), moment(detail.end_time, 'YYYY-MM-DD HH:mm:ss')]

                        if(detail.limit_buy){
                            formData.limit_buy=detail.limit_buy
                            detail.is_activity_count=1
                        }else{
                            detail.is_activity_count=0
                        }


                        formData.bg_img=detail.bg_img || ''
                        formData.share_img=detail.share_img || ''
                        formData.content=detail.content ||''


                    this.setState({
                        detail: detail,
                        tableData: goods_list,
                        formData,
                        showEditor:true
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }

    

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    fetchExpress = (params = {}) => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;

        this.setState({
            isLoading: true
        })
        expressTemplateLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.data.data;
                    this.setState({
                        expressLists: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleFormDataUpdate = (name, type) => {
        return (e, d) => {
            const { formData } = this.state
            if (type == 'radio') {
                formData[name] = e.target.value
            } else {
                formData[name] = e
            }

            this.setState({
                formData
            })
        }
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.content = content;
        this.setState({
            formData
        })
    }

    handleSaveForm = () => {

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let { formData, detail, step1_data } = this.state
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''

                if (values.full_cut) {
                    values.full_cut_rule = this.FullDiscount.get_full_cut_data()
                } else {
                    values.full_cut_rule = null
                }

                if (!values.is_activity_count) {
                    formData.limit_buy = 0
                }

                step1_data = { ...values, ...formData }

                this.setState({
                    step: 2,
                    step1_data
                })
            }
        })

    }

    handleItem = () => {
        let tableData = this.state.tableData || [],
            step1_data=this.state.step1_data,
            store_id=step1_data.store_id || 0;

        this.MarketItemPicker.show(store_id,res => {
            if (res) {
                let lists = [];
                res.forEach((item, index) => {
                    tableData.forEach((child, childKey) => {
                        if (child.id == item.id) {
                            item = child
                        }else{
                            item.basic_sales=0
                        }
                    })
                    lists.push(item)
                })
                tableData.forEach((child, childKey) => {
                   let index=lists.findIndex(val=>val.id==child.id);
                  
                   if(index ==-1){
                    lists.push(child)
                   }
                })
                

                if (lists.length > 29) {
                    lists.length = 30
                }
                this.setState({
                    tableData: lists
                })
            }
        })
    }

    handleDelete = (rowData, index) => {
        let tableData = this.state.tableData;
        tableData.splice(index, 1);
        this.setState({
            tableData
        })
    }

    handleEdit = (rowData, index) => {
        let sendData = {},
            tableData = this.state.tableData || [];
        for (var i in rowData['goods_extend']) {
            if (!rowData[i]) {
                rowData[i] = rowData['goods_extend'][i]
            }
        }
        delete rowData['goods_extend']
        this.setState({
            currentItem: rowData
        }, () => {
            this.SetItemStock.show(result => {
                if (result.item.sku_list && result.item.sku_list.length) {
                    let tmp_stock = 0,
                        sku_price_arr = []
                    result.item.sku_list.forEach((sku, skuKey) => {
                        if (sku.is_join) {
                            sku.stock = sku.stock ? Number(sku.stock) : 0
                            tmp_stock = tmp_stock + sku.stock;

                            sku.sales_price = sku.sales_price ?? sku.price;
                            sku_price_arr.push(parseFloat(sku.sales_price))
                        }
                    })
                    result.item.stock = tmp_stock;

                    if (sku_price_arr.length) {
                        if (Math.min(...sku_price_arr) == Math.max(...sku_price_arr)) {
                            result.item.sales_price = `${Math.max(...sku_price_arr).toFixed(2)}`
                        } else {
                            result.item.sales_price = `${Math.min(...sku_price_arr).toFixed(2)}~${Math.max(...sku_price_arr).toFixed(2)}`
                        }
                    }
                }

                rowData = result.item;
                rowData.sort = result.sort || 0;
                rowData.basic_sales = result.basic_sales || 0;
                rowData.can_sale_stock = result.item.stock;
                rowData.sales_price = result.item.sales_price ?? result.item.price;

                tableData[index] = rowData;
                this.setState({
                    tableData
                })

            })
        })
    }

    showStockEdit = (e, rowData, index, bool) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'can_sale_stock':
                lists[index]['stock_edit'] = bool
                break;
        }
        this.setState({
            tableData: lists
        })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }

    handlePrevStep = () => {
        this.setState({
            step: 1
        })
    }

    saveData = () => {
        let itemLists = this.state.tableData || [] ,
            step1_data = this.state.step1_data,
            goods_list = [],
            saveData={};

            goods_list=itemLists.map(item=>{
                let sku_list=[]
                if(item.sku_list){
                    sku_list=item.sku_list.map(val=>{
                        
                        return {
                            id:val.id,
                            is_join:val.is_join ? 1 : 0,
                            price:val.sales_price  ?? val.price ,
                            stock:val.stock ?? 0
                        }
                    })
                }

                return {
                    id:item.id,
                    price:item.sales_price ?? item.price,
                    stock:item.stock ||0 ,
                    sku_list,
                    sort:item.sort,
                    basic_sales:item.basic_sales
                }
            })

            step1_data.time=null

            saveData={...step1_data,goods_list}

            if(this.props.match.params.id){
                saveData.id=this.props.match.params.id
            }

            this.setState({
                isLoading:true
            })
            socialGroupSave(saveData).then(res=>{
                if(res.code==1){
                    message.success(res.msg,()=>{
                        this.goBack()
                    })
                }

            }).finally(()=>{
                this.setState({
                    isLoading:false
                })
            })
    }


    goBack = () => {
        this.props.history.goBack()
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 19
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form
        const data = this.state.detail || {}
        const formData = this.state.formData


        return (
            <div className='shadow-radius' >
                <div className={`active-add flex justify-between ${this.state.step == 1 ? '' : 'hide-max'}`}>
                    <div className="phone-wrap">
                        <img src="https://img.cxkoo.com/chengxuan/1/2023-01-16/c502f9def5f69351a5055f672106ba37.png" alt="" />
                    </div>
                    <div className="ctrl-wrap">
                        <Form  {...formItemLayout} className={'model-form '} >
                            <div className={`msg-title mgb15`}>基本信息</div>
                            <FormItem label={'活动标题'}>
                                {getFieldDecorator('name', {
                                    initialValue: data.name || undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入活动标题'
                                        }
                                    ]
                                })(<Input
                                    style={{ width: 400 }}
                                    placeholder={'请输入活动标题'}
                                    maxLength={60}
                                    suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/60</span>}
                                />)}
                                <span className={'fi-help-text'}>活动标题最多 60 个字符</span>
                            </FormItem>
                            <FormItem label={'活动副标题'}>
                                {getFieldDecorator('sub_title', {
                                    initialValue: data.sub_title || undefined,
                                })(<Input
                                    style={{ width: 400 }}
                                    placeholder={'请输入活动副标题'}
                                    maxLength={30}
                                    suffix={<span>{getFieldValue("sub_title") ? getFieldValue("sub_title").length : 0}/30</span>}
                                />)}
                                <span className={'fi-help-text'}>活动标题最多 30 个字符</span>
                            </FormItem>
                            <FormItem label={'活动时间'}>
                                {getFieldDecorator('time', {
                                    initialValue: data.time || null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择活动时间'
                                        }
                                    ]
                                })(
                                    <RangePicker
                                        style={{ width: 400 }}
                                        showTime={{ format: 'HH:mm:ss' }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        placeholder={['开始时间', '结束时间']}
                                    />
                                )}
                            </FormItem>

                            <FormItem label={"适用门店"} >
                                {getFieldDecorator('suit_store_type', {
                                    initialValue: data.suit_store_type || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择适用门店'
                                        }
                                    ]
                                })(
                                    <Radio.Group  >
                                        <Radio value={1}> 所有门店</Radio>
                                        <Radio value={2}>指定门店 </Radio>
                                    </Radio.Group>
                                )}
                                <span className={'fi-help-text'}>选择全部门店时，建议查看添加的商品是否所有门店都有库存</span>
                            </FormItem>

                            {
                                getFieldValue("suit_store_type") == 2 ?
                                    <FormItem label={'选择门店'} >
                                        {getFieldDecorator('store_id', {
                                            initialValue: data.store_id  || undefined,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择指定门店'
                                                }
                                            ]
                                        })(
                                            <Select
                                                style={{ width: 400 }}
                                                placeholder="请选择指定门店"
                                            >
                                                {
                                                    this.state.storeLists.map(store => (
                                                        <Option value={store.id} key={store.id}>{store.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        )}

                                    </FormItem>
                                    : null
                            }

                            <FormItem label={"限购方式"} >
                                {getFieldDecorator('is_activity_count', {
                                    initialValue: data.is_activity_count || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择活动限购方式'
                                        }
                                    ]
                                })(
                                    <Radio.Group   >
                                        <Radio value={0}>不限制 </Radio>
                                        <Radio value={1}>每人每种商品限购
                                            <InputNumber onChange={this.handleFormDataUpdate('limit_buy', 'inputnumber')} value={formData.limit_buy} style={{ width: 50 }} min={1} precision={0} className="mgl5 mgr5" />件
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>

                            <FormItem label={"优惠券"} >
                                {getFieldDecorator('can_use_coupon', {
                                    initialValue: data.can_use_coupon || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择优惠券使用方式'
                                        }
                                    ]
                                })(
                                    <Radio.Group  >
                                        <Radio value={0}>不叠加</Radio>
                                        <Radio value={1}>可叠加
                                            <Tooltip placement={`right`} title={`活动商品都参与优惠券叠加，可用优惠券不含兑换券`}>
                                                <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                            </Tooltip>
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>

                            <FormItem label={"满减优惠"} >
                                {getFieldDecorator('full_cut', {
                                    initialValue: data.full_cut || 0,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择是否参与满减'
                                        }
                                    ]
                                })(
                                    <Radio.Group  >
                                        <Radio value={0}>不叠加</Radio>
                                        <Radio value={1}>可叠加
                                            <Tooltip placement={`right`} title={`活动商品都参与满减叠加`}>
                                                <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                            </Tooltip>
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>

                            {
                                getFieldValue("full_cut") == 1 ?
                                    <FormItem label={' '} className={'no-colon'} >
                                        <FullDiscount onRef={e => { this.FullDiscount = e }} full_cut_rule={data.full_cut_rule || []}  />
                                    </FormItem>
                                    : null
                            }

                            <FormItem label={"参与限制"} >
                                {getFieldDecorator('join_set', {
                                    initialValue: data.join_set || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择参与限制'
                                        }
                                    ]
                                })(
                                    <Radio.Group  >
                                        <Radio value={1}>所有客户 </Radio>
                                        <Radio value={2}>仅限新客户
                                            <Tooltip placement={`right`} title={`新客户指店铺内没有订单的客户，或仅拥有以下类型订单的客户：全额退款订单、已关闭的待付款订单`}>
                                                <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                            </Tooltip>
                                        </Radio>

                                    </Radio.Group>
                                )}
                            </FormItem>

                            <FormItem label={'物流方式'}>
                                {getFieldDecorator('express_set', {
                                    initialValue: data.express_set || [1],
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择物流方式'
                                        }
                                    ]
                                })(
                                    <Checkbox.Group >
                                        <Checkbox value={1}>快递配送</Checkbox>
                                        <Checkbox value={2}>到店自提</Checkbox>
                                    </Checkbox.Group>
                                )}
                            </FormItem>
                            {
                                getFieldValue('express_set').includes(1)
                                    ?
                                    <FormItem label={'选择快递模板'}>
                                        {
                                            getFieldDecorator('post_template_id', {
                                                initialValue: data.post_template_id || undefined,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择运费模板'
                                                    }
                                                ]
                                            })(
                                                <Select style={{ width: '300px' }} placeholder={'请选择'} >
                                                    {
                                                        this.state.expressLists.map((item, index) => (
                                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            )
                                        }
                                        <Link to={'/express/template'} target={`_blank`} >
                                            <Button type={'primary'} ghost icon={'plus'} className={`mgl10`}>新增运费模板</Button>
                                        </Link>
                                        <Button type={'primary'} ghost icon={`sync`} className={`mgl10`} onClick={() => { this.fetchExpress({ showLoading: true }) }} >刷新模板数据</Button>
                                    </FormItem>
                                    :
                                    null
                            }

                            <FormItem label={'浏览次数'}>
                                {getFieldDecorator('pv', {
                                    initialValue: data.pv || undefined
                                })(
                                    <InputNumber
                                        style={{ width: 400 }}
                                        min={0}
                                        precision={0}
                                        placeholder={''}
                                    />
                                )}
                                <span className={'fi-help-text'}>浏览次数=初始数+实际浏览次数，会在活动开始后展示</span>
                            </FormItem>

                            <FormItem label={'跟团人数'}>
                                {getFieldDecorator('group_num', {
                                    initialValue: data.group_num || undefined
                                })(
                                    <InputNumber
                                        style={{ width: 400 }}
                                        min={0}
                                        precision={0}
                                        placeholder={''}
                                    />
                                )}
                                <span className={'fi-help-text'}>跟团人数=初始人数+实际跟团人数，会在活动开始后展示</span>
                            </FormItem>

                            <FormItem label={'排序'}>
                                {getFieldDecorator('sort', {
                                    initialValue: data.sort || 0
                                })(
                                    <InputNumber
                                        style={{ width: 400 }}
                                        min={0}
                                        precision={0}
                                        placeholder={''}
                                    />
                                )}
                                <span className={'fi-help-text'}>小程序端及后台呈现的形式，按照数字从小到大排列，不填写，默认按后添加的在前</span>
                            </FormItem>

                            <FormItem label={`背景图`}>
                                <div className={'clearfix'}>
                                    {
                                        formData.bg_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={formData.bg_img}
                                                onDel={e => { this.delImg(e, 'bg_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'bg_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：750x420像素，单张不超过2M</span>
                            </FormItem>

                            <FormItem label={'分享描述'}>
                                {getFieldDecorator('share_title', {
                                    initialValue: data.share_title || undefined
                                })(<Input
                                    style={{ width: 400 }}
                                    placeholder={'请输入分享描述'}
                                    maxLength={20}
                                    suffix={<span>{getFieldValue("share_title") ? getFieldValue("share_title").length : 0}/20</span>}
                                />)}
                                <span className={'fi-help-text'}>将展示在分享链接中，最多20个字符</span>
                            </FormItem>

                            <FormItem label={`分享图片`}>
                                <div className={'clearfix'}>
                                    {
                                        formData.share_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={formData.share_img}
                                                onDel={e => { this.delImg(e, 'share_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'share_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M，用于小程序卡片分享</span>
                            </FormItem>

                            <FormItem label={'商品详情'}>
                                {
                                    this.state.showEditor
                                        ?
                                        <Editor id={'text'} value={formData.content} callback={content => this.EditorChange(content)}></Editor>
                                        :
                                        null
                                }
                            </FormItem>

                            <FormItem label={' '} className={'no-colon'} >
                                <Button onClick={this.goBack} >取消</Button>
                                <Button type='primary' className='mgl20' onClick={this.handleSaveForm} >下一步添加商品</Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>

                <div className={this.state.step == 2 ? '' : 'hide-max'}>
                    <div className={`msg-title mgb15`}  >添加团购商品</div>
                    <Button type={`primary`} onClick={this.handleItem}>添加商品</Button>
                    <div className='mgt15'>
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={(record, index) => record.id}
                        ></Table>
                    </div>
                    <div className={`txtCenter mgt20`}>
                        <Button type={`default`} className={`mgr15`} onClick={this.handlePrevStep}>上一步</Button>
                        <Button type={`primary`} onClick={this.saveData}>保存</Button>
                    </div>
                </div>


                <MarketItemPicker
                    onRef={e => { this.MarketItemPicker = e }}
                    selectIds={this.state.tableData.map(item => { return Number(item.id) })}
                    multiple={true}
                ></MarketItemPicker>

                <SetItemStock
                    onRef={e => { this.SetItemStock = e }}
                    item={this.state.currentItem}
                ></SetItemStock>

                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(GroupSolitaireAdd))
