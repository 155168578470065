import React from 'react'
import { Form, Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import { fxMemberLists, fxAuditLists, fxAudit  } from '@/api/fx'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import CX from '@/utils/CX'
import MemberLists from "./components/member-lists";
import AuditLists from "./components/audit-lists";
const FormItem = Form.Item
const { TabPane } = Tabs

class FxMemberLists extends React.Component{
    state = {
        activeStatus:'1',
        isLoading:false,
    }

    componentDidMount() {

    }
    clickTab = (val) => {
        this.setState({
            activeStatus:val
        })
        console.log(val);
        if(val == 1){
            this.MemberLists.fetchData()
        }
    }


    render() {
        return(
            <div className={`shadow-radius`}>
                <Tabs  onChange={this.clickTab} type="card" className={''} activeKey={this.state.activeStatus}>
                    <TabPane tab="分销员列表" key={`1`} >
                        <MemberLists onRef={ e => { this.MemberLists = e } }></MemberLists>
                    </TabPane>
                    <TabPane tab="信息审核" key={`2`} >
                        <AuditLists onRef={ e => { this.AuditLists = e } }></AuditLists>
                    </TabPane>
                </Tabs>
                { this.state.isLoading ? <Loading /> : null }

            </div>
        )
    }


}

export default Form.create({})(withRouter(FxMemberLists))
