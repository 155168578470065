import React, { Component } from 'react'
import { Button, Table, Pagination ,Divider, message} from 'antd'
import ConditionTags from './components/ConditionTags'
import { labelList ,labelDeleteLabel} from '@/api/user'

export default class ConditionLabel extends Component {
    state = {
        columns: [
            {
                title: '标签组名称',
                dataIndex: '1',
                width:200,
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.group_name}</div>
                    )
                }
            },
            {
                title: '条件内容',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    let desc=rowData.desc || {}
                    return (
                        <div>
                            {
                                desc.user ? 
                                <div className='flex mgt5'>
                                    <div className='flex-shrink0'>客户条件：</div>
                                    <div>
                                        {
                                           desc.user.map((val,index)=>{
                                            return <p key={index}>{val}</p>
                                           }) 
                                        }
                                    </div>
                                </div>:null
                            }
                             {
                                desc.goods  ? 
                                <div className='flex mgt5'>
                                    <div className='flex-shrink0'>商品条件：</div>
                                    <div>
                                        {
                                           desc.goods.map((val,index)=>{
                                            return <p key={index}>{val}</p>
                                           }) 
                                        }
                                    </div>
                                </div>:null
                            }
                             {
                                desc.points ? 
                                <div className='flex mgt5'>
                                    <div className='flex-shrink0'>积分条件：</div>
                                    <div>
                                        {
                                           desc.points.map((val,index)=>{
                                            return <p key={index}>{val}</p>
                                           }) 
                                        }
                                    </div>
                                </div>:null
                            }
                             {
                                desc.trade ? 
                                <div className='flex mgt5'>
                                    <div className='flex-shrink0'>交易条件：</div>
                                    <div>
                                        {
                                           desc.trade.map((val,index)=>{
                                            return <p key={index}>{val}</p>
                                           }) 
                                        }
                                    </div>
                                </div>:null
                            }

                        </div>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: '3',
                width:180,
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.created_at}</div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width:140,
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleEdit(rowData)}}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={()=>{this.handleDele(rowData.id)}} >删除</Button>
                        </div>
                    )
                }
            }
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        per_page: 20,
        total: 0,
        status:1,
        tag_item:null
    }

    componentDidMount(){
        this.fetchData()
    }
   
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.activeKey == '2' && this.state.status != 1 ){
            this.setState({
                status:1
            })
        }
    }

    fetchData = () => {
        let sendData={
            page:this.state.page,
            per_page:this.state.per_page
        }

        this.setState({
            tableLoading:true
        })
        labelList(sendData).then(res=>{
            if(res.code==1){
                let {total,current_page,data}=res.data.list
                this.setState({
                    total,
                    page:current_page,
                    tableData:data
                })
            }

        }).finally(()=>{
            this.setState({
                tableLoading:false
            })
        })
    }

    handleEdit=item=>{
        this.setState({
            tag_item:item,
            status:3
        })
    }

    handleDele=id=>{
        labelDeleteLabel({id}).then(res=>{
            if(res.code==1){
                message.success('删除成功！');
                this.fetchData()
            }
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    handleCreate=()=>{
        this.setState({
            status:2
        })
    }

    handleBack=()=>{
        this.setState({
            status:1
        },()=>{
            this.fetchData()
        })
    }


    render() {

        if(this.state.status != 1){
            return <ConditionTags back={this.handleBack} status={this.state.status} tag_item={this.state.tag_item} />
        }


        return (
            <div>
                <div className='mgt10'>
                    <Button type={'primary'}onClick={this.handleCreate} >条件打标签</Button>
                    <span className='mgl20 fz14'>每天0点系统根据规则设置给客户动态打标签，同一个标签组只能设置一个规则</span>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}
