import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, DatePicker, Table, Pagination, Tooltip, Tag } from 'antd'
import moment from 'moment'
import { redGameGameData ,exportRedGame} from "@/api/red-game.js"
import CX from '@/utils/CX'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class RedGameActivity extends Component {

    state = {
        columns: [
            {
                title: '客户信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className='flex align-center'>
                            <img src={rowData.user.head_img_url} width={60} alt="" />
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.user.name || ''}
                                    <i className={`icon-${rowData.user.sex == 1 ? 'male' : rowData.user.sex == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.user.mobile || `暂无手机号`}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '红包个数',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData.red_num}</p>
                }
            },
            {
                title: '奖品类型',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    if(!rowData.prize){
                        return  <p>{'-'}</p>
                    }

                    let type=rowData.prize.type,type_text
                    if(type==1){
                        type_text="优惠券"
                    }else if(type==2){
                        type_text="积分"
                    }else{
                        type_text="微信零钱"
                    }

                    if(!rowData.game_prize_id ){
                        type_text="-"
                    }
                    return <p>{type_text}</p>
                }
            },

          
            {
                title: '奖品内容',
                dataIndex: '4',
                render: (text, rowData, index) => {

                    if(!rowData.prize){
                        return  <p>{'-'}</p>
                    }
                    let prize=rowData.prize,  type=prize.type,type_text
                    if(type==1){
                        type_text=prize.coupon.name
                    }else if(type==2){
                        type_text= prize.points+"积分"
                    }else{
                        type_text=prize.money+"元"
                    }

                    if(!rowData.game_prize_id ){
                        type_text="-"
                    }

                    return <p>{type_text}</p>
                }
            },
           
            {
                title: '参与时间',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.created_at || '-'}</p>
                    )
                }
            },

            {
                title: '发奖时间',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.send_time || '-'}</p>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    let status_text,color,wx_text
                   

                    if(rowData.game_prize_id ){
                        color='green'
                        status_text='奖励已发放'

                        if(!rowData.status ){
                            status_text='奖励发放失败'
                        }

                    }else{
                        color=''
                        status_text='未得奖'
                    }

                    if(rowData.prize && rowData.prize.type ==3 && rowData.status==0){
                        wx_text='账户余额不足'
                    }

                    return (
                        <div>
                            <Tag color={color}>{ status_text}</Tag>
                            {wx_text?<p>{wx_text}</p>:null}
                        </div>
                    )
                }
            },


        ],
        searchForm: {
            user_name: "",
            start_time: "",
            end_time: "",
            status:0
        },
        storeList: [],
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false,
       
    }

    componentDidMount() {
        this.fetchData()
        
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.id = this.props.match.params.id

        this.setState({
            tableLoading: true
        })
        redGameGameData(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }



    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }

    handleExport = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                let sendData = values;
                sendData.id = this.props.match.params.id
                this.setState({
                    isLoading: true
                })
                exportRedGame(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '红包雨活动数据.csv';
                        CX.exportExcel(blob, fileName)
                    }).finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        searchForm.status=0
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }





    render() {
       
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>客户系信息</span>}  className={'fl'}>
                                {
                                    getFieldDecorator('user_name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入呢称/手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'状态'} className={'fl mgl40'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: '200px' }}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>奖励已发放</Select.Option>
                                            <Select.Option value={2}>奖励发放失败</Select.Option>
                                            <Select.Option value={3}>未得奖</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                        </div>

                        <div className={`clearfix`}>
                          
                            <FormItem  label={<span>&emsp;中奖时间</span>} className={'fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: null
                                    })(
                                        <RangePicker
                                            style={{ width: 400 }}
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder={['开始时间', '结束时间']}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;</span>}  >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleExport() }}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default Form.create({})(withRouter(RedGameActivity))