import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Divider, Tabs, message } from 'antd';
import BaseForm from './components/BaseForm'
import ShareForm from './components/ShareForm';
import PrizeForm from './components/PrizeForm';
import Loading from '@/components/Loading';
import {lotterySave,lotteryDetail} from '@/api/lucky.js';
import moment from 'moment'
import { LuckyWheel, LuckyGrid } from '@lucky-canvas/react';
import '@/assets/css/lucky/index.scss';
const FormItem = Form.Item;
const { TabPane } = Tabs;

const no_prize_img = 'https://img.cxkoo.com/chengxuan/1/2023-05-05/4a47e7a95759bc2340e3babe797939a8.png';
const default_bg_img = "https://img.cxkoo.com/chengxuan/1/2023-05-05/64dad0836dec8f7dccbadeb06894c3b7.png";
class LuckyAdd extends Component {
    state = {
        activeStatus: '1',
        isLoading: false,
        bg_img: "https://img.cxkoo.com/chengxuan/1/2023-05-05/64dad0836dec8f7dccbadeb06894c3b7.png",
        share_poster: "",
        is_edit: false,
        activityData: {},
        bg_color: "#FFDCB6",
        isForceRender: false,
        lottery_type: 1,
        exampleLists: [
            {
                id: 1,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },
            {
                id: 2,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },
            {
                id: 3,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },
            {
                id: 4,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },
            {
                id: 5,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },
            {
                id: 6,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },
            {
                id: 7,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },
            {
                id: 8,
                prize_id: '0',
                prize_img: no_prize_img,
                odds_num: 0,
                prize_name: '谢谢参与'
            },

        ],
        prize_list_show: 1,
        extra_prize: [1],
        start_time:'',
        end_time:''
    }

    componentDidMount() {

        if (this.props.match.params.id) {
            this.setState({
                is_edit: true
            })
            this.fetchData()
        } else {
            this.setState({
                isForceRender: true
            })
        }
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })

        lotteryDetail({id:this.props.match.params.id}).then(res=>{
            if(res.code ==1){

                const {detail}=res.data

                if(detail.start_time && detail.end_time){
                    detail.time=[moment(detail.start_time,'YYYY-MM-DD HH:mm:ss'),moment(detail.end_time,'YYYY-MM-DD HH:mm:ss')]
                }
                detail.extra_prize=[];
                if(detail.points_switch){
                    detail.extra_prize.push(1)
                }
                if(detail.share_switch){
                    detail.extra_prize.push(2)
                }
                if(detail.order_switch){
                    detail.extra_prize.push(3)
                }

                detail.play_limit=[];
                if(detail.join_day_user_switch){
                    detail.play_limit.push(1)
                }
                if(detail.join_user_switch){
                    detail.play_limit.push(2)
                }

                detail.gain_limit=[];
                if(detail.prize_day_user_switch){
                    detail.gain_limit.push(1)
                }
                if(detail.prize_user_switch){
                    detail.gain_limit.push(2)
                }

                if(detail.status ==2){
                    detail.is_disabled=true;
                }
                

                this.setState({
                    activityData:detail,
                    bg_img:detail.lottery_bgimg,
                    bg_color:detail.lottery_bgcolor,
                    exampleLists:detail.lottery_rule,
                    lottery_type:detail.lottery_type,
                    prize_list_show:detail.prize_list_show,
                    start_time:detail.start_time,
                    end_time:detail.end_time,
                    extra_prize:detail.extra_prize
                },()=>{
                    this.setState({
                        isForceRender: true
                    })
                })
            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    clickTab = val => {
        this.setState({
            activeStatus: val
        })
    }

    saveData = async () => {
        const BaseFormData = await this.BaseForm.getFormData()
    
        if (!BaseFormData) {
            return this.clickTab('1')
        }

        const PrizeFormData = await this.PrizeForm.getFormData()
        if (!PrizeFormData) {
            return this.clickTab('2')
        }

        const ShareFormData = await this.ShareForm.getFormData()
        if (!ShareFormData) {
            return this.clickTab('3')
        }

        const sendData = { ...BaseFormData, ...PrizeFormData, ...ShareFormData }
       

        if (this.state.is_edit) {
            sendData.id = this.state.activityData.id
        }

        console.log(sendData);

        this.setState({
            isLoading: true
        })

        lotterySave(sendData).then(res => {
            if (res.code == 1) {
                message.success(res.msg)
                .then(() => {
                    // this.props.history.push({
                    //     pathname: `/collect-likes/lists`
                    // })
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }



    updateData = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleChangeTabs = key => {
        this.setState({
            activeStatus: key
        })
    }

    render() {

        const { bg_color, bg_img, exampleLists, lottery_type, prize_list_show, extra_prize,start_time,end_time } = this.state;


        const temPrizes1 = [
            { x: 0, y: 0, borderRadius: '10px', fonts: [{ text: exampleLists[0].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[0].prize_img, width: "40%", height: "40%", top: '15%' }] },
            { x: 1, y: 0, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[1].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[1].prize_img, width: "40%", height: "40%", top: '15%' }] },
            { x: 2, y: 0, borderRadius: '10px', fonts: [{ text: exampleLists[2].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[2].prize_img, width: "40%", height: "40%", top: '15%' }] },
            { x: 2, y: 1, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[3].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[3].prize_img, width: "40%", height: "40%", top: '15%' }] },
            { x: 2, y: 2, borderRadius: '10px', fonts: [{ text: exampleLists[4].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[4].prize_img, width: "40%", height: "40%", top: '15%' }] },
            { x: 1, y: 2, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[5].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[5].prize_img, width: "40%", height: "40%", top: '15%' }] },
            { x: 0, y: 2, borderRadius: '10px', fonts: [{ text: exampleLists[6].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[6].prize_img, width: "40%", height: "40%", top: '15%' }] },
            { x: 0, y: 1, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[7].prize_name, top: '65%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[7].prize_img, width: "40%", height: "40%", top: '15%' }] }
        ];

        const temPrizes2 = [
            { x: 0, y: 0, borderRadius: '10px', fonts: [{ text: exampleLists[0].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[0].prize_img, width: "38%", top: '35%' }] },
            { x: 1, y: 0, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[1].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[1].prize_img, width: "38%", top: '35%' }] },
            { x: 2, y: 0, borderRadius: '10px', fonts: [{ text: exampleLists[2].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[2].prize_img, width: "38%", top: '35%' }] },
            { x: 2, y: 1, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[3].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[3].prize_img, width: "38%", top: '35%' }] },
            { x: 2, y: 2, borderRadius: '10px', fonts: [{ text: exampleLists[4].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[4].prize_img, width: "38%", top: '35%' }] },
            { x: 1, y: 2, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[5].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[5].prize_img, width: "38%", top: '35%' }] },
            { x: 0, y: 2, borderRadius: '10px', fonts: [{ text: exampleLists[6].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[6].prize_img, width: "38%", top: '35%' }] },
            { x: 0, y: 1, background: '#FEE9C8', borderRadius: '10px', fonts: [{ text: exampleLists[7].prize_name, top: '13%', fontSize: '12px', fontColor: '#D55817' }], imgs: [{ src: exampleLists[7].prize_img, width: "38%", top: '35%' }] }
        ];


        return (
            <div className='shadow-radius lucky'>
                <div className="clearfix mgt15">
                    <div className="phone-wrap fl">
                        <div className="phone-title">幸运抽奖</div>
                        <div className="phone-content" style={{ backgroundImage: 'url(' + (bg_img || default_bg_img) + ')', backgroundColor: bg_color }}>
                            <div className="activity_desc">活动说明</div>
                            <div className="activity_time">{ start_time?( start_time+'~'+end_time):''}</div>
                            {
                                lottery_type == 1 ?
                                    <div className="grid_wrap">
                                        <div className="grid_box">
                                            <LuckyGrid width="250px" height="250px"
                                                buttons={[
                                                    {
                                                        x: 1, y: 1,
                                                        borderRadius: '10px',
                                                        imgs: [{ src: "https://img.cxkoo.com/chengxuan/1/2023-05-05/3550ef40242ce43ec705228ef30ade53.png", width: '100%', height: '100%' }]
                                                    }
                                                ]}
                                                prizes={temPrizes1}
                                                defaultStyle={{ background: '#FEF3DD' }}
                                                default-config={{ gutter: '14rpx' }}
                                            ></LuckyGrid>
                                        </div>
                                        <div className="points">
                                            <div className="txt1 fz18">9999</div>
                                            <div className="txt2 fz13">可用积分</div>
                                        </div>
                                        <div className="prize">
                                            <div className="txt1">
                                                <span className="fz18 ft-bold">0</span>
                                                <span className="fz15">个</span>
                                                <span className="you"></span>
                                            </div>
                                            <div className="txt2 fz13">我的奖品</div>
                                        </div>
                                        <div className="residue">
                                            <div className="label">
                                                <div>剩余</div>
                                                <div>次数</div>
                                            </div>
                                            <div className="num">9</div>
                                        </div>
                                    </div> :
                                    <div className="wheel_wrap">
                                        <div className="wheel_box">
                                            <LuckyWheel width="259px" height="259px"
                                                buttons={[
                                                    {
                                                        radius: '35%',
                                                        imgs: [{
                                                            src: 'https://img.cxkoo.com/chengxuan/1/2023-05-05/0e39fb377a05acb9d917b6d9ea5cc680.png',
                                                            width: '69.5px',
                                                            height: '96px',
                                                            top: '-56px'
                                                        }]
                                                    }
                                                ]}
                                                prizes={temPrizes2}
                                                defaultStyle={{ background: '#FEF3DD' }}
                                            ></LuckyWheel>
                                        </div>
                                        <div className="points">
                                            <div className="txt1 fz18">9999</div>
                                            <div className="txt2 fz13">可用积分</div>
                                        </div>
                                        <div className="prize">
                                            <div className="txt1">
                                                <span className="fz18 ft-bold">0</span>
                                                <span className="fz15">个</span>
                                                <span className="you"></span>
                                            </div>
                                            <div className="txt2 fz13">我的奖品</div>
                                        </div>
                                        <div className="residue">
                                            <div className="label">
                                                <div>剩余</div>
                                                <div>次数</div>
                                            </div>
                                            <div className="num">9</div>
                                        </div>
                                    </div>
                            }

                            {
                                extra_prize.length ?
                                    <div className="pdr15 pdl15">
                                        <div className="task_wrap">
                                            <div className="head">
                                                <div className="head_txt">做任务得抽奖机会</div>
                                            </div>
                                            {
                                                extra_prize.includes(1) ?
                                                    <div className="task">
                                                        <div className="left t1"></div>
                                                        <div className="right">
                                                            <div className="desc">
                                                                <div className="txt1">积分兑换</div>
                                                                <div className="txt2">消耗100积分可兑换1次抽奖机会</div>
                                                            </div>
                                                            <div className="btn">立即兑换</div>
                                                        </div>
                                                    </div> : null

                                            }
                                            {
                                                extra_prize.includes(2) ?
                                                    <div className="task">
                                                        <div className="left t2"></div>
                                                        <div className="right">
                                                            <div className="desc">
                                                                <div className="txt1">邀请得抽奖机会（0/4）</div>
                                                                <div className="txt2">邀请1位好友得1次抽奖机会</div>
                                                            </div>
                                                            <div className="btn">去邀请</div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                extra_prize.includes(3) ?
                                                    <div className="task">
                                                        <div className="left t3"></div>
                                                        <div className="right">
                                                            <div className="desc">
                                                                <div className="txt1">下单得抽奖机会（0/2）</div>
                                                                <div className="txt2">订单满300元得1次抽奖机会</div>
                                                            </div>
                                                            <div className="btn">去下单</div>
                                                        </div>
                                                    </div> : null
                                            }

                                        </div>
                                        {
                                            prize_list_show ?
                                                <div className="winning_wrap">
                                                    <div className="head">
                                                        <div className="head_txt">中奖名单</div>
                                                    </div>
                                                    <div className="title">{`——   仅展示最近20位   ——`}</div>

                                                    <div className="winning">
                                                        <div className="left">
                                                            <img src="https://img.cxkoo.com/chengxuan/0/2023-04-12/33a2b95727bb7b269d3772ff106f46cd.jpg" alt="" />
                                                        </div>
                                                        <div className="right">
                                                            <div>
                                                                <div className="nickname">无敌美少女</div>
                                                                <div className="time">2023-04-13 10:46</div>
                                                            </div>
                                                            <div className="prize">10元红包</div>
                                                        </div>
                                                    </div>
                                                    <div className="winning">
                                                        <div className="left">
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2023-03-28/02e3253494db5e85b7d1959b633034ba.jpeg" alt="" />
                                                        </div>
                                                        <div className="right">
                                                            <div>
                                                                <div className="nickname">扶摇直上九万里</div>
                                                                <div className="time">2023-04-13 10:46</div>
                                                            </div>
                                                            <div className="prize">10元红包</div>
                                                        </div>
                                                    </div>
                                                    <div className="winning">
                                                        <div className="left">
                                                            <img src="https://img.cxkoo.com/chengxuan/0/2023-04-25/ca6e28e04b2cb962e53aaa8943c348df.jpeg" alt="" />
                                                        </div>
                                                        <div className="right">
                                                            <div>
                                                                <div className="nickname">雨天的风</div>
                                                                <div className="time">2023-04-13 10:46</div>
                                                            </div>
                                                            <div className="prize">10元红包</div>
                                                        </div>
                                                    </div>

                                                    <div className="more winning">
                                                        <text>查看更多</text>
                                                        <text className="you"></text>
                                                    </div>
                                                </div> : null
                                        }

                                    </div> : null

                            }

                        </div>
                    </div>
                    <div className="ctrl-wrap fl mgl20">

                        <Tabs className={'mgt15'} activeKey={this.state.activeStatus} onChange={this.handleChangeTabs}>
                            <TabPane tab="基础设置" key={'1'}>
                                <BaseForm onRef={e => this.BaseForm = e} updateData={this.updateData} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData}></BaseForm>
                            </TabPane>
                            <TabPane tab="奖品设置" key={'2'} forceRender={this.state.isForceRender}>
                                <PrizeForm onRef={e => this.PrizeForm = e} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData} updateData={this.updateData} ></PrizeForm>
                            </TabPane>
                            <TabPane tab="页面装修" key={'3'} forceRender={this.state.isForceRender} >
                                <ShareForm onRef={e => this.ShareForm = e} saveData={this.saveData} updateTab={this.clickTab} is_edit={this.state.is_edit} activityData={this.state.activityData} updateData={this.updateData} ></ShareForm>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(LuckyAdd))