import React from 'react'
import { Modal, Alert, Upload, Icon, Button } from 'antd'
import Cookie from 'js-cookie'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import { orderImport,exportExpressTrade } from '@/api/order'
import CX from '@/utils/CX'
const { Dragger } = Upload
const ACCEPT_FILE_NAMES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/wps-office.xlsx','text/csv','application/octet-stream','application/vnd.ms-excel']


class OrderUpload extends React.Component{
    state = {
        title:'批量发货',
        visible:false,
        modalLoading:false,
        downLoadTpl:'',
        GoodsFile:'',
        callback:null,
        isLoading:false
    }
    componentDidMount() {
        this.props.onRef(this)
        // let downLoadTpl = `${process.env.REACT_APP_BASE_API}/mp/trade/exportExpressTrade?t=`+CX.genUUID()
        // this.setState({
        //     downLoadTpl
        // })
    }
    show = (callback) => {
        let downLoadTpl = `${process.env.REACT_APP_BASE_API}/mp/trade/exportExpressTrade?t=${CX.genUUID()}&api_token=${Cookie.get('token') }`
        this.setState({
            visible:true,
            GoodsFile:'',
            callback : callback || null,
            downLoadTpl
        })
    }
    handleExport=()=>{
        this.setState({
            isLoading:true
        })
        exportExpressTrade({})
        .then(res => {
            let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                fileName = '待发货订单.csv';
            CX.exportExcel(blob,fileName)

        })
        .finally(() => {
            this.setState({
                isLoading:false
            })
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleCancel = () => {
        this.close();
    }
    handleUpload = () => {
        let files  = this.state.GoodsFile,
            callback = this.state.callback;
        if(files) {
            let fd = new FormData()
            fd.append('file', files);
            fd.append('api_token', Cookie.get('token') || ``)
            this.setState({
                modalLoading: true
            })
            orderImport(fd)
                .then(res => {
                    if (res.code == 1) {
                        message.success('操作成功')
                            .then(() => {
                                this.close();
                                if(typeof callback == 'function'){
                                    callback(res)
                                }
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        modalLoading: false,
                    })
                })
        }




    }

    render() {
        const props = {
            name: 'file',
            multiple: false,
            showUploadList:false,
            beforeUpload:(file) => {
                this.setState({
                    GoodsFile:''
                },() => { 
                    if(!ACCEPT_FILE_NAMES.includes(file.type)){
                        message.error('仅支持 xlsx格式');
                        return ;
                    }
                    this.setState({
                        GoodsFile:file
                    })
                    return false;
                 })
                
            },
        }

        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={700}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                centered={true}
                okText={`确认发货`}
                okButtonProps={{ onClick:() => { this.handleUpload() } }}
            >
                <Alert message={
                    <div className={`clearfix`}>
                        请仔细阅读Excel文件-工作表【使用说明】，按要求填写发货信息。
                        {/* <a onClick={this.handleExport}  className={`fi-link fr`} >下载待发货订单</a> */}
                        <a href={ this.state.downLoadTpl } target={`_blank`}  className={`fi-link fr`} >下载待发货订单</a>
                    </div>
                } type="info" showIcon />
                <div className={`mgt10`} style={{ height:'250px' }}>
                    <Dragger {...props} >
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        {
                            this.state.GoodsFile
                                ?
                                <p className="ant-upload-text">{ this.state.GoodsFile.name }</p>
                                :
                                <div>
                                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                    <p className="ant-upload-hint">
                                        文件格式仅支持xlsx，单个文件需小于10M
                                    </p>
                                </div>
                        }
                        <div className={`mgt30 txtCenter`}>
                            <Button type={`primary`} >上传文件</Button>
                        </div>
                    </Dragger>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </Modal>
        )
    }
}

export default OrderUpload
