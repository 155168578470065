import React, { Component } from 'react'
import { Tabs, Radio, Table, Pagination, Select } from 'antd'
import Chart from '@/components/Chart/Chart'
import { pointsDistribute } from '@/api/data'
const { TabPane } = Tabs;
const { Option } = Select;

const Category1 = {
    1: '客户数',
    2: '会员数'
}

const Category2 = {
    1: '发放积分数',
    2: '发放客户数',
    3: '发放会员数'
}

const Category3 = {
    1: '消耗积分数',
    2: '消耗客户数',
    3: '消耗会员数'
}

export default class PointsDistribution extends Component {
    state = {
        chartData1: {
            title: {
                text: "客户总数",
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                right: 'right',
                data: ['0-20000', '20000-40000', '40000-60000', '60000-80000', '80000以上']
            },
            series: [
                {
                    name: '可用积分分布',
                    type: 'pie',
                    radius: ['40%', '60%'],
                    center: ['40%', '54%'],
                    // minAngle: 5,
                    avoidLabelOverlap: true,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 1048, name: '0-20000' },
                        { value: 735, name: '20000-40000' },
                        { value: 580, name: '40000-60000' },
                        { value: 484, name: '60000-80000' },
                        { value: 300, name: '80000以上' }
                    ]
                }
            ],
            color: ['#4081F5', '#7E6BFC', '#C05FFA', '#F572C2', '#F97B69', '#F49A62', '#FABA37', '#4AEBB0', '#4ED4EF', '#586E94']
        },
        chartData2: {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['签到积分', '会员权益获得积分', '每日查看直播预告', '每日订阅直播预告', '消费得积分', '完善信息得积分']
            },
            series: [
                {
                    name: '积分数',
                    type: 'bar',
                    barWidth: 20,
                    data: [18203, 23489, 29034, 104970, 131744, 630230]
                },
            ],
            color: ['#7F64FF']
        },
        chartData3: {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['积分兑换扣除', '商家手动调整', '参与红包雨消耗', '参与幸运抽奖消耗']
            },
            series: [
                {
                    name: '积分数',
                    type: 'bar',
                    barWidth: 20,
                    data: [18203, 23489, 29034, 104970]
                },
            ],
            color: ['#7F64FF']
        },
        radio_value_1: '1',
        radio_value_2: '1',
        radio_value_3: '1',
        formData1: {
            data1: [],
            data2: [],
            legend1: [],
            legend2: [],
            tableData1: [],
            tableData2: []
        },
        tableData2:[],
        tableData3:[],
        point2_type:'1',
        point3_type:'1'
    }

    componentDidMount() {
        this.fetchData({ type: 1 });
        this.fetchData({ type: 2,scene_type:this.state.radio_value_2 ,time_type:this.state.point2_type})
        this.fetchData({ type: 3,scene_type:this.state.radio_value_3 ,time_type:this.state.point3_type})
    }

    handleChangeRadio = (e, name) => {
        let val = e.target.value
        this.setState({
            [name]: val
        },()=>{
            if(name=='radio_value_2'){
                this.fetchData({type:2,scene_type:this.state.radio_value_2,time_type:this.state.point2_type})
            }else if(name=='radio_value_3'){
                this.fetchData({type:3,scene_type:this.state.radio_value_3 ,time_type:this.state.point3_type})
            }
        })
    }

    fetchData = (sendData) => {
        pointsDistribute(sendData).then(res => {
            if (res.code == 1) {
                if (sendData.type == 1) {
                    let { user, member_user } = res.data.data;
                    let legend1 = [], legend2 = [], data1, data2;

                    data1 = user.map(val => {
                        legend1.push(val.range);
                        return { name: val.range, value: val.num }
                    })
                    data2 = member_user.map(val => {
                        legend2.push(val.range);
                        return { name: val.range, value: val.num }
                    })
                    this.setState({
                        formData1: {
                            data1,
                            data2,
                            legend1,
                            legend2,
                            tableData1: user,
                            tableData2: member_user
                        }
                    })
                }else if(sendData.type==2){
                    let data=res.data.data;
                    let xArr=[],yArr=[];
                    data.forEach(val=>{
                        xArr.push(val.value);
                        yArr.push(val.name)
                    })
                    let chartData2=this.state.chartData2;
                    chartData2.yAxis.data=yArr.reverse();
                    chartData2.series[0].data=xArr.reverse();

                    this.setState({
                        tableData2:data,
                        chartData2
                    })
                }else if(sendData.type==3){
                    let data=res.data.data;
                    let xArr=[],yArr=[];
                    data.forEach(val=>{
                        xArr.push(val.value);
                        yArr.push(val.name)
                    })
                    let chartData3=this.state.chartData3;
                    chartData3.yAxis.data=yArr.reverse();
                    chartData3.series[0].data=xArr.reverse();

                    this.setState({
                        tableData3:data,
                        chartData3
                    })
                }
            }
        })
    }

  

    handleSelectChange=(e,type)=>{
        if(type==2){
            this.setState({
                point2_type:e
            },()=>{
                this.fetchData({ type: 2,scene_type:this.state.radio_value_2 ,time_type:this.state.point2_type})
            })
        }else if(type==3){
            this.setState({
                point3_type:e
            },()=>{
                this.fetchData({ type: 3,scene_type:this.state.radio_value_3 ,time_type:this.state.point3_type})
            })
        }
    }

    render() {
        const { formData1, radio_value_1, chartData1 } = this.state;
        const dataArr = formData1['data' + radio_value_1].filter(item =>{
            return item.value !== 0;
        })
        let legendArr = []
        dataArr.forEach(item =>{
            legendArr.push(item.name)
        })
        chartData1['legend']['data'] = legendArr;
        chartData1['series'][0]['data'] = dataArr;
        // chartData1['legend']['data'] = formData1['legend' + radio_value_1];
        // chartData1['series'][0]['data'] = formData1['data' + radio_value_1];
        let tableData1 = formData1['tableData' + radio_value_1];
        let tableData2 = this.state.tableData2;
        let tableData3 = this.state.tableData3;

        let columns1 = [
            {
                title: '分布区间',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.range}</p>
                    )
                }
            },
            {
                title: Category1[this.state.radio_value_1],
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.num}</p>
                    )
                }
            },
            {
                title: '占比',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.per_cent}%</p>
                    )
                }
            }
        ]

        let columns2 = [
            {
                title: '发放途径',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name}</p>
                    )
                }
            },
            {
                title: Category2[this.state.radio_value_2],
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.value}</p>
                    )
                }
            },
            {
                title: '占比',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.percent}%</p>
                    )
                }
            }
        ]

        let columns3 = [
            {
                title: '发放途径',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name}</p>
                    )
                }
            },
            {
                title: Category3[this.state.radio_value_3],
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.value}</p>
                    )
                }
            },
            {
                title: '占比',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.percent}%</p>
                    )
                }
            }
        ]

        return (
            <div>
                <Tabs defaultActiveKey={'1'} >
                    <TabPane tab="可用积分分布" key="1">
                        <div>
                            <Radio.Group onChange={e => { this.handleChangeRadio(e, 'radio_value_1') }} value={this.state.radio_value_1} >
                                <Radio.Button value="1">客户数</Radio.Button>
                                <Radio.Button value="2">会员数</Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="flex">
                            <div className='flex1'>
                                <Chart chartData={chartData1} className={'pie-chart'} height={'450px'}></Chart>
                            </div>
                            <div className='flex1 mgl30'>
                                <div className={'content-body mgt15'}>
                                    <Table
                                        size={`middle`}
                                        columns={columns1}
                                        dataSource={tableData1}
                                        rowKey={record => record.id}
                                        pagination={false}
                                    ></Table>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="发放积分分布" key="2">
                        <div className='flex justify-between'>
                            <Radio.Group onChange={e => { this.handleChangeRadio(e, 'radio_value_2') }} value={this.state.radio_value_2} >
                                <Radio.Button value="1">积分数TOP10</Radio.Button>
                                <Radio.Button value="2">客户数TOP10</Radio.Button>
                                <Radio.Button value="3">会员数TOP10</Radio.Button>
                            </Radio.Group>
                            <Select value={this.state.point2_type} style={{ width: 120 }} onChange={e=>{this.handleSelectChange(e,2)}}>
                                <Option value="1">近7天</Option>
                                <Option value="2">近30天</Option>
                                <Option value="3">近90天</Option>
                            </Select>
                        </div>
                        <div className="flex">
                            <div className='flex1'>
                                <Chart chartData={this.state.chartData2} className={'pie-chart'} height={'450px'}></Chart>
                            </div>
                            <div className='flex1 mgl30'>
                                <div className={'content-body mgt15'}>
                                    <Table
                                        size={`middle`}
                                        columns={columns2}
                                        dataSource={tableData2}
                                        rowKey={record => record.id}
                                        pagination={false}
                                    ></Table>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="消耗积分分布" key="3">
                        <div className='flex justify-between'>
                            <Radio.Group onChange={e => { this.handleChangeRadio(e, 'radio_value_3') }} value={this.state.radio_value_3} >
                                <Radio.Button value="1">积分数</Radio.Button>
                                <Radio.Button value="2">客户数</Radio.Button>
                                <Radio.Button value="3">会员数</Radio.Button>
                            </Radio.Group>
                            <Select value={this.state.point3_type} style={{ width: 120 }} onChange={e=>{this.handleSelectChange(e,3)}}>
                                <Option value="1">近7天</Option>
                                <Option value="2">近30天</Option>
                                <Option value="3">近90天</Option>
                            </Select>
                        </div>
                        <div className="flex">
                            <div className='flex1'>
                                <Chart chartData={this.state.chartData3} className={'pie-chart'} height={'450px'}></Chart>
                            </div>
                            <div className='flex1 mgl30'>
                                <div className={'content-body mgt15'}>
                                    <Table
                                        size={`middle`}
                                        columns={columns3}
                                        dataSource={tableData3}
                                        rowKey={record => record.id}
                                        pagination={false}
                                    ></Table>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
