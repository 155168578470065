import React, { Component } from 'react'
import { Tabs } from 'antd';
import NoteManage from './components/note-manage';
import CommentManage from './components/comment-manage';
import CreatorManage from './components/creator-manage';
import FeatureSet from './components/feature-set';

const { TabPane } = Tabs;

export default class RecommedNote extends Component {

    render() {
        return (
            <div className='shadow-radius'>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="笔记管理" key="1">
                        <NoteManage />
                    </TabPane>
                    <TabPane tab="评论管理" key="2">
                        <CommentManage />
                    </TabPane>
                    <TabPane tab="创作者管理" key="3">
                        <CreatorManage />
                    </TabPane>
                    <TabPane tab="功能设置" key="4">
                        <FeatureSet />
                    </TabPane>
                </Tabs>
            </div>
        )
    }   
}
