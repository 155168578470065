import $axios from '@/axios/axios.js'

export function userLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/list`,
        method:'post',
        data
    })
}

export function getUser(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/detail`,
        method:'post',
        data
    })
}

export function userEdit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/edit`,
        method:'post',
        data
    })
}

export function userCreate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/create`,
        method:'post',
        data
    })
}


export function userTrade(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/userTrade`,
        method:'post',
        data
    })
}

export function userCardDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/cardDetail`,
        method:'post',
        data
    })
}

export function userChangePoints(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/changePoints`,
        method:'post',
        data
    })
}

export function userPointsRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/pointsRecord`,
        method:'post',
        data
    })
}


export function userImport(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/import`,
        method:'post',
        headers:{'Content-Type':'multipart/form-data'},
        data
    })
}

export function getSignConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/sign/getConfig`,
        method:'post',
        data
    })
}

export function setSignConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/sign/setConfig`,
        method:'post',
        data
    })
}

export function setSignConfigStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/sign/setStatus`,
        method:'post',
        data
    })
}

export function createLabelGroup(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/saveGroup`,
        method:'post',
        data
    })
}


export function labelGroupList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/group`,
        method:'post',
        data
    })
}


export function groupDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/groupDetail`,
        method:'post',
        data
    })
}

export function deleteGroup(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/deleteGroup`,
        method:'post',
        data
    })
}

export function labelList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/label`,
        method:'post',
        data
    })
}

export function labelSaveLabel(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/saveLabel`,
        method:'post',
        data
    })
}

export function labelSelectLabel(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/selectLabel`,
        method:'post',
        data
    })
}

export function labelDeleteLabel(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/label/deleteLabel`,
        method:'post',
        data
    })
}

export function labelSetLabel(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/setLabel`,
        method:'post',
        data
    })
}

export function userDeleLabel(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/deleteLabel`,
        method:'post',
        data
    })
}

export function userLabelList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/labelList`,
        method:'post',
        data
    })
}

export function userViewers(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/user/userViewers`,
        method:'post',
        data
    })
}