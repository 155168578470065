import React from 'react'
import { Form, Input, Table, Select, Icon, Button, Checkbox, Tag } from 'antd'
import '@/assets/css/course/syllabus.scss'
const FormItem = Form.Item


class Syllabus extends React.Component {
    state = {
        tabList: []
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    componentDidMount() {
        this.props.refSyllabus(this)

    }

    componentWillReceiveProps(nextProps, nextContext) {
        
        let tabList = nextProps.course_outline
        if(nextProps.id){
            this.setState({
                tabList
            })
        }
        

    }

    getInputValue = (e, i, j) => {
        let value = e.target.value,
            type = e.currentTarget.dataset.type || ``,
            tabList = this.state.tabList
        if (type === "title") {
            tabList[i].title = value
        } else if (type === "text") {
            tabList[i].data[j] = value
        }
        this.setState({
            tabList
        })
    }

    handleAdd = () => {
        let tabList = this.state.tabList
        tabList.push({
            title: "",
            data: [""]
        })
        this.setState({
            tabList
        })
    }
    handleAddText = (i, j) => {
        let tabList = this.state.tabList
        tabList[i].data.push("")
        this.setState({
            tabList
        })
    }
    handleDeleText = (i, j) => {
        let tabList = this.state.tabList
        if (tabList[i].data.length > 1) {
            tabList[i].data.splice(j, 1)
        } else {
            tabList.splice(i, 1)
        }
        this.setState({
            tabList
        })
    }


    render() {
        return (
            <FormItem label={`课程大纲`} >
                {
                    this.state.tabList.map((v, i) => {
                        return (
                            <div className="syllabus-wrap" key={i}>
                                <div className="syllabus-label">
                                    <Input
                                        placeholder={'请输入描述标题'}
                                        style={{ width: '150px' }}
                                        value={v.title}
                                        onChange={(e) => { this.getInputValue(e, i) }}
                                        data-type={'title'}
                                    />
                                </div>
                                <div className="syllabus-content">
                                    {
                                        v.data.map((text, j) => {
                                            return (
                                                <div key={j}>
                                                    <Input
                                                        placeholder={'请输入标题下的内容'}
                                                        style={{ width: '300px', marginBottom: "10px" }}
                                                        value={text}
                                                        onChange={(e) => { this.getInputValue(e, i, j) }}
                                                        data-type={'text'}

                                                    />
                                                    {
                                                        j === 0 ? <Icon type="plus-circle-o" className="syllabus-icon" onClick={() => { this.handleAddText(i, j) }} /> : null
                                                    }

                                                    <Icon type="minus-circle-o" className="syllabus-icon" onClick={() => { this.handleDeleText(i, j) }} />
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>

                        )
                    })
                }

                < Button type={'primary'} onClick={this.handleAdd} >增加标题</Button>

            </FormItem>
        )
    }

}

export default Form.create({})(Syllabus)
