import React from 'react'
import { withRouter } from 'react-router-dom'
import { getPoster, saveSharePoster, posterStatus, delPoster } from '@/api/fx'
import { Rnd } from 'react-rnd'
import { Form, Checkbox, Radio, Input, Button, Icon, Tag, Modal } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import '@/assets/css/fx/fx.scss'
import '@/assets/css/design/lists.scss'
import '@/assets/css/dashboard/index.scss'
const RND_ENABLE_SIZE = { top: false, left: false, right: false, bottom: false, topLeft: false, topRight: false, bottomRight: false, bottomLeft: false }
const FormItem = Form.Item
const ValidateKeys = ['user_img_left', 'user_img_top', 'user_name_font_size', 'qrcode_left', 'qrcode_top', 'tips_font_size', 'tips_left', 'tips_top']
const defaultData = {

}
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})


class SharePoster extends React.Component {
    state = {
        formData: {
            bg_img: 'https://img.cxkoo.com/chengxuan/1/2022-03-04/0077d328619039528d37dfec0c6a5915.png',
            poster_set: [1, 2, 3, 4],
            user_img_shape: 1,
            user_img_top: 17,
            user_img_left: 20,
            user_name_font_size: 15,
            user_name_font_color: '#ffffff',
            qrcode_shape: 2,
            qrcode_left: 142,
            qrcode_top: 500,
            tips_left: 145,
            tips_top: 595,
            tips_font_size: 12,
            tips_font_color: '#ffffff',
            qrcode_pages: '',
            tips_name: '扫码加入我们吧'
        },
        isLoading: false,
        lists: [],
        qrcode: '',
        default_img:"https://img.cxkoo.com/chengxuan/1/2022-03-04/0077d328619039528d37dfec0c6a5915.png"

    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData.type = 2;
        this.setState({
            isLoading: true
        })
        getPoster(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.list,
                        qrcode = res.data.qrcode;
                    for (var i in lists) {
                        let rowData = lists[i];
                        for (var j in rowData) {
                            if (ValidateKeys.includes(j)) {
                                rowData[j] = (rowData[j] / 2).toFixed(0)
                            }
                        }
                    }

                    this.setState({
                        lists,
                        qrcode
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'logo':
                case 'staff_qrcode':
                    formData[name] = imgs[0];
                    break;
                default:
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    handleResetImg=(keyNmae)=>{
        return ()=>{
            const formData=this.state.formData || {}
            formData[keyNmae]=this.state.default_img
            this.setState({
                formData
            })
        }
    }
    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }
    handleColorChange = (color, name) => {
        let hex = color.hex,
            formData = this.state.formData || {};
        formData[name] = hex;
        this.setState({
            formData
        })

    }
    handleResetColor = e => {
        let name = e.currentTarget.dataset.name || ``,
            color = e.currentTarget.dataset.color || ``,
            formData = this.state.formData || {};
        formData[name] = color;
        this.setState({
            formData
        })
    }
    handleDrag = (e, data, keyName) => {
        let formData = this.state.formData || {},
            left = data.x,
            top = data.y;
        switch (keyName) {
            case 'avatar':
                formData.user_img_left = left;
                formData.user_img_top = top;
                break;
            case 'code':
                formData.qrcode_left = left;
                formData.qrcode_top = top;
                break;
            case 'code_txt':
                formData.tips_left = left;
                formData.tips_top = top;
                break;
        }
        this.setState({
            formData
        })
    }
    handleInputChange = (e, name) => {
        let formData = this.state.formData || {},
            val = e.target.value;
        formData[name] = val;
        this.setState({ formData })
    }
    handleRadioChange = (e, name) => {
        let formData = this.state.formData || {},
            val = e.target.value;
        formData[name] = val;
        this.setState({ formData })
    }
    handleCheckChange = (val) => {
        let formData = this.state.formData || {};
        formData.poster_set = val;
        this.setState({ formData })
    }
    handleUse = rowData => {
        let sendData = {}
        sendData.id = rowData.id;
        sendData.status = rowData.status == 1 ? 2 : 1;
        this.setState({
            isLoading: true
        })
        posterStatus(sendData)
            .then(res => {
                if (res.code == 1) {
                    message.success('操作成功')
                        .then(() => {
                            this.fetchData()
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }
    handleEdit = rowData => {
        rowData.poster_set = [];
        if (rowData.show_user_img) {
            rowData.poster_set.push(1);
        }
        if (rowData.show_user_name) {
            rowData.poster_set.push(2)
        }
        if (rowData.show_qrcode) {
            rowData.poster_set.push(3)
        }
        if (rowData.show_qrcode_tips) {
            rowData.poster_set.push(4)
        }
        this.setState({
            isLoading: true
        })
        this.props.form.resetFields();
        setTimeout(() => {
            this.setState({
                formData: rowData
            })
            this.setState({
                isLoading: false
            })

        }, 1000)

    }
    handleDelete = rowData => {
        let sendData = {},
            formData = this.state.formData || {};
        sendData.id = rowData.id;
        Modal.confirm({
            title: `确定要删除这个海报吗？`,
            okType: `danger`,
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                delPoster(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('删除成功')
                                .then(() => {
                                    if (sendData.id == formData.id) {
                                        window.location.reload()
                                    } else {
                                        this.fetchData();
                                    }
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {},
            poster_set = formData.poster_set || [];
        for (var i in formData) {
            sendData[i] = formData[i];
        }
        sendData.show_user_img = poster_set.includes(1) ? 1 : 0;
        sendData.show_user_name = poster_set.includes(2) ? 1 : 0;
        sendData.show_qrcode = poster_set.includes(3) ? 1 : 0;
        sendData.show_qrcode_tips = poster_set.includes(4) ? 1 : 0;
        for (var j in sendData) {
            if (ValidateKeys.includes(j)) {
                sendData[j] = sendData[j] * 2
            }
        }
        if (!sendData.bg_img) {
            message.error('请设置背景图');
            return;
        }
        let imgContext = new Image();
        imgContext.src = sendData.bg_img;
        sendData.bg_img_width = imgContext.width;
        sendData.bg_img_height = imgContext.height;
        this.setState({
            isLoading: true
        })
        saveSharePoster(sendData)
            .then(res => {
                if (res.code == 1) {
                    message.success('保存成功')
                        .then(() => {
                            this.fetchData();
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })




    }
    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return (
            <div className={``}>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <div className={' clearfix mgt15'}>
                    <div className={'diy-fx-phone poster fl'}>
                        <div className={'phone-title'}>
                            <div className={`title-txt`}>推广海报</div>
                        </div>
                        <div className={'phone-angle'}></div>
                        <div
                            className={'phone-inner-poster'}
                        >
                            <img
                                src={data.bg_img || 'https://img.cxkoo.com/chengxuan/1/2022-03-04/0077d328619039528d37dfec0c6a5915.png'}
                                alt=""
                                className={`poster-img`}
                            />
                            <Rnd
                                bounds=".phone-inner-poster"
                                enableResizing={RND_ENABLE_SIZE}
                                position={{
                                    x: data.user_img_left,
                                    y: data.user_img_top,
                                }}
                                maxWidth="100%"
                                onDrag={(e, data) => { this.handleDrag(e, data, 'avatar') }}
                                ref={e => { this.userHandler = e }}
                            >
                                <div className={`user-info`}>
                                    {
                                        data.poster_set && data.poster_set.includes(1)
                                            ?
                                            <div className={`avatar ${data.user_img_shape == 1 ? 'radius' : ''}`} style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2022-01-18/897ce3c3d847709d52fa636308217254.png)` }} ></div>
                                            :
                                            null
                                    }
                                    {
                                        data.poster_set && data.poster_set.includes(2)
                                            ?
                                            <div className={`nickname`} style={{ color: data.user_name_font_color || '#ffffff', fontSize: `${data.user_name_font_size}px` }}>青春无敌</div>
                                            :
                                            null
                                    }
                                </div>
                            </Rnd>
                            {
                                data.poster_set && data.poster_set.includes(3)
                                    ?
                                    <Rnd
                                        bounds=".phone-inner-poster"
                                        enableResizing={RND_ENABLE_SIZE}
                                        position={{
                                            x: data.qrcode_left,
                                            y: data.qrcode_top,
                                        }}
                                        maxWidth="100%"
                                        onDrag={(e, data) => { this.handleDrag(e, data, 'code') }}
                                        ref={e => { this.codeHandler = e }}
                                    >
                                        <div
                                            className={`qr-code ${data.qrcode_shape == 1 ? 'radius' : ''}`}
                                            style={{ backgroundImage: `url(${this.state.qrcode})` }}
                                        ></div>
                                    </Rnd>
                                    :
                                    null

                            }
                            {
                                data.poster_set && data.poster_set.includes(4)
                                    ?
                                    <Rnd
                                        bounds=".phone-inner-poster"
                                        enableResizing={RND_ENABLE_SIZE}
                                        position={{
                                            x: data.tips_left,
                                            y: data.tips_top,
                                        }}
                                        maxWidth="100%"
                                        onDrag={(e, data) => { this.handleDrag(e, data, 'code_txt') }}
                                        ref={e => { this.codeTxtHandler = e }}
                                    >
                                        <div
                                            className={`qr-code-txt`}
                                            style={{ color: data.tips_font_color || '#ffffff', fontSize: `${data.tips_font_size}px` }}
                                        >
                                            {data.tips_name}
                                        </div>
                                    </Rnd>
                                    :
                                    null

                            }
                        </div>
                    </div>
                    <div className={'diy-fx-ctrl fl mgl20'}>
                        <div className={`data-title`}>
                            <span className={`fi-title`}>基本信息</span>
                            <span className={`fi-sub-title mgl15`}></span>
                        </div>
                        <Form className={`model-form`} {...formItemLayout}>
                            <FormItem label={`背景图`}>
                                <div className={'clearfix'}>
                                    {
                                        data.bg_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={data.bg_img}
                                                onDel={e => { this.delImg(e, 'bg_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'bg_img') }}
                                            ></ImgAdd>
                                    }
                                    <Button type='link' className='mgt60' onClick={this.handleResetImg('bg_img')}>恢复默认</Button>
                                </div>
                                <span className={'fi-help-text'}>建议上传尺寸：宽度750px，高度不限</span>
                            </FormItem>
                            <FormItem label={`展示信息`}>
                                {getFieldDecorator('poster_set', {
                                    initialValue: data.poster_set || [1, 2, 3, 4]
                                })(
                                    <Checkbox.Group onChange={this.handleCheckChange}>
                                        <Checkbox value={1}>用户头像</Checkbox>
                                        <Checkbox value={2}>用户昵称</Checkbox>
                                        <Checkbox value={3}>小程序码</Checkbox>
                                        <Checkbox value={4}>识别小程序提示</Checkbox>
                                    </Checkbox.Group>
                                )}

                            </FormItem>
                            <FormItem label={`用户头像`}>
                                <div>
                                    {getFieldDecorator('user_img_shape', {
                                        initialValue: data.user_img_shape || 1,
                                        rules: []
                                    })(
                                        <Radio.Group onChange={e => { this.handleRadioChange(e, 'user_img_shape') }}>
                                            <Radio value={1}>
                                                圆形
                                            </Radio>
                                            <Radio value={2}>
                                                方形
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </FormItem>
                            <FormItem label={` `} colon={false}>
                                <div>
                                    左偏移量
                                    {getFieldDecorator('user_img_left', {
                                        initialValue: data.user_img_left || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'user_img_left') }}
                                        />
                                    )}
                                    上偏移量
                                    {getFieldDecorator('user_img_top', {
                                        initialValue: data.user_img_top || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'user_img_top') }}
                                        />
                                    )}
                                </div>
                            </FormItem>
                            <FormItem label={`用户昵称`}>
                                <div>
                                    字体大小
                                    {getFieldDecorator('user_name_font_size', {
                                        initialValue: data.user_name_font_size || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'user_name_font_size') }}
                                        />
                                    )}

                                </div>
                                <div className={`mgt10 flex`}>
                                    <span className={`mgr5`}>字体颜色</span>
                                    <ColorPicker
                                        onRef={e => { this.ColorPicker = e }}
                                        color={data.user_name_font_color || '#ffffff'}
                                        callback={(color) => { this.handleColorChange(color, 'user_name_font_color') }}
                                    ></ColorPicker>
                                    <span className={`fi-link mgl10`} onClick={this.handleResetColor} data-name="user_name_font_color" data-color="#ffffff">重置</span>
                                </div>
                            </FormItem>
                            <FormItem label={`小程序码`}>
                                <div>
                                    {getFieldDecorator('qrcode_shape', {
                                        initialValue: data.qrcode_shape || 2,
                                        rules: []
                                    })(
                                        <Radio.Group onChange={e => { this.handleRadioChange(e, 'qrcode_shape') }}>
                                            <Radio value={1}>
                                                圆形
                                            </Radio>
                                            <Radio value={2}>
                                                方形
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </FormItem>
                            <FormItem label={` `} colon={false}>
                                <div>
                                    左偏移量
                                    {getFieldDecorator('qrcode_left', {
                                        initialValue: data.qrcode_left || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'qrcode_left') }}
                                        />
                                    )}
                                    上偏移量
                                    {getFieldDecorator('qrcode_top', {
                                        initialValue: data.qrcode_top || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'qrcode_top') }}
                                        />
                                    )}
                                </div>
                            </FormItem>
                            <FormItem label={`识别小程序提示`}>
                                <div>
                                    文案设置
                                    {getFieldDecorator('tips_name', {
                                        initialValue: data.tips_name || '',
                                    })(
                                        <Input
                                            style={{ width: '200px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'tips_name') }}
                                        />
                                    )}
                                </div>
                                <div className={`mgt10`}>
                                    字体大小
                                    {getFieldDecorator('tips_font_size', {
                                        initialValue: data.tips_font_size || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'tips_font_size') }}
                                        />
                                    )}

                                </div>
                                <div className={`mgt10`}>
                                    左偏移量
                                    {getFieldDecorator('tips_left', {
                                        initialValue: data.tips_left || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'tips_left') }}
                                        />
                                    )}
                                    上偏移量
                                    {getFieldDecorator('tips_top', {
                                        initialValue: data.tips_top || 0,
                                    })(
                                        <Input
                                            addonAfter={`px`}
                                            style={{ width: '120px' }}
                                            className={`mgl5 mgr15`}
                                            onChange={e => { this.handleInputChange(e, 'tips_top') }}
                                        />
                                    )}
                                </div>
                                <div className={`mgt10 flex`}>
                                    <span className={`mgr5`}>字体颜色</span>
                                    <ColorPicker
                                        onRef={e => { this.ColorPicker = e }}
                                        color={data.tips_font_color || '#ffffff'}
                                        callback={(color) => { this.handleColorChange(color, 'tips_font_color') }}
                                    ></ColorPicker>
                                    <span className={`fi-link mgl10`} onClick={this.handleResetColor} data-name="tips_font_color" data-color="#ffffff">重置</span>
                                </div>
                            </FormItem>
                            <FormItem label={`小程序路径`}>
                                {getFieldDecorator('qrcode_pages', {
                                    initialValue: data.qrcode_pages || '',
                                })(
                                    <Input
                                        style={{ width: '200px' }}
                                        onChange={e => { this.handleInputChange(e, 'qrcode_pages') }}
                                    />
                                )}
                                <span className={`fi-help-text`}>请填写商品/优惠券/营销活动->推广->小程序路径，不填写默认到小程序首页</span>
                            </FormItem>
                            <FormItem label={` `} colon={false}>
                                <Button type={`primary`} onClick={this.saveData}>立即保存</Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
                <div className={`data-title mgt15`}>
                    <span className={`fi-title`}>海报列表</span>
                    <span className={`fi-sub-title mgl15`}></span>
                </div>
                <div className={`main-list mgt15`}>
                    <ul style={{ paddingLeft: '0px' }}>
                        {
                            this.state.lists.map((item, index) => (
                                <li className={`main-list-item`} key={index}>
                                    <div className={`page`}>
                                        <div className={`cover`} style={{ backgroundImage: `url(${item.bg_img})`, height: `190px` }}>
                                            <div className={`float-cover`}>
                                                <div className={`ul-box`}>
                                                    <div className={`top-bottom`} onClick={() => { this.handleDelete(item) }}>
                                                        <EditorIcon type={`icon-shanchu`} className={`delete-icon`} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                item.status == 1
                                                    ?
                                                    <div className={`current`}>
                                                        <Tag color={`#5E4CFF`} className={`mgr5`}>已启用</Tag>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className={`dec`}>
                                            <div className={`cover-title`}>
                                                <div className={`title`} title={`${item.page_title}`}>{item.page_title}</div>
                                            </div>
                                            <div className={`cover-date`}>
                                                <Icon type="clock-circle" className={`icon-clock`} />
                                                {item.created_at}
                                            </div>
                                        </div>
                                        <div className={`bottom-button`}>
                                            <ul>
                                                <li className={`bdr`}>
                                                    <div className={`btm-button `} onClick={() => { this.handleUse(item) }}>{item.status == 1 ? '停用' : '启用'}</div>
                                                </li>
                                                <li>
                                                    <div className={`btm-button `} onClick={() => { this.handleEdit(item) }} >编辑</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }

                    </ul>
                </div>

                {this.state.isLoading ? <Loading /> : null}

            </div>
        )
    }
}
export default Form.create({})(withRouter(SharePoster))
