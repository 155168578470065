import $axios from '@/axios/axios.js'

export function newGiftSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/newGift/save`,
        method:'post',
        data
    })
}

export function newGiftLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/newGift/lists`,
        method:'post',
        data
    })
}

export function newGiftDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/newGift/detail`,
        method:'post',
        data
    })
}

export function newGiftDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/newGift/delete`,
        method:'post',
        data
    })
}

export function newGiftEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/newGift/end`,
        method:'post',
        data
    })
}
