import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Drawer, Icon, message } from 'antd'
import {lotteryGameData} from '@/api/lucky.js'
import SeePrize from './components/SeePrize'
import PartakeRecord from './components/PartakeRecord'
const FormItem = Form.Item;

class LotteryDetail extends Component {

    state = {
        columns: [
            {
                title: '客户信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url} alt="" width={60} />
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} onClick={() => { this.handleEdit(rowData) }}>
                                    {rowData.name || `游客`}
                                    <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>

                                </div>
                                <div className={`lh25`}>{rowData.mobile || `暂无手机号`}</div>

                            </div>
                        </div>
                    )
                }
            },
            {
                title: '已抽奖次数',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.num } </p>
                    )
                }
            },
            {
                title: '中奖次数',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p> { rowData.reward_num}</p>
                    )
                }
            },

            {
                title: '操作',
                dataIndex: '7',
                width: '250px',
                render: (text, rowData, index) => {

                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleRecord(rowData.user_id,1)}}>抽奖记录</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleSeePrize(rowData.user_id) }}>查看奖品</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleRecord(rowData.user_id,2)}}>邀请记录</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleRecord(rowData.user_id,3)}}>下单记录</Button>
                        </div>
                    )
                }
            }
        ],
        searchForm: {
            name: "",
            mobile: ''
        },
        activeStatus: '0',
        page: 1,
        per_page: 10,
        total: 0,
        tableData: [],
        tableLoading: false,
        poster: "",
        path: ""
    }

    componentDidMount() {
       
        
        if(this.props.match.params.id){
            this.fetchData()
        }
    }

    handleSeePrize=user_id=>{
        this.SeePrize.show({user_id,game_id:this.props.match.params.id})
    }
    handleRecord=(user_id,type)=>{
        this.PartakeRecord.show({type,user_id,game_id:this.props.match.params.id})
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.game_id=this.props.match.params.id

        this.setState({
            tableLoading: true
        })
        lotteryGameData(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }


    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;

                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }


    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div className='shadow-radius'>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户昵称'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户昵称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'客户手机号'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem className={'fl no-colon'} label={<span>&emsp;&emsp;&emsp;&emsp;</span>}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                 <SeePrize onRef={e=>{this.SeePrize=e}}></SeePrize>
                 <PartakeRecord onRef={e=>{this.PartakeRecord=e}} ></PartakeRecord>
            </div>
        )
    }
}

export default Form.create({})(withRouter(LotteryDetail))