import React, { Component } from 'react'
import { Form, Input, Select, DatePicker, Button, Tabs, Pagination, Tag, Icon, Tooltip, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { addRemark,  pickUpCode } from '@/api/order'
import {socialGroupGroupData,exportGroupData} from '@/api/group-solitaire'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import Remark from '../order/components/remark'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class GroupSolitaireActivity extends Component {

    state = {
        searchForm: {
            order_sn: '',
            status:0,
            start_time: '',
            end_time: ''
        },
        tableData: [],
        storeLists: [],
        isLoading: false,
        noData: false,
        page: 1,
        total: 0,
        per_page: 10,
        lastRefundMoney: 0,
        payMent: 0,
        refundMoney: 0,
        social_group:{}
    }

    componentDidMount(){
        if (this.props.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.id=this.props.match.params.id;
        this.setState({
            isLoading:true
        })
        socialGroupGroupData(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page,
                        social_group=res.data.social_group;
                    this.setState({
                        tableData:lists,
                        total,
                        per_page,
                        social_group
                    })
                    if(lists.length < 1){
                        this.setState({
                            noData:true
                        })
                    }else {
                        this.setState({
                            noData:false
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }

    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }

    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                sendData.id=this.props.match.params.id;
                this.setState({
                    searchForm,
                    tableLoading:true
                })
                exportGroupData(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '订单导出.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })

            }
        })
    }

    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }

    handleRemark = (rowData) => {
        let sendData = {};
        this.Remark.show(res => {
            sendData.remark = res.remark || '';
            sendData.order_sn = rowData.order_sn;
            if(!sendData.remark){ return false };
            this.setState({
                isLoading:true
            })
            addRemark(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('标记成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })
    }

    handlePickUp = (rowData) => {
        let sendData = {};
        sendData.order_sn = rowData.order_sn;
        sendData.use_code = rowData.use_code || ``;
        Modal.confirm({
            title:'是否对这笔订单进行核销？',
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                pickUpCode(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('核销成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }

    render() { 

        const { getFieldDecorator } = this.props.form

        return (
            <div className={`shadow-radius`} >
                  <Remark onRef={ e => { this.Remark = e } }></Remark>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'活动名称'} className={'fl'} >
                                <span>{this.state.social_group.name}</span>
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={"商户单号"} className={'fl'} >
                                {
                                    getFieldDecorator('order_sn', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'订单状态'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('status')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>待付款</Select.Option>
                                            <Select.Option value={4}>待使用</Select.Option>
                                            <Select.Option value={3}>待发货</Select.Option>
                                            <Select.Option value={7}>交易成功</Select.Option>
                                            <Select.Option value={8}>交易关闭</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem label={'跟团时间'} className={' fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <RangePicker className={`mgl10`}></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`} >
                            <FormItem className={'mgl80 fl'} >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleExport() }}>导出</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={''}>
                    <table className="cxtables cxtables-sku">
                        <colgroup>
                            <col width={`18%`} />
                            <col width={`8%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`8%`} />
                            <col width={`8%`} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>商品信息</td>
                                <td>单价/数量</td>
                                <td>客户</td>
                                <td>支付信息</td>
                                <td>客户/收货人</td>
                                <td>下单门店</td>
                                <td>订单状态</td>
                                <td>操作</td>
                            </tr>
                        </thead>
                        {
                            this.state.noData ?
                                <tbody>
                                    <tr>
                                        <td colSpan={8}>
                                            <div className={`lh25 txtCenter`}>暂无订单数据</div>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                null
                        }

                    </table>
                    {
                        this.state.tableData.map((item, index) => (
                            <table className="cxtables cxtables-sku mgt10" key={index}>
                                <colgroup>
                                    <col width={`18%`} />
                                    <col width={`8%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`8%`} />
                                    <col width={`8%`} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <td colSpan={8} className={`ftnormal clearfix`}>
                                            <Tag color={`#ff0000`}>{item.trade_type_name}</Tag>
                                            <span>商户单号：<span>{item.order_sn}</span></span>
                                            <Icon type="file-text" className={`mgl5 cursor`} onClick={() => { this.handleCopy(item.order_sn) }} />
                                            <span className={`mgl10`}>下单时间：<span>{item.created_at}</span></span>
                                            {
                                                item.remark
                                                    ?
                                                    <Tooltip placement="left" title={`${item.remark}`}>
                                                        <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/6b8f2e533456302124ee110cd006b18f.png" width={20} className={`fr cursor`} alt="" />
                                                    </Tooltip>
                                                    :
                                                    <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/610b1cfaa3f361987ea789c8cacd7a8a.png" width={20} className={`fr cursor`} alt="" onClick={() => { this.handleRemark(item) }} />
                                            }
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={`noPadding bd-right`} colSpan={2}>
                                            <div className={`clearfix`}>
                                                {
                                                    item.goods_lists && item.goods_lists.map((good, goodIndex) => (
                                                        <div className={`clearfix bd-bottom`} key={goodIndex}>
                                                            <div className={`flex jusify-start align-center td-item fl`} style={{ width: '75%' }}>
                                                                {
                                                                    good.type && good.type == 3
                                                                        ?
                                                                        <img src="https://img.cxkoo.com/chengxuan/1/2022-02-16/98cd675ad8e95215d35a76b5b85d9127.png" alt="" width={60} />
                                                                        :
                                                                        <img src={good.cover_img} alt="" width={60} />
                                                                }
                                                                <div className={`mgl10`}>
                                                                    {
                                                                        good.type && good.type == 3
                                                                            ?
                                                                            <Link to={`/coupon/add/${good.id}`} target={`_blank`} className={`fi-link lh25`}>
                                                                                {good.name || ''}
                                                                            </Link>
                                                                            :
                                                                            <Link to={`/item/add/${good.id}`} target={`_blank`} className={`fi-link lh25`} >
                                                                                {good.name || ''}
                                                                                {
                                                                                    good.marketing_type == 1
                                                                                        ?
                                                                                        <Tag className={`mgl5`} color={'red'}>限时抢购</Tag>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </Link>
                                                                    }
                                                                    <div className={`lh25`}>{good.sku_info ? good.sku_info.name : ''}</div>
                                                                    <div className={`lh25`}>商品编码：{good.sku_info ? good.sku_info.goods_sku_code : good.goods_code ? good.goods_code : '--'}</div>
                                                                </div>
                                                            </div>
                                                            <div className={` td-price fr`}>
                                                                {
                                                                    item.trade_type == 9
                                                                        ?
                                                                        null
                                                                        :
                                                                        <div className={`lh25 txtRight`}>&yen;{good.price}</div>
                                                                }
                                                                <div className={`lh25 txtRight`}>x{good.num}</div>
                                                                {
                                                                    good.refund_info
                                                                        ?
                                                                        <div className={`lh25 txtRight colorRed`}>已退{good.refund_info.refund_num}件</div>
                                                                        :
                                                                        null
                                                                }
                                                                <div className={`lh25 txtRight`}>
                                                                    {/* {
                                                                        item.trade_type == 4 && [1, 2, 5].includes(item.trade_status)
                                                                            ?
                                                                            <Button size={`small`} onClick={() => { this.handlePartRefund(good) }}>单品退款</Button>
                                                                            :
                                                                            null
                                                                    } */}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.user_info.name}</div>
                                            <div className={`lh25`}>{item.user_info.mobile}</div>
                                        </td>
                                        <td>
                                            {
                                                [0, 6, 7, 8].includes(item.trade_status)
                                                    ?
                                                    <div>
                                                        <div className={`lh25 ft-bold`}>待支付：￥{item.pay_ment}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className={`lh25 ft-bold`}>支付合计：￥{item.pay_ment}</div>
                                                        {
                                                            item.post_fee > 0
                                                                ?
                                                                <div className={`lh25`}>（含快递：&yen;{item.post_fee}）</div>
                                                                :
                                                                null

                                                        }

                                                    </div>
                                            }
                                            {
                                                item.trade_extends && item.refund_price > 0
                                                    ?
                                                    <div className={`lh25 colorRed`}>退款金额：￥{item.refund_price || '-'}</div>
                                                    :
                                                    null
                                            }
                                            <div className={`lh25`}>支付方式：{item.pay_type_name || '-'}</div>
                                            <div className={`lh25`}>支付时间：{item.pay_time || '-'}</div>
                                            <div className={`lh25`}>配送方式：{item.post_type == 1 ? '快递物流' : '到店自提'}</div>


                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.user_address ? item.user_address.name : '-'}</div>
                                            <div className={`lh25`}>{item.user_address ? item.user_address.mobile : '-'}</div>
                                            <div className={`lh25`}>{item.user_address ? item.user_address.address : '-'}</div>

                                            {
                                                item.user_remark
                                                    ?
                                                    <div className={`lh25`}>
                                                        <Tooltip placement="right" title={`${item.user_remark}`}>
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/0ad4ac21baead02dd06edf8431a7a1ed.png" alt="" width={20} className={`cursor`} />
                                                        </Tooltip>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </td>
                                        <td>
                                            <div className={`lh25`}>{item.storeInfo ? item.storeInfo.name : '-'}</div>
                                        </td>
                                        <td>
                                            <div>
                                                {
                                                    [1, 2].includes(item.trade_status)
                                                        ?
                                                        <Tag color="green">{item.trade_status_name}</Tag>
                                                        :
                                                        null
                                                }
                                                {
                                                    [0, 4, 6, 7, 8].includes(item.trade_status)
                                                        ?
                                                        <Tag>{item.trade_status_name}</Tag>
                                                        :
                                                        null
                                                }
                                                {
                                                    [3, 5, 9, 10, 11, 12].includes(item.trade_status)
                                                        ?
                                                        <Tag color="orange">{item.trade_status_name}</Tag>
                                                        :
                                                        null
                                                }
                                            </div>

                                        </td>
                                        <td>
                                            {
                                                item.trade_status == 1 && item.post_type == 2
                                                    ?
                                                    <div className={`btn-row mgb5`}>
                                                        <span className={`fi-link fz14 w150`} onClick={() => { this.handlePickUp(item) }}>在线核销</span>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={'btn-row'}>
                                                <Link to={`/order/detail/${item.order_sn}`} target={`_blank`} className={`fi-link fz14 w150`}>查看详情</Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))
                    }

                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(GroupSolitaireActivity))
