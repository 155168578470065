import React from 'react'
import { Form, Button, Tag, Table, Pagination, Divider, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { suitStores } from '@/api/member-card'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import CouponFitStore from '@/views/coupon/components/CouponFitStore'
const FormItem = Form.Item;

class RechargeRecord extends React.Component{
    state = {
        columns:[
            {
                title:'商户单号',
                dataIndex:'order_sn',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.order_sn || ''}</p>
                    )
                }
            },
            {
                title:'充值时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{ rowData.created_at }</p>
                        </div>
                    )
                }
            },
            {
                title:'充值方式',
                dataIndex:'type_name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            { rowData.type_name }
                        </div>
                    )
                }
            },
            {
                title:'充值储值金额',
                dataIndex:'change_deposits_money',
                render:(text, rowData, index) => {
                    return (
                        <p>+{rowData.change_deposits_money}元</p>
                    )
                }
            },
            {
                title:'充值赠送金额',
                dataIndex:'change_present_money',
                render:(text, rowData, index) => {
                    return (
                        <p>+{rowData.change_present_money}元</p>
                    )
                }
            },
            {
                title:'充值赠送积分',
                dataIndex:'present_points',
                render:(text, rowData, index) => {
                    return (
                        <p>{ rowData.present_points }</p>
                    )
                }
            },
            {
                title:'充值门店',
                dataIndex:'store_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{ rowData.store_name }</p>
                    )
                }
            },
            {
                title:'操作员',
                dataIndex:'operate_user_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{ rowData.operate_user_name || '--' }</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    if(!rowData.order_sn){
                        return <div></div>
                    }
                    
                    return (
                        <div className={'btn-row'}>
                            <Link to={`/order/detail/${rowData.order_sn || ''}`} target={`_blank`} className={`fi-link w150`}>查看订单</Link>
                        </div>
                    )
                }
            },
        ],
        suitStores:[],
        tableLoading:false,
        selectRows:[],

    }

    componentDidMount() {
        this.props.onRef(this)
    }
    fetchData = () => {}
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleSuitStore = (rowData) => {
        let sendData = {}
        sendData.id = rowData.id || ``;
        this.setState({
            isLoading:true
        })
        suitStores(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store_list.data;
                    this.setState({
                        suitStores:lists
                    },() => {
                        this.CouponFitStore.show()
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleEdit = rowData => {
        this.props.history.push({
            pathname:`/user/add-card/${rowData.id}`
        })
    }





    render() {
        let tableData = this.props.tableData || [],
            per_page = this.props.per_page || 10,
            page = this.props.page || 1,
            total = this.props.total || 0;
        console.log('tableData',tableData)
        const { getFieldDecorator } = this.props.form
        return(
            <div>
                <CouponFitStore onRef={ e => { this.CouponFitStore = e } } stores={ this.state.suitStores }></CouponFitStore>
                { this.state.isLoading ? <Loading /> : null }
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => index}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}></div>
                    <div className={'fr'}>
                        {
                            tableData.length
                                ?
                                <Pagination showQuickJumper current={page} total={total} pageSize={per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default Form.create({})(withRouter(RechargeRecord))
