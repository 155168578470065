import React from 'react'
import { Modal, Input, Button, Table, Pagination,Select, Tag } from 'antd'
import { timeDiscountLists, timeDiscountActivity } from '@/api/time-discount'
import CouponFitStore from '@/views/coupon/components/CouponFitStore'
import CX from '@/utils/CX'


export default class TimeDiscountPicker extends React.Component{
    state = {
        title:'选择活动',
        visible:false,
        modalLoading:false,
        tableLoading:false,
        isLoading:false,
        columns:[
            {
                title:'活动名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'活动时间',
                dataIndex:'time',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.start_time}-{rowData.end_time}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 1
                                    ?
                                    <Tag color={`orange`}>未开始</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 2
                                    ?
                                    <Tag color={`green`}>进行中</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 3
                                    ?
                                    <Tag color={``}>已结束</Tag>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        suitStores:[],
        selectRows:[],
        selectedRowKeys:[],
        page:1,
        name:'',
        status:'',
        coupon_type:0,
        per_page:8,
        total:0,
        callback:null,
        shouldChooseDisable:false
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.selectIds){
            this.setState({
                selectedRowKeys:nextProps.selectIds
            })
        }
        if(nextProps.shouldChooseDisable){
            this.setState({
                shouldChooseDisable:nextProps.shouldChooseDisable
            })
        }
    }

    show = (callback) => {
        this.setState({
            visible:true,
            callback: callback ? callback : null
        })
        this.fetchData();
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    fetchData = () => {
        let sendData = {
            page:this.state.page,
            status:this.state.status,
            name:this.state.name,
            per_page:this.state.per_page
        }
        this.setState({
            tableLoading:true
        })
        timeDiscountActivity(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    renderSelectRows = lists => {
        let selectedRowKeys = this.state.selectedRowKeys,
            selectRows = this.state.selectRows;
        if(selectedRowKeys.length){
            for(var k in lists){
                lists[k]['disable'] = 0
                if(selectedRowKeys.includes(lists[k]['id'])){
                    if(this.state.shouldChooseDisable){
                        lists[k]['disable'] = 1;
                    }
                    let selectRowsIds = selectRows.map(item => {return item.id})
                    if(!selectRowsIds.includes(lists[k]['id'])){
                        selectRows.push(lists[k])
                    }
                }
            }
            this.setState({
                selectRows
            })
        }


    }

    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback(this.state.selectRows)
        }

        this.close();
    }
    handleCancel = () => {
        this.close()
    }
    handleCurrentChange = val =>  {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    getInputValue = e => {
        this.setState({
            name: e.target.value
        })
    }
    getSelectValue = val => {
        this.setState({
            status:val
        })
    }
    render() {
        let multiple = !!this.props.multiple
        const rowSelection = {
            type: multiple ? 'checkbox' : 'radio',
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {
                if(selectedRowKeys.length == selectedRows.length){
                    this.setState({
                        selectRows:selectedRows,
                        selectedRowKeys
                    })
                }else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr,selectedRowKeys);
                    for(var i in diffArr){
                        for (var j in  oldList){
                            if(oldList[j]['id'] == diffArr[i]){
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows:tmpList,
                        selectedRowKeys
                    })


                }
            },
            getCheckboxProps :record => ({
                disabled: record.disable == 1,
                name:record.name
            })
        }
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    maskClosable={false}
                    confirmLoading={this.state.modalLoading}
                    centered={true}
                >
                    <CouponFitStore onRef={ e => { this.CouponFitStore = e } } stores={ this.state.suitStores }></CouponFitStore>
                    <div className={'title clearfix'}>
                        <div className={'fr btn-group'}>
                            <Input value={this.state.name} onChange={this.getInputValue} placeholder={'活动名称'} style={{'width':'180px'}} />
                            <Select style={{width:`180px`}}  placeholder={'活动状态'} className={`mgl10`} onChange={this.getSelectValue} >
                                <Select.Option value={``}>全部</Select.Option>
                                <Select.Option value={1}>未开始</Select.Option>
                                <Select.Option value={2}>进行中</Select.Option>
                            </Select>
                            <Button type={'primary'} icon={'search'} style={{'verticalAlign':'top'}} onClick={() => {this.fetchData()}}>搜索</Button>
                        </div>
                    </div>
                    <div className={'mgt20'}>
                        <div className={`min-h400`}>
                            <Table
                                size={`middle`}
                                rowSelection={rowSelection}
                                columns={this.state.columns}
                                dataSource={this.state.tableData}
                                loading={this.state.tableLoading}
                                rowKey={record => record.id}
                                pagination={false}
                            >
                            </Table>
                        </div>
                        {
                            this.state.tableData.length
                                ?
                                <div className={'clearfix mgt10'}>
                                    <div className={'fl'}>
                                        <p>已选择{this.state.selectedRowKeys.length}个活动</p>
                                    </div>
                                    <div className={'fr'}>
                                        <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </Modal>
            </div>
        )
    }

}
