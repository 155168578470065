import React from 'react'
import './EditForm.scss'
import { Link } from 'react-router-dom'
import { Form, Col, Row, Table, Tag, Pagination } from 'antd'
import { storageDesc } from '@/api/stock'

import { STOCK_IN_TYPES, STOCK_OUT_TYPES } from '@/constants/stock'
const FormItem = Form.Item;


class StorageDetail extends React.Component{
    state = {
        columns:[
            {
                title:'仓库/门店',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div>{ rowData.store ? rowData.store.name : '--' }</div>
                    )
                }
            },
            {
                title:'门店logo',
                dataIndex:'logo',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <img src={ rowData.store ? rowData.store.logo : '' } width={60} alt=""/>
                        </div>
                    )
                }
            },

            {
                title:'实物库存',
                dataIndex:'stock1',
                render:(text, rowData, index) => {
                    return (
                        <p>0</p>
                    )
                }
            },
            {
                title:'待出库',
                dataIndex:'stock2',
                render:(text, rowData, index) => {
                    return (
                        <p>0</p>
                    )
                }
            },
            {
                title:'可用库存',
                dataIndex:'stock3',
                render:(text, rowData, index) => {
                    return(
                        <div>{ rowData.stock || 0 }</div>
                    )
                }
            }
        ],
        tableData:[],
        itemInfo:{},
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
    }
    fetchData = () => {
        let sendData = {}
        if(this.props.goods_id){
            sendData.goods_id = this.props.goods_id
            sendData.sku_id = this.props.sku_id || ``
            this.setState({
                tableLoading:true
            })
            storageDesc(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.goods_info,
                            lists = res.data.detail.data,
                            total = res.data.detail.total,
                            per_page = res.data.detail.per_page;
                        if(detail.sku && detail.sku.alias){
                            let sku_names = [];
                            for(var i in detail.sku.alias){
                                sku_names.push(detail.sku.alias[i])
                            }
                            detail.sku.sku_names = sku_names
                        }
                        this.setState({
                            itemInfo:detail,
                            tableData:lists,
                            total,per_page
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        tableLoading:false
                    })
                })

        }

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        let itemInfo = this.state.itemInfo || {}
        return(
            <div className={`drawer-cont`}>
                <div className={`msg-title`}></div>
                <Form className={'flex pd20 '} layout={`inline`}>
                    <div className={`flex jusify-start align-center`}>
                        <img src={itemInfo.cover_img} alt="" width={60}/>
                        <div className={`mgl10`}>
                            <Link to={`/item/add/${itemInfo.id}`} target={`_blank`} className={`fi-link w150`}>{itemInfo.name || ''}</Link>
                            <p>{itemInfo.goods_code || ''}</p>
                        </div>
                    </div>
                    <div className={`flex1 mgl50`}>
                        <Row>
                            <Col span={10}>
                                <FormItem label={'sku编码'} >
                                    <div className={``}>
                                        <p>{ itemInfo.sku ? itemInfo.sku.goods_sku_code : '--' }</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={10}>
                                <FormItem label={'单位'} >
                                    <div className={``}>
                                        <p>{ itemInfo.unit_name || '件' }</p>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={10}>
                                <FormItem label={'规格'} >
                                    <div className={``}>
                                        {
                                            itemInfo.sku && itemInfo.sku.sku_names.map( (item,index) => (
                                                <Tag className={`mgr5`} key={index}>{item}</Tag>
                                            ))
                                        }
                                        { !itemInfo.sku ? '无' : '' }
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>

                </Form>
                <div className={`msg-title`}></div>
                <div className={' pd20 '}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                    <div className={'clearfix mgt10'}>
                        <div className={'fr'}>
                            {
                                this.state.tableData.length
                                    ?
                                    <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Form.create({})( StorageDetail )
