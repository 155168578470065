import React, { Component } from 'react'
import { Form, Input, Button, Select, Divider, Table ,TimePicker} from 'antd'
import moment from 'moment'
import message from '@/utils/message'

const weekList=[
    {id:1,name:"星期一"},
    {id:2,name:"星期二"},
    {id:3,name:"星期三"},
    {id:4,name:"星期四"},
    {id:5,name:"星期五"},
    {id:6,name:"星期六"},
    {id:0,name:"星期日"}
]

export default class WeekCourse extends Component {
    state = {
        columns: [
            {
                title: '星期',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Select value={rowData.week_id} style={{ width: 120 }} onChange={v=>this.handleSelect(v,index)}>
                                {
                                    weekList.map(v=>(
                                        <Select.Option value={v.id} key={v.id}>{v.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    )
                }
            },
            {
                title: '上课时间段',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <TimePicker placeholder='开始时间' format={"HH:mm"} value={moment(rowData.start_time, 'HH:mm')}  onChange={(time,timeStr)=>{this.handleChange(timeStr,index,"start_time")}} /> 至
                            <TimePicker placeholder='结束时间' format={"HH:mm"}  value={moment(rowData.end_time, 'HH:mm')}  onChange={(time,timeStr)=>{this.handleChange(timeStr,index,"end_time")}} />
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div onClick={()=>{this.handleDele(index)}}>删除</div>
                    )
                }
            },

        ],
        tableData: [{week_id:1,start_time:"00:00",end_time:"00:00"}],
    }

    componentDidMount(){
        this.props.onRef(this)

        let course_week_data=this.props.course_week_data
        if(Array.isArray(this.props.course_week_data)){
            if(course_week_data.length>0){
                this.setState({
                    tableData:course_week_data
                })
            }
        }
    }

    handleSelect=(v,index)=>{
        let tableData=this.state.tableData
        tableData[index].week_id=v
        this.setState({
            tableData
        })
    }

    handleChange=(time,index,name)=>{
        console.log(time,index,name);
        let tableData=this.state.tableData
        tableData[index][name]=time ||"00:00"
        this.setState({
            tableData
        })
    }

    handleAdd=()=>{
        let tableData=this.state.tableData
        
        tableData.push({week_id: 1,start_time:"00:00",end_time:"00:00"})
        this.setState({
            tableData
        })
    }

    handleDele=index=>{
        let tableData=this.state.tableData
        if(tableData.length===1){
          return message.error("数量至少为1")
        }
        tableData.splice(index,1)
        this.setState({
            tableData
        })
    }

    getData=()=>{
        let flag=false,tableData=this.state.tableData
        tableData.forEach(v=>{
            let start_time=moment(v.start_time,'HH:mm'),
                end_time=moment(v.end_time,'HH:mm'),
                diff=moment(end_time).diff(moment(start_time),'seconds')
                if(diff <= 0){
                    flag=true
                }
        })

        if(flag){
            return false
        }else{
            return  tableData
        }

        
    }


    render() {

        return (
            <div >
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={(record,index )=> index}
                    pagination={false}
                ></Table>
                 <Button type={'link'} onClick={this.handleAdd} icon={'plus'} >添加</Button>
                 <Divider />
            </div>
        )
    }
}
