import $axios from '@/axios/axios.js'

export function jizanList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan`,
        method:'post',
        data
    })
}
export function jizanSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/save`,
        method:'post',
        data
    })
}

export function jizanDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/detail`,
        method:'post',
        data
    })
}

export function jizanEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/end`,
        method:'post',
        data
    })
}


export function jizanActive(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/active`,
        method:'post',
        data
    })
}

export function jizanVirtualNum(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/addVirtualNum`,
        method:'post',
        data
    })
}


export function jizanRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/record`,
        method:'post',
        data
    })
}

export function jizanReward(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/reward`,
        method:'post',
        data
    })
}

export function jizanSendWallet(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/sendWallet`,
        method:'post',
        data
    })
}

export function jizanVerify(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/verify`,
        method:'post',
        data
    })
}

export function jizanVerifyStore(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/verifyStore`,
        method:'post',
        data
    })
}

export function jizanDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/delete`,
        method:'post',
        data
    })
}


export function exportActive(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/exportActive`,
        method:'get',
        responseType:'blob',
        params
    })
}


export function exportRecord(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/exportRecord`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function exportReward(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/jizan/exportReward`,
        method:'get',
        responseType:'blob',
        params
    })
}