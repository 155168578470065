import React from 'react'
import { Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import LabelLists from './label-lists'
import ConditionLabel from './condition-label'
const { TabPane } = Tabs;

class UserLabel extends React.Component {

    state = {
        activeKey:"1"
    }

    changeTabs=key=>{
        this.setState({
            activeKey:key
        })
    }


    render() {


        return (
            <div className={'shadow-radius'}>
                <Tabs defaultActiveKey={this.state.activeKey} onChange={this.changeTabs}>
                    <TabPane tab="标签管理" key="1">
                        <LabelLists />
                    </TabPane>
                    <TabPane tab="条件打标签" key="2">
                        <ConditionLabel activeKey={this.state.activeKey} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }


}

export default withRouter(UserLabel)
