import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Table, Pagination, Divider, Select, TimePicker, DatePicker, Modal } from 'antd'
import moment from 'moment'
import Loading from '@/components/Loading'
import PlanCourseEdit from './planCourseEdit'
import PlanCourse from './planCourse'
import { storeLists } from '@/api/store'
import { gradeCourseTable, gradeDeleTable, gradeExportTable ,clearGradeTable} from '@/api/educational';
import CX from '@/utils/CX';
import message from '@/utils/message'
const FormItem = Form.Item

class GradeSchedule extends Component {
    state = {
        columns: [
            {
                title: '上课时间',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{`${rowData.table_date}   ${rowData.start_time}-${rowData.end_time}`}</p>
                    )
                }
            },
            {
                title: '上课地点',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.store.name}</p>
                    )
                }
            },
            {
                title: '老师/助教',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.teacher_name}{rowData.assist_teacher_name && ('/' + rowData.assist_teacher_name)}</p>
                    )
                }
            },
            {
                title: '实到/应到',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p>{`${rowData.sign_num}/${this.props.grade_detail.grade_student_count}`}</p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '5',
                render: (text, rowData, index) => {

                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={() => { this.handleSignIn(rowData) }}>点名签到</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={() => { this.handleEdit(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={() => { this.handleDele([rowData.id]) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        storeLists: [],
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        tableLoading: false,
        searchForm: {
            start_time: "",
            end_time: "",
            store_id: ""
        },
        isPlanCourse: false,
        isAddPlanCourse: false,
        plan_row: {},
        selectedRowKeys: []
    }

    componentDidMount() {
        this.fetchData()

        this.fetchStore()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = {};

                searchForm.start_time = values.start_time || ""
                searchForm.end_time = values.end_time || ""
                searchForm.store_id = values.store_id || ""

                if (searchForm.start_time) {
                    searchForm.start_time = moment(values.start_time).format('YYYY-MM-DD HH:mm')
                }
                if (searchForm.end_time) {
                    searchForm.end_time = moment(values.end_time).format('YYYY-MM-DD HH:mm')
                }
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }

    handleExport = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = {};

                searchForm.start_time = values.start_time || ""
                searchForm.end_time = values.end_time || ""
                searchForm.store_id = values.store_id || ""

                if (searchForm.start_time) {
                    searchForm.start_time = moment(values.start_time).format('YYYY-MM-DD HH:mm')
                }
                if (searchForm.end_time) {
                    searchForm.end_time = moment(values.end_time).format('YYYY-MM-DD HH:mm')
                }
                searchForm.grade_id = this.props.grade_id

                this.setState({
                    isLoading: true
                })
                gradeExportTable(searchForm).then(res => {
                    let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                        fileName = '班级课表.csv';
                    CX.exportExcel(blob, fileName)
                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
            }
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }


    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        sendData.grade_id = this.props.grade_id
        this.setState({
            tableLoading: true
        })
        gradeCourseTable(sendData).then(res => {

            if (res.code === 1) {
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    handleSignIn = row => {
        this.props.history.push({
            pathname: `/educational/sign-in/` + row.id
        })
    }

    handleEdit = row => {
        this.setState({
            isPlanCourse: true,
            plan_row: row
        })
    }

    handleDele = (ids,title) => {
        let sendData = {}
        sendData.course_table_ids = ids
        Modal.confirm({
            title: title ||  `确认删除当前排课内容吗？`,
            okText: "我在想想",
            cancelText: "确认",
            centered:true,
            onOk: () => {
            },
            onCancel: () => {
                this.setState({
                    isLoading: true
                })
                gradeDeleTable(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    }).finally(() => {
                        this.setState({
                            isLoading: false,
                            selectedRowKeys:[]
                        })
                    })
            }
        })
    }

    batchDelete=()=>{
        let ids=this.state.selectedRowKeys
        this.handleDele(ids,'确认删除选择的排课内容吗？')
    }

    closePlanCourse = () => {
        this.setState({
            isPlanCourse: false
        })
    }
    closeAddPlanCourse = () => {
        this.setState({
            isAddPlanCourse: false
        })
    }

    openPlanCourse = () => {
        this.setState({
            isAddPlanCourse: true
        })
    }

    clearPlanCourse=()=>{
        let sendData={
            grade_id:this.props.grade_id
        }
        Modal.confirm({
            title: `确认清空当前课表吗？`,
            okText: "我在想想",
            cancelText: "确认",
            centered:true,
            onOk: () => {
            },
            onCancel: () => {
                this.setState({
                    isLoading: true
                })
                clearGradeTable(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    }).finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            }
        };

        return (
            <div >
                <div className={' mgb15'}>
                    {this.props.grade_detail.status != 3 ?
                        <>
                            <Button type={'primary'} onClick={this.openPlanCourse} >新增排课</Button>
                            <Button  className='mgl20' onClick={this.clearPlanCourse} >清空课表</Button>
                        </>:null
                    }

                </div>
                <div className={''}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'上课时间'} className={'fl'}>
                            {
                                getFieldDecorator('start_time', {
                                })(
                                    <DatePicker placeholder='开始时间' format={"YYYY-MM-DD HH:mm"} />
                                )
                            }
                            <span className='mgl10 mgr10'>至</span>

                        </FormItem>

                        <FormItem className={'fl'}>
                            {
                                getFieldDecorator('end_time', {
                                })(
                                    <DatePicker placeholder='结束时间' format={"YYYY-MM-DD HH:mm"} />
                                )
                            }

                        </FormItem>

                        <FormItem label={'上课地点'} className={'fl mgl20'} >
                            {
                                getFieldDecorator('store_id', {
                                })(
                                    <Select style={{ width: 200 }} placeholder={'请选择'}>
                                        {
                                            this.state.storeLists.map(store => (
                                                <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>

                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary' onClick={() => this.search()}>筛选</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => this.handleExport()}>导出</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    {
                        this.state.tableData.length ?
                            <div className={'fl'}>
                                <Button onClick={this.batchDelete} >批量删除</Button>
                            </div> : null
                    }
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                {this.state.isPlanCourse ? <PlanCourseEdit closePlanCourse={this.closePlanCourse} handleS={this.fetchData} grade_line={this.props.grade_detail} plan_row={this.state.plan_row} /> : null}
                {this.state.isAddPlanCourse ? <PlanCourse closePlanCourse={this.closeAddPlanCourse} handleS={this.fetchData} grade_line={this.props.grade_detail} /> : null}
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(GradeSchedule))
