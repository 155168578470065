import React from 'react'
import { Form, Input, Radio, Button, Select, Icon, Checkbox, TreeSelect, Modal, Tooltip, TimePicker, Row, Col } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import '@/assets/css/item/item.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Editor from '@/components/Editor/Editor'
import PropTypes from "prop-types";
import { itemAdd, itemCates, addCate, itemDetail, itemEdit } from '@/api/item'
import { expressTemplateLists } from '@/api/express'
import AddCategory from './addCategory'
import message from '@/utils/message'
const FormItem = Form.Item
const { SHOW_ALL } = TreeSelect;

class EditForm extends React.Component{
    static contextTypes = {
        setLoading: PropTypes.func,
        setContextState:PropTypes.func,
        showItemStorePrice:PropTypes.bool
    }

    state = {
        formData: '',
        cateLists:[],
        expressLists:[],
        cateVisible:false,
        cateModalLoading:false,
        currentCate:null,
        showEditor:false,  //富文本编辑器
        showSku:false, //sku
        tableData:[
            { lesson:'',o_price:'',price:'',num:'' }
        ],
        booking_rules:[
            { title:'适用人群', desc:'10岁-12岁少儿，男女都可' },
            { title:'适用基础', desc:'零基础' }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchCate()
        if(this.props.match.params.id){
            this.fetchData()
        }else {
            this.setState({
                showEditor:true,
            })
        }
    }

    fetchData = (params = {}) => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        this.context.setLoading(true);
        itemDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail,
                        goods_extend = detail.goods_extend;
                    for(var i in goods_extend){
                        if(!detail[i]){
                            detail[i] = goods_extend[i]
                        }
                    }
                    delete detail['goods_extend']
                    if(detail.limit_buy > 0){
                        detail.is_limit_buy = 1;
                    }
                    let showItemStorePrice = !detail.props
                    this.setState({
                        formData:detail,
                        showEditor:true,
                        showSku:true
                    })
                    this.context.setContextState({
                        showItemStorePrice:showItemStorePrice,
                    })

                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })

    }
    fetchCate = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.context.setLoading(true)
        itemCates(sendData)
            .then(res => {
                let lists = res.data.list.data;
                lists.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })
                this.setState({
                    cateLists:lists
                })

            })
            .finally(res => {
                this.context.setLoading(false)
            })
    }
    handleAdd = () => {
        this.setState({
            currentCate:null,
            cateVisible:true
        })
    }
    handleAddCate = () => {
        this.formRef.getData(res => {
            if(res){
                if(this.state.cateModalLoading){ return; }
                this.setState({
                    cateModalLoading:true
                })
                res.pid = 0;
                addCate(res)
                    .then(resp => {
                        if(resp.code == 1){
                            message.success(resp.msg)
                                .then(() => {
                                    this.fetchCate()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            cateModalLoading:false,
                            cateVisible:false
                        })
                    })


            }
        })

    }
    handleCateCancel = () => {
        this.setState({
            cateVisible:false
        })
    }
    handleRadioChange = e => {

    }
    handleInputChange = e => {
        let value = e.target.value,
            name = e.currentTarget.dataset.name,
            formData = this.state.formData || {}
        formData[name] = value;
        this.setState({
            formData
        })
    }

    changeImg = e => {
        let name = e.currentTarget.dataset.name || ``,
            index = e.currentTarget.dataset.index || ``,
            formData = this.state.formData || {};

        this.Gallery.show(imgs => {
            formData[name][index] = imgs[0]
            this.setState({
                formData
            })
        })

    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'cover_img':
                case 'share_img':
                    formData[name] = imgs[0];
                    break;
                case 'pic_urls':
                    formData['pic_urls'] = formData['pic_urls'] || [];
                    formData['pic_urls'] = formData['pic_urls'].concat(imgs);
                    if(formData['pic_urls'].length > 10){
                        formData['pic_urls'] = formData['pic_urls'].slice(0,10)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }
    handleSort = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if(oldIndex == newIndex){
            return ;
        }
        let targetRow = urls.splice(oldIndex,1)[0];
        urls.splice(newIndex,0,targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }
    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.content = content;
        this.setState({
            formData
        })

    }
    saveData = () => {
        let formData = this.state.formData || {},
            sendData = {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                console.log(values);
                sendData = values;
                sendData.cover_img = formData.cover_img || ``;
                sendData.pic_urls = formData.pic_urls || [];
                sendData.share_img = formData.share_img || ``;
                sendData.content = formData.content || ``;
                sendData.store_price = this.ItemStorePrice ? this.ItemStorePrice.getData() : [];
                let skuInfo = this.ItemSku ?  this.ItemSku.getData() : {};
                sendData.norms = skuInfo.norms.length ? skuInfo.norms : '';
                sendData.props = Object.keys(skuInfo.props).length ? skuInfo.props : '';
                sendData.sku_props = Object.keys(skuInfo.sku).length ? skuInfo.sku : '';
                sendData.goods_type = 1;
                if(sendData.is_limit_buy == 0){
                    sendData.limit_buy = 0
                }
                this.context.setLoading(true);
                if(this.props.match.params.id){
                    sendData.id = this.props.match.params.id
                    itemEdit(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success(res.msg)
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/item/lists`
                                        })
                                    })

                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })
                }else {
                    itemAdd(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success(res.msg)
                                    .then(() => {
                                        this.props.history.push({
                                            pathname:`/item/lists`
                                        })
                                    })

                            }
                        })
                        .finally(() => {
                            this.context.setLoading(false)
                        })
                }



            }
        })
    }

    render() {
        let data = this.state.formData || {},
            storeLists = [];
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return(
            <div>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    <div className={`msg-title mgb15`}>基本信息</div>
                    <FormItem label={'线下课名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入课程名称'
                                }
                            ]
                        })(<Input
                            placeholder={'请输入课程名称，限60字符'}
                            onChange={this.handleInputChange}
                            data-name={`name`}
                            maxLength={60}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/60</span>}
                        />)}
                        <span className={`fi-help-text`}>线下课名称最多 60 个字符</span>
                    </FormItem>
                    <FormItem label={'课程简介'}>
                        {getFieldDecorator('name', {
                            initialValue: data.name || ``,
                        })(<Input
                            placeholder={'请输入课程简介'}
                            onChange={this.handleInputChange}
                            data-name={`name`}
                            maxLength={30}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/30</span>}
                        />)}
                        <span className={`fi-help-text`}>在课程标题下面展示简介信息，最多 30 个字符</span>
                    </FormItem>
                    <FormItem label={'课程详情图'} required={true}>
                        <div className={'clearfix'}>
                            {
                                data.pic_urls && data.pic_urls.length
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.pic_urls }
                                        onDel={ e => { this.delImg(e,'pic_urls') } }
                                        onSort={ e => { this.handleSort(e,'pic_urls') } }
                                    ></PictureCard>
                                    :
                                    null
                            }
                            {
                                data.pic_urls && data.pic_urls.length >= 10
                                    ?
                                    null
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'pic_urls') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>
                                建议尺寸：750x750像素，单张不超过2M
                                <br/>
                                最多可上传10张
                            </span>
                    </FormItem>
                    <FormItem label={'课程视频介绍'} required={true}>
                        <div className={`clearfix`}>
                            {
                                data.cover_img
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.cover_img }
                                        onDel={ e => { this.delImg(e,'cover_img') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'cover_img') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>支持本地上传视频，格式为MP4，建议大小不要超过40M</span>
                    </FormItem>
                    <FormItem label={'课程类目'}>
                        <div>
                            {
                                getFieldDecorator('category_ids',{
                                    initialValue: data.category_ids || [],
                                    rules:[
                                        {
                                            required:true,
                                            message: '请选择课程类目'
                                        }
                                    ]
                                })(
                                    <TreeSelect
                                        treeData={ this.state.cateLists }
                                        treeCheckable={true}
                                        showCheckedStrategy={SHOW_ALL}
                                        style={{width:'300px'}}
                                        placeholder={'请选择'}
                                    />
                                )
                            }
                            <Button type={'primary'} ghost onClick={this.handleAdd} icon={'plus'} className={`mgl10`}>新增分类</Button>
                        </div>
                    </FormItem>
                    <FormItem label={'课程亮点'}>
                        <div>
                            {
                                getFieldDecorator('suit_stores',{
                                    initialValue: data.suit_stores || [],
                                })(
                                    <Select placeholder={'请选择'}  mode="multiple" className={``} style={{ width:'300px' }}>
                                        {
                                            storeLists.map((item,index) => (
                                                <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </div>
                    </FormItem>
                    <FormItem label={'基础销量'}>
                        {getFieldDecorator('basic_sales', {
                            initialValue: data.basic_sales || ``,
                        })(<Input placeholder={'请输入'} maxLength={30} onBlur={this.handleInputChange} data-name={`basic_sales`} />)}
                    </FormItem>
                    <FormItem label={'分享标题'}>
                        {getFieldDecorator('share_title', {
                            initialValue: data.share_title || ``,
                        })(<Input placeholder={'请输入'} maxLength={30} />)}
                        <span className={`fi-help-text`}>将展示在分享链接中</span>
                    </FormItem>
                    <FormItem label={'分享图'}>
                        <div className={`clearfix`}>
                            {
                                data.share_img
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.share_img }
                                        onDel={ e => { this.delImg(e,'share_img') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'share_img') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M</span>
                    </FormItem>
                    <FormItem label={'加粉推广'}>
                        <div className={`clearfix`}>
                            {
                                data.fans_img
                                    ?
                                    <PictureCard
                                        onRef={ e => { this.PictureCard = e } }
                                        imgs={ data.fans_img }
                                        onDel={ e => { this.delImg(e,'fans_img') } }
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={ e => { this.ImgAdd = e } }
                                        onClick={ e => { this.chooseImg(e,'fans_img') } }
                                    ></ImgAdd>
                            }
                        </div>
                        <span className={'fi-help-text'}>用户购买后可以自动跳出二维码引导加微信或者进群，图片尺寸150*150px。</span>
                    </FormItem>
                    <div className={`msg-title mgb15`}>价格和售卖信息</div>
                    <FormItem label={<span>收费方式</span>}>
                        {getFieldDecorator('sell_channel', {
                            initialValue: data.sell_channel || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择销售渠道'
                                }
                            ]
                        })(
                            <Radio.Group onChange={ this.handleRadioChange } disabled={!!this.props.match.params.id}>
                                <Radio value={1}>
                                    按课时
                                    <Tooltip placement={`right`} title={`适合按上课次数销售的场景`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled"/>
                                    </Tooltip>
                                </Radio>
                                <Radio value={2}>
                                    按时段
                                    <Tooltip placement={`right`} title={`适合月卡、季度卡、年卡销售的场景`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled"/>
                                    </Tooltip>
                                </Radio>
                                <Radio value={3}>
                                    按期
                                    <Tooltip placement={`right`} title={`适合春季班，秋季班，暑假班，寒假班等按期销售的场景`}>
                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled"/>
                                    </Tooltip>
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'课程费用'} required={true}>
                        <div>
                            <table className="cxtables cxtables-sku">
                                <thead>
                                <tr>
                                    <td>课时</td>
                                    <td>售价</td>
                                    <td>门市价</td>
                                    <td>名额（人）</td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.tableData.map((item, index) => (
                                        <tr key={index} className="cbMoney">
                                            <td>
                                                <Input
                                                    placeholder={'请输入'}
                                                    style={{ width:'200px' }}
                                                    value={item.goods_sku_code}
                                                    onChange={(e) => { this.getInputValue(e,item,index) }}
                                                    data-name={`goods_sku_code`}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    placeholder={'请输入'}
                                                    style={{ width:'200px' }}
                                                    value={item.price}
                                                    onChange={(e) => { this.getInputValue(e,item,index) }}
                                                    data-name={`price`}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    placeholder={'请输入'}
                                                    style={{ width:'200px' }}
                                                    value={item.market_price}
                                                    onChange={(e) => { this.getInputValue(e,item,index) }}
                                                    data-name={`market_price`}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    placeholder={'请输入'}
                                                    style={{ width:'200px' }}
                                                    value={item.weight}
                                                    onChange={(e) => { this.getInputValue(e,item,index) }}
                                                    data-name={`weight`}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </FormItem>
                    <FormItem label={'售课时间'}>
                        {getFieldDecorator('bind_time_type', {
                            initialValue: data.bind_time_type || 1,
                            rules: [
                                {
                                    required:true
                                }
                            ]
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                    立即开售
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    定时开售，设置开售时间
                                    {
                                        getFieldDecorator('bind_time',{
                                            initialValue:data.bind_time || ''
                                        })(
                                            <TimePicker
                                                className={'mgl10 mgr10'}>
                                            </TimePicker>
                                        )
                                    }

                                </Radio>
                                <Radio value={3} className={`block mgb15`}>
                                    暂不开售，下架状态
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <div className={`msg-title mgb15`}>课程详情</div>
                    <FormItem label={'上课地点'}>
                        <div>
                            {
                                getFieldDecorator('location',{
                                    initialValue: data.location,
                                    rules:[
                                        {
                                            required:true ,
                                            message:'请选择上课地点'
                                        }
                                    ]
                                })(
                                    <Select placeholder={'请选择'}   className={``} style={{ width:'300px' }}>
                                        {
                                            storeLists.map((item,index) => (
                                                <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </div>
                    </FormItem>
                    <FormItem label={'上课老师'}>
                        <div>
                            {
                                getFieldDecorator('tech_id',{
                                    initialValue: data.tech_id,
                                })(
                                    <Button type={`primary`} ghost={true}>选择老师</Button>
                                )
                            }
                            <span className={`fi-help-text`}>最多选择20个老师；课程关联老师后，会显示在课程详情页</span>
                        </div>
                    </FormItem>
                    <FormItem label={'课程大纲'}>
                        <div>
                            {
                                this.state.booking_rules.map((item,index) => (
                                    <Row key={index}>
                                        <Col span={4}>
                                            {
                                                getFieldDecorator(`booking_rules[${index}].title`,{
                                                    initialValue: item.title
                                                })(
                                                    <Input placeholder={'请输入须知'} maxLength={30} />
                                                )
                                            }
                                        </Col>
                                        <Col span={18} className={`mgl10`}>
                                            {
                                                getFieldDecorator(`booking_rules[${index}].desc`,{
                                                    initialValue: item.desc
                                                })(
                                                    <Input placeholder={'请输入须知'} maxLength={30} />
                                                )
                                            }
                                            <Icon type="plus-circle" className={'mgl10 fz18 book-icon'} onClick={() => { this.addRules(index) }} />
                                            {
                                                index > 0
                                                    ?
                                                    <Icon type="minus-circle" className={'mgl10 fz18 book-icon'} onClick={() => { this.delRules(index) }} />
                                                    :
                                                    ''
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }
                            <Row>
                                <Button type={`primary`} ghost={true}>增加标题</Button>

                            </Row>
                        </div>
                    </FormItem>
                    <FormItem label={'课程详情'}>
                        {
                            this.state.showEditor
                                ?
                                <Editor id={'text'} value={data.content} callback={ content => this.EditorChange(content) }></Editor>
                                :
                                null
                        }
                    </FormItem>

                    <FormItem label={` `} colon={false}>
                        <Button type={`primary`} onClick={ this.saveData }>立即保存</Button>
                    </FormItem>





                </Form>

            </div>
        )
    }


}

export default Form.create({})(withRouter(EditForm))
