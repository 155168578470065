/**
 * 标题文本组件
 * @type 6
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})

class tpl_con_type6 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }
    state = {
        moduleData:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这个模块吗？`,
            okType:`danger`,
            onOk:() => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`标题文本`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{ paddingTop:`${moduleData.content.modulePadding}px`,paddingBottom:`${moduleData.content.modulePadding}px`}} onClick={ this.handleEditModule }>
                        <div className={`diy-txt-container style${moduleData.content.alignStyle}`} style={{ backgroundColor:`${moduleData.content.backgroundColor}` }}>
                            <div className={`wrap`}>
                                <div className={`diy-txt-content ${moduleData.content.fontBold == 1 ? 'ft-bold' : ''}`} style={{ color:`${moduleData.content.titleColor}`, fontSize:`${ moduleData.content.fontSize }px` }}>{ moduleData.content.title }</div>
                            </div>
                        </div>
                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type6)

