import React, { Component } from 'react'
import { Form, Input, Button, Select, Radio, Row, Col, Modal, Checkbox } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { staffList ,staffSave} from '@/api/staff'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import message from '@/utils/message'
const FormItem = Form.Item;

class AddStaff extends Component {

    state = {
        modalLoading: false,
        head_img: ""
    }
    componentDidMount() {
        if (this.props.edit_status) {
           
            this.setState({
                head_img:this.props.staff.head_img
            })
        }
    }

    delImg = (e, keyName) => {
        this.setState({
            head_img: ""
        })
    }
    chooseImg = (e, keyName) => {
        this.Gallery.show(imgs => {
            this.setState({
                head_img: imgs[0]
            })
        })
    }



    saveData = () => {
        let sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;

                let pattern = /^1[0-9]{10}$/

                if (!pattern.test(sendData.staff_mobile)) {
                    return message.error("请输入正确的手机号")
                }
                sendData.password = sendData.password.trim()

                if (!(sendData.password.length >= 6 && sendData.password.length <= 20)) {
                    return message.error("密码长度为6-20")
                }

                sendData.head_img=this.state.head_img

                sendData.role_ids= sendData.role_ids.join()



                console.log(sendData);
                if (this.props.edit_status) {
                    sendData.id = this.props.staff.id
                    sendData.sort = this.props.staff.sort
                }

                this.setState({
                    modalLoading: true
                })
                staffSave(sendData).then(res => {

                    if (res.code === 1) {
                        message.success(res.msg)
                        this.setState({
                            modalLoading: false
                        })
                        this.props.success()
                        this.handleCancel()
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.props.close()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        let data = {}
        if (this.props.edit_status) {
            data = this.props.staff
        }

        return (

            <Modal
                title={`${this.props.edit_status ? '编辑' : '新增'}员工`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                    <Row gutter={24}>
                        <Col span={20}>
                            <FormItem label="员工姓名">
                                {getFieldDecorator('name', {
                                    initialValue: data.name || "",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入员工姓名'
                                        }
                                    ]
                                })(<Input placeholder='请输入员工姓名' />)}
                            </FormItem>

                            <FormItem label="员工手机号">
                                {getFieldDecorator('staff_mobile', {
                                    initialValue: data.staff_mobile || "",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入员工手机号'
                                        }
                                    ]
                                })(<Input placeholder='请输入员工手机号' maxLength={11} />)}
                                <span className={'fi-help-text'}>手机号为系统唯一标识，用户后台登陆账号使用</span>
                            </FormItem>

                            <FormItem label="登陆密码">
                                {getFieldDecorator('password', {
                                    initialValue: data.password || "",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入登陆密码'
                                        }
                                    ]
                                })(<Input.Password placeholder='请输入登陆密码' maxLength={20} />)}
                                <span className={'fi-help-text'}>6-20位密码，用户后台登陆使用</span>
                            </FormItem>

                            <FormItem label={'头像'} >
                                <div className={`clearfix`}>
                                    {
                                        this.state.head_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={this.state.head_img}
                                                onDel={e => { this.delImg(e, 'head_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'head_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议尺寸：750x750像素，单张不超过2M</span>
                            </FormItem>


                            <FormItem label={'角色'} >
                                {
                                    getFieldDecorator('role_ids', {
                                        initialValue: data.role_ids_array || [],
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择所属角色'
                                            }
                                        ]
                                    })(
                                        <Checkbox.Group
                                            options={this.props.role_list}
                                        />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'所属门店'} >
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: data.store_id || undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择所属门店'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择所属门店'}    >
                                            {
                                                this.props.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label="状态">
                                {getFieldDecorator('status', {
                                    initialValue: data.status || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择在职状态'
                                        }
                                    ]
                                })(
                                    <Radio.Group >
                                        <Radio value={1}>在职</Radio>
                                        <Radio value={2}>离职</Radio>
                                    </Radio.Group>
                                )}
                                <span className={'fi-help-text'}>设定离职后，该员工不可登录后台进行任何操作</span>
                            </FormItem>

                        </Col>

                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(AddStaff))