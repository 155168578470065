import React, { Component } from 'react'
import {Button, Row, Col, Modal, Divider } from 'antd';

export default class LogDetail extends Component {
    state = {
        visible:true,
        CXModules: [],
        tplTems: {
            textSelect: val => {
                let dataset = val.dataset.filter(item => item.is_choose)
                let content = dataset.map((item,i) => <span key={i}>【{item.name}】</span>)
                return {
                    title: val.title,
                    content: content
                }
            },
            imgSelect: val => {
                let dataset = val.dataset.filter(item => item.is_choose)
                let content = dataset.map((item,i) => {
                    return (
                        <div key={i} className='mgr20'>
                            <p>【{item.name}】</p>
                            <img src={item.pic} alt="" width={180} />
                        </div>
                    )
                })
                return {
                    title: val.title,
                    content: <div className='flex flex-wrap'>{content}</div>
                }
            },
            dropSelct: val => {
                let dataset = val.dataset.filter(item => item.value == val.selectedValue)
                let content = dataset.map((item,i) => <span key={i}>【{item.name}】</span>)
                return {
                    title: val.title,
                    content: content
                }
            },
            oneInput: val => {
                return {
                    title: val.title,
                    content: <span>【{val.value}】</span>
                }
            },
            manyInput: val => {
                let content = val.dataset.map((item,i) => {
                    return (
                        <div key={i}>
                            <p>{item.name}</p>
                            <p>【{item.value}】</p>
                        </div>
                    )
                })

                return {
                    title: val.title,
                    content: content
                }
            },
            address: val => {
                let content = val.provinceName + val.cityName + val.areaName + val.address
                return {
                    title: val.title,
                    content: content
                }
            }
        }
    }

    renderTem = (val) => {
        let data = {}, tplTems = this.state.tplTems

        switch (val.type) {
            case 3:
            case 4:
                data = tplTems.textSelect(val.content)
                break;
            case 5:
            case 6:
                data = tplTems.imgSelect(val.content)
                break;
            case 7:
                data = tplTems.dropSelct(val.content)
                break;
            case 8:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                data = tplTems.oneInput(val.content)
                break;
            case 17:
                data = tplTems.address(val.content)
                break;
            default:
                return <></>
        }

        return (
            <Row className='mgt10'>
                <Col span={6} className={'tar pdr10'}>{data.title}：</Col>
                <Col span={14}>{data.content}</Col>
            </Row>
        )

    }

    handleOk=()=>{
        this.props.close()
    }


    render() {
        const CXModules=this.props.CXModules || []
        return (
            <Modal
                title="调查表详情"
                width={800}
                visible={this.state.visible}

                onCancel={this.handleOk}
                footer={ <Button onClick={this.handleOk}>取消</Button> }
            >
                 {
                        CXModules.map((val,index) => {
                            let Tem = this.renderTem(val)
                          
                            return <div key={index}>{Tem}</div>
                        })
                    }
            </Modal>
        )
    }
}
