import React from 'react'
import { Row, Col, Tabs, Icon } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})
const { TabPane } = Tabs

class ShortcutsPanel extends React.Component{
    state = {
        components:[
            {
                name:'基础组件',
                plugins:[
                    {
                        type:0,
                        name:'页面设置',
                        icon:'icon-setting'
                    },
                    {
                        type:1,
                        name:'文本',
                        icon:'icon-biaoti'
                    },
                    {
                        type:2,
                        name:'图片',
                        icon:'icon-tupian'
                    }
                ]
            },
            {
                name:'常用组件',
                plugins:[
                    {
                        type:3,
                        name:'单选',
                        icon:'icon-danxuan'
                    },
                    {
                        type:4,
                        name:'多选',
                        icon:'icon-duoxuan'
                    },
                    {
                        type:5,
                        name:'图片单选',
                        icon:'icon-tpdanxuan'
                    },
                    {
                        type:6,
                        name:'图片多选',
                        icon:'icon-tpduoxuan'
                    },
                    {
                        type:7,
                        name:'下拉',
                        icon:'icon-xiala'
                    },
                    {
                        type:8,
                        name:'单项填空',
                        icon:'icon-danxiangtiankong'
                    },
                    {
                        type:9,
                        name:'多项填空',
                        icon:'icon-duoxiangtiankong'
                    },
                    {
                        type:10,
                        name:'横杠填空',
                        icon:'icon-henggang'
                    },
                    {
                        type:11,
                        name:'简答',
                        icon:'icon-jianda'
                    },
                ]
            },
            {
                name:'个人信息',
                plugins:[
                    {
                        type:12,
                        name:'姓名',
                        icon:'icon-xingming'
                    },
                    {
                        type:13,
                        name:'电话',
                        icon:'icon-dianhua'
                    },
                    {
                        type:14,
                        name:'微信',
                        icon:'icon-weixin'
                    },
                    {
                        type:15,
                        name:'qq',
                        icon:'icon-qq'
                    },
                    {
                        type:16,
                        name:'邮箱',
                        icon:'icon-youxiang'
                    },
                    {
                        type:17,
                        name:'地址',
                        icon:'icon-dizhi'
                    },
                ]
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)

    }
    handleClickPlugin = item => {
        this.props.handleCurrentChange && this.props.handleCurrentChange(item)
    }

    render() {
        let components = this.state.components
        return(
            components.map((item,index) => (
                <Tabs  tabBarGutter={10} key={index}>
                    <TabPane key={`${index}`} tab={ item.name }>
                        <Row gutter={20}>
                            {
                                item.plugins && item.plugins.map(plugin => (
                                    <Col span={12} style={{ marginTop:'10px' }} key={plugin.type}>
                                        <div className={`shortcut-button`} onClick={() => { this.handleClickPlugin(plugin) }}>
                                            <i className={`comp-icons ${ plugin.icon }`}></i>
                                            <span className={`shortcut-txt`}>{ plugin.name }</span>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </TabPane>
                </Tabs>
            ))
        )
    }
}

export default ShortcutsPanel
