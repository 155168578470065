import React from 'react'
import { Form, Tabs, Checkbox, Input, Radio, Button, Switch } from 'antd'
import { withRouter } from 'react-router-dom'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import Editor from '@/components/Editor/Editor'
import {getConfig,setConfig} from '@/api/config'
const FormItem = Form.Item;

class TikTokOrder extends React.Component {
    state = {
        isLoading: false,
        showEditor: false,
        formData: {
            is_switch:0,
            money:0,
            point:0,
            is_agree:0,
            agree_content:'',
            content:''
        }
    }

    componentDidMount() {
       this.fetchData()
    }

    fetchData(){
        this.setState({isLoading:true})
        getConfig({key:'order_convert'}).then(res=>{
            if(res.code==1){
                let {value}=res.data.config;
                if(value){
                    value=JSON.parse(value)
                    this.setState({
                        formData:value
                    })
                }
            }
            this.setState({
                showEditor: true
            })
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.content = content;
        this.setState({
            formData
        })
    }

    saveData=()=>{
        let sendData = {key:'order_convert'},
        formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                values.is_switch=values.is_switch?1:0;
                values.is_agree=values.is_agree?1:0;

                sendData['value'] = {...formData, ...values};
               
                this.setState({isLoading:true})
                setConfig(sendData).then(res=>{
                    if(res.code==1){
                        message.success('保存成功')
                    }

                }).finally(()=>{
                    this.setState({
                        isLoading:false
                    })
                })

            }
        })
    }


    render() {
        let data = this.state.formData || {};
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 15
            }
        }

        return (
            <div className={`shadow-radius`}>

                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`msg-title mgb15`}>配置转换规则</div>
                    <FormItem label={'转换开关'}>
                        {getFieldDecorator('is_switch', {
                            initialValue: !!data.is_switch ,
                            valuePropName: 'checked'
                        })(
                            <Switch />
                        )}
                        &emsp;限近3个月内的消费订单才可转换积分
                    </FormItem>

                    <FormItem label={'兑换积分规则'} style={{ marginBottom: 0 }}>
                        每消费&emsp;
                        <Form.Item
                            style={{ display: 'inline-block', width: '200px' }}
                        >
                            {getFieldDecorator('money', {
                                initialValue: data.money 
                            })(<Input
                                placeholder={''}
                                maxLength={30}
                            />)}
                        </Form.Item>
                        &emsp;元，获得&emsp;
                        <Form.Item style={{ display: 'inline-block', width: '200px' }}>
                            {getFieldDecorator('point', {
                                initialValue: data.point 
                            })(<Input
                                placeholder={''}
                                maxLength={30}
                            />)}
                        </Form.Item>
                        &emsp;积分
                    </FormItem>

                    <FormItem label={'兑换同意开关'}>
                        {getFieldDecorator('is_agree', {
                            initialValue: !!data.is_agree ,
                            valuePropName: 'checked'
                        })(
                            <Switch />
                        )}
                        &emsp;开启则用户兑换时需勾选同意才可以兑换成功
                    </FormItem>

                    <FormItem label={'内容'}>
                        {getFieldDecorator('agree_content', {
                            initialValue: data.agree_content || '您知悉并同意您下单的抖音订单在每一次转换中，根据您填写的订单号将该笔订单的商品名称和金额提供给“XXX小程序”用于订单转换和展示。'
                        })(
                            <Input.TextArea
                                placeholder={''}
                                style={{width:400}}
                                rows={4}
                            />
                        )}
                    </FormItem>

                    <FormItem label={'兑换规则说明'}>
                        {
                            this.state.showEditor
                                ?
                                <Editor id={'text'} value={data.content} callback={content => this.EditorChange(content)}></Editor>
                                :
                                null
                        }
                    </FormItem>



                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>立即保存</Button>
                    </FormItem>
                </Form>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }

}

export default Form.create({})(withRouter(TikTokOrder))
