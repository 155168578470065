import $axios from '@/axios/axios.js'

export function getCity(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/logistic/city`,
        method:'post',
        data
    })
}

export function addExpressTemplate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/logistic/save`,
        method:'post',
        data
    })
}

export function expressTemplateLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/logistic/lists`,
        method:'post',
        data
    })
}

export function expressTemplateDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/logistic/detail`,
        method:'post',
        data
    })
}

export function expressTemplateDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/logistic/delete`,
        method:'post',
        data
    })
}
