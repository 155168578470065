/**
 * 商品列表组件
 * @type 8
 * @return Object
 */
import React from 'react'
import {Form, Icon, Modal, Tooltip} from 'antd'
import {ICON_FONT_URL} from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'


const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ8 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }

    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}

        return (
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`商品列表`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>
                        {
                            moduleData.content.style == 1
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`flowList pdl10 pdr10`}>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 0
                                                        ?
                                                        <div
                                                            className={`flow-item ${ moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : '' }`}
                                                            key={index}
                                                        >
                                                            <div className="item-img"
                                                                 style={{backgroundImage: `url(${item.cover_img})`}}></div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.name}</div>
                                                                <div className="sub-title">{ moduleData.content.showSubTitle ? item.subtitle : '' }</div>
                                                                <div className={`mgt10`}>
                                                                    <div className="sale">{ moduleData.content.showSale ? `已售${item.sales}件` : '' }</div>
                                                                    <div className="price-cart">
                                                                        <div className="price-group">
                                                                            <div className="price">&yen;
                                                                                <span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}
                                                                            </div>
                                                                            <div className="o-price">{ moduleData.content.showOprice ? `￥${item.market_price}` : `` }  </div>
                                                                        </div>
                                                                        {
                                                                            moduleData.content.showCart
                                                                                ?
                                                                                <div
                                                                                    className={ `
                                                                                ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                                ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                                ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                                                `
                                                                                    }
                                                                                >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }

                                        </div>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 1
                                                        ?
                                                        <div
                                                            className={`flow-item ${ moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : '' }`}
                                                            key={index}>
                                                            <div className="item-img"
                                                                 style={{backgroundImage: `url(${item.cover_img})`}}></div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.name}</div>
                                                                <div className="sub-title">{ moduleData.content.showSubTitle ? item.subtitle : '' }</div>
                                                                <div className={`mgt10`}>
                                                                    <div className="sale">{ moduleData.content.showSale ? `已售${item.sales}件` : '' }</div>
                                                                    <div className="price-cart">
                                                                        <div className="price-group">
                                                                            <div className="price">&yen;
                                                                                <span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}
                                                                            </div>
                                                                            <div className="o-price">{ moduleData.content.showOprice ? `￥${item.market_price}` : `` } </div>
                                                                        </div>
                                                                        {
                                                                            moduleData.content.showCart
                                                                                ?
                                                                                <div
                                                                                    className={ `
                                                                                ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                                ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                                ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                                                `
                                                                                    }
                                                                                >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`flowList pdl10 pdr10`}>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item ${ moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : '' }`}
                                            >
                                                <div className="item-img"
                                                     style={{backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)`}}></div>
                                                <div className="item-desc">
                                                    <div className="title">示例商品名称</div>
                                                    <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                    <div className={`mgt10`}>
                                                        <div className="sale">{ moduleData.content.showSale ? `已售100件` : '' }</div>
                                                        <div className="price-cart">
                                                            <div className="price-group">
                                                                <div className="price">&yen;
                                                                    <span>100</span>.00
                                                                </div>
                                                                <div className="o-price"> { moduleData.content.showOprice ? `￥100.00` : `` }  </div>
                                                            </div>
                                                            {
                                                                moduleData.content.showCart
                                                                    ?
                                                                    <div
                                                                        className={ `
                                                                                ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                                ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                                ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                                                `
                                                                        }
                                                                    >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item ${ moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : '' }`}
                                            >
                                                <div className="item-img"
                                                     style={{backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)`}}></div>
                                                <div className="item-desc">
                                                    <div className="title">示例商品名称</div>
                                                    <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                    <div className={`mgt10`}>
                                                        <div className="sale">{ moduleData.content.showSale ? `已售100件` : '' }</div>
                                                        <div className="price-cart">
                                                            <div className="price-group">
                                                                <div className="price">&yen;
                                                                    <span>100</span>.00
                                                                </div>
                                                                <div className="o-price">{ moduleData.content.showOprice ? `￥100.00` : `` } </div>
                                                            </div>
                                                            {
                                                                moduleData.content.showCart
                                                                    ?
                                                                    <div
                                                                        className={ `
                                                                                ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                                ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                                ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                                                `
                                                                        }
                                                                    >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null

                        }
                        {
                            moduleData.content.style == 2
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className="item-column3">
                                        {
                                            moduleData.content.dataset.map((item, index) => (
                                                <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`} key={index}>
                                                    <div className="top-img"
                                                         style={{backgroundImage: `url(${item.cover_img})`}}></div>
                                                    <div className="item-desc">
                                                        <div className="title">{item.name}</div>
                                                        <div className="sub-title">{ moduleData.content.showSubTitle ? item.subtitle : '' }</div>
                                                        <div className="price-group">
                                                            <div className="price">&yen;
                                                                <span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}
                                                            </div>
                                                            {
                                                                moduleData.content.showCart
                                                                    ?
                                                                    <div
                                                                        className={ `
                                                                                ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                                ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                                ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                                                `
                                                                        }
                                                                    >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="item-column3">
                                        <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' } `}>
                                            <div className="top-img noImg"></div>
                                            <div className="item-desc">
                                                <div className="title">这里显示商品标题，最多2行字</div>
                                                <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                <div className="price-group">
                                                    <div className="price">
                                                        &yen;
                                                        <span>99</span>
                                                        .00
                                                    </div>
                                                    {
                                                        moduleData.content.showCart
                                                            ?
                                                            <div
                                                                className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                            >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' } `}>
                                            <div className="top-img noImg"></div>
                                            <div className="item-desc">
                                                <div className="title">这里显示商品标题，最多2行字</div>
                                                <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                <div className="price-group">
                                                    <div className="price">
                                                        &yen;
                                                        <span>99</span>
                                                        .00
                                                    </div>
                                                    {
                                                        moduleData.content.showCart
                                                            ?
                                                            <div
                                                                className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                            >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' } `}>
                                            <div className="top-img noImg"></div>
                                            <div className="item-desc">
                                                <div className="title">这里显示商品标题，最多2行字</div>
                                                <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                <div className="price-group">
                                                    <div className="price">
                                                        &yen;
                                                        <span>99</span>
                                                        .00
                                                    </div>
                                                    {
                                                        moduleData.content.showCart
                                                            ?
                                                            <div
                                                                className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                            >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null

                        }
                        {
                            moduleData.content.style == 3
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`bdbox pdl10 pdr10`}>
                                        {
                                            moduleData.content.dataset.map((item,index) => (
                                                <div className={`cx-item-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`} key={index}>
                                                    <div className={`left`} style={{backgroundImage: `url(${item.cover_img})`}}>
                                                    </div>
                                                    <div className={`right`}>
                                                        <div className={`title`}>{item.name}</div>
                                                        <div className={`sub-title`}>{ moduleData.content.showSubTitle ? item.subtitle : '' }</div>
                                                        <div className={`right-bottom`}>
                                                            <div className={`sale`} >{ moduleData.content.showSale ? `已售${item.sales}件` : '' }</div>
                                                            <div className={`price-cart`}>
                                                                <div className={`price-group`}>
                                                                    <div className={`price`}>
                                                                        &yen;
                                                                        <span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}
                                                                    </div>
                                                                    <div className={`o-price`} >{ moduleData.content.showOprice ? `￥${item.market_price}` : `` }</div>
                                                                </div>
                                                                {
                                                                    moduleData.content.showCart
                                                                        ?
                                                                        <div
                                                                            className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                                        >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className={`bdbox pdl10 pdr10`}>
                                        <div className={`cx-item-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`} >
                                            <div className={`left`} style={{backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)`}}>
                                            </div>
                                            <div className={`right`}>
                                                <div className={`title`}>商品标题</div>
                                                <div className={`sub-title`}>{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                <div className={`right-bottom`}>
                                                    <div className={`sale`} >{ moduleData.content.showSale ? `已售100件` : '' }</div>
                                                    <div className={`price-cart`}>
                                                        <div className={`price-group`}>
                                                            <div className={`price`}>
                                                                &yen;
                                                                <span>99</span>.00
                                                            </div>
                                                            <div className={`o-price`} >{ moduleData.content.showOprice ? `￥100.00` : `` }</div>
                                                        </div>
                                                        {
                                                            moduleData.content.showCart
                                                                ?
                                                                <div
                                                                    className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                                >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`cx-item-style2 ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`} >
                                            <div className={`left`} style={{backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)`}}>
                                            </div>
                                            <div className={`right`}>
                                                <div className={`title`}>商品标题</div>
                                                <div className={`sub-title`}>{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                <div className={`right-bottom`}>
                                                    <div className={`sale`} >{ moduleData.content.showSale ? `已售100件` : '' }</div>
                                                    <div className={`price-cart`}>
                                                        <div className={`price-group`}>
                                                            <div className={`price`}>
                                                                &yen;
                                                                <span>99</span>.00
                                                            </div>
                                                            <div className={`o-price`} >{ moduleData.content.showOprice ? `￥100.00` : `` }</div>
                                                        </div>
                                                        {
                                                            moduleData.content.showCart
                                                                ?
                                                                <div
                                                                    className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                                >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null
                        }
                        {
                            moduleData.content.style == 4
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`item-style4`}>
                                        <div className={`flex-cont`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' }`} key={index}>
                                                        <div className="top-img"
                                                             style={{backgroundImage: `url(${item.cover_img})`}}></div>
                                                        <div className="item-desc">
                                                            <div className="title">{item.name}</div>
                                                            <div className="sub-title">{ moduleData.content.showSubTitle ? item.subtitle : '' }</div>
                                                            <div className="price-group">
                                                                <div className="price">&yen;
                                                                    <span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}
                                                                </div>
                                                                {
                                                                    moduleData.content.showCart
                                                                        ?
                                                                        <div
                                                                            className={ `
                                                                                ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                                ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                                ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                                                `
                                                                            }
                                                                        >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`item-style4`}>
                                        <div className={`flex-cont`}>
                                            <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' } `}>
                                                <div className="top-img noImg"></div>
                                                <div className="item-desc">
                                                    <div className="title">这里显示商品标题，最多2行字</div>
                                                    <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                    <div className="price-group">
                                                        <div className="price">
                                                            &yen;
                                                            <span>99</span>
                                                            .00
                                                        </div>
                                                        {
                                                            moduleData.content.showCart
                                                                ?
                                                                <div
                                                                    className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                                >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' } `}>
                                                <div className="top-img noImg"></div>
                                                <div className="item-desc">
                                                    <div className="title">这里显示商品标题，最多2行字</div>
                                                    <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                    <div className="price-group">
                                                        <div className="price">
                                                            &yen;
                                                            <span>99</span>
                                                            .00
                                                        </div>
                                                        {
                                                            moduleData.content.showCart
                                                                ?
                                                                <div
                                                                    className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                                >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : '' } `}>
                                                <div className="top-img noImg"></div>
                                                <div className="item-desc">
                                                    <div className="title">这里显示商品标题，最多2行字</div>
                                                    <div className="sub-title">{ moduleData.content.showSubTitle ? '商品副标题' : '' }</div>
                                                    <div className="price-group">
                                                        <div className="price">
                                                            &yen;
                                                            <span>99</span>
                                                            .00
                                                        </div>
                                                        {
                                                            moduleData.content.showCart
                                                                ?
                                                                <div
                                                                    className={ `
                                                                        ${moduleData.content.cartStyle == 1 ? 'cart' : ''}
                                                                        ${moduleData.content.cartStyle == 2 ? 'cart-style2' : ''}
                                                                        ${moduleData.content.cartStyle == 3 ? 'cart-style3' : ''}
                                                            `}
                                                                >{ moduleData.content.cartStyle == 3 ? '购买' : '' }</div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null
                        }

                        <EditorIcon type={`icon-guanbi`} className={`del-module`}
                                    onClick={this.handleDeleteModule}></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_typ8)
