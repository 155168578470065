import React from 'react'
import { Form, Input, Table, Select, Icon, Button, Checkbox, Tag } from 'antd'
import '@/assets/css/course/syllabus.scss'
const FormItem = Form.Item


class RuleNotice extends React.Component {
    state = {
        tabList: []
    }
    componentDidMount() {
        this.props.onRef(this)

    }

    componentWillReceiveProps(nextProps, nextContext) {

        let tabList = nextProps.tabList
        if (nextProps.id) {
            this.setState({
                tabList
            })
        }

    }

    getInputValue = (e, i) => {
        let value = e.target.value,
            type = e.currentTarget.dataset.type || ``,
            tabList = this.state.tabList
        tabList[i][type] = value
        this.setState({
            tabList: [...tabList]
        })
    }

    handleAdd = () => {
        let tabList = this.state.tabList
        tabList.push({
            title: "",
            text: ""
        })
        this.setState({
            tabList
        })
    }
 
    handleDeleText = (i, j) => {
        let tabList = this.state.tabList
        tabList.splice(i, 1)
        this.setState({
            tabList
        })
    }


    render() {
        return (
            <FormItem label={this.props.title|| `须知`} >
                <div className={this.state.tabList.length?'syllabus-wrap':''} style={{display:'block'}} >
                    {
                        this.state.tabList.map((item, i) => {
                            return (
                                <div className="flex1 flex" key={i}>
                                    <div className="syllabus-label">
                                        <Input
                                            placeholder={'请输入描述标题'}
                                            style={{ width: '150px' }}
                                            value={item.title}
                                            onChange={(e) => { this.getInputValue(e, i) }}
                                            data-type={'title'}
                                        />
                                    </div>
                                    <div className="syllabus-content">
                                        <div >
                                            <Input
                                                placeholder={'请输入标题下的内容'}
                                                style={{ width: '300px', marginBottom: "10px" }}
                                                value={item.text}
                                                onChange={(e) => { this.getInputValue(e, i) }}
                                                data-type={'text'}
                                            />
                                            <Icon type="minus-circle-o" className="syllabus-icon" onClick={() => { this.handleDeleText(i) }} />
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }


                </div>

                < Button type={'primary'} onClick={this.handleAdd} ghost>增加标题</Button>

            </FormItem>
        )
    }

}

export default Form.create({})(RuleNotice)
