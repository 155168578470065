import React, { Component } from 'react'
import { Form, Input, InputNumber, Select, DatePicker, Row, Col, Modal, TreeSelect } from 'antd'
import moment from 'moment'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import PictureCard from '@/components/PictureCard/PictureCard'
import Gallery from '@/components/Gallery/Gallery'
import { withRouter } from 'react-router-dom'
import { gradeStudentList,studentSign} from '@/api/educational'
import message from '@/utils/message'
const FormItem = Form.Item;

class StudentLeave extends Component {

    state = {
        studentLists: [],
        formData: {},
        modalLoading: false
    }

    handleSearch = val => {

        if (val) {

            let parent_mobile = val.trim()

            if (parent_mobile.length == 11) {
                let sendData = {}
                sendData.page = 1;
                sendData.per_page = 1000;
                sendData.parent_mobile = parent_mobile;
                gradeStudentList(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            let lists = res.data.list.data;
                            this.setState({
                                studentLists: lists
                            })
                        }
                    })
            }
        }


    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleSort = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = urls.splice(oldIndex, 1)[0];
        urls.splice(newIndex, 0, targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'promote_img':
                case 'share_img':
                    formData[name] = imgs[0];
                    break;
                case 'pic_urls':
                    formData['pic_urls'] = formData['pic_urls'] || [];
                    formData['pic_urls'] = formData['pic_urls'].concat(imgs);
                    if (formData['pic_urls'].length > 10) {
                        formData['pic_urls'] = formData['pic_urls'].slice(0, 9)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }




    saveData = () => {
        let sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;

                sendData.start_time = values.start_time ? moment(values.start_time).format('YYYY-MM-DD HH:mm') : ''
                sendData.end_time = values.end_time ? moment(values.end_time).format('YYYY-MM-DD HH:mm') : ''

                if(!moment(sendData.start_time).isBefore(sendData.end_time)){
                    return message.error("开始时间必须小于结束时间")
                }

                sendData.pic_urls=this.state.formData.pic_urls || []

                

                this.setState({
                    modalLoading: true
                })
                studentSign(sendData).then(res => {

                    if (res.code === 1) {
                        message.success(res.msg)
                        this.setState({
                            modalLoading: false
                        })
                        this.props.success()
                        this.handleCancel()
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.props.close()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        let data = this.state.formData || {}


        return (

            <Modal
                title={`新建请假`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={20}>
                            <FormItem label="学员">
                                {getFieldDecorator('student_id', {
                                    initialValue: undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择学员'
                                        }
                                    ]
                                })(
                                    <Select placeholder={'请输入学员手机号搜索'} showSearch onSearch={this.handleSearch} defaultActiveFirstOption={false} showArrow={false} filterOption={false} >
                                        {this.state.studentLists.map(v => {
                                            return <Select.Option value={v.id} key={v.id}>{v.real_name}</Select.Option>
                                        })}
                                    </Select>
                                )}
                            </FormItem>

                            <FormItem label={'请假类型'} >
                                {
                                    getFieldDecorator('type', {
                                        initialValue: undefined,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择请假类型'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择请假类型'}   >
                                            <Select.Option value={1} >事假</Select.Option>
                                            <Select.Option value={2} >病假</Select.Option>
                                            <Select.Option value={3} >其他</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'开始时间'} >
                                {
                                    getFieldDecorator('start_time', {
                                        initialValue: null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择开始时间'
                                            }
                                        ]
                                    })(
                                        <DatePicker showTime placeholder='请选择开始时间'  style={{ width: "100%" }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'结束时间'} >
                                {
                                    getFieldDecorator('end_time', {
                                        initialValue: null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择结束时间'
                                            }
                                        ]
                                    })(
                                        <DatePicker showTime placeholder='请选择结束时间' style={{ width: "100%" }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'请假原因'} >
                                {
                                    getFieldDecorator('reason', {
                                        initialValue: undefined,
                                    })(
                                        <Input.TextArea rows={4} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={<></>} className='no-colon' >
                                <div className={'clearfix'}>
                                    {
                                        data.pic_urls && data.pic_urls.length
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={data.pic_urls}
                                                onDel={e => { this.delImg(e, 'pic_urls') }}
                                                onSort={e => { this.handleSort(e, 'pic_urls') }}
                                            ></PictureCard>
                                            :
                                            null
                                    }
                                    {
                                        data.pic_urls && data.pic_urls.length >= 9
                                            ?
                                            null
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'pic_urls') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>可上传相关凭证，最多9张</span>
                            </FormItem>

                        </Col>

                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(StudentLeave))