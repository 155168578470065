/**
 * 拼团列表组件
 * @type 17
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'


const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ17 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}

        return (
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`拼团列表`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`,
                        '--activity-text-color': `${moduleData.content.textColor}`,
                        '--start-color': `${moduleData.content.startColor}`,
                        '--not-start-color': `${moduleData.content.notStartColor}`,
                        '--start-default-bgcolor':`${moduleData.content.startColor=='#FF3636'?'linear-gradient(90deg, #FF6336 0%, #FF3636 100%)': moduleData.content.startColor}`,
                        '--not-start-default-bgcolor':`${moduleData.content.notStartColor=='#6D30FF'?'linear-gradient(90deg, #54D0FF 0%, #6D30FF 100%)': moduleData.content.notStartColor}`,
                    }} onClick={this.handleEditModule}>
                        {
                            moduleData.content.style == 1
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`flowList group-bugying pdl10 pdr10`}>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 0
                                                        ?
                                                        <div
                                                            className={`flow-item ${item.status == 1 ? `not-start` : item.status == 2 ? `start` : ''} ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                                            key={index}
                                                        >
                                                            <div className="item-img"
                                                                style={{ backgroundImage: `url(${item.share_poster})` }}>
                                                                <div className="time">
                                                                    <span>{item.status==2?'距结束':item.status==1?'距开始':''}</span>
                                                                    <span>1天23:59:59</span>
                                                                </div>
                                                            </div>
                                                            <div className="item-desc">
                                                                <div className="title">
                                                                    <div className="group">
                                                                        <div className="group-left">{item.open_num}</div>
                                                                        <div className="group-right">人拼</div>
                                                                    </div>
                                                                    <span>{item.goods_info && item.goods_info.name}</span>
                                                                </div>
                                                                <div className="sub-title">{moduleData.content.showSubTitle ? item.goods_info && (item.goods_info.sub_title || item.goods_info.subtitle || '') : ''}</div>
                                                                <div className="avatar-wrap">
                                                                    <div className="avatar-list">
                                                                        {
                                                                            item.user && item.user.map((img_url, i) => {
                                                                                return <img src={img_url} alt="" className='img' key={i} />
                                                                            })
                                                                        }
                                                                        <div className="img-more">...</div>
                                                                    </div>
                                                                    <div className="subtitle">{moduleData.content.showSales ? item.status == 1 ? `${item.sales}人想要` : item.status == 2 ? `${item.sales}人已拼` : '' : ''}</div>
                                                                </div>
                                                                <div className="btn">
                                                                    <div className="price">拼团价 &yen;<span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}</div>
                                                                    <div className="btn-right">去开团</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }

                                        </div>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 1
                                                        ?
                                                        <div
                                                            className={`flow-item ${item.status == 1 ? `not-start` : item.status == 2 ? `start` : ''} ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                                            key={index}
                                                        >
                                                            <div className="item-img"
                                                                style={{ backgroundImage: `url(${item.share_poster})` }}>
                                                                <div className="time">
                                                                    <span>{item.status==2?'距结束':item.status==1?'距开始':''}</span>
                                                                    <span>1天23:59:59</span>
                                                                </div>
                                                            </div>
                                                            <div className="item-desc">
                                                                <div className="title">
                                                                    <div className="group">
                                                                        <div className="group-left">{item.open_num}</div>
                                                                        <div className="group-right">人拼</div>
                                                                    </div>
                                                                    <span>{item.goods_info && item.goods_info.name}</span>
                                                                </div>
                                                                <div className="sub-title">{moduleData.content.showSubTitle ? item.goods_info && (item.goods_info.sub_title || item.goods_info.subtitle || '') : ''}</div>
                                                                <div className="avatar-wrap">
                                                                    <div className="avatar-list">
                                                                        {
                                                                            item.user && item.user.map((img_url, i) => {
                                                                                return <img src={img_url} alt="" className='img' key={i} />
                                                                            })
                                                                        }
                                                                        <div className="img-more">...</div>
                                                                    </div>
                                                                    <div className="subtitle">{moduleData.content.showSales ? item.status == 1 ? `${item.sales}人想要` : item.status == 2 ? `${item.sales}人已拼` : '' : ''}</div>
                                                                </div>
                                                                <div className="btn">
                                                                    <div className="price">拼团价 &yen;<span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}</div>
                                                                    <div className="btn-right">去开团</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`flowList group-bugying pdl10 pdr10`}>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item start ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                            >
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}>
                                                    <div className="time">
                                                        <span>距结束</span>
                                                        <span>1天23:59:59</span>
                                                    </div>
                                                </div>
                                                <div className="item-desc">
                                                    <div className="title">
                                                        <div className="group">
                                                            <div className="group-left">3</div>
                                                            <div className="group-right">人拼</div>
                                                        </div>
                                                        <span>这里展示课程标题，最多展示2行字</span>
                                                    </div>
                                                    <div className="sub-title">{moduleData.content.showSubTitle ? '这里展示课程简介，超出部分...' : ''}</div>

                                                    <div className="avatar-wrap">
                                                        <div className="avatar-list">
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/e7e9b59c4ba39980885ff5062b951c64.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/0b0443d04c06d401c0399eba5193c90a.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/897ce3c3d847709d52fa636308217254.png" alt="" className='img' />
                                                            <div className="img-more">...</div>
                                                        </div>
                                                        <div className="subtitle">已拼222件</div>
                                                    </div>
                                                    <div className="btn">
                                                        <div className="price">拼团价 &yen;<span>269</span>.00</div>
                                                        <div className="btn-right">去开团</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item start ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                            >
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}>
                                                    <div className="time">
                                                        <span>距结束</span>
                                                        <span>1天23:59:59</span>
                                                    </div>
                                                </div>
                                                <div className="item-desc">
                                                    <div className="title">
                                                        <div className="group">
                                                            <div className="group-left">3</div>
                                                            <div className="group-right">人拼</div>
                                                        </div>
                                                        <span>这里展示商品标题，最多展示2行字</span>
                                                    </div>
                                                    <div className="sub-title">{moduleData.content.showSubTitle ? '这里展示商品副标题，超出部分...' : ''}</div>

                                                    <div className="avatar-wrap">
                                                        <div className="avatar-list">
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/e7e9b59c4ba39980885ff5062b951c64.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/0b0443d04c06d401c0399eba5193c90a.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/897ce3c3d847709d52fa636308217254.png" alt="" className='img' />
                                                            <div className="img-more">...</div>
                                                        </div>
                                                        <div className="subtitle">{moduleData.content.showSales ? '已拼1233件' : ''} </div>
                                                    </div>
                                                    <div className="btn">
                                                        <div className="price">拼团价 &yen;<span>269</span>.00</div>
                                                        <div className="btn-right">去开团</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null
                        }

                        {
                            moduleData.content.style == 2
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`bdbox pdl10 pdr10`}>
                                        {
                                            moduleData.content.dataset.map((item, index) => (
                                                <div className={`cx-item-style2 group-bugying ${item.status == 1 ? `not-start` : item.status == 2 ? `start` : ''} ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} key={index}>
                                                    <div className={`left`} style={{ backgroundImage: `url(${item.share_poster})` }}>
                                                    <div className="time">
                                                        <span>{item.status==2?'距结束':item.status==1?'距开始':''}</span>
                                                        <span>1天23:59:59</span>
                                                    </div>
                                                    </div>
                                                    <div className={`right`}>
                                                        <div className="title">
                                                            <div className="group">
                                                                <div className="group-left">{item.open_num}</div>
                                                                <div className="group-right">人拼</div>
                                                            </div>
                                                            <span>{item.goods_info && item.goods_info.name}</span>
                                                        </div>
                                                        <div className="sub-title">{moduleData.content.showSubTitle ? item.goods_info && (item.goods_info.sub_title || item.goods_info.subtitle || '') : ''}</div>
                                                        <div className={`right-bottom`}>
                                                            <div className="avatar-wrap">
                                                                <div className="avatar-list">
                                                                    {
                                                                        item.user && item.user.map((img_url, i) => {
                                                                            return <img src={img_url} alt="" className='img' key={i} />
                                                                        })
                                                                    }
                                                                    <div className="img-more">...</div>
                                                                </div>
                                                                <div className="subtitle">{moduleData.content.showSales ? item.status == 1 ? `${item.sales}人想要` : item.status == 2 ? `${item.sales}人已拼` : '' : ''}</div>
                                                            </div>
                                                            <div className="btn">
                                                                <div className="price">拼团价 &yen;<span>{CX.dealPrice(item.price)[0]}</span>.{CX.dealPrice(item.price)[1]}</div>
                                                                <div className="btn-right">去开团</div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className={`bdbox pdl10 pdr10`}>
                                        <div className={`cx-item-style2 start group-bugying ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} >
                                            <div className={`left`} style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}>
                                                <div className="time">
                                                    <span>距结束</span>
                                                    <span>1天23:59:59</span>
                                                </div>
                                            </div>
                                            <div className={`right`}>
                                                <div className="title">
                                                    <div className="group">
                                                        <div className="group-left">3</div>
                                                        <div className="group-right">人拼</div>
                                                    </div>
                                                    <span>这里展示商品标题，最多展示2行字</span>
                                                </div>
                                                <div className="sub-title">{moduleData.content.showSubTitle ? '这里展示商品副标题，超出部分...' : ''}</div>
                                                <div className={`right-bottom`}>
                                                    <div className="avatar-wrap">
                                                        <div className="avatar-list">
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/e7e9b59c4ba39980885ff5062b951c64.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/0b0443d04c06d401c0399eba5193c90a.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/897ce3c3d847709d52fa636308217254.png" alt="" className='img' />
                                                            <div className="img-more">...</div>
                                                        </div>
                                                        <div className="subtitle">{moduleData.content.showSales ? '已拼1233件' : ''} </div>
                                                    </div>
                                                    <div className="btn">
                                                        <div className="price">拼团价 &yen;<span>269</span>.00</div>
                                                        <div className="btn-right">去开团</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`cx-item-style2 start group-bugying ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} >
                                            <div className={`left`} style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}>
                                                <div className="time">
                                                    <span>距结束</span>
                                                    <span>1天23:59:59</span>
                                                </div>
                                            </div>
                                            <div className={`right`}>
                                                <div className="title">
                                                    <div className="group">
                                                        <div className="group-left">3</div>
                                                        <div className="group-right">人拼</div>
                                                    </div>
                                                    <span>这里展示商品标题，最多展示2行字</span>
                                                </div>
                                                <div className="sub-title">{moduleData.content.showSubTitle ? '这里展示商品副标题，超出部分...' : ''}</div>
                                                <div className={`right-bottom`}>
                                                    <div className="avatar-wrap">
                                                        <div className="avatar-list">
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/e7e9b59c4ba39980885ff5062b951c64.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/0b0443d04c06d401c0399eba5193c90a.png" alt="" className='img' />
                                                            <img src="https://img.cxkoo.com/chengxuan/1/2022-01-18/897ce3c3d847709d52fa636308217254.png" alt="" className='img' />
                                                            <div className="img-more">...</div>
                                                        </div>
                                                        <div className="subtitle">{moduleData.content.showSales ? '已拼1233件' : ''} </div>
                                                    </div>
                                                    <div className="btn">
                                                        <div className="price">拼团价 &yen;<span>269</span>.00</div>
                                                        <div className="btn-right">去开团</div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                :
                                null
                        }
                        {
                            moduleData.content.style == 3
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`item-style4 group-bugying`}>
                                        <div className={`flex-cont`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    <div className={`item-list ${item.status == 1 ? `not-start` : item.status == 2 ? `start` : ''} ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} key={index}>
                                                        <div className="top-img"
                                                            style={{ backgroundImage: `url(${item.share_poster})` }}>
                                                            <div className="time">
                                                                <span>{item.status==2?'距结束':item.status==1?'距开始':''}</span>
                                                                <span>1天23:59:59</span>
                                                            </div>
                                                        </div>
                                                        <div className="item-desc">
                                                            <div className="title">
                                                                <div className="group">
                                                                    <div className="group-left">{item.open_num}</div>
                                                                    <div className="group-right">人拼</div>
                                                                </div>
                                                                <span>{item.goods_info && item.goods_info.name}</span>
                                                            </div>
                                                            <div className="sub-title">{moduleData.content.showSubTitle ? item.goods_info && (item.goods_info.sub_title || item.goods_info.subtitle || '') : ''}</div>

                                                            <div className="price-group">
                                                                <div className="price">
                                                                    &yen;
                                                                    <span>{CX.dealPrice(item.price)[0]}</span>
                                                                    .{CX.dealPrice(item.price)[1]}
                                                                </div>
                                                                <div className="btn">{item.status==2?`去开团`:item.status==1?'提醒我':''} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`item-style4 group-bugying start`}>
                                        <div className={`flex-cont`}>
                                            <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''} `}>
                                                <div className="top-img noImg">
                                                    <div className="time">
                                                        <span>距结束</span>
                                                        <span>1天23:59:59</span>
                                                    </div>
                                                </div>
                                                <div className="item-desc">
                                                    <div className="title">
                                                        <div className="group">
                                                            <div className="group-left">3</div>
                                                            <div className="group-right">人拼</div>
                                                        </div>
                                                        <span>这里展示商品标题，最多展示2行字</span>
                                                    </div>
                                                    <div className={`sub-title`}>{moduleData.content.showSubTitle ? '这里展示课程简介，超出部分...' : ''}</div>

                                                    <div className="price-group">
                                                        <div className="price">
                                                            &yen;
                                                            <span>99</span>
                                                            .00
                                                        </div>
                                                        <div className="btn">去开团</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''} `}>
                                                <div className="top-img noImg">
                                                    <div className="time">
                                                        <span>距结束</span>
                                                        <span>1天23:59:59</span>
                                                    </div>
                                                </div>
                                                <div className="item-desc">
                                                    <div className="title">
                                                        <div className="group">
                                                            <div className="group-left">3</div>
                                                            <div className="group-right">人拼</div>
                                                        </div>
                                                        <span>这里展示商品标题，最多展示2行字</span>
                                                    </div>
                                                    <div className={`sub-title`}>{moduleData.content.showSubTitle ? '这里展示课程简介，超出部分...' : ''}</div>

                                                    <div className="price-group">
                                                        <div className="price">
                                                            &yen;
                                                            <span>99</span>
                                                            .00
                                                        </div>
                                                        <div className="btn">去开团</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''} `}>
                                                <div className="top-img noImg">
                                                    <div className="time">
                                                        <span>距结束</span>
                                                        <span>1天23:59:59</span>
                                                    </div>
                                                </div>
                                                <div className="item-desc">
                                                    <div className="title">
                                                        <div className="group">
                                                            <div className="group-left">3</div>
                                                            <div className="group-right">人拼</div>
                                                        </div>
                                                        <span>这里展示商品标题，最多展示2行字</span>
                                                    </div>
                                                    <div className={`sub-title`}>{moduleData.content.showSubTitle ? '这里展示课程简介，超出部分...' : ''}</div>

                                                    <div className="price-group">
                                                        <div className="price">
                                                            &yen;
                                                            <span>99</span>
                                                            .00
                                                        </div>
                                                        <div className="btn">去开团</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null
                        }

                        <EditorIcon type={`icon-guanbi`} className={`del-module`}
                            onClick={this.handleDeleteModule}></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_typ17)
