import React, { Component } from 'react'
import { Table, Button } from 'antd'
import { SetBirthdayVipRule, SetBirthdayPointsRule, SetBirthdayCouponRule } from './set-vip-price'

export default class BirthdayDiscount extends Component {
    state = {
        columns: [
            {
                title: '生日权益',
                dataIndex: '1',
                width: 160,
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.name} </div>
                    )
                }
            },
            {
                title: 'V1',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v1 || '-'} </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v2 || '-'} </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v3 || '-'} </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v4 || '-'}</div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v5 || '-'}</div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '8',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v6 || '-'} </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: 80,
                render: (text, rowData, index) => {

                    return <Button type='link' size='small' onClick={() => { this.handleEdit(rowData, index) }}>编辑</Button>
                }
            },
        ],
        tableData: [
            {
                id: 1,
                name: '所有商品、自助买单',
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            },
            {
                id: 2,
                name: '消费积分多倍',
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            },
            {
                id: 3,
                name: '生日礼券',
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            }
        ]
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        let detail = this.props.detail || {};
        if (Array.isArray(detail.rule) && detail.rule.length) {
            let tableData = this.state.tableData, rule = detail.rule;


            if (rule[0].rule) {
                tableData[0].rule = rule[0].rule;
                if (tableData[0].rule.v1 > 0) {
                    tableData[0].v1 = tableData[0].rule.v1 + '折'
                }
                if (tableData[0].rule.v2 > 0) {
                    tableData[0].v2 = tableData[0].rule.v2 + '折'
                }
                if (tableData[0].rule.v3 > 0) {
                    tableData[0].v3 = tableData[0].rule.v3 + '折'
                }
                if (tableData[0].rule.v4 > 0) {
                    tableData[0].v4 = tableData[0].rule.v4 + '折'
                }
                if (tableData[0].rule.v5 > 0) {
                    tableData[0].v5 = tableData[0].rule.v5 + '折'
                }
                if (tableData[0].rule.v6 > 0) {
                    tableData[0].v6 = tableData[0].rule.v6 + '折'
                }
            }

            if (rule[1].rule) {
                tableData[1].rule = rule[1].rule;
                if (tableData[1].rule.v1 > 0) {
                    tableData[1].v1 = tableData[1].rule.v1 + '倍'
                }
                if (tableData[1].rule.v2 > 0) {
                    tableData[1].v2 = tableData[1].rule.v2 + '倍'
                }
                if (tableData[1].rule.v3 > 0) {
                    tableData[1].v3 = tableData[1].rule.v3 + '倍'
                }
                if (tableData[1].rule.v4 > 0) {
                    tableData[1].v4 = tableData[1].rule.v4 + '倍'
                }
                if (tableData[1].rule.v5 > 0) {
                    tableData[1].v5 = tableData[1].rule.v5 + '倍'
                }
                if (tableData[1].rule.v6 > 0) {
                    tableData[1].v6 = tableData[1].rule.v6 + '倍'
                }
            }

            if (rule[2].rule) {
                tableData[2].rule = rule[2].rule;

                if (tableData[2].rule.v1?.length) {
                    let num = 0;
                    tableData[2].rule.v1= tableData[2].rule.v1.map((item,index) => {
                        if (item.coupon_id && item.num > 0) {
                            num += +item.num;
                        }
                        item.id=index+1;
                        return item
                    })
                    if (num > 0) {
                        tableData[2].v1 = `已选${num}张优惠券`
                    }
                } else {
                    tableData[2].rule.v1 = [
                        { id: 1, coupon_id: '', num: '' }
                    ]
                }

                if (tableData[2].rule.v2?.length) {
                    let num = 0;
                    tableData[2].rule.v2= tableData[2].rule.v2.map((item,index) => {
                        if (item.coupon_id && item.num > 0) {
                            num += +item.num;
                        }
                        item.id=index+1;
                        return item
                    })
                    if (num > 0) {
                        tableData[2].v2 = `已选${num}张优惠券`
                    }
                } else {
                    tableData[2].rule.v2 = [
                        { id: 1, coupon_id: '', num: '' }
                    ]
                }

                if (tableData[2].rule.v3?.length) {
                    let num = 0;
                    tableData[2].rule.v3= tableData[2].rule.v3.map((item,index) => {
                        if (item.coupon_id && item.num > 0) {
                            num += +item.num;
                        }
                        item.id=index+1;
                        return item
                    })
                    if (num > 0) {
                        tableData[2].v3 = `已选${num}张优惠券`
                    }
                } else {
                    tableData[2].rule.v3 = [
                        { id: 1, coupon_id: '', num: '' }
                    ]
                }

                if (tableData[2].rule.v4?.length) {
                    let num = 0;
                    tableData[2].rule.v4= tableData[2].rule.v4.map((item,index) => {
                        if (item.coupon_id && item.num > 0) {
                            num += +item.num;
                        }
                        item.id=index+1;
                        return item
                    })
                    if (num > 0) {
                        tableData[2].v4 = `已选${num}张优惠券`
                    }
                } else {
                    tableData[2].rule.v4 = [
                        { id: 1, coupon_id: '', num: '' }
                    ]
                }

                if (tableData[2].rule.v5?.length) {
                    let num = 0;
                    tableData[2].rule.v5= tableData[2].rule.v5.map((item,index) => {
                        if (item.coupon_id && item.num > 0) {
                            num += +item.num;
                        }
                        item.id=index+1;
                        return item
                    })
                    if (num > 0) {
                        tableData[2].v5 = `已选${num}张优惠券`
                    }
                } else {
                    tableData[2].rule.v5 = [
                        { id: 1, coupon_id: '', num: '' }
                    ]
                }

                if (tableData[2].rule.v6?.length) {
                    let num = 0;
                    tableData[2].rule.v6= tableData[2].rule.v6.map((item,index) => {
                        if (item.coupon_id && item.num > 0) {
                            num += +item.num;
                        }
                        item.id=index+1;
                        return item
                    })
                    if (num > 0) {
                        tableData[2].v6 = `已选${num}张优惠券`
                    }
                } else {
                    tableData[2].rule.v6 = [
                        { id: 1, coupon_id: '', num: '' }
                    ]
                }
            }

            this.setState({
                tableData
            })

        }
    }

    getData = () => {
        let tableData = this.state.tableData;
        tableData = tableData.map(item => {
            let obj = {
                id: item.id,
                name: item.name
            }
            if (item.rule) {
                obj.rule = item.rule

                if (item.id == 3) {
                    let rule = item.rule;
                    rule.v1 = item.rule.v1?.filter?.(val => {
                        if (val.coupon_id && val.num) {
                            return true
                        }
                        return false
                    })
                    rule.v2 = item.rule.v2?.filter?.(val => {
                        if (val.coupon_id && val.num) {
                            return true
                        }
                        return false
                    })
                    rule.v3 = item.rule.v3?.filter?.(val => {
                        if (val.coupon_id && val.num) {
                            return true
                        }
                        return false
                    })
                    rule.v4 = item.rule.v4?.filter?.(val => {
                        if (val.coupon_id && val.num) {
                            return true
                        }
                        return false
                    })
                    rule.v5 = item.rule.v5?.filter?.(val => {
                        if (val.coupon_id && val.num) {
                            return true
                        }
                        return false
                    })
                    rule.v1 = item.rule.v1?.filter?.(val => {
                        if (val.coupon_id && val.num) {
                            return true
                        }
                        return false
                    })
                    rule.v6 = item.rule.v6?.filter?.(val => {
                        if (val.coupon_id && val.num) {
                            return true
                        }
                        return false
                    })
                    obj.rule = rule
                }
            }
            return obj
        })
        return tableData
    }

    handleEdit = (row, index) => {
        if (index == 0) {
            this.SetBirthdayVipRule.show(row, (res) => {
                let tableData = this.state.tableData;
                tableData[index] = res
                this.setState({
                    tableData
                })
            })
        } else if (index == 1) {
            this.SetBirthdayPointsRule.show(row, (res) => {
                let tableData = this.state.tableData;
                tableData[index] = res
                this.setState({
                    tableData
                })
            })
        } else if (index == 2) {
            this.SetBirthdayCouponRule.show(row, (res) => {
                let tableData = this.state.tableData;
                tableData[index] = res
                this.setState({
                    tableData
                })
            })
        }

    }
    render() {
        return (
            <>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <SetBirthdayVipRule onRef={e => this.SetBirthdayVipRule = e} />
                <SetBirthdayPointsRule onRef={e => this.SetBirthdayPointsRule = e} />
                <SetBirthdayCouponRule onRef={e => this.SetBirthdayCouponRule = e} />

            </>
        )
    }
}
