import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, InputNumber, Radio, Tabs, message } from 'antd';
import moment from 'moment'
import ChapterPrize from './ChapterPrize';
import { couponLists } from '@/api/coupon'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class PrizeForm extends Component {
    state = {
        activeKey: '1',
        couponLists: [],

        panes: [
            {
                id: '1',
                content: {
                    start_time: "",
                    end_time: "",
                    prize_type: [],
                    tableData: []
                }
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchCoupon()

        const { is_edit, activityData } = this.props
        if (is_edit && activityData.game_red_gift.length) {
            let panes = []
            activityData.game_red_gift.forEach(val => {
                let item = {}
                item.id = val.id
                item.content = {
                    start_time: val.start_at,
                    end_time: val.end_at,
                    prize_type: [],
                    tableData: []
                }

                item.content.prize_type = val.data.map(v => v.type)

                item.content.tableData = val.data.map(v => {
                    let obj = {
                        id: v.type,
                        prize_num: v.total_num,
                        min: v.red_min,
                        max: v.red_max
                    }

                    if (obj.id == 1) {
                        obj.coupon_id = v.coupon_id
                        obj.name = "赠送优惠券"
                    } else if (obj.id == 2) {
                        obj.integral_num = v.points
                        obj.name = "赠送积分"
                    } else {
                        obj.wechat_money = v.money
                        obj.name = "赠送微信零钱"
                    }
                    return obj
                })

                panes.push(item)
            })
            let activeKey = panes[0].id + ''
            this.setState({
                panes, activeKey
            })

        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.red_num != this.props.red_num) {
            let panes = this.state.panes
            panes = panes.map(val => {
                val.content.prize_type = []
                val.content.tableData = []
                return val
            })

            this.setState({
                panes
            })
        }
    }



    fetchCoupon = () => {
        let sendData = {
            page: 1,
            per_page: 100,
            status: 1
        };

        couponLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.coupon_list.data,
                        total = res.data.coupon_list.total,
                        per_page = res.data.coupon_list.per_page;

                    lists = lists.filter(val => val.last_num > 0)
                    this.setState({
                        couponLists: lists,
                        total, per_page
                    })

                }
            })
    }

    getFormData = () => {

        return new Promise((reslove, reject) => {

            let panes = this.state.panes, prize = []

            for (let index = 0; index < panes.length; index++) {
                if ((!panes[index].content.start_time) || (!panes[index].content.end_time)) {

                    message.error("请选择场次时间")
                    return reslove(false)
                }
            }

            panes.forEach((val, i) => {
                let item = {}
                item.start_at = val.content.start_time
                item.end_at = val.content.end_time
                item.data = []
                val.content.tableData.forEach((v, j) => {
                    let obj = {}
                    obj.type = v.id

                    if (v.id == 1) {
                        obj.coupon_id = v.coupon_id
                    } else if (v.id == 2) {
                        obj.points = v.integral_num
                    } else {
                        obj.money = v.wechat_money
                    }

                    obj.total_num = v.prize_num
                    obj.red_min = v.min
                    obj.red_max = v.max
                    item.data.push(obj)
                })
                prize.push(item)
            })

            return reslove({ prize })
        })
    }

    handleChangeTabs = key => {
        this.setState({
            activeKey: key
        })
    }
    onEdit = (targetKey, action) => {

        if (this.props.is_disabled) {
            return message.error("编辑状态下不可修改场次")
        }

        if (action == 'add') {
            this.handleAdd()
        } else if (action == 'remove') {
            let { activeKey } = this.state;
            let lastIndex;
            
            this.state.panes.forEach((pane, i) => {
                if (pane.id == targetKey) {
                    lastIndex = i - 1;
                }
            });
           
            const panes = this.state.panes.filter(pane => pane.id != targetKey);
            if (panes.length && activeKey == targetKey) {
                if (lastIndex >= 0) {
                    activeKey = panes[lastIndex].id+''
                } else {
                    activeKey = panes[0].id+''
                }
            }
          
            this.setState({ panes, activeKey });
        }
    }

    handleAdd = () => {
        let panes = this.state.panes
        let id = new Date().getTime() + ''
        panes.push({
            id: id,
            content: {
                start_time: "",
                end_time: "",
                prize_type: [],
                tableData: []
            }
        });

        this.setState({ panes, activeKey: id });
    }

    updatePanes(index, content) {
        let panes = this.state.panes
        panes[index].content = content
        this.setState({
            panes
        })
    }


    render() {

        const { is_edit, is_disabled } = this.props
        const operations =this.state.panes.length >= 3 ?null: <Button icon="plus" onClick={this.handleAdd} type="primary" disabled={is_disabled} ></Button>

        return (
            <>
                <Tabs className={'mgt15'} hideAdd tabBarExtraContent={operations}  type="editable-card" onEdit={this.onEdit} activeKey={this.state.activeKey} onChange={this.handleChangeTabs}  >

                    {
                        this.state.panes.map((pane, index) => {
                            return (
                                <TabPane forceRender={true} tab={['第一场', '第二场', '第三场'][index]} key={pane.id} closable={index !== 0}>
                                    <ChapterPrize is_disabled={is_disabled} is_edit={is_edit} updatePanes={(c) => { this.updatePanes(index, c) }} couponLists={this.state.couponLists} content={pane.content} red_num={this.props.red_num || 0} ></ChapterPrize>
                                </TabPane>
                            )
                        })
                    }

                </Tabs>

                <div className='mgt20'>
                    <Button onClick={() => this.props.updateTab('1')}>上一步</Button>
                    <Button type='primary' className='mgl20' onClick={() => this.props.updateTab('3')}>下一步</Button>
                </div>
            </>

        )
    }
}

export default PrizeForm
