import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Select, Button, DatePicker, Tooltip, Icon, Checkbox } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
import '@/assets/css/dashboard/index.scss'
import '@/assets/css/finance/index.scss'
import '@/assets/css/fx/fx.scss'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const TIPS = <div>
    <p>新增分销员：统计范围内，成为分销员的数量。</p>
    <p>绑定客户数：统计范围内，分销员绑定的客户数量。</p>
    <p>订单数：统计范围内，产生佣金收益的订单数量之和，包含自购订单（开启自购返佣时会结算佣金）剔除结算前已退款的订单。</p>
    <p>分销订单金额：统计范围内，佣金收益订单，实付商品金额之和</p>
    <p>退款金额：统计范围内，佣金收益订单中产生退款商品金额之和。</p>
    <p>发放佣金：统计范围内，已结算佣金的总和。</p>
</div>

class FxAnalyse extends React.Component{
    state = {
        searchForm:{},
        page:1
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {

    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(values.time_type == 1){
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM') : ''
                    values.end_time = ''
                    if(!values.start_time){
                        message.error('请选择时间');
                        return ;
                    }
                }else if(values.time_type == 2){
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                    values.end_time = ''
                    if(!values.start_time){
                        message.error('请选择时间');
                        return ;
                    }
                }else {
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                    values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                }
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={``}>
                <Form className={'search-form'}>
                    <div className={`content-head mgb15`}>
                        <div className={`clearfix`}>
                            <FormItem label={`时间筛选`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('time_type')(
                                        <Select style={{width:150}} placeholder={'请选择'} className={`mgr10`}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>月汇总</Select.Option>
                                            <Select.Option value={2}>日汇总</Select.Option>
                                            <Select.Option value={3}>自定义时间</Select.Option>
                                        </Select>
                                    )
                                }
                                {
                                    getFieldDecorator('time')(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                            </FormItem>
                        </div>

                        <div className={`clearfix`}></div>
                    </div>
                    <div className={`data-title mgt15`}>
                    <span className={`fi-title`}>
                        数据概览
                        <Tooltip placement="topLeft" title={TIPS}>
                            <Icon type="question-circle"  className={`mgl5`} />
                        </Tooltip>
                    </span>
                        <span className={`fi-sub-title mgl15`}></span>
                    </div>
                    <div className={`flex justify-start align-center mgt15`}>
                        <div className={`finance-data-cont`}>
                            <div className="data-finance-item no-border">
                                <div className="txt1">
                                    <div className="item-txt">
                                        新增分销员（人）
                                    </div>
                                </div>
                                <div className="chart-num">10</div>
                            </div>
                            <div className="data-finance-item no-border">
                                <div className="txt1">
                                    <div className="item-txt">
                                        绑定客户数（人）
                                    </div>
                                </div>
                                <div className="chart-num">20</div>
                            </div>
                            <div className="data-finance-item no-border">
                                <div className="txt1">
                                    <div className="item-txt">
                                        订单数（笔）
                                    </div>
                                </div>
                                <div className="chart-num">20</div>
                            </div>
                            <div className="data-finance-item no-border">
                                <div className="txt1">
                                    <div className="item-txt">
                                        分销订单金额（元）
                                    </div>
                                </div>
                                <div className="chart-num">20</div>
                            </div>
                            <div className="data-finance-item no-border">
                                <div className="txt1">
                                    <div className="item-txt">
                                        退款金额（元）
                                    </div>
                                </div>
                                <div className="chart-num">20</div>
                            </div>
                            <div className="data-finance-item no-border">
                                <div className="txt1">
                                    <div className="item-txt">
                                        发放佣金（元）
                                    </div>
                                </div>
                                <div className="chart-num">20</div>
                            </div>

                        </div>

                    </div>
                    <div className={`data-title mgt15`}>
                        <span className={`fi-title`}>
                            指标变化趋势
                        </span>
                        <span className={`fi-sub-title mgl15`}></span>
                    </div>
                    <div className={`data-check-inner mgt15`}>
                        <FormItem label={`展示信息`}>
                            <Checkbox value={1} className={`mgl20`}>新增分销员(人)</Checkbox>
                            <Checkbox value={2} className={`mgl20`}>绑定客户数(人)</Checkbox>
                            <Checkbox value={3} className={`mgl20`}>订单数(笔)</Checkbox>
                            <Checkbox value={4} className={`mgl20`}>分销订单金额(元)</Checkbox>
                            <Checkbox value={4} className={`mgl20`}>退款金额(元)</Checkbox>
                            <Checkbox value={4} className={`mgl20`}>发放佣金(元)</Checkbox>
                        </FormItem>
                    </div>
                </Form>

            </div>
        )
    }
}
export default Form.create({})(withRouter(FxAnalyse))
