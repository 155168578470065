import React from 'react'
import { HashRouter,Route,Switch } from 'react-router-dom'
import Login from '@/views/login/Login'
import Register from '@/views/login/Register'
import Forget from '@/views/login/Forget'
import Design from '@/views/design/add'
import CustomForm from '@/views/custom-form/add'
import AuthRouter from '@/views/auth/AuthRouter'
import Layout from '@/views/layout/index'

const Router = () => {
    return(
        <HashRouter>
            <Switch>
                <Route component={Login} exact path='/login' ></Route>
                <Route component={Register} exact path='/register' ></Route>
                <Route component={Forget} exact path='/forget' ></Route>
                <Route component={Design} exact path='/design/add/:type?/:id?' ></Route>
                <Route component={CustomForm} exact path='/custom-form/add/:id?' ></Route>
                <AuthRouter path="/" component={Layout}></AuthRouter>
            </Switch>
        </HashRouter>
    )
}
export default Router;
