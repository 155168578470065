import React from 'react'
import { Form, Input, Radio, DatePicker, Select, Tooltip, Icon, Checkbox, Button, Table, Pagination, Divider } from 'antd'
import { withRouter } from 'react-router-dom'
import { itemDetail } from '@/api/item'
import { storeLists } from '@/api/store'
import { timeDiscountSave, timeDiscountDetail } from '@/api/time-discount'
import { labelGroupList} from '@/api/user'
import Loading from '@/components/Loading'
import Gallery from '@/components/Gallery/Gallery'
import moment from 'moment'
import message from '@/utils/message'
import SetItemDiscount from './components/set-item-discount'
import MarketItemPicker from './components/market-item-picker'
const FormItem = Form.Item;

class AddTimeDiscount extends React.Component {
    state = {
        step: 1,
        formData: '',
        isLoading: false,
        storeLists: [],
        tagsLists:[],
        columns: [
            {
                title: '商品名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '零售价',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <p>&yen;{rowData.price}</p>
                    )
                }
            },
            {
                title: '活动库存',
                dataIndex: 'can_sale_stock',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.can_sale_stock || `未设置`}</div>
                    )
                }
            },
            {
                title: '优惠后折扣价',
                dataIndex: 'show_price',
                render: (text, rowData, index) => {

                    if(!rowData.show_price){
                        return  <div>未设置</div>
                    }

                    return (
                        <div>&yen;{rowData.show_price}</div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: '25%',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleEdit(rowData, index) }}>设置活动折扣</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDelete(rowData, index) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        currentItem: ''
    }
    componentDidMount() {
        this.fetchStore();
        this.fetchTages()
        if (this.props.match.params.id) {
            this.fetchData()
        }
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
            .finally(() => {
            })
    }
    fetchTages = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        labelGroupList(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.list.data,arr=[];

                    lists.forEach(item=>{
                        let ids=item.label_value.split(','),
                            names=item.label_value_name.split(',');
                        ids.forEach((val,i)=>{
                            arr.push({
                                id:item.id+'_'+ids[i],
                                name:item.group_name+'-'+names[i]
                            })
                        })
                    })


                    this.setState({
                        tagsLists: arr
                    })
                }
            })
            .finally(() => {
            })
    }
    fetchData = () => {
        let sendData = {}
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading: true
        })
        timeDiscountDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let detail = res.data.detail,
                        goods_list = detail.goods_list || [];
                    detail.suit_stores = detail.suit_store_ids;
                   
                    if (detail.limit_buy > 0) {
                        detail.limit_buy_type = 1
                    } else {
                        detail.limit_buy_type = 0
                    }
                    goods_list.forEach((item, index) => { })

                    this.setState({
                        formData: detail,
                        tableData: goods_list
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }
    handleItem = () => {
        let tableData = this.state.tableData || []
        this.MarketItemPicker.show(res => {
            if (res) {
                let lists = [];
                res.forEach((item, index) => {
                    tableData.forEach((child, childKey) => {
                        if (child.id == item.id) {
                            item = child
                        }
                    })
                    lists.push(item)
                })
                this.setState({
                    tableData: lists
                })
            }
        })
    }
    handleEdit = (rowData, index) => {
        let sendData = {},
            tableData = this.state.tableData || [];
        for (var i in rowData['goods_extend']) {
            if (!rowData[i]) {
                rowData[i] = rowData['goods_extend'][i]
            }
        }
        delete rowData['goods_extend']
        this.setState({
            currentItem: rowData
        }, () => {
            this.SetItemDiscount.show(result => {
                if (result.item.sku_list && result.item.sku_list.length) {
                    let tmp_stock = 0,
                        sku_price_arr = []
                    result.item.sku_list.forEach((sku, skuKey) => {
                        if (sku.is_join) {
                            sku.stock = sku.stock ? Number(sku.stock) : 0
                            sku.sales_price = sku.sales_price ? sku.sales_price : 0
                            tmp_stock = tmp_stock + sku.stock;
                            if (parseFloat(sku.sales_price)) {
                                sku_price_arr.push(parseFloat(sku.sales_price))
                            } else {
                                sku_price_arr.push(0)
                            }
                        }
                    })
                    result.item.stock = tmp_stock;
                    if (sku_price_arr.length) {
                        if (Math.min(...sku_price_arr) == Math.max(...sku_price_arr)) {
                            result.item.sales_price = `${Math.max(...sku_price_arr).toFixed(2)}`

                        } else {
                            result.item.sales_price = `${Math.min(...sku_price_arr).toFixed(2)}~${Math.max(...sku_price_arr).toFixed(2)}`
                        }
                    }

                }
                rowData = result.item;
                rowData.sort = result.sort || ``;
                rowData.basic_sales = result.basic_sales || ``;
                rowData.basic_tobuy_num = result.basic_tobuy_num || ``;
                rowData.can_sale_stock = result.item.stock;
                rowData.show_price = result.item.sales_price;
                tableData[index] = rowData;
                this.setState({
                    tableData
                })
            })
        })
    }
    handleNextStep = () => {
        let formData = this.state.formData || {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                for (var i in values) {
                    formData[i] = values[i]
                }
                formData.start_time = moment(formData.start_time).format('YYYY-MM-DD HH:mm:ss')
                formData.end_time = moment(formData.end_time).format('YYYY-MM-DD HH:mm:ss')
                if(values.label_ids){
                    formData.label_ids=values.label_ids.join(',');
                }
                this.setState({
                    formData,
                    step: 2
                })
            }
        })

    }
    handlePrevStep = () => {
        this.setState({
            step: 1
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {},
            goods_list = [],
            itemLists = this.state.tableData;
        itemLists.forEach(child => {
            child.sku_list && child.sku_list.forEach(sku => {
                sku.is_join = sku.is_join ? 1 : 0;
                sku.price = sku.sales_price;
                sku.discount = sku.discount || ``;
                sku.cut_price = sku.cut_price || ``;
                sku.time_discount_goods_sku_id = sku.time_discount_goods_sku_id || ``;
            })
        })
        sendData = formData;
        goods_list = itemLists.map((good) => {
            return {
                id: good.id,
                discount: good.discount || ``,
                cut_price: good.cut_price || ``,
                price: good.sales_price || ``,
                stock: good.stock || ``,
                basic_tobuy_num: good.basic_tobuy_num || '',
                basic_sales: good.basic_sales || ``,
                sort: good.sort || ``,
                sku_list: good.sku_list || [],
                time_discount_goods_id: good.time_discount_goods_id || ``
            }
        })
        sendData.goods_list = goods_list;
        if (sendData.can_discount == 0) {
            sendData.can_use_coupon = 0;
            sendData.can_use_points= 0;
        }
        if (sendData.limit_buy < 0) {
            message.error('限购数量不能小于0')
            return;
        }
        this.setState({
            isLoading: true
        })
        timeDiscountSave(sendData)
            .then(res => {
                if (res.code == 1) {
                    message.success('保存成功')
                        .then(() => {
                            this.props.history.push({
                                pathname: `/time-discount/lists`
                            })
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })

    }
    handleDelete = (rowData, index) => {
        let tableData = this.state.tableData;
        tableData.splice(index, 1);
        this.setState({
            tableData
        })
    }
    render() {
        let data = this.state.formData || {}
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 15
            }
        }

        return (
            <div className={`shadow-radius`}>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <MarketItemPicker
                    onRef={e => { this.MarketItemPicker = e }}
                    selectIds={this.state.tableData.map(item => { return Number(item.id) })}
                    multiple={true}
                ></MarketItemPicker>
                <SetItemDiscount
                    onRef={e => { this.SetItemDiscount = e }}
                    item={this.state.currentItem}
                ></SetItemDiscount>
                <Form {...formItemLayout} className={'model-form'}>
                    {
                        this.state.step == 1
                            ?
                            <div>
                                <div className={`msg-title mgb15`}>基本信息</div>
                                <div className="flex">
                                    <div className={`flex1`}>

                                        <FormItem label={'活动名称'}>
                                            {getFieldDecorator('name', {
                                                initialValue: data && data.name,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请输入活动名称'
                                                    }
                                                ]
                                            })(<Input
                                                placeholder={'请输入活动名称，限20字符'}
                                                maxLength={20}
                                                suffix={<span>{data.name && data.name.length ? data.name.length : 0}/20</span>}
                                            />
                                            )}
                                        </FormItem>
                                        <FormItem label={'活动时间'} required={true}>
                                            <div className={'form-group'}>
                                                {
                                                    getFieldDecorator('start_time', {
                                                        initialValue: data.start_time ? moment(data.start_time, 'YYYY-MM-DD HH:mm:ss') : null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择开始时间'
                                                            }
                                                        ]
                                                    })(
                                                        <DatePicker className={'mgr5'} showTime></DatePicker>
                                                    )
                                                }
                                                至
                                                {
                                                    getFieldDecorator('end_time', {
                                                        initialValue: data.end_time ? moment(data.end_time, 'YYYY-MM-DD HH:mm:ss') : null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择结束时间'
                                                            }
                                                        ]
                                                    })(
                                                        <DatePicker className={'mgl5 mgr5'} showTime></DatePicker>
                                                    )
                                                }
                                            </div>
                                        </FormItem>
                                        <FormItem
                                            label={
                                                <span>
                                                    活动门店
                                                    <Tooltip placement={`right`} title={`所有售有选中商品的门店全部参加活动，后续创建的售有选中商品的门店自动加入该活动。`}>
                                                        <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                                    </Tooltip>
                                                </span>
                                            }
                                        >
                                            {getFieldDecorator('suit_store_type', {
                                                initialValue: data.suit_store_type || 1,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择营业类型'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    <Radio value={1}>全部门店</Radio>
                                                    <Radio value={2}>指定门店</Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                        {
                                            getFieldValue('suit_store_type') == 2
                                                ?
                                                <FormItem label={'选择门店'}>
                                                    {getFieldDecorator('suit_stores', {
                                                        initialValue: data.suit_stores || [],
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择适用门店'
                                                            }
                                                        ]
                                                    })(
                                                        <Select mode="multiple" style={{ width: '300px' }} placeholder="请选择">
                                                            {
                                                                this.state.storeLists.map(store => (
                                                                    <Select.Option
                                                                        value={store.id}
                                                                        key={store.id}
                                                                    >{store.name}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    )}
                                                </FormItem>
                                                :
                                                ''
                                        }
                                        <FormItem label={'限购方式'}>
                                            {getFieldDecorator('limit_buy_type', {
                                                initialValue: data.limit_buy_type || 0,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择限购方式'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    <Radio value={0}>不限购</Radio>
                                                    <Radio value={1}>
                                                        每人每种商品限购
                                                        {
                                                            getFieldDecorator('limit_buy', {
                                                                initialValue: data.limit_buy || 0,
                                                            })(
                                                                <Input
                                                                    style={{ width: '80px' }}
                                                                    className={`mgl5 mgr5`}
                                                                    disabled={getFieldValue('limit_buy_type') == 0}
                                                                />
                                                            )
                                                        }
                                                        件
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                        <FormItem label={'优惠叠加'}>
                                            {getFieldDecorator('can_discount', {
                                                initialValue: data.can_discount || 0,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择优惠叠加'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    <Radio value={0}>不叠加</Radio>
                                                    <Radio value={1}>可叠加</Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                        {
                                            getFieldValue('can_discount') == 1
                                                ?
                                                <FormItem label={'可叠加优惠'}>
                                                    {
                                                        getFieldDecorator('can_use_coupon', {
                                                            initialValue: data.can_use_coupon ?? 1,
                                                            valuePropName: 'checked'
                                                        })(
                                                            <Checkbox className={`mgr5`}>
                                                                优惠券
                                                                <Tooltip placement={`right`} title={`可用优惠券不含兑换券`}>
                                                                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                                                </Tooltip>
                                                            </Checkbox>
                                                        )
                                                    }

                                                <FormItem >
                                                    {
                                                        getFieldDecorator('can_use_points', {
                                                            initialValue: data.can_use_points ?? 1,
                                                            valuePropName: 'checked'
                                                        })(
                                                            <Checkbox className={`mgr5`}>  积分抵扣 </Checkbox>
                                                        )
                                                    }

                                                    
                                                </FormItem>
                                                </FormItem>
                                                :
                                                ''
                                        }
                                        <FormItem label={'参与设置'}>
                                            {getFieldDecorator('join_set', {
                                                initialValue: data.join_set || 1,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择参与设置'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    <Radio value={1}>所有用户</Radio>
                                                    <Radio value={2}>
                                                        仅限新用户
                                                        <Tooltip placement={`right`} title={`新用户指店铺内没有订单的客户，或仅拥有以下类型订单的客户：全额退款订单、已关闭的待付款订单。`}>
                                                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                                        </Tooltip>
                                                    </Radio>
                                                    <Radio value={3}>标签用户购买</Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                        {
                                            getFieldValue('join_set') == 3
                                                ?
                                                <FormItem label={'标签用户'}>
                                                    {getFieldDecorator('label_ids', {
                                                        initialValue: data.label_ids_ary || [],
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择标签'
                                                            }
                                                        ]
                                                    })(
                                                        <Select mode="multiple" style={{ width: '300px' }} placeholder="请选择">
                                                            {
                                                                this.state.tagsLists.map(store => (
                                                                    <Select.Option
                                                                        value={store.id}
                                                                        key={store.id}
                                                                    >{store.name}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    )}
                                                </FormItem>
                                                :
                                                ''
                                        }
                                        <div className={`txtCenter`}>
                                            <Button type={`primary`} onClick={this.handleNextStep}>下一步</Button>
                                        </div>
                                    </div>
                                    <div style={{ width: 300 }} >
                                        <img src="https://img.cxkoo.com/chengxuan/1/2023-01-04/d8a37a9fdc7ea4f8d91306584f9731a2.png" alt="" width={200} />
                                    </div>
                                </div>
                            </div>


                            :
                            null
                    }
                    {
                        this.state.step == 2
                            ?
                            <div>
                                <div className={`msg-title mgb15`}>添加活动商品</div>
                                <div className={``}>
                                    <Button type={`primary`} onClick={this.handleItem}>添加商品</Button>
                                    <div className={'content-body mgt10'}>
                                        <Table
                                            size={`middle`}
                                            columns={this.state.columns}
                                            dataSource={this.state.tableData}
                                            loading={this.state.tableLoading}
                                            rowKey={(record, index) => index}
                                            pagination={false}
                                        ></Table>
                                    </div>
                                    <div className={'clearfix mgt10'}>

                                    </div>
                                </div>
                                <div className={`txtCenter`}>
                                    <Button type={`default`} className={`mgr15`} onClick={this.handlePrevStep}>上一步</Button>
                                    <Button type={`primary`} onClick={this.saveData}>保存</Button>
                                </div>
                            </div>
                            :
                            null
                    }





                </Form>
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }

}

export default Form.create({})(withRouter(AddTimeDiscount))

