import $axios from '@/axios/axios.js'

export function timeDiscountLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/timeDiscount/lists`,
        method:'post',
        data
    })
}

export function timeDiscountSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/timeDiscount/save`,
        method:'post',
        data
    })
}

export function timeDiscountDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/timeDiscount/detail`,
        method:'post',
        data
    })
}

export function timeDiscountDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/timeDiscount/delete`,
        method:'post',
        data
    })
}

export function timeDiscountEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/timeDiscount/end`,
        method:'post',
        data
    })
}

export function timeDiscountActivity(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/timeDiscount/selectActivity`,
        method:'post',
        data
    })
}
