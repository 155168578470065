import React from 'react'
import { Modal, Input, Button, Table, Pagination, Tag } from 'antd'
import { Link } from 'react-router-dom'
import {socialSelectLists} from '@/api/group-solitaire'
import CX from '@/utils/CX'

export default class GroupSolitairePicker extends React.Component {

    state = {
        title: '选择拼团',
        visible: false,
        modalLoading: false,
        tableLoading: false,
        columns: [
            {
                title: '活动名称',
                dataIndex: '1',
                width: 80,
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.name}</p>
                    )
                }
            },
            {
                title: '浏览次数',
                dataIndex: '2',
                width:90,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.pv || 0}</p>
                    )
                }
            },
            {
                title: '跟团人数',
                dataIndex: '3',
                width:90,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.group_num || 0 }</p>
                    )
                }
            },
            
            {
                title: '活动时间',
                dataIndex: '4',
                width:320,
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.start_time} 至 {rowData.end_time} </p>
                    )
                }
            },
            {
                title: '活动状态',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <Tag color={rowData.status == 1 ? 'orange' : rowData.status == 2 ? 'green' : ''}> {rowData.status == 1 ? '未开始' : rowData.status == 2 ? '进行中' : '已结束'}</Tag>
                    )
                }
            },
        ],
        tableData: [],
        selectRows: [],
        selectedRowKeys: [],
        page: 1,
        name: '',
        per_page: 10,
        total: 0,
        callback: null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    show = (callback) => {
        let selectIds = this.props.selectIds || [],
            items = this.props.items || [];
        this.setState({
            visible: true,
            callback: callback ? callback : null,
            selectedRowKeys: selectIds,
            selectRows: items
        }, () => {
            this.fetchData();
        })
    }
    close = () => {
        this.setState({
            name: '',
            page: 1,
            visible: false
        })
    }
    fetchData = () => {
        let sendData = {
            page: this.state.page,
            name: this.state.name,
            per_page: this.state.per_page
        }
        this.setState({
            tableLoading: true
        })
        socialSelectLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let tableData = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.renderSelectRows(tableData)
                    this.setState({
                        tableData, per_page, total
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }
    renderSelectRows = lists => {
        let selectedRowKeys = this.state.selectedRowKeys,
            selectRows = this.state.selectRows;
        if (selectedRowKeys.length) {
            for (var k in lists) {
                if (selectedRowKeys.includes(lists[k]['id'])) {
                    let selectRowsIds = selectRows.map(item => { return item.id })
                    if (!selectRowsIds.includes(lists[k]['id'])) {
                        selectRows.push(lists[k])
                    }
                }
            }
            this.setState({
                selectRows
            })
        }
    }
    handleOk = () => {
        let callback = this.state.callback;
        if (typeof callback == 'function') {
            callback(this.state.selectRows)
        }
        this.close();
    }
    handleCancel = () => {
        this.close()
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }
    getInputValue = e => {
        this.setState({
            name: e.target.value
        })
    }

    render() {
        let multiple = !!this.props.multiple
        const rowSelection = {
            type: multiple ? 'checkbox' : 'radio',
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {

                if (selectedRowKeys.length == selectedRows.length) {
                    this.setState({
                        selectRows: selectedRows,
                        selectedRowKeys
                    })
                } else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr, selectedRowKeys);
                    for (var i in diffArr) {
                        for (var j in oldList) {
                            if (oldList[j]['id'] == diffArr[i]) {
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows: tmpList,
                        selectedRowKeys
                    })


                }
            }
        }
        return (
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    maskClosable={false}
                    confirmLoading={this.state.modalLoading}
                    centered={true}

                >
                    <div className={'title clearfix'}>
                        <div className={'fr btn-group'}>
                            <Input value={this.state.name} onChange={this.getInputValue} placeholder={'输入活动名称'} style={{ 'width': '180px' }} />
                            <Button type={'primary'} icon={'search'} style={{ 'verticalAlign': 'top' }} onClick={this.fetchData}>搜索</Button>
                        </div>
                    </div>
                    <div className={'mgt20'}>
                        <Table
                            size={`middle`}
                            rowSelection={rowSelection}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={record => record.id}
                            pagination={false}
                        >
                        </Table>
                        {
                            this.state.tableData.length
                                ?
                                <div className={'clearfix mgt10'}>
                                    <div className={'fl'}>
                                        <p>已选择{this.state.selectedRowKeys.length}样活动</p>
                                    </div>
                                    <div className={'fr'}>
                                        <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    </div>
                                </div>
                                :
                                ''
                        }

                    </div>

                </Modal>
            </div>
        )

    }

}


