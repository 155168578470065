import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, DatePicker, Tag, Drawer, Divider } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { userLists, userLabelList, userViewers } from '@/api/user'
import { adminDetail } from '@/api/admin'
import message from '@/utils/message'
import UserDetail from './components/UserDetail'
import UserUpload from './components/UserUpload'
import CreateUser from './components/CreateUser'
import ViewersList from './components/ViewersList'
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker } = DatePicker

class UserLists extends React.Component {
    state = {
        columns: [
            {
                title: '客户信息',
                dataIndex: 'logo',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url} alt="" width={60} />
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} onClick={() => { this.handleEdit(rowData) }}>
                                    {rowData.name || `游客`}
                                    <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                    {
                                        rowData.second_name
                                            ?
                                            <span>（{rowData.second_name}）</span>
                                            :
                                            null
                                    }
                                </div>
                                <div className={`lh25`}>{rowData.mobile || `暂无手机号`}</div>
                                <div className={`lh25`}>
                                    <Tag>普通会员</Tag>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '客户资产',
                dataIndex: 'name',
                width: 140,
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>持卡：{rowData.card_num}张</p>
                            <p className={`lh25`}>余额：{rowData.wallet_money}元</p>
                            <p className={`lh25`}>积分：{rowData.points}</p>
                        </div>
                    )
                }
            },
            {
                title: '卡明细',
                dataIndex: 'price',
                width: '300px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {rowData.time_card_list && rowData.time_card_list.map(val => {
                                return <p className={`lh25`} key={val.id}><Link to={`/user/user-times-card/${rowData.id}/${val.id}`} target={`_blank`}>{val.card_name}</Link> 有效期：{val.end_date || '永久有效'}</p>
                            })}

                        </div>
                    )
                }
            },
            {
                title: '邀请人',
                dataIndex: 'first_user',
                width: 160,
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>姓名：{rowData.first_user?.name || '-'}</p>
                            <p className={`lh25`}>手机：{rowData.first_user?.mobile || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title: '客户归属',
                dataIndex: 'stock2',
                width: 160,
                render: (text, rowData, index) => {

                    let store_name = '总部'
                    if (rowData.store) {
                        store_name = rowData.store.name
                    }

                    return (
                        <div>
                            <p className={`lh25`}>{store_name}</p>
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: 100,
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} style={{ width: '80px' }} onClick={() => { this.handleEdit(rowData) }}>查看详情</Button>
                            {
                                [6, 9].includes(this.state.version_id) ?
                                    <>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => { this.handleViewers(rowData) }}>观演人信息</Button>

                                    </> : null
                            }

                        </div>
                    )
                }
            },
        ],
        searchForm: {
            user_id: '',
            name: '',
            mobile: '',
            source: '',
            start_time: '',
            end_time: ''
        },
        tableData: [],
        tableLoading: false,
        drawerVisible: false,
        page: 1,
        total: 0,
        per_page: 10,
        currentRow: '',
        tags_list: [],
        version_id: '',
    }

    componentDidMount() {
        this.fetchData()
        this.fetchLabel()
        this.fetchAdmin()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading: true
        })
        userLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.user_lists.data,
                        total = res.data.user_lists.total,
                        per_page = res.data.user_lists.per_page;
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }
    fetchAdmin = () => {
        let sendData = {};
        adminDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let shop = res.data.shop;
                    this.setState({
                        version_id: shop.version_id
                    })
                }
                this.fetchData()
            })
    }
    fetchLabel = () => {
        userLabelList({}).then(res => {
            if (res.code == 1) {
                let tags_list = res.data.list
                this.setState({
                    tags_list
                })
            }
        })

    }
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page: 1
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        this.props.history.push({
            pathname: `/store/add`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })

    }
    handleUpload = () => {
        this.userUpload.show(res => {
            this.fetchData();
        })
    }

    handleEdit = rowData => {
        this.setState({
            drawerVisible: true,
            currentRow: rowData
        })
    }
    handleViewers = rowData => {
        let sendData = {
            user_id: rowData.id
        }
        userViewers(sendData).then(res => {
            if (res.code == 1) {
                console.log(res)
                this.ViewersList.show(res.data.list)

            }
        })
    }
    handleStatus = () => {
        message.info('敬请期待')
    }
    handleShare = () => {
        message.info('敬请期待')
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible: false,
            currentRow: null
        })
        this.fetchData();
    }

    handleAddUser = () => {
        this.userCreate.show(() => {
            this.fetchData()
        })
    }

    handleUpload = () => {
        this.userUpload.show(res => {
            this.fetchData();
        });
    }


    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectRows: selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return (
            <div className={`shadow-radius`}>
                <div className={`content-msg mgb10`}>
                    <p>
                        当前店铺共有<span className={`ft-bold`}>{this.state.total}</span>位客户
                    </p>
                </div>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户姓名'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户姓名' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'手机号码'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'客户来源'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('source')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={1}>手工添加</Select.Option>
                                            <Select.Option value={2}>微信小程序</Select.Option>
                                            <Select.Option value={3}>外部导入</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        {/*<div className={`clearfix`}>*/}
                        {/*<FormItem label={'客户身份'} className={'fl'}>*/}
                        {/*{*/}
                        {/*getFieldDecorator('type')(*/}
                        {/*<Select style={{width:200}} placeholder={'请选择'}>*/}
                        {/*<Select.Option value={1}>非会员</Select.Option>*/}
                        {/*<Select.Option value={2}>普通会员</Select.Option>*/}
                        {/*</Select>*/}
                        {/*)*/}
                        {/*}*/}
                        {/*</FormItem>*/}
                        {/*<FormItem label={'客户归属'} className={'mgl20 fl'}>*/}
                        {/*{*/}
                        {/*getFieldDecorator('belong')(*/}
                        {/*<Select style={{width:200}} placeholder={'请选择'}>*/}
                        {/*<Select.Option value={1}>总部</Select.Option>*/}
                        {/*<Select.Option value={2}>门店1</Select.Option>*/}
                        {/*<Select.Option value={3}>门店2</Select.Option>*/}
                        {/*<Select.Option value={4}>门店3</Select.Option>*/}
                        {/*</Select>*/}
                        {/*)*/}
                        {/*}*/}
                        {/*</FormItem>*/}
                        {/*</div>*/}
                        <div className={`clearfix`}>
                            <FormItem label={'成为客户时间'} className={' fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>

                            <FormItem label={'客户标签'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('label_id')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.tags_list.map(item => {
                                                    return <Select.Option value={item.extra_id}>{item.extra_name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`} >
                            <FormItem className={'fl'} >
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                {/* <Button type={"default"} className={'mgl10'} onClick={() => {this.handleUpload()}}>客户导入</Button> */}
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAddUser} icon={'plus'}>新增客户</Button>
                    <Button type={'default'} onClick={() => { this.handleUpload() }} icon={'upload'} className={`mgl10`}>客户导入</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`客户详情`}
                            closable={true}
                            width={`88%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={this.handleDrawerClose}
                            headerStyle={{ borderBottom: '0px' }}
                            bodyStyle={{ padding: '0px' }}
                        >
                            <UserDetail
                                onRef={e => { this.UserDetail = e }}
                                user_id={this.state.currentRow ? this.state.currentRow.id : ''}
                            ></UserDetail>
                        </Drawer>
                        :
                        null
                }

                <UserUpload onRef={e => { this.userUpload = e }} />

                <CreateUser onRef={e => { this.userCreate = e }} />
                <ViewersList onRef={e => { this.ViewersList = e }} />
            </div>
        )
    }

}

export default Form.create({})(withRouter(UserLists))
