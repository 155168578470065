import React from 'react'
import { Form, Input, Button, Table, DatePicker, Radio, Icon, Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists, storeDelete } from '@/api/store'
import { newGiftSave, newGiftDetail } from  '@/api/new-gift'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
import moment from 'moment'
import './newGift.scss'
const FormItem = Form.Item;
class AddNewGift extends React.Component{
    state = {
        formData:'',
        isLoading:false,
        storeLists:[],
        columns: [
            {
                title: '券名称',
                dataIndex: 'name',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '券类型',
                dataIndex: 'coupon_type',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title: '赠送数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <Input
                            style={{width:'100px'}}
                            placeholder={`张`}
                            value = { rowData.num || `` }
                            onChange={ e => { this.handleNumChange(e,rowData,index) } }
                        />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => {this.handleDelItem(rowData, index)}}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentItem:''


    }

    componentDidMount() {
        if( this.props.match.params.id ){
            this.fetchData()
        }
    }
    fetchData = () => {
        let sendData = {}
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading:true
        })
        newGiftDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail;
                    detail.present_coupon_data = detail.present_coupon_list;
                    this.setState({
                        formData:detail
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    handleCoupon = () => {
        let formData = this.state.formData || {}
        this.CouponPicker.show(res => {
            if(res.length > 10){
                res.length = 10;
            }
            formData.present_coupon_data = res;
            this.setState({
                formData
            })
        })
    }
    handleNumChange = (e,rowData,index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data,
            val = e.target.value;
        present_coupon_data[index]['num'] = val;
        this.setState({
            formData
        })

    }
    handleDelItem = (rowData,index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data;
        present_coupon_data.splice(index,1);
        this.setState({
            formData
        })
    }
    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            formData = this.state.formData || {};
        formData[name] = hex;
        this.setState({
            formData
        })

    }
    handleResetColor = e => {
        let name = e.currentTarget.dataset.name || ``,
            color = e.currentTarget.dataset.color || ``,
            formData = this.state.formData || {};
        formData[name] = color;
        this.setState({
            formData
        })
    }
    chooseImg = (e,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'bg_img':
                    formData[name] = imgs[0];
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    delImg = (e,keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if(typeof formData[name] == 'string'){
            formData[name] = ''
        }else {
            formData[name].splice(index,1);
        }
        this.setState({
            formData
        })
    }


    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                console.log(values);
                values.start_at = moment(values.start_at).format('YYYY-MM-DD HH:mm:ss');
                values.end_at = moment(values.end_at).format('YYYY-MM-DD HH:mm:ss');
                sendData = values;
                sendData.present_coupon_data = formData.present_coupon_data.map(item => { return { id:item.id,num:item.num } });
                sendData.id = formData.id || '';
                sendData.bg_img = formData.bg_img || '';
                sendData.button_color = formData.button_color || '#FFD40D';
                sendData.font_color = formData.font_color || '#FA4C30';
                if(sendData.is_default == 1){
                    sendData.bg_img = '';
                }

                this.setState({
                    isLoading:true
                })
                newGiftSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/new-gift/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })



            }
        })


    }


    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return(
            <div className={'shadow-radius'} style={{ paddingBottom:'250px' }}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <CouponPicker
                    onRef={ e => { this.CouponPicker = e } }
                    multiple={true}
                    selectIds={ data.present_coupon_data && data.present_coupon_data.map(item => {return Number(item.id)})}
                ></CouponPicker>
                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`msg-title mgb15`}>基本信息</div>
                    <FormItem label={'活动名称'}>
                        {getFieldDecorator('name', {
                            initialValue: data && data.name,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入活动名称'
                                }
                            ]
                        })(<Input
                            placeholder={'请输入活动名称，限20字符'}
                            maxLength={20}
                            suffix={<span>{data.name && data.name.length ? data.name.length : 0}/20</span>}
                            />
                        )}
                    </FormItem>
                    <FormItem label={'活动时间'} required={true}>
                        <div className={'form-group'}>
                            {
                                getFieldDecorator('start_at',{
                                    initialValue: data.start_at ? moment(data.start_at, 'YYYY-MM-DD HH:mm:ss') : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择开始时间'
                                        }
                                    ]
                                })(
                                    <DatePicker className={'mgr5'} showTime></DatePicker>
                                )
                            }
                            至
                            {
                                getFieldDecorator('end_at',{
                                    initialValue: data.end_at ? moment(data.end_at, 'YYYY-MM-DD HH:mm:ss') : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择结束时间'
                                        }
                                    ]
                                })(
                                    <DatePicker className={'mgl5 mgr5'} showTime></DatePicker>
                                )
                            }
                        </div>
                    </FormItem>
                    <FormItem label={'发放方式'}>
                        {getFieldDecorator('present_type', {
                            initialValue: data.present_type  || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择发放方式'
                                }
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={1}>直接发放</Radio>
                                <Radio value={2}>需领取</Radio>
                            </Radio.Group>
                        )}
                        {
                            getFieldValue('present_type') == 1
                                ?
                                <span className={`fi-help-text`}>在指定时间，给新客户直接将优惠券发放到账；客户进入店铺时，会弹窗提示</span>
                                :
                                <span className={`fi-help-text`}>活动期间内，新客户任意时刻进入店铺将会弹窗提示领券，未领取的下次进入仍然提示，仅可领取一次，已领取的下次进入不再提示</span>

                        }
                    </FormItem>
                    <FormItem label={'选择优惠'}>
                        {
                            getFieldDecorator('present_coupon_data',{
                                initialValue:data.present_coupon_data || []
                            })(
                                <Button type={`primary`} onClick={ this.handleCoupon } >选择优惠</Button>
                            )
                        }
                    </FormItem>
                    {
                        data.present_coupon_data && data.present_coupon_data.length
                            ?
                            <FormItem label={` `} colon={false}>
                                <Table
                                    size={`middle`}
                                    columns={this.state.columns}
                                    dataSource={data.present_coupon_data}
                                    rowKey={record => record.id}
                                    style={{ width:'700px' }}
                                ></Table>
                            </FormItem>
                            :
                            null
                    }
                    <div className={`msg-title mgb15`}>页面样式</div>
                    <div className={`clearfix new-gift`} style={{ position:'relative' }}>
                        <div className={`preview`}>
                            <div className={`preview-title`}>当前样式预览：</div>
                            <div className={`preview-cont`}>
                                <div className={`mob`}>
                                    <div className={`mob-overlay`}></div>
                                    <div className={`coupon-bg`} style={{ backgroundImage:`url(${ getFieldValue('is_default') == 2 && data.bg_img ?  data.bg_img : 'https://img.cxkoo.com/chengxuan/1/2022-01-19/e22aac6ec84f1d38163cae51fbd18992.png'  })` }}>
                                        <div className={`coupon-inner-img`}></div>
                                        <div className={`coupon-inner-btn`} style={{ color: data.font_color || '#FA4C30',backgroundColor:data.button_color || '#FFD40D'  }}>
                                            立即领取
                                        </div>
                                        <div className={`coupon-inner-close`}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormItem label={`按钮颜色`}>
                            <div className={`flex`}>
                                <ColorPicker
                                    onRef={e => { this.ColorPicker = e }}
                                    color={data.button_color || '#FFD40D'}
                                    callback={ (color) => { this.handleColorChange(color,'button_color') } }
                                ></ColorPicker>
                                <span className={`fi-link mgl10`} onClick={ this.handleResetColor } data-name="button_color" data-color="#FFD40D">重置</span>
                            </div>
                        </FormItem>
                        <FormItem label={`字体颜色`}>
                            <div className={`flex`}>
                                <ColorPicker
                                    onRef={e => { this.ColorPicker = e }}
                                    color={data.font_color || '#FA4C30'}
                                    callback={ (color) => { this.handleColorChange(color,'font_color') } }
                                ></ColorPicker>
                                <span className={`fi-link mgl10`} onClick={ this.handleResetColor } data-name="font_color" data-color="#FA4C30">重置</span>
                            </div>
                        </FormItem>
                        <FormItem label={`页面背景`}>
                            {getFieldDecorator('is_default', {
                                initialValue: data.is_default || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择'
                                    }
                                ]
                            })(
                                <Radio.Group className={`mgt10`}>
                                    <Radio value={1} className={``}>默认背景</Radio>
                                    <Radio value={2} className={``}>自定义图片</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>

                        {
                            getFieldValue('is_default') == 2
                                ?
                                <FormItem label={'自定义图片'}>
                                    {getFieldDecorator('bg_img', {
                                        initialValue: data.bg_img || ``,
                                    })(
                                        <div className={'clearfix'}>
                                            {
                                                data.bg_img
                                                    ?
                                                    <PictureCard
                                                        onRef={ e => { this.PictureCard = e } }
                                                        imgs={ data.bg_img }
                                                        onDel={ e => { this.delImg(e,'bg_img') } }
                                                    ></PictureCard>
                                                    :
                                                    <ImgAdd
                                                        onRef={ e => { this.ImgAdd = e } }
                                                        onClick={ e => { this.chooseImg(e,'bg_img') } }
                                                    ></ImgAdd>
                                            }
                                        </div>
                                    )}
                                    <span className={'fi-help-text'}>建议尺寸：600x800像素，单张不超过500k</span>
                                </FormItem>
                                :
                                null
                        }
                        <FormItem label={` `} colon={false}>
                            <Button type={`primary`} onClick={ this.saveData }>立即保存</Button>
                        </FormItem>
                    </div>


                </Form>

                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(AddNewGift))
