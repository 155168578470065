const ljw = {
    //Access-Control-Allow-Origin: https://foo.example
    // Access-Control-Allow-Methods: POST, GET, OPTIONS
    // Access-Control-Allow-Headers: X-PINGOTHER, Content-Type
    // Access-Control-Allow-Credentials: true
    createElement:(vNode) => {
        let tag = vNode.tag || '',
            attrs = vNode.attrs || {},
            children = vNode.children || [];
        if(!tag){ return null }
        let ele = document.createElement(tag);
        for(var attrName in attrs){
            if(attrs.hasOwnProperty(attrName)){
                ele.setAttribute(attrName,attrs[attrName])
            }
        }
        if(typeof children === 'string'){
            ele.appendChild(document.createTextNode(children))
        }else {
            children.forEach((child,childKey) => {
                ele.appendChild(ljw.createElement(child))
            })
        }

        return ele
    },
    updateChildren:(vnode,newVnode) => {
        let children = vnode.children || [],
            newChildren = newVnode.children || [];
        children.forEach((child,childKey) => {
            let newChildrenVnode = newChildren[childKey];
            if(child.tag === newChildrenVnode.tag){
                ljw.updateChildren(child,newChildrenVnode)
            }else {
                //需要更新
                //ljw.replaceVnode(child,newChildrenVnode);
            }
        })
    },
    bubbleSort:( arr = [] ) => {
         for(let i = 0; i < arr.length; i++){
             for(let j = 0; j < arr.length - i - 1; j++){
                 if(arr[j] > arr[j + 1]){
                     [arr[j],arr[j + 1]] = [arr[j + 1], arr[j]]
                 }
             }
         }
         return arr;
    },
    quickSort:(arr = []) => {
        const len = arr.length;
        if(len <= 1){ return arr }
        let midIndex = parseInt(len / 2),
            midVal = arr.splice(midIndex,1)[0],
            left = [],
            right = [];
        arr.forEach((item,index) => {
            item > midVal ? right.push(item) : left.push(item)
        })
        return [ ...ljw.quickSort(left), midVal, ...ljw.quickSort(right) ];
    },
    mergeSort:(arr = []) => {
        const len = arr.length;
        if(len <= 1){ return arr }
        let midIndex = parseInt(len / 2),
            left = ljw.mergeSort(arr.slice(0,midIndex)),
            right = ljw.mergeSort(arr.slice(midIndex));
        return ljw.merge(left,right);
    },
    merge:( arr1 = [],arr2 = [] ) => {
        let len1 = arr1.length,
            len2 = arr2.length,
            index1 = 0,
            index2 = 0,
            res = [];
        while (index1 < len1 && index2 < len2){
            res.push(arr1[index1] < arr2[index2] ? arr1[index1++] : arr2[index2++])
        }
        return [ ...res,...(index1 === len1 ? arr2.slice(index2) : arr1.slice(index1)) ]
    },
    selectSort:(arr = []) => {
        for(let i = 0; i < arr.length; i++ ){
            let k = i;
            for(let j = i+1; j < arr.length; j++){
                if(arr[j] < arr[k]){
                    k = j;
                }
            }
            [ arr[i],arr[k] ] = [ arr[k],arr[i] ]
        }
        return arr;
    },
    insertSort:(arr = []) => {
        for(let i = 1; i < arr.length; i++ ){
            let j = i,
                val = arr[i];
            while (j > 0 && arr[j] < arr[j-1]){
                [ arr[j],arr[j-1] ] = [ arr[j-1],arr[j] ];
                j--;
            }
            arr[j] = val;
        }
        return arr;
    },
    //希尔排序
    shellSort:(arr = []) => {
        for( let i = Math.floor((0 + arr.length -1) / 2); i >= 1; i = Math.floor(i / 2) ){
            for(let j = i; j < arr.length; j++){
                while (arr[j - i] > arr[j] ){
                    [ arr[j - i], arr[j] ] = [ arr[j], arr[j-i] ];
                    j = j - i;
                }
            }
        }
        return arr
    },
    /**
     * This is just a simple version of deep copy
     * Has a lot of edge cases bug
     * If you want to use a perfect deep copy, use lodash's _.cloneDeep
     * @param {Object} source
     * @returns {Object}
     */
    deepClone:(source = {}) => {
        if(!source && typeof source !== 'object'){
            throw new Error(` error arguments `)
        }
        const targetObj = source.constructor === Array ? [] : {}
        Object.keys(source).forEach((keys) => {
            if(source[keys] && typeof source[keys] === 'object'){
                targetObj[keys] = ljw.deepClone(source[keys])
            }else {
                targetObj[keys] = source[keys]
            }
        })
        return targetObj
    },
    /**
     * 节流: n 秒内只运行一次，若在 n 秒内重复触发，只有一次执行
     * 防抖: n 秒后在执行该事件，若在 n 秒内被重复触发，则重新计时
     * @param func
     * @param wait
     * @param immediate
     * @return {function(...[*]=): *}
     */
    debounce:(func, wait, immediate) => {
        let timeout, args, context, timestamp, result;

        const later = function() {
            // 据上一次触发时间间隔
            const last = +new Date() - timestamp;

            // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
                if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout) context = args = null;
                }
            }
        };

        return function(...args) {
            context = this;
            timestamp = +new Date();
            const callNow = immediate && !timeout;
            // 如果延时不存在，重新设定延时
            if (!timeout) timeout = setTimeout(later, wait);
            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }

            return result;
        };
    },
    throttled:(fn,delay = 500) => {
        let timer = null
        return function (...args) {
            if (!timer) {
                timer = setTimeout(() => {
                    fn.apply(this, args)
                    timer = null
                }, delay);
            }
        }

    }



}
export default ljw
