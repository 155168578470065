/**
 * 页面设置组件
 * @type 0
 * @return Object
 */

import React from 'react'
import { Form, Input, Radio, Icon ,Button} from 'antd'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import Gallery from '@/components/Gallery/Gallery'
import PropTypes from "prop-types";

const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 17 }}


class tpl_ctrl_type0 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }
    state = {
        moduleData:{}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    changeTitle = e => {
        let moduleData = this.state.moduleData,
            value = e.target.value;
        moduleData.title = value;
        this.context.updateDesign(moduleData)
    }
    handleBgChange = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.backgroundColor = hex;
        this.context.updateDesign(moduleData)
    }
    handleStyleChange = e => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.NavigationBackgroundStyle = value
        this.context.updateDesign(moduleData)
    }
    handleNavBgColor = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.NavigationBackgroundColor = hex;
        this.context.updateDesign(moduleData)
    }
    handleTitleStyle = e => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.titleColorStyle = value;
        this.context.updateDesign(moduleData)
    }
    changeShareTitle = e => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.shareTitle = value;
        this.context.updateDesign(moduleData)
    }
    chooseImg = () => {
        let moduleData = this.state.moduleData;
        this.Gallery.show(res => {
            if(res){
                moduleData.shareImg = res[0]
                this.context.updateDesign(moduleData)
            }
        })
    }
    delImg = e => {
        e.stopPropagation();
        let moduleData = this.state.moduleData;
        moduleData.shareImg = ''
        this.context.updateDesign(moduleData)
    }
    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData[name]=color
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <div className={`ctrl-right-title`}>页面设置</div>
                    <Form  className={`cx-ctrl-panel`} >
                        <div className={`cx-ctrl-panel--title`}>基本设置</div>
                        <div className={`cx-ctrl-panel--box`}>
                            <FormItem label={`页面名称`}>
                                <Input type={'text'} placeholder={`页面名称`} value={moduleData.title} onChange={this.changeTitle} />
                            </FormItem>
                            <FormItem label={`背景颜色`} { ...ctrlItemLayout }>
                                <ColorPicker  style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.backgroundColor} callback={ this.handleBgChange } ></ColorPicker>
                                <Button type="link" onClick={()=>this.handleResetColor('backgroundColor','#ffffff')}>重置</Button>
                            </FormItem>
                            {/*<FormItem label={`标题栏颜色`}>*/}
                                {/*<Radio.Group value={moduleData.NavigationBackgroundStyle} onChange={ this.handleStyleChange }>*/}
                                    {/*<Radio value={1}>自定义颜色</Radio>*/}
                                    {/*<Radio value={0}>跟随主题色</Radio>*/}
                                {/*</Radio.Group>*/}
                            {/*</FormItem>*/}
                            {
                                moduleData.NavigationBackgroundStyle == 1
                                    ?
                                    <FormItem label={`标题栏颜色`} { ...ctrlItemLayout }>
                                        <ColorPicker  style={{display:"inline-block"}} onRef={e => { this.ColorPicker1 = e }} color={moduleData.NavigationBackgroundColor} callback={ this.handleNavBgColor } ></ColorPicker>
                                        <Button type="link" onClick={()=>this.handleResetColor('NavigationBackgroundColor','#ffffff')}>重置</Button>
                                    </FormItem>
                                    :
                                    null
                            }

                            <FormItem label={`标题字体颜色`}>
                                <Radio.Group value={moduleData.titleColorStyle} onChange={ this.handleTitleStyle }>
                                    <Radio value={0}>黑色</Radio>
                                    <Radio value={1}>白色</Radio>
                                </Radio.Group>
                            </FormItem>
                            <FormItem label={`分享标题`}>
                                <Input type={'text'} placeholder={`分享标题`} value={moduleData.shareTitle} onChange={ this.changeShareTitle }  />
                            </FormItem>
                            <FormItem label={`分享图片`}>
                                <div className={'clearfix'}>
                                    <div className={'img-add'} onClick={this.chooseImg} data-name="shareImg">
                                        {
                                            moduleData.shareImg
                                                ?
                                                <div>
                                                    <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={this.delImg} data-name={'shareImg'}  />
                                                    <img src={moduleData.shareImg} alt=""/>
                                                </div>
                                                :
                                                '+'
                                        }
                                    </div>
                                </div>
                                <span className={'fi-help-text'}>建议上传尺寸：500*400像素</span>
                            </FormItem>
                        </div>

                    </Form>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type0)
