import React from 'react'
import {withRouter} from 'react-router-dom'
import {Form} from 'antd'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import '@/assets/css/miniprogram/user-center.scss'

const FormItem = Form.Item

class UserStyle2 extends React.Component {
    state = {}

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }

    fetchData = () => {

    }

    render() {
        let data = this.props.current || {}
        const {getFieldDecorator} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return (
            <div className={`user-style2`}>
                <div className='mine-cont'>
                    <div className={`mine-head ${ data.showMemberCard ?'':'hide-card' }`}  style={{ backgroundImage:`url(${ data.bgImg || 'https://img.cxkoo.com/chengxuan/1/2021-10-20/01ec0cd4af17f4cc63a4c0b4e98d99a2.png' })` }}>
                        <div className="status-bar"></div>
                        <div className="head-cont">
                            <div className="user-cont flex justify-between align-center">
                                <div className="user-info">
                                    <div className="avatar"></div>
                                    <div className="info-desc">
                                        <div className="nick-name">登录/注册</div>
                                        <div className="level">普通会员</div>
                                    </div>

                                </div>
                                <div className="right"></div>
                            </div>
                            {
                                data.showMemberCard 
                                    ?
                                    <div className="member-card">
                                        <div className="left">
                                            <img
                                                src="https://img.cxkoo.com/chengxuan/1/2021-10-20/fd317797e2d786e68cc6a29409b7a789.png"></img>
                                            <div className="txt">开通会员享受专属权益</div>
                                        </div>
                                        <div className="right">
                                            <div className="right-btn">了解详情</div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="mine-content">
                        <div className="cx-mine-panel move-top">
                            <div className="flex justify-between align-center h100">
                                <div className="top-item">
                                    <div className="txt1">0</div>
                                    <div className="txt2">余额</div>
                                </div>
                                <div className="top-item">
                                    <div className="txt1">0</div>
                                    <div className="txt2">积分</div>
                                </div>
                                <div className="top-item">
                                    <div className="txt1">0</div>
                                    <div className="txt2">卡</div>
                                </div>
                                <div className="top-item">
                                    <div className="txt1">0</div>
                                    <div className="txt2">优惠券</div>
                                </div>
                                <div className="top-item">
                                    <div className="txt1">0</div>
                                    <div className="txt2">成长值</div>
                                </div>
                            </div>
                        </div>
                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">我的订单</div>
                                <div className="right">
                                    <div className="txt">全部</div>
                                    <div className="arrow"></div>
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-between align-center">
                                <div className="order-item">
                                    <div className="item-img icon1">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待付款</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon2">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待发货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon3">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待提货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon4">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待收货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon5">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">退款/售后</div>
                                </div>
                            </div>
                        </div>
                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">快捷服务</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-start align-center flex-wrap">
                                {
                                    data.userCenter && data.userCenter.map((item,index) => (
                                        item.isShow
                                            ?
                                            <div className="tool-item" key={item.id}>
                                                <img src={ item.icon }></img>
                                                <div className="txt">{item.name}</div>
                                            </div>
                                            :
                                            null
                                    ))
                                }
                            </div>
                        </div>
                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">我要推广</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-start align-center flex-wrap">
                                {
                                    data.shareTool && data.shareTool.map((child,childKey) => (
                                        child.isShow
                                            ?
                                            <div className="tool-item" key={child.id}>
                                                <img src={ child.icon }></img>
                                                <div className="txt">{ child.name }</div>
                                            </div>
                                            :
                                            null

                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(UserStyle2))

