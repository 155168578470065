import React, { Component } from 'react'
import { Form, Input, Button, Select, TimePicker, InputNumber, Radio, Table, Tooltip, Tag, TreeSelect, Checkbox, message } from 'antd';
import moment from 'moment'

const FormItem = Form.Item;


class ChapterPrize extends Component {
    state = {
        couponLists: [],
        formData: {

        },
        columns: [
            {
                title: '奖品类型',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.name}</p>
                    )
                }
            },
            {
                title: '奖品内容',
                dataIndex: '2',

                render: (text, rowData, index) => {
                    if (rowData.id == 1) {
                        return (
                            <Select disabled={this.props.is_disabled} style={{ width: 200 }} value={rowData.coupon_id} placeholder={'请选择'} onChange={this.handleChange('coupon_id', index)}>
                                {this.props.couponLists.map(val => {
                                    return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                })}
                            </Select>
                        )

                    } else if (rowData.id == 2) {
                        return (
                            <InputNumber
                                style={{ width: 200 }}
                                min={0}
                                value={rowData.integral_num}
                                precision={0}
                                placeholder={'请输入赠送积分数'}
                                onChange={this.handleChange('integral_num', index)}
                                disabled={this.props.is_disabled}
                            />
                        )
                    } else {
                        return (
                            <InputNumber
                                style={{ width: 200 }}
                                min={1}
                                value={rowData.wechat_money}
                                precision={2}
                                placeholder={'请输入赠送的微信零钱'}
                                onChange={this.handleChange('wechat_money', index)}
                                disabled={this.props.is_disabled}
                            />
                        )
                    }
                }
            },
            {
                title: '点击红包个数（个）',
                dataIndex: '3',

                render: (text, rowData, index) => {
                    return (
                        <div>
                            <InputNumber
                                style={{ width: 80 }}
                                min={1}
                                precision={0}
                                value={rowData.min}
                                onChange={this.handleChange('min', index)}
                                disabled={true}
                            />
                            ~
                            <InputNumber
                                style={{ width: 80 }}
                                min={rowData.min}
                                precision={0}
                                value={rowData.max}
                                onChange={this.handleChange('max', index)}
                                max={this.props.red_num}
                                disabled={(index === 2) || this.props.is_disabled}
                            />
                        </div>
                    )
                }
            },
            {
                title: '奖品个数',
                dataIndex: '4',

                render: (text, rowData, index) => {
                    return (
                        <InputNumber
                            style={{ width: 120 }}
                            min={0}
                            precision={0}
                            placeholder={'请输入奖品个数'}
                            value={rowData.prize_num}
                            onChange={this.handleChange('prize_num', index)}

                        />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '5',
                width: "80px",
                render: (text, rowData, index) => {
                    return (
                        <Button disabled={this.props.is_disabled} type={"link"} size={"small"} onClick={() => { this.handleDele(index, rowData.id) }}>删除</Button>
                    )
                }
            },
        ],

    }

    componentDidMount() {

    }



    handleTime = name => {
        return (time, timeString) => {
            let content = this.props.content

            if (name == 'start_time' && content['end_time']) {
                if (this.time_to_sec(timeString) > this.time_to_sec(content['end_time'])) {
                    return message.error('开始时间不能大于结束时间')
                }
            }

            if (name == 'end_time' && content['start_time']) {
                if (this.time_to_sec(timeString) < this.time_to_sec(content['start_time'])) {
                    return message.error('结束时间不能小于开始时间')
                }
            }



            content[name] = timeString
            this.props.updatePanes(content)
        }
    }

    time_to_sec = (time) => {
        if (time !== null) {
            var s = "";
            var hour = time.split(":")[0];
            var min = time.split(":")[1];
            var sec = time.split(":")[2];
            s = Number(hour * 3600) + Number(min * 60) + Number(sec);
            return s;
        }
    }

    handleCheck = id => {
        return () => {

            let { content, content: { prize_type, tableData }, red_num } = this.props

            if (!red_num) {
                return message.error('请先填写红包个数')
            }

            if (prize_type.includes(id)) { return }


            let table = {}
            if (id == 1) {
                table = {
                    name: "赠送优惠券",
                    coupon_id: undefined
                }
            } else if (id == 2) {
                table = {
                    name: "赠送积分",
                    integral_num: undefined
                }
            } else {
                table = {
                    name: "赠送微信零钱",
                    wechat_money: undefined
                }
            }

            table.min = 0
            table.max = 0

            if (tableData.length == 0) {
                table.min = 1
                table.max = red_num
            } else {
                let prev = tableData[tableData.length - 1].max
                if (prev < red_num) {
                    table.min = prev + 1
                    table.max = red_num
                } else {
                    return message.error("可点击红包数量值已为最小")
                }
            }

            table.id = id
            table.prize_num = 0

            tableData.push(table)
            prize_type.push(id)
            content.prize_type = prize_type
            content.tableData = tableData
            this.props.updatePanes(content)
        }
    }

    handleChange = (name, index) => {
        return val => {
            let content = this.props.content, tableData = content.tableData

            if (name == 'max') {
                if ((tableData.length == 2 && index == 0) || (tableData.length == 3 && index == 1)) {
                    if (val > this.props.red_num - 1) { return }
                    tableData[index + 1]['min'] = val + 1
                } else if (tableData.length == 3 && index == 0) {
                    if (val > tableData[index + 1].max - 1) { return }
                    tableData[index + 1]['min'] = val + 1
                }
            }
            tableData[index][name] = val
            content.tableData = tableData
            this.props.updatePanes(content)
        }
    }



    handleDele = (index, id) => {
        let content = this.props.content, tableData = content.tableData, prize_type = content.prize_type
        prize_type = prize_type.filter(val => val != id)
        tableData.splice(index, 1)
        if (tableData.length == 1) {
            tableData[0].min = 1
        } else if (tableData.length == 2) {
            tableData[0].min = 1
            tableData[1].min = tableData[0].max + 1
        }
        content.prize_type = prize_type
        content.tableData = tableData
        this.props.updatePanes(content)
    }



    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const { is_disabled, content: { start_time, end_time, prize_type, tableData } } = this.props

        return (
            <Form {...formItemLayout} className={'model-form '} >
                <div className={`msg-title mgb15`}>基本选项</div>

                <FormItem label={'场次时间'} required={true}>
                    <TimePicker
                        style={{ width: 200 }}
                        format="HH:mm:ss"
                        placeholder={'开始时间'}
                        value={start_time ? moment(start_time, 'HH:mm:ss') : undefined}
                        onChange={this.handleTime('start_time')}
                    />
                    至
                    <TimePicker
                        style={{ width: 200 }}
                        format="HH:mm:ss"
                        placeholder={'结束时间'}
                        value={end_time ? moment(end_time, 'HH:mm:ss') : undefined}
                        onChange={this.handleTime('end_time')}
                    />
                </FormItem>

                <FormItem label={'奖品类型'} required={true}>

                    <Button type={prize_type.includes(1) ? 'primary' : 'default'} icon={prize_type.includes(1) ? 'check' : 'none'} onClick={this.handleCheck(1)} disabled={is_disabled}>赠送优惠券</Button>
                    <Button className='mgl20' type={prize_type.includes(2) ? 'primary' : 'default'} icon={prize_type.includes(2) ? 'check' : 'none'} onClick={this.handleCheck(2)} disabled={is_disabled}>赠送积分</Button>
                    <Button className='mgl20' onClick={this.handleCheck(3)} type={prize_type.includes(3) ? 'primary' : 'default'} icon={prize_type.includes(3) ? 'check' : 'none'} disabled={is_disabled}>赠送微信零钱</Button>
                </FormItem>

                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={tableData}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>

            </Form>
        )
    }
}

export default Form.create({})(ChapterPrize)
