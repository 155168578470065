import React, { Component } from 'react'
import { Modal, Pagination,Table ,Tag,Button,message} from 'antd';
import { gradeApi} from '@/api/educational'
import { studentAddGrade} from '@/api/student'

class StudentAddGrade extends Component {
    state = {
        columns: [
            {
                title: '班级名称',
                dataIndex: 'name',
                
                render: (text, rowData, index) => {
                    return (
                        <div >
                              <Button type="link" onClick={()=>this.handleDetail(rowData.id) }> {rowData.name}</Button>
                        </div>
                    )
                }
            },
            {
                title: '课程类目',
                dataIndex: 'course_category',
            
                render: (text, rowData, index) => {
                    return (
                        <div>
                           {rowData.course_category && rowData.course_category.name}
                        </div>
                    )
                }
            },
            {
                title: '上课地点',
                dataIndex: 'store',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <span>{rowData.store && rowData.store.name }</span>
                        </div>
                    )
                }
            },
            {
                title: '班级人数/总人数',
                dataIndex: 'charge_type',
                render: (text, rowData, index) => {
                  
                    return (
                        <div>
                            {`${rowData.student_num}/${rowData.grade_num}`}
                        </div>
                    )
                }
            },
            {
                title: '已上/排课总数',
                dataIndex: 'basic_sales',
                render: (text, rowData, index) => {
                    return (
                        <div>
                        {`0/${rowData.table_num}`}
                    </div>
                    )
                }
            },
            {
                title: '开班时间',
                dataIndex: 'start_date',
               
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {rowData.start_date}
                        </div>
                    )
                }
            },
            {
                title: '结班时间',
                dataIndex: 'end_date',
                render: (text, rowData, index) => {
                    return (
                        <div>
                              {rowData.end_date}
                        </div>
                    )
                }
            },
            {
                title: '班级状态',
                dataIndex: 'status',
                render: (text, rowData, index) => {
                    let status_color, status_text
                    if (rowData.status == 1) {
                        status_text = "待开班"
                        status_color = "red"
                    } else if (rowData.status == 2) {
                        status_text = "开班中"
                        status_color = "green"
                    } else {
                        status_text = "已结班"
                        status_color = ""
                    }
                    return (
                        <Tag color={status_color}>{status_text}</Tag>
                    )
                }
            }
        ],
        visible: true,
        tableData:[],
        tableLoading:false,
        page:1,
        per_page:10,
        total:0,
        selectedRowKeys:[],
        modalLoading:false
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        gradeApi(sendData)
            .then(res => {
                
                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                  
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    handleOk = () => {
        let grade_ids=this.state.selectedRowKeys,sendData={}
        sendData.grade_ids=grade_ids
        sendData.student_id=this.props.student_id
        this.setState({
            modalLoading:true
        })
        studentAddGrade(sendData).then(res=>{
            this.setState({
                modalLoading:false
            })
            if(res.code==1){
                message.success(res.msg)
               
                if(typeof(this.props.onSuccess) == 'function'){
                    this.props.onSuccess()
                    this.handleCancel()
                }
            }
          
        })
    }

    handleCancel = () => {
        this.props.onClose()
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }

    render() {

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            }
        }

        return (
            <Modal
                title="添加班级"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                confirmLoading={this.state.modalLoading}
                width={1000}
            >
                <div className={'grade-container'} >
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        rowSelection={rowSelection}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

            </Modal>
        )
    }
}


export default StudentAddGrade