import $axios from '@/axios/axios.js'

export function liveGroup(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/live/group`,
        method:'post',
        data
    })
}

export function liveGroupSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/live/saveGroup`,
        method:'post',
        data
    })
}

export function liveGroupDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/live/deleteGroup`,
        method:'post',
        data
    })
}

export function liveSaveTimes(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/live/saveTimes`,
        method:'post',
        data
    })
}

export function liveSaveDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/live/detail`,
        method:'post',
        data
    })
}

export function liveLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/live`,
        method:'post',
        data
    })
}

export function liveSendNotice(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/live/sendNotice`,
        method:'post',
        data
    })
}
