import $axios from '@/axios/axios.js'

export function formLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/question-template`,
        method:'post',
        data
    })
}

export function formSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/question-template/save`,
        method:'post',
        data
    })
}

export function formDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/question-template/detail`,
        method:'post',
        data
    })
}

export function formDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/question-template/delete`,
        method:'post',
        data
    })
}

export function formSubmitUser(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/question-template/userLog`,
        method:'post',
        data
    })
}

export function submitUserDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/question-template/destroy`,
        method:'post',
        data
    })
}

export function submitUserExport(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/question-template/export`,
        method:'get',
        params
    })
}
