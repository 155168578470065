import React, { Component } from 'react'
import { Modal, Button ,Table,Pagination} from 'antd';
import { jizanRecord } from "@/api/collect-likes.js"

export default class LikesDetail extends Component {
    state={
        columns:[
            {
                title: '客户信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className='flex align-center'>
                            <img src={rowData.head_img_url} width={60} alt="" />
                            <div className='mgl10'>
                                <p className='fi-link'>{rowData.name || ''}
                                    <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                </p>
                                <p>{rowData.mobile || `暂无手机号`}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '点赞时间',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.zan_time} </p>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        per_page:10,
        total:0
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData=()=>{
        this.setState({
            tableLoading:true
        })
        jizanRecord({join_id:this.props.join_id}).then(res=>{
            if(res.code==1){
                let { total, per_page, data } = res.data.list
                this.setState({
                    total, per_page, tableData: data
                })
            }

        }).finally(()=>{
            this.setState({
                tableLoading:false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData();
        })
    }


    handleCancel = () => {
        this.props.close()
    }

    render() {
        return (
            <Modal
                title="点赞明细"
                visible={true}
                onOk={this.handleCancel}
                onCancel={this.handleCancel}
                width={700}
                footer={[<Button type="primary" key='ok' onClick={this.handleCancel}>确认</Button>]}
            >
                <div>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}
