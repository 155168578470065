/**
 * 扫码下单组件
 * @type 10
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip} from 'antd'
import PropTypes from "prop-types";
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import Gallery from '@/components/Gallery/Gallery'
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 17 }}

class tpl_ctrl_type10 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }
    state = {
        moduleData:{},
        itemStyleGroup:[
            {
                name:'一行一个',
                style:1,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/0286fef6460e27c1e3a8f796991e2380.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/845a95002c6d0adfda6928801d74ce7a.png']
            },
            {
                name:'一行二个',
                style:2,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/6ba92c930723d0fcbcf775bda369adb1.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/f1dcbd0b7694e2e1cff713b1d0c3aa4d.png']
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)

    }
    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }
    chooseImg = (item,index) => {
        let moduleData = this.state.moduleData;
        this.Gallery.show(res => {
            if(res){
                moduleData.content.dataset[index]['pic'] = res[0];
                this.context.updateDesign(moduleData)
            }
        })

    }
    changeTxt = (e,item,index) => {
        let moduleData = this.state.moduleData,
            value = e.target.value;
        moduleData.content.dataset[index]['title'] = value;
        this.context.updateDesign(moduleData)
    }
    delImg = (item,index) => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这块内容吗？`,
            okType:`danger`,
            onOk:() => {
                moduleData.content.dataset.splice(index,1);
                this.context.updateDesign(moduleData)
            }
        })
    }
    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <div className={`ctrl-right-title`}>行业组件</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`组件样式`}>
                                    <ul className={`template-box`}>
                                        {
                                            this.state.itemStyleGroup.map((item,index) => (
                                                <Tooltip effect="dark" placement="bottom" title={ item.name } key={index}>
                                                    <li className={`temp ${ moduleData.content.style == item.style ? 'selected' : '' }`} onClick={ () => { this.handleItemStyle(item.style) } }>
                                                        {
                                                            moduleData.content.style == item.style
                                                                ?
                                                                <img src={ item.imgs[0] } className={`img`} alt=""/>
                                                                :
                                                                <img src={ item.imgs[1] } className={`img`} alt=""/>

                                                        }
                                                    </li>
                                                </Tooltip>
                                            ))
                                        }
                                    </ul>
                                </FormItem>
                                <FormItem label={`背景颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.bgColor} callback={ (color) => { this.handleColorChange(color,'bgColor') } } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('bgColor','#ffffff')}>重置</Button>
                                </FormItem>
                                <FormItem label={`文字颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.txtColor} callback={ (color) => { this.handleColorChange(color,'txtColor') } } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('txtColor','#000000')}>重置</Button>
                                </FormItem>
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>内容设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                {
                                    moduleData.content.dataset.map((item,index) => (
                                        <div className={`cx-img-list`} key={index}>
                                            <div className={`cx-img-item clearfix`}>
                                                <FormItem label={`业务内容`} {...ctrlItemLayout}>
                                                    <Input type={`text`} value={item.title} onChange={ (e) => { this.changeTxt(e,item,index) } } placeholder={`请选择`}  />
                                                </FormItem>
                                                <FormItem label={`业务图片`} {...ctrlItemLayout}>
                                                    <div className={'clearfix'}>
                                                        <div className={'img-add'} onClick={() => { this.chooseImg(item,index) }} data-name="pic">
                                                            {
                                                                item.pic
                                                                    ?
                                                                    <div>
                                                                        <img src={item.pic} alt=""/>
                                                                    </div>
                                                                    :
                                                                    '+'
                                                            }
                                                        </div>
                                                    </div>
                                                </FormItem>
                                            </div>
                                            {
                                                index > 0
                                                    ?
                                                    <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delImg(item,index) }} data-name={'pic'}  />
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))
                                }
                                <Button type={`primary`} className={`w100`} onClick={this.handleAdd} disabled={moduleData.content.dataset.length >= 5} >添加图片</Button>
                            </div>
                        </div>
                    </Form>

                </div>
                :
                null
        )

    }

}

export default Form.create({})(tpl_ctrl_type10)
