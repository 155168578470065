import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Select, Button, Table, Pagination, Divider, Tag, Modal, DatePicker } from 'antd'
import { drawRecord, suitStores, changeEndAt, changeStatus } from '@/api/coupon'
import { acceptRefund } from '@/api/order'
import moment from 'moment'
import Loading from '@/components/Loading'
import message from '@/utils/message';
import CouponFitStore from './components/CouponFitStore'
import ModalForm from './components/ModalForm'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class CouponRecord extends React.Component{
    state = {
        selectRows:[],
        selectedRowKeys:[],
        searchForm:{
            coupon_name:'',
            coupon_type:'',
            status:'',
            user_name:'',
            user_mobile:'',
            start_time:'',
            end_time:''
        },
        columns:[
            {
                title:'优惠券名称',
                dataIndex:'coupon_name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.coupon_name || '--'}</div>
                    )
                }
            },
            {
                title:'券类型',
                dataIndex:'coupon_type',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </div>
                    )
                }
            },
            {
                title:'领取人',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p className={`lh25`}>{rowData.name}</p>
                            <p className={`lh25`}>{rowData.mobile}</p>
                        </div>

                    )
                }
            },
            {
                title:'领取时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'领取方式',
                dataIndex:'draw_way',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.draw_way }} className={`lh25`}></div>
                    )
                }
            },
            {
                title:'优惠券来源',
                dataIndex:'source_name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.source_name}</div>
                    )
                }
            },
            {
                title:'适用门店',
                dataIndex:'store',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            {
                                rowData.suit_store_type == 1
                                    ?
                                    '全部'
                                    :
                                    <span className={`fi-link`} onClick={ () => { this.handleSuitStore(rowData) } }>{ rowData.suit_store_name }</span>
                            }
                        </div>
                    )
                }
            },
            {
                title:'有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            { rowData.status == 1 ? <Tag color={`orange`}>未使用</Tag> : null }
                            { rowData.status == 2 ? <Tag color={`green`} >已使用</Tag> : null }
                            { rowData.status == 3 ? <Tag >已过期</Tag> : null }
                            { rowData.status == 4 ? <Tag >已退款</Tag> : null }
                            { rowData.status == 5 ? <Tag >已作废</Tag> : null }
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            {
                                rowData.status == 1
                                    ?
                                    <span>
                                        {
                                            rowData.pay_ment > 0
                                                ?
                                                <span>
                                                    <Button type={"link"} size={"small"} onClick={() => {this.handleRefund(rowData)}}>退款</Button>
                                                    <Divider type={`vertical`}></Divider>
                                                </span>
                                                :
                                                null
                                        }

                                        <Button type={"link"} size={"small"} onClick={() => {this.handleTime(rowData)}}>修改有效期</Button>
                                        <Divider type={`vertical`}></Divider>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleClose(rowData)}}>作废</Button>
                                        <Divider type={`vertical`}></Divider>
                                    </span>
                                    :
                                    null
                            }
                            {
                                rowData.status == 3
                                    ?
                                    <span>
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleActive(rowData)}}>激活</Button>
                                    </span>
                                    :
                                    null
                            }
                            {
                                [2,4,5].includes(rowData.status)
                                    ?
                                    <p className={`lh25`}>-</p>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        suitStores:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow:{},
        modalTitle:'',
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        drawRecord(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        per_page = res.data.list.per_page,
                        total = res.data.list.total;
                    this.setState({
                        tableData:lists,
                        per_page,
                        total,
                        selectRows:[],
                        selectedRowKeys:[]
                    })
                  
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;

                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleSuitStore = (rowData) => {
        let sendData = {}
        sendData.id = rowData.coupon_id || ``;
        this.setState({
            isLoading:true
        })
        suitStores(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store_list.data;
                    this.setState({
                        suitStores:lists
                    },() => {
                        this.CouponFitStore.show()
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleRefund = (rowData) => {
        let sendData = {};
        sendData.order_sn = rowData.order_sn || ``;
        Modal.confirm({
            title:`是否对优惠券进行退款？`,
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                acceptRefund(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleTime = (rowData) => {
        let sendData = {}
        this.setState({
            modalTitle:'修改有效期',
            currentRow:rowData
        },() => {
            this.ModalForm.show(res => {
                if(res){
                    sendData.end_at = res.end_at || ``;
                    sendData.user_coupon_ids = [rowData.id];
                    this.setState({ isLoading:true })
                    changeEndAt(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({ isLoading:false })
                        })
                }
            })
        })
    }
    handleClose = (rowData) => {
        let sendData = {}
        Modal.confirm({
            title:'是否作废这张优惠券？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                sendData.status = 0;
                sendData.user_coupon_ids = [rowData.id];
                changeStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleActive = (rowData) => {
        let sendData = {}
        this.setState({
            modalTitle:'激活',
            currentRow:rowData
        },() => {
            this.ModalForm.show(res => {
                if(res){
                    sendData.end_at = res.end_at || ``;
                    sendData.user_coupon_ids = [rowData.id];
                    sendData.status = 1;
                    this.setState({ isLoading:true })
                    changeStatus(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({ isLoading:false })
                        })
                }
            })
        })


    }
    patchTime = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.warning('请选择优惠券')
            return ;
        }
        sendData.user_coupon_ids = selectRows.map(item => {
            return item.id
        })
        this.setState({
            modalTitle:'修改有效期',
            currentRow:{}
        },() => {
            this.ModalForm.show(res => {
                if(res){
                    sendData.end_at = res.end_at || ``;
                    this.setState({ isLoading:true })
                    changeEndAt(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({ isLoading:false })
                        })
                }
            })
        })
    }
    patchOn = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.warning('请选择优惠券')
            return ;
        }
        sendData.user_coupon_ids = selectRows.map(item => {
            return item.id
        })
        this.setState({
            modalTitle:'激活',
            currentRow:{}
        },() => {
            this.ModalForm.show(res => {
                if(res){
                    sendData.end_at = res.end_at || ``;
                    sendData.status = 1;
                    this.setState({ isLoading:true })
                    changeStatus(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({ isLoading:false })
                        })
                }
            })
        })

    }
    patchOff = () => {
        let sendData = {},
            selectRows = this.state.selectRows;
        if(!selectRows.length){
            message.warning('请选择优惠券')
            return ;
        }
        sendData.user_coupon_ids = selectRows.map(item => {
            return item.id
        })
        Modal.confirm({
            title:'是否批量作废这些优惠券？',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                sendData.status = 0;
                changeStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        const rowSelection = {
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        return(
            <div >
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>优惠券名称</span>} className={'fl'} >
                                {
                                    getFieldDecorator('coupon_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入优惠券名称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'优惠券类型'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('coupon_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>满减券</Select.Option>
                                            <Select.Option value={2}>折扣券</Select.Option>
                                            <Select.Option value={3}>兑换券</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'优惠券状态'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('status')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>未使用</Select.Option>
                                            <Select.Option value={2}>已使用</Select.Option>
                                            <Select.Option value={3}>已过期</Select.Option>
                                            <Select.Option value={4}>已退款</Select.Option>
                                            <Select.Option value={5}>已作废</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;&emsp;领取人</span>} className={'fl'} >
                                {
                                    getFieldDecorator('user_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入领取人昵称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;&emsp;手机号</span>} className={'fl mgl20'} >
                                {
                                    getFieldDecorator('user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;领取时间</span>} className={' fl'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem className={'mgl20 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>

                    </Form>
                </div>
                <div className={'content-body'}>
                    <Table
                        size={`middle`}
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                        <Button className={'btn'} type={""} onClick={this.patchTime}>批量修改有效期</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchOn}>批量激活</Button>
                        <Button className={'btn mgl10'} type={""} onClick={this.patchOff}>批量作废</Button>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                <CouponFitStore onRef={ e => { this.CouponFitStore = e } } stores={ this.state.suitStores }></CouponFitStore>
                <ModalForm onRef={ e => { this.ModalForm = e } } title={this.state.modalTitle} end_at={ this.state.currentRow.end_at || '' }></ModalForm>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(CouponRecord))
