import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Tabs} from 'antd'
import moment from 'moment'
import message from '@/utils/message'
import FxAnalyse from './components/fx-analyse'
import FxSort from './components/fx-sort'

const FormItem = Form.Item
const { TabPane } = Tabs

class FxDataAnalyse extends React.Component{
    state = {
        formData:'',
        activeStatus:'0',
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {

    }

    clickTab = val => {
        this.setState({
            activeStatus:val
        },() => {
            this.fetchData()
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <Tabs  onChange={this.clickTab} type="card" className={''} activeKey={this.state.activeStatus}>
                    <TabPane tab="数据分析" key={`0`}>
                        <FxAnalyse onRef={ e => { this.FxAnalyse = e } }></FxAnalyse>
                    </TabPane>
                    <TabPane tab="分销员排行" key={`1`}>
                        <FxSort onRef={ e => { this.FxSort = e } }></FxSort>
                    </TabPane>
                </Tabs>

            </div>
        )
    }
}
export default Form.create({})(withRouter(FxDataAnalyse))
