import $axios from '@/axios/axios.js'

export function articleAuthor(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-author`,
        method:'post',
        data
    })
}

export function articleAuthorSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-author/save`,
        method:'post',
        data
    })
}


export function articleAuthorDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-author/delete`,
        method:'post',
        data
    })
}

export function articleTopic(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-topic`,
        method:'post',
        data
    })
}

export function articleTopicSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-topic/save`,
        method:'post',
        data
    })
}

export function articleTopicDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-topic/delete`,
        method:'post',
        data
    })
}

export function articleLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article`,
        method:'post',
        data
    })
}

export function articleSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/save`,
        method:'post',
        data
    })
}

export function articleDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/detail`,
        method:'post',
        data
    })
}

export function articleDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/delete`,
        method:'post',
        data
    })
}

export function articleSetTop(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/setTop`,
        method:'post',
        data
    })
}

export function articleSetTopic(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/setTopic`,
        method:'post',
        data
    })
}

export function articleSetStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/setStatus`,
        method:'post',
        data
    })
}

export function articleMessage(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-message`,
        method:'post',
        data
    })
}

export function articleMessageSetStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-message/setStatus`,
        method:'post',
        data
    })
}

export function articleMessageDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article-message/delete`,
        method:'post',
        data
    })
}

export function articleMessageList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/messageList`,
        method:'post',
        data
    })
}

export function articleMessageReplyList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/messageReplyList`,
        method:'post',
        data
    })
}

export function articleMessageReply(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/messageReply`,
        method:'post',
        data
    })
}

export function setNoteSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/config/set`,
        method:'post',
        data
    })
}

export function getNoteSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/config/get`,
        method:'post',
        data
    })
}

export function articleSetField(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/article/setField`,
        method:'post',
        data
    })
}