/**
 * web-view组件
 * @type 19
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber } from 'antd'
import PropTypes from "prop-types";
import Gallery from '@/components/Gallery/Gallery'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
const FormItem = Form.Item
const ctrlItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 16 } }

class tpl_ctrl_type19 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func
    }

    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    changeSpacePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.spacePadding = val;
        this.context.updateDesign(moduleData)
    }
    chooseImg = () => {
        let moduleData = this.state.moduleData;
       
        this.Gallery.show(res => {
            if (res) {
                moduleData.content.cover_img = res[0];
                this.context.updateDesign(moduleData)
            }
        })

    }

    changeLink = (e, item, index) => {
        let moduleData = this.state.moduleData;
        moduleData.content.jump_url = e.target.value;
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return (
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>web-view</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width: 60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                        />
                                    </div>
                                </FormItem>
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>
                                <p>添加跳转内容</p>
                                <p className='mgt10'>图片宽度750PX，高度不限；仅限与本小程序关联的公众号，进行文章链接跳转；未关联的请先至公众号后台【小程序管理】添加小程序后，再进行相关设置。</p>
                            </div>
                            <div className={`cx-ctrl-panel--box`}>
                                <div className={`cx-img-list`} >
                                    <div className={`cx-img-item clearfix`}>
                                        <FormItem label={`选择封面`} {...ctrlItemLayout}>
                                            <div className={'clearfix'}>
                                                <div className={'img-add'} onClick={() => { this.chooseImg() }} >
                                                    {
                                                        moduleData.content.cover_img
                                                            ?
                                                            <div>
                                                                <img src={moduleData.content.cover_img} alt="" />
                                                            </div>
                                                            :
                                                            '+'
                                                    }
                                                </div>
                                            </div>
                                        </FormItem>
                                        <FormItem label={`跳转地址`} {...ctrlItemLayout}>
                                            <Input type={`text`} value={moduleData.content.jump_url} onChange={(e) => { this.changeLink(e) }} placeholder={`请填写公众号文章地址`} />
                                        </FormItem>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>
                    <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                    <PopSelectLink onRef={e => { this.PopSelectLink = e }}></PopSelectLink>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type19)
