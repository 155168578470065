import React from 'react'
import { Form, Input, Button, Icon, Tabs, Tag, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { designLists, setDefault, designDelete } from '@/api/design'
import { ICON_FONT_URL } from '@/constants/iconFont'
import ShareModal from '@/components/shareModal/shareModal'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import '@/assets/css/design/lists.scss'
const FormItem = Form.Item;
const { TabPane } = Tabs;
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})
class DesignLists extends React.Component{
    state = {
        searchForm:{
            name:''
        },
        page:1,
        activeKey:`1`,
        isLoading:false,
        lists:[],
        poster:'',
        path:''
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.type = Number(this.state.activeKey);
        sendData.per_page = 100;
        this.setState({
            isLoading:true
        })
        designLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data;
                    this.setState({
                        lists
                    })
                }
            })
            .finally(() =>  {
                this.setState({
                    isLoading:false
                })
            })

    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        let activeKey = Number(this.state.activeKey)
        this.props.history.push({
            pathname:`/design/add/${activeKey}`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleEdit = rowData => {
        let activeKey = Number(this.state.activeKey)
        this.props.history.push({
            pathname: `/design/add/${activeKey}/${rowData.id}`
        })

    }
    handleUse = rowData => {
        let sendData = {}
        sendData.id =  rowData.id;
        this.setState({
            isLoading:true
        })
        setDefault(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('已成功应用到小程序')
                        .then(() => {
                            this.fetchData()
                        })
                }
            })
            .finally(() =>  {
                this.setState({
                    isLoading:false
                })
            })

    }
    handleDelete = rowData => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:`确定要删除这个装修页吗？`,
            okType:`danger`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                designDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('删除成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleStatus = () => {
        message.info('敬请期待')
    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/index/custom?id=${row.id}`
        })
        this.ShareModal.show()
    }
    clickTab = (val) => {
        this.setState({
            activeKey:val,
            lists:[]
        },() => {
            this.fetchData()
        })
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'页面名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索页面名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={``}>
                    <Tabs activeKey={this.state.activeKey} onChange={ this.clickTab }>
                        <TabPane tab="店铺主页" key={`1`}>
                            <div className={`main-list`}>
                                <ul>
                                    <Link className={`main-list-item`} to={`/design/add/${Number(this.state.activeKey)}`} target={`_blank`}>
                                        <div className={`page new-cover`}>
                                            <div className={`cover first-card`}>
                                                <div className={`content`}>
                                                    <Icon type="plus-circle" theme="filled" className={`content-svg`} />
                                                    <div className={`content-text`}>使用空白模板</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    {
                                        this.state.lists.map((item,index) => (
                                            <li className={`main-list-item`} key={index}>
                                                <div className={`page`}>
                                                    <div className={`cover`} style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2021-11-25/402e6cec4d2d08419de22ce845fd091a.png)`,height:`190px` }}>
                                                        <div className={`float-cover`}>
                                                            <div className={`ul-box`}>
                                                                <div className={`top-bottom`} onClick={ () => { this.handleDelete(item) } }>
                                                                    <EditorIcon type={`icon-shanchu`} className={`delete-icon`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.is_default == 1
                                                                ?
                                                                <div className={`current`}>
                                                                    <Tag color={`#5E4CFF`} className={`mgr5`}>已启用</Tag>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className={`dec`}>
                                                        <div className={`cover-title`}>
                                                            <div className={`title`} title={`${item.page_title}`}>{item.page_title}</div>
                                                        </div>
                                                        <div className={`cover-date`}>
                                                            <Icon type="clock-circle" className={`icon-clock`} />
                                                            { item.created_at }
                                                        </div>
                                                    </div>
                                                    <div className={`bottom-button`}>
                                                        <ul>
                                                            <li className={`bdr`}>
                                                                <div className={`btm-button `} onClick={ () => { this.handleUse(item) } }>使用</div>
                                                            </li>
                                                            <li>
                                                                <Link className={`btm-button `} to={`/design/add/${Number(this.state.activeKey)}/${item.id}`} target={`_blank`}>编辑</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }

                                </ul>
                            </div>
                        </TabPane>
                        <TabPane tab="自定义页面" key={`2`}>
                            <div className={`main-list`}>
                                <ul>
                                    <Link className={`main-list-item`}  to={`/design/add/${Number(this.state.activeKey)}`} target={`_blank`}>
                                        <div className={`page new-cover`}>
                                            <div className={`cover first-card`}>
                                                <div className={`content`}>
                                                    <Icon type="plus-circle" theme="filled" className={`content-svg`} />
                                                    <div className={`content-text`}>使用空白模板</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    {
                                        this.state.lists.map((item,index) => (
                                            <li className={`main-list-item`} key={index}>
                                                <div className={`page`}>
                                                    <div className={`cover`} style={{ backgroundImage:`url(https://img.cxkoo.com/chengxuan/1/2021-11-25/402e6cec4d2d08419de22ce845fd091a.png)`,height:`190px` }}>
                                                        <div className={`float-cover`}>
                                                            <div className={`ul-box`}>
                                                                <div className={`top-bottom`} onClick={ () => { this.handleDelete(item) } }>
                                                                    <EditorIcon type={`icon-shanchu`} className={`delete-icon`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`dec`}>
                                                        <div className={`cover-title`}>
                                                            <div className={`title`} title={`${item.page_title}`}>{item.page_title}</div>
                                                        </div>
                                                        <div className={`cover-date`}>
                                                            <Icon type="clock-circle" className={`icon-clock`} />
                                                            { item.created_at }
                                                        </div>
                                                    </div>
                                                    <div className={`bottom-button`}>
                                                        <ul>
                                                            <li className={`bdr`}>
                                                                <Link className={`btm-button`} to={`/design/add/${Number(this.state.activeKey)}/${item.id}`} target={`_blank`} >编辑</Link>
                                                            </li>
                                                            <li className={`bdr`}>
                                                                <div className={`btm-button `} onClick={ () => { this.handleShare(item) } }>推广</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(DesignLists))
