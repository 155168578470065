import React from 'react'
import { Form } from 'antd'
import { withRouter } from 'react-router-dom'
import Loading from '@/components/Loading'
import PropTypes from 'prop-types'
import EditForm from "./components/EditForm";



class AddLesson extends React.Component{
    static childContextTypes = {
        isLoading:PropTypes.bool,
        showItemStorePrice:PropTypes.bool,
        setLoading:PropTypes.func,
        setContextState:PropTypes.func,
    }
    state = {
        formData:{},
        isLoading:false,
        showItemStorePrice:false,
        currentStep:0,
    }
    getChildContext = () => {
        return{
            isLoading:this.state.isLoading,
            showItemStorePrice:this.state.showItemStorePrice,
            setLoading:(e) => {
                this.setLoading(e)
            },
            setContextState:(params = {}) => {
                this.setContextState(params)
            }
        }
    }
    setLoading = e => {
        this.setState({
            isLoading:!!e
        })
    }
    setContextState = params => {
        if (Object.keys(params).length) {
            this.setState(params)
        }
    }
    componentDidMount() {}

    render() {
        return(
            <div className={`shadow-radius`}>
                <div className={'mgt10'}>
                    {
                        this.state.currentStep == 0
                            ?
                            <EditForm onRef={e => { this.EditForm = e }} data={ this.state.formData }></EditForm>
                            :
                            null
                    }
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}

export default Form.create({})(withRouter(AddLesson))
