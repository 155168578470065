import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, DatePicker, TimePicker } from 'antd'
import message from '@/utils/message'
import moment from 'moment'
const FormItem = Form.Item
const { TextArea } = Input

class CleanDetail extends React.Component{
    state = {
        title:'动态详情',
        formData:'',
        visible:false,
        modalLoading:false,
        callback:null,
        total_fee:'0.00'

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null,
            formData: this.props.data || ''
        })
    }

    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }

    handleOk = () => {
        let sendData = {},
            formData = this.state.formData || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.book_date = values.book_date ?  moment(values.book_date).format('YYYY-MM-DD')  : '';
                for(var i in values){
                    formData[i] = values[i]
                }

                if(typeof callback == 'function'){
                    callback(formData)
                }
                this.close()
            }
        })
    }
    handleChange = val => {}
    handleCancel = () => {
        this.close()
    }
    handleSpaceChange = (val) => {
        let spaceLists = this.props.spaceLists || [],
            formData = this.state.formData || {};
        formData.space_id = val;
        for(var i in spaceLists){
            if(spaceLists[i]['id'] == val){
                formData['space_type_id'] = spaceLists[i]['space_type_id']
            }
        }
        this.setState({
            formData
        },() => { this.handlePayMent() })


    }
    handleDateChange = (e) => {
        let formData = this.state.formData || {};
        if(e){
            formData['week_id'] = moment(e).day()
        }else {
            formData['week_id'] = ''
        }
        this.setState({
            formData
        },() => { this.handlePayMent() })
    }
    handleRadioChange = (e) => {
        let val = e.target.value,
            formData = this.state.formData || {};
        formData.book_type = val;
        this.setState({
            formData
        })
    }
    handleSafeChange = e => {
        let val = e.target.value,
            formData = this.state.formData || {};
        formData['buy_safety'] = val;
        this.setState({
            formData
        },() => { this.handlePayMent() })

    }
    handleTimeChange = (e) => {
        let formData = this.state.formData || {};
        formData.time_ids = e;
        this.setState({
            formData
        },() => { this.handlePayMent() })
    }
    handleGusetTimeChange = (e) => {
        let formData = this.state.formData || {};
        formData.time_id = e;
        this.setState({
            formData
        },() => { this.handlePayMent() })
    }
    handlePayMent = () => {}
    render() {
        let spaceLists = this.props.spaceLists || [],
            mix_price = this.props.mix_price || '',
            rent_price = this.props.rent_price || '',
            time_data = this.props.time_data || [],
            payTypes = this.props.payTypes || [],
            data = this.state.formData || {};
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 18
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                okText={`打扫完成`}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`场地用途`}>
                        {getFieldDecorator('book_type', {
                            initialValue: 5,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择场地用途'
                                }
                            ],
                        })(
                            <Radio.Group onChange={ this.handleRadioChange } disabled={true}>
                                <Radio value={1}>整租</Radio>
                                <Radio value={2}>散客</Radio>
                                <Radio value={3}>培训</Radio>
                                <Radio value={4}>赛事</Radio>
                                <Radio value={5}>清扫</Radio>
                                <Radio value={6}>其他</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>


                    <FormItem label={`选择场地`}>
                        {
                            getFieldDecorator('space_id',{
                                initialValue: data.space_id,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择场地'
                                    }
                                ]
                            })(
                                <Select placeholder={'请选择'} onChange={ this.handleSpaceChange } disabled={true}>
                                    {
                                        spaceLists.map((item,index) => (
                                            <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem label={`选择场次`}>
                        {
                            getFieldDecorator('book_date',{
                                initialValue:data.book_date ? moment(data.book_date, 'YYYY-MM-DD') : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择日期'
                                    }
                                ]
                            })(
                                <DatePicker onChange={ this.handleDateChange } disabled={true}></DatePicker>
                            )
                        }
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        {
                            getFieldDecorator('time_ids',{
                                initialValue: data.time_ids || [],
                            })(
                                <Select
                                    placeholder={'请选择'}
                                    mode="multiple"
                                    onChange={ this.handleTimeChange }
                                    disabled={true}
                                >
                                    {
                                        time_data.map((item,index) => (
                                            <Select.Option value={item.id} key={index} >{ item.time }</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(CleanDetail))
