import React, { Component } from 'react'
import { Form, Input, Radio, Button, Select, Icon, InputNumber, Divider, TreeSelect, Modal, Tooltip, DatePicker, message } from 'antd'
import { storeLists } from '@/api/store'
import { saveSignSetting, signSetting ,downloadQrcode,downloadSignTemplate} from '@/api/educational'
import Loading from '@/components/Loading'
import '@/assets/css/educational/educational.scss';
const FormItem = Form.Item

class SignCode extends Component {
    state = {
        storeLists: [],
        isLoading: false,
        codeFrom:{
            code_url:"",
            store_name:"",
            store_id:""
        },
        configData:{}
    }

    componentDidMount() {
        this.fetchStore()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                    if(lists.length>0){
                        this.fetchSetting(lists[0].id)
                    }
                    
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    fetchSetting(store_id){
        let codeFrom=this.state.codeFrom ||{},configData={}
        signSetting({
            store_id:store_id
        }).then(res=>{
            if(res.code==1){
                codeFrom.code_url=res.data.code_url;
                if(! res.data.config){
                    this.setState({
                        codeFrom
                    })
                    return 
                }

                codeFrom.is_store=res.data.config.is_store
                codeFrom.store_name=this.state.storeLists.find(v=>v.id==store_id).name
                codeFrom.store_id=store_id

                configData=res.data.config;
                configData.store_id=store_id
                
                this.setState({
                    codeFrom,
                    configData
                },()=>{
                    this.putSetting()
                })
            }
        })
    }

    putSetting=()=>{
       let data= this.state.configData
        let is_store=data.is_store,
        is_rule=data.is_rule,
        start_time=data.start_time,
        end_time=data.end_time,
        sign_points=data.sign_points ||0;

        this.props.form.setFieldsValue({
            is_store,is_rule,sign_points
        })

        if(is_rule){
           setTimeout(() => {
            this.props.form.setFieldsValue({
                start_time,end_time
            })
           }, 200);
        }
        
    }

    handleChange=val=>{
       this.fetchSetting(val)
    }

    downloadCode=()=>{
        let codeFrom=this.state.codeFrom
        if(codeFrom){
            this.setState({
                isLoading: true
            })
            downloadQrcode({store_id:codeFrom.store_id}).then(res=>{
                if(res.code==1){
                    let url=res.data.code_url
                    window.open(url,"_blank");
                }
            }).finally(()=>{
                this.setState({
                    isLoading: false
                })
            })
        }else{
            message.error("下载失败，请重新生成二维码后在下载")
        }
    }
    downloadSignTem=()=>{
        let codeFrom=this.state.codeFrom
        if(codeFrom){
            let url= downloadSignTemplate({store_id:codeFrom.store_id})
            window.open(url,"_blank");
        }else{
            message.error("下载失败，请重新生成二维码后在下载")
        }
    }

    saveData = () => {
        let sendData = {}, config = {}
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData.store_id = values.store_id
                config.is_store = values.is_store
                config.is_rule = values.is_rule
                config.sign_points=values.sign_points
                config.start_time = values.start_time || ""
                config.end_time = values.end_time || ""
                sendData.config = config

                this.setState({
                    isLoading: true
                })

                saveSignSetting(sendData).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                        this.fetchSetting( sendData.store_id)
                    }
                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
            }
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 16
            }
        }

        let data = this.state.configData
        const { getFieldDecorator, getFieldValue } = this.props.form

        return (
            <div className={'shadow-radius sign-code'} >
                <div className='sign-code-left'>
                    <div className="code-container">
                        <div className="code-img">
                            {this.state.codeFrom.code_url?<img src={this.state.codeFrom.code_url} alt=""  />:null}
                            <div className="code-store">{this.state.codeFrom.is_store?this.state.codeFrom.store_name:""}</div>
                        </div>
                    </div>
                    <div className='mgt30'>
                        <Button type="primary" ghost className='mgl20' onClick={this.downloadSignTem}>下载签到模板 </Button>
                        <Button type="primary" ghost className='mgl20' onClick={this.downloadCode}> 下载二维码</Button>
                    </div>
                </div>
                <div className='sign-code-right'>
                    <Form {...formItemLayout} className={'model-form'}>
                        <div className={`msg-title mgb15`}>基本设置</div>
                        <FormItem label={'校区'}>
                            {getFieldDecorator('store_id', {
                                initialValue: data.store_id || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择所属门店'
                                    }
                                ]
                            })(
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="请选择"
                                    onChange={this.handleChange}
                                >
                                    {
                                        this.state.storeLists.map(store => (
                                            <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )}
                        </FormItem>

                        <FormItem label={"校区名"} >
                            {getFieldDecorator('is_store', {
                                initialValue: data.is_store || 1
                            })(
                                <Radio.Group >
                                    <Radio value={1}>显示</Radio>
                                    <Radio value={0}>不显示</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>

                        <FormItem label={"签到规则"} >
                            {getFieldDecorator('is_rule', {
                                initialValue: data.is_rule || 0
                            })(
                                <Radio.Group >
                                    <Radio value={1}>限制</Radio>
                                    <Radio value={0}>不限制</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>

                        {
                            getFieldValue("is_rule") == 1 ?
                                <FormItem label={" "} className="no-colon">
                                    <span>课程开始前&emsp;</span>

                                    <FormItem style={{ display: "inline-block" }}>
                                        {getFieldDecorator('start_time', {
                                            initialValue: data.start_time || 2
                                        })(
                                            <InputNumber min={0} step={0.1} precision={1} />
                                        )}
                                    </FormItem>

                                    <span>&emsp;小时可以签到，课程结束后&emsp;</span>
                                    <FormItem style={{ display: "inline-block" }}>
                                        {getFieldDecorator('end_time', {
                                            initialValue: data.end_time || 2
                                        })(
                                            <InputNumber min={0} step={0.1} precision={1} />
                                        )}
                                    </FormItem>
                                    <span>&emsp;小时停止签到</span>
                                </FormItem> : null
                        }

                        <FormItem label={"签到得积分"} >
                            每成功签到一次，给学员增加&emsp;
                            {getFieldDecorator('sign_points', {
                                initialValue: data.sign_points || 0
                            })(
                                <InputNumber step={1} precision={0} />
                            )}
                             &emsp;积分
                        </FormItem>

                        <FormItem label={" "} className="no-colon">
                            <Button type={'primary'} onClick={this.saveData}>保存</Button>
                        </FormItem>
                    </Form>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(SignCode)
