import $axios from '@/axios/axios.js'

export function pointsLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points/lists`,
        method:'post',
        data
    })
}

export function pointsExport(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points/export`,
        method:'get',
        responseType:'blob',
        params
    })
}

export function pointsData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points/data`,
        method:'post',
        data
    })
}

export function pointsRank(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points/rank`,
        method:'post',
        data
    })
}

export function pointsTrend(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points/trend`,
        method:'post',
        data
    })
}

export function pointsDistribute(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points/distribute`,
        method:'post',
        data
    })
}

export function teacherData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/education-data/teacherData`,
        method:'post',
        data
    })
}

export function exportTeacherData(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/education-data/exportTeacherData`,
        method:'get',
        responseType:'blob',
        params
    })
}


export function courseData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/education-data/courseData`,
        method:'post',
        data
    })
}

export function courseTrend(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/education-data/courseTrend`,
        method:'post',
        data
    })
}

export function studentCourseRank(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/education-data/studentCourseRank`,
        method:'post',
        data
    })
}