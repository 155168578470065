const IconImgSrc = [
    {
        icons:[
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/318ccd722007e2c8f1840190bfc98bef.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/55389c7dd443991b1268e79e5b5420ff.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/cbf1e5a3cf4dd65b113404c749835000.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/cc4df7affe87b0340f15240176e4fc06.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/106b580e4527c12ccf7cceeaf576a937.png'
        ]
    },
    {
        icons:[
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/dd771b06b941b1c4d729299f95df2f90.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/e34235b81b869598545a0520c77dab8e.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/d72a83999766a54be4f4ccb440dc8f5c.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/7d7ab682ba6218099d84390a93376ee0.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-09/1f5ce828e1ddd47d2700cf79652491e3.png'
        ]
    },
    {
        icons:[
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/48bf22c0659badc47793ebbc8a935474.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/cc92ca3dc56b56dcf28c8ad876456277.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/f7e888d5f54da7904c9bd711429d769c.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/6b7361354f4c28d22efb346d33c7f10c.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/496750dbc8ceddc3cf8ebbac6ebfe72a.png'
        ]
    },
    {
        icons:[
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/8d238576efae67e288574a1b00088c73.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/fa0d9e86a8137eb653ab1918e9f7dab5.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/e9201f65256365579662232314979c50.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/1ce7dfe197c5fdc99592fdfc0a86d46d.png',
            'https://img.cxkoo.com/chengxuan/1/2021-12-13/6c292626bc85479469254ccbb8cc9f2c.png'
        ]
    },
    {
        icons:[
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/d0b17c6ba39f072f28475d972c6136d5.png',
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/ef885376efa67bdac60adcdcc1c34c91.png',
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/d9f0c9c6b1a3c4aae86dd5dec0f59037.png',
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/9edeab056505e369d756581ecb86165a.png',
        ]
    },
    {
        icons:[
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/24a2cb146555bc1c5ccc72c99f1a69a9.png',
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/4745bb3f7449d8c59a25a8c23053c5e8.png',
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/2f6e3944475e6b8f041fe6fffd23dae8.png',
            'https://img.cxkoo.com/chengxuan/1/2022-11-01/70361d2ede7c632389ef55dfdba8f974.png',
           
        ]
    }
]
export default IconImgSrc
