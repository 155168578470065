import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio } from 'antd'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class CardRecharge extends React.Component{
    state = {
        title:'充值',
        visible:false,
        modalLoading:false,
        callback:null,
        current:''

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false,
            current:''
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(typeof callback == 'function'){
                    callback(values)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    handleSelectChange = val => {
        let rechargeRules = this.props.rechargeRules,
            current = rechargeRules[val];
        this.setState({
            current
        })
    }
    render() {
        const storeLists = this.props.storeLists || []
        const rechargeRules = this.props.rechargeRules || []
        const data = this.props.data || {}
        let current = this.state.current || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`选择充值套餐`}>
                        {getFieldDecorator('ruleIndex', {
                            initialValue: data.ruleIndex,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择充值套餐'
                                }
                            ],
                        })(
                            <Select placeholder={'请选择'} onChange={ this.handleSelectChange }>
                                {
                                    rechargeRules.map((item,index) => (
                                        <Select.Option value={index} key={index}>
                                            充{ item.recharge_money }元，送{ item.present_money }元+{ item.present_points }积分
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label={`实付金额`}>
                        <p className={``}>{ current.recharge_money ? `${current.recharge_money}元` : '请选择套餐' }</p>
                    </FormItem>
                    <FormItem label={`到账金额`}>
                        <p className={``}>{ current.recharge_money ? `${parseFloat(current.recharge_money) + parseFloat(current.present_money)}元+${current.present_points}积分` : '请选择套餐' }</p>
                    </FormItem>
                    <FormItem label={`充值门店`}>
                        {getFieldDecorator('store_id', {
                            initialValue: data.store_id,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择充值门店'
                                }
                            ],
                        })(
                            <Select placeholder={'请选择'}>
                                {
                                    storeLists.map((item,index) => (
                                        <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                    ))
                                }
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label={`操作员`}>
                        {
                            getFieldDecorator('remark',{
                                initialValue: data.remark || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写操作员'
                                    }
                                ]
                            })(
                                <Input placeholder={`操作员姓名`} />
                            )
                        }
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(CardRecharge))
