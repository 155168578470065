import $axios from '@/axios/axios.js'

export function designLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/list`,
        method:'post',
        data
    })
}

export function designDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/detail`,
        method:'post',
        data
    })
}

export function designAdd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/create`,
        method:'post',
        data
    })
}

export function designEdit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/edit`,
        method:'post',
        data
    })
}

export function designDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/delete`,
        method:'post',
        data
    })
}

export function tplLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/template`,
        method:'post',
        data
    })
}

export function changeTpl(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/templateDetail`,
        method:'post',
        data
    })
}

export function setDefault(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/design/setDefault`,
        method:'post',
        data
    })
}

export function setIndexPop(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/index-poster/setConfig`,
        method:'post',
        data
    })
}


export function getIndexPop(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/index-poster/getConfig`,
        method:'post',
        data
    })
}


export function setAppColor(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/config/set`,
        method:'post',
        data
    })
}

export function getAppColor(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/config/get`,
        method:'post',
        data
    })
}