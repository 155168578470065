/**
 * 拼团列表组件
 * @type 17
 * @return Object
 */

 import React from 'react'
 import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip } from 'antd'
 import PropTypes from "prop-types";
 import GroupBuyingPicker from '@/components/groupBuyingPicker/groupBuyingPicker'
 import ColorPicker from '@/components/ColorPicker/ColorPicker'
 import PictureCard from '@/components/PictureCard/PictureCard'
 const FormItem = Form.Item
 const ctrlItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }
 
 class tpl_ctrl_type17 extends React.Component {
     static contextTypes = {
         updateDesign: PropTypes.func
     }
 
     state = {
         moduleData: {},
         itemStyleGroup: [
            {
                name:'一行两列',
                style:1,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-11-22/ea5d1c9940155160ec729f67cb3f7ff5.png','https://img.cxkoo.com/chengxuan/1/2021-11-22/32854ed39350a6a2bbc22da3654d9fb0.png']
            },
            {
                name:'详细列表',
                style:2,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-11-22/f7dcf64fdd72cbb723424b305555ad8e.png','https://img.cxkoo.com/chengxuan/1/2021-11-22/0b322df6db5236e45ca4b83d6e0e9b05.png']
            },
            {
                name:'横向滑动',
                style:3,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-11-22/97ce104bf88be476eed6dcca94434b7e.png','https://img.cxkoo.com/chengxuan/1/2021-11-22/3c8595b015d56382929a650c7c771265.png']
            }
         ]
     }
 
     componentDidMount() {
         this.props.onRef(this)
         let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
     componentWillReceiveProps(nextProps, nextContext) {
         let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
 
     changePadding = val => {
         let moduleData = this.state.moduleData;
         moduleData.content.modulePadding = val;
         this.context.updateDesign(moduleData)
     }
     addItem = (item, index) => {
         let moduleData = this.state.moduleData;
         this.ItemPicker.show(res => {
             if (res.length) {
                let dataset=res.splice(0,6);
                    dataset=dataset.map(val=>{
                        val.share_poster= val.share_poster|| val.goods_info &&(val.goods_info.cover_img || val.goods_info.pic_urls && val.goods_info.pic_urls[0]|| '' ) || ''
                        return val
                    })
                 moduleData.content.dataset = dataset;
                 this.context.updateDesign(moduleData)
             }
         })
 
     }
     changeItem = (e) => {
         let moduleData = this.state.moduleData,
             index = e.currentTarget.dataset.index || 0;
         this.ItemPicker.show(res => {
             if (res.length) {
                 moduleData.content.dataset[index] = res[0];
                 this.context.updateDesign(moduleData)
             }
         })
     }
     handleStyleChange = (e, name) => {
         let value = e.target.value,
             moduleData = this.state.moduleData;
         moduleData.content[name] = value;
         this.context.updateDesign(moduleData)
 
     }
     handleItemStyle = (val) => {
         let moduleData = this.state.moduleData;
         moduleData.content.style = val;
         this.context.updateDesign(moduleData)
 
     }
 
     handleColorChange = ( color,name ) => {
         let hex = color.hex,
             moduleData = this.state.moduleData;
         moduleData.content[name] = hex;
         this.context.updateDesign(moduleData)
     }
 
     handleResetColor=(name,color)=>{
         let moduleData=this.state.moduleData
         moduleData.content[name]=color
         this.context.updateDesign(moduleData)
     }

     delImg = (e) => {
        let moduleData = this.state.moduleData,
            index = e.index || 0
        moduleData.content.dataset.splice(index, 1);
        this.context.updateDesign(moduleData)
    }

    handleSort = (e) => {
        let moduleData = this.state.moduleData,
            dataset = moduleData.content.dataset || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = dataset.splice(oldIndex, 1)[0];
        dataset.splice(newIndex, 0, targetRow);
        moduleData.content.dataset = dataset;
        this.context.updateDesign(moduleData)
    }
 
 
     render() {
         let moduleData = this.state.moduleData || {}
         return (
             Object.keys(moduleData).length
                 ?
                 <div>
                     <div className={`ctrl-right-title`}>拼团</div>
                     <Form>
                         <div className={`cx-ctrl-panel`}>
                             <div className={`cx-ctrl-panel--box`}>
                                 <FormItem label={`选择拼团列表样式`}>
                                     <ul className={`template-box`}>
                                         {
                                             this.state.itemStyleGroup.map((item, index) => (
                                                 <Tooltip effect="dark" placement="bottom" title={item.name} key={index}>
                                                     <li className={`temp ${moduleData.content.style == item.style ? 'selected' : ''}`} onClick={() => { this.handleItemStyle(item.style) }}>
                                                         {
                                                             moduleData.content.style == item.style
                                                                 ?
                                                                 <img src={item.imgs[0]} className={`img`} alt="" />
                                                                 :
                                                                 <img src={item.imgs[1]} className={`img`} alt="" />
 
                                                         }
                                                     </li>
                                                 </Tooltip>
                                             ))
                                         }
                                     </ul>
                                 </FormItem>
 
                                 <FormItem label={`组件间距`}>
                                     <div className={`cx-slider`}>
                                         <Slider
                                             min={0}
                                             max={20}
                                             value={moduleData.content.modulePadding}
                                             onChange={this.changePadding}
                                             className={`cx-slider-main`}
                                         ></Slider>
                                         <InputNumber
                                             min={0}
                                             max={20}
                                             style={{ width: 60 }}
                                             value={moduleData.content.modulePadding}
                                             onChange={this.changePadding}
                                         />
                                     </div>
                                 </FormItem>
                                 <FormItem label={`拼团样式`} {...ctrlItemLayout} className={"mgt20"} >
                                     <Radio.Group buttonStyle="solid" value={moduleData.content.borderStyle} onChange={(e) => { this.handleStyleChange(e, 'borderStyle') }}>
                                         <Radio.Button value={1}>无边</Radio.Button>
                                         <Radio.Button value={2}>卡片</Radio.Button>
                                         <Radio.Button value={3}>描边</Radio.Button>
                                     </Radio.Group>
                                 </FormItem>
                                 <FormItem label={`副标题`} { ...ctrlItemLayout }>
                                     <Radio.Group value={moduleData.content.showSubTitle} onChange={(e) => { this.handleStyleChange(e,'showSubTitle') }}>
                                         <Radio value={1}>显示</Radio>
                                         <Radio value={0}>不显示</Radio>
                                     </Radio.Group>
                                 </FormItem>
                                 <FormItem label={`拼团销量`} { ...ctrlItemLayout }>
                                     <Radio.Group value={moduleData.content.showSales} onChange={(e) => { this.handleStyleChange(e,'showSales') }}>
                                         <Radio value={1}>显示</Radio>
                                         <Radio value={0}>不显示</Radio>
                                     </Radio.Group>
                                 </FormItem>
                                
                                 <FormItem label={`文字颜色`} {...ctrlItemLayout} >
                                     <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.textColor} callback={(color) => { this.handleColorChange(color, 'textColor') }} ></ColorPicker>
                                     <Button type="link" onClick={()=>this.handleResetColor('textColor','#ffffff')}>重置</Button>
                                 </FormItem>
 
                                 <FormItem label={`进行中主题色`} {...ctrlItemLayout}>
                                     <ColorPicker  style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.startColor} callback={(color) => { this.handleColorChange(color, 'startColor') }} ></ColorPicker>
                                     <Button type="link" onClick={()=>this.handleResetColor('startColor','#FF3636')}>重置</Button>
                                 </FormItem>
 
                                 <FormItem label={`未开始主题色`} {...ctrlItemLayout}>
                                     <ColorPicker  style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.notStartColor} callback={(color) => { this.handleColorChange(color, 'notStartColor') }} ></ColorPicker>
                                     <Button type="link" onClick={()=>this.handleResetColor('notStartColor','#6D30FF')}>重置</Button>
                                 </FormItem>
                             </div>
                         </div>
                         <div className={`cx-ctrl-panel`}>
                             <div className={`cx-ctrl-panel--title`}>添加拼团活动</div>
                             <div className={`cx-ctrl-panel--box`}>
                                 <div className={`cx-img-list`} >
                                     <div className={`cx-img-item clearfix`}>
                                         <FormItem label={`选择拼团`} {...ctrlItemLayout}>
                                             <div className={'clearfix'}>
                                                 
			                                    <PictureCard
                                                    onRef={e => { this.PictureCard = e }}
                                                    imgs={moduleData.content.dataset}
                                                    onDel={e => { this.delImg(e) }}
                                                    onSort={e => { this.handleSort(e) }}
                                                    item_key={'share_poster'}
                                                    item_id={'id'}
                                                ></PictureCard>
                                                 {
                                                     moduleData.content.dataset.length >=6? null :<div className={'img-add'} onClick={this.addItem} data-name="pic">+</div>
                                                 }
                                                 
                                             </div>
                                         </FormItem>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </Form>
 
                     <GroupBuyingPicker
                         onRef={e => { this.ItemPicker = e }}
                         selectIds={moduleData.content.dataset.length ? moduleData.content.dataset.map(item => { return Number(item.id) }) : []}
                         items={moduleData.content.dataset.length ? moduleData.content.dataset : []}
                         multiple={true}
                     ></GroupBuyingPicker>
                 </div>
                 :
                 null
         )
     }
 }
 
 export default Form.create({})(tpl_ctrl_type17)
 