import React, { Component } from 'react'
import { Divider, Button, Tag ,Tabs} from 'antd';
import { withRouter, Link } from 'react-router-dom'
import {gradeDetailApi} from '@/api/educational'
import GradeStudent from './components/gradeStudent';
import GradeSchedule from './components/gradeSchedule';
import CourseRecord from './components/courseRecourd';
import AddGrade from './components/addGrade'
import Loading from '@/components/Loading'

import '@/assets/css/educational/educational.scss';
const { TabPane } = Tabs;

class GradeDetail extends Component {

    state = {
        activeKey:"1",
        isLoading:false,
        gradeDetail:{},
        isAddGrade:false
    }

    componentDidMount(){
        if(!this.props.match.params.id){
            this.props.history.goBack()
        }
        this.fetchData()
        let signPage=window.sessionStorage.getItem("sign-page")
        if(signPage){
            this.setState({
                activeKey:'2'
            })
            window.sessionStorage.removeItem("sign-page")
        }
       
    }

    fetchData = () => {
        let sendData = {}
        sendData.id=this.props.match.params.id
       
        this.setState({
            isLoading:true
        })
        gradeDetailApi(sendData)
            .then(res => {
                
                if (res.code == 1) {
                  this.setState({
                    gradeDetail:res.data.detail
                  })
                }
            }).finally(()=>{
                this.setState({
                    isLoading:false
                })
            })
           
    }

    closeAddGrade=()=>{
        this.setState({
            isAddGrade:false
        })
    }

    handleEdit=()=>{
        this.setState({
            isAddGrade:true
        })
    }

    changeTabs=key=>{
       
        this.setState({
            activeKey:key
        })
    }

    render() {
        let gradeDetail=this.state.gradeDetail

        return (
            <div className={'shadow-radius'}>
                <div className={'grade-head'} >
                    <div className='grade-title mgb30'>
                        <div>
                            <span className='head-title'>{gradeDetail.name||"-"}</span>
                            <Tag color={gradeDetail.status==2?'#5E4CFF':gradeDetail.status==1?"#FF0000":"#9D9D9D"} className='head-tag'>{gradeDetail.status==2?'开班中':gradeDetail.status==1?"待开班":"已结班"}</Tag>
                        </div>
                        <div>
                            {
                                gradeDetail.status != 3 &&  < Button  onClick={this.handleEdit}>编辑</Button>
                            }
                        </div>
                    </div>
                    <Divider />
                    <div className='grade-head-content'>
                        <div className='head-line'>
                            <div className="head-item">课程类目：{gradeDetail.course_category?gradeDetail.course_category.name:"-"}</div>
                            <div className="head-item">班级人数：{gradeDetail.grade_student_count}</div>
                            <div className="head-item">班级总人数：{gradeDetail.grade_num}</div>
                            <div className="head-item">已排课数：{gradeDetail.course_num}</div>
                        </div>
                        <div  className='head-line'>
                            <div className="head-item">上课次数：{gradeDetail.study_num||"-"}</div>
                            <div className="head-item">开班时间：{gradeDetail.start_date||"-"}</div>
                            <div className="head-item">结班时间：{gradeDetail.end_date||"-"}</div>
                        </div>
                    </div>
                </div>
                <Tabs activeKey={this.state.activeKey} onChange={this.changeTabs} className={"mgt15"}>
                    <TabPane tab="班级学员" key="1">
                        <GradeStudent grade_id={this.props.match.params.id} refreshDetail={this.fetchData}  grade_num={gradeDetail.grade_num} />
                    </TabPane>
                    <TabPane tab="班级课表" key="2">
                        <GradeSchedule grade_id={this.props.match.params.id} grade_detail={this.state.gradeDetail} />
                    </TabPane>
                    <TabPane tab="上课记录" key="3">
                        <CourseRecord grade_id={this.props.match.params.id} grade_detail={this.state.gradeDetail}  />
                    </TabPane>
                   
                </Tabs>

                {this.state.isAddGrade? <AddGrade closeAddGrade={this.closeAddGrade} handleS={this.fetchData} edit_status={true} grade_line={this.state.gradeDetail} /> :null}
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default withRouter(GradeDetail)
