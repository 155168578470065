import React from 'react'
import { Form, Select, Button, DatePicker, Drawer, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { getTotalBook, setTotalBook, copyTotalBook, courtDynamic, courtGetTime, addCourtBook, cleanUp, courtBookingOrder, courtCash, courtCancelOrder } from '@/api/court'
import { payTypeLists } from '@/api/system'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import moment from 'moment'
import AddCourtOrder from './components/add-court-order'
import CourtStatusDetail from './components/court-status-detail'
import CleanDetail from './components/clean-detail'
import '@/assets/css/court/court.scss'
const FormItem = Form.Item

class CourtBookingStatus extends React.Component{
    state = {
        allStore:[],
        currentStore:'',
        currentDate:moment().format('YYYY-MM-DD'),
        currentSpaceType:'',
        spaceTypeLists:[],
        spaceLists:[],
        nowSpaceLists:[],
        timeData:[],
        status_data:'',
        isLoading:false,
        drawerVisible:false,
        pop_time_data:[],
        payTypes:[],
        mix_price:'',
        rent_price:'',
        insurance_fee:'',
        cleanData:null,
        currentRow:null,
        guestOnly:false, //只能约散客
        trade_lists:[]
    }
    componentDidMount() {
        this.fetchStore()
            .then(() => {
                this.fetchData()
                this.getCourtTimePrice()
                this.fetchPayType()
            })
    }
    fetchData = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '',
            currentDate = this.state.currentDate || '';
        sendData.store_id = currentStore ? currentStore.id : ''
        sendData.date = this.state.currentDate;
        this.setState({
            isLoading:true
        })
        courtDynamic(sendData)
            .then(res => {
                if(res.code == 1){
                    let spaceTypeLists = res.data.space_type_list,
                        spaceLists = res.data.space_list || [],
                        timeData = res.data.time_data || [],
                        status_data = res.data.status_data || {};
                    spaceTypeLists.forEach((item,index) => {
                        item.is_choose = 0;
                    })
                    if(timeData && timeData.length){
                        timeData = this.handleTimeDisable(timeData)
                    }
                    if(spaceTypeLists && spaceTypeLists.length){
                        spaceTypeLists[0]['is_choose'] = 1;
                        this.setState({
                            spaceTypeLists,
                            spaceLists,
                            timeData,
                            status_data,
                            currentSpaceType:spaceTypeLists[0]
                        },() => {
                            this.handleTableData()
                        })
                    }else {
                        // message.error('请设置场地类型')
                    }

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    getCourtTimePrice = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        sendData.store_id = currentStore ? currentStore.id : '';
        this.setState({
            isLoading:true
        })
        courtGetTime(sendData)
            .then(res => {
                if(res.code == 1){
                    let time_data = res.data.time_data || [],
                        mix_price = res.data.mix_price || '',
                        rent_price = res.data.rent_price || '',
                        insurance_fee = res.data.insurance_fee || '';
                    this.setState({
                        pop_time_data:time_data,
                        mix_price,rent_price,insurance_fee
                    })



                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchPayType = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100
        this.setState({
            isLoading:true
        })
        payTypeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data;
                    this.setState({
                        payTypes:lists
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading:true
        })
        return new Promise((resolve, reject) => {
            storeLists(sendData)
                .then(res => {
                    if(res.code == 1){
                        let lists = res.data.store.data || [],
                            currentStore = this.state.currentStore || '';
                        if(!currentStore){
                            currentStore = lists[0]
                        }
                        this.setState({
                            allStore:lists,
                            currentStore
                        },() => {
                            resolve()
                        })
                    }else {
                        reject()
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })

    }
    handleStoreChange = (val) => {
        let currentStore = '',
            allStore = this.state.allStore || [];
        if(val){
            allStore.map(item => {
                if(item.id == val){
                    currentStore = item;
                    this.setState({
                        currentStore
                    },() => {
                        this.fetchData();
                    })
                }
            })
        }

    }
    handleToday = () => {
        this.setState({
            currentDate:moment().format('YYYY-MM-DD')
        },() => {
            this.fetchData()
        })
    }
    handleTimeDisable = ( lists ) => {
        let currentDate = this.state.currentDate || '',
            now_stamp = Date.parse(new Date());
        if(!currentDate){
            currentDate = moment().format('YYYY-MM-DD')
        }
        lists.forEach((item,index) => {
            item.fmt = new Date(`${currentDate} ${item.time.split('~')[0]}:00`).getTime();
            if(now_stamp > item.fmt){
                item.disable = 1
            }else {
                item.disable = 0
            }
        })
        return lists
    }
    handleDateChange = e => {
        this.setState({
            currentDate: e ? moment(e).format('YYYY-MM-DD') : ''
        },() => {
            this.fetchData();
        })

    }
    handleSpace = (rowData,index) => {
        let spaceTypeLists = this.state.spaceTypeLists || [];
        spaceTypeLists.forEach((item) => {
            item.is_choose = 0
        })
        spaceTypeLists[index]['is_choose'] = 1;
        this.setState({
            spaceTypeLists,
            currentSpaceType:spaceTypeLists[index]
        },() => {
            this.handleTableData()
        })

    }
    handleAdd = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        this.setState({
            currentRow:null,
            guestOnly:false
        },() => {
            this.AddCourtOrder.show(res => {
                if(res){
                    sendData = res;
                    sendData.store_id = currentStore ? currentStore.id : '';
                    this.setState({
                        isLoading:true
                    })
                    addCourtBook(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('保存成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })

                }
            })
        })

    }
    handleTableData = () => {
        let currentSpaceType = this.state.currentSpaceType || '',
            spaceLists = this.state.spaceLists || [],
            nowSpaceLists = [];
        if (currentSpaceType){
            nowSpaceLists = spaceLists.filter( item => { return item.space_type_id == currentSpaceType.id } )
            this.setState({
                nowSpaceLists
            })
        }
    }
    renderTdStatus = (rowData) => {
        let currentSpaceType = this.state.currentSpaceType,
            nowSpaceLists = this.state.nowSpaceLists,
            status_data = this.state.status_data || {};
        return(
            nowSpaceLists.map( (space,spaceIndex) => (
                <td key={`${currentSpaceType.id}_${space.id}_${rowData.id}`}>
                    <div
                        className={`fake-td status_${ status_data[`${currentSpaceType.id}_${space.id}_${rowData.id}`] || 0 }`}
                    >
                        {
                            !status_data[`${currentSpaceType.id}_${space.id}_${rowData.id}`]
                                ?
                                rowData.disable == 1
                                    ?
                                    '已过期'
                                    :
                                    <span
                                        className={`fi-link`}
                                        onClick={ () => { this.handleBookTime(rowData,space) } }
                                    >可订</span>
                                :
                                ''
                        }
                        {
                            status_data[`${currentSpaceType.id}_${space.id}_${rowData.id}`] == 1
                                ?
                                <span
                                    className={`fi-link`}
                                    style={{ color:'#fff' }}
                                    onClick={ () => { this.handleOrderDetail(rowData,space) } }
                                >锁定</span>
                                :
                                ''
                        }
                        {
                            status_data[`${currentSpaceType.id}_${space.id}_${rowData.id}`] == 2
                                ?
                                <span
                                    className={`fi-link`}
                                    style={{ color:'#fff' }}
                                    onClick={ () => { this.handleOrderDetail(rowData,space) } }
                                >散客</span>
                                :
                                ''
                        }
                        {
                            status_data[`${currentSpaceType.id}_${space.id}_${rowData.id}`] == 3
                                ?
                                <span
                                    className={`fi-link`}
                                    style={{ color:'#fff' }}
                                    onClick={ () => { this.handleCleanDetail(rowData,space) } }
                                >清扫中</span>
                                :
                                ''
                        }


                    </div>
                </td>
            ))
        )
    }
    handleCleanDetail = (rowData,space) => {
        let detail = {},
            sendData = {},
            currentStore = this.state.currentStore || '';
        detail.time_ids = [rowData.id];
        detail.time = rowData.time;
        detail.book_date = this.state.currentDate || moment().format('YYYY-MM-DD')
        detail.space_id = space.id
        this.setState({
            cleanData:detail
        },() => {
            this.CleanDetail.show(res => {
                sendData.store_id = currentStore ? currentStore.id : '';
                sendData.space_id = space.id;
                sendData.space_type_id = space.space_type_id;
                sendData.book_date = detail.book_date;
                sendData.book_time = [{ time:rowData.time, price:'' }]
                this.setState({
                    isLoading:true
                })
                cleanUp(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })



            })
        })
    }
    handleBookTime = (rowData,space) => {
        let detail = {},
            sendData = {},
            currentStore = this.state.currentStore || '';
        detail.time_ids = [rowData.id];
        detail.time_id=rowData.id
        //detail.time = rowData.time;
        detail.book_date = this.state.currentDate || moment().format('YYYY-MM-DD')
        detail.space_id = space.id
        detail.space_type_id = space.space_type_id
        detail.week_id = this.state.currentDate ? moment( this.state.currentDate ).day() : moment().day()
        
        this.setState({
            currentRow:detail,
            guestOnly:false
        },() => {
            this.AddCourtOrder.show(res => {
                if(res){
                    sendData = res;
                    sendData.book_time = [{ time:rowData.time, price:'' }];
                    sendData.store_id = currentStore ? currentStore.id : '';
                    this.setState({
                        isLoading:true
                    })
                    addCourtBook(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('保存成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })

                }
            })
        })
    }
    handleOrderDetail = (rowData,space) => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        sendData.store_id = currentStore ? currentStore.id : '';
        sendData.space_id = space.id;
        sendData.space_type_id = space.space_type_id;
        sendData.book_date = this.state.currentDate || moment().format('YYYY-MM-DD')
        sendData.book_time = rowData.time 
        this.setState({
            isLoading:true
        })
        courtBookingOrder(sendData)
            .then(res => {
                if(res.code == 1){
                    let trade_lists = res.data.trade_lists.data || [];
                    this.setState({
                        trade_lists:trade_lists,
                        drawerVisible:true
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible:false
        })
    }
    handleCash = (rowData) => {
        let detail = {},
            sendData = {};
        detail.book_type = rowData.space_info?.space_use_type;
        detail.mobile = rowData.space_info?.phone || '';
        detail.user_name = rowData.space_info?.name || '';
        detail.real_name = rowData.space_info?.real_name || '';
        detail.id_number = rowData.space_info?.id_number || '';
        detail.space_id = rowData.space_info?.space_id || '';
        detail.book_date = this.state.currentDate || moment().format('YYYY-MM-DD')
        detail.time_id = rowData.book_info.time_ids ? rowData.book_info.time_ids[0] : ''
        detail.time_ids = rowData.book_info?.time_ids || [];
        detail.time = rowData.time;
        detail.buy_safety = rowData.space_info?.is_buy_insurance;
        detail.space_type_id = rowData.space_info?.space_type_id;
        detail.pay_ment  = rowData.pay_ment;
        detail.pay_type_id = rowData.pay_type_id || ''
        detail.is_need_bill = rowData.space_info?.is_need_bill
        detail.bill_head = rowData.space_info?.bill_head
        detail.bill_account_number = rowData.space_info?.bill_account_number
        detail.bill_email = rowData.space_info?.bill_email
        detail.week_id = this.state.currentDate ? moment( this.state.currentDate ).day() : moment().day()
        detail.isCash = 1;
        this.setState({
            currentRow:detail,
            guestOnly:false,
        },() => {
            this.AddCourtOrder.show(res => {
                if(res){
                    sendData.order_sn = rowData.order_sn;
                    sendData.pay_type_id = res.pay_type_id;
                    sendData.is_need_bill = res.is_need_bill;
                    sendData.bill_head = res.bill_head;
                    sendData.bill_account_number = res.bill_account_number;
                    sendData.bill_email = res.bill_email;
                    this.setState({
                        isLoading:true
                    })
                    courtCash(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(res => {
                                        this.handleDrawerClose()
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })

                }
            })
        })

    }
    handleCancel = (rowData) => {
        let sendData = {}
        sendData.order_sn = rowData.order_sn || '';
        Modal.confirm({
            title:`是否取消订单`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                courtCancelOrder(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.handleDrawerClose()
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleGuestBook = (rowData) => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        this.handleDrawerClose()
        this.setState({
            currentRow:null,
            guestOnly:true
        },() => {
            this.AddCourtOrder.show(res => {
                if(res){
                    sendData = res;
                    sendData.store_id = currentStore ? currentStore.id : '';
                    this.setState({
                        isLoading:true
                    })
                    addCourtBook(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('保存成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })

                }
            })
        })

    }


    render() {
        let allStore = this.state.allStore || [],
            currentStore = this.state.currentStore || {},
            spaceTypeLists = this.state.spaceTypeLists || [],
            nowSpaceLists = this.state.nowSpaceLists || [],
            timeData = this.state.timeData || [];
        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }
        return(
            <div className={`shadow-radius`}>
                <AddCourtOrder
                    onRef={ e => { this.AddCourtOrder = e } }
                    spaceLists={ this.state.spaceLists }
                    mix_price={ this.state.mix_price }
                    rent_price={ this.state.rent_price }
                    time_data={ this.state.pop_time_data }
                    payTypes={ this.state.payTypes.filter(item => { return item.status == 1 }) }
                    insurance_fee={ this.state.insurance_fee }
                    data={ this.state.currentRow }
                    guestOnly={ this.state.guestOnly }
                ></AddCourtOrder>
                <CleanDetail
                    onRef={ e => { this.CleanDetail = e } }
                    spaceLists={ this.state.spaceLists }
                    mix_price={ this.state.mix_price }
                    rent_price={ this.state.rent_price }
                    time_data={ this.state.pop_time_data }
                    payTypes={ this.state.payTypes.filter(item => { return item.status == 1 }) }
                    insurance_fee={ this.state.insurance_fee }
                    data={ this.state.cleanData }
                ></CleanDetail>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'选择门店'} >
                            <Select style={{width:200}} placeholder={'请选择'} value={ currentStore.id } onChange={ this.handleStoreChange }>
                                {
                                    allStore.map(store => (
                                        <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                    ))
                                }
                            </Select>
                        </FormItem>
                    </Form>
                    <div className={`clearfix`}></div>
                </div>
                <div className={`ktv-btn-group mgb15`}>
                    {
                        spaceTypeLists.map((item,index) => (
                            <div className={`ktv-btn`} key={index}>
                                <Button
                                    type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                    onClick={ () => { this.handleSpace(item,index) } }
                                >
                                    <div>
                                        { item.name }
                                    </div>
                                </Button>
                            </div>
                        ))
                    }
                </div>
                <div className={`clearfix`}>
                    <div className={`fl`}>
                        <DatePicker
                            className={``}
                            value={ this.state.currentDate ? moment(this.state.currentDate, 'YYYY-MM-DD') : null }
                            onChange={ this.handleDateChange }
                        />
                        <Button type={`primary mgl10`} ghost={true} onClick={ this.handleToday }>返回今天</Button>
                    </div>
                    <div className={`fr`}>
                        <span className={`court-badge mgl20`}>
                            <span className={`badge-status`}></span>
                            <span className={`badge-text`}>可订</span>
                        </span>
                        <span className={`court-badge mgl20 lock`}>
                            <span className={`badge-status`}></span>
                            <span className={`badge-text`}>锁定</span>
                        </span>
                        <span className={`court-badge mgl20 guest`}>
                            <span className={`badge-status`}></span>
                            <span className={`badge-text`}>散客</span>
                        </span>
                        <span className={`court-badge mgl20 clean`}>
                            <span className={`badge-status`}></span>
                            <span className={`badge-text`}>清扫中</span>
                        </span>
                        <Button type={'primary mgl20'} onClick={this.handleAdd} icon={'plus'}>添加预约</Button>
                    </div>
                </div>
                <div className={`clearfix mgt15`}>
                    <table className="cxtables cxtables-sku">
                        <thead className={`court-thead`}>
                            <tr className={`txtCenter`}>
                                <td>时段/场地</td>
                                {
                                    nowSpaceLists.map((item,index) => (
                                        <td key={item.id}>{ item.name }</td>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className={`court-tbody`}>
                        {
                            timeData.map((item,index) => (
                                <tr className={`cbMoney`} key={index}>
                                    <td>
                                        <div className={`fake-td`}>{ item.time }</div>
                                    </td>
                                    {
                                        this.renderTdStatus(item)
                                    }
                                </tr>
                            ))
                        }
                        </tbody>

                    </table>

                </div>
                { this.state.isLoading ? <Loading /> : null }
                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`动态详情`}
                            closable={true}
                            width={`75%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={ this.handleDrawerClose }
                            headerStyle={{borderBottom:'0px'}}
                            bodyStyle={{padding:'0px'}}
                        >
                            <CourtStatusDetail
                                onRef={ e => { this.CourtStatusDetail = e } }
                                lists={ this.state.trade_lists || [] }
                                onCash = { (e) => { this.handleCash(e) } }
                                onCancel={ (e) => { this.handleCancel(e) } }
                                onAddBook={ (e) => { this.handleGuestBook(e) } }
                            ></CourtStatusDetail>
                        </Drawer>
                        :
                        null
                }
            </div>
        )
    }
}

export default Form.create({})(withRouter(CourtBookingStatus))
