import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter, Route, Switch } from 'react-router-dom';
import { routes } from '@/router/routes'
import { Layout } from 'antd';
const { Content } = Layout


const MainContent = ({ location }) => {
    return(
        <Content >
            <Switch>
                {
                    routes.map(ele => {
                        return(
                            <Route
                                render={ () => { return(<ele.component />)} }
                                key={ele.path}
                                path={ele.path}>
                            </Route>
                        )
                    })
                }
                <Redirect from='/' exact to="/dashboard"  />
                <Redirect to="/error/404" />
            </Switch>
        </Content>
    )
}

const mapStateToProps = state => ({ userInfo:state.userInfo })


export default withRouter(connect(mapStateToProps)(MainContent))
