import React from 'react'
import { Form, Input, Checkbox, Icon, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { courtStoreDetail, addCourtTag, delCourtTag, saveCourtDesc } from '@/api/court'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import Editor from '@/components/Editor/Editor'

import '@/assets/css/court/court.scss'
const FormItem = Form.Item
const { TextArea } = Input


class CourtDetail extends React.Component{
    state = {
        formData:'',
        showEditor:false,
        tagLists:[],
        isLoading:false,
        showAddTag:false,
        newTagName:'',
        newTagCheck:false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = (params = {}) => {
        let sendData = {}
        if(this.props.match.params.id){
            this.setState({
                isLoading:true
            })
            sendData.id = this.props.match.params.id
            courtStoreDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.store,
                            tag_list = res.data.tag_list;
                        for(var i in detail.facilities_tag_ids){
                            detail.facilities_tag_ids[i] = Number(detail.facilities_tag_ids[i])
                        }
                        this.setState({
                            formData:detail,
                            tagLists:tag_list,
                            showEditor:true
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        }

    }
    handleCheckChange = (e,item,index) => {
        let formData = this.state.formData || {},
            facilities_tag_ids = formData.facilities_tag_ids || [],
            val = e.target.checked,
            tag_id = item.id;
        if(val){
            if(!facilities_tag_ids.includes(tag_id)){
                facilities_tag_ids.push(tag_id)
            }
        }else {
            if(facilities_tag_ids.includes(tag_id)){
                for(var i in facilities_tag_ids){
                    if(facilities_tag_ids[i] == tag_id){
                        facilities_tag_ids.splice(i,1)
                    }
                }
            }
        }
        formData.facilities_tag_ids = facilities_tag_ids;
        this.setState({
            formData
        })

    }
    delTag = (item,index) => {
        let sendData = {},
            tagLists = this.state.tagLists || [],
            formData = this.state.formData || {},
            facilities_tag_ids = formData.facilities_tag_ids || [];
        sendData.store_id = this.props.match.params.id;
        sendData.id = item.id;
        this.setState({
            isLoading:true
        })
        delCourtTag(sendData)
            .then(res => {
                if(res.code == 1){
                    tagLists.splice(index,1)
                    if(facilities_tag_ids.includes(sendData.id)){
                        for(var i in facilities_tag_ids){
                            if(facilities_tag_ids[i] == sendData.id){
                                facilities_tag_ids.splice(i,1)
                            }
                        }
                        formData.facilities_tag_ids = facilities_tag_ids;
                        this.setState({
                            formData
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.buy_notes = content;
        this.setState({
            formData
        })

    }
    handleAddTag = () => {
        this.setState({
            showAddTag:true
        })
    }
    handleCloseTag = () => {
        this.setState({
            showAddTag:false
        })
    }
    handleInputChange = (e) => {
        let val = e.target.value,
            sendData = {};
        sendData.store_id = this.props.match.params.id;
        sendData.name = val;
        if(val){
            this.setState({
                isLoading:true
            })
            addCourtTag(sendData)
                .then(res => {
                    if(res.code == 1){
                        let tag = res.data.tag,
                            tagLists = this.state.tagLists || [];
                        tagLists.push(tag);
                        this.setState({
                            tagLists,
                            showAddTag:false
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        }


    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
                for(var i in formData){
                    if(!sendData[i]){
                        sendData[i] = formData[i]
                    }
                }
                sendData.store_id = this.props.match.params.id;
                sendData.facilities_tag_ids = sendData.facilities_tag_ids.join(',')
                this.setState({
                    isLoading:true
                })
                saveCourtDesc(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/court/manage`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }


    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return(
            <div className={'shadow-radius'}>
                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`msg-title mgb15`}>场地详情</div>
                    <FormItem label={`场馆简介`}>
                        {
                            getFieldDecorator('desc',{
                                initialValue: data.desc || ``,
                                rules: []
                            })(
                                <TextArea placeholder={`请输入`} rows={8}></TextArea>
                            )
                        }
                        <span className={`fi-help-text`}></span>
                    </FormItem>
                    <FormItem label={`场馆设施服务`}>
                        {
                            this.state.tagLists.map((item,index) => (
                                <div className={`court-tag`} key={index}>
                                    <Checkbox
                                        checked={ data.facilities_tag_ids.includes(item.id) }
                                        onChange={(e) => { this.handleCheckChange(e,item,index) }}
                                    >{ item.name }</Checkbox>
                                    {
                                        item.shop_id != 0
                                            ?
                                            <Icon type="close-circle" theme="filled"  className={`court-delete-icon`} onClick={() => { this.delTag(item,index) }}   />
                                            :
                                            null
                                    }
                                </div>
                            ))
                        }
                        {
                            this.state.showAddTag
                                ?
                                <div className={`court-tag`}>
                                    <Checkbox></Checkbox>
                                    <Input type={`text`} className={`mgl10`} style={{ width:'80px' }} size={`small`} onBlur={ this.handleInputChange } />
                                    <Icon type="close-circle" theme="filled"  className={`court-delete-icon`} onClick={ this.handleCloseTag }   />
                                </div>
                                :
                                null
                        }

                        <Button type={`primary`} ghost={true} icon={`plus`} onClick={  this.handleAddTag }>自定义</Button>
                    </FormItem>
                    <FormItem label={`购买须知`}>
                        {
                            this.state.showEditor
                                ?
                                <Editor id={'text'} value={data.buy_notes} callback={ content => this.EditorChange(content) }></Editor>
                                :
                                null
                        }
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={`primary`} onClick={ this.saveData }>立即保存</Button>
                    </FormItem>


                </Form>

                { this.state.isLoading ? <Loading/> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(CourtDetail))

