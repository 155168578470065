import React, { Component } from 'react'
import { Modal, Button, Form, Select, message, InputNumber, Radio, Input,DatePicker } from 'antd';
import { couponLists } from '@/api/coupon'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import moment from 'moment';

const { RangePicker } = DatePicker;

const point_img = "https://img.cxkoo.com/chengxuan/1/2023-05-05/cec690777438e1b2b91c3fb520ea42b2.png";
const coupon_img = "https://img.cxkoo.com/chengxuan/1/2023-05-05/f59d948d3abb9c7118957b4e58866a7c.png";
const wx_img = "https://img.cxkoo.com/chengxuan/1/2023-05-05/6a7c27fb69ea43935e779b0a3e445e28.png";

const FormItem = Form.Item
class PrizeAdd extends Component {
    state = {
        visible:false,
        couponLists: [],
        formData: {
            prize_img: point_img
        },
        callBack:null,
    }

    componentDidMount() {
        this.fetchCoupon()
        if(this.props.onRef){
            this.props.onRef(this)
        }
    }

    show=(callBack,row)=>{

        if(row  && row.exchange_time_type == 2){
            row.exchange_time=[moment(row.exchange_start_time,'YYYY-MM-DD HH:mm:ss'),moment(row.exchange_end_time,'YYYY-MM-DD HH:mm:ss')]
        }
        
        this.setState({
            callBack,
            formData:row ||{prize_img: point_img},
            visible:true
        })
    }

    fetchCoupon = () => {
        let sendData = {
            page: 1,
            per_page: 100,
            status: 1
        };

        couponLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.coupon_list.data;

                    lists = lists.filter(val => val.last_num > 0)
                    this.setState({
                        couponLists: lists,
                    })
                }
            })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }


    handleok = () => {
        let {formData,callBack}=this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                
                if(values.exchange_time_type == 2){
                    values.exchange_start_time = values.exchange_time ? moment(values.exchange_time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                    values.exchange_end_time = values.exchange_time ? moment(values.exchange_time[1]).format('YYYY-MM-DD HH:mm:ss') : ''
                    if(values.exchange_time){
                        delete values.exchange_time;
                    }
                    
                }


                values.prize_img=formData.prize_img;

                if(!values.prize_img){
                    return message.error('奖品图片为必填字段')
                }

                if(formData.give_out_num){
                    values.give_out_num=formData.give_out_num
                }else{
                    values.give_out_num=0;
                }


                if(formData.id){
                    values.id=formData.id;
                }else{
                    values.id=Date.now().toString(36)
                }

                if(typeof callBack == 'function'){
                    callBack(values)
                    this.handleCancel()
                }
        
            }
        })
    }

    handleCancel = () => {
        this.setState({
            visible:false
        })
    }

    handleChange = (val) => {
        console.log(val);
        let formData = this.state.formData || {};
        if (val == 1) {
            formData['prize_img'] = point_img;
        } else if (val == 2) {
            formData['prize_img'] = coupon_img;
        } else if (val == 4) {
            formData['prize_img'] = wx_img;
        }else {
            formData['prize_img'] = '';
        }
        this.setState({
            formData
        })
    }
    handleReset=(val)=>{
        let formData = this.state.formData || {};
        formData['prize_img']=''
        this.setState({
            formData
        },()=>{
            this.handleChange(val)
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

       
        const data = this.state.formData;

        let is_disabled=false;
        if(this.props.is_disabled && data.id){
            is_disabled=true
        }

        return (
            <Modal
                title="添加奖品"
                visible={this.state.visible}
                onOk={this.handleok}
                onCancel={this.handleCancel}
                width={700}
                destroyOnClose={true}
            >
                <Form className={`model-form`} {...formItemLayout}>

                    <FormItem label={"奖品类型"} >
                        {getFieldDecorator('prize_type', {
                            initialValue: data.prize_type || 1,
                        })(
                            <Radio.Group onChange={e=>{this.handleChange(e.target.value)}} disabled={is_disabled} >
                                <Radio value={1}>积分 </Radio>
                                <Radio value={2}>优惠券 </Radio>
                                <Radio value={3}>实物奖 </Radio>
                                <Radio value={4}>微信零钱 </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>

                    <FormItem label={'奖品名称'}>
                        {getFieldDecorator('prize_name', {
                            initialValue: data.prize_name || undefined,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入奖品名称'
                                }
                            ]
                        })(<Input
                            style={{ width: 400 }}
                            placeholder={'请输入奖品名称'}
                            maxLength={6}
                            suffix={<span>{getFieldValue("prize_name") ? getFieldValue("prize_name").length : 0}/6</span>}
                        />)}
                    </FormItem>

                    {
                        getFieldValue("prize_type") == 1 ?
                            <FormItem label={'积分'} >
                                {getFieldDecorator('points', {
                                    initialValue: data.points || undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入奖励的积分数'
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        min={0}
                                        precision={0}
                                        style={{ width: 400 }}
                                        placeholder={'请输入奖励的积分数'}
                                    />
                                )}
                            </FormItem> : null
                    }

                    {
                        getFieldValue("prize_type") == 2 ?
                            <FormItem label={"赠送优惠券"} >
                                {getFieldDecorator('coupon_id', {
                                    initialValue: data.coupon_id || undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择优惠券'
                                        }
                                    ]
                                })(
                                    <Select style={{ width: 400 }} placeholder={'请选择'}>
                                        {this.state.couponLists.map(val => {
                                            return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                        })}
                                    </Select>
                                )}
                            </FormItem>
                            : null
                    }

                    {
                        getFieldValue("prize_type") == 4 ?
                            <FormItem label={'微信零钱'}>
                                {getFieldDecorator('wallet_money', {
                                    initialValue: data.wallet_money || undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入赠送的微信零钱'
                                        }
                                    ]
                                })(
                                    <InputNumber
                                        style={{ width: 400, paddingRight: 100 }}
                                        min={1}
                                        precision={2}
                                        placeholder={'请输入赠送的微信零钱'}
                                    />
                                )}
                               
                                <span className={'fi-help-text'}>请确保您开通了企业支付到零钱功能，且零钱金额不能小于1元</span>
                            </FormItem>
                            : null
                    }

                    <FormItem label={`奖品图片`} required={true}>
                        <div className={'clearfix'}>
                            {
                                data.prize_img
                                    ?
                                    <PictureCard
                                        onRef={e => { this.PictureCard = e }}
                                        imgs={data.prize_img}
                                        onDel={e => { this.delImg(e, 'prize_img') }}
                                    ></PictureCard>
                                    :
                                    <ImgAdd
                                        onRef={e => { this.ImgAdd = e }}
                                        onClick={e => { this.chooseImg(e, 'prize_img') }}
                                    ></ImgAdd>
                            }
                            {
                                getFieldValue("prize_type") != 3?
                                <Button type="link" className='mgt60' onClick={()=>{this.handleReset(getFieldValue("prize_type"))}} >恢复默认</Button>:null
                            }
                            
                        </div>
                        <span className={'fi-help-text'}>建议尺寸：100X100像素</span>
                    </FormItem>

                    <FormItem label={'奖品发放数量'}>
                        {getFieldDecorator('prize_num', {
                            initialValue: data.prize_num || undefined,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入奖品数量'
                                }
                            ]
                        })(
                            <InputNumber
                                style={{ width: 400, paddingRight: 100 }}
                                min={is_disabled? (data.prize_num|| 0):0}
                                precision={0}
                                placeholder={'请输入奖品数量'}
                            />
                        )}
                        <span className={'fi-help-text'}>奖品数量必填，0表示0件奖品</span>
                    </FormItem>

                    {
                        getFieldValue("prize_type") == 3 ?
                            <>
                                <FormItem label={"兑奖方式"} >
                                    {getFieldDecorator('exchange_type', {
                                        initialValue: data.exchange_type || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择兑奖方式'
                                            }
                                        ]
                                    })(
                                        <Radio.Group >
                                            <Radio value={1}>快递</Radio>
                                            <Radio value={2}>上门自取</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>

                                <FormItem label={"兑奖期限"} >
                                    {getFieldDecorator('exchange_time_type', {
                                        initialValue: data.exchange_time_type || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择兑奖期限'
                                            }
                                        ]
                                    })(
                                        <Radio.Group >
                                            <Radio value={1}>活动结束前都可领奖</Radio>
                                            <Radio value={2}>固定期限</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>

                                {
                                    getFieldValue("exchange_time_type") == 2 ?
                                        <FormItem label={' '} className={'no-colon'}>
                                            {getFieldDecorator('exchange_time', {
                                                initialValue: data.exchange_time || null,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择兑奖时间'
                                                    }
                                                ]
                                            })(
                                                <RangePicker
                                                    style={{ width: 400 }}
                                                    showTime={{ format: 'HH:mm:ss' }}
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    placeholder={['开始时间', '结束时间']}
                                                />
                                            )}
                                        </FormItem>
                                        : null
                                }

                            </>
                            : null
                    }


                </Form>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
            </Modal>
        )
    }
}

export default Form.create({})(PrizeAdd)
