import React from 'react'
import { Modal, Input, Button, Table, Pagination, Tag } from 'antd'
import { Link } from 'react-router-dom'
import CX from '@/utils/CX'

export default class ViewersList extends React.Component{

    state = {
        title:'观演人',
        visible:false,
        modalLoading:false,
        tableLoading:false,
        columns:[
            {
                title:'姓名',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            {rowData.name}
                        </div>
                    )
                }
            },
            {
                title:'身份证',
                dataIndex:'ID',
                render:(text, rowData, index) => {
                    
                    return (
                        <div>
                            {rowData.certificate_number}
                        </div>
                    )
                }
            },
            
        ],
        tableData:[],
        selectRows:[],
        selectedRowKeys:[],
        page:1,
        name:'',
        per_page:10,
        total:0,
        callback:null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    show = (list=[]) => {
        this.setState({
            visible:true,
            tableData:list
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
   
  
    handleOk = () => {
        this.close();
    }
    handleCancel = () => {
        this.close()
    }
  
   

    render() {
       
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={915}
                    maskClosable={false}
                    confirmLoading={this.state.modalLoading}
                    centered={true}
                >
        
                    <div className={'mgt20'}>
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={record => record.id}
                            pagination={false}
                        >
                        </Table>
                    </div>

                </Modal>
            </div>
        )

    }

}


