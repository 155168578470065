import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Switch } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import message from '@/utils/message'
import '@/assets/css/item/item.scss'
const FormItem = Form.Item
const { TextArea } = Input

class SetItemDiscount extends React.Component{
    state = {
        title:'设置活动折扣',
        visible:false,
        modalLoading:false,
        callback:null,
        tableData:[],
        sku:{},
        skuCache:{},
        props:{}, //属性
        norms:[], //规格
        currentItem:'',
        patch_stock:'',
        patch_discount:'',
        patch_cut_price:'',
        patch_sales_price:'',
        patch_join:false

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        },() => {
            this.initSku()
        })
    }
    initSku = () => {
        let item = this.props.item || {};
        if(item.sku_list && item.sku_list.length){
            let norms = item.norms,
                props = item.props,
                sku = this.state.sku,
                skuCache = this.state.skuCache;
            item.sku_props = {};
            item.sku_list.forEach((child,childKey) => {
                let props = child.props;
                child.props_arr = Object.values(props);
                item.sku_props[Object.values(props).join(';')] = child;
            })
            skuCache = sku = item.sku_props;
            this.setState({
                norms,
                props,
                skuCache,
                sku
            },() => {
                this.combinaSku()
            })
        }
        this.setState({
            currentItem:this.props.item
        })
    }
    combinaSku = () => {
        //组合sku
        let keys=[],
            norms = this.state.norms || [],
            sku = this.state.sku || {}
        norms.forEach((item,index) => {
            if(item.props && item.props.length){
                keys.push(item.props);
            }
        })
        sku = {};
        this.setState({
            sku
        },() => {
            //向sku中插入数据，并更新skuCache缓存
            switch(keys.length){
                case 0:
                    break;
                case 1:
                    for(var i=0;i<keys[0].length;i++){
                        var arr=[keys[0][i]];
                        this.addSku(arr.join(";"));
                    }
                    break;
                case 2:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            var arr=[keys[0][i],keys[1][j]];
                            this.addSku(arr.join(";"));
                        }
                    }
                    break;
                case 3:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                var arr=[keys[0][i],keys[1][j],keys[2][k]];
                                this.addSku(arr.join(";"));
                            }
                        }
                    }
                    break;
                case 4:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n]];
                                    this.addSku(arr.join(";"));
                                }

                            }
                        }
                    }
                    break;
                case 5:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    for(var m=0;m<keys[4].length;m++){
                                        var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n],keys[4][m]];
                                        this.addSku(arr.join(";"));
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        })

    }
    addSku = (key,val) => {
        let skuCache = this.state.skuCache || {},
            sku = this.state.sku || {},
            itemInfo = this.props.item || {};
        //添加sku
        if(!skuCache[key]){
            let tmparr=key.split(";"),
                defaultSku = { market_price:'',price:'',basic_sales:'',cover_img:'',weight:'',goods_sku_code:'',store_price:[], props:tmparr };
            defaultSku.price = itemInfo.price || ``;
            defaultSku.market_price = itemInfo.market_price || ``;
            defaultSku.basic_sales = itemInfo.basic_sales || ``;
            defaultSku.weight = itemInfo.weight || ``;
            skuCache[key]= sku[key] = defaultSku;
        }else {
            sku[key] = skuCache[key];
        }


        let tableData = [];
        for(var i in sku){
            sku[i]['sku_key'] = i
            tableData.push(sku[i])
        }
        this.setState({
            sku,skuCache,tableData
        },() => {
        })
    }
    getInputValue = (e,rowData,index) => {
        let name = e.currentTarget.dataset.name || ``,
            value = e.target.value,
            tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        let floatValue = value ? parseFloat(value) : '';
        switch (name) {
            case 'discount':
                //打折
                if(value > 10){
                    value = 10
                }
                skuCache[sku_key][name] = sku[sku_key][name] = value;
                if(floatValue){
                    skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] =  (sku[sku_key]['price'] * floatValue / 10).toFixed(2)
                    skuCache[sku_key]['cut_price'] = ( sku[sku_key]['price'] - skuCache[sku_key]['sales_price'] ).toFixed(2)
                    if(skuCache[sku_key]['sales_price'] < 0){
                        skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] = 0
                    }
                }

                break;
            case 'cut_price':
                //减价
                skuCache[sku_key][name] = sku[sku_key][name] = value;
                if(floatValue){
                    skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] =  (sku[sku_key]['price'] - floatValue).toFixed(2)
                    skuCache[sku_key]['discount'] = (skuCache[sku_key]['sales_price'] * 10 / sku[sku_key]['price'] ).toFixed(2)
                    if(skuCache[sku_key]['sales_price'] < 0){
                        skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] = 0
                    }
                }
                break;
            case 'sales_price':
                //折后价
                skuCache[sku_key][name] = sku[sku_key][name] = value;
                if(floatValue){
                    skuCache[sku_key]['cut_price'] = (sku[sku_key]['price'] - floatValue).toFixed(2);
                    skuCache[sku_key]['discount'] = (floatValue * 10 / sku[sku_key]['price']).toFixed(2);
                }
                break;
            default:
                skuCache[sku_key][name] = sku[sku_key][name] = value;
                break;
        }
        this.setState({
            sku,skuCache
        })
    }
    handleIsJoin = (e,rowData,index) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        skuCache[sku_key]['is_join'] = sku[sku_key]['is_join'] = e;
        this.setState({
            sku,skuCache
        })
    }
    handleDisable = (rowData,index) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        skuCache[sku_key]['edit_cut'] = sku[sku_key]['edit_cut'] = !rowData['edit_cut'];
        this.setState({
            sku,skuCache
        })
    }
    handlePatchSet = (e,name) => {
        let val = e.target.value,
            tableData = this.state.tableData,
            sku = this.state.sku,
            keyName = `patch_${name}`,
            skuCache = this.state.skuCache;
        let floatValue = val ? parseFloat(val) : '';
        if(floatValue){
            switch (name) {
                case 'discount':
                    //批量设置折扣
                    for(var i in tableData){
                        let sku_key = tableData[i]['sku_key'];
                        skuCache[sku_key][name] = sku[sku_key][name] = floatValue;
                        skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] = (sku[sku_key]['price'] * floatValue / 10).toFixed(2);
                        skuCache[sku_key]['cut_price'] = sku[sku_key]['cut_price'] = (sku[sku_key]['price'] - skuCache[sku_key]['sales_price']).toFixed(2);
                    }
                    break;
                case 'cut_price':
                    //批量设置减价
                    for(var i in tableData){
                        let sku_key = tableData[i]['sku_key'];
                        skuCache[sku_key][name] = sku[sku_key][name] = floatValue;
                        skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] = (sku[sku_key]['price'] - floatValue).toFixed(2);
                        skuCache[sku_key]['discount'] = sku[sku_key]['discount'] = ( skuCache[sku_key]['sales_price'] * 10 / sku[sku_key]['price']).toFixed(2);
                        if(skuCache[sku_key]['sales_price'] < 0){
                            skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] = 0
                        }
                    }
                    break;
                case 'sales_price':
                    //批量设置现价
                    for(var i in tableData){
                        let sku_key = tableData[i]['sku_key'];
                        skuCache[sku_key][name] = sku[sku_key][name] = floatValue;
                        skuCache[sku_key]['cut_price'] = sku[sku_key]['cut_price'] = (sku[sku_key]['price'] - floatValue).toFixed(2);
                        skuCache[sku_key]['discount'] = sku[sku_key]['discount'] = ( floatValue * 10 / sku[sku_key]['price']).toFixed(2);

                    }
                    break;
                default:
                    for(var i in tableData){
                        let sku_key = tableData[i]['sku_key'];
                        skuCache[sku_key][name] = sku[sku_key][name] = floatValue;
                    }
                    break;
            }
        }
        let datas = {};
        datas['sku'] = sku;
        datas['skuCache'] = skuCache;
        datas[keyName] = val;
        this.setState(datas)
    }
    handlePatchJoin = (e) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache;
        for(var i in tableData){
            let sku_key = tableData[i]['sku_key'];
            skuCache[sku_key]['is_join'] = sku[sku_key]['is_join'] = e;
        }

        this.setState({
            patch_join:e,
            sku,skuCache
        })
    }
    handleIntChange = (e) => {
        let value = Number(e.target.value),
            tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache;
        if( sku && Object.keys(sku).length){
            switch (value) {
                case 1:
                    //不抹分角
                    break;
                case 2:
                    //抹分
                    for(var i in tableData){
                        let sku_key = tableData[i]['sku_key'];
                        if(skuCache[sku_key]['sales_price']){
                            skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] =   Math.floor(Number(sku[sku_key]['sales_price']) * 10 ) /10
                        }
                    }
                    break;
                case 3:
                    //抹分抹角
                    for(var i in tableData){
                        let sku_key = tableData[i]['sku_key'];
                        if(skuCache[sku_key]['sales_price']){
                            skuCache[sku_key]['sales_price'] = sku[sku_key]['sales_price'] =  Math.floor(sku[sku_key]['sales_price']);
                        }
                    }
                    break;
            }
            this.setState({
                sku,skuCache
            })
        }else {
            let currentItem = this.state.currentItem;
            switch (value) {
                case 1:
                    break;
                case 2:
                    if(currentItem['sales_price']){
                        currentItem['sales_price'] =  (Math.floor(Number(currentItem['sales_price']) * 10 ) /10 ).toFixed(1)
                    }
                    break;
                case 3:
                    if(currentItem['sales_price']){
                        currentItem['sales_price'] =   Math.floor(currentItem['sales_price']);
                    }
                    break;
            }
            this.setState({
                currentItem
            })
        }


    }
    handleCommonChange = (e,name) => {
        let val = e.target.value,
            currentItem = this.state.currentItem || {};
        let floatValue = val ? parseFloat(val) : '';
        switch (name) {
            case 'discount':
                currentItem[name] = val;
                if (floatValue){
                    currentItem.sales_price = (currentItem.price * floatValue / 10).toFixed(2);
                }
                break;
            case 'cut_price':
                currentItem[name] = val;
                if(floatValue){
                    currentItem.sales_price = (currentItem.price - floatValue).toFixed(2);
                    if( currentItem.sales_price < 0){
                        currentItem.sales_price  =  0
                    }
                }
                break;
            default:
                currentItem[name] = val;
                break;
        }
        this.setState({
            currentItem
        })

    }
    toDo = () => {}
    close = () => {
        this.setState({
            visible:false,
            tableData:[],
            sku:{},
            skuCache:{},
            props:{}, //属性
            norms:[], //规格
            patch_stock:'',
            patch_discount:'',
            patch_cut_price:'',
            patch_sales_price:'',
            patch_join:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            callback = this.state.callback,
            currentItem = this.state.currentItem,
            sku = this.state.sku;
        this.props.form.validateFields((err,values) => {
            if(!err){
                res = values;
                res.item = currentItem
                if(typeof callback == 'function'){
                    callback(res)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let currentItem = this.state.currentItem || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 21
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            Object.keys(currentItem).length
                ?
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={1100}
                    centered={true}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                >
                    <Form className={`model-form`} { ...formItemLayout }>
                        <FormItem label={`商品名称`} required={true}>
                            <div>
                                <p>{ currentItem.name }</p>
                                {
                                    currentItem.props
                                        ?
                                        <table className="cxtables cxtables-sku">
                                            <thead>
                                            <tr>
                                                {
                                                    this.state.norms.map((item, index) => (
                                                        <td key={index}>{item.name}</td>
                                                    ))
                                                }
                                                <td>是否参与</td>
                                                <td>商品库存</td>
                                                <td>零售价</td>
                                                <td>活动库存</td>
                                                <td>设置折扣</td>
                                                <td>活动价</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.tableData.map((item, index) => (
                                                    <tr key={index} className="cbMoney">
                                                        {
                                                            this.state.tableData[index].props_arr.map((k, v) => (
                                                                <td key={v}>{this.state.props[k]}</td>
                                                            ))
                                                        }
                                                        <td>
                                                            <Switch checked={!!item.is_join } onChange={ (e) => { this.handleIsJoin(e,item,index) } }></Switch>
                                                        </td>
                                                        <td>
                                                            { item.sku_stock }
                                                        </td>
                                                        <td>
                                                            &yen;{item.price}
                                                        </td>
                                                        <td>
                                                            <Input
                                                                placeholder={'活动库存'}
                                                                style={{ width:'100px' }}
                                                                data-name={`stock`}
                                                                value={item.stock}
                                                                onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <span onClick={ () => { item.edit_cut ?  this.handleDisable(item,index) : this.toDo() } }>
                                                                  <Input
                                                                      addonBefore={`打折`}
                                                                      placeholder={'折'}
                                                                      style={{ width:'130px',textAlign:'right' }}
                                                                      data-name={`discount`}
                                                                      value={item.discount}
                                                                      disabled={ item.edit_cut || !item.is_join }
                                                                      onChange={(e) => { this.getInputValue(e,item,index) }}
                                                                  />
                                                            </span>
                                                            &emsp;
                                                            <span onClick={ () => { !item.edit_cut ? this.handleDisable(item,index) : this.toDo() } }>
                                                               <Input
                                                                   addonBefore={`减价`}
                                                                   placeholder={'元'}
                                                                   style={{ width:'130px',textAlign:'right' }}
                                                                   data-name={`cut_price`}
                                                                   value={item.cut_price}
                                                                   disabled={ !item.edit_cut || !item.is_join }
                                                                   onChange={(e) => { this.getInputValue(e,item,index) }}
                                                               />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                addonBefore={`折后`}
                                                                placeholder={'元'}
                                                                style={{ width:'130px',textAlign:'right' }}
                                                                data-name={`sales_price`}
                                                                value={item.sales_price}
                                                                onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            <tr className="cbMoney">
                                                <td>
                                                    批量设置
                                                </td>
                                                <td>
                                                    <Switch checked={ !!this.state.patch_join } onChange={ (e) => { this.handlePatchJoin(e) } }></Switch>
                                                </td>
                                                <td colSpan={this.state.norms.length + 1}></td>
                                                <td>
                                                    <Input
                                                        placeholder={'活动库存'}
                                                        style={{ width:'100px' }}
                                                        data-name={`stock`}
                                                        value={ this.state.patch_stock }
                                                        onChange={(e) => { this.handlePatchSet(e,'stock') }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        addonBefore={`打折`}
                                                        placeholder={'折'}
                                                        style={{ width:'130px',textAlign:'right' }}
                                                        data-name={`discount`}
                                                        value={ this.state.patch_discount }
                                                        onChange={(e) => { this.handlePatchSet(e,'discount') }}
                                                    />
                                                    &emsp;
                                                    <Input
                                                        addonBefore={`减价`}
                                                        placeholder={'元'}
                                                        style={{ width:'130px',textAlign:'right' }}
                                                        data-name={`cut_price`}
                                                        value={ this.state.patch_cut_price }
                                                        onChange={(e) => { this.handlePatchSet(e,'cut_price') }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        addonBefore={`折后`}
                                                        placeholder={'元'}
                                                        style={{ width:'130px',textAlign:'right' }}
                                                        data-name={`sales_price`}
                                                        value={ this.state.patch_sales_price }
                                                        onChange={(e) => { this.handlePatchSet(e,'sales_price') }}
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        :
                                        <table className="cxtables cxtables-sku">
                                            <thead>
                                            <tr>
                                                <td>商品库存</td>
                                                <td>零售价</td>
                                                <td>活动库存</td>
                                                <td>设置折扣</td>
                                                <td>活动价</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={`cbMoney`}>
                                                <td>
                                                    { currentItem.goods_stock }
                                                </td>
                                                <td>
                                                    &yen;{ currentItem.price }
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder={'活动库存'}
                                                        style={{ width:'100px' }}
                                                        value={ currentItem.stock}
                                                        onChange={ (e) => { this.handleCommonChange(e,'stock') } }
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        addonBefore={`打折`}
                                                        placeholder={'折'}
                                                        style={{ width:'130px',textAlign:'right' }}
                                                        value={ currentItem.discount}
                                                        onChange={ (e) => { this.handleCommonChange(e,'discount') } }
                                                    />
                                                    &emsp;
                                                    <Input
                                                        addonBefore={`减价`}
                                                        placeholder={'元'}
                                                        style={{ width:'130px',textAlign:'right' }}
                                                        value={ currentItem.cut_price}
                                                        onChange={ (e) => { this.handleCommonChange(e,'cut_price') } }
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        addonBefore={`折后`}
                                                        placeholder={'元'}
                                                        style={{ width:'130px',textAlign:'right' }}
                                                        value={ currentItem.sales_price}
                                                        onChange={ (e) => { this.handleCommonChange(e,'sales_price') } }
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                }


                            </div>
                        </FormItem>
                        <FormItem label={`取整`}>
                            {getFieldDecorator('int', {
                                initialValue: currentItem.int  || 1
                            })(
                                <Radio.Group onChange={ this.handleIntChange }>
                                    <Radio value={1}>不抹分角</Radio>
                                    <Radio value={2}>抹分</Radio>
                                    <Radio value={3}>抹分抹角</Radio>
                                </Radio.Group>
                            )}

                        </FormItem>
                        <FormItem label={`活动排序`}>
                            {getFieldDecorator('sort', {
                                initialValue:  currentItem.sort,
                            })(<Input placeholder={''} style={{ width:'150px' }} />)}
                            <span className={`fi-help-text`}>小程序端及后台呈现的形式，按照数字从小到大排列，不填写，默认按后添加的在前</span>
                        </FormItem>
                        <FormItem label={`初始想买人数`}>
                            {getFieldDecorator('basic_tobuy_num', {
                                initialValue: currentItem.basic_tobuy_num,
                            })(<Input placeholder={''} style={{ width:'150px' }} />)}
                            <span className={`fi-help-text`}>在活动预热中展示，不填写默认为0</span>
                        </FormItem>
                        <FormItem label={`初始活动销量`}>
                            {getFieldDecorator('basic_sales', {
                                initialValue: currentItem.basic_sales,
                            })(<Input placeholder={''} style={{ width:'150px' }} />)}
                            <span className={`fi-help-text`}>客户看到的销量=初始活动销量+已售数量，会在活动开始后展示</span>
                        </FormItem>
                    </Form>
                </Modal>
                :
                null
        )
    }
}

export default Form.create({})(withRouter(SetItemDiscount))
