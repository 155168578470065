import $axios from '@/axios/axios.js'

export function cateLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/cateLists`,
        method:'post',
        data
    })
}

export function saveCate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/saveCate`,
        method:'post',
        data
    })
}

export function delCate(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/delCate`,
        method:'post',
        data
    })
}

export function pointMallLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/lists`,
        method:'post',
        data
    })
}

export function pointMallSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/save`,
        method:'post',
        data
    })
}

export function pointMallDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/detail`,
        method:'post',
        data
    })
}

export function pointMallDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/delete`,
        method:'post',
        data
    })
}

export function pointMallStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/setStatus`,
        method:'post',
        data
    })
}

export function suitStores(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/suitStores`,
        method:'post',
        data
    })
}

export function setConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/setOthers`,
        method:'post',
        data
    })
}

export function getConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/points-mall/getOthers`,
        method:'post',
        data
    })
}
