import React from 'react'
import { Form, Alert, Button, Table, Select, Input, Modal, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { guestBookLists, guestBookSave, delGuestBook, guestBookSuitStore, getCourtTypes, copyGuestBook, saveGuestTime, delGuestTime } from '@/api/court'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import CopyTime from '@/views/ktv/components/copy-time'
import AddGuestTime from './add-guest-time'
const FormItem = Form.Item;
class GuestBookManage extends React.Component{
    state = {
        allStore:[],
        currentStore:'',
        week:[
            { id:1,name:'周一',is_choose:1,special_date:'' },
            { id:2,name:'周二',is_choose:0,special_date:'' },
            { id:3,name:'周三',is_choose:0,special_date:'' },
            { id:4,name:'周四',is_choose:0,special_date:'' },
            { id:5,name:'周五',is_choose:0,special_date:'' },
            { id:6,name:'周六',is_choose:0,special_date:'' },
            { id:0,name:'周日',is_choose:0,special_date:'' },
        ],
        currentWeek:{ id:1,name:'周一',is_choose:1,special_date:'' },
        spaceTypeLists:[],
        timeLists:[],
        currentSpaceType:'',
        showEdit:false,
        tableLoading:false,
        isLoading:false,
        currentRow:null,
        page:1,
        total:0,
        per_page:10
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchStore()
            .then(() => {
                this.fetchData()
            })
    }

    fetchData = () => {
        let sendData = {},
            currentStore = this.state.currentStore || {};
        sendData.store_id = currentStore.id || ''
        this.setState({
            tableLoading:true
        })
        guestBookLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let spaceTypeLists = res.data.space_type_list,
                        timeLists = res.data.time_list || [],
                        price_data = res.data.price_data || '';
                    if(price_data){
                        for(var i in price_data){
                            price_data[i]['key'] = i
                        }
                    }
                    spaceTypeLists.forEach((item,index) => {
                        item.is_choose = 0;
                    })
                    if(spaceTypeLists && spaceTypeLists.length){
                        spaceTypeLists[0]['is_choose'] = 1;
                        timeLists.forEach((item,index) => {
                            item.price_data = price_data || {}
                        })
                        this.setState({
                            spaceTypeLists:spaceTypeLists,
                            timeLists:timeLists,
                            currentSpaceType:spaceTypeLists[0]
                        })
                    }else {
                        message.error('请设置场地类型')
                    }


                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading:true
        })
        return new Promise((resolve, reject) => {
            storeLists(sendData)
                .then(res => {
                    if(res.code == 1){
                        let lists = res.data.store.data || [],
                            currentStore = this.state.currentStore || '';
                        if(!currentStore){
                            currentStore = lists[0]
                        }
                        this.setState({
                            allStore:lists,
                            currentStore
                        },() => {
                            resolve()
                        })
                    }else {
                        reject()
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })

    }
    renderWeek = () => {
        let week = this.state.week || [];
        return(
            <div className={`ktv-btn-group mgb15`}>
                {
                    week.map((item,index) => (
                        <div className={`ktv-btn`} key={index}>
                            <Button
                                type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                onClick={ () => { this.handleWeek(item,index) } }
                            >
                                <div>
                                    { item.name }
                                </div>
                            </Button>
                        </div>
                    ))
                }
            </div>
        )

    }
    handleStoreChange = (val) => {
        let currentStore = '',
            allStore = this.state.allStore || [];
        if(val){
            allStore.map(item => {
                if(item.id == val){
                    currentStore = item;
                    this.setState({
                        currentStore
                    },() => {
                        this.fetchData();
                    })
                }
            })
        }

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAddTime = () => {
        let sendData = {},
            currentStore = this.state.currentStore || {};
        this.setState({
            currentRow:null
        },() => {
            this.AddGuestTime.show(res => {
                if(res){
                    sendData = res;
                    sendData.store_id = currentStore.id || '';
                    this.setState({
                        isLoading:true
                    })
                    saveGuestTime(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })
                }
            })
        })

    }
    handleWeek = (rowData,index) => {
        let week = this.state.week || [];
        week.forEach((item) => {
            item.is_choose = 0
        })
        week[index]['is_choose'] = 1;
        this.setState({
            week,
            currentWeek:week[index]
        })
    }
    handleSpace = (rowData,index) => {
        let spaceTypeLists = this.state.spaceTypeLists || [];
        spaceTypeLists.forEach((item) => {
            item.is_choose = 0
        })
        spaceTypeLists[index]['is_choose'] = 1;
        this.setState({
            spaceTypeLists,
            currentSpaceType:spaceTypeLists[index]
        })

    }
    showEdit = () => {
        this.setState({
            showEdit:true
        })
    }
    handleCancel = () => {
        this.setState({
            showEdit:false
        })
    }
    handleDurationChange = (e,rowData,index,keyName) => {
        let timeLists = this.state.timeLists || [],
            val = e.target.value;
        if(!timeLists[index]['price_data'][keyName]){
            timeLists[index]['price_data'][keyName] = { key:keyName,price:'',duration:'' }
        }
        timeLists[index]['price_data'][keyName]['duration'] = val;

        this.setState({
            timeLists
        })
       
    }
    handleDurationBlur = (e,rowData,index,keyName) => {
        let timeLists = this.state.timeLists || [],
            val = e.target.value;
        if(!timeLists[index]['price_data'][keyName]){
            timeLists[index]['price_data'][keyName] = { key:keyName,price:'',duration:'' }
        }
        timeLists[index]['price_data'][keyName]['duration'] = val;

        this.setState({
            timeLists
        }, ()=>{
            this.handleSave(()=>{
                message.success('修改成功')
            })
        })
       
    }
    handlePriceChange = (e,rowData,index,keyName) => {
        let timeLists = this.state.timeLists || [],
            val = e.target.value;
        if(!timeLists[index]['price_data'][keyName]){
            timeLists[index]['price_data'][keyName] = { key:keyName,price:'',duration:'' }
        }
        timeLists[index]['price_data'][keyName]['price'] = val;

        this.setState({
            timeLists
        })
    }
    handlePriceBlur= (e,rowData,index,keyName) => {
        let timeLists = this.state.timeLists || [],
            val = e.target.value;
        if(!timeLists[index]['price_data'][keyName]){
            timeLists[index]['price_data'][keyName] = { key:keyName,price:'',duration:'' }
        }
        timeLists[index]['price_data'][keyName]['price'] = val;

        this.setState({
            timeLists
        }, ()=>{
            this.handleSave(()=>{
                message.success('修改成功')
            })
        })
    }
    handleSave = (callback) => {
        let timeLists = this.state.timeLists || [],
            currentStore = this.state.currentStore || {},
            sendData = {},
            price_data = [];
        sendData.store_id = currentStore.id || '';
        timeLists.forEach((item,index) => {
            if(item.price_data){
                for(var i in item.price_data){
                    if(item.price_data[i]['price']){
                        item.price_data[i]['price'] = parseFloat(item.price_data[i]['price']).toFixed(2)
                    }
                    price_data.push(item.price_data[i])
                }
            }
        })
        sendData.price_data = price_data;
        this.setState({
            tableLoading:true
        })
        guestBookSave(sendData)
            .then(res => {
                if(res.code == 1){
                    if(callback && typeof callback == 'function'){
                        callback()
                    }else {
                        message.success('保存成功')
                            .then(() => {
                                this.setState({
                                    showEdit:false
                                })
                            })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false,
                })
            })

    }
    handleTableData = () => {
        let currentSpaceType = this.state.currentSpaceType || '',
            currentWeek = this.state.currentWeek || '',
            timeLists = this.state.timeLists || [],
            columns = [];
        if(currentWeek && currentSpaceType){
            columns = [
                {
                    title: '时段',
                    width: 300,
                    dataIndex: 'timeRange',
                    render:(text, rowData, index) => {
                        return (
                            <div className={`lh25`}>
                                <span>{ rowData.name }&nbsp;{`${rowData.start_time }-${rowData.end_time}`}</span>
                                <Icon type="edit" className={`mgl5 cursor`} onClick={ () => { this.handleEditTime(rowData,index) } }  />
                            </div>
                        )
                    }
                },
                {
                    title:'可用时长',
                    dataIndex:'duration',
                    render:(text, rowData, index) => {
                        return (
                            <div className={`court-table-form`}>
                                {
                                    this.state.showEdit
                                        ?
                                        <FormItem label={``} colon={false}>
                                            <Input
                                                value={`${rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`] ? rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`]?.duration : '' }`}
                                                style={{ width:'150px' }}
                                                addonAfter={'小时'}
                                                onChange={ (e) => { this.handleDurationChange(e,rowData,index,`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`) } }
                                                onBlur={ (e) => { this.handleDurationBlur(e,rowData,index,`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`) } }
                                            />
                                        </FormItem>
                                        :
                                        <p>{`${rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`] ? rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`]?.duration + '小时' : `未设置` }`}</p>
                                }
                            </div>
                        )
                    }
                },
                {
                    title:'价格',
                    dataIndex:'price',
                    render:(text, rowData, index) => {
                        return (
                            <div className={`court-table-form`}>
                                {
                                    this.state.showEdit
                                        ?
                                        <FormItem label={``} colon={false}>
                                            <Input
                                                value={`${rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`] ? rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`]?.price : '' }`}
                                                style={{ width:'150px' }}
                                                addonAfter={'元/人'}
                                                onChange={ (e) => { this.handlePriceChange(e,rowData,index,`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`) } }
                                                onBlur={ (e) => { this.handlePriceBlur(e,rowData,index,`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`) } }
                                            />
                                        </FormItem>
                                        :
                                        <p>{`${rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`]  ? rowData.price_data[`${currentSpaceType.id}_${currentWeek.id}_${rowData.id}`]?.price + '元/人' : `未设置` }`}</p>
                                }
                            </div>
                        )
                    }
                },
                {
                    title:'操作',
                    dataIndex:'opts',
                    render:(text, rowData, index) => {
                        return (
                            <div className={`court-table-form`}>
                                <div className={`btn-row`}>
                                    <Button type={`link`} onClick={ () => { this.handleDelTime(rowData,index) } } >删除时段</Button>
                                </div>
                            </div>
                        )
                    }
                },
            ];
            return(
                <div className={'content-body mgt15'}>
                    <Table
                        style={{ width:'100%' }}
                        size={`middle`}
                        columns={ columns }
                        dataSource={ timeLists }
                        rowKey={(record,index) => index }
                        pagination={false}
                    ></Table>
                </div>
            )

        }
    }
    handleCopyTime = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '',
            currentSpaceType = this.state.currentSpaceType || '',
            currentWeek = this.state.currentWeek || '';
        sendData.store_id = currentStore ? currentStore.id : '';
        sendData.space_type_id = currentSpaceType ? currentSpaceType.id : '';
        sendData.origin_week_id = currentWeek ? currentWeek.id : '';
        this.CopyTime.show(res => {
            if(res){
                sendData.to_week_ids = res.weeks;
                this.setState({
                    isLoading:true
                })
                copyGuestBook(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('复制成功')
                                .then(() => {
                                    this.setState({
                                        showEdit:false
                                    })
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleDelTime = (rowData,index) => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        Modal.confirm({
            title: `是否删除该时段`,
            okType: `danger`,
            onOk: () => {
                sendData.store_id = currentStore ? currentStore.id : '';
                sendData.id = rowData.id || ``;
                this.setState({
                    isLoading:true
                })
                delGuestTime(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleEditTime = (rowData,index) => {
        let sendData = {},
            currentStore = this.state.currentStore || {};
        this.setState({
            currentRow:rowData
        },() => {
            this.AddGuestTime.show(res => {
                if(res){
                    sendData = res;
                    sendData.store_id = currentStore.id || '';
                    this.setState({
                        isLoading:true
                    })
                    saveGuestTime(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData()
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false
                            })
                        })
                }
            })
        })

    }



    render() {
        let allStore = this.state.allStore || [],
            currentStore = this.state.currentStore || {},
            spaceTypeLists = this.state.spaceTypeLists || [];
        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }
        return(
            <div className={'shadow-radius'}>
                <CopyTime
                    onRef={ e => { this.CopyTime = e } }
                    weeks = { this.state.week }
                    current={ this.state.currentWeek }
                    currentSpaceType = { this.state.currentSpaceType }
                    error={`请注意！复制后该日期原有已设置好的租赁模式及价格设置将被覆盖，请及时检查和确认。`}
                ></CopyTime>
                <AddGuestTime
                    onRef={ e => { this.AddGuestTime = e } }
                    data={ this.state.currentRow }
                ></AddGuestTime>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'选择门店'} >
                            <Select style={{width:200}} placeholder={'请选择'} value={ currentStore.id } onChange={ this.handleStoreChange }>
                                {
                                    allStore.map(store => (
                                        <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                    ))
                                }
                            </Select>
                        </FormItem>
                    </Form>
                    <div className={`clearfix`}></div>
                </div>
                <div className={`fi-title mgb15`}>价格管理</div>
                <Alert message={`选择门店进行单独设置，设置后保存。`} className={`mgb15`} type="warning" banner />
                <div className={`mgb15`}>
                    <Button type='default' onClick={() => this.showEdit()}>编辑价格表</Button>
                </div>
                <div className={`ktv-btn-group mgb15`}>
                    {
                        spaceTypeLists.map((item,index) => (
                            <div className={`ktv-btn`} key={index}>
                                <Button
                                    type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                    onClick={ () => { this.handleSpace(item,index) } }
                                >
                                    <div>
                                        { item.name }
                                    </div>
                                </Button>
                            </div>
                        ))
                    }
                </div>
                { this.renderWeek() }
                {
                    this.state.showEdit
                        ?
                        null
                        :
                        <div className={`mgb15`}>
                            <Button type={`primary`} onClick={ this.handleCopyTime }>复制到其他日期</Button>
                        </div>
                }

                <Form className={`model-form`} { ...formItemLayout }>
                    { this.handleTableData() }
                    {
                        this.state.showEdit
                            ?
                            <div className={`mgt15`}>
                                <Button type={'default'} onClick={this.handleAddTime} className={``}>添加时段</Button>
                            </div>
                            :
                            null
                    }

                    {
                        this.state.showEdit
                            ?
                            <div className={`mgt15`}>
                                <Button type={'primary'} onClick={this.handleSave} >保存价格表</Button>
                                <Button type={'default'} onClick={this.handleCancel} className={`mgl10`}>取消</Button>
                            </div>
                            :
                            null
                    }
                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(GuestBookManage))
