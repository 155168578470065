import React from 'react'
import { Form, Input } from 'antd'
import Mapper from '@/components/Mapper/Mapper'
const FormItem = Form.Item

class ChooseMap extends React.Component{
    state = {
        formData:''
    }
    componentDidMount() {
        this.props.onRef(this)
        let data = this.props.data || {},
            formData = this.state.formData || {};
    }
    showMap = () => {
        this.Mapper.show(res => {
            let formData = this.state.formData || {},
                data = this.props.data || {};
            for(var i in res){
                formData[i] = res[i]
            }
            formData.linkType = data.options[0]['linkType'];
            formData.name = data.options[0]['name'];
            formData.link = res.address || '地图导航';
            this.props.form.setFieldsValue({
                address: res.address || '',
            });
            this.setState({
                formData
            })
            this.props.onChange && this.props.onChange([formData])
        })
    }
    clearData = () => {
        this.setState({
            formData:''
        })
    }
    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 18
            }
        }
        return(
            <div>
                <Mapper onRef={e => { this.Mapper = e }} lat={data.lat || ''} lng={data.lng || ''} address={data.address || ''}></Mapper>
                <Form className={`model-form`} {...formItemLayout}>
                    <FormItem label={'选择地址'}>
                        {getFieldDecorator('address', {
                            initialValue: data.address || '',
                            rules: []
                        })(<Input placeholder={'点击选择地址'}  onClick={this.showMap} />)}
                    </FormItem>
                </Form>
            </div>
        )
    }
}

export default Form.create({})(ChooseMap)
