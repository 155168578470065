import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Radio, Input, Checkbox, Button, Table } from 'antd'
import { getFxConfig, setFxConfig } from '@/api/fx'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import ItemPicker from '@/components/ItemPicker/ItemPicker'
import '@/assets/css/fx/fx.scss'
import '@/assets/css/dashboard/index.scss'
const FormItem = Form.Item

class FxSetting extends React.Component{
    state = {
        formData:'',
        isLoading:false,
        columns:[
            {
                title:'商品名称',
                dataIndex:'name',
                width:'60%',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title:'零售价',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>&yen;{rowData.price || '--'}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={() => { this.handleDelItem(rowData,index) }} >删除</Button>
                        </div>
                    )
                }
            }
        ],
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        getFxConfig(sendData)
            .then(res =>{
                if(res.code == 1){
                    let config = res.data.config || ''
                    this.setState({
                        formData:config
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleRadioChange = () => {}
    chooseSuitItems = () => {
        let formData = this.state.formData || {};
        this.setState({
            chooseItems: formData.suit_goods ? formData.suit_goods : []
        },() => {
            this.ItemPicker.show(res => {
                if(res.length){
                    formData.suit_goods = res
                    this.setState({
                        formData
                    })
                }
            })
        })

    }
    handleDelItem = (rowData,index) => {
        let formData = this.state.formData || {},
            suit_goods = formData.suit_goods;
        for(var i in suit_goods){
            if(suit_goods[i]['id'] == rowData.id){
                suit_goods.splice(i,1)
            }
        }
        formData.suit_goods = suit_goods
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                let config = values
                if(config.is_suit_goods){
                    if(!formData.suit_goods || formData.suit_goods.length < 1){
                        message.error('请选择商品');
                        return ;
                    }
                }
                if(formData.suit_goods){
                    config.suit_goods = formData.suit_goods.map(item => { return item.id })
                }
                for(var x in config){
                    if(typeof config[x] === 'boolean'){
                        config[x] = config[x] ? 1 : 0
                    }
                }
                if(config.apply_set == 1){
                    config.audit_type = 1;
                }
                if(config.mode == 2){
                    //团长模式
                    config.reward_commission_rate = 0
                }
                sendData.config = config;
                this.setState({
                    isLoading:true
                })

                setFxConfig(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return(
            <div className={`shadow-radius`}>
                <ItemPicker
                    onRef={ e => { this.ItemPicker = e } }
                    selectIds={data.suit_goods && data.suit_goods.length ? data.suit_goods.map(item => {return item.id}) : []}
                    items={ data.suit_goods && data.suit_goods.length ? data.suit_goods : [] }
                    multiple={ true }
                ></ItemPicker>
                <div className={`content-msg mgb15`}>佣金结算时间根据每笔订单完成时间进行结算。分销玩法模式在切换之前下单的订单按照原有模式进行返佣，切换以后下单的订单按照现有模式进行返佣。</div>
                <Form className={`model-form`} { ...formItemLayout }>
                    <div>
                        {
                            getFieldDecorator('mode',{
                                initialValue:data.mode || 1
                            })(
                                <Radio.Group className={`flex justify-between align-start`} onChange={ this.handleRadioChange }>
                                    <div className={`fx-radio-panel`}>
                                        <div className={`fx-radio-title`}>
                                            <Radio value={1}>分销裂变模式</Radio>
                                        </div>
                                        <div className={`fx-radio-desc`}>
                                            分销裂变模式下分销员通过分享商品链接即可绑定客户并发展下级分销员，客户及分销员下单都拿佣金。
                                        </div>
                                    </div>
                                    <div className={`fx-radio-panel`}>
                                        <div className={`fx-radio-title`}>
                                            <Radio value={2}>团长模式</Radio>
                                        </div>
                                        <div className={`fx-radio-desc`}>
                                            团长模式下团长(即分销员)通过分享商品链接绑定客户，客户下单后拿单次佣金且不持续发展下级分销员。
                                        </div>
                                    </div>
                                </Radio.Group>
                            )
                        }
                    </div>
                    <div className={`msg-title mgt15 mgb10`}>佣金设置</div>
                    <FormItem label={`分销商品默认佣金比例`}>
                        {
                            getFieldDecorator('goods_commission_rate',{
                                initialValue:data.goods_commission_rate || ''
                            })(
                                <Input
                                    addonAfter={`%`}
                                    placeholder={'请设置'}
                                    style={{ width:'200px' }}
                                />
                            )
                        }
                        {
                            getFieldValue('mode') == 1
                                ?
                                <span className={`fi-help-text`}>分销员绑定客户购买分销商品，分销员可获得分销商品佣金=分销商品佣金比例*商品实付款（扣除邮费和优惠）。</span>
                                :
                                <span className={`fi-help-text`}>团长绑定客户购买分销商品，团长可获得分销商品佣金=团长分销商品（活动）佣金比例*商品实付款（扣除邮费和优惠）。</span>
                        }
                    </FormItem>
                    {
                        getFieldValue('mode') == 1
                            ?
                            <FormItem label={`邀请奖励默认佣金比例`}>
                                {
                                    getFieldDecorator('reward_commission_rate',{
                                        initialValue:data.reward_commission_rate || ''
                                    })(
                                        <Input
                                            addonAfter={`%`}
                                            placeholder={'请设置'}
                                            style={{ width:'200px' }}
                                        />
                                    )
                                }
                                <span className={`fi-help-text`}>下级分销员的绑定客户购买分销商品，上级分销员获得邀请奖励佣金=邀请奖励佣金比例*商品实付款（扣除邮费和优惠）</span>
                            </FormItem>
                            :
                            null
                    }
                    <div className={`msg-title mgt10 mgb10`}>分销员招募设置</div>
                    <FormItem label={'申请门槛'}>
                        {getFieldDecorator('apply_set', {
                            initialValue: data.apply_set || 2,
                            rules: []
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                    无门槛 <span className={`mgl10`} style={{ color:'#999' }}>访问小程序成为分销员</span>
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    需要主动申请 <span className={`mgl10`} style={{ color:'#999' }}>填写相关信息成为分销员</span>
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    {
                        getFieldValue('apply_set') == 2
                            ?
                            <FormItem label={'审核方式'}>
                                {getFieldDecorator('audit_type', {
                                    initialValue: data.audit_type || 1,
                                    rules: []
                                })(
                                    <Radio.Group>
                                        <Radio value={1} className={`mgb15 mgt10 block`}>
                                            人工审核
                                        </Radio>
                                        <Radio value={2} className={`block mgb15`}>
                                            自动审核通过
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>
                            :
                            null
                    }

                    {/*<FormItem label={`自动成为分销员条件`}>*/}
                        {/*<div>*/}
                            {/*{*/}
                                {/*getFieldDecorator('is_least_amount', {*/}
                                    {/*initialValue: data.is_least_amount || 0,*/}
                                    {/*valuePropName: 'checked'*/}
                                {/*})(*/}
                                    {/*<Checkbox className={`mgr5`}></Checkbox>*/}
                                {/*)*/}
                            {/*}*/}
                            {/*交易金额满*/}
                            {/*{getFieldDecorator('least_amount', {*/}
                                {/*initialValue: data.least_amount || '',*/}
                            {/*})(*/}
                                {/*<Input addonAfter={`元`} style={{width: '180px'}} className={`mgl5 mgr5`} />*/}
                            {/*)}*/}
                        {/*</div>*/}
                        {/*<div>*/}
                            {/*{*/}
                                {/*getFieldDecorator('is_least_trade_num', {*/}
                                    {/*initialValue: data.is_least_trade_num|| 0,*/}
                                    {/*valuePropName: 'checked'*/}
                                {/*})(*/}
                                    {/*<Checkbox className={`mgr5`}></Checkbox>*/}
                                {/*)*/}
                            {/*}*/}
                            {/*交易笔数满*/}
                            {/*{getFieldDecorator('least_trade_num', {*/}
                                {/*initialValue: data.least_trade_num || '',*/}
                            {/*})(*/}
                                {/*<Input addonAfter={`笔`} style={{width: '180px'}} className={`mgl5 mgr5`}/>*/}
                            {/*)}*/}
                        {/*</div>*/}
                        {/*<div>*/}
                            {/*{*/}
                                {/*getFieldDecorator('is_suit_goods', {*/}
                                    {/*initialValue: data.is_suit_goods || 0,*/}
                                    {/*valuePropName: 'checked'*/}
                                {/*})(*/}
                                    {/*<Checkbox className={`mgr5`}></Checkbox>*/}
                                {/*)*/}
                            {/*}*/}
                            {/*购买指定商品*/}
                            {/*{*/}
                                {/*getFieldDecorator('suit_goods_type',{*/}
                                    {/*initialValue:data.suit_goods_type || 1*/}
                                {/*})(*/}
                                    {/*<Radio.Group className={`mgl10`}>*/}
                                        {/*<Radio value={1} >*/}
                                            {/*任意购买一件*/}
                                        {/*</Radio>*/}
                                        {/*<Radio value={2} >*/}
                                            {/*全部购买*/}
                                        {/*</Radio>*/}
                                    {/*</Radio.Group>*/}
                                {/*)*/}
                            {/*}*/}
                        {/*</div>*/}
                    {/*</FormItem>*/}
                    {/*{*/}
                        {/*getFieldValue('is_suit_goods')*/}
                            {/*?*/}
                            {/*<div>*/}
                                {/*<FormItem label={'选择商品'}>*/}
                                    {/*{*/}
                                        {/*getFieldDecorator('suit_goods',{*/}
                                            {/*initialValue:data.suit_goods || []*/}
                                        {/*})(*/}
                                            {/*<Button type={`primary`} onClick={ this.chooseSuitItems }>请选择商品</Button>*/}
                                        {/*)*/}
                                    {/*}*/}
                                {/*</FormItem>*/}
                                {/*{*/}
                                    {/*data.suit_goods && data.suit_goods.length*/}
                                        {/*?*/}
                                        {/*<FormItem label={` `} colon={false}>*/}
                                            {/*<Table*/}
                                                {/*size={`middle`}*/}
                                                {/*columns={this.state.columns}*/}
                                                {/*dataSource={data.suit_goods}*/}
                                                {/*rowKey={record => record.id}*/}
                                                {/*style={{ width:'600px' }}*/}
                                            {/*></Table>*/}
                                        {/*</FormItem>*/}
                                        {/*:*/}
                                        {/*null*/}
                                {/*}*/}

                            {/*</div>*/}
                            {/*:*/}
                            {/*null*/}
                    {/*}*/}

                    <div className={`msg-title mgt10 mgb10`}>客户关系</div>

                    <FormItem label={'绑定客户条件'}>
                        {getFieldDecorator('bind_type', {
                            initialValue: data.bind_type || 1,
                            rules: []
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                    客户通过分销员的小程序链接/海报绑定
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    客户通过成交产生订单即可被绑定
                                </Radio>
                                <div>
                                    {
                                        getFieldDecorator('filter_pay_guest', {
                                            initialValue: data.filter_pay_guest || 0,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox className={`mgr5`}></Checkbox>
                                        )
                                    }
                                    不包含在店内已消费的客户
                                </div>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'绑定时长'}>
                        {getFieldDecorator('bind_time_type', {
                            initialValue: data.bind_time_type || 1,
                            rules: []
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                    永久绑定
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    有效期
                                    {
                                        getFieldDecorator('bind_time',{
                                            initialValue:data.bind_time || ''
                                        })(
                                            <Input addonAfter={`天`} className={`addon-after-input mgl10 mgr10`} style={{ width:'200px' }} ></Input>
                                        )
                                    }
                                    结束后自动解绑

                                </Radio>
                            </Radio.Group>
                        )}
                        <span className={`fi-help-text`}>解绑后的客户，通过其他分销员的分享链接或下单，会被重新绑定上级</span>
                    </FormItem>
                    <FormItem label={'分销员自购'}>
                        {getFieldDecorator('self_buy', {
                            initialValue: data.self_buy || 1,
                            rules: []
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                    不允许和自己绑定客户关系，自购无佣金
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    默认始终和自己绑定客户关系，自购有佣金
                                </Radio>
                            </Radio.Group>
                        )}
                        
                    </FormItem>
                    <div className={`msg-title mgt10 mgb10`}>结算设置</div>

                    <FormItem label={`提现方式`}>
                        {getFieldDecorator('withdraw_set', {
                            initialValue: data.withdraw_set || []
                        })(
                            <Checkbox.Group >
                                <Checkbox value={1}>微信零钱</Checkbox>
                                <Checkbox value={2}>储值卡余额</Checkbox>
                                <Checkbox value={3}>支付宝</Checkbox>
                                <Checkbox value={4}>银行卡</Checkbox>
                            </Checkbox.Group>
                        )}
                        <span className={`fi-help-text`}>
                            分销员申请后可以直接提现到微信零钱账户，请确保已开通企业付款到零钱功能；
                            <br/>
                            储值卡余额需分销员申请一张储值卡，或商家主动给分销员开储值卡。
                            <br/>
                            支付宝及银行卡后台只做审核查看，需商家自行转账到用户提交的账户内。
                        </span>
                    </FormItem>
                    <FormItem label={`提现门槛`}>
                        {
                            getFieldDecorator('withdraw_min',{
                                initialValue:data.withdraw_min || ''
                            })(
                                <Input addonAfter={`元`} className={`addon-after-input mgl10 mgr10`} style={{ width:'200px' }} ></Input>
                            )
                        }
                        <span className={`fi-help-text`}>如提现到微信零钱，因企业支付到零钱的限制金额需设置大于等于1元，如其他方式可不舍门槛</span>
                    </FormItem>
                    <FormItem label={`发放手续费比例`}>
                        {
                            getFieldDecorator('service_rate',{
                                initialValue:data.service_rate || ''
                            })(
                                <Input addonAfter={`%`} className={`addon-after-input mgl10 mgr10`} style={{ width:'200px' }} ></Input>
                            )
                        }
                        <span className={`fi-help-text`}>设置后，分销员获得收益时会按照比例扣除部分手续费</span>
                    </FormItem>
                    <FormItem label={`提现时间`}>
                        每月
                        {
                            getFieldDecorator('start_date',{
                                initialValue:data.start_date || ''
                            })(
                                <Input addonAfter={`日`} className={`addon-after-input mgl10 mgr10`} style={{ width:'120px' }} ></Input>
                            )
                        }
                        至
                        {
                            getFieldDecorator('end_date',{
                                initialValue:data.end_date || ''
                            })(
                                <Input addonAfter={`日`} className={`addon-after-input mgl10 mgr10`} style={{ width:'120px' }} ></Input>
                            )
                        }
                        <span className={`fi-help-text`}>设置后在将在设置的区间才能提现，否则任何时间都能提现。</span>
                    </FormItem>
                    <FormItem label={'储值余额购买'}>
                        {getFieldDecorator('balance_buy', {
                            initialValue: data.balance_buy || 1,
                            rules: []
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                    无佣金
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    有佣金
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>

                    <FormItem label={` `} colon={false}>
                        <Button type={`primary`} onClick={ this.saveData }>立即保存</Button>
                    </FormItem>
                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}
export default Form.create({})(withRouter(FxSetting))
