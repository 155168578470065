import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Modal ,TreeSelect} from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { CourseListsApi} from '@/api/course'
import { studentAddCourseNum} from '@/api/student'
import message from '@/utils/message'
const FormItem = Form.Item;

class StudentAddCourseNum extends Component {

    state = {
        courseLists: [],
        storeLists: [],
        modalLoading: false
    }
    componentDidMount() {
        // this.fetchStore()
        // this.fetchCourse()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    fetchCourse = () => {

        CourseListsApi({ page: 1, per_page: 100 ,charge_type:1}).then(res => {

            if (res.code === 1) {
                let { data } = res.data.list

                this.setState({
                    courseLists: data
                })
            }
        })
    }

    handleCourse=(val,e)=>{
        let course_num=e.props.course_num
        this.props.form.setFieldsValue({
            course_num: course_num
          });
    }


   

    saveData = () => {
        let sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData=values
                sendData.student_id=this.props.student.id
              
                this.setState({
                    modalLoading: true
                })
                studentAddCourseNum(sendData).then(res => {
                   
                    if (res.code === 1) {
                        message.success(res.msg)
                      
                        this.props.success()
                        this.handleCancel()
                    } 
                    this.setState({
                        modalLoading: false
                    })
                })

            }
        })
    }
    handleCancel = () => {
        this.props.close()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }

        const { getFieldDecorator } = this.props.form
        

        return (

            <Modal
                title={`调整课时`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={20}>
                            <FormItem label="学员">
                               <span>{this.props.student.real_name}</span>
                            </FormItem>

                            {/* <FormItem label={'选择校区'} >
                                {
                                    getFieldDecorator('store_id', {
                                      
                                    })(
                                        <Select placeholder={'请选择校区'}   >
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}  >{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>


                            <FormItem label={'选择课程'} >
                                {
                                    getFieldDecorator('course_id', {
                                       
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择课程'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择课程'} onChange={this.handleCourse} >
                                            {
                                                this.state.courseLists.map(course => (
                                                    <Select.Option value={course.id} key={course.id} course_num={course.course_num}>{`${course.name} ${course.price}元${course.course_num}课时`}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem> */}


                            <FormItem label="课时数">
                                {getFieldDecorator('course_num', {
                                    initialValue:"",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入课时数'
                                        }
                                    ]
                                })(<Input placeholder='请输入要增/减的课时数（-1代表减1课时）' />)}
                            </FormItem>

                            <FormItem label="备注">
                                {getFieldDecorator('remark', {
                                    initialValue: ""
                                })(<Input placeholder='备注' />)}
                            </FormItem>

                        </Col>

                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(StudentAddCourseNum))