import React, { Component } from 'react'
import { Modal, Button, Form,Select, message } from 'antd';
import { jizanVerify,jizanVerifyStore } from "@/api/collect-likes.js"

const FormItem = Form.Item
class LikesDetail extends Component {
    state = {
        confirmLoading: false,
        storeList:[]
    }

    componentDidMount() {
      
        this.fetchStore()
    }

  

    fetchStore = () => {
        let sendData = {join_id: this.props.join_id};
      
        jizanVerifyStore(sendData).then(res => {

            if (res.code === 1) {
               
                this.setState({
                    storeList: res.data.store_list
                })
            }
        })
    }


    handleok=()=>{
        this.props.form.validateFields((err, values) => {
            if (!err) {
               let sendData=values
                sendData.join_id=this.props.join_id

                this.setState({
                    confirmLoading:true
                })
                jizanVerify(sendData).then(res=>{
                    if(res.code==1){
                        message.success(res.msg)
                        this.props.success()

                    }
                    this.props.close()
                })
                
            }
        })
    }

    handleCancel = () => {
        this.props.close()
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        return (
            <Modal
                title="自提核销码"
                visible={true}
                onOk={this.handleok}
                onCancel={this.handleCancel}
                width={700}
                confirmLoading={this.state.confirmLoading}
            >
                <Form className={`model-form`} {...formItemLayout}>
                    <FormItem label={'核销码'}> {this.props.use_code || ''}</FormItem>
                    <FormItem label={`选择核销门店`}>
                        {
                            getFieldDecorator('store_id', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择核销门店'
                                    }
                                ]
                            })(
                                <Select>
                                  
                                    {this.state.storeList.map(val=>{
                                        return   <Select.Option value={val.id} key={val.id}>{val.name}</Select.Option>
                                    })}
                                </Select>
                            )
                        }
                    </FormItem>

                </Form>
            </Modal>
        )
    }
}

export default Form.create({})(LikesDetail)
