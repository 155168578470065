import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, InputNumber, Radio, Pagination, Tooltip, Tag, TreeSelect, Drawer, Icon } from 'antd';
import AreaPicker from '@/components/AreaPicker/AreaPicker'
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class BaseForm extends Component {
    state = {
        selectItems: [],
        formData: {
            cost_point:1,
            limit:1,
            share_num: undefined,
            share_count: undefined,
            win_prize_count:1
        }
    }

    componentDidMount() {
        this.props.onRef(this)
     
    }

    componentWillReceiveProps(nextProps) {

        if ((nextProps.activityData != this.props.activityData) && this.props.is_edit) {

            let data = nextProps.activityData, selectItems = this.state.selectItems
            let { formData } = this.state
            const {cost_point,limit,share_num,share_count,win_prize_count}=data

            formData={...formData,cost_point,limit,share_num,share_count,win_prize_count}
          
            selectItems = data.join_area || []

            this.setState({
                formData,
                selectItems
            })
        }
    }


    addFreeArea = () => {
        let selectItems = this.state.selectItems

        this.AreaPicker.show({
            selectItems: selectItems,
            is_default_select: true,
            callback: (res) => {

                if (res.length) {
                    let selectItems = res
                    this.setState({
                        selectItems
                    })
                }
            }
        })
    }

    handleNumberChange = name => {
        return val => {
            if (typeof val === 'number') {

                let formData = this.state.formData
                formData[name] = val
                this.setState({
                    formData
                })
            }
        }
    }

    handleRednum=num=>{
      
        this.props.updateData('red_num',num || 0)
    }

    getFormData = () => {
        return new Promise((reslove, reject) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const { formData, selectItems } = this.state
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                    values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : ''


                    if (values.join_area_type == 2) {
                        values.join_area = selectItems
                    }

                    if(values.is_cost_point >0){
                        values.cost_point=formData.cost_point
                    }else{
                        values.cost_point=values.is_cost_point
                    }

                    if(values.is_limit >0){
                        values.limit=formData.limit
                    }else{
                        values.limit=values.is_limit
                    }

                    if(values.is_win_prize_count >0){
                        values.win_prize_count=formData.win_prize_count
                    }else{
                        values.win_prize_count=values.is_win_prize_count
                    }

                    values.share_num=formData.share_num
                    values.share_count=formData.share_count

                    return reslove({ ...values })
                } else {
                    return reslove(false)
                }
            })
        })
    }


    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const data = this.props.activityData || {}
        const formData = this.state.formData || {}
        const { is_edit ,is_disabled} = this.props
        const radioSty = { display: "block", marginTop: "8px" }
        const desc_default=
        `1、红包雨活动每天有n场，分别是n点场，n点场，n点场\n 2、每人可以参加n场活动，分别点满n个，n个，n个获得对应奖励\n 3、奖励可在我的奖品中查看
        `

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>基本信息</div>
                <FormItem label={'活动名称'}>
                    {getFieldDecorator('name', {
                        initialValue: data.name || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入活动名称'
                            }
                        ]
                    })(<Input
                        style={{ width: 400 }}
                        placeholder={'请输入活动名称'}
                        maxLength={20}
                        suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/20</span>}
                    />)}
                </FormItem>

                <FormItem label={'活动时间'}>
                    {getFieldDecorator('time', {
                        initialValue: data.time || null,
                        rules: [
                            {
                                required: true,
                                message: '请选择活动时间'
                            }
                        ]
                    })(
                        <RangePicker
                            style={{ width: 400 }}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder={['开始时间', '结束时间']}
                        />
                    )}
                </FormItem>

                <FormItem label={'红包总个数'}>
                    {getFieldDecorator('red_num', {
                        initialValue: data.red_num || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入掉落红包的总数'
                            }
                        ]
                    })(
                        <InputNumber
                            style={{ width: 400 }}
                            min={data.red_num || 0}
                            precision={0}
                            placeholder={'请输入掉落红包的总数'}
                            onChange={this.handleRednum}
                            disabled={is_disabled}
                        />
                    )}
                </FormItem>

                <FormItem label={'抢红包的总时长'}>
                    {getFieldDecorator('red_second', {
                        initialValue: data.red_second || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入抢红包的总时长'
                            }
                        ]
                    })(
                        <InputNumber
                            style={{ width: 400, paddingRight: 100 }}
                            min={data.red_second || 0}
                            precision={0}
                            placeholder={'请输入抢红包的总时长'}

                        />
                    )}
                    <span className='custom-input-suffix'>秒</span>
                    <span className={'fi-help-text'}>建议：设置的红包个数与时长比例≤6个/秒</span>
                </FormItem>



                <div className={`msg-title mgb15`}>参与条件</div>

                <FormItem label={"参与人群"} >
                    {getFieldDecorator('join_user', {
                        initialValue: data.join_user || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择参与人群'
                            }
                        ]
                    })(
                        <Radio.Group   >
                            <Radio value={1}>所有客户 </Radio>
                            <Radio value={2}>仅限新客户
                                <Tooltip placement={`right`} title={`新客户指店铺内没有订单的客户，或仅拥有以下类型订单的客户：全额退款订单、已关闭的待付款订单`}>
                                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                </Tooltip>
                            </Radio>

                        </Radio.Group>
                    )}
                </FormItem>

                <FormItem label={"消耗积分"} >
                    {getFieldDecorator('is_cost_point', {
                        initialValue: data.is_cost_point || 0,
                        rules: [
                            {
                                required: true,
                                message: '请选择消耗积分'
                            }
                        ]
                    })(
                        <Radio.Group >
                            <Radio value={0} >不消耗 </Radio>

                            <Radio value={1} >消耗 <InputNumber   onChange={this.handleNumberChange("cost_point")} value={formData.cost_point} style={{ width: 80 }} min={1} precision={0} /> 积分</Radio>
                        </Radio.Group>
                    )}
                </FormItem>

                <FormItem label={"可玩次数"} >
                    {getFieldDecorator('is_limit', {
                        initialValue: data.is_limit || -1,
                        rules: [
                            {
                                required: true,
                                message: '请选择可玩次数'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={-1}  >不限次数 </Radio>
                            <Radio value={-2}  >一人一次 </Radio>
                            <Radio value={-3}  >一天一次 </Radio>
                            <br />
                            <Radio value={1} style={radioSty}>自定义次数
                                {getFieldValue("is_limit") == 1 ? <>
                                    <InputNumber className='mgl20'   onChange={this.handleNumberChange("limit")} value={formData.limit} style={{ width: 80 }} min={1} precision={0} /> 次/天</> : null}
                            </Radio>
                        </Radio.Group>
                    )}
                    <span className={'fi-help-text'}>如红包雨有多场次，选择一天一次则用户一天内只能参与其中一场</span>
                </FormItem>

                <FormItem label={'分享后赠送次数'}>
                    <span>分享后可增加 <InputNumber   onChange={this.handleNumberChange("share_num")} value={formData.share_num} style={{ width: 80 }} min={1} precision={0} /> 次，最多可分享 <InputNumber   onChange={this.handleNumberChange("share_count")} value={formData.share_count} style={{ width: 80 }} min={1} precision={0} /> 次</span>

                    <span className={'fi-help-text'}>此为额外增加，不消耗积分数，如默认可玩次数一人一次，则分享一次增加一次，以此类推。</span>
                </FormItem>


                <FormItem label={"中奖次数"} >
                    {getFieldDecorator('is_win_prize_count', {
                        initialValue: data.is_win_prize_count || 0
                    })(
                        <Radio.Group   >
                            <Radio value={0}>不限制 </Radio>
                            <Radio value={1}> 限制</Radio>
                        </Radio.Group>
                    )}
                    {getFieldValue("is_win_prize_count") == 1? <div>每位参与活动的客户可中奖 <InputNumber  onChange={this.handleNumberChange("win_prize_count")} value={formData.win_prize_count} style={{ width: 80 }} min={1} precision={0} /> 次</div> : null}
                    <span className={'fi-help-text'}>如设置1次，可玩多次的情况，则用户可以一直玩，但是仅只中一次奖。</span>
                </FormItem>

                <FormItem label={"是否授权"} >
                    {getFieldDecorator('is_authorize', {
                        initialValue: data.is_authorize || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择是否授权'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}>授权 </Radio>
                            <Radio value={2}>不授权 </Radio>
                        </Radio.Group>
                    )}
                    <span className={'fi-help-text'}> 授权时，需要授权手机号才可参与活动。</span>
                </FormItem>

               

                <FormItem label={"可参与地区"} >
                    {getFieldDecorator('join_area_type', {
                        initialValue: data.join_area_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择可参与地区'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}>所有地区 </Radio>
                            <Radio value={2}>部分地区</Radio>
                            {getFieldValue("join_area_type") == 2 ? <span className='text_link' onClick={this.addFreeArea}>{this.state.selectItems.length > 0 ? '查看设置' : '请选择地区'}</span> : null}
                        </Radio.Group>
                    )}
                </FormItem>

                <div className={`msg-title mgb15`}>展示设置</div>

                <FormItem label={'活动说明'}>
                    {getFieldDecorator('desc', {
                        initialValue: data.desc || desc_default,

                    })(<Input.TextArea
                        style={{ width: 400 }}
                        placeholder={''}
                        rows={5}
                        maxLength={500}
                        suffix={<span>{getFieldValue("desc") ? getFieldValue("desc").length : 0}/500</span>}
                    />)}
                </FormItem>

                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button type='primary' onClick={() => this.props.updateTab('2')}>下一步</Button>
                </FormItem>

                <div className={'no-colon mgt60 pdb50'}> </div>

                <AreaPicker onRef={e => {
                    this.AreaPicker = e
                }}></AreaPicker>

                
            </Form>
        )
    }
}

export default Form.create({})(BaseForm)
