import React from 'react'
import { Modal } from 'antd'
import { Rnd } from 'react-rnd'
import message from '@/utils/message'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import './EditHotArea.scss'
const RND_ENABLE_SIZE = { top:false, left:false, right:false, bottom:false, topLeft:false, topRight:false, bottomRight:true, bottomLeft:false }
class EditHotArea extends React.Component {
    state = {
        title: '热区编辑器',
        visible: false,
        modalLoading: false,
        callBack: null,
        pic:'',
        hotAreas:[]
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    show = (params = {}) => {
        if(!params.pic){
            message.error('缺少图片地址：missing params pic');
            return false;
        }
        if(!params.hotAreas){
            params.hotAreas = [];
        }
        this.setState({
            visible:true,
            callBack : params.callBack ? params.callBack : null,
            pic:params.pic,
            hotAreas:params.hotAreas
        })
    }

    handleOk = () => {
        let hotAreas = this.state.hotAreas || [],
            callBack = this.state.callBack,
            hotImgContext = this.refs.hotImg.getBoundingClientRect(),
            imgWidth = hotImgContext.width,
            imgHeight = hotImgContext.height;
        for(var i in hotAreas){
            hotAreas[i]['w-percent'] = parseFloat(hotAreas[i]['width'] / imgWidth * 100).toFixed(2);
            hotAreas[i]['h-percent'] = parseFloat(hotAreas[i]['height'] / imgHeight * 100).toFixed(2);
            hotAreas[i]['l-offset-percent'] = parseFloat(hotAreas[i]['left'] / imgWidth * 100).toFixed(2);
            hotAreas[i]['t-offset-percent'] = parseFloat(hotAreas[i]['top'] / imgHeight * 100).toFixed(2);
        }

        if(typeof callBack == 'function'){
            callBack(hotAreas)
        }
        this.close();
    }
    close = () => {
        this.setState({
            visible:false
        })
    }
    handleCancel = () => {
       this.close()
    }
    handleAddHot = () => {
        let hotAreas = this.state.hotAreas,
            newData = { left: 10,top: 10,width: 100,height: 100, linkType:0, link:''};
        if(hotAreas.length >= 10){
            message.error('单张图片最多设置10个热区');
            return ;
        }
        hotAreas.push(newData)
        this.setState({
            hotAreas
        })


    }
    handleDrag = (e, data,item,index) => {
        let hotAreas = this.state.hotAreas;
        item.left = data.x;
        item.top = data.y;
        hotAreas[index] = item;
        this.setState({
            hotAreas
        })

    }
    handleResize = (e, dir, ref, delta, position,item,index) => {
        let hotAreas = this.state.hotAreas;
        item.width =  Number(item.width) + delta.width;
        item.height = Number(item.height) + delta.height;
        hotAreas[index] = item;
        this.setState({
            hotAreas
        })
    }
    handleChangeLink = (item,index) => {
        let hotAreas = this.state.hotAreas;
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    item[i] = res[i];
                }
                hotAreas[index] = item;
                this.setState({
                    hotAreas
                })
            }

        })
    }
    handleDeleteHot = (e,item,index) => {
        e.stopPropagation()
        let hotAreas = this.state.hotAreas;
        Modal.confirm({
            title:`确定删除这个热区吗？`,
            onOk:() => {
                hotAreas.splice(index,1);
                this.setState({
                    hotAreas
                })
            }
        })

    }


    render() {
        return (
            <div>
                <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={600}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                    centered={true}
                    cancelText={`添加热区`}
                    cancelButtonProps={{ onClick:() => { this.handleAddHot() } }}
                >
                    <div className={`decorate-hot-area-image-editor__dialog`}>
                        <div className="header-content">
                            <span className="step-content">
                                <span className="step-num">1</span>
                                <span className="step-text">添加热区</span>
                                <span>-</span>
                            </span>
                            <span className="step-content">
                                <span className="step-num">2</span>
                                <span className="step-text">调整热区大小和位置</span>
                                <span>-</span>
                            </span>
                            <span className="step-content">
                                <span className="step-num">3</span>
                                <span className="step-text">设置关联链接</span>
                                <span>-</span>
                            </span>
                            <span className="step-content">
                                <span className="step-num">4</span>
                                <span className="step-text">保存生效</span>
                                <span></span>
                            </span>
                        </div>
                        <div className={`image-content`}>
                            <div className={`image-content-inner`}>
                                <img className={`decorate-hot-area-image-editor__dialog-image`} src={this.state.pic} ref={`hotImg`} alt=""/>
                                {
                                    this.state.hotAreas.map((item,index) => (
                                        <Rnd
                                            key={index}
                                            bounds=".image-content-inner"
                                            enableResizing={RND_ENABLE_SIZE}
                                            position={{
                                                x: item.left,
                                                y: item.top,
                                            }}
                                            size={{
                                                width: item.width,
                                                height: item.height,
                                            }}
                                            maxWidth="100%"
                                            minWidth={60}
                                            minHeight={60}
                                            onDrag={(e,data) => { this.handleDrag(e,data,item,index) }}
                                            onResizeStop={(e,dir,ref,delta,position) => { this.handleResize(e,dir,ref,delta,position,item,index) }}
                                            ref={ e => { this[`rnd${index}`] = e }}
                                        >
                                            <div className={`hotarea-rnd`}>
                                                <div className={`hotarea-inner`}>
                                                    <div className={`hotarea`} onDoubleClick={ () => { this.handleChangeLink(item,index) } }>
                                                        <div className={`hotarea-text`}>
                                                            {
                                                                item.name
                                                                    ?
                                                                    <div>
                                                                        <div>{ item.name }</div>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <div>双击设置</div>
                                                                        <div>关联链接</div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`hotarea-close`} onClick={(e) => { this.handleDeleteHot(e,item,index) }} >×</div>
                                            </div>
                                        </Rnd>
                                    ))
                                }


                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default EditHotArea
