import React from 'react'
import { Form, Button, Table, Select,Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { roomStatus, setDateStock } from '@/api/book'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import AddRoomStock from './add-room-stock'
const FormItem = Form.Item

class KtvRoomStatus extends React.Component{
    static contextTypes = {
        setLoading: PropTypes.func,
        setContextState: PropTypes.func,
    }
    state = {
        formData:{},
        currentStore:'',
        columns:[],
        tableData:[],
        tableLoading:false,
        isLoading:false,
        week_list:[],
        room_list:[],
        time_list:[],
        stock_data:'',
        currentWeek:'',
        currentStockData:'',
        currentRow:'', //当前编辑弹层的数据
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()

    }
    fetchData = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        if(!currentStore){
            this.setState({
                currentStore:this.props.storeLists[0]
            })
        }
        sendData.store_id = currentStore ? currentStore.id : this.props.storeLists[0].id;
        this.context.setLoading(true)
        roomStatus(sendData)
            .then(res => {
                if(res.code == 1){
                    let room_list = res.data.room_list || [],
                        week_list = res.data.week_list || [],
                        time_list = res.data.time_list || [],
                        currentWeek = this.state.currentWeek,
                        stock_data = res.data.stock_data || '';
                    if(week_list.length){
                        if(currentWeek){
                            let currentId = currentWeek.id || ``;
                            currentWeek= '';
                            week_list.forEach((item,index) => {
                                item.is_choose = 0;
                                if(item.id == currentId){
                                    item.is_choose = 1;
                                    currentWeek = item
                                }
                            })
                        }else {
                            week_list.forEach((item,index) => {
                                item.is_choose = 0;
                            })
                            week_list[0]['is_choose'] = 1;
                            currentWeek = week_list[0]
                        }
                        if(!currentWeek){
                            week_list[0]['is_choose'] = 1;
                            currentWeek = week_list[0]
                        }
                    }

                    this.setState({
                        room_list,week_list,time_list,stock_data,currentWeek,
                        currentStore:res.data.store ? res.data.store : this.state.currentStore
                    },() => {
                        if(stock_data.length == 0){
                            this.handleDefaults()
                        }else {
                            this.handleCurrentStock()
                        }
                    })
                }
            })
            .finally(() => {
                this.context.setLoading(false)

            })

    }
    handleStoreChange = (val) => {
        let currentStore = '',
            storeLists = this.props.storeLists || [];
        if(val){
            storeLists.map(item => {
                if(item.id == val){
                    currentStore = item;
                    this.setState({
                        currentStore
                    },() => {
                        this.fetchData();
                    })
                }
            })
        }

    }
    renderWeek = () => {
        let week_list = this.state.week_list || [];
        return(
            <div className={`ktv-btn-group mgb15`}>
                {
                    week_list.map((item,index) => (
                        <div className={`ktv-btn`} key={index}>
                            <Button
                                type={`${ item.is_choose == 1 ? 'primary':'default' }`}
                                onClick={ () => { this.handleWeek(item,index) } }
                            >
                                <div>{ item.date } { item.week_name }</div>
                            </Button>
                        </div>
                    ))
                }
            </div>
        )
    }
    handleWeek = (rowData,index) => {
        let week_list = this.state.week_list || [],
            currentWeek = '';
        for(var i in week_list){
            week_list[i]['is_choose'] = 0
            if(week_list[i]['id'] == rowData.id){
                week_list[i]['is_choose'] = 1
                currentWeek = week_list[i]
            }
        }
        this.setState({
            week_list,
            currentWeek
        },() => {
            this.handleCurrentStock()
        })

    }
    handleCurrentStock = () => {
        let currentWeek = this.state.currentWeek,
            room_list = this.state.room_list,
            time_list = this.state.time_list,
            currentStockData = {};
        if(currentWeek){
            currentStockData.week_id = currentWeek.id;
            currentStockData.week_name = currentWeek.week_name;
            currentStockData.date = currentWeek.date;
            currentStockData.special_date = currentWeek.special_date;
            currentStockData.room_list = [];
            for(var i in room_list){
                room_list[i].time_list = time_list;
            }
            currentStockData.room_list = room_list
            this.setState({
                currentStockData
            },() => {
                this.handleTableData()
            })
        }else {
            this.handleDefaults()
        }
    }
    handleTableData = () => {
        let currentStockData = this.state.currentStockData,
            stock_data = this.state.stock_data;
        if(currentStockData){
            let room_list = currentStockData.room_list,
                columns = [],
                tableData = [];
            room_list.forEach((room,roomIndex) => {
                columns.push(
                    {
                        title:<div className={`txtCenter`}>{room.name}</div>,
                        align:'center',
                        dataIndex:room.id,
                        render:(text, rowData, index) => {
                            return (
                                <div className={`lh25 txtCenter`}>
                                    {
                                        rowData[`${currentStockData.week_id}_${rowData.id}_${room.id}`] && rowData[`${currentStockData.week_id}_${rowData.id}_${room.id}`].stock > 0
                                            ?
                                            <div className={`fi-link`} onClick={ () => { this.handleChangeStock(rowData,`${currentStockData.week_id}_${rowData.id}_${room.id}`) } }>可订{ rowData[`${currentStockData.week_id}_${rowData.id}_${room.id}`].stock }</div>
                                            :
                                            <div className={`fi-link colorRed`} onClick={ () => { this.handleChangeStock(rowData,`${currentStockData.week_id}_${rowData.id}_${room.id}`) } }>满包</div>
                                    }
                                    <div className={`lh25`}>已售{ rowData[`${currentStockData.week_id}_${rowData.id}_${room.id}`] ?  rowData[`${currentStockData.week_id}_${rowData.id}_${room.id}`].booking_num : 0 }</div>
                                </div>
                            )
                        }
                    }
                )
                room.time_list && room.time_list.forEach((time,timeIndex) => {
                    let key = `${currentStockData.week_id}_${time.id}_${room.id}`
                    time[key] = stock_data[key]
                })
            })
            tableData = room_list[0]['time_list']
            let thFirst = {
                    title: '时段',
                    width: 170,
                    dataIndex: 'timeRange',
                    fixed: 'left',
                    render:(text, rowData, index) => {
                        return(
                            <div className={`lh25`}><p>{`${rowData.start_time}-${rowData.end_time}`}</p></div>
                        )
                    }
                };
            columns = [ thFirst,...columns ]
            this.setState({
                columns,tableData
            })

        }

    }
    handleDefaults = () =>  {
        let room_list = this.state.room_list,
            currentStore = this.state.currentStore || '',
            columns = [],
            tableData = [{id:0,name:'未设置'}];
        room_list.forEach((room,roomIndex) => {
            columns.push(
                {
                    title:<div className={`txtCenter`}>{room.name}</div>,
                    align:'center',
                    dataIndex:room.id,
                    render:(text, rowData, index) => {
                        return (
                            <div className={`lh25 txtCenter`}>
                                <div className={`fi-link colorRed`} onClick={ this.handleNoData }>满包</div>
                                <div className={`lh25`}>已售0</div>
                            </div>
                        )
                    }
                }
            )
        })
        let thFirst = {
            title: '时段',
            width: 170,
            dataIndex: 'timeRange',
            fixed: 'left',
            render:(text, rowData, index) => {
                return(
                    <div className={`lh25`}>
                        {
                            currentStore.is_online_all_day
                                ?
                                '全天营业'
                                :
                                `${currentStore.online_start_time}-${currentStore.online_end_time}`
                        }
                    </div>
                )
            }
        };
        columns = [ thFirst,...columns ]
        this.setState({
            tableData,
            columns
        })

    }
    handleNoData = () => {
        Modal.confirm({
            title:`提示`,
            content:`您还没有设置时段库存，请前往设置`,
            onOk:() => {
                this.context.setContextState({
                    activeKey:'2'
                })
            }
        })
    }
    handleChangeStock = (rowData,uniqueKey) => {
        let currentRow = {},
            currentStore = this.state.currentStore || '';
        currentRow.uniqueKey = uniqueKey;
        if(rowData[uniqueKey]){
            currentRow.booking_num =  rowData[uniqueKey]['booking_num'];
            currentRow.id = rowData[uniqueKey]['id'];
            currentRow.stock = rowData[uniqueKey]['stock'];
        }
        this.setState({
            currentRow
        },() => {
            this.AddRoomStock.show(res => {
                if(res){
                    let sendData = {},
                        [ week_id, time_id, room_id ] = uniqueKey.split('_');
                    sendData.store_id = currentStore ? currentStore.id : this.props.storeLists[0].id;
                    sendData.week_id = week_id;
                    sendData.time_id= time_id;
                    sendData.room_id = room_id;
                    sendData.id = currentRow.id;
                    sendData.stock = res.stock;
                    this.context.setLoading(true);
                    setDateStock(sendData)
                        .then(result => {
                            if(result.code == 1){
                                message.success('设置成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() =>{
                            this.context.setLoading(false);
                        })




                }
            })
        })

    }
    render() {
        let data = this.state.formData || {},
            storeLists = this.props.storeLists || [],
            currentStore = this.state.currentStore || {};
        const {getFieldDecorator, getFieldValue} = this.props.form
        return(
            <div className={``}>
                <AddRoomStock onRef={ e => { this.AddRoomStock = e } } data={ this.state.currentRow }></AddRoomStock>
                <div className={''}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'选择门店'} className={'fl mgl10'}>
                            <Select style={{width:170}} placeholder={'请选择'} value={ currentStore.id } onChange={ this.handleStoreChange }>
                                {
                                    storeLists.map(store => (
                                        <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                    ))
                                }
                            </Select>
                            <span className={`fi-help-text`}>选择门店进行单独设置，设置后保存</span>
                        </FormItem>
                    </Form>
                </div>
                { this.renderWeek() }
                <div className={'mgt15'}>
                    <Table
                        style={{ width:'100%' }}
                        size={`middle`}
                        columns={ this.state.columns }
                        dataSource={ this.state.tableData }
                        rowKey={(record,index) => index }
                        scroll={{ x: 1200 }}
                        pagination={false}
                        bordered
                    ></Table>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(KtvRoomStatus))
