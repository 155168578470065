import React, { Component } from 'react'
import { Form, Input, Button, Radio, Select, DatePicker, Row, Col, Modal, TreeSelect, Divider, TimePicker } from 'antd'
import moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { gradeSetTable,teacherApi } from '@/api/educational'
import { CourseCategoryApi } from '@/api/course'
import WeekCourse from './weekCourse'
import message from '@/utils/message'
const FormItem = Form.Item;

class PlanCourse extends Component {

    state = {
        courseLists: [],
        storeLists: [],
        teacherList:[],
        modalLoading: false
    }
    componentDidMount() {
        this.fetchStore()
        this.fetchCourse()
        this.fetchTeacher()
    }

    fetchTeacher = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        
        teacherApi(sendData)
            .then(res => {
                
                if (res.code == 1) {
                    let lists = res.data.list.data
        
                    this.setState({
                        teacherList: lists
                    })
                }
            })
            
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    fetchCourse = () => {

        CourseCategoryApi({ page: 1, per_page: 100 }).then(res => {

            if (res.code === 1) {
                let { data } = res.data.list

                data.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.disabled = item.children && item.children.length > 0
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })

                this.setState({
                    courseLists: data
                })
            }
        })
    }




    saveData = () => {
        let sendData = {}, data = this.props.grade_line
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values;
                sendData.grade_id=data.id

                if (sendData.open_date) {
                    sendData.open_date = moment(values.open_date).format('YYYY-MM-DD')

                    if (moment(data.start_date,'YYYY-MM-DD').diff(moment(sendData.open_date,'YYYY-MM-DD'),'days') > 0) {
                        return message.error("开课时间必需大于开班时间")
                    }
                }

                if (sendData.course_end_date) {
                    sendData.course_end_date = moment(values.course_end_date).format('YYYY-MM-DD')

                    if (moment(data.end_date,'YYYY-MM-DD').diff(moment(sendData.course_end_date,'YYYY-MM-DD'),'days') < 0 ) {
                        return message.error("结束时间必须小于结班时间")
                    }
                }

                if (sendData.course_start_time) {
                    sendData.course_start_time = moment(values.course_start_time).format('HH:mm')
                }

                if (sendData.course_end_time) {
                    sendData.course_end_time = moment(values.course_end_time).format('HH:mm')

                    let start_time=moment(sendData.course_start_time,'HH:mm'),
                    end_time=moment( sendData.course_end_time,'HH:mm'),
                    diff=moment(end_time).diff(moment(start_time),'seconds')

                    if(diff <= 0){
                        return message.error("排课的结束时间必须大于开始时间")
                    }
                }

                if (sendData.course_type === 1) {
                    let course_week_data = this.weekRef.getData()
                    if(!course_week_data){
                        return message.error("排课的结束时间必须大于开始时间")
                    }
                    sendData.course_week_data=course_week_data
                }


                sendData = { ...data, ...sendData }

                this.setState({
                    modalLoading: true
                })
                gradeSetTable(sendData).then(res => {

                    if (res.code === 1) {
                        message.success(res.msg)
                        this.setState({
                            modalLoading: false
                        })
                        this.props.handleS()
                        this.handleCancel()
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.props.closePlanCourse()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form
        let data = this.props.grade_line || {}

        return (

            <Modal
                title={`排课`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={24}>
                            <FormItem label={'课程类目'} >
                                {
                                    getFieldDecorator('category_id', {
                                        initialValue: data.category_id || "",
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择课程类目'
                                            }
                                        ]
                                    })(
                                        <TreeSelect
                                            showSearch
                                            style={{ width: 300 }}
                                            placeholder={'请选择课程类目'}
                                            treeData={this.state.courseLists}
                                            disabled={true}
                                        />
                                    )
                                }
                            </FormItem>
                            <FormItem label="上课班级">
                                {getFieldDecorator('name', {
                                    initialValue: data.name || "",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择上课班级'
                                        }
                                    ]
                                })(
                                    <Input placeholder='请输入班级名称' disabled  style={{ width: 300 }} />
                                )}
                                <span className={`fi-help-text`}>开班时间（{data.start_date}到{data.end_date}）</span>
                            </FormItem>

                            <FormItem label="排课方式">
                                {getFieldDecorator('course_type', {
                                    initialValue: 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择排课方式'
                                        }
                                    ]
                                })(
                                    <Radio.Group>
                                        <Radio value={1}>按周重复排课</Radio>
                                        <Radio value={2}>按天重复排课</Radio>
                                    </Radio.Group>
                                )}
                                {
                                    getFieldValue("course_type") === 1 ? <WeekCourse course_week_data={[]} onRef={e => { this.weekRef = e }} /> : null
                                }
                            </FormItem>

                            <FormItem label={'开课时间'} >
                                {
                                    getFieldDecorator('open_date', {
                                        initialValue:  null,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择开课时间'
                                            }
                                        ]
                                    })(
                                        <DatePicker placeholder='请选择开课时间'  style={{ width: 300 }} />
                                    )
                                }
                            </FormItem>

                            {
                                getFieldValue("course_type") === 2 ?
                                <FormItem className='clearfix ' label={'上课时间'} style={{margin:0}}  required={true}>
                                <FormItem  className={'fl'}>
                                    {
                                        getFieldDecorator('course_start_time', {
                                            initialValue:  null,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请填写开始时间'
                                                }
                                            ]
                                        })(<TimePicker placeholder='开始时间' format={"HH:mm"} />)
                                    }
                                    <span className='mgl10 mgr10'>至</span>
                                </FormItem>
                                
                                <FormItem  className={'fl'}>
                                  
                                    {
                                        getFieldDecorator('course_end_time', {
                                            initialValue:  null,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请填写结束时间'
                                                }
                                            ]
                                        })(<TimePicker placeholder='结束时间' format={"HH:mm"} />)
                                    }
                                </FormItem>
                            </FormItem> : null
                            }

                            <FormItem label="结束方式">
                                {getFieldDecorator('course_end_type', {
                                    initialValue: 1
                                })(
                                    <Radio.Group>
                                        <Radio value={1}>按课节总数</Radio>
                                        <Radio value={2}>按日期结束</Radio>
                                    </Radio.Group>
                                )}

                            </FormItem>

                            {
                                getFieldValue("course_end_type") === 1 ?
                                    <FormItem label={'排课总数'} >
                                        {
                                            getFieldDecorator('course_total_num', {
                                                initialValue:  null,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请填写次要排的课节总数'
                                                    }
                                                ]
                                            })(<Input placeholder='本次要排的课节总数'  style={{ width: 300 }} />)
                                        }
                                    </FormItem>
                                    :
                                    <FormItem label={'结束时间'} >
                                        {
                                            getFieldDecorator('course_end_date', {
                                                initialValue: null,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择结束时间'
                                                    }
                                                ]
                                            })(
                                                <DatePicker placeholder='请选择结束时间'  style={{ width: 300 }} />
                                            )
                                        }
                                    </FormItem>
                            }




                            <FormItem label={'扣课时数'} >
                                {
                                    getFieldDecorator('course_cost_num', {
                                        initialValue: data.course_cost_num || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请填写每节课所扣课时数'
                                            }
                                        ]
                                    })(<Input addonAfter="课时" placeholder='请填写每节课所扣课时数'  style={{ width: 300 }} />)
                                }
                            </FormItem>

                            <FormItem label={'上课地点'} >
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: data.store_id || "",
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择上课地点'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择上课地点'}  style={{ width: 300 }}  >
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                                <Link to={'/store/lists'} target={`_blank`} >
                                    <span className='text_link mgl15'>新增</span>
                                </Link>
                                <Divider type={`vertical`}></Divider>
                                <span className='text_link' onClick={() => { this.fetchStore() }}>刷新</span>
                            </FormItem>

                            <FormItem label={'老师'} >
                                {
                                    getFieldDecorator('teacher_id', {
                                        initialValue: data.teacher_id || undefined,
                                    })(
                                        <Select placeholder={'请选择老师'}  style={{ width: 300 }}   >
                                            {
                                                this.state.teacherList.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                                <Link to={'/staff/lists'} target={`_blank`} >
                                    <span className='text_link mgl15'>新增</span>
                                </Link>
                                <Divider type={`vertical`}></Divider>
                                <span className='text_link' onClick={() => { this.fetchTeacher() }}>刷新</span>
                            </FormItem>

                            <FormItem label={'助教'} >
                                {
                                    getFieldDecorator('assist_teacher_id', {
                                        initialValue: data.assist_teacher_id || undefined,
                                    })(
                                        <Select placeholder={'请选择助教'}  style={{ width: 300 }}   >
                                            {
                                                this.state.teacherList.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                                <Link to={'/staff/lists'} target={`_blank`} >
                                    <span className='text_link mgl15'>新增</span>
                                </Link>
                                <Divider type={`vertical`}></Divider>
                                <span className='text_link' onClick={() => { this.fetchTeacher() }}>刷新</span>
                            </FormItem>

                        </Col>

                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(PlanCourse))