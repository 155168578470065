import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, Tag, TreeSelect, Modal, Divider, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { staffList ,roleList,staffDelete,staffSave} from '@/api/staff'
import AddStaff from './components/addStaff'
import message from '@/utils/message'
import Loading from '@/components/Loading'


const { TreeNode } = TreeSelect;

const FormItem = Form.Item;

class Staff extends React.Component {

    state = {
        selectRows: [],
        searchForm: {
            name: "",
            mobile:"",
            store_id:0,
            status: 0,
            order_field:'',
            order_asc:''
        },
        columns: [
            {
                title: '员工ID',
                dataIndex: '1',

                render: (text, rowData, index) => {
                    return (
                        <div >{rowData.id}</div>
                    )
                }
            },
            {
                title: '员工姓名',
                dataIndex: '2',

                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.name} </div>
                    )
                }
            },
            {
                title: '手机号',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div> {rowData.staff_mobile}</div>
                    )
                }
            },
            {
                title: '角色',
                dataIndex: '4',
                render: (text, rowData, index) => {

                    return (
                        <div> {rowData.role_name}</div>
                    )
                }
            },
            {
                title: '所属门店',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {rowData?.store?.name || '-'}
                        </div>
                    )
                }
            },
            {
                title: '排序',
                dataIndex: '6',
                defaultSortOrder: '',
                sorter: true,
                render: (text, rowData, index) => {
                    return (
                        <div>
                        {
                            rowData.sort_edit
                                ?
                                <Input
                                    style={{ width: '80px' }}
                                    value={rowData.sort}
                                    onChange={(e) => { this.handleInputChange(e, rowData, index) }}
                                    onBlur={(e) => { this.handleField(e, rowData, index) }}
                                    data-name={'sort'}
                                />
                                :
                                <span>{rowData.sort}</span>
                        }
                        <Icon type="edit" className={`mgl5 cursor`} onClick={(e) => { this.showEdit(e, rowData, index) }} data-name={`sort`} />
                    </div>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.status==1?"在职":"离职"} </div>
                    )
                }
            },
          
            {
                title: '操作',
                dataIndex: 'opts',

                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>

                            <Button type={"link"} size={"small"} onClick={() => { this.editStaff(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.delteStaff(rowData.id) }}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        storeLists: [],
        role_list:[],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,
        is_staff:false,
        staff_line:"",
        edit_status:false

    }

    componentDidMount() {
           this.fetchData()
        this.fetchStore()
        this.fetchRole()
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
    }

    fetchRole = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
       
        roleList(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data
                    lists=lists.map(v=>{
                        let obj={}
                       obj.value=v.id +''
                       obj.label=v.name
                        return obj
                    })


                    this.setState({
                        role_list: lists
                    })
                }
            })
           
    }


    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.per_page = this.state.per_page;
        this.setState({
            tableLoading: true
        })
        staffList(sendData)
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    
                        lists.forEach(item => {
                            item.sort_edit = 0;
                        })

                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }

    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = {
            name: "",
            mobile:"",
            store_id:0,
            status: 0
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    handleInputChange = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value
        lists[index][name] = val;
        this.setState({
            tableData: lists
        })
    }

    handleField = (e, rowData, index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        if (!val) { return; }

        sendData = { ...rowData }
        sendData[name] = val;

        staffSave(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.fetchData();
                }
            })
    }

    showEdit = (e, rowData, index) => {
        let lists = this.state.tableData

            lists[index]['sort_edit'] = 1
        this.setState({
            tableData: lists
        })
    }

    handleSort = (pagination, filters, sorter) => {
        let 
            order = sorter.order,
            searchForm = this.state.searchForm;
        if (order) {
            searchForm.order_field = 'sort';
            searchForm.order_asc = order == 'ascend' ? 'asc' : 'desc';
        } else {
            searchForm.order_field = '';
            searchForm.order_asc = '';
        }
        
        this.setState({
            searchForm
        },() => {
            this.fetchData()
        })
    }

    delteStaff=id=>{
        Modal.confirm({
            title: `是否确认要删除？删除后无法恢复，请谨慎操作！`,
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                staffDelete({ id})
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }

    editStaff=row=>{
        this.setState({
            staff_line:row,
            edit_status:true,
            is_staff:true
        })
    }

    handleAdd=()=>{
        this.setState({
            is_staff:true
        })
    }

    handleCloseStaff=()=>{
        this.setState({
            is_staff:false,
            edit_status:false
        })
    }


    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <div className={'shadow-radius'}>

                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'员工姓名'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入员工姓名' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                            <FormItem label={'手机号'} className={'fl mgl40'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入员工手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>

                        </div>

                        <div className={`clearfix`}>

                            <FormItem label={'所属门店'} className={' fl'}>
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>


                            <FormItem label={<span>&emsp;状态</span> }  className={'mgl40 fl'}>
                                {
                                    getFieldDecorator('status', {
                                        initialValue: 0
                                    })(
                                        <Select style={{ width: 200 }} placeholder={'请输入班级状态'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>在职</Select.Option>
                                            <Select.Option value={2}>离职</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>

                        <div className={`clearfix`}>
                            <FormItem className={'mgl70 fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增员工</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                        onChange={(pagination, filters, sorter) => { this.handleSort(pagination, filters, sorter) }}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
 
                {this.state.is_staff?<AddStaff staff={this.state.staff_line}  edit_status={this.state.edit_status} close={this.handleCloseStaff} success={this.fetchData} storeLists={this.state.storeLists} role_list={this.state.role_list} />:null }
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }


}

export default Form.create({})(withRouter(Staff))
