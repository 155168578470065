import React from 'react'
import { Modal, Input, Button, Table, Pagination, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { articleLists} from '@/api/recommend.js'
import CX from '@/utils/CX'

export default class NotePicker extends React.Component{

    state = {
        title:'选择笔记',
        visible:false,
        modalLoading:false,
        tableLoading:false,
        columns:[
            {
                title: '标题',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.title}</p>
                    )
                }
            },
            {
                title: '创作者',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    let author_info=rowData.author_info ||{}
                    return (
                        <p>{author_info.name} </p>
                    )
                }
            },
            {
                title: '话题',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.topic_name}</p>
                    )
                }
            },
            {
                title: '创建时间',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.publish_time ||rowData.created_at} </p>
                    )
                }
            },
            {
                title: '公开状态',
                dataIndex: '6',
                width: 90,
                render: (text, rowData, index) => {
                    let color = "", tag_text = ""

                    if (rowData.status == 0 || rowData.status == 2) {
                        color = ""
                        tag_text = "隐藏"
                    } else if (rowData.status == 3) {
                        color = "orange"
                        tag_text = "待审核"
                    } else if (rowData.status == 1) {
                        color = "green"
                        tag_text = "公开"

                        if (rowData.is_top) {
                            color = "red"
                            tag_text = "置顶"
                        }
                    }

                    return (
                        <Tag color={color}>{tag_text} </Tag>
                    )
                }
            }
        ],
        tableData:[],
        selectRows:[],
        selectedRowKeys:[],
        page:1,
        name:'',
        per_page:10,
        total:0,
        callback:null
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    show = (callback) => {
        let selectIds = this.props.selectIds || [],
            items = this.props.items || [];
        this.setState({
            visible:true,
            callback: callback ? callback : null,
            selectedRowKeys:selectIds,
            selectRows:items
        },() => {
            this.fetchData();
        })
    }
    close = () => {
        this.setState({
            name:'',
            page:1,
            visible:false
        })
    }
    fetchData = () => {
        let sendData = {
            page:this.state.page,
            name:this.state.name,
            per_page:this.state.per_page
        }
        this.setState({
            tableLoading:true
        })
        articleLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let { data ,total,per_page} = res.data.list
                    let tableData = data
                    this.renderSelectRows(tableData)
                    this.setState({
                        tableData, per_page, total
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    renderSelectRows = lists => {
        let selectedRowKeys = this.state.selectedRowKeys,
            selectRows = this.state.selectRows;
        if(selectedRowKeys.length){
            for(var k in lists){
                if(selectedRowKeys.includes(lists[k]['id'])){
                    let selectRowsIds = selectRows.map(item => {return item.id})
                    if(!selectRowsIds.includes(lists[k]['id'])){
                        selectRows.push(lists[k])
                    }
                }
            }
            this.setState({
                selectRows
            })
        }


    }
    handleOk = () => {
        let callback = this.state.callback;
        if(typeof callback == 'function'){
            callback(this.state.selectRows)
        }
        this.close();
    }
    handleCancel = () => {
        this.close()
    }
    handleCurrentChange = val =>  {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    getInputValue = e => {
        this.setState({
            name: e.target.value
        })
    }

    render() {
        let multiple = !!this.props.multiple
        const rowSelection = {
            type: multiple ? 'checkbox' : 'radio',
            selectedRowKeys:this.state.selectedRowKeys,
            onChange: (selectedRowKeys,selectedRows) => {

                if(selectedRowKeys.length == selectedRows.length){
                    this.setState({
                        selectRows:selectedRows,
                        selectedRowKeys
                    })
                }else {
                    let arr = [],
                        oldList = this.state.selectRows,
                        tmpList = [];
                    selectedRows.forEach(item => {
                        arr.push(item.id)
                    })
                    let diffArr = CX.diff(arr,selectedRowKeys);
                    for(var i in diffArr){
                        for (var j in  oldList){
                            if(oldList[j]['id'] == diffArr[i]){
                                tmpList.push(oldList[j])
                            }
                        }
                    }
                    tmpList = tmpList.concat(selectedRows);
                    this.setState({
                        selectRows:tmpList,
                        selectedRowKeys
                    })
                }
            }
        }
        return(
            <div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={915}
                    maskClosable={false}
                    confirmLoading={this.state.modalLoading}
                    centered={true}

                >
                    <div className={'title clearfix'}>
                        <div className={'fr btn-group'}>
                            <Input value={this.state.name} onChange={this.getInputValue} placeholder={'输入笔记名称'} style={{'width':'180px'}} />
                            <Button type={'primary'} icon={'search'} style={{'verticalAlign':'top'}} onClick={this.fetchData}>搜索</Button>
                        </div>
                    </div>
                    <div className={'mgt20'}>
                        <Table
                            size={`middle`}
                            rowSelection={rowSelection}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.tableLoading}
                            rowKey={record => record.id}
                            pagination={false}
                        >
                        </Table>
                        {
                            this.state.tableData.length
                                ?
                                <div className={'clearfix mgt10'}>
                                    <div className={'fl'}>
                                        <p>已选择{this.state.selectedRowKeys.length}篇笔记</p>
                                    </div>
                                    <div className={'fr'}>
                                        <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                    </div>
                                </div>
                                :
                                ''
                        }

                    </div>

                </Modal>
            </div>
        )

    }

}


