import React from 'react'
import {
    Form,
    Input,
    Radio,
    Button,
    Select,
    Icon,
    Checkbox,
    Tooltip,
    Table,
    Modal,
    DatePicker,
    TimePicker,
    Switch
} from 'antd'
import {withRouter, Link} from 'react-router-dom'
import { storeLists } from '@/api/store'
import { uploadFile } from '@/api/file'
import '@/assets/css/item/item.scss'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Editor from '@/components/Editor/Editor'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
import ItemPicker from '@/components/ItemPicker/ItemPicker'
import PropTypes from "prop-types";
import message from '@/utils/message';
import moment from 'moment';
import Cookie from 'js-cookie'
import '@/assets/css/card/detail.scss'
import EquityModal from './EquityModal'
import RewardModal from './RewardModal'
import { setConfig, getConfig } from '@/api/config'


const FormItem = Form.Item
const {TextArea} = Input

class LevelEditForm extends React.Component {
    static contextTypes = {
        setLoading: PropTypes.func
    }
    state = {
        formData: {
            user_level_switch:0,
            join_switch:0,
            pay_rule:[
                {
                    effect_time:1,
                    price:10,
                    exp:10
                }
            ],
            level_rule:[
                {
                    level:1,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/f711d4c42de17903e82d1cf4fe704d0e.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:1
                },
                {
                    level:2,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/ae6eeb21652b570350d5835e3a6fbcee.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:2
                },
                {
                    level:3,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/1e8704dc744929a27255f70b3011ff91.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:3
                },
                {
                    level:4,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/22d0358ae34048cd955202a72de6ceca.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:4
                },
                {
                    level:5,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/dd3e4665df76909ec18d6e5fb34cfac4.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:5
                },
                {
                    level:6,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/c3e450dd3e6c458ca941d7e89b4a3d12.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:6
                }
            ],
            reduce_rule:{
                reduce_switch:0,
                month_value:1,
                lists:[
                    {
                        level:1,
                        exp:null
                    },
                    {
                        level:2,
                        exp:null
                    },
                    {
                        level:3,
                        exp:null
                    },
                    {
                        level:4,
                        exp:null
                    },
                    {
                        level:5,
                        exp:null
                    },
                    {
                        level:6,
                        exp:null
                    }
                ]
            }
        },
        columns: [
            {
                title: '有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <Select style={{ width: '150px' }} placeholder="请选择" value={rowData.effect_time} onChange={(e) => { this.handleSelectChange(e,index) }}>
                            <Select.Option value={1}>1个月</Select.Option>
                            <Select.Option value={2}>3个月</Select.Option>
                            <Select.Option value={3}>6个月</Select.Option>
                            <Select.Option value={4}>12个月</Select.Option>
                        </Select>
                    )
                }
            },
            {
                title: '价格',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <Input 
                            addonAfter={'元'} 
                            style={{ width:'130px' }}
                            value = { rowData.price || `` }
                            onChange={ e => { this.handleInputChange(e,index) } } 
                        />
                    )
                }
            },
            {
                title: '获得成长值',
                dataIndex: 'exp',
                render: (text, rowData, index) => {
                    return (
                        <Input 
                            addonAfter={'点'} 
                            style={{ width:'130px' }}
                            value = { rowData.exp || `` }
                            onChange={ e => { this.handleInputExpChange(e,index) } } 
                        />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => {this.delRule(index)}}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ],
        levelColumns: [
            {
                title: '等级',
                dataIndex:'level',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>V{rowData.level}</p>
                    )
                }
            },
            {
                title: '会员卡面',
                dataIndex: 'cover_img',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex align-center`}>
                            <img src={rowData.cover_img} alt="" height={60} onClick={ e => { this.chooseImg(e,'cover_img',index) } }/>
                            <div onClick={()=>{ this.resetImg(index) }} style={{color:'blue',cursor:'pointer'}} className={`mgl15`}>重置</div>
                        </div>
                    )
                }
            },
            {
                title: '等级名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <Input
                            style={{width:'100px'}}
                            placeholder= "请输入等级名称"
                            value = { rowData.name || `` }
                            onChange={ e => { this.handleLevelInputChange(e,'name',index) } }
                        />
                    )
                }
            },
            {
                title: '升级条件',
                dataIndex: 'exp',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex  align-center`}>
                            <div className={`mgr10`}>成长值达到</div>
                            <Input
                                style={{width:'100px'}}
                                value = { rowData.exp || `` }
                                onChange={ e => { this.handleLevelInputChange(e,'exp',index) } }
                            />
                        </div>
                        
                    )
                }
            },
            {
                title: '等级权益',
                dataIndex: 'equity',
                render: (text, rowData, index) => {
                    if(rowData.equity){
                        return (
                            <div >
                                <div className={`float-modal`}>
                                    <div className={`small-modal`}>已设置{rowData.equity.equity.length + rowData.equity.gift.length}项</div>
                                    <div className={`data-modal`}>
                                    <div style={{color:'blue'}} className={`fz16`}>会员权益：</div>

                                    {
                                        rowData.equity.equity.map((item,index) => {
                                            return (<div className={'mgl5 mgb5'} key={item.id}>· {item.name}</div>)
                                        })
                                    }
                                    <div style={{color:'blue'}} className={`fz16`}>每月礼包：</div>
                                    {
                                        rowData.equity.gift.map((items,indexs) => {
                                            return (<div className={'mgl5 mgb5'} key={items.id}>· {items.name}</div>)
                                        })
                                    }
                                    </div>
                                </div>
                                
                                <div onClick={()=>{ this.updateEquity(index,2)}} style={{color:'blue',cursor:'pointer'}}>修改</div>
                            </div>
                        )
                    }else{
                        return (
                            <div onClick={()=>{ this.updateEquity(index,1)}} style={{color:'blue',cursor:'pointer'}}>
                                设置权益
                            </div>
                        )
                    }
                }
            },
            {
                title: '升级奖励(选填)',
                dataIndex: 'reward',
                render: (text, rowData, index) => {
                    if(rowData.reward){
                        return (
                            <div>
                                <div className={`float-modal`}>
                                    <div className={`small-modal`}>已设置{rowData.reward.length}项</div>
                                    <div className={`data-modal`}>
                                    <div style={{color:'blue'}} className={`fz16`}>升级奖励：</div>

                                    {
                                        rowData.reward.map((item,index) => {
                                            return (<div className={'mgl5 mgb5'} key={item.id}>· {item.name}</div>)
                                        })
                                    }
                                    </div>
                                </div>
                                <div onClick={()=>{ this.updateReward(index,2)}} style={{color:'blue',cursor:'pointer'}}>修改</div>
                            </div>
                        )
                    }else{
                        return (
                            <div onClick={()=>{ this.updateReward(index,1)}} style={{color:'blue',cursor:'pointer'}}>
                                设置奖励
                            </div>
                        )
                    }
                }
            },
        ],
        ruleColumns:[
            {
                title:'等级',
                dataIndex:'name',
                width:'30%',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>V{rowData.level || ''}</p>
                    )
                }
            },
            {
                title:'保级条件',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    if(rowData.level == 1){
                        return ( 
                            <p className={`lh25`}>
                                用户授权信息
                            </p>
                        )
                    }else{
                        return (
                            <div className={`flex align-center`}>
                                <div className={`mgr15`}>成长值达到</div>
                                <Input value={rowData.exp}  style={{ width:'130px' }} onChange={ (e) => { this.handleExpInputChange(e,index) }}></Input>
                            </div>
                        )
                    }
                    
                }
            },
        ],
        multipleSelect: true,
        chooseItems: [],
        storeLists: [],
        step: 1,
        updateEquitylevel:null,
        updateRewardlevel:null,
        showEditor:false,
        uniqueId:""+new Date().getMilliseconds() + Math.round(Math.random()*100),
        rewardData:null,
        equityData:null
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
    }

    fetchData = () => {
		getConfig({ key: 'user_level' }).then(res => {
			if (res.code == 1) {
				let { value } = res.data.config;
				if (value) {
					value = JSON.parse(value)

					this.setState({
						formData: value
					})
				}
			}
		}).finally(() => {
			
		})
    }
    handleCoupon = () => {
        let formData = this.state.formData || {}
        this.CouponPicker.show(res => {
            console.log('res',res)
            if(res.length > 10){
                res.length = 10;
            }
            formData.present_coupon_json = res;
            this.setState({
                formData
            })
        })
    }
    handleChangeType = (e,rowData,index) => {
        let val = e.target.value,
            formData = this.state.formData || {},
            present_goods_data = formData.present_goods_data || [];
        present_goods_data[index]['limit_type'] =  val;
        this.setState({
            formData
        })
    }
    handleTimesChange = (e,rowData,index) => {
        let formData = this.state.formData || {},
            present_goods_data = formData.present_goods_data || [],
            val = e.target.value;
        present_goods_data[index]['limit_num'] = val;
        this.setState({
            formData
        })
    }
    handleExpInputChange = (e,index) => {
        let formData = this.state.formData,
            val = e.target.value;
            formData.reduce_rule.lists[index].exp = val;
        this.setState({
            formData
        })

    }

    handleLevelInputChange = (e,name,index) => {
        let formData = this.state.formData,
            val = e.target.value;
            formData.level_rule[index][name] = val;
        this.setState({
            formData
        })

    }

    handleSelectChange =(e,index) =>{
        let formData = this.state.formData || {},
            val = e;
        formData.pay_rule[index].effect_time = val;
        this.setState({ formData })
    }

    handelMonthChange =(e) =>{
        let formData = this.state.formData || {},
            val = e;
        formData.reduce_rule.month_value = val;
        this.setState({ formData })
    }

    handleInputChange = (e,index) => {
        let formData = this.state.formData || {},
            val = e.target.value;
        formData.pay_rule[index].price = val;
        this.setState({ formData })
    }


    handleInputExpChange = (e,index) => {
        let formData = this.state.formData || {},
            val = e.target.value;
        formData.pay_rule[index].exp = val;
        this.setState({ formData })
    }
    
    
    saveData = () => {
        console.log('最终的结果',this.state.formData);
        let sendData = {}, tableData = this.state.formData;
		sendData['key'] = 'user_level'
		sendData.value = tableData
		setConfig(sendData).then(res => {
			if (res.code == 1) {
				message.success('保存成功');
                this.props.history.push({
                    pathname:`/level/user-level`
                })
			}
		})    
    }

    delRule = (index) =>{
        let resData = this.state.formData;
        resData.pay_rule.splice(index,1);
        this.setState({
            formData:resData
        })
    }

    addRule = () =>{
        let data = this.state.formData;
        let newArr = {
            effect_time:null,
            price:0
        }
        if(data.pay_rule.length >=3 ){
            message.error('最多只能添加3条付费规则');
            return;
        }else{
            data.pay_rule.push(newArr)
        }
        this.setState({
            formData:data
        })
    }

    handleRadioChange = (e) => {
        let data = this.state.formData,
            val = e.target.value;
        data.join_switch = val;
        this.setState({
            formData:data
        })
    }

    handleReduceSwitch = (e) => {
        let data = this.state.formData,
            val = e.target.value;
        data.reduce_rule.reduce_switch = val;
        this.setState({
            formData:data
        })
    }

    updateEquity = (index,type) =>{
        this.setState({
            updateEquitylevel:index
        })
        let row = [];
        if(type === 2){
            let formData = this.state.formData
            row = formData.level_rule[index].equity
        }
        this.equityCreate.show(row, (res)=>{
            console.log(res)
        })
    }

    updateReward = (index,type) =>{
        this.setState({
            updateRewardlevel:index
        })
        let row = [];
        if(type === 2){
            let formData = this.state.formData
            row = formData.level_rule[index].reward
        }
        this.rewardCreate.show(row,(res)=>{
            
        })
    }

    handleEquityData = (res) =>{
        console.log('子组件传递的值', res);
        // this.
        let data = this.state.formData;
        let index = this.state.updateEquitylevel;
        if(res.equity.length>0 || res.gift.length>0){
            data.level_rule[index].equity = res
        }else{
            data.level_rule[index].equity = null
        }
        this.setState({
            formData:data
        })
    }
    handleRewarData = (res) =>{
        console.log('子组件传递的值', res);
        // this.
        let data = this.state.formData;
        let index = this.state.updateRewardlevel;
        if(res.length>0){
            data.level_rule[index].reward = res
        }else{
            data.level_rule[index].reward = null
        }
        this.setState({
            formData:data
        })
    }



    chooseImg = (e,keyName,i) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData.level_rule[i].cover_img = imgs[0]
            this.setState({
                formData
            })
        })
    }

    resetImg = (i) => {
        let formData = this.state.formData || {};
        switch (i) {
            case 0:
                formData.level_rule[i].cover_img = 'https://img.cxkoo.com/chengxuan/1/2023-08-09/f711d4c42de17903e82d1cf4fe704d0e.png'
                break;
            case 1:
                formData.level_rule[i].cover_img = 'https://img.cxkoo.com/chengxuan/1/2023-08-09/ae6eeb21652b570350d5835e3a6fbcee.png'
                break;
            case 2:
                formData.level_rule[i].cover_img = 'https://img.cxkoo.com/chengxuan/1/2023-08-09/1e8704dc744929a27255f70b3011ff91.png'
                break;
            case 3:
                formData.level_rule[i].cover_img = 'https://img.cxkoo.com/chengxuan/1/2023-08-09/22d0358ae34048cd955202a72de6ceca.png'
                break;
            case 4:
                formData.level_rule[i].cover_img = 'https://img.cxkoo.com/chengxuan/1/2023-08-09/c3e450dd3e6c458ca941d7e89b4a3d12.png'
                break;
            case 5:
                formData.level_rule[i].cover_img = 'https://img.cxkoo.com/chengxuan/1/2023-08-09/dd3e4665df76909ec18d6e5fb34cfac4.png'
                break;
            
        }
        this.setState({
            formData
        })
    }

    
    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        
        return (
            <div className={``}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    {
                        this.state.step == 1
                            ?
                            <div>
                                <div className={`msg-title mgb15`}>等级方案</div>
                                <FormItem label={'等级规则'}>
                                    <div className={`flex`}>
                                        <div>按成长值升降级</div>
                                        <div className={`mgl15`}><Link className={`fi-link fz14 w150`} to={`/level/dev-task`}>设置规则</Link></div>
                                    </div>
                                </FormItem>
                                <FormItem label={'付费加入会员'}>
                                        <Radio.Group value={data.join_switch} onChange={ (e) => { this.handleRadioChange(e,'required') } }>
                                            <Radio value={0}>不启用</Radio>
                                            <Radio value={1}>启用</Radio>
                                        </Radio.Group>
                                </FormItem>
                                {
                                    data.join_switch?
                                    <FormItem label={'付费规则'}>
                                        <Table
                                            size={`middle`}
                                            columns={this.state.columns}
                                            dataSource={data.pay_rule}
                                            rowKey={record => record.id}
                                            style={{ width:'50%' }}
                                            pagination={false}
                                        ></Table>
                                        <div className='flex'>
                                            <div style={{color:'blue',cursor:'pointer'}} onClick={this.addRule}>+添加规则</div>
                                            <div style={{color:'#999999',marginLeft:'20px', fontSize:'12px'}}>付费后根据成长值获得点数，直接到达对应等级</div>
                                        </div>
                                    </FormItem>:null
                                }
                                <FormItem label={'等级设置'}>
                                    <Table
                                        size={`middle`}
                                        columns={this.state.levelColumns}
                                        dataSource={data.level_rule}
                                        rowKey={record => record.id}
                                        style={{ width:'100%' }}
                                        pagination={false}
                                    ></Table>
                                </FormItem>
                                <FormItem label={'降级规则'}>
                                    <Radio.Group className={`mgt10`} value={data.reduce_rule.reduce_switch} onChange={ (e)=>{ this.handleReduceSwitch(e)}}>
                                        <Radio value={0} className={`mgb15 block`}>
                                            不降级
                                        </Radio>
                                        <Radio value={1} className={`mgb15 block`}>
                                            得降级
                                            <Select  style={{width:'120px'}} placeholder="请选择" className={`mgl5 mgr5`} value={data.reduce_rule.month_value} onChange={(e)=>{ this.handelMonthChange(e) }}>
                                                <Select.Option value={1}>1个月</Select.Option>
                                                <Select.Option value={2}>3个月</Select.Option>
                                                <Select.Option value={3}>6个月</Select.Option>
                                                <Select.Option value={4}>12个月</Select.Option>
                                            </Select>
                                            内，未达到保级条件则降一级
                                        </Radio>
                                    </Radio.Group>
                                    <Table
                                        size={`middle`}
                                        columns={this.state.ruleColumns}
                                        dataSource={data.reduce_rule.lists}
                                        rowKey={record => record.level}
                                        style={{ width:'50%' }}
                                        pagination={false}
                                    ></Table>
                                </FormItem>
                                <div className={`txtCenter`}>
                                    <Button type={`primary`} onClick={ this.saveData }>保存</Button>
                                </div>
                            </div>
                            :
                            null
                    }

                </Form>
                <EquityModal onRef={e => { this.equityCreate = e }} sendEquity={this.handleEquityData} equityData={this.state.equityData}></EquityModal>
                <RewardModal onRef={e => { this.rewardCreate = e }} sendReward={this.handleRewarData} rewardData={this.state.rewardData}></RewardModal>
            </div>
        )
    }

}

export default Form.create({})(withRouter(LevelEditForm))
