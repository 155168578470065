/**
 * 积分兑换组件
 * @type 24
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'


const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ24 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        let stylePrice = moduleData?.content?.priceColor ? { color: moduleData.content.priceColor } : {};
        let styleBtn = moduleData?.content?.showBtn ? { color: moduleData.content.btnTextColor, background: moduleData.content.btnBgColor } : {};
        return (
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`积分兑换`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>
                        {
                            moduleData.content.showCategory ?
                                <>
                                    {
                                        Object.values(moduleData.content.category).length ?
                                            <div className="point-exchange-tabs">

                                                <div className='flex'>
                                                    {
                                                        Object.values(moduleData.content.category).map((val, index) => {
                                                            return (
                                                                <div className={`point-exchange-tab ${index == 0 ? 'active' : ''}`} key={val.category_id} >{val.category_name}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> :
                                            <div className="point-exchange-tabs">
                                                <div className="point-exchange-tab active">分类一</div>
                                                <div className="point-exchange-tab">分类二</div>
                                            </div>
                                    }
                                </> : null
                        }


                        {
                            moduleData.content.style == 1
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className={`flowList pdl10 pdr10`} >
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 0
                                                        ?
                                                        <div
                                                            className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                                            key={index}
                                                        >
                                                            <div className="item-img"
                                                                style={{ backgroundImage: `url(${item.cover_img})` }}></div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.prize_name}</div>
                                                                <div className={`mgt10`}>
                                                                    <div className="price-cart">
                                                                        <div className="price-group">
                                                                            <div className="price-point" style={stylePrice}>{item.show_points}</div>

                                                                        </div>
                                                                        {
                                                                            moduleData.content.showBtn ?
                                                                                <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }

                                        </div>
                                        <div className={`column`}>
                                            {
                                                moduleData.content.dataset.map((item, index) => (
                                                    index % 2 == 1
                                                        ?
                                                        <div
                                                            className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                                            key={index}>
                                                            <div className="item-img"
                                                                style={{ backgroundImage: `url(${item.cover_img})` }}></div>
                                                            <div className="item-desc">
                                                                <div className="title">{item.prize_name}</div>
                                                                <div className={`mgt10`}>
                                                                    <div className="price-cart">
                                                                        <div className="price-group">
                                                                            <div className="price-point" style={stylePrice}>{item.show_points}</div>
                                                                        </div>
                                                                        {
                                                                            moduleData.content.showBtn ?
                                                                                <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className={`flowList pdl10 pdr10`}>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                            >
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                <div className="item-desc">
                                                    <div className="title">这里显示积分商品标题，最多2行字</div>
                                                    <div className={`mgt10`}>
                                                        <div className="price-cart">
                                                            <div className="price-group">
                                                                <div className="price-point" style={stylePrice} >99积分+9.9元</div>
                                                            </div>
                                                            {
                                                                moduleData.content.showBtn ?
                                                                    <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`column`}>
                                            <div
                                                className={`flow-item ${moduleData.content.borderStyle == 2 ? `shadow` : moduleData.content.borderStyle == 3 ? 'border' : ''}`}
                                            >
                                                <div className="item-img"
                                                    style={{ backgroundImage: `url(https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png)` }}></div>
                                                <div className="item-desc">
                                                    <div className="title">这里显示积分商品标题，最多2行字</div>
                                                    <div className={`mgt10`}>
                                                        <div className="price-cart">
                                                            <div className="price-group">
                                                                <div className="price-point" style={stylePrice}>99积分</div>
                                                            </div>
                                                            {
                                                                moduleData.content.showBtn ?
                                                                    <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null

                        }
                        {
                            moduleData.content.style == 2
                                ?
                                moduleData.content.dataset.length
                                    ?
                                    <div className="item-column3">
                                        {
                                            moduleData.content.dataset.map((item, index) => (
                                                <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''}`} key={index}>
                                                    <div className="top-img"
                                                        style={{ backgroundImage: `url(${item.cover_img})` }}></div>
                                                    <div className="item-desc">
                                                        <div className="title">{item.prize_name}</div>

                                                        <div className="price-group">
                                                            <div className="price" style={stylePrice}>{item.show_points}</div>
                                                            {
                                                                moduleData.content.showBtn ?
                                                                    <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="item-column3">
                                        <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''} `}>
                                            <div className="top-img noImg"></div>
                                            <div className="item-desc">
                                                <div className="title">这里显示积分商品标题，最多2行字</div>
                                                <div className="price-group">
                                                    <div className="price" style={stylePrice}>99积分</div>
                                                    {
                                                        moduleData.content.showBtn ?
                                                            <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''} `}>
                                            <div className="top-img noImg"></div>
                                            <div className="item-desc">
                                                <div className="title">这里显示积分商品标题，最多2行字</div>
                                                <div className="price-group">
                                                    <div className="price" style={stylePrice}>99积分</div>
                                                    {
                                                        moduleData.content.showBtn ?
                                                            <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`item-list ${moduleData.content.borderStyle == 2 ? 'shadow' : moduleData.content.borderStyle == 3 ? 'border' : ''} `}>
                                            <div className="top-img noImg"></div>
                                            <div className="item-desc">
                                                <div className="title">这里显示积分商品标题，最多2行字</div>
                                                <div className="price-group">
                                                    <div className="price" style={stylePrice}>99积分</div>
                                                    {
                                                        moduleData.content.showBtn ?
                                                            <div className="price-btn" style={styleBtn} >兑换</div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                null

                        }

                        <EditorIcon type={`icon-guanbi`} className={`del-module`}
                            onClick={this.handleDeleteModule}></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_typ24)
