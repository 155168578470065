import $axios from '@/axios/axios.js'

export function uploadFile(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/file/uploadFile`,
        method:'post',
        headers:{'Content-Type':'multipart/form-data'},
        data,
    })
}
