import React from 'react'
import { Form, Input, Button, Table, Pagination, Divider, Select, Switch, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import ShareModal from '@/components/shareModal/shareModal'
import RoomManageLists from './components/RoomLists'
import AddDesk from './components/AddDesk'
import { roomLists, deskLists, deskStatus, deskDelete } from '@/api/desk'
const FormItem = Form.Item

class DeskAdd extends React.Component{
    state = {
        searchForm:{
            name:'',
            room_id:''
        },
        columns:[
            {
                title:'包厢/桌台号名称',
                dataIndex:'desk_name',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.desk_name || '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'包厢/桌台类型',
                dataIndex:'room',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.room ? rowData.room.name : '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'容纳人数',
                dataIndex:'room_size',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p>{rowData.room ? rowData.room.room_size : '--'}</p>
                        </div>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <Switch checked={!!rowData.status} onChange={ (e) => { this.handleSwitch(e,rowData,index) } }></Switch>
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleEdit(rowData) } }>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleDelete(rowData) } }>删除</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={`link`} size={`small`} onClick={ () => { this.handleShare(rowData) } }>桌码</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        roomLists:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow:'',
        poster:'',
        path:''
    }
    componentDidMount() {
        this.fetchData()
        this.fetchRoom()
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.store_id = this.props.match.params.id || ``;
        this.setState({
            tableLoading:true
        })
        deskLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.desk_list.data,
                        total = res.data.desk_list.total,
                        per_page = res.data.desk_list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchRoom = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        sendData.store_id = this.props.match.params.id || ``;
        roomLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.room_list.data;
                    this.setState({
                        roomLists:lists,
                    })

                }
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleManage = () => {
        this.RoomManageLists.show(res => {
            this.fetchRoom()

        })
    }
    handleAdd = () => {
        this.setState({
            currentRow:''
        },() => {
            this.AddDesk.show(() => {
                this.fetchData();
            })
        })

    }
    handleEdit = (rowData) => {
        this.setState({
            currentRow:rowData
        },() => {
            this.AddDesk.show(() => {
                this.fetchData();
            })
        })
    }
    handleDelete = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        sendData.store_id = this.props.match.params.id || ``;
        Modal.confirm({
            title:'是否删除该包厢/桌台',
            onOk:() => {
                this.setState({
                    tableLoading:true
                })
                deskDelete(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })

            }
        })
    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/scan/item?desk_id=${row.id}`
        })
        this.ShareModal.show()
    }
    handleSwitch = ( e,rowData,index ) => {
        let sendData = {},
            tableData = this.state.tableData;
        sendData.id = rowData.id;
        sendData.status = e ? 1 : 0;
        sendData.store_id = this.props.match.params.id || ``;
        rowData['status'] = e ? 1 : 0;
        tableData[index] = rowData
        this.setState({
            tableData:tableData
        })
        deskStatus(sendData)
            .then(res => {
                if(res.code == 1){
                    this.fetchData();
                }
            })
            .finally(() => {
            })

    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <RoomManageLists onRef={ e => { this.RoomManageLists = e } }></RoomManageLists>
                <AddDesk onRef={ e => { this.AddDesk = e } } roomLists={this.state.roomLists} data={this.state.currentRow}></AddDesk>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'包厢/桌台号'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='搜索包厢/桌台号名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem label={'包厢/桌台类型'} className={'fl mgl20'}>
                            {
                                getFieldDecorator('room_id',{
                                    initialValue: 0
                                })(
                                    <Select style={{width:200}} placeholder={'请选择'}>
                                        <Select.Option value={0}>全部</Select.Option>
                                        {
                                            this.state.roomLists.map((item,index) => (
                                                <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={''}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>添加包厢/桌台号</Button>
                    <Button type={'default'} className={`mgl10`} onClick={this.handleManage} >管理包厢/桌台类型</Button>

                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(DeskAdd))
