import React, { Component } from 'react'
import { Form, Input, Radio, Button, Select, Icon, InputNumber, Divider, TreeSelect, Table, Tooltip, DatePicker, message } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Loading from '@/components/Loading'
import Editor from '@/components/Editor/Editor'
import VideoAdd from '@/components/videoAdd/videoAdd'
import ItemPicker from '@/components/ItemPicker/ItemPicker'
import CreatorAdd from './components/creatorAdd'
import TopicManage from './components/topic-manage'
import moment from 'moment'
import Mapper from '@/components/Mapper/Mapper'
import { articleAuthor, articleTopic, articleSave, articleDetail } from '@/api/recommend.js'

const FormItem = Form.Item

class NoteAdd extends Component {
  state = {
    isLoading: false,
    formData: {},
    chooseItems: [],
    creatorList: [],
    topicList: [],
    showEditor: false
  }

  componentDidMount() {

    this.fetchCreator()
    this.fetchTopic()
    if (this.props.match.params.id) {
      this.fetchData()
    } else {
      this.setState({
        showEditor: true
      })
    }
  }
  fetchData = () => {
    let sendData = {};
    sendData.article_id = this.props.match.params.id;
    this.setState({
      isLoading: true
    })
    articleDetail(sendData)
      .then(res => {
        if (res.code == 1) {
          let detail = res.data.detail

          detail.recommend_info = detail.recommend_info || []
          detail.publish_time = detail.publish_time ? moment(detail.publish_time, 'YYYY-MM-DD HH:mm:ss') : undefined

          this.setState({
            formData: detail,
            showEditor: true
          })

        }
      })
      .finally(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  fetchCreator = () => {
    let sendData = {};
    sendData.page = 1;
    sendData.per_page = 100;

    this.setState({
      isLoading: true
    })

    articleAuthor(sendData).then(res => {
      if (res.code == 1) {
        let { data } = res.data.list
        this.setState({
          creatorList: data
        })
      }
    }).finally(() => {
      this.setState({
        isLoading: false
      })
    })
  }

  fetchTopic = () => {
    let sendData = {};
    sendData.page = 1;
    sendData.per_page = 100;

    this.setState({
      isLoading: true
    })

    articleTopic(sendData).then(res => {
      if (res.code == 1) {
        let { data } = res.data.list
        this.setState({
          topicList: data
        })
      }
    }).finally(() => {
      this.setState({
        isLoading: false
      })
    })
  }



  EditorChange = content => {
    let formData = this.state.formData || {};
    formData.content = content;
    this.setState({
      formData
    })
  }

  delImg = (e, keyName) => {
    let name = keyName || ``,
      index = e.index || 0,
      formData = this.state.formData || {};
    if (typeof formData[name] == 'string') {
      formData[name] = ''
    } else {
      formData[name].splice(index, 1);
    }
    this.setState({
      formData
    })
  }

  chooseImg = (e, keyName) => {
    let name = keyName || ``,
      formData = this.state.formData || {};

    if (name === 'recommend_info') {
      return this.chooseSuitItems()
    }
    this.Gallery.show(imgs => {
      switch (name) {
        case 'cover':
        case 'share_img':
          formData[name] = imgs[0];
          break;
      }
      this.setState({
        formData
      })
    })
  }
  handleSort = (e, keyName) => {
    let name = keyName || ``,
      formData = this.state.formData || {},
      urls = formData[name] || [],
      oldIndex = e.oldIndex,
      newIndex = e.newIndex;
    if (oldIndex == newIndex) {
      return;
    }
    let targetRow = urls.splice(oldIndex, 1)[0];
    urls.splice(newIndex, 0, targetRow);
    formData[name] = urls;
    this.setState({
      formData
    })
  }

  handleVideo = (url) => {
    let formData = this.state.formData || {};
    formData.video = url
    this.setState({
      formData
    })
  }
  handleVideoDele=()=>{
    let formData = this.state.formData || {};
    formData.video = '';
    this.setState({
      formData
    })
  }

  saveData = (status) => {
    let formData = this.state.formData || {},
      sendData = {};
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        sendData = values
        sendData.publish_time = values.publish_time ? moment(values.publish_time).format('YYYY-MM-DD HH:mm:ss') : ''
        sendData.topic_ids = values.topic_ids.join()

        sendData.cover = formData.cover || ''
        sendData.video = formData.video || ''
        sendData.recommend_info = formData.recommend_info && formData.recommend_info.map(item => {
          return { id: item.id, name: item.name, price: item.price, cover_img: item.cover_img, subtitle: item.subtitle }
        })

        sendData.lat=formData.lat || ''
        sendData.lng=formData.lng || ''

        sendData.content = formData.content || ''

        sendData.share_img = formData.share_img || ''

        sendData.status = status

        if (formData.id) {
          sendData.article_id = formData.id
        }

        this.setState({
          isLoading: true
        })
        articleSave(sendData).then(res => {
          if (res.code == 1) {
            message.success('保存成功').then(() => {
              this.goBack()
            })

          }
        }).finally(() => {
          this.setState({
            isLoading: false
          })
        })

      }
    })
  }
  goBack = () => {
    this.props.history.goBack()
  }

  chooseSuitItems = () => {
    let formData = this.state.formData || {};
    this.setState({
      chooseItems: []
    }, () => {
      this.ItemPicker.show(res => {
        if (res.length) {
          formData.recommend_info = res
          this.setState({
            formData
          })
        }
      })
    })
  }


  handleAddOption = name => {
    switch (name) {
      case 'author_id':
        return this.CreatorAdd.show({
          creatorData: '',
          callBack: () => {
            this.fetchCreator()
          }
        })
      case 'topic_ids':
        return this.TopicManage.show()

      default:
        break;
    }
  }

  showMap = e => {
    this.Mapper.show(res => {
      let formData = this.state.formData || {};
      let map_address = JSON.parse(res.map_address);
      if (!map_address) {
        return
      }
      formData['lng'] = res.lng;
      formData['lat'] = res.lat;
      this.props.form.setFieldsValue({
        address_name: map_address.poiname || '',
      });
      this.setState({
        formData
      })
    })
  }


  render() {
    let data = this.state.formData || {}
    const formItemLayout = {
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 20
      }
    }
    const { getFieldDecorator, getFieldValue } = this.props.form

    return (
      <div className={`shadow-radius`}>
        <div className={'mgt10'}>
          <Mapper onRef={e => { this.Mapper = e }} lat={data.lat || ''} lng={data.lng || ''} address={data.address_name || ''}></Mapper>
          <Gallery onRef={e => { this.Gallery = e }}></Gallery>
          <Form {...formItemLayout} className={'model-form ec-input-w300'}>
            <FormItem label={'笔记标题'}>
              {getFieldDecorator('title', {
                initialValue: data.title || ``,
                rules: [
                  {
                    required: true,
                    message: '请输入笔记标题'
                  }
                ]
              })(<Input
                style={{ width: 300 }}
                placeholder={'请输入笔记标题'}
                maxLength={60}
                suffix={<span>{getFieldValue("title").length}/60</span>}
              />)}
              <span className={`fi-help-text`}>笔记名称最多 60 个字符</span>
            </FormItem>

            <FormItem label={'创作者'}>
              {getFieldDecorator('author_id', {
                initialValue: data.author_id || undefined,
                rules: [
                  {
                    required: true,
                    message: '请选择创作者'
                  }
                ]
              })(
                <Select
                  style={{ width: 300 }}
                  placeholder="请选择"
                >
                  {
                    this.state.creatorList.map(item => (
                      <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              )}
              <span className='text_link mgl15' onClick={() => { this.handleAddOption('author_id') }}>新增</span>
            </FormItem>

            <FormItem label={'创建时间'}>
              {getFieldDecorator('publish_time', {
                initialValue: data.publish_time || undefined,
              })(
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="请选择时间"
                />
              )}
              <span className={'fi-help-text'}>不必填，时间可自由设定，如没填写则展示当前时间。</span>
            </FormItem>

            <FormItem label={'封面图'} required={true} >
              <div className={`clearfix`}>
                {
                  data.cover
                    ?
                    <PictureCard
                      onRef={e => { this.PictureCard = e }}
                      imgs={data.cover}
                      onDel={e => { this.delImg(e, 'cover') }}
                    ></PictureCard>
                    :
                    <ImgAdd
                      onRef={e => { this.ImgAdd = e }}
                      onClick={e => { this.chooseImg(e, 'cover') }}
                    ></ImgAdd>
                }
              </div>
              <span className={'fi-help-text'}>上传的图片宽度750，高度不限，建议尺寸3:4、4:3或1:1，大小不超过2M</span>
            </FormItem>

            <FormItem label={'视频介绍'} >
              <div className={'clearfix'}>
                {
                  data.video
                    ?
                    <div className="cx-video-wrap">
                        <video className='video-item' controls src={data.video}></video>
                        <div className="video-dele" onClick={this.handleVideoDele} ></div>
                    </div>
                    :
                    <VideoAdd
                      handleVideo={this.handleVideo}
                    ></VideoAdd>
                }
              </div>
              <span className={'fi-help-text'}> 支持本地上传视频，格式为MP4，建议大小不要超过40M</span>
            </FormItem>

            <FormItem label={'笔记话题'}>
              {getFieldDecorator('topic_ids', {
                initialValue: data.topic_ids || [],
                rules: [
                  {
                    required: true,
                    message: '请选择话题'
                  }
                ]
              })(
                <Select
                  style={{ width: 300 }}
                  placeholder="请选择话题"
                  mode="multiple"
                  showArrow
                >
                  {
                    this.state.topicList.map(item => (
                      <Select.Option value={`${item.id}`} key={item.id}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              )}
              <span className='text_link mgl15' onClick={() => { this.handleAddOption('topic_ids') }} >新增</span>
              <Divider type={`vertical`}></Divider>
              <span className='text_link' onClick={() => { this.fetchTopic() }}>刷新</span>
            </FormItem>

            <FormItem label={'添加地点'}>
              {getFieldDecorator('address_name', {
                initialValue: data.address_name || '',
              })(<Input placeholder={'点击添加地点'} maxLength={30} onClick={this.showMap} />)}
            </FormItem>


            <FormItem label={'推荐商品'}  >
              <div className={`clearfix`}>

                <PictureCard
                  onRef={e => { this.PictureCard = e }}
                  imgs={data.recommend_info}
                  onDel={e => { this.delImg(e, 'recommend_info') }}
                  onSort={e => { this.handleSort(e, 'recommend_info') }}
                  item_key={'cover_img'}
                  item_id={'id'}
                ></PictureCard>

                <ImgAdd
                  onRef={e => { this.ImgAdd = e }}
                  onClick={e => { this.chooseImg(e, 'recommend_info') }}
                  icon_name={"选择商品"}
                ></ImgAdd>

              </div>
              <span className={'fi-help-text'}>可拖拽图片调整商品显示顺序</span>
            </FormItem>

            <FormItem label={'基础阅读量'}>
              {getFieldDecorator('base_pv', {
                initialValue: data.base_pv || ``
              })(<InputNumber
                style={{ width: 300 }}
                precision={0}
                placeholder={'请输入基础阅读量'}
              />)}
            </FormItem>

            <FormItem label={'基础点赞数'}>
              {getFieldDecorator('base_zan', {
                initialValue: data.base_zan || ``
              })(<InputNumber
                style={{ width: 300 }}
                precision={0}
                placeholder={'请输入基础点赞数'}
              />)}
            </FormItem>

            <FormItem label={'分享描述'}>
              {getFieldDecorator('share_title', {
                initialValue: data.share_title || ``
              })(<Input
                placeholder={'请输入分享描述'}
                maxLength={20}
                style={{ width: 300 }}
                suffix={<span>{getFieldValue("share_title").length}/20</span>}
              />)}
              <span className={`fi-help-text`}>将展示在分享链接中，最多20个字符</span>
            </FormItem>

            <FormItem label={'分享图片'}>
              <div className={`clearfix`}>
                {
                  data.share_img
                    ?
                    <PictureCard
                      onRef={e => { this.PictureCard = e }}
                      imgs={data.share_img}
                      onDel={e => { this.delImg(e, 'share_img') }}
                    ></PictureCard>
                    :
                    <ImgAdd
                      onRef={e => { this.ImgAdd = e }}
                      onClick={e => { this.chooseImg(e, 'share_img') }}
                    ></ImgAdd>
                }
              </div>
              <span className={'fi-help-text'}>建议尺寸：500x400像素。图片越有针对性，越有助于提高点击率，单张不超过2M</span>
            </FormItem>

            <FormItem label={'正文内容'}>
              {
                this.state.showEditor ?
                  <Editor id={'text'} value={data.content} callback={content => this.EditorChange(content)}></Editor> : null

              }

            </FormItem>

            <FormItem label={` `} colon={false}>
              <Button type={'primary'} onClick={() => { this.saveData(1) }}>保存发布</Button>
              <Button className='mgl15' onClick={() => { this.saveData(2) }} >存草稿</Button>
              <Button className='mgl15' onClick={this.goBack} >取消</Button>
            </FormItem>
          </Form>
        </div>

        <ItemPicker
          onRef={e => { this.ItemPicker = e }}
          selectIds={this.state.chooseItems.length ? this.state.chooseItems.map(item => { return item.id }) : []}
          items={this.state.chooseItems.length ? this.state.chooseItems : []}
          multiple={true}
        ></ItemPicker>
        <CreatorAdd onRef={e => this.CreatorAdd = e} />
        <TopicManage onRef={e => this.TopicManage = e} />

        {this.state.isLoading ? <Loading /> : null}
      </div>
    )
  }
}

export default Form.create({})(withRouter(NoteAdd))
