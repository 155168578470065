import React, { Component } from 'react'
import { Modal, Button, Table, Radio, InputNumber, message, Switch } from 'antd';
import { getConfig, setConfig } from '@/api/config'

export default class DevTask extends Component {
	state = {
		columns: [
			{
				title: '成长任务',
				dataIndex: '1',
				render: (text, rowData, index) => {
					return <p> {rowData.name}</p>;
				},
			},
			{
				title: '支付方式',
				dataIndex: '5',
				render: (text, rowData, index) => {

					return (
						<div>
							<p className=''> { rowData.pay_desc && rowData.pay_desc[0] ||''}</p>
							<p className='mgt10'>{ rowData.pay_desc && rowData.pay_desc[1] || ''}</p>
						</div>
					)
				},
			},
			{
				title: '获得成长值',
				dataIndex: '2',
				render: (text, rowData, index) => {
					if (rowData.id == 3 || rowData.id == 4 || rowData.id == 5) {
						return (
							<div>
								<div>
									<InputNumber min={0} value={rowData.exp_type1_num} precision={1} onChange={val => { this.InputNumberChange(val, 'exp_type1_num', index) }} />
									<span className='mgl10'>点/次</span>
								</div>
								<div className='mgt5'>
									<InputNumber min={0} value={rowData.exp_type2_num} precision={1} onChange={val => { this.InputNumberChange(val, 'exp_type2_num', index) }} />
									<span className='mgl10'>点/次</span>
								</div>
							</div>
						)
					}

					return (
						<div>
							<InputNumber min={0} value={rowData.exp_num} precision={1} onChange={val => { this.InputNumberChange(val, 'exp_num', index) }} />
							<span className='mgl10'>点/次</span>
						</div>
					)
				},
			},
			{
				title: '启用状态',
				dataIndex: '6',
				render: (text, rowData, index) => {
					return <Switch checked={!!rowData.switch} onChange={e => { this.handleSwitch(e, 'switch', index) }} />
				},
			},
			{
				title: '日上限',
				dataIndex: '3',
				render: (text, rowData, index) => {

					return (
						<div>
							<Radio.Group onChange={e => { this.RedioChange(e, 'day_switch', index) }} value={rowData.day_switch}  >
								<Radio value={0}>无上限</Radio>
								<Radio value={1}>
									<InputNumber min={1} value={rowData.day_max_num} precision={0} onChange={val => { this.InputNumberChange(val, 'day_max_num', index) }} disabled={rowData.day_switch == 0} />
									<span className='mgl10'>次/日</span>
								</Radio>
							</Radio.Group>
						</div>
					)
				},
			},
		],
		tableData: [
			{
				id: 1,
				name: '储值卡充值',
				exp_num: 0,
				switch: 0,
				day_switch: 0,
				day_max_num: 1,
				pay_desc: ''
			},
			{
				id: 2,
				name: '购买次卡',
				exp_num: 0,
				switch: 0,
				day_switch: 0,
				day_max_num: 1,
				pay_desc: ''
			},
			{
				id: 3,
				name: '购买商品(含活动）',
				exp_type1_num: 0,
				exp_type2_num: 0,
				switch: 0,
				day_switch: 0,
				day_max_num: 1,
				pay_desc: ['会员余额支付','其它支付方式']
			},
			{
				id: 4,
				name: '购买课程',
				exp_type1_num: 0,
				exp_type2_num: 0,
				switch: 0,
				day_switch: 0,
				day_max_num: 1,
				pay_desc: ['会员余额支付','其它支付方式']
			},
			{
				id: 5,
				name: '自助买单',
				exp_type1_num: 0,
				exp_type2_num: 0,
				switch: 0,
				day_switch: 0,
				day_max_num: 1,
				pay_desc: ['会员余额支付','其它支付方式']
			},
			{
				id: 6,
				name: '发布笔记',
				exp_num: 0,
				switch: 0,
				day_switch: 0,
				day_max_num: 1,
				pay_desc: ''
			},
			{
				id: 7,
				name: '评论笔记',
				exp_num: 0,
				switch: 0,
				day_switch: 0,
				day_max_num: 1,
				pay_desc: ''
			},
		],
		tableLoading: false
	}

	componentDidMount() {
		this.fetchData()
	}

	fetchData() {
		this.setState({ tableLoading: true })
		getConfig({ key: 'exp_rule' }).then(res => {
			if (res.code == 1) {
				let { value } = res.data.config;
				if (value) {
					value = JSON.parse(value)

					this.setState({
						tableData: value
					})
				}
			}
		}).finally(() => {
			this.setState({
				tableLoading: false
			})
		})
	}

	InputNumberChange = (val, name, i) => {
		let tableData = this.state.tableData;
		tableData[i][name] = val;
		this.setState({
			tableData
		})
	}

	RedioChange = (e, name, i) => {
		let tableData = this.state.tableData;
		let value = e.target.value;
		tableData[i][name] = value;
		this.setState({
			tableData
		})
	}

	handleSwitch = (val, name, i) => {
		let tableData = this.state.tableData;
		tableData[i][name] = val ? 1 : 0;
		this.setState({
			tableData
		})
	}

	saveData = () => {
		let sendData = {}, tableData = this.state.tableData;
		sendData['key'] = 'exp_rule'
		sendData.value = tableData
		setConfig(sendData).then(res => {
			if (res.code == 1) {
				message.success('保存成功');
			}
		})
	}

	render() {
		return (
			<div className={`shadow-radius`}>
				<Table
					size={`middle`}
					columns={this.state.columns}
					dataSource={this.state.tableData}
					loading={this.state.tableLoading}
					rowKey={(record) => record.id}
					pagination={false}
				></Table>
				<div className='flex justify-center pd30'>
					<Button type='primary' onClick={this.saveData}>保存</Button>
				</div>
			</div>
		)
	}
}
