import React, { Component } from 'react'
import { Form, Input, Button, Select, Icon, InputNumber, Divider, Table, DatePicker, message } from 'antd'
import GoodsAddModal from './components/goods-add-modal';
import Loading from '@/components/Loading'
import {liveSaveTimes,liveSaveDetail} from '@/api/livestream';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment'

const FormItem = Form.Item
const { RangePicker } = DatePicker;

class CalendarAdd extends Component {
    state = {
        formData: {},
        columns: [
            {
                title: '商品信息',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start`}>
                            <img src={rowData.cover} alt="" width={60} height={60} />
                            <div className={`mgl10`}>
                                <p>{rowData.name || ''}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '分组',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p> {rowData.group_name }</p>
                    )
                }
            },
            {
                title: '赠品',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start`}>
                            {
                                rowData.present_img?<img src={rowData.present_img} alt="" width={60} height={60} />:null
                            }
                            
                            <div className={`mgl10`}>
                                <p>{rowData.present_info || '-'}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '优惠/到手价',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p> { rowData.price}</p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '9',
                render: (text, rowData, index) => {
                    return (
                        <div className={'btn-row'}>
                            <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type={"link"} size={"small"} onClick={() => { this.handleDele(rowData)}}>删除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        isLoading: false,
    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.fetchData()
        }
    }

    fetchData=()=>{
        this.setState({
            isLoading:true
        })
        liveSaveDetail({id:this.props.match.params.id}).then(res=>{
            if(res.code == 1){
                let detail=res.data.detail;
                console.log(detail);
                let tableData=JSON.parse(detail.live_goods);
                console.log(tableData);
                this.setState({
                    formData:detail,
                    tableData
                })
            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    handleAddGoods = () => {
        let tableData=this.state.tableData;
        this.GoodsAddModalRef.show((res) => {
            tableData.push(res)
            this.setState({
                tableData
            })
        })
    }
    handleEdit=row=>{
        let tableData=this.state.tableData;
        let index=tableData.findIndex(val=>(val.id == row.id));
        this.GoodsAddModalRef.show((res) => {
            if(index != -1){
                tableData[index]=res
                this.setState({
                    tableData
                })
            }
        },row)
    }
    handleDele=row=>{
        let tableData=this.state.tableData;
        let index=tableData.findIndex(val=>(val.id == row.id));
        if(index != -1){
            tableData.slice(index,1)
            this.setState({
                tableData
            })
        }
    }

    saveData = () => {
        let sendData,tableData=this.state.tableData
        this.props.form.validateFields((err, values) => {
            if (!err) {
                sendData = values;

                if(sendData.start_time){
                    sendData.start_time=moment(sendData.start_time).format('YYYY-MM-DD HH:MM')
                }

                if(this.props.match.params.id){
                    sendData.id=this.props.match.params.id
                }
                sendData.live_goods=tableData;
                let group_ids=tableData.map(val=>val.group_id);
                sendData.live_group=Array.from(new Set(group_ids));
                
                this.setState({
                    isLoading:true
                })
                liveSaveTimes(sendData).then(res=>{
                    if(res.code==1){
                        message.success(res.msg);
                        this.props.history.goBack()
                    }
                }).finally(()=>{
                    this.setState({
                        isLoading:false
                    })
                })

            }
        })
    }

    render() {
        let data = this.state.formData || {};
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return (
            <div className={`shadow-radius`} >
                <div className="mgt10">
                    <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                        <FormItem label="直播标题">
                            {getFieldDecorator('title', {
                                initialValue: data && data.title || '',
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入直播标题，限6个字符'
                                    }
                                ]
                            })(<Input placeholder='请输入直播标题，限6个字符' maxLength={6}
                                suffix={<span>{getFieldValue("name")?.length || 0}/6</span>} />)}
                        </FormItem>

                        <FormItem label="开播时间">
                            {getFieldDecorator('start_time', {
                                initialValue: data && data.start_time && moment(data.start_time),
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择输入开始时间'
                                    }
                                ]
                            })(<DatePicker showTime placeholder="开始时间" />
                            )}
                        </FormItem>
                        <FormItem label="直播商品" required={true} >
                            <div>
                                <Button icon={'plus'} type={'primary'} onClick={this.handleAddGoods} >增加商品</Button>
                            </div>
                            <div className={' mgt15'}>
                                <Table
                                    size={`middle`}
                                    columns={this.state.columns}
                                    dataSource={this.state.tableData}
                                    rowKey={record => record.id}
                                    pagination={{ pageSize: 10 }}
                                ></Table>
                            </div>
                        </FormItem>
                        <FormItem label={` `} colon={false}>
                            <Button type={'primary'} onClick={this.saveData}>保存</Button>
                        </FormItem>
                    </Form>
                </div>

                <GoodsAddModal onRef={e => { this.GoodsAddModalRef = e }} ></GoodsAddModal>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}
export default Form.create({})( withRouter(CalendarAdd))