import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Select, Button, Table, Pagination, Divider, Tag, DatePicker } from 'antd'
import {pointsLists,pointsExport} from '@/api/data'
import Loading from '@/components/Loading'
import message from '@/utils/message';
import moment from 'moment'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class PointsDetail extends React.Component {
    state = {
        searchForm: {
            name:'',
            mobile:'',
            points_type:'',
            type:'',
            start_time:'',
            end_time:''
        },
        columns: [
            {
                title: '客户信息',
                dataIndex: 'logo',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url} alt="" width={60} />
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} >
                                    {rowData.name || `游客`}
                                    <i className={`icon-${rowData.sex == 1 ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                    {
                                        rowData.second_name
                                            ?
                                            <span>（{rowData.second_name}）</span>
                                            :
                                            null
                                    }
                                </div>
                                <div className={`lh25`}>{rowData.mobile || `暂无手机号`}</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '积分类型',
                dataIndex: 'points_type',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.change_points>0?'获得积分':rowData.change_points<0?'消耗积分':''}</p>
                    )
                }
            },
            {
                title: '获取/消耗途径',
                dataIndex: 'description',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.type_name}</p>
                    )
                }
            },
            {
                title: '内容',
                dataIndex: 'draw_way',
                render: (text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.content }</div>
                    )
                }
            },
            {
                title: '积分明细',
                dataIndex: 'store',
                render: (text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.change_points}</div>
                    )
                }
            },
            {
                title: '变更时间',
                dataIndex: 'effect_time',
                render: (text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.updated_at}</div>
                    )
                }
            },
        ],
        tableData: [],
        suitStores: [],
        tableLoading: false,
        isLoading: false,
        page: 1,
        total: 0,
        per_page: 10,

    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        pointsLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    page: 1,
                    searchForm
                }, () => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                this.setState({
                    searchForm,
                    tableLoading: true
                })
                pointsExport(sendData)
                    .then(res => {
                        let blob = new Blob([res], { type: 'application/vnd.ms-excel' }),
                            fileName = '积分明细导出.csv';
                        CX.exportExcel(blob, fileName)
                    })
                    .finally(() => {
                        this.setState({
                            tableLoading: false
                        })
                    })
            }
        })
    }


    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div >
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={'客户昵称'} className={'fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户昵称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'客户手机号'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入客户手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'积分类型'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('points_type')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>获得积分</Select.Option>
                                            <Select.Option value={2}>消耗积分</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'获取/消耗途径'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('type')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>开卡赠送积分</Select.Option>
                                            <Select.Option value={2}>积分兑换扣除</Select.Option>
                                            <Select.Option value={3}>积分兑换返还</Select.Option>
                                            <Select.Option value={4}>商家手动调整</Select.Option>
                                            <Select.Option value={5}>完善信息赠送</Select.Option>
                                            <Select.Option value={6}>集赞赠送</Select.Option>
                                            <Select.Option value={7}>红包雨消耗</Select.Option>
                                            <Select.Option value={8}>红包雨奖励得积分</Select.Option>
                                            <Select.Option value={10}>会员导入</Select.Option>
                                            <Select.Option value={11}>签到奖励</Select.Option>
                                            <Select.Option value={14}>学员积分</Select.Option>
                                            <Select.Option value={15}>抖店兑换得积分</Select.Option>
                                            <Select.Option value={16}>拉新奖励得积分</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                           
                            <FormItem label={'变更时间'} className={' fl'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;&emsp;&emsp;&emsp;</span>} colon={false} className={' fl'}>
                                <Button type='primary' onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleExport()}}>导出</Button>
                            </FormItem>
                        </div>

                    </Form>
                </div>

                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                {this.state.isLoading ? <Loading /> : null}

            </div>
        )
    }
}

export default Form.create({})(withRouter(PointsDetail))
