/**
 * 多项填空组件
 * @type 9
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio} from 'antd'
import PropTypes from "prop-types";
import Gallery from '@/components/Gallery/Gallery'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 16 }}

class tpl_ctrl_type9 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }

    state = {
        moduleData:{}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    changeSpacePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.spacePadding = val;
        this.context.updateDesign(moduleData)
    }
    chooseImg = (item,index) => {
        let moduleData = this.state.moduleData;
        this.Gallery.show(res => {
            if(res){
                moduleData.content.dataset[index]['pic'] = res[0];
                this.context.updateDesign(moduleData)
            }
        })

    }
    delImg = (item,index) => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除吗？`,
            okType:`danger`,
            onOk:() => {
                moduleData.content.dataset.splice(index,1);
                this.context.updateDesign(moduleData)
            }
        })
    }
    changeLink = (e,item,index) => {
        let moduleData = this.state.moduleData;
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    moduleData.content.dataset[index][i] = res[i];
                }
                this.context.updateDesign(moduleData)
            }
        })

    }
    handleAdd = () => {
        let moduleData = this.state.moduleData,
            newData = { name:'问题',placeholder:'',value:'' };
        moduleData.content.dataset.push(newData);
        this.context.updateDesign(moduleData)
    }
    handleUp = (item,index) => {
        let moduleData = this.state.moduleData;
        if(index == 0){ return ; }
        let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
        moduleData.content.dataset.splice(index, 1);
        moduleData.content.dataset.splice(index - 1, 0, tmpdata);
        this.context.updateDesign(moduleData)
    }
    handleDown = (item,index) => {
        let moduleData = this.state.moduleData,
            len = moduleData.content.dataset.length;
        if(index == len - 1){ return ; }
        let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
        moduleData.content.dataset.splice(index, 1);
        moduleData.content.dataset.splice(index + 1, 0, tmpdata);
        this.context.updateDesign(moduleData)
    }
    handleInputChange = (e,keyName) => {
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content[keyName] = val;
        this.context.updateDesign(moduleData)
    }
    handleRadioChange = (e,keyName) => {
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content[keyName] = val;
        this.context.updateDesign(moduleData)
    }
    handleDatasetInput = (e,item,index,keyName) => {
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content.dataset[index][keyName] = val;
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>多项填空</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`左右间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.spacePadding || 0}
                                            onChange={ this.changeSpacePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.spacePadding || 0}
                                            onChange={ this.changeSpacePadding }
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`标题`}>
                                    <Input placeholder={'请输入标题'} value={moduleData.content.title} onChange={(e) => { this.handleInputChange(e,'title') }} maxLength={30} />
                                </FormItem>
                                <FormItem label={`副标题`}>
                                    <Input placeholder={'请输入副标题'} value={moduleData.content.subTitle} onChange={(e) => { this.handleInputChange(e,'subTitle') }} maxLength={30} />
                                </FormItem>
                                <FormItem label={`是否必填`}>
                                    <Radio.Group value={moduleData.content.required} onChange={ (e) => { this.handleRadioChange(e,'required') } }>
                                        <Radio value={1}>必填</Radio>
                                        <Radio value={0}>非必填</Radio>
                                    </Radio.Group>
                                </FormItem>
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>选项设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                {
                                    moduleData.content.dataset.map((item,index) => (
                                        <div className={`cx-img-list`} key={index}>
                                            <div className={`cx-img-item clearfix`}>
                                                <div className={`w100 pdt15`}></div>
                                                <FormItem label={`内容标题`} {...ctrlItemLayout}>
                                                    <Input
                                                        type={`text`}
                                                        value={item.name}
                                                        placeholder={`请输入选项内容`}
                                                        onChange={(e) => { this.handleDatasetInput(e,item,index,'name') }}
                                                    />
                                                </FormItem>
                                            </div>
                                            <div className="ctrl-item-list-actions">
                                                <i className={`gicon-arrow-up`} onClick={ () => { this.handleUp(item,index) }}></i>
                                                <i className={`gicon-arrow-down`} onClick={ () => { this.handleDown(item,index) }}></i>
                                            </div>
                                            {
                                                index > 0
                                                    ?
                                                    <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delImg(item,index) }} data-name={'pic'}  />
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))
                                }
                                <Button type={`primary`} className={`w100`} onClick={this.handleAdd} disabled={moduleData.content.dataset.length >= 15} >添加选项</Button>

                            </div>
                        </div>

                    </Form>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type9)
