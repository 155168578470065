import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Form, TreeSelect } from 'antd'
const FormItem = Form.Item


class SetCourseCategory extends React.Component{
    state = {
        formData:{},
        title:'批量修改课程类目',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null,
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let   callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                this.close()
                if(typeof callback == 'function'){
                    callback(values)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let data = this.props.data || {},
            cateLists = this.props.cateLists || []

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 17
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`选择分类`} >
                        {getFieldDecorator('category_id', {
                            initialValue: data.category_id || "",
                            rules:[
                                {
                                    required:true,
                                    message: '请选择分类'
                                }
                            ]
                        })(
                            <TreeSelect
                            showSearch
                            style={{ width: 300 }}
                            placeholder={'请选择'}
                            treeData={cateLists}
                        />
                        )}
                    </FormItem>



                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(SetCourseCategory))
