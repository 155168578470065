import React, { Component } from 'react'
import { Form, Input, Radio, Button, Checkbox, message } from 'antd'
import { setNoteSetting, getNoteSetting } from '@/api/recommend.js'

const FormItem = Form.Item

class FeatureSet extends Component {
    state = {
        formData: {
            user_note_switch:1,
            user_note_check:1,
            comment_switch: 1,
            comment_check: 1,
            title: '种草笔记',
            style: 1,
            tab_title: {
                left: "发现",
                right: "店主说"
            },
            tab_checked:[1,2]
        },
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({
            isLoading: true
        })

        getNoteSetting({ key: "article_setting" }).then(res => {
            if (res.code == 1) {
                let value = res.data.config && res.data.config.value
                if (typeof value == 'string') {
                    value=JSON.parse(value)
 
                    this.setState({
                        formData:value
                    })
                }
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    saveData = () => {
        let { formData } = this.state, sendData={},value={}

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                value={...formData,...values}
               console.log(sendData);
               sendData.key='article_setting'
               sendData.value=value
       
               this.setState({
                   isLoading: true
               })
       
               setNoteSetting(sendData).then(res => {
                   if (res.code == 1) {
                       message.success(res.msg)
                   }
               }).finally(() => {
                   this.setState({
                       isLoading: false
                   })
               })

            }
        })
       
       
        

    }

    changeListMode = name => {
        return e => {
            let formData = this.state.formData
            formData.tab_title[name] = e.currentTarget.value
            this.setState({
                formData
            })
        }
    }
    render() {

        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        return (
            <div>
                <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                    <div className={`msg-title mgb15`}>基础设置</div>
                    <FormItem label={"客户发布笔记"}>
                        {getFieldDecorator('user_note_switch', {
                            initialValue: data.user_note_switch ?? 1,
                        })(
                            <Radio.Group  >
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={"客户发布审核"}>
                        {getFieldDecorator('user_note_check', {
                            initialValue: data.user_note_check ?? 1,
                        })(
                            <Radio.Group  >
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>

                    <div className={`msg-title mgb15`}>评论设置</div>
                    <FormItem label={"评论开关"}>
                        {getFieldDecorator('comment_switch', {
                            initialValue: data.comment_switch ?? 1,
                        })(
                            <Radio.Group  >
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={"评论审核"}>
                        {getFieldDecorator('comment_check', {
                            initialValue: data.comment_check ?? 1,
                        })(
                            <Radio.Group  >
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <div className={`msg-title mgb15`}>列表设置</div>
                    <FormItem label={'列表页标题'}>
                        {getFieldDecorator('title', {
                            initialValue: data.title || ``,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入列表页标题'
                                }
                            ]
                        })(<Input
                            style={{ width: 300 }}
                            placeholder={'请输入列表页标题'}
                            maxLength={20}
                        />)}
                    </FormItem>
                    <FormItem label={"列表样式"}>
                        {getFieldDecorator('style', {
                            initialValue: data.style || 1,
                        })(
                            <Radio.Group  >
                                <Radio value={1}>瀑布流</Radio>
                                <Radio value={2}>小图列表</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>

                    <FormItem label={"列表模块"}>
                        {getFieldDecorator('tab_checked', {
                            initialValue: data.tab_checked || [1, 2],
                        })(
                            <Checkbox.Group  >
                                <Checkbox value={1} disabled>
                                    <Input
                                        style={{ width: 300 }}
                                        value={data.tab_title.left}
                                        maxLength={20}
                                        onChange={this.changeListMode('left')}
                                    />
                                </Checkbox>
                                <div className="mgt10"></div>
                                <Checkbox value={2}>
                                    <Input
                                        style={{ width: 300 }}
                                        value={data.tab_title.right}
                                        maxLength={20}
                                        onChange={this.changeListMode('right')}
                                    />
                                </Checkbox>
                            </Checkbox.Group>
                        )}
                    </FormItem>


                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={this.saveData}>保存</Button>
                    </FormItem>

                </Form>
            </div>
        )
    }
}

export default Form.create({})(FeatureSet)
