import React from 'react'
import { SketchPicker } from 'react-color'
import './ColorPicker.scss'

class ColorPicker extends React.Component{

    state = {
        color:'#fff',
        showPanel:false,
        style:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        if(this.props.color){
            const color = this.props.color;
            this.setState({
                color
            })
        }
       
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.color){
            this.setState({
                color:nextProps.color
            })
        }
    }

    handleColorChange = val => {
        let color = val.hex;
        this.setState({
            color
        })
        this.props.callback && this.props.callback(val)
    }
    show = () => {
        this.setState({
            showPanel:true
        })
    }
    close = () => {
        this.setState({
            showPanel:false
        })
    }

    render() {
        const style=this.props.style || {}

        return(
            <div style={style}>
                <div className={'colorPanel'}>
                    <div
                        className={'colorInner'}
                        style={{backgroundColor:`${this.state.color}`}}
                        onClick={this.show}
                    ></div>
                    {
                        this.state.showPanel
                            ?
                            <SketchPicker
                                disableAlpha={true}
                                color={this.state.color}
                                onChange={this.handleColorChange}
                            />
                            :
                            ''
                    }
                    {
                        this.state.showPanel
                            ?
                            <div className={'colorMask'} onClick={this.close}></div>
                            :
                            ''
                    }

                </div>
            </div>
        )
    }
}
export default ColorPicker
