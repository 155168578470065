import React from 'react'
import { Form, Table, Pagination, Tag, Button, Switch, message } from 'antd'
import { openCardDetail } from '@/api/member-card'
import { withRouter, Link } from 'react-router-dom'
import Loading from '@/components/Loading'
import '@/assets/css/card/detail.scss'
import { getConfig, setConfig } from '@/api/config'
const FormItem = Form.Item;

class UserLevel extends React.Component{
    state = {
        infoColumns:[
            {
                title:'等级',
                dataIndex:'level',
                render:(text, rowData, index) => {
                    return <p className={`lh25`}>V{rowData.level || ''}</p>
                }
            },
            {
                title:'卡面',
                dataIndex:'cover_img',
                render:(text, rowData, index) => {
                    return (
                        <img src={rowData.cover_img} alt="" height={60}/>
                    )
                }
            },
            {
                title:'等级名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return <p className={`lh25`}>{rowData.name || ''}</p>
                }
            },
            {
                title:'升级条件',
                dataIndex:'up_rule',
                render:(text, rowData, index) => {
                    let formData = this.state.formData;
                    if(index == 0){
                        return <p className={`lh25`}>无门槛<br></br>注册信息</p>
                    }else{
                        return <p className={`lh25`}>成长值达到{formData.level_rule[index].exp || 0}</p>
                    }
                    
                }
            },
            {
                title:'降级策略',
                dataIndex:'reduce_rule',
                render:(text, rowData, index) => {
                    let formData = this.state.formData;
                    if(formData.reduce_rule.reduce_switch){
                        return <p className={`lh25`}>成长值达到{formData.reduce_rule.lists[index].exp || 0}</p>
                    }else{
                        return <p className={`lh25`}>不降级</p>
                    }
                    
                }
            },
            {
                title:'等级权益',
                dataIndex:'equity',
                render:(text, rowData, index) => {
                    if(rowData.equity){
                        return (
                            <div className={`float-modal`}>
                                <p className={`lh25 small-modal`}>已选{rowData.equity.equity.length +  rowData.equity.gift.length || 0}项</p>
                                <div className={`data-modal`}>
                                    <div style={{color:'blue'}} className={`fz16`}>会员权益：</div>

                                    {
                                        rowData.equity.equity.map((item,index) => {
                                            return (<div className={'mgl5 mgb5'} key={item.id}>· {item.name}</div>)
                                        })
                                    }
                                    <div style={{color:'blue'}} className={`fz16`}>每月礼包：</div>
                                    {
                                        rowData.equity.gift.map((items,indexs) => {
                                            return (<div className={'mgl5 mgb5'} key={items.id}>· {items.name}</div>)
                                        })
                                    }
                                    </div>
                            </div>
                       
                        )
                    }else{
                        return '无'
                    }
                    
                }
            },
            {
                title:'升级礼包',
                dataIndex:'reward',
                render:(text, rowData, index) => {
                    if(rowData.reward){
                        return (
                            <div className={`float-modal`}>
                                <p className={`lh25 small-modal`}>已选{rowData.reward.length || 0}项</p>
                                <div className={`data-modal`}>
                                    <div style={{color:'blue'}} className={`fz16`}>升级奖励：</div>

                                    {
                                        rowData.reward.map((item,index) => {
                                            return (<div className={'mgl5 mgb5'} key={item.id}>· {item.name}</div>)
                                        })
                                    }
                                    </div>
                            </div>
                       
                        )
                    }else{
                        return  '无'
                    }
                }
            },
            {
                title:'会员数',
                dataIndex:'user_num',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.user_num || 0}</p>
                    )
                }
            }
        ],
        formData:{
            user_level_switch:0,
            join_switch:0,
            pay_rule:[
                {
                    effect_time:1,
                    price:10
                }
            ],
            level_rule:[
                {
                    level:1,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/f711d4c42de17903e82d1cf4fe704d0e.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:1
                },
                {
                    level:2,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/ae6eeb21652b570350d5835e3a6fbcee.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:2
                },
                {
                    level:3,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/1e8704dc744929a27255f70b3011ff91.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:3
                },
                {
                    level:4,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/22d0358ae34048cd955202a72de6ceca.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:4
                },
                {
                    level:5,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/dd3e4665df76909ec18d6e5fb34cfac4.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:5
                },
                {
                    level:6,
                    cover_img:'https://img.cxkoo.com/chengxuan/1/2023-08-09/c3e450dd3e6c458ca941d7e89b4a3d12.png',
                    name:'普通会员',
                    exp:0,
                    equity:null,
                    reward:null,
                    id:6
                }
            ],
            reduce_rule:{
                reduce_switch:0,
                month_value:1,
                lists:[
                    {
                        level:1,
                        exp:null
                    },
                    {
                        level:2,
                        exp:null
                    },
                    {
                        level:3,
                        exp:null
                    },
                    {
                        level:4,
                        exp:null
                    },
                    {
                        level:5,
                        exp:null
                    },
                    {
                        level:6,
                        exp:null
                    }
                ],
            }
        },
        tableData:[],
        isLoading:false,
        page:1,
        total:0,
        per_page:10
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        getConfig({ key: 'user_level' }).then(res => {
			if (res.code == 1) {
				let { value } = res.data.config;
				if (value) {
					value = JSON.parse(value)

					this.setState({
						formData: value
					})
				}
			}
            console.log(this.state.formData)
		}).finally(() => {
			
		})
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }

    handleAdd = () => {
        this.props.history.push({
            pathname:`/level/level-edit`
        })
    }

    handleNoitce = () =>{
        this.props.history.push({
            pathname:`/level/vip-notice`
        })
    }

    handleSwitch = (val, name) => {
		let formData = this.state.formData;
		formData.user_level_switch = val ? 1 : 0;
		this.setState({
			formData
		})
        let sendData = {}, tableData = this.state.formData;
		sendData['key'] = 'user_level'
		sendData.value = tableData
		setConfig(sendData).then(res => {
			if (res.code == 1) {
				message.success('保存成功');

			}
		})
	}


    render() {
        let formData = this.state.formData;
        let pay_str = '';
        const pay_rule = formData.pay_rule;
        if(pay_rule){
            pay_rule.forEach((item,index) => {
                if(item.effect_time == 1){
                    pay_str += '1个月 ￥'+ item.price + ','
                }else if(item.effect_time == 2){
                    pay_str += '3个月 ￥'+ item.price + ','
                }else if(item.effect_time == 3){
                    pay_str += '6个月 ￥'+ item.price + ','
                }else if(item.effect_time == 4){
                    pay_str += '9个月 ￥'+ item.price + ','
                }
            });
        }
        formData.pay_str = pay_str;
        return(
            <div className={`shadow-radius`}>
                <div className={``}>
                    <div className={`msg-title`}>
                        <div className={`flex align-center justify-between`}>
                            <div>会员等级模式</div>
                            <div><Switch checked={!!formData.user_level_switch} onChange={e => { this.handleSwitch(e, 'switch') }}/></div>
                        </div>
                        <div style={{color:'#999'}} className={'fz12 mgt10 fw-400'}>开启会员等级模式后，会员等级会随成长值的变化而变化。关闭后，等级不长，且不享受等级权益。</div>
                    </div>
                    
                </div>
                
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} >编辑会员方案</Button>
                    <Button type={'default'} onClick={() => { this.handleNoitce() }} className={`mgl10`}>会员等级须知</Button>
                </div>
                <div className={'mgt10'} style={{color:'#000'}}>
                    <div className={'fz16 fw-600'}>等级与权益</div>
                    <div className={'flex mgt15'}>
                        <div style={{width:"100px",textAlign:'right'}}>等级规则：</div>
                        <div>按成长值升降级</div>
                    </div>
                    <div className={'flex mgt15'}>
                        <div style={{width:"100px",textAlign:'right'}}>付费加入会员：</div>
                        <div>{formData.join_switch &&  formData.join_switch == 1? '启用':'不启用'}</div>
                    </div>
                    {
                        formData.join_switch == 1?
                        <div className={'flex mgt15'}>
                                <div style={{width:"100px",textAlign:'right'}}><span style={{color:'red',marginRight:'5px'}}>*</span>付费规则：</div>
                                <div>{formData.pay_str}</div>
                        </div>
                        : null
                    }
                   
                    <div className={'flex mgt15'}>
                        <div style={{width:"100px",textAlign:'right'}}> 等级设置：</div>
                        <div style={{width:'90%'}}>
                            <Table
                                size={`middle`}
                                columns={this.state.infoColumns}
                                dataSource={formData.level_rule}
                                rowKey={record => record.id}
                                pagination={false}
                                style={{ width:'100%'}}
                            ></Table>
                        </div>
                    </div>
                </div>

                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}

export default Form.create({})(withRouter(UserLevel))
