import React from 'react'
import {withRouter} from 'react-router-dom'
import {Modal, Input, Form, Select, Row, Col, Alert, Checkbox} from 'antd'
import message from '@/utils/message'
import moment from 'moment'
import Gallery from '@/components/Gallery/Gallery'

const FormItem = Form.Item

class CopyTime extends React.Component {
    state = {
        chooseWeeks:[],
        title: '复制到其他日期',
        visible: false,
        modalLoading: false,
        callback: null

    }

    componentDidMount() {
        this.props.onRef(this)
    }

    show = (callback) => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            callback: callback || null,
            chooseWeeks:[]
        })
    }
    close = () => {
        this.setState({
            visible: false
        })
    }
    handleWeekChange = (val) => {
        this.setState({
            chooseWeeks:val
        })
    }
    handleOk = () => {
        let res = {},
            callback = this.state.callback;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.weeks = this.state.chooseWeeks || []
                this.close()
                if (typeof callback == 'function') {
                    callback(values)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }

    render() {
        let weeks = this.props.weeks || [],
            current = this.props.current || {},
            currentSpaceType = this.props.currentSpaceType || '',
            validateLists = weeks.filter( item => { return item.id != current.id && item.special_date == ''  } )
        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 18
            }
        }
        const {getFieldDecorator} = this.props.form

        return (
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Gallery onRef={e => {
                    this.Gallery = e
                }}></Gallery>
                <Form className={`model-form`} {...formItemLayout}>
                    <Alert message={`${this.props.error || '请注意！复制后该日期原有价目表将被覆盖（特殊日不可复制）请及时检查和确认。'}`} className={`mgb15`} type="error" banner />
                    {
                        currentSpaceType
                            ?
                            <FormItem label={`当前场地类型`}>
                                <p className={``}>{ currentSpaceType.name || '-' }</p>
                            </FormItem>
                            :
                            null
                    }
                    <FormItem label={`当前设置日期`}>
                        <p className={``}>{ current.time_name || current.name }</p>
                    </FormItem>
                    <FormItem label={`复制到日期`} required={true}>
                        <Checkbox.Group onChange={ this.handleWeekChange } value={ this.state.chooseWeeks }>
                            {
                                validateLists.map( (child,childKey) => (
                                    <Checkbox value={child.id} key={child.id}>{ child.time_name || child.name }</Checkbox>
                                ))
                            }
                        </Checkbox.Group>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(CopyTime))
