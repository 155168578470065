import React from 'react'
import { Tabs } from 'antd'
import { withRouter } from 'react-router-dom'
import BMILists from './components/bmiLists';
import BMIAssort from './components/bmiAssort';
const { TabPane } = Tabs;



class EducationalBMI extends React.Component {

    state = {
        activeKey:"1"
    }

    changeTabs=key=>{
        this.setState({
            activeKey:key
        })
    }


    render() {


        return (
            <div className={'shadow-radius'}>
                <Tabs defaultActiveKey={this.state.activeKey} onChange={this.changeTabs}>
                    <TabPane tab="体测表单" key="1">
                        <BMILists></BMILists>
                    </TabPane>
                    <TabPane tab="项目属性管理" key="2">
                       <BMIAssort></BMIAssort>
                    </TabPane>
                   
                </Tabs>

            </div>
        )
    }


}

export default withRouter(EducationalBMI)
