import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import Loading from '@/components/Loading'
import PropTypes from 'prop-types'
import LevelEditForm from "./components/LevelEditForm";
const FormItem = Form.Item

class LevelEdit extends React.Component{
    static childContextTypes = {
        isLoading:PropTypes.bool,
        setLoading:PropTypes.func,
    }
    state = {
        formData:{},
        isLoading:false,
    }
    getChildContext = () => {
        return{
            isLoading:this.state.isLoading,
            setLoading:(e) => {
                this.setLoading(e)
            },
        }
    }
    setLoading = e => {
        this.setState({
            isLoading:!!e
        })
    }
    componentDidMount() {}
    render() {
        return(
            <div className={`shadow-radius`}>
                <div className={'mgt10'}>
                    <LevelEditForm onRef={e => { this.TimesCardEditForm = e }} data={ this.state.formData }></LevelEditForm>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(LevelEdit))
