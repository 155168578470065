import React from 'react'
import { Form, Button, Row, Col, Tabs, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { userCardDetail } from '@/api/user'
import { rechargeRules } from '@/api/member-card'
import { userTimesCard, changeEndDate, disUseTimesCard } from '@/api/times-card'
import { storeLists } from '@/api/store'
import ConsumeRecord from './components/ConsumeRecord'
import RechargeRecord from './components/RechargeRecord'
import CardRights from './components/CardRights'
import UseRecord from './components/UseRecord'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import CardRecharge from './components/CardRecharge'
import CardActive from './components/CardActive'
import CardWriteOff from './components/CardWriteOff'
import './components/UserDetail.scss'
const FormItem = Form.Item;
const { TabPane } =  Tabs
class UserTimesCard extends React.Component {
    state = {
        tabLists:[
            {
                name:'购卡权益',
                status:'1',
            },
            {
                name:'使用记录',
                status:'2',
            }
        ],
        activeStatus:'1',
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        rightLists:[],
        useLists:[],
        rechargeRules:[],
        storeLists:[],
        card:'',
        is_write_off:false
    };

    componentDidMount() {
        this.fetchData()
        this.fetchStore();
    }

    fetchData = () => {
        let sendData = {};
        sendData.user_id = this.props.match.params.user_id || ``;
        sendData.user_time_card_id = this.props.match.params.times_card_id || ``;
        sendData.type = this.state.activeStatus;
        sendData.page = this.state.page;
        this.setState({
            isLoading:true
        })
        userTimesCard(sendData)
            .then(res => {
                if(res.code == 1){
                    let card = res.data.card,
                        record_list = res.data.record_list;
                    if(sendData.type == 1){
                        this.setState({
                            rightLists:record_list,
                            card
                        })
                    }else {
                        this.setState({
                            useLists:record_list.data,
                            per_page:record_list.per_page,
                            total:record_list.total,
                            card
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    clickTab = val => {
        this.setState({
            activeStatus:val,
            page:1,
        },() => {
            this.fetchData()
        })
    }
    handleActive = () => {
        let sendData = {}
        this.CardActive.show(res => {
            if(res){
                sendData.type = res.type;
                sendData.user_id = this.props.match.params.user_id || ``;
                sendData.user_time_card_id = this.props.match.params.times_card_id || ``;
                if(sendData.type == 1){
                    sendData.end_date = res.end_date || ''
                    if(!sendData.end_date){
                        message.error('请选择日期');
                        return ;
                    }
                }
                if(sendData.type == 2){
                    sendData.days = res.days || ''
                    if(!sendData.days){
                        message.error('请选择时间');
                        return ;
                    }
                }
                this.setState({
                    isLoading:true
                })
                changeEndDate(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })


            }
        })
    }

    handleOff = () => {
        let sendData = {}
        Modal.confirm({
            title: `是否作废这张卡？`,
            onOk: () => {
                sendData.user_id = this.props.match.params.user_id || ``;
                sendData.user_time_card_id = this.props.match.params.times_card_id || ``;
                this.setState({
                    isLoading:true
                })
                disUseTimesCard(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/user/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }

    handleWriteOffCard=()=>{
        this.setState({
            is_write_off:true
        })
    }
    handleCloseModalCard=()=>{
        this.setState({
            is_write_off:false
        })
    }

    render() {
        let card = this.state.card || {};
        return(
            <div className={`shadow-radius`}>
                <CardRecharge
                    onRef={ e => { this.CardRecharge = e } }
                    storeLists={ this.state.storeLists }
                    rechargeRules={ this.state.rechargeRules }
                ></CardRecharge>
                <CardActive onRef={ e => { this.CardActive = e } }></CardActive>
                <div className={`card-bg times-card`}>
                    <div className={`left`}>
                        <div className={`title`}>
                            <div className={`txt1`}>{ card.card_name }</div>
                            <div className={`type`}>次卡</div>
                        </div>
                        <div className={`card_no`}>卡号：{ card.card_no }</div>
                    </div>
                    <div className={`right clearfix`}>
                        <Button type={`default`} className={`fr mgl10`} onClick={ this.handleOff }>作废</Button>
                        <Button type={`default`} className={`fr mgl10`} onClick={this.handleWriteOffCard}>扣除次数</Button>
                    </div>
                </div>
                <div className={`pd15`}>
                    <Form className={``} layout={`inline`}>
                        <Row>
                            <Col span={8} >
                                <FormItem label={`开卡金额`}>
                                    <div className={``}>{ card?.card_info?.price }元</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`开卡时间`}>
                                    <div className={``}>{ card.created_at }</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`账户归属`}>
                                    <div className={``}>{ card.open_store_name }</div>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} >
                                <FormItem label={`适用门店`}>
                                    <div className={``}>{ card.suit_stores }</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`开卡门店`}>
                                    <div className={``}>{ card.open_store_name }</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={<span>&emsp;有效期</span>}>
                                    <div className={``}>
                                        { card.effect_time }
                                        <span className={`fi-link mgl10`} onClick={ this.handleActive }>修改</span>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={``}>
                    <Tabs onChange={this.clickTab} type="card" className={'mgt10'} activeKey={this.state.activeStatus}>
                        {
                            this.state.tabLists.map((item,index) => (
                                <TabPane tab={ item.name } key={item.status}></TabPane>
                            ))
                        }
                    </Tabs>
                    <div className={``}>
                        {
                            this.state.activeStatus == 1
                                ?
                                <CardRights
                                    onRef={ e => { this.CardRights = e } }
                                    tableData={ this.state.rightLists }
                                    total={ this.state.total }
                                    per_page={ this.state.per_page }
                                    page={ this.state.page }
                                ></CardRights>
                                :
                                <UseRecord
                                    onRef={ e => { this.UseRecord = e } }
                                    tableData={ this.state.useLists }
                                    total={ this.state.total }
                                    per_page={ this.state.per_page }
                                    page={ this.state.page }
                                ></UseRecord>
                        }

                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
                {this.state.is_write_off?<CardWriteOff user_time_card_id={this.props.match.params.times_card_id } storeLists={this.state.storeLists} close={this.handleCloseModalCard} successCallBack={this.fetchData} /> : null}
            </div>
        )
    }

}

export default Form.create({})(withRouter(UserTimesCard))
