import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Select, Button, Input, Switch, Radio, Checkbox, Row, Col, Icon } from 'antd'
import { getSetting, saveSetting } from '@/api/book'
import PropTypes from 'prop-types'
import message from '@/utils/message'
import '@/assets/css/ktv/index.scss'
const FormItem = Form.Item
const initData = {
    can_book:0,
    alias:{
        sing:'仅欢唱',
        meal:'超值套餐'
    },
    booking_auto_accept:1,
    pre_booking:1,
    pre_booking_time:30,
    refund_booking:1,
    can_use_coupon:0,
    refund_booking_time:60,
    booking_tel:'',
    booking_rules:[
        { msg:'预订成功后，如您有时间调整等需求，请联系客服' },
        { msg:'开唱前X小时可随时退，逾期不可退' },
        { msg:'如需发票，请您到店消费时向前台咨询' },
        { msg:'未成年人请勿购买' },
    ]
}


class BookSetting extends React.Component{
    static contextTypes = {
        setLoading: PropTypes.func
    }
    state = {
        formData:{},
        currentStore:'',
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {},
            currentStore = this.state.currentStore || '';
        if(!currentStore){
            this.setState({
                currentStore:this.props.storeLists[0]
            })
        }
        sendData.store_id = currentStore ? currentStore.id : this.props.storeLists[0].id;
        this.context.setLoading(true)
        getSetting(sendData)
            .then(res => {
                if(res.code == 1){
                    let config = res.data.config
                    if(config){
                        this.setState({
                            formData:config
                        })
                    }else {
                        this.setState({
                            formData:initData
                        })
                    }
                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })



    }
    addRules = (index) => {
        let formData = this.state.formData || {},
            newData = { msg:''},
            booking_rules = formData.booking_rules || []
        if(booking_rules.length >= 10){
            message.error('最多设置10条')
        }
        booking_rules.push(newData);
        this.setState({
            formData
        })


    }
    delRules = (index) => {
        let formData = this.state.formData || {},
            booking_rules = formData.booking_rules || []
        booking_rules.splice(index,1);
        this.setState({
            formData
        })
    }
    handleCheckChange = (e,keyName) => {
        let val = e.target.checked ? 1 : 0,
            formData = this.state.formData || {};
        formData[keyName] = val;
        this.setState({
            formData
        })
    }
    handleStoreChange = (val) => {
        let currentStore = '',
            storeLists = this.props.storeLists || [];
        if(val){
            storeLists.map(item => {
                if(item.id == val){
                    currentStore = item;
                    this.setState({
                        currentStore
                    },() => {
                        this.fetchData();
                    })
                }
            })
        }

    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {},
            config = {};
        sendData.store_id = this.state.currentStore ? this.state.currentStore.id : this.props.storeLists[0].id;
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                config = values;
                config.pre_booking = formData.pre_booking;
                config.refund_booking = formData.refund_booking;
                for(var x in config){
                    if(typeof config[x] === 'boolean'){
                        config[x] = config[x] ? 1 : 0
                    }
                }
                if(!config.pre_booking){
                    config.pre_booking_time = ''
                }
                if(!config.refund_booking){
                    config.refund_booking_time = ''
                }
                sendData.config = config;
                this.context.setLoading(true)
                saveSetting(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.context.setLoading(false)
                    })


            }
        })
    }
    render() {
        let data = this.state.formData || {},
            storeLists = this.props.storeLists || [],
            currentStore = this.state.currentStore || {};
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 2
            },
            wrapperCol: {
                span: 7
            }
        }
        return(
            Object.keys(data).length
                ?
                <div className={``}>
                    <Form className={`model-form `} { ...formItemLayout }>
                        <FormItem label={'选择门店'} >
                            <Select style={{width:170}} placeholder={'请选择'} value={ currentStore.id } onChange={ this.handleStoreChange }>
                                {
                                    storeLists.map(store => (
                                        <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                    ))
                                }
                            </Select>
                            <span className={`fi-help-text`}>选择门店进行单独设置，设置后保存</span>
                        </FormItem>
                        <div className={`msg-title mgb10`}>小程序端预订功能</div>
                        <FormItem label={'预订开关'}>
                            {getFieldDecorator('can_book', {
                                initialValue: data.can_book == undefined ? true : !!data.can_book,
                                valuePropName:'checked'
                            })(
                                <Switch></Switch>
                            )}
                            <span className={`fi-help-text`}>
                                开启后，用户可在小程序端进行预订
                            </span>
                        </FormItem>
                        <div className={`msg-title mgb10`}>小程序端自定义名称</div>
                        <FormItem label={'仅欢唱'}>
                            {getFieldDecorator('alias.sing', {
                                initialValue: data.alias.sing || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入仅欢唱自定义名称'
                                    }
                                ]
                            })(<Input
                                placeholder={'请输入名称，限30字符'}
                                maxLength={30}
                                suffix={<span>{data.alias.sing && data.alias.sing.length ? data.alias.sing.length : 0}/30</span>}

                            />)}
                        </FormItem>
                        <FormItem label={'超值套餐'}>
                            {getFieldDecorator(`alias.meal`, {
                                initialValue: data.alias.meal || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入超值套餐自定义名称'
                                    }
                                ]
                            })(<Input
                                placeholder={'请输入名称，限30字符'}
                                maxLength={30}
                                suffix={<span>{data.alias.meal && data.alias.meal.length ? data.alias.meal.length : 0}/30</span>}
                                />
                            )}
                        </FormItem>
                        <div className={`msg-title mgb10`}>预订接单方式</div>
                        <FormItem label={'接单方式'}>
                            {getFieldDecorator('booking_auto_accept', {
                                initialValue: data.booking_auto_accept || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择接单方式'
                                    }
                                ]
                            })(
                                <Radio.Group >
                                    <Radio value={1}>自动接单</Radio>
                                    <Radio value={2}>手动接单</Radio>
                                </Radio.Group>
                            )}
                        </FormItem>
                        <div className={`msg-title mgb10`}>预订规则</div>
                        <FormItem label={<span><Checkbox className={`mgr5`} checked={ data.pre_booking } onChange={ (e) => { this.handleCheckChange(e,'pre_booking') } }></Checkbox>提前预订时间</span>}>
                            <div className={`lh40`}>
                                顾客需要提前
                                {getFieldDecorator('pre_booking_time', {
                                    initialValue: data.pre_booking_time || 30,
                                })(
                                    <Select  style={{width:'100px'}} placeholder="请选择" className={`mgl5 mgr5`}>
                                        <Select.Option value={15}>15分钟</Select.Option>
                                        <Select.Option value={30}>30分钟</Select.Option>
                                        <Select.Option value={60}>60分钟</Select.Option>
                                        <Select.Option value={90}>90分钟</Select.Option>
                                        <Select.Option value={120}>120分钟</Select.Option>
                                    </Select>
                                )}
                                预订
                            </div>
                            <span className={`fi-help-text`}>
                               勾选后，用户在小程序端预订，需要在当前时间的X分钟前预订；不勾选，表示随时可订
                            </span>
                        </FormItem>
                        <FormItem label={<span><Checkbox className={`mgr5`} checked={ data.refund_booking } onChange={ (e) => { this.handleCheckChange(e,'refund_booking') } }></Checkbox>退订时间</span>}>
                            <div className={`lh40`}>
                                顾客需要提前
                                {getFieldDecorator('refund_booking_time', {
                                    initialValue: data.refund_booking_time || 60,
                                })(
                                    <Select  style={{width:'100px'}} placeholder="请选择" className={`mgl5 mgr5`}>
                                        <Select.Option value={15}>15分钟</Select.Option>
                                        <Select.Option value={30}>30分钟</Select.Option>
                                        <Select.Option value={60}>60分钟</Select.Option>
                                        <Select.Option value={90}>90分钟</Select.Option>
                                        <Select.Option value={120}>120分钟</Select.Option>
                                    </Select>
                                )}
                                退订
                            </div>
                            <span className={`fi-help-text`}>
                               勾选后，用户需要在开唱前X分钟在小程序端提交退订申请；不勾选，表示随时可退
                            </span>
                        </FormItem>
                        <FormItem label={<span>使用优惠券</span>}>
                            <div className={`lh40`} >
                                {getFieldDecorator('can_use_coupon', {
                                    initialValue: data.can_use_coupon || 0
                                })(
                                    <Radio.Group >
                                        <Radio value={0}>不可用</Radio>
                                        <Radio value={1}>可用</Radio>
                                    </Radio.Group>
                                )}
                            </div>
                            <span className={`fi-help-text`}>设置可用，则用户有全场通用券时，可以进行抵扣</span>
                        </FormItem>
                        <FormItem label={'接收消息电话'}>
                            {getFieldDecorator('booking_tel', {
                                initialValue: data.booking_tel || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入电话号码'
                                    }
                                ]
                            })(<Input placeholder={'请输入电话号码'}  />)}
                            <span className={`fi-help-text`}>
                                若为手机号，请输入11位手机号；若为座机号，请输入区号-电话号码（0571-8888888），不支持分机号；
                                通知类型，请到<span className={`fi-link`}>消息设置</span>内进行勾选，如使用短信/电话通知，需先充值。
                            </span>
                        </FormItem>
                        <div className={`msg-title mgb10`}>预订须知</div>
                        <FormItem label={'预订须知'}>
                            <div>
                                {
                                    data.booking_rules.map((item,index) => (
                                        <Row key={index}>
                                            <Col span={20}>
                                                {
                                                    getFieldDecorator(`booking_rules[${index}].msg`,{
                                                        initialValue: item.msg
                                                    })(
                                                        <Input placeholder={'请输入须知'} maxLength={30} />
                                                    )
                                                }
                                            </Col>
                                            <Col span={4}>
                                                <Icon type="plus-circle" className={'mgl10 fz18 book-icon'} onClick={() => { this.addRules(index) }} />
                                                {
                                                    index > 0
                                                        ?
                                                        <Icon type="minus-circle" className={'mgl10 fz18 book-icon'} onClick={() => { this.delRules(index) }} />
                                                        :
                                                        ''
                                                }
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </div>

                        </FormItem>
                        <FormItem label={` `} colon={false}>
                            <Button type={`primary`} onClick={ this.saveData }>立即保存</Button>
                        </FormItem>
                    </Form>
                </div>
                :
                null
        )
    }
}
export default Form.create({})(withRouter(BookSetting))
