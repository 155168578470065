/**
 * 积分兑换组件
 * @type 24
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip } from 'antd'
import PropTypes from "prop-types";
import PointExchange from '@/components/pointItemPicker/pointItemPicker'
import PictureCard from '@/components/PictureCard/PictureCard'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
const FormItem = Form.Item
const ctrlItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

class tpl_ctrl_type24 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func
    }

    state = {
        moduleData: {},
        itemStyleGroup: [
            {
                name: '一行两列',
                style: 1,
                imgs: ['https://img.cxkoo.com/chengxuan/1/2021-11-22/ea5d1c9940155160ec729f67cb3f7ff5.png', 'https://img.cxkoo.com/chengxuan/1/2021-11-22/32854ed39350a6a2bbc22da3654d9fb0.png']
            },
            {
                name: '一行三列',
                style: 2,
                imgs: ['https://img.cxkoo.com/chengxuan/1/2021-11-22/31862b3f6c920a83b155fda50ce6a273.png', 'https://img.cxkoo.com/chengxuan/1/2021-11-22/e96b248aa2080c7de856ba18c364b309.png']
            }
        ]
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    addItem = (item, index) => {
        let moduleData = this.state.moduleData;
        this.ItemPicker.show(res => {
            if (res.length) {
                let category={};
                res=res.map(item=>{
                    item['cover_img']=item.goods_info?.cover_img || '';

                    let obj
                    if(item.category_id == 0){
                        obj={
                            category_id:0,
                            category_name:'无分类商品'
                        }
                    }else{
                        obj={
                            category_id:item.category_id,
                            category_name:item.category_name
                        }
                    }
                    
                    category[item.category_id]=obj;
                    return item
                })

                moduleData.content.dataset = res;
                moduleData.content.category=category;
                this.context.updateDesign(moduleData)
            }
        })
    }


    handleStyleChange = (e, name) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData)

    }
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)
    }

    delImg = (e) => {
        let moduleData = this.state.moduleData,
            index = e.index || 0
        moduleData.content.dataset.splice(index, 1);

        let category={};
        moduleData.content.dataset.forEach(item=>{
            let obj;
            if(item.category_id == 0){
                obj={
                    category_id:0,
                    category_name:'无分类商品'
                }
            }else{
                obj={
                    category_id:item.category_id,
                    category_name:item.category_name
                }
            }
            category[item.category_id]=obj;
        })
        moduleData.content.category=category;
        this.context.updateDesign(moduleData)
    }

    handleSort = (e) => {
        let moduleData = this.state.moduleData,
            dataset = moduleData.content.dataset || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = dataset.splice(oldIndex, 1)[0];
        dataset.splice(newIndex, 0, targetRow);
        moduleData.content.dataset = dataset;
        this.context.updateDesign(moduleData)
    }

    handleColorChange = (color, name) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }

    handleResetColor = (name, color) => {
        let moduleData = this.state.moduleData
        moduleData.content[name] = color
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return (
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>积分兑换</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`选择积分商品列表样式`}>
                                    <ul className={`template-box`}>
                                        {
                                            this.state.itemStyleGroup.map((item, index) => (
                                                <Tooltip effect="dark" placement="bottom" title={item.name} key={index}>
                                                    <li className={`temp ${moduleData.content.style == item.style ? 'selected' : ''}`} onClick={() => { this.handleItemStyle(item.style) }}>
                                                        {
                                                            moduleData.content.style == item.style
                                                                ?
                                                                <img src={item.imgs[0]} className={`img`} alt="" />
                                                                :
                                                                <img src={item.imgs[1]} className={`img`} alt="" />
                                                        }
                                                    </li>
                                                </Tooltip>
                                            ))
                                        }
                                    </ul>
                                </FormItem>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width: 60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`商品样式`} {...ctrlItemLayout}>
                                    <Radio.Group buttonStyle="solid" value={moduleData.content.borderStyle} onChange={(e) => { this.handleStyleChange(e, 'borderStyle') }}>
                                        <Radio.Button value={1}>无边</Radio.Button>
                                        <Radio.Button value={2}>卡片</Radio.Button>
                                        <Radio.Button value={3}>描边</Radio.Button>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`积分商品分类`} {...ctrlItemLayout}>
                                    <Radio.Group value={moduleData.content.showCategory} onChange={(e) => { this.handleStyleChange(e, 'showCategory') }}>
                                        <Radio value={1}>显示</Radio>
                                        <Radio value={0}>不显示</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`兑换按钮`} {...ctrlItemLayout}>
                                    <Radio.Group value={moduleData.content.showBtn} onChange={(e) => { this.handleStyleChange(e, 'showBtn') }}>
                                        <Radio value={1}>显示</Radio>
                                        <Radio value={0}>不显示</Radio>
                                    </Radio.Group>
                                </FormItem>
                                {
                                    moduleData.content.showBtn ?
                                        <>
                                            <FormItem label={`按钮颜色`} {...ctrlItemLayout} >
                                                <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnBgColor} callback={(color) => { this.handleColorChange(color, 'btnBgColor') }} ></ColorPicker>
                                                <Button type="link" onClick={() => this.handleResetColor('btnBgColor', '#ff0000')}>重置</Button>
                                            </FormItem>
                                            <FormItem label={`按钮文字颜色`} {...ctrlItemLayout} >
                                                <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnTextColor} callback={(color) => { this.handleColorChange(color, 'btnTextColor') }} ></ColorPicker>
                                                <Button type="link" onClick={() => this.handleResetColor('btnTextColor', '#ffffff')}>重置</Button>
                                            </FormItem>
                                        </> : null
                                }

                                <FormItem label={`价格颜色`} {...ctrlItemLayout} >
                                    <ColorPicker style={{ display: "inline-block" }} onRef={e => { this.ColorPicker = e }} color={moduleData.content.priceColor} callback={(color) => { this.handleColorChange(color, 'priceColor') }} ></ColorPicker>
                                    <Button type="link" onClick={() => this.handleResetColor('priceColor', '#ff0000')}>重置</Button>
                                </FormItem>
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>添加兑换商品</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <div className={`cx-img-list`} >
                                    <div className={`cx-img-item clearfix`}>
                                        <FormItem label={`选择商品`} {...ctrlItemLayout}>
                                            <div className={'clearfix'}>
                                                <PictureCard
                                                    onRef={e => { this.PictureCard = e }}
                                                    imgs={moduleData.content.dataset}
                                                    onDel={e => { this.delImg(e) }}
                                                    onSort={e => { this.handleSort(e) }}
                                                    item_key={'cover_img'}
                                                    item_id={'id'}
                                                ></PictureCard>
                                                <div className={'img-add'} onClick={this.addItem} data-name="pic">+</div>
                                            </div>
                                        </FormItem>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <PointExchange
                        onRef={e => { this.ItemPicker = e }}
                        selectIds={moduleData.content.dataset.length ? moduleData.content.dataset.map(item => { return Number(item.id) }) : []}
                        items={moduleData.content.dataset.length ? moduleData.content.dataset : []}
                        multiple={true}
                    >
                    </PointExchange>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type24)
