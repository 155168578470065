import React, { Component } from 'react'
import { Modal, Button, Form, Select, Input, Table, InputNumber, message } from 'antd';
import { timesCardItem } from '@/api/times-card.js';
import { verifyTimesCard } from '@/api/order.js'
const FormItem = Form.Item
const { Option } = Select;
const { TextArea } = Input;

class CardWriteOff extends Component {
    state = {
        columns: [
            {
                title: '商品名称',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name}</p>
                    )
                }
            },
            {
                title: '剩余次数',
                dataIndex: '2',
                width: "100px",
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.last_count}
                        </p>
                    )
                }
            },
            {
                title: '扣除',
                dataIndex: '3',
                width: "140px",
                render: (text, rowData, index) => {
                    if (!rowData.last_count) {
                        return null
                    }

                    return (
                        <div>
                            <InputNumber value={rowData.verify_count || undefined} min={0} max={rowData.last_count} precision={0} onChange={this.handleInputNumber(index)} /> 次
                        </div>
                    )
                }
            },


        ],
        tableData: [],
        card_detail: {},
        confirmLoading:false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        timesCardItem({ user_time_card_id: this.props.user_time_card_id }).then(res => {
            if (res.code == 1) {
                let { card_detail, goods_list } = res.data

                goods_list=goods_list.map(val=>{
                    val.verify_count=0
                    return val
                })
                this.setState({
                    card_detail,
                    tableData: goods_list
                })
            }
        })
    }

    handleInputNumber = index => {
        return val => {
            const tableData = this.state.tableData
            tableData[index].verify_count = val
            this.setState({
                tableData
            })
        }
    }

    handleOk = () => {
        let sendData = {}
        this.props.form.validateFields((err, values) => {
            if (!err) {
                sendData = values
                sendData.user_time_card_id = this.props.user_time_card_id
                sendData.goods_list = this.state.tableData

                this.setState({
                    confirmLoading:true
                })
                verifyTimesCard(sendData).then(res=>{
                    if (res.code == 1) {
                        message.success(res.msg)
                        this.props.close()
                        this.props.successCallBack()
                    }else{
                        this.setState({
                            confirmLoading:false
                        })
                    }

                })

            }
        })
    }

    handleCancel = () => {
        this.props.close()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 19
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        const { card_detail } = this.state
        const storeLists = this.props.storeLists || []
        return (
            <Modal
                title="次卡核销"
                visible={true}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={700}
                confirmLoading={this.state.confirmLoading}
            >
                <Form className={`model-form`} {...formItemLayout}>
                    <FormItem label={`次卡名称`} required> {card_detail.card_name || ''}</FormItem>
                    <FormItem label={'核销门店'}>
                        {getFieldDecorator('store_id', {
                            initialValue: undefined
                        })(
                            <Select placeholder='请选择'>

                                {storeLists.map(val => {
                                    return <Option key={val.id} value={val.id}>{val.name}</Option>
                                })}
                            </Select>
                        )}

                    </FormItem>
                    <FormItem label={`卡项内容`} >
                        <Table
                            size={`middle`}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            rowKey={record => record.id}
                            pagination={{ pageSize: 5 }}
                        ></Table>

                    </FormItem>

                    <FormItem label={'备注'}>
                        {getFieldDecorator('remark', {
                            initialValue: '商家手动调整'
                        })(
                            <TextArea rows={4} />
                        )}

                    </FormItem>

                </Form>
            </Modal>
        )
    }
}

export default Form.create({})(CardWriteOff)
