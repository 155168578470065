import React, { Component } from 'react'
import { Table, InputNumber,Button,Divider } from 'antd';
import CX from '@/utils/CX';


export default class FullDiscount extends Component {
    
    state = {
        columns: [
            {
                title: '满减规则设置（活动商品全都参与，可添加5条规则）',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    let lastIndex=this.state.ruleData.length -1 ;
                    return (
                        <div>满
                            <InputNumber value={rowData.full} style={{ width: 100 }} min={0}  precision={0} onChange={this.handleGoodsUpdate('full', index)} className="mgl10 mgr10" />元减
                            <InputNumber value={rowData.cut} style={{ width: 100 }} min={0}  precision={0} onChange={this.handleGoodsUpdate('cut', index)} className="mgl10 mgr10"  />元
                            {
                              lastIndex>0? <Button type='link' onClick={this.handleDele(index)}  >删除</Button>:null
                            }
                            {
                                lastIndex==index && index>0 && index<4 ?  <Divider type={`vertical`}></Divider>:null
                            }
                            {
                                lastIndex==index && lastIndex<4?<Button type='link' onClick={this.handleAdd}    >添加</Button>:null
                            }

                        </div>
                    )
                }
            }
        ],
        ruleData: [
            {
                id:1,
                full:0,
                cut:0
            }
        ]
    }

    componentDidMount(){
        this.props.onRef(this)

       let full_cut_rule=this.props.full_cut_rule || []

       if(full_cut_rule && full_cut_rule.length){
         full_cut_rule=full_cut_rule.map((val,index)=>{
            val.id=index
            return val
         })
         this.setState({
            ruleData:full_cut_rule
         })
       }
    }

    handleGoodsUpdate = (name, index) => {
        return e => {
            const { ruleData } = this.state
            ruleData[index][name] = e
            this.setState({
                ruleData
            })
        }
    }
    handleDele=index=>{
        return ()=>{
            const { ruleData } = this.state
            ruleData.splice(index,1)
            this.setState({
                ruleData
            })
        }
    }

    handleAdd=()=>{
        const { ruleData } = this.state
        if(ruleData.length>4){
            return 
        }
        ruleData.push({
            id:CX.genUUID(),
            full:0,
            cut:0
        })

        this.setState({
            ruleData
        })
    }

    get_full_cut_data=()=>{
        return this.state.ruleData.map(val=>({full:val.full,cut:val.cut}))
    }

    render() {
        const { columns, ruleData } = this.state
        return (
            <Table columns={columns} dataSource={ruleData}   rowKey={record => record.id}   pagination={false}   />
        )
    }
}
