import React from 'react'
import { Form, Input, Button, Switch, Checkbox, Table, Icon,Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'
import { saveInfo, getInfo } from '@/api/fill-information'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import Gallery from '@/components/Gallery/Gallery'
import CouponPicker from '@/components/CouponPicker/CouponPicker'
import moment from 'moment'
const FormItem = Form.Item;

class FillInformation extends React.Component{
    state = {
        formData:'',
        isLoading:false,
        columns: [
            {
                title: '券名称',
                dataIndex: 'name',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name || '--'}</p>
                    )
                }
            },
            {
                title: '券类型',
                dataIndex: 'coupon_type',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <div dangerouslySetInnerHTML = {{ __html:rowData.effect_time }} className={`lh25`}></div>
                    )
                }
            },
            {
                title: '赠送数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <Input
                            style={{width:'100px'}}
                            placeholder={`张`}
                            value = { rowData.num || `` }
                            onChange={ e => { this.handleNumChange(e,rowData,index) } }
                        />
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => {this.handleDelItem(rowData, index)}}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ],
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        this.setState({
            isLoading:true
        })
        getInfo(sendData)
            .then(res => {
                if(res.code == 1){
                    let detail = res.data.detail || {};
                    detail.present_coupon_data = detail.present_coupon_list || []
                    this.setState({
                        formData:detail
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleCoupon = () => {
        let formData = this.state.formData || {}
        this.CouponPicker.show(res => {
            if(res.length > 5){
                res.length = 5;
            }
            formData.present_coupon_data = res;
            this.setState({
                formData
            })
        })
    }
    handleNumChange = (e,rowData,index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data,
            val = e.target.value;
        present_coupon_data[index]['num'] = val;
        this.setState({
            formData
        })
    }
    handleDelItem = (rowData,index) => {
        let formData = this.state.formData,
            present_coupon_data = formData.present_coupon_data;
        present_coupon_data.splice(index,1);
        this.setState({
            formData
        })
    }
    handleSwitch = (val) => {
        console.log('val',val);
        this.props.form.setFieldsValue({
            is_open:val
        })
        this.saveData();
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                sendData = values;
                for(var i in sendData){
                    if(typeof sendData[i] === 'boolean'){
                        sendData[i] = sendData[i] ? 1 : 0
                    }
                }
                sendData.present_coupon_data = formData.present_coupon_data && formData.present_coupon_data.map(item => { return { id:item.id,num:item.num } });
                this.setState({
                    isLoading:true
                })
                saveInfo(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return(
            <div className={`shadow-radius`}>
                <CouponPicker
                    onRef={ e => { this.CouponPicker = e } }
                    multiple={true}
                    selectIds={ data.present_coupon_data && data.present_coupon_data.map(item => {return Number(item.id)})}
                ></CouponPicker>
                <Form {...formItemLayout} className={'model-form'}>
                    <div className={`sysPanel`}>
                        <div className={`sysPanel-con`}>
                            <div className={`sysPanel-title`}>奖励发放</div>
                            <div className={`sysPanel-tip`}>开启后，会按照下方设置发放对应奖励</div>
                        </div>
                        <div className={`list-switch`}>
                            {getFieldDecorator('is_open', {
                                initialValue: data.is_open === undefined ? false : !!data.is_open,
                                valuePropName:'checked'
                            })(
                                <Switch onChange={ this.handleSwitch }></Switch>
                            )}
                        </div>
                    </div>
                    <div className={`msg-title mgb15`}>基本信息</div>
                    <FormItem label={`引导完善信息入口`}>
                        <div>
                            {
                                getFieldDecorator('user_center_remind', {
                                    initialValue: data.user_center_remind || 0,
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox className={`mgr5`}></Checkbox>
                                )
                            }
                            个人中心页弹窗引导
                            <Tooltip placement={'right'} title={<div><img src="https://img.cxkoo.com/chengxuan/1/2022-01-21/9afa15d7461633ef1e594af0472c1683.png" alt="" width={200} /></div>}>
                                <span className={`fi-link mgl10`}>预览</span>
                            </Tooltip>
                        </div>
                        <div>
                            {
                                getFieldDecorator('open_card_remind', {
                                    initialValue: data.open_card_remind || 0,
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox className={`mgr5`}></Checkbox>
                                )
                            }
                            开通会员卡后详情页弹窗引导
                            <Tooltip placement={'right'} title={<div><img src="https://img.cxkoo.com/chengxuan/1/2022-01-21/fd03a9a28574d8e6e2013989968e886b.png" alt="" width={200} /></div>}>
                                <span className={`fi-link mgl10`}>预览</span>
                            </Tooltip>
                        </div>
                        <span className={`fi-help-text`}>客户没有完善信息时会一直引导，完善后不再出现</span>
                    </FormItem>
                    <FormItem label={`奖励类型`}>
                        <div>
                            {
                                getFieldDecorator('is_present_points', {
                                    initialValue: data.is_present_points || 0,
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox className={`mgr5`}></Checkbox>
                                )
                            }
                            积分，送
                            {getFieldDecorator('present_points', {
                                initialValue: data.present_points || '',
                            })(
                                <Input style={{width: '80px'}} className={`mgl5 mgr5`} />
                            )}
                            积分
                        </div>
                        <div>
                            {
                                getFieldDecorator('is_present_exp', {
                                    initialValue: data.is_present_exp|| 0,
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox className={`mgr5`}></Checkbox>
                                )
                            }
                            成长值，送
                            {getFieldDecorator('present_exp', {
                                initialValue: data.present_exp || '',
                            })(
                                <Input style={{width: '80px'}} className={`mgl5 mgr5`}/>
                            )}
                            成长值
                        </div>
                        <div>
                            {
                                getFieldDecorator('is_present_coupon', {
                                    initialValue: data.is_present_coupon || 0,
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox className={`mgr5`}></Checkbox>
                                )
                            }
                            优惠券
                            {
                                getFieldDecorator('present_coupon_data',{
                                    initialValue:data.present_coupon_data || []
                                })(
                                    <Button type={`default`} className={`mgl10`} onClick={ this.handleCoupon } >添加优惠</Button>
                                )
                            }
                            <span className={`fi-help-text`}>优惠券最多添加五张</span>
                        </div>
                    </FormItem>
                    {
                        data.present_coupon_data && data.present_coupon_data.length
                            ?
                            <FormItem label={` `} colon={false}>
                                <Table
                                    size={`middle`}
                                    columns={this.state.columns}
                                    dataSource={data.present_coupon_data}
                                    rowKey={record => record.id}
                                    style={{ width:'700px' }}
                                ></Table>
                            </FormItem>
                            :
                            null
                    }
                    <FormItem label={` `} colon={false}>
                        <Button type={`primary`} onClick={ this.saveData } >立即保存</Button>
                    </FormItem>


                </Form>

                { this.state.isLoading ? <Loading /> : null }

            </div>
        )
    }

}

export default Form.create({})(withRouter(FillInformation))
