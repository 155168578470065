/**
 * 轮播图组件
 * @type 5
 * @return Object
 */

import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})

class tpl_con_type5 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }
    state = {
        moduleData:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这个模块吗？`,
            okType:`danger`,
            onOk:() => {
                this.context.deleteModule(moduleData)
            }
        })
    }
    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`轮播图`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{ paddingTop:`${moduleData.content.modulePadding}px`,paddingBottom:`${moduleData.content.modulePadding}px`}} onClick={ this.handleEditModule }>
                        {
                            moduleData.content.style == 1
                                ?
                                <div className={`members_flash`} style={{ paddingLeft:`${moduleData.content.spacePadding || 0}px`,paddingRight:`${moduleData.content.spacePadding || 0}px`}}>
                                    <div className={`swiper`}>
                                        <img
                                            src={ moduleData.content.dataset[0]['pic'] || 'https://img.cxkoo.com/chengxuan/1/2021-11-30/0f46fd1a85220b65d77e0590010f0bd5.png' }
                                            alt=""
                                            width={`100%`}
                                            className={`${ moduleData.content.borderStyle == 2 ? 'swiper-radius' : '' }`}
                                            style={{ height:`${ moduleData.content.sizeStyle == 1 ? '' : moduleData.content.sizeStyle == 2 ? '375px' : '225px' }` }}
                                        />
                                    </div>
                                    <div className={`members_flash_time`}>
                                        {
                                            moduleData.content.dataset.map((item,index) => (
                                                <span className={`${index == 0 ? `cur` : ''}`} key={index}></span>

                                            ))
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            moduleData.content.style == 2
                                ?
                                <div className={`swiper-style2`}>
                                    <div className={`members_flash`}>
                                        <div className={`swiper`}>
                                            <img
                                                src={ moduleData.content.dataset[0]['pic'] || 'https://img.cxkoo.com/chengxuan/1/2021-11-30/0f46fd1a85220b65d77e0590010f0bd5.png' }
                                                alt=""
                                                width={`100%`}
                                                className={`${ moduleData.content.borderStyle == 2 ? 'swiper-radius' : '' }`}
                                                style={{ height:`${ moduleData.content.sizeStyle == 1 ? '' : moduleData.content.sizeStyle == 2 ? '375px' : '225px' }` }}
                                            />
                                        </div>
                                        <div className={`members_flash_time`}>
                                            {
                                                moduleData.content.dataset.map((item,index) => (
                                                    <span className={`${index == 0 ? `cur` : ''}`} key={index}></span>

                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={`member-info`}>
                                        <div className={`avatar`}
                                             style={{ backgroundImage:'url(https://img.cxkoo.com/chengxuan/1/2021-11-30/0f46fd1a85220b65d77e0590010f0bd5.png)' }}></div>
                                        <div className={`desc`}>
                                            <div className={`txt1`}>欢迎光临</div>
                                            <div className={`txt2`}>相遇,是一场美丽的邂逅</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            moduleData.content.style == 3
                                ?
                                <div className={`swiper-style3`}>
                                    <div className={`members_flash`}>
                                        <div className={`swiper`}>
                                            <img
                                                src={ moduleData.content.dataset[0]['pic'] || 'https://img.cxkoo.com/chengxuan/1/2021-11-30/0f46fd1a85220b65d77e0590010f0bd5.png' }
                                                alt=""
                                                width={`100%`}
                                                className={`${ moduleData.content.borderStyle == 2 ? 'swiper-radius' : '' }`}
                                                style={{ height:`${ moduleData.content.sizeStyle == 1 ? '' : moduleData.content.sizeStyle == 2 ? '375px' : '225px' }` }}
                                            />
                                        </div>
                                        <div className={`members_flash_time`}>
                                            {
                                                moduleData.content.dataset.map((item,index) => (
                                                    <span className={`${index == 0 ? `cur` : ''}`} key={index}></span>

                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={`diy-store-info`} style={{ backgroundColor:moduleData.content.bgColor, color:moduleData.content.txtColor }}>
                                        <div className={`store-basic`}>
                                            <div className={`logo`}
                                                 style={{ backgroundImage:'url(https://img.cxkoo.com/chengxuan/1/2021-11-30/0f46fd1a85220b65d77e0590010f0bd5.png)' }}
                                            ></div>
                                            <div className={`store-name`}>示例门店名称</div>
                                        </div>
                                        {
                                            moduleData.content.showAddress == 1
                                                ?
                                                <div className={`store-address`}>
                                                    <div className={`left`}>
                                                        <div className={`address`}>示例门店地址</div>
                                                    </div>
                                                    <div className={`right`}>
                                                        <div
                                                            className={`tel-img`}
                                                            style={{ backgroundImage:'url(https://img.cxkoo.com/chengxuan/1/2021-12-06/21b489098294fb9a1104aba3da4f0f62.png)' }}
                                                        ></div>
                                                        <div className={`txt`}>电话</div>
                                                    </div>
                                                </div>
                                                :
                                                null

                                        }
                                        <div className={`store-online-tags`}>
                                            <div className={`online`}>营业时间 9:00~18:00</div>
                                            {
                                                moduleData.content.tags && moduleData.content.tags.length
                                                    ?
                                                    <div className={`store-tags`}>
                                                        {
                                                            moduleData.content.tags.map((tag,tagIndex) => (
                                                                <div className={`tag`} key={tagIndex} style={{ borderColor:moduleData.content.txtColor }}>{ tag }</div>

                                                            ))
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>


                                    </div>
                                </div>
                                :
                                null
                        }



                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type5)
