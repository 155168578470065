import React, { Component } from 'react'
import { Table, Pagination} from 'antd'
import { studentCourseRecord} from '@/api/student'

class PeriodTab extends Component {
    state = {
        columns: [
            {
                title:<span className='mgl16'>变更时间</span>,
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return <p className='mgl16'>{rowData.updated_at ||"-"}</p>
                }
            },
            {
                title: '说明',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return <p>{rowData.desc || '-'}</p>
                }
            },
            {
                title: '明细',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return <p>{rowData.change_num || '-'}</p>
                }
            },
            {
                title: '剩余课时',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return <p>{rowData.after_num || '-'}</p>
                }
            },
            {
                title: '备注',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return <p>{rowData.remark || '-'}</p>
                }
            },
        ],
        tableData: [],
        tableLoading: false,
        page:1,
        per_page:10,
        total:0,
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData=()=>{
        let sendData={}
        sendData.student_id=this.props.student_id
        sendData.page=this.state.page
        sendData.per_page=this.state.per_page
        this.setState({
            tableLoading:true
        })
        studentCourseRecord(sendData).then(res=>{
           
            if(res.code==1){
                let list=res.data.list
                let total=list.total,
                    per_page=list.per_page,
                    tableData=list.data
                this.setState({
                    total,per_page,tableData
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading:false
            })
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }


    render() {
        return (
            <div className='pdb20'>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={this.state.tableLoading}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PeriodTab
