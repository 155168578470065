import React from 'react'
import { Form, Button, Layout, Menu, Icon, Tooltip, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import { changeTpl } from '@/api/design'
import { formDetail, formSave } from '@/api/custom-form'
import { adminDetail } from '@/api/admin'
import Loading from '@/components/Loading'
import SetPageTitle from './components/SetPageTitle'
import '@/assets/css/design/index.scss'
import '@/assets/css/custom-form/modules.scss'
import ShortcutsPanel from "./components/ShortcutsPanel";
import { ICON_FONT_URL } from '@/constants/iconFont'
import { MODULE_DEFAULT_DATA } from './mockData/defaults'
import CX from '@/utils/CX'
import PropTypes from 'prop-types'
import moment from 'moment';
import Sortable from 'sortablejs';

import TplCtrlType0 from './components/ctrl/page-setting'
import TplCtrlType1 from './components/ctrl/txt-nav'
import TplCtrlType2 from './components/ctrl/img-ad'
import TplCtrlType3 from './components/ctrl/radio'
import TplCtrlType4 from './components/ctrl/check-box'
import TplCtrlType5 from './components/ctrl/img-raido'
import TplCtrlType6 from './components/ctrl/img-check-box'
import TplCtrlType7 from './components/ctrl/option-select'
import TplCtrlType8 from './components/ctrl/single-question'
import TplCtrlType9 from './components/ctrl/multiple-question'
import TplCtrlType10 from './components/ctrl/form-question'
import TplCtrlType11 from './components/ctrl/textarea'
import TplCtrlType12 from './components/ctrl/name'
import TplCtrlType13 from './components/ctrl/mobile'
import TplCtrlType14 from './components/ctrl/wechat'
import TplCtrlType15 from './components/ctrl/qq'
import TplCtrlType16 from './components/ctrl/email'
import TplCtrlType17 from './components/ctrl/address'

import TplConType1 from './components/phone/txt-nav'
import TplConType2 from './components/phone/img-ad'
import TplConType3 from './components/phone/radio'
import TplConType4 from './components/phone/check-box'
import TplConType5 from './components/phone/img-raido'
import TplConType6 from './components/phone/img-check-box'
import TplConType7 from './components/phone/option-select'
import TplConType8 from './components/phone/single-question'
import TplConType9 from './components/phone/multiple-question'
import TplConType10 from './components/phone/form-question'
import TplConType11 from './components/phone/textarea'
import TplConType12 from './components/phone/name'
import TplConType13 from './components/phone/mobile'
import TplConType14 from './components/phone/wechat'
import TplConType15 from './components/phone/qq'
import TplConType16 from './components/phone/email'
import TplConType17 from './components/phone/address'


const { Header, Sider, Content } = Layout
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})


class CustomForm extends React.Component{
    static childContextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }

    getChildContext = () => {
        return{
            updateDesign:(e) => {
                this.updateDesign(e)
            },
            deleteModule:(module) => {
                this.deleteModule(module)
            }
        }
    }

    state = {
        app_id:"",
        design:{
            page:{},
            CXModules:[],
        },
        currentModule:null, //当前编辑的模块
        conTplNames:{
            /* 这个对象是加载动态标签用的 */
            1:TplConType1,
            2:TplConType2,
            3:TplConType3,
            4:TplConType4,
            5:TplConType5,
            6:TplConType6,
            7:TplConType7,
            8:TplConType8,
            9:TplConType9,
            10:TplConType10,
            11:TplConType11,
            12:TplConType12,
            13:TplConType13,
            14:TplConType14,
            15:TplConType15,
            16:TplConType16,
            17:TplConType17,
        },
        ctrlTplNames:{
            /* 这个对象是加载动态标签用的 */
            1:TplCtrlType1,
            2:TplCtrlType2,
            3:TplCtrlType3,
            4:TplCtrlType4,
            5:TplCtrlType5,
            6:TplCtrlType6,
            7:TplCtrlType7,
            8:TplCtrlType8,
            9:TplCtrlType9,
            10:TplCtrlType10,
            11:TplCtrlType11,
            12:TplCtrlType12,
            13:TplCtrlType13,
            14:TplCtrlType14,
            15:TplCtrlType15,
            16:TplCtrlType16,
            17:TplCtrlType17,
        },
        isLoading:false,
        showModal:false,
        modalLoading:false,
        pageTitle:'',
    }
    componentDidMount() {
        this.init()
        window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
        this.initSort();
        // this.fetchAdmin()
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    handleWindowBeforeUnload = (e) => {
        let confirmationMessage = '系统可能不会保存您所做的更改';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }
    init = () => {
        let sendData = {}
        if(this.props.match.params.id){
            //编辑模式
            sendData.id = this.props.match.params.id
            this.setState({
                isLoading:true
            })
            formDetail(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail= res.data.detail,
                            page_title = detail.name,
                            design = {};
                        design.page = JSON.parse(detail.layout).page
                        design.CXModules = JSON.parse(detail.layout).CXModules
                        this.setState({
                            currentModule:design.page,
                            pageTitle:page_title,
                            design
                        },() => {})
                    }
                })
                .finally(()  => {
                    this.setState({
                        isLoading:false
                    })
                })
        }else {
            //创建模式
            sendData.id = 1; //空白模板
            this.setState({
                isLoading:true
            })
            changeTpl(sendData)
                .then(res => {
                    if(res.code == 1){
                        let detail = res.data.template_detail,
                            design = {};
                        design.page = JSON.parse(detail.content).page
                        design.page.title = '调查问卷'
                        design.page.btnBgColor = '#ff0000';
                        design.page.btnTxt = '提交表单'
                        design.page.jump_url=''
                        design.CXModules = JSON.parse(detail.content).CXModules
                        console.log(design)
                        this.setState({
                            currentModule:design.page,
                            design,
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        }

    }
    initSort = () => {
        let sortable = new Sortable(this.refs.phoneChild,{
            group: 'shared', // set both lists to same group
            animation: 150,
            onEnd:(event) => {
                this.handleSortEnd(event)
            }
        })
    }
    fetchAdmin = () => {
        let sendData = {};
        adminDetail(sendData)
        .then(res => {
            if (res.code == 1) {
                let shop = res.data.shop,
                    program=shop.program ||{},
                    app_id=program.app_id || ''
                
                this.setState({
                    app_id
                })

            }
        })
    }
    handleSortEnd = e => {
        let design = this.state.design,
            CXModules = design.CXModules,
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if(oldIndex == newIndex){
            return ;
        }
        let targetRow = CXModules.splice(oldIndex,1)[0];
        CXModules.splice(newIndex,0,targetRow);
        this.setState({
            design
        })
    }
    handleAddModule = module => {
        module.type = Number(module.type)
        let design = this.state.design,
            CXModule = {};
        switch (module.type) {
            case 0:
                //页面设置
                CXModule = design.page;
                break;
            default:
                //添加模块
                let CXModules = design.CXModules;
                CXModule.id = CX.genUUID();
                for ( var i in MODULE_DEFAULT_DATA[module.type]){
                    CXModule[i] = MODULE_DEFAULT_DATA[module.type][i]
                }
                CXModules.push(CXModule)
                break;
        }
        this.setState({
            currentModule:CXModule,
            design
        },() => {
            setTimeout(() => {
                let height = this.refs.phoneChild.getBoundingClientRect().height ;
                this.refs.phone.scrollTop = height
            },100)

        })
    }
    renderMenuContent = () => {
        return(
            <div className={`left-panel-scroll`}>
                <ShortcutsPanel
                    onRef={ e => { this.ShortcutsPanel = e } }
                    handleCurrentChange = { e => { this.handleAddModule(e) } }
                ></ShortcutsPanel>
            </div>

        )
    }
    renderCtrl = currentModule => {
        if(currentModule){
            if(currentModule.type){
                let TplName = this.state.ctrlTplNames[currentModule.type];
                return(
                    <TplName onRef={ e => { this[`TplCtrlType${currentModule.type}`] = e } } module={currentModule} />
                )
            }else {
                switch (currentModule.dataType) {
                    case 'page':
                        return(
                            <TplCtrlType0 onRef={ e => { this.TplCtrlType0 = e } } module={currentModule}  />
                        )
                }

            }

        }else {
            return null
        }
    }
    updateDesign = newData => {
        let design = this.state.design,
            currentModule = this.state.currentModule,
            CXModules = design.CXModules;
        if(!newData['id']){
            //导航设置或页面设置
            currentModule = newData;
            switch (newData.dataType) {
                case 'page':
                    design.page = newData
                    break;
            }
        }else {
            //其他模块
            for(var i in CXModules){
                CXModules[i]['is_choose'] = 0
                if(CXModules[i]['id'] == newData['id']){
                    CXModules[i] = newData
                    currentModule = newData
                    CXModules[i]['is_choose'] = 1

                }
            }
        }
        this.setState({
            design,currentModule
        })
    }
    deleteModule = module => {
        let design = this.state.design,
            currentModule = this.state.currentModule,
            CXModules = design.CXModules;
        for(var i in CXModules){
            if(CXModules[i]['id'] == module['id']){
                CXModules.splice(i,1);
                currentModule = design.page;
            }
        }
        this.setState({
            design,currentModule
        })
    }
    handleEmpty = () => {
        let design = this.state.design,
            currentModule = this.state.currentModule;
        Modal.confirm({
            title:`是否清空当前装修的内容？`,
            onOk:() => {
                design.CXModules = [];
                currentModule = design.page;
                this.setState({
                    design,currentModule
                })
                message.success('操作成功')
            }
        })
    }
    handleCancel = () => {
        this.setState({
            showModal:false
        })
    }
    handleOk = () => {
        let sendData = {},
            design = this.state.design;
        this.formRef.props.form.validateFields((err,values) => {
            if(!err){
                sendData.name = values.title;
                let content = {}
                content.page = design.page;
                content.CXModules = design.CXModules;
                sendData.layout = JSON.stringify(content);
                sendData.status = 1;
                if(this.props.match.params.id){
                    sendData.id = this.props.match.params.id;
                }
                console.log(sendData.layout);
                this.setState({
                    isLoading:true,
                    showModal:false
                })
                formSave(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success(`操作成功`)
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/custom-form/lists`
                                    })
                                    // window.location.reload();

                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })



            }
        });

    }
    handleAddDesign = () => {
        this.setState({
            pageTitle:this.state.pageTitle || `调查问卷-${moment().format('YYYY-MM-DD-HH:mm:ss')}`,
            showModal:true

        })
    }
    handlePreview = () => {
        // message.info('敬请期待')
        let img_url=process.env.REACT_APP_BASE_API +'/mini-qrcode?app_id='+this.state.app_id
        
        Modal.info({
            title: '请保存后，扫码预览效果',
            className:"coustom-modal",
            content: (
                <div>
                    <img src={img_url} alt="" width={300} height={300} />
                </div>
            ),
            onOk() { },
        });
    }
    handleTpl = () => {
        message.info('敬请期待')
    }
    back = () => {
        Modal.confirm({
            title:`确定返回上一页吗，未保存的数据将会丢失`,
            okType:`danger`,
            onOk:() => {
                this.props.history.push({
                    pathname:`/custom-form/lists`
                })
            }
        })

    }

    render() {
        let CXModules = this.state.design.CXModules || [],
            page = this.state.design.page,
            currentModule = this.state.currentModule || null;
        return (
            <Layout style={{ height:'100vh' }} className={`editor-layout`}>
                <Header className={`header`}>
                    {/* <div className={`editor-logo`} onClick={ this.back }>
                        返回上一页
                    </div> */}
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{ lineHeight: '64px', float: 'right', background: 'transparent' }}
                    >
                        <Menu.Item key={'2'} className={`transparent-bg pdl10 pdr10`}>
                            <Button size={`small`} onClick={ this.handleAddDesign }>保存</Button>
                        </Menu.Item>
                        {/*<Menu.Item key={'3'} className={`transparent-bg pdl10 pdr10`}>*/}
                        {/*<Button size={`small`}>设置为模板</Button>*/}
                        {/*</Menu.Item>*/}
                    </Menu>
                </Header>
                <Layout>
                    <Sider theme={'light'} width={240} style={{ background: '#fff', padding: '12px' }}>
                        { this.renderMenuContent() }
                    </Sider>
                    <Layout className={`canvas-outer-wrapper`}>
                        <Content style={{ transform:'scale(1)', transformOrigin: 'center top' }}>
                            <div className={`diy-phone`}>
                                <div className={`phone-contain`}>
                                    <div className={`phone-title`} style={{ backgroundColor:`${page.NavigationBackgroundColor}` }}>
                                        <p className={`phone-title-txt`} style={{ color:`${page.titleColorStyle == 0 ? '#111111' : '#FFFFFF'}` }} >{ page.title }</p>
                                    </div>
                                    <div className={`phone-inner withoutNav `} ref={`phone`} style={{ backgroundColor:`${page.backgroundColor}` }}>
                                        <div className={`phone-inner-cont`} ref={`phoneChild`}>
                                            {
                                                CXModules.map((item,index) => {
                                                    let TplName = this.state.conTplNames[item.type];
                                                    return(
                                                        <TplName onRef={ e => { this[`TplConType${item.id}`] = e } } key={index} module={item} />
                                                    )
                                                })
                                            }
                                            {
                                                CXModules && CXModules.length
                                                    ?
                                                    <div className={`submit-button-cont`}>
                                                        <div className={`submit-button`} style={{ backgroundColor:`${page.btnBgColor}` }}>{ page.btnTxt }</div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                    <Sider theme={`light`} width={40} style={{ background: '#fff', border: '1px solid #eee' }}>
                        <Button.Group style={{ display: 'flex', flexDirection: 'column' }}>
                            <Tooltip effect="dark" placement="left" title={`清空装修内容`}>
                                <Button
                                    block
                                    className={`transparent-bg`}
                                    type={`link`}
                                    size={`small`}
                                    style={{ height: '40px', color: '#000' }}
                                    onClick={ this.handleEmpty }
                                >
                                    <EditorIcon type={`icon-qingkong`} className={`shortcut-icon fz20`} />
                                </Button>
                            </Tooltip>

                        </Button.Group>
                    </Sider>
                    <Sider theme='light' className={`cx-ctrl`} width={320}>
                        <div>
                            {
                                this.renderCtrl(currentModule)
                            }
                        </div>

                    </Sider>

                </Layout>
                { this.state.isLoading ? <Loading /> : null }
                {
                    this.state.showModal
                        ?
                        <Modal
                            title={`设置装修页名称`}
                            visible={true}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            maskClosable={false}
                        >
                            <SetPageTitle
                                title={ this.state.pageTitle }
                                visible={this.state.showModal}
                                wrappedComponentRef={form => {this.formRef = form} }
                                confirmLoading={this.state.modalLoading}
                            ></SetPageTitle>
                        </Modal>
                        :
                        null
                }
            </Layout>
        )
    }

}

export default Form.create({})(withRouter(CustomForm))
