import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Form, DatePicker } from 'antd'
import message from '@/utils/message'
import moment from 'moment'
const FormItem = Form.Item

class AddSpecialDate extends React.Component{
    state = {
        formData:{},
        title:'选择日期',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null,
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let res = {},
            formData = this.state.formData || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.special_date = moment(values.special_date).format('YYYY-MM-DD')
                this.close()
                if(typeof callback == 'function'){
                    callback(values)
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        const data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={400}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`选择日期`}>
                        {
                            getFieldDecorator('special_date',{
                                initialValue: data.special_date ? moment(data.special_date, 'YYYY-MM-DD') : null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择日期'
                                    }
                                ]
                            })(
                                <DatePicker></DatePicker>
                            )
                        }
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddSpecialDate))
