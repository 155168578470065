import React, { Component } from 'react'
import { Modal, Button, Form, Input, Row, Col, Icon } from 'antd';
import { liveGroupSave } from '@/api/livestream'
const FormItem = Form.Item;

class GoodsGroupMadal extends Component {
    state = {
        formData: {},
        title: '新增分组',
        visible: false,
        callback: null,
        confirmLoading:false
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = (callback, row) => {
        if (row) {
            this.setState({
                formData: row,
                visible: true,
                title: '编辑分组',
                callback: callback || null
            })
        } else {
            this.setState({
                visible: true,
                callback: callback || null
            })
        }
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleOk = () => {
        let sendData, {formData,callback} = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                sendData = values;
                if (formData.id) {
                    sendData.id = formData.id
                }
                this.setState({
                    confirmLoading:true
                })

                liveGroupSave(sendData).then(res => {
                    this.setState({
                        confirmLoading:false
                    })

                    if (res.code == 1) {
                        if (typeof callback == 'function') {
                            callback()
                        }
                        this.handleCancel()
                    }
                })


            }
        })
    }

    render() {

        let data = this.state.formData;
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 17
            }
        }

        return (
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <FormItem label="分组名称">
                                {getFieldDecorator('name', {
                                    initialValue: data && data.name || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入分类名称，限6个字符'
                                        }
                                    ]
                                })(<Input placeholder='请输入分类名称，限6个字符' maxLength={6}
                                    suffix={<span>{getFieldValue("name")?.length || 0}/6</span>} />)}
                            </FormItem>
                            <FormItem label="排序号">
                                {getFieldDecorator('sort', {
                                    initialValue: (data && data.sort) ?? 0,
                                })(<Input />)}
                                <span className={`fi-help-text`}>按照从小到大排列，不填写，默认按后添加的在前</span>
                            </FormItem>
                        </Col>
                        <Col span={4}>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default Form.create({})(GoodsGroupMadal)