import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Checkbox } from 'antd'
import { deskAdd, deskEdit } from '@/api/desk'
import { getCourtTypes, addStoreCourt, saveStoreCourt } from "@/api/court";
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class AddCourt extends React.Component{
    state = {
        title:'添加场地号',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                sendData = values;
                sendData.store_id = this.props.match.params.id || ``;
                sendData.is_hide = sendData.is_hide ? 1 : 0
                if(sendData.size_type == 0){
                    sendData.space_size = values.space_size_name
                }else if(sendData.size_type == 1){
                    sendData.space_size = '全场'
                }else if(sendData.size_type == 2){
                    sendData.space_size = '半场'
                }
                this.setState({
                    modalLoading:true
                })
                if(this.props.data && this.props.data.id){
                    sendData.id = this.props.data.id
                    saveStoreCourt(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('编辑成功')
                                    .then(() => {
                                        this.close()
                                        if(typeof callback == 'function'){
                                            callback()
                                        }
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                modalLoading:false
                            })
                        })
                }else {
                    addStoreCourt(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('添加成功')
                                    .then(() => {
                                        this.close()
                                        if(typeof callback == 'function'){
                                            callback()
                                        }
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                modalLoading:false
                            })
                        })
                }


            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        const courtTypes = this.props.courtTypes || []
        const data = this.props.data || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`选择场地类型`}>
                        {
                            getFieldDecorator('space_type_id',{
                                initialValue: data.space_type_id,
                                rules:[
                                    {
                                        required: true,
                                        message: '请选择场地类型'
                                    }
                                ]
                            })(
                                <Select placeholder={'请选择'}>
                                    {
                                        courtTypes.map((item,index) => (
                                            <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem label={'场地规模'}>
                        {getFieldDecorator('size_type', {
                            initialValue: data.size_type || 0,
                            rules: []
                        })(
                            <Radio.Group>
                                <Radio value={1} className={`mgb15 mgt10 block`}>
                                     全场
                                </Radio>
                                <Radio value={2} className={`block mgb15`}>
                                    半场
                                </Radio>
                                <Radio value={0} className={`block `}>
                                    自定义
                                    {
                                        getFieldDecorator('space_size_name',{
                                            initialValue: data.space_size_name || '',

                                        })(<Input className={`mgl10`} style={{ width:'100px' }} />)
                                    }
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'隐藏场地'}>
                        {getFieldDecorator('is_hide', {
                            initialValue: data.is_hide || 0,
                            valuePropName: 'checked'
                        })(
                            <Checkbox>选中, 表示小程序中不显示此场地，仅显示在管理后台</Checkbox>
                        )}
                    </FormItem>

                    {
                        data.id
                            ?
                            <FormItem label={`场地号`}>
                                {
                                    getFieldDecorator('name',{
                                        initialValue: data.name || ``,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入名称'
                                            }
                                        ]
                                    })(
                                        <Input placeholder={`请输入`} />
                                    )
                                }
                            </FormItem>
                            :
                            <FormItem label={`场地号`}>
                                {
                                    getFieldDecorator('space_name',{
                                        initialValue: data.space_name || ``,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入名称'
                                            }
                                        ]
                                    })(
                                        <TextArea placeholder={`请输入`} rows={8}></TextArea>
                                    )
                                }
                                <span className={`fi-help-text`}>输入您需要添加的场地编号，并通过回车进行分隔进行批量添加；数字、字母、汉字不限，不支持特殊字符</span>
                            </FormItem>
                    }

                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddCourt))
