import React from 'react'
import { Form, Table, Pagination, Tag } from 'antd'
import { openCardDetail } from '@/api/member-card'
import { withRouter, Link } from 'react-router-dom'
import Loading from '@/components/Loading'
import '@/assets/css/card/detail.scss'
const FormItem = Form.Item;

class CardDetail extends React.Component{
    state = {
        infoColumns:[
            {
                title:'开卡方式',
                dataIndex:'type',
                render:(text, rowData, index) => {
                    return (
                        <p>
                            { rowData.open_type == 1 ? '免费开卡' : '充值开卡' }
                        </p>
                    )
                }
            },
            {
                title:'有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.effect_time }</p>
                    )
                }
            },
            {
                title:'权益',
                dataIndex:'rights',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.present_coupon == 1
                                    ?
                                    <p>赠优惠券：{rowData.present_coupon_data.length}张</p>
                                    :
                                    <p>无权益</p>
                            }
                        </div>
                    )
                }
            },
            {
                title:'适用门店',
                dataIndex:'stores',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.suit_stores}</p>
                    )
                }
            },
            {
                title:'扣款顺序',
                dataIndex:'sort',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.cost_order_type == 1
                                    ?
                                    <p>先赠送余额，后储值余额</p>
                                    :
                                    rowData.cost_order_type == 2
                                        ?
                                        <p>先储值余额，后赠送余额</p>
                                        :
                                        <p>储值和赠送余额等比例扣减</p>
                            }
                        </div>
                    )
                }
            },
            {
                title:'储值规则',
                dataIndex:'rules',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.recharge_rules.map((item,index) => (
                                    <p key={index} className={`lh25`}>充{ item.recharge_money }元，送{ item.present_money }元+{ item.present_points }积分</p>
                                ))
                            }
                        </div>
                    )
                }
            },
            {
                title:'修改时间',
                dataIndex:'updated_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.updated_at}</p>
                    )
                }
            }
        ],
        columns:[
            {
                title:'开卡人',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.name || ''}</p>
                            <p className={`lh25`}>{ rowData.phone }</p>
                            <p className={`lh25`}>
                                <Tag>普通会员</Tag>
                            </p>
                        </div>
                    )
                }
            },
            {
                title:'开卡/充值金额',
                dataIndex:'price',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>实付{ rowData.pay_ment }元</p>
                            {
                                rowData.present_info
                                    ?
                                    <p>赠送{ rowData.present_info.present_money }元 + { rowData.present_info.present_points }积分</p>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'权益',
                dataIndex:'right',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.present_info
                                    ?
                                    <p>赠优惠券：{ rowData.present_info.present_coupon_num }张</p>
                                    :
                                    <p>无</p>
                            }

                        </div>
                    )
                }
            },
            {
                title:'有效期',
                dataIndex:'effect_time',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.user_card ? rowData.user_card.effect_time : '--'}</p>
                    )
                }
            },
            {
                title:'支付时间',
                dataIndex:'pay_time',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.pay_time}</p>
                            <Link to={`/order/detail/${rowData.order_sn}`} target={`_blank`} className={`fi-link w150`}>订单详情</Link>

                        </div>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.user_card
                                    ?
                                    <Tag color={rowData.user_card.status == 1 ? `green` : rowData.user_card.status == 3 ? `orange` : ''}>{rowData.user_card.status == 1 ? '使用中' : rowData.user_card.status == 2 ? '已作废' : '已过期'}</Tag>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'开卡门店',
                dataIndex:'store',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.store ? rowData.store.name : '--'}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Link to={`/user/member-card/${rowData.user_id}/${rowData.user_card.id}`} target={`_blank`} className={`fi-link w150`}>查看卡详情</Link>
                        </div>
                    )
                }
            }
        ],
        cardData:[],
        tableData:[],
        isLoading:false,
        page:1,
        total:0,
        per_page:10
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        sendData.page = this.state.page;
        this.setState({
            isLoading:true
        })
        openCardDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let card_detail = res.data.card_detail,
                        lists = res.data.card_record.data,
                        per_page = res.data.card_record.per_page,
                        total = res.data.card_record.total;
                    lists.forEach(item => {
                        item.present_info= JSON.parse(item.present_info)
                    })
                    console.log('lists',lists)
                    this.setState({
                        tableData:lists,
                        cardData:[card_detail],
                        total,per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }

    render() {
        return(
            <div className={`shadow-radius`}>
                <div className={`msg-title`}>卡详情</div>
                <div className={`clearfix card-detail mgt10`}>
                    {
                        this.state.cardData && this.state.cardData.length
                            ?
                            <div className="card fl">
                                <div className="title">
                                    <div className="crown"></div>
                                    <div className="type-img"></div>
                                    <div className="txt"></div>
                                </div>
                                <div className="opt-txt"></div>
                                <div className="card-no">{ this.state.cardData[0]['name'] }</div>
                                <div className="balance"></div>
                                <div className="valid">有效期：{ this.state.cardData[0]['effect_time'] }</div>
                                <div className="qr-img"></div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className={`content-body mgt10`}>
                    <Table
                        size={`middle`}
                        columns={this.state.infoColumns}
                        dataSource={this.state.cardData}
                        rowKey={(record, index) => index}
                        pagination={false}
                        style={{ width:'100%'}}
                    ></Table>
                </div>
                <div className={`msg-title mgt10`}>开卡记录</div>
                <div className={`content-body mgt10`}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        rowKey={(record, index) => index}
                        pagination={false}
                        style={{ width:'100%'}}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}></div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>

                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}

export default Form.create({})(withRouter(CardDetail))
