import React from 'react'
import { Form, Input, Button, Select, Table, Pagination, DatePicker, TreeSelect, Tag } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { stockLists } from '@/api/stock'
import { storeLists } from '@/api/store'
import { itemCates } from '@/api/item'
import moment from 'moment';
import Loading from '@/components/Loading'
import { STOCK_IN_TYPES, STOCK_OUT_TYPES } from '@/constants/stock'
const FormItem = Form.Item;
const { RangePicker } = DatePicker
const STOCK_IN_TYPE_IDS = STOCK_IN_TYPES.map(item => { return item.id })
const STOCK_OUT_TYPE_IDS = STOCK_OUT_TYPES.map(item => { return item.id })

class StockDetail extends React.Component{
    state = {
        columns:[
            {
                title:'商品信息',
                dataIndex:'name',
                width:'300px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.cover_img} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <Link to={`/item/add/${rowData.goods_id}`} target={`_blank`} className={`fi-link w150`}>{rowData.name || ''}</Link>
                                <p>{rowData.goods_code || ''}</p>
                            </div>
                        </div>
                    )
                }

            },
            {
                title:'sku编码',
                dataIndex:'goods_sku_code',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.sku_info ? rowData.sku_info.goods_sku_code : '--'}</p>
                    )
                }
            },
            {
                title:'单位',
                dataIndex:'unit_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.unit_name}</p>
                    )
                }
            },
            {
                title:'规格',
                dataIndex:'stock2',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sku_info
                                    ?
                                    <Tag>
                                        {
                                            rowData.sku_info.sku_names.map((item,index) => (
                                                <span className={`lh25`} key={index}>{item}
                                                    {
                                                        index >= rowData.sku_info.sku_names.length - 1
                                                            ?
                                                            null
                                                            :
                                                            '/'
                                                    }
                                                </span>
                                            ))
                                        }
                                    </Tag>
                                    :
                                    '--'

                            }
                        </div>
                    )
                }
            },
            {
                title:'单据号',
                dataIndex:'service_code',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.service_code || `--`}</p>
                    )
                }
            },
            {
                title:'仓库/门店',
                dataIndex:'store_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.store_name || `--`}</p>
                    )
                }
            },
            {
                title:'出入库类型',
                dataIndex:'type_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.type_name}</p>
                        </div>
                    )
                }
            },
            {
                title:'出入库',
                dataIndex:'storage_num',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                STOCK_IN_TYPE_IDS.includes(rowData.type)
                                    ?
                                    <span className={`colorGreen`}>+{rowData.storage_num}</span>
                                    :
                                    null
                            }
                            {
                                STOCK_OUT_TYPE_IDS.includes(rowData.type)
                                    ?
                                    <span className={`colorRed`}>-{rowData.storage_num}</span>
                                    :
                                    null
                            }

                        </div>
                    )
                }
            },
            {
                title:'剩余量',
                dataIndex:'operate_time2',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.surplus_num || 0}</p>
                        </div>
                    )
                }
            },
            {
                title:'变动时间',
                dataIndex:'operate_time',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.operate_time}</p>
                        </div>
                    )
                }
            },
            {
                title:'操作人',
                dataIndex:'operater_name',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p>{rowData.operater_name}</p>
                        </div>
                    )
                }
            }
        ],
        searchForm:{
            store_id:'',
            code:'',
            category_id:'',
            type:'',
            start_time:'',
            end_time:''
        },
        tableData:[],
        cateLists:[],
        storeLists:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,

    }

    componentDidMount() {
        if(this.props.match.params.code){
            let searchForm = this.state.searchForm || ``
            searchForm.code = this.props.match.params.code
            this.setState({
                searchForm
            },() => {
                this.fetchData()
            })
        }else {
            this.fetchData()
        }
        this.fetchStore();
        this.fetchCate()
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchCate = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        itemCates(sendData)
            .then(res => {
                let lists = res.data.list.data,
                    defaults = [ { name:'全部',id:0 } ];
                lists = defaults.concat(lists);
                lists.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })
                this.setState({
                    cateLists:lists
                })

            })
            .finally(res => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        stockLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.record_list.data,
                        total = res.data.record_list.total,
                        per_page = res.data.record_list.per_page,
                        allStockTypes = [ ...STOCK_IN_TYPES,...STOCK_OUT_TYPES];
                    for(var i in lists){
                        for( var j in allStockTypes){
                            if(lists[i]['type'] == allStockTypes[j]['id'] ){
                                lists[i]['type_name'] = allStockTypes[j]['name']
                            }
                        }
                        if(lists[i]['sku_info']){
                            let sku_names = [],
                                sku_info = lists[i]['sku_info']
                            for(var k in sku_info['alias']){
                                sku_names.push(sku_info['alias'][k])
                            }
                            sku_info['sku_names'] = sku_names

                        }
                    }
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                delete searchForm.time;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = (type) => {
        this.props.history.push({
            pathname:`/stock/add/${type || 1}`
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }

    render() {
        let searchForm = this.state.searchForm || {}
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form '}>
                        <div className={`clearfix`}>
                            <FormItem label={'仓库/门店'} className={'fl'}>
                                {
                                    getFieldDecorator('store_id',{
                                        initialValue:0
                                    })(
                                        <Select style={{width:170}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'商品信息'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('code',{
                                        initialValue: searchForm.code || ``
                                    })(
                                        <Input placeholder='商品名/编码/sku编码'/>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'商品分类'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('category_id',{
                                        initialValue: 0
                                    })(
                                        <TreeSelect
                                            treeData={ this.state.cateLists }
                                            treeCheckable={false}
                                            style={{width:'170px'}}
                                            placeholder={'请选择'}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={'出入库类型'} className={'fl'}>
                                {
                                    getFieldDecorator('type',{
                                        initialValue: 0
                                    })(
                                        <Select style={{width:170}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                [ ...STOCK_IN_TYPES,...STOCK_OUT_TYPES].map(item => (
                                                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={'变动日期'} className={'fl mgl20'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem className={'mgl20 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>


                    </Form>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}

export default Form.create({})(withRouter(StockDetail))
