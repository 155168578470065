import $axios from '@/axios/axios.js'


export function GetFxUser(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/detail`,
        method:'post',
        data
    })
}

export function GetFxUserData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/agentData`,
        method:'post',
        data
    })
}

export function FxFrozen(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/frozen`,
        method:'post',
        data
    })
}

export function FxUserLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/userList`,
        method:'post',
        data
    })
}
export function AddFxUser(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/addAgent`,
        method:'post',
        data
    })
}

export function FxUserChange(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/cancel`,
        method:'post',
        data
    })
}

export function ChangeFxCommission(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/changeCommission`,
        method:'post',
        data
    })
}

export function ChangeRefer(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/changeReferee`,
        method:'post',
        data
    })
}

export function setFxConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/setConfig`,
        method:'post',
        data
    })
}

export function getFxConfig(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/getConfig`,
        method:'post',
        data
    })
}

export function getPoster(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/poster`,
        method:'post',
        data
    })
}

export function saveRecruit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/saveRecruit`,
        method:'post',
        data
    })
}

export function saveSharePoster(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/savePromote`,
        method:'post',
        data
    })
}

export function posterStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/enablePoster`,
        method:'post',
        data
    })
}

export function delPoster(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/deletePoster`,
        method:'post',
        data
    })
}



export function fxGoods(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/goods`,
        method:'post',
        data
    })
}

export function addFxGoods(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/addGoods`,
        method:'post',
        data
    })
}

export function setGoodsCommission(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/setGoodsCommission`,
        method:'post',
        data
    })
}

export function removeFxGoods(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/removeGoods`,
        method:'post',
        data
    })
}

export function fxCards(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/card`,
        method:'post',
        data
    })
}

export function addFxCards(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/addCard`,
        method:'post',
        data
    })
}

export function setCardsCommission(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/setCardCommission`,
        method:'post',
        data
    })
}

export function removeFxCards(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/removeCard`,
        method:'post',
        data
    })
}

export function fxMemberLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/lists`,
        method:'post',
        data
    })
}

export function fxAuditLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/auditList`,
        method:'post',
        data
    })
}

export function fxAudit(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/audit`,
        method:'post',
        data
    })
}

export function fxCommissionRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/commissionRecord`,
        method:'post',
        data
    })
}

export function exportCommissionRecord(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/agent/exportCommissionRecord`,
        method:'get',
        responseType:'blob',
        params
    })
}

