import React, { Component } from 'react'
import { Form, Input, Button, Modal, message } from 'antd'
import { studentRefuseApply } from '@/api/educational'
const FormItem = Form.Item;

export default class LeaveRefuse extends Component {
    state={
        confirmLoading:false,
        reason:"",
    }

    handleCancel=()=>{
        this.props.close()
    }

    handleOk=()=>{
       
        let sendData={
            id:this.props.id,
            refuse_reason:this.state.reason
        }

        this.setState({
            confirmLoading:true
        })
        
        studentRefuseApply(sendData).then(res=>{
            if(res.code==1){
              message.success("操作成功")
              setTimeout(() => {
                this.props.success()
                this.handleCancel()
              }, 1500);
            }else{
                this.setState({
                    confirmLoading:false
                })
            }
        })
    }

    handleTextArea = e => {
      
        this.setState({
            reason:e.currentTarget.value
        })
    }


    render() {
        return (
            <Modal
            title="拒绝申请"
            visible={true}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            confirmLoading={this.state.confirmLoading}
        >
            <FormItem label={'拒绝原因'} className="flex" >
                < Input.TextArea rows={4} style={{ width: 400 }} onChange={this.handleTextArea} />
            </FormItem>
        </Modal>
        )
    }
}
