import React from 'react'
import { Form, Input, Button, Table, Pagination, Switch, Modal, Divider, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { storeStatus, storeDelete } from '@/api/store'
import { payTypeLists, savePayType, delPayType, payTypeStatus, payTypeSort } from '@/api/system'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import AddPayType from'./components/add-pay-type'
import LjwPromise from '../ljw/promise'
const FormItem = Form.Item;
class PayType extends React.Component{
    state = {
        columns:[
            {
                title:'记账方式名称',
                dataIndex:'name',
                width:'250px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.icon} alt="" width={40}  />
                            <div className={`mgl10`}>
                                <p>{rowData.name || '--'}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'类型',
                dataIndex:'type_name',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.type_name || '自定义记账'}</p>
                    )
                }
            },
            {
                title:'来源',
                dataIndex:'shop_id',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.is_default == 1 ? '系统默认' : '自定义'}</p>
                    )
                }
            },
            {
                title:'状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <Switch checked={!!rowData.status} onChange={ (e) => { this.handleSwitch(e,rowData,index) } }></Switch>
                        </div>
                    )
                }
            },
            {
                title:'排序',
                dataIndex:'sort',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.sort_edit
                                    ?
                                    <Input
                                        style={{ width:'80px' }}
                                        value={rowData.sort}
                                        onChange={ (e) => { this.handleInputChange(e,rowData,index) } }
                                        onBlur={ (e) => { this.handleField(e,rowData,index) } }
                                        data-name={'sort'}
                                    />
                                    :
                                    <span>{rowData.sort}</span>
                            }
                            <Icon type="edit" className={`mgl5 cursor`} onClick={ (e) => { this.showEdit(e,rowData,index) } } data-name={`sort`} />
                        </div>
                    )
                }
            },
            {
                title:'创建时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <p>{rowData.created_at}</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        rowData.is_default == 1
                            ?
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleView(rowData)}}>查看</Button>
                            </div>
                            :
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>编辑</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleView(rowData)}}>查看</Button>
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleDelete(rowData)}}>删除</Button>
                            </div>
                    )
                }
            },
        ],
        searchForm:{
            name:'',
        },
        tableData:[],
        tableLoading:false,
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        currentRow:null

    }

    componentDidMount() {
        this.fetchData()
        let myPromise = new LjwPromise((resolve,reject) => {})
        console.log(myPromise)
    }
    fetchData = () => {
        let sendData = {}
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            tableLoading:true
        })
        payTypeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.list.data,
                        total = res.data.list.total,
                        per_page = res.data.list.per_page;
                    lists.forEach(item => {
                        item.sort_edit = 0;
                    })
                    this.setState({
                        tableData:lists,
                        total,per_page
                    })

                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    showEdit = (e,rowData,index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '';
        switch (name) {
            case 'price':
                lists[index]['price_edit'] = 1
                break;
            case 'sort':
                lists[index]['sort_edit'] = 1
                break;
            case 'basic_sales':
                lists[index]['sales_edit'] = 1
                break;
        }
        this.setState({
            tableData:lists
        })
    }
    handleInputChange = (e,rowData,index) => {
        let lists = this.state.tableData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value,
            sendData = {};
        lists[index][name] = val;
        this.setState({
            tableData:lists
        })
    }
    handleField = (e,rowData,index) => {
        let val = e.target.value,
            sendData = {};
        if(!val){ return ; }
        sendData.id = rowData.id;
        sendData.sort = val;
        payTypeSort(sendData)
            .then(res => {
                if(res.code == 1){
                    this.fetchData();
                }
            })

    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAdd = () => {
        let sendData = {}
        this.setState({
            currentRow:null
        },() => {
            this.AddPayType.show(res => {
                console.log(res);
                if(res){
                    sendData = res;
                    this.setState({
                        tableLoading:true
                    })
                    savePayType(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('保存成功')
                                    .then(() => {
                                        this.fetchData()
                                    })

                            }
                        })
                        .finally(() => {
                            this.setState({
                                tableLoading:false
                            })
                        })

                }

            })
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })

    }
    handleEdit = rowData => {
        let sendData = {}
        rowData.disable = 0;
        this.setState({
            currentRow:rowData
        },() => {
            this.AddPayType.show(res => {
                if(res){
                    sendData = res;
                    this.setState({
                        tableLoading:true
                    })
                    savePayType(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('保存成功')
                                    .then(() => {
                                        this.fetchData()
                                    })

                            }
                        })
                        .finally(() => {
                            this.setState({
                                tableLoading:false
                            })
                        })

                }

            })

        })
    }
    handleView = (rowData) => {
        rowData.disable = 1;
        this.setState({
            currentRow:rowData
        },() => {
            this.AddPayType.show()

        })

    }
    handleSwitch = ( e,rowData,index ) => {
        let sendData = {},
            tableData = this.state.tableData;
        sendData.id = rowData.id;
        sendData.status = e ? 1 : 0;
        rowData['status'] = e ? 1 : 0;
        tableData[index] = rowData
        this.setState({
            tableData:tableData
        })
        payTypeStatus(sendData)
            .then(res => {
                if(res.code == 1){
                    this.fetchData();
                }
            })
            .finally(() => {
            })

    }


    handleStatus = (rowData,status) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        sendData.status = status;
        Modal.confirm({
            title:`是否将门店状态设置为${rowData.status == 1 ? '歇业' : '营业'}`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                storeStatus(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleDelete = (rowData) => {
        let sendData = {}
        sendData.ids = [rowData.id];
        Modal.confirm({
            title:`是否删除该记账方式？`,
            onOk:() => {
                this.setState({
                    tableLoading:true
                })
                delPayType(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('删除成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })
            }
        })

    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={'shadow-radius'}>
                <AddPayType onRef={ e => { this.AddPayType = e } } data={this.state.currentRow} ></AddPayType>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form clearfix'}>
                        <FormItem label={'记账方式名称'} className={'fl'}>
                            {
                                getFieldDecorator('name',{
                                    initialValue: ''
                                })(
                                    <Input placeholder='请输入名称'/>
                                )
                            }
                        </FormItem>
                        <FormItem className={'mgl20 fl'}>
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>新增记账方式</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}
export default Form.create({})(withRouter(PayType))
