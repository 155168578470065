import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Form, Icon, Row, Table, Tag, Pagination, Button } from 'antd'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import CX from '@/utils/CX'
const FormItem = Form.Item;


class CourtStatusDetail extends React.Component{
    state = {
        tableData:[],
        itemInfo:{},
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    fetchData = () => {
    }
    handleCash = (rowData) => {
        this.props.onCash && this.props.onCash(rowData)
    }
    handleCancel = (rowData) => {
        this.props.onCancel && this.props.onCancel(rowData)

    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }
    handleGuestBook = () => {
        this.props.onAddBook && this.props.onAddBook({})
    }
    render() {
        let lists = this.props.lists || []
        return(
            lists && lists.length
                ?
                <div className={`drawer-cont`}>
                    <div className={`msg-title`}></div>
                    <div className={'pd20'}>
                        <table className="cxtables cxtables-sku">
                            <colgroup>
                                <col width={`18%`} />
                                <col width={`8%`} />
                                <col width={`10%`} />
                                <col width={`10%`} />
                                <col width={`8%`} />
                                <col width={`8%`} />
                            </colgroup>
                            <thead>
                            <tr>
                                <td>预订信息</td>
                                <td>场次价格/数量</td>
                                <td>客户</td>
                                <td>预订金额</td>
                                <td>订单状态</td>
                                <td>操作</td>
                            </tr>
                            </thead>
                        </table>
                        {
                            lists.map((item,index) => (
                                <table className="cxtables cxtables-sku mgt10" key={index}>
                                    <colgroup>
                                        <col width={`18%`} />
                                        <col width={`8%`} />
                                        <col width={`10%`} />
                                        <col width={`10%`} />
                                        <col width={`8%`} />
                                        <col width={`8%`} />
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <td colSpan={6} className={`ftnormal clearfix`}>
                                            <span>商户单号：<span>{ item.order_sn }</span></span>
                                            <Icon type="file-text" className={`mgl5 cursor`} onClick={ () => { this.handleCopy(item.order_sn) } } />
                                            <span className={`mgl10`}>下单时间：<span>{ item.created_at }</span></span>
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={`noPadding bd-right`} colSpan={2}>
                                            <div className={`clearfix`}>
                                                <div className={`flex jusify-start align-center td-item fl`} style={{ width:'75%' }}>
                                                    <div className={`mgl10`}>
                                                        <div className={`lh25`}>时间：{ item.book_info?.book_date }</div>
                                                        {
                                                            item.book_info.book_time && item.book_info.book_time.length
                                                                ?
                                                                item.book_info.book_time.map((child,childKey) => (
                                                                    <div className={`lh25`} key={childKey}>
                                                                        <span>{ childKey == 0 ? '时段：' : <span>&emsp;&emsp;&emsp;</span> }</span>{ child.time_period }
                                                                        {
                                                                            child.price > 0
                                                                                ?
                                                                                <span>价格：&yen;{ child.price }</span>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                ))
                                                                :
                                                                null

                                                        }
                                                        <div className={`lh25`}>场地：{ item.book_info?.space_type_name }{ item.book_info?.space_name }</div>
                                                        <div className={`lh25`}>用途：{ item.book_info?.use_type_name }</div>
                                                    </div>

                                                </div>
                                                <div className={`td-price fr`}>
                                                    <div className={`lh25 txtRight`}></div>
                                                    <div className={`lh25 txtRight`}></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="lh25">{ item.name }</div>
                                            <div className="lh25">{ item.phone }</div>
                                        </td>
                                        <td>
                                            <div>
                                                {
                                                    [0,6,7,8].includes(item.trade_status)
                                                        ?
                                                        <div className="lh25 ft-bold">待支付：￥{ item.pay_ment }</div>
                                                        :
                                                        <div className="lh25 ft-bold">已支付：￥{ item.pay_ment }</div>
                                                }
                                            </div>
                                            <div className="lh25">支付方式：{ item.pay_type_name }</div>
                                            <div className="lh25">支付时间：{ item.pay_time }</div>
                                            <div className="lh25">是否开票：{ item.space_info?.is_need_bill == 1 ? '是' : '否' }</div>
                                        </td>
                                        <td>
                                            <div>
                                                {
                                                    [1,2].includes(item.trade_status)
                                                        ?
                                                        <Tag color="green">{ item.trade_status_name }</Tag>
                                                        :
                                                        null
                                                }
                                                {
                                                    [0,4,6,7,8].includes(item.trade_status)
                                                        ?
                                                        <Tag>{ item.trade_status_name }</Tag>
                                                        :
                                                        null

                                                }
                                                {
                                                    [3,5,9,10,11,12].includes(item.trade_status)
                                                        ?
                                                        <Tag color="orange">{ item.trade_status_name }</Tag>
                                                        :
                                                        null

                                                }
                                            </div>
                                        </td>
                                        <td align="center">
                                            {
                                                [0].includes(item.trade_status)
                                                    ?
                                                    <div className={`btn-row txtCenter mgb5`}>
                                                        <span
                                                            className={`fi-link fz14 w150`}
                                                            onClick={ () => { this.handleCash(item) } }
                                                        >收银结账</span>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={`btn-row mgb5`}>
                                                <Link to={`/order/detail/${item.order_sn}`} target={`_blank`}  className={`fi-link fz14 w150`} >查看详情</Link>
                                            </div>
                                            {
                                                [0,1,2].includes(item.trade_status)
                                                    ?
                                                    <div className={`btn-row mgb5`}>
                                                        <span className={`fi-link fz14 w150`} onClick={ () => { this.handleCancel(item) } }>取消订单</span>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            ))
                        }
                        <div className={`clearfix`}>
                            <Button type={`primary`} className={`mgt10 fr`}  onClick={ this.handleGuestBook }>添加散客预约</Button>
                        </div>
                    </div>

                </div>
                :
                null
        )
    }
}
export default Form.create({})( CourtStatusDetail )
