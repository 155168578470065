import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, DatePicker } from 'antd'
import message from '@/utils/message'
import moment from 'moment'
const FormItem = Form.Item
const { TextArea } = Input

class AddShortLink extends React.Component{
    state = {
        title:'生成推广链接',
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(values.end_type == 1){
                    values.end_time = ''
                }
                values.end_time = values.end_time ? moment(values.end_time).format('YYYY-MM-DD') : ''
                if(typeof callback == 'function'){
                    callback(values)
                }
                this.close()
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        const data = this.props.data || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`链接名称`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入链接名称'
                                    }
                                ]
                            })(
                                <Input placeholder={`请输入`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`失效时间`}>
                        {getFieldDecorator('end_type', {
                            initialValue: data.end_type  || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择失效时间'
                                }
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={1}>永久有效</Radio>
                                <Radio value={2}>
                                    {
                                        getFieldDecorator('end_time',{
                                            initialValue: data.end_time ? moment(data.end_time, 'YYYY-MM-DD') : null,
                                        })(
                                            <DatePicker></DatePicker>
                                        )
                                    }
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={`小程序路径`}>
                        {
                            getFieldDecorator('page',{
                                initialValue: data.page || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入小程序路径'
                                    }
                                ]
                            })(
                                <Input placeholder={`请输入`} />
                            )
                        }
                        <span className={`fi-help-text`}>链接请到’店铺-小程序路径'中复制粘贴，带参数的链接不支持跳转，请填写合法路径</span>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddShortLink))
