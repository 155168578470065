import React, { Component } from 'react'
import { Form, Input, Button} from 'antd';

import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'

const FormItem = Form.Item;

class ShareForm extends Component {
    state = {
        formData: {
            share_poster:"https://img.cxkoo.com/chengxuan/1/2022-07-11/86c1035c92118425be7ef199bb878174.png"
        },
        default_img:"https://img.cxkoo.com/chengxuan/1/2022-07-11/86c1035c92118425be7ef199bb878174.png"
    }

    componentDidMount() {
        this.props.onRef(this)

        if(this.props.is_edit){
            const {formData}=this.state
           
            let data=this.props.activityData
            formData['share_poster']=data.share_poster || ''
            formData['share_img']=data.share_img || ''
            this.setState({formData})
        }
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleResetImg = (keyNmae) => {
        return () => {
            const formData = this.state.formData || {}
            formData[keyNmae] = this.state.default_img
            this.setState({
                formData
            })
        }
    }

    saveData = () => {
        const callBack = this.props.saveData
        if (callBack && typeof callBack == 'function') {
            callBack()
        }
    }

    getFormData = () => {


        return new Promise((reslove, reject) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                this.props.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        const { formData } = this.state
                        return reslove({ ...formData, ...values })
                    } else {
                        return reslove(false)
                    }
                })
            })
        })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const data = this.props.activityData || {}
        const formData= this.state.formData || {}

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>分享信息</div>
                <FormItem label={`分享海报`}>
                    <div className={'clearfix'}>
                        {
                            formData.share_poster
                                ?
                                <PictureCard
                                    onRef={e => { this.PictureCard = e }}
                                    imgs={formData.share_poster}
                                    onDel={e => { this.delImg(e, 'share_poster') }}
                                ></PictureCard>
                                :
                                <ImgAdd
                                    onRef={e => { this.ImgAdd = e }}
                                    onClick={e => { this.chooseImg(e, 'share_poster') }}
                                ></ImgAdd>
                        }
                        <Button type='link' className='mgt60' onClick={this.handleResetImg('share_poster')}>恢复默认</Button>
                    </div>
                    <span className={'fi-help-text'}>建议尺寸：600x800像素，单张不超过2M</span>
                </FormItem>

                <FormItem label={'分享描述'}>
                    {getFieldDecorator('share_title', {
                        initialValue: data.share_title || undefined
                    })(<Input
                        style={{ width: 400 }}
                        placeholder={'请输入分享描述'}
                        maxLength={20}
                        suffix={<span>{getFieldValue("share_title") ? getFieldValue("share_title").length : 0}/20</span>}
                    />)}
                    <span className={'fi-help-text'}>将展示在分享链接中，最多20个字符</span>
                </FormItem>

                <FormItem label={`分享图片`}>
                    <div className={'clearfix'}>
                        {
                            formData.share_img
                                ?
                                <PictureCard
                                    onRef={e => { this.PictureCard = e }}
                                    imgs={formData.share_img}
                                    onDel={e => { this.delImg(e, 'share_img') }}
                                ></PictureCard>
                                :
                                <ImgAdd
                                    onRef={e => { this.ImgAdd = e }}
                                    onClick={e => { this.chooseImg(e, 'share_img') }}
                                ></ImgAdd>
                        }
                    </div>
                    <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M，用于小程序卡片分享</span>
                </FormItem>

                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button onClick={() => this.props.updateTab('2')}>上一步</Button>
                    <Button type='primary' className='mgl20' onClick={this.saveData}>保存</Button>
                </FormItem>

                <div className={'no-colon mgt60 pdb50'}> </div>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
            </Form>
        )
    }
}

export default Form.create({})(ShareForm)
