import React, { Component } from 'react'
import { Form, Input, Radio, Button, Select, Icon, InputNumber, Divider, TreeSelect, Table, Tooltip, DatePicker, message } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import VideoAdd from '@/components/videoAdd/videoAdd'
import Loading from '@/components/Loading'
import CoursePrice from './components/coursePrice'
import Syllabus from './components/syllabus'
import Editor from '@/components/Editor/Editor'
import AddTeacher from './components/addTeacher'
import CourseCategoryModal from './components/courseCategoryModal'
import { CourseCategoryApi, CourseSaveApi, detailApi } from '@/api/course'
import { gradeApi, teacherApi } from '@/api/educational'
import { storeLists } from '@/api/store'


const FormItem = Form.Item

class CourseOfflineAdd extends Component {
    state = {
        isLoading: false,
        formData: {},
        courseLists: [],
        storeLists: [],
        gradeLists: [],
        showEditor: false,
        is_teacher: false,
        columns: [
            {
                title: '老师姓名',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.name}</p>
                    )
                }
            },
            {
                title: '手机号',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.phone}
                        </p>
                    )
                }
            },
            {
                title: '教龄',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.teacher_age || "-"}
                        </p>
                    )
                }
            },
            {
                title: '职位',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            {rowData.position && rowData.position.name || "-"}
                        </p>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>
                            <Button type={'link'} onClick={() => { this.deleTeacher(index) }}>删除</Button>
                        </p>
                    )
                }
            }
        ],
        tableData: [],
        modalForm: {
            title: "新增类目",
            name: "",
            sort: "",
            id: "",
            pid: ""
        },
    }

    componentDidMount() {
        this.fetchCourse()
        this.fetchStore()
        this.fetchGrade()
        if (this.props.match.params.id) {
            this.fetchData()
        } else {
            this.setState({
                showEditor: true
            })
        }
    }
    fetchData = () => {
        let sendData = {};
        sendData.id = this.props.match.params.id;
        this.setState({
            isLoading: true
        })
        detailApi(sendData)
            .then(res => {
                if (res.code == 1) {
                    let detail = res.data.detail
                    detail.feature = JSON.parse(detail.feature)
                    detail.course_outline = JSON.parse(detail.course_outline)
                    this.setState({
                        formData: detail,
                        showEditor: true
                    })
                    this.fetchTeacher(detail.teacher_ids)

                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    fetchCourse = () => {
        this.setState({
            isLoading: true
        })
        CourseCategoryApi({ page: 1, per_page: 100 }).then(res => {

            if (res.code === 1) {
                let { data } = res.data.list

                data.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.disabled = item.children && item.children.length > 0
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })

                this.setState({
                    courseLists: data
                })
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }
    fetchGrade() {
        this.setState({
            isLoading: true
        })
        gradeApi({ page: 1, per_page: 100 })
            .then(res => {

                if (res.code == 1) {
                    let lists = res.data.list.data

                    this.setState({
                        gradeLists: lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    fetchTeacher = (arr) => {
        if (!Array.isArray(arr)) {
            return
        }
        if (arr.length === 0) {
            return
        }
        let sendData = {};
        sendData.page = 1;
        sendData.per_page = 100;
        sendData.status = 1
        this.setState({
            tableLoading: true
        })
        teacherApi(sendData).then(res => {

            if (res.code === 1) {
                let { data } = res.data.list
                let newArr = data.filter(value => arr.includes(`${value.id}`))
                this.setState({
                    tableData: newArr
                })
            }
        }).finally(() => {
            this.setState({
                tableLoading: false
            })
        })
    }

    EditorChange = content => {
        let formData = this.state.formData || {};
        formData.content = content;
        this.setState({
            formData
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        })
    }

    handleSort = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = urls.splice(oldIndex, 1)[0];
        urls.splice(newIndex, 0, targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            switch (name) {
                case 'promote_img':
                case 'share_img':
                    formData[name] = imgs[0];
                    break;
                case 'pic_urls':
                    formData['pic_urls'] = formData['pic_urls'] || [];
                    formData['pic_urls'] = formData['pic_urls'].concat(imgs);
                    if (formData['pic_urls'].length > 10) {
                        formData['pic_urls'] = formData['pic_urls'].slice(0, 10)
                    }
                    break;
            }
            this.setState({
                formData
            })
        })
    }
    handleVideo = (url) => {
        let formData = this.state.formData || {};
        formData.video_url = url
        this.setState({
            formData
        })
    }
    handleVideoDele=()=>{
        let formData = this.state.formData || {};
        formData.video_url = ''
        this.setState({
            formData
        })
    }

    changeDatePicker = (dates, open_time) => {
        let formData = this.state.formData || {};
        formData.open_time = open_time
        this.setState({
            formData
        })
    }



    saveData = () => {
        let formData = this.state.formData || {},
            sendData = {};
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = values
                if (formData.pic_urls && formData.pic_urls.length > 0) {
                    sendData.pic_urls = formData.pic_urls
                } else {
                    return message.error("请选择课程详情图")
                }

                if (values.open_type == 2) {
                    if (!formData.open_time) {
                        return message.error("请选择定时开售时间")
                    }
                }
                sendData.promote_img = formData.promote_img || ''
                sendData.share_img = formData.share_img || ''
                sendData.open_time = formData.open_time || ''
                sendData.video_url = formData.video_url || ''
                sendData.content = formData.content || ''

                let priceData = this.refPrice.getPriceData()
                sendData = { ...sendData, ...priceData }

                if (sendData.charge_type == 3) {
                    if (!sendData.grade_id) {
                        return message.error("请选择班级")
                    }
                }

                let course_outline = this.refSyllabus.state.tabList || []
                sendData.course_outline = JSON.stringify(course_outline)
                sendData.feature = JSON.stringify(values.feature)
                sendData.course_store_ids = values.course_store_ids.join()

                if (this.state.tableData.length > 0) {
                    let arr = this.state.tableData.map(v => v.id)
                    sendData.teacher_ids = arr.join()
                }

                this.setState({
                    isLoading: true
                })
                if (this.props.match.params.id) {
                    sendData.id = this.props.match.params.id
                }
                CourseSaveApi(sendData).then(res => {
                    if (res.code == 1) {
                        message.success(res.msg)
                            .then(() => {
                                this.props.history.push({
                                    pathname: `/course/offline`
                                })
                            })

                    }
                })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })
    }
    goBack = () => {
        this.props.history.goBack()
    }

    openTeacher = () => {
        this.setState({
            is_teacher: true
        })
    }
    closeTeacher = () => {
        this.setState({
            is_teacher: false
        })
    }
    handleTeacher = arr => {
        if (arr.length > 0) {
            let tableData = this.state.tableData
            let newArr = arr.filter(value => !tableData.some(item => item.id == value.id))
            if (tableData.length === 0) {
                tableData = arr
            } else {
                tableData = tableData.concat(newArr)
            }

            tableData = tableData.slice(0, 20)
            this.setState({
                tableData,
                is_teacher: false
            })

        } else {
            this.setState({
                is_teacher: false
            })
        }
    }
    deleTeacher = index => {
        let tableData = this.state.tableData
        tableData.splice(index, 1)
        this.setState({
            tableData
        })
    }


    render() {
        let data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form

        const radioSty = { display: "block", marginBottom: "8px" }
        return (
            <div className={`shadow-radius`}>
                <div className={'mgt10'}>
                    <CourseCategoryModal formData={this.state.modalForm} onRef={e => { this.ccmRef = e }} onSuccess={e => { this.fetchCourse() }} />
                    <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                    <Form {...formItemLayout} className={'model-form ec-input-w300'}>
                        <div className={`msg-title mgb15`}>基本信息</div>
                        <FormItem label={'线下课程名称'}>
                            {getFieldDecorator('name', {
                                initialValue: data.name || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入线下课程名称'
                                    }
                                ]
                            })(<Input
                                style={{ width: 300 }}
                                placeholder={'请输入线下课程名称'}
                                maxLength={60}
                                suffix={<span>{getFieldValue("name").length}/60</span>}
                            />)}
                            <span className={`fi-help-text`}>线下课名称最多 60 个字符</span>
                        </FormItem>

                        <FormItem label={'课程简介'}>
                            {getFieldDecorator('sub_title', {
                                initialValue: data.sub_title || ``
                            })(<Input
                                placeholder={'请输入课程简介'}
                                maxLength={30}
                                style={{ width: 300 }}
                                suffix={<span>{getFieldValue("sub_title").length}/30</span>}
                            />)}
                            <span className={`fi-help-text`}>在课程标题下面展示简介信息，最多 30 个字符</span>
                        </FormItem>
                        <FormItem label={'课程详情图'} required={true}>
                            <div className={'clearfix'}>
                                {
                                    data.pic_urls && data.pic_urls.length
                                        ?
                                        <PictureCard
                                            onRef={e => { this.PictureCard = e }}
                                            imgs={data.pic_urls}
                                            onDel={e => { this.delImg(e, 'pic_urls') }}
                                            onSort={e => { this.handleSort(e, 'pic_urls') }}
                                        ></PictureCard>
                                        :
                                        null
                                }
                                {
                                    data.pic_urls && data.pic_urls.length >= 10
                                        ?
                                        null
                                        :
                                        <ImgAdd
                                            onRef={e => { this.ImgAdd = e }}
                                            onClick={e => { this.chooseImg(e, 'pic_urls') }}
                                        ></ImgAdd>
                                }
                            </div>
                            <span className={'fi-help-text'}> 建议尺寸：750x750像素，单张不超过2M，首图为封面，最多可上传10张（可拖拽图片调整显示顺序）</span>
                        </FormItem>
                        <FormItem label={'课程视频介绍'} >
                            <div className={'clearfix'}>
                                {
                                    data.video_url
                                        ?
                                        <div className="cx-video-wrap">
                                            <video className='video-item' controls src={data.video_url}></video>
                                            <div className="video-dele" onClick={this.handleVideoDele} ></div>
                                        </div>
                                        :
                                        <VideoAdd
                                            handleVideo={this.handleVideo}
                                        ></VideoAdd>
                                }
                            </div>
                            <span className={'fi-help-text'}> 支持本地上传视频，格式为MP4，建议大小不要超过40M</span>
                        </FormItem>

                        <FormItem label={'课程类目'}>
                            <div>
                                {getFieldDecorator('category_id', {
                                    initialValue: data.category_id || ``,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择课程类目'
                                        }
                                    ]
                                })(
                                    <TreeSelect
                                        showSearch
                                        style={{ width: 300 }}
                                        placeholder={'请选择'}
                                        treeData={this.state.courseLists}
                                    />
                                )}

                                <span className='text_link mgl15' onClick={() => { this.ccmRef.show() }}>新增类目</span>

                            </div>
                        </FormItem>

                        <FormItem label={'课程亮点'}>
                            {getFieldDecorator('feature', {
                                initialValue: data.feature || []
                            })(
                                <Select mode="tags" style={{ width: 300 }} placeholder="请输入课程亮点">
                                </Select>
                            )}

                        </FormItem>

                        <FormItem label={'基础销量'}>
                            {getFieldDecorator('basic_sales', {
                                initialValue: data.basic_sales || ``
                            })(<InputNumber
                                style={{ width: 300 }}
                                precision={0}
                                placeholder={'请输入基础销量'}
                            />)}

                        </FormItem>

                        <FormItem label={'排序号'}>
                            {getFieldDecorator('sort', {
                                initialValue: data.sort || 0
                            })(<InputNumber
                                style={{ width: 300 }}
                                precision={0}
                                placeholder={''}
                            />)}
                            <span className={`fi-help-text`}>小程序端及后台呈现的形式，按照数字从小到大排列，不填写，默认按后添加的在前</span>
                        </FormItem>

                        <FormItem label={'分享描述'}>
                            {getFieldDecorator('share_title', {
                                initialValue: data.share_title || ``
                            })(<Input
                                placeholder={'请输入分享描述'}
                                maxLength={20}
                                style={{ width: 300 }}
                                suffix={<span>{getFieldValue("share_title").length}/20</span>}
                            />)}
                            <span className={`fi-help-text`}>将展示在分享链接中，最多 20 个字符</span>
                        </FormItem>

                        <FormItem label={'分享图片'}>
                            <div className={`clearfix`}>
                                {
                                    data.share_img
                                        ?
                                        <PictureCard
                                            onRef={e => { this.PictureCard = e }}
                                            imgs={data.share_img}
                                            onDel={e => { this.delImg(e, 'share_img') }}
                                        ></PictureCard>
                                        :
                                        <ImgAdd
                                            onRef={e => { this.ImgAdd = e }}
                                            onClick={e => { this.chooseImg(e, 'share_img') }}
                                        ></ImgAdd>
                                }
                            </div>
                            <span className={'fi-help-text'}>建议尺寸：500x400像素。单张不超过2M</span>
                        </FormItem>

                        <FormItem label={'加粉推广'} >
                            <div className={`clearfix`}>
                                {
                                    data.promote_img
                                        ?
                                        <PictureCard
                                            onRef={e => { this.PictureCard = e }}
                                            imgs={data.promote_img}
                                            onDel={e => { this.delImg(e, 'promote_img') }}
                                        ></PictureCard>
                                        :
                                        <ImgAdd
                                            onRef={e => { this.ImgAdd = e }}
                                            onClick={e => { this.chooseImg(e, 'promote_img') }}
                                        ></ImgAdd>
                                }
                            </div>
                            <span className={'fi-help-text'}>用户购买后可以自动跳出二维码引导加微信或者进群，图片尺寸150*150px。</span>
                        </FormItem>

                        <FormItem label={'二维码引导文案'}>
                            {getFieldDecorator('qrcode_title', {
                                initialValue: data.qrcode_title || ``
                            })(<Input
                                style={{ width: 300 }}
                                placeholder={'扫码添加课程顾问，咨询相关信息'}
                            />)}

                        </FormItem>

                        <div className={`msg-title mgb15`}>价格及售卖信息</div>

                        <FormItem label={"销售渠道"} >
                            {getFieldDecorator('charge_type', {
                                initialValue: data.charge_type || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择收费方式'
                                    }
                                ]
                            })(
                                <Radio.Group disabled={!!this.props.match.params.id} >
                                    <Radio value={1}>按课时
                                        <Tooltip placement={`right`} title={`适合按上课次数销售的场景`}>
                                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                        </Tooltip>
                                    </Radio>
                                    <Radio value={2}>按时段
                                        <Tooltip placement={`right`} title={`适合月卡、季度卡、年卡销售的场景`}>
                                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                        </Tooltip>

                                    </Radio>
                                    <Radio value={3}>按期
                                        <Tooltip placement={`right`} title={`适合春季班、秋季班、暑假班、寒假班等按期销售的场景`}>
                                            <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                        </Tooltip>
                                    </Radio>
                                </Radio.Group>
                            )}
                        </FormItem>

                        <CoursePrice change_type={getFieldValue("charge_type") || 1} refPrice={e => this.refPrice = e} id={this.props.match.params.id} gradeLists={this.state.gradeLists} data={data} />

                        <FormItem label={"售课时间"}>
                            {getFieldDecorator('open_type', {
                                initialValue: data.charge_type || 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择收费方式'
                                    }
                                ]
                            })(
                                <Radio.Group  >

                                    <Radio value={1} style={radioSty}>立即开售</Radio>
                                    <Radio value={2} style={radioSty}>
                                        <span className='mgr20'>定时开售，设置开售时间</span>
                                        <DatePicker
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            placeholder="请选择时间"
                                            onChange={this.changeDatePicker}
                                        />
                                    </Radio>
                                    <Radio value={3} style={radioSty}>暂不开售，下架状态</Radio>


                                </Radio.Group>
                            )}
                        </FormItem>

                        <div className={`msg-title mgb15`}>课程详情</div>
                        <FormItem label={'上课地点'}>
                            {getFieldDecorator('course_store_ids', {
                                initialValue: data.course_store_ids || [],
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择上课地点'
                                    }
                                ]
                            })(
                                <Select
                                    style={{ width: 300 }}
                                    placeholder="请选择"
                                    mode="multiple"
                                    showArrow
                                >
                                    {
                                        this.state.storeLists.map(store => (
                                            <Select.Option value={`${store.id}`} key={store.id}>{store.name}</Select.Option>

                                        ))
                                    }
                                </Select>
                            )}
                            <Link to={'/store/lists'} target={`_blank`} >
                                <span className='text_link mgl15'>新增</span>
                            </Link>
                            <Divider type={`vertical`}></Divider>
                            <span className='text_link' onClick={() => { this.fetchStore() }}>刷新</span>
                        </FormItem>

                        <FormItem label={'上课老师'}>
                            < Button type={'primary'} onClick={this.openTeacher} >选择老师</Button>
                            {
                                this.state.tableData.length > 0 ?
                                    <Table
                                        size={`middle`}
                                        columns={this.state.columns}
                                        dataSource={this.state.tableData}
                                        rowKey={record => record.id}
                                        pagination={false}
                                        className="mgt10"
                                    ></Table>
                                    : null
                            }
                            <span className={`fi-help-text`}>最多选择20个老师；课程关联老师后，会显示在课程详情页</span>
                        </FormItem>

                        <Syllabus refSyllabus={e => this.refSyllabus = e} course_outline={data.course_outline || []} id={this.props.match.params.id} />

                        <FormItem label={'课程详情'}>
                            {
                                this.state.showEditor
                                    ?
                                    <Editor id={'text'} value={data.content} callback={content => this.EditorChange(content)}></Editor>
                                    :
                                    null
                            }
                        </FormItem>

                        <FormItem label={` `} colon={false}>
                            <Button type={'primary'} onClick={this.saveData}>保存</Button>
                            <Button className='mgl15' onClick={this.goBack} >取消</Button>
                        </FormItem>

                    </Form>
                </div>
                {this.state.is_teacher ? <AddTeacher close={this.closeTeacher} success={this.handleTeacher} /> : null}
                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }
}

export default Form.create({})(withRouter(CourseOfflineAdd))