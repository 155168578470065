import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, InputNumber, Radio, Checkbox, Tooltip, Tag, TreeSelect, Drawer, Icon } from 'antd';
import AreaPicker from '@/components/AreaPicker/AreaPicker'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

class BaseForm extends Component {
    state = {
        selectItems: [],
        formData: {
            exchange_points: 1,
            invite_num: 1,
            invite_prize_num: 1,
            invite_limit_num: 1,
            order_money: 0,
            order_limit_num: 1,
            join_day_user_num: 1,
            join_user_num: 1,
            prize_day_user_num: 1,
            prize_user_num: 1,
            order_page: ''
        },

    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillReceiveProps(nextProps) {

        if ((nextProps.activityData != this.props.activityData) && this.props.is_edit) {

            let data = nextProps.activityData, selectItems = this.state.selectItems


            selectItems = data.join_area || [];

            let {
                exchange_points,
                invite_num,
                invite_prize_num,
                invite_limit_num,
                order_money,
                order_limit_num,
                join_day_user_num,
                join_user_num,
                prize_day_user_num,
                prize_user_num,
                order_page
            } = data;

            let formData = {
                exchange_points,
                invite_num,
                invite_prize_num,
                invite_limit_num,
                order_money,
                order_limit_num,
                join_day_user_num,
                join_user_num,
                prize_day_user_num,
                prize_user_num,
                order_page
            }

            this.setState({
                formData,
                selectItems
            })
        }
    }

    addFreeArea = () => {
        let selectItems = this.state.selectItems

        this.AreaPicker.show({
            selectItems: selectItems,
            is_default_select: true,
            callback: (res) => {

                if (res.length) {
                    let selectItems = res
                    this.setState({
                        selectItems
                    })
                }
            }
        })
    }

    handleNumberChange = name => {
        return val => {
            if (typeof val === 'number') {

                let formData = this.state.formData
                formData[name] = val
                this.setState({
                    formData
                })
            }
        }
    }

    handleExtraPrize = arr => {
        this.props.updateData('extra_prize', arr)
    }

    changeLink = (e, item, index) => {
        let formData = this.state.formData
        this.PopSelectLink.show(res => {
            if (res) {
                formData['order_page'] = res['link'];
                this.setState({
                    formData
                })
            }
        })
    }

    handleTimeChange = (time, timeStr) => {
        this.props.updateData('start_time', timeStr[0]);
        this.props.updateData('end_time', timeStr[1])
    }

    getFormData = () => {
        return new Promise((reslove, reject) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const { formData, selectItems } = this.state
                    values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss') : ''
                    values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss') : '';

                    delete values.time;


                    if (values.join_area_type == 2) {
                        values.join_area = selectItems
                    }

                    if (values.extra_prize) {
                        values.points_switch = values.extra_prize.includes(1) ? 1 : 0;
                        values.share_switch = values.extra_prize.includes(2) ? 1 : 0;
                        values.order_switch = values.extra_prize.includes(3) ? 1 : 0;

                        delete values.extra_prize;
                    }

                    if (values.play_limit) {
                        values.join_day_user_switch = values.play_limit.includes(1) ? 1 : 0;
                        values.join_user_switch = values.play_limit.includes(2) ? 1 : 0;

                        delete values.play_limit;
                    }

                    if (values.gain_limit) {
                        values.prize_day_user_switch = values.gain_limit.includes(1) ? 1 : 0;
                        values.prize_user_switch = values.gain_limit.includes(2) ? 1 : 0;

                        delete values.gain_limit;
                    }

                    return reslove({ ...values, ...formData })
                } else {
                    return reslove(false)
                }
            })
        })
    }


    render() {

        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }

        const { getFieldDecorator, getFieldValue } = this.props.form

        const data = this.props.activityData || {}
        const formData = this.state.formData || {}

        

        return (
            <Form {...formItemLayout} className={'model-form '}>
                <div className={`msg-title mgb15`}>基本信息</div>
                <FormItem label={'活动名称'}>
                    {getFieldDecorator('name', {
                        initialValue: data.name || undefined,
                        rules: [
                            {
                                required: true,
                                message: '请输入活动名称'
                            }
                        ]
                    })(<Input
                        style={{ width: 400 }}
                        placeholder={'请输入活动名称'}
                        maxLength={20}
                        suffix={<span>{getFieldValue("name") ? getFieldValue("name").length : 0}/20</span>}
                    />)}
                </FormItem>

                <FormItem label={'活动时间'}>
                    {getFieldDecorator('time', {
                        initialValue: data.time || null,
                        rules: [
                            {
                                required: true,
                                message: '请选择活动时间'
                            }
                        ]
                    })(
                        <RangePicker
                            style={{ width: 400 }}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder={['开始时间', '结束时间']}
                            onChange={this.handleTimeChange}
                            disabled={data.is_disabled}
                        />
                    )}
                </FormItem>

                <div className={`msg-title mgb15`}>活动设置</div>

                <FormItem label={"参与人限制"} >
                    {getFieldDecorator('join_user', {
                        initialValue: data.join_user || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择参与人'
                            }
                        ]
                    })(
                        <Radio.Group   >
                            <Radio value={1}>所有客户 </Radio>
                            <Radio value={2}>仅限新客户
                                <Tooltip placement={`right`} title={`新客户指店铺内没有订单的客户，或仅拥有以下类型订单的客户：全额退款订单、已关闭的待付款订单`}>
                                    <Icon type="question-circle" className={`icon-question-balance`} theme="filled" />
                                </Tooltip>
                            </Radio>

                        </Radio.Group>
                    )}
                </FormItem>

                <FormItem label={'参与条件'} >
                    初次参与抽奖消耗
                    {getFieldDecorator('cost_point', {
                        initialValue: data.cost_point || 0,
                        rules: [
                            {
                                required: true,
                                message: '该项是必填'
                            }
                        ]
                    })(
                        <InputNumber
                            min={0}
                            precision={0}
                            placeholder={''}
                            className='mgl20 mgr20'
                            disabled={data.is_disabled}
                        />
                    )}
                    积分，参与抽奖
                    {getFieldDecorator('join_num', {
                        initialValue: data.join_num || 1,
                        rules: [
                            {
                                required: true,
                                message: '该项是必填'
                            }
                        ]
                    })(
                        <InputNumber
                            min={0}
                            precision={0}
                            placeholder={''}
                            className='mgl20 mgr20'
                            disabled={data.is_disabled}
                        />
                    )}
                    次
                    <span className={'fi-help-text'}> 填0时，则表示无需消耗积分即可参与1次抽奖</span>
                </FormItem>

                <FormItem label={"额外抽奖机会"} >
                    {getFieldDecorator('extra_prize', {
                        initialValue: data.extra_prize || [1],
                    })(
                        <Checkbox.Group onChange={this.handleExtraPrize}  disabled={data.is_disabled} >
                            <div>
                                <Checkbox value={1}>积分抽奖， 每消耗 <InputNumber onChange={this.handleNumberChange("exchange_points")} value={formData.exchange_points} style={{ width: 80 }} min={0} precision={0}  disabled={data.is_disabled} /> 积分，兑换1次抽奖机会 </Checkbox>
                            </div>
                            <div className='mgt10'>
                                <Checkbox value={2}>分享获得，每邀请 <InputNumber onChange={this.handleNumberChange("invite_num")} value={formData.invite_num} style={{ width: 80 }} min={0} precision={0}  disabled={data.is_disabled} /> 位好友，可获得  <InputNumber onChange={this.handleNumberChange("invite_prize_num")} value={formData.invite_prize_num} style={{ width: 80 }} min={0} precision={0}  disabled={data.is_disabled} /> 次抽奖机会，最多可获得 <InputNumber onChange={this.handleNumberChange("invite_limit_num")} value={formData.invite_limit_num} style={{ width: 80 }} min={0} precision={0}  disabled={data.is_disabled} /> 次</Checkbox>
                            </div>
                            <div className='mgt10'>
                                <Checkbox value={3}>下单获得，订单最低门槛为 <InputNumber onChange={this.handleNumberChange("order_money")} value={formData.order_money} style={{ width: 80 }} min={0} precision={2}  disabled={data.is_disabled} /> 元，每天最多可获得 <InputNumber onChange={this.handleNumberChange("order_limit_num")} value={formData.order_limit_num} style={{ width: 80 }} min={0} precision={0}  disabled={data.is_disabled} /> 次</Checkbox>
                            </div>
                        </Checkbox.Group>
                    )}
                </FormItem>

                {

                    getFieldValue("extra_prize").includes(3) ?
                        <FormItem label={`下单引导页面`} >
                            <Input type={`text`} style={{ width: 400 }} value={formData.order_page} onClick={(e) => { this.changeLink(e) }} placeholder={`请选择`}  disabled={data.is_disabled} />
                        </FormItem>
                        : null
                }



                <FormItem label={"抽奖限制"} >
                    {getFieldDecorator('join_limit', {
                        initialValue: data.join_limit || 1,
                    })(
                        <Radio.Group  >
                            <Radio value={1}>不限制 </Radio>
                            <Radio value={2}>限制 </Radio>
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("join_limit") == 2 ?
                        <FormItem label={' '} className={'no-colon'}>
                            {getFieldDecorator('play_limit', {
                                initialValue: data.play_limit || [],
                            })(
                                <Checkbox.Group >
                                    <div>
                                        <Checkbox value={1}>每人每天最多可抽奖 <InputNumber onChange={this.handleNumberChange("join_day_user_num")} value={formData.join_day_user_num} style={{ width: 80 }} min={1} precision={0} /> 次 </Checkbox>
                                    </div>
                                    <div className='mgt10'>
                                        <Checkbox value={2}>每人最多可在该活动抽奖 <InputNumber onChange={this.handleNumberChange("join_user_num")} value={formData.join_user_num} style={{ width: 80 }} min={1} precision={0} /> 次 </Checkbox>
                                    </div>

                                </Checkbox.Group>
                            )}

                        </FormItem>
                        : null
                }

                <FormItem label={"中奖限制"} >
                    {getFieldDecorator('prize_limit', {
                        initialValue: data.prize_limit || 1,
                    })(
                        <Radio.Group  >
                            <Radio value={1}>不限制 </Radio>
                            <Radio value={2}>限制 </Radio>
                        </Radio.Group>
                    )}
                </FormItem>

                {
                    getFieldValue("prize_limit") == 2 ?
                        <FormItem label={' '} className={'no-colon'}>
                            {getFieldDecorator('gain_limit', {
                                initialValue: data.gain_limit || [],
                            })(
                                <Checkbox.Group >
                                    <div>
                                        <Checkbox value={1}>每人每天最多可中奖 <InputNumber onChange={this.handleNumberChange("prize_day_user_num")} value={formData.prize_day_user_num} style={{ width: 80 }} min={1} precision={0} /> 次 </Checkbox>
                                    </div>
                                    <div className='mgt10'>
                                        <Checkbox value={2}>每人最多可在该活动中奖 <InputNumber onChange={this.handleNumberChange("prize_user_num")} value={formData.prize_user_num} style={{ width: 80 }} min={1} precision={0} /> 次 </Checkbox>
                                    </div>

                                </Checkbox.Group>
                            )}

                        </FormItem>
                        : null
                }

                <FormItem label={"是否授权"} >
                    {getFieldDecorator('is_authorize', {
                        initialValue: data.is_authorize || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择是否授权'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}>授权 </Radio>
                            <Radio value={2}>不授权 </Radio>
                        </Radio.Group>
                    )}
                    <span className={'fi-help-text'}> 授权时，则发起人及点赞人都需要授权手机号才可参与活动。</span>
                </FormItem>

                <FormItem label={"可参与地区"} >
                    {getFieldDecorator('join_area_type', {
                        initialValue: data.join_area_type || 1,
                        rules: [
                            {
                                required: true,
                                message: '请选择可参与地区'
                            }
                        ]
                    })(
                        <Radio.Group  >
                            <Radio value={1}>所有地区 </Radio>
                            <Radio value={2}>部分地区</Radio>
                            {getFieldValue("join_area_type") == 2 ? <span className='text_link' onClick={this.addFreeArea}>{this.state.selectItems.length > 0 ? '查看设置' : '请选择地区'}</span> : null}
                        </Radio.Group>
                    )}
                </FormItem>


                <FormItem label={' '} className={'no-colon mgb40'}>
                    <Button type='primary' onClick={() => this.props.updateTab('2')}>下一步</Button>
                </FormItem>

                <div className={'no-colon mgt60 pdb50'}> </div>

                <AreaPicker onRef={e => {
                    this.AreaPicker = e
                }}></AreaPicker>
                <PopSelectLink onRef={e => { this.PopSelectLink = e }}></PopSelectLink>

            </Form>
        )
    }
}

export default Form.create({})(BaseForm)
