import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Select, Button, Input, Switch, Radio, Checkbox, Row, Col, Icon, TimePicker } from 'antd'
import { getSetting, saveSetting } from '@/api/court'
import { storeLists } from '@/api/store'
import { uploadFile } from '@/api/file'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import moment from 'moment'
import Cookie from 'js-cookie'
import '@/assets/css/ktv/index.scss'
const FormItem = Form.Item
const { TextArea } = Input

const initData = {
    can_book:0,
    booking_auto_accept:1,
    pre_booking:1,
    pre_booking_time:2,
    refund_booking:1,
    refund_booking_time:2,
    booking_time_type:1,
    total_book_check:0,
    guest_book_check:0,
    booking_tel:'',
    insurance_company:'',
    insurance_fee:'',
    insurance_desc:'',
    agreement_file:'',
    agreement_file_name:'',
    invite_check:0,
    booking_rules:[
        { msg:'预订成功后，如您有时间调整等需求，请联系客服' },
    ]
}

class CourtBookSetting extends React.Component{
    state = {
        formData:{},
        allStore:[],
        time_array:[],
        currentStore:'',
        isLoading:false,
        uniqueId:""+new Date().getMilliseconds() + Math.round(Math.random()*100),
    }
    componentDidMount() {
        this.fetchStore()
            .then(() => {
                this.fetchData()
            })
        this.setTime()
    }
    fetchData = () => {
        let sendData = {},
            currentStore = this.state.currentStore || {};
        sendData.store_id = currentStore.id || ''
        this.setState({
            isLoading:true
        })
        getSetting(sendData)
            .then(res => {
                if(res.code == 1){
                    let config = res.data.config
                    console.log(config.booking_rules)
                    if(!config.booking_rules || config.booking_rules.length < 1){
                        config.booking_rules = [{ msg:'' }]
                    }
                    if(config){
                        this.setState({
                            formData:config
                        })
                    }else {
                        this.setState({
                            formData:initData
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            isLoading:true
        })
        return new Promise((resolve, reject) => {
            storeLists(sendData)
                .then(res => {
                    if(res.code == 1){
                        let lists = res.data.store.data || [],
                            currentStore = this.state.currentStore || '';
                        if(!currentStore){
                            currentStore = lists[0]
                        }
                        this.setState({
                            allStore:lists,
                            currentStore
                        },() => {
                            resolve()
                        })
                    }else {
                        reject()
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })

    }
    setTime = () => {
        let time_array = []
        for(var i  = 1; i <= 24; i++){
            time_array.push({ name:`${i}小时`,id:i })
        }
        this.setState({ time_array })
    }
    addRules = (index) => {
        let formData = this.state.formData || {},
            newData = { msg:''},
            booking_rules = formData.booking_rules || []
        if(booking_rules.length >= 10){
            message.error('最多设置10条')
        }
        booking_rules.push(newData);
        this.setState({
            formData
        })


    }
    delRules = (index) => {
        let formData = this.state.formData || {},
            booking_rules = formData.booking_rules || []
        booking_rules.splice(index,1);
        this.setState({
            formData
        })
    }
    handleCheckChange = (e,keyName) => {
        let val = e.target.checked ? 1 : 0,
            formData = this.state.formData || {};
        formData[keyName] = val;
        this.setState({
            formData
        })
    }
    handleStoreChange = (val) => {
        let currentStore = '',
            allStore = this.state.allStore || [];
        if(val){
            allStore.map(item => {
                if(item.id == val){
                    currentStore = item;
                    this.setState({
                        currentStore
                    },() => {
                        this.fetchData();
                    })
                }
            })
        }

    }
    uploadChange = e => {
        let files  = document.getElementById("file"+this.state.uniqueId).files,
            formData = this.state.formData || {}
        files = Array.from(files);
        let fd = new FormData()
        files.forEach(item => {
            fd.append('Filedata[]',item);
        })
        fd.append('cate_id',this.state.category_id)
        fd.append('api_token',Cookie.get('token') || ``)
        this.setState({
            isLoading:true
        })
        uploadFile(fd)
            .then(res => {
                if(res.code == 1){
                    formData.agreement_file = res.data.url;
                    formData.agreement_file_name = files[0]['name'];
                    this.setState({
                        formData
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {},
            currentStore = this.state.currentStore || {},
            config = {};
        sendData.store_id = currentStore.id || ''
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                values.booking_start_time = values.booking_start_time ? moment(values.booking_start_time).format('HH:mm:ss') : '';
                values.booking_end_time = values.booking_end_time ? moment(values.booking_end_time).format('HH:mm:ss') : '';
                config = values;
                config.pre_booking = formData.pre_booking;
                config.refund_booking = formData.refund_booking;
                config.total_book_check = formData.total_book_check;
                config.guest_book_check = formData.guest_book_check;
                config.invite_check = formData.invite_check;
                config.agreement_file = formData.agreement_file;
                config.agreement_file_name = formData.agreement_file_name;
                for(var x in config){
                    if(typeof config[x] === 'boolean'){
                        config[x] = config[x] ? 1 : 0
                    }
                }
                if(!config.pre_booking){
                    config.pre_booking_time = ''
                }
                if(!config.refund_booking){
                    config.refund_booking_time = ''
                }
                sendData.config = config;
                this.setState({
                    isLoading:true
                })
                saveSetting(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })


            }
        })
    }
    render() {
        let data = this.state.formData || {},
            allStore = this.state.allStore || [],
            currentStore = this.state.currentStore || {};
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 2
            },
            wrapperCol: {
                span: 7
            }
        }
        return(

                <div className={`shadow-radius`}>
                    {
                        Object.keys(data).length
                            ?
                            <Form className={`model-form `} { ...formItemLayout }>
                                <div className={'content-head mgb15'}>
                                    <FormItem label={'选择门店'} >
                                        <Select style={{width:170}} placeholder={'请选择'} value={ currentStore.id } onChange={ this.handleStoreChange }>
                                            {
                                                allStore.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                        <span className={`fi-help-text`}>选择门店进行单独设置，设置后保存</span>
                                    </FormItem>
                                    <div className={`clearfix`}></div>
                                </div>
                                <div className={`msg-title mgb10`}>小程序端预订功能</div>
                                <FormItem label={'预订开关'}>
                                    {getFieldDecorator('can_book', {
                                        initialValue: data.can_book == undefined ? true : !!data.can_book,
                                        valuePropName:'checked'
                                    })(
                                        <Switch></Switch>
                                    )}
                                    <span className={`fi-help-text`}>
                                    开启后，用户可在小程序端进行预订
                                </span>
                                </FormItem>
                                <div className={`msg-title mgb10`}>接受预订时段</div>
                                <FormItem label={'预约时间'}>
                                    {getFieldDecorator('booking_time_type', {
                                        initialValue: data.booking_time_type || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择接单方式'
                                            }
                                        ]
                                    })(
                                        <Radio.Group>
                                            <Radio value={1} className={`mgb15 mgt10 block`}>
                                                按营业时间
                                            </Radio>
                                            <Radio value={2} className={`block `}>
                                                {
                                                    getFieldDecorator('booking_start_time',{
                                                        initialValue: data.booking_start_time ? moment(data.booking_start_time, 'HH:mm:ss') : null,
                                                        rules: []
                                                    })(
                                                        <TimePicker
                                                            minuteStep={60}
                                                            secondStep={60}
                                                            className={'mgr5'} >
                                                        </TimePicker>
                                                    )
                                                }
                                                ~
                                                {
                                                    getFieldDecorator('booking_end_time',{
                                                        initialValue: data.booking_end_time ? moment(data.booking_end_time, 'HH:mm:ss') : null,
                                                        rules: []
                                                    })(
                                                        <TimePicker
                                                            minuteStep={60}
                                                            secondStep={60}
                                                            className={'mgl5'} >
                                                        </TimePicker>
                                                    )
                                                }
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>

                                <div className={`msg-title mgb10`}>预订接单方式</div>
                                <FormItem label={'接单方式'}>
                                    {getFieldDecorator('booking_auto_accept', {
                                        initialValue: data.booking_auto_accept || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择接单方式'
                                            }
                                        ]
                                    })(
                                        <Radio.Group >
                                            <Radio value={1}>自动接单</Radio>
                                            <Radio value={2}>手动接单</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                <div className={`msg-title mgb10`}>预订规则</div>
                                <FormItem label={<span><Checkbox className={`mgr5`} checked={ data.pre_booking } onChange={ (e) => { this.handleCheckChange(e,'pre_booking') } }></Checkbox>提前预订时间</span>}>
                                    <div className={`lh40`}>
                                        顾客需要提前
                                        {getFieldDecorator('pre_booking_time', {
                                            initialValue: data.pre_booking_time || 2,
                                        })(
                                            <Select  style={{width:'100px'}} placeholder="请选择" className={`mgl5 mgr5`}>
                                                {
                                                    this.state.time_array.map((item,index) => (
                                                        <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>

                                                    ))
                                                }
                                            </Select>
                                        )}
                                        预订
                                    </div>
                                    <span className={`fi-help-text`}>勾选后，用户在小程序端预订，需要在当前时间的X小时前预订；不勾选，表示随时可订</span>
                                </FormItem>
                                <FormItem label={<span><Checkbox className={`mgr5`} checked={ data.refund_booking } onChange={ (e) => { this.handleCheckChange(e,'refund_booking') } }></Checkbox>退订时间</span>}>
                                    <div className={`lh40`}>
                                        顾客需要提前
                                        {getFieldDecorator('refund_booking_time', {
                                            initialValue: data.refund_booking_time || 2,
                                        })(
                                            <Select  style={{width:'100px'}} placeholder="请选择" className={`mgl5 mgr5`}>
                                                {
                                                    this.state.time_array.map((item,index) => (
                                                        <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>

                                                    ))
                                                }
                                            </Select>
                                        )}
                                        退订
                                    </div>
                                    <span className={`fi-help-text`}>勾选后，用户需要在已租场地前N小时在小程序端提交退订申请，过时则无申请按钮；不勾选，表示随时可退</span>
                                </FormItem>
                                <FormItem label={'接收消息电话'}>
                                    {getFieldDecorator('booking_tel', {
                                        initialValue: data.booking_tel || ``,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入电话号码'
                                            }
                                        ]
                                    })(<Input placeholder={'请输入电话号码'}  />)}
                                    <span className={`fi-help-text`}>
                                    若为手机号，请输入11位手机号；若为座机号，请输入区号-电话号码（0571-8888888），不支持分机号；
                                    通知类型，请到<span className={`fi-link`}>消息设置</span>内进行勾选，如使用短信/电话通知，需先充值。
                                </span>
                                </FormItem>
                                <div className={`msg-title mgb10`}>发票及保险</div>
                                <FormItem label={<span>整租预订</span>} >
                                    <div className={`lh40`}>
                                        <Checkbox className={`mgr5`} checked={ data.total_book_check } onChange={ (e) => { this.handleCheckChange(e,'total_book_check') } }></Checkbox>
                                        整租预订时，客户可在小程序端填写开票信息
                                    </div>
                                    <span className={`fi-help-text`}>勾选后，用户需要开票可在下单时填写；不勾选，则不展示开票选项</span>
                                </FormItem>
                                <FormItem label={<span>散客预订</span>}>
                                    <div className={`lh40`}>
                                        <Checkbox className={`mgr5`} checked={ data.guest_book_check } onChange={ (e) => { this.handleCheckChange(e,'guest_book_check') } }></Checkbox>
                                        散客预订时，客户可在小程序端填写信息购买保险
                                    </div>
                                    <span className={`fi-help-text`}>勾选后，用户需要买保险时可填写信息；不勾选，则不展示保险选项</span>
                                </FormItem>
                                <FormItem label={'保险公司'}>
                                    {getFieldDecorator('insurance_company', {
                                        initialValue: data.insurance_company || ``,
                                    })(<Input placeholder={'请输入'} style={{ width:'300px' }}  />)}
                                </FormItem>
                                <FormItem label={'保险金额'}>
                                    {getFieldDecorator('insurance_fee', {
                                        initialValue: data.insurance_fee || ``,
                                    })(<Input placeholder={'请输入'} style={{ width:'300px' }}  addonAfter={`元`} />)}
                                </FormItem>
                                <FormItem label={'保险协议'}>
                                    {getFieldDecorator('insurance_desc', {
                                        initialValue: data && data.insurance_desc,
                                    })(<TextArea rows={6} placeholder={'运动员保险保障运动型损伤，建议购买。请填写购买保险需要的真实信息，报险电话400XXXXXXXXX。购买前请仔细阅读保险详情付款后不可退款。'} />)}
                                    <div>
                                         <span style={{'position':'relative'}}>
                                            <Button type={`default`} className={`mgl5`}>添加保险详情(pdf)</Button>
                                            <Input type="file" id={`file${this.state.uniqueId}`}  className={'pdf-file-input'} multiple="multiple" accept="application/pdf" onChange={this.uploadChange} ></Input>
                                        </span>
                                        {
                                            data.agreement_file && data.agreement_file_name
                                                ?
                                                <span className={`fi-help-text`}>已选择{data.agreement_file_name}</span>
                                                :
                                                <span className={`fi-help-text`}>未选择任何文件</span>
                                        }
                                    </div>
                                </FormItem>
                                <FormItem label={` `} colon={false}>
                                    <div className={`lh40`}>
                                        <Checkbox className={`mgr5`} checked={ data.invite_check } onChange={ (e) => { this.handleCheckChange(e,'invite_check') } }></Checkbox>
                                        下单推荐人，预订时客户可在小程序端填写推荐人
                                    </div>
                                    <span className={`fi-help-text`}>勾选后，用户预订时可填写推荐人信息，用于球场统计员工业绩使用</span>
                                </FormItem>


                                <div className={`msg-title mgb10`}>预订须知</div>
                                <FormItem label={'预订须知'}>
                                    <div>
                                        {
                                            data.booking_rules && data.booking_rules.map((item,index) => (
                                                <Row key={index}>
                                                    <Col span={20}>
                                                        {
                                                            getFieldDecorator(`booking_rules[${index}].msg`,{
                                                                initialValue: item.msg
                                                            })(
                                                                <Input placeholder={'请输入须知'} maxLength={30} />
                                                            )
                                                        }
                                                    </Col>
                                                    <Col span={4}>
                                                        <Icon type="plus-circle" className={'mgl10 fz18 book-icon'} onClick={() => { this.addRules(index) }} />
                                                        {
                                                            index > 0
                                                                ?
                                                                <Icon type="minus-circle" className={'mgl10 fz18 book-icon'} onClick={() => { this.delRules(index) }} />
                                                                :
                                                                ''
                                                        }
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </div>

                                </FormItem>
                                <FormItem label={` `} colon={false}>
                                    <Button type={`primary`} onClick={ this.saveData }>立即保存</Button>
                                </FormItem>
                            </Form>
                            :
                            null
                    }
                    { this.state.isLoading ? <Loading /> : null }
                </div>

        )
    }
}
export default Form.create({})(withRouter(CourtBookSetting))
