import React, { Component } from 'react'
import { Form, Input, Button, Select, DatePicker, Row, Col, Tabs, Table, Pagination, Modal, Tag, TreeSelect, Divider, Icon, Checkbox,Popover } from 'antd'
import moment from 'moment'
import message from '@/utils/message'
// import { storeLists } from '@/api/store'
import {  createLabelGroup } from '@/api/user'
import CouponList from './CouponList'

const FormItem = Form.Item;

 class CouponModal extends Component {
    state={
        visible:false,
        modalLoading:false,
        storeLists:[],
        popVisible:false,
        columns:[
            {
                title: '名称',
                dataIndex:'name',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.name}</p>
                    )
                }
            },
            {
                title: '券类型',
                dataIndex: 'coupon_type',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>
                            {
                                rowData.coupon_type == 1
                                    ?
                                    '满减券'
                                    :
                                    rowData.coupon_type == 2
                                        ?
                                        '折扣券'
                                        :
                                        '兑换券'
                            }
                        </p>
                    )
                }
            },
            {
                title: '剩余',
                dataIndex: 'last_num',
                render: (text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{rowData.last_num}</p>
                    )
                }
            },
            {
                title: '发放数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <div className={'flex align-center'}>
                            <div style={{border:'solid 1px #999',width:'30px',height:'30px',textAlign:'center',borderRadius:'5px',lineHeight:'30px',cursor:'pointer'}} onClick={()=>{this.changeNum('dec',index)}}>-</div>
                            <div>
                                    <Input 
                                    className={'mgr10 mgl10'}
                                    style={{ width:'80px',textAlign:'center' }}
                                    value = { rowData.num || 1 }
                                    onChange={ e => { this.handleInputChange(e,index) } } 
                                />
                            </div>
                            <div style={{border:'solid 1px #999',width:'30px',height:'30px',textAlign:'center',borderRadius:'5px',lineHeight:'30px',cursor:'pointer'}} onClick={ ()=>{ this.changeNum('inc',index)}}>+</div>
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Button
                                type={`link`}
                                size={`small`}
                                onClick={() => {this.delRule(index)}}
                            >删除</Button>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        callback:null
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    // fetchStore = () => {
    //     let sendData = {}
    //     sendData.page = 1;
    //     sendData.per_page = 100;
    //     storeLists(sendData)
    //         .then(res => {
    //             if (res.code == 1) {
    //                 let lists = res.data.store.data;
    //                 this.setState({
    //                     storeLists: lists
    //                 })
    //             }
    //         })

    // }

    show = (row,callback) => {
        console.log('-----------row',row)
        this.setState({
            visible:true,
            callback : callback || null,
            tableData:row
        })
    }



    handleChange = (res) =>{
        console.log('子组件传递的值', res);
        this.setState({
            tableData:res
        })
    }

    changeNum = (type,index) =>{
        let tableData = this.state.tableData;
        console.log(type,index);
        if(type ==='inc'){
            tableData[index].num = Number(tableData[index].num + 1);
        }else if(type === 'dec'){
            if(tableData[index].num === 1){
                return;
            }
            tableData[index].num = Number(tableData[index].num - 1);
        }
        console.log(tableData);
        this.setState({
            tableData
        })
    }


    delRule = (index) =>{
        console.log('删除优惠券')
        let resData = this.state.tableData;
        resData.splice(index,1);
        this.setState({
            tableData:resData
        })
    }

    addCoupon =() =>{
        let row = this.state.tableData;
        this.couponListCreate.show(row,(res)=>{

        })
    }

    saveData=()=>{
        this.setState({
            modalLoading: true
        })
        console.log('-------',this.state.tableData);
        let callback=this.state.callback

        const { sendCoupon }  = this.props;
        let data = this.state.tableData;
        let arr = [];
        data.forEach((item,index)=>{
            arr.push(
                {
                    id:item.id,
                    num:item.num,
                    name:item.name,
                    coupon_type:item.coupon_type,
                    last_num:item.last_num

                }
            )
        })
        sendCoupon(arr);
        // sendData = this.state.tableData;
        // if(this.props.formdata.id){
        //     sendData.id = this.props.formdata.id
        // }

        // this.setState({
        //     modalLoading: true
        // })
        // createLabelGroup(sendData).then(res => {
        //     message.success(res.msg)
        //     if (res.code === 1) {
        //         if(typeof callback == 'function'){
        //             callback()
        //         }
        //     } 
            
            this.setState({
                modalLoading: false,
                visible:false
            })
        // })


    }
    handleDel = (index) =>{
        // let resData = this.state.tableData;
        // resData.label_value.splice(index,1);
        // this.setState({
        //     tableData:resData
        // })
    }

    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }

    handlePoints = () => {
        this.setState({
            popVisible: true
        })
    }

    handleSavePoints = () => {
        this.setState({
            popVisible: false
        })
    }

    closePop = () => {
        this.setState({
            popVisible: false
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const tableData = this.state.tableData || []
        return (
            <div>
                <Modal
                    title={"编辑优惠券"}
                    visible={this.state.visible}
                    onOk={this.saveData}
                    onCancel={this.handleCancel}
                    width={1300}
                    centered={true}
                    confirmLoading={this.state.modalLoading}
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    <div>
                        <div className={`flex align-center`}>
                            <div><Button type={`primary`} onClick={ this.addCoupon }>添加优惠券</Button></div>
                            <div className={`mgl15`}>最多可添加12种优惠券</div>
                        </div>
                        <div className='mgt15'>
                            <Table
                                size={`middle`}
                                columns={this.state.columns}
                                dataSource={tableData}
                                rowKey={record => record.id}
                                style={{ width:'100%' }}
                                pagination={false}
                            ></Table>
                        </div>
                    </div>
                </Modal>
                <CouponList onRef={e => { this.couponListCreate = e }} sendFn={this.handleChange}></CouponList>
            </div>
        )
    }
}

export default Form.create({})(CouponModal)
