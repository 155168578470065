/**
 * 图文导航组件
 * @type 3
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Tooltip, InputNumber, Radio} from 'antd'
import PropTypes from "prop-types";
import Gallery from '@/components/Gallery/Gallery'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 17 }}


class tpl_ctrl_type3 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }
    state = {
        moduleData:{},
        itemStyleGroup:[
            {
                name:'单行滑动',
                style:1,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            },
            {
                name:'两行滑动',
                style:2,
                imgs:['https://img.cxkoo.com/chengxuan/1/2021-12-14/d4ab4d0225a7f23450b7b63b1fcdf52a.png','https://img.cxkoo.com/chengxuan/1/2021-12-14/a933f0ad51da86d744813a20bd58196f.png']
            }
        ]
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }

    chooseImg = (item,index) => {
        let moduleData = this.state.moduleData;
        this.Gallery.show(res => {
            if(res){
                moduleData.content.dataset[index]['pic'] = res[0];
                this.context.updateDesign(moduleData)
            }
        })

    }
    delImg = (item,index) => {
        let moduleData = this.state.moduleData;
        moduleData.content.dataset.splice(index,1);
        this.context.updateDesign(moduleData)
    }
    changeLink = (e,item,index) => {
        let moduleData = this.state.moduleData;
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    moduleData.content.dataset[index][i] = res[i];
                }
                this.context.updateDesign(moduleData)
            }
        })
    }
    handleTitle = (e,item,index) => {
        e.persist();
        let moduleData = this.state.moduleData,
            val = e.target.value;
        moduleData.content.dataset[index]['title'] = val;
        this.context.updateDesign(moduleData)

    }
    handleUp = (item,index) => {
        let moduleData = this.state.moduleData;
        if(index == 0){ return ; }
        let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
        moduleData.content.dataset.splice(index, 1);
        moduleData.content.dataset.splice(index - 1, 0, tmpdata);
        this.context.updateDesign(moduleData)
    }
    handleDown = (item,index) => {
        let moduleData = this.state.moduleData,
            len = moduleData.content.dataset.length;
        if(index == len - 1){ return ; }
        let tmpdata = moduleData.content.dataset.slice(index, index + 1)[0];
        moduleData.content.dataset.splice(index, 1);
        moduleData.content.dataset.splice(index + 1, 0, tmpdata);
        this.context.updateDesign(moduleData)
    }
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)

    }
    handleBgChange = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content.bgColor = hex;
        this.context.updateDesign(moduleData)
    }
    handleTxtChange = color => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content.txtColor = hex;
        this.context.updateDesign(moduleData)
    }
    handleAdd = () => {
        let moduleData = this.state.moduleData,
            newData = { linkType:0, link:'', pic:'https://img.cxkoo.com/chengxuan/1/2021-11-25/a2e66708f65501b8c36bd2d884ad5730.png', title:'示例标题' };
        moduleData.content.dataset.push(newData);
        this.context.updateDesign(moduleData)
    }
    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>分类导航</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>基本设置</div>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`图文样式`}>
                                    <ul className={`template-box`}>
                                        {
                                            this.state.itemStyleGroup.map((item,index) => (
                                                <Tooltip effect="dark" placement="bottom" title={ item.name } key={index}>
                                                    <li className={`temp ${ moduleData.content.style == item.style ? 'selected' : '' }`} onClick={ () => { this.handleItemStyle(item.style) } }>
                                                        {
                                                            moduleData.content.style == item.style
                                                                ?
                                                                <img src={ item.imgs[0] } className={`img`} alt=""/>
                                                                :
                                                                <img src={ item.imgs[1] } className={`img`} alt=""/>

                                                        }
                                                    </li>
                                                </Tooltip>
                                            ))
                                        }
                                    </ul>
                                </FormItem>
                                <FormItem label={`背景颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.bgColor} callback={ this.handleBgChange } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('bgColor','#fff')}>重置</Button>
                                </FormItem>
                                <FormItem label={`文字颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker1 = e }} color={moduleData.content.txtColor} callback={ this.handleTxtChange } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('txtColor','#000')}>重置</Button>
                                </FormItem>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>添加图片</div>
                            <div className={`cx-ctrl-panel--box`}>
                                {
                                    moduleData.content.dataset.map((item,index) => (
                                        <div className={`cx-img-list`} key={index}>
                                            <div className={`cx-img-item clearfix`}>
                                                <FormItem label={`选择图片`} {...ctrlItemLayout}>
                                                    <div className={'clearfix'}>
                                                        <div className={'img-add'} onClick={() => { this.chooseImg(item,index) }} data-name="pic">
                                                            {
                                                                item.pic
                                                                    ?
                                                                    <div>
                                                                        <img src={item.pic} alt=""/>
                                                                    </div>
                                                                    :
                                                                    '+'
                                                            }
                                                        </div>
                                                    </div>
                                                </FormItem>
                                                <FormItem label={`导航标题`} {...ctrlItemLayout}>
                                                    <Input type={`text`} value={item.title} placeholder={`5个字以内`} maxLength={5} onChange={(e) => { this.handleTitle(e,item,index) }}   />
                                                </FormItem>

                                                <FormItem label={`链接到`} {...ctrlItemLayout}>
                                                    <Input type={`text`} value={item.link} onClick={ (e) => { this.changeLink(e,item,index) } } placeholder={`请选择`}  />
                                                </FormItem>
                                            </div>
                                            <div className="ctrl-item-list-actions">
                                                <i className={`gicon-arrow-up`} onClick={ () => { this.handleUp(item,index) }}></i>
                                                <i className={`gicon-arrow-down`} onClick={ () => { this.handleDown(item,index) }}></i>
                                            </div>
                                            {
                                                index > 0
                                                    ?
                                                    <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delImg(item,index) }} data-name={'pic'}  />
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))
                                }
                                <Button type={`primary`} className={`w100`} onClick={this.handleAdd} >添加图片</Button>
                            </div>
                        </div>
                    </Form>
                    <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                    <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type3)
