import * as actionTypes from '../constants/index'
const sideMenu = ( state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_MENU:
            return action.data;
        case actionTypes.REMOVE_MENU:
            console.log('333')
            return (state = []);;
        default:
            return state
    }

}
export default sideMenu
