import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import '@/assets/css/dashboard/index.scss'
import { getSatistics } from '@/api/dashboard'
import { Tooltip } from 'antd'
import Loading from '@/components/Loading'
import moment from 'moment'
import Chart from '@/components/Chart/Chart'


class Index extends Component{
    state = {
        formData:{},
        chartData:{
            tooltip:{
                trigger:'axis',
            },
            legend:{
                data:['今日','昨日'],
                top:'20',
                right:'20'
            },
            grid: {
                left: '0%',
                right: '20',
                bottom: '1%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: []
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '今日',
                    type: 'line',
                    stack: '今日',
                    data: [0, 0, 0, 0, 0, 0, 0]
                },
                {
                    name: '昨日',
                    type: 'line',
                    stack: '昨日',
                    data: [0, 0, 0, 0, 0, 0, 0]
                }
            ],
            color:['#7F64FF','#B6CFFF']
        },
        isLoading:false
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        let chartData = this.state.chartData
        this.setState({
            isLoading:true
        })
        getSatistics(sendData)
            .then(res => {
                if(res.code == 1){
                    let formData = res.data.board,
                        chart = formData.money.echart;
                    chartData.xAxis.data = chart.today.times;
                    chartData.series[0]['data'] = chart.today.trade_money;
                    chartData.series[1]['data'] = chart.yesterday.trade_money;
                    this.setState({
                        formData,
                        chartData
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })

    }
    handleLink = (e) => {
        let path = e.currentTarget.dataset.path || ``;
        this.props.history.push({
            pathname:path
        })
    }
    render() {
        let formData = this.state.formData || {} ;
        return(
            <div className="dashboard-radius">
               <div className={`flex justify-start align-center`}>
                   <div className={`data-statistic`}>
                       <div className={`data-title`}>
                           <span className={`fi-title`}>每日待办事项</span>
                           <span className={`fi-sub-title mgl15`}></span>
                       </div>
                       <div className={`data-items-cont mgt30 pdb25`}>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.wait_deliver : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/3">待发货订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.wait_pickup : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/4">待自提订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.wait_pay : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/1">待付款订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.apply_refund : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/order/lists/6">待退款订单</div>
                           </div>
                           <div className={`data-item`}>
                               <div className={`item-num ft-bold primary`}>{ formData.to_handle ? formData.to_handle.goods_num : 0 }</div>
                               <div className={`item-txt arrow`} onClick={ this.handleLink } data-path="/stock/stock">已售罄商品</div>
                           </div>
                       </div>
                   </div>
               </div>
                <div className={`data-statistic mgt15`}>
                    <div className={`data-title`}>
                        <span className={`fi-title`}>实时数据</span>
                        <span className={`fi-sub-title mgl15`}>更新时间：{ moment().format('YYYY-MM-DD HH:mm:ss') }</span>
                    </div>
                    <div className={`data-chart-cont mgt25`}>
                        <div className={`left`}>
                            <div className={`left-top`}>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>实际收款金额（元）</div>
                                        <Tooltip placement="top" title={`订单完成时间在统计时间内，微信的金额总和，统计时减去退款的金额`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.money ? formData.money.total_money : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.money ? formData.money.yesterday_money : 0 }</div>
                                </div>
                            </div>
                            <div className={`left-chart`}>
                                <Chart chartData={this.state.chartData} className={'line-chart'} height={'320px'}></Chart>
                            </div>
                        </div>
                        <div className={`right`}>
                            <div className={`right-items-cont`}>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>新增客户数（人）</div>
                                        <Tooltip placement="top" title={`统计时间内，通过各种渠道新增的客户数量，包括门店手动添加、手动导入的新客户、自助注册的新客户`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.user_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.user_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>新增会员数（人）</div>
                                        <Tooltip placement="top" title={`统计时间内，通过各种渠道开通会员卡的客户数`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.member_user_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.member_user_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>支付订单数（笔）</div>
                                        <Tooltip placement="top" title={`统计时间内，支付成功的订单数（包含支付后退款、取消的订单）`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.pay_trade_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.pay_trade_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>退款订单数（笔）</div>
                                        <Tooltip placement="top" title={`统计时间内，退款成功的订单数`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.refund_trade_num_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.refund_trade_num_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>实际退款金额（元）</div>
                                        <Tooltip placement="top" title={`统计时间内，通过微信支付原路退回的金额`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.refund_trade_payment_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.refund_trade_payment_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>核销优惠券数（张）</div>
                                        <Tooltip placement="top" title={`统计时间内，客户核销优惠券的数量`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.coupon_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.coupon_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>客单价（元）</div>
                                        <Tooltip placement="top" title={`1.即消费客单价，不包括开卡充值的数据；2.订单完成时间在统计时间内，平均每个客户带来的收入`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.single_price_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.single_price_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>开卡充值金额（元）</div>
                                        <Tooltip placement="top" title={`统计时间内，开卡充值金额`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.card_deposit_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.card_deposit_data.yesterday : 0 }</div>
                                </div>
                                <div className={`data-chart-item`}>
                                    <div className={`txt1`}>
                                        <div className={`item-txt`}>页面浏览量（次）</div>
                                        <Tooltip placement="top" title={`统计时间内，页面被浏览的次数`}>
                                            <span className={`question`}></span>
                                        </Tooltip>
                                    </div>
                                    <div className={`chart-num`}>{ formData.statistics_data ? formData.statistics_data.page_view_data.today : 0 }</div>
                                    <div className={`txt2`}>昨日{ formData.statistics_data ? formData.statistics_data.page_view_data.yesterday : 0 }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`data-statistic mgt15`}>
                    <div className={`data-title`}>
                        <span className={`fi-title`}>常用功能</span>
                        <span className={`fi-sub-title mgl15`}></span>
                    </div>
                    <div className={`data-app-cont`}>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/item/add">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/4f3643f9d34f977d1562f42563592f4e.png" alt=""/>
                            <div className={`txt`}>新增商品</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/order/lists">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/e09395a4b881780d220423bf99084ea7.png" alt=""/>
                            <div className={`txt`}>订单管理</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/design/lists">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/a18260a8da40085f091dfb7767b524fc.png" alt=""/>
                            <div className={`txt`}>店铺装修</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/express/template">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/b04f4995b004e79582f68170f026ae9c.png" alt=""/>
                            <div className={`txt`}>运费模板</div>
                        </div>
                        <div className={`app-item`} onClick={ this.handleLink } data-path="/coupon/lists">
                            <img src="https://img.cxkoo.com/chengxuan/1/2021-11-25/d1e6f766fd61b061d5ae5a7e123d2df2.png" alt=""/>
                            <div className={`txt`}>优惠券</div>
                        </div>
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default withRouter(Index)
