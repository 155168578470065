import React from 'react'
import { Form, Button, Row, Col, Tabs, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { userCardDetail } from '@/api/user'
import { rechargeRules, rechargeUserCard, changeEndDate, disuseCard } from '@/api/member-card'
import { storeLists } from '@/api/store'
import ConsumeRecord from './components/ConsumeRecord'
import RechargeRecord from './components/RechargeRecord'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import CardRecharge from './components/CardRecharge'
import CardActive from './components/CardActive'
import './components/UserDetail.scss'
const FormItem = Form.Item;
const { TabPane } =  Tabs
class UserMemberCard extends React.Component {
    state = {
        tabLists:[
            {
                name:'使用记录',
                status:'1',
            },
            {
                name:'充值记录',
                status:'2',
            }
        ],
        activeStatus:'1',
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        consumeLists:[],
        rechargeLists:[],
        rechargeRules:[],
        storeLists:[],
        card:'',
        wallet:''
    };

    componentDidMount() {
        this.fetchData()
        this.fetchStore();
        this.fetchRechargeRules()
    }

    fetchData = () => {
        let sendData = {};
        sendData.user_id = this.props.match.params.user_id || ``;
        sendData.user_member_card_id = this.props.match.params.user_card_id || ``;
        sendData.type = this.state.activeStatus;
        sendData.page = this.state.page;
        this.setState({
            isLoading:true
        })
        userCardDetail(sendData)
            .then(res => {
                if(res.code == 1){
                    let card = res.data.card,
                        wallet = res.data.wallet,
                        record_list = res.data.record_list;
                    if(sendData.type == 1){
                        this.setState({
                            consumeLists:record_list.data,
                            per_page:record_list.per_page,
                            total:record_list.total,
                            card,wallet
                        })
                    }else {
                        this.setState({
                            rechargeLists:record_list.data,
                            per_page:record_list.per_page,
                            total:record_list.total,
                            card,wallet
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading:true
        })
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }
    fetchRechargeRules = () => {
        let sendData = {};
        sendData.user_id = this.props.match.params.user_id || ``;
        sendData.user_member_card_id = this.props.match.params.user_card_id || ``;
        rechargeRules(sendData)
            .then(res => {
                if(res.code == 1){
                    let rules = res.data.rules;
                    this.setState({
                        rechargeRules:rules
                    })
                }
            })

    }
    clickTab = val => {
        this.setState({
            activeStatus:val,
            page:1,
        },() => {
            this.fetchData()
        })
    }
    handleCardRecharge = () => {
        let sendData = {},
            rechargeRules = this.state.rechargeRules || [];
        this.CardRecharge.show(res => {
            if(res){
                sendData.user_id = this.props.match.params.user_id || ``;
                sendData.remark = res.remark;
                sendData.store_id = res.store_id;
                sendData.pay_ment = rechargeRules[res.ruleIndex]['recharge_money'];
                this.setState({
                    isLoading:true
                })
                rechargeUserCard(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('充值成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleActive = () => {
        let sendData = {}
        this.CardActive.show(res => {
            if(res){
                console.log(res);
                sendData.type = res.type;
                sendData.user_id = this.props.match.params.user_id || ``;
                sendData.user_member_card_id = this.props.match.params.user_card_id || ``;
                if(sendData.type == 1){
                    sendData.end_date = res.end_date || ''
                    if(!sendData.end_date){
                        message.error('请选择日期');
                        return ;
                    }
                }
                if(sendData.type == 2){
                    sendData.days = res.days || ''
                    if(!sendData.days){
                        message.error('请选择时间');
                        return ;
                    }
                }
                this.setState({
                    isLoading:true
                })
                changeEndDate(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })


            }
        })
    }

    handleOff = () => {
        let sendData = {}
        Modal.confirm({
            title: `是否作废这张卡？`,
            onOk: () => {
                sendData.user_id = this.props.match.params.user_id || ``;
                sendData.user_member_card_id = this.props.match.params.user_card_id || ``;
                this.setState({
                    isLoading:true
                })
                disuseCard(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.props.history.push({
                                        pathname:`/user/lists`
                                    })
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }



    render() {
        let card = this.state.card || {},
            wallet = this.state.wallet || {}
        return(
            <div className={`shadow-radius`}>
                <CardRecharge
                    onRef={ e => { this.CardRecharge = e } }
                    storeLists={ this.state.storeLists }
                    rechargeRules={ this.state.rechargeRules }
                ></CardRecharge>
                <CardActive onRef={ e => { this.CardActive = e } }></CardActive>
                <div className={`card-bg`}>
                    <div className={`left`}>
                        <div className={`title`}>
                            <div className={`txt1`}>{ card.member_card_name }</div>
                            <div className={`type`}>储值卡</div>
                        </div>
                        <div className={`card_no`}>卡号：{ card.member_card_no }</div>
                    </div>
                    <div className={`right clearfix`}>
                        <Button type={`primary`} className={`fr mgl10`} onClick={ this.handleCardRecharge }>充值</Button>
                        <Button type={`default`} className={`fr mgl10`} onClick={ this.handleActive }>激活</Button>
                        <Button type={`default`} className={`fr mgl10`} onClick={ this.handleOff }>作废</Button>
                    </div>
                </div>
                <div className={`pd15`}>
                    <Form className={``} layout={`inline`}>
                        <Row>
                            <Col span={8} >
                                <FormItem label={`可用余额`}>
                                    <div className={``}>&yen;{ wallet.wallet_money }</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`开卡方式`}>
                                    {
                                        card.member_card_info && card.member_card_info.open_type == 1
                                            ?
                                            <div className={``}>免费开卡</div>
                                            :
                                            <div className={``}>充值开卡</div>
                                    }
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`开卡时间`}>
                                    <div className={``}>{ card.created_at }</div>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} >
                                <FormItem label={`储值金额`}>
                                    <div className={``}>&yen;{ wallet.deposits_money }</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`适用门店`}>
                                    <div className={``}>{ card.suit_stores }</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`开卡门店`}>
                                    <div className={``}>{ card.open_store_name }</div>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} >
                                <FormItem label={`赠送金额`}>
                                    <div className={``}>&yen;{ wallet.present_money }</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={`账户归属`}>
                                    <div className={``}>总部</div>
                                </FormItem>
                            </Col>
                            <Col span={8} >
                                <FormItem label={<span>&emsp;有效期</span>}>
                                    <div className={``}>
                                        { card.effect_time }
                                        <span className={`fi-link mgl10`} onClick={ this.handleActive }>修改</span>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={``}>
                    <Tabs onChange={this.clickTab} type="card" className={'mgt10'} activeKey={this.state.activeStatus}>
                        {
                            this.state.tabLists.map((item,index) => (
                                <TabPane tab={ item.name } key={item.status}></TabPane>
                            ))
                        }
                    </Tabs>
                    <div className={``}>
                        {
                            this.state.activeStatus == 1
                                ?
                                <ConsumeRecord
                                    onRef={ e => { this.ConsumeRecord = e } }
                                    tableData={ this.state.consumeLists }
                                    total={ this.state.total }
                                    per_page={ this.state.per_page }
                                    page={ this.state.page }
                                ></ConsumeRecord>
                                :
                                <RechargeRecord
                                    onRef={ e => { this.RechargeRecord = e } }
                                    tableData={ this.state.rechargeLists }
                                    total={ this.state.total }
                                    per_page={ this.state.per_page }
                                    page={ this.state.page }
                                ></RechargeRecord>
                        }

                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }

}

export default Form.create({})(withRouter(UserMemberCard))
