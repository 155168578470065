import React, { Component } from 'react'
import { Modal, Button, Form, Input, Row, Col, Icon, InputNumber ,Select, message} from 'antd';
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import CX from '@/utils/CX';
import { liveGroup } from '@/api/livestream'
const FormItem = Form.Item;

class GoodsAddMadal extends Component {
    state = {
        formData: {},
        title: '新增商品',
        visible: false,
        callback: null,
        group_list:[]
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        this.fetchGroup()
    }

    fetchGroup=()=>{
        liveGroup({page:1,per_page:100}).then(res=>{
            if(res.code==1){
                let {current_page,data,per_page }=res.data.group_list;
                this.setState({
                    group_list:data
                })
            }
        })
    }

    show = (callback, row) => {
        if (row) {
            this.setState({
                formData: row,
                visible: true,
                title: '编辑商品',
                callback: callback || null
            })
        } else {
            this.setState({
                visible: true,
                callback: callback || null,
                formData:{}
            })
        }
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleOk = () => {
        let sendData, {formData,callback} = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                sendData = values;
                sendData.cover=formData.cover || '';
                sendData.present_img=formData.present_img||'';
                sendData.group_name=formData.group_name || '';
                if(formData.id){
                    sendData.id=formData.id
                }else{
                    sendData.id=CX.genUUID();
                }
               
                if(!sendData.cover){
                   return message.error('商品图片为必填')
                }
                
                if (typeof callback == 'function') {
                    callback(sendData)
                }
                this.handleCancel()
            }
        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {
            formData[name] = imgs[0];
            this.setState({
                formData
            })
        })
    }

    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        formData[name] = '';
        this.setState({
            formData
        })
    }

    handleSelect=(val,e)=>{
        let group_name=e.props?.label || '',
         formData=this.state.formData;
         formData['group_name']=group_name
        this.setState({
            formData
        })
    }

    render() {

        let data = this.state.formData;
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 17
            }
        }

        return (
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form {...formItemLayout} className={'model-form'}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <FormItem label="商品标题">
                                {getFieldDecorator('name', {
                                    initialValue: data && data.name || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入商品标题'
                                        }
                                    ]
                                })(<Input placeholder='请输入商品标题' />)}
                            </FormItem>
                            <FormItem label={`商品图片`} required={true} >
                                <div className={'clearfix'}>
                                    {
                                        data.cover
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={data.cover}
                                                onDel={e => { this.delImg(e, 'cover') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'cover') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议200X200px，500K以内</span>
                            </FormItem>

                            <FormItem label="分组">
                                {getFieldDecorator('group_id', {
                                    initialValue: data && data.group_id || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择'
                                        }
                                    ]
                                })(
                                    <Select onChange={this.handleSelect} >
                                        {
                                            this.state.group_list.map(val=>{
                                                return  <Select.Option value={val.id} key={val.id} label={val.name} >{val.name}</Select.Option>
                                            })
                                        }
                                       
                                    </Select>
                                )}
                            </FormItem>

                            <FormItem label="赠品内容">
                                {getFieldDecorator('present_info', {
                                    initialValue: data && data.present_info || '',
                                })(<Input.TextArea placeholder='请输入赠品内容' rows={3} />)}
                            </FormItem>
                            <FormItem label={`赠品图片`} >
                                <div className={'clearfix'}>
                                    {
                                        data.present_img
                                            ?
                                            <PictureCard
                                                onRef={e => { this.PictureCard = e }}
                                                imgs={data.present_img}
                                                onDel={e => { this.delImg(e, 'present_img') }}
                                            ></PictureCard>
                                            :
                                            <ImgAdd
                                                onRef={e => { this.ImgAdd = e }}
                                                onClick={e => { this.chooseImg(e, 'present_img') }}
                                            ></ImgAdd>
                                    }
                                </div>
                                <span className={'fi-help-text'}>建议200X200px，500K以内</span>
                            </FormItem>
                            <FormItem label="优惠/到手价">
                                {getFieldDecorator('price', {
                                    initialValue: data && data.price || 0,
                                })(
                                    <InputNumber min={0} precision={2} />
                                )}
                                <span>元</span>
                            </FormItem>

                        </Col>
                        <Col span={4}>
                        </Col>
                    </Row>
                </Form>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
            </Modal>
        )
    }
}

export default Form.create({})(GoodsAddMadal)