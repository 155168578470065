import React, { Component } from 'react'
import { Table, Button, Divider } from 'antd'
import CoursePicker from '@/components/coursePicker/coursePicker'
import { SetCourseVipRule } from './set-vip-price'

export default class CourseDiscount extends Component {
    state = {
        columns: [
            {
                title: '课程名称',
                dataIndex: '1',
                width: 140,
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.name} </div>
                    )
                }
            },
            {
                title: '优惠方式',
                dataIndex: '2',
                width: 100,
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.discount_type == 2 ? '指定价格' : '折扣'} </div>
                    )
                }
            },
            {
                title: 'V1',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v1 || '-'} </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v2 || '-'}</div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v3 || '-'} </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v4 || '-'} </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v5 || '-'} </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '8',
                render: (text, rowData, index) => {
                    return (
                        <div className='lh25'>{rowData.v6 || '-'}</div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                width: 120,
                render: (text, rowData, index) => {
                    return (
                        <div className="btn-row">
                            <Button type='link' size='small' onClick={() => { this.handleEdit(rowData, index) }}>编辑</Button>
                            <Divider type={`vertical`}></Divider>
                            <Button type='link' size='small' onClick={() => { this.handleDele(index) }}>移除</Button>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        selectedRowKeys: []
    }


    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        let detail = this.props.detail || {};
        if (Array.isArray(detail.rule) && detail.rule.length) {
            let rule = detail.rule, tableData = [];
            rule.forEach(item => {
                let obj = {
                    id: item.id,
                    name: item.name,
                    discount_type: item.discount_type,
                    charge_type: item.charge_type,
                    price: item.price
                }
                obj.discount_list = [item];

                let aStr = '', bStr = ''
                if (item.discount_type == 1) {
                    bStr = '折'
                } else {
                    aStr = '￥'
                }
                if (item.v1) {
                    obj.v1 = aStr + item.v1 + bStr
                }
                if (item.v2) {
                    obj.v2 = aStr + item.v2 + bStr
                }
                if (item.v3) {
                    obj.v3 = aStr + item.v3 + bStr
                }
                if (item.v4) {
                    obj.v4 = aStr + item.v4 + bStr
                }
                if (item.v5) {
                    obj.v5 = aStr + item.v5 + bStr
                }
                if (item.v6) {
                    obj.v6 = aStr + item.v6 + bStr
                }

                tableData.push(obj)
            })

            this.setState({
                tableData
            })
        }

    }

    getData = () => {
        let tableData = this.state.tableData;
        tableData = tableData.map(item => {
            let obj = {
                id: item.id,
                name: item.name,
                discount_type: item.discount_type || 1,
                charge_type: item.charge_type,
                price: item.price
            }
            if (item.discount_list && item.discount_list.length) {
                obj.v1 = item.discount_list[0].v1
                obj.v2 = item.discount_list[0].v2
                obj.v3 = item.discount_list[0].v3
                obj.v4 = item.discount_list[0].v4
                obj.v5 = item.discount_list[0].v5
                obj.v6 = item.discount_list[0].v6
            } else {
                obj.is_filter = true
            }
            return obj
        })
        tableData = tableData.filter(item => !item.is_filter);

        return tableData
    }

    handleAdd = () => {
        let tableData = this.state.tableData || []
        this.CoursePicker.show(res => {
            if (res) {
                let lists = [];
                res.forEach((item, index) => {
                    item.discount_type = 1;
                    tableData.forEach((child, childKey) => {
                        if (child.id == item.id) {
                            item = child
                        }
                    })
                    lists.push(item)
                })
                this.setState({
                    tableData: lists,
                })
            }
        })
    }


    batchDele = () => {
        let selectedRowKeys = this.state.selectedRowKeys;
        if (selectedRowKeys.length) {
            let tableData = this.state.tableData;
            tableData = tableData.filter(item => {
                return !selectedRowKeys.includes(item.id)
            })

            this.setState({
                tableData,
                selectedRowKeys: []
            })
        }
    }

    handleDele = index => {
        let tableData = this.state.tableData;
        tableData.splice(index, 1);
        this.setState({
            tableData
        })
    }

    handleEdit = (row, index) => {
        this.SetCourseVipRule.show(row, (res) => {
            let tableData = this.state.tableData;
            tableData[index] = res
            this.setState({
                tableData
            })
        })
    }


    render() {

        const rowSelection = {
            type: 'checkbox',
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            }
        }


        return (
            <>
                <div className="mgb20">
                    <Button type='primary' onClick={this.handleAdd}>添加课程</Button>
                    {
                        this.state.tableData.length ? <Button type='default' className='mgl20' onClick={this.batchDele}>批量移除</Button> : null
                    }

                </div>
                <Table
                    size={`middle`}
                    rowSelection={rowSelection}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>

                <CoursePicker
                    onRef={e => { this.CoursePicker = e }}
                    selectIds={this.state.tableData.map(item => { return Number(item.id) })}
                    multiple={true}
                ></CoursePicker>
                <SetCourseVipRule onRef={e => this.SetCourseVipRule = e} />
            </>
        )
    }
}
