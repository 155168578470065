import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Form, Select } from 'antd'
import message from '@/utils/message'
const FormItem = Form.Item

class UpdateTopic extends React.Component {
    state = {
        formData: {},
        title: '修改话题',
        visible: false,
        modalLoading: false,
        callback: null,
    }
    componentDidMount() {
        this.props.onRef(this)
    }

    show = (params) => {
        this.setState({
            visible: true,
            title:params.title || '修改话题',
            callback: params.callback || null,
        })
    }

    close = () => {
        this.setState({
            visible: false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let  callback = this.state.callback;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.close()
                if (typeof callback == 'function') {
                    callback(values.topic_ids||[])
                }
            }
        })
    }
    handleCancel = () => {
        this.close()
    }
    render() {
        let  topicLists = this.props.topicLists || []

        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 17
            }
        }
        const { getFieldDecorator } = this.props.form
        return (
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} {...formItemLayout}>
                    <FormItem label={`选择话题`} >
                        {getFieldDecorator('topic_ids', {
                            initialValue: [],
                            rules: [
                                {
                                    required: true,
                                    message: '请选择话题'
                                }
                            ]
                        })(
                            <Select style={{ width: '300px' }} placeholder={'请选择'}  mode="multiple" >
                                {
                                    topicLists.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        )}
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(UpdateTopic))
