import React from 'react'
import { Form, Input,Select, Icon, Button,Modal } from 'antd'
import { addNorms, addProps } from '@/api/item'
import PropTypes from "prop-types";
import message from '@/utils/message'
import ItemStorePrice from './item-store-price'
import Gallery from '@/components/Gallery/Gallery'
const FormItem = Form.Item

class ItemSku extends React.Component{
    static contextTypes = {
        setLoading: PropTypes.func,
        setContextState:PropTypes.func,
    }

    state = {
        tableData:[],
        sku:{},
        skuCache:{},
        props:{}, //属性
        norms:[], //规格
        storeVisible:false,
        currentSku:null,
        currentSkuTableIndex:0,
        modalLoading:false,
        patch_basic_sales:'',
        patch_price:'',
        patch_market_price:'',
        patch_weight:''
    }

    componentDidMount() {
        this.props.onRef(this)
        this.initSku()
    }
    initSku = () => {
        let item = this.props.item || {};
        if(item.sku_props && Object.keys(item.sku_props).length){
            let norms = item.norms,
                props = item.props,
                sku = this.state.sku,
                skuCache = this.state.skuCache,
                sku_props = item.sku_props;
            skuCache = sku = sku_props;
            this.setState({
                norms,
                props,
                skuCache,
                sku
            },() => {
                this.combinaSku()
            })

        }
    }
    addNorms = () => {
        let tmp = {name:"规格名称", props:[],props_json:[]};
        this.context.setLoading(true);
        addNorms(tmp)
            .then(res => {
                if(res.code == 1){
                    tmp.props = [];
                    tmp.name = res.data.data.text || ``;
                    tmp.id = res.data.data.key || ``;
                    let norms = this.state.norms;
                    norms.push(tmp);
                    this.setState({
                        norms
                    },() => {
                        this.combinaSku();
                    })

                }
            })
            .finally(() => {
                this.context.setLoading(false)
            })

    }
    combinaSku = () => {
        //组合sku
        let keys=[],
            norms = this.state.norms || [],
            sku = this.state.sku || {}
        norms.forEach((item,index) => {
            if(item.props && item.props.length){
                keys.push(item.props);
            }
        })
        sku = {};
        this.setState({
            sku
        },() => {
            //向sku中插入数据，并更新skuCache缓存
            switch(keys.length){
                case 0:
                    //无sku展示门店定价
                    this.context.setContextState({
                        showItemStorePrice:true
                    })
                    break;
                case 1:
                    for(var i=0;i<keys[0].length;i++){
                        var arr=[keys[0][i]];
                        this.addSku(arr.join(";"));
                    }
                    break;
                case 2:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            var arr=[keys[0][i],keys[1][j]];
                            this.addSku(arr.join(";"));
                        }
                    }
                    break;
                case 3:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                var arr=[keys[0][i],keys[1][j],keys[2][k]];
                                this.addSku(arr.join(";"));
                            }
                        }
                    }
                    break;
                case 4:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n]];
                                    this.addSku(arr.join(";"));
                                }

                            }
                        }
                    }
                    break;
                case 5:
                    for(var i=0;i<keys[0].length;i++){
                        for(var j=0;j<keys[1].length;j++){
                            for(var k=0;k<keys[2].length;k++){
                                for(var n=0;n<keys[3].length;n++){
                                    for(var m=0;m<keys[4].length;m++){
                                        var arr=[keys[0][i],keys[1][j],keys[2][k],keys[3][n],keys[4][m]];
                                        this.addSku(arr.join(";"));
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        })

    }
    addSku = (key,val) => {
        let skuCache = this.state.skuCache || {},
            sku = this.state.sku || {},
            itemInfo = this.props.item || {};
        //添加sku
        console.log('skuCache',skuCache)
        console.log('key',key)
        console.log(Object.keys(skuCache))
        if(!skuCache[key]){
            let skuCacheKeysArr = Object.keys(skuCache),
                cover_img = '',
                goods_sku_code = '';
            skuCacheKeysArr.forEach(item => {
                if(key.includes(item)){
                    cover_img = skuCache[item]['cover_img'];
                    goods_sku_code = skuCache[item]['goods_sku_code'];
                }
            })
            let tmparr=key.split(";"),
                defaultSku = { market_price:'',price:'',basic_sales:'',cover_img:'',weight:'',goods_sku_code:'',store_price:[], props:tmparr };
            defaultSku.price = itemInfo.price || ``;
            defaultSku.market_price = itemInfo.market_price || ``;
            defaultSku.basic_sales = itemInfo.basic_sales || ``;
            defaultSku.weight = itemInfo.weight || ``;
            defaultSku.cover_img = cover_img;
            defaultSku.goods_sku_code = goods_sku_code;
            skuCache[key]= sku[key] = defaultSku;
        }else {
            sku[key] = skuCache[key];
        }


        let tableData = [];
        for(var i in sku){
            sku[i]['sku_key'] = i
            tableData.push(sku[i])
        }
        if(sku && Object.keys(sku).length){
            this.context.setContextState({
                showItemStorePrice:false
            })
        }else {
            this.context.setContextState({
                showItemStorePrice:true
            })
        }
        this.setState({
            sku,skuCache,tableData
        },() => {
            // console.log('tableData',tableData)
            // console.log('norms',this.state.norms);
            // console.log('props',this.state.props);
            // console.log('sku',this.state.sku);
            // console.log('skuCache',this.state.skuCache);
        })
    }
    changeNormsName = (e,index,original) => {
        let val = e.target.value,
            norms = this.state.norms;
        norms[index]['name'] = val;
        this.setState({
            norms
        })

    }
    updateNormsName = (e,index,original) => {
        let val = e.target.value,
            norms = this.state.norms,
            sendData = {};
        sendData.name = val;
        sendData.id = original
        addNorms(sendData)
            .then(res => {
                if(res.code == 1){
                    norms[index]['name'] = res.data.data.text || ``;
                    norms[index]['id'] = res.data.data.key || ``;
                    this.setState({
                        norms
                    },() => {
                        this.combinaSku();
                    })
                }
            })
            .finally(() => {})
    }
    delNorms = (index,normsid) =>  {
        let norms = this.state.norms,
            normProps = norms[index]['props'],
            props = this.state.props;
        for(var i in normProps){
            delete props[normProps[i]]
        }
        norms.splice(index,1);
        this.setState({
            norms,props
        },() => {
            this.combinaSku();
        })
    }
    addNormsVal = (val,index,normsid) => {
        val = val.label
        let props = this.state.props,
            norms = this.state.norms;

        if(!val || val == ''){
            return false;
        }
        this.addNewProps(val,normsid,(data) => {
            props[data.key]=data.val;
            norms[index].props.push(data.key);
            let tmp_props = {}
            tmp_props.key = data.key.toString();
            tmp_props.label = data.val;
            norms[index].props_json.push(tmp_props);

            this.setState({
                props,norms
            },() => {
                //更新数据及视图
                this.combinaSku();
            })

        })
    }
    addNewProps = (text,normsid,callback) => {
        //添加规格属性值
        text = text.replace(/(^\s*)|(\s*$)/g,'');
        let props = this.state.props;

        var passed = true;
        for(var i in props){
            if(props[i] == text){
                passed = false
            }
        }
        if(!passed){
            message.error('该属性已存在');
            return false;
        }
        let sendData = {};
        sendData.id = normsid;
        sendData.text = text;
        this.context.setLoading(true);
        addProps(sendData)
            .then(res => {
                if(res.code == 1){
                    let tmp = {key:res.data.data.key,val:res.data.data.text};
                    if(callback){
                        callback(tmp);
                    }
                }

            })
            .finally(() => {
                this.context.setLoading(false);
            })
    }
    delNormsVal = (e,index) => {
        let norms = this.state.norms,
            props = this.state.props,
            propId = Number(e.key)
        for(var i in norms[index]['props']){
            if(norms[index]['props'][i] == propId){
                norms[index]['props'].splice(i,1)
            }
        }
        for(var j in norms[index]['props_json']){
            if(norms[index]['props_json'][j]['key'] == propId){
                norms[index]['props_json'].splice(j,1)
            }
        }
        delete props[propId];
        this.setState({
            norms,props
        },() => {
            this.combinaSku()
        })

    }
    renderTableTitle = () => {
        return(
            <div>
                <span className={`batch-setting-title`}>批量设置</span>
                <a className={`btn-batch-setting`}>零售价</a>
                <a className={`btn-batch-setting`}>重量</a>
            </div>
        )
    }
    chooseSkuImg = (rowData,index) => {
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache;
        this.Gallery.show(res => {
            if(res){
                let sku_key = tableData[index]['sku_key'];
                skuCache[sku_key]['cover_img'] = sku[sku_key]['cover_img'] = res[0];
                this.combinaSku()
            }
        })

    }
    delSkuImg = (e,rowData,index) => {
        e.stopPropagation();
        let tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        skuCache[sku_key]['cover_img'] = sku[sku_key]['cover_img'] = '';
        this.combinaSku();
    }
    getInputValue = (e,rowData,index) => {
        let name = e.currentTarget.dataset.name || ``,
            value = e.target.value,
            tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            sku_key = tableData[index]['sku_key'];
        skuCache[sku_key][name] = sku[sku_key][name] = value;
        this.setState({
            sku,skuCache
        })
    }
    handlePatchSet = (e,name) => {
        let value = e.target.value,
            tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            keyName = `patch_${name}`,
            datas = {};
        for(var i in tableData){
            let sku_key = tableData[i]['sku_key'];
            skuCache[sku_key][name] = sku[sku_key][name] = value;
        }
        datas['sku'] = sku;
        datas['skuCache'] = skuCache;
        datas[keyName] = value;
        this.setState(datas)

    }
    showSkuStorePrice = (rowData,index) => {
        let currentSku = this.state.currentSku,
            currentSkuTableIndex = index
        currentSku = rowData;
        this.setState({
            currentSku,
            currentSkuTableIndex,
            storeVisible:true
        })

    }
    handleStoreSetting = () => {
        let store_price = this.ItemStorePrice.getData(),
            tableData = this.state.tableData,
            sku = this.state.sku,
            skuCache = this.state.skuCache,
            currentSkuTableIndex = this.state.currentSkuTableIndex,
            sku_key = tableData[currentSkuTableIndex]['sku_key'];
        skuCache[sku_key]['store_price'] = sku[sku_key]['store_price'] = store_price;
        this.combinaSku();
        this.setState({
            storeVisible:false
        })
    }
    handleStoreCancel = () => {
        this.setState({
            storeVisible:false
        })
    }
    getData = () => {
        let skuData = {},
            norms = this.state.norms,
            props = this.state.props,
            sku = this.state.sku;
        skuData.norms = norms;
        skuData.props = props;
        skuData.sku = sku;
        return skuData;
    }


    render() {
        return(
            <div>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <FormItem label={`规格设置`}>
                    {
                        this.state.norms.map((item,index) => {
                            return(
                                <div className={`ec-panel mgb20`} key={index}>
                                    <div className={`ec-panel-header`}>
                                        <Input
                                            value={item.name}
                                            placeholder={'规格名称，例如：颜色'}
                                            maxLength={20}
                                            className={`s-input`}
                                            onChange={ (e) => { this.changeNormsName(e,index,item.id) } }
                                            onBlur={(e) => { this.updateNormsName(e,index,item.id) }}
                                        />
                                        <div className={`ec-action`}>
                                            <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={() => { this.delNorms(index,item.id) }} />
                                        </div>
                                    </div>
                                    <div className={`ec-panel-body`}>
                                        <FormItem label={`规格值`} labelCol={{span:2}} wrapperCol={{span:20}} >
                                            <Select
                                                mode="tags"
                                                labelInValue
                                                style={{ width: '400px' }}
                                                value={item.props_json}
                                                onSelect={(e) => { this.addNormsVal(e,index,item.id) }}
                                                onDeselect={(e) => { this.delNormsVal(e,index) }}
                                                tokenSeparators={[',']}
                                            >
                                                {
                                                    item.props_json && item.props_json.map(child => (
                                                        <Select.Option value={child.key.toString()} key={child.key}>{child.label}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </FormItem>
                                    </div>

                                </div>
                            )
                        })
                    }

                    <div className={`ec-panel`}>
                        <div className={`ec-panel-header`}>
                            <Button type={`default`} disabled={this.state.norms.length >= 3} onClick={this.addNorms}>添加规格</Button>
                        </div>
                    </div>
                </FormItem>
                {
                    Object.keys(this.state.sku).length
                        ?
                        <FormItem label={`规格信息`} required={true}>
                            <div>
                                <div>
                                    <table className="cxtables cxtables-sku">
                                        <thead>
                                        <tr>
                                            {
                                                this.state.norms.map((item, index) => (
                                                    <td key={index}>{item.name}</td>
                                                ))
                                            }
                                            <td>sku图片</td>
                                            <td>sku编码</td>
                                            <td>sku基础销量</td>
                                            <td>sku零售价</td>
                                            <td>sku划线价</td>
                                            <td>sku重量（kg）</td>
                                            <td>门店自主定价</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.tableData.map((item, index) => (
                                                <tr key={index} className="cbMoney">
                                                    {
                                                        this.state.tableData[index].props.map((k, v) => (
                                                            <td key={v}>{this.state.props[k]}</td>
                                                        ))
                                                    }

                                                    <td>
                                                        <div className={'clearfix'}>
                                                            <div className={'img-add'} onClick={() => { this.chooseSkuImg(item,index) }} data-name={`cover_img`}>
                                                                {
                                                                    item.cover_img
                                                                        ?
                                                                        <div>
                                                                            <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={(e) => { this.delSkuImg(e,item,index) } } data-name={'cover_img'}  />
                                                                            <img src={item.cover_img} alt=""/>
                                                                        </div>
                                                                        :
                                                                        '+'
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            placeholder={'sku编码'}
                                                            style={{ width:'100px' }}
                                                            value={item.goods_sku_code}
                                                            onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            data-name={`goods_sku_code`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            placeholder={'基础销量'}
                                                            style={{ width:'100px' }}
                                                            value={item.basic_sales}
                                                            onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            data-name={`basic_sales`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            placeholder={'零售价'}
                                                            style={{ width:'100px' }}
                                                            value={item.price}
                                                            onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            data-name={`price`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            placeholder={'划线价'}
                                                            style={{ width:'100px' }}
                                                            value={item.market_price}
                                                            onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            data-name={`market_price`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            placeholder={'重量'}
                                                            style={{ width:'100px' }}
                                                            value={item.weight}
                                                            onChange={(e) => { this.getInputValue(e,item,index) }}
                                                            data-name={`weight`}
                                                        />
                                                    </td>
                                                    <td style={{position:'relative'}}>
                                                        {
                                                            item.store_price && item.store_price.length
                                                                ?
                                                                <Button type={`primary`} size={`small`} onClick={() => { this.showSkuStorePrice(item,index) }}>查看门店定价</Button>
                                                                :
                                                                <Button type={`default`} size={`small`} onClick={() => { this.showSkuStorePrice(item,index) }}>立即设置</Button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                            <tr className="cbMoney">
                                                <td>
                                                    批量设置
                                                </td>
                                                <td colSpan={this.state.norms.length + 1}></td>
                                                <td>
                                                    <Input
                                                        placeholder={'sku基础销量'}
                                                        style={{ width:'100px' }}
                                                        value={ this.state.patch_basic_sales }
                                                        onChange={(e) => { this.handlePatchSet(e,'basic_sales') }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder={'sku零售价'}
                                                        style={{ width:'100px' }}
                                                        value={ this.state.patch_price }
                                                        onChange={(e) => { this.handlePatchSet(e,'price') }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder={'sku划线价'}
                                                        style={{ width:'100px' }}
                                                        value={ this.state.patch_market_price }
                                                        onChange={(e) => { this.handlePatchSet(e,'market_price') }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        placeholder={'sku重量'}
                                                        style={{ width:'100px' }}
                                                        value={ this.state.patch_weight }
                                                        onChange={(e) => { this.handlePatchSet(e,'weight') }}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </FormItem>
                        :
                        null
                }

                {
                    this.state.storeVisible
                        ?
                        <Modal
                            title={'设置门店定价'}
                            width={900}
                            visible={this.state.storeVisible}
                            onOk={this.handleStoreSetting}
                            onCancel={this.handleStoreCancel}
                            maskClosable={false}
                            confirmLoading={this.state.modalLoading}
                        >
                            <ItemStorePrice
                                item={this.state.currentSku}
                                onRef={e => { this.ItemStorePrice = e }}
                            ></ItemStorePrice>
                        </Modal>
                        :
                        null
                }
            </div>
        )
    }

}

export default Form.create({})(ItemSku)
