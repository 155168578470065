import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Form, Table, Tag, Pagination, Input, Select, DatePicker, Button, Popover, Carousel, Tabs, Modal, Collapse,Card} from 'antd'
import { getUser, userEdit, userTrade, userChangePoints, labelSelectLabel,labelSetLabel,userDeleLabel } from '@/api/user'
import { openUserCard } from '@/api/member-card'
import { storeLists } from '@/api/store'
import { TRADE_TYPES } from '@/constants/trade'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Loading from '@/components/Loading'
import CardOpen from './CardOpen'
import moment from 'moment'
import message from '@/utils/message';
import PointsRecord from './PointsRecord'
import './UserDetail.scss';
const FormItem = Form.Item;
const { RangePicker } = DatePicker
const { TextArea } = Input
const { TabPane } = Tabs;
const { Panel } = Collapse;

class UserDetail extends React.Component {
    state = {
        formData: '',
        user_card: '',
        consume_data: '',
        wallet: 0,
        user_coupon_num: 0,
        isLoading: false,
        editForm: {
            second_name: false,
            sex: false,
            mobile: false,
            birthday: false,
            wechat_id: false,
        },
        columns: [
            {
                title: <span className='mgl16'>商户单号</span>,
                dataIndex: 'order_sn',
                render: (text, rowData, index) => {
                    return (
                        <div className={`lh25 mgl16`}>{rowData.order_sn || '-'}</div>
                    )
                }
            },
            {
                title: '商品名称',
                dataIndex: 'name',
                width: '300px',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.goods_lists && rowData.goods_lists.length > 0
                                    ?
                                    rowData.goods_lists.map((item, index) => (
                                        <div className={`lh25`} key={index}>{item.name} {item.num > 0 ? 'x' : ''}{item.num}</div>
                                    ))
                                    :
                                    <div className={`lh25`}>{rowData.trade_type_name}</div>
                            }
                        </div>
                    )
                }
            },
            {
                title: '下单门店',
                dataIndex: 'store',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.store && rowData.store.name || '-'}</div>
                    )
                }
            },
            {
                title: '下单时间',
                dataIndex: 'created_at',
                render: (text, rowData, index) => {
                    return (
                        <div>{rowData.created_at || '-'}</div>
                    )
                }
            },
            {
                title: '订单金额',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.trade_extends && rowData.trade_type == 9 && rowData.trade_extends.cost_points > 0
                                    ?
                                    <p>&yen;{rowData.pay_ment} + {rowData.trade_extends.cost_points}积分</p>
                                    :
                                    <p>&yen;{rowData.pay_ment}</p>
                            }
                        </div>
                    )
                }
            },
            {
                title: '操作',
                dataIndex: 'opts',
                render: (text, rowData, index) => {
                    return (
                        <div className={`btn-row`}>
                            <Link to={`/order/detail/${rowData.order_sn}`} target={`_blank`} className={`fi-link w150`}>订单详情</Link>
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        storeLists: [],
        tableLoading: false,
        popVisible: false,
        page: 1,
        total: 0,
        per_page: 0,
        tabsKey: '1',
        visibleTags: false,
        tags_list: [],
        label_list:[]
    }
    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
        this.getUserTrade();
        this.fetchStore();
        this.fetchTags()
    }
    fetchData = () => {
        let sendData = {}
        if (this.props.user_id) {
            sendData.user_id = this.props.user_id;
            this.setState({
                isLoading: true
            })
            getUser(sendData)
                .then(res => {
                    if (res.code == 1) {
                        let user = res.data.user_info,
                            user_card = res.data.user_card,
                            wallet = res.data.wallet,
                            user_coupon_num = res.data.user_coupon_num,
                            consume_data = res.data.consume_data,
                            label_list=user.label || [];
                        this.setState({
                            formData: user,
                            user_card,
                            consume_data,
                            wallet,
                            user_coupon_num,
                            label_list
                        })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        }

    }
    fetchTags = () => {
        labelSelectLabel({})
            .then(res => {
                if (res.code == 1) {
                    let tags_list = res.data.list
                    this.setState({
                        tags_list
                    })
                }
            })
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        this.setState({
            tableLoading: true
        })
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })
    }
    getUserTrade = () => {
        let sendData = {}
        this.props.form.validateFields((err, values) => {
            sendData = values;
            sendData.user_id = this.props.user_id;
            sendData.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
            sendData.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
            sendData.page = this.state.page;
            sendData.per_page = 10
        })
        this.setState({
            tableLoading: true
        })
        userTrade(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page;
                    this.setState({
                        tableData: lists,
                        total, per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading: false
                })
            })

    }
    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.getUserTrade()
        })
    }
    handleInputChange = (e) => {
        let formData = this.state.formData,
            name = e.currentTarget.dataset.name || '',
            val = e.target.value;
        formData[name] = val;
        this.setState({
            formData
        })
    }
    handleSexChange = (val) => {
        let formData = this.state.formData,
            editForm = this.state.editForm,
            sendData = {};
        formData.sex = val;
        this.setState({
            formData
        }, () => {
            this.updateUser('sex')
        })
    }
    handleDayChange = (time) => {
        let formData = this.state.formData;
        formData.birthday = time ? moment(time).format('YYYY-MM-DD') : ''
        this.setState({
            formData
        }, () => {
            this.updateUser('birthday')
        })

    }
    updateUser = (name = '') => {
        let sendData = {},
            editForm = this.state.editForm,
            formData = this.state.formData;
        sendData = formData;
        sendData.user_id = formData.id;
        editForm[name] = false
        this.setState({
            isLoading: true
        })
        userEdit(sendData)
            .then(res => {
                if (res.code == 1) {
                    this.setState({
                        editForm
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    handleEdit = (e) => {
        let editForm = this.state.editForm,
            name = e.currentTarget.dataset.name || '';
        editForm[name] = true;
        this.setState({
            editForm
        })

    }
    handlePoints = () => {
        this.setState({
            popVisible: true
        })
    }
    handleSavePoints = () => {
        let sendData = {}
        if (this.props.user_id) {
            sendData.user_id = this.props.user_id;
            this.setState({
                isLoading: true
            })
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    sendData.change_points = values.change_points;
                    sendData.remark = values.remark;
                    userChangePoints(sendData)
                        .then(res => {
                            if (res.code == 1) {
                                message.success('操作成功');
                                this.closePop();
                                this.fetchData()

                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading: false
                            })
                        })
                }
            })
        }
    }
    closePop = () => {
        let datas = {}
        datas[`change_points`] = '';
        datas['remark'] = ''
        this.props.form.setFieldsValue(datas)
        this.setState({
            popVisible: false
        })
    }
    handleTrade = row => {
        this.props.history.push({
            pathname: `/order/detail/${row.order_sn}`
        })
    }
    handleSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.getUserTrade()
        })
    }
    handleUserCard = (rowData, index) => {
        if (rowData.time_card_id) {
            this.props.history.push({
                pathname: `/user/user-times-card/${rowData['user_id']}/${rowData['id']}`
            })

        } else {
            this.props.history.push({
                pathname: `/user/member-card/${rowData['user_id']}/${rowData['id']}`
            })
        }

    }
    handleOpenCard = () => {
        let sendData = {}
        this.CardOpen.show(res => {
            if (res) {
                sendData = res;
                sendData.user_id = this.props.user_id;
                this.setState({
                    isLoading: true
                })
                openUserCard(sendData)
                    .then((res) => {
                        if (res.code == 1) {
                            message.success('开卡成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }

        })
    }

    chooseImg = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {};
        this.Gallery.show(imgs => {

            if (imgs[0]) {
                switch (name) {
                    case 'head_img_url':
                        formData[name] = imgs[0];
                        break;
                    case 'user_img':
                        formData['user_img'] = formData['user_img'] || [];
                        formData['user_img'] = formData['user_img'].concat(imgs);
                        if (formData['user_img'].length > 3) {
                            formData['user_img'] = formData['user_img'].slice(0, 3)
                        }
                        break;
                }

                this.setState({
                    formData
                }, () => {
                    this.updateUser(name)
                })
            }

        })
    }
    delImg = (e, keyName) => {
        let name = keyName || ``,
            index = e.index || 0,
            formData = this.state.formData || {};
        if (typeof formData[name] == 'string') {
            formData[name] = ''
        } else {
            formData[name].splice(index, 1);
        }
        this.setState({
            formData
        }, () => {
            this.updateUser(name)
        })
    }

    handleSort = (e, keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            urls = formData[name] || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = urls.splice(oldIndex, 1)[0];
        urls.splice(newIndex, 0, targetRow);
        formData[name] = urls;
        this.setState({
            formData
        })
    }

    handleCarouselPrev = (e) => {
        e.nativeEvent.stopImmediatePropagation()
        this.Carousel.prev()
    }

    handleCarouselNext = (e) => {
        e.nativeEvent.stopImmediatePropagation()
        this.Carousel.next()
    }


    tabsChange = key => {
        this.setState({
            tabsKey: key
        })
    }

    handleShowTags = () => {
        let label_ids=this.state.label_list.map(val=>val.extra_id);
        let tags_list=this.state.tags_list;

        tags_list=tags_list.map((item,i)=>{
            item.label_data=item.label_data.map((val,j)=>{
                val.is_checked=label_ids.includes(val.extra_id)
                return val
            })
            return item
        })

        this.setState({
            visibleTags: true,
            tags_list
        })
    }

    tagsCancel = () => {
        this.setState({
            visibleTags: false
        })
    }

    tagsSelcet=(i,j)=>{
       
        let tags_list=this.state.tags_list;
        tags_list[i].label_data[j].is_checked=!tags_list[i].label_data[j].is_checked;
        this.setState({
            tags_list
        })
    }

    handleTagsClose=item=>{
        let {i,j}=item;
        let tags_list=this.state.tags_list;
        tags_list[i].label_data[j].is_checked=false
        this.setState({
            tags_list
        })
    }

    handleTagsOk = () => {
        let tags_list=this.state.tags_list,ids=[],label_list=[];
        tags_list.forEach((item,i)=>{
            item.label_data && item.label_data.forEach((val,j)=>{

                if(val.is_checked){
                    ids.push(val.extra_id);
                    label_list.push({...val,extra_name:val.name})
                }
            })
        })

        labelSetLabel({user_id:this.props.user_id,label_ids:ids}).then(res=>{
            if(res.code==1){
                message.success('设置成功！')
                
                this.setState({
                    visibleTags:false,
                    label_list
                })
            }
        })

    }

    handleTagDele=(e,item,index)=>{
        e.preventDefault();
        let sendData={
            label_id:item.extra_id,
            user_id:this.props.user_id
        }

        this.setState({
            isLoading:true
        })

        userDeleLabel(sendData).then(res=>{
            if(res.code==1){
                let label_list=this.state.label_list;
                label_list.splice(index,1);

                this.setState({
                    label_list
                })
            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })

    }

    render() {
        let data = this.state.formData || {},
            user_card = this.state.user_card || '',
            consume_data = this.state.consume_data || {};
        console.log('user_card', user_card)
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 7
            },
            wrapperCol: {
                span: 16
            }
        }
        const content = (
            <div>
                <Form {...formItemLayout} className={`model-form`}>
                    <FormItem label={`增减积分`} className={`mgb5`}>
                        {
                            getFieldDecorator('change_points', {
                                initialValue: '',
                            })(
                                <Input placeholder='请输入' />
                            )
                        }
                        <span className={`fi-help-text`}>请输入要增/减的积分数（-1代表减1积分）</span>
                    </FormItem>
                    <FormItem label={`备注`} className={`mgb5`}>
                        {
                            getFieldDecorator('remark', {
                                initialValue: '',
                            })(
                                <TextArea rows={2} maxLength={20} placeholder='最多输入20个字' />
                            )
                        }
                    </FormItem>
                    <FormItem label={` `} colon={false} className={`clearfix mgb5`}>
                        <Button size={`small`} type={`primary`} className={`fr mgl10`} onClick={this.handleSavePoints}>确定</Button>
                        <Button size={`small`} type={`default`} className={`fr mgl10`} onClick={this.closePop}>取消</Button>
                    </FormItem>
                </Form>
            </div>
        )


        let tags_list=this.state.tags_list,selct_tags=[];
        
        tags_list.forEach((item,i)=>{
            item.label_data && item.label_data.forEach((val,j)=>{

                if(val.is_checked){
                    selct_tags.push({...val,i,j})
                }
            })
        })

        return (
            <div className={`drawer-cont`}>
                <Gallery onRef={e => { this.Gallery = e }}></Gallery>
                <CardOpen
                    onRef={e => { this.CardOpen = e }}
                    storeLists={this.state.storeLists}
                ></CardOpen>
                <div className={`msg-title`}></div>
                <div className={`flex justify-between align-center pd20`}>
                    <div className={`flex jusify-start align-center`}>
                        <img src={data.head_img_url} alt="" width={60} className='cursor' onClick={e => { this.chooseImg(e, 'user_img') }} />

                        <div className={`mgl10`}>
                            <div className={`lh30`}>
                                {data.name || '游客'}
                                <i className={`icon-${data.sex == 1 ? 'male' : data.sex == 2 ? 'female' : ''}`}></i>
                            </div>
                            <div>
                                <Tag>普通会员</Tag>
                            </div>
                        </div>
                    </div>
                    <div className={``}>
                        <Button type={`primary`} onClick={this.handleOpenCard}>立即开卡</Button>
                    </div>
                </div>
                <div className={`msg-title`}></div>
                <div className={'user-content'}>
                    <div className={`user-content-wrapper`}>
                        <div className={`user-info-wrapper`}>
                            <div className={`user-card h100`}>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>客户基本信息</div>
                                    </div>
                                </div>
                                <div className={`user-card-body`}>
                                    <ul className={`user-info`}>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>客户照片</div>
                                            <div className={`right user_photo`}>
                                                {
                                                    data.user_img && data.user_img.length
                                                        ?
                                                        <PictureCard
                                                            onRef={e => { this.PictureCard = e }}
                                                            imgs={data.user_img}
                                                            onDel={e => { this.delImg(e, 'user_img') }}
                                                            onSort={e => { this.handleSort(e, 'user_img') }}
                                                        ></PictureCard>
                                                        :
                                                        null
                                                }
                                                {
                                                    data.user_img && data.user_img.length >= 3
                                                        ?
                                                        null
                                                        :
                                                        <ImgAdd
                                                            onRef={e => { this.ImgAdd = e }}
                                                            onClick={e => { this.chooseImg(e, 'user_img') }}
                                                        ></ImgAdd>
                                                }
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>手机号</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{data.mobile || '无'}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>备注名</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.second_name
                                                        ?
                                                        <Input
                                                            value={data.second_name}
                                                            placeholder={`填写备注名`}
                                                            style={{ width: '150px' }}
                                                            onChange={this.handleInputChange}
                                                            data-name={`second_name`}
                                                            onBlur={() => { this.updateUser('second_name') }}
                                                        />
                                                        :
                                                        <div className={`right-txt`}>{data.second_name || ''}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="second_name" ></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>性别</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.sex
                                                        ?
                                                        <Select
                                                            style={{ width: 150 }}
                                                            placeholder={'请选择'}
                                                            value={data.sex}
                                                            data-name={`sex`}
                                                            onChange={this.handleSexChange}
                                                        >
                                                            <Select.Option value={0}>未知</Select.Option>
                                                            <Select.Option value={1}>男</Select.Option>
                                                            <Select.Option value={2}>女</Select.Option>
                                                        </Select>
                                                        :
                                                        <div className={`right-txt`}>{data.sex == 1 ? '男' : data.sex == 2 ? '女' : '未知'}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="sex"></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>生日</div>
                                            <div className={`right`}>
                                                {
                                                    this.state.editForm.birthday
                                                        ?
                                                        <DatePicker
                                                            onChange={this.handleDayChange}
                                                            style={{ width: 150 }}
                                                            value={data.birthday ? moment(data.birthday, 'YYYY-MM-DD') : null}
                                                        ></DatePicker>
                                                        :
                                                        <div className={`right-txt`}>{data.birthday || ''}</div>
                                                }
                                                <div className={`icon-pencil mgl10`} onClick={this.handleEdit} data-name="birthday"></div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>客户来源</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{data.source_name || '-'}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>客户归属</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{data.store?.name || '总部'}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>成为客户时间</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{data.created_at}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>会员信息</div>
                                    </div>
                                </div>
                                <div className={`user-card-body`}>
                                    <ul className={`user-info`}>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>持卡</div>
                                            <div className={`right`}>
                                                {
                                                    user_card && user_card.length
                                                        ?
                                                        <div className={`right-txt`}>{user_card.length}</div>
                                                        :
                                                        <div className={`right-txt`}>0</div>
                                                }
                                            </div>
                                        </li>
                                        <Carousel ref={e => this.Carousel = e}>
                                            {
                                                user_card && user_card.length
                                                    ?
                                                    user_card.map((item, index) => (
                                                        <div className={`card mgt10 ${item.time_card_id ? 'times-card' : ''}`} key={index} >
                                                            <div className={`card-cover`} onClick={() => { this.handleUserCard(item, index) }}></div>
                                                            <div className="title" >
                                                                <div className="crown"></div>
                                                                <div className="type-img"></div>
                                                                <div className="txt"></div>
                                                            </div>
                                                            <div className="opt-txt"></div>
                                                            <div className="card-no" >{item.member_card_name || item.card_name}</div>
                                                            <div className="balance" >{item.member_card_no || item.card_no}</div>
                                                            <div className="card_end_date" >有效期：{item.end_date || "-"}</div>
                                                            <div className="valid"></div>
                                                            <div className="qr-img"></div>
                                                            {index != 0 ? <div className="prev" onClick={this.handleCarouselPrev}></div> : null}
                                                            {index != (user_card.length - 1) ? <div className="next" onClick={this.handleCarouselNext}></div> : null}


                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Carousel>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>会员等级</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>普通会员</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>成长值</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{this.state.formData.user_extends?this.state.formData.user_extends.exp:0}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>可用余额</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>&yen;{this.state.wallet}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>优惠券</div>
                                            <div className={`right`}>
                                                <div className={`right-txt`}>{this.state.user_coupon_num}</div>
                                            </div>
                                        </li>
                                        <li className={`user-info-li`}>
                                            <div className={`left`}>积分</div>
                                            <div className={`right`}>
                                                <Link
                                                    className={`right-txt fi-link`}
                                                    to={`/user/points-record/${this.props.user_id || ``}`}
                                                    target={`_blank`}
                                                >{data.points}</Link>
                                                <Popover
                                                    content={content}
                                                    title={null}
                                                    placement={`top`}
                                                    trigger="click"
                                                    visible={this.state.popVisible}>
                                                    <div className={`icon-pencil mgl10`} onClick={this.handlePoints} ></div>
                                                </Popover>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={`flex1`}>
                            <div className={`user-card`}>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>客户标签</div>
                                    </div>
                                </div>
                                <div className={`user-card-body inner`}>
                                    <div className='pdl20 pdr20'>
                                        <Button type={'link'} icon={'plus'} onClick={this.handleShowTags} >编辑标签</Button>
                                        {
                                            this.state.label_list.map((item,index)=>{
                                                return <Tag closable onClose={(e) => { this.handleTagDele(e,item,index)}} className='mgl10' key={item.extra_id
                                                }>{item.extra_name}</Tag>
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className={`user-card mgt10`}>
                                <div className={`user-card-head`}>
                                    <div className={`user-card-head-wrapper`}>
                                        <div className={`user-card-head-title`}>消费画像</div>
                                    </div>
                                </div>
                                <div className={`user-card-body inner`}>
                                    <div className={`overview-panel-wrapper`}>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>最近消费时间</div>
                                                <div className={`value`}>{consume_data.last_trade_time || '-'}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>累计消费金额</div>
                                                <div className={`value`}>{consume_data.consume_money}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>累计消费订单数</div>
                                                <div className={`value`}>{consume_data.consume_times}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>退款总额</div>
                                                <div className={`value`}>{consume_data.refund_payment || 0}</div>
                                            </div>
                                        </div>
                                        <div className={`panel-item`}>
                                            <div className={`column`}>
                                                <div className={`title`}>退款订单总数</div>
                                                <div className={`value`}>{consume_data.refund_num || 0}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className={`user-card  mgt10`}>

                                <Tabs defaultActiveKey={this.state.tabsKey} onChange={this.tabsChange}>

                                    <TabPane tab={<span style={{ paddingLeft: '8px' }}>交易记录</span>} key="1">
                                        <Form className={`search-form mgt15`}>
                                            <div className={`clearfix`}>
                                                <FormItem label={'订单类型'} className={'mgl24 fl'}>
                                                    {
                                                        getFieldDecorator('trade_type', {
                                                            initialValue: 0
                                                        })(
                                                            <Select style={{ width: 150 }} placeholder={'请选择'} >
                                                                {
                                                                    TRADE_TYPES.map((item, index) => (
                                                                        <Select.Option value={item.trade_type} key={index}>{item.name}</Select.Option>
                                                                    ))
                                                                }
                                                                <Select.Option value={5} >包厢预订</Select.Option>
                                                            </Select>
                                                        )
                                                    }
                                                </FormItem>
                                                <FormItem label={'订单状态'} className={'mgl15 fl'}>
                                                    {
                                                        getFieldDecorator('status', {
                                                            initialValue: 0
                                                        })(
                                                            <Select style={{ width: 150 }} placeholder={'请选择'} >
                                                                <Select.Option value={0}>全部</Select.Option>
                                                                <Select.Option value={1}>待使用</Select.Option>
                                                                <Select.Option value={2}>待收货</Select.Option>
                                                                <Select.Option value={3}>已退款</Select.Option>
                                                                <Select.Option value={4}>交易关闭</Select.Option>
                                                                <Select.Option value={5}>交易成功</Select.Option>
                                                            </Select>
                                                        )
                                                    }
                                                </FormItem>
                                                <FormItem label={'下单时间'} className={'mgl15 fl'}>
                                                    {
                                                        getFieldDecorator('time', {
                                                            initialValue: ''
                                                        })(
                                                            <RangePicker style={{ width: 230 }} ></RangePicker>
                                                        )
                                                    }
                                                </FormItem>
                                                <FormItem label={''} className={'mgl15 fl'}>
                                                    <Button type={`primary`} onClick={this.handleSearch}>查询</Button>

                                                </FormItem>

                                            </div>
                                            <div className={`clearfix`}>

                                            </div>
                                        </Form>
                                        <div className={`user-card-body`}>
                                            <Table
                                                size={`middle`}
                                                columns={this.state.columns}
                                                dataSource={this.state.tableData}
                                                loading={this.state.tableLoading}
                                                rowKey={record => record.id}
                                                pagination={false}
                                            ></Table>
                                            <div className={'clearfix mgt10'}>
                                                <div className={'fr'}>
                                                    {
                                                        this.state.tableData.length
                                                            ?
                                                            <Pagination current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>


                                    <TabPane tab={<span style={{ paddingLeft: '8px' }}>积分明细</span>} key="2">
                                        <PointsRecord user_id={this.props.user_id} />
                                    </TabPane>

                                </Tabs>



                            </div>
                        </div>
                    </div>

                </div>

                <Modal
                    title="选择标签"
                    width={800}
                    visible={this.state.visibleTags}
                    onOk={this.handleTagsOk}
                    onCancel={this.tagsCancel}
                >
                    <p className='fz20 fw-500'>标签组</p>
                    <div className='mgt5'>
                        <Collapse  >
                            {
                                this.state.tags_list.map((item,index) => {
                                    return (
                                        <Panel header={item.group_name} key={item.group_id}>
                                            <div className="user-tags">
                                                {
                                                    item.label_data && item.label_data.map((val,j) => {
                                                        return <div className={val.is_checked?'active user-tag mgr10':'user-tag mgr10'} onClick={()=>{this.tagsSelcet(index,j)}} key={item.extra_id} >{val.label_name}</div>
                                                    })
                                                }

                                            </div>
                                        </Panel>
                                    )
                                })
                            }
                        </Collapse>
                    </div>
                    <p className='fz20 fw-500 mgt20'>已选标签（{selct_tags.length}）</p>
                    <div className='mgt5'>
                        <Card >
                           {
                            selct_tags.map(item=>{
                               return <Tag closable onClose={() => { this.handleTagsClose(item)}} className='mgr10' key={item.extra_id}>{item.name}</Tag>
                            })
                           }
                            
                        </Card>
                    </div>
                </Modal>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        )
    }

}
export default Form.create({})(withRouter(UserDetail))
