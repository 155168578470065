import React from 'react'
import { Form, Select, DatePicker, Input, Button, Table, Pagination, Tag, Tooltip, Icon, Tabs, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import '@/assets/css/finance/index.scss'
import { financeDetail, financeExport, withdrawAudit, agreeWithdraw, refuseWithdraw } from '@/api/finance'
import moment from 'moment'
import message from '@/utils/message'
import CX from '@/utils/CX'
import RefuseRemark from './components/refuse-remark'
import PayCode from './components/pay-code'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { TabPane } = Tabs

class WithDrawAudit extends React.Component{
    state = {
        searchForm:{
            apply_sn:'',
            nick_name:'',
            mobile:'',
            pay_type:'', //支付方式 1-微信 2-储值卡余额 3-支付宝 4-银行卡
            start_time:'',
            end_time:''
        },
        columns:[
            {
                title:'申请编号',
                dataIndex:'apply_sn',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.apply_sn || '--'}</div>
                    )
                }
            },
            {
                title:'申请时间',
                dataIndex:'created_at',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>{rowData.created_at || '--'}</div>
                    )
                }
            },
            {
                title:'申请人信息',
                dataIndex:'user_info',
                width:'15%',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p className={`lh25`}>{ rowData.user_name || '--' }</p>
                            <p className={`lh25`}>{ rowData.mobile || '--' }</p>
                        </div>
                    )
                }
            },
            {
                title:'申请提现金额',
                dataIndex:'withdraw_money',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div className={`lh25`}>{ rowData.withdraw_money }元</div>
                        </div>
                    )
                }
            },
            {
                title:'提现方式',
                dataIndex:'pay_type',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            <p className={`lh25`}>
                                {
                                    rowData.pay_type == 1
                                        ?
                                        '微信'
                                        :
                                        rowData.pay_type == 2
                                            ?
                                            '储值卡余额'
                                            :
                                            rowData.pay_type == 3
                                                ?
                                                '支付宝'
                                                :
                                                rowData.pay_type == 4
                                                    ?
                                                    '银行卡'
                                                    :
                                                    null
                                }
                            </p>
                        </div>
                    )
                }
            },
            {
                title:'提现账号',
                dataIndex:'store',
                render:(text, rowData, index) => {
                    return (
                        <div className={`lh25`}>
                            {
                                [1,2].includes(rowData.pay_type)
                                    ?
                                    <div>
                                        <p className={`lh25`}>{ rowData.user_name  || '-'}</p>
                                    </div>
                                    :
                                    null
                            }
                            {
                                rowData.pay_type == 3
                                    ?
                                    <div>
                                        <p className={`lh25`}>支付宝账号：{ rowData.pay_account || '-' }</p>
                                        <p className={`lh25`}>姓名：{ rowData.real_name || '-' }</p>
                                    </div>
                                    :
                                    null
                            }
                            {
                                rowData.pay_type == 4
                                    ?
                                    <div>
                                        <p className={`lh25`}>开户账号：{ rowData.pay_account || '-' }</p>
                                        <p className={`lh25`}>开户行：{ rowData.bank_name || '-' }</p>
                                        <p className={`lh25`}>姓名：{ rowData.real_name || '-' }</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'结算状态',
                dataIndex:'status',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 0
                                    ?
                                    <Tag color={`orange`}>待审核</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 1
                                    ?
                                    <Tag color={`green`}>审核成功</Tag>
                                    :
                                    null
                            }
                            {
                                rowData.status == 2
                                    ?
                                    <Tag>已拒绝</Tag>
                                    :
                                    null
                            }
                        </div>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.status == 0
                                    ?
                                    <div className={`lh25`}>
                                        <Button type={`link`} onClick={ () => { this.handleAgree(rowData) } }>同意</Button>
                                        <Button type={`link`} onClick={ () => { this.handleRefuse(rowData) } }>驳回</Button>
                                    </div>
                                    :
                                    '--'

                            }

                        </div>

                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:10,
        activeStatus:'0',
        balance_commission:0,
        to_handle_commission:0
    }
    componentDidMount() {
        this.fetchData()


    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        this.setState({
            tableLoading:true
        })
        withdrawAudit(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.withdraw_list.data,
                        total = res.data.withdraw_list.total,
                        per_page = res.data.withdraw_list.per_page,
                        balance_commission = res.data.balance_commission,
                        to_handle_commission = res.data.to_handle_commission;
                    this.setState({
                        tableData:lists,
                        total,per_page,balance_commission,to_handle_commission
                    })


                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })


    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){




            }
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData()
        })
    }
    handleAgree = row => {
        console.log(row);
        let sendData = {}
        sendData.id = row.id || '';
        if([3,4].includes(row.pay_type)){
            this.PayCode.show(res => {
                sendData.pay_code = res.pay_code;
                this.setState({
                    tableLoading:true
                })
                agreeWithdraw(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })
            })
        }else {
            Modal.confirm({
                title:`同意后，系统会自动打款到分销员账户内，成功后不可撤回。`,
                okType:`danger`,
                onOk:() => {
                    this.setState({
                        tableLoading:true
                    })
                    agreeWithdraw(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('操作成功')
                                    .then(() => {
                                        this.fetchData();
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                tableLoading:false
                            })
                        })
                }
            })
        }


    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleRefuse = row => {
        let sendData = {}
        sendData.id = row.id || '';
        this.RefuseRemark.show(res => {
            sendData.remark = res.reason;
            this.setState({
                tableLoading:true
            })
            refuseWithdraw(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('操作成功')
                            .then(() => {
                                this.setState({
                                    page:1
                                },() => {
                                    this.fetchData();
                                })
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        tableLoading:false
                    })
                })
        })
    }
    clickTab = val => {
        this.setState({
            activeStatus:val
        },() => {
            this.fetchData()
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <RefuseRemark onRef={ e => { this.RefuseRemark = e } }></RefuseRemark>
                <PayCode onRef={ e => { this.PayCode = e } }></PayCode>
                <div className={`content-head mgb15`}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={`申请编号`} className={`fl`}>
                                {
                                    getFieldDecorator('apply_sn',{
                                        initialValue:''
                                    })(
                                        <Input placeholder='请输入申请编号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={`申请人昵称`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('nick_name',{
                                        initialValue:''
                                    })(
                                        <Input placeholder='请输入申请人昵称' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={`手机号`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('mobile',{
                                        initialValue:''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={`申请时间`} className={`fl`}>
                                {
                                    getFieldDecorator('time')(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                            <FormItem label={`提现方式`} className={`fl mgl20`}>
                                {
                                    getFieldDecorator('pay_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>微信</Select.Option>
                                            <Select.Option value={2}>储值卡余额</Select.Option>
                                            <Select.Option value={3}>支付宝</Select.Option>
                                            <Select.Option value={4}>银行卡</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                            </FormItem>

                        </div>
                        <div className={`clearfix`}></div>
                    </Form>
                </div>
                <div className={`flex justify-start align-center`}>
                    <div className={`finance-data-cont`}>
                        <div className="data-finance-item no-border">
                            <div className="txt1">
                                <div className="item-txt">
                                    已发放佣金（元）
                                    <Tooltip placement="topLeft" title={`商家通过微信系统发放或手动转账成功的佣金金额总和`}>
                                        <Icon type="question-circle"  className={`mgl5`} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="chart-num">{ this.state.balance_commission }</div>
                        </div>
                        <div className="data-finance-item no-border">
                            <div className="txt1">
                                <div className="item-txt">
                                    待审核佣金（元）
                                    <Tooltip placement="topLeft" title={`分销员提交申请，商家还未审核的佣金金额总和`}>
                                        <Icon type="question-circle"  className={`mgl5`} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="chart-num">{ this.state.to_handle_commission }</div>
                        </div>
                    </div>
                </div>
                <Tabs  onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="待审核" key={`0`}></TabPane>
                    <TabPane tab="已成功" key={`1`}></TabPane>
                    <TabPane tab="已驳回" key={`2`}></TabPane>
                </Tabs>
                <div className={`content-body`}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={(record,index) => index}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fl'}>
                    </div>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(WithDrawAudit))
