import React from 'react'
import { Modal, Tooltip, Icon,Button } from 'antd'
import Loading from '@/components/Loading'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import Cookie from 'js-cookie'
import { authInfo, updateInfo, unBind, bindTester, unBindTester, release ,programClearCache} from '@/api/miniProgram'
import BindModal from './components/bindModal'
import '@/assets/css/miniprogram/index.scss'



class MiniProgramLists extends React.Component{
    //小程序发布状态，1-审核中；2-审核通过；3-审核驳回；4-已发布；5-已下架
    state = {
        miniProgram:{},
        isLoading:false,
        hasData:false,
        showModal:false,
        modalLoading:false,
        title:'',
        type:1, //1 绑定体验者  2解绑体验者
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        authInfo(sendData)
            .then(res => {
                if(res.code == 1){
                    let program = res.data.program;
                    this.setState({
                        hasData:true,
                        miniProgram:program
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleOk = () => {
        this.formRef.props.form.validateFields((err,values) => {
            if(!err){
                let sendData = {},
                    type = this.state.type;
                sendData.wechat_id = values.name;
                sendData.app_id = this.state.miniProgram.programData.app_id;
                this.setState({
                    isLoading:true,
                    modalLoading:true
                })
                if(type == 1){
                    bindTester(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('绑定成功')
                                    .then(() => {
                                        this.setState({
                                            showModal:false
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false,
                                modalLoading:false
                            })
                        })
                }else {
                    unBindTester(sendData)
                        .then(res => {
                            if(res.code == 1){
                                message.success('解绑成功')
                                    .then(() => {
                                        this.setState({
                                            showModal:false
                                        })
                                    })
                            }
                        })
                        .finally(() => {
                            this.setState({
                                isLoading:false,
                                modalLoading:false
                            })
                        })

                }


            }
        })
    }
    handleCancel = () => {
        this.setState({
            showModal:false
        })
    }
    bindTest = () => {
        this.setState({
            title:`绑定体验者`,
            type:1,
            showModal:true
        })
    }
    unbindTest = () => {
        this.setState({
            title:`解绑体验者`,
            type:2,
            showModal:true
        })
    }
    handleRelease = () => {
        let sendData = {}
        sendData.app_id = this.state.miniProgram.programData.app_id;
        Modal.confirm({
            title:`是否立即发布小程序，审核需要1-2天`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                release(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('发布成功，请等待审核')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }
    handleUpdate = () => {
        let sendData = {}
        sendData.app_id = this.state.miniProgram.programData.app_id;
        Modal.confirm({
            title:`是否立即更新小程序信息`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                updateInfo(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })

    }
    handleUnbind = () => {
        let sendData = {}
        sendData.app_id = this.state.miniProgram.programData.app_id;
        Modal.confirm({
            title:`是否将小程序解绑？解绑后会影响小程序正常使用`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                unBind(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('小程序已解绑')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }

    updateWxImg=(url)=>{

        let sendData = {}
        sendData.app_id = this.state.miniProgram.programData.app_id;

        this.setState({
            isLoading:true
        })
        programClearCache(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('小程序码已更新')
                        .then(() => {
                            this.fetchData()
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }

    render() {
        let program = this.state.miniProgram || {}
        return(
            <div className={'shadow-radius'}>
                {
                    this.state.hasData
                        ?
                        program.is_bind == 1
                            ?
                            <div>
                                <div className={`content-msg mgb20 flex justify-between`}>
                                    <div>
                                        请先给自己设置体验权限。当页面装修保存后，可以先用体验码预览您的小程序页面，查看没有问题后可以正式发布。系统每次更新后如需使用新功能，需重新提交发布。
                                        <br/>
                                        如需解绑，请到微信小程序后台-设置-第三方设置中进行解绑。
                                    </div>
                                    <div>
                                        <a href={`/toAuth/${Cookie.get('token')}`} target={`_blank`}>
                                            <Button type={'primary'}>获取更多权限</Button>
                                        </a>
                                    </div>
                                </div>
                               
                                <div className={`auth`}>
                                    <div className={`card`}>
                                        <div className={`card-title`}>版本信息</div>
                                        <div className={`card-desc`}>
                                            <div className={`form-item`}>
                                                <div className={`fi-name`}>AppId：</div>
                                                <div className={`form-control`}>{program.programData.app_id}</div>
                                            </div>
                                            <div className={`form-item`}>
                                                <div className={`fi-name`}>授权时间：</div>
                                                <div className={`form-control`}>{program.programData.auth_time}</div>
                                            </div>

                                            <div className={`form-item`}>
                                                <div className={`fi-name`}>小程序名称：</div>
                                                <div className={`form-control`}>{program.programData.nick_name}</div>
                                            </div>
                                            <div className={`form-item`}>
                                                <div className={`fi-name`}>服务类目：</div>
                                                {
                                                    program.programData.program_cat
                                                        ?
                                                        <div className={`form-control`}>{`${program.programData.program_cat.first_class}-${program.programData.program_cat.second_class}-${program.programData.program_cat.third_class}`}</div>
                                                        :
                                                        <div className={`form-control`}>-</div>
                                                }
                                            </div>
                                            <div className={`form-item`}>
                                                <div className={`fi-name`}>发布版本：</div>
                                                <div className={`form-control`}>{program.releaseInfo.template_id}</div>
                                            </div>
                                            <div className={`form-item`}>
                                                <div className={`fi-name`}>提交时间：</div>
                                                <div className={`form-control`}>{program.releaseInfo.created_at}</div>
                                            </div>
                                            <div className={`form-item`}>
                                                <div className={`fi-name`}>最新状态：</div>
                                                <div className={`form-control`}>
                                                    <div>
                                                        {
                                                            program.releaseInfo.status == 1
                                                                ?
                                                                <div className={`lh30`}>
                                                                    审核失败
                                                                    <Tooltip title={ program.releaseInfo.status_name }>
                                                                        <Icon type="question-circle" className={`mgl5`} />
                                                                    </Tooltip>
                                                                </div>
                                                                :
                                                                <div className={`lh30`}>
                                                                    { program.releaseInfo.status_name }
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`card-btn-group`}>
                                            <div className={`card-btn ghost`} onClick={ this.handleUpdate }>更新信息</div>
                                            <div className={`card-btn `} onClick={ this.handleRelease }>立即发布</div>

                                        </div>
                                    </div>
                                    <div className={`card`}>
                                        <div className={`card-title full`}>测试体验</div>
                                        <div className={`card-desc`}>
                                            <div className={`mini-code`}>
                                                <img src={program.qrcode} alt=""/>
                                            </div>

                                        </div>
                                        <div className={`card-btn-group`}>
                                            <div className={`card-btn`} onClick={ this.bindTest }>绑定体验者</div>
                                            <div className={`card-btn ghost`} onClick={ this.unbindTest } >解绑体验者</div>
                                        </div>
                                    </div>
                                    <div className={`card`}>
                                        <div className={`card-title`}>小程序码</div>
                                        <div className={`card-desc`}>
                                            <div className={`mini-code`}>
                                                <img src={program.sunQrcode} alt=""/>
                                            </div>
                                        </div>
                                        <div className={`card-btn-group`}>
                                            <div className={`card-btn`} onClick={this.updateWxImg }>更新小程序码</div>
                                            <a href={program.downSunQrcode} target={`_blank`}>
                                                <div className={`card-btn ghost`}>下载小程序码</div>
                                            </a>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={`no-auth`}>
                                <div className={`card`}>
                                    <div className={`card-title`}>已拥有小程序账号</div>
                                    <div className={`card-logo`}>
                                        <img src="https://img.cxkoo.com/chengxuan/3/2021-09-26/085d028d6f5aea6c556d1b5fd645b8b2.png" alt=""/>
                                    </div>
                                    <div className={`card-desc`}>
                                        <p className={`mgb10`}>
                                            小程序类型为非个人类型，即除了个人类型小程序外，其它类型均可
                                        </p>
                                        <p className={`mgb10`}>
                                            已完成基本信息完善，未进行信息完善时，将无法正常使用
                                        </p>
                                        <p>
                                            登录微信小程序，打开【设置→运营服务后台管理】→可以授权管理，确认没有授权给其他人；如果有授权给其他人，则需要先进行“停止授权”，然后再回来进行授权操作。
                                        </p>
                                    </div>
                                    <a href={ this.state.miniProgram.auth_url }>
                                        <div className={`card-btn ghost`}>去绑定</div>
                                    </a>

                                </div>
                                <div className={`card`}>
                                    <div className={`card-title`}>还没有小程序账号</div>
                                    <div className={`card-logo`}>
                                        <img src="https://img.cxkoo.com/chengxuan/3/2021-09-26/0ec3252b3feea84c708bf11f23f6da8a.png" alt=""/>
                                    </div>
                                    <div className={`card-desc`}>
                                        <p>
                                            方法一：先进行小程序的注册申请请小类型为非个人类型（个人类型是由于选择了可选程序，无法进行授权）
                                        </p>
                                        <p>
                                            方法二：通过公众号进行快速注册（要求：公众号已完成微信认证）登录公众号后台，点击进入登录，打开【小程序管理→添加→快速注册并认证小程序】，根据提示进行操作；注意：快速注册时，请勾选【微信认证资质】以公众号资质完成小程序认证。
                                        </p>
                                    </div>
                                    <a href={`https://mp.weixin.qq.com/`} target={`_blank`}>
                                        <div className={`card-btn `}>去注册</div>
                                    </a>
                                </div>
                            </div>
                        :
                        null
                }
                { this.state.isLoading ? <Loading /> : null }
                {
                    this.state.showModal
                        ?
                        <Modal
                            title={this.state.title}
                            visible={true}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            maskClosable={false}
                            confirmLoading={this.state.modalLoading}
                        >
                            <BindModal
                                visible={this.state.showModal}
                                wrappedComponentRef={form => {this.formRef = form} }
                            ></BindModal>
                        </Modal>
                        :
                        null
                }

            </div>
        )
    }
}

export default withRouter(MiniProgramLists)
