import $axios from '@/axios/axios.js'

export function pullNewLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pull-new`,
        method:'post',
        data
    })
}

export function pullNewSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pull-new/save`,
        method:'post',
        data
    })
}

export function pullNewDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pull-new/detail`,
        method:'post',
        data
    })
}


export function pullNewEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pull-new/end`,
        method:'post',
        data
    })
}


export function pullNewDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pull-new/delete`,
        method:'post',
        data
    })
}


export function pullNewPullData(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pull-new/pullData`,
        method:'post',
        data
    })
}

export function exportPullNew(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/pull-new/export`,
        method:'get',
        responseType:'blob',
        params
    })
}