import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Table, Input, Form, Radio } from 'antd'
import { timesCardItem } from '@/api/times-card'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class TimesCardPickUp extends React.Component{
    state = {
        title:'选择核销次数',
        columns:[
            {
                title:'商品名称',
                dataIndex:'name',
                width:'70%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.name || '-'}</p>
                            <p className={`lh25`} style={{ color:'#999' }}>剩余{ rowData.last_count== -1?'无限':rowData.last_count }次</p>
                        </div>
                    )
                }
            },
            {
                title:'核销次数',
                dataIndex:'opt',
                width:'30%',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type={`number`}
                                value={rowData.verify_count}
                                style={{ width:'100px' }}
                                min={0}
                                onChange={ (e) => { this.handleChange(e,rowData,index) } }
                            ></Input>
                        </div>
                    )
                }
            }
        ],
        tableData:[],
        tableLoading:false,
        visible:false,
        modalLoading:false,
        callback:null,

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null,
        },() => {
            this.fetchData()
        })
    }
    fetchData = () => {
        let sendData = {}
        sendData.user_time_card_id = this.props.user_time_card_id || '';
        this.setState({
            tableLoading:true
        })
        timesCardItem(sendData)
            .then(res => {
                if(res.code == 1){
                    let goods_list = res.data.goods_list || [];
                    goods_list && goods_list.forEach((good,goodIndex) => {
                        good.verify_count = good.verify_count || 0
                    })
                    this.setState({
                        tableData:goods_list
                    })
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })

    }
    handleChange = (e,rowData,index) => {
        let tableData = this.state.tableData || [],
            val = e.target.value;

            if( rowData.last_count !=-1){
                val = val > rowData.last_count ? rowData.last_count : val
            }
       
        tableData[index]['verify_count'] = val;
        this.setState({
            tableData
        })

    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    handleOk = () => {
        let sendData = {},
            tableData = this.state.tableData || [],
            callback = this.state.callback;
        if(typeof callback == 'function'){
            callback(tableData)
        }
        this.close()

    }
    handleCancel = () => {
        this.close()
    }
    render() {
        const storeLists = this.props.storeLists || []
        const data = this.props.data || {}
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
              <div className={`times-card-cont`}>
                  <div className={'content-body'}>
                      <Table
                          size={`middle`}
                          columns={this.state.columns}
                          dataSource={this.state.tableData}
                          loading={this.state.tableLoading}
                          rowKey={record => record.id}
                          pagination={false}
                      ></Table>
                  </div>
              </div>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(TimesCardPickUp))
