import React, { Component } from 'react'
import { Form, Input, Button, Table, Select, DatePicker, Row, Col, Modal, TreeSelect, Divider, TimePicker } from 'antd'
import moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import { storeLists } from '@/api/store'
import { gradeEditTable } from '@/api/educational'
import { CourseCategoryApi } from '@/api/course'
import message from '@/utils/message'
const FormItem = Form.Item;

class PlanCourseEdit extends Component {

    state = {
        courseLists: [],
        storeLists: [],
        modalLoading: false,
        formData: {}
    }
    componentDidMount() {
        this.fetchStore()
        this.fetchCourse()

        let plan_row = this.props.plan_row, formData = {}
        formData = {
            course_date: plan_row.table_date,
            start_time: plan_row.start_time,
            end_time: plan_row.end_time
        }
        this.setState({
            formData
        })
    }

    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists: lists
                    })
                }
            })

    }

    fetchCourse = () => {

        CourseCategoryApi({ page: 1, per_page: 100 }).then(res => {

            if (res.code === 1) {
                let { data } = res.data.list

                data.forEach(item => {
                    item.key = item.id;
                    item.value = item.id;
                    item.title = item.name;
                    item.disabled = item.children && item.children.length > 0
                    item.children && item.children.forEach(child => {
                        child.key = child.id
                        child.value = child.id
                        child.title = child.name
                    })
                })

                this.setState({
                    courseLists: data
                })
            }
        })
    }




    saveData = () => {
        let sendData = {}, data = this.props.plan_row, formData = this.state.formData
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                sendData = formData;
                sendData.course_table_id = data.id



                if (!sendData.course_date) {
                    return message.error("开课日期必填")
                }

                if (!sendData.start_time) {
                    return message.error("上课时间段必填")
                }

                if (!sendData.end_time) {
                    return message.error("上课时间段必填")
                }

                this.setState({
                    modalLoading: true
                })
                gradeEditTable(sendData).then(res => {

                    if (res.code === 1) {
                        message.success(res.msg)
                        this.setState({
                            modalLoading: false
                        })
                        this.props.handleS()
                        this.handleCancel()
                    } else {
                        this.setState({
                            modalLoading: false
                        })
                    }
                })

            }
        })
    }
    handleCancel = () => {
        this.props.closePlanCourse()
    }
    handleChange = (date, name) => {
        let formData = this.state.formData
        formData[name] = date
        this.setState({
            formData
        })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 20
            }
        }


        const { getFieldDecorator, getFieldValue } = this.props.form
        let data = this.props.grade_line || {}
        let plan_row = this.props.plan_row



        const columns = [
            {
                title: '开课日期',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <DatePicker placeholder='请选择开课时间' format={"YYYY-MM-DD"} defaultValue={moment(plan_row.table_date, 'YYYY-MM-DD')} onChange={(time, timeStr) => { this.handleChange(timeStr, "course_date") }} />
                        </div>
                    )
                }
            },
            {
                title: '上课时间段',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <TimePicker placeholder='开始时间' format={"HH:mm"} defaultValue={moment(plan_row.start_time, 'HH:mm')} onChange={(time, timeStr) => { this.handleChange(timeStr, "start_time") }} /> 至
                            <TimePicker placeholder='结束时间' format={"HH:mm"} defaultValue={moment(plan_row.end_time, 'HH:mm')} onChange={(time, timeStr) => { this.handleChange(timeStr, "end_time") }} />
                        </div>
                    )
                }
            },
        ]

        return (

            <Modal
                title={`编辑排课`}
                visible={true}
                onOk={this.saveData}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form {...formItemLayout} className={'model-form'}>

                    <Row gutter={24}>
                        <Col span={24}>
                            <FormItem label={'课程类目'} >
                                {
                                    getFieldDecorator('category_id', {
                                        initialValue: data.category_id || "",
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择课程类目'
                                            }
                                        ]
                                    })(
                                        <TreeSelect
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder={'请选择课程类目'}
                                            treeData={this.state.courseLists}
                                            disabled={true}
                                        />
                                    )
                                }
                            </FormItem>
                            <FormItem label="上课班级">
                                {getFieldDecorator('name', {
                                    initialValue: data.name || "",
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择上课班级'
                                        }
                                    ]
                                })(
                                    <Input placeholder='请输入班级名称' disabled style={{ width: 200 }} />
                                )}
                                <span className={`fi-help-text`}>开班时间（{data.start_date}到{data.end_date}）</span>
                            </FormItem>

                            <FormItem label="排课方式" required={true}>
                                <Table
                                    size={`middle`}
                                    columns={columns}
                                    dataSource={[{ id: 1 }]}
                                    loading={false}
                                    rowKey={record => record.id}
                                    pagination={false}
                                ></Table>
                            </FormItem>

                            <FormItem label={'扣课时数'} >
                                {
                                    getFieldDecorator('course_cost_num', {
                                        initialValue: plan_row.course_cost_num,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请填写每节课所扣课时数'
                                            }
                                        ]
                                    })(<Input addonAfter="课时" placeholder='请填写每节课所扣课时数' style={{ width: 200 }} disabled={true} />)
                                }
                            </FormItem>

                            <FormItem label={'上课地点'} >
                                {
                                    getFieldDecorator('store_id', {
                                        initialValue: plan_row.store_id || "",
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择上课地点'
                                            }
                                        ]
                                    })(
                                        <Select placeholder={'请选择上课地点'} style={{ width: 200 }} disabled={true} >
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                                <Link to={'/store/lists'} target={`_blank`} >
                                    <span className='text_link mgl15'>新增</span>
                                </Link>
                                <Divider type={`vertical`}></Divider>
                                <span className='text_link' onClick={() => { this.fetchStore() }}>刷新</span>
                            </FormItem>

                            {/* <FormItem label={'老师'} >
                                {
                                    getFieldDecorator('teacher_id', {
                                        initialValue: data.teacher_id || "",
                                    })(
                                        <Select placeholder={'请选择上课地点'} style={{ width: 200 }}  >
                                            {
                                                [].map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                                <Link to={'/store/lists'} target={`_blank`} >
                                    <span className='text_link mgl15'>新增</span>
                                </Link>
                                <Divider type={`vertical`}></Divider>
                                <span className='text_link' onClick={() => { this.fetchStore() }}>刷新</span>
                            </FormItem> */}


                        </Col>

                    </Row>
                </Form>

            </Modal>


        )
    }
}

export default Form.create({})(withRouter(PlanCourseEdit))