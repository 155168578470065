import * as actionTypes from '../constants/index'
const admin = (state = '', action) => {
    switch (action.type) {
        case actionTypes.SET_ADMIN:
            return action.data;
            break;
        case actionTypes.REMOVE_ADMIN:
            return ( state = '' );
            break;
        default:
            return state;
    }
}
export default admin
