import React from 'react'
import {withRouter} from 'react-router-dom'
import {Form} from 'antd'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import Gallery from '@/components/Gallery/Gallery'
import '@/assets/css/miniprogram/user-center.scss'

const FormItem = Form.Item

class UserStyle1 extends React.Component {
    state = {}

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData()
    }

    fetchData = () => {

    }

    render() {
        let data = this.props.current || {}
        const {getFieldDecorator} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 20
            }
        }
        return (
            <div className={`user-style1`}>
                <div className="mine-cont">
                    <div className="mine-head" style={{ backgroundImage:`url(${data.bgImg || 'https://img.cxkoo.com/chengxuan/1/2021-12-09/4a7ba5fa6ca563d77bc2914de0883b6a.png'})` }}>
                        <div className="head-cont">
                            <div className="user-cont">
                                <div className="avatar"></div>
                                <div className="icon-group"></div>
                                <div className="user-info">
                                    <div className="nickname">登录/注册</div>
                                    <div className="user-items">
                                        <div className="user-item">0 收藏</div>
                                        <div className="user-item">0 优惠券</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mine-items-cont">
                        {
                          data.showMemberCard 
                              ?
                              <div className="vip-close">
                                  <div className="txt1">开通VIP会员，享专属特权！</div>
                                  <div className="vip-btn">立即开通</div>
                              </div>
                              :
                              null
                        }

                        <div className={`cx-mine-panel  ${ data.showMemberCard  ? 'first': '' }`}>
                            <div className="cx-panel-title">
                                <div className="left">我的订单</div>
                                <div className="right">
                                    <div className="txt">全部</div>
                                    <div className="arrow"></div>
                                </div>
                            </div>
                            <div className="cx-panel-cont flex justify-between align-center">
                                <div className="order-item">
                                    <div className="item-img icon1">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待付款</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon2">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待发货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon3">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待提货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon4">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">待收货</div>
                                </div>
                                <div className="order-item">
                                    <div className="item-img icon5">
                                        <div className="badge">0</div>
                                    </div>
                                    <div className="txt">退款/售后</div>
                                </div>
                            </div>
                        </div>
                        <div className="cx-mine-panel mgt20">
                            <div className="cx-panel-title">
                                <div className="left">快捷服务</div>
                                <div className="right">
                                </div>
                            </div>
                            <div className="cx-quick-items">
                                {
                                    data.userCenter && data.userCenter.map((item, index) => (
                                        item.isShow
                                            ?
                                            <div className="quick-item" key={index}>
                                                <div className="left">
                                                    <div className="icon"
                                                         style={{backgroundImage: `url(${item.icon})`}}></div>
                                                    <div className="txt">{item.name}</div>
                                                </div>
                                                <div className="right">
                                                    <div className="arrow"></div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create({})(withRouter(UserStyle1))
