import $axios from '@/axios/axios.js'

export function ticketLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/ticket`,
        method:'post',
        data
    })
}

export function ticketDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/ticket/detail`,
        method:'post',
        data
    })
}

export function ticketDele(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/ticket/delete`,
        method:'post',
        data
    })
}

export function ticketSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/ticket/save`,
        method:'post',
        data
    })
}

export function ticketEnd(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/ticket/end`,
        method:'post',
        data
    })
}