import React from 'react'
import { Form, Input, Select, DatePicker, Button, Pagination, Tag, Divider, Table, Tooltip, Icon, Drawer, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { fxMemberLists, fxAuditLists, fxAudit, FxFrozen, AddFxUser  } from '@/api/fx'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import FxUserDetail from './fx-user-detail'
import FxMemberPicker from './fx-member-picker'
import ShareModal from '@/components/shareModal/shareModal'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class MemberLists extends React.Component{
    state = {
        searchForm:{
            name:'',
            mobile:'',
            start_time:'',
            end_time:'',
            status:'',
        },
        columns:[
            {
                title:'分销员信息',
                dataIndex:'name',
                width:'200px',
                render:(text, rowData, index) => {
                    return (
                        <div className={`flex jusify-start align-center`}>
                            <img src={rowData.head_img_url} alt="" width={60}/>
                            <div className={`mgl10`}>
                                <div className={`fi-link w150 lh25`} onClick={() => {this.handleEdit(rowData)}}>
                                    { rowData.name || `游客` }
                                    <i className={`icon-${rowData.sex == 1  ? 'male' : rowData.sex == 2 ? 'female' : ''}`}></i>
                                    {
                                        rowData.status == 4
                                            ?
                                            <Tag color={`red`} className={`mgl5`}>已冻结</Tag>
                                            :
                                            null
                                    }

                                </div>
                                <div className={`lh25`}>{ rowData.mobile || `暂无手机号` }</div>
                                <div className={`lh25`}>邀请码：{ rowData.invite_code }</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:() => {
                    return(
                        <div>
                            邀请人
                            <Tooltip placement="topLeft" title={`该分销员的上级分销员`}>
                                <Icon type="question-circle"  className={`mgl5`} />
                            </Tooltip>
                        </div>
                    )
                },
                dataIndex:'first_user',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.first_user
                                    ?
                                    <div>
                                        <p className={`lh25`}>
                                            { rowData.first_user.name || '-' }
                                        </p>
                                        <p className={`lh25`}>
                                            { rowData.first_user.mobile || '-' }
                                        </p>

                                    </div>
                                    :
                                    <div>
                                        <p className={`lh25`}>无</p>
                                    </div>

                            }
                        </div>

                    )
                }
            },
            {
                title:'下级客户数',
                dataIndex:'lower_user_num',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.lower_user_num || 0 }</p>
                    )
                }
            },
            {
                title:'下级分销员数',
                dataIndex:'lower_agent_num',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.lower_agent_num || 0 }</p>
                    )
                }
            },
            {
                title:'累计收益',
                dataIndex:'total_commission',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.total_commission }元</p>
                    )
                }
            },
            {
                title:'分销等级',
                dataIndex:'level',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>-</p>
                    )
                }
            },
            {
                title:'加入时间',
                dataIndex:'agent_time',
                render:(text, rowData, index) => {
                    return (
                        <p className={`lh25`}>{ rowData.agent_time }</p>
                    )
                }
            },
            {
                title:'操作',
                dataIndex:'opts',
                render:(text, rowData, index) => {
                    return (
                        <div>
                            <div className={'btn-row'}>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleEdit(rowData)}}>详情</Button>
                                <Divider type={`vertical`}></Divider>
                                {
                                    rowData.status == 4
                                        ?
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleFrozen(rowData,2)}}>解冻</Button>
                                        :
                                        <Button type={"link"} size={"small"} onClick={() => {this.handleFrozen(rowData,1)}}>冻结</Button>
                                }
                                <Divider type={`vertical`}></Divider>
                                <Button type={"link"} size={"small"} onClick={() => {this.handleShare(rowData)}}>推广码</Button>
                            </div>
                        </div>
                    )
                }
            },
        ],
        tableData:[],
        isLoading:false,
        page:1,
        total:0,
        per_page:10,
        drawerVisible:false,
        currentRow:'',
        poster:'',
        path:''
    }

    componentDidMount() {
        this.props.onRef(this)
        this.fetchData();
        console.log(222);
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        this.setState({
            isLoading:true
        })
        fxMemberLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.lists.data,
                        total = res.data.lists.total,
                        per_page = res.data.lists.per_page;
                    this.setState({
                        tableData:lists,
                        total,
                        per_page
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    page:1,
                    searchForm
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for(var i in searchForm){
            searchForm[i] = ''
        }
        searchForm.status = 0;
        this.setState({
            searchForm,
            page:1
        },() => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }
    handleEdit = rowData => {
        this.setState({
            drawerVisible:true,
            currentRow :rowData
        })
    }
    handleDrawerClose = () => {
        this.setState({
            drawerVisible:false,
            currentRow:null
        })
        this.fetchData();
    }
    handleAdd = () => {
        let sendData = {}
        this.FxMemberPicker.show(res => {
            console.log(res)
            if(res && res.length){
                sendData.user_id = res[0]['id'];
                this.setState({
                    isLoading:true
                })
                AddFxUser(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('添加成功')
                                .then(() => {
                                    this.handleReset()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleFrozen = (rowData,status) => {
        let sendData = {}
        sendData.user_id = rowData.user_id || '';
        sendData.status = status;
        Modal.confirm({
            title:`是否${ sendData.status == 1 ? '冻结': '解冻' }该分销员？`,
            okType:`danger`,
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                FxFrozen(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('操作成功')
                                .then(() => {
                                    this.handleReset()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    handleShare = row => {
        this.setState({
            poster:row.code_url,
            path:`/pages/index/index`
        })
        this.ShareModal.show()
    }




    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={``}>
                <FxMemberPicker
                    onRef={ e => { this.FxMemberPicker = e } }
                ></FxMemberPicker>
                <ShareModal onRef={e => { this.ShareModal = e }} poster={ this.state.poster } path={ this.state.path } ></ShareModal>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>分销员信息</span>} className={'fl'} >
                                {
                                    getFieldDecorator('name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='昵称/姓名' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>分销员手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>分销员状态</span>} className={'fl'}>
                                {
                                    getFieldDecorator('status')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={``}>全部</Select.Option>
                                            <Select.Option value={1}>正常</Select.Option>
                                            <Select.Option value={2}>待审核</Select.Option>
                                            <Select.Option value={3}>已拒绝</Select.Option>
                                            <Select.Option value={4}>冻结</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;&emsp;加入时间</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <FormItem  label={<span>&emsp;&emsp;&emsp;&emsp;&emsp;</span>} colon={false} >
                            <Button type='primary'  onClick={() => this.search()}>搜索</Button>
                            <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                        </FormItem>
                        <div className={`clearfix`}></div>
                    </Form>
                </div>
                <div className={'mgt10'}>
                    <Button type={'primary'} onClick={this.handleAdd} icon={'plus'}>添加分销员</Button>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }
                {
                    this.state.drawerVisible
                        ?
                        <Drawer
                            title={`分销员详情`}
                            closable={true}
                            width={`85%`}
                            placement={`right`}
                            visible={this.state.drawerVisible}
                            onClose={ this.handleDrawerClose }
                            headerStyle={{borderBottom:'0px'}}
                            bodyStyle={{padding:'0px'}}
                        >
                            <FxUserDetail
                                onRef = { e => { this.UserDetail= e } }
                                user_id={  this.state.currentRow ? this.state.currentRow.user_id : '' }
                                onClose={ this.handleDrawerClose }
                            ></FxUserDetail>
                        </Drawer>
                        :
                        null
                }



            </div>
        )
    }


}

export default Form.create({})(withRouter(MemberLists))
