import React, { Component } from 'react'
import { Modal, Divider, Row, Col ,Button} from 'antd'

export default class VerifyNote extends Component {
    state = {
        visible: false,
        article:{},
        callback:null
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    show = (params) => {
        this.setState({
            visible: true,
            article:params.article || {},
            callback: params.callback || null,
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
       
    }

    handleOk = () => {
        let callback=this.state.callback
        if(typeof callback == 'function'){
            callback(1)
        }
        this.handleCancel()
    }
    handleRefuse=()=>{
        let callback=this.state.callback
        if(typeof callback == 'function'){
            callback(4)
        }

        this.handleCancel()
    }

    render() {
        let article=this.state.article || {},
          content=article.content || [],
          author_info=article.author_info || {}

        return (
            <Modal
                title="审核笔记"
                visible={this.state.visible}
                onCancel={this.handleCancel}
                width={800}
                footer={[
                    <Button key="back" onClick={this.handleRefuse}>拒绝</Button>,
                    <Button key="submit" type="primary"  onClick={this.handleOk}>通过</Button>
                  ]}
            >
                <Row type="flex" justify="space-around" align="middle" className='mgb20'>
                    <Col span={12}>
                        <div className="flex align-center">
                            <span>笔记创作者：</span>
                            <img src={author_info.img} alt="" style={{ width: 60, borderRadius: "50%" }} className={'mgl20 mgr20'} />
                            <span>{author_info.name}</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="flex align-center">
                            <span>笔记封面：</span>
                            <img src={article.cover} alt="" style={{ width: 60 }} className={'mgl20'} />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row className='mgt10 mgb20'>
                    <Col span={20}>创建时间：{article.created_at}</Col>
                </Row>
                <Divider />
                <Row className='mgt10'>
                    <Col span={24}>
                        <div className="flex" style={{maxHeight:'400px',overflow:'auto'}}>
                            <span>笔记内容：</span>
                            <div className='flex1'>
                                {
                                    content.map((item,index)=>{
                                        return (
                                            <div key={ index +'a' } className=' mgb20'>
                                                {
                                                    item.type=='text'?
                                                    <p>{item.value}</p>:null
                                                }
                                                {
                                                    item.type=='img'?
                                                    <img src={item.value}  style={{width:'100%'}} alt="" />:null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        )
    }
}
