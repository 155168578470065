import $axios from '@/axios/axios.js'

export function stockDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/stockDetail`,
        method:'post',
        data
    })
}
export function priceDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/priceDetail`,
        method:'post',
        data
    })
}

export function addBookTime(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/addTime`,
        method:'post',
        data
    })
}
export function editBookTime(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/editTime`,
        method:'post',
        data
    })
}

export function addWeek(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/addWeek`,
        method:'post',
        data
    })
}

export function delWeek(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/delWeek`,
        method:'post',
        data
    })
}

export function delTime(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/delTime`,
        method:'post',
        data
    })
}

export function bookComboList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/comboList`,
        method:'post',
        data
    })
}

export function saveStock(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/saveStock`,
        method:'post',
        data
    })
}

export function savePrice(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/savePrice`,
        method:'post',
        data
    })
}

export function getSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/getSetting`,
        method:'post',
        data
    })
}

export function saveSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/saveSetting`,
        method:'post',
        data
    })
}

export function roomStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/roomStatus`,
        method:'post',
        data
    })
}

export function setDateStock(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/book/setDateStock`,
        method:'post',
        data
    })
}


