import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input, Form, Button } from 'antd'
import { modifyPwd, sendSms } from '@/api/admin'
import message from '@/utils/message'
const FormItem = Form.Item

class ChangePwd extends React.Component{
    state = {
        formData:'',
        title:'修改密码',
        visible:false,
        isLoading:false,
        modalLoading:false,
        seconds: 60, //倒计时
        isSendSms:false,
        _cx:'',
        callback:null,
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        this.setState({
            visible:true,
            callback : callback || null
        })
    }
    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }
    countDown = () => {
        let seconds = this.state.seconds;
        if(seconds <= 0){
            this.setState({
                seconds:60,
                isSendSms:false
            })
            return false;

        }else {
            this.setState({
                seconds:this.state.seconds-1
            })
            setTimeout(() => {
                this.countDown();
            },1000)

        }
    }
    sendSms = () => {
        let sendData = {},
            admin = this.props.admin || {}
        sendData.mobile = admin.mobile || '';
        if(this.state.isLoading){ return ; }
        this.setState({
            isLoading:true
        })
        sendSms(sendData)
            .then(res => {
                if(res.code == 1){
                    message.success('短信验证码已发送');
                    this.setState({
                        isSendSms:true,
                        _cx:res.data._cx || ''
                    })
                    this.countDown()
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })


    }
    handleOk = () => {
        let sendData = {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                if(values.set_password != values.confirm_password){
                    message.error('两次输入的密码不一致');
                    return ;
                }
                sendData = values;
                sendData._cx = this.state._cx;
                this.setState({
                    modalLoading:true
                })
                modifyPwd(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('修改成功')
                                .then(() => {
                                    this.props.history.push('/login')
                                    this.close()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            modalLoading:false
                        })
                    })
            }
        })
    }
    handleCancel = () => {
        this.close()
        this.props.form.resetFields();
    }
    render() {
        const admin = this.props.admin || {}
        const data = this.state.formData || {}
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`登录账号`}>
                        {getFieldDecorator('mobile', {
                            initialValue: admin.mobile  || '',
                        })(
                            <div>{ admin.mobile || '' }</div>
                        )}
                    </FormItem>
                    <FormItem label={`短信验证`}>
                        {getFieldDecorator('sms_code', {
                            initialValue: data.sms_code  || '',
                            rules: [
                                {
                                    required: true,
                                    message: '请输入短信验证码'
                                }
                            ],
                        })(
                            <Input placeholder={`短信验证码`} style={{ width:'150px' }} maxLength={6} />
                        )}
                        <Button
                            className={`mgl10`}
                            type={`primary`}
                            onClick={this.sendSms}
                            disabled={this.state.isSendSms}
                            loading={ this.state.isLoading }
                        >
                            {
                                this.state.isSendSms
                                    ?
                                    `重新发送${this.state.seconds}秒`
                                    :
                                    '获取验证码'
                            }
                        </Button>
                    </FormItem>
                    <FormItem label={`原密码`}>
                        {
                            getFieldDecorator('origin_password',{
                                initialValue: data.origin_password || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入原密码'
                                    }
                                ]
                            })(
                                <Input placeholder={`请输入原密码`} type={`password`} />
                            )
                        }
                    </FormItem>
                    <FormItem label={`新密码`}>
                        {
                            getFieldDecorator('set_password',{
                                initialValue: data.set_password || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入登录密码'
                                    }
                                ]
                            })(
                                <Input placeholder={`请输入登录密码`} type={`password`} />
                            )
                        }
                        <span className={`fi-help-text`}>密码必须包含字母和数字，长度在6~20位。</span>
                    </FormItem>
                    <FormItem label={`确认密码`}>
                        {
                            getFieldDecorator('confirm_password',{
                                initialValue: data.confirm_password || ``,
                                rules: [
                                    {
                                        required: true,
                                        message: '请再次输入密码'
                                    }
                                ]
                            })(
                                <Input placeholder={`请输入登录密码`} type={`password`} />
                            )
                        }
                        <span className={`fi-help-text`}>密码必须包含字母和数字，长度在6~20位。</span>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(ChangePwd))

