import React from 'react'
import { Form, Input, Select, DatePicker, Button, Tabs, Pagination, Tag, Icon, Tooltip, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { orderLists, addRemark, maxRefundMoney, orderPartRefund, orderExport, pickUpCode ,deliverTrade} from '@/api/order'
import { storeLists } from '@/api/store'
import { TRADE_TYPES } from '@/constants/trade'
import message from '@/utils/message'
import moment from 'moment'
import Loading from '@/components/Loading'
import Remark from './components/remark'
import Deliver from './components/deliver'
import ItemRefund from './components/item-refund'
import OrderUpload from './components/orderUpload'
import CX from '@/utils/CX'
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { TabPane } = Tabs

// 订单类型 1普通订单 2优惠券订单 3自助买单订单 4扫码下单订单 5KTV订单  8限时打折 9 积分兑换
//支付状态0待支付1已支付2已完成3申请退款4已退款5已部分退款6用户已取消7超时未支付自动关闭8订单过期(废弃)9待接单10待发货11已发货12待支付尾款
//goods_lists type:1-商品 2-套餐 3-优惠券 4-课程
class OrderLists extends React.Component{
    state = {
        activeStatus:'0',
        searchForm:{
            order_sn:'',
            goods_name:'',
            pay_type:'',
            user_name:'',
            user_mobile:'',
            receive_user_name:'',
            receive_user_mobile:'',
            trade_type:'',
            post_type:'',
            order_store_id:'',
            time_type:'',
            start_time:'',
            end_time:''
        },
        tableData:[],
        storeLists:[],
        isLoading:false,
        noData:false,
        page:1,
        total:0,
        per_page:10,
        lastRefundMoney:0,
        payMent:0,
        refundMoney:0
    }

    componentDidMount() {
        if(this.props.match.params.status){
            let status = this.props.match.params.status
            this.setState({
                activeStatus:String(status)
            },() => {
                this.fetchData()
            })
        }else {
            this.fetchData()
        }
        this.fetchStore();
    }
    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
        sendData.status = this.state.activeStatus;
        this.setState({
            isLoading:true
        })
        orderLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.trade_lists.data,
                        total = res.data.trade_lists.total,
                        per_page = res.data.trade_lists.per_page;
                    this.setState({
                        tableData:lists,
                        total,
                        per_page
                    })
                    if(lists.length < 1){
                        this.setState({
                            noData:true
                        })
                    }else {
                        this.setState({
                            noData:false
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    fetchStore = () => {
        let sendData = {}
        sendData.page = 1;
        sendData.per_page = 100;
        storeLists(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.store.data;
                    this.setState({
                        storeLists:lists
                    })
                }
            })
            .finally(() => {})
    }
    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }
    handleExport = () => {
        let sendData = {}
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                sendData = values;
                sendData.status = this.state.activeStatus;
                this.setState({
                    searchForm,
                    tableLoading:true
                })
                orderExport(sendData)
                    .then(res => {
                        let blob = new Blob([res], {type: 'application/vnd.ms-excel'}),
                            fileName = '订单导出.csv';
                        CX.exportExcel(blob,fileName)

                    })
                    .finally(() => {
                        this.setState({
                            tableLoading:false
                        })
                    })

            }
        })
    }
    handleDeliver = () => {
        this.OrderUpload.show(res => {
            this.fetchData();
        });
    }
    clickTab = val => {
        this.setState({
            activeStatus:val
        },() => {
            this.fetchData()
        })
    }
    handleCurrentChange = val => {
        this.setState({
            page:val
        },() => {
            this.fetchData();
        })
    }
    handleReset = () => {
        this.props.form.resetFields()
        let searchForm = this.state.searchForm;
        for (var i in searchForm) {
            searchForm[i] = ''
        }

        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }
    handleDetail = (rowData) => {
        this.props.history.push({
            pathname:`/order/detail/${rowData.order_sn || ''}`
        })

    }
    handleCopy = (text) => {
        CX.copyTxt(text);
        message.success('复制成功')
    }
    handleExpress = (item) => {
        let sendData = {}
        this.Deliver.show(res => {
            sendData.order_sn =item.order_sn;
            sendData.express_id = res.express_id || '';
            sendData.express_sn = res.express_sn || '';
            this.setState({
                isLoading:true
            })
            deliverTrade(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('标记发货成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })

        })
    }
    handleRemark = (rowData) => {
        let sendData = {};
        this.Remark.show(res => {
            sendData.remark = res.remark || '';
            sendData.order_sn = rowData.order_sn;
            if(!sendData.remark){ return false };
            this.setState({
                isLoading:true
            })
            addRemark(sendData)
                .then(res => {
                    if(res.code == 1){
                        message.success('标记成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading:false
                    })
                })
        })
    }
    handlePartRefund = (rowData) =>  {
        let sendData = {};
        sendData.refund_id = rowData.refund_id || ''
        this.setState({
            isLoading:true
        })
        maxRefundMoney(sendData)
            .then(res => {
                if(res.code == 1){
                    let last_refund_money = res.data.last_refund_money,
                        pay_ment = res.data.pay_ment,
                        refund_money = res.data.refund_money;
                    this.setState({
                        lastRefundMoney:last_refund_money,
                        payMent:pay_ment,
                        refundMoney:refund_money
                    },() => {
                        this.ItemRefund.show((values) =>  {
                            let postData = values;
                            postData.refund_id = rowData.refund_id || ''
                            this.setState({
                                isLoading:true
                            })
                            orderPartRefund(postData)
                                .then(result => {
                                    if(result.code == 1){
                                        message.success('操作成功')
                                            .then(() => {
                                                this.fetchData();
                                            })
                                    }
                                })
                                .finally(() => {
                                    this.setState({
                                        isLoading:false
                                    })
                                })
                        })
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handlePickUp = (rowData) => {
        let sendData = {};
        sendData.order_sn = rowData.order_sn;
        sendData.use_code = rowData.use_code || ``;
        Modal.confirm({
            title:'是否对这笔订单进行核销？',
            okType:'primary',
            onOk:() => {
                this.setState({
                    isLoading:true
                })
                pickUpCode(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('核销成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })

            }
        })
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys,selectedRows) => {
                this.setState({
                    selectRows:selectedRows,
                    selectedRowKeys
                })
            }
        }
        const { getFieldDecorator } = this.props.form
        return(
            <div className={`shadow-radius`}>
                <Remark onRef={ e => { this.Remark = e } }></Remark>
                <Deliver onRef={ e => { this.Deliver = e } }></Deliver>
                <ItemRefund
                    onRef={ e => { this.ItemRefund = e } }
                    refundMoney={ this.state.refundMoney }
                    lastRefundMoney={ this.state.lastRefundMoney }
                    payMent = { this.state.payMent }
                ></ItemRefund>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;商户单号</span>} className={'fl'} >
                                {
                                    getFieldDecorator('order_sn',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商户单号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;&emsp;商品名称</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('goods_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入商品名称/商品编码' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'支付方式'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('pay_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={2}>微信支付</Select.Option>
                                            <Select.Option value={4}>储值卡余额支付</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;客户昵称</span>} className={'fl'}>
                                {
                                    getFieldDecorator('user_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称/ID' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>&emsp;客户手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'订单类型'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('trade_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            {
                                                TRADE_TYPES.map((item,index) => (
                                                    <Select.Option value={item.trade_type} key={index}>{ item.name }</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={'收货人昵称'} className={'fl'}>
                                {
                                    getFieldDecorator('receive_user_name',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称/ID' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'收货人手机号'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('receive_user_mobile',{
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{width:'200px'}} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={'配送方式'} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('post_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>快递物流</Select.Option>
                                            <Select.Option value={2}>到店自提</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;时间筛选</span>} className={' fl'}>
                                {
                                    getFieldDecorator('time_type')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={1}>下单时间</Select.Option>
                                            <Select.Option value={2}>支付时间</Select.Option>
                                            <Select.Option value={3}>发货时间</Select.Option>
                                            <Select.Option value={4}>成交时间</Select.Option>
                                            <Select.Option value={5}>退款时间</Select.Option>
                                        </Select>
                                    )
                                }

                            </FormItem>
                            <FormItem className={' fl'}>
                                {
                                    getFieldDecorator('time',{
                                        initialValue:''
                                    })(
                                        <RangePicker className={`mgl10`}></RangePicker>
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;下单门店</span>} className={'fl'}>
                                {
                                    getFieldDecorator('order_store_id')(
                                        <Select style={{width:200}} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            {
                                                this.state.storeLists.map(store => (
                                                    <Select.Option value={store.id} key={store.id}>{store.name}</Select.Option>

                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem  className={'mgl10 fl'}>
                                <Button type='primary'  onClick={() => this.search()}>筛选</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => { this.handleReset() }}>重置</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleExport()}}>导出</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleDeliver()}}>批量发货</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <Tabs  onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                    <TabPane tab="全部" key={`0`}></TabPane>
                    <TabPane tab="待付款" key={`1`}></TabPane>
                    <TabPane tab="待接单" key={`2`}></TabPane>
                    <TabPane tab="待发货" key={`3`}></TabPane>
                    <TabPane tab="待使用" key={`4`}></TabPane>
                    <TabPane tab="待收货" key={`5`}></TabPane>
                    <TabPane tab="退款中" key={`6`}></TabPane>
                    <TabPane tab="交易成功" key={`7`}></TabPane>
                    <TabPane tab="交易关闭" key={`8`}></TabPane>
                </Tabs>
                <div className={''}>
                    <table className="cxtables cxtables-sku">
                        <colgroup>
                            <col width={`18%`} />
                            <col width={`8%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`10%`} />
                            <col width={`8%`} />
                            <col width={`8%`} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>商品信息</td>
                                <td>单价/数量</td>
                                <td>客户</td>
                                <td>支付信息</td>
                                <td>客户/收货人</td>
                                <td>下单门店</td>
                                <td>订单状态</td>
                                <td>操作</td>
                            </tr>
                        </thead>
                        {
                            this.state.noData ?
                                <tbody>
                                    <tr>
                                        <td colSpan={8}>
                                            <div className={`lh25 txtCenter`}>暂无订单数据</div>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                null
                        }

                    </table>
                    {
                        this.state.tableData.map((item,index) => (
                            <table className="cxtables cxtables-sku mgt10" key={index}>
                                <colgroup>
                                    <col width={`18%`} />
                                    <col width={`8%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`10%`} />
                                    <col width={`8%`} />
                                    <col width={`8%`} />
                                </colgroup>
                                <thead>
                                <tr>
                                    <td colSpan={8} className={`ftnormal clearfix`}>
                                        <Tag color={`#ff0000`}>{ item.trade_type_name }</Tag>
                                        <span>商户单号：<span>{ item.order_sn }</span></span>
                                        <Icon type="file-text" className={`mgl5 cursor`} onClick={ () => { this.handleCopy(item.order_sn) } } />
                                        <span className={`mgl10`}>下单时间：<span>{ item.created_at }</span></span>
                                        {
                                            item.remark
                                                ?
                                                <Tooltip placement="left" title={`${item.remark}`}>
                                                    <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/6b8f2e533456302124ee110cd006b18f.png" width={20} className={`fr cursor`} alt=""/>
                                                </Tooltip>
                                                :
                                                <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/610b1cfaa3f361987ea789c8cacd7a8a.png" width={20} className={`fr cursor`} alt="" onClick={ () => { this.handleRemark(item) } }/>
                                        }
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={`noPadding bd-right`} colSpan={2}>
                                        <div className={`clearfix`}>
                                            {
                                                item.trade_type == 2
                                                    ?
                                                    <div className={`clearfix bd-bottom`}>
                                                        <div className={`flex jusify-start align-center td-item fl`} >
                                                            <img src='https://img.cxkoo.com/chengxuan/1/2021-12-01/6475deea89e063313535f1833bd5a682.png' alt="" width={60}/>
                                                            <div className={`mgl10`}>
                                                                <div className={`lh25`}>优惠券</div>
                                                            </div>
                                                        </div>
                                                        <div className={`td-price fr`}>
                                                            <div className={`lh25 txtRight`}>&yen;{ item.pay_ment }</div>
                                                            <div className={`lh25 txtRight`}>x1</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                item.trade_type == 3
                                                    ?
                                                    <div className={`clearfix bd-bottom`}>
                                                        <div className={`flex jusify-start align-center td-item fl`} >
                                                            <img src='https://img.cxkoo.com/chengxuan/1/2021-12-01/5de35c6664440f9b23ca979c1006651b.png' alt="" width={60}/>
                                                            <div className={`mgl10`}>
                                                                <div className={`lh25`}>自助买单</div>
                                                            </div>
                                                        </div>
                                                        <div className={` td-price fr`}>
                                                            <div className={`lh25 txtRight`}>&yen;{ item.pay_ment }</div>
                                                            <div className={`lh25 txtRight`}>x1</div>
                                                        </div>
                                                    </div>

                                                    :
                                                    null
                                            }
                                            {
                                                [6,7].includes(item.trade_type)
                                                    ?
                                                    <div className={`clearfix bd-bottom`}>
                                                        <div className={`flex jusify-start align-center td-item fl`} >
                                                            <img src='https://img.cxkoo.com/chengxuan/1/2022-01-11/5161fc5d824d2409129d1c98e1117a2e.png' alt="" width={60}/>
                                                            <div className={`mgl10`}>
                                                                <div className={`lh25`}>{ item.card_info?.name }</div>
                                                            </div>
                                                        </div>
                                                        <div className={` td-price fr`}>
                                                            <div className={`lh25 txtRight`}>&yen;{ item.pay_ment }</div>
                                                            <div className={`lh25 txtRight`}>x1</div>
                                                        </div>
                                                    </div>

                                                    :
                                                    null
                                            }
                                            {
                                                [11].includes(item.trade_type)
                                                    ?
                                                    <div className={`clearfix bd-bottom`}>
                                                        <div className={`flex jusify-start align-center td-item fl`} >
                                                            <img src='https://img.cxkoo.com/chengxuan/1/2022-04-15/c75f123a37f87cb9c6dc721536b82f0b.png' alt="" width={60}/>
                                                            <div className={`mgl10`}>
                                                                <div className={`lh25`}>{ item.card_info?.name }</div>
                                                            </div>
                                                        </div>
                                                        <div className={` td-price fr`}>
                                                            <div className={`lh25 txtRight`}>&yen;{ item.pay_ment }</div>
                                                            <div className={`lh25 txtRight`}>x1</div>
                                                        </div>
                                                    </div>

                                                    :
                                                    null
                                            }

                                            {
                                                item.goods_lists && item.goods_lists.map((good,goodIndex) => (
                                                    <div className={`clearfix bd-bottom`} key={goodIndex}>
                                                        <div className={`flex jusify-start align-center td-item fl`} style={{ width:'75%' }}>
                                                            {
                                                                good.type && good.type == 3
                                                                    ?
                                                                    <img src="https://img.cxkoo.com/chengxuan/1/2022-02-16/98cd675ad8e95215d35a76b5b85d9127.png" alt="" width={60}/>
                                                                    :
                                                                    <img src={good.cover_img} alt="" width={60}/>
                                                            }
                                                            <div className={`mgl10`}>
                                                                {
                                                                    good.type && good.type == 3
                                                                        ?
                                                                        <Link to={`/coupon/add/${good.id}`} target={`_blank`} className={`fi-link lh25`}>
                                                                            {good.name || ''}
                                                                        </Link>
                                                                        :
                                                                        <Link to={`/item/add/${good.id}`} target={`_blank`} className={`fi-link lh25`} >
                                                                            {good.name || ''}
                                                                            {
                                                                                good.marketing_type == 1
                                                                                    ?
                                                                                    <Tag className={`mgl5`} color={'red'}>限时抢购</Tag>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </Link>
                                                                }
                                                                <div className={`lh25`}>{ good.sku_info ? good.sku_info.name : '' }</div>
                                                                <div className={`lh25`}>商品编码：{  good.sku_info ? good.sku_info.goods_sku_code : good.goods_code ? good.goods_code : '--'  }</div>
                                                            </div>
                                                        </div>
                                                        <div className={` td-price fr`}>
                                                            {
                                                                item.trade_type == 9
                                                                    ?
                                                                    null
                                                                    :
                                                                    <div className={`lh25 txtRight`}>&yen;{ good.price }</div>
                                                            }
                                                            <div className={`lh25 txtRight`}>x{ good.num }</div>
                                                            {
                                                                good.refund_info
                                                                    ?
                                                                    <div className={`lh25 txtRight colorRed`}>已退{ good.refund_info.refund_num }件</div>
                                                                    :
                                                                    null
                                                            }
                                                            <div className={`lh25 txtRight`}>
                                                                {
                                                                    item.trade_type == 4 && [1,2,5].includes(item.trade_status)
                                                                        ?
                                                                        <Button size={`small`} onClick={ () => { this.handlePartRefund(good) } }>单品退款</Button>
                                                                        :
                                                                        null
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className={`lh25`}>{ item.user_info.name }</div>
                                        <div className={`lh25`}>{ item.user_info.mobile }</div>
                                    </td>
                                    <td>
                                        {
                                           [0,6,7,8].includes(item.trade_status)
                                                ?
                                               <div>
                                                   {
                                                       item.trade_type == 9
                                                           ?
                                                           <div className={`lh25 ft-bold`}>待支付：￥{ item.pay_ment } + { item.trade_extends.cost_points }积分</div>
                                                           :
                                                           <div className={`lh25 ft-bold`}>待支付：￥{ item.pay_ment }</div>

                                                   }
                                               </div>
                                                :
                                               <div>
                                                   {
                                                       item.trade_type == 9
                                                            ?
                                                           <div className={`lh25 ft-bold`}>支付合计：￥{ item.pay_ment } + { item.trade_extends.cost_points }积分</div>
                                                           :
                                                           <div className={`lh25 ft-bold`}>支付合计：￥{ item.pay_ment }</div>
                                                   }
                                                   {
                                                       item.post_fee > 0
                                                            ?
                                                           <div className={`lh25`}>（含快递：&yen;{ item.post_fee }）</div>
                                                           :
                                                           null

                                                   }

                                               </div>
                                        }
                                        {
                                            item.trade_extends && item.refund_price > 0
                                                ?
                                                <div className={`lh25 colorRed`}>退款金额：￥{ item.refund_price || '-' }</div>
                                                :
                                                null
                                        }
                                        <div className={`lh25`}>支付方式：{ item.pay_type_name || '-' }</div>
                                        <div className={`lh25`}>支付时间：{ item.pay_time || '-' }</div>
                                        {
                                           [1,15].includes(item.trade_type)
                                                ?
                                                <div className={`lh25`}>配送方式：{ item.post_type == 1 ? '快递物流':'到店自提' }</div>
                                                :
                                                null
                                        }
                                        {
                                            [2,3].includes(item.trade_type)
                                                ?
                                                <div className={`lh25`}>配送方式：无需物流</div>
                                                :
                                                null
                                        }
                                        {
                                            item.trade_type == 4
                                                ?
                                                <div className={`lh25`}>配送方式：堂食</div>
                                                :
                                                null
                                        }

                                    </td>
                                    <td>
                                        <div className={`lh25`}>{ item.user_address ? item.user_address.name : '-' }</div>
                                        <div className={`lh25`}>{ item.user_address ? item.user_address.mobile : '-' }</div>
                                        <div className={`lh25`}>{ item.user_address ? item.user_address.address : '-' }</div>
                                        {
                                            item.trade_type == 4
                                                ?
                                                <div className={`lh25`}>包厢/桌台：{ item.desk_info ? item.desk_info.room.name:'-' }{ item.desk_info ? item.desk_info.desk_name : '-' } </div>
                                                :
                                                null
                                        }
                                        {
                                            item.user_remark
                                                ?
                                                <div className={`lh25`}>
                                                    <Tooltip placement="right" title={`${item.user_remark}`}>
                                                        <img src="https://img.cxkoo.com/chengxuan/1/2021-11-05/0ad4ac21baead02dd06edf8431a7a1ed.png" alt="" width={20} className={`cursor`} />
                                                    </Tooltip>
                                                </div>
                                                :
                                                null
                                        }

                                    </td>
                                    <td>
                                        <div className={`lh25`}>{ item.storeInfo ? item.storeInfo.name : '-' }</div>
                                    </td>
                                    <td>
                                        {
                                            item.trade_type==14 && item.group_trade && (!item.group_trade.success_at) && (![4,6,7,8].includes(item.trade_status))?
                                                <div>
                                                     <Tag>未成团</Tag>
                                                </div>:
                                                 <div>
                                                    {
                                                    [1,2].includes(item.trade_status)
                                                            ?
                                                        <Tag color="green">{ item.trade_status_name }</Tag>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        [0,4,6,7,8].includes(item.trade_status)
                                                            ?
                                                            <Tag>{ item.trade_status_name }</Tag>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        [3,5,9,10,11,12].includes(item.trade_status)
                                                            ?
                                                            <Tag color="orange">{ item.trade_status_name }</Tag>
                                                            :
                                                            null
                                                    }
                                                </div>
                                        }
                                       
                                    </td>
                                    <td>
                                        {
                                            item.trade_status == 1 && item.post_type == 2
                                                ?
                                                <div className={`btn-row mgb5`}>
                                                    <span className={`fi-link fz14 w150`} onClick={() => {this.handlePickUp(item)}}>在线核销</span>
                                                </div>
                                                :
                                                null
                                        }
                                         {
                                            item.trade_status== 10
                                                ?
                                                <div className={`btn-row mgb5`}>
                                                    <span className={`fi-link fz14 w150`} onClick={() => {this.handleExpress(item)}}>标记发货</span>
                                                </div>
                                                :
                                                null
                                        }
                                        <div className={'btn-row'}>
                                            <Link to={`/order/detail/${item.order_sn}`} target={`_blank`} className={`fi-link fz14 w150`}>查看详情</Link>
                                        </div>
                                    </td>


                                </tr>
                                </tbody>
                            </table>
                        ))
                    }

                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div>
                { this.state.isLoading ? <Loading /> : null }

                <OrderUpload onRef={e => { this.OrderUpload = e }} />
            </div>
        )
    }


}

export default Form.create({})(withRouter(OrderLists))
