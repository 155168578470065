/**
 * 笔记列表组件
 * @type 18
 * @return Object
 */

 import React from 'react'
 import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip } from 'antd'
 import PropTypes from "prop-types";
 import ItemPicker from '@/components/ItemPicker/ItemPicker'
 import NotePicker from '@/components/notePicker/notePicker'
 import PictureCard from '@/components/PictureCard/PictureCard'
 const FormItem = Form.Item
 const ctrlItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 17 } }
 
 class tpl_ctrl_type18 extends React.Component {
     static contextTypes = {
         updateDesign: PropTypes.func
     }
 
     state = {
         moduleData: {},
         itemStyleGroup: [
             {
                 name: '瀑布流',
                 style: 1,
                 imgs: ['https://img.cxkoo.com/chengxuan/1/2021-11-22/ea5d1c9940155160ec729f67cb3f7ff5.png', 'https://img.cxkoo.com/chengxuan/1/2021-11-22/32854ed39350a6a2bbc22da3654d9fb0.png']
             },
            
             {
                 name: '列表',
                 style: 2,
                 imgs: ['https://img.cxkoo.com/chengxuan/1/2021-11-22/f7dcf64fdd72cbb723424b305555ad8e.png', 'https://img.cxkoo.com/chengxuan/1/2021-11-22/0b322df6db5236e45ca4b83d6e0e9b05.png']
             }
         ]
     }
 
     componentDidMount() {
         this.props.onRef(this)
         let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
     componentWillReceiveProps(nextProps, nextContext) {
         let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
         tmp && this.setState({ moduleData: JSON.parse(tmp) })
     }
 
     changePadding = val => {
         let moduleData = this.state.moduleData;
         moduleData.content.modulePadding = val;
         this.context.updateDesign(moduleData)
     }
     addItem = (item, index) => {
         let moduleData = this.state.moduleData;
         this.ItemPicker.show(res => {
             if (res.length) {
                 moduleData.content.dataset = res.slice(0,10);
                 this.context.updateDesign(moduleData)
             }
         })
     }
    
     handleStyleChange = (e, name) => {
         let value = e.target.value,
             moduleData = this.state.moduleData;
         moduleData.content[name] = value;
         this.context.updateDesign(moduleData)
 
     }
     handleItemStyle = (val) => {
         let moduleData = this.state.moduleData;
         moduleData.content.style = val;
         this.context.updateDesign(moduleData)
     }
 
     delImg = (e) => {
         let moduleData = this.state.moduleData,
             index = e.index || 0
         moduleData.content.dataset.splice(index, 1);
         this.context.updateDesign(moduleData)
     }
 
     handleSort = (e) => {
         let moduleData = this.state.moduleData,
             dataset = moduleData.content.dataset || [],
             oldIndex = e.oldIndex,
             newIndex = e.newIndex;
         if (oldIndex == newIndex) {
             return;
         }
         let targetRow = dataset.splice(oldIndex, 1)[0];
         dataset.splice(newIndex, 0, targetRow);
         moduleData.content.dataset = dataset;
         this.context.updateDesign(moduleData)
     }
 
 
     render() {
         let moduleData = this.state.moduleData || {}
         return (
             Object.keys(moduleData).length
                 ?
                 <div>
                     <div className={`ctrl-right-title`}>笔记列表</div>
                     <Form>
                         <div className={`cx-ctrl-panel`}>
                             <div className={`cx-ctrl-panel--box`}>
                                 <FormItem label={`笔记列表样式`}>
                                     <ul className={`template-box`}>
                                         {
                                             this.state.itemStyleGroup.map((item, index) => (
                                                 <Tooltip effect="dark" placement="bottom" title={item.name} key={index}>
                                                     <li className={`temp ${moduleData.content.style == item.style ? 'selected' : ''}`} onClick={() => { this.handleItemStyle(item.style) }}>
                                                         {
                                                             moduleData.content.style == item.style
                                                                 ?
                                                                 <img src={item.imgs[0]} className={`img`} alt="" />
                                                                 :
                                                                 <img src={item.imgs[1]} className={`img`} alt="" />
 
                                                         }
                                                     </li>
                                                 </Tooltip>
                                             ))
                                         }
                                     </ul>
                                 </FormItem>
                                 <FormItem label={`组件间距`}>
                                     <div className={`cx-slider`}>
                                         <Slider
                                             min={0}
                                             max={20}
                                             value={moduleData.content.modulePadding}
                                             onChange={this.changePadding}
                                             className={`cx-slider-main`}
                                         ></Slider>
                                         <InputNumber
                                             min={0}
                                             max={20}
                                             style={{ width: 60 }}
                                             value={moduleData.content.modulePadding}
                                             onChange={this.changePadding}
                                         />
                                     </div>
                                 </FormItem>
                                
                                 <FormItem label={`点赞量`} {...ctrlItemLayout}>
                                     <Radio.Group value={moduleData.content.showLike} onChange={(e) => { this.handleStyleChange(e, 'showLike') }}>
                                         <Radio value={1}>显示</Radio>
                                         <Radio value={0}>不显示</Radio>
                                     </Radio.Group>
                                 </FormItem>
                                 
                                 <FormItem label={`浏览量`} {...ctrlItemLayout}>
                                     <Radio.Group value={moduleData.content.showPv} onChange={(e) => { this.handleStyleChange(e, 'showPv') }}>
                                         <Radio value={1}>显示</Radio>
                                         <Radio value={0}>不显示</Radio>
                                     </Radio.Group>
                                 </FormItem>
                             </div>
                         </div>
                         <div className={`cx-ctrl-panel`}>
                             <div className={`cx-ctrl-panel--title`}>添加笔记</div>
                             <div className={`cx-ctrl-panel--box`}>
                                 <div className={`cx-img-list`} >
                                     <div className={`cx-img-item clearfix`}>
                                         <FormItem label={`选择笔记`} {...ctrlItemLayout}>
                                             <div className={'clearfix'}>
                                                 <PictureCard
                                                     onRef={e => { this.PictureCard = e }}
                                                     imgs={moduleData.content.dataset}
                                                     onDel={e => { this.delImg(e) }}
                                                     onSort={e => { this.handleSort(e) }}
                                                     item_key={'cover'}
                                                     item_id={'id'}
                                                 ></PictureCard>
                                                 {
                                                    moduleData.content.dataset.length >= 10?null:
                                                    <div className={'img-add'} onClick={this.addItem} data-name="pic">+</div>
                                                 }
                                                
                                             </div>
                                         </FormItem>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </Form>
                     <NotePicker
                         onRef={e => { this.ItemPicker = e }}
                         selectIds={moduleData.content.dataset.length ? moduleData.content.dataset.map(item => { return Number(item.id) }) : []}
                         items={moduleData.content.dataset.length ? moduleData.content.dataset : []}
                         multiple={true}
                     ></NotePicker>
                 </div>
                 :
                 null
         )
     }
 }
 
 export default Form.create({})(tpl_ctrl_type18)
 