import React, { Component } from 'react'
import { Form, Input, Select, DatePicker, Button, Tabs, Pagination, Tag, Table } from 'antd'
import moment from 'moment'
import {tiktokOrderRecord} from '@/api/order'
const FormItem = Form.Item
const { RangePicker } = DatePicker

class TikTokOrder extends Component {
    state={
        columns:[
            {
                title: '订单号',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.order_sn || '-'}</p>
                    )
                }
            },
            {
                title: '转换时间',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.exchange_time || '-'}</p>
                    )
                }
            },
            {
                title: '订单金额',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.pay_ment || '-'}</p>
                    )
                }
            },
            {
                title: '客户信息',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                       <div>
                         <p>{rowData.name || '-'}</p>
                         <p>{rowData.mobile || '-'}</p>
                       </div>
                    )
                }
            },
            {
                title: '转换积分',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.exchange_points }</p>
                    )
                }
            },
            {
                title: '转换状态',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <Tag color='green'>转换成功</Tag>
                    )
                }
            },
            {
                title: '转换类型',
                dataIndex: '7',
                render: (text, rowData, index) => {
                    return (
                        <p>{rowData.exchange_type_name}</p>
                    )
                }
            },
        ],
        tableData:[],
        tableLoading:false,
        page:1,
        total:0,
        per_page:15,
        searchForm:{

        },
        exchange_info:{}
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {};
        sendData = this.state.searchForm;
        sendData.page = this.state.page;
       sendData.per_page=this.state.per_page;
        this.setState({
            tableLoading:true
        })
        tiktokOrderRecord(sendData)
            .then(res => {
                if(res.code == 1){
                    let lists = res.data.trade_list.data,
                        total = res.data.trade_list.total,
                        per_page = res.data.trade_list.per_page,
                        exchange_info=res.data.exchange_info;
                    this.setState({
                        tableData:lists,
                        total,
                        per_page,
                        exchange_info
                    })
                  
                }
            })
            .finally(() => {
                this.setState({
                    tableLoading:false
                })
            })
    }

    search = () => {
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.start_time = values.time ? moment(values.time[0]).format('YYYY-MM-DD') : ''
                values.end_time = values.time ? moment(values.time[1]).format('YYYY-MM-DD') : ''
                let searchForm = values;
                this.setState({
                    searchForm,
                    page:1
                },() => {
                    this.fetchData();
                })
            }
        })
    }

    handleReset = () => {
        this.props.form.resetFields()
        let searchForm ={};
        this.setState({
            searchForm,
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    handleCurrentChange = val => {
        this.setState({
            page: val
        }, () => {
            this.fetchData()
        })
    }

    render() {


        const { getFieldDecorator } = this.props.form
        return (
            <div className={`shadow-radius`}>
                <div className={'content-head mgb15'}>
                    <Form className={'search-form'}>
                        <div className={`clearfix`}>
                            <FormItem label={<span>转换订单号</span>} className={'fl'} >
                                {
                                    getFieldDecorator('order_sn', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入转换订单号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>客户昵称</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('name', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入昵称' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>客户手机号</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('mobile', {
                                        initialValue: ''
                                    })(
                                        <Input placeholder='请输入手机号' style={{ width: '200px' }} />
                                    )
                                }
                            </FormItem>
                        </div>
                        <div className={`clearfix`}>
                            <FormItem label={<span>&emsp;转换类型</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('exchange_type')(
                                        <Select style={{ width: 200 }} placeholder={'请选择'}>
                                            <Select.Option value={0}>全部</Select.Option>
                                            <Select.Option value={1}>用户手动转换</Select.Option>
                                            <Select.Option value={2}>自动转换</Select.Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem label={<span>转换时间</span>} className={'mgl20 fl'}>
                                {
                                    getFieldDecorator('time', {
                                        initialValue: ''
                                    })(
                                        <RangePicker className={`mgl10`}></RangePicker>
                                    )
                                }
                            </FormItem>

                        </div>

                        <div className={`clearfix`} >
                            <FormItem className={'mgl80 fl'} >
                                <Button type='primary' onClick={() => this.search()}>查询</Button>
                                <Button type={"default"} className={'mgl10'} onClick={() => {this.handleReset()}}>重置</Button>
                            </FormItem>
                        </div>
                    </Form>
                </div>
                <div className="flex fz18">
                    <div className="flex1 content-head pd30">
                        <p>转换成功订单数</p>
                        <p className='fz22 ft-bold color-primary mgt15'>{this.state.exchange_info?.exchange_num || 0}</p>
                    </div>
                    <div className="flex1 content-head mgl20 pd30">
                        <p>转换成功总积分</p>
                        <p className='fz22 ft-bold color-primary mgt15'>{this.state.exchange_info?.exchange_points || 0}</p>
                    </div>
                </div>
                <div className={'content-body mgt15'}>
                    <Table
                        size={`middle`}
                        columns={this.state.columns}
                        dataSource={this.state.tableData}
                        loading={this.state.tableLoading}
                        rowKey={record => record.id}
                        pagination={false}
                      
                    ></Table>
                </div>
                <div className={'clearfix mgt10'}>
                    <div className={'fr'}>
                        {
                            this.state.tableData.length
                                ?
                                <Pagination showQuickJumper current={this.state.page} total={this.state.total} pageSize={this.state.per_page} onChange={this.handleCurrentChange} />
                                :
                                ''
                        }
                    </div>
                </div> 
            </div>
        )
    }
}

export default Form.create({})(TikTokOrder)
