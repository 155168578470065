import $axios from '@/axios/axios.js'
import Cookie from 'js-cookie'
import CX from '@/utils/CX'

export function gradeApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade`,
        method:'post',
        data
    })
}
export function gradeSaveApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/save`,
        method:'post',
        data
    })
}

export function gradeDetailApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/detail`,
        method:'post',
        data
    })
}

export function gradeDeleteApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/delete`,
        method:'post',
        data
    })
}

export function savePosition(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/teacher/savePosition`,
        method:'post',
        data
    })
}

export function getPosition(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/teacher/position`,
        method:'post',
        data
    })
}

export function delePosition(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/teacher/deletePosition`,
        method:'post',
        data
    })
}

export function gradeStudentApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/student`,
        method:'post',
        data
    })
}


export function gradeAddStudent(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/addStudent`,
        method:'post',
        data
    })
}


export function gradeMoveStudent(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/moveStudent`,
        method:'post',
        data
    })
}

export function gradeStudentList(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/studentList`,
        method:'post',
        data
    })
}

export function gradeSetTable(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/setTable`,
        method:'post',
        data
    })
}

export function gradeEditTable(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/editTable`,
        method:'post',
        data
    })
}

export function gradeDeleTable(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/deleteTable`,
        method:'post',
        data
    })
}

export function gradeCourseTable(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/courseTable`,
        method:'post',
        data
    })
}

export function gradeCourseRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/courseRecord`,
        method:'post',
        data
    })
}

export function gradeTableStudent(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/tableStudent`,
        method:'post',
        data
    })
}

export function gradeSign(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/sign`,
        method:'post',
        data
    })
}

export function teacherApi(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/teacher`,
        method:'post',
        data
    })
}

export function teacherDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/teacher/detail`,
        method:'post',
        data
    })
}

export function teacherSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/teacher/save`,
        method:'post',
        data
    })
}

export function signSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/signSetting`,
        method:'post',
        data
    })
}

export function saveSignSetting(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/saveSignSetting`,
        method:'post',
        data
    })
}


export function downloadQrcode(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/downloadQrcode`,
        method:'post',
        data
    })
}


export function downloadSignTemplate(params={}) {
    params.api_token = Cookie.get('token') || ``;
    let url=`${process.env.REACT_APP_BASE_API}/mp/grade/downloadSignTemplate?t=${CX.genUUID()}`
    for (const key in params) {
       url=url+"&"+key+"="+params[key]
    }
    return url
}

export function studentApplyOff(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/applyOff`,
        method:'post',
        data
    })
}

export function studentApplyDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/applyDetail`,
        method:'post',
        data
    })
}

export function studentAgreeApply(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/agreeApply`,
        method:'post',
        data
    })
}

export function studentRefuseApply(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/refuseApply`,
        method:'post',
        data
    })
}


export function gradeExportTable(params) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/exportTable`,
        method:'get',
        params,
        responseType:'blob',
    })
}


export function studentSign(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/student/sign`,
        method:'post',
        data
    })
}

export function clearGradeTable(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/clearTable`,
        method:'post',
        data
    })
}

export function searchGradeStudent(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/searchGradeStudent`,
        method:'post',
        data
    })
}

export function physicalItem(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-item`,
        method:'post',
        data
    })
}
export function physicalItemSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-item/save`,
        method:'post',
        data
    })
}
export function physicalItemDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-item/delete`,
        method:'post',
        data
    })
}

export function physicalForm(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-form`,
        method:'post',
        data
    })
}
export function physicalFormSave(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-form/save`,
        method:'post',
        data
    })
}
export function physicalFormDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-form/detail`,
        method:'post',
        data
    })
}
export function physicalFormSetStatus(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-form/setStatus`,
        method:'post',
        data
    })
}
export function physicalFormDelete(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-form/delete`,
        method:'post',
        data
    })
}
export function physicalFormCopy(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/physical-form/copy`,
        method:'post',
        data
    })
}

export function gradeCopy(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/grade/copy`,
        method:'post',
        data
    })
}