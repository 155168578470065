/**
 * 社群团购
 * @type 23
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip } from 'antd'
import PropTypes from "prop-types";
import GroupSolitairePicker from '@/components/groupSolitairePicker/groupSolitairePicker';
import ColorPicker from '@/components/ColorPicker/ColorPicker'
import PictureCard from '@/components/PictureCard/PictureCard'
const FormItem = Form.Item
const ctrlItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

class tpl_ctrl_type23 extends React.Component {
    static contextTypes = {
        updateDesign: PropTypes.func
    }

    state = {
        moduleData: {}
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    addItem = (item, index) => {
        let moduleData = this.state.moduleData;
        this.ItemPicker.show(res => {
            if (res.length) {
                let lists=res.splice(0,6);
                lists.forEach(item=>{
                    if(item.goods_list && item.goods_list.length){
                        item.cover_img=item.goods_list[0]?.cover_img
                    }
                })
                moduleData.content.dataset = lists;
                this.context.updateDesign(moduleData)
            }
        })

    }
   
    handleStyleChange = (e, name) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData)

    }
    handleItemStyle = (val) => {
        let moduleData = this.state.moduleData;
        moduleData.content.style = val;
        this.context.updateDesign(moduleData)

    }

    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }

    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }

    delImg = (e) => {
        let moduleData = this.state.moduleData,
            index = e.index || 0
        moduleData.content.dataset.splice(index, 1);
        this.context.updateDesign(moduleData)
    }

    handleSort = (e) => {
        let moduleData = this.state.moduleData,
            dataset = moduleData.content.dataset || [],
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = dataset.splice(oldIndex, 1)[0];
        dataset.splice(newIndex, 0, targetRow);
        moduleData.content.dataset = dataset;
        this.context.updateDesign(moduleData)
    }


    render() {
        let moduleData = this.state.moduleData || {}
        return (
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>老师</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--box`}>
                               

                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width: 60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={this.changePadding}
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`组件样式`} {...ctrlItemLayout} className={"mgt20"} >
                                    <Radio.Group buttonStyle="solid" value={moduleData.content.borderStyle} onChange={(e) => { this.handleStyleChange(e, 'borderStyle') }}>
                                        <Radio.Button value={1}>无边</Radio.Button>
                                        <Radio.Button value={2}>卡片</Radio.Button>
                                        <Radio.Button value={3}>描边</Radio.Button>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`活动浏览量`} {...ctrlItemLayout}>
                                    <Radio.Group value={moduleData.content.showPv} onChange={(e) => { this.handleStyleChange(e, 'showPv') }}>
                                        <Radio value={1}>显示</Radio>
                                        <Radio value={0}>不显示</Radio>
                                    </Radio.Group>
                                </FormItem>
                                <FormItem label={`跟团人数`} {...ctrlItemLayout}>
                                    <Radio.Group value={moduleData.content.showGroupNum} onChange={(e) => { this.handleStyleChange(e, 'showGroupNum') }}>
                                        <Radio value={1}>显示</Radio>
                                        <Radio value={0}>不显示</Radio>
                                    </Radio.Group>
                                </FormItem>

                               
                                <FormItem label={`按钮颜色`} {...ctrlItemLayout} >
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnColor} callback={(color) => { this.handleColorChange(color, 'btnColor') }} ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('btnColor','#FF3636')}>重置</Button>
                                </FormItem>

                                <FormItem label={`按钮文字颜色`} {...ctrlItemLayout}>
                                    <ColorPicker  style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnTextColor} callback={(color) => { this.handleColorChange(color, 'btnTextColor') }} ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('btnTextColor','#FFFFFF')}>重置</Button>
                                </FormItem>

                            </div>
                        </div>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--title`}>
                                <p>添加活动</p>
                                <p className='fz13'>鼠标拖拽可调整活动顺序，最多可选6个</p>
                            </div>
                            
                            <div className={`cx-ctrl-panel--box`}>
                                <div className={`cx-img-list`} >
                                    <div className={`cx-img-item clearfix`}>
                                        <FormItem label={`选择活动`} {...ctrlItemLayout}>
                                            <div className={'clearfix'}>

                                                <PictureCard
                                                    onRef={e => { this.PictureCard = e }}
                                                    imgs={moduleData.content.dataset}
                                                    onDel={e => { this.delImg(e) }}
                                                    onSort={e => { this.handleSort(e) }}
                                                    item_key={'cover_img'}
                                                    item_id={'id'}
                                                ></PictureCard>
                                                
                                                {
                                                    moduleData.content.dataset.length >=6? null :<div className={'img-add'} onClick={this.addItem} data-name="pic">+</div>
                                                }
                                                
                                            </div>
                                        </FormItem>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>

                    <GroupSolitairePicker
                        onRef={e => { this.ItemPicker = e }}
                        selectIds={moduleData.content.dataset.length ? moduleData.content.dataset.map(item => { return Number(item.id) }) : []}
                        items={moduleData.content.dataset.length ? moduleData.content.dataset : []}
                        multiple={true}

                    ></GroupSolitairePicker>
                </div>
                :
                null
        )
    }
}

export default Form.create({})(tpl_ctrl_type23)
