import React, { Component } from 'react';
import { Button } from 'antd';
import AddHot from './components/addHot';
import Sortable from 'sortablejs';
import Loading from '@/components/Loading';
import {getConfig,setConfig} from '@/api/config';
import '@/assets/css/design/search.scss'

export default class SearchSet extends Component {
    state = {
        hotLists: [],
        isLoading:false
    }

    componentDidMount() {
        this.initSort()
        this.fetchData()
    }

    fetchData=()=>{
        this.setState({
            isLoading:true
        })
        getConfig({key:"hot_search_keywords"}).then(res=>{
            if(res.code==1){
                let {config}=res.data,
                 value=config && config.value;
                if(typeof value == 'string'){
                    value=JSON.parse(value)
                    this.setState({
                        hotLists:value
                    })
                }
            }
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }

    handleAdd = () => {
        this.AddHot.show(res => {
            let hotLists = this.state.hotLists;
            if (hotLists.length > 9) {
                return
            }
            res.id = Date.now() + '' ;
            res.pv=0;
            hotLists.push(res);
            this.setState({
                hotLists
            },()=>{
                this.updateConfig()
            })
        })
    }
    handleDele = index => {
        let hotLists = this.state.hotLists;
        hotLists.splice(index, 1)
        this.setState({
            hotLists
        },()=>{
            this.updateConfig()
        })
    }
    initSort = () => {
        let sortable = new Sortable(this.refs.userSearchTable, {
            group: 'shared', // set both lists to same group
            animation: 150,
            onEnd: (event) => {
                this.handleSortEnd(event)
            }
        })
    }
    handleSortEnd = e => {
        let hotLists = this.state.hotLists,
            oldIndex = e.oldIndex,
            newIndex = e.newIndex;
        if (oldIndex == newIndex) {
            return;
        }
        let targetRow = hotLists.splice(oldIndex, 1)[0];
        hotLists.splice(newIndex, 0, targetRow);
        this.setState({
            hotLists
        },()=>{
            this.updateConfig()
        })
    }
    updateConfig=()=>{
        let hotLists = this.state.hotLists;
        this.setState({
            isLoading:true
        })
        setConfig({key:"hot_search_keywords",value:hotLists}).then(res=>{
        }).finally(()=>{
            this.setState({
                isLoading:false
            })
        })
    }


    render() {

        return (
            <div className={'shadow-radius'} >
                <AddHot onRef={e => this.AddHot = e} ></AddHot>
                <div className={`msg-title `}>热门搜索词设置</div>
                <div className="flex mgt20">
                    <div className='flex1'>
                        <table className='cxtables cxtables-sku'>
                            <colgroup>
                                <col width={`30%`} />
                                <col width={`30%`} />
                                <col width={`20%`} />
                                <col width={`20%`} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td>热词名称</td>
                                    <td>跳转链接</td>
                                    <td>点击次数</td>
                                    <td>操作</td>
                                </tr>
                            </thead>
                            <tbody ref={'userSearchTable'} >
                                {
                                    this.state.hotLists.map((val, i) => {
                                        return (
                                            <tr key={val.id} className='cursor-move' >
                                                <td>
                                                    {
                                                        val.is_hot?<i className='hot-icon'></i>:null
                                                    }
                                                    
                                                    {val.name}
                                                </td>
                                                <td>{val.link || '无'}</td>
                                                <td>{val.pv || 0}</td>
                                                <td>
                                                    <Button type="link" onClick={() => this.handleDele(i)} >删除</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {
                            this.state.hotLists.length>9?null:
                            <Button type="dashed" block icon={'plus'} className='mgt20' onClick={this.handleAdd} >添加热词 </Button>
                        }
                       
                        <span className={'fi-help-text mgt10'} >最多可添加10条热词，可拖动热词排序。</span>
                    </div>
                    <div className="search-phone">
                        <div className="phone-title">搜索</div>
                        <div className="pdl20 pdr20">
                            <div className="search">
                                <div className='flex'>
                                    <div className="search-icon"></div>
                                    <div>搜一搜</div>
                                </div>
                                <div className="search-btn">搜索</div>
                            </div>
                            <div className="flex justify-between mgt15">
                                <div className="fz17 ft-bold">搜索历史</div>
                                <div className="dele-icon"></div>
                            </div>
                            <div className="record_wrap">
                                <div className="record">双十一爆款</div>
                                <div className="record">洗发水</div>
                                <div className="record">沐浴露</div>
                                <div className="record">迪奥香水</div>
                                <div className="record">安耐晒小金瓶90ml</div>
                                <div className="record">生日礼物</div>
                            </div>
                            <div className="flex justify-between mgt15">
                                <div className="fz17 ft-bold">热门搜索</div>
                            </div>
                            <div className="record_wrap">

                                {
                                    this.state.hotLists.map(val => {
                                        return (
                                            <div className={`hot record ${val.is_hot ? 'active' : ''}`} key={val.id} >
                                                <span>{val.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading?<Loading />:null
                }
            </div>
        )
    }
}
