import React from 'react'
import { Form, Switch, Input, Button, Icon, Radio } from 'antd'
import { setIndexPop, getIndexPop } from '@/api/design'
import Loading from '@/components/Loading'
import message from '@/utils/message'
import Gallery from '@/components/Gallery/Gallery'
import PictureCard from '@/components/PictureCard/PictureCard'
import ImgAdd from '@/components/ImgAdd/ImgAdd'
import PopSelectLink from '@/components/PopSelectLink/PopSelectLink'
import { withRouter } from 'react-router-dom'
import '@/assets/css/design/add-pop.scss'
const FormItem = Form.Item
class ADPOP extends React.Component{
    state = {
        formData:'',
        isLoading:false
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        let sendData = {}
        this.setState({
            isLoading:true
        })
        getIndexPop(sendData)
            .then(res => {
                if(res.code == 1){
                    let config = res.data.config;
                    this.setState({
                        formData:config
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    chooseImg = (e,index,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            poster_info = formData.poster_info || [];

        this.Gallery.show(res => {
            if(res){
                poster_info[index][keyName] = res[0];
                this.setState({
                    formData
                })
                let datas = {}
                datas[`poster_info[${index}].img`] = res[0]
                this.props.form.setFieldsValue(datas)
            }
        })
    }
    delImg = (e,index,keyName) => {
        let name = keyName || ``,
            formData = this.state.formData || {},
            poster_info = formData.poster_info || [];
        poster_info[index][keyName] = '';
        this.setState({
            formData
        })
        let datas = {}
        datas[`poster_info[${index}].img`] = ''
        this.props.form.setFieldsValue(datas)
    }
    handleAddItem = () => {
        let formData = this.state.formData || {},
            poster_info = formData.poster_info || [],
            newData = { img: '', link:'' };
        if(poster_info && poster_info.length >= 3){
            message.error('最多添加三张图片');
            return ;
        }
        poster_info.push(newData);
        formData.poster_info = poster_info;
        this.setState({
            formData
        })
    }
    delItem = (e,item,index) => {
        e.stopPropagation()
        let formData = this.state.formData || {},
            poster_info = formData.poster_info || [];
        poster_info.splice(index,1);
        this.setState({
            formData
        })
    }
    changeLink = (e,item,index) => {
        let formData = this.state.formData || {},
            poster_info = formData.poster_info || [];
        this.PopSelectLink.show(res => {
            if(res){
                for(var i in res){
                    poster_info[index][i] = res[i];
                }
                this.setState({
                    formData
                })
            }
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData || {};
        this.props.form.validateFieldsAndScroll((err,values) => {
            if(!err){
                let config = values;
                for(var x in config){
                    if(typeof config[x] === 'boolean'){
                        config[x] = config[x] ? 1 : 0
                    }
                }
                config.poster_info = formData.poster_info;
                sendData.config = config;
                this.setState({
                    isLoading:true
                })
                setIndexPop(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData();
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })
            }
        })
    }

    render() {
        let data = this.state.formData || {}
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 9
            }
        }
        const innerLayout={
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 17
            }
        }

        return(
            <div className={`shadow-radius`}>
                <Gallery onRef={e => {this.Gallery = e}}></Gallery>
                <PopSelectLink onRef={ e => { this.PopSelectLink = e } }></PopSelectLink>
                <Form className={`model-form`} { ...formItemLayout }>
                    <div className={`sysPanel`}>
                        <div className={`sysPanel-con`}>
                            <div className={`sysPanel-title`}>首页广告投放</div>
                            <div className={`sysPanel-tip`}>开启后，用户访问首页会优先展示</div>
                        </div>
                        <div className={`list-switch`}>
                            {getFieldDecorator('poster_show', {
                                initialValue: data.poster_show == undefined ? false : !!data.poster_show,
                                valuePropName:'checked'
                            })(
                                <Switch></Switch>
                            )}
                        </div>
                    </div>
                    <div className={`msg-title mgb15`}>基本内容设置</div>
                    <FormItem label={`弹窗广告内容`} required={true} >
                        {
                            data.poster_info && data.poster_info.map((item,index) => (
                                <div className={'group-panel mgb10'} key={index}>
                                    <FormItem label={`图片`} required={true} { ...innerLayout }>
                                        {getFieldDecorator(`poster_info[${index}].img`, {
                                            initialValue: item.img || '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择图片'
                                                }
                                            ]
                                        })(
                                            <div className={'clearfix'}>
                                                {
                                                    item.img
                                                        ?
                                                        <PictureCard
                                                            onRef={ e => { this.PictureCard = e } }
                                                            imgs={ item.img }
                                                            onDel={ e => { this.delImg(e,index,'img') } }
                                                        ></PictureCard>
                                                        :
                                                        <ImgAdd
                                                            onRef={ e => { this.ImgAdd = e } }
                                                            onClick={ e => { this.chooseImg(e,index,'img') } }
                                                        ></ImgAdd>
                                                }
                                            </div>
                                        )}
                                        <span className={'fi-help-text'}>建议尺寸：600x800像素，单张不超过2M</span>
                                    </FormItem>
                                    <FormItem label={`链接到`} required={true} { ...innerLayout }>
                                        <Input
                                            value={item.link}
                                            placeholder={'仅支持系统内的小程序链接'}
                                            style={{width:'250px'}}
                                            suffix={ <Icon type="link" /> }
                                            onClick={ (e) => { this.changeLink(e,item,index) } }
                                        />
                                    </FormItem>
                                    {
                                        index > 0
                                            ?
                                            <Icon type="close-circle" theme="filled"  className={`delete-icon`} onClick={(e) => { this.delItem(e,item,index) }}   />
                                            :
                                            null
                                    }
                                </div>
                            ))
                        }
                        {
                            data.poster_info && data.poster_info.length >= 3
                                ?
                                null
                                :
                                <Button type={`primary`} onClick={ this.handleAddItem }>新增内容</Button>
                        }
                    </FormItem>
                    <FormItem label={`弹出频次`} required={true} >
                        {getFieldDecorator('show_times_type', {
                            initialValue: data.show_times_type || 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择弹出频次'
                                }
                            ]
                        })(
                            <Radio.Group>
                                <Radio value={1}>每次进入</Radio>
                                <Radio value={2}>自定义频次</Radio>
                            </Radio.Group>
                        )}
                        {
                            getFieldValue('show_times_type') == 2
                                ?
                                <div>
                                    每
                                    {getFieldDecorator('self_times', {
                                        initialValue: data.self_times || 1,
                                        rules: []
                                    })(<Input placeholder={''} style={{width:'70px'}}  className={`mgl5 mgr5`}  />)}
                                    天推送一次
                                </div>
                                :
                                null

                        }
                    </FormItem>
                    <FormItem label={` `} colon={false}>
                        <Button type={'primary'} onClick={ this.saveData }>保存</Button>
                    </FormItem>

                </Form>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(ADPOP))
