let LINK_TYPES  = [
    {
        label:'常用链接',
        type:1,
        options:[
            { linkType:7, name:'自定义链接', link:''},
            { linkType:0, name:'店铺首页', link:'/pages/index/index'},
            { linkType:1, name:'商品列表', link:'/pages/item/lists'},
            { linkType:2, name:'商品分类', link:'/pages/item/cates'},
            { linkType:3, name:'购物车', link:'/pages/cart/index'},
            { linkType:4, name:'个人中心', link:'/pages/mine/mine'},
            { linkType:5, name:'自助买单', link:'/pages/selfPay/index'},
            { linkType:8, name:'限时折扣', link:'/pages/timeDiscount/lists'},
            { linkType:9, name:'包厢预订', link:'/pages/ktv/index'},
            { linkType:10, name:'积分商城', link:'/pages/pointMall/index'},
            { linkType:13, name:'会员卡列表', link:'/pages/card/lists'},
            { linkType:14, name:'老师列表', link:'/pages/tabBar/teacher'},
            { linkType:15, name:'课程列表', link:'/pages/tabBar/course'},
            { linkType:17, name:'拼团列表', link:'/pages/group-buying/lists'},
            { linkType:18, name:'笔记列表', link:'/pages/tabBar/note'},
            { linkType:19, name:'社群团购', link:'/pages/group-solitaire/lists'},
            { linkType:20, name:'订单转换', link:'/pages/tiktok/index'},
        ]
    },
    {
        label:'商品链接',
        type:2,
        options:[
            { linkType:6, name:'商品详情', link:'/pages/item/detail' }
        ]
    },
    {
        label:'课程链接',
        type:5,
        options:[
            { linkType:16, name:'课程详情', link:'pages/course/detail' }
        ]
    },
    {
        label:'拨打电话',
        type:3,
        options:[
            { linkType:11, name:'拨打电话', link:'' }
        ]
    },
    {
        label:'地图导航',
        type:4,
        options:[
            { linkType:12, name:'地图导航', link:'', lat:'', lng:'', address:'' }
        ]
    },


]
export default LINK_TYPES
