/**
 * KTV预订组件
 * @type 12
 * @return Object
 */
import React from 'react'
import { Form, Icon, Modal, Tooltip } from 'antd'
import { ICON_FONT_URL } from '@/constants/iconFont'
import PropTypes from "prop-types";
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl:ICON_FONT_URL
})

class tpl_con_type12 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func,
        deleteModule:PropTypes.func
    }
    state = {
        moduleData:{}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title:`确定删除这个模块吗？`,
            okType:`danger`,
            onOk:() => {
                this.context.deleteModule(moduleData)
            }
        })
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`休闲娱乐预订`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{ paddingTop:`${moduleData.content.modulePadding}px`,paddingBottom:`${moduleData.content.modulePadding}px`}} onClick={ this.handleEditModule }>
                        <div className={`tpl12-cont`}>
                            <div className="booking-info">
                                <div className="book-title">
                                    <div className="left">
                                        <div className={`txt1`}>预订</div>
                                        <div className={`book-store-name`}>
                                            <div className={`txt2`}>示例门店</div>
                                        </div>
                                    </div>
                                    <div className="sales"></div>
                                </div>
                                <div className="scroll-cont">
                                    <div className="week-scroll">
                                        <div className="week-scroll-cont">
                                            <div className="week active">
                                                <div className="txt1">今天</div>
                                                <div className="txt2">12-07</div>
                                            </div>
                                            <div className="week">
                                                <div className="txt1">周三</div>
                                                <div className="txt2">12-08</div>
                                            </div>
                                            <div className="week">
                                                <div className="txt1">周四</div>
                                                <div className="txt2">12-09</div>
                                            </div>
                                            <div className="week">
                                                <div className="txt1">周五</div>
                                                <div className="txt2">12-10</div>
                                            </div>
                                            <div className="week">
                                                <div className="txt1">周六</div>
                                                <div className="txt2">12-11</div>
                                            </div>
                                            <div className="week">
                                                <div className="txt1">周日</div>
                                                <div className="txt2">12-12</div>
                                            </div>
                                            <div className="week">
                                                <div className="txt1">周一</div>
                                                <div className="txt2">12-13</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="room-scroll">
                                        <div className="room-scroll-cont">
                                            <div className="room active">
                                                <div className="txt1">小包</div>
                                                <div className="txt2">（3-5人）</div>
                                            </div>
                                            <div className="room">
                                                <div className="txt1">中包</div>
                                                <div className="txt2">（6-8人）</div>
                                            </div>
                                            <div className="room">
                                                <div className="txt1">大包</div>
                                                <div className="txt2">（10-12人）</div>
                                            </div>
                                            <div className="room">
                                                <div className="txt1">豪华包</div>
                                                <div className="txt2">（15-20人）</div>
                                            </div>
                                            <div className="room">
                                                <div className="txt1">小包</div>
                                                <div className="txt2">（3-5人）</div>
                                            </div>
                                            <div className="room">
                                                <div className="txt1">小包</div>
                                                <div className="txt2">（3-5人）</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="meal-scroll">
                                        <div className="meal-scroll-cont">
                                            <div className="meal active">仅欢唱</div>
                                            <div className="meal">超值套餐</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="time-cont">
                                    <div className="time-group-cont">
                                        <div className="time-group">
                                            <div className="title">下午档13:00-18:00，任选4小时</div>
                                            <div className="desc">
                                                <div className="price">
                                                    &yen;
                                                    <span>188</span>
                                                </div>
                                                <div className="book-btn">预订</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-group-cont">
                                        <div className="time-group">
                                            <div className="title">黄金档18:00-次日02:00内，任选4小时</div>
                                            <div className="desc">
                                                <div className="price">
                                                    &yen;
                                                    <span>188</span>
                                                </div>
                                                <div className="book-btn">预订</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }
}

export default Form.create({})(tpl_con_type12)

