/**
 * 搜索组件
 * @type 9
 * @return Object
 */

import React from 'react'
import { Form, Slider, Input, Button, Icon, Modal, InputNumber, Radio, Tooltip} from 'antd'
import PropTypes from "prop-types";
import ItemPicker from '@/components/ItemPicker/ItemPicker'
import ColorPicker from '@/components/ColorPicker/ColorPicker'
const FormItem = Form.Item
const ctrlItemLayout = {labelCol: { span: 7 }, wrapperCol: { span: 17 }}

class tpl_ctrl_type9 extends React.Component{
    static contextTypes = {
        updateDesign:PropTypes.func
    }
    state = {
        moduleData:{},
    }

    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({ moduleData: JSON.parse(tmp) })
    }

    changePadding = val => {
        let moduleData = this.state.moduleData;
        moduleData.content.modulePadding = val;
        this.context.updateDesign(moduleData)
    }
    handleStyleChange = (e,name) => {
        let value = e.target.value,
            moduleData = this.state.moduleData;
        moduleData.content[name] = value;
        this.context.updateDesign(moduleData)

    }
    handleColorChange = ( color,name ) => {
        let hex = color.hex,
            moduleData = this.state.moduleData;
        moduleData.content[name] = hex;
        this.context.updateDesign(moduleData)
    }
    changePlaceHolder = e => {
        let moduleData = this.state.moduleData,
            value = e.target.value;
        moduleData.content.placeholder = value;
        this.context.updateDesign(moduleData)
    }

    handleResetColor=(name,color)=>{
        let moduleData=this.state.moduleData
        moduleData.content[name]=color
        this.context.updateDesign(moduleData)
    }

    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <div>
                    <div className={`ctrl-right-title`}>商品搜索</div>
                    <Form>
                        <div className={`cx-ctrl-panel`}>
                            <div className={`cx-ctrl-panel--box`}>
                                <FormItem label={`组件间距`}>
                                    <div className={`cx-slider`}>
                                        <Slider
                                            min={0}
                                            max={20}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                            className={`cx-slider-main`}
                                        ></Slider>
                                        <InputNumber
                                            min={0}
                                            max={20}
                                            style={{ width:60 }}
                                            value={moduleData.content.modulePadding}
                                            onChange={ this.changePadding }
                                        />
                                    </div>
                                </FormItem>
                                <FormItem label={`搜索框样式`}  { ...ctrlItemLayout } >
                                    <Radio.Group value={moduleData.content.style} onChange={(e) => { this.handleStyleChange(e,'style') }} >
                                        <Radio value={1}>描边</Radio>
                                        <Radio value={2}>填充</Radio>
                                    </Radio.Group>
                                </FormItem>
                                {
                                    moduleData.content.style == 1
                                        ?
                                        <FormItem label={`描边颜色`} { ...ctrlItemLayout }>
                                            <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.borderColor} callback={ (color) => { this.handleColorChange(color,'borderColor') } } ></ColorPicker>
                                            <Button type="link" onClick={()=>this.handleResetColor('borderColor','#C2C2C2')}>重置</Button>
                                        </FormItem>
                                        :
                                        null
                                }
                                {
                                    moduleData.content.style == 2
                                        ?
                                        <FormItem label={`背景颜色`} { ...ctrlItemLayout }>
                                            <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.bgColor} callback={ (color) => { this.handleColorChange(color,'bgColor') } } ></ColorPicker>
                                            <Button type="link" onClick={()=>this.handleResetColor('bgColor','#F5F5F5')}>重置</Button>
                                        </FormItem>
                                        :
                                        null
                                }
                                <FormItem label={`按钮颜色`} { ...ctrlItemLayout }>
                                    <ColorPicker style={{display:"inline-block"}} onRef={e => { this.ColorPicker = e }} color={moduleData.content.btnColor} callback={ (color) => { this.handleColorChange(color,'btnColor') } } ></ColorPicker>
                                    <Button type="link" onClick={()=>this.handleResetColor('btnColor','#000')}>重置</Button>
                                </FormItem>
                                <FormItem label={`搜索提示`}>
                                    <Input type={'text'} placeholder={`请输入`} value={moduleData.content.placeholder} onChange={this.changePlaceHolder} />
                                </FormItem>
                            </div>
                        </div>
                    </Form>

                </div>
                :
                null
        )

    }

}

export default Form.create({})(tpl_ctrl_type9)
