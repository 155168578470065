import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, DatePicker, TimePicker } from 'antd'
import { userLists } from '@/api/user'
import message from '@/utils/message'
import moment from 'moment'
const FormItem = Form.Item
const { TextArea } = Input

class AddCourtOrder extends React.Component{
    state = {
        title:'',
        formData:'',
        visible:false,
        modalLoading:false,
        callback:null,
        total_fee:'0.00',
        results:[]

    }
    componentDidMount() {
        this.props.onRef(this)
    }
    show = (callback) => {
        let title = '添加预约';
        if(this.props.data && this.props.data.isCash){
            title = '收银结账'
        }
        this.setState({
            visible:true,
            callback : callback || null,
            formData: this.props.data || '',
            results:[],
            title:title
        },() => {
            if(this.props.data){
                this.handlePayMent()
            }
        })
    }

    close = () => {
        this.setState({
            visible:false
        })
        this.props.form.resetFields();
    }

    handleOk = () => {
        let sendData = {},
            formData = this.state.formData || {},
            callback = this.state.callback;
        this.props.form.validateFields((err,values) => {
            if(!err){
                values.book_date = values.book_date ?  moment(values.book_date).format('YYYY-MM-DD')  : '';
                for(var i in values){
                    formData[i] = values[i]
                }

                if(typeof callback == 'function'){
                    callback(formData)
                }
                this.close()
            }
        })
    }
    handleSearch = (val) => {
        let sendData = {},
            formData = this.state.formData || {};
        if(val){
            formData.mobile = val;
        }
        if(val && val.length == 11){
            //搜
            sendData.mobile = val;
            sendData.page = 1;
            userLists(sendData)
                .then(res => {
                    if(res.code == 1){
                        let lists = res.data.user_lists.data;
                        this.setState({
                            results:lists
                        })

                    }
                })
        }
        this.setState({
            formData
        })

    }
    handleChange = val => {
        let results = this.state.results || [],
            formData = this.state.formData || {};
        results.forEach((item) => {
            if(item.id == val){
                formData.user_name = item.name
            }
        })
        this.setState({ formData })
    }
    handleCancel = () => {
        this.close()
    }
    handleSpaceChange = (val) => {
        let spaceLists = this.props.spaceLists || [],
            formData = this.state.formData || {};
        formData.space_id = val;
        for(var i in spaceLists){
            if(spaceLists[i]['id'] == val){
                formData['space_type_id'] = spaceLists[i]['space_type_id']
            }
        }
        this.setState({
            formData
        },() => { this.handlePayMent() })


    }
    handleDateChange = (e) => {
        let formData = this.state.formData || {};
        if(e){
            formData['week_id'] = moment(e).day()
        }else {
            formData['week_id'] = ''
        }
        this.setState({
            formData
        },() => { this.handlePayMent() })
    }
    handleRadioChange = (e) => {
        let val = e.target.value,
            formData = this.state.formData || {};
        formData.book_type = val;
        this.setState({
            formData
        })
    }
    handleSafeChange = e => {
        let val = e.target.value,
            formData = this.state.formData || {};
        formData['buy_safety'] = val;
        this.setState({
            formData
        },() => { this.handlePayMent() })

    }
    handleTimeChange = (e) => {
        let formData = this.state.formData || {};
        formData.time_ids = e;
        this.setState({
            formData
        },() => { this.handlePayMent() })
    }
    handleGusetTimeChange = (e) => {
        let formData = this.state.formData || {};
            formData.time_id = e;
        this.setState({
            formData
        },() => { this.handlePayMent() })
    }
    handlePayMent = () => {
        let formData = this.state.formData || {},
            week_id = formData.week_id || '',
            space_id = formData.space_id || '',
            space_type_id = formData.space_type_id || '',
            mix_price = this.props.mix_price || '',
            rent_price = this.props.rent_price || '',
            time_data = this.props.time_data || [],
            insurance_fee = this.props.insurance_fee || '',
            time_ids = formData.time_ids || [],
            book_type = Number(formData.book_type || this.props.form.getFieldValue('book_type')),
            total_fee = 0,
            book_time = [];
        switch (book_type) {
            case 1:
            case 3:
            case 4:
            case 6:
                //整租
                if(rent_price && rent_price[space_type_id]){
                    let tmp = rent_price[space_type_id]
                    if(week_id && space_id){
                        let keyName = `${week_id}_${space_id}`,
                            price_arr = tmp[keyName] || [];
                        price_arr.forEach((child,childKey) => {
                            child.time = `${child.start_time || ''}~${child.end_time || ''}`
                            if(time_ids.includes(child.id)){
                                book_time.push(child);
                                total_fee = total_fee + parseFloat(child.price)
                            }
                        })
                        total_fee = total_fee.toFixed(2)
                        formData.book_time = book_time
                        this.setState({
                            total_fee,
                            formData
                        })

                    }
                }
                break;
            case 2:
                //散客
                let time_id = formData.time_id || '',
                    price = '',
                    buy_safety = formData.buy_safety || this.props.form.getFieldValue('buy_safety');
                if(mix_price[space_type_id]){
                    price = mix_price[space_type_id]
                }
                for(var i in time_data){
                    if(time_data[i]['id'] == time_id){
                        book_time.push({ time:time_data[i]['time'], price:price })
                    }
                }
                formData.book_time = book_time;
                this.setState({
                    formData
                })
                break;
            case 5:
                //清扫
                for(var i in time_data){
                    if( time_ids.includes(time_data[i]['id'])){
                        book_time.push({ time:time_data[i]['time'], price:'' })
                    }
                }
                formData.book_time = book_time
                this.setState({
                    total_fee,
                    formData
                })
                break;

        }
    }
    render() {
        let spaceLists = this.props.spaceLists || [],
            mix_price = this.props.mix_price || '',
            rent_price = this.props.rent_price || '',
            time_data = this.props.time_data || [],
            payTypes = this.props.payTypes || [],
            data = this.state.formData || {};
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 18
            }
        }
        const { getFieldDecorator, getFieldValue } = this.props.form
        return(
            <Modal
                title={this.state.title}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={700}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    {
                        this.props.guestOnly
                            ?
                            <FormItem label={`场地用途`}>
                                {getFieldDecorator('book_type', {
                                    initialValue: 2,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择场地用途'
                                        }
                                    ],
                                })(
                                    <Radio.Group onChange={ this.handleRadioChange }>
                                        <Radio value={2}>散客</Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>
                            :
                            <FormItem label={`场地用途`}>
                                {getFieldDecorator('book_type', {
                                    initialValue: data.book_type  || 1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择场地用途'
                                        }
                                    ],
                                })(
                                    <Radio.Group onChange={ this.handleRadioChange } disabled={!!data.isCash}>
                                        <Radio value={1}>整租</Radio>
                                        <Radio value={2}>散客</Radio>
                                        <Radio value={3}>培训</Radio>
                                        <Radio value={4}>赛事</Radio>
                                        <Radio value={5}>清扫</Radio>
                                        <Radio value={6}>其他</Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>

                    }
                    {
                        [5].includes(getFieldValue('book_type'))
                            ?
                            null
                            :
                            <FormItem label={`客户手机号`} required={true}>
                                <Select
                                    value={ data.mobile }
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={ this.handleChange }
                                    onSearch={this.handleSearch}
                                    disabled={!!data.isCash}
                                    placeholder={`请输入预约人的手机号`}
                                >
                                    {
                                        this.state.results.map((item,index) => (
                                            <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                        ))
                                    }
                                </Select>
                            </FormItem>

                    }
                    {
                        [5].includes(getFieldValue('book_type'))
                            ?
                            null
                            :
                            <FormItem label={`客户姓名`}>
                                {
                                    getFieldDecorator('user_name',{
                                        initialValue: data.user_name || ``,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入姓名'
                                            }
                                        ]
                                    })(
                                        <Input placeholder={`请输入预约人的姓名`} disabled={!!data.isCash} />
                                    )
                                }
                            </FormItem>
                    }

                    <FormItem label={`选择场地`}>
                        {
                            getFieldDecorator('space_id',{
                                initialValue: data.space_id,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择场地'
                                    }
                                ]
                            })(
                                <Select placeholder={'请选择'} onChange={ this.handleSpaceChange } disabled={!!data.isCash}>
                                    {
                                        spaceLists.map((item,index) => (
                                            <Select.Option value={item.id} key={index}>{ item.name }</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    {
                        [2].includes(getFieldValue('book_type'))
                            ?
                            <div>
                                <FormItem label={`选择场次`}>
                                    {
                                        getFieldDecorator('book_date',{
                                            initialValue:data.book_date ? moment(data.book_date, 'YYYY-MM-DD') : null,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择日期'
                                                }
                                            ]
                                        })(
                                            <DatePicker onChange={ this.handleDateChange } disabled={!!data.isCash}></DatePicker>
                                        )
                                    }
                                </FormItem>
                                <FormItem label={` `} colon={false}>
                                    {
                                        getFieldDecorator('time_id',{
                                            initialValue: data.time_id,
                                        })(
                                            <Select
                                                placeholder={'请选择'}
                                                onChange={ this.handleGusetTimeChange }
                                                disabled={!!data.isCash}
                                            >
                                                {
                                                    time_data.map((item,index) => (
                                                        <Select.Option value={item.id} key={index} >{ item.time }</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </div>
                            :
                            null
                    }
                    {
                        [1,3,4,5,6].includes(getFieldValue('book_type'))
                            ?
                            <div>
                                <FormItem label={`选择场次`}>
                                    {
                                        getFieldDecorator('book_date',{
                                            initialValue:data.book_date ? moment(data.book_date, 'YYYY-MM-DD') : null,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请选择日期'
                                                }
                                            ]
                                        })(
                                            <DatePicker onChange={ this.handleDateChange } disabled={!!data.isCash}></DatePicker>
                                        )
                                    }
                                </FormItem>
                                <FormItem label={` `} colon={false}>
                                    {
                                        getFieldDecorator('time_ids',{
                                            initialValue: data.time_ids || [],
                                        })(
                                            <Select
                                                placeholder={'请选择'}
                                                mode="multiple"
                                                onChange={ this.handleTimeChange }
                                                disabled={!!data.isCash}
                                            >
                                                {
                                                    time_data.map((item,index) => (
                                                        <Select.Option value={item.id} key={index} >{ item.time }</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </div>

                            :
                            null
                    }
                    {
                        [5].includes(getFieldValue('book_type'))
                            ?
                            null
                            :
                            <div>
                                {
                                    [2].includes(getFieldValue('book_type'))
                                        ?
                                        <FormItem label={`是否购买保险`}>
                                            {getFieldDecorator('buy_safety', {
                                                initialValue: data.buy_safety  || 0,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group onChange={ this.handleSafeChange } disabled={!!data.isCash}>
                                                    <Radio value={1}>购买</Radio>
                                                    <Radio value={0}>不购买</Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                        :
                                        null
                                }
                                {
                                    [2].includes(getFieldValue('book_type'))
                                        ?
                                        null
                                        :
                                        <FormItem label={`金额合计`}>
                                            <p>&yen;{ this.state.total_fee }</p>
                                        </FormItem>

                                }

                                <FormItem label={`应收金额`}>
                                    {
                                        getFieldDecorator('pay_ment',{
                                            initialValue: data.pay_ment || ``,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请输入'
                                                }
                                            ]
                                        })(
                                            <Input placeholder={`请输入`} addonBefore={`￥`} disabled={!!data.isCash} />
                                        )
                                    }
                                </FormItem>
                                {
                                    data.isCash == 1
                                        ?
                                        null
                                        :
                                        <FormItem label={`支付状态`}>
                                            {getFieldDecorator('trade_status', {
                                                initialValue: data.trade_status  || 0,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group>
                                                    <Radio value={0}>未支付</Radio>
                                                    <Radio value={1}>已支付</Radio>
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                }
                                {
                                    getFieldValue('trade_status') == 0 && !data.isCash
                                        ?
                                        null
                                        :
                                        <FormItem label={`支付方式`}>
                                            {getFieldDecorator('pay_type_id', {
                                                initialValue: data.pay_type_id  || '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ],
                                            })(
                                                <Radio.Group className={`flex flex-wrap`} style={{ marginTop:'-10px' }}>
                                                    {
                                                        payTypes.map((item,index) => (
                                                            <Radio value={item.id} className={`flex  justify-start align-center mgt10`} key={index}>
                                                                <div className={`flex justify-start align-center flex-wrap`}>
                                                                    <img src={ item.icon } alt="" width={35} />
                                                                    <span className={`mgl5`} style={{ width:'70px',whiteSpace:'normal' }}>{ item.name }</span>
                                                                </div>
                                                            </Radio>
                                                        ))
                                                    }
                                                </Radio.Group>
                                            )}
                                        </FormItem>
                                }
                                {
                                    [2].includes(getFieldValue('book_type'))
                                        ?
                                        null
                                        :
                                        <div>
                                            <FormItem label={`是否开票`}>
                                                {getFieldDecorator('is_need_bill', {
                                                    initialValue: data.is_need_bill  || 0,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择'
                                                        }
                                                    ],
                                                })(
                                                    <Radio.Group>
                                                        <Radio value={0}>不开票</Radio>
                                                        <Radio value={1}>开票</Radio>
                                                    </Radio.Group>
                                                )}
                                            </FormItem>
                                            {
                                                getFieldValue('is_need_bill') == 1
                                                    ?
                                                    <div>
                                                        <FormItem label={`发票抬头`}>
                                                            {
                                                                getFieldDecorator('bill_head',{
                                                                    initialValue: data.bill_head || ``,
                                                                })(
                                                                    <Input placeholder={`发票抬头`} />
                                                                )
                                                            }
                                                        </FormItem>
                                                        <FormItem label={`发票税号`}>
                                                            {
                                                                getFieldDecorator('bill_account_number',{
                                                                    initialValue: data.bill_account_number || ``,
                                                                })(
                                                                    <Input placeholder={`发票税号`} />
                                                                )
                                                            }
                                                        </FormItem>
                                                        <FormItem label={`邮箱地址`}>
                                                            {
                                                                getFieldDecorator('bill_email',{
                                                                    initialValue: data.bill_email || ``,
                                                                })(
                                                                    <Input placeholder={`邮箱地址`} />
                                                                )
                                                            }
                                                        </FormItem>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>

                                }
                            </div>
                    }
                    {
                        [2].includes(getFieldValue('book_type')) && getFieldValue('buy_safety') == 1
                            ?
                            <div>
                                <FormItem label={`真实姓名`}>
                                    {
                                        getFieldDecorator('real_name',{
                                            initialValue: data.real_name || ``,
                                        })(
                                            <Input placeholder={`请输入真实姓名`} disabled={!!data.isCash} />
                                        )
                                    }
                                </FormItem>
                                <FormItem label={`身份证号`}>
                                    {
                                        getFieldDecorator('id_number',{
                                            initialValue: data.id_number || ``,
                                        })(
                                            <Input placeholder={`请输入身份证号`} disabled={!!data.isCash} />
                                        )
                                    }
                                </FormItem>
                            </div>

                            :
                            null
                    }
                    <FormItem label={`商家备注`}>
                        {
                            getFieldDecorator('remark',{
                                initialValue: data.remark || ``,
                            })(
                                <TextArea rows={4} placeholder={`商家备注`} />
                            )
                        }
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddCourtOrder))
