import React from 'react'
import { Icon, Modal } from 'antd'
import Sortable from 'sortablejs'
import CX from '@/utils/CX'
import './PictureCard.scss'

class PictureCard extends React.Component {
    state = {
        previewVisible:false,
        previewImage:'',
        refId:CX.genUUID()
    }

    componentDidMount() {
        this.props.onRef(this)
        this.initSort();
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }
    handleDelete = (img,index) => {
        let onDel = this.props.onDel || '';
        if(onDel){
            onDel({ img,index })
        }
    }
    handlePreview = (img,index) => {
        this.setState({
            previewImage:img,
            previewVisible:true
        })
    }
    handleCancel = () => {
        this.setState({
            previewVisible:false
        },() => {
            setTimeout(() => {
                this.setState({
                    previewImage:''
                })
            },500)
        })
    }
    initSort = () => {
        let sortable = new Sortable(this.refs[this.state.refId],{
            group: this.state.refId, // set both lists to same group
            animation: 150,
            onEnd:(event) => {
                this.handleSortEnd(event)
            }
        })
    }
    handleSortEnd = e => {
        this.props.onSort && this.props.onSort(e);
    }

    render() {
        let imgs = this.props.imgs || [];
        if (typeof imgs == 'string') {
            imgs = [imgs]
        }
        return (
            <div className={`ant-upload-list ant-upload-list-picture-card ${ this.state.refId }`} ref={this.state.refId} id={this.state.refId}>
                {
                    imgs.map((img, index) => (
                        <div className={`ant-upload-list-picture-card-container`} key={this.props.item_id? img[this.props.item_id]:`${img}${index}`}>
                            <span>
                                <div
                                    className={`ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card`}>
                                    <div className={`ant-upload-list-item-info`}>
                                        <span>
                                            <span className={`ant-upload-list-item-thumbnail`}>
                                                <img
                                                    src={this.props.item_key?img[this.props.item_key]:img }
                                                    alt=""
                                                    className={`ant-upload-list-item-image`}
                                                />
                                            </span>
                                        </span>
                                    </div>
                                    <span className={`ant-upload-list-item-actions`}>
                                        <Icon type="eye" className={`anticon-eye-o`} onClick={ () => { this.handlePreview(this.props.item_key?img[this.props.item_key]:img,index) } } />
                                        <Icon type="delete" className={`anticon-delete`} onClick={ () => { this.handleDelete(img,index) } } />
                                    </span>
                                </div>
                            </span>
                        </div>
                    ))
                }
                <Modal title={`图片预览`} visible={ this.state.previewVisible } footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>

            </div>
        )
    }
}

export default PictureCard
