import React from 'react'
import { Form, Steps, Col, Row, Tag, Tabs, Button, Modal, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import Deliver from './components/deliver'
import Remark from './components/remark'
import GoodsOrder from './components/goods-order'
import CouponOrder from './components/coupon-order'
import SelfOrder from './components/self-order'
import KtvOrder from './components/ktv-order'
import CardOrder from './components/card-order'
import CourtOrder from './components/court-order'
import TimesCardOrder from './components/times-card-order'
import CourseOrderDetail from "./course-detail"
import GroupBuyDetail from '@/components/GroupBuyDetail/GroupBuyDetail'
import GroupOrder from './components/group-order'
import TicketOrder from './components/ticket-order'
import { orderDetail, acceptRefund, refuseRefund, deliverTrade, addRemark, pickUpCode, orderPrint, AcceptOrder } from '@/api/order'
import '@/assets/css/order/index.scss'
const FormItem = Form.Item
const { Step } = Steps
const { TabPane } = Tabs

//订单类型 1-普通下单(购物车) 2-优惠券 3-自助买单 4-扫码点餐 5-包厢预订 6-储值卡开卡 7-储值卡充值 8-限时打折 9-积分兑换 10-场馆预订 14 团购
//支付状态0待支付1已支付2已完成3申请退款4已退款5已部分退款6用户已取消7超时未支付自动关闭8订单过期(废弃)9待接单10待发货11已发货12待支付尾款
// 团状态 1待付款2拼团中3拼团成功4退款中5已退款6拼团到期未成功7交易关闭8已核销
class OrderDetail extends React.Component {
    state = {
        trade: '',
        currentStep: 0,
        columns: [
            {
                title: '商品编码',
                dataIndex: 'code',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={``}>
                            <p className={`lh25`}>{rowData.sku_info ? rowData.sku_info.goods_sku_code : rowData.goods_code ? rowData.goods_code : '--'}</p>
                        </div>
                    )
                }
            },
            {
                title: '商品名称',
                dataIndex: 'name',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.name}</p>
                        </div>
                    )
                }
            },
            {
                title: '规格',
                dataIndex: 'sku',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.sku_info ? rowData.sku_info.name : '-'}</p>
                        </div>
                    )
                }
            },
            {
                title: '零售价',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.price}</p>
                        </div>
                    )
                }
            },
            {
                title: '数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.num}</p>
                        </div>
                    )
                }
            },
            {
                title: '单位',
                dataIndex: 'unit',
                render: (text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{rowData.unit_name || '件'}</p>
                        </div>
                    )
                }
            },
            {
                title: '优惠',
                dataIndex: 'discount_price',
                render: (text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{rowData.discount_price || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title: '合计',
                dataIndex: 'total',
                render: (text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{rowData.pay_ment || '-'}</p>
                        </div>
                    )
                }
            }
        ],
        tableData: [],
        isLoading: false,
        is_course: false
    }

    componentDidMount() {
        this.fetchData()
    }
    fetchData = () => {
        let sendData = {}
        sendData.order_sn = this.props.match.params.id
        this.setState({
            isLoading: true
        })
        orderDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                    let trade = res.data.trade,
                        post_type = trade.post_type,
                        currentStep = 0,
                        tableData = trade.goods_lists || [],
                        is_course = false,
                        group_trade = trade.group_trade || {}
                    switch (trade.trade_status) {
                        case 0:
                        case 4:
                        case 6:
                        case 7:
                        case 8:
                            currentStep = 0;
                            break;
                        case 1:
                        case 9:
                            currentStep = 1;
                            if (trade.trade_status == 1 && post_type == 2) {
                                currentStep = 2; //自提目前自动接单支付成功即待核销
                            }
                            if ([2, 3, 5, 10].includes(trade.trade_type)) {
                                currentStep = 2; //优惠券  自助买单
                            }
                            if (trade.trade_status == 9) {
                                currentStep = 2;
                            }
                            break;
                        case 10:
                            currentStep = 3;
                            break;
                        case 11:

                            if (post_type == 1) {
                                //快递
                                currentStep = 3;
                            }
                            break;
                        case 2:
                            currentStep = 4;
                            if (post_type == 1) {
                                //快递
                                currentStep = 5;
                            }
                            break;
                        case 3:
                            currentStep = 1;
                            break;
                        default:
                            currentStep = 0;
                            break;
                    }
                    if (trade.trade_type == 12) {
                        is_course = true
                    }

                    if (trade.trade_type == 14) {
                        if ([1, 4, 5, 6, 7].includes(group_trade.status)) {
                            currentStep = 0;
                        } else if (group_trade.status == 2) {
                            currentStep = 2;
                        } else if (group_trade.status == 3) {
                            currentStep = 3;
                            if (trade.finished_at) {
                                currentStep = 4;
                            }
                        } else {
                            currentStep = 4;
                        }
                    }

                    if (trade.trade_type == 18) {
                        if ([0, 4, 6, 7, 8].includes(trade.trade_status)) {
                            currentStep = 0;
                        } else if ([1, 9].includes(trade.trade_status)) {
                            currentStep = 1;
                        } else if ([10, 11].includes(trade.trade_status)) {
                            currentStep = 2;
                        } else if (trade.trade_status == 2) {
                            currentStep = 3;
                        } else {
                            currentStep = 0;
                        }
                    }


                    let trade_total_price = 0;
                    trade.goods_lists && trade.goods_lists.forEach((good, goodIndex) => {
                        good.total_price = good.total_price ? parseFloat(good.total_price) : 0
                        trade_total_price = trade_total_price + good.total_price
                    })
                    trade.trade_total_price = trade_total_price ? trade_total_price.toFixed(2) : 0
                    if (trade.trade_type == 10) {
                        tableData = [trade]
                    }
                    this.setState({
                        trade, tableData, currentStep, is_course
                    })

                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    onStepChange = val => { }
    handleRefund = () => {
        let trade = this.state.trade,
            sendData = {};
        sendData.order_sn = trade.order_sn;
        Modal.confirm({
            title: `是否对这笔订单进行退款？`,
            okType: 'primary',
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                acceptRefund(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })

    }
    handleRefuse = () => {
        let trade = this.state.trade,
            sendData = {};
        sendData.order_sn = trade.order_sn;
        Modal.confirm({
            title: `是否拒绝该订单退款申请`,
            okType: 'primary',
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                refuseRefund(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }
    handlePickUp = () => {
        let trade = this.state.trade,
            sendData = {};
        sendData.order_sn = trade.order_sn;
        sendData.use_code = trade.use_code || ``;
        Modal.confirm({
            title: '是否对这笔订单进行核销？',
            okType: 'primary',
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                pickUpCode(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('核销成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })

            }
        })


    }
    handleExpress = () => {
        let sendData = {}
        this.Deliver.show(res => {
            sendData.order_sn = this.state.trade.order_sn;
            sendData.express_id = res.express_id || '';
            sendData.express_sn = res.express_sn || '';
            this.setState({
                isLoading: true
            })
            deliverTrade(sendData)
                .then(res => {
                    if (res.code == 1) {
                        message.success('标记发货成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })

        })
    }
    handleRemark = () => {
        let sendData = {};
        this.Remark.show(res => {
            sendData.remark = res.remark || '';
            sendData.order_sn = this.state.trade.order_sn;
            if (!sendData.remark) { return false };
            this.setState({
                isLoading: true
            })
            addRemark(sendData)
                .then(res => {
                    if (res.code == 1) {
                        message.success('标记成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })

        })
    }
    handlePrint = () => {
        let trade = this.state.trade,
            sendData = {};
        sendData.order_sn = trade.order_sn;
        Modal.confirm({
            title: `是否打印该订单小票`,
            okType: 'primary',
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                orderPrint(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })
    }
    handleAccept = () => {
        let trade = this.state.trade,
            sendData = {};
        sendData.order_sn = trade.order_sn;
        this.setState({
            isLoading: true
        })
        AcceptOrder(sendData)
            .then(res => {
                if (res.code == 1) {
                    message.success('接单成功')
                        .then(() => {
                            this.fetchData()
                        })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    render() {
        let trade = this.state.trade || {}

        if (this.state.is_course) {
            return <CourseOrderDetail trade={trade} />
        }

        return (
            <div className={`shadow-radius`}>
                <Deliver onRef={e => { this.Deliver = e }}></Deliver>
                <Remark onRef={e => { this.Remark = e }}></Remark>
                {
                    [2, 3, 6, 7, 11].includes(trade.trade_type)
                        ?
                        <div className={'pd20'}>
                            <Steps progressDot current={this.state.currentStep} onChange={this.onStepChange} size={'small'}>
                                <Step title="客户下单" description={trade.created_at || ''} />
                                <Step title="客户付款" description={trade.pay_time || ''} />
                                <Step title="交易成功" description={trade.finished_at || ''} />
                            </Steps>
                        </div>
                        :
                        null
                }
                {
                    [1, 9, 15, 8].includes(trade.trade_type)
                        ?
                        <div className={'pd20'}>
                            <Steps progressDot current={this.state.currentStep} onChange={this.onStepChange} size={'small'}>
                                <Step title="客户下单" description={trade.created_at || ''} />
                                <Step title="客户付款" description={trade.pay_time || ''} />
                                <Step title={`${trade.post_type == 1 ? '等待接单' : '商家接单'} `} description={trade.accept_time || ''} />
                                {
                                    trade.post_type == 1
                                        ?
                                        <Step title={trade.trade_status == 11 ? "商家已发货" : "等待商家发货"} description={trade.deliver_time || ''} />
                                        :
                                        null
                                }
                                <Step title="交易成功" description={trade.finished_at || ''} />
                                {/* <Step title="评价" /> */}
                            </Steps>
                        </div>
                        :
                        null
                }
                {
                    [4, 5, 10].includes(trade.trade_type)
                        ?
                        <div className={'pd20'}>
                            <Steps progressDot current={this.state.currentStep} onChange={this.onStepChange} size={'small'}>
                                <Step title="客户下单" description={trade.created_at || ''} />
                                <Step title="客户付款" description={trade.pay_time || ''} />
                                <Step title="商家接单" description={trade.accept_time || ''} />
                                <Step title="交易成功" description={trade.finished_at || ''} />
                            </Steps>
                        </div>
                        :
                        null
                }

                {
                    [14].includes(trade.trade_type)
                        ?
                        <div className={'pd20'}>
                            <Steps progressDot current={this.state.currentStep} onChange={this.onStepChange} size={'small'}>
                                <Step title="客户下单" description={trade.created_at || ''} />
                                <Step title="客户付款" description={trade.pay_time || ''} />
                                <Step title="拼团中" />
                                <Step title="已成团" description={trade.group_trade && trade.group_trade.success_at || ''} />
                                <Step title="交易成功" description={trade.finished_at || ''} />
                            </Steps>
                        </div>
                        :
                        null
                }

                {
                    [18].includes(trade.trade_type)
                        ?
                        <div className={'pd20'}>
                            <Steps progressDot current={this.state.currentStep} onChange={this.onStepChange} size={'small'}>
                                <Step title="客户下单" description={trade.created_at || ''} />
                                <Step title="客户付款" description={trade.pay_time || ''} />
                                {
                                    trade.post_type == 1
                                        ?
                                        <Step title={trade.trade_status == 11 ? "商家已发货" : "等待商家发货"} description={trade.deliver_time || ''} />
                                        :
                                        null
                                }
                                <Step title="交易成功" description={trade.finished_at || ''} />
                            </Steps>
                        </div>
                        :
                        null
                }


                <div className={`msg-title`}>订单基本信息</div>

                <Form className={' pd20 '} layout={`inline`}>
                    <div className={`order_sn`}>
                        <span className={`mgr10`}>商户单号：{trade.order_sn}</span>
                        {
                            [1, 2].includes(trade.trade_status)
                                ?
                                <Tag color="green">{trade.trade_status_name}</Tag>
                                :
                                null
                        }
                        {
                            [0, 6, 7, 8].includes(trade.trade_status)
                                ?
                                <Tag color="red">{trade.trade_status_name}</Tag>
                                :
                                null

                        }
                        {
                            [3, 4, 5, 9, 10, 11, 12].includes(trade.trade_status)
                                ?
                                <Tag color="orange">{trade.trade_status_name}</Tag>
                                :
                                null

                        }
                    </div>
                    <div>
                        <Row>
                            <Col span={6}>
                                <FormItem label={'客户昵称'} >
                                    <div className={``}>
                                        <p>{trade.user_info ? trade.user_info.name : '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'客户手机号'} >
                                    <div className={``}>
                                        <p>{trade.user_info ? trade.user_info.mobile : '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'客户ID'} >
                                    <div className={``}>
                                        <p>{trade.user_info ? trade.user_info.id : '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'会员等级'} >
                                    <div className={``}>
                                        <p>普通会员</p>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <FormItem label={'订单类型'} >
                                    <div className={``}>
                                        <p>{trade.trade_type_name}订单</p>
                                    </div>
                                </FormItem>
                            </Col>
                            {
                                [10].includes(trade.trade_type)
                                    ?
                                    <Col span={6}>
                                        <FormItem label={'下单门店'} >
                                            <div className={``}>
                                                <p>{trade.store?.name || '-'}</p>
                                            </div>
                                        </FormItem>
                                    </Col>
                                    :
                                    <Col span={6}>
                                        <FormItem label={trade.trade_type == 18 ? '取票方式' : '配送方式'} >
                                            {
                                                [2, 3, 5, 6, 7, 11].includes(trade.trade_type)
                                                    ?
                                                    <div className={``}>
                                                        <p>无需配送</p>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                [1, 9, 14, 15].includes(trade.trade_type)
                                                    ?
                                                    <div className={``}>
                                                        {
                                                            trade.post_type == 0
                                                                ?
                                                                <p>无需配送</p>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            trade.post_type == 1
                                                                ?
                                                                <p>快递物流</p>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            trade.post_type == 2
                                                                ?
                                                                <p>到店自提</p>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                trade.trade_type == 4
                                                    ?
                                                    <div className={``}>
                                                        <p>堂食</p>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                [18].includes(trade.trade_type)
                                                    ?
                                                    <div className={``}>
                                                        {
                                                            trade.post_type == 1
                                                                ?
                                                                <p>顺丰到付</p>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            trade.post_type == 2
                                                                ?
                                                                <p>自提</p>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </FormItem>
                                    </Col>
                            }

                            {
                                [0, 6, 7, 8].includes(trade.trade_status)
                                    ?
                                    null
                                    :
                                    <Col span={6}>
                                        <FormItem label={'支付方式'} >
                                            <div className={``}>
                                                <p>{trade.pay_type_name || '-'}</p>
                                            </div>
                                        </FormItem>
                                    </Col>

                            }
                            <Col span={6}>
                                <FormItem label={'订单备注'} >
                                    <div className={``}>
                                        <p>{trade.user_remark || '暂无备注'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                        {
                            trade.pay_type == 2 && trade.pay_no
                                ?
                                <Row>
                                    <Col span={12}>
                                        <FormItem label={'微信交易流水号'} >
                                            <div className={``}>
                                                <p>{trade.pay_no}</p>
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>
                                :
                                null
                        }

                        {
                            trade.group_trade && trade.group_trade.type == 2 ?
                                <>
                                    <Row>
                                        <Col span={6}>
                                            <FormItem label={'报名校区'} >
                                                <div className={``}>
                                                    <p>{trade.store ? trade.store.name : ""}</p>
                                                </div>
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem label={'学员信息'} >
                                                <div className={``}>
                                                    <p>{trade.student_info ? trade.student_info.real_name : "-"}</p>
                                                </div>
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem label={'学员手机号'} >
                                                <div className={``}>
                                                    <p>{trade.student_info ? trade.student_info.parent_mobile : "-"}</p>
                                                </div>
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem label={'课程顾问'} >
                                                <div className={``}>
                                                    <p>{trade.student_info ? (trade.student_info.course_adviser || "-") : '-'}</p>
                                                </div>
                                            </FormItem>
                                        </Col>
                                    </Row>


                                </> : null
                        }

                        <Row>
                            {
                                trade.trade_type == 4
                                    ?
                                    <Col span={6}>
                                        <FormItem label={'包厢/桌台'} >
                                            <div className={``}>
                                                <p>{trade.desk_info.room ? trade.desk_info.room.name : '-'}/{trade.desk_info.desk_name}</p>
                                            </div>
                                        </FormItem>
                                    </Col>
                                    :
                                    null
                            }
                            {
                                [10].includes(trade.trade_type)
                                    ?
                                    <Col span={6}>
                                        <FormItem label={'订单来源'} >
                                            <div className={``}>
                                                <p>{trade.source == 1 ? '小程序' : '后台'}</p>
                                            </div>
                                        </FormItem>
                                    </Col>
                                    :
                                    null

                            }

                            <Col span={12}>
                                <FormItem label={'标记内容'} >
                                    <div className={``}>
                                        <span className={`mgr10`}>{trade.remark}</span>
                                        <Button type={`default`} size={`small`} onClick={this.handleRemark}>标记</Button>
                                    </div>
                                </FormItem>
                            </Col>

                        </Row>
                    </div>

                </Form>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="订单商品信息" key="1">
                        <div>
                            {
                                trade.first_user_id > 0 || trade.second_user_id > 0
                                    ?
                                    <div>
                                        <div className={`msg-title`}>佣金信息</div>
                                        <Form className={' pd20 '} layout={`inline`}>
                                            <div>
                                                <Row>
                                                    <Col span={18}>
                                                        <FormItem label={'结算状态'} >
                                                            <div className={`colorRed`}>
                                                                {trade.is_commission == 1 ? '已结算' : '未结算'}
                                                            </div>
                                                        </FormItem>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={18}>
                                                        <FormItem label={<span>&emsp;&emsp;玩法</span>} >
                                                            <div className={``}>
                                                                {trade.commission_model == 1 ? '分销裂变模式' : '团长模式'}
                                                            </div>
                                                        </FormItem>
                                                    </Col>
                                                </Row>
                                                {
                                                    trade.first_user_id > 0
                                                        ?
                                                        <Row>
                                                            <Col span={18}>
                                                                <FormItem label={<span>分销佣金</span>} >
                                                                    <div className={``}>
                                                                        {trade.first_user ? trade.first_user.name : ''}   &yen;{trade.first_commission || 0}
                                                                        （手续费：-&yen;{trade.first_commission_charge}）
                                                                    </div>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        null
                                                }
                                                {
                                                    trade.second_user_id > 0
                                                        ?
                                                        <Row>
                                                            <Col span={18}>
                                                                <FormItem label={<span>邀请奖励</span>} >
                                                                    <div className={``}>
                                                                        {trade.second_user ? trade.second_user.name : ''}  &yen;{trade.second_commission}
                                                                        （手续费：-&yen;{trade.second_commission_charge}）
                                                                    </div>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </Form>
                                    </div>
                                    :
                                    null
                            }


                            {
                                [14].includes(trade.trade_type) ? <GroupBuyDetail group_info={trade.group_info} group_list={trade.group_list} group_trade={trade.group_trade} ></GroupBuyDetail> : null
                            }


                            {
                                [2, 3].includes(trade.trade_type)
                                    ?
                                    <div className={`msg-title mgt15`}>订单信息</div>
                                    :
                                    [5, 10].includes(trade.trade_type)
                                        ?
                                        <div className={`msg-title mgt15`}>预订详情</div>
                                        :
                                        <div className={`msg-title mgt15`}>订单商品信息</div>

                            }


                            <div className={'content-body mgt15'}>
                                {
                                    [1, 8, 9, 15].includes(trade.trade_type)
                                        ?
                                        <GoodsOrder onRef={e => { this.GoodsOrder = e }} trade_type={trade.trade_type} tableData={this.state.tableData}></GoodsOrder>
                                        :
                                        null
                                }
                                {
                                    trade.trade_type == 2
                                        ?
                                        <CouponOrder onRef={e => { this.CouponOrder = e }} tableData={[this.state.trade]}></CouponOrder>
                                        :
                                        null
                                }
                                {
                                    trade.trade_type == 3
                                        ?
                                        <SelfOrder onRef={e => { this.SelfOrder = e }} tableData={[this.state.trade]}></SelfOrder>
                                        :
                                        null
                                }
                                {
                                    trade.trade_type == 4
                                        ?
                                        <GoodsOrder onRef={e => { this.GoodsOrder = e }} tableData={this.state.tableData}></GoodsOrder>
                                        :
                                        null
                                }
                                {
                                    trade.trade_type == 5
                                        ?
                                        <KtvOrder onRef={e => { this.KtvOrder = e }} tableData={this.state.tableData} trade={this.state.trade}></KtvOrder>
                                        :
                                        null
                                }
                                {
                                    [6, 7].includes(trade.trade_type)
                                        ?
                                        <CardOrder onRef={e => { this.CardOrder = e }} tableData={this.state.tableData} trade={this.state.trade}></CardOrder>
                                        :
                                        null
                                }
                                {
                                    trade.trade_type == 10
                                        ?
                                        <CourtOrder onRef={e => { this.CourtOrder = e }} tableData={this.state.tableData} trade={this.state.trade}></CourtOrder>
                                        :
                                        null
                                }
                                {
                                    [11].includes(trade.trade_type)
                                        ?
                                        <TimesCardOrder onRef={e => { this.CardOrder = e }} tableData={[this.state.trade]} ></TimesCardOrder>
                                        :
                                        null
                                }
                                {
                                    [14].includes(trade.trade_type)
                                        ?
                                        <GroupOrder onRef={e => { this.GroupOrder = e }} trade={this.state.trade} tableData={this.state.tableData} ></GroupOrder>
                                        :
                                        null
                                }
                                {
                                    [18].includes(trade.trade_type)
                                        ?
                                        <TicketOrder onRef={e => { this.TicketOrder = e }} tableData={[this.state.trade]} ></TicketOrder>
                                        :
                                        null
                                }
                            </div>
                            <div className={`clearfix`}>
                                <div className={`fr order-price`}>
                                    <div className={`order-price-item`}>
                                        <div className={`txt1`}>{trade.trade_type == 5 ? '预订总额' : trade.trade_type == 14 ? '支付合计' : '商品总额'}：</div>
                                        <div className={`txt2`}>&yen;{trade.trade_total_price || trade.total_price}</div>
                                    </div>
                                    {
                                        trade.post_type == 1 && [1, 9].includes(trade.trade_type)
                                            ?
                                            <div className={`order-price-item`}>
                                                <div className={`txt1`}>配送费：</div>
                                                <div className={`txt2`}>&yen;{trade.post_fee}</div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        trade.trade_extends && trade.trade_extends.coupon_discount_price > 0
                                            ?
                                            <div className={`order-price-item`}>
                                                <div className={`txt1`}>优惠券抵扣：</div>
                                                <div className={`txt2`}>-&yen;{trade.trade_extends.coupon_discount_price || '0.00'}</div>
                                            </div>
                                            :
                                            ''
                                    }
                                    {
                                        trade.trade_extends && trade.trade_extends.level_discount_price > 0
                                            ?
                                            <div className={`order-price-item`}>
                                                <div className={`txt1`}>会员优惠：</div>
                                                <div className={`txt2`}>-&yen;{trade.trade_extends.level_discount_price || '0.00'}</div>
                                            </div>
                                            :
                                            ''
                                    }
                                    {
                                        trade.trade_extends && trade.trade_extends.points_discount_price > 0
                                            ?
                                            <div className={`order-price-item`}>
                                                <div className={`txt1`}>积分抵扣：</div>
                                                <div className={`txt2`}>-&yen;{trade.trade_extends.points_discount_price || '0.00'}</div>
                                            </div>
                                            :
                                            ''
                                    }

                                    {
                                        trade.trade_extends && trade.trade_type == 15
                                            ?
                                            <div className={`order-price-item`}>
                                                <div className={`txt1`}>满减优惠：</div>
                                                <div className={`txt2`}>-&yen;{trade.trade_extends.full_cut_price || '0.00'}</div>
                                            </div>
                                            :
                                            ''
                                    }
                                    <div className={`order-price-item`}>
                                        {
                                            [0, 6, 7, 8].includes(trade.trade_status)
                                                ?
                                                <div className={`txt1`}>待付款：</div>
                                                :
                                                <div className={`txt1`}>实付款：</div>

                                        }
                                        {
                                            trade.trade_extends && trade.trade_type == 9 && trade.trade_extends.cost_points > 0
                                                ?
                                                <div className={`txt2 primary`}>&yen;{trade.pay_ment} + {trade.trade_extends.cost_points}积分</div>
                                                :
                                                <div className={`txt2 primary`}>&yen;{trade.pay_ment}</div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className={`clearfix`}>
                                <div className={`fr`}>
                                    <div className={`order-btn-group`}>
                                        {
                                            [0, 6, 7, 8].includes(trade.trade_status) && trade.trade_type != 18
                                                ?
                                                null
                                                :
                                                <Button type={'default'} className={`mgl10`} onClick={this.handlePrint}>打印小票</Button>
                                        }
                                        {
                                            trade.trade_type == 14 && (trade.group_trade && (trade.group_trade.status == 2 && (!trade.group_trade.is_tuan) || [3, 8].includes(trade.group_trade.status))) ?
                                                <Button type={'default'} className={`mgl10`} onClick={this.handleRefund}>退款</Button>
                                                :
                                                null

                                        }
                                        {
                                            trade.trade_status == 9
                                                ?
                                                <div>
                                                    <Button type={'default'} className={`mgl10`} onClick={this.handleRefund}>拒单</Button>
                                                    <Button type={'default'} className={`mgl10`} onClick={this.handleAccept}>接单</Button>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            trade.trade_status == 1
                                                ?
                                                <div>
                                                    {
                                                        trade.pay_ment > 0 && trade.trade_type != 14
                                                            ?
                                                            <Button type={'default'} className={`mgl10`} onClick={this.handleRefund}>退款</Button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        trade.post_type == 2 && ![5, 10].includes(trade.trade_type)
                                                            ?
                                                            <Button type={'default'} className={`mgl10`} onClick={this.handlePickUp}>核销</Button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                :
                                                null

                                        }
                                        {
                                            trade.trade_status == 1 && [5, 10].includes(trade.trade_type)
                                                ?
                                                <Button type={'default'} className={`mgl10`} onClick={this.handlePickUp}>核销</Button>
                                                :
                                                null
                                        }
                                        {
                                            trade.trade_status == 10
                                                ?
                                                <div>
                                                    {
                                                        trade.pay_ment > 0 && trade.trade_type != 14
                                                            ?
                                                            <Button type={'default'} className={`mgl10`} onClick={this.handleRefund}>退款</Button>
                                                            :
                                                            null
                                                    }
                                                    <Button type={'default'} className={`mgl10`} onClick={this.handleExpress}>标记发货</Button>
                                                </div>
                                                :
                                                null

                                        }
                                        {
                                            trade.trade_status == 3
                                                ?
                                                <div>
                                                    <Button type={'default'} className={`mgl10`} onClick={this.handleRefund}>同意退款</Button>
                                                    <Button type={'default'} className={`mgl10`} onClick={this.handleRefuse}>拒绝退款</Button>
                                                </div>
                                                :
                                                null
                                        }




                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    {
                        [2, 3, 4, 5, 6, 7, 10, 11, 18].includes(trade.trade_type)
                            ?
                            null
                            :
                            <TabPane tab="配送及物流信息" key="2">
                                <div>
                                    <div className={`msg-title`}>收货人信息</div>
                                    <Form className={' pd20 '} layout={`inline`}>
                                        <div>
                                            <Row>
                                                <Col span={18}>
                                                    <FormItem label={`${trade.post_type == 1 ? '收货人' : '提货人'}`} >
                                                        <div className={``}>
                                                            <p>{trade.user_address ? trade.user_address.name : '-'}</p>
                                                        </div>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={18}>
                                                    <FormItem label={'手机号'} >
                                                        <div className={``}>
                                                            <p>{trade.user_address ? trade.user_address.mobile : '-'}</p>
                                                        </div>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            {
                                                trade.post_type == 1
                                                    ?
                                                    <Row>
                                                        <Col span={18}>
                                                            <FormItem label={'收货地址'} >
                                                                <div className={``}>
                                                                    <p>{trade.user_address ? trade.user_address.address : '-'}</p>
                                                                </div>
                                                            </FormItem>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </Form>
                                    <div className={`msg-title`}>物流信息</div>
                                    <Form className={' pd20 '} layout={`inline`}>
                                        <div>
                                            {
                                                trade.express && trade.express.express_name ?
                                                    <Row>
                                                        <Col span={18}>
                                                            <FormItem label={'快递公司'} >
                                                                <div className={``}>
                                                                    <p>{trade.express.express_name}</p>
                                                                </div>

                                                            </FormItem>

                                                        </Col>
                                                        <Col span={18}>

                                                            <FormItem label={'快递单号'} >
                                                                <div className={``}>
                                                                    <p>{trade.express.express_sn}</p>
                                                                </div>

                                                            </FormItem>
                                                        </Col>


                                                    </Row> :
                                                    <Row>

                                                        <Col span={18}>
                                                            <FormItem label={'物流进度'} >
                                                                <div className={``}>
                                                                    <p>暂无物流进度</p>
                                                                </div>

                                                            </FormItem>
                                                        </Col>

                                                    </Row>

                                            }



                                        </div>
                                    </Form>
                                </div>
                            </TabPane>
                    }
                    {
                        [18].includes(trade.trade_type) ?
                            <TabPane tab="观演人及物流信息" key="2">
                                <div>
                                    <div className={`msg-title`}>观演人信息</div>
                                    <Form className={' pd20 '} layout={`inline`}>
                                        <div>
                                            {
                                                trade.ticket_info?.user_info?.map(val => {
                                                    return (
                                                        <Row key={val.id}>
                                                            <Col span={18}>
                                                                <FormItem label={'手机号'} >
                                                                    <div className={``}>
                                                                        <span>观演人：{val.name}</span>
                                                                        <span className='mgl20'>身份证：{val.certificate_number}</span>
                                                                    </div>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }


                                        </div>
                                    </Form>
                                    {
                                        trade.post_type == 1 ? <>

                                            <div className={`msg-title`}>收货人信息</div>
                                            <Form className={' pd20 '} layout={`inline`}>
                                                <div>
                                                    <Row>
                                                        <Col span={18}>
                                                            <FormItem label={`${trade.post_type == 1 ? '收货人' : '提货人'}`} >
                                                                <div className={``}>
                                                                    <p>{trade.user_address ? trade.user_address.name : '-'}</p>
                                                                </div>
                                                            </FormItem>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={18}>
                                                            <FormItem label={'手机号'} >
                                                                <div className={``}>
                                                                    <p>{trade.user_address ? trade.user_address.mobile : '-'}</p>
                                                                </div>
                                                            </FormItem>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        trade.post_type == 1
                                                            ?
                                                            <Row>
                                                                <Col span={18}>
                                                                    <FormItem label={'收货地址'} >
                                                                        <div className={``}>
                                                                            <p>{trade.user_address ? trade.user_address.address : '-'}</p>
                                                                        </div>
                                                                    </FormItem>
                                                                </Col>
                                                            </Row>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </Form>
                                        </> : null
                                    }

                                    <div className={`msg-title`}>物流信息</div>
                                    <Form className={' pd20 '} layout={`inline`}>
                                        <div>
                                            {
                                                trade.express && trade.express.express_name ?
                                                    <Row>
                                                        <Col span={18}>
                                                            <FormItem label={'快递公司'} >
                                                                <div className={``}>
                                                                    <p>{trade.express.express_name}</p>
                                                                </div>

                                                            </FormItem>

                                                        </Col>
                                                        <Col span={18}>

                                                            <FormItem label={'快递单号'} >
                                                                <div className={``}>
                                                                    <p>{trade.express.express_sn}</p>
                                                                </div>

                                                            </FormItem>
                                                        </Col>


                                                    </Row> :
                                                    <Row>

                                                        <Col span={18}>
                                                            <FormItem label={'物流进度'} >
                                                                <div className={``}>
                                                                    <p>{trade.post_type == 1 ? '暂无物流进度' : '无需配送及物流'} </p>
                                                                </div>

                                                            </FormItem>
                                                        </Col>

                                                    </Row>

                                            }

                                        </div>
                                    </Form>
                                </div>
                            </TabPane>
                            : null
                    }
                </Tabs>




                {this.state.isLoading ? <Loading /> : null}


            </div>
        )
    }

}

export default Form.create({})(withRouter(OrderDetail))
