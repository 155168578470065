import React from 'react'
import { Form, Tabs, Checkbox, Input, Radio, Button, Col } from 'antd'
import { withRouter } from 'react-router-dom'
import Loading from '@/components/Loading'
import { setTicket } from '@/api/print'
import message from '@/utils/message'
import '@/assets/css/system/index.scss'
const FormItem = Form.Item;
const { TabPane } = Tabs

class Ticket extends React.Component{
    state = {
        formData:'',
        activeStatus:'1',
        activePic:'https://img.cxkoo.com/chengxuan/1/2021-12-16/78b3e798c801dbdc376fd5ba7e587de0.png',
        isLoading:false,
        tabLists:[
            {
                name:'收银小票',
                status:'1',
                pic:'https://img.cxkoo.com/chengxuan/1/2021-12-16/78b3e798c801dbdc376fd5ba7e587de0.png'
            },
            {
                name:'后厨小票',
                status:'2',
                pic:'https://img.cxkoo.com/chengxuan/1/2021-12-16/61ea2bc384f1bbf0588ccbba03ad43ad.png'
            },
            {
                name:'开卡充值小票',
                status:'3',
                pic:'https://img.cxkoo.com/chengxuan/1/2021-12-16/56bfc99850975680336cf72feaa7b90c.png'
            },
            {
                name:'预约小票',
                status:'4',
                pic:'https://img.cxkoo.com/chengxuan/1/2021-12-16/ea545710b0fb9cf9385bd7f0721f329b.png'
            },
            {
                name:'核销小票',
                status:'5',
                pic:'https://img.cxkoo.com/chengxuan/1/2021-12-16/0bc14157d01b45fae53ae8f7a9d7de59.png'
            },
            {
                name:'存酒小票',
                status:'6',
                pic:'https://img.cxkoo.com/chengxuan/1/2021-12-16/8dd30a11a313d4fbacd2882198d0adb9.png'
            }
        ]
    }
    componentDidMount() {
        this.fetchData()
    }
    clickTab = val => {
        let tabLists = this.state.tabLists,
            activePic = '';
        tabLists.forEach(item => {
            if(item.status == val){
                activePic = item.pic
            }
        })
        this.setState({
            activeStatus:val,
            activePic:activePic
        },() => {
            this.fetchData()
        })
    }
    fetchData = () => {
        let sendData={}
        sendData.scene_type = this.state.activeStatus;
        this.setState({
            isLoading:true
        })
        setTicket(sendData)
            .then(res => {
                if(res.code == 1){
                    let config = res.data.config;
                    console.log('config',config)
                    this.setState({
                        formData: config
                    })
                }
            })
            .finally(() => {
                this.setState({
                    isLoading:false
                })
            })
    }
    handleCheckChange = (e,name) => {
        let formData = this.state.formData || {};
        formData[name] = e.target.checked ? 1 : 0
        this.setState({
            formData
        })
    }
    saveData = () => {
        let sendData = {},
            formData = this.state.formData;
        this.props.form.validateFields((err,values) => {
            if(!err){
                sendData = values;
                sendData.scene_type = Number(this.state.activeStatus);
                sendData.id = formData.id || '';
                sendData.store_address = formData.store_address;
                sendData.store_phone = formData.store_phone;
                sendData.sun_qrcode = formData.sun_qrcode;
                sendData.show_paperwork = formData.show_paperwork;
                console.log(sendData)
                this.setState({
                    isLoading:true
                })
                setTicket(sendData)
                    .then(res => {
                        if(res.code == 1){
                            message.success('保存成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading:false
                        })
                    })



            }
        })


    }
    render() {
        let data = this.state.formData || {};
        const {getFieldDecorator, getFieldValue} = this.props.form
        const formItemLayout = {
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            }
        }
        return(
            <div className={`shadow-radius`}>
                <div className={`inner-cont`}>
                    <Col span={18}>
                        <div className={``}>
                            <Tabs onChange={this.clickTab} type="card" className={'mgt15'} activeKey={this.state.activeStatus}>
                                {
                                    this.state.tabLists.map((item,index) => (
                                        <TabPane tab={ item.name } key={item.status}></TabPane>
                                    ))
                                }
                            </Tabs>
                            <Form {...formItemLayout} className={'model-form '}>
                                <div className={`msg-title mgb15`}>小票信息</div>
                                <FormItem label={`页尾`}>
                                    <Checkbox checked={data.store_address || 0} onChange={ (e) => { this.handleCheckChange(e,'store_address') } }>门店地址</Checkbox>
                                    <Checkbox checked={data.store_phone || 0} onChange={ (e) => { this.handleCheckChange(e,'store_phone') } }>门店电话</Checkbox>
                                    {
                                        this.state.activeStatus != 2
                                            ?
                                            <span>
                                                <Checkbox checked={data.sun_qrcode || 0} onChange={ (e) => { this.handleCheckChange(e,'sun_qrcode') } } >小程序太阳码</Checkbox>
                                                <Checkbox checked={data.show_paperwork || 0} onChange={ (e) => { this.handleCheckChange(e,'show_paperwork') } } >文案</Checkbox>
                                            </span>
                                            :
                                            null
                                    }

                                </FormItem>
                                {
                                    this.state.activeStatus != 2
                                        ?
                                        <FormItem label={`文案设置`}>
                                            {getFieldDecorator('paperwork', {
                                                initialValue: data.paperwork || `关注小程序，享更多优惠`,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请填写文案'
                                                    }
                                                ]
                                            })(<Input placeholder={'请填写文案'} disabled={!data.show_paperwork} />)}
                                        </FormItem>
                                        :
                                        null
                                }

                                <div className={`msg-title mgb15`}>打印设置</div>
                                <FormItem label={`一次打印`}>
                                    {getFieldDecorator('printer_num', {
                                        initialValue: data.printer_num || 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请设置一次打印张数'
                                            }
                                        ]
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>1张</Radio>
                                            <Radio value={2}>2张</Radio>
                                            <Radio value={3}>3张</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                <FormItem label={`收款后打印方式`} required={true}>
                                    {getFieldDecorator('printer_type', {
                                        initialValue: data.printer_type === undefined ? 1 : data.printer_type,
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>自动打印</Radio>
                                            <Radio value={0}>手动打印</Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                <FormItem label={`  `} colon={false}>
                                    <Button type={`primary`} onClick={ this.saveData }>保存</Button>
                                </FormItem>
                            </Form>

                        </div>
                    </Col>
                    <Col span={6} >
                        <div className={`ticket-preview`}>
                            <img src={this.state.activePic} className={`ticket-img`} alt=""/>
                            {
                                this.state.activeStatus != 2 && data.sun_qrcode
                                    ?
                                    <img src={`https://img.cxkoo.com/chengxuan/1/2021-12-16/1f0260012da4f250ee9693a15eb00bbf.png`} className={`sun-code`} alt=""/>
                                    :
                                    null
                            }
                        </div>
                    </Col>

                </div>
                { this.state.isLoading ? <Loading /> : null }
            </div>
        )
    }
}

export default Form.create({})(withRouter(Ticket))
