import React from 'react'
import { Form, Steps, Col, Row, Tag, Tabs, Button, Modal, Table } from 'antd'
import { withRouter } from 'react-router-dom'
import message from '@/utils/message'
import Loading from '@/components/Loading'
import Deliver from './components/deliver'
import Remark from './components/remark'
import { orderDetail, acceptRefund, addRemark } from '@/api/order'
import '@/assets/css/order/index.scss'
const FormItem = Form.Item
const { Step } = Steps
const { TabPane } = Tabs

//订单类型 1-普通下单(购物车) 2-优惠券 3-自助买单 4-扫码点餐 5-包厢预订 6-储值卡开卡 7-储值卡充值 8-限时打折 9-积分兑换 10-场馆预订
//支付状态0待支付1已支付2已完成3申请退款4已退款5已部分退款6用户已取消7超时未支付自动关闭8订单过期(废弃)9待接单10待发货11已发货12待支付尾款
class CourseOrderDetail extends React.Component {
    state = {
        trade: '',
        currentStep: 0,
        columns: [
            {
                title: '课程信息',
                dataIndex: 'name',
                width: '200px',
                render: (text, rowData, index) => {
                    return (
                        <div className={``}>
                            <p className={`lh25`}>{rowData.name || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title: '课时数',
                dataIndex: 'course_num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.course_num ? rowData.course_num + "课时" : "-"}</p>
                        </div>
                    )
                }
            },
            {
                title: '有效期',
                dataIndex: 'sku',
                render: (text, rowData, index) => {

                    return (
                        <div>
                            <p className={`lh25`}>{this.state.course_text}</p>
                        </div>
                    )
                }
            },
            {
                title: '售价',
                dataIndex: 'price',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>￥{rowData.price}</p>
                        </div>
                    )
                }
            },
            {
                title: '数量',
                dataIndex: 'num',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <p className={`lh25`}>{rowData.num}</p>
                        </div>
                    )
                }
            },

            {
                title: '优惠',
                dataIndex: 'discount_price',
                render: (text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{rowData.discount_price || '-'}</p>
                        </div>
                    )
                }
            },
            {
                title: '合计',
                dataIndex: 'total',
                render: (text, rowData, index) => {
                    return (
                        <div className={''}>
                            <p className={`lh25`}>{rowData.pay_ment || '-'}</p>
                        </div>
                    )
                }
            }
        ],
        tableData: [],
        isLoading: false,
        course_text: "-"

    }

    componentDidMount() {
       
        if(this.props.trade){
            this.handleInit(this.props.trade)
        }else{
            this.fetchData()
        }
        
    }
    fetchData = () => {
        let sendData = {}
        sendData.order_sn = this.props.match.params.id
        this.setState({
            isLoading: true
        })
        orderDetail(sendData)
            .then(res => {
                if (res.code == 1) {
                  let trade = res.data.trade
                  this.handleInit(trade)
                }
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    handleInit = (trade) => {
        let 
            post_type = trade.post_type,
            currentStep = 0,
            tableData = trade.goods_lists || [],
            course_info = trade.course_info
        switch (trade.trade_status) {
            case 0:
            case 4:
            case 6:
            case 7:
            case 8:
                currentStep = 0;
                break;
            case 1:
            case 9:
                currentStep = 1;
                if (trade.trade_status == 1 && post_type == 2) {
                    currentStep = 2; //自提目前自动接单支付成功即待核销
                }
                if ([2, 3, 5, 10].includes(trade.trade_type)) {
                    currentStep = 2; //优惠券  自助买单
                }
                break;
            case 10:
                currentStep = 3;
                break;
            case 11:
                currentStep = 4;
                break;
            case 2:
                currentStep = 4;
                if (post_type == 1) {
                    //快递
                    currentStep = 5;
                }
                break;
            case 3:
                currentStep = 1;
                break;
            default:
                currentStep = 0;
                break;
        }
        let trade_total_price = 0;
        trade.goods_lists && trade.goods_lists.forEach((good, goodIndex) => {
            good.total_price = good.total_price ? parseFloat(good.total_price) : 0
            trade_total_price = trade_total_price + good.total_price
        })
        trade.trade_total_price = trade_total_price ? trade_total_price.toFixed(2) : 0
        if (trade.trade_type == 10) {
            tableData = [trade]
        }
        let course_text = this.course_tag(course_info)
        this.setState({
            trade, tableData, currentStep, course_text
        })
    }

    course_tag = (item) => {
        if (!item) {
            return "-"
        }

        if (item.charge_type == 1) {
            return '-'
        } else if (item.charge_type == 2) {
            return item.course_time + ["", "天", "月", "季", "年"][item.course_time_unit]
        } else {
            if (item.grade_info) {
                return item.grade_info.name + item.grade_info.start_date + "至" + item.grade_info.end_date
            }
            return ""
        }
    }
    onStepChange = val => { }
    handleRefund = () => {
        let trade = this.state.trade,
            sendData = {};
        sendData.order_sn = trade.order_sn;
        Modal.confirm({
            title: `是否对这笔订单进行退款？`,
            okType: 'primary',
            onOk: () => {
                this.setState({
                    isLoading: true
                })
                acceptRefund(sendData)
                    .then(res => {
                        if (res.code == 1) {
                            message.success('操作成功')
                                .then(() => {
                                    this.fetchData()
                                })
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        })
                    })
            }
        })

    }



    handleRemark = () => {
        let sendData = {};
        this.Remark.show(res => {
            sendData.remark = res.remark || '';
            sendData.order_sn = this.state.trade.order_sn;
            if (!sendData.remark) { return false };
            this.setState({
                isLoading: true
            })
            addRemark(sendData)
                .then(res => {
                    if (res.code == 1) {
                        message.success('标记成功')
                            .then(() => {
                                this.fetchData()
                            })
                    }
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })

        })
    }

    render() {
        let trade = this.state.trade || {}
        return (
            <div className={`shadow-radius`}>
                <Deliver onRef={e => { this.Deliver = e }}></Deliver>
                <Remark onRef={e => { this.Remark = e }}></Remark>
                {
                    [2, 3, 6, 7, 11, 12].includes(trade.trade_type)
                        ?
                        <div className={'pd20'}>
                            <Steps progressDot current={this.state.currentStep} onChange={this.onStepChange} size={'small'}>
                                <Step title="客户下单" description={trade.created_at || ''} />
                                <Step title="客户付款" description={trade.pay_time || ''} />
                                <Step title="交易成功" description={trade.finished_at || ''} />
                            </Steps>
                        </div>
                        :
                        null
                }

                <div className={`msg-title`}>订单基本信息</div>

                <Form className={' pd20 '} layout={`inline`}>
                    <div className={`order_sn`}>
                        <span className={`mgr10`}>商户单号：{trade.order_sn}</span>
                        {
                            [1, 2].includes(trade.trade_status)
                                ?
                                <Tag color="green">{trade.trade_status_name}</Tag>
                                :
                                null
                        }
                        {
                            [0, 6, 7, 8].includes(trade.trade_status)
                                ?
                                <Tag color="red">{trade.trade_status_name}</Tag>
                                :
                                null

                        }
                        {
                            [3, 4, 5, 9, 10, 11, 12].includes(trade.trade_status)
                                ?
                                <Tag color="orange">{trade.trade_status_name}</Tag>
                                :
                                null
                        }
                    </div>
                    <div>
                        <Row>
                            <Col span={6}>
                                <FormItem label={'客户昵称'} >
                                    <div className={``}>
                                        <p>{trade.user_info ? trade.user_info.name : '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'客户手机号'} >
                                    <div className={``}>
                                        <p>{trade.user_info ? trade.user_info.mobile : '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'客户ID'} >
                                    <div className={``}>
                                        <p>{trade.user_info ? trade.user_info.id : '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'会员等级'} >
                                    <div className={``}>
                                        <p>普通会员</p>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <FormItem label={'订单类型'} >
                                    <div className={``}>
                                        <p>{trade.trade_type_name}订单</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'报名校区'} >
                                    <div className={``}>
                                        <p>{trade.store ? trade.store.name : ""}</p>
                                    </div>
                                </FormItem>
                            </Col>

                            <Col span={6}>
                                <FormItem label={'学员信息'} >
                                    <div className={``}>
                                        <p>{trade.student_info ? trade.student_info.real_name : "-"}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'学员手机号'} >
                                    <div className={``}>
                                        <p>{trade.student_info ? trade.student_info.parent_mobile : "-"}</p>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={6}>
                                <FormItem label={'课程顾问'} >
                                    <div className={``}>
                                        <p>{trade.student_info ? (trade.student_info.course_adviser || "-") : '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>

                            <Col span={6}>
                                <FormItem label={'订单来源'} >
                                    <div className={``}>
                                        <p>{trade.source == 1 ? '小程序' : '后台'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'订单备注'} >
                                    <div className={``}>
                                        <p>{trade.user_remark || '-'}</p>
                                    </div>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label={'标记内容'} >
                                    <div className={``}>
                                        <span className={`mgr10`}>{trade.remark || "-"}</span>
                                        <Button type={`default`} size={`small`} onClick={this.handleRemark}>标记</Button>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>

                </Form>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="订单商品信息" key="1">
                        <div>

                            <div className={'content-body mgt15'}>
                                <Table
                                    size={`middle`}
                                    columns={this.state.columns}
                                    dataSource={this.state.tableData}
                                    rowKey={record => record.id}
                                    pagination={false}
                                ></Table>

                            </div>
                            <div className={`clearfix`}>
                                <div className={`fr order-price`}>
                                    <div className={`order-price-item`}>
                                        <div className={`txt1`}>课程总额：</div>
                                        <div className={`txt2`}>&yen;{trade.trade_total_price || trade.total_price}</div>
                                    </div>

                                    {
                                        trade.trade_extends && trade.trade_extends.coupon_discount_price>0
                                            ?
                                            <div className={`order-price-item`}>
                                                <div className={`txt1`}>优惠券抵扣：</div>
                                                <div className={`txt2`}>-&yen;{trade.trade_extends.coupon_discount_price || '0.00'}</div>
                                            </div>
                                            :
                                            ''
                                    }
                                     {
                                        trade.trade_extends && trade.trade_extends.points_discount_price>0
                                            ?
                                            <div className={`order-price-item`}>
                                                <div className={`txt1`}>积分抵扣：</div>
                                                <div className={`txt2`}>-&yen;{ trade.trade_extends.points_discount_price || '0.00' }</div>
                                            </div>
                                            :
                                            ''
                                    }
                                    <div className={`order-price-item`}>
                                        {
                                            [0, 6, 7, 8].includes(trade.trade_status)
                                                ?
                                                <div className={`txt1`}>待付款：</div>
                                                :
                                                <div className={`txt1`}>实付款：</div>

                                        }
                                        <div className={`txt2 primary`}>&yen;{trade.pay_ment}</div>

                                    </div>
                                </div>
                            </div>
                            <div className={`clearfix`}>
                                <div className={`fr`}>
                                    <div className={`order-btn-group`}>

                                        {
                                            trade.trade_status == 2
                                                ?
                                                <div>
                                                    {
                                                        trade.pay_ment > 0
                                                            ?
                                                            <Button type={'default'} className={`mgl10`} onClick={this.handleRefund}>退款</Button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                :
                                                null

                                        }




                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>

                </Tabs>






                {this.state.isLoading ? <Loading /> : null}


            </div>
        )
    }


}

export default Form.create({})(withRouter(CourseOrderDetail))
