/**
 * 扫码下单组件
 * @type 10
 * @return Object
 */

import React from 'react'
import {Form, Icon, Modal, Tooltip} from 'antd'
import {ICON_FONT_URL} from '@/constants/iconFont'
import PropTypes from "prop-types";
import CX from '@/utils/CX'
const EditorIcon = Icon.createFromIconfontCN({
    scriptUrl: ICON_FONT_URL
})

class tpl_con_typ10 extends React.Component{
    static contextTypes = {
        updateDesign: PropTypes.func,
        deleteModule: PropTypes.func
    }
    state = {
        moduleData: {}
    }
    componentDidMount() {
        this.props.onRef(this)
        let tmp = this.props.module ? JSON.stringify(this.props.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let tmp = nextProps.module ? JSON.stringify(nextProps.module) : ''
        tmp && this.setState({moduleData: JSON.parse(tmp)})
    }
    handleEditModule = () => {
        let moduleData = this.state.moduleData;
        this.context.updateDesign(moduleData)
    }
    handleDeleteModule = () => {
        let moduleData = this.state.moduleData;
        Modal.confirm({
            title: `确定删除这个模块吗？`,
            okType: `danger`,
            onOk: () => {
                this.context.deleteModule(moduleData)
            }
        })
    }
    render() {
        let moduleData = this.state.moduleData || {}
        return(
            Object.keys(moduleData).length
                ?
                <Tooltip placement={`left`} title={`行业组件`}>
                    <div className={`modulePadding ${moduleData.is_choose ? 'selected' : ''}`} style={{
                        paddingTop: `${moduleData.content.modulePadding}px`,
                        paddingBottom: `${moduleData.content.modulePadding}px`
                    }} onClick={this.handleEditModule}>
                        <div className={`tpl10-cont`}>
                            {
                                moduleData.content.style  == 1
                                    ?
                                    <div className={`item-style1`} style={{ backgroundColor : `${ moduleData.content.bgColor }` }}>
                                        {
                                            moduleData.content.dataset.map((item,index) => (
                                                <div className={`scan-item`} key={index}>
                                                    <div
                                                        className={`scan-item-img`}
                                                        style={{ backgroundImage:`url(${ item.pic })` }}
                                                    ></div>
                                                    <div className={`txt`} style={{ color:`${ moduleData.content.txtColor }` }}>{ item.title }</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    null
                            }
                            {
                                moduleData.content.style == 2
                                    ?

                                    <div className={`item-style2`}>
                                        {
                                            moduleData.content.dataset.map((item,index) => (
                                                <div className={`scan-item`} key={index} style={{ backgroundColor:`${ moduleData.content.bgColor }` }}>
                                                    <div
                                                        className={`scan-item-img`}
                                                        style={{ backgroundImage:`url(${ item.pic })` }}
                                                    ></div>
                                                    <div className={`txt`} style={{ color:`${ moduleData.content.txtColor }` }}>{ item.title }</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    null
                            }

                        </div>
                        <EditorIcon type={`icon-guanbi`} className={`del-module`} onClick={ this.handleDeleteModule }></EditorIcon>
                    </div>
                </Tooltip>
                :
                null
        )
    }

}

export default Form.create({})(tpl_con_typ10)
