import React, { Component } from 'react'
import { Modal, Table, Input, Radio, Select } from 'antd'
import { couponLists } from '@/api/coupon'

class SetExpendVipRule extends Component {
    state = {
        visible: false,
        columns: [
            {
                title: 'V1',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v1}
                                onChange={this.handleChange('v1')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v2}
                                onChange={this.handleChange('v2')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v3}
                                onChange={this.handleChange('v3')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v4}
                                onChange={this.handleChange('v4')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v5}
                                onChange={this.handleChange('v5')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v6}
                                onChange={this.handleChange('v6')}
                            />
                        </div>
                    )
                }
            },
        ],
        tableData: [
            {
                id: 1,
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            },
        ],
        callBack: null
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = (row, callBack) => {
        let tableData = this.state.tableData;
        tableData[0] = row;
        this.setState({
            tableData,
            callBack: callBack || null,
            visible: true
        })
    }

    handleChange = name => {
        return e => {
            let value = e.target.value;
            let tableData = this.state.tableData;
            tableData[0][name] = value;
            this.setState({
                tableData
            })
        }
    }

    handleOk = () => {
        let callBack = this.state.callBack;
        let tableData = this.state.tableData;
        if (typeof callBack == 'function') {
            callBack(tableData[0])
        }

        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        return (
            <Modal
                title="设置会员价"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={850}
            >
                <p className='mgt10 mgb20'>优惠方式：折扣</p>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
            </Modal>
        )
    }
}

class SetGoodsVipRule extends Component {
    state = {
        visible: false,
        columns: [
            {
                title: '规格',
                dataIndex: 'sku',
                render: (text, rowData, index) => {
                    if (rowData.norms && rowData.norms.length) {
                        return (
                            <div>
                                {
                                    rowData.norms.map(val => {
                                        return <p>{val.name}：{val.value}</p>
                                    })
                                }
                            </div>
                        )
                    }

                    return <p>-</p>
                }
            },
            {
                title: '零售价',
                dataIndex: 'price',
                width: 100,
                render: (text, rowData, index) => {
                    return (
                        <div>&yen;{rowData.price}</div>
                    )
                }
            },
            {
                title: 'V1',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v1}
                                onChange={this.handleChange('v1', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v2}
                                onChange={this.handleChange('v2', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v3}
                                onChange={this.handleChange('v3', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v4}
                                onChange={this.handleChange('v4', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v5}
                                onChange={this.handleChange('v5', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v6}
                                onChange={this.handleChange('v6', index)}
                            />
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        callBack: null,
        discount_type: 1,
        goods: {}
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = (row, callBack) => {
        let tableData = [],
            goods = row;

        if (row.discount_list && row.discount_list.length) {
            tableData = row.discount_list
        } else {
            if (row.sku_list && row.sku_list.length) {
                let norms = row.goods_extend.norms,
                    props = row.goods_extend.props;

                row.sku_list.map(val => {
                    let item = {
                        id: row.id,
                        sku_id: val.id,
                        price: val.price,
                        v1: '',
                        v2: '',
                        v3: '',
                        v4: '',
                        v5: '',
                        v6: ''
                    }
                    item.norms = norms.map(v => {
                        let key = val.props[v.id];
                        return ({ name: v.name, value: props[key] })
                    })
                    tableData.push(item)
                })
            } else {
                tableData.push({
                    id: row.id,
                    sku_id: '',
                    price: row.price,
                    v1: '',
                    v2: '',
                    v3: '',
                    v4: '',
                    v5: '',
                    v6: ''
                })
            }
        }


        this.setState({
            tableData,
            goods,
            discount_type: row.discount_type || 1,
            callBack: callBack || null,
            visible: true
        })
    }

    handleChange = (name, index) => {
        return e => {
            let value = e.target.value;
            let tableData = this.state.tableData;
            tableData[index][name] = value;
            this.setState({
                tableData
            })
        }
    }

    handleOk = () => {
        let { goods, tableData, discount_type, callBack } = this.state
        if (tableData.length) {
            let min = { v1: 0, v2: 0, v3: 0, v4: 0, v5: 0, v6: 0 },
                max = { v1: 0, v2: 0, v3: 0, v4: 0, v5: 0, v6: 0 };

            tableData.forEach((item, index) => {
                let v1 = Number(item.v1);
                if (!isNaN(v1)) {
                    if (index == 0) {
                        min.v1 = item.v1
                        max.v1 = item.v1
                    }
                    min.v1 = min.v1 < v1 ? min.v1 : v1;
                    max.v1 = max.v1 > v1 ? max.v1 : v1;
                }
                let v2 = Number(item.v2);
                if (!isNaN(v2)) {
                    if (index == 0) {
                        min.v2 = item.v2
                        max.v2 = item.v2
                    }
                    min.v2 = min.v2 < v2 ? min.v2 : v2;
                    max.v2 = max.v2 > v2 ? max.v2 : v2;
                }
                let v3 = Number(item.v3);
                if (!isNaN(v3)) {
                    if (index == 0) {
                        min.v3 = item.v3
                        max.v3 = item.v3
                    }
                    min.v3 = min.v3 < v3 ? min.v3 : v3;
                    max.v3 = max.v3 > v3 ? max.v3 : v3;
                }
                let v4 = Number(item.v4);
                if (!isNaN(v4)) {
                    if (index == 0) {
                        min.v4 = item.v4
                        max.v4 = item.v4
                    }
                    min.v4 = min.v4 < v4 ? min.v4 : v4;
                    max.v4 = max.v4 > v4 ? max.v4 : v4;
                }
                let v5 = Number(item.v5);
                if (!isNaN(v5)) {
                    if (index == 0) {
                        min.v5 = item.v5
                        max.v5 = item.v5
                    }
                    min.v5 = min.v5 < v5 ? min.v5 : v5;
                    max.v5 = max.v5 > v5 ? max.v5 : v5;
                }
                let v6 = Number(item.v6);
                if (!isNaN(v6)) {
                    if (index == 0) {
                        min.v6 = item.v6
                        max.v6 = item.v6
                    }
                    min.v6 = min.v6 < v6 ? min.v6 : v6;
                    max.v6 = max.v6 > v6 ? max.v6 : v6;
                }
            })

            for (let key in min) {
                if (min[key] != max[key]) {
                    if (discount_type == 1) {
                        goods[key] = min[key] + '~' + max[key] + '折'
                    } else {
                        goods[key] = '￥' + min[key] + '~' + max[key]
                    }
                } else if (min[key] == max[key] && min[key] != 0) {
                    if (discount_type == 1) {
                        goods[key] = min[key] + '折'
                    } else {
                        goods[key] = '￥' + min[key]
                    }
                }
            }
        }

        goods.discount_type = discount_type;
        goods.discount_list = tableData;

        if (typeof callBack == 'function') {
            callBack(goods)
        }

        this.setState({
            visible: false,
            tableData: []
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    onRadioChange = e => {
        this.setState({
            discount_type: e.target.value,
        });
    }

    render() {
        return (
            <Modal
                title="设置会员价"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={1100}
            >
                <p >商品名称：{this.state.goods.name}</p>
                <div className='mgt20 mgb20'>优惠方式：
                    <Radio.Group onChange={this.onRadioChange} value={this.state.discount_type}>
                        <Radio value={1}>折扣</Radio>
                        <Radio value={2}>指定价格</Radio>
                    </Radio.Group>
                </div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => (record.id + '_' + record.sku_id)}
                    pagination={false}
                ></Table>
            </Modal>
        )
    }
}

class SetCourseVipRule extends Component {
    state = {
        visible: false,
        columns: [
            {
                title: '收费方式',
                dataIndex: 'charge_type',
                render: (text, rowData, index) => {
                    let charge_type
                    if (rowData.charge_type == 1) {
                        charge_type = "按课时"
                    } else if (rowData.charge_type == 2) {
                        charge_type = "按时段"
                    } else {
                        charge_type = "按期"
                    }
                    return <p>{charge_type || '-'}</p>
                }
            },
            {
                title: '零售价',
                dataIndex: 'price',
                width: 100,
                render: (text, rowData, index) => {
                    return (
                        <div>&yen;{rowData.price}</div>
                    )
                }
            },
            {
                title: 'V1',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v1}
                                onChange={this.handleChange('v1', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v2}
                                onChange={this.handleChange('v2', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v3}
                                onChange={this.handleChange('v3', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v4}
                                onChange={this.handleChange('v4', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v5}
                                onChange={this.handleChange('v5', index)}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter={this.state.discount_type == 1 ? "折" : "元"}
                                value={rowData.v6}
                                onChange={this.handleChange('v6', index)}
                            />
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        callBack: null,
        discount_type: 1,
        goods: {}
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = (row, callBack) => {
        let tableData = [],
            goods = row;

        if (row.discount_list) {
            tableData = row.discount_list
        } else {
            tableData.push({
                id: row.id,
                price: row.price,
                charge_type: row.charge_type,
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            })
        }


        this.setState({
            tableData,
            goods,
            discount_type: row.discount_type || 1,
            callBack: callBack || null,
            visible: true
        })
    }

    handleChange = (name, index) => {
        return e => {
            let value = e.target.value;
            let tableData = this.state.tableData;
            tableData[index][name] = value;
            this.setState({
                tableData
            })
        }
    }

    handleOk = () => {
        let { goods, tableData, discount_type, callBack } = this.state
        if (tableData.length == 1) {
            let aStr = '', bStr = ''
            if (discount_type == 1) {
                bStr = '折'
            } else {
                aStr = '￥'
            }
            if (tableData[0].v1) {
                goods.v1 = aStr + tableData[0].v1 + bStr
            }
            if (tableData[0].v2) {
                goods.v2 = aStr + tableData[0].v2 + bStr
            }
            if (tableData[0].v3) {
                goods.v3 = aStr + tableData[0].v3 + bStr
            }
            if (tableData[0].v4) {
                goods.v4 = aStr + tableData[0].v4 + bStr
            }
            if (tableData[0].v5) {
                goods.v5 = aStr + tableData[0].v5 + bStr
            }
            if (tableData[0].v6) {
                goods.v6 = aStr + tableData[0].v6 + bStr
            }
        }

        goods.discount_type = discount_type;
        goods.discount_list = tableData;

        if (typeof callBack == 'function') {
            callBack(goods)
        }

        this.setState({
            visible: false,
            tableData: []
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    onRadioChange = e => {
        this.setState({
            discount_type: e.target.value,
        });
    }

    render() {
        return (
            <Modal
                title="设置会员价"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={1100}
            >
                <p >课程名称：{this.state.goods.name}</p>
                <div className='mgt20 mgb20'>优惠方式：
                    <Radio.Group onChange={this.onRadioChange} value={this.state.discount_type}>
                        <Radio value={1}>折扣</Radio>
                        <Radio value={2}>指定价格</Radio>
                    </Radio.Group>
                </div>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => (record.id)}
                    pagination={false}
                ></Table>
            </Modal>
        )
    }
}

class SetBirthdayVipRule extends Component {
    state = {
        visible: false,
        columns: [
            {
                title: 'V1',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v1}
                                onChange={this.handleChange('v1')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v2}
                                onChange={this.handleChange('v2')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v3}
                                onChange={this.handleChange('v3')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v4}
                                onChange={this.handleChange('v4')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v5}
                                onChange={this.handleChange('v5')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="折"
                                value={rowData.v6}
                                onChange={this.handleChange('v6')}
                            />
                        </div>
                    )
                }
            },
        ],
        tableData: [

        ],
        callBack: null,
        time: 1,
        detail: {}
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = (row, callBack) => {
        let { tableData, time } = this.state;
        if (row.rule) {
            tableData[0] = row.rule;
            time = row.rule.time || 1;
        } else {
            tableData[0] = {
                id: 1,
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            }
            time = 1;
        }

        this.setState({
            detail: row,
            tableData,
            time,
            callBack: callBack || null,
            visible: true
        })
    }

    handleChange = name => {
        return e => {
            let value = e.target.value;
            let tableData = this.state.tableData;
            tableData[0][name] = value;
            this.setState({
                tableData
            })
        }
    }

    handleOk = () => {
        let { tableData, callBack, detail, time } = this.state;
        detail.rule = tableData[0];
        detail.rule.time = time;
        if (detail.rule.v1 > 0) {
            detail.v1 = detail.rule.v1 + '折'
        }
        if (detail.rule.v2 > 0) {
            detail.v2 = detail.rule.v2 + '折'
        }
        if (detail.rule.v3 > 0) {
            detail.v3 = detail.rule.v3 + '折'
        }
        if (detail.rule.v4 > 0) {
            detail.v4 = detail.rule.v4 + '折'
        }
        if (detail.rule.v5 > 0) {
            detail.v5 = detail.rule.v5 + '折'
        }
        if (detail.rule.v6 > 0) {
            detail.v6 = detail.rule.v6 + '折'
        }

        if (typeof callBack == 'function') {
            callBack(detail)
        }

        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleSelect = value => {
        this.setState({
            time: value
        })
    }

    render() {
        return (
            <Modal
                title="设置会员价"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={850}
            >
                <p className='mgt10 mgb20'>生日权益：所有商品、自助买单 <span className='mgl20 fz12 color-999'>注：设置10折表示无折扣</span></p>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className='mgt30'>
                    <span><span style={{ color: '#f44' }}>*</span> 生效时间：</span>
                    <Select value={this.state.time} style={{ width: 200 }} onChange={this.handleSelect}>
                        <Select.Option value={1}>生日当天</Select.Option>
                        <Select.Option value={2}>生日当周</Select.Option>
                        <Select.Option value={3}>生日当月</Select.Option>
                    </Select>
                </div>
            </Modal>
        )
    }
}

class SetBirthdayPointsRule extends Component {
    state = {
        visible: false,
        columns: [
            {
                title: 'V1',
                dataIndex: '1',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="倍"
                                value={rowData.v1}
                                onChange={this.handleChange('v1')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="倍"
                                value={rowData.v2}
                                onChange={this.handleChange('v2')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="倍"
                                value={rowData.v3}
                                onChange={this.handleChange('v3')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="倍"
                                value={rowData.v4}
                                onChange={this.handleChange('v4')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="倍"
                                value={rowData.v5}
                                onChange={this.handleChange('v5')}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            <Input
                                type='number'
                                style={{ width: 110 }}
                                placeholder={''}
                                maxLength={10}
                                addonAfter="倍"
                                value={rowData.v6}
                                onChange={this.handleChange('v6')}
                            />
                        </div>
                    )
                }
            },
        ],
        tableData: [

        ],
        callBack: null,
        time: 1,
        detail: {}
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    show = (row, callBack) => {
        let { tableData, time } = this.state;
        if (row.rule) {
            tableData[0] = row.rule;
            time = row.rule.time || 1;
        } else {
            tableData[0] = {
                id: 1,
                v1: '',
                v2: '',
                v3: '',
                v4: '',
                v5: '',
                v6: ''
            }
            time = 1;
        }

        this.setState({
            detail: row,
            tableData,
            time,
            callBack: callBack || null,
            visible: true
        })
    }

    handleChange = name => {
        return e => {
            let value = e.target.value;
            let tableData = this.state.tableData;
            tableData[0][name] = value;
            this.setState({
                tableData
            })
        }
    }

    handleOk = () => {
        let { tableData, callBack, detail, time } = this.state;
        detail.rule = tableData[0];
        detail.rule.time = time;
        if (detail.rule.v1 > 0) {
            detail.v1 = detail.rule.v1 + '倍'
        }
        if (detail.rule.v2 > 0) {
            detail.v2 = detail.rule.v2 + '倍'
        }
        if (detail.rule.v3 > 0) {
            detail.v3 = detail.rule.v3 + '倍'
        }
        if (detail.rule.v4 > 0) {
            detail.v4 = detail.rule.v4 + '倍'
        }
        if (detail.rule.v5 > 0) {
            detail.v5 = detail.rule.v5 + '倍'
        }
        if (detail.rule.v6 > 0) {
            detail.v6 = detail.rule.v6 + '倍'
        }

        if (typeof callBack == 'function') {
            callBack(detail)
        }

        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleSelect = value => {
        this.setState({
            time: value
        })
    }

    render() {
        return (
            <Modal
                title="设置多倍积分"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={850}
            >
                <p className='mgt10 mgb20'>生日权益：消费积分多倍 </p>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                ></Table>
                <div className='mgt30'>
                    <span><span style={{ color: '#f44' }}>*</span> 生效时间：</span>
                    <Select value={this.state.time} style={{ width: 200 }} onChange={this.handleSelect}>
                        <Select.Option value={1}>生日当天</Select.Option>
                        <Select.Option value={2}>生日当周</Select.Option>
                        <Select.Option value={3}>生日当月</Select.Option>
                    </Select>
                </div>
            </Modal>
        )
    }
}


class SetBirthdayCouponRule extends Component {
    state = {
        visible: false,
        columns: [
            {
                title: 'V1',
                dataIndex: '1',
                width: 200,
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.v1.map((item, j) => {
                                    return (
                                        <div className="bg-p8 mgb10" key={item.id}>

                                            <Select value={item.coupon_id || undefined} style={{ width: 180 }} onChange={this.handleCouponSelect('v1', j)} placeholder='请选择优惠券'>
                                                {this.state.couponLists.map(val => {
                                                    return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                                })}
                                            </Select>
                                            <div className='flex mgt10 align-center'>
                                                <span className='mgl10'>发放数量：</span>
                                                <Input
                                                    type='number'
                                                    style={{ width: 100 }}
                                                    placeholder={''}
                                                    maxLength={10}
                                                    addonAfter="张"
                                                    value={item.num}
                                                    onChange={this.handleChange('v1', j)}
                                                />
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                rowData.v1.length < 5 ?
                                    <p className='fz12 color-999'><span className='text_link fz16' onClick={this.handleAdd('v1')}>+增加</span>最多可选5张</p> : null
                            }

                        </div>
                    )
                }
            },
            {
                title: 'V2',
                dataIndex: '2',
                render: (text, rowData, index) => {
                    return (
                        <div>
                           {
                                rowData.v2.map((item, j) => {
                                    return (
                                        <div className="bg-p8 mgb10" key={item.id}>

                                            <Select value={item.coupon_id || undefined} style={{ width: 180 }} onChange={this.handleCouponSelect('v2', j)} placeholder='请选择优惠券'>
                                                {this.state.couponLists.map(val => {
                                                    return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                                })}
                                            </Select>
                                            <div className='flex mgt10 align-center'>
                                                <span className='mgl10'>发放数量：</span>
                                                <Input
                                                    type='number'
                                                    style={{ width: 100 }}
                                                    placeholder={''}
                                                    maxLength={10}
                                                    addonAfter="张"
                                                    value={item.num}
                                                    onChange={this.handleChange('v2', j)}
                                                />
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                rowData.v2.length < 5 ?
                                    <p className='fz12 color-999'><span className='text_link fz16' onClick={this.handleAdd('v2')}>+增加</span>最多可选5张</p> : null
                            }
                        </div>
                    )
                }
            },
            {
                title: 'V3',
                dataIndex: '3',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.v3.map((item, j) => {
                                    return (
                                        <div className="bg-p8 mgb10" key={item.id}>

                                            <Select value={item.coupon_id || undefined} style={{ width: 180 }} onChange={this.handleCouponSelect('v3', j)} placeholder='请选择优惠券'>
                                                {this.state.couponLists.map(val => {
                                                    return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                                })}
                                            </Select>
                                            <div className='flex mgt10 align-center'>
                                                <span className='mgl10'>发放数量：</span>
                                                <Input
                                                    type='number'
                                                    style={{ width: 100 }}
                                                    placeholder={''}
                                                    maxLength={10}
                                                    addonAfter="张"
                                                    value={item.num}
                                                    onChange={this.handleChange('v3', j)}
                                                />
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                rowData.v3.length < 5 ?
                                    <p className='fz12 color-999'><span className='text_link fz16' onClick={this.handleAdd('v3')}>+增加</span>最多可选5张</p> : null
                            }
                        </div>
                    )
                }
            },
            {
                title: 'V4',
                dataIndex: '4',
                render: (text, rowData, index) => {
                    return (
                        <div>
                            {
                                rowData.v4.map((item, j) => {
                                    return (
                                        <div className="bg-p8 mgb10" key={item.id}>

                                            <Select value={item.coupon_id || undefined} style={{ width: 180 }} onChange={this.handleCouponSelect('v4', j)} placeholder='请选择优惠券'>
                                                {this.state.couponLists.map(val => {
                                                    return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                                })}
                                            </Select>
                                            <div className='flex mgt10 align-center'>
                                                <span className='mgl10'>发放数量：</span>
                                                <Input
                                                    type='number'
                                                    style={{ width: 100 }}
                                                    placeholder={''}
                                                    maxLength={10}
                                                    addonAfter="张"
                                                    value={item.num}
                                                    onChange={this.handleChange('v4', j)}
                                                />
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                rowData.v4.length < 5 ?
                                    <p className='fz12 color-999'><span className='text_link fz16' onClick={this.handleAdd('v4')}>+增加</span>最多可选5张</p> : null
                            }
                        </div> 
                    )
                }
            },
            {
                title: 'V5',
                dataIndex: '5',
                render: (text, rowData, index) => {
                    return (
                        <div>
                           {
                                rowData.v5.map((item, j) => {
                                    return (
                                        <div className="bg-p8 mgb10" key={item.id}>

                                            <Select value={item.coupon_id || undefined} style={{ width: 180 }} onChange={this.handleCouponSelect('v5', j)} placeholder='请选择优惠券'>
                                                {this.state.couponLists.map(val => {
                                                    return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                                })}
                                            </Select>
                                            <div className='flex mgt10 align-center'>
                                                <span className='mgl10'>发放数量：</span>
                                                <Input
                                                    type='number'
                                                    style={{ width: 100 }}
                                                    placeholder={''}
                                                    maxLength={10}
                                                    addonAfter="张"
                                                    value={item.num}
                                                    onChange={this.handleChange('v5', j)}
                                                />
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                rowData.v5.length < 5 ?
                                    <p className='fz12 color-999'><span className='text_link fz16' onClick={this.handleAdd('v5')}>+增加</span>最多可选5张</p> : null
                            }
                        </div>
                    )
                }
            },
            {
                title: 'V6',
                dataIndex: '6',
                render: (text, rowData, index) => {
                    return (
                        <div>
                             {
                                rowData.v6.map((item, j) => {
                                    return (
                                        <div className="bg-p8 mgb10" key={item.id}>

                                            <Select value={item.coupon_id || undefined} style={{ width: 180 }} onChange={this.handleCouponSelect('v6', j)} placeholder='请选择优惠券'>
                                                {this.state.couponLists.map(val => {
                                                    return <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                                                })}
                                            </Select>
                                            <div className='flex mgt10 align-center'>
                                                <span className='mgl10'>发放数量：</span>
                                                <Input
                                                    type='number'
                                                    style={{ width: 100 }}
                                                    placeholder={''}
                                                    maxLength={10}
                                                    addonAfter="张"
                                                    value={item.num}
                                                    onChange={this.handleChange('v6', j)}
                                                />
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                rowData.v6.length < 5 ?
                                    <p className='fz12 color-999'><span className='text_link fz16' onClick={this.handleAdd('v6')}>+增加</span>最多可选5张</p> : null
                            }
                        </div>
                    )
                }
            },
        ],
        tableData: [],
        callBack: null,
        time: 1,
        detail: {},
        couponLists: []
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        this.fetchCoupon()
    }

    fetchCoupon = () => {
        let sendData = {
            page: 1,
            per_page: 100,
            status: 1
        };


        couponLists(sendData)
            .then(res => {
                if (res.code == 1) {
                    let lists = res.data.coupon_list.data,
                        total = res.data.coupon_list.total,
                        per_page = res.data.coupon_list.per_page;

                    lists = lists.filter(val => val.last_num > 0)
                    this.setState({
                        couponLists: lists,
                        total, per_page
                    })

                }
            })

    }

    show = (row, callBack) => {
        let { tableData, time } = this.state;
        if (row.rule) {
            tableData[0] = row.rule;
            time = row.rule.time || 1;
        } else {
            tableData[0] = {
                id: 1,
                v1: [{ id: 1, coupon_id: '', num: '' }],
                v2: [{ id: 1, coupon_id: '', num: '' }],
                v3: [{ id: 1, coupon_id: '', num: '' }],
                v4: [{ id: 1, coupon_id: '', num: '' }],
                v5: [{ id: 1, coupon_id: '', num: '' }],
                v6: [{ id: 1, coupon_id: '', num: '' }]
            }
            time = 1;
        }

        this.setState({
            detail: row,
            tableData,
            time,
            callBack: callBack || null,
            visible: true
        })
    }

    handleAdd = name => {
        return () => {
            let tableData = this.state.tableData;
            let vArr = tableData[0][name];
            if (vArr.length < 5) {
                vArr.push({
                    id: vArr.length + 1,
                    coupon_id: '',
                    num: ''
                })
            } else {
                return
            }
            tableData[0][name] = vArr;
            this.setState({
                tableData
            })

        }
    }

    handleCouponSelect = (name, index) => {
        return value => {
            let tableData = this.state.tableData;
            tableData[0][name][index].coupon_id = value;
            this.setState({
                tableData
            })
        }
    }

    handleChange = (name, index) => {
        return e => {
            let value = e.target.value;
            let tableData = this.state.tableData;
            tableData[0][name][index].num = value;
            this.setState({
                tableData
            })
        }
    }

    handleOk = () => {
        let { tableData, callBack, detail, time } = this.state;
        detail.rule = tableData[0];
        detail.rule.time = time;
        if (detail.rule.v1.length > 0) {
            let num=0;
            detail.rule.v1.forEach(item=>{
                if(item.coupon_id && item.num>0){
                    num += +item.num;
                }
            })
            if(num>0){
                detail.v1 = `已选${num}张优惠券`
            }
        }
        if (detail.rule.v2.length > 0) {
            let num=0;
            detail.rule.v2.forEach(item=>{
                if(item.coupon_id && item.num>0){
                    num += +item.num;
                }
            })
            if(num>0){
                detail.v2 = `已选${num}张优惠券`
            }
        }
        if (detail.rule.v3.length > 0) {
            let num=0;
            detail.rule.v3.forEach(item=>{
                if(item.coupon_id && item.num>0){
                    num += +item.num;
                }
            })
            if(num>0){
                detail.v3 = `已选${num}张优惠券`
            }
        }
        if (detail.rule.v4.length > 0) {
            let num=0;
            detail.rule.v4.forEach(item=>{
                if(item.coupon_id && item.num>0){
                    num += +item.num;
                }
            })
            if(num>0){
                detail.v4 = `已选${num}张优惠券`
            }
        }
        if (detail.rule.v5.length > 0) {
            let num=0;
            detail.rule.v5.forEach(item=>{
                if(item.coupon_id && item.num>0){
                    num += +item.num;
                }
            })
            if(num>0){
                detail.v5 = `已选${num}张优惠券`
            }
        }
        if (detail.rule.v6.length > 0) {
            let num=0;
            detail.rule.v6.forEach(item=>{
                if(item.coupon_id && item.num>0){
                    num += +item.num;
                }
            })
            if(num>0){
                detail.v6 = `已选${num}张优惠券`
            }
        }
       

        if (typeof callBack == 'function') {
            callBack(detail)
        }

        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleSelect = value => {
        this.setState({
            time: value
        })
    }

    render() {
        return (
            <Modal
                title="设置生日礼券"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={1300}
            >
                <p className='mgt10 mgb20'>生日权益：生日礼券</p>
                <Table
                    size={`middle`}
                    columns={this.state.columns}
                    dataSource={this.state.tableData}
                    loading={false}
                    rowKey={record => record.id}
                    pagination={false}
                    rowClassName='table-bg-unset'
                ></Table>
                <div className='mgt30'>
                    <span><span style={{ color: '#f44' }}>*</span> 生效时间：</span>
                    <Select value={this.state.time} style={{ width: 200 }} onChange={this.handleSelect}>
                        <Select.Option value={1}>生日当天</Select.Option>
                        <Select.Option value={2}>生日当周</Select.Option>
                        <Select.Option value={3}>生日当月</Select.Option>
                    </Select>
                </div>
            </Modal>
        )
    }
}


export {
    SetExpendVipRule,
    SetGoodsVipRule,
    SetCourseVipRule,
    SetBirthdayVipRule,
    SetBirthdayPointsRule,
    SetBirthdayCouponRule
}