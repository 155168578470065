import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Select, Input, Form, Radio, Checkbox } from 'antd'
import { CourseCategorySaveApi } from '@/api/course'
import message from '@/utils/message'
const FormItem = Form.Item
const { TextArea } = Input

class AddCourt extends React.Component{
    state = {
        modalLoading:false,
        show:false
    }
    componentDidMount() {
        this.props.onRef(this)
       
    }


    handleOk=()=>{
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.sort=values.sort?values.sort:0
                if(isNaN(values.sort)){
                    values.sort=0
                }
                let sendData = values;
                sendData.id=this.props.formData.id
                sendData.pid=this.props.formData.pid

                this.setState({
                    modalLoading:true
                })
                CourseCategorySaveApi(sendData).then(res=>{
                    message.success(res.msg)
                    if(res.code === 1){
                        this.handleCancel()
                        this.props.onSuccess()
                    }
                }).finally(()=>{
                    this.setState({
                        modalLoading:false
                    })
                })
                
            }
        })
    }
    handleCancel=()=>{
        this.setState({
            show:false
        })
    }
    show=()=>{
        this.setState({
            show:true
        })
    }
    render() {
       
        let formData=this.props.formData
       
        const formItemLayout = {
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 16
            }
        }
        const { getFieldDecorator,getFieldValue } = this.props.form
        return(
            <Modal
                title={formData.title||""}
                visible={this.state.show}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={600}
                centered={true}
                confirmLoading={this.state.modalLoading}
                maskClosable={false}
                destroyOnClose={true}
            >
                <Form className={`model-form`} { ...formItemLayout }>
                    <FormItem label={`类目名称`}>
                        {
                            getFieldDecorator('name',{
                                initialValue: formData.name||"",
                                rules:[
                                    {
                                        required: true,
                                        message: '类目名称'
                                    }
                                ]
                            })(
                                <Input placeholder='请输入类目名称，限制10个字符'  maxLength={10} suffix={<span>{getFieldValue("name").length}/10</span>} />
                            )
                        }
                    </FormItem>
                    <FormItem label={'排序号'}>
                        {getFieldDecorator('sort', {
                            initialValue: formData.sort || ""
                        })(
                            <Input  />
                        )}
                        <span className={`fi-help-text`}>从小到大排列，小的靠前</span>
                    </FormItem>
                </Form>

            </Modal>
        )
    }
}

export default Form.create({})(withRouter(AddCourt))
