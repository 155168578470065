import $axios from '@/axios/axios.js'

export function stockIn(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/storage/store`,
        method:'post',
        data
    })
}

export function stockOut(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/storage/deliver`,
        method:'post',
        data
    })
}

export function stockRecord(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/storage/record`,
        method:'post',
        data
    })
}

export function stockDetail(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/storage/record-detail`,
        method:'post',
        data
    })
}

export function stockLists(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/storage/lists`,
        method:'post',
        data
    })

}

export function storageDesc(data) {
    return $axios({
        url:`${process.env.REACT_APP_BASE_API}/mp/storage/detail`,
        method:'post',
        data
    })
}


