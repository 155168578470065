import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, message,Input } from 'antd'
import { adminDetail ,OpenApiApply} from '@/api/admin'
import { setShop } from '@/redux/actions/shop'
import Loading from '@/components/Loading'
import CX from '@/utils/CX.js'
import '@/assets/css/open/index.scss';
const apiLists = [
  {
    id: 1,
    icon: "https://img.cxkoo.com/chengxuan/1/2022-12-09/4fca5b46faa10bd1d08fd49c73162c10.png",
    title: "商品API",
    text: "商品信息、库存管理"
  },
  {
    id: 2,
    icon: "https://img.cxkoo.com/chengxuan/1/2022-12-09/00bc3a0208d47330bb3639d273e55abf.png",
    title: "客户API",
    text: "客户列表、客户详情、互动轨迹"
  },
  {
    id: 3,
    icon: "https://img.cxkoo.com/chengxuan/1/2022-12-09/7cb20285dbadce7dee00a75c72e17ffb.png",
    title: "客户洞察API",
    text: "客户标签、客户自定义属性管理"
  },
  {
    id: 4,
    icon: "https://img.cxkoo.com/chengxuan/1/2022-12-09/20456e45b8364c141180d95579c889f5.png",
    title: "订单API",
    text: "订单数据与状态同步"
  },
  {
    id: 5,
    icon: "https://img.cxkoo.com/chengxuan/1/2022-12-09/faa9fcea156da6ba52dfd385068dde35.png",
    title: "营销API",
    text: "多钟营销工具"
  },
  {
    id: 6,
    icon: "https://img.cxkoo.com/chengxuan/1/2022-12-09/d327242153b19015c99c338d43af256c.png",
    title: "更多能力",
    text: "提交需求，快速对接"
  }
]

class Open extends Component {
  state = {
    visible: false,
    isLoading:false
  }

  fetchData = () => {
    let sendData = {};
    adminDetail(sendData)
      .then(res => {
        if (res.code == 1) {
          let shop = res.data.shop;
          this.props.setShop(shop);
        }
      })
  }

  handleCancel = () => {
    this.setState({
      visible: false
    })
  }
  handleShow = () => {
    this.setState({
      visible: true
    })
  }

  handleOpenApply=()=>{
    this.setState({
      isLoading:true
    })
    OpenApiApply({}).then(res=>{
      if(res.code==1){
        this.fetchData()
      }else{
        message.error(res.msg)
      }
    }).finally(()=>{
      this.setState({
        isLoading:false
      })
    })
  }

  handleCopy = (text) => {
    CX.copyTxt(text);
    message.success('复制成功')
  }

  render() {
    const shop = this.props.shop || {}
    const link_url='https://www.yuque.com/g/mr6g29/rcz7gy/collaborator/join?token=t6QWde0cSZ1wJbnr#'

    return (
      <div className="open-mp-box">
        <div className='open-mp' >
          <div className="banner-box">
            <img src="https://img.cxkoo.com/chengxuan/1/2022-12-09/bc3a9fe18ec1dfd441c39c6f6addab6b.png" alt="" className='banner-bg' />
            <div className="banner-txt">
              <p className="txt1">澄瑄开放平台</p>
              <p className="txt2 mgt20">打破数据孤岛，实现企业智能化数据建设，加速企业数字化转型</p>
            </div>
          </div>
          <div className="fz30 tac fw-500 pdt50 color-000">服务能力</div>
          <div className="open-serve tac">
            <div className="serve-item">
              <i className="icon1"></i>
              <p className='fz16 mgt20 ft-bold '>数据接入</p>
              <p className='fz13 mgt20 tal'>支持企业自有数据通过多种方式灵活接入，形成对私域会员的全域洞察</p>
            </div>
            <div className="serve-item mgl10">
              <i className="icon2"></i>
              <p className='fz16 mgt20 ft-bold '>业务接入</p>
              <p className='fz13 mgt20 tal'>基于业务扩展点，支持企业自有业务模块化嵌入至澄瑄软件系统，打通私域会员的完整业务流</p>
            </div>
            <div className="serve-item mgl10">
              <i className="icon3"></i>
              <p className='fz16 mgt20 ft-bold '>开放API</p>
              <p className='fz13 mgt20 tal'>丰富的API接口，企业可以基于API将澄瑄软件系统与内部已有信息系统进行有效整合，实现全面数字化升级</p>
            </div>
            <div className="serve-item mgl10">
              <i className="icon4"></i>
              <p className='fz16 mgt20 ft-bold '>解决方案咨询</p>
              <p className='fz13 mgt20 tal'>客户成功导向，基于丰富的行业经验结合企业特征深度诊断，为企业量身定制完整解决方案</p>
            </div>
          </div>
          <div className="open-api">
            <div className="fz30 tac fw-500 pdt50 color-000">开放API</div>
            <div className="api-wrap">
              {
                apiLists.map(item => (
                  <div className="api-col color-000" key={item.id}>
                    <div className="left">
                      <i className="icon" style={{ backgroundImage: `url('${item.icon}')` }} ></i>
                    </div>
                    <div>
                      <p className="fz20 fw-500">{item.title}</p>
                      <p className="fz13 mgt10">{item.text}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="process ">
            <div className="fz30 tac fw-500 color-000">接入流程</div>
            <div className="row mgt50 tac">
              <div className="col">
                <i className="icon1"></i>
                <p className="fz16 ft-bold mgt20">开通澄瑄旗下系统产品</p>
                <p className="fz13 mgt10">仅针对付费版企业开放</p>
              </div>
              <div className="you-icon"></div>
              <div className="col">
                <i className="icon1"></i>
                <p className="fz16 ft-bold mgt20">开发者认证</p>
                <p className="fz13 mgt10">提交开发者资质信息</p>
              </div>
              <div className="you-icon"></div>
              <div className="col">
                <i className="icon1"></i>
                <p className="fz16 ft-bold mgt20">申请Key</p>
                <p className="fz13 mgt10">获取专属调用秘钥</p>
              </div>
              <div className="you-icon"></div>
              <div className="col">
                <i className="icon1"></i>
                <p className="fz16 ft-bold mgt20">集成产品与服务</p>
                <p className="fz13 mgt10">调用API进行产品集成</p>
              </div>
            </div>
          </div>

        </div>
        <div className="footer-btns">
          {shop.open_api_key ?
            <Button type='primary' onClick={this.handleShow} >查看密钥</Button> :
            <Button type='primary' onClick={this.handleOpenApply} >立即接入</Button>
          }
        </div>
        <Modal
          title="相关密钥"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          wrapClassName={"open-modal"}
          width={700}
        >
          <div className="modal-body ">
            <div className="row">
              <span className='row-label'>open_appid：</span>
              <Input value={shop.open_appid}   style={{width:350}} />
              
              <Button type='link' size='small' onClick={()=>this.handleCopy(shop.open_appid)}  className='mgl10' >复制</Button>
            </div>
            <div className="row">
              <span className='row-label'>open_api_key：</span>
              <Input.Password value={shop.open_api_key}   style={{width:350}} />
              <Button type='link' size='small' onClick={()=>this.handleCopy(shop.open_api_key)}  className='mgl10' >复制</Button>
            </div>
            <div className="row">
              <span className='row-label'>open_api_secret：</span>
              <Input.Password value={shop.open_api_secret}    style={{width:350}} />
              <Button type='link' size='small' onClick={()=>this.handleCopy(shop.open_api_secret)}  className='mgl10' >复制</Button>
            </div>
            <div className="row">
              <span className='row-label'>文档地址：</span>
              <div className="link"> <a href={link_url} target={'_blank'} >{link_url}</a></div>
              <Button type='link' size='small'onClick={()=>this.handleCopy(link_url)} className='mgl10' >复制</Button>
            </div>
          </div>
        </Modal>
        {this.state.isLoading?<Loading />:null}
      </div>
    )
  }
}

const mapStateToProps = state => state
const mapDispatchToProps = dispatch => ({
  setShop: data => {
    dispatch(setShop(data))
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(Open)